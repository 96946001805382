import { LabelledOption } from '@shared/Select';
import { InterestLevel } from '@ascension/_gqltypes/subbie.generated';

const { INTERESTED, NOT_INTERESTED, QUOTED, QUOTING, EXPLICITLY_RESET, UNACTIONED } = InterestLevel;

export type WatchlistOption = LabelledOption<InterestLevel>;

const WATCHLIST_OPTIONS: WatchlistOption[] = [
  {
    value: INTERESTED,
    label: 'Interested',
  },
  {
    value: NOT_INTERESTED,
    label: 'Not Interested',
  },
  {
    value: QUOTING,
    label: 'Quoting',
  },
  {
    value: QUOTED,
    label: 'Quoted',
  },
];

const WATCHLIST_ENUM_OPTIONS: LabelledOption<InterestLevel>[] = [
  {
    value: InterestLevel.INTERESTED,
    label: 'Interested',
  },
  {
    value: InterestLevel.NOT_INTERESTED,
    label: 'Not Interested',
  },
  {
    value: InterestLevel.QUOTING,
    label: 'Quoting',
  },
  {
    value: InterestLevel.QUOTED,
    label: 'Quoted',
  },
];

const WATCHLIST_OPTION_RESET: WatchlistOption = {
  value: EXPLICITLY_RESET,
  label: 'Clear Selection',
};

const WATCHLIST_OPTION_UNSELECTED: WatchlistOption = {
  value: UNACTIONED,
  label: 'Please Select',
};

export { INTERESTED, NOT_INTERESTED, QUOTED, QUOTING };
export {
  WATCHLIST_OPTIONS,
  WATCHLIST_ENUM_OPTIONS,
  WATCHLIST_OPTION_RESET,
  WATCHLIST_OPTION_UNSELECTED,
};
