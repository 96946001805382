import { TransformerVersionMap, Transformer } from './types';

const version1: Transformer = {
  buildText: (data) => <>{data.get('message')}</>,
  buildLink: () => null,
};

export const generalNotificationTransformerVersionMap: TransformerVersionMap = {
  1: version1,
};
