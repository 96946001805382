import { gql } from '@apollo/client';

export const HAS_SSC_ACCESS = gql`
  query HasSubbieSupplierConnectionAccess {
    hasSubbieSupplierConnectionAccess
  }
`;

export const GET_SUBBIE_CONNECTION_CREDIT_BALANCE = gql`
  query SubbieConnectionCreditBalance {
    subbieConnectionCreditBalance {
      id
      allowed
      used
      startDate
      renewalDate
    }
  }
`;
