import { useState } from 'react';
import { useQuery } from '@apollo/client';
import PageLoadingSpinner from '../../../../shared/LoadingSpinner/PageLoadingSpinner';
import ProfilesPageLayout from '../../../../shared/Profiles/Page/ProfilesPageLayout';
import ProfilesSummary from '../../../../shared/Profiles/ProfilesSummary';
import { ProfileSection } from '../../../../shared/Profiles/enums';
import { SUBBIE_PROFILE_FOR_ACCOUNT } from './queries';
import {
  SubbieProfileForAccount,
  SubbieProfileForAccountVariables,
} from './types/SubbieProfileForAccount';
import { EntityId } from '@ascension/types';

type SubbieProfilesPageProps = {
  accountId: EntityId;
};

const SubbieProfilesPage = ({ accountId }: SubbieProfilesPageProps) => {
  const [section, setSection] = useState<ProfileSection>(ProfileSection.SUMMARY);

  const { data, loading } = useQuery<SubbieProfileForAccount, SubbieProfileForAccountVariables>(
    SUBBIE_PROFILE_FOR_ACCOUNT,
    {
      variables: { accountId },
    },
  );

  if (loading || !data) return <PageLoadingSpinner />;

  const renderCurrentSection = () => {
    switch (section) {
      case ProfileSection.SUMMARY:
      default:
        return <ProfilesSummary profile={data.subbieProfileForAccount} />;
    }
  };

  return (
    <ProfilesPageLayout
      profile={data.subbieProfileForAccount}
      currentSection={section}
      setSection={setSection}
      returnUrl="/"
    >
      {renderCurrentSection()}
    </ProfilesPageLayout>
  );
};

export default SubbieProfilesPage;
