import { useEffect } from 'react';
import { SSCWatchlistModal } from '@ssc/features/SSCWatchlistModal';
import ChooseSubTradesModal from '@subbie/features/ChooseSubTradesModal';
import ProjectSliderContainer from '@subbie/modal/ProjectSliderContainer';
import AccountProvider from '@subbie/context/AccountProvider';
import { ModalName, useModalContext } from '@subbie/context/ModalContext';
import { NoteCountProvider } from '@subbie/context/NoteCountProvider';
import WatchlistProvider from '@subbie/context/WatchlistProvider';
import { EntityId } from '@ascension/types';

const PROJECT_MODAL_EVENT = 'launch-project-modal';

const GlobalOverlayListener = () => {
  const { addModal } = useModalContext<{ projectId: EntityId; target?: string }>();

  useEffect(() => {
    const handleEvent = ({
      detail: { projectId: eventProjectId },
    }: CustomEvent<{ projectId: string }>) =>
      addModal(ModalName.Project, { projectId: parseInt(eventProjectId) });

    document.addEventListener(PROJECT_MODAL_EVENT, handleEvent);

    return () => document.removeEventListener(PROJECT_MODAL_EVENT, handleEvent);
  }, [addModal]);

  return (
    <AccountProvider>
      <WatchlistProvider>
        <NoteCountProvider>
          <ProjectSliderContainer />
        </NoteCountProvider>
        <SSCWatchlistModal />
      </WatchlistProvider>
      <ChooseSubTradesModal />
    </AccountProvider>
  );
};

/**
 * dispatch an event that can be listened for from react/vanilla js
 */
export const dispatchProjectModalEvent = (projectId: number): boolean =>
  document.dispatchEvent(
    new CustomEvent(PROJECT_MODAL_EVENT, {
      detail: {
        projectId,
      },
    }),
  );

export default GlobalOverlayListener;
