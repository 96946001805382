import { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { Slider } from '@estimateone/frontend-components';
import RedactedSliderHeader from '../../ProjectSliderHeader/RedactedSliderHeader';
import { handlePaywallAnalyticsEvent } from '../const';
import RoadblockModal from './RoadblockModal';
import LoadingSpinnerFloating from '../../../../../shared/LoadingSpinner/LoadingSpinnerFloating';
import { fireAndForget } from '@shared/Util/async';
import { useCurrentUser } from '@subbie/context/AccountProvider/hooks';
import { Action, Event, useAnalytics } from '../../../../../hooks/Analytics';
import { useProjectApi } from '../../../hooks';
import { ProjectRedactedReason } from '@ascension/_gqltypes/subbie.generated';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

const redirectWithDelay = (target: string, delay = 400): Promise<void> =>
  new Promise((resolve) => {
    setTimeout(() => {
      window.location.assign(target);
      return resolve();
    }, delay);
  });

type LegacyProjectPaywallProps = {
  projectId: EntityId;
  removeModal: () => void;
  target?: string;
  reason: ProjectRedactedReason;
  hasDocs: boolean;
};

const LegacyProjectPaywall = ({
  projectId,
  removeModal,
  target = '#project-modal',
  reason,
  hasDocs,
}: LegacyProjectPaywallProps) => {
  const user = useCurrentUser();
  const [isPaneOpen, setSliderOpen] = useState(true);
  const { projectSliderData } = useProjectApi(projectId);
  const { addEvent } = useAnalytics(Event.INTERACT);

  useEffect(() => {
    Modal.setAppElement('body');

    const handleOpenCloseAnalyticsEvent = (isOpen: boolean) =>
      addEvent({
        action: isOpen ? Action.PROJECT_SLIDER_OPEN : Action.PROJECT_SLIDER_CLOSE,
        projectId,
        variation: 'legacy',
        hasDocs,
      });

    fireAndForget(async () => {
      await Promise.all([
        handlePaywallAnalyticsEvent(projectId),
        handleOpenCloseAnalyticsEvent(true),
      ]);

      if (target && target !== '#project-modal') {
        await redirectWithDelay(target);
      }
    });

    return function closeEvent() {
      handleOpenCloseAnalyticsEvent(false);
    };
  }, [addEvent, projectId, target, hasDocs]);

  if (!user || !projectSliderData) return <LoadingSpinnerFloating />;

  const { project } = projectSliderData;

  // TODO: this component should accept a paywalled project object
  if (project.__typename !== 'PaywalledNoticeboardProject') {
    return null;
  }

  const handleClose = () => {
    setSliderOpen(false);
    removeModal();
  };

  return (
    <>
      <Slider rootAppElement="#root" isOpen={isPaneOpen} onRequestClose={handleClose}>
        <div className={styles.roadblockSlider}>
          <RedactedSliderHeader user={user} project={project} />
          <div className={styles.redactedProjectDetails} />
        </div>
        <div className={styles.roadblockSliderOverlay} />
      </Slider>

      {isPaneOpen && (
        <RoadblockModal
          removeModal={handleClose}
          reason={reason}
          projectId={project.id}
          disableAnalytics
        />
      )}
    </>
  );
};

export default LegacyProjectPaywall;
