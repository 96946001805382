import $ from 'jquery';

if (typeof Appcues !== 'undefined') {
  /* global Appcues:false */
  Appcues.on('flow_started', (eventData) => {
    if (window.global.user) {
      window.analyticsService.addEvent('interact', {
        action: 'CueFlowStart',
        flowName: eventData.flowName,
        flowId: eventData.flowId,
      });
    }
  });

  Appcues.on('flow_completed', (eventData) => {
    if (window.global.user) {
      window.analyticsService.addEvent('interact', {
        action: 'CueFlowComplete',
        flowName: eventData.flowName,
        flowId: eventData.flowId,
      });
    }
  });

  Appcues.on('flow_skipped', (eventData) => {
    if (window.global.user) {
      window.analyticsService.addEvent('interact', {
        action: 'CueFlowSkipped',
        flowName: eventData.flowName,
        flowId: eventData.flowId,
      });
    }
  });

  $('body').on('click', '[data-appcues-trigger]', (evt) => {
    const flowId = $(evt.currentTarget).data('appcues-trigger');

    if (flowId) {
      Appcues.show(flowId);
    }
  });
}
