import { useEffect, useMemo, useState } from 'react';
import { GroupBase } from 'react-select';
import { joinClassNames } from '@estimateone/frontend-components';
import SpeciFinderSliderButton from '@subbie/common/SpeciFinderSliderButton/SpeciFinderSliderButton';
import AddQuoteButton from './Buttons/AddQuoteButton';
import { PackageSelect } from './PackageSelect';
import { getGroupedPackageSelectOptions, getPackageSelectOptions } from './PackageSelect/utils';
import { StageDocsAccess } from './StageDocsAccess';
import { LabelledOption } from '@shared';
import LoadingSpinner from '@shared/LoadingSpinner';
import { useAccountStockTrades, useAccountType } from '@subbie/context/AccountProvider/hooks';
import { Action, useInteractAnalytics } from '@ascension/components/hooks/Analytics';
import { usePackageDownloadStatus } from '@subbie/modal/ProjectSlider/ProjectSliderBody/ProjectStages/StageRow/StageDocs/hooks';
import { GroupedPackageOption, PackageOption } from './PackageSelect/types';
import { AccountType, UserPackageDownloadStatus } from '@ascension/_gqltypes/subbie.generated';
import { EntityId, Stage } from '@subbie/modal/ProjectSlider/types';
import stageRowStyles from '../styles.scss';
import styles from './styles.scss';

type StageDocsProps = {
  stage: Pick<Stage, 'id' | 'activePackages'>;
  projectId: EntityId;
  builderName: string | null;
  keywords?: string[];
};

const isRecommendedPackages = (
  item: GroupBase<GroupedPackageOption>[] | PackageOption[],
): item is GroupBase<GroupedPackageOption>[] => item.length === 2 && 'options' in item[0];

const getRecommendedPackageId = (options: GroupBase<GroupedPackageOption>[] | PackageOption[]) => {
  if (isRecommendedPackages(options)) {
    const recommendedPackageList = options.find((option) => option.label === 'Recommended');
    if (recommendedPackageList && recommendedPackageList.options.length === 1) {
      return recommendedPackageList.options[0].value;
    }
  }

  return null;
};

export const StageDocs = ({
  stage: { id, activePackages },
  projectId,
  builderName,
  keywords = [],
}: StageDocsProps) => {
  const fullSetId = activePackages.find(({ fullSet }) => fullSet)?.id;

  const [packageId, setPackageId] = useState<EntityId>();
  const userStockTrades = useAccountStockTrades();
  const currentAccountType = useAccountType();
  const canRecommendPackage = currentAccountType === AccountType.SUBCONTRACTOR;
  const userStockTradeIds = useMemo(
    () => userStockTrades.map(({ id: stockTradeId }) => stockTradeId),
    [userStockTrades],
  );

  const { addEvent } = useInteractAnalytics();

  const handlePackageChange = ({ value }: LabelledOption<EntityId>) => setPackageId(value);

  const { loading, status } = usePackageDownloadStatus(id, packageId);

  const handleAnalytics = () => {
    addEvent({
      action: Action.SPECIFINDER_PROJECT_SLIDER_TRIGGER_CLICKED,
      stageId: id,
      projectId,
    });
  };

  const options = useMemo(
    () =>
      canRecommendPackage
        ? getGroupedPackageSelectOptions(activePackages, userStockTradeIds)
        : getPackageSelectOptions(activePackages),
    [activePackages, userStockTradeIds, canRecommendPackage],
  );

  const recommendedPackageId = getRecommendedPackageId(options);

  // Preserve the original behaviour where full set is selected by default or if it is the only package
  const shouldSetPackageId =
    packageId === undefined && (!canRecommendPackage || activePackages.length === 1);

  useEffect(() => {
    if (shouldSetPackageId) {
      setPackageId(fullSetId);
    } else if (
      canRecommendPackage &&
      recommendedPackageId !== packageId &&
      recommendedPackageId !== null
    ) {
      setPackageId(recommendedPackageId);
    }
  }, [canRecommendPackage, fullSetId, shouldSetPackageId, recommendedPackageId]);

  const packageName = activePackages.find(({ id: _ }) => _ === packageId)?.title;

  if (loading) return <LoadingSpinner />;

  const showAddQuoteButton =
    status?.includes(UserPackageDownloadStatus.APPROVED) && packageId !== undefined;

  const showKeywordsButton = keywords.length !== 0;
  const showDocsAccess =
    builderName !== null && packageId !== undefined && packageName !== undefined;

  return (
    <>
      <div className={stageRowStyles.sdRow}>
        <div className={joinClassNames(styles.btnContainer, styles.topButtons)}>
          <div>
            {showKeywordsButton && (
              <SpeciFinderSliderButton stageId={id} onKeywordsButtonClick={handleAnalytics} />
            )}
          </div>
          {showAddQuoteButton && (
            <AddQuoteButton projectId={projectId} packageId={packageId} stageId={id} />
          )}
        </div>
        <PackageSelect
          stageId={id}
          options={options}
          selectedValue={packageId ?? null}
          onChange={handlePackageChange}
          useGroupedRecommendedOptions={canRecommendPackage}
        />
      </div>
      {canRecommendPackage && packageId === fullSetId && (
        <span className={joinClassNames('small', styles.fullSetWarning)}>
          The full set may contain documents that are not relevant to you. We recommend selecting a
          specific package.
        </span>
      )}
      <div className={stageRowStyles.sdRow}>
        {showDocsAccess && (
          <StageDocsAccess
            stageId={id}
            packageId={packageId}
            packageName={packageName}
            builderName={builderName}
            packageStatus={status}
          />
        )}
      </div>
    </>
  );
};
