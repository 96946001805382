import { gql } from '@apollo/client';
import Quotes from '../../../../api/fragments/Quotes';

export const QUOTE_TO_COMPLETE = gql`
  query QuoteToComplete($quoteId: EntityId!, $stageId: EntityId!) {
    quote(id: $quoteId) {
      ...DownloadableQuoteFile
      package {
        id
        title
      }
      rfq {
        id
      }
    }
    stage(id: $stageId) {
      ... on UnrestrictedStage {
        id
        name
        projectAddress {
          id
          address1
          shortAddress
        }
        activePackages {
          id
          title
        }
        account {
          primaryCountry {
            id
          }
        }
      }
    }
  }
  ${Quotes.fragments.downloadableQuoteFile}
`;
