import { useState, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import Routing from 'routing';
import qs from 'query-string';
import { CTA, CTASize } from '@ascension/components/fec/CTA';
import { UpsellCard } from '@ascension/components/fec/UpsellCard';
import { LOCAL_STORAGE_CON_DEET_RETURN_PROJECT_ID } from '@subbie/modal/ProjectSlider/const';
import PurchaseAddonModal from '@subbie/modal/PurchaseAddonModal';
import LoadingSpinner from '@shared/LoadingSpinner';
import { useAccountType, useHasActivePaidLicenses } from '@subbie/context/AccountProvider/hooks';
import { useAnalytics, Event, Action } from '@ascension/components/hooks/Analytics';
import { ButtonName } from '@ascension/components/hooks/Analytics/buttonNames';
import useLocalStorage from '@ascension/components/hooks/LocalStorage';
import { GET_CONSULTANT_DETAILS_ADDON } from '@subbie/features/ConsultantFeature/ConsultantDirectory/queries';
import { AnalyticsTrackedParam, SourceTrackingToken } from '@ascension/enums/Analytics';
import { RequiresProjectId } from '../ConsultantsTable/types';
import { AccountType } from '@ascension/_gqltypes/subbie.generated';
import { EntityId } from '@ascension/types';
import { GetConsultantDetailsAddon } from '@subbie/features/ConsultantFeature/ConsultantDirectory/types/GetConsultantDetailsAddon';

const consultantsDirectoryUrl = Routing.generate('app_addressbook_consultants', {
  [AnalyticsTrackedParam.IN_APP_SOURCE]: SourceTrackingToken.LEARN_MORE_ROADBLOCK_CONSULTANTS,
});

export const PaywallCTA = ({ projectId }: RequiresProjectId) => {
  const accountType = useAccountType();
  const isSupplier = accountType === AccountType.SUPPLIER;
  const { addEvent } = useAnalytics(Event.INTERACT);

  const { data, loading } = useQuery<GetConsultantDetailsAddon>(GET_CONSULTANT_DETAILS_ADDON);
  const hasActivePaidLicenses = useHasActivePaidLicenses();
  const [showModal, setShowModal] = useState(false);

  const { set: setReturnProjectId } = useLocalStorage<EntityId>(
    LOCAL_STORAGE_CON_DEET_RETURN_PROJECT_ID,
  );

  const handleCTAClick = useCallback(() => {
    addEvent({
      action: Action.BUTTON_CLICKED,
      buttonName: ButtonName.CONSULTANT_DETAILS_CTA,
      projectId,
      accountType: accountType ?? 'unknown',
    });
    setReturnProjectId(projectId);
    window.location.assign(consultantsDirectoryUrl);
  }, [accountType, addEvent, projectId, setReturnProjectId]);

  const handlePlanSelectRedirect = useCallback(() => {
    const originalQueryStringParts = qs.parse(window.location.search);

    window.location.assign(
      Routing.generate('app_accountupgrade_index', {
        ...originalQueryStringParts,
        [AnalyticsTrackedParam.IN_APP_SOURCE]: SourceTrackingToken.UPGRADE_ROADBLOCK_CONSULTANTS,
      }),
    );
  }, []);

  const handleUpgrade = useCallback(() => {
    addEvent({
      action: Action.BUTTON_CLICKED,
      buttonName: ButtonName.CONSULTANT_DETAILS_UPGRADE,
      accountType: accountType ?? 'unknown',
    });
    handlePlanSelectRedirect();
  }, [accountType, addEvent, handlePlanSelectRedirect]);

  const handleGetAddon = useCallback(() => {
    addEvent({
      action: Action.BUTTON_CLICKED,
      buttonName: ButtonName.CONSULTANT_DETAILS_GET_ADDON,
      accountType: accountType ?? 'unknown',
    });

    if (hasActivePaidLicenses && data?.addon) {
      setShowModal(true);
    } else {
      handlePlanSelectRedirect();
    }
  }, [accountType, addEvent, data?.addon, handlePlanSelectRedirect, hasActivePaidLicenses]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (isSupplier) {
    return (
      <UpsellCard
        headerText="Find the architects and consultants spec'ing your business"
        text="For those who want up-to-date information on which architects and consultants are specifying their products, brands or competitors."
        primaryButtonAttr={{ children: 'Upgrade now', onClick: handleUpgrade }}
        learnMoreAttr={{ onClick: handleCTAClick }}
      />
    );
  }

  return (
    <>
      <CTA
        text="Get the consultant details add-on to see architects, engineers and other consultants on each project."
        size={CTASize.Small}
        primaryButtonAttr={{ children: 'Get add-on', onClick: handleGetAddon }}
        secondaryButtonAttr={{ children: 'Learn more', onClick: handleCTAClick }}
      />
      {data?.addon && (
        <PurchaseAddonModal
          addon={data.addon}
          showModal={showModal}
          onClose={() => setShowModal(false)}
          source={SourceTrackingToken.GENERIC}
        />
      )}
    </>
  );
};
