import { Button, ButtonVariant, Icon, IconName } from '@estimateone/frontend-components';
import { ScopeOfWorksMenu } from '@scope-of-works/features/ScopeOfWorksMenu';
import { LettingScheduleStatus } from '../../LettingScheduleStatus';
import { LettingScheduleStatusLabel } from '../../LettingScheduleStatusLabel';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

export type PackageDashboardHeaderProps = {
  packageId: EntityId;
  packageTitle: string;
  stageName: string;
  lettingScheduleUrl: string;
  documentsUrl: string;
  lettingScheduleStatus: LettingScheduleStatus;
};

export const PackageDashboardHeader = ({
  packageId,
  packageTitle,
  stageName,
  lettingScheduleUrl,
  documentsUrl,
  lettingScheduleStatus,
}: PackageDashboardHeaderProps) => (
  <div className={styles.headerContainer}>
    <a className={styles.breadcrumbContainer} href={lettingScheduleUrl}>
      <Icon className={styles.backIcon} name={IconName.ArrowLeft} />
      <span className={styles.breadcrumbLink}>{stageName}</span>
    </a>
    <div className={styles.packageDetails}>
      <h1 className={styles.packageName}>{packageTitle}</h1>
      <div className={styles.packageStatus}>
        {lettingScheduleStatus !== undefined && (
          <LettingScheduleStatusLabel status={lettingScheduleStatus} isActive />
        )}
      </div>
      <div className={styles.buttonWrapper}>
        <ScopeOfWorksMenu packageId={packageId} />
        <Button variant={ButtonVariant.Grey} href={documentsUrl}>
          <Icon className={styles.fileIcon} name={IconName.File} />
          Documents
        </Button>
      </div>
    </div>
  </div>
);
