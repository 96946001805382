import { useEffect, useState } from 'react';

export const useBannerControl = () => {
  const [hideBanner, setHideBanner] = useState(false);
  const [bannerViewCount, setBannerViewCount] = useState(0);
  const showBanner = bannerViewCount <= 10 && !hideBanner;

  useEffect(() => {
    const permanentlyHideBanner = localStorage.getItem('hideConnectedCompanyBanner') === 'true';
    const totalViewCount = Number(localStorage.getItem('connectedCompanyBannerViewCount')) + 1;

    setHideBanner(permanentlyHideBanner);
    setBannerViewCount(totalViewCount);
    localStorage.setItem('connectedCompanyBannerViewCount', totalViewCount.toString());
  }, []);

  const dismissBanner = () => {
    setHideBanner(true);
    localStorage.setItem('hideConnectedCompanyBanner', 'true');
  };

  return { showBanner, dismissBanner };
};
