import { useCallback, useEffect, useState } from 'react';
import { useField, useForm } from 'react-final-form';
import { Col, Row } from '@estimateone/frontend-components';
import { format } from 'date-fns';
import { DatePickerField } from '@shared/FinalForm/Field';
import { InviteToQuoteFormFieldName } from '../../enums';
import { InviteToQuoteFormValues } from '../../types';
import styles from './styles.scss';

export const DueDateField = () => {
  const {
    input: { value: packageValue },
  } = useField<InviteToQuoteFormValues[InviteToQuoteFormFieldName.Package]>(
    InviteToQuoteFormFieldName.Package,
  );

  const { change: setFieldValue, getState } = useForm();
  const { values } = getState();
  const [isFirstLoad, setFirstLoad] = useState(true);

  const [showQuotesDueInfo, setShowQuotesDueInfo] = useState(false);

  useEffect(() => {
    const quotesDueDate = values[InviteToQuoteFormFieldName.DueDate];
    if (!isFirstLoad || !quotesDueDate) {
      const value = packageValue.quotesDueAt ? new Date(packageValue.quotesDueAt) : null;
      setFieldValue(InviteToQuoteFormFieldName.DueDate, value);
    }
    setFirstLoad(false);
  }, [packageValue]);

  useField<InviteToQuoteFormValues[InviteToQuoteFormFieldName.DueDate]>(
    InviteToQuoteFormFieldName.DueDate,
  );

  const validateQuotesDueByDate = useCallback(
    (quotesDueByDate: Date): string[] => {
      if (!quotesDueByDate) {
        return ['Select a due date for this package'];
      }

      const letByDate = packageValue.lettingScheduleDetails?.letByDate
        ? new Date(packageValue.lettingScheduleDetails?.letByDate)
        : null;

      if (letByDate && quotesDueByDate.getTime() > letByDate.getTime()) {
        return [
          `Enter a date before or equal to the Let By date (${format(letByDate, 'd MMM yyyy')})`,
        ];
      }
      return [];
    },
    [packageValue],
  );

  return (
    <Row>
      <Col span={4} excludeBottomGutter>
        <DatePickerField
          fieldName={InviteToQuoteFormFieldName.DueDate}
          id="invite-to-quote--due-date"
          label="Package Quotes Due"
          containerClassName={styles.formField}
          disabled={!packageValue}
          placeholderText={packageValue ? 'Select a date' : 'Select a package first'}
          isRequired
          onCalendarOpen={() => {
            setShowQuotesDueInfo(true);
          }}
          onCalendarClose={() => {
            setShowQuotesDueInfo(false);
          }}
          validate={validateQuotesDueByDate}
        />
      </Col>
      {showQuotesDueInfo ? (
        <Col span={8} excludeBottomGutter alignContentY="top">
          <div className={styles.dateFieldText} id="invite-to-quote--due-date-info">
            The <span className={styles.dateFieldTextBold}>Quotes Due</span> date in the Schedule
            will be updated with any new date entered here. Previously invited subcontractors
            <span className={styles.dateFieldTextBold}> won’t</span> be automatically notified.
          </div>
        </Col>
      ) : (
        <div />
      )}
    </Row>
  );
};
