import { SearchAreaOptions, SearchServiceArea } from '../../atoms';
import {
  ContractSizeMaxEnum,
  ContractSizeMinEnum,
  WorkforceSizeEnum,
} from '@ascension/_gqltypes/builder.generated';
import { ValueLabelOption } from '@estimateone/frontend-components/src/components/Form/Select/types';

export type LocationOption = {
  lat: number;
  lon: number;
  locationLabel: string;
  serviceArea?: SearchServiceArea;
};

export const SubbieNetworkFilterFormId = 'SubbieNetworkFilterForm';

export const DISTANCE_OPTIONS = [10, 25, 50, 100, 150, 200, 250, 300] as const;

export type DistanceValueOption = ValueLabelOption<number>;

export enum SubbieNetworkFilterFormFields {
  TradeFilterId = 'tradeFilter',
  SearchArea = 'searchArea',
  WorkforceFilterId = 'workforceFilter',
  Distance = 'distance',
  Location = 'location',
  CategoryFilterId = 'categoryFilter',
  CompanyListFilterId = 'companyListFilter',
  RelationshipTypeAddressBook = 'RelationshipTypeAddressBook',
  RelationshipTypeNotInAddressBook = 'RelationshipTypeNotInAddressBook',
  ContractSizeMin = 'contractSizeMin',
  ContractSizeMax = 'contractSizeMax',
}

export type ContractSizeValueOption<T> = { monetaryValue: number } & ValueLabelOption<T>;

export type SubbieNetworkFilterFormValues = {
  [SubbieNetworkFilterFormFields.TradeFilterId]?: ValueLabelOption<number>;
  [SubbieNetworkFilterFormFields.WorkforceFilterId]?: ValueLabelOption<WorkforceSizeEnum>;
  [SubbieNetworkFilterFormFields.Distance]?: DistanceValueOption;
  [SubbieNetworkFilterFormFields.Location]?: LocationOption;
  [SubbieNetworkFilterFormFields.CategoryFilterId]?: ValueLabelOption<number>;
  [SubbieNetworkFilterFormFields.CompanyListFilterId]?: ValueLabelOption<number>;
  [SubbieNetworkFilterFormFields.RelationshipTypeAddressBook]?: boolean;
  [SubbieNetworkFilterFormFields.RelationshipTypeNotInAddressBook]?: boolean;
  [SubbieNetworkFilterFormFields.ContractSizeMin]?: ContractSizeValueOption<ContractSizeMinEnum>;
  [SubbieNetworkFilterFormFields.ContractSizeMax]?: ContractSizeValueOption<ContractSizeMaxEnum>;
  [SubbieNetworkFilterFormFields.SearchArea]?: SearchAreaOptions;
};

export const allSubbieNetworkFilterFormFields = [
  SubbieNetworkFilterFormFields.TradeFilterId,
  SubbieNetworkFilterFormFields.WorkforceFilterId,
  SubbieNetworkFilterFormFields.Distance,
  SubbieNetworkFilterFormFields.Location,
  SubbieNetworkFilterFormFields.CategoryFilterId,
  SubbieNetworkFilterFormFields.CompanyListFilterId,
  SubbieNetworkFilterFormFields.RelationshipTypeAddressBook,
  SubbieNetworkFilterFormFields.RelationshipTypeNotInAddressBook,
  SubbieNetworkFilterFormFields.ContractSizeMin,
  SubbieNetworkFilterFormFields.ContractSizeMax,
  SubbieNetworkFilterFormFields.SearchArea,
] satisfies SubbieNetworkFilterFormFields[];

export type SubbieNetworkFilterFormErrors = {
  [Property in SubbieNetworkFilterFormFields]?: string[];
};
