import { Form } from 'react-final-form';
import { useQuery } from '@apollo/client';
import { Button, ButtonVariant, useFullscreen } from '@estimateone/frontend-components';
import { DatePickerField, TextInputField } from '../../../shared/FinalForm/Field';
import { SingleSelectField } from '../../../shared/FinalForm/Field/SingleSelectField';
import LoadingSpinner from '../../../shared/LoadingSpinner';
import { LabelledOption } from '../../../shared/Select';
import { SubmissionResponse, useCreatePackageWithLettingScheduleDetails } from './hooks';
import { GET_DISCIPLINES } from './queries';
import { EntityId } from '../../../../types';
import { GetDisciplines, GetDisciplinesVariables } from './types/GetDisciplines';
import styles from './styles.scss';

export enum CreateNewPackageFormFieldName {
  PackageName = 'title',
  Discipline = 'tradeDisciplineId',
  StartOnSiteDate = 'startOnSiteDate',
  OtherErrors = 'otherErrors',
}

export type CreateNewPackageFormValues = {
  [CreateNewPackageFormFieldName.PackageName]: string;
  [CreateNewPackageFormFieldName.Discipline]: LabelledOption<number>;
  [CreateNewPackageFormFieldName.StartOnSiteDate]: Date;
};

type FormErrors = Record<string, string[]>;

type CreateNewPackageProps = {
  stageId: EntityId;
  onSuccess: (packageWithDetails: SubmissionResponse['package']) => void;
  onCancel: () => void;
};

const isSuccessfulSubmission = (response: SubmissionResponse) => response.package !== undefined;

const transformDate = (date?: Date) =>
  date
    ? new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())).toISOString()
    : null;

const voidEntry: { value: number | null; label: string } = { value: null, label: '-' };

export const CreateNewPackageForm = ({ stageId, onSuccess, onCancel }: CreateNewPackageProps) => {
  const { data, loading } = useQuery<GetDisciplines, GetDisciplinesVariables>(GET_DISCIPLINES, {
    variables: { stageId },
  });

  const { submit } = useCreatePackageWithLettingScheduleDetails();
  const fullscreen = useFullscreen();

  const handleFormSubmit = async (
    values: CreateNewPackageFormValues,
  ): Promise<FormErrors | undefined> => {
    const response = await submit(
      stageId,
      values[CreateNewPackageFormFieldName.Discipline]?.value ?? null,
      values[CreateNewPackageFormFieldName.PackageName],
      transformDate(values[CreateNewPackageFormFieldName.StartOnSiteDate]),
    );

    if (isSuccessfulSubmission(response)) {
      onSuccess(response.package);
      return undefined;
    }

    return response.errors.reduce((previousValue, currentValue) => {
      const newValue = { ...previousValue };
      if (currentValue.field) {
        if (newValue[currentValue.field] === undefined) {
          // eslint-disable-next-line fp/no-mutation
          newValue[currentValue.field] = [];
        }
        newValue[currentValue.field].push(currentValue.message);
      }
      return newValue;
    }, {} as FormErrors);
  };

  if (loading) return <LoadingSpinner />;

  if (!data) {
    throw new Error('No data for disciplines has been returned.');
  }

  return (
    <Form<CreateNewPackageFormValues> onSubmit={handleFormSubmit}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <TextInputField
            id="package-name-input"
            label="Package Name"
            isRequired
            fieldName={CreateNewPackageFormFieldName.PackageName}
            validate={(name) =>
              (name?.trim().length ?? 0) === 0 ? ['Choose a name for the new package'] : undefined
            }
          />
          <SingleSelectField
            aria-label="Discipline"
            className={styles.discipline}
            id="discipline-select"
            label="Discipline"
            fieldName={CreateNewPackageFormFieldName.Discipline}
            placeholder="Choose a Discipline"
            options={[voidEntry].concat(
              data.disciplines.map((discipline) => ({
                value: discipline.id,
                label: discipline.name,
              })),
            )}
            menuPortalTarget={
              fullscreen.container instanceof HTMLElement ? fullscreen.container : document.body
            }
          />
          <div className={styles.dates}>
            <div>
              <DatePickerField
                className={styles.date}
                id="start-on-site-datepicker"
                label="Start on Site Date"
                fieldName={CreateNewPackageFormFieldName.StartOnSiteDate}
              />
            </div>
          </div>
          <div className={styles.buttons}>
            <Button
              variant={ButtonVariant.Secondary}
              type="button"
              fullWidth
              onClick={() => onCancel()}
            >
              Cancel
            </Button>
            <Button type="submit" fullWidth>
              Confirm
            </Button>
          </div>
        </form>
      )}
    </Form>
  );
};
