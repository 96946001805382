import { useCallback, useState } from 'react';
import Routing from 'routing';
import {
  ActionMenu,
  Button,
  ButtonSize,
  ButtonVariant,
  Icon,
  IconName,
} from '@estimateone/frontend-components';
import E1Request from '@ascension/js/classes/E1Request';
import { InviteToQuoteSlideout } from '../InviteToQuoteSlideout';
import { useGetAddressBookCompany } from '../InviteToQuoteSlideout/InviteToQuoteForm/hooks/useGetAddressBookCompany';
import { GetAddressBookCompany_company as CompanySelectOption } from '../InviteToQuoteSlideout/InviteToQuoteForm/types/GetAddressBookCompany';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

export type InviteToQuoteAddressBookMenuButtonProps = {
  preselectedCompanyId: EntityId;
  size?: ButtonSize;
};

export const InviteToQuoteAddressBookMenuButton = ({
  preselectedCompanyId,
  size,
}: InviteToQuoteAddressBookMenuButtonProps) => {
  const [isSlideoutOpen, setIsSlideoutOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [preselectedCompany, setPreselectedCompany] = useState<CompanySelectOption>();
  const { getAddressBookCompany } = useGetAddressBookCompany();

  const fetchPreselectedCompany = useCallback(async () => {
    const response = await getAddressBookCompany({
      variables: { companyId: preselectedCompanyId },
    });
    setPreselectedCompany(response.data?.company);
  }, [getAddressBookCompany, preselectedCompanyId]);

  const triggerElement = (
    <Button
      onClick={(e) => {
        e.stopPropagation();
        setIsMenuOpen(!isMenuOpen);
      }}
      variant={ButtonVariant.Secondary}
      size={size}
    >
      Invite to Quote
      <Icon name={IconName.ChevronDown} marginLeft="small" size="0.6em" />
    </Button>
  );

  const redirectToTenderInviteSinglePage = () => {
    const queryParams = { company: preselectedCompanyId };

    const request = Routing.generate('app_addressbook_invitesinglemodal', {
      ...queryParams,
    });

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    new E1Request(request).setShowLoadingModal().submit();
  };

  return (
    <>
      <ActionMenu
        triggerElement={triggerElement}
        onClickOutside={() => setIsMenuOpen(false)}
        visible={isMenuOpen}
        placement="bottom-end"
      >
        <>
          <ActionMenu.Item
            className={styles.menuItem}
            onClick={() => {
              setIsMenuOpen(false);
              redirectToTenderInviteSinglePage();
            }}
          >
            Tender (pre-award) project
          </ActionMenu.Item>
          <ActionMenu.Item
            className={styles.menuItem}
            onClick={async (e) => {
              e.stopPropagation();
              await fetchPreselectedCompany();
              setIsSlideoutOpen(true);
              setIsMenuOpen(false);
            }}
          >
            Procurement (post-award) project
          </ActionMenu.Item>
        </>
      </ActionMenu>
      {preselectedCompany ? (
        <InviteToQuoteSlideout
          stageId={undefined}
          isOpen={isSlideoutOpen}
          onRequestClose={() => {
            setIsSlideoutOpen(false);
          }}
          preselectedPackageId={undefined}
          preselectedCompany={preselectedCompany}
        />
      ) : null}
    </>
  );
};
