import { useField } from 'react-final-form';
import { Col, Row, FieldWrapper, Fieldset } from '@estimateone/frontend-components';
import { DatePickerField } from '../../../../../../shared/FinalForm/Field';
import { UpdateAwardedDateOptions, UpdateStageStatusFieldName } from '../../../enums';
import styles from './styles.scss';

export const AwardedDateOptions = () => {
  const {
    meta: { error: awardedAtError, submitError: awardedAtSubmitError },
  } = useField(UpdateStageStatusFieldName.AwardedAt);
  const {
    meta: { error: projectCompletionError, submitError: projectCompletionSubmitError },
  } = useField(UpdateStageStatusFieldName.ProjectCompletion);

  const errors = [
    ...(awardedAtError || awardedAtSubmitError || []),
    ...(projectCompletionError || projectCompletionSubmitError || []),
  ];

  return (
    <div className={styles.awardedDateOptions}>
      <FieldWrapper errors={errors}>
        <Fieldset legend="Project dates" hideLegend>
          <Row>
            {Object.keys(UpdateAwardedDateOptions).map(
              (fieldName: keyof typeof UpdateAwardedDateOptions) => (
                <Col key={fieldName} span={4} excludeBottomGutter>
                  <DatePickerField
                    id={`markAwardedDateField${fieldName}`}
                    fieldName={fieldName}
                    label={UpdateAwardedDateOptions[fieldName]}
                    hideErrorMessages
                    isRequired={fieldName === UpdateStageStatusFieldName.AwardedAt}
                    maxDate={
                      fieldName === UpdateStageStatusFieldName.AwardedAt ? new Date() : undefined
                    }
                  />
                </Col>
              ),
            )}
          </Row>
        </Fieldset>
      </FieldWrapper>
    </div>
  );
};
