import { useRef, useEffect } from 'react';
import * as D3 from 'd3';
import { Line, ScaleBand, ScaleLinear, Selection } from 'd3';
import {
  DEFAULT_STROKE_WIDTH,
  LEFT_Y_AXIS_LINE_COLOUR,
  LEFT_Y_AXIS_NAME,
  TOOLTIP_STROKE_WIDTH,
  X_AXIS_LINE_COLOUR,
  Y_AXIS_TICK_COUNT,
} from './constants';
import { formatMonthYearForDisplay, mapMonthNumberToText } from './utils';
import { SingleYAxisDataPoint, ChartDimensions, AddToolTipProps } from './types';
import styles from './styles.module.scss';

type SingleYAxisLineChartProps = {
  className?: string;
  chartId: string;
  dataPoints: SingleYAxisDataPoint[][];
  dimensions: ChartDimensions;
  addToolTip?: ({ chart }: AddToolTipProps) => void;
};

const getOrdinalXAxis = (rangeStart: number, rangeEnd: number, xAxisValues: string[]) =>
  D3.scaleBand<number | string | Date>().range([rangeStart, rangeEnd]).domain(xAxisValues);

const getMaxRoundedDomainValue = (dataMap: SingleYAxisDataPoint[]) => {
  const maxDomainValue = D3.max(dataMap, (data: SingleYAxisDataPoint) =>
    Math.max(data[LEFT_Y_AXIS_NAME]),
  );
  if (!maxDomainValue) {
    return 0;
  }
  // Increase the number till it becomes divisible by 4 so that 5 scale-points
  // (0 being the first and rest 4 being divisbles of 4) on y-axis can be shown
  return Math.ceil(maxDomainValue / 4) * 4;
};

const plotLine = (
  xAxis: ScaleBand<string | number | Date>,
  yAxis: ScaleLinear<number, number>,
): Line<SingleYAxisDataPoint> => {
  const offsetToCenterXAxis = xAxis.bandwidth() / 2;

  return D3.line<SingleYAxisDataPoint>()
    .x((dataPoint) => offsetToCenterXAxis + Number(xAxis(formatMonthYearForDisplay(dataPoint))))
    .y((dataPoint) => yAxis(dataPoint[LEFT_Y_AXIS_NAME]));
};

const drawMarker = (
  chartId: string,
  chart: Selection<SVGGElement, unknown, null, undefined>,
  data: SingleYAxisDataPoint[],
  xAxis: ScaleBand<string | number | Date>,
  yAxis: ScaleLinear<number, number>,
  colour: string,
) => {
  const offsetToCenterXAxis = xAxis.bandwidth() / 2;
  chart
    .selectAll('.dot')
    .data(data)
    .enter()
    .append('circle')
    .attr(
      'class',
      (dataPoint: SingleYAxisDataPoint) =>
        `${chartId} marker ${LEFT_Y_AXIS_NAME} ${mapMonthNumberToText(
          dataPoint.month,
        )}${dataPoint.year}`,
    )
    .attr(
      'cx',
      (dataPoint: SingleYAxisDataPoint) =>
        offsetToCenterXAxis + Number(xAxis(formatMonthYearForDisplay(dataPoint))),
    )
    .attr('cy', (dataPoint: SingleYAxisDataPoint) => yAxis(dataPoint[LEFT_Y_AXIS_NAME]))
    .attr('fill', colour)
    .attr('r', '8');
};

const getChart = (selector: SVGSVGElement, { width, height, margin }: ChartDimensions) => {
  D3.select(selector).selectAll('svg').remove();

  return D3.select(selector)
    .append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', `translate(${margin.left}, ${margin.top})`);
};

const addLine = (
  chart: Selection<SVGGElement, unknown, null, undefined>,
  data: SingleYAxisDataPoint[],
  colour: string,
  plottingFunction: Line<SingleYAxisDataPoint>,
  showDottedLine = false,
) => {
  const strokeWidth = colour == null ? TOOLTIP_STROKE_WIDTH : DEFAULT_STROKE_WIDTH;
  const line = chart.append('path');
  line
    .datum(data)
    .attr('fill', 'none')
    .attr('stroke', colour)
    .attr('stroke-width', strokeWidth)
    .attr('d', plottingFunction);
  if (showDottedLine) {
    line.style('stroke-dasharray', '7, 7');
    line.style('stroke-linecap', 'round');
  }
};

const renderChart = (currentChartSelector: SVGSVGElement, props: SingleYAxisLineChartProps) => {
  const yAxisRightLabelOffset = 0;

  const { chartId, dataPoints, dimensions, addToolTip } = props;

  const { width, height } = dimensions;

  const xAxisValues = dataPoints[0].map((data) => formatMonthYearForDisplay(data));
  const xAxis = getOrdinalXAxis(0, width - yAxisRightLabelOffset, xAxisValues);

  const yLeftMaxDomainValue = getMaxRoundedDomainValue(dataPoints.flat());

  const yLeftAxis = D3.scaleLinear().range([height, 0]).domain([0, yLeftMaxDomainValue]);

  const chart = getChart(currentChartSelector, dimensions);

  // Adding X axis
  chart
    .append('g')
    .attr('transform', `translate(0, ${height})`)
    .call(D3.axisBottom(xAxis).tickSize(0).tickPadding(15))
    .call((g) => g.select('.domain').attr('stroke', X_AXIS_LINE_COLOUR))
    .call((g) => g.select('.domain').remove());

  // Adding Y left axis
  const yLeftAxisFragment = yLeftMaxDomainValue / 4;
  const yLeftTicks = Array.from({ length: Y_AXIS_TICK_COUNT }, (v, i) => i).map((n) =>
    Number((n * yLeftAxisFragment).toFixed(2)),
  );

  chart
    .append('g')
    .call(
      D3.axisLeft(yLeftAxis)
        .tickValues(yLeftTicks)
        .tickSize(0)
        .tickFormat((d) => d.toLocaleString()),
    )
    .call((g) => g.selectAll('.tick text').classed(styles.tickText, true))
    .call((g) => g.select('.domain').remove())
    .call((g) =>
      g
        .selectAll('.tick line')
        .clone()
        .attr('x2', width - yAxisRightLabelOffset)
        .attr('stroke-opacity', 0.1),
    );

  const plotLeftLine = plotLine(xAxis, yLeftAxis);

  const yAxisMarkers: {
    yAxis: D3.ScaleLinear<number, number>;
    yAxisColour: string[];
  }[] = [
    {
      yAxis: yLeftAxis,
      yAxisColour: LEFT_Y_AXIS_LINE_COLOUR,
    },
  ];

  yAxisMarkers.forEach(({ yAxis, yAxisColour }) => {
    dataPoints.forEach((data, index) => {
      const solidLineData = data.filter(({ isCurrentMonth }) => !isCurrentMonth);
      const currentQuarterIndex = data.findIndex(({ isCurrentMonth }) => isCurrentMonth);
      const dottedLineData = data.slice(currentQuarterIndex - 1);

      drawMarker(chartId, chart, data, xAxis, yAxis, yAxisColour[index]);
      addLine(chart, solidLineData, LEFT_Y_AXIS_LINE_COLOUR[index], plotLeftLine);
      addLine(chart, dottedLineData, LEFT_Y_AXIS_LINE_COLOUR[index], plotLeftLine, true);
    });
  });

  if (addToolTip) {
    const chartLinesAndMarkers = chart.selectAll<
      SVGElement | SVGCircleElement,
      SingleYAxisDataPoint[]
    >('circle,path');
    addToolTip({ chart: chartLinesAndMarkers });
  }
};

export const SingleYAxisLineChart = ({
  className,
  chartId,
  dataPoints,
  addToolTip,
  dimensions,
}: SingleYAxisLineChartProps) => {
  const chartSelector = useRef<SVGSVGElement | null>(null);
  const { width, height, margin } = dimensions;

  useEffect(() => {
    if (chartSelector.current) {
      renderChart(chartSelector.current, {
        chartId,
        dataPoints,
        addToolTip,
        dimensions,
      });
    }
  }, [chartSelector, addToolTip, dimensions, dataPoints, chartId]);

  const svgWidth = width + margin.left + margin.right;
  const svgHeight = height + margin.top + margin.bottom;

  return (
    <>
      <div id={`${chartId}_tooltip`} className={className} />
      <svg
        id={chartId}
        className={className}
        ref={chartSelector}
        width={svgWidth}
        height={svgHeight}
      />
    </>
  );
};
