import { HelpIcon } from '@estimateone/frontend-components';
import { SingleSelectField } from '@shared/FinalForm/Field/SingleSelectField';
import { HelpSliderTrigger } from '@shared/HelpSliderTrigger';
import { InviteToQuoteFormFieldName } from '../../enums';
import { RfqCorrespondenceType } from '@ascension/_gqltypes/builder.generated';
import { ValueLabelOption } from '@estimateone/frontend-components/src/components/Form/Select/types';
import styles from './styles.scss';

const correspondenceTypeOptions: ValueLabelOption<RfqCorrespondenceType>[] = [
  { value: RfqCorrespondenceType.REQUEST_FOR_QUOTE, label: 'Request for Quote (RFQ)' },
  { value: RfqCorrespondenceType.CONFIRM_QUOTE, label: 'Confirm Quote' },
];

export const CorrespondenceTypeField = () => (
  <>
    <label
      htmlFor="invite-to-quote--correspondence-type"
      className={styles.correspondenceTypeLabel}
    >
      <div>Correspondence Type *</div>
      <HelpSliderTrigger template="correspondence-types">
        <HelpIcon />
      </HelpSliderTrigger>
    </label>
    <SingleSelectField
      id="invite-to-quote--correspondence-type"
      label="Correspondence Type"
      hideLabel
      defaultValue={correspondenceTypeOptions[0]}
      fieldName={InviteToQuoteFormFieldName.CorrespondenceType}
      isRequired
      options={correspondenceTypeOptions}
    />
  </>
);
