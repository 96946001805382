import Routing from 'routing';
import E1Request from '../../../../js/classes/E1Request';
import { LabelledOption } from '@shared';
import { EntityId } from '@ascension/types';

/* eslint-disable camelcase */
type TradeResult = {
  id: EntityId;
  name: string;
  discipline: string | null;
  discipline_index: number;
  e1_map: string | null;
};
/* eslint-enable */

type TransformedTradeResult = {
  id: EntityId;
  name: string;
  discipline: string | null;
  disciplineIndex: number;
  e1Map: string | null;
};

const cameliseTradeResult = ({
  id,
  name,
  discipline,
  discipline_index: disciplineIndex,
  e1_map: e1Map,
}: TradeResult): TransformedTradeResult => ({
  id,
  name,
  discipline,
  disciplineIndex,
  e1Map,
});

export const requestTrades = async (): Promise<TransformedTradeResult[]> =>
  (
    await new E1Request<{ success: true; data: TradeResult[] }>(
      Routing.generate('app_addressbooktrade_fetch'),
    ).submit()
  ).data.map(cameliseTradeResult);

type CompanyListResult = {
  id: EntityId;
  name: string;
  createdAt: {
    date: string;
  };
  companyCount: number;
};

export const requestLists = async () =>
  (
    await new E1Request<{ success: true; data: CompanyListResult[] }>(
      Routing.generate('app_addressbooktag_fetch'),
    ).submit()
  ).data;

export type CompanyResult = {
  id: EntityId;
  name: string;
  phone: string | null;
  address: string;
};

type CompanySearchFields = {
  emailAddress?: string;
  companyName?: string;
};

export const requestCompanySuggestions = async ({
  emailAddress,
  companyName,
}: CompanySearchFields) =>
  (
    await new E1Request<{ success: true; data: CompanyResult[] }, CompanySearchFields>(
      Routing.generate('api_address_book_company_search'),
      'GET',
      {
        emailAddress,
        companyName,
      },
    ).submit()
  ).data;

export type TradeOrListOption = LabelledOption<EntityId>;
type WritableTradeDisciplineGroup = {
  readonly label: string;
  options: TradeOrListOption[];
};

export const createOption = ({
  id,
  name,
}: TransformedTradeResult | CompanyListResult): TradeOrListOption => ({
  label: name,
  value: id,
});

const createGroup = (
  groupName: string,
  firstOption: TransformedTradeResult | CompanyListResult,
): WritableTradeDisciplineGroup => ({
  label: groupName,
  options: [createOption(firstOption)],
});

type IndexedGroup = { [discipline: string]: WritableTradeDisciplineGroup };

export const createTradeOptionsGroupedByDiscipline = (
  trades: TransformedTradeResult[],
): WritableTradeDisciplineGroup[] => {
  const tradeGroups = trades.reduce<IndexedGroup>((groups, trade) => {
    const { discipline } = trade;

    if (!discipline) {
      return groups;
    }

    if (discipline in groups) {
      const existingGroup = groups[discipline];
      return {
        ...groups,
        [discipline]: {
          ...existingGroup,
          options: existingGroup.options.concat([createOption(trade)]),
        },
      };
    }

    return { ...groups, [discipline]: createGroup(discipline, trade) };
  }, {});

  const sortedDisciplineNames = Object.keys(tradeGroups).sort();

  return sortedDisciplineNames.map((discipline) => tradeGroups[discipline]);
};
