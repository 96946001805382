import { useState } from 'react';
import { Form } from 'react-final-form';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  IntegrateIcon,
  Modal,
  ModalSize,
  RadioButton,
  Tooltip,
} from '@estimateone/frontend-components';
import { DocSyncPlugin } from '@builder/features/DocSyncIntegration/DocSyncPlugin/DocSyncPlugin';
import { procorePlugin } from '@builder/features/DocSyncIntegration/DocSyncPlugin/ProcorePlugin';
import {
  useAvailablePlugins,
  useHasDocumentIntegration,
  useHasExistingDocuments,
  useIsInSharepointExperiment,
} from '@builder/features/DocSyncIntegration/DocumentIntegrationProvider';
import { ProcoreConnect } from '../ProcoreConnect';
import { useHasUploadStarted } from '../ProcoreConnect/ProcoreSyncController/useUploader';
import { RadioButtonGroupField } from '@shared/FinalForm/Field/RadioButtonGroupField';
import styles from './styles.scss';

export const DocumentIntegrationConnectButton = () => {
  const availablePlugins = useAvailablePlugins();
  const hasDocumentIntegration = useHasDocumentIntegration();
  const hasExistingDocuments = useHasExistingDocuments();
  const isInSharepointExperiment = useIsInSharepointExperiment();
  const hasStartedUpload = useHasUploadStarted();
  const [isIntegrationSelectionModalOpen, setIsIntegrationSelectionModalOpen] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState<null | DocSyncPlugin['id']>(null);
  const [isPluginInformationModalOpen, setIsPluginInformationModalOpen] = useState(false);

  const isDisabled = hasDocumentIntegration || hasStartedUpload || hasExistingDocuments;

  if (availablePlugins.length === 0) {
    return false;
  }

  /**
   * This allows us to preserve the current behaviour for Procore connections
   * while we work on the Sharepoint integration
   */
  if (!isInSharepointExperiment) {
    const isProcoreAvailable = availablePlugins.some((plugin) => plugin.id === procorePlugin.id);

    return isProcoreAvailable ? <ProcoreConnect isDisabled={isDisabled} /> : false;
  }

  if (isDisabled) {
    return (
      <Tooltip
        tooltipId="docsyncConnectTooltip"
        text="You can't connect to an external document store if your project has existing documents."
      >
        {/* wrap button to ensure tooltip works when disabled */}
        <div>
          <Button variant={ButtonVariant.Grey} size={ButtonSize.Medium} disabled>
            <IntegrateIcon className={styles.icon} />
            <div>Setup Integration</div>
          </Button>
        </div>
      </Tooltip>
    );
  }

  const selectedPlugin = selectedIntegration
    ? availablePlugins.find((plugin) => plugin.id === selectedIntegration)
    : null;

  return (
    <>
      <Button
        variant={ButtonVariant.Secondary}
        size={ButtonSize.Medium}
        onClick={() => {
          if (availablePlugins.length === 1) {
            setSelectedIntegration(availablePlugins[0].id);
            setIsPluginInformationModalOpen(true);
            return;
          }

          setIsIntegrationSelectionModalOpen(true);
        }}
      >
        <IntegrateIcon className={styles.icon} />
        <div>Setup Integration</div>
      </Button>

      <Modal
        isOpen={isIntegrationSelectionModalOpen}
        onRequestClose={() => setIsIntegrationSelectionModalOpen(false)}
        size={ModalSize.Small}
      >
        <Form<{ integrationType: DocSyncPlugin['id'] }>
          onSubmit={(values) => {
            if (values.integrationType) {
              setIsIntegrationSelectionModalOpen(false);
              setSelectedIntegration(values.integrationType);
              setIsPluginInformationModalOpen(true);
            }
          }}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className={styles.modalForm}>
              <Modal.Section>
                <h1 className={styles.modalHeading}>Setup Integration</h1>
                <p>Easily sync your documents to E1 from your preferred platform.</p>
                <div className={styles.modalRadioContainer}>
                  <RadioButtonGroupField
                    fieldName="integrationType"
                    legend="Choose your document source"
                  >
                    {availablePlugins.map((plugin) => (
                      <RadioButton id={plugin.id} key={plugin.id} value={plugin.id}>
                        {plugin.displayName}
                      </RadioButton>
                    ))}
                  </RadioButtonGroupField>
                </div>

                <div className={styles.modalActions}>
                  <Button
                    size={ButtonSize.Medium}
                    variant={ButtonVariant.Grey}
                    onClick={() => setIsIntegrationSelectionModalOpen(false)}
                    fullWidth
                  >
                    Cancel
                  </Button>
                  <Button
                    size={ButtonSize.Medium}
                    variant={ButtonVariant.Primary}
                    fullWidth
                    type="submit"
                  >
                    Setup Integration
                  </Button>
                </div>
              </Modal.Section>
            </form>
          )}
        </Form>
      </Modal>

      {selectedPlugin ? (
        <selectedPlugin.ConnectionInformationModal
          isOpen={isPluginInformationModalOpen}
          onCancel={() => {
            setIsPluginInformationModalOpen(false);
          }}
        />
      ) : null}
    </>
  );
};
