import { CellProps } from '@estimateone/frontend-components';
import { LettingScheduleOptions } from '../LettingScheduleOptions';
import { EntityId } from '@ascension/types';
import { Package } from '@builder/features/ProcurementLettingSchedule/types';
import styles from '../styles.scss';

export const OptionsCell =
  (stageId: EntityId) =>
  ({ row: { original } }: CellProps<Package>) => (
    <div className={styles.cellAlign}>
      <LettingScheduleOptions originalPackage={original} stageId={stageId} />
    </div>
  );
