import { useCallback } from 'react';
import { Slider } from '@estimateone/frontend-components';
import { ConnectionPreferenceForm } from '@subbie/modal/ConnectionPreferenceSlider/ConnectionPreferenceForm';
import ConnectionPreferenceFooter from './ConnectionPreferenceFooter/ConnectionPreferenceFooter';
import { useConnectionPreferenceFormContext } from './ConnectionPreferenceFormContext';
import { ConnectionPreferenceSliderHeader } from './ConnectionPreferenceSliderHeader';
import { useModalContext } from '@subbie/context/ModalContext';
import { useRemoveConnectionPreference } from '@subbie/modal/ConnectionPreferenceSlider/ConnectionPreferenceForm/hooks';
import { ConnectionPreferenceFormPage } from '@subbie/modal/ConnectionPreferenceSlider/ConnectionPreferenceFormContext/types';

type ConnectionPreferenceSliderProps = {
  readonly isPaneOpen: boolean;
  readonly handleClose: () => void;
};

export const ConnectionPreferenceSlider = ({
  isPaneOpen,
  handleClose,
}: ConnectionPreferenceSliderProps) => {
  const { removeModal } = useModalContext();
  const removePreference = useRemoveConnectionPreference();

  const { hasExistingPreference, page } = useConnectionPreferenceFormContext();

  const isEditing = hasExistingPreference;

  const closeParentAndSelf = useCallback(() => {
    handleClose();
    removeModal();
  }, [handleClose, removeModal]);

  const onCompleteSubmission = useCallback(() => {
    handleClose();
  }, [handleClose]);

  const onRemovePreference = useCallback(async () => {
    const errors = await removePreference();

    if (errors) {
      return errors;
    }

    handleClose();
    return null;
  }, [removePreference, handleClose]);

  return (
    <Slider rootAppElement="#root" isOpen={isPaneOpen} onRequestClose={closeParentAndSelf}>
      <ConnectionPreferenceSliderHeader
        title={
          isEditing && page === ConnectionPreferenceFormPage.ConnectionPreference
            ? 'Edit contact details'
            : 'Promote products to subcontractors'
        }
        handleClose={handleClose}
      />
      <Slider.Body>
        <ConnectionPreferenceForm onFormComplete={onCompleteSubmission} />
      </Slider.Body>
      <Slider.Footer>
        <ConnectionPreferenceFooter
          onCancel={handleClose}
          onRemovePreference={onRemovePreference}
        />
      </Slider.Footer>
    </Slider>
  );
};
