import { E1Link, Icon, IconName } from '@estimateone/frontend-components';
import { showUniversalProfileDrawer } from '@builder/pages/Profiles/UniversalProfileDrawer';
import { Pricing, PricingVariant } from '../../../../../fec';
import { generateQuotesLink, generateViewQuoteLink, showCompanySlideout } from '../utils';
import { useIsUniversalDrawerEnabled } from '@builder/context/AccountProvider/hooks';
import { QuoteReturnRoute } from '@ascension/enums';
import {
  GetPackageWithRfqs_package_lettingScheduleDetails_awardedToRfq_contact as Contact,
  GetPackageWithRfqs_package_lettingScheduleDetails_awardedToRfq_latestQuote as Quote,
} from '../types/GetPackageWithRfqs';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

const Dot = () => <span role="presentation">&nbsp;&middot;&nbsp;</span>;

const hasFullAddress = (address: string | undefined) => {
  if (!address) {
    return false;
  }

  return address.trim().length > 0;
};

export type AwardedSubbieInfoProps = {
  stageId: EntityId;
  rfqId: EntityId;
  contact: Contact | null;
  latestQuote: Quote | null;
};

export const AwardedSubbieInfo = ({
  stageId,
  rfqId,
  contact,
  latestQuote,
}: AwardedSubbieInfoProps) => {
  const isUniversalDrawerEnabled = useIsUniversalDrawerEnabled();

  const showCompanySlideoutOrUniversalDrawer = (companyId: number) =>
    isUniversalDrawerEnabled
      ? showUniversalProfileDrawer({ companyId })
      : showCompanySlideout(companyId, rfqId);

  return (
    <>
      <h3 className={styles.awardedToTitle}>
        <Icon className={styles.awardedIcon} name={IconName.Awarded} size={20} />
        <E1Link
          className={styles.awardedToTitleText}
          onClick={() =>
            contact?.company?.id
              ? showCompanySlideoutOrUniversalDrawer(contact.company.id)
              : undefined
          }
        >
          {contact?.company?.name}
        </E1Link>
        <Dot />
        {latestQuote?.amount !== undefined && latestQuote.amount !== null ? (
          <E1Link
            className={styles.awardedToTitleText}
            href={generateViewQuoteLink(latestQuote.id, QuoteReturnRoute.BuilderPackageDashboard)}
          >
            <Pricing price={latestQuote.amount} variant={PricingVariant.Default} />
          </E1Link>
        ) : (
          <E1Link className={styles.uploadQuoteLink} href={generateQuotesLink(stageId)}>
            Upload a quote
          </E1Link>
        )}
      </h3>
      {hasFullAddress(contact?.company?.address?.fullAddress ?? undefined) && (
        <address className={styles.awardedToCompanyAddress}>
          {contact?.company?.address?.fullAddress}
        </address>
      )}
      <p className={styles.awardedToContactDetails}>
        <span>{contact?.fullName}</span>

        {contact?.email && (
          <>
            <Dot />
            <span>{contact.email}</span>
          </>
        )}

        {(contact?.phone || contact?.company?.phone) && (
          <>
            <Dot />
            <span>{contact.phone ?? contact.company?.phone}</span>
          </>
        )}
      </p>
    </>
  );
};
