import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { ADDRESS_BOOK_CONTACTS_BY_COMPANY_AND_PACKAGE } from './queries';
import { EntityId } from '../../../../../../../../types';
import {
  GetContactsByCompanyAndPackage,
  GetContactsByCompanyAndPackageVariables,
} from './types/GetContactsByCompanyAndPackage';

export const useAddressBookCompanyContacts = (companyId: EntityId, packageId: EntityId) => {
  const [loadContacts, { data, loading }] = useLazyQuery<
    GetContactsByCompanyAndPackage,
    GetContactsByCompanyAndPackageVariables
  >(ADDRESS_BOOK_CONTACTS_BY_COMPANY_AND_PACKAGE, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (companyId && packageId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      loadContacts({ variables: { companyId, packageId } });
    }
  }, [companyId, packageId, loadContacts]);

  return {
    contacts: data?.contactsToInvite,
    loading,
  };
};
