import $ from 'jquery';
import Routing from 'routing';
import * as D3 from 'd3';
import E1Request from '../../../classes/E1Request';

type StatDatum = {
  month: number;
  year: number;
  tenderCount: number;
};

export type StatsResults = StatDatum[];

const fetchStatDataForRegion = (regionId: number): Promise<StatsResults> => {
  const fetchStatsUrl = Routing.generate('app_stat_stat', {
    id: regionId,
  });

  return new E1Request<{ success: true; results: StatsResults }>(fetchStatsUrl)
    .submit()
    .then(({ results }) => results);
};

const getMonthAndYearForStatDatum = ({ month, year }: StatDatum) =>
  `${month.toString().padStart(2, '0')}/${year}`;

export default class StatChart {
  private $target: JQuery;
  private data: StatsResults;
  private $container: JQuery;
  private readonly barColour: string;
  private readonly updateChart: (data: StatsResults) => void;
  private initialLoadComplete = false;

  constructor(
    private readonly targetId: string,
    width: number,
    regionId: number,
    barColour: string,
  ) {
    this.$target = $(`#${this.targetId}`);
    this.barColour = barColour;
    this.data = [];
    this.$container = this.$target.closest('.loading-container');
    this.updateChart = this.draw(width);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.update(regionId);
  }

  public async update(regionId: number): Promise<void> {
    this.data = await fetchStatDataForRegion(regionId);
    this.updateChart(this.data);
    this.initialLoadComplete = true;
    this.$container.addClass('has-loaded');
  }

  private getAnimationDuration() {
    return this.initialLoadComplete ? 500 : 0;
  }

  private draw(containerWidth: number) {
    const containerHeight = containerWidth * 0.4;

    const selector = `#${this.targetId}`;
    const margin = { top: 30, right: 30, bottom: 70, left: 60 };
    const width = containerWidth - margin.left - margin.right;
    const height = containerHeight - margin.top - margin.bottom;

    const svg = D3.select(selector)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    // X-axis
    const months = D3.scaleBand().range([0, width]).padding(0.2);
    const monthsAxis = svg.append('g').attr('transform', `translate(0, ${height})`);

    // Y-axis
    const count = D3.scaleLinear().range([height, 0]);
    const countAxis = svg.append('g').attr('class', 'countAxis');

    return (data: StatsResults) => {
      months.domain(data.map(getMonthAndYearForStatDatum));
      monthsAxis.call(D3.axisBottom(months));

      count.domain([0, 1.1 * data.reduce((max, { tenderCount }) => Math.max(max, tenderCount), 0)]);
      countAxis.transition().duration(this.getAnimationDuration()).call(D3.axisLeft(count));

      const bars = svg.selectAll('rect').data(data);

      bars
        .join('rect')
        .transition()
        .duration(this.getAnimationDuration())
        .attr('x', (d) => months(getMonthAndYearForStatDatum(d)) || 0)
        .attr('y', ({ tenderCount }) => count(tenderCount))
        .attr('width', months.bandwidth())
        .attr('height', ({ tenderCount }) => height - count(tenderCount))
        .attr('fill', this.barColour);

      // Add values to top of all the bars
      // I think this is a bit more intuitive than making the user mouse over the bars
      // as in the highcharts implementation
      svg.selectAll('text.bar').data(data).remove();

      svg
        .selectAll('text.bar')
        .data(data)
        .enter()
        .append('text')
        .attr('class', 'bar')
        .attr('text-anchor', 'middle')
        .attr('x', (d) => (months(getMonthAndYearForStatDatum(d)) || 0) + months.bandwidth() / 2)
        .attr('y', ({ tenderCount }) => count(tenderCount) - 12)
        .text(({ tenderCount }) => tenderCount)
        .style('opacity', 0)
        .transition()
        .style('opacity', 1)
        .delay(100)
        .duration(250);

      bars.exit().remove();
    };
  }
}
