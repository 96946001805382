import { Button, ButtonSize, ButtonVariant } from '@estimateone/frontend-components';
import { useViewDocs } from '../hooks';
import { EntityId } from '@ascension/types';

type ViewDocsButtonProps = {
  packageId: EntityId;
  stageId: EntityId;
};

const ViewDocsButton = ({ packageId, stageId }: ViewDocsButtonProps) => {
  const { loading, viewDocsRequest } = useViewDocs(stageId, packageId);

  return (
    <Button
      variant={ButtonVariant.Secondary}
      size={ButtonSize.Small}
      onClick={viewDocsRequest}
      disabled={loading}
    >
      View
    </Button>
  );
};

export default ViewDocsButton;
