import { useNavigate } from 'react-router-dom-v5-compat';
import { AlertIcon, E1Link, SkeletonLoader, TextLink } from '@estimateone/frontend-components';
import styles from './OfficeContactList.module.scss';
import { ActionableOfficeListItem } from './views';
import { OfficeServiceAreaTag } from '@shared/OfficeServiceAreaTag/OfficeServiceAreaTag';
import { Address, getAddressLines } from '@shared/Util/address';
import { useUserImperialSetting } from '@subbie/context/AccountProvider/hooks';
import { useOwnAccount } from '@profiles/features/CompanyProfile/hooks';
import { CompanyOffice } from '@profiles/providers/ProfileProvider/types';

export const OfficeContactListErrorView = () => (
  <div className={styles.officeSkeleton}>
    <p className={styles.officeListHeader}>Office</p>
    <AlertIcon title="office list error icon" />
  </div>
);

export const OfficeContactListLoader = () => (
  <div className={styles.officeSkeleton}>
    <SkeletonLoader width="50%" height="40px" />
    <SkeletonLoader height="16px" count={10} />
  </div>
);

export const OfficeAddressLines = ({ address }: { address: Address }) => {
  const addressLines = getAddressLines(address);

  return (
    <div>
      <p>{addressLines.firstLine}</p>
      <p>{addressLines.secondLine}</p>
    </div>
  );
};

function getAbsoluteURL(url: null): null;
function getAbsoluteURL(url: string): string;
function getAbsoluteURL(url: string | null) {
  if (!url) {
    return null;
  }
  if (url.startsWith('//') || url.startsWith('http')) {
    return url;
  }
  return `//${url}`;
}

type OfficeContactListProps = {
  offices: CompanyOffice[];
};

export const OfficeContactListNotOwnAccount = ({ offices }: OfficeContactListProps) => {
  const currentOffices = offices.filter(({ deletedAt }) => deletedAt === null);

  return (
    <div>
      <div className={styles.officeListHeadingWrapper}>
        <p className={styles.officeListHeader}>Office</p>
      </div>
      {currentOffices.map(({ id, name, contactable, address }) => (
        <div key={id} className={styles.officeCard}>
          <div className={styles.officeCardContainer}>
            <div className={styles.officeCardDetails}>
              <div className={styles.officeCardAddress}>
                <p className={styles.officeCardHeader}>{name}</p>
                <OfficeAddressLines address={address} />
              </div>
              <div className={styles.officeCardContact}>
                {contactable?.phone && <p>{contactable.phone}</p>}
                {contactable?.email && (
                  <p>
                    <TextLink href={`mailto:${contactable.email}`}>{contactable.email}</TextLink>
                  </p>
                )}
                {contactable?.web && (
                  <p>
                    <TextLink external href={getAbsoluteURL(contactable.web)}>
                      {contactable.web}
                    </TextLink>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const OfficeContactListOwnAccount = ({ offices }: OfficeContactListProps) => {
  const navigate = useNavigate();
  const defaultOfficeId = offices.find((office) => office.isDefaultOffice)?.ascensionId ?? -1;
  const imperialUnitSetting = useUserImperialSetting();

  if (imperialUnitSetting === undefined) {
    return null;
  }

  return (
    <div>
      <div className={styles.officeListHeadingWrapper}>
        <p className={styles.officeListHeader}>Office</p>
        <E1Link onClick={() => navigate('offices/add')} aria-label="Add office">
          Add
        </E1Link>
      </div>
      {offices.map(({ id, ascensionId, name, contactable, address, serviceArea }) => (
        <div key={id} className={styles.officeCard}>
          <div className={styles.officeCardContainer}>
            <div className={styles.officeCardDetails}>
              <div className={styles.officeCardAddress}>
                <p className={styles.officeCardHeader}>{name}</p>
                <OfficeAddressLines address={address} />
              </div>
              <div className={styles.officeCardContact}>
                {contactable?.phone && <p>{contactable.phone}</p>}
                {contactable?.email && (
                  <p>
                    <TextLink href={`mailto:${contactable.email}`}>{contactable.email}</TextLink>
                  </p>
                )}
                {contactable?.web && (
                  <p>
                    <TextLink external href={getAbsoluteURL(contactable.web)}>
                      {contactable.web}
                    </TextLink>
                  </p>
                )}
              </div>
            </div>

            <div>
              <ActionableOfficeListItem
                key={name}
                id={ascensionId ?? +id} // editing an office requires an account, meaning an ascensionId would exist
                name={name}
                defaultOfficeId={defaultOfficeId}
              />
            </div>
          </div>
          {serviceArea && (
            <div className={styles.officeCardServiceArea}>
              <p>Office Service Area:</p>
              <OfficeServiceAreaTag
                address={address}
                serviceArea={serviceArea}
                imperialUnitSetting={imperialUnitSetting}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export const OfficeContactList = ({ offices }: OfficeContactListProps) => {
  const { isOwnAccount, loading } = useOwnAccount();

  if (loading) {
    return null;
  }

  const currentOffices = offices.filter(({ deletedAt }) => deletedAt === null);

  if (!isOwnAccount) {
    return <OfficeContactListNotOwnAccount offices={currentOffices} />;
  }

  return <OfficeContactListOwnAccount offices={currentOffices} />;
};
