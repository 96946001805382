import { useEffect, useState, ReactElement } from 'react';
import { createBuilderClient } from '@api/E1ApolloProvider';
import { reportError } from '@ascension/components/helpers/errorReporter';
import { NetworkV2AscensionDataContext } from './context';
import { SUBBIE_NETWORK_FILTER_DATA } from './queries';
import { SubbieNetworkFilterDataQuery as STT } from './queries.generated';

export type DataContextProps = {
  stockTrades: STT['stockTrades'];
  trades: STT['trades'];
  stageCategories: STT['stageCategories'];
  geocoordinates: { lat: number; lon: number };
  companyTags: STT['companyTags'];
  currentUser: STT['currentUser'];
};

const { Provider } = NetworkV2AscensionDataContext;

const NetworkV2AscensionDataProvider = ({ children }: { children: ReactElement }) => {
  const [loaded, setLoaded] = useState(false);
  const [stockTrades, setStockTrades] = useState<STT['stockTrades']>([]);
  const [trades, setTrades] = useState<STT['trades']>([]);
  const [stageCategories, setStageCategories] = useState<STT['stageCategories']>([]);
  const [geocoordinates, setGeocoordinates] = useState<{ lat: number; lon: number }>({
    lat: 0.0,
    lon: 0.0,
  });
  const [companyTags, setCompanyTags] = useState<STT['companyTags']>([]);
  const [currentUser, setCurrentUser] = useState<STT['currentUser']>({
    id: 0,
    __typename: 'User',
    address: null,
    account: null,
    imperialUnitsSetting: false,
  });

  useEffect(() => {
    if (!loaded) {
      createBuilderClient()
        .query<STT>({
          query: SUBBIE_NETWORK_FILTER_DATA,
        })
        .then((res) => {
          setStockTrades(
            res.data.stockTrades.map((v) => v).sort((a, b) => a.name.localeCompare(b.name)),
          );
          setTrades(res.data.trades);
          setStageCategories(res.data.stageCategories);
          setGeocoordinates({
            lat: res.data.currentUser.address?.latitude ?? 0.0,
            lon: res.data.currentUser.address?.longitude ?? 0.0,
          });
          setCompanyTags(res.data.companyTags);
          setLoaded(true);
          setCurrentUser(res.data.currentUser);
        })
        .catch(reportError);
    }
  }, [loaded]);

  return (
    <Provider
      value={
        loaded
          ? {
              stockTrades,
              trades,
              stageCategories,
              geocoordinates,
              companyTags,
              currentUser,
            }
          : undefined
      }
    >
      {children}
    </Provider>
  );
};

export default NetworkV2AscensionDataProvider;
