import { PropsWithChildren } from 'react';
import { Card as FrontendCard, CardVariant } from '@estimateone/frontend-components';
import styles from './Card.module.scss';

export const Card = ({ children }: PropsWithChildren<unknown>) => (
  <FrontendCard className={styles.card} variant={CardVariant.Grey}>
    {children}
  </FrontendCard>
);

// repackage for in-house consumers
export const CardBody = FrontendCard.Body;
