import { useQuery } from '@apollo/client';
import { reportError } from '@ascension/components/helpers/errorReporter';
import { GET_SUBBIE_CONNECTION_CREDIT_BALANCE, HAS_SSC_ACCESS } from './queries';
import { HasSubbieSupplierConnectionAccessQuery } from './queries.generated';
import { SubbieConnectionCreditBalance } from './types/SubbieConnectionCreditBalance';

export type SubbieConnectionCreditBalanceDetails = Partial<SubbieConnectionCreditBalance>;

export const useHasSubbieSupplierConnectionAccess = (): boolean => {
  const { data, loading } = useQuery<HasSubbieSupplierConnectionAccessQuery>(HAS_SSC_ACCESS, {
    onError: reportError,
  });

  if (loading || data?.hasSubbieSupplierConnectionAccess === undefined) {
    return false;
  }

  return data.hasSubbieSupplierConnectionAccess;
};

export const useSubbieConnectionCreditBalance = (): SubbieConnectionCreditBalanceDetails => {
  const hasAccess = useHasSubbieSupplierConnectionAccess();

  const { loading, error, data } = useQuery<SubbieConnectionCreditBalance>(
    GET_SUBBIE_CONNECTION_CREDIT_BALANCE,
    {
      skip: !hasAccess,
    },
  );

  if (loading || error || !data || data?.subbieConnectionCreditBalance.allowed <= 0) {
    return {
      subbieConnectionCreditBalance: undefined,
    };
  }

  return {
    subbieConnectionCreditBalance: data?.subbieConnectionCreditBalance,
  };
};
