import { Tag, TagVariant, Tooltip } from '@estimateone/frontend-components';

export const SSCFreeTrialTag = () => (
  <Tooltip
    place="bottom"
    text="Until 17 June 2024 you have unlimited access to promote your products to subcontractors & 1 credit per month to unlock subcontractor leads."
    tooltipId="free-trial-tooltip"
  >
    <Tag variant={TagVariant.Electric050} text="Free Trial" />
  </Tooltip>
);
