import { useContext, useMemo, ReactNode, createContext } from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import { reportError } from '@ascension/components/helpers/errorReporter';
import { GET_COMPANY_PROFILE, GET_COMPANY_PROFILE_BY_ACCOUNT_ID } from './queries';
import {
  CompanyProfileByAccountIdQuery,
  CompanyProfileByAccountIdQueryVariables,
  CompanyProfileQuery,
  CompanyProfileQueryVariables,
} from './queries.generated';

type Profile = CompanyProfileQuery['companyProfile'];

export type ProfileContextProps = {
  loading: boolean;
  profile?: Profile;
  error?: ApolloError;
  refetch: () => void;
};

export const ProfileContext = createContext<ProfileContextProps>({
  loading: true,
  refetch: () => {},
});

type ProfileByAccountIdProviderProps = {
  accountId: number;
  children?: ReactNode;
};

const ProfileByAccountIdProvider = ({ accountId, children }: ProfileByAccountIdProviderProps) => {
  const { loading, data, error, refetch } = useQuery<
    CompanyProfileByAccountIdQuery,
    CompanyProfileByAccountIdQueryVariables
  >(GET_COMPANY_PROFILE_BY_ACCOUNT_ID, { variables: { id: `${accountId}` }, onError: reportError });

  const value = useMemo(
    () => ({
      loading,
      error,
      profile: data?.companyProfile,
      refetch,
    }),
    [loading, error, data],
  );

  return <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>;
};

type ProfileByProfileIdProviderProps = {
  companyProfileId: string;
  children?: ReactNode;
};

const ProfileByProfileIdProvider = ({
  companyProfileId,
  children,
}: ProfileByProfileIdProviderProps) => {
  const { loading, data, error, refetch } = useQuery<
    CompanyProfileQuery,
    CompanyProfileQueryVariables
  >(GET_COMPANY_PROFILE, {
    variables: { id: companyProfileId },
    onError: reportError,
  });

  const value = useMemo(
    () => ({
      loading,
      error,
      profile: data?.companyProfile,
      refetch,
    }),
    [loading, error, data],
  );

  return <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>;
};

type ProfileProviderProps = {
  companyProfileId?: string;
  accountId?: number;
  children?: ReactNode;
};

export const ProfileProvider = ({
  children,
  companyProfileId,
  accountId,
}: ProfileProviderProps) => {
  if (companyProfileId) {
    return (
      <ProfileByProfileIdProvider companyProfileId={companyProfileId}>
        {children}
      </ProfileByProfileIdProvider>
    );
  }

  if (accountId) {
    return (
      <ProfileByAccountIdProvider accountId={accountId}>{children}</ProfileByAccountIdProvider>
    );
  }

  throw new Error('nothing was provided!');
};

export const useProfileContext = () => {
  const ctx = useContext(ProfileContext);

  if (ctx === undefined) {
    throw new Error('useProfileContext and its hooks may only be used within an ProfileProvider');
  }

  return ctx;
};

export const useProfileAccountId = () => {
  const ctx = useProfileContext();

  const accountId =
    typeof ctx.profile?.account?.id === 'number'
      ? ctx.profile?.account?.id
      : typeof ctx.profile?.account?.id === 'string'
        ? Number(ctx.profile?.account?.id)
        : null;

  return {
    loading: ctx.loading,
    error: ctx.error,
    accountId,
  };
};

export const useProfileId = () => {
  const ctx = useProfileContext();

  return ctx.profile?.id;
};

export const useCompanyProfileCacheItemId = () => {
  const ctx = useProfileContext();

  return `Profile:${ctx.profile?.id}`;
};

export const useCompanyLogoURL = () => {
  const hash = useMemo(() => Date.now(), []);
  const ctx = useProfileContext();
  const logoUrl = ctx.profile?.logoUrl;

  return logoUrl ? `${logoUrl}?t=${hash}` : undefined;
};
