import { ReactNode, createContext, useMemo } from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import { UNIVERSAL_PROFILE_DRAWER_REFRESH_EVENT } from '../../events';
import { fireAndForget } from '@shared/Util/async';
import { useEventListener } from '../../hooks/useEventListener';
import { GET_UNIVERSAL_COMPANY_PROFILE } from './queries';
import {
  UniversalCompanyProfileQuery,
  UniversalCompanyProfileQueryVariables,
} from './queries.generated';
import { ListenerEvent } from '@ascension/enums';
import { UniversalProfileInput } from './types/global';

export type NetworkCompanyResponse = UniversalCompanyProfileQuery['networkCompany'];

export type E1NetworkCompany = Extract<NetworkCompanyResponse, { __typename?: 'E1NetworkCompany' }>;

export type ShadowNetworkCompany = Extract<
  NetworkCompanyResponse,
  { __typename?: 'ShadowNetworkCompany' }
>;

export type UniversalProfileResponse = UniversalCompanyProfileQuery['universalProfile'];

export type UniversalConnectedProfile = Extract<
  UniversalProfileResponse,
  { __typename?: 'ConnectedProfile' }
>;

export type UniversalSyncedProfiles = Extract<
  UniversalProfileResponse,
  { __typename?: 'SyncedProfiles' }
>;

export type UniversalNetworkProfile = Extract<
  UniversalProfileResponse,
  { __typename?: 'ProfileResponse' }
>;

type UniversalProfileProviderProps = {
  companyProfileId?: string;
  accountId?: number;
  addressBookCompanyId?: number;
  children?: ReactNode;
};

type UniversalProfileContextProps = {
  loading: boolean;
  universalProfile: UniversalProfileResponse | null;
  networkCompany: NetworkCompanyResponse | null;
  error: ApolloError | null;
  refetch: () => void;
};

export const UniversalProfileContext = createContext<UniversalProfileContextProps>({
  loading: true,
  universalProfile: null,
  networkCompany: null,
  error: null,
  refetch: () => {},
});

export const UniversalProfileProvider = ({
  children,
  companyProfileId,
  accountId,
  addressBookCompanyId,
}: UniversalProfileProviderProps) => {
  const input: UniversalProfileInput = {
    profileId: companyProfileId,
    accountId: accountId?.toString(),
    addressbookCompanyId: addressBookCompanyId?.toString(),
  };

  const { data, loading, error, refetch } = useQuery<
    UniversalCompanyProfileQuery,
    UniversalCompanyProfileQueryVariables
  >(GET_UNIVERSAL_COMPANY_PROFILE, {
    variables: { input },
    notifyOnNetworkStatusChange: true,
  });

  useEventListener(window, ListenerEvent.AddressBookCompanyUpdated, () => {
    fireAndForget(() => refetch({ input }));
  });

  useEventListener(document, UNIVERSAL_PROFILE_DRAWER_REFRESH_EVENT, () => {
    if (addressBookCompanyId) {
      fireAndForget(() => refetch({ input }));
    }
  });

  const value = useMemo(
    () => ({
      loading,
      universalProfile: data?.universalProfile ?? null,
      networkCompany: data?.networkCompany ?? null,
      error: error ?? null,
      refetch,
    }),
    [loading, data?.universalProfile, data?.networkCompany, error, refetch],
  );

  return (
    <UniversalProfileContext.Provider value={value}>{children}</UniversalProfileContext.Provider>
  );
};
