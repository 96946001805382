import $ from 'jquery';
import Routing from 'routing';
import moment from 'moment-timezone';
import _ from 'underscore';
import E1Request from '../classes/E1Request';
import Form from '../classes/Form';
import { truncate } from '../utils/truncate';
import { activateLoadingButton, deactivateLoadingButton } from './loading_button';

function StageFileList(stageId, $target, initFunc, fetchUrl) {
  this.stageId = stageId;
  this.$target = $target;
  this.$container = $target.closest('.stage-file-container');
  this.$emptyPlaceholder = this.$target
    .closest('.loading-container')
    .find('.empty-text-placeholder');
  this.$downloadAllBtn = this.$container.find('.btn-download-all');
  this.data = [];
  this.editable = $target.attr('data-editable');
  this.tableShowing = true;
  this.initFunc = initFunc;
  this.fetchUrl = fetchUrl;
  this.$controlPanel = this.$container.find('.table-control-panel-container');
  this.$folderUploadButton = $('a#path-file-uploader');

  const self = this;

  self.fetch(self.initFunc);
}

StageFileList.prototype.drawTable = function () {
  const self = this;
  self.toggleTableDisplay();

  if (self.table != null) {
    self.table.rows().remove();
    self.table.rows.add(self.data).draw();
  }

  self.updateSelectedTotals();
};

StageFileList.prototype.fetch = async function (callback) {
  const self = this;
  const { data } = await new E1Request(self.fetchUrl, 'GET').submit();

  self.data = data.map((file) => ({ DT_RowId: `dt_data_${file.id}`, ...file }));

  if (typeof callback === 'function') {
    callback(self);
  }
};

StageFileList.prototype.updateTable = function (fetch, cb) {
  const self = this;
  if (fetch) {
    self.fetch($.proxy(self.drawTable, self));
  } else {
    self.drawTable();
  }
  if (typeof cb !== 'undefined') {
    cb();
  }
};

StageFileList.prototype.getData = function () {
  return this.data;
};

StageFileList.prototype.toggleTableDisplay = function (immediate = true) {
  const self = this;

  if (!self.data.length) {
    if (self.$emptyPlaceholder.length && self.tableShowing) {
      if (immediate) {
        self.$target.hide();
        self.$downloadAllBtn.hide();
        self.$emptyPlaceholder.show();
      } else {
        self.$target.fadeOut(() => {
          self.$emptyPlaceholder.fadeIn();
        });
      }
      self.tableShowing = false;
    } else if (!self.$emptyPlaceholder.length && self.tableShowing) {
      self.$target.show();
      self.$downloadAllBtn.show();

      self.tableShowing = true;
    }
  } else if (self.$emptyPlaceholder.length && !self.tableShowing) {
    if (immediate) {
      self.$target.show();
      self.$downloadAllBtn.show();
      self.$emptyPlaceholder.hide();
    } else {
      self.$emptyPlaceholder.fadeOut(() => {
        self.$target.fadeIn();
      });
    }
    self.tableShowing = true;
  }
};

StageFileList.prototype.init = function (self) {
  const $body = $('body');

  if (self.editable) {
    if (self.$controlPanel.length > 0) {
      self.$controlPanel.prependTo('.bottom-bar');
      self.$controlPanel.removeClass('hide');
    }

    self.$target.on('change', '.select-checkbox', function () {
      const checked = $(this).is(':checked');
      const $row = $(this).closest('tr');
      const row = $row.get(0);
      const rowData = self.table.row(row).data();

      rowData.selected = checked;

      self.data = self.table.rows().data();

      self.$target.trigger('data-selected-updated');
      self.updateSelectedTotals();
    });
    self.$target.on('change', '.select-all-rows', function () {
      const checked = $(this).is(':checked');

      $.each(self.data, (i, row) => {
        row.selected = checked;
      });

      self.$target.trigger('data-selected-updated');
      self.updateTable();
      self.updateSelectedTotals();
    });

    self.$target.on('click', '.remove-file', async ({ currentTarget }) =>
      new E1Request(
        Routing.generate('app_stagefiles_delete_modal', {
          id: self.stageId,
          fileId: $(currentTarget).data('upload-id'),
        }),
      ).submit(),
    );

    self.$target.on('click', '.edit-file', async ({ currentTarget }) =>
      new E1Request(
        Routing.generate('app_stagefiles_edit_modal', {
          id: self.stageId,
          file: $(currentTarget).data('upload-id'),
        }),
      ).submit(),
    );

    self.$controlPanel.on(
      'click',
      '.remove-multiple-file',
      async () =>
        new E1Request(
          Routing.generate('app_stagefiles_delete_modal', {
            id: self.stageId,
            fileId: self.getSelectedIds(),
          })
            .setShowLoadingModal()
            .submit(),
        ),
    );

    self.$controlPanel.on('click', '.download-multiple-file', () => {
      window.location = Routing.generate('app_stagefiles_download', {
        id: self.stageId,
        fileId: self.getSelectedIds(),
      });
    });

    self.$controlPanel.on('click', '.move-docs-trigger', async ({ currentTarget }) => {
      const $button = $(currentTarget);
      activateLoadingButton($button);

      await new E1Request(
        Routing.generate('app_stagefiles_movemodal', {
          id: self.stageId,
          fileId: self.getSelectedIds(),
        }),
        'POST',
      )
        .setShowLoadingModal()
        .submit();

      deactivateLoadingButton($button);
    });

    $body.on(
      'submit',
      'form.edit-file-form, form.move-stage-file-form, form.rem-file-form',
      async (submitEvent) => {
        submitEvent.preventDefault();
        const $form = $(submitEvent.currentTarget);
        const magicForm = new Form($form);

        await magicForm.submit();
        self.updateTable(true);

        return false;
      },
    );
    self.updateSelectedTotals();
  }
};

StageFileList.prototype.getSelectedIds = function () {
  const self = this;
  const selected = _.filter(self.data, (file) => file.selected);
  return _.pluck(selected, 'id');
};

StageFileList.prototype.updateSelectedTotals = function () {
  const self = this;
  const selected = _.filter(self.data, (file) => file.selected);

  const selectedCount = selected.length;
  const totalCount = self.data.length;
  const allSelected = selectedCount >= totalCount;

  self.$target.find('.select-all-rows').prop('checked', allSelected);

  $('.select_count', self.$container).each(function () {
    const text = allSelected ? $(this).data('all-text') : selectedCount;

    $(this).text(text);
  });

  $('.select_count', self.$controlPanel).each(function () {
    self.$controlPanel.toggleClass('hide', selectedCount === 0);

    const text = allSelected ? $(this).data('all-text') : selectedCount;

    $(this).text(text);
  });
};

StageFileList.prototype.canUploadToFolder = function () {
  const self = this;
  return self.$folderUploadButton.length > 0 && self.$folderUploadButton.hasClass('activated');
};

export default function initStageDocTable(target) {
  const $target = $(target);
  const stageId = $target.attr('data-stage');
  const docTable = new StageFileList(
    stageId,
    $target,
    (list) => {
      list.table = list.$target.DataTable({
        paging: false,
        data: list.data,
        info: false,
        order: [[2, 'desc']],
        columns: [
          {
            data: 'selected',
            orderable: false,
            defaultContent: '',
            class: 'checkboxCol',
            render(data, type, row, meta) {
              const $checkbox = $("<input type='checkbox' class='select-checkbox'>").attr(
                'data-id',
                row.id,
              );

              if (row.selected) {
                $checkbox.attr('checked', 'checked');
              }

              if (!row.editable) {
                $checkbox.attr('disabled', 'disabled');
              }

              list.data[meta.row] = row;
              const $ctn = $('<div>').append($checkbox);
              return $ctn.prop('outerHTML');
            },
          },
          {
            data: null,
            render(data, type, row) {
              const $container = $('<div>');
              const $link = $('<a>').text(row.title);
              $link.attr('target', '_blank');
              $link.attr(
                'href',
                Routing.generate('app_stagefiles_stream', { id: stageId, fileId: row.id }),
              );
              $link.attr('data-upload-id', row.id);
              $link.addClass('upload-link').addClass('link');
              $container.append($link);

              if (row.private) {
                const $fileStatus = $('<span>').addClass('tag file-status');
                $fileStatus.addClass('private');
                $container.append($fileStatus);
              }

              return $container.prop('outerHTML');
            },
          },
          {
            title: 'Directory',
            data: ({ currentDirectoryName }) => currentDirectoryName || '',
            render: $.fn.dataTable.render.text(),
          },
          {
            data: 'created_by',
            render(data) {
              return data ? _.escape(truncate(data, 30)) : ' - ';
            },
          },
          {
            data: 'created_at',
            render(data) {
              if (data !== null) {
                const date = moment.unix(data);
                return date.format('DD/MM/YY h:mm:ss a');
              }
              return '-';
            },
          },
          {
            data: null,
            orderable: false,
            render(data, type, row) {
              const $container = $('<div>').addClass('btn-container-inline');

              if (row.editable) {
                const $btnIconTemplate = $('<button>')
                  .addClass('btn btn-default btn-icon')
                  .attr('data-upload-id', row.id);

                const $btnRemove = $btnIconTemplate.clone().addClass('remove-file');
                const $iconBin = $('<i>').addClass('icon icon-bin');
                $btnRemove.append($iconBin);

                const $btnEdit = $btnIconTemplate.clone().addClass('edit-file');
                const $iconEdit = $('<i>').addClass('icon icon-edit');
                $btnEdit.append($iconEdit);

                $container.append($btnEdit, $btnRemove);
              }

              const $btnDownload = $('<a>')
                .addClass('btn btn-default btn-icon download-file')
                .attr('data-upload-id', row.id)
                .attr(
                  'href',
                  Routing.generate('app_stagefiles_stream', { id: stageId, fileId: row.id }),
                );
              const $iconDownload = $('<i>').addClass('icon icon-download');
              $btnDownload.append($iconDownload);

              $container.append($btnDownload);
              return $container.prop('outerHTML');
            },
          },
        ],
        dom: 'Rrtp',
      });
      list.toggleTableDisplay(true);
      list.$target.closest('.loading-container').addClass('has-loaded');
      list.$target.rowGrouping({
        iGroupingColumnIndex: 2,
        bExpandableGrouping: true,
        bSetGroupingClassOnTR: true,
        fnOnGroupCompleted(group) {
          const elem = group.nGroup;
          const path = group.text;
          const $targetTd = $(elem).find('td:first').empty();
          const $groupContainer = $('<div />').addClass(
            'd-flex justify-content-between align-items-center',
          );

          const $folderContainer = $('<div />')
            .addClass('d-flex align-items-center')
            .append(path || '-');

          const $arrowIcon = $('<i />').addClass('icon-arrow');
          const $folderIcon = $('<i />').addClass('icon-folder');

          $folderContainer.prepend($arrowIcon, $folderIcon);

          if (list.editable) {
            const $renameLink = $('<button />')
              .addClass('rename-folder-trigger')
              .addClass('btn btn-xs btn-default')
              .attr('data-path', path)
              .text('Rename Folder');

            const $uploadCtn = $('<div />').addClass('upload-folder-ctn').append($renameLink);

            if (list.canUploadToFolder()) {
              const $uploadIcon = $('<i />').addClass('icon icon-sm icon-upload icon-left');

              const $uploadLink = $('<button />')
                .addClass('upload-folder-trigger')
                .addClass('btn btn-xs btn-default ml-2')
                .attr('data-path', path)
                .text('Upload to Folder')
                .prepend($uploadIcon);

              $uploadCtn.append($uploadLink);
            }

            $groupContainer.append($folderContainer, $uploadCtn);
            $targetTd.append($groupContainer);
          }

          list.$target.trigger('datatable-row-init', $(elem));
        },
      });
      list.init(list);
    },
    Routing.generate('app_stagefiles_fetch', { id: stageId }),
  );
  $target.trigger('data-updated');
  return docTable;
}
