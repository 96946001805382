import { gql } from '@apollo/client';

export const GET_SUBBIE_PRODUCTS_AND_RECOMMENDATION = gql`
  query GetProductsAndRecommend {
    recommendedProduct: productByFilter(filter: RECOMMENDED) {
      id
      name
      isAddon
      pricingOptions {
        billingCycle
        price {
          amount
        }
        priceExGST {
          amount
        }
        savings {
          amount
        }
      }
    }
    products(tag: SUBBIE_MEMBER) {
      id
      name
      isAddon
      pricingOptions {
        billingCycle
        price {
          amount
        }
        priceExGST {
          amount
        }
        savings {
          amount
        }
      }
    }
    supplierProducts: products(tag: SUPPLIER_EXTRA) {
      id
      name
      isAddon
      pricingOptions {
        billingCycle
        price {
          amount
        }
        priceExGST {
          amount
        }
        savings {
          amount
        }
      }
    }
    lite: products(tag: SUBBIE_PRICING_EXPERIMENT) {
      id
      name
      isAddon
      pricingOptions {
        billingCycle
        price {
          amount
        }
        priceExGST {
          amount
        }
        savings {
          amount
        }
      }
    }
    addons: products(tag: SUBBIE_ADDON) {
      id
      name
      pricingOptions {
        price {
          amount
        }
        priceExGST {
          amount
        }
        billingCycle
      }
      isAddon
      shortDescription
      longDescription
      isPurchased
    }
  }
`;
