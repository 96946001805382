import { E1Link, Pricing, Tag, TagVariant, CurrencySymbol } from '@estimateone/frontend-components';
import { EntityId } from '@ascension/types';
import styles from '../shared/styles.scss';

export const DiscountTag = ({ tagText }: { tagText: string }) => (
  <Tag variant={TagVariant.Magic600} text={tagText} className={styles.discountTag} />
);

export const DiscontinuedTag = () => <Tag variant={TagVariant.Crimson600} text="Discontinued" />;

export const UpgradeLine = ({ upgradeProductId }: { upgradeProductId: EntityId }) => (
  <div>
    <span className={styles.upgradeText}>Save on your membership!</span>
    <E1Link>
      <span className="longsell_product_trigger" data-product-id={upgradeProductId}>
        Switch to annual billing
      </span>
    </E1Link>
  </div>
);

export const LicensePaidForByLine = ({
  paidLicenseProviderName,
}: {
  paidLicenseProviderName: string;
}) => <div>Paid for by {paidLicenseProviderName}</div>;

export const CreditCardContent = ({
  lastFourDigits,
  creditCardId,
}: {
  lastFourDigits: string;
  creditCardId: EntityId;
}) => (
  <>
    <span className={styles.creditCardLastFourDigitsText}>ending {lastFourDigits}</span>
    <E1Link>
      <span className="edit-credit-card-trigger" data-credit-card={creditCardId}>
        Update card details
      </span>
    </E1Link>
  </>
);

type PricingProp = {
  price: number;
  symbol?: CurrencySymbol;
};

const PreDiscountedPrice = ({ price, symbol }: PricingProp) => (
  <span className={styles.preDiscountedPrice}>
    <Pricing price={price} symbol={symbol ?? CurrencySymbol.Dollar} />
  </span>
);

const PostDiscountedPrice = ({ price, symbol }: PricingProp) => (
  <span className={styles.postDiscountedPrice}>
    <Pricing price={price} symbol={symbol ?? CurrencySymbol.Dollar} />
  </span>
);

export const PriceContent = ({
  isDiscountActive,
  discountAdjustedAmount,
  isAdditionalState,
  productPrice,
  licensePrice,
  currencySymbol,
}: {
  isDiscountActive: boolean;
  discountAdjustedAmount: number;
  isAdditionalState: boolean;
  productPrice: number;
  licensePrice: number;
  currencySymbol?: CurrencySymbol;
}) =>
  isDiscountActive && isAdditionalState ? (
    <>
      <PreDiscountedPrice price={productPrice} symbol={currencySymbol} />
      <PreDiscountedPrice price={licensePrice} symbol={currencySymbol} />
      <PostDiscountedPrice price={discountAdjustedAmount} symbol={currencySymbol} />
    </>
  ) : isDiscountActive ? (
    <>
      <PreDiscountedPrice price={licensePrice} symbol={currencySymbol} />
      <PostDiscountedPrice price={discountAdjustedAmount} symbol={currencySymbol} />
    </>
  ) : isAdditionalState ? (
    <>
      <PreDiscountedPrice price={productPrice} symbol={currencySymbol} />
      <PostDiscountedPrice price={licensePrice} symbol={currencySymbol} />
    </>
  ) : (
    <Pricing price={licensePrice} symbol={currencySymbol ?? CurrencySymbol.Dollar} />
  );
