import { useField } from 'react-final-form';
import { PropsValue } from 'react-select';
import { PackageSelectOptionFragment as PackageSelectOption } from './fragments.generated';
import {
  SingleSelectField,
  SingleSelectFieldProps,
} from '../../../../shared/FinalForm/Field/SingleSelectField';
import { comparePackageTitlesAlphaFullSetLast } from '../../../../shared/Util/sorting';

export type PackageSelectFieldProps = SingleSelectFieldProps<PackageSelectOption> & {
  packages: PackageSelectOption[];
};

export const PackageSelectField = ({
  fieldName,
  packages,
  isRequired,
  defaultValue,
  ...props
}: PackageSelectFieldProps) => {
  const { input: packageInputProps } = useField<PropsValue<PackageSelectOption>>(fieldName, {
    defaultValue,
    validate: (value) =>
      isRequired ? (value ? undefined : ['Select package from the list']) : undefined,
  });

  const sortedPackages = [...packages].sort((a, b) =>
    comparePackageTitlesAlphaFullSetLast(a.label, b.label),
  );

  return (
    <SingleSelectField<PackageSelectOption>
      fieldName={fieldName}
      placeholder=""
      options={sortedPackages}
      isRequired={isRequired}
      {...props}
      {...packageInputProps}
    />
  );
};
