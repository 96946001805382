import { useContext } from 'react';
import { DistanceUnit } from '@shared';
import { SubbieNetworkFilterDataContext } from './context';
import { Country } from '@ascension/enums';
import { ValueLabelOption } from '@estimateone/frontend-components/src/components/Form/Select/types';

const idAndNameToOption = <T extends { id: number; name: string }>({
  id,
  name,
}: T): ValueLabelOption<number> => ({
  value: id,
  label: name,
});

export const useAccountData = () => useContext(SubbieNetworkFilterDataContext);

export const useUserImperialSetting = () => {
  const ascAccountData = useAccountData();
  return ascAccountData?.currentUser.imperialUnitsSetting;
};

export const useGetDistanceLabel = (distance: number) => {
  const imperial = useUserImperialSetting();
  return imperial ? `${distance}${DistanceUnit.MILES}` : `${distance}${DistanceUnit.KM}`;
};

export const useTradesOptions = () => {
  const ascAccountData = useAccountData();
  return ascAccountData ? ascAccountData.stockTrades.map(idAndNameToOption) : null;
};

export const useStockTradeList = () => {
  const ctx = useAccountData();

  return ctx ? ctx.stockTrades : undefined;
};

export const useCategoryOptions = () => {
  const ascAccountData = useAccountData();
  return ascAccountData ? ascAccountData.stageCategories.map(idAndNameToOption) : null;
};

export const useCompanyTags = () => {
  const ascAccountData = useAccountData();
  return ascAccountData ? ascAccountData.companyTags?.map(idAndNameToOption) : null;
};

export const useAccountPrimaryCountryId = () => {
  const ascAccountData = useAccountData();
  return ascAccountData ? ascAccountData?.currentUser.account?.primaryCountry?.id : null;
};

export const useInCountry = (id: number): boolean => {
  const ascAccountData = useAccountData();
  return ascAccountData?.currentUser.account?.primaryCountry?.id === id || false;
};

export const useIsAustralianAccount = (): boolean => useInCountry(Country.COUNTRY_AUSTRALIA);
