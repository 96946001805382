import 'core-js/stable';
import 'custom-event-polyfill';
import 'isomorphic-unfetch';
import { ComponentProps, lazy, Suspense } from 'react';
import { createRoot, Root } from 'react-dom/client';
import { Provider as JotaiProvider } from 'jotai';
import { BuilderAndScopesApiProvider, BuilderApiProvider } from '@api/ApiProvider';
import { DefaultOperatingCountryProvider } from '@ascension/components/localisation/DefaultOperatingCountryProvider';
import { renderWithParents, renderWithParentsFromRoot } from '@ascension/components/render-helper';
import BuilderErrorBoundary from '@builder/BuilderErrorBoundary';
import { AccountSettingsSchedule } from '@builder/features/AccountSettingsSchedule';
import AddressBookDuplicateContainer from '@builder/features/AddressBookDuplicates';
import {
  AB_DUPLICATE_COUNT_UPDATE_EVENT,
  AbDuplicateCountUpdateEventData,
} from '@builder/features/AddressBookDuplicates/events';
import { SsoSecretBanner } from '@builder/features/Banners/SsoSecretBanner';
import { TermsBanner } from '@builder/features/Banners/TermsBanner';
import { ConnectionSuggestions } from '@builder/features/ConnectionSuggestions';
import { DocSyncStorageRegion } from '@builder/features/DocSyncIntegration/DocSyncPlugin/DocSyncPlugin';
import { procorePlugin } from '@builder/features/DocSyncIntegration/DocSyncPlugin/ProcorePlugin';
import { sharepointPlugin } from '@builder/features/DocSyncIntegration/DocSyncPlugin/SharepointPlugin';
import { DocumentIntegrationConnectButton } from '@builder/features/DocSyncIntegration/DocumentIntegrationConnectButton';
import { DocumentIntegrationProvider } from '@builder/features/DocSyncIntegration/DocumentIntegrationProvider';
import { DocumentMatrixNotifyModal } from '@builder/features/Documents/DocumentMatrixNotifyModal/DocumentMatrixNotifyModal';
import { TenderDocumentsHeader } from '@builder/features/Documents/TenderDocumentsHeader/TenderDocumentsHeader';
import { InviteToQuote } from '@builder/features/InviteToQuote';
import { InvitesAndResponses } from '@builder/features/InvitesAndResponses';
import ManualContactMergeContainer from '@builder/features/ManualContactMerge';
import NotificationsFeature from '@builder/features/NotificationsFeature';
import NotificationsErrorBoundary from '@builder/features/NotificationsFeature/ErrorBoundaries';
import { PackageDashboard } from '@builder/features/ProcurementLettingSchedule/PackageDashboard';
import { ProcurementLettingScheduleController } from '@builder/features/ProcurementLettingSchedule/ProcurementLettingScheduleController';
import { ProcurementInfoCard } from '@builder/features/ProcurementWizard/ProcurementInfoCard';
import { SubbieProfilesSliderWithEventListener } from '@builder/features/ProfilesFeature/SubbieProfilesSlider';
import { SsoSecretManagement } from '@builder/features/SsoSecrets';
import { StageAccessControlContainer } from '@builder/features/StageAccessControl';
import { StageSettings } from '@builder/features/StageSettings';
import { TenderDashboardLinks } from '@builder/features/TenderDashboardLinks';
import { TenderInfoCard } from '@builder/features/TenderWizard/TenderInfoCard';
import { TendersReadyForHandoverList } from '@builder/features/TendersReadyForHandoverList';
import UpdateTenderStatusModal from '@builder/features/UpdateTenderStatusModal';
import SubbieProfilesPage from '@builder/pages/Profiles/SubbieProfilesPage';
import { UniversalProfileDrawer } from '@builder/pages/Profiles/UniversalProfileDrawer';
import CompleteQuotePage from '@builder/pages/Quotes/CompleteQuotePage';
import CreateQuoteFromRfqPage from '@builder/pages/Quotes/CreateQuoteFromRfqPage';
import ReviseQuotePage from '@builder/pages/Quotes/ReviseQuotePage';
import QuoteViewPage from '@builder/pages/Quotes/View';
import StageQuotesListPage from '@builder/pages/StageQuotesListPage';
import { TenderHandoverPage } from '@builder/pages/TenderHandoverPage';
import LicenseTable from '@subbie/pages/LicensesPage/LicenseTable';
import { UniversalProfileDrawerSubpage } from '@subbie/pages/Profiles/universalProfileDrawerSubpage';
import { CurrencyInfoContextProvider, FileUploader, LoadingSpinner } from '@shared';
import { EmotionCacheProvider } from '@shared/EmotionCacheProvider';
import { FileManagerParams } from '@shared/FileUploader/types';
import { FlashMessageListener } from '@shared/FlashMessageListener';
import QuotesErrorBoundary from '@shared/Quotes/ErrorBoundaries';
import { BuilderAccountProvider } from '@builder/context/AccountProvider/BuilderAccountProvider';
import BuilderQuotesProvider from '@builder/context/BuilderQuotesProvider';
import { TwigGlobalProvider } from '@builder/context/TwigFeatureProvider/TwigGlobalProvider';
import { StageType, UploadCategory, UploadParentType } from '@ascension/enums';
import { EntityId } from '@ascension/types';

const render = () => renderWithParents();

const ProcurementDocumentsHeader = lazy(
  () => import('@builder/features/Documents/ProcurementDocumentsHeader/ProcurementDocumentsHeader'),
);

const renderWithErrorBoundaryWithBuilderAndScopesApi = <T extends DOMStringMap>(
  containerId: string,
) => renderWithParents<T>(<BuilderErrorBoundary />, <BuilderAndScopesApiProvider />)(containerId);

const renderWithErrorBoundaryAndApi = <T extends DOMStringMap>(containerId: string) =>
  renderWithParents<T>(<BuilderErrorBoundary />, <BuilderApiProvider />)(containerId);

const renderWithErrorBoundaryAndApiFromRoot = <T extends DOMStringMap>(
  container: HTMLElement | null,
  root: Root,
) =>
  renderWithParentsFromRoot<T>(<BuilderErrorBoundary />, <BuilderApiProvider />)(container, root);

renderWithErrorBoundaryAndApi<{
  title: string;
  details: string;
  termsUrl: string;
  privacyUrl: string;
}>('terms-banner')(({ title, details, termsUrl, privacyUrl }) => (
  <TermsBanner title={title} details={details} termsUrl={termsUrl} privacyUrl={privacyUrl} />
));

render()('sso-secret-expiry-banner')(({ expiringSecretDate }) => (
  <SsoSecretBanner secretExpiresAt={expiringSecretDate} />
));

renderWithErrorBoundaryAndApi('address-book-duplicate-page')(() => (
  <>
    <ManualContactMergeContainer />
    <AddressBookDuplicateContainer />
  </>
));

renderWithErrorBoundaryAndApi('address-book-connection-suggestions-page')(() => (
  <BuilderAccountProvider>
    <DefaultOperatingCountryProvider>
      <ConnectionSuggestions />
    </DefaultOperatingCountryProvider>
  </BuilderAccountProvider>
));

renderWithErrorBoundaryAndApi('update-tender-status-modal-container')(() => (
  <UpdateTenderStatusModal />
));

renderWithErrorBoundaryAndApi<{ stageId: string }>('builder-invites-responses')(({ stageId }) => (
  <InvitesAndResponses stageId={parseInt(stageId)} />
));

renderWithErrorBoundaryAndApi('license-table-builder')(() => <LicenseTable />);

renderWithParents<{ pollRate: string }>(
  <NotificationsErrorBoundary />,
  <BuilderApiProvider />,
)('react-notifications-container')(({ pollRate }) => (
  <NotificationsFeature defaultPollIntervalSeconds={parseInt(pollRate)} />
));

renderWithErrorBoundaryAndApi<{ accountId: string }>('subbie-profiles-view-page')(
  ({ accountId }) => <SubbieProfilesPage accountId={parseInt(accountId)} />,
);

const renderQuotesPage = <T extends DOMStringMap>(containerId: string) =>
  renderWithParents<T>(
    <QuotesErrorBoundary />,
    <BuilderApiProvider />,
    <CurrencyInfoContextProvider />,
  )(containerId);

renderQuotesPage<{
  quoteId: string;
  quotesListUrl: string;
  returnTo: string;
  returnUrl: string;
  profilesAscensionUrl: string;
  hasFixedPriceQuotesFeature: string;
}>('builder-quote-view-page')(
  ({
    quoteId,
    quotesListUrl,
    returnTo,
    returnUrl,
    profilesAscensionUrl,
    hasFixedPriceQuotesFeature,
  }) => (
    <TwigGlobalProvider
      options={{ hasFixedPriceQuotesFeature: hasFixedPriceQuotesFeature === '1', isBuilder: true }}
    >
      <BuilderAccountProvider>
        <QuoteViewPage
          profilesAscensionUrl={profilesAscensionUrl}
          quoteId={parseInt(quoteId)}
          quotesListUrl={quotesListUrl}
          returnTo={parseInt(returnTo)}
          returnUrl={returnUrl}
        />
      </BuilderAccountProvider>
    </TwigGlobalProvider>
  ),
);

renderQuotesPage<
  {
    quoteId: string;
    stageId: string;
    returnUrl: string;
    hasFixedPriceQuotesFeature: string;
  } & Pick<FileManagerParams, 'fileManagerLocale'>
>('builder-quote-revise-page')(
  ({ fileManagerLocale, quoteId, stageId, returnUrl, hasFixedPriceQuotesFeature }) => (
    <TwigGlobalProvider
      options={{ hasFixedPriceQuotesFeature: hasFixedPriceQuotesFeature === '1', isBuilder: true }}
    >
      <ReviseQuotePage
        fileManagerLocale={fileManagerLocale}
        quoteId={parseInt(quoteId)}
        stageId={parseInt(stageId)}
        returnUrl={returnUrl}
      />
    </TwigGlobalProvider>
  ),
);

renderQuotesPage<{ stageId: string; stageType: string; profilesAscensionUrl: string }>(
  'builder-stage-quote-upload-page',
)(({ stageId, stageType, profilesAscensionUrl }) => (
  <BuilderAccountProvider>
    <BuilderQuotesProvider stageId={parseInt(stageId)}>
      <StageQuotesListPage
        stageId={parseInt(stageId)}
        stageType={parseInt(stageType)}
        profilesAscensionUrl={profilesAscensionUrl}
      />
    </BuilderQuotesProvider>
  </BuilderAccountProvider>
));

renderQuotesPage<{
  quoteId: string;
  returnUrl: string;
  stageId: string;
  hasFixedPriceQuotesFeature: string;
}>('builder-stage-quote-complete-page')(
  ({ quoteId, returnUrl, stageId, hasFixedPriceQuotesFeature }) => (
    <TwigGlobalProvider
      options={{ hasFixedPriceQuotesFeature: hasFixedPriceQuotesFeature === '1', isBuilder: true }}
    >
      <CompleteQuotePage
        quoteId={parseInt(quoteId)}
        returnUrl={returnUrl}
        stageId={parseInt(stageId)}
      />
    </TwigGlobalProvider>
  ),
);

renderQuotesPage<{
  returnUrl: string;
  rfqId: string;
  stageId: string;
  hasFixedPriceQuotesFeature: string;
}>('builder-stage-quote-create-from-rfq-page')(
  ({ returnUrl, rfqId, stageId, hasFixedPriceQuotesFeature }) => (
    <TwigGlobalProvider
      options={{ hasFixedPriceQuotesFeature: hasFixedPriceQuotesFeature === '1', isBuilder: true }}
    >
      <CreateQuoteFromRfqPage
        rfqId={parseInt(rfqId)}
        stageId={parseInt(stageId)}
        returnUrl={returnUrl}
      />
    </TwigGlobalProvider>
  ),
);

renderWithErrorBoundaryAndApi<{ profilesAscensionUrl: string }>('subbie-profiles-flyout')(
  ({ profilesAscensionUrl }) => (
    <SubbieProfilesSliderWithEventListener profilesAscensionUrl={profilesAscensionUrl} />
  ),
);

renderWithParents(<BuilderErrorBoundary />)('procurement-wizard-infocard')(() => (
  <ProcurementInfoCard />
));

renderWithErrorBoundaryAndApi<{ accessRestrictedTooltip: string }>('tender-handover-accordion')(
  ({ accessRestrictedTooltip }) => (
    <TendersReadyForHandoverList accessRestrictedTooltip={accessRestrictedTooltip} />
  ),
);

renderWithErrorBoundaryAndApi<{
  stageId: string;
  hasDocumentIntegration: string;
  cspNonce: string;
}>('procurement-letting-schedule')(({ stageId, hasDocumentIntegration, cspNonce }) => (
  <EmotionCacheProvider cacheKey="procurement-letting-schedule" nonce={cspNonce}>
    <BuilderAccountProvider>
      <ProcurementLettingScheduleController
        stageId={parseInt(stageId)}
        hasDocumentIntegration={hasDocumentIntegration === '1'}
      />
    </BuilderAccountProvider>
  </EmotionCacheProvider>
));

type MatrixNotifyStartEvent = CustomEvent<{
  stageId: EntityId;
  stageType: StageType;
  addendumId?: EntityId;
}>;

// eslint-disable-next-line fp/no-let
let notifyModalRoot: Root | undefined;
const notifyModalContainer = document.getElementById('matrix-notify-modal');

if (notifyModalContainer) {
  // eslint-disable-next-line fp/no-let
  let mountedNotifyModal: HTMLElement | null | undefined = null;
  notifyModalContainer.addEventListener('finalise-matrix-start', (e: MatrixNotifyStartEvent) => {
    if (!mountedNotifyModal) {
      // eslint-disable-next-line fp/no-mutation
      notifyModalRoot = createRoot(notifyModalContainer!);
      // eslint-disable-next-line fp/no-mutation
      mountedNotifyModal = renderWithErrorBoundaryAndApiFromRoot(
        notifyModalContainer,
        notifyModalRoot,
      )(() => (
        <DocumentMatrixNotifyModal
          stageId={e.detail.stageId}
          stageType={e.detail.stageType}
          root={notifyModalContainer}
        />
      ));
    }
  });
  notifyModalContainer.addEventListener('finalise-matrix-end', () => {
    if (mountedNotifyModal) {
      if (notifyModalRoot) notifyModalRoot.unmount();
      // eslint-disable-next-line fp/no-mutation
      mountedNotifyModal = null;
    }
  });
}

renderWithErrorBoundaryAndApi('account-settings-schedule')(() => <AccountSettingsSchedule />);

renderWithErrorBoundaryAndApi<{
  stageId: string;
  stageName: string;
  stageType: string;
  docsyncAuthHost: string;
  hasDocumentIntegration: string;
  isRevisionFlowEnabled: string;
  activeDocumentCount: string;
  stagePackageCount: string;
  hasExistingAddendum: string;
  existingAddendumId: string | undefined;
  filemanagerRegion: DocSyncStorageRegion;
  isInSharepointExperiment: string;
}>('documents-header-procurement')(
  ({
    stageId,
    stageName,
    stageType,
    docsyncAuthHost,
    hasDocumentIntegration,
    activeDocumentCount,
    stagePackageCount,
    hasExistingAddendum,
    existingAddendumId,
    filemanagerRegion,
    isInSharepointExperiment,
  }) => {
    // eslint-disable-next-line fp/no-mutation, camelcase
    __webpack_public_path__ = document.body.dataset.cdnRoot
      ? `${document.body.dataset.cdnRoot}/`
      : '/';

    return (
      <Suspense fallback={<LoadingSpinner />}>
        <DocumentIntegrationProvider
          plugins={[procorePlugin, sharepointPlugin]}
          stageId={parseInt(stageId)}
          stageName={stageName}
          stageType={parseInt(stageType)}
          storageRegion={filemanagerRegion}
          authHost={docsyncAuthHost}
          isInSharepointExperiment={isInSharepointExperiment === '1'}
          hasExistingDocuments={parseInt(activeDocumentCount) > 0}
          hasDocumentIntegration={hasDocumentIntegration === '1'}
        >
          <ProcurementDocumentsHeader
            stageId={parseInt(stageId)}
            stageType={parseInt(stageType)}
            activeDocumentCount={parseInt(activeDocumentCount)}
            stagePackageCount={parseInt(stagePackageCount)}
            hasExistingAddendum={hasExistingAddendum === '1'}
            existingAddendumId={existingAddendumId ? parseInt(existingAddendumId) : null}
          />
        </DocumentIntegrationProvider>
      </Suspense>
    );
  },
);

renderWithErrorBoundaryAndApi<{
  docsyncAuthHost: string;
  stageId: string;
  stageName: string;
  stageType: string;
  filemanagerRegion: DocSyncStorageRegion;
  isInSharepointExperiment: string;
  hasDocumentIntegration: string;
  activeDocumentCount: string;
}>('tender-wizard-connect-to-docsync')(
  ({
    docsyncAuthHost,
    stageId,
    stageName,
    isInSharepointExperiment,
    stageType,
    filemanagerRegion,
    hasDocumentIntegration,
    activeDocumentCount,
  }) => (
    <DocumentIntegrationProvider
      stageId={parseInt(stageId)}
      stageName={stageName}
      stageType={parseInt(stageType)}
      plugins={[procorePlugin, sharepointPlugin]}
      storageRegion={filemanagerRegion}
      authHost={docsyncAuthHost}
      isInSharepointExperiment={isInSharepointExperiment === '1'}
      hasExistingDocuments={parseInt(activeDocumentCount) > 0}
      hasDocumentIntegration={hasDocumentIntegration === '1'}
    >
      <DocumentIntegrationConnectButton />
    </DocumentIntegrationProvider>
  ),
);

renderWithErrorBoundaryAndApi<{
  docsyncAuthHost: string;
  stageId: string;
  stageName: string;
  isProcoreSliderOpen: string;
  hasDocumentIntegration: string;
  activeDocumentCount: string;
  stageType: string;
  filemanagerRegion: DocSyncStorageRegion;
  isInSharepointExperiment: string;
}>('procurement-wizard-connect-to-docsync')(
  ({
    docsyncAuthHost,
    stageId,
    stageName,
    activeDocumentCount,
    isInSharepointExperiment,
    stageType,
    filemanagerRegion,
    hasDocumentIntegration,
  }) => (
    <DocumentIntegrationProvider
      stageId={parseInt(stageId)}
      stageName={stageName}
      stageType={parseInt(stageType)}
      plugins={[procorePlugin, sharepointPlugin]}
      storageRegion={filemanagerRegion}
      authHost={docsyncAuthHost}
      isInSharepointExperiment={isInSharepointExperiment === '1'}
      hasExistingDocuments={parseInt(activeDocumentCount) > 0}
      hasDocumentIntegration={hasDocumentIntegration === '1'}
    >
      <DocumentIntegrationConnectButton />
    </DocumentIntegrationProvider>
  ),
);

renderWithErrorBoundaryAndApi<{
  stageId: string;
  stageName: string;
  stageType: string;
  stagePackageCount: string;
  activeDocumentCount: string;
  existingAddendumId: string | undefined;
  existingAddendumName: string | undefined;
  docsyncAuthHost: string;
  filemanagerRegion: DocSyncStorageRegion;
  isInSharepointExperiment: string;
  hasDocumentIntegration: string;
}>('documents-header-tender')(
  ({
    stageId,
    stageName,
    stageType,
    stagePackageCount,
    activeDocumentCount,
    existingAddendumId,
    existingAddendumName,
    docsyncAuthHost,
    filemanagerRegion,
    isInSharepointExperiment,
    hasDocumentIntegration,
  }) => (
    <DocumentIntegrationProvider
      stageId={parseInt(stageId)}
      stageName={stageName}
      stageType={parseInt(stageType)}
      plugins={[procorePlugin, sharepointPlugin]}
      storageRegion={filemanagerRegion}
      authHost={docsyncAuthHost}
      isInSharepointExperiment={isInSharepointExperiment === '1'}
      hasExistingDocuments={parseInt(activeDocumentCount) > 0}
      hasDocumentIntegration={hasDocumentIntegration === '1'}
    >
      <TenderDocumentsHeader
        stageId={parseInt(stageId)}
        stageType={parseInt(stageType)}
        existingAddendumId={existingAddendumId ? parseInt(existingAddendumId) : null}
        existingAddendumName={existingAddendumName || null}
        stagePackageCount={parseInt(stagePackageCount)}
        activeDocumentCount={parseInt(activeDocumentCount)}
      />
    </DocumentIntegrationProvider>
  ),
);

renderWithErrorBoundaryAndApi<{ stageId: string }>('stage-settings')(({ stageId }) => (
  <StageSettings stageId={parseInt(stageId)} />
));

renderWithErrorBoundaryAndApi<{
  stageId: string;
  hasStageRestrictedAccess: string;
}>('stage-team')(({ stageId, hasStageRestrictedAccess }) => (
  <BuilderAccountProvider>
    <StageAccessControlContainer
      stageId={parseInt(stageId)}
      hasStageRestrictedAccess={hasStageRestrictedAccess === '1'}
    />
  </BuilderAccountProvider>
));

renderWithErrorBoundaryAndApi('sso-secrets')(() => <SsoSecretManagement />);

renderWithErrorBoundaryAndApi<{
  stageId: string;
  stageType: string;
  hasAbView: string;
  hasBuilderQuotesFeature: string;
  hasStageRetenderAccess: string;
  hasTenderHandoverAccess: string;
  hasStageRestrictedAccess: string;
  hasDocumentIntegration: string;
}>('tender-dashboard-links')(
  ({
    stageId,
    stageType,
    hasAbView,
    hasBuilderQuotesFeature,
    hasStageRetenderAccess,
    hasTenderHandoverAccess,
    hasStageRestrictedAccess,
    hasDocumentIntegration,
  }) => (
    <BuilderAccountProvider>
      <TenderDashboardLinks
        stageId={parseInt(stageId)}
        stageType={stageType}
        hasAbView={hasAbView === '1'}
        hasBuilderQuotesFeature={hasBuilderQuotesFeature === '1'}
        hasStageRetenderAccess={hasStageRetenderAccess === '1'}
        hasTenderHandoverAccess={hasTenderHandoverAccess === '1'}
        hasStageRestrictedAccess={hasStageRestrictedAccess === '1'}
        hasDocumentIntegration={hasDocumentIntegration === '1'}
      />
    </BuilderAccountProvider>
  ),
);

renderWithErrorBoundaryWithBuilderAndScopesApi<{
  stageId: string;
  packageId: string;
  scopesUiUrl: string;
  hasDocumentIntegration: string;
}>('package-dashboard')(({ stageId, packageId, scopesUiUrl, hasDocumentIntegration }) => (
  <BuilderAccountProvider>
    <PackageDashboard
      stageId={parseInt(stageId)}
      packageId={parseInt(packageId)}
      scopesUiUrl={scopesUiUrl}
      hasDocumentIntegration={hasDocumentIntegration === '1'}
    />
  </BuilderAccountProvider>
));

renderWithParents(<BuilderErrorBoundary />)('tender-wizard-infocard')(() => <TenderInfoCard />);

renderWithErrorBoundaryWithBuilderAndScopesApi<{ stageId: string }>(
  'builder-invite-to-quote-container',
)(({ stageId }) => <InviteToQuote stageId={parseInt(stageId)} />);

renderWithErrorBoundaryAndApi<{ stageId: string; hasFixedPriceQuotesFeature: string }>(
  'builder-tender-wizard-handover-page',
)(({ stageId, hasFixedPriceQuotesFeature }) => (
  <TwigGlobalProvider
    options={{ hasFixedPriceQuotesFeature: hasFixedPriceQuotesFeature === '1', isBuilder: true }}
  >
    <TenderHandoverPage stageId={parseInt(stageId)} />
  </TwigGlobalProvider>
));

export const renderDrawerUi = () => {
  renderWithErrorBoundaryAndApi<{
    canViewAddressBook: string;
    hasProcurementPhaseFeature: string;
    activeSubpage: UniversalProfileDrawerSubpage;
    gatewayBaseUri: string;
  }>('render-drawer-ui-container')(
    ({ canViewAddressBook, hasProcurementPhaseFeature, activeSubpage }) => (
      <BuilderAccountProvider>
        <JotaiProvider>
          <UniversalProfileDrawer
            canViewAddressBook={canViewAddressBook === '1'}
            hasProcurementPhaseFeature={hasProcurementPhaseFeature === '1'}
            activeSubpage={activeSubpage}
          />
        </JotaiProvider>
        <FlashMessageListener />
      </BuilderAccountProvider>
    ),
  );
};

type UploaderProps = ComponentProps<typeof FileUploader>;

export const renderUppy = (
  containerId: string,
  onUploadComplete: UploaderProps['onUploadComplete'],
  onAllUploadsComplete: UploaderProps['onAllUploadsComplete'],
  allowAutoUnzipChoice: boolean,
  autoUnzip: boolean,
) => {
  renderWithErrorBoundaryAndApi<{
    region: string;
    parentId: string;
    parentType: UploadParentType;
    category: UploadCategory;
  }>(containerId)(({ region, parentId, parentType, category }) => (
    <FileUploader
      fileManagerLocale={region}
      parentId={parseInt(parentId)}
      parentType={parentType}
      category={category}
      // onFileUploaded={onFileUploaded}
      onUploadComplete={onUploadComplete}
      onAllUploadsComplete={onAllUploadsComplete}
      allowAutoUnzipChoice={allowAutoUnzipChoice}
      autoUnzip={autoUnzip}
    />
  ));
};

document.addEventListener(
  AB_DUPLICATE_COUNT_UPDATE_EVENT,
  ({ detail: { duplicateCount } }: CustomEvent<AbDuplicateCountUpdateEventData>) => {
    const countText = duplicateCount >= 100 ? '100+' : duplicateCount.toString();
    document.querySelectorAll('[data-duplicate-count]').forEach((el) => {
      // eslint-disable-next-line fp/no-mutation, no-param-reassign
      el.textContent = countText;
    });
  },
);
