import AppStorageService from './AppStorageService';

/**
 * An implementation of Web Storage API specific for sessionStorage
 */
export default class SessionStorageService<
  T extends string | number | boolean | object,
> extends AppStorageService<T> {
  constructor(storageKey: string) {
    super(storageKey, window.sessionStorage);
  }
}
