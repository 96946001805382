import { gql } from '@apollo/client';

export const PACKAGES_FOR_QUOTE_COVERAGE_REPORT = gql`
  query GetPackagesForQuoteCoverageReport($stageId: EntityId!) {
    activePackages(stageId: $stageId) {
      id
      title
      discipline {
        id
        name
      }
      quotesDueAt
      countOfQuotedRfqs
      countOfDocDownloads
      countOfQuotingRfqs
      countOfNotQuotingRfqs
      countOfInvitesSent
      countOfCompanyInvitesSent
      countOfTotalRfqs
    }
  }
`;
