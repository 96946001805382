import { GroupBase } from 'react-select';
import { prefixMenuOptionsWithCheckIcon, SelectSize } from '@estimateone/frontend-components';
import { GroupedPackageOptionLabel } from './GroupedPackageOptionLabel';
import { PackageOptionLabel } from './PackageOptionLabel';
import { LabelledOption, SingleSelect } from '@shared';
import { GroupedPackageOption, PackageOption, PackageValue } from './types';
import { EntityId } from '@subbie/modal/ProjectSlider/types';

type PackageSelectProps = {
  stageId: EntityId;
  options: GroupBase<GroupedPackageOption>[] | PackageOption[];
  selectedValue: PackageValue | null;
  onChange?: (packageOption: LabelledOption<PackageValue>) => void;
  useGroupedRecommendedOptions: boolean;
};

export const PackageSelect = ({
  stageId,
  options,
  selectedValue,
  onChange,
  useGroupedRecommendedOptions,
}: PackageSelectProps) => {
  const optionLabelFormatter = prefixMenuOptionsWithCheckIcon<LabelledOption<PackageValue>, true>(
    useGroupedRecommendedOptions ? GroupedPackageOptionLabel : PackageOptionLabel,
  );

  return (
    <SingleSelect<PackageValue>
      id={`stage-${stageId}-package`}
      name={`stage-${stageId}-package`}
      label="Package"
      placeholder="Select package"
      hideLabel
      size={SelectSize.Small}
      options={options}
      formatOptionLabel={optionLabelFormatter}
      value={selectedValue}
      onChange={({ selectedOption }) => {
        if (onChange && selectedOption) {
          onChange(selectedOption);
        }
      }}
    />
  );
};
