import { Card, CardVariant, StatusConnectedIcon, Tooltip } from '@estimateone/frontend-components';
import { CompanyCardClickableWrapper } from '@builder/common/CompanyCardWithHeading';
import { showUniversalProfileDrawer } from '@builder/pages/Profiles/UniversalProfileDrawer';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

type CompanyHeaderProps = {
  id: EntityId;
  name: string;
  address: string | null;
  phone: string | null;
  hasNetworkConnection: boolean;
};

type CompanyCardProps = {
  id: EntityId;
  name: string;
  address: string | null;
  phone: string | null;
  contacts: {
    id: EntityId;
    fullName: string;
    email: string | null;
    phone: string | null;
  }[];
  hasNetworkConnection: boolean;
};

const ConnectedIcon = () => (
  <div className={styles.companyCardHeaderConnectedIcon}>
    <Tooltip
      text="This company is part of the E1 Network. You are connected and can invite them to quote."
      tooltipId="network-connected-tooltip"
    >
      <StatusConnectedIcon />
    </Tooltip>
  </div>
);

const CompanyHeader = ({ id, name, address, phone, hasNetworkConnection }: CompanyHeaderProps) => (
  <div className={styles.companyCardHeader}>
    <h5>
      <div className={styles.companyCardHeaderName}>
        <div>{name}</div>
        {hasNetworkConnection ? <ConnectedIcon /> : null}
      </div>
      <div className={styles.companyCardHeaderId}>#{id}</div>
    </h5>
    <div className={styles.companyDetails}>
      <span>{address || '-'}</span>
      <span>{phone || '-'}</span>
    </div>
  </div>
);

type ContactCardDetailProps = { name: string; email: string | null; phone: string | null };

const ContactCardDetail = ({ name, email, phone }: ContactCardDetailProps) => (
  <li className={styles.contact}>
    <div className={styles.contactName} title={name}>
      <strong>{name}</strong>
    </div>
    <div className={styles.contactEmail} title={email || '-'}>
      {email || '-'}
    </div>
    <div className={styles.contactPhone} title={phone || '-'}>
      {phone || '-'}
    </div>
  </li>
);

type ContactDetailsProps = {
  contacts: {
    id: EntityId;
    fullName: string;
    email: string | null;
    phone: string | null;
  }[];
};

const ContactDetails = ({ contacts }: ContactDetailsProps) => (
  <ul className={styles.companyCardContacts}>
    {contacts.map(({ id, fullName, email, phone }) => (
      <ContactCardDetail key={id} name={fullName} email={email} phone={phone} />
    ))}
  </ul>
);

const CompanyCardContent = ({
  id,
  address,
  name,
  phone,
  contacts,
  hasNetworkConnection,
}: CompanyHeaderProps & ContactDetailsProps) => (
  <Card variant={CardVariant.Grey}>
    <Card.Body isWhite>
      <CompanyHeader
        id={id}
        name={name}
        address={address}
        phone={phone}
        hasNetworkConnection={hasNetworkConnection}
      />
      <ContactDetails contacts={contacts} />
    </Card.Body>
  </Card>
);

const CompanyCard = ({
  id,
  name,
  address,
  phone,
  contacts,
  hasNetworkConnection,
}: CompanyCardProps) => (
  <CompanyCardClickableWrapper
    onClick={() => {
      showUniversalProfileDrawer({ companyId: id });
    }}
  >
    <CompanyCardContent
      id={id}
      name={name}
      address={address}
      phone={phone}
      contacts={contacts}
      hasNetworkConnection={hasNetworkConnection}
    />
  </CompanyCardClickableWrapper>
);

export default CompanyCard;
