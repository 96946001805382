import {
  Button,
  ButtonSize,
  ButtonVariant,
  E1Link,
  LinkVariant,
  Modal,
  ModalSize,
} from '@estimateone/frontend-components';
import { ConnectionInformationModalProps } from '@builder/features/DocSyncIntegration/DocSyncPlugin/DocSyncPlugin';
import {
  useDocsyncAuthHost,
  useDocumentIntegrationContext,
} from '@builder/features/DocSyncIntegration/DocumentIntegrationProvider';
import { connectToSharepoint } from '@builder/features/DocSyncIntegration/Sharepoint/connectToSharepoint';
import styles from './styles.scss';

const localisedSupportArticles = {
  DEFAULT:
    'https://builder.support.estimateone.com/en/articles/1738852256-sharepoint-integration-guide',
  INTERNATIONAL:
    'https://international.support.estimateone.com/en/articles/1738818348-sharepoint-integration-guide.',
};

export const SharepointConnectionInformationModal = ({
  isOpen,
  onCancel,
}: ConnectionInformationModalProps) => {
  const { stageId, stageType, storageRegion } = useDocumentIntegrationContext();
  const authHost = useDocsyncAuthHost();

  const isAuOrNzBuilder = storageRegion === 'ap-southeast-2';
  const supportArticleLink = isAuOrNzBuilder
    ? localisedSupportArticles.DEFAULT
    : localisedSupportArticles.INTERNATIONAL;

  return (
    <Modal isOpen={isOpen} onRequestClose={onCancel} size={ModalSize.Small}>
      <Modal.Section>
        <h1 className={styles.modalHeading}>Setup SharePoint Integration</h1>
        <p>
          Enable a <strong>one-way connection</strong> from SharePoint to this E1 project
        </p>
        <p>Continue to:</p>
        <ol>
          <li>Log in to SharePoint</li>
          <li>Choose the matching site and folder</li>
          <li>Choose documents to make available to E1</li>
          <li>
            Then upload, revise or edit documents on <strong>SharePoint only</strong>.
          </li>
        </ol>
        <p>
          Read our{' '}
          <E1Link
            href={supportArticleLink}
            variant={LinkVariant.Grey}
            target="_blank"
            rel="noopener noreferrer"
          >
            Knowledge Base article
          </E1Link>{' '}
          for more information
        </p>
        <div className={styles.modalActions}>
          <Button
            size={ButtonSize.Medium}
            variant={ButtonVariant.Grey}
            onClick={onCancel}
            fullWidth
          >
            Cancel
          </Button>
          <Button
            size={ButtonSize.Medium}
            variant={ButtonVariant.Primary}
            fullWidth
            onClick={() => connectToSharepoint(stageId, stageType, authHost)}
          >
            Setup Integration
          </Button>
        </div>
      </Modal.Section>
    </Modal>
  );
};
