import { DrawerBody, DrawerFooter, DrawerToolbar } from '@estimateone/frontend-components';
import { ProjectHistoryForm } from '@profiles/features/CompanyProfile/components/ProjectHistory/ProjectHistoryForm';
import {
  DrawerWrappedComponentProps,
  withDrawerRoute,
} from '@profiles/features/CompanyProfile/components/withDrawerRoute';
import { DrawerFooterButtons } from '@profiles/features/CompanyProfile/modules/Drawer/DrawerFooterButtons';
import { extractFieldErrors } from '@profiles/features/CompanyProfile/utils';
import { sendAppcuesEditProfileEvent } from '@profiles/features/CompanyProfile/utils/appcues';
import { useAddProjectToProjectHistory } from './hooks';
import {
  ProjectHistoryFieldNames,
  ProjectHistoryFieldValues,
} from '@profiles/features/CompanyProfile/components/ProjectHistory/ProjectHistoryForm/types';

export const AddHistoricalProject = withDrawerRoute(({ onClose }: DrawerWrappedComponentProps) => {
  const formId = 'PROJECT_HISTORY_FORM';
  const { submit: submitForm, error, reset } = useAddProjectToProjectHistory();
  const closeDrawer = () => {
    onClose();
    reset();
  };

  const onSubmit = async (values: ProjectHistoryFieldValues) => {
    const { data, errors: submitErrors } = await submitForm(values);
    if (submitErrors) {
      return;
    }

    if (data?.addProjectToHistory.__typename === 'Errors') {
      const { errors } = data.addProjectToHistory;

      // eslint-disable-next-line consistent-return
      return extractFieldErrors(errors, [
        ProjectHistoryFieldNames.Name,
        ProjectHistoryFieldNames.BuilderName,
        ProjectHistoryFieldNames.State,
        ProjectHistoryFieldNames.Region,
        ProjectHistoryFieldNames.CompletionDate,
        ProjectHistoryFieldNames.Category,
        ProjectHistoryFieldNames.ContractSize,
      ]);
    }

    sendAppcuesEditProfileEvent();

    closeDrawer();
  };

  return (
    <>
      <DrawerToolbar />
      <DrawerBody>
        <ProjectHistoryForm
          id={formId}
          isEdit={false}
          onSubmit={onSubmit}
          error={error}
          canSearchProjects
        />
      </DrawerBody>
      <DrawerFooter>
        <DrawerFooterButtons formId={formId} close={closeDrawer} saveButtonText="Save Project" />
      </DrawerFooter>
    </>
  );
});
