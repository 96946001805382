import { ComponentType } from 'react';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import {
  Drawer,
  DrawerContent,
  DrawerOverlay,
  LoadingSpinner,
} from '@estimateone/frontend-components';
import { useProfileContext } from '@profiles/providers/ProfileProvider';
import { ProfileContextProps } from '@profiles/providers/ProfileProvider/ProfileProvider';

export type DrawerWrappedComponentProps = {
  onClose: () => void;
  profile: NonNullable<ProfileContextProps['profile']>;
  params: Record<string, string | undefined>;
};

export function withDrawerRoute(WrappedComponent: ComponentType<DrawerWrappedComponentProps>) {
  return () => {
    const navigate = useNavigate();

    const { profile } = useProfileContext();
    const params = useParams();

    return (
      <Drawer isOpen onClose={() => navigate('/')}>
        <DrawerOverlay />
        <DrawerContent>
          {profile ? (
            <WrappedComponent params={params} profile={profile} onClose={() => navigate('/')} />
          ) : (
            <LoadingSpinner />
          )}
        </DrawerContent>
      </Drawer>
    );
  };
}
