import { Form } from 'react-final-form';
import { TextAreaField, TextInputField } from '@shared/FinalForm/Field';
import { useUpdatePendingAddendumDetails } from '../../hooks';
import { StageType } from '@ascension/enums';
import { Addendum } from '../../types';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

export const ADDENDUM_DETAILS_FORM_ID = 'addendum-details-form';

enum AddendumDetailsFieldName {
  Name = 'name',
  Description = 'description',
}

type AddendumDetailsFieldValues = {
  [AddendumDetailsFieldName.Name]: string | null;
  [AddendumDetailsFieldName.Description]: string | null;
};

type FormErrors = Record<string, string[]>;

export const AddendumDetailsForm = ({
  stageId,
  stageType,
  pendingAddendum,
  onSuccess,
}: {
  stageId: EntityId;
  stageType: StageType;
  pendingAddendum: Addendum;
  onSuccess: (updatedAddendum: Addendum) => void;
}) => {
  const { submit: updatePendingAddendumDetails } = useUpdatePendingAddendumDetails();

  const handleOnSubmit = async (
    values: AddendumDetailsFieldValues,
  ): Promise<FormErrors | undefined> => {
    const response = await updatePendingAddendumDetails(
      stageId,
      values[AddendumDetailsFieldName.Name]!,
      values[AddendumDetailsFieldName.Description]!,
    );

    if (!response) return undefined;

    if (response.__typename === 'Errors') {
      return response.errors.reduce((errors, apiError) => {
        const newErrors = { ...errors };
        const fieldName = apiError.field ?? 'unknown';
        if (newErrors[fieldName] === undefined) {
          // eslint-disable-next-line fp/no-mutation
          newErrors[fieldName] = [];
        }
        newErrors[fieldName].push(apiError.message);
        return newErrors;
      }, {} as FormErrors);
    }

    onSuccess(response);

    return undefined;
  };

  return (
    <Form<AddendumDetailsFieldValues>
      onSubmit={handleOnSubmit}
      keepDirtyOnReinitialize
      initialValues={{
        [AddendumDetailsFieldName.Name]: pendingAddendum.name,
        [AddendumDetailsFieldName.Description]: pendingAddendum.description,
      }}
    >
      {({ handleSubmit }) => (
        <form id={ADDENDUM_DETAILS_FORM_ID} name={ADDENDUM_DETAILS_FORM_ID} onSubmit={handleSubmit}>
          <div className={styles.detailsForm}>
            <div className={styles.fieldWrapper}>
              <TextInputField
                id={`${ADDENDUM_DETAILS_FORM_ID}--name`}
                label={`${stageType === StageType.TYPE_TENDER ? 'Addendum' : 'Revision'} Name`}
                fieldName={AddendumDetailsFieldName.Name}
                validate={(value) => (!value ? ['Add a name for this revision'] : undefined)}
                isRequired
                placeholder="Revision 1"
                maxLength={255}
              />
            </div>
            <div className={styles.fieldWrapper}>
              <TextAreaField
                className={styles.descriptionField}
                id={`${ADDENDUM_DETAILS_FORM_ID}--description`}
                label="Description"
                fieldName={AddendumDetailsFieldName.Description}
                placeholder="Add a short description of changes"
                rows={4}
              />
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};
