export enum Action {
  // General
  BUTTON_CLICKED = 'ButtonClicked',
  LINK_CLICKED = 'LinkClicked',
  VIEW_PAGE = 'ViewPage',
  ERROR_GENERATED = 'ErrorGenerated',
  // Calsync
  CALENDAR_SYNC_WEBCAL_LINK_CLICK = 'CalendarSyncWebcalClick',
  CALENDAR_SYNC_ICS_LINK_COPY = 'CalendarSyncIcsLinkCopy',
  // Consultants
  CONSULTANT_ABN_CLICK = 'ConsultantAbnLinkClick',
  CONSULTANT_ACN_CLICK = 'ConsultantAcnLinkClick',
  CONSULTANT_OFFICE_EMAIL_CLICK = 'ConsultantOfficeEmailClick',
  CONSULTANT_WEBSITE_CLICK = 'ConsultantWebsiteClick',
  CONSULTANT_INSIGHTS_REPORT_REQUESTED = 'ConsultantInsightsReportRequested',
  CONSULTANT_PROFILE_VISITED_FROM_INSIGHTS_REPORT_TABLE_NAME = 'ConsultantProfileVisitedFromInsightsReportTableName',
  CONSULTANT_PROJECT_MATCH_COUNT_CLICKED = 'ConsultantProjectMatchCountClicked',
  // Help
  HELP_MODAL_OPEN = 'HelpModalOpen',
  HELP_MODAL_CLOSE = 'HelpModalClose',
  // Invite Wizard
  INVITE_WIZARD_PACKAGE_DATE_PICKER_CLICK = 'InviteWizardPackageDatePickerClick',
  INVITE_WIZARD_PACKAGE_DATE_PICKER_DATE_UPDATE = 'InviteWizardPackageDatePickerDateUpdate',
  INVITE_WIZARD_NEXT_TRADE_CLICK = 'InviteWizardNextTradeClick',
  INVITE_WIZARD_PREVIOUS_TRADE_CLICK = 'InviteWizardPreviousTradeClick',
  // Cancellation / Discontinue
  NOTIFY_INTENT_TO_CANCEL_START = 'NotifyIntentToCancelStart',
  NOTIFY_INTENT_TO_CANCEL_COMPLETE = 'NotifyIntentToCancelComplete',
  DISCONTINUE_SINGLE_LICENSE_START = 'DiscontinueSingleLicenseStart',
  DISCONTINUE_SINGLE_LICENSE_ABORT = 'DiscontinueSingleLicenseAbort',
  DISCONTINUE_SINGLE_LICENSE_COMPLETE = 'DiscontinueSingleLicenseComplete',
  // Quotes
  QUOTES_ADD_CONTACT_START = 'QuotesAddContactStart',
  QUOTES_ADD_CONTACT_COMPLETE = 'QuotesAddContactComplete',
  QUOTES_ADD_COMPANY_START = 'QuotesAddCompanyStart',
  QUOTES_ADD_COMPANY_COMPLETE = 'QuotesAddCompanyComplete',
  QUOTE_COVERAGE_OPEN = 'QuoteCoverageOpen',
  QUOTE_COVERAGE_CLOSE = 'QuoteCoverageClose',
  QUOTE_COVERAGE_TABLE_SORT = 'QuoteCoverageTableSort',
  QUOTE_COVERAGE_TABLE_CLICK_ROW = 'QuoteCoverageTableClickRow',
  // Notifications
  NOTIFICATION_OPEN = 'NotificationOpen',
  NOTIFICATION_ITEM_CLICK = 'NotificationItemClick',
  // Tender Status
  TENDER_UPDATE_STATUS_MODAL_CLOSE = 'UpdateStageStatusModalClose',
  TENDER_UPDATE_STATUS_MODAL_SUBMIT = 'UpdateStageStatusModalSubmit',
  // ITP
  ITP_QUOTE_ADD_BUTTON_CLICKED = 'ItpQuoteAddButtonClicked',
  ITP_QUOTE_SUBMIT_CLICKED = 'ItpQuoteSubmitClicked',
  ITP_QUOTE_REVISE_CLICKED = 'ItpQuoteReviseClicked',
  ITP_QUOTE_DELETE_CLICKED = 'ItpQuoteDeleteClicked',
  ITP_SIDE_BANNER_SIGNUP_CLICKED = 'ItpSideBannerSignupClicked',
  // Specifinder
  SPECIFINDER_KEYWORD_FIELD_FORM_ERROR = 'SpeciFinderKeywordFieldFormError',
  SPECIFINDER_KEYWORD_TYPE_FIELD_FORM_ERROR = 'SpeciFinderKeywordTypeFieldFormError',
  SPECIFINDER_KEYWORD_LEARN_MORE_CLICKED = 'SpeciFinderKeywordLearnMoreClicked',
  SPECIFINDER_KEYWORD_VARIATION_ADDED = 'SpeciFinderKeywordVariationAdded',
  SPECIFINDER_KEYWORD_INCLUSION_ADDED = 'SpeciFinderKeywordInclusionAdded',
  SPECIFINDER_KEYWORD_EXCLUSION_ADDED = 'SpeciFinderKeywordExclusionAdded',
  SPECIFINDER_SETTINGS_UPGRADE_BUTTON_CLICKED = 'SpeciFinderUpgradeKeywordsClicked',
  SPECIFINDER_NOTICEBOARD_ADD_KEYWORD_PROMPT_SHOWN = 'SpeciFinderNoticeBoardAddKeywordPromptShown',
  SPECIFINDER_NOTICEBOARD_ADD_KEYWORD_PROMPT_CLICKED = 'SpeciFinderNoticeBoardAddKeywordPromptClicked',
  SPECIFINDER_NOTICEBOARD_ADD_KEYWORD_PROMPT_DISMISSED = 'SpeciFinderNoticeBoardAddKeywordPromptDismissed',
  SPECIFINDER_NOTICEBOARD_UPGRADE_BANNER_SHOWN = 'SpeciFinderNoticeBoardUpgradeBannerShown',
  SPECIFINDER_NOTICEBOARD_UPGRADE_BANNER_LEARN_MORE_CLICKED = 'SpeciFinderNoticeBoardUpgradeBannerLearnMoreClicked',
  SPECIFINDER_NOTICEBOARD_UPGRADE_BANNER_UPGRADE_CLICKED = 'SpeciFinderNoticeBoardUpgradeBannerUpgradeClicked',
  SPECIFINDER_NOTICEBOARD_UPGRADE_BANNER_DISMISSED = 'SpeciFinderNoticeBoardUpgradeBannerDismissed',
  SPECIFINDER_PROJECT_SLIDER_TRIGGER_CLICKED = 'SpeciFinderProjectSliderTriggerClicked',
  SPECIFINDER_INVITES_ONBOARDING_CREATE_KEYWORDS_CLICKED = 'SF_InvitesOnboardingCreateKeywordClicked',
  SPECIFINDER_INVITES_ONBOARDING_KEYWORD_ENTERED = 'SF_InvitesOnboardingKeywordEntered',
  SPECIFINDER_INVITES_ONBOARDING_KEYWORD_SAVED = 'SF_InvitesOnboardingKeywordSaved',
  SPECIFINDER_INVITES_ONBOARDING_KEYWORD_SKIPPED = 'SF_InvitesOnboardingKeywordSkipped',
  SPECIFINDER_LEADS_KEYWORDS_CLICKED = 'SF_LeadsKeywordClicked',
  SPECIFINDER_LEADS_ICON_CLICKED = 'SF_LeadsIconClicked',
  SPECIFINDER_LEADS_PAGE_VIEWED = 'SF_LeadsPageViewed',
  // Subbie Supplier Connection (SSC)
  SSC_PROJECT_SUBBIE_LIST_VIEWED = 'SscProjectSubbieListViewed',
  SSC_SUBBIE_PROFILE_VIEWED = 'SscSubbieProfileViewed',
  SSC_VIEW_SUBBIES_BUTTON_CLICKED = 'SscViewSubcontractorsButtonClicked',
  SSC_SHARE_AND_CONNECT_BUTTON_CLICKED = 'SscStartProjectSpecificationDetail',
  SSC_SHARE_AND_CONNECT_EDIT_BUTTON_CLICKED = 'SscStartEditProjectSpecificationDetail',
  SSC_SHARE_CONTACT_DETAILS_BUTTON_CLICKED = 'SscShareContactDetailsButtonClicked',
  SSC_SUBBIE_LIST_UNLOCK_BUTTON_CLICKED = 'SscUnlockButtonClicked',
  SSC_SUBBIE_LIST_MODAL_UNLOCK_BUTTON_CLICKED = 'SscModalUnlockButtonClicked',
  SSC_PROJECT_SUPPLIER_LIST_VIEWED = 'SscProjectSupplierListViewed',
  // Noticeboard
  PROJECT_ROW_CLICKED = 'ProjectRowClicked',
  NOTICEBOARD_SORT = 'NoticeboardSort',
  NOTICEBOARD_SEARCH = 'NoticeboardSearch',
  // Project Slider
  CONSTRUCTION_DATES_PAYWALL_CLICKED = 'ConstructionDatesPaywallClicked',
  PACKAGE_REQUESTED = 'PackageRequested',
  PROJECT_SLIDER_OPEN = 'ProjectSliderOpen',
  PROJECT_SLIDER_CLOSE = 'ProjectSliderClose',
  PROJECT_SLIDER_TAB = 'ProjectSliderTab',
  PROJECT_SLIDER_OPEN_FROM_NOTICEBOARD = 'ProjectSliderOpenFromNoticeboard', // A more detailed version of ProjectRowClicked
  TENDER_UNLOCKED = 'TenderUnlocked',
  PROJECT_SLIDER_OPEN_WITH_NO_CREDITS_LEFT = 'ProjectSliderOpenWithNoCreditsLeft',
  // Invite Slider
  INVITE_SLIDER_OTHER_BUILDERS_PAYWALL_CLICKED = 'InviteSliderOtherBuildersPaywallClicked',
  INVITE_SLIDER_EXTRA_OPEN = 'InviteSliderExtraOpen',
  // Upgrade
  UPGRADE_START = 'UpgradeStart',
  UPGRADE_OFFER = 'UpgradeOffer',
  UPGRADE_ADDONS = 'UpgradeAddons',
  UPGRADE_CHECKOUT = 'UpgradeCheckout',
  UPGRADE_COMPLETE = 'UpgradeComplete',
  UPGRADE_CHARGEBEE_LICENSE = 'UpgradeChargebeeLicense',
  // Suggest Corrections
  PROJECT_CONSULTANT_CORRECTION_OPEN = 'ProjectConsultantCorrectionOpen',
  PROJECT_CONSULTANT_CORRECTION_CLOSE = 'ProjectConsultantCorrectionClose',
  CONSULTANT_DETAILS_CORRECTION_OPEN = 'ConsultantDetailsCorrectionOpen',
  CONSULTANT_DETAILS_CORRECTION_CLOSE = 'ConsultantDetailsCorrectionClose',
  // Addons
  ADDON_PURCHASE_MODAL_SHOW = 'AddonPurchaseModalShow',
  ADDON_PURCHASE_COMPLETE = 'AddonPurchaseComplete',
  ADDON_PURCHASE_PAYMENT_DETAILS = 'AddonPurchaseModalPaymentDetails', // payment details what? There is no verb!
  // Market Insights page
  MARKET_INSIGHTS_CHART_TOOLTIP_SHOW = 'MarketInsightsChartTooltipShow',
  // Market Insights engagement banner
  MARKET_INSIGHTS_ENGAGEMENT_BANNER_CLICKED = 'MarketInsightsEngagementBannerClicked',
  MARKET_INSIGHTS_ENGAGEMENT_BANNER_DIMISSED = 'MarketInsightsEngagementBannerDismissed',
  // Specification Insights page
  SPECIFICATION_INSIGHTS_CHART_TOOLTIP_SHOW = 'SpecificationInsightsChartTooltipShow',
  EXPLORE_INSIGHTS_PROJECTS = 'ExploreInsightsProjects',
  FILTER_INSIGHTS_PROJECTS = 'FilterInsightsProjects',
  VIEW_PROJECT_SLIDER_FROM_INSIGHTS_PROJECTS = 'ViewProjectSliderFromInsightsProjects',
  SPECIFICATION_INSIGHTS_SAVE_REPORT = 'SpecificationInsightsSaveReport',
  SPECIFICATION_INSIGHTS_VIEW_SAVED_REPORT = 'SpecificationInsightsViewSavedReport',

  // Plan Select Page
  PLAN_SELECT_PAGE_FAQ_OPENED = 'PlanSelectPageFaqOpened',
  PLAN_SELECT_PAGE_TOOLTIP_ENTERED = 'PlanSelectPageTooltipEntered',
  PLAN_SELECT_PAGE_BILLING_CYCLE_SWITCH = 'PlanSelectPageBillingCycleSwitch',
  PLAN_SELECT_PAGE_STATE_PRODUCT_SWITCH = 'PlanSelectPageStateProductSwitch',

  // Subbie Profiles view
  SUBBIE_PROFILE_VIEWED = 'SubbieProfileViewed',
  // Subbie Profile Completion Click
  SUBBIE_PROFILES_COMPLETION_CLICK = 'SubbieProfilesCompletionClick',
  // Subbie Profile Insights Click
  SUBBIE_PROFILE_INSIGHTS_CLICK = 'SubbieProfileInsightsClick',
  // Specification Insights page
  SPECIFICATION_INSIGHTS_UPDATE_CHARTS = 'SpecificationInsightsUpdateCharts',
  // Subbie Complete Profile Click
  SUBBIE_COMPLETE_PROFILE_CLICK = 'CompleteProfileClicked',
  // Subbie Onboarding Form
  SUBBIE_ONBOARDING_PROFILE_ERROR = 'SubbieOnboardingProfileError',
  SUBBIE_ONBOARDING_PROFILE_SUCCESS = 'SubbieOnboardingProfileSuccess',

  SUBBIE_NETWORK_OPT_IN = 'SubbieNetworkOptIn',
  SUBBIE_NETWORK_ENROLMENT_COMPLETED = 'SubbieNetworkEnrolmentCompleted',
  SUBBIE_NETWORK_OPT_OUT = 'SubbieNetworkOptOut',

  // Directory Opt-in Modal Show
  SUBBIE_DIRECTORY_OPT_IN_MODAL_SHOW = 'SubbieDirectoryOptInModalShow',
  SUBBIE_DIRECTORY_SUBBIE_SELECT = 'SubbieDirectorySelectSubbie',
  SUBBIE_DIRECTORY_SHADOW_PROFILE_SELECT = 'SubbieDirectorySelectShadowProfile',

  // Subbie Matching Profiles
  SUBBIE_MATCHING_PROFILES_FOUND = 'SubbieMatchingProfilesFound',
  SUBBIE_MATCHING_PROFILES_FOUND_BULK = 'SubbieMatchingProfilesFoundBulk',
  SUBBIE_MATCHING_PROFILE_VIEWED = 'SubbieMatchingProfileViewed',
  SUBBIE_MATCHING_PROFILE_ACCEPTED = 'SubbieMatchingProfileAccepted',
  SUBBIE_MATCHING_PROFILE_IGNORED = 'SubbieMatchingProfileIgnored',
  SUBBIE_MATCHING_PROFILE_NEW_CONNECTION = 'SubbieMatchingProfileNewConnection',

  // Subbie Profile Completion Widget
  SUBBIE_PROFILE_COMPLETION_WIDGET_OPENED = 'SubbieProfileCompletionWidgetOpened',
  SUBBIE_PROFILE_COMPLETION_WIDGET_CLOSED = 'SubbieProfileCompletionWidgetClosed',
  SUBBIE_PROFILE_COMPLETION_WIDGET_ENCOUNTERED_ERROR = 'SubbieProfileCompletionWidgetEncounteredError',
  SUBBIE_PROFILE_COMPLETION_WIDGET_FIELD_SUBMITTED = 'SubbieProfileCompletionWidgetFieldSubmitted',
  SUBBIE_PROFILE_COMPLETION_WIDGET_FIELD_VIEWED = 'SubbieProfileCompletionWidgetFieldViewed',

  // Profile Views / Activity Feed
  SUBBIE_FEED_PROFILE_VIEWS_MODAL_SHOW = 'SubbieFeedProfileViewsModalShow',
  SUBBIE_FEED_UNACTIONED_ADDENDA_MODAL_SHOW = 'SubbieFeedUnactionedAddendaModalShow',
  SUBBIE_FEED_UNACTIONED_INVITES_MODAL_SHOW = 'SubbieFeedUnactionedInvitesModalShow',

  // Subbie Network
  SUBBIE_NETWORK_SEARCH = 'NetworkSearched',

  // Addressbook
  ADDRESSBOOK_SLIDER_TAB_CLICKED = 'AddressbookSliderTabClicked',
  ADDRESS_BOOK_CONTACT_ERROR_CLICKED = 'ContactErrorLinkClicked',

  // Add Project History Banner
  PROJECT_HISTORY_BANNER_MODAL_OPENED = 'ProjectHistoryBannerModalOpened',
  PROJECT_HISTORY_BANNER_DISMISSED = 'ProjectHistoryBannerDismissed',

  // Opt-in flows
  SUBBIE_ACCOUNT_ONBOARDING_COMPLETED = 'SubbieAccountOnboardingCompleted',
  SUBBIE_CONFIRM_DETAILS_ALERT_CLICKED = 'SubbieConfirmDetailsAlertClicked',
  SUBBIE_CONFIRM_DETAILS_BANNER_CLICKED = 'SubbieConfirmDetailsBannerClicked',
  SUBBIE_NETWORK_ENROLMENT_MODAL_OPENED = 'SubbieNetworkEnrolmentModalOpened',
  SUBBIE_NETWORK_ENROLMENT_MODAL_CLOSED = 'SubbieNetworkEnrolmentModalClosed',
  SUBBIE_NETWORK_ENROLMENT_MODAL_NEXT = 'SubbieNetworkEnrolmentModalNext',

  // Leads
  LEADS_SORTING_UPDATED = 'ProjectSortingUpdated',
  LEADS_FILTER_DRAWER_OPEN = 'LeadsFilterDrawerOpen',
  LEADS_FILTER_SUBMIT = 'LeadsFilterSubmit',
  LEADS_FILTER_RESET = 'LeadsFilterReset',
  LEADS_MOVE_TO_ARCHIVE = 'LeadsMoveToArchive',
  LEADS_MOVE_TO_ACTIVE = 'LeadsMoveToActive',
  LEADS_VIEW_OTHER_BUILDERS_BUTTON_CLICKED = 'LeadsViewOtherBuildersButtonClicked',
}
