import { addDays, subDays, differenceInCalendarDays } from 'date-fns';

export type Timings = {
  sendInvitesByDate: Date | null;
  quotesDueByDateInterval: number | null;
  quotesDueByDate: Date | null;
  letByDateInterval: number | null;
  letByDate: Date | null;
  startOnSiteDateInterval: number | null;
  startOnSiteDate: Date | null;
};

export enum Trigger {
  SEND_INVITES_BY_DATE_CHANGE = 'send_invites_by_date_change',
  QUOTES_DUE_BY_INTERVAL_CHANGE = 'quotes_due_by_interval_change',
  QUOTES_DUE_BY_DATE_CHANGE = 'quotes_due_by_date_change',
  LET_BY_INTERVAL_CHANGE = 'let_by_interval_change',
  LET_BY_DATE_CHANGE = 'let_by_date_change',
  START_ON_SITE_INTERVAL_CHANGE = 'start_on_site_interval_change',
  START_ON_SITE_DATE_CHANGE = 'start_on_site_date_change',
}

type IntervalCalculation = (state: Timings) => { newState: Timings; newTrigger: Trigger | null };

// Rules:
// 1. upstream date can not be altered indirectly, if set
// 2. prefer to change dates over intervals
// 3. look to update upstream (towards latest date) fields only if the value is changing
// 4. else, always change downstream fields to force propagate towards the earliest date
// 5. nature abhors a vacuum, if a state is cleared and it can be rebuilt do so - do not destroy other values

type DateTimings = {
  downstreamDate: Date | null;
  downstreamInterval: number | null;
  date: Date | null;
  upstreamInterval: number | null;
  upstreamDate: Date | null;
};

type DateChange = (
  state: DateTimings & {
    downstreamDateTrigger: Trigger | null;
    downstreamIntervalTrigger: Trigger | null;
    dateTrigger: Trigger;
    upstreamDateTrigger: Trigger | null;
    upstreamIntervalTrigger: Trigger | null;
  },
) => {
  newState: DateTimings;
  newTrigger: Trigger | null;
};

const handleDateChange: DateChange = (state) => {
  if (state.date) {
    if (!state.upstreamDate && state.upstreamInterval) {
      const upstreamDate = addDays(state.date, state.upstreamInterval);
      return {
        newState: { ...state, upstreamDate },
        newTrigger: state.upstreamDateTrigger,
      };
    }
    if (
      state.upstreamDate &&
      typeof state.upstreamInterval === 'number' &&
      state.upstreamInterval >= 0 &&
      state.date.getTime() > state.upstreamDate.getTime()
    ) {
      const date = subDays(state.upstreamDate, state.upstreamInterval);
      return { newState: { ...state, date }, newTrigger: null };
    }
    if (state.upstreamDate) {
      const upstreamInterval = differenceInCalendarDays(state.upstreamDate, state.date);
      if (state.upstreamInterval !== upstreamInterval) {
        return {
          newState: { ...state, upstreamInterval },
          newTrigger: state.upstreamIntervalTrigger,
        };
      }
    }
    if (state.downstreamInterval) {
      const downstreamDate = subDays(state.date, state.downstreamInterval);
      return {
        newState: { ...state, downstreamDate },
        newTrigger: state.downstreamDateTrigger,
      };
    }
    if (state.downstreamDate) {
      const downstreamInterval = differenceInCalendarDays(state.date, state.downstreamDate);
      return {
        newState: { ...state, downstreamInterval },
        newTrigger: state.downstreamIntervalTrigger,
      };
    }
  } else if (state.upstreamDate && state.upstreamInterval) {
    const date = subDays(state.upstreamDate, state.upstreamInterval);
    return { newState: { ...state, date }, newTrigger: null };
  } else if (state.downstreamDate && state.downstreamInterval) {
    const date = addDays(state.downstreamDate, state.downstreamInterval);
    return { newState: { ...state, date }, newTrigger: null };
  }

  return { newState: state, newTrigger: null };
};

const handleStartOnSiteDateChange: IntervalCalculation = (state) => {
  const updatedState = handleDateChange({
    downstreamDate: state.letByDate,
    downstreamInterval: state.startOnSiteDateInterval,
    date: state.startOnSiteDate,
    upstreamInterval: null,
    upstreamDate: null,
    downstreamDateTrigger: Trigger.LET_BY_DATE_CHANGE,
    downstreamIntervalTrigger: Trigger.START_ON_SITE_INTERVAL_CHANGE,
    dateTrigger: Trigger.START_ON_SITE_DATE_CHANGE,
    upstreamDateTrigger: null,
    upstreamIntervalTrigger: null,
  });

  return {
    newState: {
      ...state,
      letByDate: updatedState.newState.downstreamDate,
      startOnSiteDate: updatedState.newState.date,
    },
    newTrigger: updatedState.newTrigger,
  };
};

const handleLetByDateChange: IntervalCalculation = (state) => {
  const updatedState = handleDateChange({
    downstreamDate: state.quotesDueByDate,
    downstreamInterval: state.letByDateInterval,
    date: state.letByDate,
    upstreamInterval: state.startOnSiteDateInterval,
    upstreamDate: state.startOnSiteDate,
    downstreamDateTrigger: Trigger.QUOTES_DUE_BY_DATE_CHANGE,
    downstreamIntervalTrigger: Trigger.LET_BY_INTERVAL_CHANGE,
    dateTrigger: Trigger.LET_BY_DATE_CHANGE,
    upstreamDateTrigger: Trigger.START_ON_SITE_DATE_CHANGE,
    upstreamIntervalTrigger: Trigger.START_ON_SITE_INTERVAL_CHANGE,
  });

  return {
    newState: {
      ...state,
      quotesDueByDate: updatedState.newState.downstreamDate,
      letByDate: updatedState.newState.date,
      startOnSiteDateInterval: updatedState.newState.upstreamInterval,
      startOnSiteDate: updatedState.newState.upstreamDate,
    },
    newTrigger: updatedState.newTrigger,
  };
};

const handleQuotesDueByDateChange: IntervalCalculation = (state) => {
  const updatedState = handleDateChange({
    downstreamDate: state.sendInvitesByDate,
    downstreamInterval: state.quotesDueByDateInterval,
    date: state.quotesDueByDate,
    upstreamInterval: state.letByDateInterval,
    upstreamDate: state.letByDate,
    downstreamDateTrigger: Trigger.SEND_INVITES_BY_DATE_CHANGE,
    downstreamIntervalTrigger: Trigger.QUOTES_DUE_BY_INTERVAL_CHANGE,
    dateTrigger: Trigger.QUOTES_DUE_BY_DATE_CHANGE,
    upstreamDateTrigger: Trigger.LET_BY_DATE_CHANGE,
    upstreamIntervalTrigger: Trigger.LET_BY_INTERVAL_CHANGE,
  });

  return {
    newState: {
      ...state,
      sendInvitesByDate: updatedState.newState.downstreamDate,
      quotesDueByDate: updatedState.newState.date,
      letByDateInterval: updatedState.newState.upstreamInterval,
      letByDate: updatedState.newState.upstreamDate,
    },
    newTrigger: updatedState.newTrigger,
  };
};

const handleSendInvitesDueByDateChange: IntervalCalculation = (state) => {
  const updatedState = handleDateChange({
    downstreamDate: null,
    downstreamInterval: null,
    date: state.sendInvitesByDate,
    upstreamInterval: state.quotesDueByDateInterval,
    upstreamDate: state.quotesDueByDate,
    dateTrigger: Trigger.SEND_INVITES_BY_DATE_CHANGE,
    upstreamDateTrigger: Trigger.QUOTES_DUE_BY_DATE_CHANGE,
    upstreamIntervalTrigger: Trigger.QUOTES_DUE_BY_INTERVAL_CHANGE,
    downstreamDateTrigger: null,
    downstreamIntervalTrigger: null,
  });

  return {
    newState: {
      ...state,
      sendInvitesByDate: updatedState.newState.date,
      quotesDueByDateInterval: updatedState.newState.upstreamInterval,
      quotesDueByDate: updatedState.newState.upstreamDate,
    },
    newTrigger: updatedState.newTrigger,
  };
};

type IntervalTimings = {
  downstreamDate: Date | null;
  interval: number | null;
  upstreamDate: Date | null;
};

type IntervalChange = (
  state: IntervalTimings & {
    upstreamTrigger: Trigger;
    downstreamTrigger: Trigger;
  },
) => {
  newState: IntervalTimings;
  newTrigger: Trigger | null;
};

const handleIntervalChanged: IntervalChange = (state) => {
  if (state.interval !== null && state.interval >= 0) {
    if (state.downstreamDate && !state.upstreamDate) {
      const upstreamDate = addDays(state.downstreamDate, state.interval);
      return {
        newState: { ...state, upstreamDate },
        newTrigger: state.upstreamTrigger,
      };
    }
    if (state.upstreamDate) {
      const downstreamDate = subDays(state.upstreamDate, state.interval);
      return { newState: { ...state, downstreamDate }, newTrigger: state.downstreamTrigger };
    }
  } else if (state.upstreamDate && state.downstreamDate) {
    const interval = differenceInCalendarDays(state.upstreamDate, state.downstreamDate);
    return { newState: { ...state, interval }, newTrigger: null };
  }

  return { newState: state, newTrigger: null };
};

const handleStartOnSiteIntervalChange: IntervalCalculation = (state) => {
  const updatedState = handleIntervalChanged({
    downstreamDate: state.letByDate,
    interval: state.startOnSiteDateInterval,
    upstreamDate: state.startOnSiteDate,
    downstreamTrigger: Trigger.LET_BY_DATE_CHANGE,
    upstreamTrigger: Trigger.START_ON_SITE_DATE_CHANGE,
  });

  return {
    newState: {
      ...state,
      letByDate: updatedState.newState.downstreamDate,
      startOnSiteDateInterval: updatedState.newState.interval,
      startOnSiteDate: updatedState.newState.upstreamDate,
    },
    newTrigger: updatedState.newTrigger,
  };
};

const handleLetByIntervalChange: IntervalCalculation = (state) => {
  const updatedState = handleIntervalChanged({
    downstreamDate: state.quotesDueByDate,
    interval: state.letByDateInterval,
    upstreamDate: state.letByDate,
    downstreamTrigger: Trigger.QUOTES_DUE_BY_DATE_CHANGE,
    upstreamTrigger: Trigger.LET_BY_DATE_CHANGE,
  });

  return {
    newState: {
      ...state,
      quotesDueByDate: updatedState.newState.downstreamDate,
      letByDateInterval: updatedState.newState.interval,
      letByDate: updatedState.newState.upstreamDate,
    },
    newTrigger: updatedState.newTrigger,
  };
};

const handleQuotesDueByIntervalChange: IntervalCalculation = (state) => {
  const updatedState = handleIntervalChanged({
    downstreamDate: state.sendInvitesByDate,
    interval: state.quotesDueByDateInterval,
    upstreamDate: state.quotesDueByDate,
    downstreamTrigger: Trigger.SEND_INVITES_BY_DATE_CHANGE,
    upstreamTrigger: Trigger.QUOTES_DUE_BY_DATE_CHANGE,
  });

  return {
    newState: {
      ...state,
      sendInvitesByDate: updatedState.newState.downstreamDate,
      quotesDueByDateInterval: updatedState.newState.interval,
      quotesDueByDate: updatedState.newState.upstreamDate,
    },
    newTrigger: updatedState.newTrigger,
  };
};

const calculationMap = new Map<Trigger, IntervalCalculation>([
  [Trigger.SEND_INVITES_BY_DATE_CHANGE, handleSendInvitesDueByDateChange],
  [Trigger.QUOTES_DUE_BY_INTERVAL_CHANGE, handleQuotesDueByIntervalChange],
  [Trigger.QUOTES_DUE_BY_DATE_CHANGE, handleQuotesDueByDateChange],
  [Trigger.LET_BY_INTERVAL_CHANGE, handleLetByIntervalChange],
  [Trigger.LET_BY_DATE_CHANGE, handleLetByDateChange],
  [Trigger.START_ON_SITE_INTERVAL_CHANGE, handleStartOnSiteIntervalChange],
  [Trigger.START_ON_SITE_DATE_CHANGE, handleStartOnSiteDateChange],
]);

export const autoCalculateTimings = (state: Timings, trigger: Trigger | null): Timings => {
  if (trigger) {
    const calculator = calculationMap.get(trigger);
    if (calculator) {
      const { newState, newTrigger } = calculator(state);
      return autoCalculateTimings(newState, newTrigger);
    }
  }

  return state;
};
