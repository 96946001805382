import { InfoCard, ProcurementIllustration } from '@estimateone/frontend-components';

export const ProcurementInfoCard = () => (
  <InfoCard
    icon={<ProcurementIllustration />}
    heading="You are creating a Procurement Phase project"
  >
    <p>
      The project has been awarded to your company. You’re inviting subbies to quote on package
      contracts for project delivery.
    </p>
  </InfoCard>
);
