import Moment from 'moment-timezone';
import { getUserTimezone } from '@ascension/js/app/timezone';
import { GroupedPackageOption } from '../types';
import styles from './styles.scss';

export const GroupedPackageOptionLabel = ({
  label,
  isFullSet,
  quotesDueAt,
  matchesUserStockTrade,
}: GroupedPackageOption) => {
  if (quotesDueAt) {
    return (
      <div className={styles.packageOptionLabel}>
        <span>{label} *</span>
        <span className={styles.dueDate}>
          Due by {Moment.tz(quotesDueAt, getUserTimezone()).format('DD MMM YYYY')}
        </span>
      </div>
    );
  }

  if (isFullSet) {
    return (
      <span data-testid="grouped-package-option-fullset-label" className={styles.fullSetOption}>
        {label}
      </span>
    );
  }

  if (matchesUserStockTrade) {
    return <strong data-testid="grouped-package-option-trade-match-label">{label}</strong>;
  }

  return <span data-testid="grouped-package-option-default-label">{label}</span>;
};
