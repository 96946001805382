import { ChangeEvent, useEffect, useState } from 'react';
import { joinClassNames } from '@estimateone/frontend-components';
import styles from './styles.scss';

export type InlineEditableTextProps = {
  value: string | null;
  maxLength?: number;
  minLength?: number;
  shrinkSizeOnError?: boolean;
  onValueChange?: (value: string | null) => void;
  onValidate?: (value: string | null) => void;
} & JSX.IntrinsicElements['input'];

export const InlineEditableText = ({
  value,
  maxLength = 24,
  minLength = 0,
  shrinkSizeOnError = false,
  onValueChange = () => undefined,
  onValidate = () => undefined,
  className,
  ...props
}: InlineEditableTextProps) => {
  const [latestValue, setLatestValue] = useState<string | null>(value);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const isValid = errorMessage === null;
  useEffect(() => setLatestValue(value), [value]);

  return (
    <>
      <input
        className={joinClassNames(
          styles.editableText,
          className,
          !isValid ? styles.errorState : '',
          shrinkSizeOnError && !isValid ? styles.shrinkedInputField : '',
        )}
        value={latestValue || ''}
        type="text"
        placeholder="Add text…"
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setLatestValue(e.target.value);

          const newValue = e.target.value.trim();
          const isTooShort = newValue.length < minLength;
          const isTooLong = newValue.length > maxLength;

          onValidate(newValue);

          if (isTooShort) {
            setErrorMessage(
              `Must contain at least ${minLength} character${minLength === 1 ? '' : 's'}`,
            );
            return;
          }

          if (isTooLong) {
            setErrorMessage(`Max character count ${maxLength}`);
            return;
          }

          setErrorMessage(null);
          onValueChange(newValue === '' ? null : newValue);
        }}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            e.currentTarget.blur();
          }
        }}
        {...props}
      />
      {!isValid && <div className={styles.errorMessage}>{errorMessage}</div>}
    </>
  );
};
