import { ApolloError } from '@apollo/client';
import { Button, ButtonVariant, Modal, ModalSize } from '@estimateone/frontend-components';
import { FormError } from '@profiles/features/CompanyProfile/components/FormError';
import styles from './DeleteOfficeConfirmation.module.scss';

export type DeleteConfirmationProps = {
  name: string;
  field: string;
  isOpen: boolean;
  onConfirm: () => void;
  onAbort: () => void;
  error?: ApolloError;
};

export const DeleteConfirmation = ({
  name,
  field,
  isOpen,
  onAbort,
  onConfirm,
  error,
}: DeleteConfirmationProps) => {
  const handleSubmit = async () => {
    const errorsOrNull = await onConfirm();
    if (errorsOrNull === null) {
      onAbort();
    }
  };

  return (
    <Modal
      rootElement="#MFE_PROFILES_ROOT"
      isOpen={isOpen}
      onRequestClose={onAbort}
      size={ModalSize.Small}
    >
      <Modal.Section>
        {error && <FormError />}
        <h3 className={styles.header}>Delete {field}</h3>
        <p className={styles.content}>
          Are you sure you want to delete <b>{name}</b>?
        </p>
        <Button type="button" variant={ButtonVariant.Red} onClick={handleSubmit}>
          Delete
        </Button>
      </Modal.Section>
    </Modal>
  );
};
