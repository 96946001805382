import { Button, ButtonSize, ButtonVariant } from '@estimateone/frontend-components';
import styles from './RoadblockButtons.module.scss';

export const RoadblockButtonEnquire = ({ onClick }: { onClick: () => void }) => (
  <div className={styles.buttonContainer}>
    <Button
      type="button"
      variant={ButtonVariant.Primary}
      onClick={onClick}
      fullWidth
      size={ButtonSize.Large}
    >
      Enquire now
    </Button>
  </div>
);

export const RoadblockButtonLiteToEssentialUpgrade = ({ onClick }: { onClick: () => void }) => (
  <div className={styles.buttonContainer}>
    <Button
      type="button"
      variant={ButtonVariant.Electric}
      onClick={onClick}
      fullWidth
      size={ButtonSize.Large}
    >
      Upgrade for unlimited awarded project access
    </Button>
  </div>
);

export const RoadblockButtonUpgrade = ({ href }: { href: string }) => (
  <div className={styles.buttonContainer}>
    <Button
      type="button"
      variant={ButtonVariant.Primary}
      href={href}
      fullWidth
      size={ButtonSize.Large}
    >
      See pricing and plans
    </Button>
  </div>
);
