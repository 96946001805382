import { createContext, useContext } from 'react';
import { ScopeRfqStatusObject } from '@builder/features/ProcurementLettingSchedule/PackageDashboard/hooks/useGetScopeOfWorks';
import { Scope_scopeByPackage as Scope } from '../types/Scope';

export type ScopeOfWorksContextType = {
  scope: Scope | null;
  status: ScopeRfqStatusObject | null;
  loading: boolean;
  refetch: () => void;
  scopesUiUrl: string | null;
};

const ScopeOfWorksContext = createContext<ScopeOfWorksContextType>({
  scope: null,
  status: null,
  loading: false,
  refetch: () => undefined,
  scopesUiUrl: null,
});

export const ScopeOfWorksProvider = ScopeOfWorksContext.Provider;

export const useScopeOfWorks = () => {
  const scopeOfWorks = useContext(ScopeOfWorksContext);
  return scopeOfWorks;
};
