import { useQuery } from '@apollo/client';
import SubbieProfilesSliderLayout from '../SubbieProfilesSliderLayout';
import LoadingSpinnerFloating from '../../../../../shared/LoadingSpinner/LoadingSpinnerFloating';
import { SUBBIE_PROFILE_FOR_ACCOUNT } from '@builder/pages/Profiles/SubbieProfilesPage/queries';
import { EntityId } from '@ascension/types';
import {
  SubbieProfileForAccount,
  SubbieProfileForAccountVariables,
} from '@builder/pages/Profiles/SubbieProfilesPage/types/SubbieProfileForAccount';

type SubbieProfilesSliderViewProps = {
  accountId: EntityId;
  profilesAscensionUrl: string;
};

const SubbieProfilesSliderView = ({
  accountId,
  profilesAscensionUrl,
}: SubbieProfilesSliderViewProps) => {
  const { data } = useQuery<SubbieProfileForAccount, SubbieProfileForAccountVariables>(
    SUBBIE_PROFILE_FOR_ACCOUNT,
    {
      variables: { accountId },
    },
  );

  if (!data) return <LoadingSpinnerFloating />;

  return (
    <SubbieProfilesSliderLayout
      profile={data.subbieProfileForAccount}
      profilesAscensionUrl={profilesAscensionUrl}
    />
  );
};

export default SubbieProfilesSliderView;
