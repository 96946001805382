import { InfoCard } from '@estimateone/frontend-components';
import styles from './styles.scss';

export const ConnectionPreferenceInfoCard = () => (
  <InfoCard
    // eslint-disable-next-line react/jsx-no-useless-fragment
    icon={<></>}
    heading="Who can view these contact details?"
    className={styles.connectionPreferenceInfoCard}
  >
    <ul>
      <li>
        Your contact information can be viewed by subcontractors who are interested in this project
        and have the same trades listed on their accounts as you.
      </li>
      <li>You can track the subcontractors who have viewed your details.</li>
    </ul>
  </InfoCard>
);
