import { ReactNode, useState } from 'react';
import { E1Link, Modal, ModalSize } from '@estimateone/frontend-components';
import { EditCoreTradesForm } from '@profiles/features/CompanyProfile/components/Trades/EditTrades/EditCoreTradesForm/EditCoreTradesForm';
import useModalState from '@profiles/features/CompanyProfile/components/Trades/EditTrades/useModalState';
import { ProfileStockTrade } from '@profiles/features/CompanyProfile/components/Trades/Trades';
import { sendAppcuesEditProfileEvent } from '@profiles/features/CompanyProfile/utils/appcues';
import { KnowledgeBaseLocale } from '@profiles/providers/TranslationsProvider';
import { EditTradesForm } from './EditTradesForm/EditTradesForm';
import { useFlashMessage } from '@shared';
import { TradeFormServiceType } from '@profiles/features/CompanyProfile/components/Trades/hooks/useTradeFormService';
import { UpdateTradesMutation } from '@profiles/features/CompanyProfile/components/Trades/mutations.generated';
import {
  EditCoreTradesFieldValues,
  EditTradesFieldValues,
} from '@profiles/features/CompanyProfile/components/Trades/types';

const ALLOW_MODAL_OVERFLOW = {
  style: {
    content: {
      overflow: 'visible',
    },
  },
};

type EditTradeModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  children: ReactNode;
};
const EditTradeModal = ({ isOpen, onRequestClose, children }: EditTradeModalProps) => (
  <Modal
    rootElement="#MFE_PROFILES_ROOT"
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    size={ModalSize.Small}
    reactModalProps={ALLOW_MODAL_OVERFLOW}
  >
    <Modal.Section>{children}</Modal.Section>
  </Modal>
);

type TradesProps = {
  trades: ProfileStockTrade[];
  tradeFormService: TradeFormServiceType;
  knowledgeBaseLocale: KnowledgeBaseLocale;
};

type StockTrade = UpdateTradesMutation['changeStockTrades'][number];

export const EditTrades = ({ trades, tradeFormService, knowledgeBaseLocale }: TradesProps) => {
  const [stockTrades, setStockTrades] = useState<StockTrade[]>([]);
  const [coreTradeIds, setCoreTradeIds] = useState<number[]>([]);
  const { submitTradeForm, tradeFormInitError, submitCoreTradeForm, coreTradeFormInitError } =
    tradeFormService;
  const {
    modalState,
    handleOpenEditTrades,
    handleCloseEditTrades,
    handleOpenCoreTradesModal,
    handleCloseCoreTradesModal,
    handleBackToEditTrades,
  } = useModalState();
  const { success: successMessage } = useFlashMessage();

  const {
    loading: allTradesLoading,
    data: allTrades,
    hasError: allTradesQueryHasError,
  } = tradeFormService.getAllStockTrades();
  if (allTradesLoading || allTradesQueryHasError) {
    return null;
  }

  const showSuccessfulTradesSubmitMessage = () => {
    successMessage({
      title: 'Success!',
      message: 'Trades saved',
    });
  };

  const extractCoreIds = (
    updatedStockTrades: StockTrade[],
    tradesForCoreTradeIdExtraction: ProfileStockTrade[],
  ) =>
    updatedStockTrades
      .filter((stockTrade) =>
        tradesForCoreTradeIdExtraction.some(
          (trade) => trade.isCoreTrade && trade.id === stockTrade.id,
        ),
      )
      .map((stockTrade) => stockTrade.id);

  const onTradesSubmit = async (values: EditTradesFieldValues) => {
    const { hasErrors, updatedStockTrades } = await submitTradeForm(values);

    if (hasErrors) {
      return false;
    }

    const coreIds = extractCoreIds(updatedStockTrades, trades);
    setStockTrades(updatedStockTrades);
    setCoreTradeIds(coreIds);
    handleOpenCoreTradesModal();

    return true;
  };

  const onCoreTradesSubmit = async (values: EditCoreTradesFieldValues) => {
    const { hasErrors } = await submitCoreTradeForm(values);
    if (hasErrors) {
      return false;
    }
    showSuccessfulTradesSubmitMessage();
    handleCloseCoreTradesModal();

    sendAppcuesEditProfileEvent();

    return true;
  };

  return (
    <div id="EDIT-TRADES-OVERRIDE">
      <EditTradeModal isOpen={modalState.editTrades} onRequestClose={handleCloseEditTrades}>
        <EditTradesForm
          tradeIds={trades.map((trade) => trade.id)}
          tradesOptions={allTrades}
          close={handleCloseEditTrades}
          onTradesSubmit={onTradesSubmit}
          formInitError={tradeFormInitError}
        />
      </EditTradeModal>

      <EditTradeModal
        isOpen={modalState.editCoreTrades}
        onRequestClose={handleCloseCoreTradesModal}
      >
        <EditCoreTradesForm
          coreTrades={coreTradeIds}
          coreTradesOptions={stockTrades}
          backToEditTrades={handleBackToEditTrades}
          onCoreTradesSubmit={onCoreTradesSubmit}
          formInitError={coreTradeFormInitError}
          knowledgeBaseLocale={knowledgeBaseLocale}
        />
      </EditTradeModal>
      <E1Link onClick={handleOpenEditTrades}>Edit</E1Link>
    </div>
  );
};
