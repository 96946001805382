import Routing from 'routing';
import { Alert, AlertVariant, E1Link, joinClassNames } from '@estimateone/frontend-components';
import E1Request from '@ascension/js/classes/E1Request';
import { EntityId } from '@ascension/types';
import styles from './styles.module.scss';

export type PendingAddendumBannerProps = {
  stageId: EntityId;
  existingAddendumId: EntityId | null;
  existingAddendumName: string | null;
  onDismiss: () => void;
};

export const DocumentAddendumBanner = ({
  stageId,
  existingAddendumId,
  existingAddendumName,
  onDismiss,
}: PendingAddendumBannerProps) => (
  <div className={joinClassNames(styles.addendumBanner, 'flex-grow-1')}>
    {existingAddendumId !== null ? (
      <Alert variant={AlertVariant.Yellow} onDismiss={onDismiss}>
        <p>
          <E1Link
            href={Routing.generate('app_stageaddendum_documents', { id: existingAddendumId })}
          >
            There is currently a pending addendum, {existingAddendumName}.
          </E1Link>{' '}
          You should really complete it before continuing.
        </p>
      </Alert>
    ) : (
      <Alert variant={AlertVariant.Blue} onDismiss={onDismiss}>
        <p>
          Need to process an addendum? We highly recommend the{' '}
          <E1Link
            onClick={async () => {
              const route = Routing.generate('app_stageaddendum_newmodal', { stage_id: stageId });
              const request = new E1Request(route);
              request.setShowLoadingModal();
              await request.submit();
            }}
          >
            Addendum Wizard
          </E1Link>
          .
        </p>
      </Alert>
    )}
  </div>
);
