import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { PACKAGE_AWARDEE_CANDIDATES } from '../queries';
import {
  GetAwardeesForPackage,
  GetAwardeesForPackageVariables,
  GetAwardeesForPackage_package_activeRfqs as ActiveRfqs,
  GetAwardeesForPackage_stage_UnrestrictedStage as StageDetails,
} from '../types/GetAwardeesForPackage';
import { RfqQuotingStatus } from '@ascension/_gqltypes/builder.generated';
import { EntityId } from '@ascension/types';

type UseGetAwardeesForPackage = {
  loading: boolean;
  haveAllDeclined: boolean;
  activeRfqs: ActiveRfqs[];
  stageDetails: StageDetails;
};

const sortByCompanyName = (rfqA: ActiveRfqs, rfqB: ActiveRfqs) => {
  const companyA = rfqA?.contact?.company?.name;
  const companyB = rfqB?.contact?.company?.name;
  if (companyA && companyB) {
    return companyA.localeCompare(companyB);
  }
  if (companyA && !companyB) {
    return -1;
  }
  if (!companyA && companyB) {
    return 1;
  }
  return 0;
};

export const useGetAwardeesForPackage = (
  packageId: EntityId,
  stageId: EntityId,
): Omit<UseGetAwardeesForPackage, 'stageDetails'> & { stageDetails: StageDetails | undefined } => {
  const { data, loading } = useQuery<GetAwardeesForPackage, GetAwardeesForPackageVariables>(
    PACKAGE_AWARDEE_CANDIDATES,
    {
      variables: { packageId, stageId },
    },
  );

  const activeRfqs = data?.package.activeRfqs ?? [];
  const stageDetails = data?.stage;

  const { haveAllDeclined, filteredRfqs } = useMemo(() => {
    const filteredSortedRfqs = activeRfqs
      .filter((rfq) => rfq.quotingStatus !== RfqQuotingStatus.DECLINED)
      .sort(sortByCompanyName);

    return {
      haveAllDeclined: activeRfqs.length > 0 && filteredSortedRfqs.length === 0,
      filteredRfqs: filteredSortedRfqs,
    };
  }, [activeRfqs]);

  return {
    haveAllDeclined,
    loading,
    activeRfqs: filteredRfqs,
    stageDetails: stageDetails?.__typename === 'UnrestrictedStage' ? stageDetails : undefined,
  };
};
