import { gql } from '@apollo/client';

export const GET_PROJECT_INTERESTED_SUBBIES = gql`
  query SubbieListProjectInterestedSubbies($projectId: EntityId!) {
    projectInterestedSubbies(projectId: $projectId) {
      id
      accountId
      name
      mainLocation {
        id
        accountOfficeId
        suburb
        state
      }
      numberOfOtherLocations
      stockTrades {
        id
        name
      }
      viewedAt
      lastActiveAt
    }
  }
`;
