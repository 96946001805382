import { CurrencySymbol } from '@estimateone/frontend-components';
import Currency from '@ascension/js/classes/Currency';
import {
  CreditCardContent,
  DiscontinuedTag,
  DiscountTag,
  LicensePaidForByLine,
  PriceContent,
  UpgradeLine,
} from './LicenseRowCellContent';
import { EntityId } from '@ascension/types';
import styles from '../shared/styles.scss';

const getCurrencySymbol = (symbol: string): CurrencySymbol =>
  Object.values(CurrencySymbol).find((value) => value === symbol) ?? CurrencySymbol.Dollar;

type LicenseRowProps = {
  productName: string;
  hasAdditionalLicenseDiscount: boolean;
  licenseCurrency: string;
  licensePrice: number;
  productPrice: number;
  billingCycle: string;
  activatedAt: string;
  renewDate: string;
  creditCardLastFourDigitsText: string;
  isDiscountActive: boolean;
  discountExpiryDescriptionWithTax?: string;
  upgradeProductId?: EntityId;
  isAdditionalState: boolean;
  discountAdjustedAmount: number;
  creditCardId?: EntityId;
  paidLicenseProviderName?: string;
  sharedLicenseAccountName?: string;
  isDiscontinued: boolean;
};

const LicenseRow = ({
  productName,
  hasAdditionalLicenseDiscount,
  licenseCurrency,
  licensePrice,
  productPrice,
  billingCycle,
  activatedAt,
  renewDate,
  creditCardLastFourDigitsText,
  isDiscountActive,
  discountExpiryDescriptionWithTax,
  upgradeProductId,
  isAdditionalState,
  discountAdjustedAmount,
  creditCardId,
  paidLicenseProviderName,
  sharedLicenseAccountName,
  isDiscontinued,
}: LicenseRowProps) => {
  const hasDiscount = hasAdditionalLicenseDiscount || isDiscountActive;
  const discountTagText = hasAdditionalLicenseDiscount ? 'Add. state discount' : 'Discounted';
  const currency = new Currency(0, licenseCurrency);
  const currencySymbol = getCurrencySymbol(currency.getSymbol() ?? '$');

  return (
    <tr>
      <td>
        <div>
          <span className={styles.productNameSpan}>{productName}</span>
          {sharedLicenseAccountName && <span>{sharedLicenseAccountName}</span>}
          {hasDiscount && <DiscountTag tagText={discountTagText} />}
          {isDiscontinued && <DiscontinuedTag />}
        </div>
        {paidLicenseProviderName && (
          <LicensePaidForByLine paidLicenseProviderName={paidLicenseProviderName} />
        )}
        {discountExpiryDescriptionWithTax && <div>{discountExpiryDescriptionWithTax}</div>}
        {upgradeProductId && <UpgradeLine upgradeProductId={upgradeProductId} />}
      </td>
      <td>
        <PriceContent
          discountAdjustedAmount={discountAdjustedAmount}
          isAdditionalState={isAdditionalState}
          isDiscountActive={isDiscountActive}
          licensePrice={licensePrice}
          productPrice={productPrice}
          currencySymbol={currencySymbol}
        />
      </td>
      <td>{billingCycle}</td>
      <td>{activatedAt}</td>
      <td>{renewDate || '-'}</td>
      <td>
        {creditCardId ? (
          <CreditCardContent
            creditCardId={creditCardId}
            lastFourDigits={creditCardLastFourDigitsText}
          />
        ) : (
          '-'
        )}
      </td>
    </tr>
  );
};

export default LicenseRow;
