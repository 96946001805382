import { gql } from '@apollo/client';

export const CHANGE_PRIMARY_CONTACT = gql`
  mutation ChangePrimaryContact($userId: EntityId!) {
    changePrimaryContact(userId: $userId) {
      id
      preferredContact {
        id
        fullName
        firstName
        lastName
        email
        phone
      }
    }
  }
`;

export const HIDE_PROFILE_CONTACT = gql`
  mutation HideProfileContact($userId: EntityId!) {
    hideProfileContact(userId: $userId) {
      id
      ... on Profile {
        id
        people {
          id
          ascensionId
          fullName
          firstName
          lastName
          isDefaultContact
          isVisible
          contactable {
            id
            email
            phone
          }
        }
      }
    }
  }
`;
