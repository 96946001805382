import { gql } from '@apollo/client';

export const LicensesTableResult = gql`
  fragment LicensesTableResult on License {
    id
    account {
      name
    }
    billingCycle
    activatedAt
    expiredAt
    isAutoRenew
    isDiscontinued
    canRenew
    discount {
      adjustedAmount
      isActive
      expiryDescriptionWithTax
    }
    price {
      amount
      currency {
        code
      }
    }
    creditCard {
      id
      number
      isExpired
    }
    product {
      id
      name
      tag
      pricingOptions {
        billingCycle
        price {
          amount
        }
      }
    }
  }
`;
