import { Modal, ModalSize, ProgressBar } from '@estimateone/frontend-components';
import { GetDocumentIntegrationSyncProgress_documentIntegrationSyncProgress as SyncProgress } from '../hooks/types/GetDocumentIntegrationSyncProgress';
import styles from './styles.scss';

interface DocumentIntegrationSyncProgressProps {
  taskCount: SyncProgress['taskCount'];
  tasksCompleted: SyncProgress['tasksCompleted'];
  isProgressModalOpen: boolean;
}

export const DocumentIntegrationSyncProgress = ({
  taskCount,
  tasksCompleted,
  isProgressModalOpen,
}: DocumentIntegrationSyncProgressProps) => (
  <Modal size={ModalSize.Small} isOpen={isProgressModalOpen} hideClose onRequestClose={() => null}>
    <Modal.Section>
      <div className={styles.progressContainer}>
        <h3 className={styles.progressHeading}>Importing Files…</h3>
        <ProgressBar
          text={
            taskCount < 0 ? 'Retrieving file information...' : `${tasksCompleted} of ${taskCount}`
          }
          percentage={Math.floor((tasksCompleted / taskCount) * 100)}
        />
        <div className={styles.progressInfo}>
          This may take several minutes. The page will refresh when all files are imported. You can
          close this window without losing progress.
        </div>
      </div>
    </Modal.Section>
  </Modal>
);
