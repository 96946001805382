import $ from 'jquery';
import Routing from 'routing';
import { escape } from 'lodash';
import E1Request from '../classes/E1Request';
import GenericList from '../classes/GenericList';
import { truncate } from '../utils/truncate';
import { getFirstName, getLastName, getPhone } from './contact_util';
import { AccountTypeValue } from '../../enums';
import { AccountType } from '../../_gqltypes/subbie.generated';

// Stage - Requests specific
$(() => {
  const $tables = $('table.package-requests-dataTable');
  $.each($tables, function () {
    const $table = $(this);
    const stageId = $table.attr('data-stage');
    const channel = $table.attr('data-channel');
    const profileIconPath = $table.attr('data-profile-icon-path');
    const tooltipProfileTag = $table.attr('data-profile-tag-tooltip');

    const $typeModifier = $table.closest('.section').find('.request-action');
    const type = $typeModifier.val();

    const route = Routing.generate('app_stagerfq_fetchactiverequests', {
      id: stageId,
      channel,
      type,
    });

    const requestsDataTable = new GenericList(
      $table,
      (list) => {
        list.table = list.$target.DataTable({
          paging: false,
          data: list.data,
          info: false,
          order: [[0, 'asc']],
          columns: [
            {
              data: 'companyName',
              render(data, display, request) {
                const $container = $('<div>');
                const $link = $('<a />');
                $link
                  .text(data)
                  .addClass('link vcard')
                  .attr('role', 'button')
                  .attr('data-stage-id', stageId);
                const userId = request.user?.id;
                const accountId = request.user?.account?.id;
                const accountType = request.user?.account?.type;

                const accountIsSubcontractor =
                  accountType === AccountTypeValue[AccountType.SUBCONTRACTOR];

                if (request.contact) {
                  // Address Book
                  $link.attr('data-company-id', request.contact.company.id);
                } else if (request.user) {
                  $link.attr('data-account-id', accountId).attr('data-user-id', userId);
                }
                $container.append($link);

                const nonAddressBookProfile =
                  !request.contact && userId && accountId && accountIsSubcontractor;

                if (nonAddressBookProfile) {
                  const $profileTag = $('<br/><div class="tag profile-tag">').html(
                    `<img src="${profileIconPath}" alt="profile tag"/>Profile</div>`,
                  );
                  $profileTag.attr('title', tooltipProfileTag);
                  $container.append($profileTag);
                  $link.attr('data-company-profile-exists', 'true');
                }

                return $container.prop('outerHTML');
              },
            },
            {
              data: null,
              render(data, display, request) {
                const firstName = request.firstName || '';
                const lastName = request.lastName || '';
                const fullName = `${firstName} ${lastName}`;

                const $link = $('<a />')
                  .text(fullName)
                  .addClass('link vcard')
                  .attr('role', 'button')
                  .attr('data-stage-id', stageId);

                if (request.contact) {
                  // Address Book
                  $link.attr('data-company-id', request.contact.company.id);
                } else if (request.user) {
                  $link
                    .attr('data-account-id', request.user.account.id)
                    .attr('data-user-id', request.user.id);
                  if (request.user?.account?.type === AccountTypeValue[AccountType.SUBCONTRACTOR]) {
                    $link.attr('data-company-profile-exists', 'true');
                  }
                }

                return $link.prop('outerHTML');
              },
            },
            {
              data: 'phone',
              render: $.fn.dataTable.render.text(),
            },
            {
              data: null,
              render(data, display, _, details) {
                switch (display) {
                  case 'filter':
                  case 'sort':
                    return data.package.title;
                  default:
                    if (data.package) {
                      if (data.approvedAt || data.declinedAt) {
                        return escape(truncate(data.package.title, 70));
                      }

                      const $template = $(list.$placeholderRow.find('td').get(details.col)).clone();

                      const $select = $template.find('select');
                      $select.find(`option[value=${data.package.id}]`).attr('selected', 'selected');
                      return $template.html();
                    }
                    return '-';
                }
              },
            },
            {
              data: null,
              orderable: false,
              render(data, display, _, details) {
                if (data.approvedAt || data.declinedAt) {
                  const $content = $('<div>');

                  if (data.approvedAt) {
                    $content.append($('<span>Approved</span>'));
                  } else {
                    $content.append($('<span>Declined</span>'));
                  }
                  return $content.prop('outerHTML');
                }

                const $template = $(list.$placeholderRow.find('td').get(details.col)).clone();
                $template.find('.action-request').attr('data-request-id', data.id);
                return $template.prop('outerHTML');
              },
            },
          ],
          dom: 'Rrtp',
        });
        list.toggleTableDisplay(true);
        list.$target.closest('.loading-container').addClass('has-loaded');
        list.table.column(5).search(2).draw();
      },
      route,
      null,
      (self, data) => {
        const result = data;
        result.companyName = null;
        result.phone = null;
        result.firstName = null;
        result.lastName = null;

        if (data.contact) {
          const { company } = data.contact;
          result.firstName = getFirstName(data.contact);
          result.lastName = getLastName(data.contact);
          result.companyName = company.name;
          result.phone = getPhone(data.contact) || company.phone;
        } else {
          const { account } = data.user;
          const defaultOffice = account.default_office;
          const userOffice = data.user.office;
          result.firstName = data.user.firstName;
          result.lastName = data.user.lastName;
          result.companyName = account.name;
          result.phone = userOffice ? userOffice.phone : defaultOffice.phone;
        }

        return result;
      },
    );

    async function processRequest(genericList, id, rfqId, action, packageId, reason = null) {
      const decisionRoute = Routing.generate('app_stagerfq_decision', {
        id,
        rfqId,
        packageId,
      });

      await new E1Request(decisionRoute, 'POST', {
        decision: action ? 1 : 0,
        reason,
      }).submit();

      genericList.updateTable(true);
    }

    $table.on('click', '.action-request', async (e) => {
      const $target = $(e.currentTarget);
      const requestId = $target.attr('data-request-id');
      const action = $target.hasClass('approve-request');
      const reason = action ? null : $target.data('declined-reason');
      const packageId = $target.closest('tr').find('select.package-request-change').val();
      if (requestId && packageId) {
        await processRequest(requestsDataTable, stageId, requestId, action, packageId, reason);
      }
    });

    $typeModifier.on('change', (e) => {
      const changeType = $(e.currentTarget).val();

      requestsDataTable.fetchUrl = Routing.generate('app_stagerfq_fetchactiverequests', {
        id: stageId,
        channel,
        type: changeType,
      });

      requestsDataTable.updateTable(true);
    });
  });
});
