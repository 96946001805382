import { useCallback } from 'react';
import { Form } from 'react-final-form';
import Routing from 'routing';
import {
  Button,
  ButtonVariant,
  Modal,
  ModalSize,
  RadioButton,
} from '@estimateone/frontend-components';
import E1Request from '@ascension/js/classes/E1Request';
import { reportError } from '@ascension/components/helpers/errorReporter';
import { TextInputField } from '@shared/FinalForm/Field';
import { RadioButtonGroupField } from '@shared/FinalForm/Field/RadioButtonGroupField';
import { useFlashMessage } from '@shared/Util';
import {
  useCloseRemoveNetworkConnectionModal,
  useRemoveNetworkConnectionModalContext,
} from './hooks';
import {
  RemoveNetworkConnectionFormValues,
  DisconnectedReasonFieldName,
  RemoveNetworkConnectionFormFields,
  RemoveNetworkConnectionFormErrors,
  RemoveConnectionFormId,
  reasonOptionsMap,
} from './types';
import { EntityId } from '@ascension/types';
import styles from './styles.module.scss';

type RemoveNetworkConnectionModalProps = {
  onSuccess: (data: { connectedCompanyId: EntityId }) => void;
};

const removeConnectionToNetworkRequest = (
  addressBookCompanyId: number,
  reason: DisconnectedReasonFieldName,
  otherExplanation?: string,
) => {
  const route = Routing.generate('app_addressbookcompany_removenetworkcompanyconnection', {
    id: addressBookCompanyId,
  });

  // E1Request will show the success flash message
  // Set E1Request `passive` param to true to handle error flash messages ourselves
  return new E1Request<{ success: boolean }>(
    route,
    'PATCH',
    { reason, otherExplanation },
    true,
  ).submit();
};

export const RemoveNetworkConnectionModal = ({ onSuccess }: RemoveNetworkConnectionModalProps) => {
  const { data, open } = useRemoveNetworkConnectionModalContext();
  const { closeModal } = useCloseRemoveNetworkConnectionModal();
  const { warning: showErrorMessage } = useFlashMessage();

  const removeConnectionToNetwork = useCallback(
    async (
      addressBookCompanyId: EntityId,
      reason: DisconnectedReasonFieldName,
      otherExplanation?: string,
    ) => {
      try {
        const result = await removeConnectionToNetworkRequest(
          addressBookCompanyId,
          reason,
          otherExplanation,
        );

        if (!result.success) {
          throw new Error('Failed to remove connection to network company');
        }

        onSuccess({
          connectedCompanyId: addressBookCompanyId,
        });
        closeModal();
      } catch (error) {
        reportError(error as Error);
        showErrorMessage({
          title: 'Error',
          message: 'Something went wrong when trying to remove connection. Please try again.',
        });
      }
    },
    [closeModal, onSuccess, showErrorMessage],
  );

  if (!data?.addressbookCompanyId) {
    return null;
  }

  const handleFormSubmit = async (values: RemoveNetworkConnectionFormValues) => {
    const { reason, otherExplanation } = values;
    await removeConnectionToNetwork(data.addressbookCompanyId, reason, otherExplanation);
  };

  const validateForm = (
    values: RemoveNetworkConnectionFormValues,
  ): RemoveNetworkConnectionFormErrors => {
    if (!values.reason) {
      return {
        [RemoveNetworkConnectionFormFields.Reason]: ['Please select a reason'],
      };
    }
    if (values.reason === DisconnectedReasonFieldName.OTHER) {
      const otherExplanation = values.otherExplanation?.trim();

      if (!otherExplanation) {
        return {
          [RemoveNetworkConnectionFormFields.OtherExplanation]: [
            'Specify a reason to remove Connection',
          ],
        };
      }
      if (otherExplanation.length < 3) {
        return {
          [RemoveNetworkConnectionFormFields.OtherExplanation]: [
            'Reason must be more than 2 characters',
          ],
        };
      }
    }

    return {};
  };

  return (
    <Modal isOpen={open} onRequestClose={closeModal} size={ModalSize.Small}>
      <Modal.Section>
        <div className={styles.modalSection}>
          <div className={styles.modalContent}>
            <div className={styles.modalHeader}>
              Are you sure you want to remove the Connection?
            </div>
            <div className={styles.textContainer}>
              By removing the E1 Network connection access to information provided by the company
              will be lost.
            </div>
            <Form<RemoveNetworkConnectionFormValues>
              onSubmit={handleFormSubmit}
              validate={validateForm}
            >
              {({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit} id={RemoveConnectionFormId}>
                  <RadioButtonGroupField
                    fieldName={RemoveNetworkConnectionFormFields.Reason}
                    legend="Select a Reason"
                    isRequired
                    className={styles.modalRadioButtonGroup}
                  >
                    {reasonOptionsMap.map(({ id, label }) => (
                      <RadioButton className={styles.modalRadioButton} key={id} id={id} value={id}>
                        {label}
                      </RadioButton>
                    ))}
                  </RadioButtonGroupField>
                  {values.reason === DisconnectedReasonFieldName.OTHER && (
                    <div className={styles.modalInputRow}>
                      <TextInputField
                        fieldName={RemoveNetworkConnectionFormFields.OtherExplanation}
                        id=""
                        label="Please specify reason"
                        hideLabel
                        maxLength={255}
                        placeholder="Please specify..."
                      />
                    </div>
                  )}
                </form>
              )}
            </Form>
          </div>
          <div className={styles.removeConnectionProfileButtons}>
            <Button variant={ButtonVariant.Grey} onClick={closeModal} fullWidth>
              Cancel
            </Button>
            <Button
              variant={ButtonVariant.Red}
              type="submit"
              fullWidth
              form={RemoveConnectionFormId}
            >
              Remove Connection
            </Button>
          </div>
        </div>
      </Modal.Section>
    </Modal>
  );
};
