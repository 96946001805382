import { DrawerBody, DrawerFooter, DrawerToolbar } from '@estimateone/frontend-components';
import { OperatingCountryProvider } from '@ascension/components/localisation';
import { OperatingCountry } from '@ascension/components/localisation/operatingCountry';
import { DrawerFooterButtons } from '@profiles/features/CompanyProfile/modules/Drawer/DrawerFooterButtons';
import { sendAppcuesEditProfileEvent } from '@profiles/features/CompanyProfile/utils/appcues';
import { DrawerWrappedComponentProps, withDrawerRoute } from '../../withDrawerRoute';
import { OfficeDetailsForm } from '../OfficeDetailsForm';
import styles from './AddOfficeDetailsDrawer.module.scss';
import { useSubmitForm } from './hooks';
import { OfficeDetailsFieldValues } from '../OfficeDetailsFields/types';

export const AddOfficeDetailsDrawer = withDrawerRoute(
  ({ onClose, profile }: DrawerWrappedComponentProps) => {
    const { submit, errors } = useSubmitForm();
    const formId = 'office-details-form-id';

    const onSubmit = async (values: OfficeDetailsFieldValues) => {
      const success = await submit(values);
      if (success) {
        onClose();
      }

      sendAppcuesEditProfileEvent();
    };

    const countryId = profile.account?.primaryCountry?.id ?? OperatingCountry.AU;

    return (
      <>
        <DrawerToolbar />
        <DrawerBody>
          <OperatingCountryProvider countryId={countryId}>
            <h2 className={styles.formHeader}>Add Office</h2>
            <OfficeDetailsForm formId={formId} onSubmit={onSubmit} errors={errors} />
          </OperatingCountryProvider>
        </DrawerBody>
        <DrawerFooter>
          <DrawerFooterButtons formId={formId} close={onClose} />
        </DrawerFooter>
      </>
    );
  },
);
