import { StylesConfig } from 'react-select';

const reactSelectStyles: StylesConfig = {
  control: (base) => ({
    ...base,
    borderRadius: '4px !important',
    border: '2px solid #E6E6E6 !important',
  }),
  singleValue: (base) => ({
    ...base,
    color: '#4D4D4D',
  }),
  indicatorSeparator: (base) => ({ ...base, display: 'none' }),
  dropdownIndicator: (base) => ({ ...base, color: '#00C389' }),
  menu: (base) => ({
    ...base,
    zIndex: 2,
    overflowY: 'scroll',
    maxHeight: '10rem',
  }),
  option: (base, { isFocused, isSelected }) => ({
    ...base,
    backgroundColor: isFocused ? '#E9FBF6 !important' : 'white !important',
    color: isSelected || isFocused ? '#141B4D' : '#666666',
    fontWeight: isSelected ? 600 : 400,
  }),
};

export default reactSelectStyles;
