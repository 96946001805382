import { gql } from '@apollo/client';

export const GET_DOCUMENT_INTEGRATION_SYNC_PROGRESS = gql`
  query GetDocumentIntegrationSyncProgress($stageId: EntityId!) {
    documentIntegrationSyncProgress(stageId: $stageId) {
      taskCount
      tasksCompleted
    }
  }
`;

export const GET_DOCSYNC_LAST_SYNCED_AT = gql`
  query GetDocsyncLastSyncedAt($stageId: EntityId!) {
    getDocSyncMap(stageId: $stageId) {
      ... on DocSyncMap {
        lastSyncedAt
      }
      ... on Errors {
        errors {
          message
        }
      }
    }
  }
`;

export const GET_DOCSYNC_STAGE = gql`
  query GetDocSyncStage($stageId: EntityId!) {
    stage(id: $stageId) {
      ... on UnrestrictedStage {
        id
        name
        activeDocumentCount
      }
    }
    getDocumentIntegration(stageId: $stageId) {
      id
      type
    }
  }
`;

export const GET_DOCSYNC_MAP_FOR_MODIFICATION = gql`
  query GetDocSyncMapForModification($stageId: EntityId!) {
    getDocSyncMapForModification(stageId: $stageId) {
      siteId
      siteName
      driveId
      baseFolder {
        id
        name
        path
      }
      driveItems {
        id
        isSelected
        name
        type
      }
    }
  }
`;
