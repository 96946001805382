import { useContext, useState } from 'react';
import ContactListSelector, {
  ContactListSelectorOption,
} from '@builder/features/SubbieNetworkInvitations/components/ContactListSelector/ContactListSelector';
import { CounterButton } from '@builder/features/SubbieNetworkInvitations/components/CounterButton';
import { TenderWizardSubNav } from '@builder/features/SubbieNetworkInvitations/components/TenderWizardSubNav';
import { UnifiedInvites } from '@builder/features/SubbieNetworkInvitations/entrypoints/UnifiedInvites';
import { ContactListsFeatureFlagContext } from '@builder/features/SubbieNetworkInvitations/context/ContactListsFeatureFlagProvider';
import { NetworkSearchProvider } from '@builder/features/SubbieNetworkInvitations/context/NetworkSearchProvider';
import { SelectedContactsContext } from '@builder/features/SubbieNetworkInvitations/context/SelectedContactsProvider';
import { useGetBuilderContactListEntries } from '@builder/features/SubbieNetworkInvitations/hooks';
import styles from './styles.module.scss';

const TenderWizardNetworkInvites = ({ stageId }: { stageId: number }) => {
  const [isConfirmInvitesModalOpen, setIsConfirmInvitesModalOpen] = useState(false);
  const isContactListsFeatureFlagOn = useContext(ContactListsFeatureFlagContext);
  const { totalNumberOfSelectedContacts } = useContext(SelectedContactsContext);
  const [selectedContactList, setSelectedContactList] = useState<
    ContactListSelectorOption | undefined
  >(undefined);
  const { contactLists, loading } = useGetBuilderContactListEntries();
  const [isContactListSelectorMenuOpen, setIsContactListSelectorMenuOpen] = useState(false);

  const listOptions = contactLists?.map((list) => ({ value: list?.id, label: list?.name })) ?? [];

  return (
    <>
      <TenderWizardSubNav stageId={stageId}>
        <div className={styles.tenderWizardSubnavControls}>
          {isContactListsFeatureFlagOn && (
            <>
              <ContactListSelector
                onChange={setSelectedContactList}
                options={listOptions}
                selectedOption={selectedContactList}
                isLoading={loading}
                isMenuOpen={isContactListSelectorMenuOpen}
                setIsMenuOpen={setIsContactListSelectorMenuOpen}
                onApplySelectedList={() => {
                  setIsContactListSelectorMenuOpen(false);
                  /** TODO: Code here for applying the list to the results */
                }}
              />
              <span className={styles.separator} />
            </>
          )}
          <CounterButton
            onClick={() => setIsConfirmInvitesModalOpen(true)}
            count={totalNumberOfSelectedContacts()}
            disabled={totalNumberOfSelectedContacts() === 0}
          >
            Send Invitations
          </CounterButton>
        </div>
      </TenderWizardSubNav>
      <NetworkSearchProvider>
        <UnifiedInvites
          stageId={stageId}
          isConfirmInvitesModalOpen={isConfirmInvitesModalOpen}
          setIsConfirmInvitesModalOpen={setIsConfirmInvitesModalOpen}
        />
      </NetworkSearchProvider>
    </>
  );
};

export default TenderWizardNetworkInvites;
