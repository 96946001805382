import { Reference, useApolloClient, useMutation } from '@apollo/client';
import { ADD_PROFILE_TO_ADDRESS_BOOK } from '../mutations';
import {
  AddProfileToAddressBook,
  AddProfileToAddressBookVariables,
  AddProfileToAddressBook_addProfileToAddressBook_ConnectedBuilderNetworkCompany as ConnectedNetworkCompany,
} from '../types/AddProfileToAddressBook';

export const useConnectProfileToAddressBook = () => {
  const { cache } = useApolloClient();

  const [addProfileToAddressBook, { loading }] = useMutation<
    AddProfileToAddressBook,
    AddProfileToAddressBookVariables
  >(ADD_PROFILE_TO_ADDRESS_BOOK);

  const connectProfileToAddressBook = async (profileId: string, name: string) => {
    const { data, errors } = await addProfileToAddressBook({ variables: { profileId } });
    const { addProfileToAddressBook: result } = data ?? {};
    const addressBookEntry = result as ConnectedNetworkCompany;

    if (!errors && result !== null && result !== undefined) {
      cache.modify({
        fields: {
          searchNetworkCompanies(existingResults, { readField }) {
            const updatedResults = existingResults.results.map((ref: Reference) => {
              const cachedName = readField('name', ref);

              if (cachedName === name) {
                return {
                  __ref: `ConnectedBuilderNetworkCompany:${addressBookEntry.id}`,
                };
              }
              return ref;
            });

            return {
              ...existingResults,
              results: updatedResults,
            };
          },
        },
      });
    }

    return result;
  };

  return {
    connectProfileToAddressBook,
    connectProfileToAddressBookLoading: loading,
  };
};
