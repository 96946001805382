import { Select, SelectSize } from '@estimateone/frontend-components';
import { PriorityFilterOption } from '.';
import { ValueLabelOption } from '@estimateone/frontend-components/src/components/Form/Select/types';
import styles from './styles.scss';

type PriorityFilterInputProps = {
  priorityFilterOptions: PriorityFilterOption[];
  onChange: (values: ValueLabelOption<string>[]) => void;
  value: ValueLabelOption<string>[];
};

const PriorityFilterInput = ({
  priorityFilterOptions,
  onChange,
  value,
}: PriorityFilterInputProps) => (
  <Select
    id="filter-priority"
    aria-label="Priority Filter"
    className={styles.scheduleFilter}
    classNamePrefix="priorityFilterSelect"
    label="Priority"
    placeholder="Filter by Priority"
    onChange={onChange}
    size={SelectSize.Small}
    options={priorityFilterOptions}
    isMulti
    value={value}
  />
);

export { PriorityFilterInput };
