import { differenceInCalendarDays } from 'date-fns';
import {
  calculateStatusForLetByDate,
  calculateStatusForQuotesDueDate,
  calculateStatusForSendInvitesDate,
  calculateStatusForStartOnSiteDate,
} from './calculateStatus';
import { MilestoneState } from './milestoneState';
import styles from '../styles.scss';

const DAYS_REMAINING_PLACEHOLDER = '{DAYS_REMAINING}';
const DAY_DAYS_PLACEHOLDER = '{DAY_DAYS_PLACEHOLDER}';

const CELL_IMMINENT_TEXT = `${DAYS_REMAINING_PLACEHOLDER} ${DAY_DAYS_PLACEHOLDER} left`;
const CELL_TODAY_TEXT = 'Due today';
const CELL_OVERDUE_TEXT = 'Overdue';

const INVITES_IMMINENT_TEXT = `Invites for this package are due in ${DAYS_REMAINING_PLACEHOLDER} ${DAY_DAYS_PLACEHOLDER}`;
const INVITES_TODAY_TEXT = 'Invites for this package are due today';
const INVITES_OVERDUE_TEXT = 'Invites for this package are now overdue';

const QUOTES_IMMINENT_TEXT = `Quotes for this package are due in ${DAYS_REMAINING_PLACEHOLDER} ${DAY_DAYS_PLACEHOLDER}`;
const QUOTES_TODAY_TEXT = 'Quotes for this package are due today';
const QUOTES_OVERDUE_TEXT = 'Quotes for this package are now overdue';

const LETTING_IMMINENT_TEXT = `Letting for this package is due in ${DAYS_REMAINING_PLACEHOLDER} ${DAY_DAYS_PLACEHOLDER}`;
const LETTING_TODAY_TEXT = 'Letting for this package is due today';
const LETTING_OVERDUE_TEXT = 'This package is yet to be awarded and is now overdue';

const START_ON_SITE_IMMINENT_TEXT = `The start on site date for this package is expected in ${DAYS_REMAINING_PLACEHOLDER} ${DAY_DAYS_PLACEHOLDER}`;
const START_ON_SITE_TODAY_TEXT = 'The start on site date for this package is today';
const START_ON_SITE_OVERDUE_TEXT = 'This package is yet to be awarded and is now overdue';

const getMilestoneText = (
  date: Date | null,
  status: MilestoneState,
  imminentText: string,
  todayText: string,
  overdueText: string,
) => {
  if (date === null) {
    return '';
  }

  if (status === MilestoneState.IMMINENT) {
    const differenceInDays = differenceInCalendarDays(date, new Date());
    if (differenceInDays === 0) {
      return todayText;
    }

    return imminentText
      .replace(DAYS_REMAINING_PLACEHOLDER, `${differenceInDays}`)
      .replace(DAY_DAYS_PLACEHOLDER, differenceInDays === 1 ? 'day' : 'days');
  }

  if (status === MilestoneState.OVERDUE) {
    return overdueText;
  }

  return '';
};

const getCellMilestoneText = (date: Date | null, status: MilestoneState) =>
  getMilestoneText(date, status, CELL_IMMINENT_TEXT, CELL_TODAY_TEXT, CELL_OVERDUE_TEXT);

const getClassNamesForDateCell = (status: MilestoneState): string[] => {
  const classNames = [];
  if (status === MilestoneState.OVERDUE) {
    classNames.push(styles.cellDanger);
  } else if (status === MilestoneState.IMMINENT) {
    classNames.push(styles.cellWarning);
  } else if (status === MilestoneState.COMPLETE) {
    classNames.push(styles.cellComplete);
  } else if (status === MilestoneState.AWARDED) {
    classNames.push(styles.cellSuccess);
  }
  return classNames;
};

export const getMilestoneInfoForSendInvitesDate = (
  date: Date | null,
  numberOfInvitesSent: number,
  isPackageAwarded = false,
) => {
  const status = calculateStatusForSendInvitesDate(date, numberOfInvitesSent, isPackageAwarded);
  const hasMilestoneText =
    date && [MilestoneState.OVERDUE, MilestoneState.IMMINENT].includes(status);

  return {
    classNames: getClassNamesForDateCell(status),
    hasMilestoneText,
    milestoneText: hasMilestoneText ? getCellMilestoneText(date, status) : undefined,
    extraMilestoneText: hasMilestoneText
      ? getMilestoneText(
          date,
          status,
          INVITES_IMMINENT_TEXT,
          INVITES_TODAY_TEXT,
          INVITES_OVERDUE_TEXT,
        )
      : undefined,
  };
};

export const getMilestoneInfoForQuotesDueDate = (
  date: Date | null,
  numberOfInvitesQuoted: number,
  isPackageAwarded = false,
) => {
  const status = calculateStatusForQuotesDueDate(date, numberOfInvitesQuoted, isPackageAwarded);

  const hasMilestoneText =
    date && [MilestoneState.OVERDUE, MilestoneState.IMMINENT].includes(status);

  return {
    classNames: getClassNamesForDateCell(status),
    hasMilestoneText,
    milestoneText: hasMilestoneText ? getCellMilestoneText(date, status) : undefined,
    extraMilestoneText: hasMilestoneText
      ? getMilestoneText(date, status, QUOTES_IMMINENT_TEXT, QUOTES_TODAY_TEXT, QUOTES_OVERDUE_TEXT)
      : undefined,
  };
};

export const getMilestoneInfoForLetByDate = (date: Date | null, isPackageAwarded = false) => {
  const status = calculateStatusForLetByDate(date, isPackageAwarded);
  const hasMilestoneText =
    date && [MilestoneState.OVERDUE, MilestoneState.IMMINENT].includes(status);

  return {
    classNames: getClassNamesForDateCell(status),
    hasMilestoneText,
    milestoneText: hasMilestoneText ? getCellMilestoneText(date, status) : undefined,
    extraMilestoneText: hasMilestoneText
      ? getMilestoneText(
          date,
          status,
          LETTING_IMMINENT_TEXT,
          LETTING_TODAY_TEXT,
          LETTING_OVERDUE_TEXT,
        )
      : undefined,
  };
};

export const getMilestoneInfoForStartOnSiteDate = (date: Date | null, isPackageAwarded = false) => {
  const status = calculateStatusForStartOnSiteDate(date, isPackageAwarded);
  const hasMilestoneText =
    date && [MilestoneState.OVERDUE, MilestoneState.IMMINENT].includes(status);

  return {
    classNames: getClassNamesForDateCell(status),
    hasMilestoneText,
    milestoneText: hasMilestoneText ? getCellMilestoneText(date, status) : undefined,
    extraMilestoneText: hasMilestoneText
      ? getMilestoneText(
          date,
          status,
          START_ON_SITE_IMMINENT_TEXT,
          START_ON_SITE_TODAY_TEXT,
          START_ON_SITE_OVERDUE_TEXT,
        )
      : undefined,
  };
};
