import { gql } from '@apollo/client';

export const GET_CHECKOUT_DATA = gql`
  query GetCheckoutData($productId: EntityId!) {
    supportDetails {
      email
      phone
    }
    currentUser {
      id
      account {
        id
        activeCreditCards {
          id
          maskedNumber
          expiry
        }
      }
    }
    pricingOptions(id: $productId, terms: [1, 12]) {
      billingCycle
      priceExGST {
        amount
      }
    }
  }
`;

export const PURCHASE_ADDON_EXISTING_CC = gql`
  mutation PurchaseAddonWithExistingCC($input: PurchaseAddonExistingCCInput!) {
    addonPurchaseData: purchaseAddonWithExistingCC(input: $input) {
      __typename
      ... on License {
        invoiceItem {
          invoice {
            id
            isPaid
            billingContact {
              email
            }
          }
        }
      }
      ... on Errors {
        errors {
          field
          message
        }
      }
    }
  }
`;

export const PURCHASE_ADDON_NEW_CC = gql`
  mutation PurchaseAddonWithNewCC($input: PurchaseAddonNewCCInput!) {
    addonPurchaseData: purchaseAddonWithNewCC(input: $input) {
      __typename
      ... on License {
        invoiceItem {
          invoice {
            id
            isPaid
            billingContact {
              email
            }
          }
        }
      }
      ... on Errors {
        errors {
          field
          message
        }
      }
    }
  }
`;

export const PURCHASE_ADDON_EFT = gql`
  mutation PurchaseAddonWithEFT($input: PurchaseAddonEFTInput!) {
    addonPurchaseData: purchaseAddonWithEFT(input: $input) {
      __typename
      ... on License {
        invoiceItem {
          invoice {
            id
            isPaid
            billingContact {
              email
            }
          }
        }
      }
      ... on Errors {
        errors {
          field
          message
        }
      }
    }
  }
`;
