import Routing from 'routing';
import {
  Alert,
  AlertVariant,
  Col,
  E1Link,
  Icon,
  IconName,
  Row,
} from '@estimateone/frontend-components';
import { EntityId } from '../../../../../../types';

type AlreadyQuotedAlertProps = { quoteId: EntityId; packageName: string };

const AlreadyQuotedAlert = ({ quoteId: id, packageName }: AlreadyQuotedAlertProps) => (
  <Alert variant={AlertVariant.Yellow}>
    <Row>
      <Col span="auto" alignContentY="center" excludeBottomGutter>
        <Row>
          <Col alignContentY="center" excludeBottomGutter>
            <Icon name={IconName.Help} />
          </Col>
          <Col alignContentY="center" excludeBottomGutter>
            <strong>
              The contact you have selected has already quoted the {packageName} package.
            </strong>
            <span>Do you want to add a quote revision?</span>
          </Col>
        </Row>
      </Col>
      <Col alignContentY="center" excludeBottomGutter>
        <E1Link link={Routing.generate('app_quote_revise', { id, returnTo: 'list' })}>
          Add quote revision
        </E1Link>
      </Col>
    </Row>
  </Alert>
);

export default AlreadyQuotedAlert;
