import { IconVariant, NotificationCounter } from '@estimateone/frontend-components';
import { htmlDecode } from '@builder/utils/htmlDecode';
import { NoteIcon } from '../NoteIcon';
import styles from './styles.scss';

export type PackageDashboardNotesWidgetProps = {
  noteCount: number;
  text: string;
  onClick?: () => void;
};

export const PackageDashboardNotesWidget = ({
  noteCount,
  text,
  onClick,
}: PackageDashboardNotesWidgetProps) => (
  <div className={styles.notesWidgetContainer}>
    <button className={styles.notesWidget} onClick={onClick} type="button">
      <NotificationCounter className={styles.notesCounter} count={noteCount}>
        <NoteIcon variant={IconVariant.Default} />
      </NotificationCounter>
      <div className={styles.latestNote}>{htmlDecode(text)}</div>
    </button>
  </div>
);
