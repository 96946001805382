import { useField, useForm } from 'react-final-form';
import { FormatOptionLabelMeta } from 'react-select/base';
import {
  Icon,
  IconName,
  Tag,
  TagVariant,
  AsyncCreatableSelect,
} from '@estimateone/frontend-components';
import kebabCase from 'lodash/kebabCase';
import { getCategoryLabel } from '@profiles/features/CompanyProfile/components/Categories/utils';
import { FieldWrapper } from '@profiles/features/CompanyProfile/modules/FinalFormFields/FieldWrapper';
import styles from './SearchableProjectName.module.scss';
import { useDebouncedProjectSearch } from './hooks';
import { SearchE1ProjectsQuery } from './queries.generated';
import {
  LinkedProjectOption,
  ProjectHistoryFieldLabels,
  ProjectHistoryFieldNames,
} from '../../types';

type State = SearchE1ProjectsQuery['searchE1Projects']['projects'][number]['address']['state'];

const Placeholder = () => (
  <div className={styles.projectNamePlaceholder}>
    <Icon name={IconName.Search} />
    <p>Search by project name or ID, or add manually</p>
  </div>
);

const NO_MATCHING_PROJECTS_MESSAGE = 'No matching projects found';
export const NO_MATCHING_PROJECTS_OPTION: LinkedProjectOption = {
  id: null,
  value: '-2',
  label: NO_MATCHING_PROJECTS_MESSAGE,
  status: null,
  state: null,
  category: null,
};

const formatProjectLabel = (
  { label, status, state }: LinkedProjectOption, // TODO: is status a thing?
  { context }: FormatOptionLabelMeta<LinkedProjectOption>,
) => {
  if (context === 'menu') {
    return (
      <div className={styles.projectNameOption}>
        <span className={styles.label}>{label}</span>
        {state ? <span className={styles.stateName}>{state.shortName}</span> : null}
        {status ? (
          <div className={styles.status} data-testid="linked-project-status">
            <Tag variant={TagVariant.Iron050} text={status} />
          </div>
        ) : null}
      </div>
    );
  }

  return label;
};

const formatCreateLabel = () => (
  <div className={styles.createNewOption}>
    <div className={styles.createNewOptionIcon}>
      <Icon name={IconName.Add} />
    </div>
    Create New
  </div>
);

export const SearchableProjectName = () => {
  const {
    meta: { error, submitError, touched },
  } = useField<LinkedProjectOption>(ProjectHistoryFieldNames.Name);

  const { change } = useForm();
  const search = useDebouncedProjectSearch();

  type SelectedOptionArgs = {
    name: string;
    id: number | null;
    state: State | null;
    category: string | null;
  };

  const setSelectedOption = ({ name, id, state, category }: SelectedOptionArgs) => {
    change(ProjectHistoryFieldNames.Name, name);
    change(ProjectHistoryFieldNames.LinkedProjectId, id);
    if (state) {
      change(ProjectHistoryFieldNames.State, {
        value: state.id,
        label: state.shortName,
      });
    }
    if (category) {
      change(ProjectHistoryFieldNames.Category, {
        value: kebabCase(category).replace(/-/g, '_').toUpperCase(),
        label: getCategoryLabel(category),
      });
    }
  };

  return (
    <div>
      <FieldWrapper errors={error || submitError} hideErrorMessages={false} showErrors={touched}>
        <AsyncCreatableSelect<LinkedProjectOption>
          isRequired
          id={ProjectHistoryFieldNames.Name}
          label={ProjectHistoryFieldLabels.Name}
          hideLabel={false}
          placeholder={<Placeholder />}
          noOptionsMessage={() => NO_MATCHING_PROJECTS_MESSAGE}
          loadOptions={search}
          openMenuOnFocus
          formatOptionLabel={formatProjectLabel}
          formatCreateLabel={formatCreateLabel}
          isOptionDisabled={({ value }) => value === NO_MATCHING_PROJECTS_OPTION.value}
          onChange={(selectedOption, actionMeta) => {
            if (
              (actionMeta.action === 'select-option' || actionMeta.action === 'create-option') &&
              selectedOption
            ) {
              setSelectedOption({
                name: selectedOption.label,
                id: selectedOption.id,
                state: selectedOption.state,
                category: selectedOption.category,
              });
            }
          }}
        />
      </FieldWrapper>
    </div>
  );
};
