import { useNavigate } from 'react-router-dom-v5-compat';
import { useQuery } from '@apollo/client';
import { LoadingSpinner, LoadingSpinnerSize, Toggle } from '@estimateone/frontend-components';
import { reportError } from '@ascension/components/helpers/errorReporter';
import { useGlobalErrorBanner } from '../../GlobalErrorBanner';
import styles from '../ProfileSettings.module.scss';
import { useSubmitSSCOptOut } from './hooks';
import { GET_SSC_OPT_OUT_PREFERENCE } from '@profiles/features/CompanyProfile/modules/ViewerProvider/queries';
import { SscOptOutPreferenceQuery } from '@profiles/features/CompanyProfile/modules/ViewerProvider/queries.generated';

export const SSCOptOutOption = () => {
  const { loading, data } = useQuery<SscOptOutPreferenceQuery>(GET_SSC_OPT_OUT_PREFERENCE);
  const isOptedIntoSSC =
    data?.hasOptedOutOfSSC !== undefined ? data.hasOptedOutOfSSC === false : true;

  const { openBanner } = useGlobalErrorBanner();
  const navigate = useNavigate();

  const { optIn } = useSubmitSSCOptOut({
    onError: (apolloError) => {
      openBanner();
      reportError(new Error(apolloError.message));
    },
  });

  return loading ? (
    <section className={styles.setting}>
      <h2 className={styles.settingHeading}>Activity Visibility</h2>
      <LoadingSpinner size={LoadingSpinnerSize.Medium} />
    </section>
  ) : (
    <section className={styles.setting}>
      <h2 className={styles.settingHeading}>Activity Visibility</h2>
      <div className={styles.toggleWrapper}>
        <span>Allow Suppliers to view your activity</span>
        <Toggle
          label="Allow Suppliers to view your activity"
          checked={isOptedIntoSSC}
          onChange={isOptedIntoSSC ? () => navigate('/settings/ssc/edit') : () => optIn()}
          size="Small"
        />
      </div>
      <aside className={styles.explanationCard}>
        <h3>How does this work?</h3>
        <ul>
          <li>
            Suppliers can access your details on projects you&apos;re involved in the tendering
            process of. They may reach out to offer pricing or information on specified products you
            install.
          </li>
        </ul>
        <h3>When is my profile visible?</h3>
        <ul>
          <li>
            Your profile will be visible to relevant <b>Suppliers</b> on projects that have been
            added to your Watchlist as &apos;interested&apos;, &apos;quoting&apos; or
            &apos;quoted&apos; and on projects that you have responded to the Builder as
            &apos;quoting&apos;.
          </li>
        </ul>
      </aside>
    </section>
  );
};
