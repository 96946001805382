import { projects_projects_address as ProcoreProjectAddress } from './types/projects';

export enum Country {
  AU = 'Australia',
  GB = 'United Kingdom',
  NZ = 'New Zealand',
  IE = 'Ireland',
  US = 'United States',
}

export const getAddressString = (address?: ProcoreProjectAddress) => {
  if (address) {
    const addressString = `${address.addressLine1 ? `${address.addressLine1}, ` : ''}${
      address.city ? `${address.city}, ` : ''
    }${address.stateCode ? `${address.stateCode}, ` : ''}${address.zip ? `${address.zip}, ` : ''}${
      address.countryCode ? Country[address.countryCode as keyof typeof Country] : ''
    }`;
    return addressString.trim().replace(/,$/, '');
  }
  return '';
};
