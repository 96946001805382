import Moment from 'moment-timezone';
import { getUserTimezone } from '@ascension/js/app/timezone';
import { PackageOption } from '../types';
import styles from './styles.scss';

export const PackageOptionLabel = ({ label, quotesDueAt }: PackageOption) => {
  if (quotesDueAt) {
    return (
      <div className={styles.packageOptionLabel}>
        <span>{label} *</span>
        <span className={styles.dueDate}>
          Due by {Moment.tz(quotesDueAt, getUserTimezone()).format('DD MMM YYYY')}
        </span>
      </div>
    );
  }

  return <span>{label}</span>;
};
