import $ from 'jquery';

$(() => {
  $(document).on('mfpOpen', () => {
    const $packageNotifyTable = $('table.package-notify-table');
    const $correspondenceMessage = $('.correspondence-message');
    const $noCorrespondenceNecessary = $('.no-correspondence-necessary');

    $('#package-notify-select-all').on('change', (selectAllEvent) => {
      $packageNotifyTable
        .find('input:checkbox')
        .prop('checked', $(selectAllEvent.currentTarget).prop('checked'));
    });

    $packageNotifyTable.find('input:checkbox').on('change', () => {
      const selectedCount = $packageNotifyTable.find(
        'input:checkbox:checked:not(#package-notify-select-all)',
      ).length;
      if (selectedCount === 0) {
        $correspondenceMessage.hide();
        $noCorrespondenceNecessary.show();
      } else {
        $correspondenceMessage.show();
        $noCorrespondenceNecessary.hide();
      }
    });
  });
});
