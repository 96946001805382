import $ from 'jquery';
import Routing from 'routing';
import _ from 'underscore';
import E1Request from '../classes/E1Request';
import Form from '../classes/Form';
import Modal from '../classes/Modal';
import Throttler from '../classes/Throttler';
import {
  getAllDateClasses,
  initQuotesDueDatePicker,
  TimezoneAwareDateHelpers,
} from '../utils/date';
import { fetchMatrixDetails, matrixIsInReadOnlyMode } from './stage_matrix_details';
import { monitorTrades } from './stage_matrix_trades';
import { StageStatus } from '../../enums';
import quillify from '../utils/quillify';

const getStageStatus = (statusSelectionEvent) => {
  const $statusOpt = $(statusSelectionEvent.currentTarget);

  const isSelect = $statusOpt.prop('tagName') === 'SELECT';

  const $elemWithData = isSelect ? $statusOpt : $statusOpt.parent();

  const stageId = $elemWithData.data('stage-id');
  const status = parseInt(isSelect ? $statusOpt.val() : $statusOpt.data('status-id'));

  if (status === StageStatus.TENDER_PENDING) {
    const route = Routing.generate('app_tenderstage_update', { id: stageId });
    const req = new E1Request(route, 'POST', { status });
    req.submit();
    return;
  }

  document.dispatchEvent(
    new CustomEvent('getStageStatusResult', {
      detail: {
        stageId,
        status,
      },
    }),
  );
};

$(() => {
  const $body = $('body');

  $('input.datetime').constraintAwareDatePicker({
    autoclose: true,
    useCurrent: false,
  });

  $body.on('change', 'input.hidden_datetime', (e) => {
    const $target = $(e.currentTarget);
    const dateString = $target.val();
    const date = TimezoneAwareDateHelpers.getMomentFromDateString(
      dateString,
      $target.data('date-format'),
      true,
    );
    const dateClass = TimezoneAwareDateHelpers.getQuotesDueClassFromDate(date);
    const $date = $target.closest('.date');

    Object.values(getAllDateClasses()).forEach((classString) => {
      $date.removeClass(classString);
    });

    $date.addClass(dateClass);
  });

  $body.on('click', 'input.hidden_datetime', (e) => {
    $(e.currentTarget).datepicker('show');
  });

  $body.on('click', 'a.change_date', (e) => {
    $(e.currentTarget).closest('.quotes_due').find('input.hidden_datetime').datepicker('show');
  });

  const $inputs = $('input.hidden_datetime_eager[name=quotesDueDate]', $body);
  initQuotesDueDatePicker($inputs);

  $body.on('click', '.badge_stage_status_switch .option', (e) => {
    const $option = $(e.currentTarget);
    const $container = $option.closest('.badge_stage_status_switch');

    if ($option.data('status-id') > 0 && $container.data('stage-id') > 0) {
      $('.option', $container).removeClass('active');
      $option.addClass('active');
    }
  });

  const $tradeForm = $('.trade-select');
  if ($tradeForm.length > 0) {
    monitorTrades($tradeForm);
  }

  const $fixedPriceCtn = $('.fixed-price-quote');
  const isFixedPriceFieldVisible = $fixedPriceCtn.find('input[type=radio]').length > 0;
  if (isFixedPriceFieldVisible) {
    $fixedPriceCtn.show();
  }

  const $tenderDetailsForm = $('form.tender-details-form');
  if ($tenderDetailsForm.length > 0) {
    $tenderDetailsForm.on('change', '.private_toggle input[type=radio]', (e) => {
      const $container = $(e.currentTarget).closest('.private_toggle');
      const $selected = $container.find('input[type=radio]:checked:first');
      const $autoApproveCtn = $tenderDetailsForm.find('.auto-approve-option');
      const $autoApproveInput = $autoApproveCtn.find('input[type=radio]:checked:first');
      const data = {};
      data[$selected.attr('name')] = $selected.val();
      if ($autoApproveInput.length > 0) {
        data[$autoApproveInput.attr('name')] = $autoApproveInput.val();
      }
      data.update_form = true;
      const request = new E1Request(
        $tenderDetailsForm.attr('action'),
        $tenderDetailsForm.attr('method'),
        data,
      );
      $autoApproveCtn.hide();
      $fixedPriceCtn.hide();
      request.submit((req, resp) => {
        const $page = $(resp.content);
        const $newValueCtn = $page.find('.auto-approve-option');
        const $newFixedPriceCtn = $page.find('.fixed-price-quote');
        if ($newValueCtn.length > 0) {
          $autoApproveCtn.html($newValueCtn.html());
          $autoApproveCtn.show();
        }
        if ($newFixedPriceCtn.length > 0) {
          $fixedPriceCtn.html($newFixedPriceCtn.html());
          const hasActiveField = $newFixedPriceCtn.find('input[type=radio]').length > 0;
          if (hasActiveField) {
            $fixedPriceCtn.show();
          }
        }
      });
    });

    const $privacyToggle = $tenderDetailsForm.find('.tender_stage_no_private');
    $privacyToggle.on('change', 'input[type="radio"]', (e) => {
      // if the val is 1, then the stage has been set to private. Launch the upgrade modal.
      if (parseInt($(e.currentTarget).val(), 10) === 1) {
        const url = Routing.generate('app_upgrade_private');
        const request = new E1Request(url);
        request.submit();
      }
    });
  }

  /**
   * Catch a stage form submission, and check to see if we need to hijack it with project matches
   */
  const $stageDetailsForm = $('form.stage-details-form');
  if ($stageDetailsForm.length > 0) {
    quillify($stageDetailsForm, {
      useExistingData: false,
      formElementSelector: '[data-quill-form-element]',
      editorElementSelector: '[data-quill-editor]',
      quillConfig: {
        theme: 'snow',
        placeholder: 'Enter text here...',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
          ],
        },
      },
    });
    const $projectId = $stageDetailsForm.find('input.project-override-input');
    const $confirmed = $stageDetailsForm.find('input.project-confirmed-input');
    const stageDetailsForm = new Form($stageDetailsForm, $stageDetailsForm.attr('action'));
    $stageDetailsForm.find('*:input[type!=hidden]:first').trigger('focus');

    const throttler = new Throttler();

    let matchRequest = null;
    $body.on('submit', 'form.stage-details-form', () => {
      throttler.add(() => {
        let desiredRequest = stageDetailsForm;
        if ($stageDetailsForm.hasClass('is-wizard')) {
          if (!$confirmed.val()) {
            stageDetailsForm.preSubmit();
            const data = stageDetailsForm.gatherInputs();
            const url = $stageDetailsForm.data('matchurl');
            if (matchRequest !== null && matchRequest.isPending()) {
              matchRequest.abort();
            }
            matchRequest = new E1Request(url, 'POST', data, false);
            matchRequest.extraCallback = (response) => {
              stageDetailsForm.deactivateLoading();
              if (response.continue) {
                $confirmed.val(1);
                stageDetailsForm.submit();
              } else if (typeof response.errors !== 'undefined') {
                _.each(response.errors, (e, section) => {
                  stageDetailsForm.showErrors(section, e);
                });
              }
            };
            desiredRequest = matchRequest;
          }
        }
        desiredRequest.submit();
      });
      return false;
    });

    /**
     * Catch the submission of the match form, and update the tender form with the selected project
     */
    $body.on('submit', 'form.project_match_form', (submitEvent) => {
      const $matchForm = $(submitEvent.currentTarget);
      new Form($matchForm, $matchForm.attr('action')); // eslint-disable-line no-new

      const selectedProject = $('input.matched_project_input:checked').val();

      $projectId.val(selectedProject);
      $confirmed.val(true);

      Modal.closeAll();
      stageDetailsForm.submit();
      return false;
    });
  }

  const $gridContainer = $('#gridContainer');
  if ($gridContainer.length > 0) {
    const stageId = parseInt($gridContainer.attr('data-stage'), 10);

    const route = Routing.generate('app_stagematrix_pendingchangemodal', {
      id: stageId,
    });

    const request = new E1Request(route);
    request.extraCallback = (response) => {
      if (!response.success) {
        return;
      }

      if (typeof response.modal_string === 'string') {
        // We've returned the "Pending Matrix Change" modal and we need to wait
        // for it to complete before loading the matrix.
        return;
      }

      if (response.matrix_change_processed) {
        // If a matrix change for the stage ever existed, it's been processed.
        // Load the matrix.
        fetchMatrixDetails(true, true);
        return;
      }

      const readOnly = matrixIsInReadOnlyMode();

      if (readOnly && !response.matrix_change_processed) {
        // A matrix change for the stage exists. However, it's not ready for processing
        // and we are in read-only mode. So we'll ignore it for the time being. Load the matrix.
        fetchMatrixDetails(true, true);
        return;
      }

      if (!readOnly && !response.matrix_change_processed) {
        // We are not in read-only mode, a matrix change for the stage exists, but
        // it's not ready for processing. Load the matrix and prompt the user to
        // finalise the matrix change.
        fetchMatrixDetails(true, true, (matrixWrapper) => {
          if (!response.matrix_change_processed) {
            matrixWrapper.finaliseMatrix();
          }
        });
      }
    };
    request.passive = true;
    request.submit();

    if ($gridContainer[0].hasAttribute('data-create-packages')) {
      new E1Request(
        Routing.generate('app_stagepackage_generatemodal', {
          id: stageId,
        }),
      ).submit();
    }
  }

  $body.on('submit', 'form.generate-package-form', async (e) => {
    e.preventDefault();
    await new Form($(e.currentTarget)).submit();
    window.location.reload();
  });

  $(document).on('submit', 'form.download_package', (e) => {
    const $form = $(e.currentTarget);
    const $packageSelect = $form.find('select').first();
    const packageId = $packageSelect.val();
    const stageId = $form.attr('data-stage-id');

    if (stageId > 0 && packageId > 0) {
      const url = Routing.generate('app_stagepackage_download', {
        id: stageId,
        packageId,
      });
      const request = new E1Request(url);
      request.submit();
    } else {
      alert('Please select a package');
    }
    return false;
  });

  $(document).on('setStageStatus', (e) => {
    const { stageId, status } = e.detail;

    const $statusSelect = $(`select[data-stage-id='${stageId}']`);

    const $statusSwitch = $(`.badge_stage_status_switch[data-stage-id=${stageId}]`);

    if ($statusSelect.length) {
      $statusSelect.val(status).trigger('change');
    } else if ($statusSwitch.length) {
      $statusSwitch.find('.option').removeClass('active');
      $statusSwitch.find(`.option[data-status-id=${status}]`).addClass('active');
    }
  });

  $body
    .on('change', 'select.stage_status_modifier', getStageStatus)
    .on('click', '.badge_stage_status_switch .option', getStageStatus);

  if ($body.attr('data-force-modal') === 'stage_awarded') {
    const stageId = $('.stage-id-container').val();
    document.dispatchEvent(
      new CustomEvent('setStageStatus', {
        detail: { stageId, status: StageStatus.TENDER_AWARDED },
      }),
    );
  }
});
