import { Button } from '@estimateone/frontend-components';
import styles from './styles.scss';

export type CounterButtonProps = {
  children: React.ReactNode;
  count: number;
  disabled?: boolean;
  onClick: (props: Pick<CounterButtonProps, 'count' | 'disabled'>) => void;
};

export const CounterButton = ({ children, onClick, disabled, count }: CounterButtonProps) => (
  <Button
    className={styles.layout}
    disabled={disabled}
    onClick={(e) => {
      e.preventDefault();
      onClick({ count, disabled });
    }}
  >
    {children}
    <div className={styles.counter}>{count}</div>
  </Button>
);
