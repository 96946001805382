import { useField, useForm } from 'react-final-form';
import { joinClassNames, prefixMenuOptionsWithCheckIcon } from '@estimateone/frontend-components';
import { SingleSelectField } from '@shared/FinalForm/Field/SingleSelectField';
import { CompleteQuoteFormSpecificFieldName } from '../../enums';
import { CompleteQuoteFormValues, ContactOption, ContactValue } from '../../types';
import styles from './styles.scss';

const ContactLabel = ({ value, label, invited, mostRecentQuoteId }: ContactOption) => (
  <div className={joinClassNames(styles.companyContactLabel, value === -1 && styles.addNewOption)}>
    <div>{label}</div>
    <ul>
      {invited && <li>Invited</li>}
      {mostRecentQuoteId && <li>Quoted</li>}
    </ul>
  </div>
);

type ContactSelectorProps = {
  options: ContactOption[];
  placeholder: string;
  onAddContact: () => void;
  isDisabled?: boolean;
};

const ADD_NEW_VAL = -1;

export const ADD_NEW_CONTACT_OPTION: ContactOption = {
  value: ADD_NEW_VAL,
  label: 'Add a new contact',
  invited: false,
  mostRecentQuoteId: undefined,
};

const ContactSelector = ({
  options,
  placeholder,
  onAddContact,
  isDisabled = false,
}: ContactSelectorProps) => {
  const { change: updateFieldValue } = useForm<CompleteQuoteFormValues>();

  const {
    input: { value: contactValue, onChange, ...contactInputProps },
  } = useField<ContactValue>(CompleteQuoteFormSpecificFieldName.Contact);

  const handleChange = (selectedOption: ContactOption) => {
    const { value: id, mostRecentQuoteId } = selectedOption;

    if (id === ADD_NEW_VAL) {
      updateFieldValue(CompleteQuoteFormSpecificFieldName.Contact, undefined);
      return onAddContact();
    }

    return onChange({ id, mostRecentQuoteId });
  };

  const selectedContactOption = options.find((o) => o.value === contactValue.id);

  return (
    <SingleSelectField<ContactOption>
      fieldName={CompleteQuoteFormSpecificFieldName.Contact}
      id="quote-ab-contact"
      label="Contact"
      placeholder={placeholder}
      isDisabled={isDisabled}
      options={options}
      formatOptionLabel={prefixMenuOptionsWithCheckIcon<ContactOption>(ContactLabel)}
      value={isDisabled ? null : selectedContactOption || null}
      onChange={(selectedOption) => (selectedOption ? handleChange(selectedOption) : null)}
      hideErrors={isDisabled}
      {...contactInputProps}
    />
  );
};

export default ContactSelector;
