import { gql } from '@apollo/client';

export const UPDATE_SCHEDULE_SETTINGS = gql`
  mutation UpdateScheduleSettings($input: UpdateScheduleSettingsInput!) {
    updateScheduleSettings(input: $input) {
      id
      quotesDueAtInterval
      letByDateInterval
      startOnSiteDateInterval
      trade {
        id
        name
      }
    }
  }
`;
