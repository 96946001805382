export const APP_NAME = 'profiles-ui';

// These are now a backend concern
// export const S3_BASE_HREF = 'https://s3.ap-southeast-2.amazonaws.com';
// export const COMPANY_LOGO_BUCKET = 'e1-profiles-dev-apse2-company-logo';
// export const COMPANY_LOGO_BUCKET = process.env.REACT_APP_COMPANY_LOGO_BUCKET;
// export const COMPANY_LOGO_BASE_HREF = `${S3_BASE_HREF}/${COMPANY_LOGO_BUCKET}`;

export const AU_COUNTRY_ID = 1;
export const UK_COUNTRY_ID = 4;
export const IE_COUNTRY_ID = 11;
