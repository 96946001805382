import { useCallback, useRef } from 'react';
import { useApolloClient } from '@apollo/client';
import { NO_MATCHING_PROJECTS_OPTION } from './SearchableProjectName';
import { SEARCH_PROJECTS } from './queries';
import { SearchE1ProjectsQuery, SearchE1ProjectsQueryVariables } from './queries.generated';
import { LinkedProjectOption } from '../../types';

const MIN_INPUT_LENGTH = 3;

export const useDebouncedProjectSearch = () => {
  const { query } = useApolloClient();

  const executeQuery = (userQuery: string) =>
    query<SearchE1ProjectsQuery, SearchE1ProjectsQueryVariables>({
      query: SEARCH_PROJECTS,
      variables: {
        input: {
          query: userQuery,
        },
      },
    });

  const timeout = useRef<NodeJS.Timeout | undefined>(undefined);

  return useCallback((input: string, callback: (options: LinkedProjectOption[]) => unknown) => {
    if (input.length < MIN_INPUT_LENGTH) {
      return;
    }

    if (timeout.current) clearTimeout(timeout.current);

    timeout.current = setTimeout(async () => {
      const { data } = await executeQuery(input);

      const projects = data?.searchE1Projects?.projects ?? [];

      const options = projects.map(({ name, id, status, address, stageCategoryName }) => ({
        label: name,
        value: name,
        id,
        status,
        state: address?.state || null,
        category: stageCategoryName || null,
      }));

      callback(options.length ? [...options] : [NO_MATCHING_PROJECTS_OPTION]);
    }, 750);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
