import { Alert, AlertVariant } from '@estimateone/frontend-components';
import { RfqTimestampFields } from '../../../api/fragments/Quotes/types';

type RfqStatusAlertProps = {
  rfq: RfqTimestampFields;
};

const notNull = (field: string | null): boolean => field !== null;
const isNull = (field: string | null): boolean => !notNull(field);

/**
 * Rfqs can be both requested and revoked
 */
export const isNotApprovedRfq = (rfq: RfqTimestampFields) =>
  isNull(rfq.approvedAt) || notNull(rfq.declinedAt) || notNull(rfq.revokedAt);

const getStatusText = (rfq: RfqTimestampFields): string => {
  switch (true) {
    case notNull(rfq.revokedAt):
      return 'has been revoked';
    case notNull(rfq.declinedAt):
      return 'has been declined';
    // Invited, not accepted
    case notNull(rfq.invitedAt):
      return 'has not been actioned';
    // Requested, not accepted
    case notNull(rfq.requestedAt):
      return 'has not been approved';
    default:
      throw new Error(`Invalid status text for rfq`);
  }
};

const RfqStatusAlert = ({ rfq }: RfqStatusAlertProps) => {
  if (!isNotApprovedRfq(rfq)) return null;

  return (
    <Alert variant={AlertVariant.Yellow}>
      <span>
        The invitation for the selected contact <strong>{getStatusText(rfq)}</strong>.
      </span>
      <br />
      <span>
        Submitting this quote will <strong>approve the invitation</strong> and grant them access to
        the relevant documentation.
      </span>
    </Alert>
  );
};

export default RfqStatusAlert;
