import $ from 'jquery';
import Routing from 'routing';
import E1Request from '../classes/E1Request';
import Form from '../classes/Form';
import Modal from '../classes/Modal';
import gtmDataLayerPush from '../utils/google_tag_manager_helper';

function LongsellForm($form) {
  this.$form = $form;
  this.paymentOption = this.$form.find('input.payment-option-modifier:checked').val();
  this.$chargeExistingInput = this.$form.find('input.is_new_cc_toggle');
  this.chargeExisting = this.$chargeExistingInput.val() === '1';
  this.creditCard = this.$form.find('select.credit-card-selector').val();
  this.submitButton = this.$form.find('button');
  this.submitButton.prop('disabled', false);

  this.$form.on('change', 'input.payment-option-modifier', () => {
    this.paymentOption = this.$form.find('input.payment-option-modifier:checked').val();
    this.draw();
  });

  this.$form.on('change', 'select.credit-card-selector', () => {
    this.creditCard = this.$form.find('select.credit-card-selector').val();
    this.$chargeExistingInput.val(this.creditCard === '-1' ? 0 : 1).trigger('change');
  });

  this.$chargeExistingInput.on('change', () => {
    this.chargeExisting = this.$chargeExistingInput.val() === '1';
    this.draw();
  });

  this.$form.on('submit', () => false);
}

LongsellForm.prototype = {
  draw() {
    this.submitButton.prop(
      'disabled',
      this.paymentOption === 'CC' && this.chargeExisting && !this.creditCard,
    );

    if (this.paymentOption === 'CC') {
      this.$form.find('.eft_related').addClass('hide');
      this.$form.find('.cc_related').removeClass('hide');

      if (this.chargeExisting === true) {
        this.$form.find('.new_cc').addClass('hide');
        this.$form.find('.existing_cc').removeClass('hide');
      } else {
        this.$form.find('.existing_cc').addClass('hide');
        this.$form.find('.new_cc').removeClass('hide');
        $(document).trigger('show-eway-form');
      }
    } else if (this.paymentOption === 'EFT') {
      this.$form.find('.cc_related').addClass('hide');
      this.$form.find('.eft_related').removeClass('hide');
    }
  },
  async submit() {
    this.submitButton.prop('disabled', true);
    await new Form(this.$form).submit();
    this.submitButton.prop('disabled', false);
  },
};

function updateUpgradeForm($form) {
  const $optionRadio = $form.find('input.payment-option-modifier:checked');
  const $token = $form.find('#account_upgrade__token');

  const data = {};
  data[$optionRadio.attr('name')] = $optionRadio.val();
  if ($token.length > 0) {
    data[$token.attr('name')] = $token.val();
  }

  data.update_form = true;

  const request = new E1Request($form.attr('action'), $form.attr('method'), data);
  request.submit((req, resp) => {
    const $page = $(resp.content);
    const $ccSection = $page.find('.pay_creditcard');
    $ccSection.find('.help-block').remove();
    $ccSection.find('.has-error').removeClass('has-error');
    $form.find('.pay_creditcard').html($ccSection.html());

    const $eftExplain = $('.eft-explain');

    if ($optionRadio.val() === 'EFT') {
      $eftExplain.show();
    } else {
      $eftExplain.hide();
      $(document).trigger('init-eway-form');
    }
  });
}

$(() => {
  const $body = $('body');
  $body
    .on('click', '.upgrade-prompt', (e) => {
      window.analyticsService.addEvent('upsell', {
        action: 'UpgradePromptModalPass',
      });

      e.preventDefault();
      const $this = $(e.currentTarget);

      gtmDataLayerPush({ event: 'upgrade-prompt-click' });
      window.location = $this.attr('href');
    })
    .on('click', '.add-state-license-trigger', async (upgradeButtonClick) => {
      const { projectId } = upgradeButtonClick.currentTarget.dataset;
      return new E1Request(
        Routing.generate('app_upgrade_request_contact_me', { projectId }),
        'POST',
      ).submit();
    })
    .on('click', '.request-callback-trigger', async (requestCallbackClick) => {
      const { context } = requestCallbackClick.currentTarget.dataset;
      return new E1Request(
        Routing.generate('app_upgrade_request_callback', { context }),
        'POST',
      ).submit();
    })
    .on('click', '.callback-update-phone-trigger', async () => {
      const $numberInput = $('#contactNumber');
      try {
        await new E1Request(
          Routing.generate('app_upgrade_request_callback', {
            phone: $numberInput.val(),
            context: $numberInput.data('context'),
          }),
          'POST',
        ).submit();
      } catch (validationError) {
        const $errorContainer = $('.global-error');
        $errorContainer.find('.help-block').text(validationError.errors._global); // eslint-disable-line no-underscore-dangle
        $numberInput.closest('.input-group').addClass('has-error');
        $errorContainer.removeClass('hide');
      }
    });

  const formModifierClasses = ['input.payment-option-modifier'];
  $.each(formModifierClasses, (i, elementClass) => {
    $body.on('change', elementClass, (e) => {
      const $modifierInput = $(e.currentTarget);
      const $form = $modifierInput.closest('form');

      if ($form.hasClass('upgrade-account-form')) {
        updateUpgradeForm($form);
      }
    });
  });

  $body.on('submit', '.upgrade-account-form', (e) => {
    const $form = $(e.currentTarget);
    const form = new Form($form);
    form.extraCallback = (response) => {
      if (response.modal_string != null && typeof response.modal_string === 'string') {
        Modal.closeAll();
        const modal = new Modal(response.modal_string, {
          modal: true,
        });
        modal.show();
      }
      if (response.success) {
        gtmDataLayerPush({ event: 'in-app-upgrade' });
      }
    };
    form.submit();
    return false;
  });

  const $productCtn = $('.product-option-container');
  if ($productCtn.length > 0) {
    $productCtn.on('change', '.product-selector', (e) => {
      const val = $(e.currentTarget).val();
      const intId = parseInt(val, 10);
      $productCtn.find('.product-membership-ctn').addClass('hide');
      if (!isNaN(intId)) {
        $productCtn.find(`.product-membership-ctn[data-id=${val}]`).removeClass('hide');
      } else {
        $productCtn.find('.product-membership-ctn.empty').removeClass('hide');
      }
    });
  }

  const $longsellForm = $('form.longsell-account-form');
  if ($longsellForm.length > 0) {
    const longsellForm = new LongsellForm($longsellForm);
  }

  $('table.comparison-table').each(function () {
    const $table = $(this);
    const productCount = $table.data('product-count');
    const $featureRows = $table.find('tr.product-feature');
    if (typeof productCount === 'undefined') {
      throw new Error('You must define data-product-count on the comparison table');
    }
    const columnCount = $featureRows.length;
    $featureRows.each(function (rowIndex) {
      const $featureRow = $(this);
      const $heading = $featureRow.find('th').first();
      [...new Array(productCount).keys()].forEach((col, colIndex) => {
        const featureIncluded = !this.hasAttribute(`data-excluded-${col + 1}`);
        /**
         * @param {String[]} additionalDisplayClasses - classes to denote bottom left/right columns for ease of styling
         */
        const additionalDisplayClasses = [];
        if (rowIndex + 1 === columnCount) {
          const cellClassPrefix = 'table-inner-bottom';
          if (colIndex === 0) {
            additionalDisplayClasses.push(`${cellClassPrefix}-left`);
          }
          if (colIndex === productCount - 1) {
            additionalDisplayClasses.push(`${cellClassPrefix}-right`);
          }
        }
        const includedAltText = featureIncluded ? 'Included' : 'Not Included';
        $featureRow.append(
          $('<td>', {
            title: includedAltText,
            class: `feature-${featureIncluded ? 'included' : 'excluded'}${
              additionalDisplayClasses.length ? ' ' : ''
            }${additionalDisplayClasses.join(' ')}`, // eslint-disable-line quote-props
          }).append(`<span role="img" aria-label="${includedAltText}">`),
        );
      });
      $heading
        .append(
          $('<i>')
            .addClass('icon icon-help-circled-1')
            .attr('title', $heading.parent().data('help-content'))
            .tooltipster({
              offsetX: -5,
              offsetY: 10,
              position: 'right',
              multiple: false,
              functionReady: (origin, tooltip) => {
                tooltip.addClass('tooltipster-builder-upgrade');
              },
            }),
        )
        .removeData('help-content');
    });
    $table
      .find('td.full-height-column')
      .attr('rowspan', $table.find('tbody').children('tr').length);
  });

  $('.btn-upgrade-trigger').on('click', (e) => {
    const $button = $(e.currentTarget);
    switch ($button.data('product')) {
      case 'mid':
        location.href = Routing.generate('app_accountupgrade_marketintel', { billing_cycle: 12 });
        break;
      case 'pro': {
        const enquireModalRequest = new E1Request(
          Routing.generate('app_upgrade_marketintel_enquire'),
        );
        enquireModalRequest.submit();
        break;
      }
      default:
        break;
    }
  });
});
