import { SingleSelectField } from '@shared/FinalForm/Field/SingleSelectField';
import { SubbieNetworkFilterFormFields } from '@builder/common/SubbieNetwork/utils/filters/types';
import { ValueLabelOption } from '@estimateone/frontend-components/src/components/Form/Select/types';

type CategoryFilterProps = {
  categoryOptions: ValueLabelOption<number>[] | null;
  dropUp?: boolean;
};

export const CategoryFilter = ({ categoryOptions, dropUp }: CategoryFilterProps) => {
  if (!categoryOptions) {
    return null;
  }

  return (
    <SingleSelectField
      id="drawer-filter-categories"
      inputId="drawer-filter-categories"
      fieldName={SubbieNetworkFilterFormFields.CategoryFilterId}
      label=""
      options={categoryOptions}
      hideLabel
      placeholder="Select..."
      isClearable
      menuPlacement={dropUp ? 'top' : 'bottom'}
    />
  );
};
