/** SUBBIE_CREDIT_EXPERIMENT_ALPHA_CODE */
import { E1Link } from '@estimateone/frontend-components';
import { FreeCreditButtonsView } from '../ButtonsSection';
import { FreeCreditsGraphicSection } from '../GraphicSection';
import { FreeCreditsHeader } from '../HeaderSection';
import { TradesSection } from '../TradesSection';
import styles from './styles.module.scss';

const learnMoreLink = 'https://estimateone.com/e004-b/';

const ReminderContent = () => (
  <>
    Just a reminder, free accounts include access to <strong>three tenders per month. </strong>
    Once you have used all your free tenders, you won&apos;t have access to your next free tender
    until the 1st of next month.
  </>
);

type AdditionalContentProps = {
  upgradeUrl: string;
};

const AdditionalContent = ({ upgradeUrl }: AdditionalContentProps) => (
  <div>
    <p>
      <strong>Nice, found a winning tender?</strong>
    </p>
    <p>
      <ReminderContent />{' '}
      <E1Link href={learnMoreLink} target="_blank" rel="noopener noreferrer">
        Learn more
      </E1Link>
    </p>
    <br />
    <p>
      <strong>Want to access all open tenders? </strong>By{' '}
      <E1Link href={upgradeUrl}>upgrading your account</E1Link> you&apos;ll get full access across
      open tenders in your state, awarded work information plus a whole lot more!
    </p>
  </div>
);

type MultiFreeCreditsContentViewProps = AdditionalContentProps & {
  onClick: () => void;
  isUnlockProjectLoading: boolean;
  projectId: number;
  allowedCredits: number;
  usedCredits: number;
};

export const MultiFreeCreditsContentView = ({
  upgradeUrl,
  onClick,
  isUnlockProjectLoading,
  projectId,
  allowedCredits,
  usedCredits,
}: MultiFreeCreditsContentViewProps) => (
  <>
    <FreeCreditsGraphicSection allowedCredits={allowedCredits} />
    <FreeCreditsHeader allowedCredits={allowedCredits} usedCredits={usedCredits} />
    <TradesSection projectId={projectId} />
    <div className={styles.additionalFeaturesContainerCreditExperiment}>
      <AdditionalContent upgradeUrl={upgradeUrl} />
    </div>
    <FreeCreditButtonsView
      upgradeUrl={upgradeUrl}
      onClick={onClick}
      isUnlockProjectLoading={isUnlockProjectLoading}
    />
  </>
);
