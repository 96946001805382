import { useQuery } from '@apollo/client';
import { GET_DOCSYNC_STAGE } from './queries';
import { GetDocSyncStage, GetDocSyncStageVariables } from './types/GetDocSyncStage';

export const useGetDocSyncStage = (stageId: number, shouldSkip: boolean) => {
  const { data, loading, error } = useQuery<GetDocSyncStage, GetDocSyncStageVariables>(
    GET_DOCSYNC_STAGE,
    {
      variables: {
        stageId,
      },
      skip: shouldSkip,
    },
  );

  const stage = data?.stage.__typename === 'UnrestrictedStage' ? data.stage : undefined;

  return {
    data: {
      stage,
      documentIntegration: data?.getDocumentIntegration,
    },
    loading,
    hasError: error !== undefined,
  };
};
