import { CONTACT_US_OPEN_EVENT, ContactUsOpenEventPayload } from '@ascension/js/app/help';
import { EnquiryNature } from '@ascension/_gqltypes/subbie.generated';

export const openContactUs = (licenseState: string, userPhone?: string): void => {
  const phoneText = userPhone ? `\n\nMy contact number is ${userPhone}.` : '';
  const enquiry = `I’m interested in adding a license that includes ${licenseState}.${phoneText}`;

  document.dispatchEvent(
    new CustomEvent<ContactUsOpenEventPayload>(CONTACT_US_OPEN_EVENT, {
      detail: { enquiry, enquiryNature: EnquiryNature.UPGRADE },
    }),
  );
};

export const getWordWithArticle = (word: string): string => {
  const vowels = 'aeiou';
  const firstLetter = word.charAt(0).toLowerCase();
  const article = vowels.includes(firstLetter) ? 'an' : 'a';
  return `${article} ${word}`;
};

/** SUBBIE_CREDIT_EXPERIMENT_ALPHA_CODE */
export const numberToWord = (number: number): string => {
  switch (number) {
    case 1:
      return 'one';
    case 3:
      return 'three';
    case 10:
      return 'ten';
    default:
      return '';
  }
};
