import { useContext } from 'react';
import { AccountContext } from './BuilderAccountProvider';
import {
  BuilderAccountWithExperiments_currentUser_account_realUsers as RealUser,
  BuilderAccountWithExperiments_currentUser as CurrentUser,
} from './types/BuilderAccountWithExperiments';
import { ExperimentName, FeatureName } from '@ascension/_gqltypes/builder.generated';

export const useInExperiment = (name: ExperimentName): boolean =>
  useContext(AccountContext)?.inExperiment(name) || false;

export const useRealUsers = (): RealUser[] => useContext(AccountContext)?.realUsers ?? [];

export const useCurrentUser = (): CurrentUser | null =>
  useContext(AccountContext)?.currentUser ?? null;

export const useHasFeature = (feature: FeatureName): boolean =>
  useContext(AccountContext)?.hasFeature(feature) || false;

export const useIsUniversalDrawerEnabled = (): boolean =>
  useHasFeature(FeatureName.NETWORK_UNIFIED_PROFILE);

export const useIsNetworkConnectionsEnabled = (): boolean =>
  useHasFeature(FeatureName.NETWORK_CONNECTIONS);

export const useUserImperialSetting = () => {
  const currentUser = useCurrentUser();
  return currentUser?.imperialUnitsSetting;
};
