import { gql } from '@apollo/client';

export const PackageLettingSchedule = gql`
  fragment PackageLettingSchedule on Package {
    id
    title
    fullSet
    quotesDueAt
    lettingScheduleDetails {
      id
      priority
      rfqsSentByDate
      quotesDueAtInterval
      quotesDueAt
      letByDateInterval
      letByDate
      startOnSiteDateInterval
      startOnSiteDate
      budgetAmount
      actualAmount
      isAwarded
      awardedToRfq {
        id
        companyName
        contact {
          company {
            id
            name
          }
        }
      }
      assignedToUser {
        id
        fullName
      }
      latestNote {
        id
        text
      }
      noteCount
      customColumnValues {
        column {
          identifier
        }
        value
      }
    }
    activeRfqs {
      id
      quotingStatus
      quoteDueAt
      contact {
        company {
          id
        }
      }
    }
  }
`;
