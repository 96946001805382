import {
  StatusConnectedIcon,
  StatusNotConnectedIcon,
  Tooltip,
} from '@estimateone/frontend-components';

type ConnectionIndicatorProps = {
  connected?: boolean;
};

export const ConnectionIndicator = ({ connected }: ConnectionIndicatorProps) =>
  connected ? (
    <Tooltip
      text="This company is part of the E1 Network. You are connected and can invite them to quote."
      tooltipId="network-connected-tooltip"
    >
      <StatusConnectedIcon />
    </Tooltip>
  ) : (
    <Tooltip
      text="This company is part of the E1 Network. Connect to invite them to quote."
      tooltipId="network-notconnnected-tooltip"
    >
      <StatusNotConnectedIcon />
    </Tooltip>
  );
