import { ReactNode } from 'react';
import { NotificationsLoadingIcon } from './index';
import ErrorBoundary from '../../../shared/ErrorBoundary';

const NotificationsErrorBoundary = ({ children }: { children?: ReactNode }) => (
  <ErrorBoundary category="notifications" displayedError={<NotificationsLoadingIcon />}>
    {children}
  </ErrorBoundary>
);

export default NotificationsErrorBoundary;
