import $ from 'jquery';
import Quill, { QuillOptionsStatic } from 'quill';

type QuillExtendedConfig = {
  useExistingData?: boolean;
  formElementSelector?: string;
  editorElementSelector?: string;
  quillConfig?: QuillOptionsStatic | undefined;
};

type QuillConfig = Required<QuillExtendedConfig>;

const defaultConfiguration: QuillConfig = {
  useExistingData: false,
  formElementSelector: '[data-quill-form-element]',
  editorElementSelector: '[data-quill-editor]',
  quillConfig: {
    theme: 'snow',
    placeholder: 'Enter text here...',
    modules: {
      toolbar: [
        [{ header: [false, 2, 1] }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
      ],
    },
  },
};

export default function quillify(selector: string, options: QuillExtendedConfig) {
  const configuration = options ? { ...defaultConfiguration, ...options } : defaultConfiguration;
  const $quillWrappers = $(selector);

  $quillWrappers.each((index, wrapper) => {
    const $wrapper = $(wrapper);
    const $formElement = $wrapper.find(configuration.formElementSelector).eq(0);
    const quill = new Quill(
      $wrapper.find(configuration.editorElementSelector)[0],
      configuration.quillConfig,
    );

    if (configuration.useExistingData) {
      const existingData = $formElement.val();
      if (existingData) {
        // eslint-disable-next-line fp/no-mutation
        quill.root.textContent = existingData.toString();
      }
    }

    quill.on('text-change', () => {
      $formElement.val(quill.root.innerHTML);
      $formElement.trigger('change');
    });
  });
}
