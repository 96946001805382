/* eslint-disable react/jsx-props-no-spreading */
import { useField } from 'react-final-form';
import { DatePicker, DatePickerProps } from '@estimateone/frontend-components';
import { FieldWrapper } from '../FieldWrapper';

export type DatePickerFieldProps = {
  field: string;
} & Omit<DatePickerProps, 'onChange'>;

export const DatePickerField = ({ field, id, label, ...props }: DatePickerFieldProps) => {
  const {
    input: { value, ...fieldInput },
    meta: { error, submitError, touched },
  } = useField<Date>(field);

  return (
    <FieldWrapper errors={error || submitError} hideErrorMessages={false} showErrors={touched}>
      {/* Container is required for the datepicker to attach itself to */}
      <div>
        <DatePicker id={id} label={label} selected={value || null} {...props} {...fieldInput} />
      </div>
    </FieldWrapper>
  );
};
