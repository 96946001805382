import { ButtonSize } from '@estimateone/frontend-components';

export enum CTAVariant {
  Green = 'green',
  Yellow = 'yellow',
}

export enum CTASize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export const CTAButtonSize = {
  [CTASize.Small]: ButtonSize.Small,
  [CTASize.Medium]: ButtonSize.Medium,
  [CTASize.Large]: ButtonSize.Large,
};
