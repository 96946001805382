import { useQuery } from '@apollo/client';
import { GET_BUILDER_CONTACT_LIST_ENTRIES } from '@builder/features/SubbieNetworkInvitations/hooks/queries';
import { GetBuilderContactListEntries } from '@builder/features/SubbieNetworkInvitations/hooks/types/GetBuilderContactListEntries';

export const useGetBuilderContactListEntries = () => {
  const { data, loading, error } = useQuery<GetBuilderContactListEntries>(
    GET_BUILDER_CONTACT_LIST_ENTRIES,
  );

  return {
    contactLists: data?.defaultAddressBook.lists,
    error,
    loading,
  };
};
