import { AddressSearchFieldTypes } from '@shared/form/AddressSearchFields/AddressSearchFields';
import { ServiceAreaFieldTypes } from '@shared/form/ServiceAreaFields/ServiceAreaFields';

export enum OfficeDetailsFormTypes {
  ADD = 'ADD_OFFICE_DETAILS_FORM',
  EDIT = 'EDIT_OFFICE_DETAILS_FORM',
}

export enum OfficeDetailsFieldNames {
  AccountOfficeId = 'accountOfficeId',
  Name = 'name',
  OfficeEmail = 'officeEmail',
  PhoneNumber = 'phoneNumber',
  Website = 'website',
}

export enum OfficeDetailsFieldLabels {
  OfficeEmail = 'Office Email',
  PhoneNumber = 'Office Phone',
  Website = 'Website',
}

export type OfficeDetailsFieldValues = AddressSearchFieldTypes &
  ServiceAreaFieldTypes & {
    [OfficeDetailsFieldNames.OfficeEmail]: string | null;
    [OfficeDetailsFieldNames.PhoneNumber]: string | null;
    [OfficeDetailsFieldNames.Website]: string | null;
  };

export type OfficeDetailsFormErrors = Partial<
  Record<keyof OfficeDetailsFieldValues, string[] | undefined>
>;

export type EditOfficeDetailsFieldValues = OfficeDetailsFieldValues & {
  [OfficeDetailsFieldNames.AccountOfficeId]: GraphQLEntityId;
  [OfficeDetailsFieldNames.Name]: string;
};
