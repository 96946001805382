import { useEffect } from 'react';
import Modal from 'react-modal';
import { BroadcastChannel } from 'broadcast-channel';
import wait from '../../../js/utils/wait';
import { reportError } from '../../helpers/errorReporter';
import ProjectSlider from './ProjectSlider';
import LegacyProjectPaywall from './ProjectSlider/ProjectPaywalls/LegacyProjectPaywall';
import ProjectPaywall from './ProjectSlider/ProjectPaywalls/ProjectPaywall';
import { PROJECT_UNLOCKED_WITH_CREDIT } from './ProjectSlider/ProjectPaywalls/ProjectPaywall/SubblierRoadblockAdditionalFeatures/events';
import LoadingSpinnerFloating from '../../shared/LoadingSpinner/LoadingSpinnerFloating';
import { ConsultantDetailsAccessProvider } from '@subbie/context/ConsultantDetailsAccessContext';
import { useApplyStatusUpdate } from '@subbie/context/WatchlistProvider/hooks';
import { useProjectSubbieSummary, useProjectApi } from './hooks';
import { EntityId } from '../../../types';
import { GetProjectSliderData_projectForSlider as SliderProject } from './types/GetProjectSliderData';
import { ProjectSubbieSummary_projectSubbieSummary as ProjectSubbieSummary } from '@subbie/modal/types/ProjectSubbieSummary';

type ProjectSliderGroupProps = {
  projectId: EntityId;
  removeModal: () => void;
  target?: string;
};

type SliderOrRoadblockChoiceProps = {
  projectId: EntityId;
  projectSliderData: SliderProject;
  projectSubbieSummary?: ProjectSubbieSummary;
  handleClose: () => void;
};

const SliderOrRoadblockChoice = ({
  projectId,
  projectSliderData,
  projectSubbieSummary,
  handleClose,
}: SliderOrRoadblockChoiceProps) => {
  const { project, notes: projectNotes, projectActivityLogs } = projectSliderData;
  const { __typename, hasDocs, address } = project;

  if (__typename === 'ViewableNoticeboardProject') {
    // Everyone sees the same non-paywall slider for now
    return (
      <ProjectSlider
        project={project}
        projectNotes={projectNotes}
        projectLogs={projectActivityLogs}
        projectSubbieSummary={projectSubbieSummary}
        removeModal={handleClose}
      />
    );
  }

  const { redactedReason } = project;

  // Features slider - all accounts, but fall back if no state could be resolved
  if (address.state) {
    return <ProjectPaywall project={project} reason={redactedReason} removeModal={handleClose} />;
  }

  // Note: International addresses generally don't have a state component, so if an AU user is trying
  // to view an international project they are not licensed for (e.g. via a direct link), the project
  // will come back as "redacted" 50/50 and this legacy error/paywall will trigger (which is a tad confusing)
  // TODO: find a way to guess state so we do not need to fall back?
  reportError(
    {
      name: 'ProjectStateMissingError',
      message: `The address for ${projectId} does not have a state`,
    },
    {
      extra: {
        redactedReason,
        shortAddress: address.shortAddress,
      },
    },
  );

  return (
    <LegacyProjectPaywall
      projectId={projectId}
      removeModal={handleClose}
      reason={redactedReason}
      hasDocs={hasDocs || false} // TODO: why is this nullable?
    />
  );
};

const ProjectSliderGroup = ({
  projectId,
  removeModal,
  target = '#project-modal',
}: ProjectSliderGroupProps) => {
  const applyStatusUpdate = useApplyStatusUpdate();

  useEffect(() => {
    Modal.setAppElement('body');

    if (target && target !== '#project-modal') {
      const timer = setTimeout(() => {
        window.location.replace(target);
      }, 400);
      return () => clearTimeout(timer);
    }
    return undefined;
  }, [target]);

  const {
    projectSliderData,
    refetch: refetchProjectSliderData,
    loading: projectSliderDataLoading,
  } = useProjectApi(projectId);
  const { projectSubbieSummary, loading: projectSubbieSummaryLoading } =
    useProjectSubbieSummary(projectId);

  useEffect(() => {
    // Make sure the jQuery managed parts of the app know about the latest watchlist status
    // TODO: Remove this once the watchlist table is rewritten in react and can share a WatchlistProvider with the project slider.
    // Note: we need the check for the query loading state here, otherwise we fire two pointless events, because we initially
    // render with stale data out of the cache
    if (!projectSliderData || projectSliderDataLoading) {
      return;
    }
    const status = projectSliderData?.project.watchlistEntry?.status;
    if (status) {
      applyStatusUpdate(projectSliderData.project.id, status);
    }
  }, [applyStatusUpdate, projectSliderData]);

  useEffect(() => {
    const broadcastChannel = new BroadcastChannel('project_slider');

    const handler = async (data: string) => {
      if (data === PROJECT_UNLOCKED_WITH_CREDIT) {
        await refetchProjectSliderData();
      }
    };
    broadcastChannel.addEventListener('message', handler);

    // cleans up event listeners
    return () => broadcastChannel.removeEventListener('message', handler);
  }, [refetchProjectSliderData]);

  if (!projectId) return null;
  if (!projectSliderData || projectSubbieSummaryLoading) return <LoadingSpinnerFloating />;

  // Required to allow slider to animate closing before unmount
  const handleClose = () => wait(300).then(() => removeModal());

  return (
    <ConsultantDetailsAccessProvider>
      <SliderOrRoadblockChoice
        projectId={projectId}
        projectSliderData={projectSliderData}
        projectSubbieSummary={projectSubbieSummary}
        handleClose={handleClose}
      />
    </ConsultantDetailsAccessProvider>
  );
};

export default ProjectSliderGroup;
