import { useEffect, useState } from 'react';
import {
  MutationFunctionOptions,
  MutationHookOptions,
  MutationResult,
  OperationVariables,
  useMutation,
} from '@apollo/client';
import { DocumentNode } from 'graphql/language/ast';

type Entity = OperationVariables;

type GenericGraphQLType = { __typename: string };

type GraphQLType<T = string> = {
  __typename: T;
};

type Error = GraphQLType<'Error'> & {
  field: string | null;
  message: string;
};

type ErrorList = Error[];

type ErrorUnionMember = GraphQLType<'Errors'> & {
  errors: ErrorList;
};

/**
 * Get the top-level response field so consuming components do
 * not have to destructure
 */
const getMutationRootField = <T>(mutation: DocumentNode): keyof T => {
  const definitions = mutation.definitions[0];
  // @ts-ignore
  const { selections } = definitions.selectionSet;
  return selections[0].name.value;
};

interface ValidatedMutationResult<T> {
  responseData?: T;
  validationErrors?: ErrorList;
  loading: boolean;
  called: boolean;
}

type ValidatedMutationTuple<TData, TResolved, TVariables> = [
  (options?: MutationFunctionOptions<TData, TVariables>) => Promise<MutationResult<TData>>,
  ValidatedMutationResult<TResolved>,
];

/**
 * The standard useMutation hook, with error handling and automatic unpacking
 * of the response or our validation errors union
 */
export function useValidatedMutation<
  TData,
  TVariables extends Entity,
  TResolved extends GenericGraphQLType,
>(
  mutation: DocumentNode,
  options?: MutationHookOptions<TData, TVariables>,
): ValidatedMutationTuple<TData, TResolved, TVariables> {
  const rootField = getMutationRootField<TData>(mutation);

  const [loading, setLoading] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<ErrorList>([]);
  const [responseData, setResponseData] = useState<TResolved>();

  const [mutate, { loading: isLoading, data, error, called }] = useMutation<TData, TVariables>(
    mutation,
    options,
  );

  if (error) throw Error(error.message);

  useEffect(() => {
    setLoading(isLoading);
    if (data) {
      const firstDataNode = data[rootField] as unknown as TResolved | ErrorUnionMember;

      if ('errors' in firstDataNode) {
        setValidationErrors(firstDataNode.errors);
        return;
      }

      setResponseData(firstDataNode as TResolved);
    }
  }, [data, isLoading, rootField]);

  // @ts-ignore
  return [mutate, { loading, validationErrors, responseData, called }];
}
