import { reportError } from '../../../../helpers/errorReporter';
import { notificationTypeMap } from './notificationTypeMap';
import { appendOrCreate, buildDisplayNotification, formatData } from './utils';
import { DisplayNotification, Notification } from '../types';
import { isGroupableTransformer, Transformer } from './types';
import { NotificationType } from '@ascension/_gqltypes/builder.generated';

export const getTransformer = (type: NotificationType, version: number): Transformer | undefined =>
  notificationTypeMap[type][version];

const getDisplayNotificationKey = ({
  id,
  type,
  topic,
  version,
  viewedAt,
}: Notification): string | undefined => {
  const topicMap = topic.reduce(
    (acc: Map<string, string>, segment) => acc.set(segment.key, segment.value),
    new Map(),
  );

  const transformer = getTransformer(type, version);
  if (transformer === undefined) {
    return undefined;
  }

  if (isGroupableTransformer(transformer)) {
    const groupingKey = transformer.getGroupingKey(topicMap);
    return [type, groupingKey, viewedAt || 'new'].join('-');
  }
  return [type, id, viewedAt || 'new'].join('-');
};

const transformNotifications = (notifications: Notification[]): DisplayNotification => {
  const headNotification = notifications[0];
  const { type, version, data } = headNotification;
  const formattedData = formatData(data);

  const transformer = getTransformer(type, version) as Transformer;
  const notificationKey = getDisplayNotificationKey(headNotification) as string;

  if (isGroupableTransformer(transformer) && notifications.length > 1) {
    return buildDisplayNotification(
      notificationKey,
      headNotification,
      transformer.buildGroupText(formattedData, notifications.length),
      transformer.buildGroupLink(formattedData) ?? null,
    );
  }

  return buildDisplayNotification(
    notificationKey,
    headNotification,
    transformer.buildText(formattedData),
    transformer.buildLink(formattedData) ?? null,
  );
};

export const groupNotifications = (notifications: Notification[]): DisplayNotification[] => {
  const notificationGroups = notifications.reduce((groups, notification) => {
    const key = getDisplayNotificationKey(notification);
    if (key === undefined) {
      const error = new Error(
        `Undefined transformer implementation for notification type ${notification.type}, version ${notification.version}`,
      );
      reportError(error);
      return groups;
    }

    return appendOrCreate<string, Notification>(groups, key, notification);
  }, new Map<string, Notification[]>());

  return Array.from(notificationGroups.values(), (notificationGroup) =>
    transformNotifications(notificationGroup),
  );
};
