import { useState } from 'react';
import { SpeciFinderKeywordMatchesSlider } from '@subbie/modal/SpeciFinderKeywordMatchesSlider';
import KeywordsButton from '../KeywordsButton';
import { EntityId } from '@ascension/types';

type SpeciFinderSliderButtonProps = {
  stageId: EntityId;
  onKeywordsButtonClick: () => void;
};

const SpeciFinderSliderButton = ({
  stageId,
  onKeywordsButtonClick,
}: SpeciFinderSliderButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleKeywordsButtonClick = () => {
    setIsOpen(true);
    onKeywordsButtonClick();
  };

  return (
    <>
      <KeywordsButton stageId={stageId} onClick={handleKeywordsButtonClick} />
      <SpeciFinderKeywordMatchesSlider
        isPaneOpen={isOpen}
        stageId={stageId}
        handleClose={() => setIsOpen(false)}
      />
    </>
  );
};

export default SpeciFinderSliderButton;
