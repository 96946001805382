import { ReactNode, createContext, useContext, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import {
  GET_IS_AUTHORISED_TO_OPT_INTO_DIRECTORY,
  GET_SSC_OPT_OUT_PREFERENCE,
  GET_USER_CONTEXT,
} from './queries';
import {
  isAuthorisedToOptIntoDirectoryQuery,
  SscOptOutPreferenceQuery,
  UserContextQuery,
  UserDetailsFragment as UserDetails,
} from './queries.generated';
import { AccountType } from '@ascension/_gqltypes/profiles.generated';

export type ViewerContextProps =
  | {
      loading: false;
      // lets us only do one null check
      context: {
        user: UserDetails;
        isAdmin: () => boolean;
        isOwnAccount: () => boolean;
      };
      hasOptedOutOfSSC: boolean;
      isAuthorisedToOptIntoDirectory: boolean;
    }
  | {
      loading: true;
      context: null;
      hasOptedOutOfSSC: boolean;
      isAuthorisedToOptIntoDirectory: boolean;
    };

export const ViewerContext = createContext<ViewerContextProps>({
  loading: true,
  context: null,
  hasOptedOutOfSSC: false,
  isAuthorisedToOptIntoDirectory: false,
});

type ViewerProviderProps = {
  children: ReactNode;
  profileAccountId: number | null;
};

const DefaultContext: ViewerContextProps = {
  loading: true,
  context: null,
  hasOptedOutOfSSC: false,
  isAuthorisedToOptIntoDirectory: false,
};

/**
 * The viewer is the current authenticated user
 */
export const ViewerProvider = ({ children, profileAccountId }: ViewerProviderProps) => {
  const { data: userData } = useQuery<UserContextQuery>(GET_USER_CONTEXT);

  const accountType = userData?.currentUser.account?.type;
  const isSubbieOrSupplier =
    accountType && [AccountType.SUBCONTRACTOR, AccountType.SUPPLIER].includes(accountType);

  const { data: sscOptOutData } = useQuery<SscOptOutPreferenceQuery>(GET_SSC_OPT_OUT_PREFERENCE, {
    skip: !isSubbieOrSupplier,
  });
  const { data: canOptInToDirectoryData } = useQuery<isAuthorisedToOptIntoDirectoryQuery>(
    GET_IS_AUTHORISED_TO_OPT_INTO_DIRECTORY,
    {
      skip: !isSubbieOrSupplier,
    },
  );

  const hasOptedOutOfSSC = sscOptOutData?.hasOptedOutOfSSC ?? false;
  const isAuthorisedToOptIntoDirectory =
    canOptInToDirectoryData?.isAuthorisedToOptIntoDirectory ?? false;

  const value: ViewerContextProps = useMemo(() => {
    if (!userData) {
      return DefaultContext;
    }

    const isOwnAccount =
      profileAccountId !== null && userData.currentUser?.account?.id === profileAccountId;

    return {
      loading: false,
      context: {
        user: userData.currentUser,
        isAdmin: () => userData.currentUser.isAdmin && isOwnAccount,
        isOwnAccount: () => isOwnAccount,
      },
      hasOptedOutOfSSC,
      isAuthorisedToOptIntoDirectory,
    };
  }, [userData, hasOptedOutOfSSC, isAuthorisedToOptIntoDirectory]);

  return <ViewerContext.Provider value={value}>{children}</ViewerContext.Provider>;
};

export const useViewerContext = () => {
  const ctx = useContext(ViewerContext);

  if (ctx === undefined) {
    throw new Error('useHasOptedOutOfSSC may only be used within a ViewerProvider');
  }

  return ctx;
};
