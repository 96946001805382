import { useCallback, useState } from 'react';
import { LoadingSpinner } from '@estimateone/frontend-components';
import {
  Button,
  ButtonSize,
  ButtonVariant,
} from '@estimateone/frontend-components/src/components/Button';
import { ConnectionPreferenceSlider } from '@subbie/modal/ConnectionPreferenceSlider';
import { ConnectionPreferenceFormProvider } from '@subbie/modal/ConnectionPreferenceSlider/ConnectionPreferenceFormContext';
import { useCurrentAccount } from '@subbie/context/AccountProvider/hooks';
import { Action, Event, useAnalytics } from '@ascension/components/hooks/Analytics';
import { InterestLevel } from '@ascension/_gqltypes/subbie.generated';
import { GetConnectionPreference_connectionPreference as ConnectionPreference } from '@subbie/modal/ProjectSlider/ProjectSliderBody/types/GetConnectionPreference';
import styles from './styles.scss';

type ConnectionPreferenceCardProps = {
  watchlistStatus: InterestLevel;
  preference: ConnectionPreference | undefined;
  loading: boolean;
};

const ConnectionPreferenceCard = ({
  preference,
  loading,
  watchlistStatus,
}: ConnectionPreferenceCardProps) => {
  const [showSlider, setShowSlider] = useState(false);
  const { addEvent } = useAnalytics(Event.INTERACT);
  const hasSharedContacts =
    preference?.deletedAt === null && typeof preference?.contactId === 'number';
  const account = useCurrentAccount();

  const onCloseSlider = useCallback(() => {
    setShowSlider(false);
  }, []);

  const onOpenSlider = useCallback(async () => {
    addEvent({
      action: hasSharedContacts
        ? Action.SSC_SHARE_AND_CONNECT_EDIT_BUTTON_CLICKED
        : Action.SSC_SHARE_AND_CONNECT_BUTTON_CLICKED,
      supplierId: account?.id ?? 0,
      projectId: preference?.projectId ?? 0,
    });
    setShowSlider(true);
  }, [hasSharedContacts, preference, addEvent, account?.id]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!preference) {
    return null;
  }

  const cardContent = hasSharedContacts
    ? {
        title: `${preference.viewCount} views`,
        description: 'Discover who has viewed your contact details.',
        buttonText: 'Edit details',
      }
    : {
        title: 'Promote your products to subcontractors',
        description:
          "Let subcontractors know you're the go-to for specified or equivalent products on this project.",
        buttonText: 'Promote products',
      };

  return (
    <div>
      <div className={styles.shareContactsCardContainer}>
        <div className={styles.textContainer}>
          <div className={styles.header}>{cardContent.title}</div>
          <div className={styles.text}>{cardContent.description}</div>
        </div>

        <div className={styles.buttonContainer}>
          <Button variant={ButtonVariant.Grey} size={ButtonSize.Small} onClick={onOpenSlider}>
            {cardContent.buttonText}
          </Button>
        </div>
      </div>
      <ConnectionPreferenceFormProvider preference={preference} watchlistStatus={watchlistStatus}>
        <ConnectionPreferenceSlider isPaneOpen={showSlider} handleClose={onCloseSlider} />
      </ConnectionPreferenceFormProvider>
    </div>
  );
};

export default ConnectionPreferenceCard;
