import {
  Button,
  ButtonSize,
  ButtonVariant,
  Icon,
  IconName,
} from '@estimateone/frontend-components';
import styles from './styles.scss';

type SliderBackButtonProps = {
  handleClose: () => void;
};

export const SliderBackButton = ({ handleClose }: SliderBackButtonProps) => (
  <div className={styles.backButton}>
    <Button
      variant={ButtonVariant.Transparent}
      size={ButtonSize.Small}
      className={styles.sliderBackButton}
      onClick={handleClose}
    >
      <Icon
        name={IconName.ArrowLeft}
        className={styles.leftIcon}
        marginRight="small"
        size="0.8em"
      />
      <span>Back</span>
    </Button>
  </div>
);
