import $ from 'jquery';
import Routing from 'routing';
import E1Request from '../classes/E1Request';
import Modal from '../classes/Modal';

$(() => {
  $(document).on('mfpOpen', () => {
    const magnificPopupInstance = $.magnificPopup.instance;
    const container = magnificPopupInstance.contentContainer;
    const $modal = $(container);
    const $exportDiv = $modal.find('.pending_awarded_subbie_export_notice');

    if (!$exportDiv.length) return undefined;

    const exportId: number = $exportDiv.data('export-id');

    const awardedSubbieExportStatusRoute = Routing.generate('app_addressbookawarded_exporttoxls', {
      exportId,
    });

    const getExportResponse = () =>
      new E1Request<{
        success: boolean;
        error: string | null;
        presignedLink: string | null;
      }>(awardedSubbieExportStatusRoute)
        .submit()
        .then(({ presignedLink }) => {
          if (presignedLink) {
            window.open(presignedLink, '_blank');
            Modal.closeAll();
            return;
          }
          setTimeout(() => getExportResponse(), 1000);
        });

    return getExportResponse();
  });
});
