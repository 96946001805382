import { Row, Col, Card, CardVariant, joinClassNames } from '@estimateone/frontend-components';
import {
  SECRET_STATUS_ACTIVE_CURRENT,
  SECRET_STATUS_UPCOMING,
} from '@builder/features/SsoSecrets/SsoSecretManagement';
import { displayHumanReadableDate, getNumberOfDaysToExpire } from './utils';
import styles from './styles.scss';

type SsoSecretCardProps = {
  secret: string;
  expiry: string;
  created: string;
  createdBy: string | null;
  status: string;
};

const getStatusText = (status: string) => {
  switch (status) {
    case SECRET_STATUS_ACTIVE_CURRENT:
      return 'Active Secret';
    case SECRET_STATUS_UPCOMING:
      return 'Upcoming Secret';
    default:
      return 'Expired Secret';
  }
};

const getCardVariant = (status: string) => {
  switch (status) {
    case SECRET_STATUS_ACTIVE_CURRENT:
      return CardVariant.Green;
    case SECRET_STATUS_UPCOMING:
      return CardVariant.Blue;
    default:
      return CardVariant.Grey;
  }
};

export const SsoSecretCard = ({
  secret,
  expiry,
  created,
  createdBy,
  status,
}: SsoSecretCardProps) => (
  <Row>
    <Col span={8}>
      <Card variant={getCardVariant(status)}>
        <Card.Body>
          <p>
            <span className={joinClassNames(styles.secretStatus, styles.bold)}>
              {getStatusText(status)}
            </span>
            {secret}
          </p>
          <p>
            <span className={styles.bold}>Added</span>
            {displayHumanReadableDate(created)} by {createdBy}
          </p>
          <p>
            <span className={styles.bold}>Duration</span>
            {displayHumanReadableDate(created)} to {displayHumanReadableDate(expiry)}{' '}
            {status === SECRET_STATUS_ACTIVE_CURRENT &&
              `- expires in ${getNumberOfDaysToExpire(expiry)} day(s)`}
          </p>
        </Card.Body>
      </Card>
    </Col>
  </Row>
);
