import $ from 'jquery';
import { E1Response } from '../classes/E1Request';
import Form from '../classes/Form';
import gtmDataLayerPush from '../utils/google_tag_manager_helper';
import { AccountTypeNumeric } from '../../enums';
import { SignUpEvent } from '../../enums/Analytics';

type SignUpResponseMessage = {
  title: string;
  message: string;
};
/* eslint-disable camelcase */
type SignupResponse = E1Response & {
  message: SignUpResponseMessage;
  account_id: number;
  user_id: number;
  redirect_uri?: string;
};

const showThankYouOverlay = ({ message }: SignupResponse) => {
  const $overlay = $('.thank-you-outer');

  $overlay.find('.title').text(message.title);
  $overlay.find('.message').text(message.message);
  $overlay.fadeIn(400).addClass('open').css('display', 'flex');
};

const trackSignup = (event: string, $form?: JQuery): void => {
  gtmDataLayerPush({ event });
  const accountType = parseInt(
    <string>$form?.find('input[name="subbie_account_sign_up[type]"]:checked').val(),
  );
  switch (accountType) {
    case AccountTypeNumeric.Supplier:
      gtmDataLayerPush({ event: SignUpEvent.SUPPLIER_SIGN_UP });
      break;
    case AccountTypeNumeric.Subcontractor:
      gtmDataLayerPush({ event: SignUpEvent.SUBBIE_SIGN_UP });
      break;
    default:
  }
};

$(() => {
  const $body = $('body');
  $body.find('.first-focus').trigger('focus');

  $body.on('submit', 'form.signup-form', async (e) => {
    e.preventDefault();
    const $form = $(e.currentTarget);
    const response = await new Form<SignupResponse>($form).submit();

    if (!response.success) return;

    if (response.message !== undefined) {
      trackSignup(SignUpEvent.GENERIC, $form);
      showThankYouOverlay(response);
    }
  });

  $body.on('submit', 'form.signup-invite-form', async (e) => {
    e.preventDefault();
    const $form = $(e.currentTarget);
    const response = await new Form<SignupResponse>($form).submit();

    if (!response.success) return;

    if (response.message !== undefined) {
      trackSignup(SignUpEvent.INVITE_SIGNUP);
      if (response.redirect_uri !== undefined) {
        // eslint-disable-next-line fp/no-mutation
        window.location.href = response.redirect_uri;
      } else {
        showThankYouOverlay(response);
      }
    }
  });

  $body.on('submit', 'form.signup-form-marketing', async (e) => {
    e.preventDefault();
    const $form = $(e.currentTarget);
    const response = await new Form<SignupResponse>($form).submit();

    if (!response.success) return;

    if (response.account_id !== undefined && response.user_id !== undefined) {
      trackSignup(SignUpEvent.GENERIC, $form);
      // Clear sign up form fields
      $form[0].reset();
      $form.find("[name*='trades']").trigger('change'); // The select2 needs encouragement to refresh
    }
  });

  $body.on('form-submitted-success', '.existing-user-form', (e, response) => {
    if (response.message !== undefined) {
      const $form = $(e.currentTarget);
      const { message } = response;
      const $modalTitle = $form.parent().find('.modal-title');

      $form.find('.modal-footer').remove();
      $modalTitle.html(message.title);
      $form.find('.message').text(message.message);
    }
  });
});
