import {
  Button,
  ButtonProps,
  ButtonVariant,
  joinClassNames,
} from '@estimateone/frontend-components';
import { CTAButtonSize, CTASize, CTAVariant } from './enums';
import styles from './styles.scss';

type CTAProps = {
  text: string;
  variant?: CTAVariant;
  size?: CTASize;
  primaryButtonAttr?: Omit<ButtonProps, 'variant' | 'size'>;
  secondaryButtonAttr?: Omit<ButtonProps, 'variant' | 'size'>;
};

export const CTA = ({
  text,
  variant = CTAVariant.Green,
  size = CTASize.Medium,
  primaryButtonAttr,
  secondaryButtonAttr,
}: CTAProps) => (
  <div className={joinClassNames(styles.cta, styles[variant], styles[size])}>
    <span>{text}</span>
    {primaryButtonAttr && (
      <div className={styles.buttonContainer}>
        <Button variant={ButtonVariant.Primary} size={CTAButtonSize[size]} {...primaryButtonAttr}>
          {primaryButtonAttr.children}
        </Button>
        {secondaryButtonAttr && (
          <Button
            variant={ButtonVariant.Secondary}
            size={CTAButtonSize[size]}
            {...secondaryButtonAttr}
          >
            {secondaryButtonAttr.children}
          </Button>
        )}
      </div>
    )}
  </div>
);
