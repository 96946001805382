import { BillingFrequency } from '../PlanDetailsSection';
import { useCreditCardDetails } from './useCreditCardDetails';
import { useGetCurrentLiteLicenseDetails } from './useGetCurrentLiteLicenseDetails';
import { useLiteToEssentialUpgradePricing } from './useLiteToEssentialUpgradePricing';
import { BillingCycle } from '@ascension/_gqltypes/subbie.generated';

export const useLiteToEssentialCheckoutData = () => {
  const currentLicenseDetails = useGetCurrentLiteLicenseDetails();
  const { creditCardDetails, loading: ccDetailsLoading } = useCreditCardDetails();
  const pricing = useLiteToEssentialUpgradePricing();

  if (ccDetailsLoading || !pricing || !currentLicenseDetails) {
    return {
      loading: true,
    };
  }

  const billingFrequency: BillingFrequency =
    currentLicenseDetails.billingCycle === BillingCycle.TWELVE_MONTHS ? 'annual' : 'monthly';

  const ongoingAmount = pricing.essentialLicenseOngoingAmounts[billingFrequency];
  const proratedAmount = pricing.upgradeLiteToEssentialProratedAmounts[billingFrequency];

  return {
    billingCycle: currentLicenseDetails.billingCycle,
    billingFrequency,
    creditCardDetails: creditCardDetails
      ? {
          lastFourDigits: creditCardDetails.lastFourDigits,
          expiryMonth: creditCardDetails.expiryMonth,
          expiryYear: creditCardDetails.expiryYear,
          brand: creditCardDetails.brand,
          isExpired: creditCardDetails.isExpired,
        }
      : undefined,
    ongoingAmount: ongoingAmount.amount,
    proratedAmountDue: proratedAmount.amountDue.amount,
    proratedCreditsApplied: proratedAmount.creditsApplied?.amount,
  };
};
