import { useQuery } from '@apollo/client';
import { INVITE_TO_QUOTE_ACTIVE_STAGES } from './queries';
import { GetInviteToQuoteActiveStages } from './types/GetInviteToQuoteActiveStages';

export const useGetStages = () => {
  const { data, loading } = useQuery<GetInviteToQuoteActiveStages>(INVITE_TO_QUOTE_ACTIVE_STAGES);

  const unrestrictedStages = data?.activeStages.filter(
    (stage) => stage.__typename === 'UnrestrictedStage',
  );

  return { unrestrictedStages, loading };
};
