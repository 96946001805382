export const addWordBreakOpportunityAtChar = (email: string, separator = '@') => {
  const [user, domain] = email.split(separator, 2);

  return (
    <>
      {user}@<wbr />
      {domain}
    </>
  );
};
