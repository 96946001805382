import { Slider } from '@estimateone/frontend-components';
import { SubbieListSliderContent } from '@subbie/modal/SubbieListSlider/SubbieListSliderContent';
import { SubbieListSliderHeader } from '@subbie/modal/SubbieListSlider/SubbieListSliderHeader';
import { useModalContext } from '@subbie/context/ModalContext';
import { Project } from '@subbie/modal/ProjectSlider/types';

type SubbieListSliderProps = {
  readonly isPaneOpen: boolean;
  readonly project: Project;
  readonly handleClose: () => void;
  readonly onUnlock: () => void;
  readonly unlockInProgress: boolean;
};

export const SubbieListSlider = ({
  isPaneOpen,
  project,
  handleClose,
  onUnlock,
  unlockInProgress,
}: SubbieListSliderProps) => {
  const { removeModal } = useModalContext();

  const closeParentAndSelf = () => {
    handleClose();
    removeModal();
  };

  return (
    <Slider rootAppElement="#root" isOpen={isPaneOpen} onRequestClose={closeParentAndSelf}>
      {isPaneOpen && (
        <>
          <SubbieListSliderHeader handleClose={handleClose} project={project} />
          <SubbieListSliderContent
            projectId={project.id}
            watchlistStatus={project.watchlistEntry?.status}
            onUnlock={onUnlock}
            unlockInProgress={unlockInProgress}
            closeSliderContent={handleClose}
          />
        </>
      )}
    </Slider>
  );
};
