import { gql } from '@apollo/client';

export const DELETE_ACCOUNT_OFFICE = gql`
  mutation DeleteAccountOffice($accountOfficeId: EntityId!) {
    deleteAccountOfficeV2(accountOfficeId: $accountOfficeId) {
      ... on ProfileOffice {
        id
        deletedAt
      }
      ... on Errors {
        errors {
          message
        }
      }
    }
  }
`;
