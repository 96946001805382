import { gql } from '@apollo/client';
import { PendingAddendumDetails } from './fragments';

export const GET_PENDING_ADDENDUM = gql`
  query GetPendingAddendum($stageId: EntityId!) {
    pendingAddendum(stageId: $stageId) {
      ...PendingAddendumDetails
    }
  }
  ${PendingAddendumDetails}
`;

export const GET_DOWNLOADED_PACKAGES = gql`
  query GetDownloadedPackages($input: DownloadedPackagesInput!) {
    downloadedPackages(input: $input) {
      id
      title
      discipline
      awardedAt
      docChanges {
        package {
          id
        }
        changeSet {
          document {
            id
          }
          timestamp
        }
        documentRegister
      }
      numToBeNotified
      rfqNotDownloadedResponded
      rfqNotDownloadedNoResponse
    }
  }
`;

export const GET_NOTIFY_PROGRESS = gql`
  query GetNotifyProgress($stageId: EntityId!) {
    notifyProgress(stageId: $stageId) {
      matrixChangeProcessed
      processedNotifications
      totalNotifications
    }
  }
`;
