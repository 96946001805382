import $ from 'jquery';

// blasphemous function that will allow us to download files
// based on post requests - so help me god
export default function downloadFromPost(url: string, data: Record<string, string | number>) {
  const form = $('<form/>').attr('action', url).attr('method', 'post');

  const keyProps = Object.keys(data);
  keyProps.forEach((prop) => {
    form.append($('<input/>').attr('type', 'hidden').attr('name', prop).attr('value', data[prop]));
  });

  // submit form then remove it.
  form.appendTo('body').trigger('submit').remove();
}
