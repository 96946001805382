import { useQuery } from '@apollo/client';
import { LIST_RESTRICTED_STAGE_ACCESS } from '../queries';
import {
  ListRestrictedStageAccess,
  ListRestrictedStageAccessVariables,
  ListRestrictedStageAccess_listRestrictedStageAccess as RestrictedStageAccess,
} from '../types/ListRestrictedStageAccess';
import { EntityId } from '@ascension/types';

const defaultArray: RestrictedStageAccess[] = [];

export const useListRestrictedStageAccess = (stageId: EntityId) => {
  const { loading, data, refetch } = useQuery<
    ListRestrictedStageAccess,
    ListRestrictedStageAccessVariables
  >(LIST_RESTRICTED_STAGE_ACCESS, {
    variables: { stageId },
  });

  return {
    restrictedStageAccess: data?.listRestrictedStageAccess ?? defaultArray,
    loading,
    refetch,
  };
};
