import { useLazyQuery } from '@apollo/client';
import { INVITE_TO_QUOTE_STAGE } from '../queries';
import {
  GetInviteToQuoteStage,
  GetInviteToQuoteStageVariables,
} from '../types/GetInviteToQuoteStage';

export const useGetInviteToQuoteStage = () => {
  const [getInviteToQuoteStage, { data, loading }] = useLazyQuery<
    GetInviteToQuoteStage,
    GetInviteToQuoteStageVariables
  >(INVITE_TO_QUOTE_STAGE, { fetchPolicy: 'no-cache' });

  return {
    stage: data?.stage?.__typename === 'UnrestrictedStage' ? data.stage : undefined,
    loading,
    getInviteToQuoteStage,
  };
};
