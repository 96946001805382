import { gql } from '@apollo/client';

export const NetworkContacts = {
  fragments: {
    builderContact: gql`
      fragment BuilderContact on BuilderContact {
        id
        firstName
        lastName
        fullName
        phone
        builderEmail: email
        position
        isPreferred
        contactTypes
        hasEmailError
      }
    `,
    connectedContact: gql`
      fragment ConnectedContact on ConnectedContact {
        id
        firstName
        lastName
        fullName
        phone
        email
        isPrimary
        preferredFirstName
        preferredLastName
        preferredFullName
        preferredPhone
        position
        isPreferred
        contactTypes
        hasEmailError
      }
    `,
    networkCompanyContact: gql`
      fragment NetworkCompanyContact on NetworkCompanyContact {
        id
        firstName
        lastName
        fullName
        phone
        email
      }
    `,
    mergedContact: gql`
      fragment MergedContact on MergedContact {
        id
        firstName
        lastName
        fullName
        phone
        email
        isPrimary
        preferredFirstName
        preferredLastName
        preferredFullName
        preferredPhone
        position
        isPreferred
        contactTypes
        hasEmailError
      }
    `,
  },
};
