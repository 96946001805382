import { useMutation } from '@apollo/client';
import { SET_PACKAGE_LETTING_SCHEDULE_ASSIGNED_TO_USER } from '../mutations';
import {
  SetPackageLettingScheduleAssignedToUser,
  SetPackageLettingScheduleAssignedToUserVariables,
} from '../types/SetPackageLettingScheduleAssignedToUser';
import { EntityId } from '@ascension/types';
import { Package } from '@builder/features/ProcurementLettingSchedule/types';
import { ValueLabelOption } from '@estimateone/frontend-components/src/components/Form/Select/types';

export type AssignedToOption = ValueLabelOption<EntityId | null>;

export const useAssignPackageToUser = (originalPackage: Package) => {
  const [mutate] = useMutation<
    SetPackageLettingScheduleAssignedToUser,
    SetPackageLettingScheduleAssignedToUserVariables
  >(SET_PACKAGE_LETTING_SCHEDULE_ASSIGNED_TO_USER);

  const assignPackageToUser = async (userOption: AssignedToOption | null) =>
    mutate({
      variables: {
        input: {
          packageId: originalPackage.id,
          userId: userOption?.value,
        },
      },
      optimisticResponse: originalPackage?.lettingScheduleDetails
        ? {
            setPackageLettingScheduleAssignedToUser: {
              ...originalPackage,
              lettingScheduleDetails: {
                ...originalPackage.lettingScheduleDetails,
                assignedToUser: userOption?.value
                  ? {
                      __typename: 'User',
                      id: userOption.value,
                      fullName: userOption.label,
                    }
                  : null,
              },
            },
          }
        : undefined,
    });

  return { assignPackageToUser };
};
