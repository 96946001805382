import { gql } from '@apollo/client';

export const DELETE_NOTE = gql`
  mutation DeleteNote($projectNoteId: EntityId!) {
    deleteNoteOnProject(projectNoteId: $projectNoteId) {
      ... on ProjectNote {
        id
        deletedAt
      }
      ... on Errors {
        errors {
          field
          message
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
