import { ComponentProps } from 'react';
import {
  Button,
  ButtonProps,
  ButtonSize,
  ButtonVariant,
  E1Link,
} from '@estimateone/frontend-components';
import styles from './styles.scss';

type UpsellCardProps = {
  headerText: string;
  text: string;
  primaryButtonAttr?: Pick<ButtonProps, 'onClick' | 'children'>;
  learnMoreAttr?: Omit<ComponentProps<typeof E1Link>, 'children'>;
};

export const UpsellCard = ({
  headerText,
  text,
  primaryButtonAttr,
  learnMoreAttr,
}: UpsellCardProps) => (
  <div className={styles.upsellCard}>
    <div className={styles.textContainer}>
      <span className={styles.header}>{headerText}</span>
      <span className={styles.text}>
        {text}&nbsp;
        {learnMoreAttr && (
          <E1Link rel="noopener noreferrer" {...learnMoreAttr}>
            Learn more
          </E1Link>
        )}
      </span>
    </div>

    {primaryButtonAttr && (
      <div className={styles.buttonContainer}>
        <Button variant={ButtonVariant.Electric} size={ButtonSize.Small} {...primaryButtonAttr}>
          {primaryButtonAttr.children}
        </Button>
      </div>
    )}
  </div>
);
