import { Fragment } from 'react';
import { joinClassNames } from '@estimateone/frontend-components';
import HighlightedText from '../../../../shared/HighlightedText';
import TruncatedText from '../../../../shared/TruncatedText';
import { DocumentHighlightsForStageKeywordMatches_documentHighlightsForStageKeywordMatches_keywordWithDocumentHighlights as DocumentHighlightsByKeyword } from '../../types/DocumentHighlightsForStageKeywordMatches';
import styles from './styles.scss';

type SpeciFinderDocumentMatchesTableProps = {
  documentHighlightsByKeyword: DocumentHighlightsByKeyword[];
};

export const SpeciFinderDocumentMatchesTable = ({
  documentHighlightsByKeyword,
}: SpeciFinderDocumentMatchesTableProps) => (
  <div
    className={joinClassNames(
      'd-flex mb-2 justify-content-between align-items-center',
      styles.keywordMatchTable,
    )}
  >
    <table className="table keywords-table table-data-align-top">
      <thead>
        <tr>
          <th>Keyword</th>
          <th>Document</th>
          <th>Extract</th>
        </tr>
      </thead>
      <tbody>
        {documentHighlightsByKeyword.map((keywordWithHighlight) => {
          const documentCount = keywordWithHighlight.documentHighlights.length;
          const [firstDocument] = keywordWithHighlight.documentHighlights;
          const { documentHighlights } = keywordWithHighlight;
          const { keyword } = keywordWithHighlight;
          const isRowSpanRequired = documentCount > 1;

          const firstRowDocument = (
            <>
              <td>
                <TruncatedText text={firstDocument.name} limit={25} />
              </td>
              <td className={styles.extract}>
                <HighlightedText text={firstDocument.highlight} term={keyword} />
              </td>
            </>
          );

          const documentsHighlightsMapping = documentHighlights
            .slice(1, documentCount)
            .map(({ documentId, name, highlight }) => (
              <tr key={documentId}>
                <td>
                  <TruncatedText text={name} limit={25} />
                </td>
                <td className={styles.extract}>
                  <HighlightedText text={highlight} term={keyword} />
                </td>
              </tr>
            ));

          const rowSpan = isRowSpanRequired ? documentCount : undefined;

          return (
            <Fragment key={keyword}>
              <tr className={styles.rowStyles}>
                <td rowSpan={rowSpan} valign="top" className={styles.keyword}>
                  {keyword}
                </td>
                {firstRowDocument}
              </tr>
              {documentsHighlightsMapping}
            </Fragment>
          );
        })}
      </tbody>
    </table>
  </div>
);
