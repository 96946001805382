/** SUBBIE_CREDIT_EXPERIMENT_ALPHA_CODE */
import {
  SingleFreeCreditContentView,
  LitePlanCreditsContentView,
  MultiFreeCreditsContentView,
} from '../RoadblockCreditExperiments/ContentView';

type RoadblockCreditExperimentUnlockContentProps = {
  upgradeUrl: string;
  onClick: () => void;
  isUnlockProjectLoading: boolean;
  projectId: number;
  allowedCredits: number;
  usedCredits: number;
  removeModal: () => void;
};

export const RoadblockCreditExperimentUnlockContent = ({
  upgradeUrl,
  onClick,
  isUnlockProjectLoading,
  projectId,
  allowedCredits,
  usedCredits,
  removeModal,
}: RoadblockCreditExperimentUnlockContentProps) => {
  if (allowedCredits === 1) {
    return (
      <SingleFreeCreditContentView
        upgradeUrl={upgradeUrl}
        onClick={onClick}
        isUnlockProjectLoading={isUnlockProjectLoading}
        projectId={projectId}
        allowedCredits={allowedCredits}
      />
    );
  }
  if (allowedCredits === 3) {
    return (
      <MultiFreeCreditsContentView
        upgradeUrl={upgradeUrl}
        onClick={onClick}
        isUnlockProjectLoading={isUnlockProjectLoading}
        projectId={projectId}
        allowedCredits={allowedCredits}
        usedCredits={usedCredits}
      />
    );
  }
  if (allowedCredits === 10) {
    return (
      <LitePlanCreditsContentView
        onClick={onClick}
        isUnlockProjectLoading={isUnlockProjectLoading}
        projectId={projectId}
        allowedCredits={allowedCredits}
        usedCredits={usedCredits}
        removeModal={removeModal}
      />
    );
  }
  return null;
};
