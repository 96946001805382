import { SearchServiceArea } from '@builder/features/SubbieNetwork/atoms';
import { SelectedPlace } from '../PlaceSuggestingSelect';
import { LocationOption } from '../types';

const serviceArea = (
  adminLevel1: string | undefined,
  country: string | undefined,
  resultTypes: string[] | undefined,
): SearchServiceArea | undefined => {
  if (!adminLevel1 || !country || !resultTypes) return undefined;
  const isCountrySearch = resultTypes?.includes('country');
  const isAustralianStateSearch =
    country === 'Australia' && resultTypes?.includes('administrative_area_level_1');

  if (isCountrySearch) {
    return {
      country,
    };
  }

  if (isAustralianStateSearch) {
    return {
      country,
      state: adminLevel1,
    };
  }

  return undefined;
};

export const locationOptions = (selectedPlace: SelectedPlace): LocationOption => {
  const { lat, lon, adminLevel1, country, resultTypes, locationLabel } = selectedPlace;

  return {
    lat,
    lon,
    locationLabel,
    serviceArea: serviceArea(adminLevel1, country, resultTypes),
  };
};
