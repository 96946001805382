import { useMutation } from '@apollo/client';
import { UPDATE_OFFICE_DETAILS } from './mutations';
import {
  UpdateOfficeDetailsMutation,
  UpdateOfficeDetailsMutationVariables,
} from './mutations.generated';
import {
  OfficeDetailsFieldNames,
  EditOfficeDetailsFieldValues,
} from '../OfficeDetailsFields/types';

const getServiceAreaObject = (
  values: EditOfficeDetailsFieldValues,
): UpdateOfficeDetailsMutationVariables['input']['serviceArea'] => {
  switch (values.serviceAreaType) {
    case 'by-country':
      return {
        countryId: values.serviceAreaCountry,
      };
    case 'by-states':
      return {
        boundaryIds: values.serviceAreaStates
          .map((state) => state?.value)
          .filter((value): value is number => value !== undefined),
      };
    case 'by-travel-distance':
      return {
        radius: values.serviceAreaRadius,
      };
    default:
      throw new Error('Unknown service area type');
  }
};

const transformValues = (
  values: EditOfficeDetailsFieldValues,
): UpdateOfficeDetailsMutationVariables => {
  if (!values.streetAddress || !values.postcode) {
    throw new Error("Street address and postcode are required fields, they shouldn't ever be null");
  }

  return {
    input: {
      accountOfficeId: values[OfficeDetailsFieldNames.AccountOfficeId],
      name: values[OfficeDetailsFieldNames.Name],
      phone: values[OfficeDetailsFieldNames.PhoneNumber],
      website: values[OfficeDetailsFieldNames.Website],
      email: values[OfficeDetailsFieldNames.OfficeEmail],
      address: {
        address1: values.streetAddress,
        suburb: values.suburb,
        stateId: parseInt(values.state?.value?.toString() || '0', 10) || null,
        postcode: values.postcode,
        city: values.city,
        province: values.province,
      },
      serviceArea: getServiceAreaObject(values),
    },
  };
};

export const useSubmitEditOfficeDetails = () => {
  const [updateOfficeDetails, { data }] = useMutation<
    UpdateOfficeDetailsMutation,
    UpdateOfficeDetailsMutationVariables
  >(UPDATE_OFFICE_DETAILS);

  const errors =
    data?.updateOfficeDetails.__typename === 'Errors' ? data.updateOfficeDetails.errors : undefined;

  const submit = async (values: EditOfficeDetailsFieldValues) => {
    const { data: result } = await updateOfficeDetails({
      variables: transformValues(values),
    });

    return result?.updateOfficeDetails.__typename !== 'Errors';
  };

  return { submit, errors };
};
