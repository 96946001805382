import { gql } from '@apollo/client';

export const CREATE_HANDOVER_PROCUREMENT_STAGE = gql`
  mutation HandoverToProcurementStage($input: HandoverToProcurementStageInput!) {
    handoverToProcurementStage(input: $input) {
      ... on UnrestrictedStage {
        id
        type
      }
    }
  }
`;
