import { useQuery } from '@apollo/client';
import CreateQuoteFromRfqForm from '../../../features/QuotesFeature/CreateQuoteFromRfqForm';
import PageLoadingSpinner from '../../../../shared/LoadingSpinner/PageLoadingSpinner';
import QuotePageLayout from '../../../../shared/Quotes/Page/QuotePageLayout';
import { CurrencyInfoContextProvider } from '@shared';
import { graphqlTypeToString } from '@shared/Quotes/utils/Address';
import { useQuoteFileManagerLocaleForStage } from '../../../hooks/FileManager';
import { RFQ_AND_STAGE } from './queries';
import { getCurrencyInfo } from '@ascension/enums';
import { RfqForQuoteCreate, RfqForQuoteCreateVariables } from './types/RfqForQuoteCreate';
import { EntityId } from '@ascension/types';

export type CreateQuoteFromRfqPageProps = {
  rfqId: EntityId;
  stageId: EntityId;
  returnUrl: string;
};

type PageContentProps = {
  rfqId: EntityId;
  stageId: EntityId;
  cancelUrl: string;
  successUrl: string | null;
};

const PageContent = ({ rfqId, stageId, cancelUrl, successUrl }: PageContentProps) => {
  const { data } = useQuery<RfqForQuoteCreate, RfqForQuoteCreateVariables>(RFQ_AND_STAGE, {
    variables: { rfqId, stageId },
  });

  const fileManagerLocale = useQuoteFileManagerLocaleForStage(stageId);

  if (!data || !fileManagerLocale) return <PageLoadingSpinner />;

  const {
    rfq: { companyName, contactName, package: stagePackage },
    stage,
  } = data;

  if (stage.__typename !== 'UnrestrictedStage') {
    throw Error('Access Denied');
  }

  const currencyInfo = getCurrencyInfo(stage.account?.primaryCountry?.id);

  return (
    <CurrencyInfoContextProvider currencyInfo={currencyInfo}>
      <CreateQuoteFromRfqForm
        fileManagerLocale={fileManagerLocale}
        rfqId={rfqId}
        projectAddress={stage.projectAddress ? graphqlTypeToString(stage.projectAddress) : ''}
        stageName={stage.name}
        packageTitle={stagePackage?.title ?? ''}
        subbieCompanyName={companyName ?? ''}
        subbieContactName={contactName ?? ''}
        cancelUrl={cancelUrl}
        successUrl={successUrl}
      />
    </CurrencyInfoContextProvider>
  );
};

const CreateQuoteFromRfqPage = ({ returnUrl, ...props }: CreateQuoteFromRfqPageProps) => {
  const returnUrlQuery = new URL(window.location.href).searchParams.get('returnUrl');
  const cancelUrl = returnUrlQuery || returnUrl;

  return (
    <QuotePageLayout title="Create quote from invite" returnUrl={cancelUrl}>
      <PageContent cancelUrl={cancelUrl} successUrl={returnUrlQuery} {...props} />
    </QuotePageLayout>
  );
};

export default CreateQuoteFromRfqPage;
