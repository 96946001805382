import $ from 'jquery';
import Routing from 'routing';
import E1Request from '../classes/E1Request';
import { subtractFromDocsDownloadedCount } from './doc_viewer';

$(() => {
  const $body = $('body');
  const $warningBox = $('[data-identifier="warning-box"]');
  const downloadUrls = {
    missing: null,
    allTransmitted: null,
  };

  /**
   * @param {Number} stageId
   * @param {String} urlType
   * @return Promise<String>
   */
  const getDownloadUrlForStageDocuments = async (stageId, urlType) => {
    const { downloadUrl } = await new E1Request(
      Routing.generate(`app_constructionstagedocument_download${urlType}`, { id: stageId }),
    ).submit();

    return downloadUrl;
  };

  /**
   * @param {string} url
   */
  const downloadDocuments = (url) => {
    $warningBox.hide();
    subtractFromDocsDownloadedCount();
    window.location = url;
  };

  $body.on('click', '.btn-download-transmitted', async (downloadClick) => {
    if (downloadUrls.allTransmitted !== null) {
      window.location = downloadUrls.allTransmitted;
    } else {
      const stageId = $(downloadClick.currentTarget).attr('data-stage');

      // The url for missing docs cannot be found after getting transmitted docs - save it first
      if (downloadUrls.missing === null) {
        downloadUrls.missing = await getDownloadUrlForStageDocuments(stageId, 'missing');
      }

      downloadUrls.allTransmitted = await getDownloadUrlForStageDocuments(stageId, 'transmitted');
      downloadDocuments(downloadUrls.allTransmitted);
    }
  });

  $body.on('click', '.btn-download-missing', async (downloadClick) => {
    if (downloadUrls.missing !== null) {
      window.location = downloadUrls.missing;
    } else {
      const stageId = $(downloadClick.currentTarget).attr('data-stage');
      downloadUrls.missing = await getDownloadUrlForStageDocuments(stageId, 'missing');
      downloadDocuments(downloadUrls.missing);
    }
  });
});
