import { memo, useCallback } from 'react';
import { useField } from 'react-final-form';
import { E1Address } from '@ascension/js/classes/Address';
import { AddressSuggestingSelect } from '../AddressSuggestingSelect/AddressSuggestingSelect';
import { FieldWrapper } from '@shared/FinalForm/Field';
import { ShortStateNameMap } from '@ascension/enums';

export const AddressSearchField = memo(
  ({ countryId, formId }: { countryId: number; formId: string }) => {
    const {
      input,
      meta: { error, submitError },
    } = useField('streetAddress');
    const stateField = useField('state');
    const suburbField = useField('suburb');
    const cityField = useField('city');
    const provinceField = useField('province');
    const postcodeField = useField('postcode');

    const stateOptions = Object.entries(ShortStateNameMap).map(([id, label]) => ({
      value: parseInt(id),
      label,
    }));

    const longToShortStateNameMap: { [key: string]: string } = {
      'New South Wales': 'NSW',
      Victoria: 'VIC',
      Queensland: 'QLD',
      'South Australia': 'SA',
      'Western Australia': 'WA',
      Tasmania: 'TAS',
      'Northern Territory': 'NT',
      'Australian Capital Territory': 'ACT',
    };

    const onChange = useCallback(
      (option: E1Address | string | null) => {
        if (typeof option === 'string') {
          input.onChange(option.trim());
        }

        if (option instanceof E1Address) {
          const { address, address2, address3, suburb, city, province, state, postcode } = option;
          const streetAddress = [address, address2, address3].filter(Boolean).join(', ');
          if (streetAddress) {
            input.onChange(streetAddress.trim());
          }

          if (suburb && suburbField) {
            suburbField.input.onChange(suburb);
          }

          if (city && cityField) {
            cityField.input.onChange(city);
          }

          if (province && provinceField) {
            provinceField.input.onChange(province);
          }

          if (state && stateField) {
            const shortState = longToShortStateNameMap[state];
            const stateOption = stateOptions.find((stateOpt) => stateOpt.label === shortState);
            if (stateOption) {
              stateField.input.onChange(stateOption);
            }
          }

          if (postcode) {
            postcodeField.input.onChange(postcode);
          }
        }
      },
      [input, suburbField, cityField, provinceField, postcodeField, stateField],
    );

    return (
      <FieldWrapper errors={error || submitError} showErrors>
        <AddressSuggestingSelect
          countryId={countryId}
          onLocationSelected={onChange}
          formId={formId}
          value={input.value ?? null}
          placeholder="Enter an address..."
        />
      </FieldWrapper>
    );
  },
);
