import { CONDEETS_DATA_STARTS_YEAR } from '@subbie/modal/ProjectSlider/ProjectSliderBody/ConsultantsTable/const';
import styles from '../styles.scss';

type ConsultantsTableEmptyProps = { createdAt: Date; cutoffYear?: number };
export const ConsultantsTableEmpty = ({
  createdAt,
  cutoffYear = CONDEETS_DATA_STARTS_YEAR,
}: ConsultantsTableEmptyProps) => {
  const withinDateRange = createdAt.getFullYear() >= cutoffYear;

  return (
    <div className={styles.consultantsEmpty}>
      {withinDateRange ? (
        <>
          <p>We&apos;re currently gathering the consultant details for you.</p>
          <p>Please check back later.</p>
        </>
      ) : (
        <p>We do not currently have data for projects created before {cutoffYear}</p>
      )}
    </div>
  );
};
