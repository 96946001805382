import $ from 'jquery';
import Routing from 'routing';
import debounce from 'lodash/debounce';
import E1Request from '../classes/E1Request';
import Location from '../classes/Location';
import ServerDataList from '../classes/ServerDataList';

const columnNames = <const>['is_favourite', 'name', 'distance', 'current_count', 'awarded_count'];

type ColumnNames = (typeof columnNames)[number];
type ColIndices = { [k in ColumnNames]: number };

type DataTablesResponse<TableDatum> = {
  data: TableDatum[];
  draw: 0 | 1;
  recordsFiltered: number;
  recordsTotal: number;
  success: true;
};

/* eslint-disable camelcase */
type DbTableDatum = {
  account_id: number;
  account_name: string;
  address_latitude: number;
  address_longitude: number;
  address_province: string | null;
  address_suburb: string;
  // Yeah, we JSON serialise numbers to strings
  // because they come out of the DB that way
  awarded_count: string;
  distance: string;
  tender_count: string;
  isFavourite: boolean;
  office_fax: string | null;
  office_phone: string | null;
  state_short_name: string;
};
/* eslint-enable */

type DbDataTablesResponse = DataTablesResponse<DbTableDatum>;

$(() => {
  $('table.builder-list')
    .toArray()
    .forEach((tbl) => {
      const $table = $(tbl);
      const $container = $table.closest('.table-container');
      const $distFilter = $container.find('select.project-distance-filter');
      const $searchInput = $container.find('input.datatable_search_input');

      const $favouritesToggle = $('.toggle-favourite');
      const $currentToggle = $('.toggle-current-tenders');
      const $awardedToggle = $('.toggle-awarded-tenders');

      const $headingRow = $table.find('tr').first().find('th');
      const colIndices: ColIndices = columnNames.reduce(
        (o, cp) => ({ ...o, [cp]: $headingRow.index($table.find(`.${cp}_col`)) }),
        {} as ColIndices,
      );

      const url = $table.data('src');

      const builderTable = new ServerDataList<DbDataTablesResponse, DbDataTablesResponse>(
        $table,
        url,
        (list) => {
          const $placeholderRow = list.$target.find('tr.data-table-placeholder').first();
          // eslint-disable-next-line fp/no-mutation, no-param-reassign
          list.table = list.$target
            .DataTable(
              $.extend({}, list.getTableDefaults(), {
                pageLength: 25,
                processing: true,
                serverSide: true,
                searchCols: [
                  null,
                  null,
                  null,
                  null,
                  null,
                  { search: $distFilter.val() },
                  false,
                  false,
                ],
                ajax(
                  data: DbDataTablesResponse,
                  cb: (data: DbDataTablesResponse) => void,
                  settings: DataTables.SettingsLegacy,
                ) {
                  list.serverRequest(data, cb, settings);
                },
                info: false,
                order: [[1, 'asc']],
                columns: [
                  {
                    orderable: false,
                    data: 'isFavourite',
                    class: 'favourite show-always',
                    render: (isFavourite: boolean, type: string, row: DbTableDatum) => {
                      if (type === 'display') {
                        const $favouriteIcon = $('<i class="icon icon-interested">');

                        const $favouriteToggle = $('<a class="favourite-toggle">')
                          .attr('data-status', isFavourite ? 1 : 0)
                          .attr('data-account-id', row.account_id)
                          .attr(
                            'title',
                            isFavourite ? 'Remove from Favourites' : 'Add to Favourites',
                          )
                          .toggleClass('active', isFavourite);

                        $favouriteToggle.append($favouriteIcon);

                        return $favouriteToggle.prop('outerHTML');
                      }
                      return isFavourite;
                    },
                  },
                  {
                    data: 'account_name',
                    name: 'account.name',
                    class: 'show-always',
                    render(
                      data: string,
                      display: string,
                      row: DbTableDatum,
                      details: DataTables.CellMetaSettings,
                    ) {
                      const template = $placeholderRow.find('td').get(details.col);
                      const $link = $(template).find('a').first();

                      return $link
                        .addClass('data-table-link')
                        .attr(
                          'href',
                          Routing.generate('app_network_accountview', {
                            id: row.account_id,
                          }),
                        )
                        .text(data)
                        .prop('outerHTML');
                    },
                  },
                  {
                    data: 'office_phone',
                    name: 'office.phone',
                    orderable: false,
                    render: $.fn.dataTable.render.text(),
                  },
                  {
                    data: 'office_fax',
                    name: 'office.fax',
                    orderable: false,
                    render: $.fn.dataTable.render.text(),
                  },
                  {
                    data: 'address_suburb',
                    name: 'address.suburb',
                    render: $.fn.dataTable.render.text(),
                  },
                  {
                    data: 'distance',
                    name: 'distance',
                    type: 'num',
                    render(distance: number, display: string) {
                      switch (display) {
                        case 'filter':
                        case 'sort':
                          return distance;
                        default: {
                          const $spanCtn = $('<div>').append(
                            $('<span>', {
                              class: 'locator-dist-calc',
                              text: Location.readableDistance(distance / 1000),
                            }),
                          );

                          return $spanCtn.html();
                        }
                      }
                    },
                  },
                  {
                    data: 'tender_count',
                    name: 'tender_count',
                    render: $.fn.dataTable.render.text(),
                  },
                  {
                    data: 'awarded_count',
                    name: 'awarded_count',
                    render: $.fn.dataTable.render.text(),
                  },
                ],
              }),
            )
            .on('init.dt', () => {
              list.$container.addClass('has-loaded');
            });
        },
      );

      const getToggleValueAndInvert = (onToggleEvt: JQuery.ClickEvent): boolean => {
        const $btn = $(onToggleEvt.currentTarget);
        const active = $btn.attr('data-active') === '1';

        $btn.attr('data-active', active ? 0 : 1);

        return active;
      };

      $favouritesToggle.on('click', (onToggleEvt) => {
        builderTable.table
          .column(colIndices.is_favourite)
          .search(String(!getToggleValueAndInvert(onToggleEvt)))
          .draw();
      });
      $currentToggle.on('click', (onToggleEvt) => {
        builderTable.table
          .column(colIndices.current_count)
          .search(String(!getToggleValueAndInvert(onToggleEvt)))
          .draw();
      });
      $awardedToggle.on('click', (onToggleEvt) => {
        builderTable.table
          .column(colIndices.awarded_count)
          .search(String(!getToggleValueAndInvert(onToggleEvt)))
          .draw();
      });

      $distFilter.on('change', () => {
        const distVal = $distFilter.val();
        if (distVal) {
          builderTable.table.column(colIndices.distance).search(distVal.toString()).draw();
        }
      });

      const throttledBuilderSearch = debounce<(e: JQuery.KeyUpEvent) => void>(
        ({ currentTarget }) => {
          const builderNameQuery = ($(currentTarget).val() || '').toString().trim();

          builderTable.table.column(colIndices.name).search(builderNameQuery).draw();
        },
        600,
      );

      $searchInput.on('keyup', throttledBuilderSearch);

      $('body').on('click', '.favourite-toggle', async ({ currentTarget }) => {
        const { accountId: id, status } = $(currentTarget).data();

        if (status) {
          await new E1Request(
            Routing.generate('app_addressbook_remfavourite', { id }),
            'POST',
          ).submit();
        } else {
          await new E1Request(
            Routing.generate('app_addressbook_addfavourite', { id }),
            'POST',
          ).submit();
        }

        builderTable.table.draw('full-hold');
      });
    });
});
