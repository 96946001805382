import { E1Link, LinkVariant, TagVariant, Tag } from '@estimateone/frontend-components';
import { useRestrictedItemList } from './hooks';
import { ProjectInterestedSubbie } from './types';
import styles from './styles.scss';

type CompanyDetailsTableCellProps = {
  subbie: ProjectInterestedSubbie;
  onClickName: (accountId: number) => void;
};

export const CompanyDetailsTableCell = ({ subbie, onClickName }: CompanyDetailsTableCellProps) => {
  const { firstItems, notShownCount } = useRestrictedItemList(subbie.stockTrades, 55);
  return (
    <>
      <E1Link
        className={styles.subbieName}
        variant={LinkVariant.Primary}
        onClick={() => onClickName(subbie.accountId)}
        title={subbie.name}
      >
        {subbie.name}
      </E1Link>
      <div className={styles.tradeContainer}>
        {firstItems.map((item) => (
          <Tag variant={TagVariant.StockTrade} text={item.name} key={item.id} />
        ))}
        {notShownCount > 0 && (
          <Tag variant={TagVariant.StockTrade} text={`+${notShownCount} more`} />
        )}
      </div>
    </>
  );
};
