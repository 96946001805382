import $ from 'jquery';
import Routing from 'routing';
import _ from 'underscore';
import E1Request from '../classes/E1Request';

export default function ConstructionDirectoryList($target, init_func, fetchUrl) {
  this.$target = $target;
  this.$container = $target.closest('.contact-table-container');
  this.$emptyPlaceholder = this.$container.find('.empty-text-placeholder');
  this.data = [];
  this.contactType = $target.attr('data-contact-type');
  this.stageId = $target.attr('data-stage-id');
  this.tableShowing = true;
  this.init_func = init_func;
  this.fetchUrl = fetchUrl;
  this.$controlPanel = this.$container.find('.table-control-panel-container');

  const self = this;
  self.fetch(self.init_func);
}

ConstructionDirectoryList.prototype.drawTable = function () {
  const self = this;
  self.toggleTableDisplay();

  if (self.table != null) {
    self.table.rows().remove();
    self.table.rows.add(self.data).draw();
  }
};

ConstructionDirectoryList.prototype.fetch = function (callback) {
  const self = this;
  const request = new E1Request(self.fetchUrl, 'GET');
  request.extraCallback = (response) => {
    const dataArr = [];
    if (typeof response.data !== 'undefined') {
      $.each(response.data, (index, val) => {
        const data = val;
        data.DT_RowId = `dt_data_${data.id}`;
        dataArr.push(data);
      });
    }

    self.data = dataArr;
    self.stageHasPackageViewAccess = response.stageHasPackageViewAccess;

    if (typeof callback !== 'undefined') {
      callback(self);
    }
  };
  request.submit();
};

ConstructionDirectoryList.prototype.updateTable = function (fetch, cb) {
  const self = this;
  if (fetch) {
    self.fetch($.proxy(self.drawTable, self));
  } else {
    self.drawTable();
  }
  if (typeof cb !== 'undefined') {
    cb();
  }
};

ConstructionDirectoryList.prototype.getData = function () {
  return this.data;
};

ConstructionDirectoryList.prototype.toggleTableDisplay = function (immediate) {
  const self = this;

  if (immediate == null) {
    immediate = true;
  }

  if (self.data.length == 0) {
    if (self.$emptyPlaceholder.length > 0 && self.tableShowing == true) {
      if (immediate) {
        self.$target.hide();
        self.$emptyPlaceholder.show();
      } else {
        self.$target.fadeOut(() => {
          self.$emptyPlaceholder.fadeIn();
        });
      }
      self.tableShowing = false;
    } else if (self.$emptyPlaceholder.length == 0 && self.tableShowing == true) {
      self.$target.show();

      self.tableShowing = true;
    }
  } else if (self.$emptyPlaceholder.length > 0 && self.tableShowing == false) {
    if (immediate) {
      self.$target.show();
      self.$emptyPlaceholder.hide();
    } else {
      self.$emptyPlaceholder.fadeOut(() => {
        self.$target.fadeIn();
      });
    }
    self.tableShowing = true;
  }
};

ConstructionDirectoryList.prototype.init = function (self) {
  if (self.$controlPanel.length > 0) {
    self.$controlPanel.prependTo('.bottom-bar');
    self.$controlPanel.removeClass('hide');
    self.$controlPanel.on('click', '.user-correspondence-trigger', (evt, data) => {
      const route = Routing.generate('app_usercorrespondence_newslider', { stageId: self.stageId });
      const request = new E1Request(route, 'POST', {
        selected: self.getSelectedIds(),
      });
      request.submit();
    });
  }

  self.updateSelectedTotals();

  self.$target.on('change', '.select-checkbox', function () {
    const id = parseInt($(this).attr('data-id'));
    const checked = $(this).is(':checked');
    const $row = $(this).closest('tr');
    const row = $row.get(0);
    const rowData = self.table.row(row).data();

    rowData.selected = checked;

    self.data = self.table.rows().data();

    self.updateSelectedTotals();
  });

  self.$target.on('change', '.select-all-rows', function () {
    const checked = $(this).is(':checked');

    $.each(self.data, (i, row) => {
      row.selected = checked;
    });
    self.updateTable();
    self.updateSelectedTotals();
  });
};

ConstructionDirectoryList.prototype.getSelectedIds = function () {
  const self = this;
  const selected = _.filter(self.data, (obj) => obj.selected == true);
  const userIds = _.pluck(selected, 'user_id');
  return userIds;
};

ConstructionDirectoryList.prototype.updateSelectedTotals = function () {
  const self = this;
  const selected = _.filter(self.data, (obj) => obj.selected == true);

  const selectedCount = selected.length;
  const totalCount = self.data.length;

  self.$target.trigger('selected-count-update', {
    selected,
  });

  self.$target.find('.select-all-rows').prop('checked', selectedCount >= totalCount);

  $('.select_count', self.$controlPanel).each(function () {
    if (selectedCount == 0) {
      self.$controlPanel.addClass('hide');
    }

    const text = selectedCount >= totalCount ? $(this).attr('data-all-text') : selectedCount;
    $(this).text(text);

    if (selectedCount > 0) {
      self.$controlPanel.removeClass('hide');
    }
  });
};
