import { gql } from '@apollo/client';
import { WatchlistProjectAssignment } from '@subbie/context/WatchlistProvider/fragments';

export const ADD_CONNECTION_PREFERENCE = gql`
  mutation AddConnectionPreference($input: AddConnectionPreferenceInput!) {
    addConnectionPreference(input: $input) {
      ... on ConnectionPreferenceUpdateSuccess {
        currentConnectionPreference {
          id
          projectId
          projectName
          contactId
          contactName
          type
          note
          viewCount
          deletedAt
        }
        currentWatchlistInterestLevel
        currentWatchlistProjectAssignment {
          ...WatchlistProjectAssignment
        }
      }
      ... on Errors {
        errors {
          field
          message
        }
      }
    }
  }
  ${WatchlistProjectAssignment}
`;

export const REMOVE_CONNECTION_PREFERENCE = gql`
  mutation RemoveConnectionPreference($projectId: EntityId!) {
    removeConnectionPreference(projectId: $projectId) {
      ... on ConnectionPreferenceRemoveSuccess {
        currentConnectionPreference {
          id
          projectId
          deletedAt
        }
      }
      ... on Errors {
        errors {
          field
          message
        }
      }
    }
  }
`;

export const UPDATE_CONNECTION_PREFERENCE = gql`
  mutation UpdateConnectionPreference($input: UpdateConnectionPreferenceInput!) {
    updateConnectionPreference(input: $input) {
      ... on ConnectionPreferenceUpdateSuccess {
        currentConnectionPreference {
          id
          projectId
          projectName
          contactId
          contactName
          type
          note
          viewCount
          deletedAt
        }
        currentWatchlistInterestLevel
        currentWatchlistProjectAssignment {
          ...WatchlistProjectAssignment
        }
      }
      ... on Errors {
        errors {
          field
          message
        }
      }
    }
  }
  ${WatchlistProjectAssignment}
`;
