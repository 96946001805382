import { gql } from '@apollo/client';

export const UPDATE_PACKAGE_TITLE = gql`
  mutation UpdatePackageTitle($input: UpdatePackageTitleInput!) {
    updatePackageTitle(input: $input) {
      ... on Package {
        id
        title
      }
      ... on Errors {
        errors {
          field
          message
        }
      }
    }
  }
`;
