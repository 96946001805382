import Routing from 'routing';
import { E1Link, Slider } from '@estimateone/frontend-components';
import { SpeciFinderDocumentMatchesTable } from './SpeciFinderDocumentMatchesTable';
import { DocumentHighlightsForStageKeywordMatches_documentHighlightsForStageKeywordMatches_keywordWithDocumentHighlights as DocumentHighlightsByKeyword } from '../../types/DocumentHighlightsForStageKeywordMatches';
import styles from './styles.scss';

type SpeciFinderKeywordMatchesSliderBodyProps = {
  keywordWithDocumentHighlights: DocumentHighlightsByKeyword[];
};

export const SpeciFinderKeywordMatchesSliderBody = ({
  keywordWithDocumentHighlights,
}: SpeciFinderKeywordMatchesSliderBodyProps) => (
  <Slider.Body scrollBodyOnly>
    <div className={styles.refineKeywordsLink}>
      <E1Link link={Routing.generate('app_account_specifinder')} target="_blank">
        <span className={styles.refineKeywordsLinkText}>Refine keywords</span>
      </E1Link>
    </div>
    <SpeciFinderDocumentMatchesTable documentHighlightsByKeyword={keywordWithDocumentHighlights} />
  </Slider.Body>
);
