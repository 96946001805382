import { gql } from '@apollo/client';

export const GET_PROJECTS_KEYWORDS = gql`
  query KeywordsMatchingProject($projectIds: [EntityId!]!) {
    keywordsMatchingProjectForNoticeboardUser(projectIds: $projectIds) {
      projectId
      keywords
    }
  }
`;
