import { Icon, IconName, joinClassNames } from '@estimateone/frontend-components';
import ScreenReaderOnly from '../../../fec/ScreenReaderOnly';
import styles from './styles.scss';

type AwardedIndicatorProps = {
  className?: string;
};

export const AwardedIndicator = ({ className }: AwardedIndicatorProps) => (
  <>
    <ScreenReaderOnly>This is an awarded project</ScreenReaderOnly>
    <Icon name={IconName.Awarded} className={joinClassNames(styles.awardedIndicator, className)} />
  </>
);
