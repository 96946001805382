import $ from 'jquery';

export default class BulkEditModal {
  $modal: JQuery<HTMLElement>;
  stageId: number;
  clearDrawingTrigger: string;
  clearRevisionTrigger: string;
  clearTitleTrigger: string;
  drawingInputSelector: string;
  revisionInputSelector: string;
  titleInputSelector: string;

  constructor($modal: JQuery<HTMLElement>, stageId: number) {
    this.$modal = $modal;
    this.stageId = stageId;
    this.clearDrawingTrigger = '.clear-drawing-trigger';
    this.clearRevisionTrigger = '.clear-revision-trigger';
    this.clearTitleTrigger = '.clear-title-trigger';
    this.drawingInputSelector = '.document-drawing-selector';
    this.revisionInputSelector = '.document-revision-selector';
    this.titleInputSelector = '.document-title-selector';

    this.init();
  }

  init() {
    this.$modal.on('click', this.clearDrawingTrigger, (e) => {
      e.preventDefault();
      BulkEditModal.clearColumn(this.drawingInputSelector);
    });

    this.$modal.on('click', this.clearRevisionTrigger, (e) => {
      e.preventDefault();
      BulkEditModal.clearColumn(this.revisionInputSelector);
    });

    this.$modal.on('click', this.clearTitleTrigger, (e) => {
      e.preventDefault();
      BulkEditModal.clearColumn(this.titleInputSelector);
    });
  }

  static clearColumn(inputSelector: string) {
    const $inputs = $(inputSelector);
    $inputs.toArray().forEach(($input) => {
      $($input).val('');
    });
  }
}
