import $ from 'jquery';
import Routing from 'routing';
import E1Request from '../classes/E1Request';
import Form from '../classes/Form';
import { ListenerEvent } from '../../enums';

/* eslint-disable camelcase */
export type AddressBookContactFormSubmitResponseData = {
  contact_types: number[];
  email: string;
  first_name: string;
  full_name: string;
  last_name: string;
  id: number;
  phone?: string;
  position?: string;
  archived_at: string;
  is_preferred: boolean;
};
/* eslint-enable */

const dispatchUpdateEvent = () => {
  window.dispatchEvent(new CustomEvent(ListenerEvent.AddressBookCompanyUpdated));
};

const addContact = (companyId: number) =>
  E1Request.fromOpts({
    url: Routing.generate('app_addressbookcompanycontact_add', { id: companyId }),
    onCloseModal: dispatchUpdateEvent,
  })
    .setShowLoadingModal()
    .submit();

const editContact = (id: number) =>
  E1Request.fromOpts({
    url: Routing.generate('app_addressbookcontact_editmodal', { id }),
    onCloseModal: dispatchUpdateEvent,
  })
    .setShowLoadingModal()
    .submit();

$(() => {
  const $body = $('body');

  $body.on('click', '.addUser', ({ currentTarget }) => {
    const id = $(currentTarget).data('abc-id');
    return addContact(id);
  });

  window.addEventListener('network-profile:add-contact', async (event: CustomEvent<string>) => {
    const companyId = Number(event.detail);
    await addContact(companyId);
  });

  $body.on('click', '.edit-contact', async ({ currentTarget }) => {
    const id = $(currentTarget).data('contact-id');
    await editContact(id);
  });

  window.addEventListener('network-profile:edit-contact', async (event: CustomEvent<string>) => {
    const id = Number(event.detail);
    await editContact(id);
  });

  // This handles the submit event for both adding and editing
  $body.on('submit', 'form.add-contact-form', async (e) => {
    e.preventDefault();

    const response = await new Form<{
      success: boolean;
      context: 'ADD' | 'EDIT';
      data: AddressBookContactFormSubmitResponseData;
    }>($(e.currentTarget)).submit();
    if (response && response.success) {
      $(document).trigger('contact-table-edited');

      switch (response.context) {
        case 'ADD':
          return document.dispatchEvent(
            new CustomEvent<AddressBookContactFormSubmitResponseData>(
              ListenerEvent.AddressBookSliderContactAdded,
              { detail: response.data },
            ),
          );
        case 'EDIT':
          return document.dispatchEvent(
            new CustomEvent<AddressBookContactFormSubmitResponseData>(
              ListenerEvent.AddressBookSliderContactUpdated,
              { detail: response.data },
            ),
          );
        default:
          return undefined;
      }
    }

    return false;
  });
});
