import Routing from 'routing';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  CellProps,
  Table,
  TableColumn,
  Tag,
  TagVariant,
  Tooltip,
} from '@estimateone/frontend-components';
import { getRange } from '@shared/BudgetRange';
import { Action, useInteractAnalytics } from '@ascension/components/hooks/Analytics';
import { ButtonName } from '@ascension/components/hooks/Analytics/buttonNames';
import { ScreenName } from '@ascension/components/hooks/Analytics/screenName';
import { getCurrencyInfo } from '@ascension/enums';
import { TenderStagesReadyForHandover_tenderStagesReadyForHandover as ReadyForHandoverStage } from './types/TenderStagesReadyForHandover';
import { InterfaceToType } from '@ascension/types';
import styles from './styles.scss';

type Stage = InterfaceToType<ReadyForHandoverStage>;

type TendersReadyForHandoverTableProps = {
  tenders: Stage[];
  accessRestrictedTooltip: string;
};

const TenderProjectNameCell =
  () =>
  ({
    row: {
      original: { name, private: isPrivate, projectAddress, hasTeamAccessControl },
    },
  }: CellProps<Stage>) => (
    <div className={styles.projectDetailsContainer}>
      <div className={styles.tenderNameContainer}>
        <span className={styles.tenderName}>{name}</span>
        {isPrivate && <Tag variant={TagVariant.RfqHasDocs} text="Invite only" />}
        {hasTeamAccessControl && <Tag variant={TagVariant.RfqE1Created} text="Restricted" />}
      </div>
      {projectAddress.fullAddress && (
        <div className={styles.projectAddress}>{projectAddress.fullAddress}</div>
      )}
    </div>
  );

const StartHandoverButton =
  (trackHandoverStart: () => void, accessRestrictedTooltip: string) =>
  ({
    row: {
      original: { id, __typename },
    },
  }: CellProps<Stage>) =>
    __typename === 'UnrestrictedStage' ? (
      <Button
        variant={ButtonVariant.Secondary}
        size={ButtonSize.Small}
        href={Routing.generate('app_tender_handover', { stage_id: id })}
        onClick={trackHandoverStart}
      >
        Start
      </Button>
    ) : (
      <Tooltip tooltipId="handoverStart" text={accessRestrictedTooltip} place="left">
        <Button size={ButtonSize.Small} disabled>
          Start
        </Button>
      </Tooltip>
    );

export const TendersReadyForHandoverTable = ({
  tenders,
  accessRestrictedTooltip,
}: TendersReadyForHandoverTableProps) => {
  const { addEvent } = useInteractAnalytics();

  const trackHandoverStart = () => {
    addEvent({
      action: Action.BUTTON_CLICKED,
      buttonName: ButtonName.PROCUREMENT_PROJECTS_READY_FOR_HANDOVER_START,
      screenName: ScreenName.PROCUREMENT_PROJECT_DASHBOARD,
    });
  };

  const columns: TableColumn<Stage>[] = [
    {
      id: 'name',
      accessor: 'name',
      Header: 'Tender Project Name',
      Cell: TenderProjectNameCell(),
    },
    {
      id: 'awardedAt',
      accessor: 'awardedAt',
      Header: 'Date Awarded',
      Cell: ({
        row: {
          original: { awardedAt },
        },
      }: CellProps<Stage>) => {
        if (!awardedAt) {
          return false;
        }
        return new Date(awardedAt).toLocaleDateString('en-AU', {
          weekday: 'short',
          month: 'short',
          year: 'numeric',
          day: 'numeric',
        });
      },
    },
    {
      accessor: 'contactName',
      Header: 'Contact',
      disableSortBy: true,
    },
    {
      Header: 'Approx. Budget',
      disableSortBy: true,
      Cell: ({
        row: {
          original: { budgetAmount, account },
        },
      }: CellProps<Stage>) => {
        const currency = getCurrencyInfo(account?.primaryCountry?.id);
        return getRange(budgetAmount, currency.currencySymbol);
      },
    },
    {
      Header: 'Handover',
      disableSortBy: true,
      Cell: StartHandoverButton(trackHandoverStart, accessRestrictedTooltip),
    },
  ];

  return (
    <div className={styles.tendersTableContainer}>
      <Table<Stage> data={tenders} columns={columns} initSortColumnId="awardedAt" isInitSortDesc />
    </div>
  );
};
