import { Select, SelectSize } from '@estimateone/frontend-components';
import { FilterOption } from '.';
import { ValueLabelOption } from '@estimateone/frontend-components/src/components/Form/Select/types';
import styles from './styles.scss';

type AssignedToFilterInputProps = {
  assignedToFilterOptions: FilterOption[];
  onChange: (values: ValueLabelOption<number>[]) => void;
  value: ValueLabelOption<number>[];
};

const AssignedToFilterInput = ({
  assignedToFilterOptions,
  onChange,
  value,
}: AssignedToFilterInputProps) => (
  <Select
    id="filter-assigned-to"
    aria-label="Assigned To Filter"
    className={styles.scheduleFilter}
    label="Assigned To"
    placeholder="Filter by Team Member"
    onChange={onChange}
    size={SelectSize.Small}
    options={assignedToFilterOptions}
    isMulti
    classNamePrefix="assignToFilterSelect"
    value={value}
  />
);

export { AssignedToFilterInput };
