import { gql } from '@apollo/client';

const Errors = {
  fragments: {
    error: gql`
      fragment ApiError on Error {
        field
        message
      }
    `,
  },
};

export const UPDATE_PROFILE_DETAILS = gql`
  mutation UpdateProfileDetails($input: UpdateProfileDetailsInput!) {
    updateProfileDetails(input: $input) {
      ... on Profile {
        id
        name
        offices {
          id
          name
          isDefaultOffice
          address {
            address1
            suburb
            state {
              id
              shortName
            }
            postcode
            city
            province
            country {
              id
              name
            }
          }
          serviceArea {
            id
            type
            radius
            boundaries {
              id
              type
              name
              shortName
            }
          }
        }
        companyNumber
        businessEntityType
        isDiscoverable
      }
      ... on Errors {
        errors {
          ...ApiError
        }
      }
    }
  }
  ${Errors.fragments.error}
`;
