import { gql } from '@apollo/client';
import { NetworkContacts } from '@builder/api/fragments/NetworkContacts';

const UNIVERSAL_PROFILE_CONTACTABLE_FRAGMENT = gql`
  fragment UniversalProfileContactableFields on UniversalProfileContactable {
    id
    phone
    email
    web
  }
`;

const UNIVERSAL_PROFILE_PERSON_FRAGMENT = gql`
  fragment UniversalProfilePersonFields on UniversalProfilePerson {
    id
    firstName
    lastName
    fullName
    isDefaultContact
    isPreferred
    hasEmailError
    positionTitle
    types
    contactable {
      ...UniversalProfileContactableFields
    }
  }
`;

const UNIVERSAL_PROFILE_OFFICE_FRAGMENT = gql`
  fragment UniversalProfileOfficeFields on UniversalProfileOffice {
    id
    name
    isDefaultOffice
    serviceArea {
      id
      type
      radius
      boundaries {
        id
        type
        name
        shortName
      }
    }
    address {
      id
      shortAddress
      address1
      address2
      address3
      suburb
      city
      province
      state {
        id
        shortName
      }
      country {
        id
        shortName
      }
      postcode
    }
    contactable {
      ...UniversalProfileContactableFields
    }
  }
`;

const UNCONNECTED_PROFILE_FRAGMENT = gql`
  fragment UnconnectedProfileFields on UnconnectedProfileData {
    id
    name
    source
    account {
      id
      type
    }
    offices {
      ...UniversalProfileOfficeFields
    }
    people {
      ...UniversalProfilePersonFields
    }
    memberSince
    logoUrl
    lastActiveAt
    updatedAt
  }
`;

const CONNECTED_PROFILE_FRAGMENT = gql`
  fragment ConnectedProfileFields on ConnectedProfileData {
    id
    name
    source
    account {
      id
      type
    }
    offices {
      ...UniversalProfileOfficeFields
    }
    people {
      ...UniversalProfilePersonFields
    }
  }
`;

const PROFILE_RESPONSE_FRAGMENT = gql`
  fragment ProfileResponse on ProfileResponse {
    profile {
      ...UnconnectedProfileFields
    }
  }
`;

const CONNECTED_PROFILE_RESPONSE_FRAGMENT = gql`
  fragment ConnectedProfileResponse on ConnectedProfile {
    connectedProfile {
      ...ConnectedProfileFields
    }
  }
`;

const SYNCED_PROFILES_FRAGMENT = gql`
  fragment SyncedProfiles on SyncedProfiles {
    profile {
      ...UnconnectedProfileFields
    }
    connectedProfile {
      ...ConnectedProfileFields
    }
  }
`;

const NETWORK_PROFILE_REQUEST_FRAGMENT = gql`
  fragment NetworkProfileRequestFields on NetworkProfileRequest {
    id
    fromAccount {
      id
      name
    }
    toAccountProfile {
      id
      name
    }
    createdAt
    __typename
  }
`;

const NETWORK_PROFILE_ENGAGEMENT_FRAGMENT = gql`
  fragment Engagement on NetworkCompanyEngagementStats {
    contactInvites: contactInvitesCount
    companyInvites: companyInvitesCount
    responseRatePercent: responseRatePercent
    quoted: quotedCount
    quotedRatePercent: quotedRatePercent
    awardedPackages: awardedPackagesCount
  }
`;

const BUSINESS_VALIDATION_FRAGMENT = gql`
  fragment BusinessValidation on BusinessValidationResult {
    hasRecord
    reason
  }
`;

const REDACTED_EXTERNAL_OFFICE_FRAGMENT = gql`
  fragment RedactedExternalOffice on RedactedExternalNetworkCompanyOffice {
    id
    name
    addressThoroughfare
    addressLocality
    email
    phone
    website
    address {
      id
      postcode
      country {
        id
        shortName
      }
    }
  }
`;

const EXTERNAL_OFFICE_FRAGMENT = gql`
  fragment ExternalOffice on ExternalNetworkCompanyOffice {
    id
    name
    addressThoroughfare
    addressLocality
    email
    phone
    website
    address {
      id
      postcode
      country {
        id
        shortName
      }
    }
    serviceArea {
      ... on RadialServiceArea {
        id
        area
      }
      ... on RegionalServiceArea {
        id
        areas
      }
      ... on CountryWideServiceArea {
        id
        area
      }
    }
  }
`;

const E1_NETWORK_COMPANY_FRAGMENT = gql`
  fragment E1NetworkCompany on E1NetworkCompany {
    id
    name
    defaultOffice {
      ...ExternalOffice
    }
    otherOffices {
      ...ExternalOffice
    }
    stockTrades {
      ... on NetworkCompanyStockTrade {
        id
        name
      }
      ... on NetworkCompanyCoreStockTrade {
        id
        name
      }
    }
    primaryContact {
      ...NetworkCompanyContact
    }
    account {
      id
    }
    aboutUs
    categoriesText
    contractSizeText
    workforceSizeText
    businessNumber
    businessNumberValidation {
      ...BusinessValidation
    }
    qualifications {
      source
      type
      level
      status
      expiryDate
    }
  }
`;

const SHADOW_NETWORK_COMPANY_FRAGMENT = gql`
  fragment ShadowNetworkCompany on ShadowNetworkCompany {
    id
    name
    defaultOffice {
      ...ExternalOffice
    }
    stockTrades {
      id
      name
    }
    primaryContact {
      ...NetworkCompanyContact
    }
    aboutUs
    categoriesText
    contractSizeText
    workforceSizeText
    businessNumber
    businessNumberValidation {
      hasRecord
      reason
    }
  }
`;

const BUILDER_NETWORK_COMPANY_FRAGMENT = gql`
  fragment BuilderNetworkCompany on BuilderNetworkCompany {
    id
    name
    state
    defaultOffice {
      id
      name
      phone
      addressThoroughfare
      addressLocality
    }
    contacts {
      ...BuilderContact
    }
    trades {
      id
      name
    }
    companyLists {
      id
      name
    }
    customFields {
      id
      name
      value
    }
    engagement {
      ...Engagement
    }
    qualifications {
      source
      type
      level
      status
      expiryDate
    }
  }
`;

const CONNECTED_E1_NETWORK_COMPANY_FRAGMENT = gql`
  fragment ConnectedE1NetworkCompany on ConnectedE1NetworkCompany {
    id
    connectedProfileAccountId
    name
    state
    defaultOffice {
      id
      name
      phone
      addressThoroughfare
      addressLocality
    }
    otherOffices {
      ...ExternalOffice
    }
    stockTrades {
      ... on NetworkCompanyStockTrade {
        id
        name
      }
      ... on NetworkCompanyCoreStockTrade {
        id
        name
      }
    }
    trades {
      id
      name
    }
    companyLists {
      id
      name
    }
    customFields {
      id
      name
      value
    }
    engagement {
      ...Engagement
    }
    aboutUs
    categoriesText
    contractSizeText
    workforceSizeText
    businessNumber
    businessNumberValidation {
      ...BusinessValidation
    }
    contacts {
      ...ConnectedContact
      ...BuilderContact
      ...MergedContact
    }
    recentlyArchivedConnectedAndMergedContacts {
      ...ConnectedContact
      ...MergedContact
    }
    qualifications {
      source
      type
      level
      status
      expiryDate
    }
  }
`;

const CONNECTED_BUILDER_NETWORK_COMPANY_FRAGMENT = gql`
  fragment ConnectedBuilderNetworkCompany on ConnectedBuilderNetworkCompany {
    id
    connectedProfileAccountId
    name
    state
    defaultOffice {
      id
      name
      phone
      addressThoroughfare
      addressLocality
    }
    otherOffices {
      ...ExternalOffice
    }
    stockTrades {
      ... on NetworkCompanyStockTrade {
        id
        name
      }
      ... on NetworkCompanyCoreStockTrade {
        id
        name
      }
    }
    trades {
      id
      name
    }
    companyLists {
      id
      name
    }
    customFields {
      id
      name
      value
    }
    engagement {
      ...Engagement
    }
    aboutUs
    categoriesText
    contractSizeText
    workforceSizeText
    businessNumber
    businessNumberValidation {
      ...BusinessValidation
    }
    contacts {
      ...ConnectedContact
      ...BuilderContact
      ...MergedContact
    }
    recentlyArchivedConnectedAndMergedContacts {
      ...ConnectedContact
      ...MergedContact
    }
    qualifications {
      source
      type
      level
      status
      expiryDate
    }
  }
`;

const REDACTED_E1_NETWORK_COMPANY_FRAGMENT = gql`
  fragment RedactedE1NetworkCompany on RedactedE1NetworkCompany {
    id
    defaultOffice {
      ...RedactedExternalOffice
    }
    otherOffices {
      ...RedactedExternalOffice
    }
    stockTrades {
      ... on NetworkCompanyStockTrade {
        id
        name
      }
      ... on NetworkCompanyCoreStockTrade {
        id
        name
      }
    }
    primaryContact {
      ...NetworkCompanyContact
    }
    account {
      id
    }
    aboutUs
    categoriesText
    businessNumber
  }
`;

const REDACTED_CONNECTED_E1_NETWORK_COMPANY_FRAGMENT = gql`
  fragment RedactedConnectedE1NetworkCompany on RedactedConnectedE1NetworkCompany {
    id
    connectedProfileAccountId
    name
    state
    defaultOffice {
      id
      name
      phone
      addressThoroughfare
      addressLocality
    }
    otherOffices {
      ...RedactedExternalOffice
    }
    stockTrades {
      ... on NetworkCompanyStockTrade {
        id
        name
      }
      ... on NetworkCompanyCoreStockTrade {
        id
        name
      }
    }
    trades {
      id
      name
    }
    companyLists {
      id
      name
    }
    customFields {
      id
      name
      value
    }
    engagement {
      ...Engagement
    }
    aboutUs
    categoriesText
    businessNumber
    contacts {
      ...ConnectedContact
      ...BuilderContact
      ...MergedContact
    }
    recentlyArchivedConnectedAndMergedContacts {
      ...ConnectedContact
      ...MergedContact
    }
  }
`;

const REDACTED_CONNECTED_BUILDER_NETWORK_COMPANY_FRAGMENT = gql`
  fragment RedactedConnectedBuilderNetworkCompany on RedactedConnectedBuilderNetworkCompany {
    id
    connectedProfileAccountId
    name
    state
    defaultOffice {
      id
      name
      phone
      addressThoroughfare
      addressLocality
    }
    otherOffices {
      ...RedactedExternalOffice
    }
    stockTrades {
      ... on NetworkCompanyStockTrade {
        id
        name
      }
      ... on NetworkCompanyCoreStockTrade {
        id
        name
      }
    }
    trades {
      id
      name
    }
    companyLists {
      id
      name
    }
    customFields {
      id
      name
      value
    }
    engagement {
      ...Engagement
    }
    aboutUs
    categoriesText
    businessNumber
    contacts {
      ...ConnectedContact
      ...BuilderContact
      ...MergedContact
    }
    recentlyArchivedConnectedAndMergedContacts {
      ...ConnectedContact
      ...MergedContact
    }
  }
`;

export const GET_UNIVERSAL_COMPANY_PROFILE = gql`
  ${UNIVERSAL_PROFILE_PERSON_FRAGMENT}
  ${UNIVERSAL_PROFILE_CONTACTABLE_FRAGMENT}
  ${UNCONNECTED_PROFILE_FRAGMENT}
  ${CONNECTED_PROFILE_FRAGMENT}
  ${UNIVERSAL_PROFILE_OFFICE_FRAGMENT}
  ${PROFILE_RESPONSE_FRAGMENT}
  ${CONNECTED_PROFILE_RESPONSE_FRAGMENT}
  ${SYNCED_PROFILES_FRAGMENT}
  ${NetworkContacts.fragments.builderContact}
  ${NetworkContacts.fragments.connectedContact}
  ${NetworkContacts.fragments.networkCompanyContact}
  ${NetworkContacts.fragments.mergedContact}
  ${NETWORK_PROFILE_ENGAGEMENT_FRAGMENT}
  ${BUSINESS_VALIDATION_FRAGMENT}
  ${EXTERNAL_OFFICE_FRAGMENT}
  ${REDACTED_EXTERNAL_OFFICE_FRAGMENT}
  ${E1_NETWORK_COMPANY_FRAGMENT}
  ${SHADOW_NETWORK_COMPANY_FRAGMENT}
  ${BUILDER_NETWORK_COMPANY_FRAGMENT}
  ${CONNECTED_E1_NETWORK_COMPANY_FRAGMENT}
  ${CONNECTED_BUILDER_NETWORK_COMPANY_FRAGMENT}
  ${REDACTED_E1_NETWORK_COMPANY_FRAGMENT}
  ${REDACTED_CONNECTED_E1_NETWORK_COMPANY_FRAGMENT}
  ${REDACTED_CONNECTED_BUILDER_NETWORK_COMPANY_FRAGMENT}

  query UniversalCompanyProfile($input: UniversalProfileInput!) {
    networkCompany(input: $input) {
      ... on E1NetworkCompany {
        ...E1NetworkCompany
      }
      ... on ShadowNetworkCompany {
        ...ShadowNetworkCompany
      }
      ... on BuilderNetworkCompany {
        ...BuilderNetworkCompany
      }
      ... on ConnectedBuilderNetworkCompany {
        ...ConnectedBuilderNetworkCompany
      }
      ... on ConnectedE1NetworkCompany {
        ...ConnectedE1NetworkCompany
      }
      ... on RedactedE1NetworkCompany {
        ...RedactedE1NetworkCompany
      }
      ... on RedactedConnectedE1NetworkCompany {
        ...RedactedConnectedE1NetworkCompany
      }
      ... on RedactedConnectedBuilderNetworkCompany {
        ...RedactedConnectedBuilderNetworkCompany
      }
    }
    universalProfile(input: $input) {
      ... on ProfileResponse {
        ...ProfileResponse
      }
      ... on ConnectedProfile {
        ...ConnectedProfileResponse
      }
      ... on SyncedProfiles {
        ...SyncedProfiles
      }
    }
  }
`;

export const GET_SYNCED_PROFILE_UPDATE_REQUEST = gql`
  ${NETWORK_PROFILE_REQUEST_FRAGMENT}

  query ProfileUpdateRequestByAddressBookCompanyId($addressBookCompanyId: EntityId!) {
    profileUpdateRequestByAddressBookCompanyId(addressBookCompanyId: $addressBookCompanyId) {
      ...NetworkProfileRequestFields
    }
  }
`;

export const GET_NETWORK_PROFILE_UPDATE_REQUEST = gql`
  ${NETWORK_PROFILE_REQUEST_FRAGMENT}

  query ProfileUpdateRequestByAccountProfileId($accountProfileId: EntityId!) {
    profileUpdateRequestByAccountProfileId(accountProfileId: $accountProfileId) {
      ...NetworkProfileRequestFields
    }
  }
`;
