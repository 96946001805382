import { useQuery } from '@apollo/client';
import { PACKAGE_DASHBOARD_RFQS } from '../queries';
import { GetPackageWithRfqs, GetPackageWithRfqsVariables } from '../types/GetPackageWithRfqs';
import { EntityId } from '@ascension/types';

export const useGetPackageWithRfqs = (packageId: EntityId, stageId: EntityId) => {
  const { data, loading, refetch } = useQuery<GetPackageWithRfqs, GetPackageWithRfqsVariables>(
    PACKAGE_DASHBOARD_RFQS,
    {
      variables: { packageId, stageId },
    },
  );

  const lettingScheduleDetails = data?.package?.lettingScheduleDetails
    ? {
        ...data.package.lettingScheduleDetails,
        rfqsSentByDate: data.package.lettingScheduleDetails.rfqsSentByDate
          ? new Date(data.package.lettingScheduleDetails.rfqsSentByDate)
          : undefined,
        letByDate: data.package.lettingScheduleDetails.letByDate
          ? new Date(data.package.lettingScheduleDetails.letByDate)
          : undefined,
        startOnSiteDate: data.package.lettingScheduleDetails.startOnSiteDate
          ? new Date(data.package.lettingScheduleDetails.startOnSiteDate)
          : undefined,
      }
    : undefined;

  const packageData = data?.package
    ? {
        ...data.package,
        quotesDueAt: data.package.quotesDueAt ? new Date(data.package.quotesDueAt) : undefined,
        lettingScheduleDetails,
        contentLastAddedAt: data.package.contentLastAddedAt
          ? new Date(data.package.contentLastAddedAt)
          : undefined,
      }
    : undefined;

  const stageData =
    data?.stage && data.stage.__typename === 'UnrestrictedStage' ? data.stage : undefined;

  const tenderPackage = data?.package?.importedFromPackage || null;

  return {
    loading,
    packageData,
    stageData,
    tenderPackage,
    refetch,
  };
};
