import { useCallback, useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useCurrentUser } from '@subbie/context/AccountProvider/hooks';
import {
  GET_SUB_TRADES_AND_ACCOUNT_TRADES,
  GET_SUB_TRADES_ONBOARDING_MODAL_USER_SETTING,
  SET_SUB_TRADES_FOR_ACCOUNT,
  SET_SUB_TRADES_ONBOARDING_MODAL_USER_SETTING,
} from './queries';
import {
  GetSubTradesAndAccountStockTradesForChooseSubTrades as Query,
  GetSubTradesAndAccountStockTradesForChooseSubTrades_currentUser_account_stockTrades as StockTrade,
  GetSubTradesAndAccountStockTradesForChooseSubTrades_allSubTradeCategories_subTrades as SubTrade,
} from './types/GetSubTradesAndAccountStockTradesForChooseSubTrades';
import {
  GetSubTradesOnboardingSetting,
  GetSubTradesOnboardingSetting_userSetting as UserSetting,
} from './types/GetSubTradesOnboardingSetting';
import {
  SetSubTradesForCurrentAccount,
  SetSubTradesForCurrentAccountVariables,
} from './types/SetSubTradesForCurrentAccount';
import {
  SetSubTradesOnboardingSetting,
  SetSubTradesOnboardingSettingVariables,
} from './types/SetSubTradesOnboardingSetting';
import { EntityId } from '@ascension/types';

export { SubTrade, StockTrade };

const useSubTradesAndAccountTrades = (skip = true): Query | undefined =>
  useQuery<Query, never>(GET_SUB_TRADES_AND_ACCOUNT_TRADES, { skip })?.data;

export const useSetSubTrades = (selectedSubTradeIds: EntityId[]): (() => Promise<unknown>) => {
  const [setSubTrades] = useMutation<
    SetSubTradesForCurrentAccount,
    SetSubTradesForCurrentAccountVariables
  >(SET_SUB_TRADES_FOR_ACCOUNT, {
    variables: { subTradeIds: selectedSubTradeIds },
  });

  return setSubTrades;
};

export const useChooseSubTradesModalData = (skip = true) => {
  const tradeData = useSubTradesAndAccountTrades(skip);

  const allSubTrades = useMemo(
    () =>
      (tradeData?.allSubTradeCategories || []).reduce(
        (acc, cat) => acc.concat(cat.subTrades),
        new Array<SubTrade>(),
      ),
    [tradeData],
  );

  const findSubTradesForStockTrade = useCallback(
    (stockTradeId: number) =>
      allSubTrades.filter(({ stockTrades }) =>
        stockTrades.map(({ id }) => id).includes(stockTradeId),
      ),
    [allSubTrades],
  );

  const userStockTrades = useMemo(
    () => tradeData?.currentUser.account?.stockTrades || [],
    [tradeData],
  );

  const suggestedSubTradeIds: EntityId[] = useMemo(
    () =>
      userStockTrades
        .reduce((acc, { id }) => acc.concat(findSubTradesForStockTrade(id)), new Array<SubTrade>())
        .map(({ id }) => id),
    [findSubTradesForStockTrade, userStockTrades],
  );

  const userStockTradeSubTradeMap: Record<string, SubTrade[]> = useMemo(
    () =>
      userStockTrades.reduce(
        (acc: Record<string, SubTrade[]>, { id, name }) => ({
          ...acc,
          [name]: findSubTradesForStockTrade(id),
        }),
        {},
      ),
    [findSubTradesForStockTrade, userStockTrades],
  );

  const currentSubTradeIds = useMemo(
    () => tradeData?.subTradesForCurrentAccount.map(({ id }) => id) || [],
    [tradeData],
  );

  return {
    loaded: tradeData !== undefined,
    allSubTrades,
    currentSubTradeIds,
    suggestedSubTradeIds,
    userStockTradeSubTradeMap,
  };
};

const getSecondsDifference = (dt: GraphQLDateTime) => (Date.now() - Date.parse(dt)) / 1_000;

const getSettingSecondsDifferenceOrUndef = (userSetting: UserSetting) => {
  if (userSetting.__typename !== 'DateTimeUserSetting') {
    return undefined;
  }

  return getSecondsDifference(userSetting.value);
};

type UseSettingHook = {
  loaded: boolean;
  setSetting: () => Promise<void>;
  settingValue: number | undefined;
};

/**
 * @typedef {Object} UseSettingHook
 * @property {number} settingValue - Seconds since the user setting was set
 *
 * @return UseSettingHook
 */
export const useSubTradesOnboardingUserSetting = (skip = true): UseSettingHook => {
  const { loading, data: queryData } = useQuery<GetSubTradesOnboardingSetting, never>(
    GET_SUB_TRADES_ONBOARDING_MODAL_USER_SETTING,
    {
      skip,
    },
  );

  const settingValueFromQuery =
    queryData === undefined ? undefined : getSettingSecondsDifferenceOrUndef(queryData.userSetting);

  const [setSetting, { data: mutationData }] = useMutation<
    SetSubTradesOnboardingSetting,
    SetSubTradesOnboardingSettingVariables
  >(SET_SUB_TRADES_ONBOARDING_MODAL_USER_SETTING, {
    variables: {
      setAt: new Date().toISOString(),
    },
  });

  const settingValueFromMutation = !mutationData
    ? undefined
    : getSettingSecondsDifferenceOrUndef(mutationData.setUserSetting);

  const setSettingFunction = useCallback(() => setSetting().then(() => undefined), [setSetting]);

  return {
    loaded: !loading && !skip,
    setSetting: setSettingFunction,
    settingValue: settingValueFromMutation || settingValueFromQuery,
  };
};

export const useAccountIsOlderThanDays = (days: number): boolean | undefined => {
  const user = useCurrentUser();
  const accountCreatedAt = user?.account?.createdAt;

  if (!accountCreatedAt) return undefined;

  const accountAgeDays = Math.floor(getSecondsDifference(accountCreatedAt) / (3600 * 24));

  return accountAgeDays > days;
};
