import $ from 'jquery';
import E1Request, { E1Response } from './E1Request';
import '../../css/utils/ajax-list.scss';

type ListResponse<T> = E1Response & {
  total: string | number | null;
  limit: number | null;
  page: number | null;
  data: T[] | string | null;
};

type RequestData = { [k: string]: string | number | boolean };

export default class AjaxList<T, D = Record<string, unknown>> {
  private data: T[];
  private request: E1Request<
    ListResponse<T>,
    RequestData & Partial<{ page: number; limit: number }>
  >;
  private total!: number;
  private page: number;
  private perPage: number;
  public $placeholder: JQuery;
  private $loadingPlaceholder: JQuery;
  private $emptyPlaceholder: JQuery;
  private $paginator: JQuery<Element> | null;
  private requestData: D | null;

  constructor(
    private readonly $itemCtn: JQuery,
    private readonly $pageCtn: JQuery,
    private fetchUrl: string,
    private drawItem: (item: T, list: AjaxList<T, D>) => string,
  ) {
    this.data = [];
    this.request = new E1Request(this.fetchUrl, 'GET', {}, true);
    this.page = 1;
    this.perPage = 10;
    this.$placeholder = this.$itemCtn.find('.ajax-list-item-placeholder').clone();
    this.$loadingPlaceholder = this.$itemCtn.find('.ajax-list-loading').clone();
    this.$emptyPlaceholder = this.$itemCtn.find('.ajax-list-empty-placeholder').clone();
    this.$paginator = null;
    this.requestData = null;

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.fetch().then(() => {
      this.toggleLoading(true);
      this.init();
    });
  }

  init() {
    this.draw();
    if (this.$pageCtn) {
      this.$pageCtn
        .bootpag({
          total: Math.ceil(this.total / this.perPage),
          maxVisible: this.perPage,
        })
        .on('page', async (event, num) => {
          this.$paginator = $(event.currentTarget);
          this.toggleLoading(true);
          this.page = num;

          await this.fetch();
          this.draw();

          this.$paginator.bootpag({
            total: Math.ceil(this.total / this.perPage),
            maxVisible: this.perPage,
          });
        });
    }
  }

  public draw() {
    if (this.data.length) {
      const $items = $('<ul>').addClass('ajax-list');

      this.data.forEach((item) => {
        $items.append($('<li>').addClass('ajax-list-item').html(this.drawItem(item, this)));
      });

      $items.append($('<div>').addClass('clearfix'));
      this.$itemCtn.html($items.prop('outerHTML'));
    } else {
      this.$itemCtn.html($(this.$emptyPlaceholder).html());
    }
    $('body').trigger('ajax-list-drawn', {
      identifier: this.$itemCtn.data('identifier'),
      listData: this.data,
    });
  }

  public async fetch() {
    this.request.data = {
      ...(this.requestData || {}),
      ...{ page: this.page, limit: this.perPage },
    };

    const response = await this.request.submit();
    this.parseResponse(response);

    return response;
  }

  public setRequestData(data: D) {
    this.requestData = data;
  }

  public toggleLoading(enable: boolean) {
    if (enable) {
      this.$itemCtn.html($(this.$loadingPlaceholder).prop('outerHTML'));
    } else {
      this.draw();
    }
  }

  private parseResponse(response: ListResponse<T>) {
    if (response.success) {
      const { total, limit, data, page } = response;

      /*
       * Both null and undefined checks here because these data
       * are obtained from many different places in the backend
       */
      if (total !== undefined && total !== null) {
        this.total = parseInt(total.toString());
      }

      if (limit !== undefined && limit !== null) {
        this.perPage = limit;
      }

      if (data !== undefined && data !== null) {
        this.data = typeof data === 'string' ? (JSON.parse(data) as T[]) : data;
      }

      if (page !== undefined && page !== null) {
        this.page = page;
      }
    }
  }
}
