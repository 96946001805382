import { useState } from 'react';
import { Form } from 'react-final-form';
import styles from '@profiles/features/CompanyProfile/components/Trades/EditTrades/EditCoreTradesForm/EditCoreTradesForm.module.scss';
import { ModalFooter } from '@profiles/features/CompanyProfile/modules/Modal/ModalFooter';
import { FormError } from '../../../FormError';
import { TradesMultiSelectField } from './fields/Trades';
import { SubbieStockTradesQuery } from '../../queries.generated';
import {
  EditTradesFieldName,
  EditTradesFieldValues,
} from '@profiles/features/CompanyProfile/components/Trades/types';

type StockTrades = SubbieStockTradesQuery['stockTrades'][number];

type EditTradesFormProps = {
  tradeIds: number[];
  tradesOptions: StockTrades[];
  close: () => void;
  onTradesSubmit: (values: EditTradesFieldValues) => Promise<boolean>;
  formInitError: boolean;
};

export const EditTradesForm = ({
  tradeIds,
  tradesOptions,
  close,
  onTradesSubmit,
  formInitError,
}: EditTradesFormProps) => {
  const [isTradeSubmissionError, setIsTradeSubmissionError] = useState<boolean>(false);
  const onSubmitWithValidation = async (values: EditTradesFieldValues): Promise<void> => {
    const isSubmissionSuccessful = await onTradesSubmit(values);
    setIsTradeSubmissionError(!isSubmissionSuccessful);
  };

  return (
    <>
      <div className={styles.headingWrapper}>
        <p className={styles.heading}>Edit Trades</p>
        <p className={styles.optionHeading}>
          Select your Trades <span className="asterisk">*</span>
        </p>
      </div>
      <Form<EditTradesFieldValues>
        keepDirtyOnReinitialize
        onSubmit={onSubmitWithValidation}
        initialValues={{
          [EditTradesFieldName.Trades]: tradesOptions.filter(({ value }) =>
            tradeIds.includes(value),
          ),
        }}
      >
        {({ handleSubmit }) => (
          <form id="EDIT_CATEGORIES_FORM" onSubmit={handleSubmit}>
            {(formInitError || isTradeSubmissionError) && <FormError />}
            <TradesMultiSelectField tradesOptions={tradesOptions} />
            <ModalFooter close={close} saveButtonText="Next: Core Trades" />
          </form>
        )}
      </Form>
    </>
  );
};
