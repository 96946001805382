import { Dispatch, SetStateAction, useState } from 'react';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  CellProps,
  Modal,
  ModalSize,
  Slider,
  Table,
  TableColumn,
} from '@estimateone/frontend-components';
import { format } from 'date-fns';
import { useAddendumActions, useDeletePendingAddendum } from './hooks';
import { usePendingAddendum } from '@builder/features/Documents/RevisionFlow/hooks';
import { StageAddenda_stageAddenda as StageAddendum } from './types/StageAddenda';
import { EntityId, InterfaceToType } from '@ascension/types';
import styles from './styles.scss';

export type Addendum = InterfaceToType<StageAddendum>;

const DescriptionCell = ({ row: { original } }: CellProps<Addendum>) => (
  <div className={styles.addendumDescriptionColumn}>{original.description}</div>
);

const StartedOnDateCell = ({ row: { original } }: CellProps<Addendum>) => (
  <div>
    {original.createdAt ? format(new Date(original.createdAt), 'd MMM yyyy, hh:mmaaa') : ''}
  </div>
);

const IssuedOnDateCell = ({ row: { original } }: CellProps<Addendum>) => (
  <div>
    {original.issuedAt
      ? format(new Date(original.issuedAt), 'd MMM yyyy, hh:mmaaa')
      : 'In progress'}
  </div>
);

const ActionsCell =
  (
    hasDocumentIntegration: boolean,
    viewAddendumChanges: (addendumId: number) => Promise<void>,
    getContinueAddendumLink: (id: number) => string,
    handleOpenRevisionFlow: () => void,
    setIsDeleteAddendumModalOpen: Dispatch<SetStateAction<boolean>>,
  ) =>
  ({ row: { original } }: CellProps<Addendum>) =>
    original.issuedAt ? (
      <Button
        variant={ButtonVariant.Grey}
        size={ButtonSize.Small}
        fullWidth={hasDocumentIntegration}
        onClick={() => viewAddendumChanges(original.id)}
      >
        View Changes
      </Button>
    ) : hasDocumentIntegration ? (
      <Button
        variant={ButtonVariant.Secondary}
        size={ButtonSize.Small}
        fullWidth
        onClick={handleOpenRevisionFlow}
      >
        Continue
      </Button>
    ) : (
      <div className={styles.addendumActionsButtonsContainer}>
        <Button
          role="link"
          size={ButtonSize.Small}
          variant={ButtonVariant.Secondary}
          href={getContinueAddendumLink(original.id)}
        >
          Continue
        </Button>
        <Button
          size={ButtonSize.Small}
          variant={ButtonVariant.RedOutline}
          onClick={() => {
            setIsDeleteAddendumModalOpen(true);
          }}
        >
          Delete
        </Button>
      </div>
    );

export const AddendumRevisionHistorySlider = ({
  stageId,
  addenda,
  hasDocumentIntegration,
  isOpen,
  onRequestClose,
  onRevisionFlowOpen,
}: {
  stageId: EntityId;
  addenda: Addendum[] | undefined;
  hasDocumentIntegration: boolean;
  isOpen: boolean;
  onRequestClose: () => void;
  onRevisionFlowOpen: () => void;
}) => {
  const { viewAddendumChanges, getContinueAddendumLink } = useAddendumActions(stageId);
  const [isDeleteAddendumModalOpen, setIsDeleteAddendumModalOpen] = useState(false);
  const { pendingAddendum } = usePendingAddendum(stageId);
  const { submit: deletePendingAddendum, loading: deleteAddendumLoading } =
    useDeletePendingAddendum(stageId);

  const handleOpenRevisionFlow = () => {
    onRequestClose();
    onRevisionFlowOpen();
  };

  const addendumTableColumns: TableColumn<Addendum>[] = [
    {
      id: 'name',
      accessor: 'name',
      Header: 'Name',
    },
    {
      id: 'description',
      accessor: 'description',
      Header: 'Description',
      Cell: DescriptionCell,
    },
    {
      id: 'startedOn',
      accessor: 'createdAt',
      Header: 'Started On',
      Cell: StartedOnDateCell,
    },
    {
      id: 'issuedOn',
      accessor: 'issuedAt',
      Header: 'Issued On',
      Cell: IssuedOnDateCell,
    },
    {
      id: 'actions',
      accessor: 'id',
      Header: 'Actions',
      Cell: ActionsCell(
        hasDocumentIntegration,
        viewAddendumChanges,
        getContinueAddendumLink,
        handleOpenRevisionFlow,
        setIsDeleteAddendumModalOpen,
      ),
    },
  ];

  return (
    <>
      <Slider isOpen={isOpen} onRequestClose={() => onRequestClose()} width="60%">
        <Slider.Header>
          <h2>Addendum/Revision History</h2>
        </Slider.Header>
        <Slider.Body scrollBodyOnly>
          <div className={styles.addendumTableContainer}>
            <Table<Addendum>
              columns={addendumTableColumns}
              data={addenda ?? []}
              initSortColumnId="startedOn"
              isInitSortDesc
            />
          </div>
        </Slider.Body>
      </Slider>

      <Modal
        isOpen={isDeleteAddendumModalOpen}
        onRequestClose={() => {
          setIsDeleteAddendumModalOpen(false);
        }}
        size={ModalSize.Small}
      >
        <Modal.Section>
          <h3 className={styles.deleteAddendumModalHeading}>{`Delete '${
            pendingAddendum ? pendingAddendum.name : ''
          }'`}</h3>
          <p>Deleting an Addendum or Revision can not be undone.</p>
          <div className={styles.deleteAddendumModalActionsContainer}>
            <Button
              variant={ButtonVariant.Grey}
              onClick={() => setIsDeleteAddendumModalOpen(false)}
              fullWidth
            >
              Cancel
            </Button>
            <Button
              variant={ButtonVariant.Red}
              fullWidth
              disabled={deleteAddendumLoading}
              onClick={async () => {
                if (pendingAddendum) {
                  await deletePendingAddendum(pendingAddendum.id);
                  setIsDeleteAddendumModalOpen(false);
                }
              }}
            >
              Yes, Delete
            </Button>
          </div>
        </Modal.Section>
      </Modal>
    </>
  );
};
