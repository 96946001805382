import { useMemo, useState } from 'react';
import {
  Button,
  ButtonVariant,
  CellProps,
  Checkbox,
  CheckboxStatus,
  E1Link,
  LinkVariant,
  Slider,
  Table,
  TableColumn,
} from '@estimateone/frontend-components';
import { QuotingStatusTag } from '../../../../common/QuotingStatusTag';
import {
  GetAwardeesForPackage_package_activeRfqs as ActiveRfqs,
  GetAwardeesForPackage_stage_UnrestrictedStage as StageDetails,
} from '../types/GetAwardeesForPackage';
import { EntityId, InterfaceToType } from '@ascension/types';
import styles from './styles.scss';

export type Rfq = InterfaceToType<ActiveRfqs>;

export type NotifyUnsuccessfulFormProps = {
  activeRfqs: ActiveRfqs[];
  awardedRfqId: number;
  packageName: string;
  packageAssignedUserName?: string;
  stageDetails: StageDetails;
  onCancel: () => void;
  onBack: () => void;
  onFormSuccess: (awardedRfqId: EntityId, notifyUnsuccessfulRfqIds: EntityId[]) => Promise<void>;
};

export const NotifyUnsuccessfulForm = ({
  activeRfqs,
  awardedRfqId,
  packageName,
  packageAssignedUserName,
  stageDetails,
  onCancel,
  onBack,
  onFormSuccess,
}: NotifyUnsuccessfulFormProps) => {
  const [notifyRfqIds, setNotifyRfqIds] = useState<number[]>([]);
  const [isEmailPreviewShown, setIsEmailPreviewShown] = useState<boolean>(false);

  const isDisabled = (rfq: ActiveRfqs): boolean => !rfq.contact?.email;

  const notifyData = useMemo(() => {
    const notAwardedRfqs = activeRfqs.filter((rfq) => rfq.id !== awardedRfqId);
    const disabledCount = notAwardedRfqs.filter((rfq) => isDisabled(rfq)).length;
    const maxSelectable = notAwardedRfqs.length - disabledCount;
    return {
      notAwardedRfqs,
      maxSelectable,
    };
  }, [activeRfqs, awardedRfqId]);

  const handleOnSubmit = async () => {
    await onFormSuccess(awardedRfqId, notifyRfqIds);
  };

  const isRfqSelected = (id: number) => notifyRfqIds.includes(id);
  const allSelected = () =>
    notifyData.maxSelectable !== 0 && notifyRfqIds.length === notifyData.maxSelectable;

  const updateAllSelected = () => {
    const newState = !allSelected();
    setNotifyRfqIds(
      newState
        ? notifyData.notAwardedRfqs.filter((rfq) => !isDisabled(rfq)).map((rfq) => rfq.id)
        : [],
    );
    return newState;
  };

  const updateSelected = (id: number) => {
    if (isRfqSelected(id)) {
      setNotifyRfqIds((currentState) => currentState.filter((value) => value !== id));
    } else {
      setNotifyRfqIds((currentState) => currentState.concat(id));
    }
  };

  const toggleEmailPreview = () => {
    setIsEmailPreviewShown(!isEmailPreviewShown);
  };

  const columns: TableColumn<Rfq>[] = useMemo(
    () => [
      {
        id: 'selected',
        accessor: (value) => value.id,
        disableSortBy: true,
        Header: () => (
          <Checkbox
            className={styles.masterCheckbox}
            id="selectAll"
            label="selectAll"
            hideLabel
            statusCurrent={allSelected() ? CheckboxStatus.Checked : CheckboxStatus.Unchecked}
            onClick={updateAllSelected}
          />
        ),
        Cell: ({ row: { original: rfq } }: CellProps<Rfq>) => (
          <Checkbox
            id={rfq.id.toString()}
            label="select"
            hideLabel
            disabled={isDisabled(rfq)}
            statusCurrent={
              isRfqSelected(rfq.id) ? CheckboxStatus.Checked : CheckboxStatus.Unchecked
            }
            onClick={() => (isDisabled(rfq) ? undefined : updateSelected(rfq.id))}
          />
        ),
      },
      {
        id: 'companyName',
        accessor: (value) => value.contact?.company?.name,
        Header: 'Company Name',
      },
      {
        id: 'contactName',
        accessor: (value) => value.contact?.fullName,
        Header: 'Contact Name',
      },
      {
        id: 'email',
        accessor: (value) => value.contact?.email,
        Header: 'Email',
        disableSortBy: true,
        Cell: ({ value }: CellProps<Rfq>) =>
          value || <div className={styles.noEmailExists}>No email exists</div>,
      },
      {
        id: 'phone',
        accessor: (value) => value.contact?.phone,
        Header: 'Phone',
        disableSortBy: true,
      },
      {
        id: 'status',
        accessor: (value) => value.quotingStatus,
        Header: 'Status',
        disableSortBy: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row: { original: rfq } }: CellProps<Rfq>) => (
          <QuotingStatusTag quotingStatus={rfq.quotingStatus} />
        ),
      },
    ],
    [notifyRfqIds],
  );

  return (
    <>
      <Slider.Body scrollBodyOnly>
        <div>
          <div className={styles.notifyPackageText}>
            Notify the unsuccessful <b>{packageName}</b> subcontractors (
            <E1Link variant={LinkVariant.Grey} onClick={toggleEmailPreview}>
              {isEmailPreviewShown ? 'hide preview' : 'show preview'}
            </E1Link>
            ):
          </div>
          {isEmailPreviewShown ? (
            <div className={styles.emailPreview}>
              <p>Hi [Subcontractor Name],</p>
              <p>
                Thank you for your time spent quoting for the {packageName} package for the{' '}
                {stageDetails.name} project. Unfortunately, you weren&apos;t successful this time.
              </p>
              <p>If you&apos;d like feedback on your quote and submission, please get in touch.</p>
              <div className={styles.emailPreviewFooter}>
                <p>Sincerely,</p>
                <p>{packageAssignedUserName || stageDetails.contactName}</p>
                {stageDetails.contactPhone ? <p>{stageDetails.contactPhone}</p> : null}
                <p>{stageDetails.account?.name}</p>
              </div>
            </div>
          ) : null}
          <div className={styles.notifySelectCount}>{notifyRfqIds.length} contact(s) selected</div>
          <div className={styles.notifyTable}>
            <Table<Rfq>
              columns={columns}
              data={notifyData.notAwardedRfqs}
              initSortColumnId="company"
            />
          </div>
        </div>
      </Slider.Body>
      <Slider.Footer>
        <div className={styles.sliderFooter}>
          <div className={styles.sliderFooterLeft}>
            <Button className={styles.sliderButton} onClick={onCancel} variant={ButtonVariant.Grey}>
              Cancel
            </Button>
          </div>
          <div className={styles.sliderFooterRight}>
            <Button className={styles.sliderButton} onClick={onBack} variant={ButtonVariant.Grey}>
              Back
            </Button>
            <Button
              className={styles.sliderButton}
              onClick={handleOnSubmit}
              variant={ButtonVariant.Primary}
            >
              Mark as Awarded
            </Button>
          </div>
        </div>
      </Slider.Footer>
    </>
  );
};
