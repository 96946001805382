import { useQuery } from '@apollo/client';
import { GET_DOWNLOADED_PACKAGES } from '../queries';
import {
  GetDownloadedPackages,
  GetDownloadedPackagesVariables,
} from '../types/GetDownloadedPackages';
import { EntityId } from '@ascension/types';

export const useGetDownloadedPackages = (stageId: EntityId, addendumId?: EntityId) => {
  const { data, loading } = useQuery<GetDownloadedPackages, GetDownloadedPackagesVariables>(
    GET_DOWNLOADED_PACKAGES,
    { variables: { input: { stageId, addendumId } }, fetchPolicy: 'network-only' },
  );

  return { data, loading };
};
