import { ComponentProps } from 'react';
import Routing from 'routing';
import {
  ActionMenu,
  Button,
  ButtonVariant,
  joinClassNames,
} from '@estimateone/frontend-components';
import { ScopeOfWorksProvider } from '@scope-of-works/providers/ScopeOfWorksProvider';
import { InviteToQuoteMenuButton } from '../../InviteToQuote/InviteToQuoteMenuButton';
import { LettingScheduleNotesSlideout } from '../LettingScheduleNotesSlideout';
import { LettingScheduleNotesSlideoutSource } from '../LettingScheduleNotesSlideout/LettingScheduleNotesSlideout';
import { useNotesSlideout } from '../LettingScheduleNotesSlideout/useNotesSlideout';
import { getLettingScheduleStatus } from '../LettingScheduleStatus';
import { AwardedSubbieInfo } from './AwardedSubbieInfo';
import { EmptyTableGuide, ProjectNoDocsImage } from './EmptyTableGuide';
import { PackageDashboardHeader } from './PackageDashboardHeader';
import { PackageDashboardNotesWidget } from './PackageDashboardNotesWidget';
import { PackageDashboardStatistics } from './PackageDashboardStatistics';
import { PackageDashboardTable } from './PackageDashboardTable';
import { PackageNoDocs } from './PackageNoDocs';
import {
  TenderResponsesAccordion,
  TenderResponsesEmptyState,
  TenderResponsesTable,
} from './TenderResponses';
import { CurrencyInfoContextProvider, LoadingSpinner } from '@shared';
import { useGetPackageWithRfqs } from './hooks/useGetPackageWithRfqs';
import { useGetScopeOfWorks } from './hooks/useGetScopeOfWorks';
import { getCurrencyInfo } from '@ascension/enums';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

const getLettingScheduleUrl = (stageId: number) =>
  Routing.generate('app_stage_view', {
    stageType: 'procurement',
    id: stageId,
  });

const getDocumentsUrl = (stageId: number) =>
  Routing.generate('app_stage_documents', {
    id: stageId,
    stageType: 'procurement',
  });

const projectNoDocs = (stageId: EntityId) => (
  <EmptyTableGuide
    image={<ProjectNoDocsImage />}
    heading="This project has no documents"
    descriptions={[
      'Check that documents have been uploaded',
      'Then assign them to the package in the Document Matrix',
    ]}
    button={
      <Button variant={ButtonVariant.Primary} href={getDocumentsUrl(stageId)}>
        Go to Documents
      </Button>
    }
  />
);

export type PackageDashboardProps = {
  stageId: EntityId;
  packageId: EntityId;
  scopesUiUrl: string;
  hasDocumentIntegration: boolean;
};

type Placement = ComponentProps<typeof ActionMenu>['placement'];

export const PackageDashboard = ({
  stageId,
  packageId,
  scopesUiUrl,
  hasDocumentIntegration,
}: PackageDashboardProps) => {
  const {
    loading,
    packageData,
    stageData,
    tenderPackage,
    refetch: refetchPackageWithRfqs,
  } = useGetPackageWithRfqs(packageId, stageId);
  const scopeOfWorks = useGetScopeOfWorks(packageId);

  const {
    isOpen: isNotesSliderOpen,
    isCritical: isNotesSliderCritical,
    packageId: noteSlideoutPackageId,
    packageTitle: noteSlideoutPackageTitle,
    openNotesSlider,
    closeNoteSlider,
  } = useNotesSlideout();

  const getInviteToQuoteButton = (menuPlacement?: Placement) => (
    <InviteToQuoteMenuButton
      stageId={stageId}
      preselectedPackageId={packageId}
      onAddedRfq={() => refetchPackageWithRfqs()}
      menuPlacement={menuPlacement}
    />
  );

  if (loading) {
    return (
      <div className={styles.loadingSection}>
        <LoadingSpinner />
      </div>
    );
  }

  if (!packageData?.lettingScheduleDetails || !stageData) {
    throw new Error('Failed to load package data');
  }

  const tenderRfqs = tenderPackage?.activeRfqs ?? [];
  const countryId = stageData.account?.primaryCountry?.id;
  const currencyInfo = getCurrencyInfo(countryId);

  const handleNotesWidgetClick = () => {
    openNotesSlider(packageData.id, packageData.title, false);
  };

  const scopeOfWorksConfig = {
    scopesUiUrl,
  };
  const scopesProviderValues = { ...scopeOfWorks, ...scopeOfWorksConfig };

  return (
    <ScopeOfWorksProvider value={scopesProviderValues}>
      <CurrencyInfoContextProvider currencyInfo={currencyInfo}>
        <main>
          <div id="package-dashboard-header">
            {packageData.title && stageData.id && stageData.name && (
              <section className={styles.sectionHeader}>
                <div className="wrapper">
                  <PackageDashboardHeader
                    packageId={packageId}
                    packageTitle={packageData.title}
                    stageName={stageData.name}
                    lettingScheduleUrl={getLettingScheduleUrl(stageId)}
                    documentsUrl={getDocumentsUrl(stageId)}
                    lettingScheduleStatus={getLettingScheduleStatus(packageData)}
                  />
                </div>
              </section>
            )}

            {packageData.lettingScheduleDetails.awardedToRfq && (
              <section className={styles.sectionHeader}>
                <div className={joinClassNames('wrapper', styles.awardToHeader)}>
                  <AwardedSubbieInfo
                    stageId={stageId}
                    rfqId={packageData.lettingScheduleDetails.awardedToRfq.id}
                    contact={packageData.lettingScheduleDetails.awardedToRfq.contact}
                    latestQuote={packageData.lettingScheduleDetails.awardedToRfq.latestQuote}
                  />
                </div>
              </section>
            )}

            <section className={styles.sectionHeader}>
              <div className={joinClassNames('wrapper', styles.packageInfo)}>
                <PackageDashboardStatistics
                  activeRfqs={packageData.activeRfqs}
                  rfqsSentByDate={packageData.lettingScheduleDetails?.rfqsSentByDate}
                  quotesDueAt={packageData.quotesDueAt}
                  letByDate={packageData.lettingScheduleDetails?.letByDate}
                  startOnSiteDate={packageData.lettingScheduleDetails?.startOnSiteDate}
                />
              </div>
            </section>

            {packageData.lettingScheduleDetails.latestNote && (
              <section className={joinClassNames(styles.sectionHeader, styles.sectionMargin)}>
                <div className={joinClassNames('wrapper', styles.notesWidgetSection)}>
                  <PackageDashboardNotesWidget
                    noteCount={packageData.lettingScheduleDetails.noteCount}
                    text={packageData.lettingScheduleDetails.latestNote.text}
                    onClick={handleNotesWidgetClick}
                  />
                </div>
              </section>
            )}
          </div>

          <div className="wrapper app-height-wrapper">
            {(() => {
              if (stageData.activeDocumentCount === 0) return projectNoDocs(stageId);
              if (packageData.countOfCurrentPackageDocuments === 0)
                return (
                  <PackageNoDocs
                    stageId={stageId}
                    hasDocumentIntegration={hasDocumentIntegration}
                  />
                );

              return (
                <>
                  <div className={styles.actionBar}>
                    <div>
                      <h3 className={styles.actionBarTitle}>Procurement Responses</h3>
                    </div>
                    <div>{getInviteToQuoteButton()}</div>
                  </div>

                  <PackageDashboardTable
                    packageData={packageData}
                    stageId={stageId}
                    activeRfqs={packageData.activeRfqs}
                    packageContentLastAddedAt={packageData.contentLastAddedAt}
                    awardedToCompanyId={
                      packageData.lettingScheduleDetails?.awardedToRfq?.contact?.company?.id
                    }
                    refetch={refetchPackageWithRfqs}
                  />

                  <TenderResponsesAccordion rfqs={tenderRfqs}>
                    <TenderResponsesTable tenderPackage={tenderPackage} />

                    {tenderRfqs.length === 0 && (
                      <TenderResponsesEmptyState isHandoverStage={!!stageData.lastStage} />
                    )}
                  </TenderResponsesAccordion>
                </>
              );
            })()}
          </div>
        </main>
        {isNotesSliderOpen &&
          noteSlideoutPackageId !== undefined &&
          noteSlideoutPackageTitle !== undefined && (
            <LettingScheduleNotesSlideout
              isOpen={isNotesSliderOpen}
              packageId={noteSlideoutPackageId}
              packageTitle={noteSlideoutPackageTitle}
              accountName={stageData.account?.name}
              onCloseHandler={closeNoteSlider}
              isCritical={isNotesSliderCritical}
              source={LettingScheduleNotesSlideoutSource.PACKAGE_DASHBOARD}
            />
          )}
      </CurrencyInfoContextProvider>
    </ScopeOfWorksProvider>
  );
};
