import { useState } from 'react';
import { Button, ButtonVariant } from '@estimateone/frontend-components';
import { useScopeOfWorks } from '../../providers/ScopeOfWorksProvider';
import { ScopesUploadModal } from '../ScopesUploadModal';
import { ScopesActionMenu } from './ScopesActionMenu';
import LoadingSpinner from '@shared/LoadingSpinner';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

export type ScopeOfWorksButtonProps = {
  packageId: EntityId;
};

enum ScopeActions {
  VIEW = 'VIEW',
  CREATE = 'CREATE',
  UPLOAD = 'UPLOAD',
}

const scopeFileActions = [
  { name: ScopeActions.VIEW, label: 'View Scope' },
  { name: ScopeActions.UPLOAD, label: 'Upload Scope PDF' },
];

const addScopeActions = [
  // { name: ScopeActions.CREATE, label: 'Create new Scope' },
  { name: ScopeActions.UPLOAD, label: 'Upload Scope file' },
];

export const ScopeOfWorksMenu = ({ packageId }: ScopeOfWorksButtonProps) => {
  const { scope, scopesUiUrl, loading, refetch } = useScopeOfWorks();
  const [isScopesModalOpen, setScopesModalVisibility] = useState(false);

  const createScope = () => {
    const editorUrl = `${scopesUiUrl}/packages/${packageId}/scopes/create`;
    window.location.assign(editorUrl);
  };

  const viewScope = () => {
    const scopeViewUrl = `${scopesUiUrl}/packages/${packageId}/scopes/view`;
    window.location.assign(scopeViewUrl);
  };

  const editScope = (scopeId: string) => {
    const editorUrl = `${scopesUiUrl}/packages/${packageId}/scopes/${scopeId}/edit`;
    window.location.assign(editorUrl);
  };

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <LoadingSpinner />
      </div>
    );
  }

  const onItemClick = (name: string) => {
    switch (name) {
      case ScopeActions.VIEW:
        viewScope();
        break;
      case ScopeActions.UPLOAD:
        setScopesModalVisibility(true);
        break;
      case ScopeActions.CREATE:
        createScope();
        break;
      default:
    }
  };

  return (
    <>
      {scope?.scopeContent && (
        <Button
          className={styles.scopesButton}
          onClick={() => editScope(scope.id)}
          variant={ButtonVariant.Grey}
        >
          Edit Scope
        </Button>
      )}
      {scope?.scopeFile && (
        <ScopesActionMenu label="Scope" items={scopeFileActions} onItemClick={onItemClick} />
      )}
      {!scope?.scopeContent && !scope?.scopeFile && (
        <ScopesActionMenu label="Add Scope" items={addScopeActions} onItemClick={onItemClick} />
      )}
      <ScopesUploadModal
        scopesUiUrl={scopesUiUrl}
        packageId={packageId}
        isOpen={isScopesModalOpen}
        onClose={() => setScopesModalVisibility(false)}
        onSuccess={refetch}
      />
    </>
  );
};
