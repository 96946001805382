import Routing from 'routing';
import { Icon, IconName, E1Link } from '@estimateone/frontend-components';
import { SliderBackButton } from '@subbie/modal/ProjectSlider/SliderBackButton';
import ProjectWatchlistSelector from '@shared/ProjectWatchlistSelector';
import { getInterestLevelOrDefault } from '@shared/ProjectWatchlistSelector/util';
import TruncatedText from '@shared/TruncatedText';
import { WatchlistSource } from '@ascension/enums';
import { Project } from '@subbie/modal/ProjectSlider/types';
import styles from './styles.scss';

type HeaderContentProps = {
  project: Project;
};

const HeaderContent = ({ project }: HeaderContentProps) => {
  const { id, name, address, watchlistEntry } = project;
  const watchListStatus = getInterestLevelOrDefault(watchlistEntry?.status);

  return (
    <div className={styles.projectSliderHeader}>
      <div className="d-flex justify-content-between align-items-center">
        <span className={styles.projectId}>ID #{id}</span>
        <E1Link
          href={Routing.generate('app_project_print', { id })}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.printBtn}
        >
          <Icon name={IconName.Print} marginRight="small" aria-hidden="true" />
          Print
        </E1Link>
      </div>
      <div className="d-flex justify-content-between align-items-end">
        <div>
          <h2>
            <TruncatedText text={name} limit={65} />
          </h2>
          <span>{address?.fullAddress}</span>
          {address && (
            <a
              href={`https://www.google.com/maps/place/${address.fullAddress}`}
              target="_blank"
              rel="noopener noreferrer"
              className={`link ${styles.viewMap}`}
            >
              View map
            </a>
          )}
        </div>
        <div className={styles.watchlistSelector}>
          <ProjectWatchlistSelector
            status={watchListStatus}
            source={WatchlistSource.PROJECT_SLIDER}
            projectId={id}
          />
        </div>
      </div>
    </div>
  );
};

type SubbieListSliderHeaderProps = {
  handleClose: () => void;
  project: Project;
};

export const SubbieListSliderHeader = ({ handleClose, project }: SubbieListSliderHeaderProps) => (
  <div className={styles.sliderHeader}>
    <SliderBackButton handleClose={handleClose} />
    <HeaderContent project={project} />
    <div className={styles.subHeader}>
      <h2>Subcontractors</h2>
      <span>
        Subcontractors who have accessed your contact details or are interested in this project.
      </span>
    </div>
  </div>
);
