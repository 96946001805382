import { useMutation } from '@apollo/client';
import { UPDATE_PENDING_ADDENDUM_DETAILS } from '../mutations';
import {
  UpdatePendingAddendumDetails,
  UpdatePendingAddendumDetailsVariables,
} from '../types/UpdatePendingAddendumDetails';
import { EntityId } from '@ascension/types';

export const useUpdatePendingAddendumDetails = () => {
  const [updatePendingAddendumDetails] = useMutation<
    UpdatePendingAddendumDetails,
    UpdatePendingAddendumDetailsVariables
  >(UPDATE_PENDING_ADDENDUM_DETAILS);

  const submit = async (stageId: EntityId, name: string, description?: string) => {
    const { data } = await updatePendingAddendumDetails({
      variables: { input: { stageId, name, description } },
    });

    return data?.updatePendingAddendumDetails;
  };

  return { submit };
};
