import { useReducer } from 'react';
import { E1Link, SkeletonLoader, Tooltip } from '@estimateone/frontend-components';
import { ContractSizeBadge, ContractSizeBadgeViewError } from '../ContractSizeBadge';
import { EditContractAndWorkforceSize } from '../EditContractAndWorkforceSize';
import { EmployeeBadge, EmployeeBadgeViewError } from '../EmployeeBadge';
import styles from './ContractAndWorkforceSize.module.scss';
import { useOwnAccount } from '@profiles/features/CompanyProfile/hooks';
import { ContractSizeEnum, WorkforceSizeEnum } from '@ascension/_gqltypes/profiles.generated';

type ViewContractAndWorkforceSizeProps = {
  contractSizeMax: ContractSizeEnum | null;
  contractSizeMin: ContractSizeEnum | null;
  workforceSize: WorkforceSizeEnum | null;
};

export const ContractAndWorkforceSizeLoading = () => (
  <div className={styles.loadingState}>
    <SkeletonLoader height="16px" />
    <SkeletonLoader height="16px" />
  </div>
);

export const ContractAndWorkforceSizeError = () => (
  <div className={styles.viewLayout}>
    <ContractSizeBadgeViewError />
    <EmployeeBadgeViewError />
  </div>
);

const EditContractAndWorkforceSizeView = ({
  contractSizeMax,
  contractSizeMin,
  workforceSize,
}: ViewContractAndWorkforceSizeProps) => {
  const [isOpen, toggle] = useReducer((curr) => !curr, false);

  return (
    <Tooltip
      text="The contract and workforce size information indicates what sized jobs you are capable of completing. This information is only visible to builders on E1."
      tooltipId="contract-and-workfoce-badge-tooltip"
      place="top"
      style={{ width: '100%' }}
    >
      <>
        <div className={styles.editLayout} onClick={toggle}>
          <ContractSizeBadge contractSizeMax={contractSizeMax} contractSizeMin={contractSizeMin} />
          <EmployeeBadge workforceSize={workforceSize} />
          <E1Link>Edit</E1Link>
        </div>
        <EditContractAndWorkforceSize
          isOpen={isOpen}
          toggle={toggle}
          contractSizeMax={contractSizeMax}
          contractSizeMin={contractSizeMin}
          workforceSize={workforceSize}
        />
      </>
    </Tooltip>
  );
};

type ContractAndWorkforceSizeProps = {
  contractSizeMax: ContractSizeEnum | null;
  contractSizeMin: ContractSizeEnum | null;
  workforceSize: WorkforceSizeEnum | null;
};

export const ContractAndWorkforceSize = ({
  contractSizeMax,
  contractSizeMin,
  workforceSize,
}: ContractAndWorkforceSizeProps) => {
  const { isOwnAccount } = useOwnAccount();

  if (!isOwnAccount) {
    return (
      <div className={styles.viewLayout}>
        <ContractSizeBadge contractSizeMax={contractSizeMax} contractSizeMin={contractSizeMin} />
        <EmployeeBadge workforceSize={workforceSize} />
      </div>
    );
  }

  return (
    <div className={styles.hoverEffect}>
      <EditContractAndWorkforceSizeView
        contractSizeMax={contractSizeMax}
        contractSizeMin={contractSizeMin}
        workforceSize={workforceSize}
      />
    </div>
  );
};
