import { useQuery } from '@apollo/client';
import { GET_STAGE_ADDENDA } from '../queries';
import { StageAddenda, StageAddendaVariables } from '../types/StageAddenda';
import { EntityId } from '@ascension/types';

export const useGetStageAddenda = (stageId: EntityId) => {
  const { data, loading, refetch } = useQuery<StageAddenda, StageAddendaVariables>(
    GET_STAGE_ADDENDA,
    {
      variables: {
        stageId,
      },
    },
  );
  return { addenda: data?.stageAddenda, loading, refetchAddenda: refetch };
};
