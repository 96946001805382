import { MouseEvent, KeyboardEvent } from 'react';
import {
  Icon,
  IconName,
  Button,
  ButtonSize,
  ButtonVariant,
} from '@estimateone/frontend-components';
import ProjectWatchlistSelector from '@shared/ProjectWatchlistSelector';
import { getInterestLevelOrDefault } from '@shared/ProjectWatchlistSelector/util';
import { DocumentHighlightsProject } from '@subbie/modal/SpeciFinderKeywordMatchesSlider/hooks/useDocumentHighlightsByStage';
import { WatchlistSource } from '@ascension/enums';
import styles from './styles.scss';

type SpeciFinderKeywordMatchesSliderHeaderProps = {
  handleClose: (event: MouseEvent | KeyboardEvent) => void;
  project: DocumentHighlightsProject;
};

export const SpeciFinderKeywordMatchesSliderHeader = ({
  handleClose,
  project,
}: SpeciFinderKeywordMatchesSliderHeaderProps) => {
  const watchListStatus = getInterestLevelOrDefault(project.watchlistEntry?.status);
  return (
    <div className={styles.sliderHeader}>
      <div className="d-flex justify-content-between align-items-end">
        <div>
          <Button
            variant={ButtonVariant.Transparent}
            size={ButtonSize.Small}
            className={styles.sliderBackButton}
            onClick={handleClose}
          >
            <Icon
              name={IconName.ArrowLeft}
              className={styles.leftIcon}
              marginRight="small"
              size="0.8em"
            />
            <span className={styles.backText}>Back</span>
          </Button>
          <h2>Keyword matches</h2>
        </div>
        <div className={styles.watchlistSelector}>
          <ProjectWatchlistSelector
            status={watchListStatus}
            source={WatchlistSource.SPECI_FINDER_KEYWORD_MATCHES_SLIDER}
            projectId={project.id}
          />
        </div>
      </div>
    </div>
  );
};
