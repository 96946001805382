import { captureException } from '@sentry/browser';
import WatchlistSelect from '@shared/form/WatchlistSelect';
import {
  useWatchlistStatusByProject,
  useUpdateWatchlistStatus,
} from '@subbie/context/WatchlistProvider/hooks';
import { useSSCHandleWatchlistStatusUpdate } from '@ssc/features/SSCWatchlistModal/hooks/useSSCHandleWatchlistStatusUpdate';
import { useSSCModalOptOut } from '@ssc/features/SSCWatchlistModal/hooks/useSSCModalOptOut';
import { WatchlistSource } from '@ascension/enums';
import { InterestLevel } from '@ascension/_gqltypes/subbie.generated';
import { EntityId } from '@ascension/types';

type ProjectWatchlistSelectorProps = {
  projectId: EntityId;
  status?: InterestLevel;
  source: WatchlistSource;
};

const ProjectWatchlistSelector = ({
  projectId,
  status: initialStatus,
  source,
}: ProjectWatchlistSelectorProps) => {
  const statusesByProject = useWatchlistStatusByProject();
  const updateStatus = useUpdateWatchlistStatus();
  const contextStatus = statusesByProject[projectId];
  const { showSSCModalForWatchlistStatusUpdate } = useSSCHandleWatchlistStatusUpdate();
  const { isOptedOut } = useSSCModalOptOut();

  const status = contextStatus ?? initialStatus;
  const handleStatusChange = (level: InterestLevel) => {
    updateStatus(projectId, level, source).catch(captureException);

    if (isOptedOut()) {
      return;
    }

    if (![WatchlistSource.NOTICEBOARD, WatchlistSource.PROJECT_SLIDER].includes(source)) {
      return;
    }

    if (
      (status === InterestLevel.UNACTIONED || status === InterestLevel.EXPLICITLY_RESET) &&
      level === InterestLevel.INTERESTED
    ) {
      showSSCModalForWatchlistStatusUpdate(projectId).catch(captureException);
    }
  };

  return (
    <WatchlistSelect projectId={projectId} status={status} onStatusChange={handleStatusChange} />
  );
};

export default ProjectWatchlistSelector;
