import { Children, PropsWithChildren, ReactNode } from 'react';
import { Form } from 'react-final-form';
import { Config } from 'final-form';
import { useConnectionPreferenceFormContext } from '@subbie/modal/ConnectionPreferenceSlider/ConnectionPreferenceFormContext';
import GlobalErrorList from '@shared/FinalForm/GlobalErrorList';
import {
  ConnectionPreferenceFormValues,
  ConnectionPreferenceFormId,
} from '@subbie/modal/ConnectionPreferenceSlider/ConnectionPreferenceFormContext/types';

export const ConnectionPreferenceFormStep = ({ children }: PropsWithChildren<unknown>) => (
  <div>{children}</div>
);

type ConnectionPreferenceFormStepperProps = {
  children: ReactNode;
  onSubmit: Config<ConnectionPreferenceFormValues>['onSubmit'];
};
export const ConnectionPreferenceFormStepper = ({
  children,
  onSubmit,
}: ConnectionPreferenceFormStepperProps) => {
  const formId = ConnectionPreferenceFormId.Id;
  const { pageIndex, initialValues } = useConnectionPreferenceFormContext();

  const activePage = Children.toArray(children)[pageIndex];

  return (
    <Form<ConnectionPreferenceFormValues>
      id={formId}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, submitError }) => (
        <form id={formId} onSubmit={handleSubmit}>
          {activePage}
          {submitError && <GlobalErrorList errors={submitError} />}
        </form>
      )}
    </Form>
  );
};
