import { useMutation } from '@apollo/client';
import { useOperatingCountryId } from '@ascension/components/localisation';
import {
  OperatingCountry,
  ValidCountryId,
} from '@ascension/components/localisation/operatingCountry';
import {
  UpdateProfileDetailsMutation,
  UpdateProfileDetailsMutationVariables,
} from './mutations.generated';
import { UPDATE_PROFILE_DETAILS } from '@profiles/features/BuildProfile/CompanyProfileForm/mutations';
import { CompanyProfileFieldValues } from './types';
import { UpdateProfileDetailsInput } from '@ascension/_gqltypes/profiles.generated';

const transformValuesForCountry =
  (operatingCountryId: ValidCountryId) =>
  (values: CompanyProfileFieldValues): UpdateProfileDetailsInput => {
    // Remove spaces in string and convert "" to null
    const trimmedCompanyNumber = (values.companyNumber ?? '').replace(/ /g, '') || null;

    if (OperatingCountry.UK === operatingCountryId || OperatingCountry.IE === operatingCountryId) {
      return {
        name: values.name ?? '',
        businessEntityType: values.businessEntity,
        companyNumber: trimmedCompanyNumber,
        primaryContactId: values.primaryContact.value.id,
        defaultOfficeAddress: {
          address1: values.streetAddress ?? '',
          suburb: null,
          city: values.city ?? null,
          stateId: null,
          province: values.province ?? null,
          postcode: values.postcode ?? '',
        },
      };
    }

    return {
      name: values.name ?? '',
      // must always submit at least an empty string
      companyNumber: trimmedCompanyNumber,
      businessEntityType: null,
      primaryContactId: values.primaryContact.value.id,
      defaultOfficeAddress: {
        address1: values.streetAddress ?? '',
        suburb: values.suburb ?? null,
        city: null,
        stateId: values?.state?.value ?? null,
        province: null,
        postcode: values.postcode ?? '',
      },
    };
  };

export const useSubmitProfileDetailsForm = () => {
  const [updateProfileDetails, { error }] = useMutation<
    UpdateProfileDetailsMutation,
    UpdateProfileDetailsMutationVariables
  >(UPDATE_PROFILE_DETAILS);
  const operatingCountryId = useOperatingCountryId();

  const transformer = transformValuesForCountry(operatingCountryId);

  const submit = async (values: CompanyProfileFieldValues) => {
    const { data, errors } = await updateProfileDetails({
      variables: { input: transformer(values) },
    });

    return {
      data,
      errors,
    };
  };

  return { submit, error: error ?? undefined };
};
