import { useEffect, useMemo } from 'react';
import { ApolloQueryResult } from '@apollo/client/core/types';
import { reportError } from '@ascension/components/helpers/errorReporter';
import { useScopesQuery } from '@scope-of-works/hooks/useScopesQuery';
import { SCOPE_OF_WORKS_QUERY } from '@scope-of-works/queries';
import { ScopeActivityStatus } from '@ascension/_gqltypes/scope-of-works.generated';
import {
  Scope as ScopeData,
  ScopeVariables,
  Scope_scopeByPackage as Scope,
} from '@scope-of-works/types/Scope';

type ScopeStatus = {
  status: ScopeActivityStatus;
  updatedAt: string;
};

export type ScopeRfqStatusObject = Record<string, ScopeStatus>;

export const useGetScopeOfWorks = (
  packageId: number,
): {
  scope: Scope | null;
  status: ScopeRfqStatusObject | null;
  loading: boolean;
  refetch: () => Promise<ApolloQueryResult<ScopeData>>;
} => {
  const { data, error, refetch, loading } = useScopesQuery<ScopeData, ScopeVariables>(
    SCOPE_OF_WORKS_QUERY,
    {
      variables: { packageId: packageId.toString() },
    },
  );
  const scope = data?.scopeByPackage ?? null;

  useEffect(() => {
    if (error) {
      reportError(error);
    }
  }, [error]);

  const status: ScopeRfqStatusObject = useMemo(
    () =>
      scope?.scopeRfqStatuses.reduce<ScopeRfqStatusObject>(
        (acc, curr) => ({
          ...acc,
          [curr.rfqId]: {
            status: curr.status,
            updatedAt: curr.updatedAt,
          },
        }),
        {},
      ) ?? {},
    [scope],
  );

  return { refetch, scope, status, loading };
};
