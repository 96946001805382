import { ConnectionIndicator } from './ConnectionIndicator';
import styles from './styles.module.scss';

type CompanyNameHeaderProps = {
  companyName: string;
  altCompanyName?: string;
  connected?: boolean;
};

export const CompanyNameHeader = ({
  companyName,
  connected,
  altCompanyName,
}: CompanyNameHeaderProps) => (
  <div className={styles.nameAndConnectionIndicator}>
    <h2 className={styles.heading}>{companyName}</h2>
    {connected !== undefined && <ConnectionIndicator connected={connected} />}
    {altCompanyName && <span className={styles.altCompanyName}>({altCompanyName})</span>}
  </div>
);
