import { LoadingSpinner, Modal } from '@estimateone/frontend-components';
import { CheckoutButton } from './CheckoutButton';
import { CreditCardDetailsSection } from './CreditCardDetailsSection';
import { LiteToEssentialCostSummaryTable } from './LiteToEssentialCostSummaryTable';
import { useLiteToEssentialCheckoutData } from '@ascension/components/account-upgrade/modal/hooks/useLiteToEssentialCheckoutData';
import { useContactUsAboutUpgrade } from '@subbie/features/AccountUpgradeFeature/pages/NewUpgradeSelection/hooks';
import { UPGRADE_TYPE } from '@subbie/features/AccountUpgradeFeature/pages/NewUpgradeSelection/hooks/types';
import { PaymentMethodError } from './enums';
import styles from './styles.scss';

const dollarSymbol = '$';

export type CheckoutSectionProps = {
  onPay: () => Promise<void>;
  onHandleEnquiry: () => void;
  onHandleUpdatePaymentMethod: () => void;
  isPaymentProcessing?: boolean;
  hasPaymentError?: boolean;
};

export const AccountUpgradeModalCheckoutSection = ({
  onPay,
  onHandleEnquiry,
  onHandleUpdatePaymentMethod,
  isPaymentProcessing,
  hasPaymentError: paymentError,
}: CheckoutSectionProps) => {
  const checkoutData = useLiteToEssentialCheckoutData();
  const launchContactModal = useContactUsAboutUpgrade(UPGRADE_TYPE.Essential);

  if ('loading' in checkoutData) {
    return <LoadingSpinner />;
  }

  const {
    billingFrequency,
    creditCardDetails,
    ongoingAmount,
    proratedAmountDue,
    proratedCreditsApplied,
  } = checkoutData;
  const proratedAmountDueDollars = proratedAmountDue / 100;

  const onPayClicked = creditCardDetails === undefined ? launchContactModal : onPay;

  if (!creditCardDetails) {
    return (
      <Modal.Section span={{ lg: 6 }}>
        <CheckoutButton
          amount={proratedAmountDueDollars}
          currencySymbol={dollarSymbol} // TODO: code to symbol
          onClick={onPayClicked}
          paymentMethod="EFT"
          isDisabled={false}
        />
      </Modal.Section>
    );
  }

  const errors = new Array<PaymentMethodError>();
  if (creditCardDetails.isExpired) {
    errors.push(PaymentMethodError.CreditCardExpired);
  }

  if (paymentError) errors.push(PaymentMethodError.PaymentMethodDeclined);

  const isPayButtonDisabled = errors.length > 0 || isPaymentProcessing;

  return (
    <Modal.Section span={{ lg: 6 }}>
      <div className={styles.modalSectionTitle}>Summary</div>
      <LiteToEssentialCostSummaryTable
        billingFrequency={billingFrequency}
        ongoingCost={ongoingAmount / 100}
        proRataDiscount={proratedCreditsApplied ? proratedCreditsApplied / 100 : 0} // TODO
        totalDue={proratedAmountDueDollars}
        currencySymbol={dollarSymbol}
      />
      <CreditCardDetailsSection
        handleEnquiry={onHandleEnquiry}
        handleUpdatePaymentMethod={onHandleUpdatePaymentMethod}
        creditCardDetails={creditCardDetails}
        errors={errors}
      />
      <CheckoutButton
        amount={proratedAmountDueDollars}
        currencySymbol={dollarSymbol}
        onClick={onPayClicked}
        paymentMethod="CC"
        isDisabled={isPayButtonDisabled}
      />
    </Modal.Section>
  );
};
