import { E1Link, Checkbox, CheckboxStatus } from '@estimateone/frontend-components';
import { WatchlistItemAddedEnum } from './enums';
import styles from './styles.scss';

type WatchlistItemAddedProps = {
  hideAddedToWatchlist: boolean;
  toggleHideAddedToWatchlist: () => void;
};

export const WatchlistItemAdded = ({
  toggleHideAddedToWatchlist,
  hideAddedToWatchlist,
}: WatchlistItemAddedProps) => (
  <section className={styles.watchlistContent}>
    <h2>{WatchlistItemAddedEnum.Title}</h2>
    <p>
      {WatchlistItemAddedEnum.Description}
      <br />
      <E1Link href={WatchlistItemAddedEnum.LearnMoreLinkHref}>
        {WatchlistItemAddedEnum.LearnMoreLinkLabel}
      </E1Link>
    </p>
    <Checkbox
      id="HideAddedToWatchlist"
      label={WatchlistItemAddedEnum.CheckboxLabel}
      onClick={toggleHideAddedToWatchlist}
      statusCurrent={hideAddedToWatchlist ? CheckboxStatus.Checked : CheckboxStatus.Unchecked}
    />
  </section>
);
