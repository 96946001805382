import { Icon, IconName } from '@estimateone/frontend-components';
import { TextAreaField } from '@shared/FinalForm/Field';
import { TenderHandoverWizardFieldName } from '../enums';
import styles from './styles.scss';

export const DescriptionDetails = () => (
  <section className={styles.descriptionSection}>
    <div className={styles.headingSection}>
      <h2>Description of Works</h2>
      <a
        aria-label="Help"
        href="https://builder.support.estimateone.com/en/articles/1705568535-creating-and-managing-projects-faq#what-should-i-write-in-the-description-of-works"
        target="_blank"
        rel="noreferrer noopener"
        className={styles.tooltip}
      >
        <Icon name={IconName.Help} className={styles.helpIcon} />
      </a>
    </div>
    <TextAreaField
      id="tender-handover-wizard--description"
      label="Description"
      fieldName={TenderHandoverWizardFieldName.Description}
      hideLabel
    />
  </section>
);
