import { CellProps } from '@estimateone/frontend-components';
import { InlineEditableCurrency } from '@builder/common/InlineEditableCurrency';
import { UpdateBudgetAmountInput } from '@ascension/_gqltypes/builder.generated';
import { EntityId } from '@ascension/types';
import { Package } from '@builder/features/ProcurementLettingSchedule/types';
import styles from '../styles.scss';

export const CurrencyCell =
  (
    id: string,
    ariaLabel: string,
    debouncedAmountUpdate: (id: EntityId, args: UpdateBudgetAmountInput) => void,
  ) =>
  ({ value, row: { original } }: CellProps<Package, number | undefined>) => (
    <div className={styles.cellAlign}>
      <InlineEditableCurrency
        id={`${id}${original.id}`}
        aria-label={ariaLabel}
        errorMessage="Enter a number (2 decimal places)"
        value={value}
        onValueChange={async (updatedValue) => {
          debouncedAmountUpdate(original.id, {
            packageId: original.id,
            amount: updatedValue?.toString() ?? null,
          });
        }}
      />
    </div>
  );
