import { gql } from '@apollo/client';
import { PackageDashboardRfq } from '../fragments';

export const SET_RFQ_QUOTING_STATUS = gql`
  mutation SetRfqQuotingStatus($input: SetRfqQuotingStatusInput!) {
    setRfqQuotingStatus(input: $input) {
      ...PackageDashboardRfq
    }
  }
  ${PackageDashboardRfq}
`;
