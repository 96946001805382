import { useQuery } from '@apollo/client';
import { GET_DOCUMENT_INTEGRATION } from './queries';
import {
  GetDocumentIntegration,
  GetDocumentIntegrationVariables,
} from './types/GetDocumentIntegration';
import { EntityId } from '@ascension/types';

export const useGetDocumentIntegration = (stageId: EntityId, skip = false) => {
  const { data, loading, refetch } = useQuery<
    GetDocumentIntegration,
    GetDocumentIntegrationVariables
  >(GET_DOCUMENT_INTEGRATION, { variables: { stageId }, skip });

  return {
    documentIntegration: data?.getDocumentIntegration,
    loading,
    refetch,
  };
};
