import Routing from 'routing';
import E1Request from '../../../../js/classes/E1Request';
import { EntityId } from '../../../../types';

export type AddressFormState = {
  id: EntityId;
  name: string;
  shortName: string;
};

export type AddressFormCountry = {
  id: EntityId;
  currency: string;
  name: string;
  // eslint-disable-next-line camelcase
  short_name: string;
  states: AddressFormState[];
};

type CountryListResponse = {
  success: true;
  data: {
    allCountries: AddressFormCountry[];
    defaultCountryId?: EntityId | null;
  };
};

export default async () =>
  new E1Request<CountryListResponse, Record<string, never>>(
    Routing.generate('api_countries', {
      getDefault: true,
    }),
  ).submit();
