import { useState } from 'react';
import { flushSync } from 'react-dom';
import Routing from 'routing';
import {
  ActionMenu,
  Button,
  ButtonVariant,
  Icon,
  IconName,
} from '@estimateone/frontend-components';
import E1Request from '@ascension/js/classes/E1Request';
import {
  generateCreateQuoteLink,
  generateQuoteLinkFromRfq,
} from '@builder/features/ProcurementLettingSchedule/PackageDashboard/utils';
import { AwardPackageSlider } from '../../../AwardPackageSlider/AwardPackageSlider';
import showHistoryModal from './showHistoryModal';
import useFlashMessage from '../../../../../../shared/Util/useFlashMessage';
import { useSetAwardedRfqOnLettingSchedule } from '../../../AwardPackageSlider/hooks/useSetAwardedRfqOnLettingSchedule';
import { useGetPackageWithRfqs } from '../../hooks/useGetPackageWithRfqs';
import { useSetRfqQuotingStatus } from '../../hooks/useSetRfqQuotingStatus';
import { Action, useInteractAnalytics } from '@ascension/components/hooks/Analytics';
import { ButtonName } from '@ascension/components/hooks/Analytics/buttonNames';
import { ScreenName } from '@ascension/components/hooks/Analytics/screenName';
import { QuoteReturnRoute } from '@ascension/enums';
import { Rfq } from '../../types';
import { RfqQuotingStatus } from '@ascension/_gqltypes/builder.generated';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

export enum AwardedState {
  UnAwarded,
  AwardedToThisCompany,
  AwardedToAnotherCompany,
}

export type PackageDashboardTableOptionsProps = {
  rfq: Rfq;
  stageId: EntityId;
  quotingStatus: RfqQuotingStatus | null;
  packageData: ReturnType<typeof useGetPackageWithRfqs>['packageData'];
  refetch: ReturnType<typeof useGetPackageWithRfqs>['refetch'];
  awardedState: AwardedState;
};

export const PackageDashboardTableOptions = ({
  rfq,
  stageId,
  quotingStatus,
  packageData,
  refetch,
  awardedState,
}: PackageDashboardTableOptionsProps) => {
  const [isAwardPackageSliderOpen, setIsAwardPackageSliderOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { addEvent } = useInteractAnalytics();
  const { submit: updateQuotingStatus } = useSetRfqQuotingStatus();
  const { submit: markAsAwarded } = useSetAwardedRfqOnLettingSchedule();
  const { success: showSuccessMessage } = useFlashMessage();

  const actionMenuButton = (
    <Button
      variant={ButtonVariant.Transparent}
      aria-expanded={isMenuOpen}
      aria-label="Toggle Options Menu"
      onClick={() => flushSync(() => setIsMenuOpen(!isMenuOpen))}
    >
      <Icon name={IconName.More} size={{ height: '0.5em', width: '1.5em' }} />
    </Button>
  );

  const options = [
    { status: RfqQuotingStatus.QUOTED, label: 'Mark as: Quoted' },
    { status: RfqQuotingStatus.QUOTING, label: 'Mark as: Quoting' },
    { status: RfqQuotingStatus.DECLINED, label: 'Mark as: Not Quoting' },
    { status: null, label: 'Mark as: No Response' },
  ];

  const isAwarded = awardedState !== AwardedState.UnAwarded;

  const copyRfqLink = async () => {
    const requestNew = Routing.generate('app_stagerfq_viewrfqlink', { id: stageId, rfqId: rfq.id });
    const req = new E1Request(requestNew, 'POST');
    req.setShowLoadingModal();
    await req.submit();
  };

  const resendRfqLink = async () => {
    const route = Routing.generate('app_stagerfq_resend', { id: stageId, rfqId: rfq.id });
    const req = new E1Request(route, 'POST');
    await req.submit();
  };

  const trackCopyRfqLinkClick = () => {
    addEvent({
      action: Action.BUTTON_CLICKED,
      buttonName: ButtonName.COPY_DOWNLOAD_LINK,
      screenName: ScreenName.PROCUREMENT_PACKAGE_DASHBOARD,
    });
  };

  const trackRfqResendLinkClick = () => {
    addEvent({
      action: Action.BUTTON_CLICKED,
      buttonName: ButtonName.RESEND_DOWNLOAD_LINK,
      screenName: ScreenName.PROCUREMENT_PACKAGE_DASHBOARD,
    });
  };

  const trackAddQuoteClick = () =>
    addEvent({
      action: Action.BUTTON_CLICKED,
      buttonName: ButtonName.PROCUREMENT_RESPONSES_TABLE_MENU_ADD_QUOTE,
      screenName: ScreenName.PROCUREMENT_PACKAGE_DASHBOARD,
    });

  const trackViewQuoteClick = () =>
    addEvent({
      action: Action.BUTTON_CLICKED,
      buttonName: ButtonName.PROCUREMENT_RESPONSES_TABLE_MENU_VIEW_QUOTE,
      screenName: ScreenName.PROCUREMENT_PACKAGE_DASHBOARD,
    });

  const { lettingScheduleDetails } = packageData ?? {};

  return (
    <>
      <ActionMenu
        triggerElement={actionMenuButton}
        visible={isMenuOpen}
        onClickOutside={() => setIsMenuOpen(false)}
      >
        <>
          <ActionMenu.Item
            key="ViewAuditTrail"
            onClick={() => {
              setIsMenuOpen(false);
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              showHistoryModal('audit', stageId, rfq.id, refetch);
            }}
            className={styles.actionItemWithoutIcon}
          >
            View Audit Trail
          </ActionMenu.Item>
          <ActionMenu.Item
            key="ViewAddNotes"
            onClick={() => {
              setIsMenuOpen(false);
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              showHistoryModal('notes', stageId, rfq.id, refetch);
            }}
            className={styles.actionItemWithoutIcon}
          >
            View/Add Notes
          </ActionMenu.Item>
          <div className={styles.menuDivider}>
            <hr />
          </div>
          <ActionMenu.Item
            key="ResendDownloadLink"
            onClick={() => {
              setIsMenuOpen(false);
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              resendRfqLink();
              trackRfqResendLinkClick();
            }}
            className={styles.actionItemWithoutIcon}
          >
            Re-send Download Link
          </ActionMenu.Item>
          <ActionMenu.Item
            key="CopyDownloadLink"
            onClick={() => {
              setIsMenuOpen(false);
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              copyRfqLink();
              trackCopyRfqLinkClick();
            }}
            className={styles.actionItemWithoutIcon}
          >
            Copy Download Link
          </ActionMenu.Item>
          <div className={styles.menuDivider}>
            <hr />
          </div>
          {!rfq.latestQuote && (
            <ActionMenu.Item
              key="AddQuote"
              onClick={async () => {
                setIsMenuOpen(false);
                await trackAddQuoteClick();
                window.location.assign(
                  generateCreateQuoteLink(rfq.id, stageId, window.location.pathname),
                );
              }}
              className={styles.actionItemWithoutIcon}
            >
              Add Quote
            </ActionMenu.Item>
          )}
          {rfq.latestQuote && (
            <ActionMenu.Item
              key="ViewQuote"
              onClick={async () => {
                setIsMenuOpen(false);
                await trackViewQuoteClick();
                const link = generateQuoteLinkFromRfq(
                  rfq,
                  QuoteReturnRoute.BuilderPackageDashboard,
                );
                if (link) window.location.assign(link);
              }}
              className={styles.actionItemWithoutIcon}
            >
              View Quote
            </ActionMenu.Item>
          )}
          {options.map((option) => (
            <ActionMenu.Item
              key={option.label}
              onClick={async () => {
                setIsMenuOpen(false);
                await updateQuotingStatus(rfq, option.status);
              }}
              className={styles.actionItemWithIcon}
            >
              {quotingStatus === option.status ? (
                <div className={styles.selectedButton}>
                  <Icon
                    name={IconName.Check}
                    size={{ height: 16, width: 16 }}
                    className={styles.selectedIcon}
                  />
                  <div className={styles.selectedLabel}>{option.label}</div>
                </div>
              ) : (
                <div className={styles.unselectedLabel}>{option.label}</div>
              )}
            </ActionMenu.Item>
          ))}
          <div className={styles.menuDivider}>
            <hr />
          </div>
          {packageData &&
          lettingScheduleDetails &&
          awardedState === AwardedState.AwardedToThisCompany ? (
            <ActionMenu.Item
              key="UndoMarkAsAwarded"
              onClick={async () => {
                setIsMenuOpen(false);
                await markAsAwarded(lettingScheduleDetails.id, null, null);
                await refetch();
                showSuccessMessage({
                  title: 'Success',
                  message: `${packageData.title} is no longer marked as Awarded`,
                });
              }}
              className={styles.actionItemWithoutIcon}
            >
              Undo Mark Package as Awarded
            </ActionMenu.Item>
          ) : (
            <ActionMenu.Item
              key="MarkAsAwarded"
              disabled={isAwarded}
              onClick={async () => {
                setIsMenuOpen(false);
                setIsAwardPackageSliderOpen(true);
              }}
              className={styles.actionItemWithoutIcon}
            >
              Mark Package as Awarded
            </ActionMenu.Item>
          )}
        </>
      </ActionMenu>
      {packageData && lettingScheduleDetails && isAwardPackageSliderOpen ? (
        <AwardPackageSlider
          isOpen={isAwardPackageSliderOpen}
          onRequestClose={() => setIsAwardPackageSliderOpen(false)}
          onMarkPackageAsAwarded={async (response) => {
            await refetch();
            showSuccessMessage({
              title: 'Success',
              message: `${packageData.title} marked as Awarded to ${response.awardedToRfq?.companyName}`,
            });
          }}
          lettingScheduleId={lettingScheduleDetails.id}
          packageId={packageData.id}
          packageTitle={packageData.title}
          packageAssignedUserName={lettingScheduleDetails.assignedToUser?.fullName ?? undefined}
          preselectedRfqId={rfq.id}
          stageId={stageId}
        />
      ) : null}
    </>
  );
};
