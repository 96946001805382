import { useEffect, useCallback } from 'react';
import { AsyncSelect } from '@estimateone/frontend-components';
import { getGoogleSuggestions, getLocationForPlace } from './utils/google';
import { LANG, LANG_REF } from './utils/lang';
import { useDebouncedCallback } from '@ascension/components/hooks/useDebouncedCallback';
import { ValueLabelOption } from '@estimateone/frontend-components/src/components/Form/Select/types';

export type SelectedPlace = {
  lat: number;
  lon: number;
  locationLabel: string;
  adminLevel1?: string;
  country?: string;
  resultTypes?: string[];
};

type GoogleResultOption = {
  placeId: string;
};

type E1ResultOption = {
  lat: number;
  lon: number;
};

type Option = GoogleResultOption | E1ResultOption;

const isGoogleResultOption = (option: Option): option is GoogleResultOption =>
  (option as GoogleResultOption).placeId !== undefined;

const selectedPlaceToOption = (option: SelectedPlace): ValueLabelOption<Option> => ({
  value: {
    lat: option.lat,
    lon: option.lon,
  },
  label: option.locationLabel,
});

type PlaceSuggestingSelectProps = {
  countryId: number;
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  onLocationSelected: (location: SelectedPlace | null) => void;
  hideLabel?: boolean;
  formId?: string;
  value: SelectedPlace | null;
  placePredictionsTypes?: string[];
};

const placePredictionsDefaultTypes = ['locality', 'administrative_area_level_3', 'postal_code'];

export const PlaceSuggestingSelect = ({
  countryId,
  onLocationSelected,
  label,
  placeholder,
  isRequired = true,
  hideLabel = false,
  formId,
  value,
  placePredictionsTypes,
}: PlaceSuggestingSelectProps) => {
  const selectedLocation = value ? selectedPlaceToOption(value) : null;
  const types = placePredictionsTypes ?? placePredictionsDefaultTypes;

  useEffect(() => {
    if (formId) {
      const onFormReset = (event: Event) => {
        if (event.target instanceof HTMLFormElement && event.target?.id === formId) {
          onLocationSelected(null);
        }
      };

      document.addEventListener('reset', onFormReset);

      return () => {
        document.removeEventListener('reset', onFormReset);
      };
    }
    return () => {
      /* noop */
    };
  }, [formId, onLocationSelected]);

  // when the user types something in, do a (debounced) search for suggestions to display
  const loadOptions = useDebouncedCallback(
    (inputValue: string, callback: (opts: ValueLabelOption<GoogleResultOption>[]) => void) => {
      if (inputValue.length < 3) {
        return callback([]);
      }

      return getGoogleSuggestions(inputValue, countryId, types)
        .then((predictions) =>
          predictions.map(({ description, placeId }) => ({
            label: description,
            value: { placeId },
          })),
        )
        .then(callback);
    },
  );

  // when the user clicks a suggestion
  const onChange = useCallback(
    (option: ValueLabelOption<Option> | null) => {
      if (!option) {
        return onLocationSelected(null);
      }

      if (isGoogleResultOption(option.value)) {
        return getLocationForPlace(option.value.placeId).then((res) => {
          onLocationSelected({
            ...res,
            locationLabel: option.label,
          });
        });
      }

      return onLocationSelected({
        ...option.value,
        locationLabel: option.label,
      });
    },
    [onLocationSelected],
  );

  return (
    <AsyncSelect<ValueLabelOption<Option>, false>
      id="place"
      inputId="place"
      name="Suburb"
      hideLabel={hideLabel}
      label={label ?? LANG[countryId][LANG_REF.PLACE_SELECT_LABEL]}
      placeholder={placeholder ?? LANG[countryId][LANG_REF.PLACE_SELECT_PLACEHOLDER]}
      onChange={onChange}
      value={selectedLocation}
      loadOptions={loadOptions}
      isRequired={isRequired}
      isClearable
    />
  );
};
