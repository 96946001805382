import { CellProps, joinClassNames } from '@estimateone/frontend-components';
import { useCurrencyFormatter, useCurrencySymbol } from '@shared/context';
import { Package } from '@builder/features/ProcurementLettingSchedule/types';
import styles from '../styles.scss';

type VarianceCellProps = CellProps<Package, string>;
export const VarianceCell = ({ row: { original } }: VarianceCellProps) => {
  const currencyFormatter = useCurrencyFormatter();
  const currency = useCurrencySymbol();
  const { budgetAmount, actualAmount } = original.lettingScheduleDetails ?? {
    budgetAmount: null,
    actualAmount: null,
  };

  const calculatedVariance =
    budgetAmount !== null && actualAmount !== null
      ? Number(budgetAmount) - Number(actualAmount)
      : undefined;

  const variance = currencyFormatter.toString(calculatedVariance ?? 0);

  const displayPrefix = calculatedVariance !== undefined && calculatedVariance > 0 ? `+` : ``;
  const displayCurrency = currency ? `${currency} ` : ``;
  const displayValue = calculatedVariance === undefined ? `-` : variance;
  const displayStyles = [styles.displayVariance];

  if (!calculatedVariance || calculatedVariance === 0) {
    displayStyles.push(styles.noVariance);
  } else if (calculatedVariance > 0) {
    displayStyles.push(styles.positiveVariance);
  } else if (calculatedVariance < 0) {
    displayStyles.push(styles.negativeVariance);
  }

  return (
    <div className={joinClassNames(styles.cellAlign, styles.variance)}>
      <span data-testid="variance" className={displayStyles.join(' ')}>
        {`${displayCurrency}${displayPrefix}${displayValue}`}
      </span>
    </div>
  );
};
