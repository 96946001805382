import { useCallback } from 'react';
import { atom, useAtom } from 'jotai';

export type SSCWatchlistAtomProps =
  | {
      isOpen: true;
      data: {
        selectedProjectId: number;
      };
    }
  | {
      isOpen: false;
      data: null;
    };

const sscWatchlistModalAtom = atom<SSCWatchlistAtomProps>({
  isOpen: false,
  data: null,
});

export const useSSCWatchlistModal = (): SSCWatchlistAtomProps & {
  open(props: { selectedProjectId: number }): void;
  close: () => void;
} => {
  const [watchlistModalAtomState, setWatchlistModalAtomState] = useAtom(sscWatchlistModalAtom);

  const open = useCallback((props: { selectedProjectId: number }) => {
    setWatchlistModalAtomState({
      isOpen: true,
      data: {
        selectedProjectId: props.selectedProjectId,
      },
    });
  }, []);

  const close = useCallback(() => {
    setWatchlistModalAtomState({
      isOpen: false,
      data: null,
    });
  }, []);

  return {
    ...watchlistModalAtomState,
    open,
    close,
  };
};
