import { useMutation } from '@apollo/client';
import { DELETE_ACCOUNT_OFFICE } from './mutations';
import {
  DeleteAccountOfficeMutation,
  DeleteAccountOfficeMutationVariables,
} from './mutations.generated';

export const useSubmitForm = () => {
  const [deleteAccountOffice, { data }] = useMutation<
    DeleteAccountOfficeMutation,
    DeleteAccountOfficeMutationVariables
  >(DELETE_ACCOUNT_OFFICE);

  const errors =
    data?.deleteAccountOfficeV2.__typename === 'Errors'
      ? data.deleteAccountOfficeV2.errors
      : undefined;

  const submit = async (accountOfficeId: GraphQLEntityId) => {
    const { data: result } = await deleteAccountOffice({
      variables: {
        accountOfficeId,
      },
    });

    return result?.deleteAccountOfficeV2.__typename !== 'Errors';
  };

  return { submit, errors };
};
