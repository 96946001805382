import { useMutation } from '@apollo/client';
import { CREATE_STAGE_SHAREPOINT_MAP } from './mutations';
import {
  CreateStageSharepointMap,
  CreateStageSharepointMapVariables,
} from './types/CreateStageSharepointMap';
import { CreateStageSharepointMapInput } from '@ascension/_gqltypes/builder.generated';

export const useCreateStageSharepointMap = () => {
  const [createStageSharepointMap, { loading, error }] = useMutation<
    CreateStageSharepointMap,
    CreateStageSharepointMapVariables
  >(CREATE_STAGE_SHAREPOINT_MAP);

  const submit = async (args: CreateStageSharepointMapInput) => {
    const { data } = await createStageSharepointMap({
      variables: {
        input: args,
      },
    });

    if (data?.createStageSharepointMap.__typename === 'Stage') {
      return { loading, errors: undefined };
    }

    if (data?.createStageSharepointMap.__typename === 'Errors') {
      return { loading, errors: data.createStageSharepointMap.errors };
    }

    return { loading, errors: undefined };
  };

  return { submit, isLoading: loading, hasError: error !== undefined };
};
