import { useApolloClient, useMutation } from '@apollo/client';
import { useCompanyProfileCacheItemId } from '@profiles/providers/ProfileProvider';
import { UPDATE_TRADES } from '@profiles/features/CompanyProfile/components/Trades/mutations';
import {
  UpdateTradesMutation,
  UpdateTradesMutationVariables,
} from '@profiles/features/CompanyProfile/components/Trades/mutations.generated';
import {
  EditTradesFieldName,
  EditTradesFieldValues,
} from '@profiles/features/CompanyProfile/components/Trades/types';

const transformValues = (values: EditTradesFieldValues): UpdateTradesMutationVariables => ({
  stockTradeIds: values[EditTradesFieldName.Trades].map((trade) => trade.value),
});

export const useEditTradeSubmitForm = () => {
  const { cache } = useApolloClient();
  const id = useCompanyProfileCacheItemId();

  const [updateTrades, { error }] = useMutation<
    UpdateTradesMutation,
    UpdateTradesMutationVariables
  >(UPDATE_TRADES, {
    onCompleted: (data) => {
      cache.modify({
        id,
        fields: {
          trades: () =>
            data?.changeStockTrades.map((stockTrade) => ({
              /**
               * Not the real Id, but we need to give the cache something
               * otherwise it triggers a refetch of (most likely) stale data
               */
              id: stockTrade.id,
              __typename: 'ProfileTrade',
              trade: {
                id: stockTrade.id,
                __typename: 'ProfileStockTrade',
                ascensionId: stockTrade.id,
                name: stockTrade.name,
              },
            })),
        },
      });
    },
  });

  const submit = async (values: EditTradesFieldValues) => {
    const { errors, data } = await updateTrades({
      variables: transformValues(values),
    });

    return { errors: errors || null, data };
  };

  return { submit, error: error ?? undefined };
};
