import { getUniqueStockTradesForProject } from '@subbie/modal/ProjectSlider/util';
import { DisplayFeatures, useSupplierPaywallDecider } from './useSupplierPaywallDecider';
import { getTranslation, LangRef } from '@shared/Util/lang';
import { User } from '@subbie/context/AccountProvider';
import {
  useCanUpgradeToConsultants,
  useCanViewConsultants,
} from '@subbie/context/ConsultantDetailsAccessContext/hooks';
import { useHasSubbieSupplierConnectionAccess } from '@subbie/common/CreditBalance/hooks';
import { useProjectSupplierSummary } from '@subbie/modal/ProjectSlider/ProjectSliderBody/ProjectSuppliers/hooks';
import { AccountType } from '@ascension/_gqltypes/subbie.generated';
import {
  GetProjectSliderData_projectForSlider_notes as Note,
  GetProjectSliderData_projectForSlider_project as Project,
} from '@subbie/modal/types/GetProjectSliderData';
import { ProjectSubbieSummary_projectSubbieSummary as ProjectSubbieSummary } from '@subbie/modal/types/ProjectSubbieSummary';

type ViewableNoticeboardProject = Extract<Project, { __typename: 'ViewableNoticeboardProject' }>;

export type TabSuffixes =
  | 'details'
  | 'stages'
  | 'subbies'
  | 'suppliers'
  | 'consultants'
  | 'trades'
  | 'history'
  | 'notes';

export type ScrollSpySection = {
  readonly tabSuffix: TabSuffixes;
  readonly label: string;
  readonly requiresFeature: string | null;
  readonly badgeValue?: number;
  readonly isNew?: boolean;
};

export const useScrollSpySections = (
  project: ViewableNoticeboardProject,
  user: User | null,
  projectNotes: Note[],
  projectSubbieSummary: ProjectSubbieSummary | undefined,
): ScrollSpySection[] => {
  const canViewConsultants = useCanViewConsultants();
  const canUpgradeToConsultants = useCanUpgradeToConsultants();
  const canViewSubbiesSection = useHasSubbieSupplierConnectionAccess();
  const { summary: projectInterestedSuppliers } = useProjectSupplierSummary(project.id);

  const uniqueProjectStockTrades = getUniqueStockTradesForProject(project);
  const userStockTradeIds = new Set(user?.account?.stockTrades.map(({ id }) => id));
  const matchedUserStockTradeCount = uniqueProjectStockTrades
    .map(({ id }) => id)
    .filter((id) => userStockTradeIds.has(id)).length;
  const subbieCount = projectSubbieSummary?.totalSubbieCount ?? 0;
  const supplierCount = projectInterestedSuppliers?.supplierCount ?? 0;
  const isSupplier = user?.account?.type === AccountType.SUPPLIER;

  const paywallSelection = useSupplierPaywallDecider();

  const commonSectionsBefore: ScrollSpySection[] = [
    {
      tabSuffix: 'details',
      label: 'Description',
      requiresFeature: null,
    },
    {
      tabSuffix: 'stages',
      label: `${getTranslation(LangRef.BUILDERS_HEADING)} & Docs`,
      requiresFeature: null,
    },
  ];
  const commonSectionsAfter: ScrollSpySection[] = [
    {
      tabSuffix: 'consultants',
      label: 'Consultants',
      requiresFeature: DisplayFeatures.CONSULTANTS,
    },
    {
      tabSuffix: 'trades',
      label: 'Trades',
      badgeValue: matchedUserStockTradeCount,
      requiresFeature: null,
    },
    {
      tabSuffix: 'history',
      label: 'History',
      requiresFeature: null,
    },
    {
      tabSuffix: 'notes',
      label: 'Notes',
      badgeValue: projectNotes.length,
      requiresFeature: null,
    },
  ];

  const allSections: ScrollSpySection[] = isSupplier
    ? [
        ...commonSectionsBefore,
        {
          tabSuffix: 'subbies',
          label: 'Subcontractors',
          badgeValue: subbieCount,
          requiresFeature: DisplayFeatures.SUBCONTRACTORS,
        },
        ...commonSectionsAfter,
      ]
    : [
        ...commonSectionsBefore,
        {
          tabSuffix: 'suppliers',
          label: 'Suppliers',
          badgeValue: supplierCount,
          requiresFeature: DisplayFeatures.SUPPLIERS,
        },
        ...commonSectionsAfter,
      ];

  // It might seem a bit counterintuitive to show a section when the user is not supposed to see them.
  // However, the reason we want to show a section when the paywall selection matches is because each section component
  // does it own checks to see if the user should see the content, and if not, it'll show a paywall instead of the content.
  const shouldShowConsultant =
    canViewConsultants ||
    (canUpgradeToConsultants && paywallSelection === DisplayFeatures.CONSULTANTS);

  const shouldShowSubbies =
    canViewSubbiesSection || paywallSelection === DisplayFeatures.SUBCONTRACTORS;

  return allSections.filter(
    ({ requiresFeature }) =>
      requiresFeature === null ||
      (requiresFeature === DisplayFeatures.CONSULTANTS && shouldShowConsultant) ||
      requiresFeature === DisplayFeatures.SUPPLIERS ||
      (requiresFeature === DisplayFeatures.SUBCONTRACTORS && shouldShowSubbies),
  );
};
