import { useLazyQuery } from '@apollo/client';
import { GET_PRESIGNED_LOGO_UPLOAD_URL } from './queries';
import { GetPresignedLogoUploadUrlQuery } from './queries.generated';

export const usePresignedLogoUploadUrl = (): (() => Promise<
  { success: false; url: undefined } | { success: true; url: string }
>) => {
  // This has to be lazy, and we cannot cache it because it expires very quickly
  const [exec] = useLazyQuery<GetPresignedLogoUploadUrlQuery>(GET_PRESIGNED_LOGO_UPLOAD_URL, {
    fetchPolicy: 'network-only',
  });

  return () =>
    exec().then((res) => {
      const url = res?.data?.presignedLogoLink;
      if (url) {
        return { success: true, url };
      }
      return { success: false };
    });
};
