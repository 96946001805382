import { Button, ButtonVariant } from '@estimateone/frontend-components';
import styles from './DrawerFooterButtons.module.scss';

export const DrawerFooterButtons = ({
  formId,
  close,
  hasValidationErrors,
  saveButtonText = 'Save',
  cancelButtonText = 'Cancel',
}: {
  formId: string;
  close: () => void;
  hasValidationErrors?: boolean;
  saveButtonText?: string;
  cancelButtonText?: string;
}) => (
  <div className={styles.drawerFooterButtonsWrapper}>
    <Button className={styles.button} variant={ButtonVariant.Grey} onClick={close}>
      {cancelButtonText}
    </Button>
    <Button
      className={styles.button}
      variant={ButtonVariant.Primary}
      type="submit"
      form={formId}
      disabled={hasValidationErrors}
    >
      {saveButtonText}
    </Button>
  </div>
);
