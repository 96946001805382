import { ReactNode } from 'react';
import styles from './TagGroup.module.scss';
import { Tag } from './subcomponents';

type TagGroupProps = {
  header: string;
  children: ReactNode;
};

export const TagGroup = ({ header, children }: TagGroupProps) => (
  <div>
    <h4 className={styles.tagsHeader}>{header}</h4>
    {children}
  </div>
);

// eslint-disable-next-line fp/no-mutation
TagGroup.Tag = Tag;
