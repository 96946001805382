import { useQuery } from '@apollo/client';
import { GET_BILLING_CONTACT } from '../queries';
import { GetBillingContact } from '../types/GetBillingContact';

export const useBillingContact = () => {
  const { loading, data } = useQuery<GetBillingContact>(GET_BILLING_CONTACT);

  return {
    loading,
    billingContact: data?.billingContact,
  };
};
