import { useAtomValue, useSetAtom } from 'jotai';
import { removeConnectionModalContextAtom } from './atoms';
import { EntityId } from '@ascension/types';

export const useRemoveNetworkConnectionModalContext = () =>
  useAtomValue(removeConnectionModalContextAtom);

export const useOpenRemoveNetworkConnectionModal = () => {
  const setModalContext = useSetAtom(removeConnectionModalContextAtom);

  const openModal = ({ data }: { data: { addressbookCompanyId: EntityId } }) => {
    setModalContext({ data, open: true });
  };
  return { openModal };
};

export const useCloseRemoveNetworkConnectionModal = () => {
  const setModalContext = useSetAtom(removeConnectionModalContextAtom);

  const closeModal = () => {
    setModalContext({
      data: null,
      open: false,
    });
  };
  return { closeModal };
};
