import { Alert, AlertVariant, AlertIcon, Modal, ModalSize } from '@estimateone/frontend-components';
import styles from './styles.scss';

export type InvitesErrorModalProps = {
  isOpen: boolean;
  sentInvitationsCount: number;
  totalInvitationsCount: number;
  onRequestClose: () => void;
  errorMessages: string[];
};

export const InvitesErrorModal = ({
  isOpen,
  totalInvitationsCount,
  sentInvitationsCount,
  onRequestClose,
  errorMessages,
}: InvitesErrorModalProps) => (
  <Modal size={ModalSize.Small} isOpen={isOpen} onRequestClose={onRequestClose}>
    <Modal.Section>
      <p className={styles.title}>
        {sentInvitationsCount === 0
          ? `No invitations were able to be sent`
          : `Only ${sentInvitationsCount} of ${totalInvitationsCount} invitations were successfully sent`}
      </p>
      <Alert variant={AlertVariant.Red}>
        <div className={styles.errorAlert}>
          <AlertIcon className={styles.errorIcon} />
          <p>
            Error:{' '}
            {errorMessages.map((msg) => (
              <span key={msg}>
                {msg} <br />
              </span>
            ))}
          </p>
        </div>
      </Alert>
    </Modal.Section>
  </Modal>
);
