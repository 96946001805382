export enum UPGRADE_TYPE {
  Default = 'Default',
  NationalOrMultiState = 'NationalOrMultiState',
  // Supplier
  Standard = 'Standard',
  Professional = 'Professional',
  ProfessionalPlus = 'ProfessionalPlus',
  ProfessionalPlusMoreCredits = 'SscGetMoreCredits',
  TrialSSC = 'TrialSSC',
  // Subbie
  Lite = 'Lite',
  Essential = 'Essential',
  EssentialNational = 'EssentialNational',
}
