export enum ContractSizeEnum {
  SIZE_0 = 0,
  SIZE_10k = 10_000,
  SIZE_25k = 25_000,
  SIZE_50k = 50_000,
  SIZE_75k = 75_000,
  SIZE_100k = 100_000,
  SIZE_250k = 250_000,
  SIZE_500k = 500_000,
  SIZE_750k = 750_000,
  SIZE_1M = 1_000_000,
  SIZE_2_5M = 2_500_000,
  SIZE_5M = 5_000_000,
  SIZE_7_5M = 7_500_000,
  SIZE_10M = 10_000_000,
  SIZE_25M = 25_000_000,
  SIZE_50M = 50_000_000,
  SIZE_50M_PLUS = 500_000_000,
  // just a large number unlikely to be in use
  // 2_147_483_647 = max 32-bit signed integer
  // todo: replace with -1 after db is moved into ascension
  AVAILABLE_UPON_REQUEST = 2_147_483_647,
}

export type ContractSizeOption = {
  min: ContractSizeEnum;
  value: ContractSizeEnum;
  label: string;
};

export const AvailableUponRequestText = 'Contract value available upon request';

export const contractSizeRangeOptions: ContractSizeOption[] = [
  {
    min: ContractSizeEnum.SIZE_0,
    value: ContractSizeEnum.SIZE_10k,
    label: '$0 - 10k',
  },
  {
    min: ContractSizeEnum.SIZE_10k,
    value: ContractSizeEnum.SIZE_25k,
    label: '$10k - 25k',
  },
  {
    min: ContractSizeEnum.SIZE_25k,
    value: ContractSizeEnum.SIZE_50k,
    label: '$25k - 50k',
  },
  {
    min: ContractSizeEnum.SIZE_50k,
    value: ContractSizeEnum.SIZE_75k,
    label: '$50k - 75k',
  },
  {
    min: ContractSizeEnum.SIZE_75k,
    value: ContractSizeEnum.SIZE_100k,
    label: '$75k - 100k',
  },
  {
    min: ContractSizeEnum.SIZE_100k,
    value: ContractSizeEnum.SIZE_250k,
    label: '$100k - 250k',
  },
  {
    min: ContractSizeEnum.SIZE_250k,
    value: ContractSizeEnum.SIZE_500k,
    label: '$250k - 500k',
  },
  {
    min: ContractSizeEnum.SIZE_500k,
    value: ContractSizeEnum.SIZE_750k,
    label: '$500k - 750k',
  },
  {
    min: ContractSizeEnum.SIZE_750k,
    value: ContractSizeEnum.SIZE_1M,
    label: '$750k - 1m',
  },
  {
    min: ContractSizeEnum.SIZE_1M,
    value: ContractSizeEnum.SIZE_2_5M,
    label: '$1m - 2.5m',
  },
  {
    min: ContractSizeEnum.SIZE_2_5M,
    value: ContractSizeEnum.SIZE_5M,
    label: '$2.5m - 5m',
  },
  {
    min: ContractSizeEnum.SIZE_5M,
    value: ContractSizeEnum.SIZE_7_5M,
    label: '$5m - 7.5m',
  },
  {
    min: ContractSizeEnum.SIZE_7_5M,
    value: ContractSizeEnum.SIZE_10M,
    label: '$7.5m - 10m',
  },
  {
    min: ContractSizeEnum.SIZE_10M,
    value: ContractSizeEnum.SIZE_25M,
    label: '$10m - 25m',
  },
  {
    min: ContractSizeEnum.SIZE_25M,
    value: ContractSizeEnum.SIZE_50M,
    label: '$25m - 50m',
  },
  {
    min: ContractSizeEnum.SIZE_50M,
    value: ContractSizeEnum.SIZE_50M_PLUS,
    label: '$50m+',
  },
  {
    min: ContractSizeEnum.AVAILABLE_UPON_REQUEST,
    value: ContractSizeEnum.AVAILABLE_UPON_REQUEST,
    label: AvailableUponRequestText,
  },
];
