import { useEffect, useState } from 'react';
import { LoadingSpinner } from '@estimateone/frontend-components';
import { StageAccessControl } from './StageAccessControl';
import { RestrictedStageAccessFragment as RestrictedStageAccess } from './fragments.generated';
import { useCurrentUser } from '@builder/context/AccountProvider/hooks';
import {
  useGrantRestrictedStageAccess,
  useListRestrictedStageAccess,
  useRevokeRestrictedStageAccess,
  useSetStageAccessRestricted,
} from './hooks';
import { EntityId } from '@ascension/types';

type StageAccessControlContainerProps = {
  stageId: EntityId;
  hasStageRestrictedAccess: boolean;
};

export const StageAccessControlContainer = ({
  stageId,
  hasStageRestrictedAccess,
}: StageAccessControlContainerProps) => {
  const [accessList, setAccessList] = useState<RestrictedStageAccess[]>([]);
  const {
    restrictedStageAccess,
    loading: accessListLoading,
    refetch: refetchRestrictedStageAccess,
  } = useListRestrictedStageAccess(stageId);
  const { setStageAccessRestricted } = useSetStageAccessRestricted(stageId);
  const { grantRestrictedStageAccess, loading: grantAccessLoading } =
    useGrantRestrictedStageAccess(stageId);
  const { revokeRestrictedStageAccess, loading: revokeAccessLoading } =
    useRevokeRestrictedStageAccess(stageId);
  const currentUser = useCurrentUser();

  useEffect(() => {
    setAccessList(restrictedStageAccess);
  }, [restrictedStageAccess]);

  const handleSetStageAccessRestricted = async (access: boolean) => {
    await setStageAccessRestricted(access);
    await refetchRestrictedStageAccess();
  };

  const handleOnGrantAccess = async (selectedMemberIds: EntityId[]) => {
    const newAccessList = await grantRestrictedStageAccess(selectedMemberIds);

    if (newAccessList) {
      setAccessList(newAccessList);
    }
  };

  const handleOnRevokeAccess = async (userId: EntityId) => {
    const newAccessList = await revokeRestrictedStageAccess(userId);

    if (newAccessList) {
      setAccessList(newAccessList);
    }
  };

  if (accessListLoading || currentUser === null) {
    return <LoadingSpinner />;
  }

  return (
    <StageAccessControl
      hasStageRestrictedAccess={hasStageRestrictedAccess}
      accessList={accessList}
      handleSetStageAccessRestricted={handleSetStageAccessRestricted}
      grantAccessLoading={grantAccessLoading}
      handleOnGrantAccess={handleOnGrantAccess}
      revokeAccessLoading={revokeAccessLoading}
      handleOnRevokeAccess={handleOnRevokeAccess}
      currentUser={currentUser}
    />
  );
};
