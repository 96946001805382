import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { getMessageForWatchlistStatusChange } from '@subbie/utils/watchlist-utils';
import { useConnectionPreferenceFormContext } from '../ConnectionPreferenceFormContext';
import { transformFormApiErrors } from '@shared/FinalForm/transformers';
import { GlobalFormFieldErrors } from '@shared/FinalForm/types';
import useFlashMessage from '@shared/Util/useFlashMessage';
import { WatchlistProjectAssignmentFragment as WatchlistProjectAssignment } from '@subbie/context/WatchlistProvider/fragments.generated';
import {
  useApplyStatusUpdate,
  useApplyTeamMemberAssignment,
  useAssignmentsByProjectId,
  useWatchlistStatusByProject,
} from '@subbie/context/WatchlistProvider/hooks';
import {
  ADD_CONNECTION_PREFERENCE,
  UPDATE_CONNECTION_PREFERENCE,
  REMOVE_CONNECTION_PREFERENCE,
} from './mutations';
import {
  ConnectionPreferenceField,
  ConnectionPreferenceFormValues,
} from '../ConnectionPreferenceFormContext/types';
import {
  AddConnectionPreference,
  AddConnectionPreferenceVariables,
  AddConnectionPreference_addConnectionPreference as AddResponse,
  AddConnectionPreference_addConnectionPreference_ConnectionPreferenceUpdateSuccess as SuccessfulAddResponse,
  AddConnectionPreference_addConnectionPreference_Errors as UnsuccessfulAddResponse,
} from './types/AddConnectionPreference';
import {
  UpdateConnectionPreference,
  UpdateConnectionPreferenceVariables,
  UpdateConnectionPreference_updateConnectionPreference as UpdateResponse,
  UpdateConnectionPreference_updateConnectionPreference_ConnectionPreferenceUpdateSuccess as SuccessfulUpdateResponse,
  UpdateConnectionPreference_updateConnectionPreference_Errors as UnsuccessfulUpdateResponse,
} from './types/UpdateConnectionPreference';
import { InterestLevel } from '@ascension/_gqltypes/subbie.generated';
import {
  RemoveConnectionPreference,
  RemoveConnectionPreferenceVariables,
} from '@subbie/modal/ConnectionPreferenceSlider/ConnectionPreferenceForm/types/RemoveConnectionPreference';

const getWatchlistStatus = (watchlistStatus: InterestLevel | null) =>
  watchlistStatus &&
  [InterestLevel.INTERESTED, InterestLevel.QUOTING, InterestLevel.QUOTED].includes(watchlistStatus)
    ? watchlistStatus
    : InterestLevel.INTERESTED;

function isSuccess(
  block?: AddResponse | UpdateResponse,
): block is SuccessfulAddResponse | SuccessfulUpdateResponse {
  return block?.__typename === 'ConnectionPreferenceUpdateSuccess';
}

export const useConnectionPreferenceFormSubmission = () => {
  const { hasExistingPreference } = useConnectionPreferenceFormContext();
  const { success } = useFlashMessage();

  const showConnectionPreferenceSuccessMessage = () => {
    success({
      title: 'Success',
      message:
        'Your contact details are being shared with tendering subcontractors interested in this project.',
    });
  };

  const showWatchlistUpdateMessages = (
    projectName: string,
    contactName: string,
    watchlistStatus: InterestLevel,
  ) => {
    success({
      title: 'Watchlist Updated',
      message: `${projectName} has been assigned to ${contactName}`,
    });

    success({
      title: 'Watchlist Updated',
      message: getMessageForWatchlistStatusChange(watchlistStatus),
    });
  };

  const [addPreference] = useMutation<AddConnectionPreference, AddConnectionPreferenceVariables>(
    ADD_CONNECTION_PREFERENCE,
  );

  const applyStatusUpdate = useApplyStatusUpdate();
  const applyTeamMemberAssignment = useApplyTeamMemberAssignment();
  const assignmentsByProject = useAssignmentsByProjectId();
  const statusesByProject = useWatchlistStatusByProject();

  const hasChanged = useCallback(
    (
      projectId: number,
      currentWatchlistProjectAssignment: WatchlistProjectAssignment,
      currentWatchlistInterestLevel: InterestLevel,
    ) =>
      assignmentsByProject[projectId]?.assignee?.id !==
        currentWatchlistProjectAssignment.assignee.id ||
      statusesByProject[projectId] !== currentWatchlistInterestLevel,
    [assignmentsByProject, statusesByProject],
  );

  const handleResponse = (data: SuccessfulAddResponse | SuccessfulUpdateResponse) => {
    const {
      currentConnectionPreference: { projectId, projectName, contactName },
      currentWatchlistInterestLevel,
      currentWatchlistProjectAssignment,
    } = data;

    if (hasChanged(projectId, currentWatchlistProjectAssignment, currentWatchlistInterestLevel)) {
      showWatchlistUpdateMessages(projectName, contactName ?? '', currentWatchlistInterestLevel);
    }
    applyStatusUpdate(projectId, getWatchlistStatus(currentWatchlistInterestLevel));
    applyTeamMemberAssignment(projectId, currentWatchlistProjectAssignment);

    showConnectionPreferenceSuccessMessage();

    return null;
  };

  const handleError = (data: UnsuccessfulAddResponse | UnsuccessfulUpdateResponse | undefined) => {
    if (data === undefined) {
      return null;
    }
    return transformFormApiErrors({}, data.errors);
  };

  const submitAddPreference = async (variables: AddConnectionPreferenceVariables) => {
    const addResponse = await addPreference({ variables });
    const addConnectionPreferenceRes = addResponse.data?.addConnectionPreference;

    if (isSuccess(addConnectionPreferenceRes)) {
      return handleResponse(addConnectionPreferenceRes);
    }

    return handleError(addConnectionPreferenceRes);
  };

  const [updatePreference] = useMutation<
    UpdateConnectionPreference,
    UpdateConnectionPreferenceVariables
  >(UPDATE_CONNECTION_PREFERENCE);

  const submitUpdatePreference = async (variables: UpdateConnectionPreferenceVariables) => {
    const updateResponse = await updatePreference({
      variables,
    });

    const updateConnectionPreferenceRes = updateResponse.data?.updateConnectionPreference;

    if (isSuccess(updateConnectionPreferenceRes)) {
      return handleResponse(updateConnectionPreferenceRes);
    }
    return handleError(updateConnectionPreferenceRes);
  };

  const submitForm = async (
    values: ConnectionPreferenceFormValues,
  ): Promise<null | GlobalFormFieldErrors> => {
    /**
     * Once we implement the contact dropdown we should always have a contactId.
     * Until then, make sure it isn't undefined.
     */
    if (values.contact === undefined) {
      return {
        [ConnectionPreferenceField.Contact]: ['You must select a contact'],
      };
    }
    const variables = {
      input: {
        contactId: values.contact.value,
        note: values.note,
        projectId: values.projectId,
        type: values.type,
      },
    };

    return hasExistingPreference
      ? submitUpdatePreference(variables)
      : submitAddPreference(variables);
  };

  return submitForm;
};

export const useRemoveConnectionPreference = () => {
  const { preference } = useConnectionPreferenceFormContext();
  const { success, warning } = useFlashMessage();

  const [removePreference] = useMutation<
    RemoveConnectionPreference,
    RemoveConnectionPreferenceVariables
  >(REMOVE_CONNECTION_PREFERENCE);

  const onRemovePreference = useCallback(async () => {
    const { projectId } = preference;
    const result = await removePreference({
      variables: {
        projectId,
      },
    });
    if (result?.data?.removeConnectionPreference?.__typename === 'Errors') {
      const errors = result.data.removeConnectionPreference.errors.map((item) => item.message);

      warning({
        title: 'Error',
        message: 'Something went wrong. Please try again.',
      });

      return errors;
    }

    success({
      title: 'Success',
      message:
        'Your contact details are no longer being shared with tendering subcontractors interested in this project.',
    });

    return null;
  }, [preference, removePreference, success, warning]);

  return onRemovePreference;
};
