import Routing from 'routing';
import { CellProps, E1Link, LinkVariant } from '@estimateone/frontend-components';
import { Package } from '@builder/features/ProcurementLettingSchedule/types';
import styles from '../styles.scss';

export const PackageNameCell =
  (stageId: number) =>
  ({
    value,
    row: {
      original: { id: packageId },
    },
  }: CellProps<Package, string>) => (
    <div className={styles.packageNameCell}>
      <E1Link
        title={value}
        variant={LinkVariant.Primary}
        href={Routing.generate('app_packagedashboard_view', {
          stageId,
          packageId,
        })}
      >
        {value}
      </E1Link>
    </div>
  );
