import $ from 'jquery';
import E1Request from './E1Request';
import BasePersistentStateDataTable from './BasePersistentStateList';

export default class ServerDataList extends BasePersistentStateDataTable {
  constructor(target, fetchUrl, initFunc, transformer) {
    super(target, initFunc, fetchUrl, transformer);
    this.init(this);
  }

  init(self) {
    super.init(self);
    self.$target.on('xhr.dt', (e, settings, json) => {
      self.data = json.data;
    });

    self.$target.on('draw.dt', () => {
      self.toggleTableDisplay();
    });

    self.table.on('init.dt', () => {
      self.replaceDefaultColVisButton();
    });
  }

  getData() {
    return this.data;
  }

  async serverRequest(data, cb) {
    if (this.isFilterAware && this.filterOptions.length === 0) {
      setTimeout(() => {
        $(document).trigger('data-table-requires-filter');
      }, 100);
      return;
    }

    if (this.isFilterAware && this.filterOptions) {
      data.filter_options = this.filterOptions;
    }

    this.$target.addClass('loading-more');
    const response = await new E1Request(this.fetchUrl, 'GET', data).submit();
    this.$target.removeClass('loading-more');

    if (typeof cb === 'function') {
      cb(this.transformer(response));
    }
  }

  updateTable(fetch, resetPagingCallback, cb) {
    const resetPaging = typeof resetPagingCallback === 'function';
    const callback = resetPaging ? resetPagingCallback : cb;

    this.table.ajax.reload(null, resetPagingCallback !== false);

    if (typeof callback === 'function') {
      callback();
    }
  }

  static defaultTransformer(response) {
    // try to parse the data as json
    try {
      response.data = JSON.parse(response.data);
    } catch (SyntaxError) {
      // Leave Response as it is.
    }
    return response;
  }
}
