import { gql } from '@apollo/client';

export const EDIT_HISTORICAL_PROJECT = gql`
  mutation UpdateHistoricalProject($input: HistoricalProjectInput!, $id: ID!) {
    updateHistoricalProject(input: $input, id: $id) {
      ... on HistoricalProject {
        __typename
        id
        name
        builderName
        state {
          id
          shortName
        }
        completionDate
        contractSize
        category
        description
      }
      ... on Errors {
        __typename
        errors {
          field
          message
        }
      }
    }
  }
`;
