import $ from 'jquery';
import Routing from 'routing';
import E1Request from '../../../classes/E1Request';

export default class IndustryStatsTable {
  private $container: JQuery;
  // Initialized in this.update(regionId), called from the constructor
  private regionId!: number;

  constructor(
    private $target: JQuery,
    regionId: number,
  ) {
    this.$container = this.$target.closest('.loading-container');
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.update(regionId);
  }

  public async update(regionId: number): Promise<void> {
    if (regionId === this.regionId) {
      return;
    }

    this.regionId = regionId;
    this.$container.removeClass('has-loaded');

    const industryStatsUrl = Routing.generate('app_stat_industry', {
      id: this.regionId,
    });

    const { tablesHtml } = await new E1Request<{ success: true; tablesHtml: string }>(
      industryStatsUrl,
    ).submit();

    if (tablesHtml) {
      const $div = $('<div />').append(tablesHtml); // XSS safe
      this.$target.html($div.find('.industry-statistics').html());
      this.$container.addClass('has-loaded');
    }
  }
}
