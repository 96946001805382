import Routing from 'routing';
import Icon from '../../../../../../../shared/Icon';
import Link from '../../../../../../../shared/Link';
import { EntityId } from '@ascension/types';
import styles from '../styles.scss';

type BuilderNameProps = {
  id?: EntityId;
  name?: string;
  isAwarded?: boolean;
};

const BuilderName = ({ id, name, isAwarded }: BuilderNameProps) => (
  <div className={styles.builderNameContainer}>
    <span className={styles.builderName}>
      {id ? (
        <Link target="_blank" href={Routing.generate('app_network_accountview', { id })}>
          {name}
        </Link>
      ) : (
        <span className={styles.incognito}>{name}</span>
      )}
    </span>
    {isAwarded && <Icon extraClass="icon-xl" icon="awarded" />}
  </div>
);

export default BuilderName;
