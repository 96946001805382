export const PackageNoDocsImage = () => (
  <svg
    width="120"
    height="120"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="120" height="120" fill="white" />
    <rect
      x="4"
      y="4"
      width="112"
      height="112"
      rx="2"
      fill="white"
      stroke="#5E79FF"
      strokeWidth="4"
    />
    <line x1="28" y1="70" x2="28" y2="118" stroke="#5E79FF" strokeWidth="4" />
    <line x1="72" y1="2" x2="72" y2="118" stroke="#5E79FF" strokeWidth="4" />
    <line x1="50" y1="2" x2="50" y2="118" stroke="#5E79FF" strokeWidth="4" />
    <line x1="94" y1="2" x2="94" y2="118" stroke="#5E79FF" strokeWidth="4" />
    <line x1="118" y1="28" x2="48" y2="28" stroke="#5E79FF" strokeWidth="4" />
    <line x1="118" y1="94" x2="2" y2="94" stroke="#5E79FF" strokeWidth="4" />
    <line x1="118" y1="72" x2="2" y2="72" stroke="#5E79FF" strokeWidth="4" />
    <line x1="118" y1="50" x2="48" y2="50" stroke="#5E79FF" strokeWidth="4" />
    <path
      d="M22.9679 29.4453C22.953 29.4677 22.9389 29.4908 22.9258 29.5144L21.9213 31.3225C21.6484 31.8137 21.0245 31.9837 20.5402 31.6987L17.189 29.7274C16.7212 29.4523 16.5583 28.8543 16.8218 28.3799L17.8742 26.4856C19.695 23.2082 23.3573 21 27.4 21C33.6477 21 38.4 25.7523 38.4 32C38.4 34.7737 37.5771 36.5467 36.5296 37.8124C35.5697 38.9724 34.4025 39.7411 33.2792 40.481C33.1403 40.5725 33.0021 40.6635 32.865 40.7548C31.8772 41.3487 31.2166 41.8363 30.8363 42.3688C30.3985 42.9817 30.3994 43.5618 30.4 43.9574C30.4 43.9719 30.4 43.9861 30.4 44V46C30.4 46.5523 29.9523 47 29.4 47H25.4C24.8477 47 24.4 46.5523 24.4 46V44C24.4 39.3497 27.1907 37.2096 29.5351 35.6452C30.4955 35.0684 31.2338 34.5942 31.7026 34.0585C32.2661 33.4145 32.4 32.7452 32.4 32C32.4 30.5728 31.9199 29.3057 31.0071 28.3929C30.0943 27.4801 28.8272 27 27.4 27C25.4121 27 23.9216 28.0148 22.9679 29.4453ZM27.4 59C26.4272 59 25.6943 58.6801 25.2071 58.1929C24.7199 57.7057 24.4 56.9728 24.4 56C24.4 55.0272 24.7199 54.2943 25.2071 53.8071C25.6943 53.3199 26.4272 53 27.4 53C28.3728 53 29.1057 53.3199 29.5929 53.8071C30.0801 54.2943 30.4 55.0272 30.4 56C30.4 56.9728 30.0801 57.7057 29.5929 58.1929C29.1057 58.6801 28.3728 59 27.4 59Z"
      fill="white"
      stroke="#5E79FF"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path d="M34 78L44 88" stroke="#5E79FF" strokeWidth="2" strokeLinecap="round" />
    <path d="M44 78L34 88" stroke="#5E79FF" strokeWidth="2" strokeLinecap="round" />
    <path d="M56 34L66 44" stroke="#5E79FF" strokeWidth="2" strokeLinecap="round" />
    <path d="M66 34L56 44" stroke="#5E79FF" strokeWidth="2" strokeLinecap="round" />
    <path d="M56 100L66 110" stroke="#5E79FF" strokeWidth="2" strokeLinecap="round" />
    <path d="M66 100L56 110" stroke="#5E79FF" strokeWidth="2" strokeLinecap="round" />
    <path d="M100 56L110 66" stroke="#5E79FF" strokeWidth="2" strokeLinecap="round" />
    <path d="M110 56L100 66" stroke="#5E79FF" strokeWidth="2" strokeLinecap="round" />
    <path d="M34 100L44 110" stroke="#5E79FF" strokeWidth="2" strokeLinecap="round" />
    <path d="M44 100L34 110" stroke="#5E79FF" strokeWidth="2" strokeLinecap="round" />
    <path d="M100 100L110 110" stroke="#5E79FF" strokeWidth="2" strokeLinecap="round" />
    <path d="M110 100L100 110" stroke="#5E79FF" strokeWidth="2" strokeLinecap="round" />
    <path d="M78 78L88 88" stroke="#5E79FF" strokeWidth="2" strokeLinecap="round" />
    <path d="M88 78L78 88" stroke="#5E79FF" strokeWidth="2" strokeLinecap="round" />
  </svg>
);
