import Routing from 'routing';
import { Step } from './enum';
import {
  useAccountType,
  useFiftyPercentRedactedCheck,
  useProjectPaywallValue,
  useIsSubbieDirectoryEnabledSubbie,
} from '../../context/AccountProvider/hooks';
import { useAccountUpgradeContext } from './context';
import { Action, Event, PayloadExtras, useAnalytics } from '../../../hooks/Analytics';
import { Product, ProductWithPrice } from './types';
import { AccountType } from '@ascension/_gqltypes/subbie.generated';

export const useAccountUpgradeSelectedProduct = (): ProductWithPrice | undefined =>
  useAccountUpgradeContext().product ?? undefined;

export const useAccountUpgradeStep = (): Step => useAccountUpgradeContext().step;

export const useGetShoppingCartItems = () => useAccountUpgradeContext().getShoppingCartItems;

export const useGetSupplierProducts = (): Product[] => useAccountUpgradeContext().supplierProducts;

export const useAccountUpgradeAnalytics = () => {
  const { addEvent } = useAnalytics(Event.INTERACT);
  const product = useAccountUpgradeSelectedProduct();
  const step = useAccountUpgradeStep();
  const getShoppingCartItems = useGetShoppingCartItems();

  const handleAnalytics = (action: Action, uri: string, extraPayload?: PayloadExtras) => {
    window.history.pushState({}, 'AccountUpgrade', uri);
    // Fixes GTM dashboard not tracking checkout steps
    if ('dataLayer' in window) {
      window.dataLayer!.push({ event: 'Upgrade Page Navigate' });
    }
    addEvent({ action, ...extraPayload });
  };

  const trackAccountStepChange = async (extraQueryParams: {
    [name: string]: string | string[];
  }) => {
    switch (step) {
      case Step.UPGRADE_SELECTION:
        return handleAnalytics(
          Action.UPGRADE_START,
          Routing.generate('app_accountupgrade_index', extraQueryParams),
        );

      case Step.UPGRADE_OFFER:
        return handleAnalytics(
          Action.UPGRADE_OFFER,
          Routing.generate('app_accountupgrade_index', extraQueryParams),
        );

      case Step.UPGRADE_ADDONS:
        return handleAnalytics(
          Action.UPGRADE_ADDONS,
          Routing.generate('app_accountupgrade_addons', extraQueryParams),
        );

      case Step.UPGRADE_CHECKOUT:
        if (product && getShoppingCartItems !== undefined) {
          return handleAnalytics(
            Action.UPGRADE_CHECKOUT,
            Routing.generate('app_accountupgrade_select', {
              id: product.id,
              billing_cycle: product.billingCycle,
              ...extraQueryParams,
            }),
            {
              addons: getShoppingCartItems()
                .filter(({ isAddon }) => isAddon)
                .map(({ id }) => id),
              productId: product.id,
            },
          );
        }
        return Promise.resolve();
      case Step.UPGRADE_THANK_YOU:
        return Promise.resolve();
      default:
        throw new Error(`There is no step ${step} in the checkout`);
    }
  };

  const trackAccountThankYou = async ({ id: productId }: ProductWithPrice) => {
    if (productId && getShoppingCartItems !== undefined) {
      return handleAnalytics(
        Action.UPGRADE_COMPLETE,
        Routing.generate('app_accountupgrade_thankyou'),
        {
          addons: getShoppingCartItems()
            .filter(({ isAddon }) => isAddon)
            .map(({ id }) => id),
          productId,
        },
      );
    }
    return Promise.resolve();
  };

  return { trackAccountStepChange, trackAccountThankYou };
};

const getProjectPaywallValueOrPlaceHolder = (projectPaywallValue?: number) =>
  projectPaywallValue ? projectPaywallValue / 1e6 : '-';

export const useNewFreeLicenseReasons = (): string[] => {
  const projectPaywallValue = useProjectPaywallValue();
  const accountType = useAccountType();
  const fiftyPercentRedacted = useFiftyPercentRedactedCheck();

  if (projectPaywallValue === undefined || fiftyPercentRedacted === undefined) return [];

  return [
    fiftyPercentRedacted
      ? 'Access to a LIMITED number of projects from over 1,000 builders'
      : `Access to projects up to $${getProjectPaywallValueOrPlaceHolder(
          projectPaywallValue,
        )}m in value`,
    'Manage invites from multiple builders',
  ].concat(accountType === AccountType.SUPPLIER ? ['Three saved keywords for project alerts'] : []);
};

export const useGetNewPaidLicenseReasons = (): string[] => {
  const projectPaywallValue = useProjectPaywallValue();
  const accountType = useAccountType();
  const fiftyPercentRedacted = useFiftyPercentRedactedCheck();
  const isSubbieDirectoryEnabled = useIsSubbieDirectoryEnabledSubbie();

  if (projectPaywallValue === undefined || fiftyPercentRedacted === undefined) return [];

  return [
    fiftyPercentRedacted
      ? 'Access to all tenders'
      : `Projects above $${getProjectPaywallValueOrPlaceHolder(projectPaywallValue)}m`,
    'Awarded builder information',
    'Sync deadlines to your work calendar',
  ]
    .concat(
      accountType === AccountType.SUPPLIER
        ? ['Speci-Finder notifications for up to 10 saved products']
        : ['Stand out with advanced profile features'],
    )
    .concat(
      isSubbieDirectoryEnabled && accountType === AccountType.SUBCONTRACTOR
        ? ['Be found by builders in the subbie directory']
        : [],
    );
};

export const useGetPaidLicenseReasonsForPaywallValue = (): string[] => {
  const projectPaywallValue = useProjectPaywallValue();
  const fiftyPercentRedacted = useFiftyPercentRedactedCheck();

  if (projectPaywallValue === undefined || fiftyPercentRedacted === undefined) return [];

  return [
    fiftyPercentRedacted
      ? 'Access to UNLIMITED projects'
      : `Access all projects greater than $${getProjectPaywallValueOrPlaceHolder(
          projectPaywallValue,
        )}m`,
    'View winning builders',
    'Sync with your calendar',
  ];
};

export const useGetUpgradeValuePropsForPaywallValue = (): {
  icon: string;
  heading: string;
  text: string;
}[] => {
  const projectPaywallValue = useProjectPaywallValue();
  const fiftyPercentRedacted = useFiftyPercentRedactedCheck();

  if (projectPaywallValue === undefined || fiftyPercentRedacted === undefined) return [];

  return [
    {
      icon: 'unlocked',
      heading: 'View UNLIMITED projects',
      text: fiftyPercentRedacted
        ? 'Access to UNLIMITED projects with builder information and expand your pipeline of work.'
        : `Access projects >$${getProjectPaywallValueOrPlaceHolder(
            projectPaywallValue,
          )}m in total value and expand your pipeline of work. View all builder information no matter the size of the project.`,
    },
    {
      icon: 'awarded',
      heading: 'View winning builders',
      text: 'Be the first to know which builders are winning projects, so you can direct your efforts to the right places.',
    },
    {
      icon: 'update',
      heading: 'Sync tender deadlines to your personal calendar',
      text: "Monitor all the projects you're interested in, directly from your calendar of choice. Never miss another tender deadline.",
    },
  ];
};
