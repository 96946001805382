import { ReactNode, useCallback, useEffect } from 'react';
import Routing from 'routing';
import { E1Link } from '@estimateone/frontend-components/src/components/E1Link';
import { WarningAlert } from '@ascension/components/fec/WarningAlert/WarningAlert';
import { getAppcues, FlowEvent } from '@ascension/components/helpers/Appcues';
import { SupplierMatchesList } from './SupplierMatchesList';
import useFlashMessage from '@shared/Util/useFlashMessage';
import { useAccountStockTrades, useCurrentAccount } from '@subbie/context/AccountProvider/hooks';
import { useProjectSupplierSummary, useViewSupplierContactDetails } from './hooks';
import { Action, Event, useAnalytics } from '@ascension/components/hooks/Analytics';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

type ProjectSuppliersProps = {
  projectId: EntityId;
};

export const ProjectSuppliersSection = ({ children }: { children: ReactNode }) => (
  <div id="project-suppliers" className={styles.projectSuppliers}>
    <h3>Suppliers</h3> {children}
  </div>
);

const profilePageLink = Routing.generate('app_new_subbie_profile_view');

export const ProjectSuppliers = ({ projectId }: ProjectSuppliersProps) => {
  const { addEvent } = useAnalytics(Event.INTERACT);
  const { warning: showWarningMessage } = useFlashMessage();
  const viewSupplierContactDetails = useViewSupplierContactDetails(projectId);

  const currentAccount = useCurrentAccount();
  const accountTrades = useAccountStockTrades();
  const { loading: summaryLoading, summary: projectSupplierSummary } =
    useProjectSupplierSummary(projectId);
  const supplierCount = projectSupplierSummary?.supplierCount ?? 0;

  useEffect(() => {
    if (summaryLoading) {
      return;
    }
    const appcues = getAppcues();
    if (appcues?.track) {
      appcues.track(FlowEvent.DISCO_ONBOARDING_SSC_VIEW_SUPPLIER, {
        supplierCount,
      });
    }
    addEvent({
      action: Action.SSC_PROJECT_SUPPLIER_LIST_VIEWED,
      projectId,
      supplierCount,
    });
  }, [addEvent, projectId, supplierCount, summaryLoading]);

  const handleContactClicked = useCallback(
    async (supplierId: number) => {
      const result = await viewSupplierContactDetails(supplierId);
      if (result.__typename === 'ProjectInterestedSupplier') {
        addEvent({
          action: Action.SSC_SHARE_CONTACT_DETAILS_BUTTON_CLICKED,
          project: projectId,
          subbie: currentAccount?.id,
          supplier: supplierId,
        });
        return;
      }

      showWarningMessage({
        title: 'An Error Occurred',
        message: 'Please close the project, or refresh your browser.',
      });
    },
    [viewSupplierContactDetails, showWarningMessage, addEvent, projectId, currentAccount?.id],
  );

  if (projectSupplierSummary === undefined) {
    return null;
  }

  if (accountTrades.length === 0) {
    return (
      <ProjectSuppliersSection>
        <WarningAlert data-testid="add-trades-alert">
          <div className={styles.noTradesAlert}>
            <p>
              Add a trade to your profile to view Suppliers who are interested in this project.
              <E1Link
                className={styles.noTradesAlertLink}
                href={profilePageLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                Add a trade
              </E1Link>
            </p>
          </div>
        </WarningAlert>
      </ProjectSuppliersSection>
    );
  }

  return (
    <ProjectSuppliersSection>
      <SupplierMatchesList
        projectId={projectId}
        supplierCount={supplierCount}
        onSupplierContactClicked={handleContactClicked}
        hasOptedOutOfSSC={projectSupplierSummary.hasOptedOutOfSSC}
      />
    </ProjectSuppliersSection>
  );
};
