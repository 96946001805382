import { useState } from 'react';
import DownloadDocsButton from './Buttons/DownloadDocsButton';
import { RequestDocsStatusButton } from './Buttons/RequestDocsStatusButton';
import ViewDocsButton from './Buttons/ViewDocsButton';
import { RequestDocsSuccessModal } from './RequestDocsSuccess/RequestDocsSuccessModal';
import useFlashMessage from '@shared/Util/useFlashMessage';
import { useIsProfilesEnabledSubbie } from '@subbie/context/AccountProvider/hooks';
import { useIsProfileComplete } from './Buttons/hooks';
import { DownloadRequestResponse, usePackageRequestButtonStatus } from './hooks';
import { UserPackageDownloadStatus } from '@ascension/_gqltypes/subbie.generated';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

type CommonIdProps = {
  stageId: EntityId;
  packageId: EntityId;
};

export type StageDocsAccessProps = CommonIdProps & {
  packageStatus?: UserPackageDownloadStatus;
  packageName: string;
  builderName: string;
};

const AccessButtons = ({ stageId, packageId }: CommonIdProps) => (
  <div className={styles.btnContainer}>
    <DownloadDocsButton packageId={packageId} />
    <ViewDocsButton packageId={packageId} stageId={stageId} />
  </div>
);

export const StageDocsAccess = ({
  stageId,
  packageId,
  packageStatus: status,
  packageName,
  builderName,
}: StageDocsAccessProps) => {
  const { requestButton, setPackageRequested } = usePackageRequestButtonStatus(status);
  const canHaveProfile = useIsProfilesEnabledSubbie();
  const isProfileComplete = useIsProfileComplete();
  const { success: showSuccess } = useFlashMessage();

  const [openModal, setOpenModal] = useState(false);

  if (status?.includes(UserPackageDownloadStatus.APPROVED)) {
    return <AccessButtons stageId={stageId} packageId={packageId} />;
  }

  const downloadDocsCallback = (response: DownloadRequestResponse) => {
    const { success, approved, flash_notification: flashMessage } = response;

    const downloadStarted = success && !approved;

    if (downloadStarted && canHaveProfile && !isProfileComplete) {
      // Show modal to prompt user to complete profile
      setOpenModal(true);
    } else {
      setOpenModal(false);
      showSuccess({ title: flashMessage.toString() });
    }
  };

  return requestButton ? (
    <>
      <RequestDocsStatusButton
        {...requestButton}
        packageId={packageId}
        requestPackage={setPackageRequested}
        successCallback={downloadDocsCallback}
      />

      {openModal ? (
        <RequestDocsSuccessModal packageName={packageName} builderName={builderName} />
      ) : null}
    </>
  ) : null;
};
