import {
  Select,
  SelectSize,
  PriorityCriticalIcon,
  PriorityHighIcon,
  PriorityMediumIcon,
  PriorityLowIcon,
  Tag,
  TagVariant,
  TagProps,
} from '@estimateone/frontend-components';
import { useSetLettingSchedulePriority } from './hooks/useSetLettingSchedulePriority';
import { LettingSchedulePriority } from '@ascension/_gqltypes/builder.generated';
import { EntityId } from '@ascension/types';
import { ValueLabelOption } from '@estimateone/frontend-components/src/components/Form/Select/types';
import styles from './styles.scss';

type PrioritySelectProps = {
  lettingScheduleId: EntityId;
  priority: LettingSchedulePriority | null;
  openNotesSliderHandler: (isCritical: boolean) => void;
};

export type PriorityOption = ValueLabelOption<LettingSchedulePriority | null> & {
  icon: TagProps['icon'];
};

const optionsList: PriorityOption[] = [
  {
    label: 'Critical',
    value: LettingSchedulePriority.CRITICAL,
    icon: <PriorityCriticalIcon />,
  },
  {
    label: 'High',
    value: LettingSchedulePriority.HIGH,
    icon: <PriorityHighIcon />,
  },
  {
    label: 'Medium',
    value: LettingSchedulePriority.MEDIUM,
    icon: <PriorityMediumIcon />,
  },
  {
    label: 'Low',
    value: LettingSchedulePriority.LOW,
    icon: <PriorityLowIcon />,
  },
];

const formatOptionLabel = (option: PriorityOption) => (
  <Tag
    className={styles.prioritySelectTag}
    variant={TagVariant.Plain}
    text={option.label}
    icon={option.icon}
  />
);

export const PrioritySelect = ({
  lettingScheduleId,
  priority,
  openNotesSliderHandler,
}: PrioritySelectProps) => {
  const { setPriority } = useSetLettingSchedulePriority(lettingScheduleId);

  const selectedOption = priority
    ? optionsList.find((option) => option.value === priority)
    : undefined;

  const onChangeFunc = async (option: PriorityOption) => {
    await setPriority(option);
    if (option?.value === LettingSchedulePriority.CRITICAL) {
      openNotesSliderHandler(true);
    }
  };

  return (
    <Select<PriorityOption>
      key={`prioritySelect${lettingScheduleId}`}
      id={`prioritySelect${lettingScheduleId}`}
      label=""
      hideLabel
      size={SelectSize.Small}
      placeholder="Select..."
      blurInputOnSelect
      menuPlacement="auto"
      maxMenuHeight={250}
      onChange={onChangeFunc}
      defaultValue={selectedOption}
      styles={{
        control: (provided) => ({ ...provided, transition: 'none', border: 'none' }),
      }}
      formatOptionLabel={formatOptionLabel}
      options={optionsList}
      isClearable
      isSearchable={false}
      className={styles.prioritySelect}
      classNamePrefix="prioritySelect"
    />
  );
};
