import { ReactElement } from 'react';
import { CheckIcon } from '@estimateone/frontend-components';
import additionalFeaturesStyles from './styles.scss';

type FeatureItemProps = {
  features: (string | ReactElement)[];
};

const FeatureItems = ({ features }: FeatureItemProps) => (
  <>
    {features.map((feature) => (
      <div
        key={typeof feature === 'string' ? feature.replace(/\s/g, '') : feature.key}
        className={additionalFeaturesStyles.featureItem}
      >
        <div className={additionalFeaturesStyles.checkIconContainer}>
          <CheckIcon className={additionalFeaturesStyles.featureTick} />
        </div>
        <span>{feature}</span>
      </div>
    ))}
  </>
);

export default FeatureItems;
