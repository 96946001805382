import { gql } from '@apollo/client';

export const GET_TENDERS_READY_FOR_HANDOVER = gql`
  query TenderStagesReadyForHandover {
    tenderStagesReadyForHandover {
      ... on BuilderStageInterface {
        id
        name
        awardedAt
        private
        contactName
        budgetAmount
        account {
          id
          primaryCountry {
            id
          }
        }
        projectId
        projectAddress {
          id
          fullAddress
        }
        hasTeamAccessControl
      }
    }
  }
`;
