import { useState } from 'react';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  Col,
  InfoCard,
  ProcurementIllustration,
  Row,
} from '@estimateone/frontend-components';
import { AddMembersSlider } from '@builder/features/StageAccessControl/AddMembers';
import { StageAccessControlSelector } from './StageAccessControlSelector';
import { TeamMembersTable } from './TeamMembersTable';
import { groupUsersByRestrictedStageAccess } from './utils/stageAccessUtils';
import useFlashMessage from '../../../shared/Util/useFlashMessage';
import { BuilderAccountWithExperiments_currentUser as CurrentUser } from '@builder/context/AccountProvider/types/BuilderAccountWithExperiments';
import { RestrictedStageAccess, StageAccess } from './types';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

type StageAccessControlProps = {
  hasStageRestrictedAccess: boolean;
  handleSetStageAccessRestricted: (access: boolean) => Promise<void>;
  accessList: RestrictedStageAccess[];
  grantAccessLoading: boolean;
  handleOnGrantAccess: (userIds: EntityId[]) => Promise<void>;
  revokeAccessLoading: boolean;
  handleOnRevokeAccess: (userId: EntityId) => Promise<void>;
  currentUser: CurrentUser;
};

export const StageAccessControl = ({
  hasStageRestrictedAccess,
  accessList,
  handleSetStageAccessRestricted,
  grantAccessLoading,
  handleOnGrantAccess,
  revokeAccessLoading,
  handleOnRevokeAccess,
  currentUser,
}: StageAccessControlProps) => {
  const [actualStageAccess, setActualStageAccess] = useState(
    hasStageRestrictedAccess ? StageAccess.RESTRICTED : StageAccess.UNRESTRICTED,
  );
  const [isAddMembersOpen, setAddMembersOpen] = useState(false);
  const { success: showSuccessMessage } = useFlashMessage();

  const handleOpenAddMembers = () => {
    setAddMembersOpen(true);
  };

  const handleCloseAddMembers = () => {
    setAddMembersOpen(false);
  };

  const handleAddMembers = async (userIds: EntityId[]) => {
    if (userIds.length !== 0) {
      await handleOnGrantAccess(userIds);
    }

    showSuccessMessage({
      title: 'Success',
      message:
        userIds.length === 0
          ? 'No team members were added to this project.'
          : 'Standard User(s) have been successfully added to this project.',
    });
  };

  const handleRemoveMember = async (userId: EntityId) => {
    await handleOnRevokeAccess(userId);

    showSuccessMessage({
      title: 'Success',
      message: 'Standard User(s) have been successfully removed from this project.',
    });
  };

  const [standardUsersWithAccess, standardUsersWithoutAccess, adminUsers] =
    groupUsersByRestrictedStageAccess(accessList);

  return (
    <>
      <div>
        <Row className={styles.accessControlRow}>
          <Col span={4}>
            <InfoCard heading="Access Control" icon={<ProcurementIllustration />}>
              <p>
                With project access control you can restrict standard role users from accessing
                specific projects on your E1 account.
              </p>
              <p>
                <a
                  href="https://builder.support.estimateone.com/en/articles/1705452819-project-access-faq"
                  target="_blank"
                  rel="noreferrer"
                >
                  Find out more
                </a>
              </p>
            </InfoCard>
          </Col>
          <Col span={7}>
            <div className={styles.optionsContainer}>
              <StageAccessControlSelector
                handleSetStageAccessRestricted={handleSetStageAccessRestricted}
                actualStageAccess={actualStageAccess}
                setActualStageAccess={setActualStageAccess}
              />
            </div>
          </Col>
        </Row>
        <div className={styles.usersTableHeader}>
          <h3 className={styles.usersTableHeading}>Standard Users</h3>
          {actualStageAccess === StageAccess.RESTRICTED && (
            <Button
              className={styles.addMemberButton}
              onClick={handleOpenAddMembers}
              variant={ButtonVariant.Primary}
              size={ButtonSize.Small}
            >
              Add User
            </Button>
          )}
        </div>
        <TeamMembersTable
          hasStageRestrictedAccess={actualStageAccess === StageAccess.RESTRICTED}
          teamMembers={actualStageAccess === StageAccess.RESTRICTED ? standardUsersWithAccess : []}
          emptyStateText={
            actualStageAccess !== StageAccess.RESTRICTED
              ? "To manage standard users set this project to 'Restricted'"
              : 'No Standard users have access'
          }
          handleRemoveMember={handleRemoveMember}
          removeMemberLoading={revokeAccessLoading}
          currentUser={currentUser}
        />
        <div className={styles.adminUserTableHeader}>
          <h3 className={styles.usersTableHeading}>Administrators</h3>
          <span>
            Account administrators have access to all projects and can not be added or removed from
            projects.
          </span>
        </div>
        <TeamMembersTable
          hasStageRestrictedAccess={actualStageAccess === StageAccess.RESTRICTED}
          teamMembers={adminUsers}
          currentUser={currentUser}
        />
      </div>

      {isAddMembersOpen && (
        <AddMembersSlider
          membersList={standardUsersWithoutAccess}
          isOpen={isAddMembersOpen}
          onRequestClose={handleCloseAddMembers}
          handleAddMembers={handleAddMembers}
          grantAccessLoading={grantAccessLoading}
        />
      )}
    </>
  );
};
