import { ReactNode } from 'react';
import Tippy from '@tippyjs/react';
import { CoverageBreakdown, QuoteCoverageBreakdown } from './CoverageBreakdown';
import styles from './styles.scss';

type CoveragePopupProps = {
  children?: ReactNode;
  quoteCoverageBreakdown: QuoteCoverageBreakdown;
};

export const CoveragePopup = ({ children, quoteCoverageBreakdown }: CoveragePopupProps) => (
  <Tippy
    content={<CoverageBreakdown {...quoteCoverageBreakdown} />}
    placement="right-start"
    delay={300}
    arrow={false}
    offset={[-24, 8]}
    popperOptions={{
      modifiers: [
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['left-start'],
          },
        },
      ],
    }}
  >
    <div className={styles.coveragePopover}>{children}</div>
  </Tippy>
);
