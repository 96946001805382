import { gql } from '@apollo/client';
import { PackageLettingSchedule } from './fragments';

export const PACKAGES_FOR_LETTING_SCHEDULE = gql`
  query GetPackagesForLettingSchedule($stageId: EntityId!) {
    activePackages(stageId: $stageId) {
      ...PackageLettingSchedule
      countOfInvitesSent
      countOfQuotedRfqs
    }

    stage(id: $stageId) {
      ... on UnrestrictedStage {
        id
        name
        account {
          id
          name
          primaryCountry {
            id
          }
        }
        canImportDocuments
      }
    }

    lettingScheduleCustomColumns(stageId: $stageId) {
      identifier
      name
    }
  }
  ${PackageLettingSchedule}
`;

export const USERS_FOR_ACCOUNT = gql`
  query GetUsersForAccount {
    accountUsers {
      id
      fullName
    }
  }
`;
