import { Icon, IconName, IconVariant, joinClassNames } from '@estimateone/frontend-components';
import styles from './styles.module.scss';

export const SingleFreeCreditExhaustedGraphicSection = () => (
  <div className={styles.roadblockGraphicContainer}>
    <div className={joinClassNames(styles.graphicIconContainer, styles.noCreditsIconContainer)}>
      <div className={styles.graphicIcon}>
        <Icon name={IconName.Clear} size="1.1em" variant={IconVariant.Red} strokeWidth={4} />
      </div>
      <p>
        <strong>No free tender</strong>
      </p>
      <div className={styles.redactedBlock} />
      <div className={styles.redactedBlock} />
    </div>
  </div>
);

export const MultiFreeCreditsExhaustedGraphicSection = () => (
  <div className={styles.roadblockGraphicContainer}>
    <div className={joinClassNames(styles.graphicIconContainer, styles.noCreditsIconContainer)}>
      <div className={styles.graphicIcon}>
        <Icon name={IconName.Clear} size="1.1em" variant={IconVariant.Red} strokeWidth={4} />
      </div>
      <p>
        <strong>No free tenders</strong>
      </p>
      <div className={styles.redactedBlock} />
      <div className={styles.redactedBlock} />
    </div>
  </div>
);

export const LitePlanCreditsExhaustedGraphicSection = () => (
  <div className={styles.roadblockGraphicContainer}>
    <div className={joinClassNames(styles.graphicIconContainer, styles.noCreditsIconContainer)}>
      <div className={styles.graphicIcon}>
        <Icon name={IconName.Clear} size="1.1em" variant={IconVariant.Red} strokeWidth={4} />
      </div>
      <p>
        <strong>No tenders</strong>
      </p>
      <div className={styles.redactedBlock} />
      <div className={styles.redactedBlock} />
    </div>
  </div>
);
