import { joinClassNames, Tag } from '@estimateone/frontend-components';
import styles from './HeightCorrectedTag.module.scss';

/**
 * The same as a Tag from the frontend-components component library, but its height is correct
 * when box-sizing: border-box is used
 */
export const HeightCorrectedTag: typeof Tag = ({ variant, text, icon, className }) => (
  <Tag
    variant={variant}
    text={text}
    icon={icon}
    className={joinClassNames(className, styles.heightCorrectedTag)}
  />
);

// eslint-disable-next-line fp/no-mutation
HeightCorrectedTag.displayName = 'HeightCorrectedTag';
