import $ from 'jquery';
import Routing from 'routing';
import E1Request from '../classes/E1Request';
import { getShortAddress } from '../utils/address_form';

function CompanyList($container, target, fetchUrl) {
  this.companies = {};
  this.$target = target;
  this.$container = $container;
  this.table = null;
  this.tag = $container.attr('data-tag-id');
  this.tagCompanies = {};
  this.existingInvites = {};
  this.$tradeSelector = this.$container.find('.trade_list');
  this.selectedTradeId = parseInt(this.$tradeSelector.val(), 10);
  this.$buttonContainer = this.$container.find('.container-operations');
  this.$submit = this.$container.find('.completetag');
  this.$selectAllCheckbox = this.$container.find('.select-all');
  this.loadingClass = 'loading-indicator glyphicon glyphicon-refresh glyphicon-spin';
  this.isDirty = false;

  this.fetchUrl = fetchUrl;

  this.$modal = null;

  const self = this;

  self.$tradeSelector.on('change', async ({ currentTarget }) => {
    self.selectedTradeId = parseInt($(currentTarget).val(), 10);

    const tradeData = self.getTradeData(self.selectedTradeId);

    if (tradeData === null) {
      await self.fetchTrade(self.selectedTradeId);
      self.$tradeSelector.trigger('change');
    } else {
      self.updateButtonList();
      await self.updateTable(self.selectedTradeId);
    }
    self.$selectAllCheckbox.prop('checked', false);
  });

  self.$selectAllCheckbox.on('click', async () => {
    const checked = self.$selectAllCheckbox.is(':checked');
    self.table
      .rows({ filter: 'applied' })
      .data()
      .toArray()
      .forEach(({ id }) => {
        self.updateList(id, 1, checked);
      });
    return self.updateTable(self.selectedTradeId);
  });

  self.$container.on('click', '.completetag', async () => self.submitList());

  self.$container.on('click', '.changeTradeLink', ({ currentTarget }) => {
    const $clickedOption = $(currentTarget);
    // Get current position in trade list
    const $selectedOption = self.$tradeSelector.find('option:selected');
    const selectedIndex = $selectedOption.index('option');

    // Get the total number of options
    const optionSize = self.$tradeSelector.find('option').length;
    const maxIndex = optionSize - 1;

    let newIndex;
    if ($clickedOption.hasClass('nextTradeLink')) {
      newIndex = Math.min(selectedIndex + 1, maxIndex);
    } else if ($clickedOption.hasClass('previousTradeLink')) {
      newIndex = Math.max(selectedIndex - 1, 0);
    }

    let count = 10;
    while (
      count > 0 &&
      $(self.$tradeSelector.find('option')[newIndex]).attr('disabled') === 'disabled'
    ) {
      if ($clickedOption.hasClass('nextTradeLink')) {
        newIndex = Math.min(newIndex + 1, maxIndex);
      } else if ($clickedOption.hasClass('previousTradeLink')) {
        newIndex = Math.max(newIndex - 1, 0);
      }
      count -= 1;
    }

    // If the new index and the previous index are not the same, update the trade list
    if (newIndex !== selectedIndex) {
      self.$tradeSelector.find('option')[newIndex].selected = true;
      self.$tradeSelector.trigger('change');
    }
  });

  self.$target.on('change', '.tag-trigger', ({ currentTarget }) => {
    const $checkbox = $(currentTarget);
    const id = $checkbox.data('company-id');

    self.updateList(id, 1, $checkbox.is(':checked'));
    self.updateListCount();
  });

  self.fetchExistingContacts().then(() => self.updateButtonList());
}

CompanyList.prototype.fetchExistingContacts = async function () {
  const self = this;

  const { data } = await new E1Request(
    Routing.generate('app_addressbooktag_fetchtagcompanies', { id: self.tag }),
    'GET',
  ).submit();

  self.existingInvites = data;
  self.existingInvites.forEach((i) => {
    self.tagCompanies[i] = 1;
  });
  await self.fetchTrade(self.selectedTradeId);
  self.init(self);
};

CompanyList.prototype.submitList = async function () {
  const self = this;
  self.$submit.append($('<i>').addClass(self.loadingClass)).addClass('disabled');

  await new E1Request(Routing.generate('app_addressbooktag_save', { id: self.tag }), 'POST', {
    companies: self.tagCompanies,
  }).submit();

  const redirectUrl = self.$container.data('redirect');

  if (redirectUrl) {
    window.location = redirectUrl;
    return;
  }
  self.isDirty = false;
  await self.updateTable(self.selectedTradeId, true);

  self.$submit.find('.loading-indicator').remove();
};

CompanyList.prototype.updateButtonList = function () {
  const self = this;
  // Get current position in trade list
  const $selectedOption = self.$tradeSelector.find('option:selected');
  const selectedIndex = $selectedOption.index('option');

  // Get the total number of options
  const optionSize = self.$tradeSelector.find('option').length;
  const maxIndex = optionSize - 1;

  self.$buttonContainer.find('li.disabled').removeClass('disabled');
  if (selectedIndex === 0) {
    self.$buttonContainer.find('.previousTradeLink').closest('li').addClass('disabled');
  }
  if (selectedIndex === maxIndex) {
    self.$buttonContainer.find('.nextTradeLink').closest('li').addClass('disabled');
  }

  // Swap the primary button to the 'complete' option if all trades have been cycled
  const $dropdown = $('.container-operations');
  let $optionToCopy = null;

  if (selectedIndex === maxIndex) {
    $optionToCopy = $dropdown.find('li.alternate-main').find('a');
  } else if (selectedIndex < maxIndex) {
    $optionToCopy = $dropdown.find('li.default-main').find('a');
  }

  if ($optionToCopy) {
    $dropdown
      .find('.primary')
      .attr('class', `primary btn btn-default btn-large ${$optionToCopy.attr('class')}`)
      .html($optionToCopy.html());
  }
};

CompanyList.prototype.updateListCount = function () {};

CompanyList.prototype.init = function (self) {
  window.onbeforeunload = function () {
    if (self.isDirty) {
      return "If you leave the page, any unsaved changes will be lost - be sure to click the 'Save Company List' button.";
    }
    return undefined;
  };
  Object.assign(self, {
    table: self.$target.DataTable({
      paging: false,
      data: self.getTradeData(self.selectedTradeId),
      info: false,

      order: [[1, 'asc']],
      columns: [
        {
          data: null,
          class: 'checkbox-cell',
          orderable: false,
          render(data, type, { id }) {
            const $checkCtn = $('<div>');
            const $checkbox = $('<input>')
              .attr('type', 'checkbox')
              .attr('data-company-id', id)
              .addClass('tag-trigger');

            if (self.tagCompanies[id] === 1) {
              $checkbox.attr('checked', 'checked');
            }

            $checkCtn.append($checkbox);
            return $checkCtn.html();
          },
        },
        {
          data: null,
          render: (data, type, { id, name }) =>
            $('<a>')
              .addClass('vcard link')
              .text(name)
              .attr('href', '#')
              .attr('data-company-id', id)
              .prop('outerHTML'),
        },
        {
          data({ phone }) {
            return phone || '-';
          },
          render: $.fn.dataTable.render.text(),
        },
        {
          data: 'address.short_address',
          render: $.fn.dataTable.render.text(),
        },
      ],
      dom: 'Rrtp',
    }),
  });
  self.$target.closest('.loading-container').addClass('has-loaded');
  self.$tradeSelector.val($('select.trade_list').val()).trigger('change');
};

CompanyList.prototype.convertData = function (data) {
  Object.assign(data, {
    DT_RowId: `dt_data_${data.id}`,
    address: { short_address: data.address ? getShortAddress(data.address) : 'No Address' },
    trade_ids: data.trades.map((t) => t.id),
  });
  return data;
};

/**
 * @param {number} tradeId
 * @return {Promise<void>}
 */
CompanyList.prototype.fetchTrade = async function (tradeId) {
  const self = this;

  self.$target.closest('.loading-container').removeClass('has-loaded');
  const { data } = await new E1Request(
    Routing.generate('app_addressbooktrade_fetchcompanies', {
      trade_id: tradeId,
    }),
    'GET',
  ).submit();

  self.companies[tradeId] = data.map(self.convertData);
  self.$target.closest('.loading-container').addClass('has-loaded');
};

/**
 * @param {number} tradeId
 */
CompanyList.prototype.getTradeData = function (tradeId) {
  const self = this;

  return self.companies[tradeId] || null;
};

/**
 * @param {number} tradeId
 */
CompanyList.prototype.drawTable = function (tradeId) {
  const self = this;
  if (self.table) {
    const tableData = self.getTradeData(tradeId);
    self.table.rows().remove();
    self.table.rows.add(tableData).draw();
  }
  self.$target.trigger('data-updated');
};

/**
 * @param {number} tradeId
 * @param {boolean} fetch
 */
CompanyList.prototype.updateTable = async function (tradeId, fetch = false) {
  const self = this;

  if (fetch) {
    await self.fetchTrade(tradeId);
  }

  self.drawTable(tradeId);
};

/**
 * @param {number} companyId
 * @param {number} tagId
 * @param {boolean} checked
 */
CompanyList.prototype.updateList = function (companyId, tagId, checked) {
  const self = this;

  if (!self.isDirty) {
    self.$submit.removeClass('disabled');
  }
  if (!(companyId in self.tagCompanies)) {
    self.tagCompanies[companyId] = [];
  }
  self.tagCompanies[companyId] = checked ? 1 : 0;
  self.isDirty = true;
};

$(() => {
  $('.company-tag-module')
    .toArray()
    .map((container) => {
      const $container = $(container);
      return new CompanyList(
        $container,
        $container.find('.company-tag-table'),
        Routing.generate('app_addressbooktag_fetchcompanies', { id: $container.data('tag-id') }),
      );
    });
});
