import { useFormState } from 'react-final-form';
import { RadioButton } from '@estimateone/frontend-components';
import { TextAreaField } from '@shared/FinalForm/Field';
import { RadioButtonGroupField } from '@shared/FinalForm/Field/RadioButtonGroupField';
import { ConnectionPreferenceType } from '@ascension/_gqltypes/subbie.generated';
import {
  ConnectionPreferenceFormValues,
  ConnectionPreferenceField,
  ConnectionPreferenceFormLabels,
} from '@subbie/modal/ConnectionPreferenceSlider/ConnectionPreferenceFormContext/types';
import styles from './styles.scss';

type ProjectInvolvementProps = {
  isEnabled: boolean;
};

export const ProjectInvolvement = ({ isEnabled }: ProjectInvolvementProps) => {
  const formState = useFormState<ConnectionPreferenceFormValues>();
  const { type } = formState.values;

  return (
    <section className={styles.projectInvolvementSection}>
      <h3>{ConnectionPreferenceFormLabels.ProjectInvolvementTitle}</h3>
      <RadioButtonGroupField
        fieldName={ConnectionPreferenceField.Type}
        name={ConnectionPreferenceField.Type}
        legend={ConnectionPreferenceFormLabels.ProjectInvolvementLegend}
        isRequired
        value={type}
        className={styles.projectInvolvementType}
        disabled={!isEnabled}
      >
        <RadioButton
          id={ConnectionPreferenceType.SPECIFIED}
          value={ConnectionPreferenceType.SPECIFIED}
          className={styles.projectInvolvementTypeOption}
        >
          {ConnectionPreferenceFormLabels.SpecifiedOption}
        </RadioButton>
        <RadioButton
          id={ConnectionPreferenceType.EQUIVALENT}
          value={ConnectionPreferenceType.EQUIVALENT}
          className={styles.projectInvolvementTypeOption}
        >
          {ConnectionPreferenceFormLabels.EquivalentOption}
        </RadioButton>
        <RadioButton
          id={ConnectionPreferenceType.SPECIFIED_AND_EQUIVALENT}
          value={ConnectionPreferenceType.SPECIFIED_AND_EQUIVALENT}
          className={styles.projectInvolvementTypeOption}
        >
          {ConnectionPreferenceFormLabels.SpecifiedAndEquivalentOption}
        </RadioButton>
      </RadioButtonGroupField>
      <TextAreaField
        fieldName={ConnectionPreferenceField.Note}
        id={ConnectionPreferenceField.Note}
        label={ConnectionPreferenceFormLabels.NoteLabel}
        maxLength={600}
        disabled={!isEnabled}
        placeholder="Optional: Add any additional information to share with all interested subcontractors"
      />
    </section>
  );
};
