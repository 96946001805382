import { BaseMutationOptions, useMutation } from '@apollo/client';
import { useCountryTermTranslator } from '@ascension/components/localisation';
import useFlashMessage from '@shared/Util/useFlashMessage';
import {
  EDIT_SUBBIE_NETWORK_OPT_IN_SETTING,
  MARK_NETWORK_RE_ENROLMENT_MODAL_AS_VIEWED,
} from './mutations';
import {
  EditSubbieDirectoryOptInSettingMutation,
  EditSubbieDirectoryOptInSettingMutationVariables,
  MarkNetworkReEnrolModalAsViewedMutation,
  MarkNetworkReEnrolModalAsViewedMutationVariables,
} from './mutations.generated';

type Options = Parameters<
  typeof useMutation<
    EditSubbieDirectoryOptInSettingMutation,
    EditSubbieDirectoryOptInSettingMutationVariables
  >
>[1];

export const useEditSubbieNetworkOptInSetting = (opts?: Options) => {
  const translateForOperatingCountry = useCountryTermTranslator();
  const { success } = useFlashMessage();

  const [editDirectoryOptIn, { error }] = useMutation<
    EditSubbieDirectoryOptInSettingMutation,
    EditSubbieDirectoryOptInSettingMutationVariables
  >(EDIT_SUBBIE_NETWORK_OPT_IN_SETTING, {
    ...opts,
    onCompleted: (
      data,
      clientOptions: BaseMutationOptions<
        EditSubbieDirectoryOptInSettingMutation,
        EditSubbieDirectoryOptInSettingMutationVariables
      >,
    ) => {
      success({
        title: 'Success',
        message: clientOptions.variables?.isOptedIn
          ? `${translateForOperatingCountry('builders')} can now discover your company in the E1 Network.`
          : `${translateForOperatingCountry('builders')} can no longer discover your company in the E1 Network.`,
      });

      if (opts?.onCompleted) {
        opts.onCompleted(data, clientOptions);
      }
    },
  });

  const submit = (isOptingIn: boolean) =>
    editDirectoryOptIn({
      variables: {
        isOptedIn: isOptingIn,
      },
    });

  return { submit, error: error ?? undefined };
};

export const useMarkNetworkReEnrolmentModalAsViewed = () => {
  const [markAsViewed] = useMutation<
    MarkNetworkReEnrolModalAsViewedMutation,
    MarkNetworkReEnrolModalAsViewedMutationVariables
  >(MARK_NETWORK_RE_ENROLMENT_MODAL_AS_VIEWED);

  return { markAsViewed };
};
