import { Tag, TagVariant } from '@estimateone/frontend-components';
import { format } from 'date-fns';
import { ScopeRfqStatusObject } from '../../hooks/useGetScopeOfWorks';
import { Scope_scopeByPackage as Scope } from '../../../../../../scope-of-works/types/Scope';
import { ScopeActivityStatus } from '@ascension/_gqltypes/scope-of-works.generated';
import styles from './styles.scss';

export type ScopeStatusTagProps = {
  rfqId: number;
  scope: Scope | null;
  scopeRfqStatus: ScopeRfqStatusObject | null;
};

const tags = {
  [ScopeActivityStatus.SENT]: {
    label: 'Sent',
    variant: TagVariant.RfqStatusNoResponse,
  },
  [ScopeActivityStatus.DOWNLOADED]: {
    label: 'Downloaded',
    variant: TagVariant.RfqDocsDownloaded,
  },
};

export const ScopeStatusTag = ({ rfqId, scope, scopeRfqStatus }: ScopeStatusTagProps) => {
  const scopeStatus = scopeRfqStatus?.[rfqId];
  if (scope && !scopeStatus) {
    return <Tag variant={TagVariant.RfqStatusNoResponse} text="Not Sent" />;
  }
  if (!scopeStatus || !Object.keys(tags).includes(scopeStatus.status)) {
    return null;
  }
  const updatedAt = format(new Date(scopeStatus.updatedAt), 'd MMM, h:mma');
  return (
    <>
      <Tag variant={tags[scopeStatus.status].variant} text={tags[scopeStatus.status].label} />
      <p className={styles.scopeStatusTime}>{updatedAt}</p>
    </>
  );
};
