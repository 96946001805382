import { ComponentProps, ReactNode } from 'react';
import { Col, joinClassNames, Row, Tag, TagVariant } from '@estimateone/frontend-components';
import { SubbieNetworkConnectionIndicator } from '@builder/common/SubbieNetwork/SubbieNetworkConnectionIndicator';
import {
  CompanyLogo,
  CompanyLogoSize,
} from '@builder/pages/Profiles/UniversalProfileDrawer/components/CompanyLogo';
import { getLabelFromProfilesContractSizes } from '@builder/pages/Profiles/UniversalProfileDrawer/mappers/contractSizeMapper';
import { isConnectedCompany } from '../../../utils';
import { TradeTags } from '../../TradeTagList';
import { CompanyQualifications } from '@shared/Profiles/CompanyQualifications';
import { useIsAustralianAccount } from '@builder/context/SubbieNetworkFilterDataProvider/hooks';
import { Country } from '@ascension/enums';
import { ContractSizeEnum } from '@ascension/_gqltypes/profiles.generated';
import { NetworkCompany } from '@builder/common/SubbieNetwork/types';
import styles from './styles.module.scss';

type CompanyContractSizeProps = {
  contractSizeMin: ContractSizeEnum | null;
  contractSizeMax: ContractSizeEnum | null;
};

const CompanyContractSize = ({ contractSizeMin, contractSizeMax }: CompanyContractSizeProps) => {
  const tagText = `Contract Size: ${getLabelFromProfilesContractSizes(contractSizeMin, contractSizeMax)}`;
  return <Tag variant={TagVariant.Practical025} text={tagText} />;
};

const CompanyHeader = ({
  company,
  ...props
}: { company: NetworkCompany } & ComponentProps<'div'>) => {
  switch (company.__typename) {
    case 'BuilderNetworkCompany':
    case 'ConnectedE1NetworkCompany':
    case 'ConnectedBuilderNetworkCompany':
      return <div data-company-id={company.id} {...props} />;
    case 'E1NetworkCompany':
    case 'ShadowNetworkCompany':
    default:
      return <div data-profile-id={company.id} {...props} />;
  }
};

type Address = NetworkCompany['address'];

const LocationDisplay = ({ address }: { address: Address }) => {
  const isAustralianBuilder = useIsAustralianAccount();

  if (address === null) {
    return '';
  }

  if (isAustralianBuilder && address.country?.id === Country.COUNTRY_AUSTRALIA) {
    return `${address.suburb ?? ''} ${address.state?.shortName ?? ''}`;
  }

  if (address.city && address.province) {
    return `${address.city}, ${address.province}`;
  }

  return `${address.city ?? ''} ${address.province ?? ''}`;
};

export type ProfileSnapshotProps = {
  company: NetworkCompany;
  children: ReactNode;
};

export const ProfileSnapshot = ({ company, children }: ProfileSnapshotProps) => {
  const hasCompanyLogo = company.__typename === 'E1NetworkCompany' || isConnectedCompany(company);

  return (
    <li className={styles.resultListItem}>
      <CompanyHeader
        className={joinClassNames(styles.companyHeader, 'vcard')}
        company={company}
        tabIndex={0}
      >
        <Row>
          {hasCompanyLogo ? (
            <Col alignContentY="top" alignContentX="left" excludeBottomGutter>
              <CompanyLogo
                companyName={company.name}
                url={company.logoUrl}
                size={CompanyLogoSize.SMALL}
              />
            </Col>
          ) : (
            /* the Row component is unnecessarily fussy and forces us to use a fragment here */
            /* eslint-disable-next-line react/jsx-no-useless-fragment */
            <></>
          )}
          <Col span="auto" className={styles.companyInfoColumn} excludeBottomGutter>
            <Row>
              <Col alignContentX="left" alignContentY="center" excludeBottomGutter>
                <h3 className={styles.companyName}>{company.name}</h3>
              </Col>
              <Col
                alignContentX="left"
                alignContentY="center"
                className={styles.statusIconColumn}
                excludeBottomGutter
              >
                {company.__typename !== 'BuilderNetworkCompany' && (
                  <SubbieNetworkConnectionIndicator connected={isConnectedCompany(company)} />
                )}
              </Col>
              <Col alignContentX="right" alignContentY="center" excludeBottomGutter>
                <CompanyQualifications qualifications={company.qualifications} />
              </Col>
            </Row>
            <Row className={styles.locationAndTags}>
              <Col alignContentY="center" excludeBottomGutter>
                <span className={styles.location}>
                  <LocationDisplay address={company.address} />
                </span>
              </Col>

              <Col alignContentX="right" excludeBottomGutter>
                <Row>
                  <Col alignContentY="center" excludeBottomGutter>
                    <TradeTags
                      stockTrades={company.stockTrades}
                      coreTrades={company.coreStockTrades}
                    />
                  </Col>
                  <Col alignContentY="center" excludeBottomGutter>
                    {company.contractSizeMin === null && company.contractSizeMax === null ? null : (
                      <CompanyContractSize
                        contractSizeMin={company.contractSizeMin}
                        contractSizeMax={company.contractSizeMax}
                      />
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </CompanyHeader>
      <Row className={styles.contactsRow}>
        <Col
          span="auto"
          alignContentX="left"
          alignContentY="center"
          className={styles.contacts}
          excludeBottomGutter
        >
          {children}
        </Col>
      </Row>
    </li>
  );
};
