import { gql } from '@apollo/client';

export const ADD_PROJECT_TO_HISTORY = gql`
  mutation AddProjectToHistory($input: HistoricalProjectInput!) {
    addProjectToHistory(input: $input) {
      ... on HistoricalProject {
        id
        name
        builderName
        region
        state {
          id
          shortName
        }
        completionDate
        contractSize
        category
        deletedAt
        linkedProjectId
        description
      }
      ... on Errors {
        errors {
          field
          message
        }
      }
    }
  }
`;
