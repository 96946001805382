const getRenderedKeywordList = (keywords: string[], maxCount?: number) => {
  if (maxCount && keywords.length > maxCount) {
    const keywordList = keywords.slice(0, maxCount);
    keywordList.push(`+ ${keywords.length - maxCount} more`);
    return keywordList;
  }

  return keywords;
};

const ProjectKeywordList = ({ keywords, maxCount }: { keywords: string[]; maxCount?: number }) => {
  const title = `Keyword match${keywords?.length > 1 ? 'es' : ''}:`;
  return (
    <div>
      <span>{title}</span>
      <ul>
        {getRenderedKeywordList(keywords, maxCount).map((keyword) => (
          <li key={keyword.replace(' ', '').toLowerCase()}>
            <span>{keyword}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ProjectKeywordList;
