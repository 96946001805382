import { useContext } from 'react';
import ConsultantDetailsAccessContext from '@subbie/context/ConsultantDetailsAccessContext/index';

export const useConsultantDetailsAccessIsLoading = () =>
  useContext(ConsultantDetailsAccessContext)?.loading ?? true;

export const useCanViewConsultants = () =>
  useContext(ConsultantDetailsAccessContext)?.canViewConsultants ?? false;

export const useCanUpgradeToConsultants = () =>
  useContext(ConsultantDetailsAccessContext)?.canUpgradeToConsultants ?? false;

export const useConsultantDetailsProvisionalLicenseId = () =>
  useContext(ConsultantDetailsAccessContext)?.provisionedLicenseId || undefined;
