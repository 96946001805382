import { useDocSyncQuery } from './useDocSyncQuery';
import { GET_PROCORE_COMPANIES } from '../queries';
import { companies as Companies } from '../types/companies';

export const useGetProcoreCompanies = () => {
  const { data, loading } = useDocSyncQuery<Companies>(GET_PROCORE_COMPANIES);

  return {
    companies: data?.companies,
    loading,
  };
};
