import { useEffect, useState } from 'react';
import {
  ActionMenu,
  Alert,
  AlertVariant,
  Button,
  ButtonVariant,
  Icon,
  IconName,
  IntegrateIcon,
} from '@estimateone/frontend-components';
import { useAvailablePlugins } from '../DocumentIntegrationProvider';
import { useFlashMessage } from '@shared/Util';
import { useTriggerDocsyncReconcile } from '../hooks/useTriggerDocsyncReconcile';
import { StageDocumentIntegrationType } from '@ascension/_gqltypes/builder.generated';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

type DocumentIntegrationSyncStatusProps = {
  fileCount: number;
  lastSynced: number | null;
  integrationType: string;
  stageId: EntityId;
  startCheckLastSynced: () => void;
  stopCheckLastSynced: () => void;
  infectedFiles: {
    id: string;
    name: string;
    revisionNumber: string;
    folder: string;
    infectionData: string;
  }[];
};

export const DocumentIntegrationSyncStatus = ({
  fileCount,
  lastSynced,
  integrationType,
  stageId,
  startCheckLastSynced,
  stopCheckLastSynced,
  infectedFiles,
}: DocumentIntegrationSyncStatusProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isCheckingLastSynced, setIsCheckingLastSynced] = useState(false);
  const [initialLastSynced, setInitialLastSynced] = useState(lastSynced);
  const [shouldRenderSlider, setShouldRenderSlider] = useState(false);
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const { triggerReconcile } = useTriggerDocsyncReconcile();
  const { success: showSuccessMessage } = useFlashMessage();
  const availablePlugins = useAvailablePlugins();

  const meatballMenuButton = (
    <Button
      className={styles.menuButton}
      variant={ButtonVariant.Transparent}
      aria-expanded={isMenuOpen}
      aria-label="Toggle Options Menu"
      onClick={() => setIsMenuOpen(!isMenuOpen)}
    >
      <Icon name={IconName.More} size={{ height: '0.25em', width: '1em' }} />
    </Button>
  );

  const getLastSyncedTimeString = () => {
    if (lastSynced) {
      const date = new Date(lastSynced * 1000);
      return `Updated ${date
        .toLocaleString('en-AU', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        })
        .replace(/ /g, '')}, ${date.toLocaleDateString('en-AU', { dateStyle: 'medium' })}`;
    }
    return 'Updated: N/A';
  };

  const handleCheckForUpdates = async () => {
    const { success } = await triggerReconcile(stageId);
    if (success) {
      startCheckLastSynced();
      setIsCheckingLastSynced(true);
    }
    setIsMenuOpen(false);
  };

  useEffect(() => {
    if (lastSynced && initialLastSynced && lastSynced > initialLastSynced && isCheckingLastSynced) {
      setIsCheckingLastSynced(false);
      stopCheckLastSynced();
      setInitialLastSynced(lastSynced);
      showSuccessMessage({ title: 'Success!', message: 'Check for updates complete' });
    }
  }, [
    stopCheckLastSynced,
    lastSynced,
    isCheckingLastSynced,
    initialLastSynced,
    showSuccessMessage,
  ]);

  const providerName =
    integrationType === StageDocumentIntegrationType.PROCORE ? 'Procore' : 'SharePoint';

  const selectedDocsyncPlugin = availablePlugins.find(
    ({ id }) => id === integrationType.toLowerCase(),
  );

  if (!selectedDocsyncPlugin) {
    return false;
  }

  const handleOpenConnectionSettings = () => {
    setShouldRenderSlider(true);
    setIsSliderOpen(true);
    setIsMenuOpen(false);
  };

  const closeSlider = () => setIsSliderOpen(false);

  return (
    <div>
      <div className={styles.syncStatusContainer}>
        {isCheckingLastSynced ? (
          <>
            <Icon name={IconName.Update} className={styles.updateIcon} />
            <span>Detecting updates...</span>
          </>
        ) : (
          <>
            <IntegrateIcon className={styles.integrateIcon} />
            <span>
              Connected to {providerName} ({fileCount} files)
            </span>
          </>
        )}
        <ActionMenu
          triggerElement={meatballMenuButton}
          visible={isMenuOpen}
          onClickOutside={() => setIsMenuOpen(false)}
          placement="bottom-start"
        >
          <ActionMenu.Item disabled className={styles.lastSynced}>
            {getLastSyncedTimeString()}
          </ActionMenu.Item>
          <ActionMenu.Item onClick={handleCheckForUpdates} className={styles.syncActionItem}>
            Check for New Updates
          </ActionMenu.Item>
          <ActionMenu.Item onClick={handleOpenConnectionSettings}>
            Change File Selection
          </ActionMenu.Item>
        </ActionMenu>
        {shouldRenderSlider && (
          <selectedDocsyncPlugin.ModifySlider
            isOpen={isSliderOpen}
            onRequestClose={closeSlider}
            stageId={stageId}
          />
        )}
      </div>
      {infectedFiles.length > 0 ? (
        <div className={styles.alertContainer}>
          <Alert variant={AlertVariant.Red} className={styles.alert}>
            <div>
              <b>Warning:</b> The following files have not been downloaded as they have been flagged
              as <b>infected</b>. To proceed, remove them from {providerName} or replace them with
              clean files.
            </div>
            <table className={styles.alertTable}>
              <thead>
                <tr>
                  <th>Filename</th>
                  <th>Revision</th>
                  <th>Folder</th>
                  <th>Scan Result</th>
                </tr>
              </thead>
              <tbody>
                {infectedFiles.map((file) => (
                  <tr key={file.id}>
                    <td>{file.name}</td>
                    <td>{file.revisionNumber}</td>
                    <td>{file.folder}</td>
                    <td>{file.infectionData}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Alert>
          <div className={styles.bigSpacer}>&nbsp;</div>
          {infectedFiles.map((file) => (
            <div key={file.id} className={styles.smallSpacer}>
              &nbsp;
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};
