import {
  SubbieNetworkFilterFormValues,
  SubbieNetworkFilterFormFields,
  SubbieNetworkFilterFormErrors,
} from './types';

type Validator = (values: SubbieNetworkFilterFormValues) => SubbieNetworkFilterFormErrors;

const noTradeSelectedError: Validator = (values: SubbieNetworkFilterFormValues) =>
  values[SubbieNetworkFilterFormFields.TradeFilterId]
    ? {}
    : { [SubbieNetworkFilterFormFields.TradeFilterId]: ['Select a Trade to see results'] };

const noLocationForDistanceError: Validator = (values: SubbieNetworkFilterFormValues) =>
  values[SubbieNetworkFilterFormFields.Distance] && !values[SubbieNetworkFilterFormFields.Location]
    ? {
        [SubbieNetworkFilterFormFields.Location]: [
          'Enter a suburb or postcode to create a search area',
        ],
      }
    : {};

const minGreaterThanMaxError: Validator = (values: SubbieNetworkFilterFormValues) => {
  const minValue = values[SubbieNetworkFilterFormFields.ContractSizeMin]?.monetaryValue ?? 0;
  const maxValue = values[SubbieNetworkFilterFormFields.ContractSizeMax]?.monetaryValue ?? 99999999;
  return minValue >= maxValue
    ? {
        [SubbieNetworkFilterFormFields.ContractSizeMin]: ['Minimum must be less than maximum'],
      }
    : {};
};

export const validateForm = (
  values: SubbieNetworkFilterFormValues,
): SubbieNetworkFilterFormErrors =>
  [
    noTradeSelectedError(values),
    noLocationForDistanceError(values),
    minGreaterThanMaxError(values),
  ].reduce(
    (errors: SubbieNetworkFilterFormErrors, validate) => ({
      ...errors,
      ...validate,
    }),
    {},
  );
