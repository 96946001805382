import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_CUSTOM_COLUMN_NAME } from '../mutations';
import {
  UpdateCustomColumnName,
  UpdateCustomColumnNameVariables,
} from '../types/UpdateCustomColumnName';
import { UpdateLettingScheduleCustomColumnNameInput } from '@ascension/_gqltypes/builder.generated';

export const useUpdateCustomColumnName = () => {
  const [updateCustomColumnName] = useMutation<
    UpdateCustomColumnName,
    UpdateCustomColumnNameVariables
  >(UPDATE_CUSTOM_COLUMN_NAME);

  const updateName = useCallback(
    async ({ stageId, identifier, name }: UpdateLettingScheduleCustomColumnNameInput) => {
      await updateCustomColumnName({
        variables: {
          input: {
            stageId,
            identifier,
            name,
          },
        },
        ignoreResults: true,
      });
    },
    [updateCustomColumnName],
  );

  return { updateName };
};
