import { OperatingCountry } from '@ascension/components/localisation/operatingCountry';
import { AddressSearchField } from './AddressSearchField';
import styles from './AddressSearchFields.module.scss';
import { SingleSelectField } from '@shared/FinalForm/Field/SingleSelectField';
import { TextInputField } from '@shared/FinalForm/Field/TextInputField';
import { ShortStateNameMap } from '@ascension/enums';
import { StateType } from '@profiles/features/CompanyProfile/types';

export type AddressSearchFieldTypes = {
  streetAddress: string | null;
  suburb: string | null;
  state: StateType | null;
  city: string | null;
  province: string | null;
  postcode: string | null;
};

export const AddressSearchFields = ({
  countryId,
  formId,
}: {
  countryId: number;
  formId: string;
}) => {
  const isAu = countryId === OperatingCountry.AU;

  const stateOptions = Object.entries(ShortStateNameMap).map(([id, label]) => ({
    value: parseInt(id),
    label,
  }));

  return (
    <div className={styles.addressFieldWrapper}>
      <div>
        <AddressSearchField countryId={countryId} formId={formId} />
      </div>
      <div className={styles.adjacentFields}>
        {isAu ? (
          <>
            <div>
              <TextInputField id="suburb" fieldName="suburb" label="Suburb" isRequired />
            </div>
            <div>
              <SingleSelectField
                id="state"
                inputId="state"
                label="State"
                fieldName="state"
                options={stateOptions}
                isRequired
              />
            </div>
          </>
        ) : (
          <>
            <div>
              <TextInputField id="city" fieldName="city" label="City" isRequired />
            </div>
            <div>
              <TextInputField id="province" fieldName="province" label="County" isRequired />
            </div>
          </>
        )}
      </div>
      <div>
        <TextInputField fieldName="postcode" id="postcode" label="Postcode" isRequired />
      </div>
    </div>
  );
};
