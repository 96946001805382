import $ from 'jquery';
import Routing from 'routing';
import _ from 'underscore';
import E1Request from '../classes/E1Request';
import DocumentUploadStatus from '../classes/DocumentUploadStatus';
import ServerlessUploader, { UploadStatus } from '../classes/file_manager/ServerlessUploader';
import initStageDocTable from './stage_file_list';
import { UploadParentType } from '../../enums';

class StageFileUploadModule {
  constructor($ctn, $target, list, stageId, apiKey, attachment, unzip) {
    this.stageId = parseInt(stageId, 10) || null;
    this.$target = $target;
    this.authKey = apiKey;
    this.list = list;
    this.files = [];
    this.endpoint = this.$target.data('file-endpoint');
    this.attachment = attachment;

    this.isUploading = false;
    this.fetchLock = null;

    this.$uploaderCtn = $ctn.find('.uploader');
    this.$uploadStatusCtn = $ctn.find('.upload-status');

    this.uploader = new ServerlessUploader(
      this.$uploaderCtn,
      'drag_target',
      'document_upload_button',
      UploadParentType.STAGE_FILE,
      this.stageId,
      'path-file-uploader',
    );

    this.uploader.setAutoUnzip(unzip);
    this.uploadManager = this.uploader.getUploadManager();
    this.uploadStatusContainer = new DocumentUploadStatus(
      this.$uploadStatusCtn,
      this.uploadManager,
    );
    this.initEvents();
  }

  updateTable() {
    if (this.fetchLock === null) {
      this.fetchLock = setTimeout(() => {
        if (this.list !== null) {
          this.list.updateTable(true, () => {
            clearTimeout(this.fetchLock);
            this.fetchLock = null;
          });
        }
      }, 1000);
    }
  }

  setAutoUnzip(autoUnzip) {
    this.uploader.setAutoUnzip(autoUnzip);
  }

  initEvents() {
    this.$uploaderCtn.on(UploadStatus.COMMIT, () => {
      this.updateTable();
      this.updateUploadStatusDiv();
    });

    this.$uploaderCtn.on(UploadStatus.ERROR, () => this.updateUploadStatusDiv());

    this.$uploaderCtn.on(UploadStatus.ADDED, () => {
      if (!this.isUploading) {
        this.startUpload();
      }
      this.updateUploadStatusDiv();
    });

    this.$uploaderCtn.on(UploadStatus.PROGRESS, () => this.updateUploadStatusDiv());

    this.$uploaderCtn.on(UploadStatus.COMPLETE, () => {
      this.uploadStatusContainer.clearUiUpdateLock();
      this.completeUpload();
    });
  }

  startUpload() {
    // this.documentQueue.startUpload();
    this.uploadStatusContainer.reset();
    this.isUploading = true;
  }

  updateUploadStatusDiv() {
    this.uploadStatusContainer.update();
  }

  completeUpload() {
    this.isUploading = false;
    this.uploadStatusContainer.complete();
  }
}

$(() => {
  if (!$('.stage-file-container').length) return;

  const $docTables = $('table.docStageDataTable');
  const $ctn = $('.stage-file-uploader');
  const $target = $ctn.find('.uploader');
  let docTable = null;

  $docTables.toArray().forEach((t) => {
    const $table = $(t);
    const dataTable = initStageDocTable($table);
    if ($table.hasClass('qq-upload-list')) {
      docTable = dataTable;
    }
  });

  if ($target.length && $docTables.length) {
    const unzip = !($.cookie('auto_unzip') === 'off');

    const uploader = new StageFileUploadModule(
      $ctn,
      $target,
      docTable,
      $target.data('stage'),
      $target.data('key'),
      null,
      unzip,
    );

    $ctn.find('.auto_unzip').prop('checked', unzip);

    $('body').on('change', '.auto_unzip', ({ currentTarget }) => {
      const isChecked = !!$(currentTarget).is(':checked');
      $.cookie('auto_unzip', isChecked ? 'on' : 'off', {
        expires: 1,
        path: '/',
        domain: 'estimateone.com',
      });
      uploader.setAutoUnzip(isChecked);
    });
  }

  let loadedDocTables = [docTable];
  loadedDocTables = _.filter(loadedDocTables, (table) => table !== null);

  $.each(loadedDocTables, (i, loadedDocTable) => {
    loadedDocTable.$target.on('datatable-row-init', (event, row) => {
      const $link = $(row).find('.upload-folder-trigger');

      $link.on('click', (onFolderClick) => {
        onFolderClick.stopPropagation();
        const $button = $('a#path-file-uploader');
        $button.attr('data-path', $link.attr('data-path'));
        $button.find('input').trigger('click');
      });

      const $renameLink = $(row).find('.rename-folder-trigger');

      $renameLink.on('click', async (onRename) => {
        onRename.stopPropagation();
        const path = $renameLink.data('path').trim();

        const folderDocs = _.filter(
          loadedDocTable.data,
          (doc) => doc.currentDirectoryName === path,
        );
        const docIds = _.pluck(folderDocs, 'id');

        return new E1Request(
          Routing.generate('app_stagefiles_movemodal', {
            id: loadedDocTable.stageId,
            folder: path,
            fileId: docIds,
          }),
          'POST',
        )
          .setShowLoadingModal()
          .submit();
      });
    });
  });
});
