import { FiltersIllustration } from '@estimateone/frontend-components';
import styles from './styles.module.scss';

type EmptyResultsViewProps = {
  isTradeSelected: boolean;
};

export const EmptyResultsView = ({ isTradeSelected }: EmptyResultsViewProps) => (
  <div className={styles.noResultsView}>
    <FiltersIllustration className={styles.illustration} />
    <p className={styles.headingText}>
      {isTradeSelected ? 'No results found' : 'Select a Trade to start seeing results'}
    </p>
    {isTradeSelected && (
      <p className={styles.text}>Try removing or changing filters to broaden your search</p>
    )}
  </div>
);
