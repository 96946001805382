import $ from 'jquery';
import Routing from 'routing';
import moment from 'moment-timezone';
import E1Request from '../classes/E1Request';
import Currency from '../classes/Currency';
import FullscreenLauncher from '../classes/FullscreenLauncher';
import GenericList from '../classes/GenericList';
import { ListenerEvent } from '../../enums';

/**
 * @typedef {Object} Invoice
 * @property {Number} id
 * @property {String} invoice_number
 * @property {String} pdf_name
 * @property {Boolean} is_cancelled
 * @property {Boolean} is_paid
 * @property {Boolean} is_overdue
 */

/**
 * @param {Date|null} date
 * @param {String} display
 * @return {String|null}
 */
const renderDate = (date = null, display = 'display') => {
  if (!date) {
    return display === 'display' ? '-' : null;
  }
  return moment(date).format(display === 'display' ? 'Do MMM YY' : 'X');
};

/**
 * @param {Invoice} invoice
 * @return {String}
 */
const getStatusClassSuffixForInvoice = (invoice) => {
  if (invoice.is_cancelled) return 'cancelled';
  if (invoice.is_paid) return 'paid';
  if (invoice.is_overdue) return 'outstanding';
  return 'pending';
};

/**
 * @param {Invoice }invoice
 * @return {string}
 */
const getViewUrl = (invoice) =>
  Routing.generate('app_invoice_view', { id: invoice.id, name: invoice.pdf_name });
/**
 * @param {Invoice }invoice
 * @return {string}
 */
const getPayUrl = (invoice) =>
  Routing.generate('app_pay_invoice', { invoice_number: invoice.invoice_number });

const initAccountInvoicesTable = ($target, fetchUrl) =>
  new GenericList(
    $target,
    (list) => {
      const isPaidInvoices =
        $target.closest('.invoice-table-container').data('invoices-status') === 'paid';
      Object.assign(list, {
        table: list.$target.DataTable({
          paging: false,
          data: list.data,
          info: false,
          order: [[isPaidInvoices ? 4 : 3, 'desc']],
          columns: [
            {
              data: 'invoice_number',
              render: (invoiceNumber, type, invoice) =>
                type === 'display'
                  ? $('<a>')
                      .addClass('link')
                      .attr({
                        target: '_blank',
                        href: getViewUrl(invoice),
                      })
                      .text(invoiceNumber)
                      .prop('outerHTML')
                  : invoiceNumber,
            },
            {
              orderable: false,
              data: 'invoice_items',
              render: (items) =>
                $('<ul>')
                  .addClass('invoice-items-list')
                  .append(
                    ...items.map((ii) =>
                      $('<li>').text(ii.name.replace('EstimateOne', '').replace('E1', '').trim()),
                    ),
                  )
                  .prop('outerHTML'),
            },
            {
              data: 'amount',
              render: (amount, type, row) =>
                type === 'display' ? new Currency(amount, row.currency).getPriceInFormat() : amount,
            },
            {
              data: 'due_at',
              render: renderDate,
            },
            {
              data: 'paid_at',
              render: renderDate,
            },
            {
              data: null,
              render(__, type, invoice, details) {
                const status = getStatusClassSuffixForInvoice(invoice);
                return type === 'display'
                  ? $(list.$placeholderRow.clone().find('td').get(details.col))
                      .find(`.sub-status-${status}`)
                      .first()
                      .prop('outerHTML')
                  : status;
              },
            },
            {
              data: null,
              class: 'options',
              orderable: false,
              render(__, type, invoice, details) {
                const template = list.$placeholderRow.clone().find('td').get(details.col);

                const $content = $(template).find('.btn-group').first();

                const $options = $content.find('ul');
                const $availableOptions = $('<ul>');
                const $button = $content.find('a.btn:first');

                if (!invoice.is_paid && invoice.currency === 'AUD') {
                  const $payInvoiceLink = $options.find('.pay-invoice').clone();
                  $availableOptions.append(
                    $('<li>').append($payInvoiceLink.attr('href', getPayUrl(invoice))),
                  );
                }

                // Always have view invoice
                const $viewLink = $options.find('.view-invoice').clone();
                const viewUrl = getViewUrl(invoice);

                $availableOptions.append($('<li>').append($viewLink.attr('href', viewUrl)));

                $button
                  .addClass('view-invoice')
                  .attr('href', viewUrl)
                  .text($options.find('.view-invoice').text());

                $options.html($availableOptions.html());
                $content
                  .find('.invoice-modifier')
                  .attr('data-invoice-id', invoice.id)
                  .attr('data-account-id', invoice.account.id);

                return $content.prop('outerHTML');
              },
            },
          ],
          dom: 'Rrtp',
        }),
      });
      list.toggleTableDisplay(true);
      list.$target.closest('.loading-container').addClass('has-loaded');
    },
    fetchUrl,
  );

$(() => {
  const $body = $('body');

  $('table.accountInvoicesTable').each(function () {
    const $table = $(this);
    initAccountInvoicesTable(
      $table,
      Routing.generate('app_account_fetchinvoices', {
        outstanding: $table.data('outstanding') || 0,
      }),
    );
  });

  $body.on('click', '.edit-credit-card-trigger', function () {
    const $link = $(this);
    const creditCardId = $link.attr('data-credit-card');
    if (creditCardId != null) {
      const route = Routing.generate('app_creditcard_update', { id: creditCardId });
      const request = new E1Request(route);
      request.show_loading_modal = true;
      request.submit();
    }
    return false;
  });

  // refresh the license table after updating credit card details
  $body.on('form-submitted-success', 'form.credit-card-update-form', () => {
    document.dispatchEvent(new CustomEvent(ListenerEvent.AccountLicenseCreditCardUpdated));
    $('#credit-card-problem-banner').hide();
  });

  if ($body.attr('data-update-credit-card-id')) {
    const ccId = $('body').data('update-credit-card-id');
    if (ccId != null) {
      const route = Routing.generate('app_creditcard_update', { id: ccId });
      const request = new E1Request(route);
      request.show_loading_modal = true;
      request.submit();
    }
  }

  $body.on('click', '.longsell_product_trigger', function () {
    const productId = $(this).attr('data-product-id');
    const route = Routing.generate('app_accountupgrade_longsell', {
      id: productId,
    });
    const launcher = new FullscreenLauncher(route, null);
    launcher.trigger();
  });
});
