import { useMemo } from 'react';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  FullScreenModal,
  Icon,
  IconName,
} from '@estimateone/frontend-components';
import { PageWrapper } from '../../PageWrapper';
import { useRevisionFlow } from '../../RevisionFlow';
import { RevisionFlowStep } from '../../RevisionFlowStep';
import { ADDENDUM_DETAILS_FORM_ID, AddendumDetailsForm } from './AddendumDetailsForm';
import { AddendumTable, TableRowItem, isFolder } from './AddendumTable';
import styles from './styles.scss';

const randomNumericId = () => performance.now();

export const ReviewStep = () => {
  const { stageId, stageType, documentIntegrationType, close, setCurrentStep, addendum } =
    useRevisionFlow();

  const data: TableRowItem[] = useMemo(
    () =>
      addendum.documents.reduce<TableRowItem[]>((rows, document) => {
        const folderRow = rows.find(
          (row) => isFolder(row) && row.name === (document.directoryName ?? '-'),
        );

        const documentRow = {
          id: document.id,
          name: document.fileName,
          supersededDocumentTitle: document.documentToSupersede?.title,
          drawingNumber: document.drawingId,
          title: document.title,
          revision: document.revision,
        };
        if (isFolder(folderRow)) {
          folderRow.subRows.push(documentRow);
        } else {
          rows.push({
            id: randomNumericId(),
            name: document.directoryName ?? '-',
            subRows: [documentRow],
          });
        }

        return rows;
      }, []),
    [addendum.documents],
  );

  return (
    <>
      <FullScreenModal.Content>
        <PageWrapper>
          <AddendumDetailsForm
            stageId={stageId}
            stageType={stageType}
            pendingAddendum={addendum}
            onSuccess={() => setCurrentStep(RevisionFlowStep.ALLOCATE)}
          />
          <AddendumTable data={data} documentIntegrationType={documentIntegrationType} />
        </PageWrapper>
      </FullScreenModal.Content>
      <FullScreenModal.Footer>
        <PageWrapper>
          <div className={styles.actionContainer}>
            <Button
              variant={ButtonVariant.Grey}
              size={ButtonSize.Medium}
              fullWidth
              onClick={close}
              style={{ width: '192px' }}
            >
              Cancel
            </Button>
            <div className={styles.submitButtonContainer}>
              <Button size={ButtonSize.Medium} type="submit" form={ADDENDUM_DETAILS_FORM_ID}>
                Next: Allocate to Package <Icon name={IconName.ArrowRight} marginLeft="small" />
              </Button>
            </div>
          </div>
        </PageWrapper>
      </FullScreenModal.Footer>
    </>
  );
};
