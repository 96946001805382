import DocumentUploadStatus from '../classes/DocumentUploadStatus';
import ServerlessUploader, { UploadStatus } from '../classes/file_manager/ServerlessUploader';
import { UploadParentType } from '../../enums';

export default class DocumentUploadModule {
  constructor($ctn, target, list, stageId, addendumId, apiKey, unzip) {
    this.stageId = parseInt(stageId, 10) || null;
    this.addendumId = parseInt(addendumId, 10) || null;
    this.$target = target;
    this.authKey = apiKey;
    this.list = list;
    this.files = list.data;
    this.endpoint = this.$target.attr('data-file-endpoint');
    this.template = $ctn.find('.qq-template');
    this.newFiles = [];
    this.isUploading = false;

    this.fetchLock = null;

    this.$uploaderCtn = $ctn.find('.uploader');
    this.$uploadStatusCtn = $ctn.find('.upload-status');

    this.uploader = new ServerlessUploader(
      this.$uploaderCtn,
      'drag_target',
      'document_upload_button',
      this.addendumId === null ? UploadParentType.STAGE : UploadParentType.ADDENDUM,
      this.addendumId || this.stageId,
      'path-file-uploader',
    );

    this.uploader.setAutoUnzip(unzip);

    this.uploadManager = this.uploader.getUploadManager();
    this.uploadStatusContainer = new DocumentUploadStatus(
      this.$uploadStatusCtn,
      this.uploadManager,
    );
    this.initEvents();
  }

  setAutoUnzip(autoUnzip) {
    this.uploader.setAutoUnzip(autoUnzip);
  }

  initEvents() {
    this.$uploaderCtn.on(UploadStatus.COMMIT, (event, { data: files }) => {
      this.updateTable(files);
      this.updateUploadStatusDiv();
    });

    this.$uploaderCtn.on(UploadStatus.ERROR, () => {
      this.updateUploadStatusDiv();
      this.completeUpload();
    });

    this.$uploaderCtn.on(UploadStatus.ADDED, () => {
      if (!this.isUploading) {
        this.startUpload();
      }
      this.updateUploadStatusDiv();
    });

    this.$uploaderCtn.on(UploadStatus.PROGRESS, () => this.updateUploadStatusDiv());

    this.$uploaderCtn.on(UploadStatus.COMPLETE, () => {
      this.uploadStatusContainer.clearUiUpdateLock();
      this.completeUpload();
    });
  }

  updateTable(files) {
    files.forEach((f) => this.list.newFiles.push(f.id));

    if (this.fetchLock === null) {
      this.fetchLock = setTimeout(() => {
        this.list.sortByNew();
        this.list.updateTable(true, () => {
          clearTimeout(this.fetchLock);
          this.fetchLock = null;
        });
      }, 1000);
    }
  }

  startUpload() {
    this.uploadStatusContainer.reset();
    this.isUploading = true;
  }

  updateUploadStatusDiv() {
    this.uploadStatusContainer.update();
  }

  completeUpload() {
    this.isUploading = false;
    this.uploadStatusContainer.complete();
  }
}
