import { useMutation, useApolloClient, gql } from '@apollo/client';
import { useCompanyProfileCacheItemId } from '../../../../../../../providers/ProfileProvider';
import { CHANGE_COMPANY_ABN } from './mutations';
import {
  ChangeBusinessIdentifierMutation,
  ChangeBusinessIdentifierMutationVariables,
} from './mutations.generated';
import { EditCompanyFieldValues } from '../../../../EditModal/types';
import { CompanyProfile } from '@profiles/providers/ProfileProvider/types';

export const useSubmitForm = () => {
  const { cache } = useApolloClient();
  const id = useCompanyProfileCacheItemId();

  const [handleChangeCompanyABN, { error }] = useMutation<
    ChangeBusinessIdentifierMutation,
    ChangeBusinessIdentifierMutationVariables
  >(CHANGE_COMPANY_ABN, {
    onCompleted: (data) => {
      if (data.changeBusinessIdentifier.__typename === 'Account') {
        cache.writeFragment<Partial<CompanyProfile>>({
          id,
          fragment: gql`
            fragment UpdatedCompanyNumber on Profile {
              companyNumber
            }
          `,
          data: {
            companyNumber: data.changeBusinessIdentifier.businessIdentifier,
          },
        });
      }
    },
  });

  const submit = async ({ businessIdentifier }: EditCompanyFieldValues) => {
    const { data, errors } = await handleChangeCompanyABN({
      variables: {
        businessIdentifier: businessIdentifier ? businessIdentifier.replace(/\s+/g, '') : null,
      },
    });

    return {
      data,
      errors,
    };
  };

  return { submit, error: error ?? undefined };
};
