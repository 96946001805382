import { useCallback, useEffect, useMemo } from 'react';
import { AsyncSelect } from '@estimateone/frontend-components';
import { E1Address } from '@ascension/js/classes/Address';
import { getGoogleSuggestions, getLocationForPlace } from './utils';
import { useDebouncedCallback } from '@ascension/components/hooks/useDebouncedCallback';
import { ValueLabelOption } from '@estimateone/frontend-components/src/components/Form/Select/types';

type GoogleResultOption = {
  placeId: string;
};

export type SelectedPlace = {
  address: E1Address | null;
};

type E1ResultOption = {
  streetAddress: string;
};

const selectedPlaceToOption = (option: SelectedPlace): ValueLabelOption<Option> => ({
  label: option.address ? option.address.toString() : '',
  value: option.address ? { streetAddress: option.address.toString() } : { placeId: '' },
});

type Option = GoogleResultOption | E1ResultOption;

const isGoogleResultOption = (option: Option): option is GoogleResultOption =>
  (option as GoogleResultOption).placeId !== undefined;

type AddressSuggestingSelectProps = {
  countryId: number;
  hideLabel?: boolean;
  formId?: string;
  value: SelectedPlace | string | null;
  onLocationSelected: (streetAddress: E1Address | string | null) => void;
  placeholder?: string;
};

export const AddressSuggestingSelect = ({
  countryId,
  hideLabel = false,
  formId,
  value,
  onLocationSelected,
  placeholder,
}: AddressSuggestingSelectProps) => {
  const selectedLocation = useMemo(() => {
    if (!value) return null;

    if (typeof value === 'string') {
      return { value: { streetAddress: value }, label: value };
    }

    return selectedPlaceToOption(value);
  }, [value]);

  useEffect(() => {
    if (formId) {
      const onFormReset = (event: Event) => {
        if (event.target instanceof HTMLFormElement && event.target?.id === formId) {
          onLocationSelected(null);
        }
      };

      document.addEventListener('reset', onFormReset);

      return () => {
        document.removeEventListener('reset', onFormReset);
      };
    }
    return () => {
      /* noop */
    };
  }, [formId, onLocationSelected]);

  const loadOptions = useDebouncedCallback(
    (inputValue: string, callback: (opts: ValueLabelOption<GoogleResultOption>[]) => void) => {
      if (inputValue.length < 3) {
        return callback([]);
      }

      return getGoogleSuggestions(inputValue, countryId)
        .then((predictions) =>
          predictions.map(({ description, placeId }) => ({
            label: description,
            value: { placeId },
          })),
        )
        .then(callback);
    },
  );

  const onChange = useCallback(
    (option: ValueLabelOption<Option> | null) => {
      if (!option) {
        return onLocationSelected(null);
      }

      if (isGoogleResultOption(option.value)) {
        return getLocationForPlace(option.value.placeId).then(
          ({ result }: { result: google.maps.places.PlaceResult }) => {
            const newAddress =
              result && result.address_components
                ? E1Address.fromGoogleResult(result)
                : new E1Address();
            onLocationSelected(newAddress);
          },
        );
      }

      return onLocationSelected(new E1Address(option.value.streetAddress));
    },
    [onLocationSelected],
  );

  // Handles user being able to type in a street address without selecting a suggestion
  // as the field is doing double duty as a street address input and a google place suggestion input
  const onInputChange = (
    inputValue: string,
    { action, prevInputValue }: { action: string; prevInputValue: string },
  ) => {
    if (action === 'input-change') return inputValue;
    if (action === 'menu-close' && prevInputValue) return onLocationSelected(prevInputValue);

    return prevInputValue;
  };

  return (
    <AsyncSelect<ValueLabelOption<Option>, false>
      id="streetAddress"
      inputId="streetAddress"
      name="streetAddress"
      hideLabel={hideLabel}
      label="Street Address"
      placeholder={placeholder}
      onChange={onChange}
      value={selectedLocation}
      loadOptions={loadOptions}
      isRequired
      isClearable
      onInputChange={onInputChange}
    />
  );
};
