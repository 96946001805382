import { gql } from '@apollo/client';

export const UNHIDE_PROFILE_CONTACT = gql`
  mutation UnhideProfileContact($userId: EntityId!) {
    unhideProfileContact(userId: $userId) {
      id
      ... on Profile {
        id
        people {
          id
          ascensionId
          fullName
          firstName
          lastName
          isDefaultContact
          isVisible
          contactable {
            id
            email
            phone
          }
        }
      }
    }
  }
`;
