import { ValueLabelOption } from '@estimateone/frontend-components/src/components/Form/Select/types';

export const idAndNameToOption = <T extends { id: number; name: string }>({
  id,
  name,
}: T): ValueLabelOption<number> => ({
  value: id,
  label: name,
});

export const isConnectedCompany = (company: { __typename: string }) => {
  switch (company.__typename) {
    case 'ConnectedE1NetworkCompany':
    case 'ConnectedBuilderNetworkCompany':
      return true;
    default:
      return false;
  }
};
