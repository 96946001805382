import React from 'react';
import Routing from 'routing';
import {
  ArrowLeftIcon,
  Button,
  ButtonVariant,
  Col,
  joinClassNames,
  Row,
} from '@estimateone/frontend-components';
import { getStageTypeAsString, StageType } from '@ascension/enums';
import styles from './styles.scss';

export type TenderWizardSubNavProps = {
  stageId: number;
  children: React.ReactNode;
};

export const TenderWizardSubNav = ({ stageId, children }: TenderWizardSubNavProps) => {
  const projectLink = Routing.generate('app_stage_monitor', {
    id: stageId,
    stageType: getStageTypeAsString(StageType.TYPE_TENDER)?.toLowerCase(),
  });

  const backToPackagesLink = Routing.generate('app_tenderstagewizard_matrix', {
    id: stageId,
  });

  return (
    <div className={joinClassNames('wrapper', styles.tenderWizardSubNavContainer)}>
      <Row>
        <Col span={3} excludeBottomGutter>
          <Button
            variant={ButtonVariant.Grey}
            className={styles.tenderWizardSubNavBtn}
            href={backToPackagesLink}
          >
            <ArrowLeftIcon desc="Back to Packages" title="Back to Packages icon" />
            Back to Packages
          </Button>
        </Col>
        <Col span={3} excludeBottomGutter>
          <Button
            className={styles.tenderWizardSubNavBtn}
            variant={ButtonVariant.Grey}
            href={projectLink}
          >
            Finish and go to Project
          </Button>
        </Col>
        <Col span={6} excludeBottomGutter alignContentX="right">
          {children}
        </Col>
      </Row>
    </div>
  );
};
