import PaywallAmount from '../../../../../common/PaywallAmount';
import styles from '../styles.scss';

const UpgradeNationalCopy = () => (
  <>Upgrade to the Essential National License to see this project.</>
);

const UpgradeForStateCopy = ({ projectState }: { projectState: string }) => (
  <>To access this project, you will need to add a license that includes {projectState}.</>
);

const UpgradeForStateOrNationalCopy = ({
  requireNationalLicense,
  projectState,
}: {
  requireNationalLicense: boolean;
  projectState: string;
}) =>
  requireNationalLicense ? (
    <UpgradeNationalCopy />
  ) : (
    <UpgradeForStateCopy projectState={projectState} />
  );

export const RoadblockCopyBudget = ({
  projectState,
  projectPaywallValue,
}: {
  projectState: string;
  projectPaywallValue: number;
}) => (
  <div className={styles.roadblockCopyContainer}>
    <p>
      Your current plan doesn&apos;t cover projects over{' '}
      <PaywallAmount amount={projectPaywallValue} /> in value.{' '}
      <UpgradeForStateCopy projectState={projectState} />
    </p>
  </div>
);

export const RoadblockCopyBudgetAdditionalLicense = ({
  requireNationalLicense,
  projectState,
  projectPaywallValue,
}: {
  requireNationalLicense: boolean;
  projectState: string;
  projectPaywallValue: number;
}) => (
  <div className={styles.roadblockCopyContainer}>
    <p>
      Your current plan doesn&apos;t cover projects over{' '}
      <PaywallAmount amount={projectPaywallValue} /> in value in {projectState}.{' '}
      <UpgradeForStateOrNationalCopy
        requireNationalLicense={requireNationalLicense}
        projectState={projectState}
      />
    </p>
  </div>
);

export const RoadblockCopyAwarded = ({ projectState }: { projectState: string }) => (
  <div className={styles.roadblockCopyContainer}>
    <p>
      Your current plan doesn&apos;t cover awarded project information.{' '}
      <UpgradeForStateCopy projectState={projectState} />
    </p>
  </div>
);

export const RoadblockCopyAwardedAdditionalLicense = ({
  projectState,
  requireNationalLicense,
}: {
  projectState: string;
  requireNationalLicense: boolean;
}) => (
  <div className={styles.roadblockCopyContainer}>
    <p>
      Your current plan doesn&apos;t include access to awarded projects in {projectState}.{' '}
      <UpgradeForStateOrNationalCopy
        requireNationalLicense={requireNationalLicense}
        projectState={projectState}
      />
    </p>
  </div>
);

export const RoadblockCopyFiftyPercentAdditionalLicense = ({
  projectState,
  requireNationalLicense,
}: {
  projectState: string;
  requireNationalLicense: boolean;
}) => (
  <div className={styles.roadblockCopyContainer}>
    <p>
      Your current plan is limited to just 50% of projects for {projectState}.{' '}
      <UpgradeForStateOrNationalCopy
        requireNationalLicense={requireNationalLicense}
        projectState={projectState}
      />
    </p>
  </div>
);
