import { useEffect, useState } from 'react';

export type SortConfig = {
  sortId: string;
  isSortedDesc: boolean;
} | null;

type UseSortConfig = [SortConfig, (currentSortId: string, isSortedDesc: boolean) => void];

export const useSortConfig = (stageId: number): UseSortConfig => {
  const sortConfigKey = `${stageId}_sortConfig`;

  const [sortConfig, setSortConfig] = useState<SortConfig>(null);

  useEffect(() => {
    const savedSortConfig = sessionStorage.getItem(sortConfigKey);
    if (savedSortConfig) {
      const parsedConfig = JSON.parse(savedSortConfig);
      setSortConfig(parsedConfig);
    }
  }, []);

  const handleSortChange = (currentSortId: string, isSortedDesc: boolean) => {
    const conf: SortConfig = {
      sortId: currentSortId,
      isSortedDesc,
    };
    if (currentSortId) {
      sessionStorage.setItem(sortConfigKey, JSON.stringify(conf));
    }
  };

  return [sortConfig, handleSortChange];
};
