export enum UpdateStageStatusFieldName {
  NotifyInvited = 'notifyInvited',
  AwardedAt = 'awardedAt',
  ProjectStart = 'constructionStartDate',
  ProjectCompletion = 'constructionEndDate',
  NotifyOption = 'notifyOption',
}

export const UpdateAwardedDateOptions = {
  [UpdateStageStatusFieldName.AwardedAt]: 'Awarded',
  [UpdateStageStatusFieldName.ProjectStart]: 'Anticipated start',
  [UpdateStageStatusFieldName.ProjectCompletion]: 'Anticipated end',
};
