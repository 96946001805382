import $ from 'jquery';
import DocumentUploadStatus from '../classes/DocumentUploadStatus';
import PendingUploadManager, { UploadList } from '../classes/file_manager/PendingUploadManager';
import ServerlessUploader, { UploadStatus } from '../classes/file_manager/ServerlessUploader';
import { UploadParentType } from '@ascension/enums';
import { EntityId } from '@ascension/types';

export default class NewCorrespondenceAttachmentUploadModule {
  $form: JQuery<HTMLElement>;
  $ctn: JQuery<HTMLElement>;
  $target: JQuery<HTMLElement>;
  authKey: string;
  stageId: EntityId;
  isUploading: boolean;
  endpoint: string;
  attachmentSelector: string;
  $attachmentDropDown: JQuery<HTMLElement>;
  $uploadCtn: JQuery<HTMLElement>;
  $uploadStatusCtn: JQuery<HTMLElement>;
  uploader: ServerlessUploader;
  uploadManager: PendingUploadManager;
  uploadStatusContainer: DocumentUploadStatus;

  constructor(
    $form: JQuery<HTMLElement>,
    $target: JQuery<HTMLElement>,
    authKey: string,
    stageId: EntityId,
    type: UploadParentType,
  ) {
    this.$form = $form;
    this.$ctn = $form.find('.file-uploader');
    this.$target = $target;
    this.authKey = authKey;
    this.stageId = stageId;
    this.isUploading = false;

    this.endpoint = $target.data('file-endpoint');

    this.attachmentSelector = '.attachment-search';
    this.$attachmentDropDown = $(this.attachmentSelector);

    this.$uploadCtn = this.$ctn.find('.uploader');
    this.$uploadStatusCtn = this.$ctn.find('.upload-status');

    this.uploader = new ServerlessUploader(
      this.$uploadCtn,
      'drag_target',
      'document_upload_button',
      type,
      this.stageId,
    );
    this.uploadManager = this.uploader.getUploadManager();
    this.uploadStatusContainer = new DocumentUploadStatus(
      this.$uploadStatusCtn,
      this.uploadManager,
    );
    this.initEvents();
  }

  setAutoUnzip(autoUnzip: boolean) {
    this.uploader.setAutoUnzip(autoUnzip);
  }

  initEvents() {
    this.$ctn.on(UploadStatus.COMPLETE, () => {
      this.updateUploadStatusDiv();
    });

    this.$ctn.on(UploadStatus.DRAFT, (draftSavedEvt, { drafts }: { drafts: UploadList }) => {
      this.updateUploadStatusDiv();
      this.updateSelect(drafts, true);

      // Update the uploads in draft cache
      ['user_correspondence', 'user_correspondence_mail'].forEach((nameAttr) => {
        this.$form.find(`[name="${nameAttr}[uploads][]"]`).trigger('upload_complete', [drafts]);
      });
    });

    this.$ctn.on(UploadStatus.ERROR, () => this.updateUploadStatusDiv());

    this.$ctn.on(UploadStatus.ADDED, () => {
      if (!this.isUploading) {
        this.startUpload();
      }
      this.updateUploadStatusDiv();
    });

    this.$ctn.on(UploadStatus.PROGRESS, () => this.updateUploadStatusDiv());

    this.$ctn.on(UploadStatus.COMPLETE, () => {
      this.uploadStatusContainer.clearUiUpdateLock();
      this.completeUpload();
    });
  }

  startUpload() {
    // this.documentQueue.startUpload();
    this.uploadStatusContainer.reset();
    this.isUploading = true;
  }

  updateUploadStatusDiv() {
    this.uploadStatusContainer.update();
  }

  completeUpload() {
    this.isUploading = false;
    this.uploadStatusContainer.complete();
  }

  updateSelect(files: UploadList, replace = false) {
    const selectedValues = replace ? [] : this.$attachmentDropDown.val() || [];
    const selectedArray = Array.isArray(selectedValues)
      ? selectedValues
      : [selectedValues.toString()];

    if (replace) {
      this.$attachmentDropDown.empty();
    }

    files.forEach(({ id, hash, size, name }) => {
      this.$attachmentDropDown.append(
        $('<option>')
          .attr('data-id', id || null)
          .attr('data-hash', hash)
          .attr('data-size', size)
          .attr('data-name', name)
          .val(name)
          .text(name),
      );
      selectedArray.push(name);
    });

    // have select2 do its thing
    this.$attachmentDropDown.val(selectedArray).trigger('change');
  }
}
