import { DisplayNotification } from './types';

export const getUnreadNotificationsSinceLastOpened = (
  notifications: DisplayNotification[],
  lastOpened?: Date,
) => {
  const unreadNotifications = notifications.filter(({ isUnread }) => isUnread);
  return lastOpened
    ? unreadNotifications.filter(({ createdAt }) => new Date(createdAt) > lastOpened)
    : unreadNotifications;
};
