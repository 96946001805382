import { ReactNode } from 'react';
import { Col, Row, StatusNotConnectedIcon } from '@estimateone/frontend-components';
import styles from './styles.module.scss';

type CompanyCardProps = {
  companyName: string;
  office: readonly string[] | null;
  contact: { fullName: string; email: string } | null;
  showNotConnectedIcon?: boolean | false;
};

export const CompanyHeading = ({ headingName }: { headingName: string }) => (
  <div className={styles.headingText}>{headingName}</div>
);

export const CompanyCardClickableWrapper = ({
  children,
  onClick,
}: {
  children: ReactNode;
  onClick: () => void;
}) => (
  <button type="button" className={styles.cardClickableWrapper} onClick={onClick}>
    {children}
  </button>
);

export const CompanyCard = ({
  companyName,
  office,
  contact,
  showNotConnectedIcon,
}: CompanyCardProps) => (
  <div className={styles.card}>
    <div className={styles.cardTitleContainer}>
      {companyName}
      {showNotConnectedIcon && <StatusNotConnectedIcon />}
    </div>
    <Row>
      <Col span={6}>
        {office?.map((line: string) => (
          <div key={line} className={styles.cardText}>
            {line}
          </div>
        ))}
      </Col>
      <Col span={6}>
        {contact && (
          <>
            <div className={styles.cardText}>{contact.fullName}</div>
            <div className={styles.cardEmailText}>{contact.email}</div>
          </>
        )}
      </Col>
    </Row>
  </div>
);
