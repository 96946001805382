import { useCallback, useEffect, useState } from 'react';
import { Button, ButtonSize, ButtonVariant } from '@estimateone/frontend-components';
import { SSCFreeTrialTag } from '@subbie/common/SSCFreeTrialTag';
import { MissingTradesAlert } from '@subbie/modal/MissingTradesAlert/MissingTradesAlert';
import { MatchingTradesAndLockedCount } from '@subbie/modal/ProjectSlider/ProjectSliderBody/ProjectSubbies/MatchingTradesAndLockedCount';
import { SubbieListSlider } from '@subbie/modal/SubbieListSlider';
import ConnectionPreference from '../ConnectionPreferenceCard';
import { SubbiesPaywall } from '../SubbiesPaywall';
import { NewTag } from '@shared/NewTag';
import { useAccountHasStockTrades, useCurrentUser } from '@subbie/context/AccountProvider/hooks';
import {
  useConnectionPreference,
  useInSSCTrialExperiment,
  useUnlockSubcontractorsHandler,
} from './hooks';
import { Action, Event, useAnalytics } from '@ascension/components/hooks/Analytics';
import {
  useHasSubbieSupplierConnectionAccess,
  useSubbieConnectionCreditBalance,
} from '@subbie/common/CreditBalance/hooks';
import { useContactUsAboutUpgrade } from '@subbie/features/AccountUpgradeFeature/pages/NewUpgradeSelection/hooks';
import { UPGRADE_TYPE } from '@subbie/features/AccountUpgradeFeature/pages/NewUpgradeSelection/hooks/types';
import { InterestLevel } from '@ascension/_gqltypes/subbie.generated';
import { Project } from '@subbie/modal/ProjectSlider/types';
import { ProjectSubbieSummary_projectSubbieSummary as ProjectSubbieSummary } from '@subbie/modal/types/ProjectSubbieSummary';
import styles from './styles.scss';

type ProjectSubbiesProps = {
  readonly projectSubbieSummary: ProjectSubbieSummary | undefined;
  readonly project: Project;
};
export const ProjectSubbies = ({ projectSubbieSummary, project }: ProjectSubbiesProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const creditBalanceSummary = useSubbieConnectionCreditBalance();
  const { addEvent } = useAnalytics(Event.INTERACT);
  const { unlockInProgress, unlockSubcontractorsHandler } = useUnlockSubcontractorsHandler(project);
  const { connectionPreference, loading: preferenceLoading } = useConnectionPreference(project.id);
  const subbieCount = projectSubbieSummary?.subbieCount ?? 0;
  const viewCount = connectionPreference?.viewCount ?? 0;
  const hasStockTrades = useAccountHasStockTrades();
  const currentUser = useCurrentUser();

  const canViewSubbies = useHasSubbieSupplierConnectionAccess();
  const inTrialExperiment = useInSSCTrialExperiment();

  const closeSlider = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const handleButtonClicked = useCallback(() => {
    addEvent({
      action: Action.SSC_VIEW_SUBBIES_BUTTON_CLICKED,
      project: project.id,
      subbieCount,
    });
    setIsOpen(true);
  }, [addEvent, project.id, subbieCount]);

  const showTrialUpgradeModal = useContactUsAboutUpgrade(UPGRADE_TYPE.TrialSSC);

  useEffect(() => {
    if (!canViewSubbies || preferenceLoading) {
      return;
    }
    addEvent({
      action: Action.SSC_PROJECT_SUBBIE_LIST_VIEWED,
      projectId: project.id,
      subbieCount, // TODO remove this as it's the same as subbieCountInterested
      subbieCountInterested: subbieCount,
      subbieCountViewed: connectionPreference?.viewCount ?? 0,
    });
  }, [addEvent, project.id, subbieCount, connectionPreference, canViewSubbies, preferenceLoading]);

  if (!canViewSubbies) {
    return (
      <div id="project-subbies" className={styles.projectSection}>
        <div className="d-flex mb-2 justify-content-between align-items-center">
          <h3>
            Subcontractors
            <span className={styles.headingTagContainer}>
              <NewTag />
            </span>
          </h3>
        </div>
        <SubbiesPaywall projectId={project.id} />
      </div>
    );
  }

  if (!projectSubbieSummary) {
    return null;
  }

  const { lockedSubbieCount, unlocked: isUnlocked } = projectSubbieSummary;

  const allowedCredits = creditBalanceSummary?.subbieConnectionCreditBalance?.allowed ?? 0;
  const usedCredits = creditBalanceSummary?.subbieConnectionCreditBalance?.used ?? 0;

  const hasNoCredits = usedCredits >= allowedCredits;

  const shouldShowUpgradeToViewButton = inTrialExperiment && !isUnlocked && hasNoCredits;
  const shouldShowViewSubcontractorsButton =
    !shouldShowUpgradeToViewButton && viewCount + subbieCount > 0;

  return (
    <>
      <div id="project-subbies" className={styles.projectSection}>
        <div className="d-flex mb-2 justify-content-between align-items-center">
          <h3>
            Subcontractors
            {inTrialExperiment && (
              <span className={styles.headingTagContainer}>
                <SSCFreeTrialTag />
              </span>
            )}
          </h3>
          {shouldShowUpgradeToViewButton && (
            <Button
              size={ButtonSize.Medium}
              variant={ButtonVariant.Electric}
              onClick={showTrialUpgradeModal}
            >
              Upgrade to view
            </Button>
          )}
          {shouldShowViewSubcontractorsButton && (
            <Button
              size={ButtonSize.Medium}
              variant={ButtonVariant.Secondary}
              onClick={handleButtonClicked}
            >
              View subcontractors
            </Button>
          )}
        </div>
        {!hasStockTrades && <MissingTradesAlert isAdminUser={currentUser?.isAdmin} />}
        <ConnectionPreference
          preference={connectionPreference}
          loading={preferenceLoading}
          watchlistStatus={project.watchlistEntry?.status ?? InterestLevel.UNACTIONED}
        />
        <MatchingTradesAndLockedCount
          subbieCount={subbieCount}
          lockedSubbieCount={lockedSubbieCount}
          isUnlocked={isUnlocked}
          creditBalanceSummary={creditBalanceSummary}
        />
      </div>
      <SubbieListSlider
        isPaneOpen={isOpen}
        project={project}
        onUnlock={unlockSubcontractorsHandler}
        handleClose={closeSlider}
        unlockInProgress={unlockInProgress}
      />
    </>
  );
};
