import { gql } from '@apollo/client';

export const GET_PROVISIONED_LICENSE_FOR_CTA = gql`
  query GetProvisionedLicenseForCta($id: EntityId!) {
    license(id: $id) {
      id
      product {
        id
        name
      }
      invoiceItem {
        id
        invoice {
          id
          invoiceNumber
        }
      }
    }
  }
`;
