import { gql } from '@apollo/client';

export const DELETE_PACKAGE = gql`
  mutation DeletePackage($input: DeletePackageInput!) {
    deletePackage(input: $input) {
      ... on Package {
        id
      }
      ... on Errors {
        errors {
          message
        }
      }
    }
  }
`;
