import { Fieldset, FieldWrapper } from '@estimateone/frontend-components';
import { SubmissionErrors, ValidationErrors } from 'final-form';
import { SingleSelectDropdownField } from '../../../../../modules/FinalFormFields/SingleSelectDropdownField/SingleSelectDropdownField';
import { contractSizeLabelMap } from '../../utils';
import { ContractSizeMaxEnumMap, ContractSizeMinEnumMap } from '../values';
import styles from './ContractSize.module.scss';
import { EditContractSizeFieldName } from '../types';
import { ContractSizeEnum } from '@ascension/_gqltypes/profiles.generated';

export type MinContractSizeOption = {
  value: ContractSizeEnum | null;
  label: string;
};
export type MaxContractSizeOption = {
  value: ContractSizeEnum | null;
  label: string;
};
export const EMPTY_CONTRACT_SIZE = {
  value: null,
  label: '-',
};

const minContractSizeOption = Object.keys(ContractSizeEnum)
  .map((value: unknown) => value as keyof typeof ContractSizeEnum)
  .map((value: keyof typeof ContractSizeEnum) => ContractSizeEnum[value])
  .map((value: ContractSizeEnum) => ({
    value,
    label: contractSizeLabelMap(value),
  }))
  .sort((a, b) => ContractSizeMinEnumMap[a.value] - ContractSizeMinEnumMap[b.value]);

const maxContractSizeOption = Object.keys(ContractSizeEnum)
  .map((value: unknown) => value as keyof typeof ContractSizeEnum)
  .map((value: keyof typeof ContractSizeEnum) => ContractSizeEnum[value])
  .map((value: ContractSizeEnum) => ({
    value,
    label: contractSizeLabelMap(value),
  }))
  .sort((a, b) => ContractSizeMaxEnumMap[a.value] - ContractSizeMaxEnumMap[b.value]);

type EditContractFieldsProps = {
  submitErrors: SubmissionErrors;
  errors: ValidationErrors;
};

export const ContractSize = ({ submitErrors, errors }: EditContractFieldsProps) => (
  <div className={styles.contractFieldsWrapper}>
    <Fieldset legend="Contract Size" hideLegend showChildErrorStyles>
      <FieldWrapper
        errors={
          errors?.[EditContractSizeFieldName.MinAndMaxContractSize] ||
          submitErrors?.[EditContractSizeFieldName.MinAndMaxContractSize]
        }
      >
        <div className={styles.adjacentFields}>
          <SingleSelectDropdownField<MinContractSizeOption>
            id="min-contract-size"
            label="Contract size (minimum)"
            fieldName={EditContractSizeFieldName.MinContractSize}
            options={[EMPTY_CONTRACT_SIZE, ...minContractSizeOption]}
          />
          <SingleSelectDropdownField<MaxContractSizeOption>
            id="max-contract-size"
            label="Contract size (maximum)"
            fieldName={EditContractSizeFieldName.MaxContractSize}
            options={[EMPTY_CONTRACT_SIZE, ...maxContractSizeOption]}
          />
        </div>
      </FieldWrapper>
    </Fieldset>
  </div>
);
