import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  DrawerToolbar,
} from '@estimateone/frontend-components';
import { getCategoryLabel } from '@profiles/features/CompanyProfile/components/Categories/utils';
import { getContractSizeLabel } from '@profiles/features/CompanyProfile/components/ProjectHistory/ContractSizeSelect';
import { ProjectHistoryForm } from '@profiles/features/CompanyProfile/components/ProjectHistory/ProjectHistoryForm';
import { DrawerFooterButtons } from '@profiles/features/CompanyProfile/modules/Drawer/DrawerFooterButtons';
import { extractFieldErrors } from '@profiles/features/CompanyProfile/utils';
import { sendAppcuesEditProfileEvent } from '@profiles/features/CompanyProfile/utils/appcues';
import { useTranslator } from '@profiles/providers/TranslationsProvider';
import { useEditHistoricalProject } from './hooks';
import { StageCategory } from '@ascension/_gqltypes/profiles.generated';
import {
  ProjectHistoryFieldNames,
  ProjectHistoryFieldValues,
} from '@profiles/features/CompanyProfile/components/ProjectHistory/ProjectHistoryForm/types';
import { HistoricalProject } from '@profiles/providers/ProfileProvider/types';

export type EditHistoricalProjectProps = {
  data: HistoricalProject;
  isOpen: boolean;
  onRequestClose: () => void;
};

export const EditHistoricalProject = ({
  data,
  isOpen,
  onRequestClose,
}: EditHistoricalProjectProps) => {
  const formId = 'PROJECT_HISTORY_FORM';
  const { submit: submitForm, error, reset } = useEditHistoricalProject(data.id);

  const translate = useTranslator();

  const closeDrawer = () => {
    reset();
    onRequestClose();
  };

  const onSubmit = async (values: ProjectHistoryFieldValues) => {
    const { data: submitData, errors: submitErrors } = await submitForm(values);
    if (submitErrors) {
      return;
    }
    if (submitData?.updateHistoricalProject.__typename === 'Errors') {
      const { errors } = submitData.updateHistoricalProject;

      // eslint-disable-next-line consistent-return
      return extractFieldErrors(errors, [
        ProjectHistoryFieldNames.Name,
        ProjectHistoryFieldNames.BuilderName,
        ProjectHistoryFieldNames.State,
        ProjectHistoryFieldNames.Region,
        ProjectHistoryFieldNames.CompletionDate,
        ProjectHistoryFieldNames.Category,
        ProjectHistoryFieldNames.ContractSize,
      ]);
    }

    sendAppcuesEditProfileEvent();

    closeDrawer();
  };

  return (
    <Drawer isOpen={isOpen} onClose={closeDrawer}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerToolbar />
        <DrawerBody>
          <ProjectHistoryForm
            id={formId}
            onSubmit={onSubmit}
            error={error}
            initialValues={{
              ...data,
              name: data.name,
              completionDate: new Date(data.completionDate),
              state: data.state
                ? {
                    value: data.state.id,
                    label: data.state.shortName,
                  }
                : undefined,
              region: data.region,
              category: {
                value: data.category,
                label: getCategoryLabel(StageCategory[data.category]),
              },
              contractSize: {
                value: data.contractSize,
                label: getContractSizeLabel(data.contractSize, translate) ?? '',
              },
              linkedProjectId: data.linkedProjectId,
            }}
            isEdit
          />
        </DrawerBody>
        <DrawerFooter>
          <DrawerFooterButtons formId={formId} close={closeDrawer} saveButtonText="Save Project" />
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
