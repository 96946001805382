import { ReactNode } from 'react';
import Routing from 'routing';
import {
  Button,
  ButtonVariant,
  Icon,
  IconName,
  Row,
  Tooltip,
} from '@estimateone/frontend-components';
import E1Request from '../../../../js/classes/E1Request';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

const showMergeModal = async (from: EntityId, to: EntityId) => {
  const route = Routing.generate('app_addressbookcompany_mergepreviewdetails', {
    id: from,
    original: to,
  });
  return new E1Request(route).setShowLoadingModal().submit();
};

type CompanyActionsProps = {
  originalId: EntityId;
  duplicateId: EntityId;
  onDismiss: () => void;
  dismissText?: string;
  mergeDirection: MERGE_DIRECTION;
};

export enum MERGE_DIRECTION {
  NONE,
  LEFT,
  RIGHT,
  BOTH,
}

const DisabledMergeTooltip = ({ children }: { children: ReactNode }) => (
  <Tooltip
    tooltipId="disabled-merge-tooltip"
    text="You cannot merge over a connected company."
    className={styles.disabledMergeTooltip}
  >
    <div>{children}</div>
  </Tooltip>
);

const RightMergeButton = ({
  onClick,
  enabled = true,
}: {
  onClick?: () => void;
  enabled?: boolean;
}) => (
  <Button
    data-testid="preview-merge-right"
    variant={ButtonVariant.Secondary}
    fullWidth
    disabled={!enabled}
    onClick={onClick}
  >
    Preview merge
    <Icon name={IconName.ArrowRight} marginLeft="small" size="0.8em" />
  </Button>
);

const DisabledRightMergeButton = () => (
  <DisabledMergeTooltip>
    <RightMergeButton enabled={false} />
  </DisabledMergeTooltip>
);

const LeftMergeButton = ({
  onClick,
  enabled = true,
}: {
  onClick?: () => void;
  enabled?: boolean;
}) => (
  <Button
    data-testid="preview-merge-left"
    variant={ButtonVariant.Secondary}
    fullWidth
    disabled={!enabled}
    onClick={onClick}
  >
    <Icon name={IconName.ArrowLeft} marginRight="small" size="0.8em" />
    Preview merge
  </Button>
);

const DisabledLeftMergeButton = () => (
  <DisabledMergeTooltip>
    <LeftMergeButton enabled={false} />
  </DisabledMergeTooltip>
);

const CompanyActions = ({
  originalId,
  duplicateId,
  onDismiss,
  dismissText = 'Ignore',
  mergeDirection = MERGE_DIRECTION.BOTH,
}: CompanyActionsProps) => (
  <div className={styles.companyActions}>
    <Row>
      {mergeDirection === MERGE_DIRECTION.BOTH || mergeDirection === MERGE_DIRECTION.RIGHT ? (
        <RightMergeButton onClick={() => showMergeModal(originalId, duplicateId)} />
      ) : (
        <DisabledRightMergeButton />
      )}
    </Row>
    <Row>
      {mergeDirection === MERGE_DIRECTION.BOTH || mergeDirection === MERGE_DIRECTION.LEFT ? (
        <LeftMergeButton onClick={() => showMergeModal(duplicateId, originalId)} />
      ) : (
        <DisabledLeftMergeButton />
      )}
    </Row>
    <Row>
      <Button variant={ButtonVariant.RedOutline} fullWidth onClick={onDismiss}>
        {dismissText}
      </Button>
    </Row>
  </div>
);

export default CompanyActions;
