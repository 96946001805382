import { useState } from 'react';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  IntegrateIcon,
  Tooltip,
} from '@estimateone/frontend-components';
import { ProcoreConnectionInformationModal } from '@builder/features/DocSyncIntegration/ProcoreConnect/ProcoreConnectionInformationModal/ProcoreConnectionInformationModal';
import styles from './styles.scss';

export type ProcoreConnectProps = {
  isDisabled: boolean;
};

export const ProcoreConnect = ({ isDisabled }: ProcoreConnectProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <ProcoreConnectionInformationModal
        isOpen={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
      />
      {isDisabled ? (
        <Tooltip
          tooltipId="procoreConnectTooltip"
          text="Check you have installed E1's app in Procore first (contact us if you need help). Then delete all files from the Documents page in E1 before connecting."
        >
          {/* wrap button to ensure tooltip works when disabled */}
          <div>
            <Button
              variant={ButtonVariant.Grey}
              size={ButtonSize.Medium}
              className={styles.procoreConnectContainer}
              disabled={isDisabled}
            >
              <IntegrateIcon className={styles.procoreConnectButtonDisabledIcon} />
              <div>Connect to Procore...</div>
            </Button>
          </div>
        </Tooltip>
      ) : (
        <Button
          title="Connect to Procore"
          variant={ButtonVariant.Grey}
          size={ButtonSize.Medium}
          className={styles.procoreConnectContainer}
          onClick={() => setIsModalOpen(true)}
        >
          <IntegrateIcon className={styles.procoreConnectButtonIcon} />
          <div>Connect to Procore...</div>
        </Button>
      )}
    </>
  );
};
