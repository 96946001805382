import React from 'react';
import { Alert, AlertVariant, Icon, IconName } from '@estimateone/frontend-components';
import styles from './FormServerError.module.scss';

export const FormServerError = () => (
  <Alert className={styles.serverError} variant={AlertVariant.Red}>
    <Icon className={styles.serverErrorIcon} name={IconName.Clear} />
    <p>Sorry, something went wrong on our end. Please Try again</p>
  </Alert>
);
