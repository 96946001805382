import { createContext } from 'react';
import { ApolloError } from '@apollo/client';
import { AvailableFeaturesQuery } from '@profiles/providers/FeatureProvider/queries.generated';

type AvailableFeatures = AvailableFeaturesQuery['availableFeatures'];

type EnabledFeatures = {
  [K in keyof AvailableFeatures]: AvailableFeatures[K];
};

type Features = Omit<EnabledFeatures, '__typename'>;

type FeatureContextLoading = {
  loading: boolean;
  context: null;
  error?: ApolloError;
};

type FeatureContextLoaded = {
  loading: boolean;
  context: Features;
  error?: ApolloError;
};

export type FeatureContextProps = FeatureContextLoading | FeatureContextLoaded;

export const FeatureContext = createContext<FeatureContextProps>({
  loading: true,
  context: null,
  error: undefined,
});
