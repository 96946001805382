import $ from 'jquery';
import Routing from 'routing';
import E1Request from '../classes/E1Request';

$(() => {
  const $body = $('body');

  const openManagePaymentMethodPortal = (portalSession: string) => {
    const cbInstance = Chargebee.getInstance();
    cbInstance.setPortalSession(portalSession);

    const cbPortal = cbInstance.createChargebeePortal();
    cbPortal.openSection(
      {
        sectionType: Chargebee.getPortalSections().PAYMENT_SOURCES,
      },
      {
        close() {
          cbInstance.logout();
        },
      },
    );
  };

  $body.on('click', 'a.manage-payment-method', async () => {
    // eslint-disable-next-line camelcase
    const request = new E1Request<{ success: boolean; portal_session_token: string }>(
      Routing.generate('app_self_serve_portal_token'),
    );
    const response = await request.submit();
    const portalSessionToken = response.portal_session_token;
    openManagePaymentMethodPortal(portalSessionToken);
  });
});
