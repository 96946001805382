import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_PACKAGE_BUDGET_AMOUNT, UPDATE_PACKAGE_ACTUAL_AMOUNT } from '../mutations';
import { UpdateActualAmount, UpdateActualAmountVariables } from '../types/UpdateActualAmount';
import { UpdateBudgetAmount, UpdateBudgetAmountVariables } from '../types/UpdateBudgetAmount';
import {
  UpdateBudgetAmountInput,
  UpdateActualAmountInput,
} from '@ascension/_gqltypes/builder.generated';

export const useUpdatePackageFinances = () => {
  const [updateBudgetAmount] = useMutation<UpdateBudgetAmount, UpdateBudgetAmountVariables>(
    UPDATE_PACKAGE_BUDGET_AMOUNT,
  );

  const [updateActualAmount] = useMutation<UpdateActualAmount, UpdateActualAmountVariables>(
    UPDATE_PACKAGE_ACTUAL_AMOUNT,
  );

  const updateBudget = useCallback(
    async ({ packageId, amount }: UpdateBudgetAmountInput) => {
      const { data } = await updateBudgetAmount({
        variables: {
          input: {
            packageId,
            amount,
          },
        },
        ignoreResults: true,
        optimisticResponse: {
          updateBudgetAmount: {
            __typename: 'LettingScheduleDetails',
            id: packageId,
            budgetAmount: amount ?? null,
          },
        },
      });
      return data?.updateBudgetAmount;
    },
    [updateBudgetAmount],
  );

  const updateActual = useCallback(
    async ({ packageId, amount }: UpdateActualAmountInput) => {
      const { data } = await updateActualAmount({
        variables: {
          input: {
            packageId,
            amount,
          },
        },
        ignoreResults: true,
        optimisticResponse: {
          updateActualAmount: {
            __typename: 'LettingScheduleDetails',
            id: packageId,
            actualAmount: amount ?? null,
          },
        },
      });
      return data?.updateActualAmount;
    },
    [updateActualAmount],
  );

  return { updateBudget, updateActual };
};
