import { useMutation } from '@apollo/client';
import { GRANT_RESTRICTED_STAGE_ACCESS } from '../mutations';
import {
  GrantRestrictedStageAccess,
  GrantRestrictedStageAccessVariables,
} from '../types/GrantRestrictedStageAccess';
import { EntityId } from '@ascension/types';

export const useGrantRestrictedStageAccess = (stageId: EntityId) => {
  const [mutate, { loading }] = useMutation<
    GrantRestrictedStageAccess,
    GrantRestrictedStageAccessVariables
  >(GRANT_RESTRICTED_STAGE_ACCESS);

  const grantRestrictedStageAccess = async (userIds: number[]) => {
    const result = await mutate({
      variables: {
        input: {
          stageId,
          userIds,
        },
      },
    });

    return result?.data?.grantRestrictedStageAccess;
  };

  return { grantRestrictedStageAccess, loading };
};
