import { Accordion, AccordionTitle } from '@estimateone/frontend-components';
import { QuoteCoverageTable } from './QuoteCoverageTable';
import { Action, useInteractAnalytics } from '../../../../hooks/Analytics';
import { EntityId } from '../../../../../types';
import { GetPackagesForQuoteCoverageReport_activePackages as ActivePackage } from '../types/GetPackagesForQuoteCoverageReport';
import styles from './styles.scss';

type QuoteCoverageProps = {
  stageId: EntityId;
  packages: ActivePackage[];
};

const QuoteCoverageAccordionTitle = () => (
  <span className={styles.quoteCoverageAccordionTitle}>
    <AccordionTitle titleText="Quote Coverage" />
  </span>
);

export const QuoteCoverage = ({ stageId, packages }: QuoteCoverageProps) => {
  const { addEvent } = useInteractAnalytics();

  const handleAccordionAnalytics = (
    action: Action.QUOTE_COVERAGE_OPEN | Action.QUOTE_COVERAGE_CLOSE,
  ) =>
    addEvent({
      action,
      stageId,
    });

  return (
    <div className={styles.quoteCoverage}>
      <Accordion
        accordionTitle={<QuoteCoverageAccordionTitle />}
        onExpand={() => handleAccordionAnalytics(Action.QUOTE_COVERAGE_OPEN)}
        onCollapse={() => handleAccordionAnalytics(Action.QUOTE_COVERAGE_CLOSE)}
        initIsCollapsed
      >
        <p className={styles.summary}>
          &apos;Quoting&apos; and &apos;Quoted&apos; count companies, not individual contacts.
          Refresh the page to update this table.
        </p>
        <QuoteCoverageTable stageId={stageId} packages={packages} />
      </Accordion>
    </div>
  );
};
