import { useQuery } from '@apollo/client';
import { GET_DOCSYNC_MAP_FOR_MODIFICATION } from './queries';
import {
  GetDocSyncMapForModification,
  GetDocSyncMapForModificationVariables,
} from './types/GetDocSyncMapForModification';

export const useGetDocSyncMapForModification = (stageId: number) => {
  const { data, loading, error, refetch } = useQuery<
    GetDocSyncMapForModification,
    GetDocSyncMapForModificationVariables
  >(GET_DOCSYNC_MAP_FOR_MODIFICATION, {
    variables: {
      stageId,
    },
  });

  return {
    data,
    loading,
    hasError: error !== undefined,
    refetch,
  };
};
