import Routing from 'routing';
import { getStageTypeForRouteWithLegacyNotificationDefault } from './utils';
import { GroupableTransformer, TransformerVersionMap } from './types';

const version1: GroupableTransformer = {
  buildText: (data) => (
    <>
      You have received a revised quote for <strong>{data.get('stageName')}</strong>
    </>
  ),
  buildLink: (data) =>
    Routing.generate('app_stage_quotes', {
      id: data.get('stageId'),
      stageType: getStageTypeForRouteWithLegacyNotificationDefault(
        parseInt(data.get('stageType') ?? ''),
      )?.toLowerCase(),
    }),
  getGroupingKey: (topicMap) => topicMap.get('stage') ?? '',
  buildGroupText: (data, count) => (
    <>
      You have received {count} revised quotes for <strong>{data.get('stageName')}</strong>
    </>
  ),
  buildGroupLink: (data) =>
    Routing.generate('app_stage_quotes', {
      id: data.get('stageId'),
      stageType: getStageTypeForRouteWithLegacyNotificationDefault(
        parseInt(data.get('stageType') ?? ''),
      )?.toLowerCase(),
    }),
};

export const quoteRevisedNotificationTransformerVersionMap: TransformerVersionMap = {
  1: version1,
};
