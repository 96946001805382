import { gql } from '@apollo/client';

export const DELETE_PENDING_ADDENDUM = gql`
  mutation deletePendingAddendum($input: DeletePendingAddendumInput!) {
    deletePendingAddendum(input: $input) {
      ... on Addendum {
        id
      }
      ... on Errors {
        errors {
          message
        }
      }
    }
  }
`;
