import { gql } from '@apollo/client';

export const ADD_NOTE = gql`
  mutation AddProjectNote($input: AddNoteToProjectInput!) {
    addNoteToProject(input: $input) {
      ... on ProjectNote {
        id
        text
        createdAt
        authorName
      }
      ... on Errors {
        errors {
          field
          message
        }
      }
    }
  }
`;
