import { Moment } from 'moment';
import moment from 'moment-timezone';
import { Action } from '../../../components/hooks/Analytics';

export default class PackageDueDatePicker {
  $datePicker: JQuery;
  onDateChange: (newFormattedDate: Moment) => void;
  MOMENT_FORMAT: string;
  DATEPICKER_FORMAT: string;
  defaultDueDate: Moment | null = null;
  onDatePickerToggle: (value: boolean) => void;
  letByDate: Moment | null = null;
  $datePickerContainer: JQuery;
  onShowErrorCallback: (hasError: boolean) => void;

  constructor(
    $datePickerContainer: JQuery,
    onDateChange: (newFormattedDate: Moment) => void,
    onDatePickerToggle: (value: boolean) => void,
    onShowErrorCallback: (hasError: boolean) => void,
  ) {
    this.$datePickerContainer = $datePickerContainer;
    this.$datePicker = $datePickerContainer.find('.package_date_picker');
    this.onDateChange = onDateChange;
    this.onDatePickerToggle = onDatePickerToggle;
    this.onShowErrorCallback = onShowErrorCallback;

    this.MOMENT_FORMAT = 'DD MMM YYYY';
    this.DATEPICKER_FORMAT = 'dd M yyyy';
  }

  init() {
    // Transform DATEPICKER_FORMAT to MOMENT_FORMAT
    const transformedDatePickerFormat = this.DATEPICKER_FORMAT.toUpperCase();

    // The default date for a package, either the tender end date or today
    const defaultViewDate = this.$datePicker.data('date-default-view-date');
    this.defaultDueDate =
      defaultViewDate !== '' ? moment(defaultViewDate, transformedDatePickerFormat) : null;

    const options = {
      autoclose: true,
      defaultViewDate: new Date(),
      format: this.DATEPICKER_FORMAT,
    };

    // https://bootstrap-datepicker.readthedocs.io/en/latest/events.html
    this.$datePicker
      .datepicker(options)
      .on('click', () => {
        window.analyticsService?.addInteractEvent({
          action: Action.INVITE_WIZARD_PACKAGE_DATE_PICKER_CLICK,
        });
        this.onDatePickerToggle(true);
        this.onShowErrorCallback(false);
      })
      .on('hide', () => {
        const newDueDate = this.$datePicker.datepicker('getDate');
        if (newDueDate) {
          if (this.letByDate && this.letByDate < moment(newDueDate)) {
            this.$datePickerContainer
              .find('.date-picker-validation-error')
              .text(
                `Enter a date before or equal to the Let By date (${this.letByDate.format(
                  this.MOMENT_FORMAT,
                )})`,
              );

            this.onShowErrorCallback(true);
          } else {
            this.onDateChange(moment(newDueDate));
          }
        } else if (this.defaultDueDate !== null) {
          // no date change when there should've been, reset to default
          this.setPackageDueDate(this.defaultDueDate);
          this.onDateChange(this.defaultDueDate);
        }
        this.onDatePickerToggle(false);
      });
  }

  setPackageDueDate(newDueDate: Moment) {
    if (newDueDate) {
      this.$datePicker.datepicker('update', newDueDate.format(this.MOMENT_FORMAT));
    } else {
      this.$datePicker.datepicker('update', '');
      this.$datePickerContainer
        .find('.date-picker-validation-error')
        .text(`Please enter a quotes due date`);

      this.onShowErrorCallback(true);
    }
  }

  setLetByDate(letByDate: Moment) {
    this.letByDate = letByDate;
  }

  setDisabled(newValue: boolean) {
    this.$datePicker.prop('disabled', newValue);
  }

  showValidationError(show: boolean) {
    this.$datePickerContainer.toggleClass('has-error', show);
  }
}
