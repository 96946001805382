import { RestrictedStageAccess, User } from '../types';

export const groupUsersByRestrictedStageAccess = (stageAccesses: RestrictedStageAccess[]) => {
  const standardUsersWithAccess: User[] = [];
  const standardUsersWithoutAccess: User[] = [];
  const adminUsers: User[] = [];

  stageAccesses.forEach((stageAccess) => {
    if (stageAccess.user.isAdmin) {
      adminUsers.push(stageAccess.user);
    } else if (stageAccess.access) {
      standardUsersWithAccess.push(stageAccess.user);
    } else {
      standardUsersWithoutAccess.push(stageAccess.user);
    }
  });

  return [standardUsersWithAccess, standardUsersWithoutAccess, adminUsers];
};
