import $ from 'jquery';
import colours from '../../css/js-colours.scss';
import { initCompetitorTable, modifyCompetitorTable } from './MarketStatistics/CompetitorTable';
import IndustryStatsTable from './MarketStatistics/IndustryStatsTable';
import StatChart from './MarketStatistics/StatChart';

const { practical900: chartBarColour } = colours;

$(() => {
  const $stats = $('.stat-container');
  if ($stats.length === 0) return;

  const defaultRegionId = $stats.data('default-region-id');
  if (!defaultRegionId) return;

  const statChartContainer = $('div.tender-stat-chart-container');
  const statChartId = 'chart_tenders';
  const statChart =
    $(`#${statChartId}`).length > 0
      ? new StatChart(
          statChartId,
          statChartContainer.width() as number,
          defaultRegionId,
          chartBarColour,
        )
      : null;

  const $competitorTable = $('.competitor-table');
  const competitorTable =
    $competitorTable.length > 0 ? initCompetitorTable($competitorTable, defaultRegionId) : null;

  const $industryTable = $('.industry-statistics');
  const industryTable =
    $industryTable.length > 0 ? new IndustryStatsTable($industryTable, defaultRegionId) : null;

  const $regionFilter = $stats.find('select.stat-region-filter');

  $regionFilter.on('change', async ({ currentTarget }) => {
    const targetVal = $(currentTarget).val();
    const regionId = targetVal ? parseInt(targetVal.toString()) : undefined;

    if (!regionId) {
      throw Error('Could not resolve region id for stats chart');
    }

    const statChartUpdatePromise: Promise<void> = statChart
      ? statChart.update(regionId)
      : Promise.resolve();

    const competitorTableUpdatePromise: Promise<void> = competitorTable
      ? modifyCompetitorTable(competitorTable, regionId)
      : Promise.resolve();

    const industryTableUpdatePromise: Promise<void> = industryTable
      ? industryTable.update(regionId)
      : Promise.resolve();

    return Promise.all([
      statChartUpdatePromise,
      competitorTableUpdatePromise,
      industryTableUpdatePromise,
    ]);
  });
});
