import { useForm } from 'react-final-form';
import { Accordion } from '@estimateone/frontend-components';
import { TenderResponsesTable } from './TenderResponsesTable';
import LoadingSpinner from '@shared/LoadingSpinner';
import { useTenderResponsesForPackage } from './hooks';
import { InviteToQuoteFormFieldName } from '../../enums';
import { InviteToQuoteFormValues } from '../../types';
import { GetInviteToQuoteStage_stage_UnrestrictedStage as UnrestrictedStage } from '../../types/GetInviteToQuoteStage';
import { GetTenderResponsesForPackage_package_activeRfqs as RFQs } from './types/GetTenderResponsesForPackage';
import styles from './styles.scss';

type Package = UnrestrictedStage['activePackages'][0];

const AccordionContent = ({
  loading,
  hasResponses,
  rfqs,
  selectedPackage,
}: {
  loading: boolean;
  hasResponses: boolean;
  rfqs: RFQs[] | undefined;
  selectedPackage: Package;
}) => {
  if (loading) return <LoadingSpinner />;

  if (hasResponses && rfqs) return <TenderResponsesTable rfqs={rfqs} />;

  return (
    <p className={styles.emptyState}>
      The {selectedPackage.label} package had no invites or responses during the Tender Phase.
    </p>
  );
};

export const TenderResponses = () => {
  const { getState } = useForm<InviteToQuoteFormValues>();
  const { values } = getState();

  const selectedPackage = values[InviteToQuoteFormFieldName.Package];

  const { rfqs, loading } = useTenderResponsesForPackage(selectedPackage?.importedFromPackage?.id);

  if (!selectedPackage) return null;

  const hasResponses = (rfqs?.length ?? 0) > 0;

  return (
    <Accordion
      accordionTitle={
        loading
          ? 'Responses from tender phase'
          : `Responses from tender phase (${hasResponses ? rfqs?.length : 'none'})`
      }
      className={styles.tenderResponses}
    >
      <AccordionContent
        loading={loading}
        hasResponses={hasResponses}
        rfqs={rfqs}
        selectedPackage={selectedPackage}
      />
    </Accordion>
  );
};
