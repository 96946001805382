import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { PACKAGES_FOR_LETTING_SCHEDULE } from '../queries';
import {
  GetPackagesForLettingSchedule,
  GetPackagesForLettingScheduleVariables,
  GetPackagesForLettingSchedule_activePackages as Package,
  GetPackagesForLettingSchedule_stage_UnrestrictedStage as Stage,
  GetPackagesForLettingSchedule_lettingScheduleCustomColumns as CustomColumns,
} from '../types/GetPackagesForLettingSchedule';
import { EntityId } from '@ascension/types';

type UseGetPackagesForLettingSchedule = {
  loading: boolean;
  activePackages: Package[];
  stage?: Stage;
  lettingScheduleCustomColumns: CustomColumns[];
};

export const useGetPackagesForLettingSchedule = (
  stageId: EntityId,
): UseGetPackagesForLettingSchedule => {
  const { data, loading } = useQuery<
    GetPackagesForLettingSchedule,
    GetPackagesForLettingScheduleVariables
  >(PACKAGES_FOR_LETTING_SCHEDULE, {
    variables: { stageId },
  });

  return {
    stage: data?.stage?.__typename === 'UnrestrictedStage' ? data.stage : undefined,
    activePackages: useMemo(
      () => data?.activePackages?.filter((pkg) => !pkg.fullSet) ?? [],
      [data],
    ),
    lettingScheduleCustomColumns: data?.lettingScheduleCustomColumns ?? [],
    loading,
  };
};
