import { Select, SelectSize } from '@estimateone/frontend-components';
import { FilterOption } from '.';
import { ValueLabelOption } from '@estimateone/frontend-components/src/components/Form/Select/types';
import styles from './styles.scss';

type StatusFilterInputProps = {
  statusFilterOptions: FilterOption[];
  onChange: (values: ValueLabelOption<number>[]) => void;
  value: ValueLabelOption<number>[];
};

const StatusFilterInput = ({ statusFilterOptions, onChange, value }: StatusFilterInputProps) => (
  <Select
    id="filter-status"
    aria-label="Status Filter"
    className={styles.scheduleFilter}
    label="Status"
    placeholder="Filter by Status"
    onChange={onChange}
    size={SelectSize.Small}
    options={statusFilterOptions}
    isMulti
    value={value}
  />
);

export { StatusFilterInput };
