import { gql } from '@apollo/client';

export const DEFAULT_OFFICE_QUERY = gql`
  query DefaultOfficeForDirectoryFilters {
    currentUser {
      id
      account {
        id
        defaultOffice {
          id
          address {
            id
            suburb
            city
            latitude
            longitude
          }
        }
      }
    }
  }
`;
