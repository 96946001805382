import { useFormState } from 'react-final-form';
import Routing from 'routing';
import { Avatar } from '@estimateone/frontend-components';
import { ConnectionPreferenceInfoCard } from '../ConnectionPreferenceInfoCard';
import { SingleSelectField } from '@shared/FinalForm/Field/SingleSelectField';
import { useCurrentUser } from '@subbie/context/AccountProvider/hooks';
import { useGetTeamUsers } from './hooks';
import { AvatarSize } from '@estimateone/frontend-components/src/enums/Avatar';
import {
  ConnectionPreferenceField,
  ConnectionPreferenceFormLabels,
  ConnectionPreferenceFormValues,
} from '../../ConnectionPreferenceFormContext/types';
import styles from './styles.scss';

type PrimaryContactSectionProps = {
  isEnabled: boolean;
};

export const PrimaryContactSection = ({ isEnabled }: PrimaryContactSectionProps) => {
  const formState = useFormState<ConnectionPreferenceFormValues>();
  const { contact } = formState.values;
  const currentUser = useCurrentUser();
  const { teamUsers: usersList } = useGetTeamUsers();

  const selectedUser = usersList.find((user) => user.id === contact?.value);
  const options = usersList.map((user) => ({
    value: user.id,
    label: user.fullName ?? '',
  }));

  const currentUserIsAdmin = currentUser?.isAdmin;
  const selectedUserIsAdmin = selectedUser?.isAdmin;
  const currentUserIsSelectedUser = currentUser?.id === selectedUser?.id;
  const noEditLink = !currentUserIsAdmin && (selectedUserIsAdmin || !currentUserIsSelectedUser);

  return (
    <section>
      <h3>{ConnectionPreferenceFormLabels.PrimaryContactTitle}</h3>
      <label className={styles.primaryContactLabel} htmlFor={ConnectionPreferenceField.Contact}>
        {ConnectionPreferenceFormLabels.PrimaryContactLegend}
      </label>
      <p className={styles.contactHelpText}>
        {ConnectionPreferenceFormLabels.PrimaryContactHelpText}
      </p>
      <SingleSelectField
        id={ConnectionPreferenceField.Contact}
        name={ConnectionPreferenceField.Contact}
        fieldName={ConnectionPreferenceField.Contact}
        label={ConnectionPreferenceFormLabels.PrimaryContactLegend}
        options={options}
        isDisabled={!isEnabled}
        hideLabel
      />
      {selectedUser && (
        <>
          <div className={styles.selectedContactContainer}>
            <div className={styles.profileSection}>
              <Avatar
                size={AvatarSize.Profile}
                userId={selectedUser.id}
                firstName={selectedUser.firstName ?? ''}
                lastName={selectedUser.lastName ?? ''}
              />
              {noEditLink ? (
                <p>{selectedUser.fullName}</p>
              ) : (
                <div className={styles.nameSection}>
                  <p className={styles.nameField}>{selectedUser.fullName}</p>
                  <a
                    className={styles.linkField}
                    target="_blank"
                    href={
                      currentUser?.id === selectedUser.id
                        ? Routing.generate('app_account_index')
                        : Routing.generate('app_myteam_index')
                    }
                    rel="noreferrer"
                  >
                    Edit details
                  </a>
                </div>
              )}
            </div>
            <div className={styles.contactDetailSection}>
              <p>{selectedUser.phone}</p>
              <p className={styles.email}>{selectedUser.email}</p>
            </div>
          </div>
          <ConnectionPreferenceInfoCard />
        </>
      )}
    </section>
  );
};
