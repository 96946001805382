import Routing from 'routing';
import { Button, ButtonVariant } from '@estimateone/frontend-components';
import { differenceInCalendarDays, format as formatDate } from 'date-fns';
import Banner, { BannerVariant } from '../../../../shared/Banner';
import styles from './styles.scss';

const ssoSecretsUrl = Routing.generate('app_account_sso_secrets');

type SsoSecretBannerProps = {
  secretExpiresAt?: string;
};

const displayHumanReadableDate = (date: string) => formatDate(new Date(date), 'd MMM yyyy');
const getNumberOfDaysToExpire = (expiresIn: string) =>
  differenceInCalendarDays(new Date(expiresIn), new Date());

export const SsoSecretBanner = ({ secretExpiresAt }: SsoSecretBannerProps) => {
  if (!secretExpiresAt) {
    return null;
  }

  return (
    <Banner variant={BannerVariant.Error}>
      <div className={styles.body}>
        <div className={styles.details}>
          <h4>
            Lose access to E1 in {getNumberOfDaysToExpire(secretExpiresAt)} days if you haven&apos;t
            added an upcoming Secret
          </h4>
          <div>
            Your current SSO Secret expires on {displayHumanReadableDate(secretExpiresAt)} Add an
            upcoming Secret in the <strong>Single Sign-On</strong> settings page.
          </div>
        </div>
        <Button link={ssoSecretsUrl} variant={ButtonVariant.Secondary} className={styles.button}>
          Go to SSO Settings
        </Button>
      </div>
    </Banner>
  );
};
