import { CellProps } from '@estimateone/frontend-components';
import { LettingScheduleNotesPane } from '../LettingScheduleNotesPane';
import { EntityId } from '@ascension/types';
import { Package } from '@builder/features/ProcurementLettingSchedule/types';
import styles from '../styles.scss';

export const NotesCell =
  (openNotesSlider: (packageId: EntityId, packageTitle: string, critical: boolean) => void) =>
  ({ row: { original } }: CellProps<Package>) => (
    <div className={styles.cellAlign}>
      <LettingScheduleNotesPane
        packageId={original.id}
        noteCount={original.lettingScheduleDetails?.noteCount}
        text={original.lettingScheduleDetails?.latestNote?.text}
        onClick={() => {
          openNotesSlider(original.id, original.title, false);
        }}
      />
    </div>
  );
