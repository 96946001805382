import { useCallback, useState } from 'react';
import {
  Button,
  ButtonProps,
  ButtonVariant,
  IconVariant,
  NotificationCounter,
  Tooltip,
} from '@estimateone/frontend-components';
import { htmlDecode } from '@builder/utils/htmlDecode';
import { NoteIcon } from '../PackageDashboard/NoteIcon';
import styles from './styles.scss';

export type LettingScheduleNotesPaneProps = {
  packageId: number;
  noteCount?: number;
  text?: string;
  onClick?: ButtonProps['onClick'];
};

export const LettingScheduleNotesPane = ({
  packageId,
  noteCount,
  text,
  onClick,
}: LettingScheduleNotesPaneProps) => {
  const [hasTooltip, setHasTooltip] = useState(false);

  const formattedText = htmlDecode(text || '');
  const checkIfTooltipIsRequired = useCallback((notePreview: HTMLDivElement | null) => {
    if (notePreview && notePreview.offsetHeight < notePreview.scrollHeight) {
      setHasTooltip(true);
    }
  }, []);

  const renderLatestNote = () =>
    hasTooltip ? (
      <Tooltip text={formattedText} tooltipId={`LettingScheduleNotesPane-${packageId}`}>
        <div className={styles.latestNote}>{formattedText}</div>
      </Tooltip>
    ) : (
      <div className={styles.latestNote} ref={checkIfTooltipIsRequired}>
        {formattedText}
      </div>
    );

  return (
    <div className={styles.notesPane}>
      <Button
        className={styles.notesButton}
        aria-label="View or add notes."
        variant={ButtonVariant.Transparent}
        onClick={onClick}
      >
        {noteCount && noteCount > 0 ? (
          <NotificationCounter count={noteCount}>
            <NoteIcon variant={IconVariant.Default} />
          </NotificationCounter>
        ) : (
          <>
            <NoteIcon variant={IconVariant.Grey} className={styles.notesIconEmpty} />
            <div className={styles.placeholder}>Add a note...</div>
          </>
        )}
      </Button>

      {text ? renderLatestNote() : undefined}
    </div>
  );
};
