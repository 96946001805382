import styles from './styles.scss';

const EftposForm = () => (
  <div className={styles.eftposForm}>
    <p>We will issue you an invoice via email.</p>
    <p>
      Features associated with this product will be unlocked as soon as we receive your payment. For
      immediate access, please use the <strong>Credit Card</strong> option above.
    </p>
  </div>
);

export default EftposForm;
