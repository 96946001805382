import { DispatchWithoutAction } from 'react';
import { Modal, ModalSize } from '@estimateone/frontend-components';
import { EditContractAndWorkforceSizeForm } from './EditContractAndWorkforceSizeForm';
import { ContractSizeEnum, WorkforceSizeEnum } from '@ascension/_gqltypes/profiles.generated';

const ALLOW_MODAL_OVERFLOW = {
  style: {
    content: {
      overflow: 'visible',
    },
  },
};

export type EditContractAndWorkforceSizeProps = {
  isOpen: boolean;
  toggle: DispatchWithoutAction;
  contractSizeMax: ContractSizeEnum | null;
  contractSizeMin: ContractSizeEnum | null;
  workforceSize: WorkforceSizeEnum | null;
};

export const EditContractAndWorkforceSize = ({
  isOpen,
  toggle,
  contractSizeMax,
  contractSizeMin,
  workforceSize,
}: EditContractAndWorkforceSizeProps) => (
  <div>
    <Modal
      rootElement="#MFE_PROFILES_ROOT"
      isOpen={isOpen}
      onRequestClose={toggle}
      size={ModalSize.Small}
      reactModalProps={ALLOW_MODAL_OVERFLOW}
    >
      <Modal.Section>
        <EditContractAndWorkforceSizeForm
          workforceSize={workforceSize}
          contractSizeMin={contractSizeMin}
          contractSizeMax={contractSizeMax}
          toggle={toggle}
        />
      </Modal.Section>
    </Modal>
  </div>
);
