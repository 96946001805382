import { useState } from 'react';
import { createRoot, Root } from 'react-dom/client';
import { Mention, MentionsInput } from 'react-mentions';
import Routing from 'routing';
import { Button } from '@estimateone/frontend-components';
import E1Request from '@ascension/js/classes/E1Request';
import { BuilderApiProvider } from '@api/ApiProvider';
import { renderWithParentsFromRoot } from '@ascension/components/render-helper';
import BuilderErrorBoundary from '@builder/BuilderErrorBoundary';
import { BuilderAccountProvider } from '@builder/context/AccountProvider/BuilderAccountProvider';
import { useRealUsers } from '@builder/context/AccountProvider/hooks';
import { EntityId } from '@ascension/types';

// eslint-disable-next-line fp/no-let
let rfqNotesRoot: Root | undefined;

type AddNoteSuccessResponse = {
  success: true;
  note: {
    getAuthorName: string;
    createdAt: unknown;
    text: string;
  };
  rfq: { id: EntityId }[];
};

type AddNoteResponse = AddNoteSuccessResponse | { success: true; error: string };

const RfqNoteWithMentions = ({
  stageId,
  rfqId,
  onNoteAdded,
}: {
  stageId: EntityId;
  rfqId: EntityId;
  onNoteAdded: (response: AddNoteSuccessResponse) => void;
}) => {
  const [noteText, setNoteText] = useState<string>('');
  const users = useRealUsers();

  const usersToSuggest = users.reduce((acc, { id, fullName }) => {
    if (fullName === null) return acc;
    return acc.concat([
      {
        id,
        display: fullName,
      },
    ]);
  }, new Array<{ id: EntityId; display: string }>());

  if (!usersToSuggest.length) {
    return null;
  }

  const addNote = async () => {
    const response = await new E1Request<AddNoteResponse, { text: string }>(
      Routing.generate('app_stagerfq_addnotewithmention', { id: stageId, rfqId }),
      'POST',
      { text: noteText },
    ).submit();

    if ('error' in response) {
      // Note was blank
      // Reasonable to do nothing
      return;
    }

    onNoteAdded(response);

    setNoteText('');
  };

  return (
    <div>
      <MentionsInput
        className="rfq-note-mentions-input"
        placeholder="Type a new note here"
        onChange={(event) => setNoteText(event.target.value)}
        value={noteText}
        style={{ height: 100, marginBottom: 20 }}
      >
        <Mention
          data={usersToSuggest}
          trigger="@"
          displayTransform={(id, display) => `@${display}`}
        />
      </MentionsInput>
      <Button onClick={() => addNote()}>Add New Note</Button>
    </div>
  );
};

export const renderRfqNoteWithMentionsComponent = (
  onNoteAdded: (response: AddNoteSuccessResponse) => void,
): void => {
  const container = document.getElementById('rfq-notes-with-mentions-container');
  if (container) {
    // eslint-disable-next-line fp/no-mutation
    rfqNotesRoot = createRoot(container!);
  }

  renderWithParentsFromRoot<{ stageId: string; rfqId: string }>(
    <BuilderErrorBoundary />,
    <BuilderApiProvider />,
  )(
    container,
    rfqNotesRoot,
  )(({ stageId, rfqId }) => (
    <BuilderAccountProvider>
      <RfqNoteWithMentions
        stageId={parseInt(stageId)}
        rfqId={parseInt(rfqId)}
        onNoteAdded={onNoteAdded}
      />
    </BuilderAccountProvider>
  ));
};

export const unmountRfqNoteWithMentionsComponent = (): void => {
  if (rfqNotesRoot) rfqNotesRoot.unmount();
};
