import { ApolloError, useQuery } from '@apollo/client';
import { GET_TENDERS_READY_FOR_HANDOVER } from './queries';
import {
  TenderStagesReadyForHandover,
  TenderStagesReadyForHandover_tenderStagesReadyForHandover as Stage,
} from './types/TenderStagesReadyForHandover';

type GetTendersReadyForHandoverResult = {
  loading: boolean;
  stages?: Stage[];
  error?: ApolloError | undefined;
};

export const useGetTendersReadyForHandover = (): GetTendersReadyForHandoverResult => {
  const { data, loading, error } = useQuery<TenderStagesReadyForHandover>(
    GET_TENDERS_READY_FOR_HANDOVER,
  );

  if (error) {
    return {
      loading: false,
      error,
    };
  }

  return {
    loading,
    stages: data?.tenderStagesReadyForHandover,
  };
};
