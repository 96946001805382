import { Component } from 'react';
import { GroupBase } from 'react-select';
import { Col, Row } from '@estimateone/frontend-components';
import {
  requestLists,
  requestTrades,
  TradeOrListOption,
  createTradeOptionsGroupedByDiscipline,
  createOption,
} from './utils';
import { AddNewContactFormField } from '.';
import Input from '../../../shared/Input';
import { LabelledOption, MultiSelect } from '@shared';
import { EntityId } from '@ascension/types';

type CompanyDetailsProps = {
  officePhone: string;
  officeFax: string;
  onChange: ({ name, value }: { name: AddNewContactFormField; value: string }) => void;
  selectedTradeIds: EntityId[];
  selectedCompanyListIds: EntityId[];
  onMultiSelectChange: (name: AddNewContactFormField, selectedIds: EntityId[]) => void;
};

class CompanyDetails extends Component<
  CompanyDetailsProps,
  { tradeOptions: GroupBase<LabelledOption<number>>[]; companyListOptions: TradeOrListOption[] }
> {
  constructor(props: CompanyDetailsProps) {
    super(props);
    this.state = {
      tradeOptions: [],
      companyListOptions: [],
    };
  }

  componentDidMount() {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    Promise.all([requestTrades(), requestLists()]).then((responses) => {
      const [tradesResponse, listResponse] = responses;
      this.setState({
        tradeOptions: createTradeOptionsGroupedByDiscipline(tradesResponse),
        companyListOptions: listResponse.map((list) => createOption(list)),
      });
    });
  }

  render() {
    const {
      officePhone,
      officeFax,
      onChange,
      selectedTradeIds,
      selectedCompanyListIds,
      onMultiSelectChange,
    } = this.props;

    const { tradeOptions, companyListOptions } = this.state;

    return (
      <section className="company-details">
        <Row>
          <Col span={12}>
            <h4 className="section-title">Company Details</h4>
          </Col>
        </Row>
        <Row>
          <Col span={{ md: 6, lg: 6 }} excludeBottomGutter>
            <Input
              id="officePhone"
              name="officePhone"
              type="text"
              value={officePhone}
              onChange={({ name, value }: { name: string; value: string }) =>
                onChange({ name: name as AddNewContactFormField, value })
              }
              label="Office Phone"
            />
          </Col>
          <Col span={{ md: 6, lg: 6 }} excludeBottomGutter>
            <Input
              id="officeFax"
              name="officeFax"
              type="text"
              value={officeFax}
              onChange={({ name, value }: { name: string; value: string }) =>
                onChange({ name: name as AddNewContactFormField, value })
              }
              label="Office Fax"
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <MultiSelect<EntityId>
              id="tradeIds"
              name="tradeIds"
              value={selectedTradeIds}
              onChange={({ name, selectedOptions }) =>
                onMultiSelectChange(
                  name as AddNewContactFormField,
                  selectedOptions.map(({ value }) => value),
                )
              }
              label="Trade(s)"
              options={tradeOptions}
              maxMenuHeight={280}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <MultiSelect<EntityId>
              id="companyListIds"
              name="companyListIds"
              value={selectedCompanyListIds}
              onChange={({ name, selectedOptions }) =>
                onMultiSelectChange(
                  name as AddNewContactFormField,
                  selectedOptions.map(({ value }) => value),
                )
              }
              label="Company List(s)"
              options={companyListOptions}
              maxMenuHeight={200}
            />
          </Col>
        </Row>
      </section>
    );
  }
}

export default CompanyDetails;
