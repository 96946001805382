import { useMemo } from 'react';
import { joinClassNames, Tooltip, Icon, IconName } from '@estimateone/frontend-components';
import { getLettingScheduleStatus, LettingScheduleStatus } from '../LettingScheduleStatus';
import { useCurrencyFormatter, useCurrencySymbol } from '@shared';
import { Package } from '@builder/features/ProcurementLettingSchedule/types';
import styles from './styles.scss';

type LettingScheduleStatisticsProps = {
  activePackages: Package[];
};

type LettingScheduleCounts = {
  packageCount: number;
  draftCount: number;
  draftPercent: number;
  pricingCount: number;
  pricingPercent: number;
  awardedCount: number;
  awardedPercent: number;
};

type Financials = {
  budget: number;
  budgetExists: boolean;
  actual: number;
  actualExists: boolean;
  variance: number;
  varianceExists: boolean;
};

const calculateStats = (packages: Package[]): LettingScheduleCounts => {
  const counts = packages.reduce(
    (prev, currentPackage): LettingScheduleCounts => {
      if (currentPackage.fullSet) {
        return prev;
      }

      const packageStatus = getLettingScheduleStatus(currentPackage);

      return {
        ...prev,
        packageCount: prev.packageCount + 1,
        draftCount:
          packageStatus === LettingScheduleStatus.Draft ? prev.draftCount + 1 : prev.draftCount,
        pricingCount:
          packageStatus === LettingScheduleStatus.Pricing
            ? prev.pricingCount + 1
            : prev.pricingCount,
        awardedCount:
          packageStatus === LettingScheduleStatus.Awarded
            ? prev.awardedCount + 1
            : prev.awardedCount,
      };
    },
    {
      packageCount: 0,
      draftCount: 0,
      draftPercent: 0,
      pricingCount: 0,
      pricingPercent: 0,
      awardedCount: 0,
      awardedPercent: 0,
    },
  );

  return {
    ...counts,
    draftPercent: (counts.draftCount / counts.packageCount) * 100,
    pricingPercent: (counts.pricingCount / counts.packageCount) * 100,
    awardedPercent: (counts.awardedCount / counts.packageCount) * 100,
  };
};

const useFinancialSummary = (activePackages: Package[]): Financials =>
  useMemo(() => {
    const defaultFinancials: Financials = {
      budget: 0,
      budgetExists: false,
      actual: 0,
      actualExists: false,
      variance: 0,
      varianceExists: false,
    };

    return activePackages.reduce<Financials>((currentFinancials, currentPackage) => {
      const { lettingScheduleDetails } = currentPackage || undefined;
      if (!lettingScheduleDetails) {
        return currentFinancials;
      }

      const budgetAmount = lettingScheduleDetails.budgetAmount
        ? Number(lettingScheduleDetails.budgetAmount)
        : 0;
      const actualAmount = lettingScheduleDetails.actualAmount
        ? Number(lettingScheduleDetails.actualAmount)
        : 0;
      const calculatedVariance =
        lettingScheduleDetails.budgetAmount && lettingScheduleDetails.actualAmount
          ? budgetAmount - actualAmount
          : 0;

      const varianceExists =
        currentFinancials.varianceExists ||
        !!(lettingScheduleDetails.budgetAmount && lettingScheduleDetails.actualAmount);
      const budgetExists = currentFinancials.budgetExists || !!lettingScheduleDetails.budgetAmount;
      const actualExists = currentFinancials.actualExists || !!lettingScheduleDetails.actualAmount;

      const budget = currentFinancials.budget + budgetAmount;
      const actual = currentFinancials.actual + actualAmount;
      const variance = currentFinancials.variance + calculatedVariance;

      return {
        budget,
        budgetExists,
        actual,
        actualExists,
        variance,
        varianceExists,
      };
    }, defaultFinancials);
  }, [activePackages]);

const useDisplayCounts = (activePackages: Package[]) =>
  useMemo(() => {
    const counts = calculateStats(activePackages);
    return {
      packageCount: counts.packageCount.toString(),
      draftCount: counts.draftCount === 0 ? '-' : counts.draftCount.toString(),
      draftPercent: counts.draftCount === 0 ? '' : counts.draftPercent.toFixed(1),
      pricingCount: counts.pricingCount === 0 ? '-' : counts.pricingCount.toString(),
      pricingPercent: counts.pricingCount === 0 ? '' : counts.pricingPercent.toFixed(1),
      awardedCount: counts.awardedCount === 0 ? '-' : counts.awardedCount.toString(),
      awardedPercent: counts.awardedCount === 0 ? '' : counts.awardedPercent.toFixed(1),
    };
  }, [activePackages]);

const useDisplayAmount = (amount: number, valueExists: boolean) => {
  const currencyFormatter = useCurrencyFormatter();
  return valueExists ? currencyFormatter.toString(amount) : '-';
};

export const LettingScheduleStatistics = ({ activePackages }: LettingScheduleStatisticsProps) => {
  const displayCounts = useDisplayCounts(activePackages);
  const currencySymbol = useCurrencySymbol();
  const financials = useFinancialSummary(activePackages);
  const varianceAsString = useDisplayAmount(financials.variance, financials.varianceExists);
  const varianceStyle =
    financials.variance === 0
      ? styles.statNeutral
      : financials.variance > 0
        ? styles.statSuccess
        : styles.statDanger;

  return (
    <div className={styles.statContainer}>
      <div className={styles.statGroup}>
        <div aria-label="Packages">
          <h3 className={styles.statTitle}>Packages</h3>
          <p className={styles.statCount}>{displayCounts.packageCount}</p>
        </div>
        <div aria-label="Draft">
          <h3 className={styles.statTitle}>Draft</h3>
          <p className={styles.statCount}>{displayCounts.draftCount}</p>
          {displayCounts.draftPercent && (
            <p className={styles.statPercentage}>{displayCounts.draftPercent}%</p>
          )}
        </div>
        <div aria-label="Pricing">
          <h3 className={styles.statTitle}>Pricing</h3>
          <p className={styles.statCount}>{displayCounts.pricingCount}</p>
          {displayCounts.pricingPercent && (
            <p className={styles.statPercentage}>{displayCounts.pricingPercent}%</p>
          )}
        </div>
        <div aria-label="Awarded">
          <h3 className={styles.statTitle}>Awarded</h3>
          <p className={styles.statCount}>{displayCounts.awardedCount}</p>
          {displayCounts.awardedPercent && (
            <p className={styles.statPercentage}>{displayCounts.awardedPercent}%</p>
          )}
        </div>
      </div>

      <div className={styles.statGroup}>
        <div className={styles.financialStat} aria-label="Variance Only">
          <div>
            <h3 className={styles.varianceTitle}>Variance Only</h3>
            <span
              className={styles.varianceTooltip}
              data-tip
              data-for="finance-variance-only-tooltip"
              aria-describedby="finance-variance-only-tooltip"
            >
              <Icon name={IconName.Help} />
            </span>
            <Tooltip
              text="Variance only includes packages where Budget and Actual values both exist."
              tooltipId="finance-variance-only-tooltip"
              place="top"
            />
          </div>
          <p className={joinClassNames(styles.statCount, varianceStyle)}>
            {currencySymbol && <span className={styles.statCurrencySymbol}>{currencySymbol}</span>}
            <span>{financials.variance > 0 ? `+${varianceAsString}` : varianceAsString}</span>
          </p>
        </div>
      </div>
    </div>
  );
};
