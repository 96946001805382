import { FetchResult, useMutation, useQuery } from '@apollo/client';
import useFlashMessage from '@shared/Util/useFlashMessage';
import { useAccountType, useInCountry } from '@subbie/context/AccountProvider/hooks';
import useApolloCache from '@ascension/components/hooks/ApolloCache';
import { GET_PROJECT_INTERESTED_SUPPLIERS, GET_PROJECT_SUPPLIER_SUMMARY } from './queries';
import { VIEW_SUPPLIER_ON_PROJECT } from './mutations';
import { Country } from '@ascension/enums';
import { EntityId } from '../../types';
import {
  ViewSupplierOnProject,
  ViewSupplierOnProjectVariables,
  ViewSupplierOnProject_viewSupplierOnProject as SupplierOnProjectOrErrors,
} from './types/ViewSupplierOnProject';
import { AccountType } from '@ascension/_gqltypes/subbie.generated';
import {
  ProjectInterestedSuppliers as ProjectInterestedSuppliersPayload,
  ProjectInterestedSuppliers_projectInterestedSuppliers as ProjectInterestedSuppliers,
  ProjectInterestedSuppliersVariables,
} from '@subbie/modal/ProjectSlider/ProjectSliderBody/ProjectSuppliers/types/ProjectInterestedSuppliers';
import {
  ProjectSupplierSummary as ProjectSupplierSummaryPayload,
  ProjectSupplierSummary_projectSupplierSummary as ProjectSupplierSummary,
  ProjectSupplierSummaryVariables,
} from '@subbie/modal/ProjectSlider/ProjectSliderBody/ProjectSuppliers/types/ProjectSupplierSummary';

export type ProjectSupplierSummaryResult = {
  readonly summary: ProjectSupplierSummary | undefined;
  readonly loading: boolean;
};

export const useProjectSupplierSummary = (projectId: EntityId): ProjectSupplierSummaryResult => {
  const accountType = useAccountType();
  const isAu = useInCountry(Country.COUNTRY_AUSTRALIA);
  const isAustralianSubbie = accountType === AccountType.SUBCONTRACTOR && isAu;

  const { data, loading } = useQuery<
    ProjectSupplierSummaryPayload,
    ProjectSupplierSummaryVariables
  >(GET_PROJECT_SUPPLIER_SUMMARY, {
    variables: { projectId },
    skip: !isAustralianSubbie || projectId <= 0,
  });

  return {
    summary: data?.projectSupplierSummary,
    loading,
  };
};

export const useProjectInterestedSuppliers = (
  projectId: EntityId,
): ProjectInterestedSuppliers[] | undefined => {
  const { warning: showWarning } = useFlashMessage();

  const accountType = useAccountType();
  const isAu = useInCountry(Country.COUNTRY_AUSTRALIA);
  const isAustralianSubbie = accountType === AccountType.SUBCONTRACTOR && isAu;
  const { data, loading } = useQuery<
    ProjectInterestedSuppliersPayload,
    ProjectInterestedSuppliersVariables
  >(GET_PROJECT_INTERESTED_SUPPLIERS, {
    variables: { projectId },
    skip: !isAustralianSubbie || projectId <= 0,
  });

  if (!loading && data && data.projectInterestedSuppliers === null) {
    showWarning({
      title: 'Project Not Found',
      message: `Project ${projectId} could not be found - it may be private, or all builders may have withdrawn`,
    });
  }

  return data?.projectInterestedSuppliers ?? undefined;
};

export const useViewSupplierContactDetails = (projectId: GraphQLEntityId) => {
  const { updateElementsInCache } = useApolloCache();

  const [viewSupplierOnProject] = useMutation<
    ViewSupplierOnProject,
    ViewSupplierOnProjectVariables
  >(VIEW_SUPPLIER_ON_PROJECT, {
    update(_, { data }: Omit<FetchResult<ViewSupplierOnProject>, 'context'>) {
      if (data) {
        const { viewSupplierOnProject: d } = data;
        if (d.__typename === 'ProjectInterestedSupplier') {
          updateElementsInCache(
            'projectInterestedSuppliers',
            [d],
            ({ id }: ProjectInterestedSuppliers) => id === d.id,
          )<ProjectInterestedSuppliersPayload>({ query: GET_PROJECT_INTERESTED_SUPPLIERS });
        }
      }
    },
  });

  const viewSupplierContact = async (
    supplierAccountId: GraphQLEntityId,
  ): Promise<SupplierOnProjectOrErrors> => {
    const result = await viewSupplierOnProject({
      variables: {
        input: {
          supplierAccountId,
          projectId,
        },
      },
    });

    if (!result.data) {
      return {
        __typename: 'Errors',
        errors: [
          {
            __typename: 'Error',
            message: 'Failed to view supplier contact details',
          },
        ],
      };
    }
    return result.data.viewSupplierOnProject;
  };

  return viewSupplierContact;
};
