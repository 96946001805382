import moment from 'moment-timezone';
import LicenseRow from './LicenseRow';
import { ActiveLicense, SharedLicense } from '../shared/hooks';
import {
  getHasAdditionalStateDiscount,
  getPaidLicenseProviderName,
  getProductName,
  getRenewDate,
  isAdditionalState,
} from '../shared/utils';
import { BillingCycle, BillingCycleText } from '@ascension/enums';
import { AccountType } from '@ascension/_gqltypes/subbie.generated';

type LicenseRowsWrapperProps = {
  accountType: AccountType;
  isMultiState: boolean;
  hasAtLeastOneFullPriceActiveMultiStateLicense: boolean;
  paidLicenseProviderName?: string;
  licenses: ActiveLicense[] | SharedLicense[];
  showSharedLicenseAccountName?: boolean;
};

const LicenseRowsWrapper = ({
  accountType,
  isMultiState,
  hasAtLeastOneFullPriceActiveMultiStateLicense,
  paidLicenseProviderName,
  licenses,
  showSharedLicenseAccountName = false,
}: LicenseRowsWrapperProps) => (
  <>
    {licenses.map((license) => {
      const {
        id,
        product: { id: productId, tag: productTag, name: productName, pricingOptions },
        billingCycle,
        activatedAt,
        expiredAt,
        isAutoRenew,
        canRenew,
        price: {
          amount: licensePrice,
          currency: { code },
        },
        creditCard,
        discount,
        isDiscontinued,
      } = license;

      const productPrice = pricingOptions.filter(
        (pricingOption) => pricingOption.billingCycle === billingCycle,
      )[0]?.price?.amount;

      const hasAdditionalLicenseDiscount = getHasAdditionalStateDiscount(
        hasAtLeastOneFullPriceActiveMultiStateLicense,
        isMultiState,
        licensePrice,
        productPrice,
      );

      const activatedAtString = activatedAt ? moment(activatedAt).format('DD MMM YY') : '-';

      const discountExpiryDescriptionWithTax =
        discount?.isActive && discount?.expiryDescriptionWithTax
          ? discount?.expiryDescriptionWithTax
          : null;

      const upgradeProductId =
        (accountType === AccountType.SUPPLIER || accountType === AccountType.SUBCONTRACTOR) &&
        canRenew &&
        billingCycle === BillingCycle.Months1
          ? productId
          : undefined;

      const sharedLicenseAccountName = showSharedLicenseAccountName
        ? license.account.name
        : undefined;

      const creditCardLastFourDigitsText =
        creditCard && (creditCard.isExpired ? `${creditCard.number} (Expired)` : creditCard.number);

      return (
        <LicenseRow
          key={`license-${id}`}
          productName={getProductName(productTag, productName)}
          licenseCurrency={code}
          licensePrice={licensePrice}
          productPrice={productPrice}
          activatedAt={activatedAtString}
          billingCycle={BillingCycleText[billingCycle as BillingCycle] || '-'}
          creditCardLastFourDigitsText={creditCardLastFourDigitsText ?? ''}
          renewDate={getRenewDate(accountType, isAutoRenew, expiredAt ?? undefined)}
          hasAdditionalLicenseDiscount={hasAdditionalLicenseDiscount}
          isDiscountActive={discount?.isActive ?? false}
          discountExpiryDescriptionWithTax={discountExpiryDescriptionWithTax ?? undefined}
          upgradeProductId={upgradeProductId}
          isAdditionalState={isAdditionalState(isMultiState, licensePrice, productPrice)}
          discountAdjustedAmount={discount?.adjustedAmount ?? 0}
          creditCardId={creditCard?.id}
          paidLicenseProviderName={
            getPaidLicenseProviderName(licensePrice, paidLicenseProviderName) ?? undefined
          }
          sharedLicenseAccountName={sharedLicenseAccountName}
          isDiscontinued={isDiscontinued ?? false}
        />
      );
    })}
  </>
);

export default LicenseRowsWrapper;
