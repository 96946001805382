import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import Routing from 'routing';
import { Col, Modal, ModalSize, Row } from '@estimateone/frontend-components';
import { LOCAL_STORAGE_CON_DEET_RETURN_PROJECT_ID } from '../ProjectSlider/const';
import BillingCycleSelection from './BillingCycleSelection';
import Confirmation from './Confirmation';
import PaymentDetails, { BillingContact } from './PaymentDetails';
import { PurchaseModalSteps } from './enum';
import { ErrorObject, getErrorMessages, transformInputForPurchase } from './utils';
import { useAccountUserList } from '@subbie/context/AccountProvider/hooks';
import { Action, Event, useAnalytics } from '../../../hooks/Analytics';
import useLocalStorage from '../../../hooks/LocalStorage';
import {
  GET_CHECKOUT_DATA,
  PURCHASE_ADDON_EFT,
  PURCHASE_ADDON_EXISTING_CC,
  PURCHASE_ADDON_NEW_CC,
} from './queries';
import { BillingCycle, PaymentMethod } from '@ascension/enums';
import { SourceTrackingToken } from '@ascension/enums/Analytics';
import { GetCheckoutData, GetCheckoutDataVariables } from './types/GetCheckoutData';
import {
  PurchaseAddonWithEFT,
  PurchaseAddonWithEFTVariables,
  PurchaseAddonWithEFT_addonPurchaseData_License as License,
} from './types/PurchaseAddonWithEFT';
import {
  PurchaseAddonWithExistingCC,
  PurchaseAddonWithExistingCCVariables,
} from './types/PurchaseAddonWithExistingCC';
import {
  PurchaseAddonWithNewCC,
  PurchaseAddonWithNewCCVariables,
} from './types/PurchaseAddonWithNewCC';
import {
  PurchaseAddonExistingCCInput,
  PurchaseAddonNewCCInput,
} from '@ascension/_gqltypes/subbie.generated';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

type Invoice = NonNullable<License['invoiceItem']>['invoice'];

type CompletedPaymentData = Pick<PurchaseAddonNewCCInput, 'secureCard'> &
  Pick<PurchaseAddonExistingCCInput, 'terms' | 'creditCardId'> & { paymentMethod: PaymentMethod };

type InitialPaymentData = Omit<CompletedPaymentData, 'creditCardId'> & {
  creditCardId: number | undefined;
};

export type PaymentData = InitialPaymentData | CompletedPaymentData;

const initialPaymentData: InitialPaymentData = {
  creditCardId: undefined,
  secureCard: null,
  terms: false,
  paymentMethod: PaymentMethod.CC,
};

type PurchaseAddonModalProps = {
  addon: {
    id: EntityId;
    name: string;
  };
  showModal: boolean;
  onClose: () => void;
  source?: SourceTrackingToken;
};

const PurchaseAddonModal = ({ addon, showModal, onClose, source }: PurchaseAddonModalProps) => {
  const [step, setStep] = useState(PurchaseModalSteps.BillingCycleSelection);
  const [billingCycle, setBillingCycle] = useState(BillingCycle.Months12);
  const [paymentData, setPaymentData] = useState(initialPaymentData);
  const [billingContact, setBillingContact] = useState<{ id: EntityId; fullName: string }>();
  const [isPurchaseLoading, setIsPurchaseLoading] = useState(false);
  const [purchaseErrors, setPurchaseErrors] = useState<ErrorObject>({});
  const [invoice, setInvoice] = useState<Invoice | null>(null);
  const accountUsers = useAccountUserList();

  const { get: getReturnProjectId, remove: removeReturnProjectId } = useLocalStorage<EntityId>(
    LOCAL_STORAGE_CON_DEET_RETURN_PROJECT_ID,
  );

  const { id: addonId, name: addonName } = addon;
  const { data: checkoutData } = useQuery<GetCheckoutData, GetCheckoutDataVariables>(
    GET_CHECKOUT_DATA,
    {
      skip: !showModal,
      variables: { productId: addonId },
    },
  );

  const [purchaseAddonEFT, { data: purchaseDataEFT }] = useMutation<
    PurchaseAddonWithEFT,
    PurchaseAddonWithEFTVariables
  >(PURCHASE_ADDON_EFT);

  const [purchaseAddonNewCC, { data: purchaseDataNewCC }] = useMutation<
    PurchaseAddonWithNewCC,
    PurchaseAddonWithNewCCVariables
  >(PURCHASE_ADDON_NEW_CC);

  const [purchaseAddonExistingCC, { data: purchaseDataExistingCC }] = useMutation<
    PurchaseAddonWithExistingCC,
    PurchaseAddonWithExistingCCVariables
  >(PURCHASE_ADDON_EXISTING_CC);

  const { addEvent } = useAnalytics(Event.INTERACT);

  useEffect(() => {
    if (showModal) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      (async () =>
        addEvent({
          action: Action.ADDON_PURCHASE_MODAL_SHOW,
          addonId,
        }))();
    }
  }, [addEvent, addonId, showModal]);

  useEffect(() => {
    // Checks that one of the mutations have fired
    const mutationResult =
      purchaseDataNewCC || purchaseDataEFT || purchaseDataExistingCC || undefined;
    if (mutationResult) {
      setIsPurchaseLoading(false);

      switch (mutationResult.addonPurchaseData.__typename) {
        case 'Errors':
          setPurchaseErrors(getErrorMessages(mutationResult.addonPurchaseData.errors));
          break;
        case 'License':
          setInvoice(mutationResult.addonPurchaseData.invoiceItem?.invoice ?? null);
          setStep(PurchaseModalSteps.Confirmation);
          break;
        default:
          break;
      }
    }
  }, [purchaseAddonNewCC, purchaseDataEFT, purchaseDataExistingCC, purchaseDataNewCC]);

  if (!checkoutData) return null;

  const {
    supportDetails,
    pricingOptions,
    currentUser: { account },
  } = checkoutData;

  if (!account) return null;

  const [monthlyPrice, annualPrice] = [BillingCycle.Months1, BillingCycle.Months12].map(
    (bc) =>
      (pricingOptions || []).find(({ billingCycle: cycle }) => cycle === bc)?.priceExGST?.amount,
  );

  const handlePurchase = async () => {
    if (!billingContact) {
      return undefined;
    }

    const { paymentMethod } = paymentData;
    const input = transformInputForPurchase({
      addonId,
      billingCycle,
      userId: billingContact.id,
      ...paymentData,
    });

    setIsPurchaseLoading(true);

    if (paymentMethod === PaymentMethod.EFT) {
      return purchaseAddonEFT({ variables: { input } });
    }

    if (paymentMethod === PaymentMethod.CC) {
      if ('creditCardId' in input) {
        return purchaseAddonExistingCC({ variables: { input } });
      }
      if ('secureCard' in input) {
        // required for type narrowing
        return purchaseAddonNewCC({ variables: { input } });
      }
    }

    return undefined;
  };

  const handleNavigatePreviousPage = () => {
    setPaymentData(initialPaymentData);
    setPurchaseErrors({});
    setStep(PurchaseModalSteps.BillingCycleSelection);
  };

  const onConfirmationClose = () => {
    const returnProjectId = getReturnProjectId();
    removeReturnProjectId();
    if (invoice && invoice.isPaid) {
      if (returnProjectId) {
        window.location.assign(`${document.referrer}?projectId=${returnProjectId}`);
      } else {
        window.location.reload();
      }
    } else {
      window.location.assign(Routing.generate('app_account_invoices'));
    }
  };

  const isAnnual = billingCycle === BillingCycle.Months12;
  const price = isAnnual ? annualPrice : monthlyPrice;

  const isBillingCycleSelection = step === PurchaseModalSteps.BillingCycleSelection;
  const isPaymentForm = step === PurchaseModalSteps.PaymentForm;
  const isConfirmation = step === PurchaseModalSteps.Confirmation;

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={isConfirmation ? onConfirmationClose : onClose}
      size={ModalSize.Small}
    >
      <Modal.Section>
        <Row>
          <Col span={8} offset={2}>
            <div className={styles.container}>
              {!isConfirmation && <h3 className={styles.addonName}>{addonName} add-on</h3>}
              {isBillingCycleSelection &&
                annualPrice !== undefined &&
                monthlyPrice !== undefined && (
                  <BillingCycleSelection
                    onAdvancePage={() => setStep(PurchaseModalSteps.PaymentForm)}
                    pricingOptions={{ annualPrice, monthlyPrice }}
                    setBillingCycle={setBillingCycle}
                    billingCycle={billingCycle}
                  />
                )}
              {isPaymentForm && price && (
                <PaymentDetails
                  billingCycle={billingCycle}
                  price={price}
                  onPreviousPage={handleNavigatePreviousPage}
                  creditCards={account.activeCreditCards}
                  paymentData={paymentData}
                  setPaymentData={setPaymentData}
                  billingContacts={(accountUsers || []).reduce(
                    (acc, { id, fullName }) => (fullName ? acc.concat([{ id, fullName }]) : acc),
                    new Array<BillingContact>(),
                  )}
                  setBillingContact={setBillingContact}
                  selectedBillingContact={billingContact}
                  onSubmit={handlePurchase}
                  isSubmitDisabled={isPurchaseLoading}
                  purchaseErrors={purchaseErrors}
                />
              )}
              {isConfirmation && invoice && invoice.billingContact?.email && (
                <Confirmation
                  addonName={addonName}
                  billingContactEmail={invoice.billingContact.email}
                  invoiceId={invoice.id}
                  isPaid={invoice.isPaid}
                  onClose={onConfirmationClose}
                  supportDetails={supportDetails}
                  source={source}
                />
              )}
            </div>
          </Col>
        </Row>
      </Modal.Section>
    </Modal>
  );
};

export default PurchaseAddonModal;
