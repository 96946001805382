import { Button, ButtonVariant } from '@estimateone/frontend-components';
import styles from './ModalFooter.module.scss';

export const ModalFooter = ({
  close,
  hasValidationErrors,
  saveButtonText = 'Save',
  cancelButtonText = 'Cancel',
}: {
  close: () => void;
  hasValidationErrors?: boolean;
  saveButtonText?: string;
  cancelButtonText?: string;
}) => (
  <div className={styles.editModalButtonsWrapper}>
    <Button className={styles.cancelButton} variant={ButtonVariant.Grey} fullWidth onClick={close}>
      {cancelButtonText}
    </Button>
    <Button variant={ButtonVariant.Primary} fullWidth type="submit" disabled={hasValidationErrors}>
      {saveButtonText}
    </Button>
  </div>
);
