import { Slider } from '@estimateone/frontend-components';
import { InviteToQuoteForm } from './InviteToQuoteForm';
import { GetAddressBookCompany_company as CompanySelectOption } from './InviteToQuoteForm/types/GetAddressBookCompany';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

type InviteToQuoteSlideoutProps = {
  stageId: EntityId | undefined;
  isOpen: boolean;
  onRequestClose: (addedRfqId: EntityId | undefined) => void;
  preselectedPackageId: EntityId | undefined;
  preselectedCompany: CompanySelectOption | undefined;
};

export const InviteToQuoteSlideout = ({
  stageId,
  isOpen,
  onRequestClose,
  preselectedPackageId,
  preselectedCompany,
}: InviteToQuoteSlideoutProps) => (
  <Slider
    isOpen={isOpen}
    onRequestClose={() => onRequestClose(undefined)}
    overlayClassName="helper-aware"
  >
    <Slider.Header>
      <h1 className={styles.sliderHeading}>Invite to Quote</h1>
    </Slider.Header>
    <InviteToQuoteForm
      stageId={stageId}
      onFormSuccess={onRequestClose}
      preselectedPackageId={preselectedPackageId}
      preselectedCompany={preselectedCompany}
    />
  </Slider>
);
