import ReactSelect, { SingleValue } from 'react-select';
import reactSelectStyles from './reactSelectStyles';
import { Package } from '../../../context/BuilderQuotesProvider';
import { EntityId } from '../../../../../types';

const packageToOption = ({ id: value, title: label }: Package): PackageOption => ({ value, label });

type InlinePackageSelectorProps = {
  packages: Package[];
  selectedPackageId?: EntityId;
  onSelectPackageId: (id: EntityId) => void;
  height?: number;
};

type PackageOption = { label: string; value: EntityId };

const InlinePackageSelector = ({
  packages,
  selectedPackageId,
  onSelectPackageId,
  height = 24,
}: InlinePackageSelectorProps) => {
  const options = packages.map(packageToOption);
  const selectedOption = options.find(({ value }) => value === selectedPackageId) || null;

  return (
    <ReactSelect
      className="reactSelectContainer"
      classNamePrefix="reactSelect"
      onChange={(selectedPackage: SingleValue<PackageOption>) =>
        onSelectPackageId((selectedPackage as PackageOption).value)
      }
      options={options}
      value={selectedOption}
      placeholder="Select package"
      styles={reactSelectStyles(height)}
    />
  );
};

export default InlinePackageSelector;
