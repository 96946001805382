import { useEffect, useMemo, useState } from 'react';
import { getLettingScheduleStatus } from '../LettingScheduleStatus';
import { Package } from '@builder/features/ProcurementLettingSchedule/types';

export type FilterApi = {
  statusFilter: number[];
  setStatusFilter: (filters: number[]) => void;
  priorityFilter: string[];
  setPriorityFilter: (filters: string[]) => void;
  packageNameFilter: string | undefined;
  setPackageNameFilter: (searchText: string) => void;
  assignedToFilter: (number | 'none')[];
  setAssignedToFilter: (filters: (number | 'none')[]) => void;
};

export type FilteredPackages = [filteredPackages: Package[], filterApi: FilterApi];

type SessionStorageKeys = {
  statusFilterKey: string;
  priorityFilterKey: string;
  assignedToFilterKey: string;
};

export const useFilteredPackages = (packages: Package[], stageId: number): FilteredPackages => {
  const [statusFilter, setStatusFilter] = useState<number[]>([]);
  const [priorityFilter, setPriorityFilter] = useState<string[]>([]);
  const [packageNameFilter, setPackageNameFilter] = useState<string | undefined>(undefined);
  const [assignedToFilter, setAssignedToFilter] = useState<(number | 'none')[]>([]);

  const keys: SessionStorageKeys = {
    statusFilterKey: `${stageId}_statusFilter`,
    priorityFilterKey: `${stageId}_priorityFilter`,
    assignedToFilterKey: `${stageId}_assignedToFilter`,
  };

  useEffect(() => {
    const savedStatusFilter = sessionStorage.getItem(keys.statusFilterKey);
    const savedPriorityFilter = sessionStorage.getItem(keys.priorityFilterKey);
    const savedAssignedToFilter = sessionStorage.getItem(keys.assignedToFilterKey);

    if (savedStatusFilter) {
      setStatusFilter(JSON.parse(savedStatusFilter));
    }
    if (savedPriorityFilter) {
      setPriorityFilter(JSON.parse(savedPriorityFilter));
    }
    if (savedAssignedToFilter) {
      setAssignedToFilter(JSON.parse(savedAssignedToFilter));
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem(keys.statusFilterKey, JSON.stringify(statusFilter || []));
    sessionStorage.setItem(keys.priorityFilterKey, JSON.stringify(priorityFilter || []));
    sessionStorage.setItem(keys.assignedToFilterKey, JSON.stringify(assignedToFilter || []));
  }, [statusFilter, priorityFilter, assignedToFilter]);

  const filteredPackages = useMemo(() => {
    const filterByPackageStatus = (pkg: Package) =>
      statusFilter.length > 0 ? statusFilter.includes(getLettingScheduleStatus(pkg)) : true;

    const filterByPackagePriority = (pkg: Package) =>
      priorityFilter.length > 0 && pkg.lettingScheduleDetails
        ? pkg.lettingScheduleDetails?.priority &&
          priorityFilter.includes(pkg.lettingScheduleDetails.priority)
        : true;

    const filterByPackageName = (pkg: Package) =>
      packageNameFilter && packageNameFilter.trim().length >= 2
        ? pkg.title.toLocaleLowerCase().indexOf(packageNameFilter.trim().toLocaleLowerCase()) > -1
        : true;

    const filterByAssignedTo = (pkg: Package) =>
      assignedToFilter.length > 0 && pkg.lettingScheduleDetails
        ? assignedToFilter.includes(pkg.lettingScheduleDetails.assignedToUser?.id ?? 'none')
        : true;

    return packages
      .filter(filterByPackageStatus)
      .filter(filterByPackagePriority)
      .filter(filterByPackageName)
      .filter(filterByAssignedTo);
  }, [packages, packageNameFilter, statusFilter, priorityFilter, assignedToFilter]);

  return [
    filteredPackages,
    {
      statusFilter,
      setStatusFilter,
      priorityFilter,
      setPriorityFilter,
      packageNameFilter,
      setPackageNameFilter,
      assignedToFilter,
      setAssignedToFilter,
    },
  ];
};
