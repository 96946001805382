import { ChangeEvent } from 'react';
import { Icon, IconName, TextInput, TextInputSize } from '@estimateone/frontend-components';
import styles from './styles.scss';

type PackageNameFilterInputProps = {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

const PackageNameFilter = ({ onChange }: PackageNameFilterInputProps) => (
  <TextInput
    id="filter-package-name"
    className={styles.scheduleFilter}
    label="Search"
    placeholder="Search for a Package"
    onChange={onChange}
    size={TextInputSize.Small}
    endAdornment={<Icon name={IconName.Search} />}
  />
);

export { PackageNameFilter };
