import { escape, trim } from 'lodash';
import { AddressBookDataSource } from '@ascension/enums/AddressBookDataSource';

type User = {
  firstName: string;
  lastName: string;
  phone?: string;
};

type AddressBookContact = {
  alternativeName: string | null;
  firstName: string | null;
  lastName: string | null;
  source: number;
  user: User | null;
  phone: string | null;
};

export function isProfileSource(source: AddressBookDataSource): boolean {
  const profileSources = [
    AddressBookDataSource.PROFILE,
    AddressBookDataSource.NETWORK_INVITATION_PROFILE,
    AddressBookDataSource.BUILDER_AND_PROFILE,
  ];

  return profileSources.includes(source);
}

export function getFullName(
  contact: Pick<
    AddressBookContact,
    'firstName' | 'lastName' | 'alternativeName' | 'user' | 'source'
  >,
): string {
  const { firstName, lastName, alternativeName, user, source } = contact;

  // Prefer user details if source is profile
  // TODO: FE should not need to care about source
  // user can't really be null if source is profile(?)
  if (isProfileSource(source) && user !== null) {
    const altNameParens = alternativeName ? ` (${alternativeName})` : '';
    const fullName = trim(`${user?.firstName} ${user?.lastName}`);
    return escape(`${fullName}${altNameParens}`);
  }

  return escape(trim(`${firstName ?? ''} ${lastName ?? ''}`));
}

export function getFirstName(
  contact: Pick<AddressBookContact, 'firstName' | 'source' | 'user'>,
): string {
  const { firstName, source, user } = contact;

  // Prefer user details if source is profile
  if (isProfileSource(source)) {
    return escape(user?.firstName ?? firstName ?? '');
  }

  return escape(`${firstName ?? ''}`);
}

export function getLastName(
  contact: Pick<AddressBookContact, 'lastName' | 'source' | 'user'>,
): string {
  const { lastName, source, user } = contact;

  // Prefer user details if source is profile
  if (isProfileSource(source)) {
    return escape(user?.lastName ?? lastName ?? '');
  }

  return escape(`${lastName ?? ''}`);
}

export function getPhone(
  contact: Pick<AddressBookContact, 'phone' | 'source' | 'user'>,
): string | null {
  const { phone, source, user } = contact;

  // Prefer user details if source is profile
  if (isProfileSource(source)) {
    return escape(user?.phone ?? phone ?? '');
  }

  return phone ? escape(phone) : null;
}
