import { useMutation } from '@apollo/client';
import { ADD_OFFICE_DETAILS } from './mutations';
import { AddOfficeDetailsMutation, AddOfficeDetailsMutationVariables } from './mutations.generated';
import { OfficeDetailsFieldValues, OfficeDetailsFieldNames } from '../OfficeDetailsFields/types';

const getServiceAreaObject = (
  values: OfficeDetailsFieldValues,
): AddOfficeDetailsMutationVariables['officeDetails']['serviceArea'] => {
  switch (values.serviceAreaType) {
    case 'by-country':
      return {
        countryId: values.serviceAreaCountry,
      };
    case 'by-states':
      return {
        boundaryIds: values.serviceAreaStates
          .map((state) => state?.value)
          .filter((value): value is number => value !== undefined),
      };
    case 'by-travel-distance':
      return {
        radius: values.serviceAreaRadius,
      };
    default:
      throw new Error('Unknown service area type');
  }
};

const transformValues = (values: OfficeDetailsFieldValues): AddOfficeDetailsMutationVariables => ({
  officeDetails: {
    phone: values[OfficeDetailsFieldNames.PhoneNumber] ?? null,
    website: values[OfficeDetailsFieldNames.Website] ?? null,
    email: values[OfficeDetailsFieldNames.OfficeEmail] ?? null,
    address: {
      address1: values.streetAddress ?? '',
      suburb: values.suburb ?? '',
      stateId: parseInt(values.state?.value?.toString() || '0', 10) || null,
      postcode: values.postcode ?? '',
      city: values.city ?? '',
      province: values.province ?? '',
    },
    serviceArea: getServiceAreaObject(values),
  },
});

export const useSubmitForm = () => {
  const [updateOfficeAddress, { data }] = useMutation<
    AddOfficeDetailsMutation,
    AddOfficeDetailsMutationVariables
  >(ADD_OFFICE_DETAILS);

  const errors =
    data?.addOfficeDetailsV2.__typename === 'Errors' ? data.addOfficeDetailsV2.errors : undefined;

  const submit = async (values: OfficeDetailsFieldValues) => {
    const { data: result } = await updateOfficeAddress({
      variables: transformValues(values),
    });

    return result?.addOfficeDetailsV2.__typename !== 'Errors';
  };

  return { submit, data: data?.addOfficeDetailsV2, errors };
};
