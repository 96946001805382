import { StageStatus } from '@ascension/enums';
import { Stage } from '@subbie/modal/ProjectSlider/types';

const transformStatusOrder = (status: Stage['status']) =>
  status < StageStatus.TENDER_LOST ? status + StageStatus.TENDER_WITHDRAWN : status;

type SortingType = { builderDetails: null | { name: string } | { isIncognito: boolean } };

const isIncognito = (account: SortingType['builderDetails']): account is { isIncognito: boolean } =>
  (account as { isIncognito: boolean })?.isIncognito !== undefined;

export const sortStages = (
  { status: statusA, builderDetails: accountA }: Pick<Stage, 'status'> & SortingType,
  { status: statusB, builderDetails: accountB }: Pick<Stage, 'status'> & SortingType,
) =>
  transformStatusOrder(statusB) - transformStatusOrder(statusA) ||
  (() => {
    // name should be before incognito, which should be before no name
    if (!isIncognito(accountA) && !isIncognito(accountB)) {
      if (accountA?.name === accountB?.name) return 0;
      if (!accountA?.name) return 1;
      if (!accountB?.name) return -1;
      return accountA.name.localeCompare(accountB.name);
    }
    if (isIncognito(accountA) && !isIncognito(accountB)) return accountB?.name ? 1 : -1;
    if (isIncognito(accountB) && !isIncognito(accountA)) return accountA?.name ? -1 : 1;
    return 0;
  })();
