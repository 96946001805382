import { useState, useEffect } from 'react';
import { Col, Fieldset, FieldWrapper, Row } from '@estimateone/frontend-components';
// eslint-disable-next-line import/no-unresolved
import { eWaySecureFieldEvent } from '@ascension/js/custom';
import { getEwayFields } from './fields';
import { useEwayKey } from '@subbie/context/EwayProvider/hooks';
import { CreditCardFormErrors, EwayPaymentData } from './types';
import styles from './styles.scss';

const EwaySecureFieldsForm = ({
  updatePaymentData,
  errors = {},
}: {
  updatePaymentData: (pd: Partial<EwayPaymentData>) => void;
  errors: CreditCardFormErrors;
}) => {
  const [secureCardToken, setSecureCardToken] = useState<string>();
  const [iFramesReady, setIFramesReady] = useState(false);
  const [iFramesValid, setIFramesValid] = useState(false);

  const fieldsInitialState = {
    name: false,
    card: false,
    expiry: false,
    expirytext: true,
    cvn: false,
  };

  const [validFields, setValidFields] = useState(fieldsInitialState);
  const [changedFields, setChangedFields] = useState(fieldsInitialState);

  const eWayPublicKey = useEwayKey();
  const fields = eWayPublicKey ? getEwayFields(eWayPublicKey) : {};

  const hasFieldError = (fieldName: keyof typeof validFields) =>
    changedFields[fieldName] && !validFields[fieldName];

  const eWaySecureFieldCallback = ({
    fieldValid,
    secureFieldCode,
    targetField,
    valueIsValid,
    valueIsSaved,
    errors: callbackErrors,
  }: eWaySecureFieldEvent) => {
    if (!fieldValid) {
      throw new Error(callbackErrors);
    }

    setChangedFields((prev) => ({ ...prev, [targetField]: valueIsSaved }));
    setValidFields((prev) => ({ ...prev, [targetField]: valueIsValid }));

    setSecureCardToken(secureFieldCode);
  };

  const invalidateIframes = () => {
    const fieldValues = Object.values(fields);
    fieldValues.forEach(({ fieldDivId }) =>
      document.querySelector(`#${fieldDivId} > iframe`)?.remove(),
    );

    setChangedFields(fieldsInitialState);
    setValidFields(fieldsInitialState);

    setIFramesValid(false);
    setIFramesReady(false);
  };

  useEffect(() => {
    if (!errors.globalCreditCard || !iFramesValid) return;

    invalidateIframes();
  }, [errors]);

  useEffect(() => {
    if (iFramesReady) return; // Prevent duplicate iFrames

    const fieldValues = Object.values(fields);
    fieldValues.forEach((config) => eWAY.setupSecureField(config, eWaySecureFieldCallback));

    setIFramesReady(true);
    setIFramesValid(true);
  }, [fields, iFramesReady]);

  useEffect(() => {
    if (Object.values(validFields).every((_) => _)) {
      updatePaymentData({ secureCard: { secureCardToken: secureCardToken ?? '' } });
    } else {
      updatePaymentData({ secureCard: { secureCardToken: '' } });
    }
  }, [secureCardToken, validFields, iFramesValid]);

  return (
    <div className={styles.creditCardForm}>
      {errors.globalCreditCard && (
        <div className="alert alert-danger">{errors.globalCreditCard}</div>
      )}
      <Row>
        <Col span="auto" excludeBottomGutter>
          <p className={styles.acceptedCards}>
            We accept <strong className="info">Visa</strong>,{' '}
            <strong className="info">Mastercard</strong> and <strong className="info">AMEX</strong>{' '}
            payments. We don&apos;t charge any credit card surcharge fees.
          </p>
        </Col>
      </Row>
      <Row>
        {Object.entries(fields).map(([name, { fieldDivId, fieldType }]) => (
          <Col key={fieldDivId} span="auto">
            <Fieldset legend={name}>
              <FieldWrapper errors={hasFieldError(fieldType) ? [`${name} is not valid`] : []}>
                <div className={styles.ewayIframeWrapper}>
                  <div id={fieldDivId} />
                </div>
              </FieldWrapper>
            </Fieldset>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default EwaySecureFieldsForm;
