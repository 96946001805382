import { useDocSyncQuery } from './useDocSyncQuery';
import { GET_PROCORE_PROJECTS } from '../queries';
import { projects as Projects } from '../types/projects';

export const useGetProcoreProjects = () => {
  const { data, loading } = useDocSyncQuery<Projects>(GET_PROCORE_PROJECTS);

  return {
    projects: data?.projects,
    loading,
  };
};
