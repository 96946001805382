import { Tag as FECTag, TagVariant } from '@estimateone/frontend-components';
import styles from '../TagGroup.module.scss';

type TagGroupTagProps = {
  text: string;
};
export const Tag = ({ text }: TagGroupTagProps) => (
  <FECTag className={styles.tag} text={text} variant={TagVariant.Practical025} />
);
// eslint-disable-next-line fp/no-mutation
Tag.displayName = 'TagGroup.Tag';
