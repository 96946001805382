import { useForm } from 'react-final-form';
import { SingleValue } from 'react-select';
import { Button, DetailsCard, Slider } from '@estimateone/frontend-components';
import ScreenReaderOnly from '@ascension/components/fec/ScreenReaderOnly';
import {
  AddressBookCompanySelectField,
  AddressBookCompanyOption,
} from '@builder/common/FinalForm/AddressBookCompanySelectField';
import {
  PackageSelectField,
  PackageSelectOption,
} from '@builder/common/FinalForm/PackageSelectField';
import { IncludeScopeOfWorksField } from '@scope-of-works/features/IncludeScopeOfWorksField';
import { StageSelect, StageSelectOption } from '../../SelectStage';
import { AddressBookContactsField } from './AddressBookContactsField';
import { CorrespondenceTypeField } from './CorrespondenceTypeField/CorrespondenceTypeField';
import { DueDateField } from './DueDateField';
import { TenderResponses } from './TenderResponses';
import GlobalErrorList from '@shared/FinalForm/GlobalErrorList';
import LoadingSpinner from '@shared/LoadingSpinner';
import { InviteToQuoteFormFieldName } from '../enums';
import { GetInviteToQuoteStage_stage_UnrestrictedStage as Stage } from '../types/GetInviteToQuoteStage';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

type InvitationPaneProps = {
  stage: Stage | undefined;
  loadingStage: boolean;
  fetchStage?: (stageId: EntityId) => Promise<void>;
  preselectedPackage: PackageSelectOption | undefined;
  preselectedCompany: AddressBookCompanyOption | undefined;
};

export const InvitationPane = ({
  stage,
  loadingStage,
  fetchStage,
  preselectedPackage,
  preselectedCompany,
}: InvitationPaneProps) => {
  const { getState, change } = useForm();
  const { submitError } = getState();

  const onStageChange = async (value?: SingleValue<StageSelectOption>) => {
    change(InviteToQuoteFormFieldName.Package, undefined);
    change(InviteToQuoteFormFieldName.Contact, undefined);
    if (fetchStage && value?.value) {
      await fetchStage(value.value);
    }
  };

  return (
    <>
      <Slider.Body scrollBodyOnly>
        {submitError ? (
          <div className={styles.errorMessage}>
            <GlobalErrorList errors={submitError} />
          </div>
        ) : null}
        <ScreenReaderOnly>
          <h2>Project summary</h2>
        </ScreenReaderOnly>
        {fetchStage ? <StageSelect stageId={stage?.id} onChange={onStageChange} /> : null}
        {loadingStage ? <LoadingSpinner /> : null}
        {stage ? (
          <>
            {!fetchStage ? (
              <DetailsCard
                contents={[
                  {
                    label: 'Project Name',
                    description: <p className={styles.summary}>{stage.name}</p>,
                  },
                ]}
              />
            ) : null}
            <ScreenReaderOnly>
              <h2>Package and contact selection</h2>
            </ScreenReaderOnly>
            <PackageSelectField
              id="invite-to-quote--package"
              label="Select Package"
              className={styles.formField}
              fieldName={InviteToQuoteFormFieldName.Package}
              packages={stage.activePackages}
              defaultValue={preselectedPackage}
              isDisabled={!!preselectedPackage}
              isRequired
            />
            <div className={styles.correspondenceAndScopesContainer}>
              <div className={styles.correspondenceContainer}>
                <CorrespondenceTypeField />
              </div>
              <div className={styles.scopesContainer}>
                <IncludeScopeOfWorksField />
              </div>
            </div>
            <DueDateField />
            <TenderResponses />
            <AddressBookCompanySelectField
              id="invite-to-quote--company"
              label="Search for a Company"
              className={styles.formField}
              fieldName={InviteToQuoteFormFieldName.Company}
              defaultValue={preselectedCompany}
              defaultOptions={preselectedCompany ? [preselectedCompany] : undefined}
              isRequired
            />
            <AddressBookContactsField />
          </>
        ) : null}
      </Slider.Body>

      <Slider.Footer>
        <Button type="submit" fullWidth>
          Next
        </Button>
      </Slider.Footer>
    </>
  );
};
