import { CraneIcon, Tooltip } from '@estimateone/frontend-components';
import { ProfileBadgeIconWrapper } from '@profiles/features/CompanyProfile/components/ProfileBadgeIconWrapper';
import { ProfileBadgeCard } from '../../ProfileBadgeCard';
import styles from './ProjectsBadge.module.scss';
import { useOwnAccount } from '@profiles/features/CompanyProfile/hooks';

const ProjectsIcon = () => (
  <ProfileBadgeIconWrapper status="info" content={<CraneIcon />} className={styles.crane} />
);

const ProjectsBadgeContent = ({ projectsCount }: { projectsCount: number }) => (
  <div className={styles.container}>
    <ProfileBadgeCard
      heading={projectsCount.toString()}
      subHeading="Projects"
      icon={<ProjectsIcon />}
    />
  </div>
);

export const ProjectsBadge = ({ projectsCount }: { projectsCount: number }) => {
  const { isOwnAccount } = useOwnAccount();

  return isOwnAccount ? (
    <Tooltip
      text="The project counter simply shows the total number of projects added to your project history. Builders commonly refer to your past project experience when deciding whether to consider you for a subcontract."
      tooltipId="projects-badge-tooltip"
      place="top"
    >
      <ProjectsBadgeContent projectsCount={projectsCount} />
    </Tooltip>
  ) : (
    <ProjectsBadgeContent projectsCount={projectsCount} />
  );
};
