export const PackageNoRfqsImage = () => (
  <svg
    width="120"
    height="120"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="4"
      y="56"
      width="112"
      height="60"
      rx="2"
      fill="white"
      stroke="#5E79FF"
      strokeWidth="4"
    />
    <path
      d="M55.9679 11.4453C55.953 11.4677 55.9389 11.4908 55.9258 11.5144L54.9213 13.3225C54.6484 13.8137 54.0245 13.9837 53.5402 13.6987L50.189 11.7274C49.7212 11.4523 49.5583 10.8543 49.8218 10.3799L50.8742 8.48564C52.695 5.2082 56.3573 3 60.4 3C66.6477 3 71.4 7.75228 71.4 14C71.4 16.7737 70.5771 18.5467 69.5296 19.8124C68.5697 20.9724 67.4025 21.7411 66.2792 22.481C66.1403 22.5725 66.0021 22.6635 65.865 22.7548C64.8772 23.3487 64.2166 23.8363 63.8363 24.3688C63.3985 24.9817 63.3994 25.5618 63.4 25.9574C63.4 25.9719 63.4 25.9861 63.4 26V28C63.4 28.5523 62.9523 29 62.4 29H58.4C57.8477 29 57.4 28.5523 57.4 28V26C57.4 21.3497 60.1907 19.2096 62.5351 17.6452C63.4955 17.0684 64.2338 16.5942 64.7026 16.0585C65.2661 15.4145 65.4 14.7452 65.4 14C65.4 12.5728 64.9199 11.3057 64.0071 10.3929C63.0943 9.48011 61.8272 9 60.4 9C58.4121 9 56.9216 10.0148 55.9679 11.4453ZM60.4 41C59.4272 41 58.6943 40.6801 58.2071 40.1929C57.7199 39.7057 57.4 38.9728 57.4 38C57.4 37.0272 57.7199 36.2943 58.2071 35.8071C58.6943 35.3199 59.4272 35 60.4 35C61.3728 35 62.1057 35.3199 62.5929 35.8071C63.0801 36.2943 63.4 37.0272 63.4 38C63.4 38.9728 63.0801 39.7057 62.5929 40.1929C62.1057 40.6801 61.3728 41 60.4 41Z"
      fill="white"
      stroke="#5E79FF"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M6 58L58.8095 97.1182C59.5167 97.642 60.4833 97.642 61.1905 97.1182L114 58"
      stroke="#5E79FF"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 114L38 82"
      stroke="#5E79FF"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M114 114L82 82"
      stroke="#5E79FF"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
