import { useEffect, useState } from 'react';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  LoadingSpinner,
  RadioButton,
  RadioButtonGroup,
} from '@estimateone/frontend-components';
import useFlashMessage from '../../../shared/Util/useFlashMessage';
import { useGetStageSettings, useUpdateStageSettingNotifyAwarded } from './hooks';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

export type StageSettingsProps = {
  stageId: EntityId;
};

export const StageSettings = ({ stageId }: StageSettingsProps) => {
  const [sendNotifications, setSendNotifications] = useState('false');
  const [currentSavedNotifyAwardedValue, setCurrentSavedNotifyAwardedValue] = useState('false');
  const [enableDefaultTimeframes, setEnableDefaultTimeframes] = useState('false');
  const { stageSettingNotifyAwarded, stageSettingEnableDefaultTimeframes, loading } =
    useGetStageSettings(stageId);
  const { updateStageSettingNotifyAwarded, loading: updateLoading } =
    useUpdateStageSettingNotifyAwarded();
  const { success: showSuccessMessage } = useFlashMessage();

  const submitHandler = async () => {
    await updateStageSettingNotifyAwarded({
      variables: { input: { stageId, value: sendNotifications === 'true' } },
    });
    setCurrentSavedNotifyAwardedValue(sendNotifications);
    showSuccessMessage({
      title: 'Success',
      message:
        sendNotifications === 'true'
          ? 'Awarded Contractors will be notified of document revisions.'
          : 'Awarded Contractors will stop being notified of document revisions.',
    });
  };

  const updateNotifications = (value: string) => {
    setSendNotifications(value);
  };

  useEffect(() => {
    if (!loading) {
      setSendNotifications(stageSettingNotifyAwarded?.toString() ?? 'false');
      setCurrentSavedNotifyAwardedValue(stageSettingNotifyAwarded?.toString() ?? 'false');
      setEnableDefaultTimeframes(stageSettingEnableDefaultTimeframes?.toString() ?? 'true');
    }
  }, [stageSettingNotifyAwarded, stageSettingEnableDefaultTimeframes, loading]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div>
        <p className={styles.settingTitle}>
          Send notifications about document revisions to awarded subcontractors?
        </p>
        <div className={styles.settingOptionsContainer}>
          <RadioButtonGroup
            name="sendNotifications"
            value={sendNotifications}
            onChange={updateNotifications}
          >
            <RadioButton
              id="notifications-yes"
              value="true"
              key="notifications-yes"
              className={styles.settingOptionButton}
              checked={sendNotifications === 'true' ? true : undefined}
            >
              <span className={styles.settingOptionLabel}>Yes, notify awarded subcontractors</span>
            </RadioButton>
            <RadioButton
              id="notifications-no"
              value="false"
              key="notifications-no"
              className={styles.settingOptionButton}
              checked={sendNotifications === 'false' ? true : undefined}
            >
              <span className={styles.settingOptionLabel}>
                No, stop notifying awarded subcontractors
              </span>
            </RadioButton>
          </RadioButtonGroup>
        </div>
      </div>
      <div>
        <p className={styles.settingTitle}>
          Enable default timeframes between dates on project schedule?
        </p>
        <div className={styles.settingOptionsContainer}>
          <RadioButtonGroup
            name="enableDefaultTimeframes"
            value={enableDefaultTimeframes}
            onChange={() => null}
            disabled
          >
            <RadioButton
              id="enable-default-timeframes-yes"
              role="radio"
              value="true"
              key="enable-default-timeframes-yes"
              disabled
              className={styles.settingOptionButton}
              checked={enableDefaultTimeframes === 'true' ? true : undefined}
            >
              <span className={styles.settingOptionLabel}>
                Yes, enable default timeframes between dates.
              </span>
            </RadioButton>
            <RadioButton
              id="enable-default-timeframes-no"
              role="radio"
              value="false"
              key="enable-default-timeframes-no"
              disabled
              className={styles.settingOptionButton}
              checked={enableDefaultTimeframes === 'false' ? true : undefined}
            >
              <span className={styles.settingOptionLabel}>
                No, disable default timeframes between dates.
              </span>
            </RadioButton>
          </RadioButtonGroup>
        </div>
        <div className={styles.settingSaveButtonContainer}>
          <Button
            variant={ButtonVariant.Primary}
            size={ButtonSize.Medium}
            className={styles.settingOptionSaveButton}
            disabled={updateLoading || sendNotifications === currentSavedNotifyAwardedValue}
            onClick={submitHandler}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};
