import { useMemo } from 'react';
import {
  Alert,
  AlertVariant,
  CellProps,
  joinClassNames,
  LoadingSpinner,
  RadioButton,
  ScreenReaderOnly,
  Select,
  Table,
  TableColumn,
  TableRow,
} from '@estimateone/frontend-components';
import { useStageNameForDocumentIntegration } from '@builder/features/DocSyncIntegration/DocumentIntegrationProvider';
import { useSitesAndDrivesQuery } from '@builder/features/DocSyncIntegration/Sharepoint/SliderSteps/hooks/useSitesAndDrives';
import { SharePointDrive, SharePointSite } from '../types';
import styles from './styles.scss';

const DriveSelectionCell =
  ({
    onSiteSelection,
    selectedDrive,
  }: {
    onSiteSelection: (site: SharePointSite) => void;
    selectedDrive: SharePointDrive | undefined;
  }) =>
  ({ value }: CellProps<SharePointDrive>) => (
    <RadioButton
      id={value}
      onChange={() => onSiteSelection(value)}
      checked={selectedDrive?.id === value}
    />
  );

type SiteSelectionProps = {
  selectedDrive: SharePointDrive | undefined;
  selectedSite: SharePointSite | undefined;
  onDriveSelection: (drive: SharePointDrive) => void;
  onSiteSelection: (site: SharePointSite) => void;
};

export const SiteSelection = ({
  onSiteSelection,
  onDriveSelection,
  selectedSite,
  selectedDrive,
}: SiteSelectionProps) => {
  const { sitesAndDrives, loading, errors } = useSitesAndDrivesQuery();
  const stageName = useStageNameForDocumentIntegration();
  const drivesForSelectedSite = useMemo<SharePointDrive[]>(
    () => sitesAndDrives?.find((site) => site.id === selectedSite?.id)?.drives ?? [],
    [sitesAndDrives, selectedSite],
  );

  if (errors) {
    return <Alert variant={AlertVariant.Red}>{errors[0].message}</Alert>;
  }

  if (loading || !sitesAndDrives) {
    return <LoadingSpinner />;
  }

  const hasDrives = drivesForSelectedSite.length > 0;

  const tableColumns: TableColumn<SharePointDrive>[] = [
    {
      id: 'checkbox',
      Header: <ScreenReaderOnly>Select Drive</ScreenReaderOnly>,
      tooltipTitle: 'Select Drive',
      accessor: 'id',
      Cell: DriveSelectionCell({ onSiteSelection, selectedDrive }),
      disableSortBy: true,
      fitToContent: true,
    },
    {
      id: 'name',
      Header: 'Drive Name',
      tooltipTitle: 'Drive Name',
      accessor: 'name',
    },
  ];

  const getRowProps = (row: TableRow<SharePointDrive>) => {
    const className = joinClassNames(
      styles.row,
      row.original.id === selectedDrive?.id && styles.selectedRow,
    );

    return {
      className,
      onClick: () => onDriveSelection(row.original),
    };
  };

  return (
    <div>
      <span>Select a site and document library that matches: {stageName}</span>
      <Select
        id="sharepoint-company-select"
        className={styles.selectSite}
        aria-label="Select Site"
        label="Select Site"
        hideLabel
        isRequired
        placeholder="Select a site..."
        defaultValue={
          selectedSite?.id ? { label: selectedSite.name, value: selectedSite.id } : undefined
        }
        options={sitesAndDrives.map((site) => ({
          label: site.displayName,
          value: site.id,
        }))}
        onChange={(option) => {
          onSiteSelection(sitesAndDrives.find((site) => site.id === option?.value)!);
        }}
      />

      {selectedSite && !hasDrives && (
        <div className={styles.noDrivesText}>There are no drives attached to this site.</div>
      )}
      {selectedSite && hasDrives && (
        <Table<SharePointDrive>
          columns={tableColumns}
          data={drivesForSelectedSite}
          rowProps={getRowProps}
        />
      )}
    </div>
  );
};
