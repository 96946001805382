import $ from 'jquery';

$(() => {
  const $body = $('body');
  $body.on('focus', '.edit-package-form__datepicker--quotes-due-at', () => {
    const $editPackageFormQuotesDueHelpText = $(
      '.edit-package-form__datepicker--quotes-due-at__help-text',
    );
    $editPackageFormQuotesDueHelpText.removeAttr('hidden');
  });
});
