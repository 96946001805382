import $ from 'jquery';
import Routing from 'routing';
import moment from 'moment-timezone';
import E1Request from '../classes/E1Request';
import Form from '../classes/Form';
import ServerDataList from '../classes/ServerDataList';
import SuperSlider from '../classes/SuperSlider';
import { isValidKeyup } from '../utils/helpers';
import quillify from '../utils/quillify';
import { truncate } from '../utils/truncate';
import UserCorrespondenceDraftModule from './classes/UserCorrespondenceDraftModule';
import { activateLoadingButton, deactivateLoadingButton } from './loading_button';
import { getUserTimezone } from './timezone';
import EditCorrespondence from './user_correspondence';
import NewCorrespondenceAttachmentUploadModule from './user_correspondence_attachment_upload';
import { UploadParentType } from '../../enums';

function UserCorrespondenceMailTable($target) {
  const self = this;
  this.$target = $target;
  this.$container = $target.closest('.user-correspondence-mail-container');
  this.correspondenceId = parseInt(self.$container.attr('data-correspondence-id'), 10);
  this.stageId = parseInt(self.$container.attr('data-stage'), 10);
  this.dataTable = null;
  self.init();
}

UserCorrespondenceMailTable.prototype = {
  init() {
    const self = this;
    self.dataTable = self.createTable();
  },
  createTable() {
    const self = this;
    const route = Routing.generate('app_usercorrespondencemail_fetch', {
      correspondence: self.correspondenceId,
    });
    return new ServerDataList(self.$target, route, (list) => {
      const $placeholderRow = list.$target.find('tr.data-table-placeholder').first();
      list.table = list.$target
        .DataTable({
          pageLength: 20,
          processing: true,
          serverSide: true,
          ajax(data, cb, settings) {
            list.serverRequest(data, cb, settings);
          },
          createdRow(row, data) {
            $(row)
              .addClass('view-user-correspondence-mail-trigger pointer-cursor')
              .attr('data-correspondence-id', data.parent_id)
              .attr('data-mail-id', data.id);
          },
          columns: [
            {
              data: 'view',
              name: 'user_correspondence.view',
              class: 'unread-column',
              orderable: false,
              render(data, display, obj, details) {
                if (display === 'display') {
                  const template = $($placeholderRow.find('td').get(details.col));
                  const $tdContent = $(template).clone();
                  $tdContent.find('span.unread-indicator').attr('data-read', Math.min(data, 1));
                  return $tdContent.html();
                }
                return data;
              },
            },
            {
              data: 'createdAt',
              name: 'user_correspondence.createdAt',
              orderable: false,
              render(data, display, obj, details) {
                const date = moment.utc(obj.createdAt.date);
                if (display !== 'display') {
                  return date.format('X');
                }
                const $template = $($placeholderRow.find('td').get(details.col)).clone();
                const $timeStamp = $template.find('.mail-conversation-date .date-timestamp');
                const $timeAgo = $template.find('.mail-conversation-date .date-ago');
                const $message = $template.find('.mail-conversation-message');
                const $fromName = $template.find('.from-contact-name');
                const $participants = $template.find('.mail-conversation-participants');
                const users = {};
                users[obj.createdBy.id] = obj.createdBy;

                date.tz(getUserTimezone());

                $fromName.text(`${obj.createdBy.firstName}:`);

                $.each(obj.items, (key, item) => {
                  if (!users[item.id]) {
                    users[item.user.id] = item.user;
                  }
                });

                const participantsToDisplay = 2;
                const numParticipants = Object.keys(users).length;
                for (let i = 0; i < participantsToDisplay && i < numParticipants; i++) {
                  const user = users[Object.keys(users)[i]];
                  const $nameSpan = $('<span>')
                    .append(
                      $('<strong>')
                        .text(`${user.firstName} ${user.lastName}`)
                        .addClass('contact-name'),
                    )
                    .append(document.createTextNode('\u00A0'))
                    .append(
                      $('<span>')
                        .text(` (${user.account.name})`)
                        .append(i !== numParticipants - 1 ? ';' : '') // Don't write a trailing semicolon
                        .addClass('company-name'),
                    )
                    .append(document.createTextNode('\u00A0'));
                  $participants.append($nameSpan);
                }

                if (numParticipants > 2) {
                  const remaining = numParticipants - 2;
                  const $moreSpan = $('<span>')
                    .text(`+${remaining} more...`)
                    .addClass('small muted more');

                  $participants.append($moreSpan);
                }

                $timeStamp.text(date.format($timeStamp.attr('data-format')));
                $timeAgo.text(date.fromNow());
                $message.text(truncate(obj.content, 290));

                if (obj.has_attachments) {
                  const $attachment = $('<i>').addClass('icon icon-attach');
                  $message.append(document.createTextNode('\u00A0'), $attachment);
                }

                return $($template).html();
              },
            },
          ],
        })
        .on('init.dt', () => {
          list.$container.addClass('has-loaded');
        });
    });
  },
};

function UserCorrespondenceMailHelper($target) {
  this.$target = $target;
  this.userCorrespondenceFormSelector = 'form.user-correspondence-mail-form';
  this.editCorrespondenceSelector = '.edit-correspondence-ctn';
  this.replyToMailSelector = '.reply-to-mail-from-slider-trigger';
  this.replySubmitSelector = '.reply-submit-trigger';
  this.emailFrames = $('.email-frame').toArray();
  this.$mailContainer = this.$target.find('.mail-item-container');
  this.init();
}

UserCorrespondenceMailHelper.prototype = {
  init() {
    const self = this;
    if (self.$target.find(self.editCorrespondenceSelector).length > 0) {
      new EditCorrespondence(self.$target.find(self.editCorrespondenceSelector));
    }

    self.$target.on('click', self.replySubmitSelector, (e) => {
      if (self.$target.hasClass('mail-stand-alone-container')) {
        self.onReplySubmit();
      } else {
        self.onReplySliderSubmit();
      }
    });

    self.$target.on('click', self.replyToMailSelector, (e) => {
      self.onReplyClick(e);
    });

    if (self.$mailContainer.attr('data-reply-type')) {
      $(
        `${self.replyToMailSelector}[data-reply-type="${self.$mailContainer.attr(
          'data-reply-type',
        )}"]`,
      ).trigger('click');
      self.$mailContainer.removeAttr('data-reply-type');
    }

    self.iframesLoaded = self.setIframeHeights();
  },
  async setIframeHeights() {
    const self = this;
    let loadingIframes = self.emailFrames.slice(0);

    return new Promise((resolve) => {
      const keepSettingHeightsOnLoadedIframes = setInterval(() => {
        loadingIframes = loadingIframes.filter((frame) => {
          const $iframeContent = $(frame).contents().find('.iframe-email-data');
          if ($iframeContent.length > 0) {
            frame.height = `${$iframeContent.height() + 30}px`;
            return false;
          }
          return true;
        });

        if (!loadingIframes.length) {
          clearInterval(keepSettingHeightsOnLoadedIframes);
          resolve();
        }
      }, 200);
    });
  },
  onReplyClick(e) {
    const $button = $(e.currentTarget);

    activateLoadingButton($button);
    $(this.replyToMailSelector).prop('disabled', true);

    const correspondenceId = $button.attr('data-correspondence-id');
    const mailId = $button.attr('data-mail-id');
    const data = {
      correspondence: correspondenceId,
      mail: mailId,
    };

    data.reply_type = $button.attr('data-reply-type');

    if ($button.attr('data-stand-alone')) {
      data.standAlone = true;
    }

    const route = Routing.generate('app_usercorrespondencemail_replypartial', data);
    const request = new E1Request(route);
    request.extraCallback = (response) => {
      const $updatedView = $(response.updated_view);
      deactivateLoadingButton($button);
      $(this.replyToMailSelector).prop('disabled', false);

      this.addReplyView($updatedView);
    };
    request.submit();
  },
  addReplyView($updatedView) {
    const self = this;
    const $replyFooter = self.$target.find('.reply-footer');

    self.$target.find('.reply-container').replaceWith($updatedView);
    self.$target.find('.super-slider-footer ').remove();
    self.$target.find('h4.section-title').removeClass('hide');
    self.$target.find('select.select2:enabled:not([readonly])').select2();

    const $form = self.$target.find(self.userCorrespondenceFormSelector);
    const $ctn = $form.find('.file-uploader');
    const $target = $ctn.find('.uploader');

    const $submitBtn = $form.find('button[type="submit"]');
    const corresId = $submitBtn.attr('data-correspondence');
    const mailId = $submitBtn.attr('data-mail');
    const draftCacheKey = `reply_${corresId}_${mailId}`;
    new UserCorrespondenceDraftModule($form, draftCacheKey, true);

    quillify('.user_correspondence_mail_content.quill-wrapper', {
      useExistingData: true,
    });

    if ($target.length) {
      new NewCorrespondenceAttachmentUploadModule(
        $form,
        $target,
        $target.data('key'),
        $target.data('stage'),
        UploadParentType.USER_CORR_ATTACH,
      );
    }

    if ($replyFooter.length) {
      $replyFooter.find('.mail-actions').addClass('hide');
      $replyFooter.find('.form-actions').removeClass('hide');
    }

    const setupCorrespondenceDropdown = ($dropdown) => {
      let recipients = [];

      $dropdown.on('select2:selecting', (evt) => {
        recipients = $(evt.currentTarget).val();
      });

      $dropdown.on('select2:select', (evt) => {
        const $dropdownTarget = $(evt.currentTarget);
        const $choice = $(evt.params.data.element);

        if ($choice.hasClass('user-group-option')) {
          const $options = $dropdownTarget.find(
            `option[data-user-group=${$choice.attr('data-group-id')}]`,
          );
          const values = $options.map((i, element) => $(element).attr('value'));

          $choice.attr('aria-selected', true);

          recipients = recipients.concat(...values);
          $dropdownTarget.val(recipients).trigger('change');
        }
      });
    };

    setupCorrespondenceDropdown($('#user_correspondence_mail_itemUsers'));
    setupCorrespondenceDropdown($('#user_correspondence_mail_ccUsers'));

    self.scrollFormIntoView();
  },
  onReplySliderSubmit() {
    const self = this;
    const $form = self.$target.find(self.userCorrespondenceFormSelector);
    const correspondenceId = self.$target
      .find(this.replySubmitSelector)
      .attr('data-correspondence');
    const mailId = self.$target.find(this.replySubmitSelector).attr('data-mail');

    const $uploads = $form.find('.attachment-search :selected');
    const uploads = $.map($uploads, (val) => {
      $(val).prop('selected', false);
      return {
        id: $(val).data('id'),
        hash: $(val).data('hash'),
        size: $(val).data('size'),
        name: $(val).data('name'),
      };
    });

    const route = Routing.generate('app_usercorrespondencemail_replypartial', {
      correspondence: correspondenceId,
      mail: mailId,
      attachments: uploads,
    });

    const form = new Form($form, route);
    form.extraCallback = (response) => {
      if (response.success) {
        $(document).trigger('update-mail-tables', {
          mailId,
          reload: true,
        });

        $form.trigger('destroy_form_cache');
      }
    };

    form.submit();
  },
  onReplySubmit() {
    const $form = this.$target.find(this.userCorrespondenceFormSelector);
    const $replyButton = this.$target.find(this.replySubmitSelector);
    const correspondenceId = $replyButton.attr('data-correspondence');
    const mailId = $replyButton.attr('data-mail');
    const $uploads = $form.find('.attachment-search :selected');
    const uploads = $.map($uploads, (val) => {
      $(val).prop('selected', false);
      return {
        id: $(val).data('id'),
        hash: $(val).data('hash'),
        size: $(val).data('size'),
        name: $(val).data('name'),
      };
    });

    const route = Routing.generate('app_usercorrespondencemail_replypartial', {
      correspondence: correspondenceId,
      mail: mailId,
      attachments: uploads,
      standAlone: true,
    });

    activateLoadingButton($replyButton);
    $replyButton.prop('disabled', true);

    const form = new Form($form, route);
    form.extraCallback = (response) => {
      deactivateLoadingButton($replyButton);
      $($replyButton).prop('disabled', false);

      if (response.success) {
        this.$target
          .find(this.editCorrespondenceSelector)
          .attr('data-new-reply-id', response.reply_id);

        $(document).trigger('update-mail-tables', {
          mailId,
          reload: true,
        });
      }
    };

    form.submit();
  },
  scrollFormIntoView() {
    const self = this;
    if (self.$target.hasClass('mail-stand-alone-container')) {
      $('html, body').animate(
        {
          scrollTop: 0,
        },
        1000,
      );
    } else {
      $('.super-slider-body').animate(
        {
          scrollTop: 0,
        },
        1000,
      );
    }
  },
  loadNewReplyPage() {
    const $editContainer = this.$target.find(this.editCorrespondenceSelector);
    const correspondenceId = $editContainer.attr('data-correspondence-id');
    const mailId = $editContainer.attr('data-new-reply-id');

    const route = Routing.generate('app_usercorrespondencemail_standalone', {
      correspondence: correspondenceId,
      mail: mailId,
    });

    window.location = route;
  },
};

function EmailThreadHelper($target) {
  const self = this;
  this.$target = $target;
  this.$emailFrames = $target.find('.email-frame');
  this.$searchField = $target.find('.mail-view-search');
  this.$mailContainer = $target.find('.mail-item-container');
  this.markClasses = {
    className: 'highlight',
    iframes: true,
    element: 'span',
    exclude: ['span.highlight'],
  };
  this.mark = null;
  self.init();
}

EmailThreadHelper.prototype = {
  init() {
    const self = this;
    if (self.$searchField) {
      self.$target.on('keyup', '.mail-view-search', (e) => {
        self.search(e);
      });
    }
    if (self.$emailFrames) {
      self.resize();
    }
  },
  resize() {
    const self = this;
    if (self.$emailFrames.length) {
      self.$emailFrames.each((i, frame) => {
        const emailFrame = frame;
        const height = $(emailFrame).contents().find('.iframe-email-data').height() + 30;
        $(emailFrame).height(height);
      });
    }
  },
  search(e) {
    const self = this;
    const keyup = e.keyCode || e.charCode;
    const searchTerm = $(e.currentTarget).val().trim().toLowerCase();

    if (!isValidKeyup(keyup)) {
      return false;
    }

    self.$mailContainer.unmark({
      iframes: true,
      done() {
        if (searchTerm.length > 2) {
          self.$mailContainer.mark(searchTerm, self.markClasses);
          self.$target.find('.mail-item').each((i, item) => {
            const $iframe = $(item).find('iframe');
            const $item = $(item);
            const iframeResult = $iframe.contents().text().toLowerCase().search(searchTerm) !== -1;
            const mailItemResult = $item.contents().text().toLowerCase().search(searchTerm) !== -1;

            if (!iframeResult && !mailItemResult) {
              $(item).addClass('hide'); // hide classes that don't match
            } else {
              $(item).removeClass('hide'); // hide classes that don't match
            }
          });
        } else {
          self.$target.find('.mail-item').each((i, frame) => {
            $(frame).closest('.mail-item').removeClass('hide');
          });
        }
      },
    });
    return false;
  },
};

$(() => {
  const $mailContainer = $('.user-correspondence-mail-container');
  const $body = $('body');

  if ($mailContainer.length) {
    const $correspondenceMailTables = $('table.user-correspondence-mail-table');
    const tables = [];
    const $tabs = $mailContainer.find('[role=tab]');
    const threadHelper = new EmailThreadHelper($mailContainer);

    $correspondenceMailTables.each((i, e) => {
      const $table = $(e);
      tables.push(new UserCorrespondenceMailTable($table));
    });

    $(document).on('update-mail-tables', (e, data) => {
      if (data.mailId) {
        tables.forEach((mailTable) => {
          if (data.reload) {
            mailTable.dataTable.table.rows().invalidate('data').draw('full-hold');
            return;
          }
          // don't trigger a request for a simple update, e.g. updating unread indicator
          const invalidatedRow = mailTable.dataTable.table.row(`[data-mail-id=${data.mailId}]`);
          const oldData = invalidatedRow.data();
          invalidatedRow.data($.extend(oldData, data));
        });
      }
    });

    $body.on('click', '.show-more-trigger', (e) => {
      const $this = $(e.currentTarget);
      const $container = $this.closest('.show-more-container');
      $container.find('.show-more').toggleClass('hide');
      $container.find('.hide-more').toggleClass('hide');
    });

    $body.on('click', '.email-dragdrop-area-trigger', ({ currentTarget }) => {
      const $trigger = $(currentTarget);
      $('div.imported-mail-view div.drag-drop-view-element').toggleClass(
        'hide',
        $trigger.data('action') === 'hide',
      );

      $trigger.addClass('hide').siblings().removeClass('hide');
    });

    $body.on('change', 'input.mail-conversation-toggle', ({ currentTarget: { value } }) => {
      if (value === 'expanded') {
        setInterval(() => {
          threadHelper.resize();
        }, 300);
      }

      $tabs.each((i, t) => {
        const $tab = $(t);
        $tab.toggleClass('hide', !$tab.hasClass(`${value}-view`));
      });
    });

    if ($mailContainer.attr('data-mail-id')) {
      const mailId = $mailContainer.attr('data-mail-id');
      const correspondenceId = $mailContainer.attr('data-correspondence-id');
      const replyType = $mailContainer.attr('data-reply-type') || null;

      const route = Routing.generate('app_usercorrespondencemail_viewslider', {
        correspondence: correspondenceId,
        id: mailId,
        reply_type: replyType,
      });

      const request = new E1Request(route);
      request.show_loading_modal = true;
      request.loadingModal = 'slider';
      request.submit();
    }
  }

  $body.on('slider-loaded', () => {
    const $target = $('.mail-correspondence-slider');
    if ($target.length) {
      new UserCorrespondenceMailHelper($target);
    }
  });

  $body.on('click', '.view-user-correspondence-mail-trigger', (e) => {
    const $this = $(e.currentTarget);
    const correspondenceId = $this.attr('data-correspondence-id');
    const mailId = $this.attr('data-mail-id');
    const route = Routing.generate('app_usercorrespondencemail_viewslider', {
      correspondence: correspondenceId,
      id: mailId,
    });

    const request = new E1Request(route);
    request.show_loading_modal = true;
    request.loadingModal = 'slider';

    request.submit((req, resp) => {
      if (typeof resp === 'object' && resp.success) {
        $(document).trigger('update-mail-tables', {
          mailId,
          view: true,
        });

        if (typeof resp.slider_string === 'string') {
          const slider = new SuperSlider(resp.slider_string, {
            callbacks: {
              onClose: (sliderInstance) => {
                sliderInstance.$slider
                  .find('form.user-correspondence-mail-form')
                  .trigger('on_slider_close');
              },
            },
          });
          slider.show();
        }
      }
    });
  });
});

$(() => {
  const $standAloneMailContainer = $('.mail-stand-alone-container');
  if ($standAloneMailContainer.length) {
    const $body = $('body');
    const mailHelper = new UserCorrespondenceMailHelper($standAloneMailContainer);

    $body.on('click', '.show-more-trigger', (e) => {
      const $container = $(e.currentTarget).closest('.show-more-container');
      $container.find('.show-more').toggleClass('hide');
      $container.find('.hide-more').toggleClass('hide');
    });

    $(document).on('mfpClose', () => {
      mailHelper.loadNewReplyPage();
    });
  }
});

$(() => {
  const $printContainer = $('.container.mail-print');
  if ($printContainer.length === 1) {
    new UserCorrespondenceMailHelper($printContainer).iframesLoaded.then(window.print);
  } else if ($printContainer.length) {
    throw new Error('There was more than one ".container.mail-print" element on the page.');
  }
});
