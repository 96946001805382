import { AlertIcon, SkeletonLoader } from '@estimateone/frontend-components';
import { TagGroup } from '../NewTagGroup';
import { NoContentPlaceholder } from '../NoContentPlaceholder';
import styles from './Categories.module.scss';
import { EditCategories } from './EditCategories';
import { getCategoryLabel } from './utils';
import { useOwnAccount } from '@profiles/features/CompanyProfile/hooks';
import { StageCategory } from '@ascension/_gqltypes/profiles.generated';

export const CategoriesErrorView = () => (
  <div className={styles.categoriesContainer}>
    <TagGroup header="Categories">
      <AlertIcon title="Categories error icon" />
    </TagGroup>
  </div>
);

export const CategoriesLoader = () => (
  <TagGroup header="Categories">
    <SkeletonLoader height="16px" count={2} />
  </TagGroup>
);

type CategoriesProps = {
  data: StageCategory[];
};

export const Categories = ({ data }: CategoriesProps) => {
  const { isOwnAccount } = useOwnAccount();

  const categoryLabels = data.map((category) => getCategoryLabel(category));

  return (
    <div className={styles.categoriesContainer}>
      <TagGroup header="Categories">
        {categoryLabels.length === 0 ? (
          <NoContentPlaceholder />
        ) : (
          categoryLabels.map((tag) => <TagGroup.Tag key={`categories-${tag}`} text={tag} />)
        )}
      </TagGroup>
      {isOwnAccount && <EditCategories categories={data} />}
    </div>
  );
};
