import {
  ContractSizeMaxEnum,
  ContractSizeMinEnum,
  WorkforceSizeEnum,
} from '@ascension/_gqltypes/builder.generated';
import { ValueLabelOption } from '@estimateone/frontend-components/src/components/Form/Select/types';

export type SearchServiceArea = {
  country: string;
  state?: string;
};

export type ContractSize = {
  min: ContractSizeMinEnum | undefined;
  max: ContractSizeMaxEnum | undefined;
};

export const DISTANCE_OPTIONS = [10, 25, 50, 100, 150, 200, 250, 300] as const;

export enum SubbieNetworkFilterFormFields {
  TradeFilterId = 'tradeFilter',
  SearchArea = 'searchArea',
  WorkforceFilterId = 'workforceFilter',
  Distance = 'distance',
  Location = 'location',
  CategoryFilterId = 'categoryFilter',
  ContractSizeMin = 'contractSizeMin',
  ContractSizeMax = 'contractSizeMax',
}

export type SubbieNetworkFilterFormValues = {
  [SubbieNetworkFilterFormFields.TradeFilterId]?: ValueLabelOption<number>;
  [SubbieNetworkFilterFormFields.WorkforceFilterId]?: ValueLabelOption<WorkforceSizeEnum>;
  [SubbieNetworkFilterFormFields.Distance]?: DistanceValueOption;
  [SubbieNetworkFilterFormFields.Location]?: LocationOption;
  [SubbieNetworkFilterFormFields.CategoryFilterId]?: ValueLabelOption<number>;
  [SubbieNetworkFilterFormFields.ContractSizeMin]?: ContractSizeValueOption<ContractSizeMinEnum>;
  [SubbieNetworkFilterFormFields.ContractSizeMax]?: ContractSizeValueOption<ContractSizeMaxEnum>;
  [SubbieNetworkFilterFormFields.SearchArea]?: SearchAreaOptions;
};

export const allSubbieNetworkFilterFormFields = [
  SubbieNetworkFilterFormFields.TradeFilterId,
  SubbieNetworkFilterFormFields.WorkforceFilterId,
  SubbieNetworkFilterFormFields.Distance,
  SubbieNetworkFilterFormFields.Location,
  SubbieNetworkFilterFormFields.CategoryFilterId,
  SubbieNetworkFilterFormFields.ContractSizeMin,
  SubbieNetworkFilterFormFields.ContractSizeMax,
  SubbieNetworkFilterFormFields.SearchArea,
] satisfies SubbieNetworkFilterFormFields[];

export type SubbieNetworkFilterFormErrors = {
  [Property in SubbieNetworkFilterFormFields]?: string[];
};

export type ContractSizeValueOption<T> = { monetaryValue: number } & ValueLabelOption<T>;

export type DistanceValueOption = ValueLabelOption<number>;

export type SearchAreaOptions = 'service-area' | 'office-location';

export type LocationOption = {
  lat: number;
  lon: number;
  locationLabel: string;
  serviceArea?: SearchServiceArea;
};
