import { useEffect, useState, useRef } from 'react';
import { Icon, IconName, IconVariant } from '@estimateone/frontend-components';
import { RoadblockLicensePointerIcon } from './RoadblockIcons';
import styles from './RoadblockSections.module.scss';
import { useAccountType } from '@subbie/context/AccountProvider/hooks';
import { AccountType } from '@ascension/_gqltypes/subbie.generated';

type RoadblockSectionPremiumPageProps = {
  productName: string;
  redactedFifty?: boolean;
};

export const RoadblockSectionPremiumPage = ({
  productName,
  redactedFifty,
}: RoadblockSectionPremiumPageProps) => {
  const accountType = useAccountType();

  return (
    <div className={styles.roadblockGraphicContainer}>
      <div className={styles.graphicIconContainer}>
        <div className={styles.graphicIcon}>
          {redactedFifty ? (
            <Icon name={IconName.Locked} size="1.1em" variant={IconVariant.Blue} strokeWidth={4} />
          ) : (
            <Icon name={IconName.Tools} size="1.1em" variant={IconVariant.Blue} strokeWidth={4} />
          )}
        </div>
        <p>
          {redactedFifty && accountType === AccountType.SUBCONTRACTOR ? (
            <strong>Essential</strong>
          ) : (
            <strong>{productName}</strong>
          )}
        </p>
        <div className={styles.redactedBlock} />
        <div className={styles.redactedBlock} />
      </div>
    </div>
  );
};

export const RoadblockSectionAdditionalLicense = ({ licenseName }: { licenseName: string }) => {
  const [pointerLeft, setPointerLeft] = useState<string>();
  const requireAdditionalLicenseButtonRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (requireAdditionalLicenseButtonRef.current) {
      // Place the pointer icon to the bottom right corner the button with length determined by the state name.
      setPointerLeft(`${requireAdditionalLicenseButtonRef.current.offsetWidth - 4}px`);
    }
  }, [licenseName]);

  return (
    <div className={styles.roadblockGraphicContainer}>
      <div
        className={styles.additionalLicenseButton}
        ref={requireAdditionalLicenseButtonRef}
        id="requireAdditionalLicenseButton"
      >
        {licenseName}
      </div>
      <div
        className={styles.additionalLicensePointer}
        id="additionalLicensePointer"
        style={{ marginLeft: pointerLeft }}
      >
        <RoadblockLicensePointerIcon />
      </div>
    </div>
  );
};
