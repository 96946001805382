import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_SHAREPOINT_DRIVE_ITEMS } from '../queries';
import { GetDriveItemsQueryVariables } from '@builder/features/DocSyncIntegration/Sharepoint/SliderSteps/queries.generated';
import { GetDriveItems } from '@builder/features/DocSyncIntegration/Sharepoint/SliderSteps/types/GetDriveItems';

export const useGetDriveItems = (driveId: string, driveItemId: string) => {
  const { data, loading } = useQuery<GetDriveItems, GetDriveItemsQueryVariables>(
    GET_SHAREPOINT_DRIVE_ITEMS,
    { variables: { driveId, driveItemId } },
  );

  if (data?.getSharePointDriveItems.__typename === 'SharePointDriveItems') {
    return { driveItems: data.getSharePointDriveItems.driveItems, loading, errors: undefined };
  }
  if (data?.getSharePointDriveItems.__typename === 'Errors') {
    return { driveItems: undefined, loading, errors: data.getSharePointDriveItems.errors };
  }

  return { driveItems: undefined, loading, errors: undefined };
};

export const useLazyGetDriveItems = (driveId: string) => {
  const [lazyQuery] = useLazyQuery<GetDriveItems, GetDriveItemsQueryVariables>(
    GET_SHAREPOINT_DRIVE_ITEMS,
  );
  const handleLazyQuery = async (driveItemId: string) => {
    const result = await lazyQuery({ variables: { driveId, driveItemId } });

    if (result?.data?.getSharePointDriveItems.__typename === 'SharePointDriveItems') {
      return result.data.getSharePointDriveItems.driveItems.filter(
        (item) => item.type === 'FOLDER',
      );
    }
    if (result?.data?.getSharePointDriveItems.__typename === 'Errors') {
      return undefined;
    }

    return undefined;
  };

  return { handleLazyQuery };
};
