import Routing from 'routing';
import TokenCache from '@api/GatewayProvider/TokenCache';
import { procorePlugin } from '@builder/features/DocSyncIntegration/DocSyncPlugin/ProcorePlugin';
import { getStageTypeAsString, StageType } from '@ascension/enums';
import { EntityId } from '@ascension/types';

export const connectToProcore = async (stageId: EntityId, docsyncAuthHost: string) => {
  const stageUrl = Routing.generate('app_stage_documents', {
    id: stageId,
    stageType: getStageTypeAsString(StageType.TYPE_PROCUREMENT)?.toLowerCase(),
  });
  const redirectUri = `${window.location.protocol}//${window.location.host}${stageUrl}?openDocSyncSlider=${procorePlugin.id}`;
  const idpUrl = Routing.generate('gateway_jwt_authorise');
  const tokenCache = new TokenCache(idpUrl);

  const response = await fetch(`${docsyncAuthHost}/claims`, {
    headers: {
      Authorization: `Bearer ${await tokenCache.getToken()}`,
    },
  });

  if (response.ok) {
    const { claims }: { claims: string } = await response.json();

    const authUrl = `${docsyncAuthHost}/procore/auth?redirectUri=${redirectUri}&claims=${claims}`;

    window.location.assign(authUrl);
  }
};
