import { useEffect, useState } from 'react';
import { useForm } from 'react-final-form';
import { Label } from '@estimateone/frontend-components/src/components/Form/Label';
import { reportError } from '@ascension/components/helpers/errorReporter';
import { CheckboxField } from '../../../shared/FinalForm/Field';
import LoadingSpinner from '@shared/LoadingSpinner';
import { useScopesQuery } from '../../hooks/useScopesQuery';
import { SCOPE_OF_WORKS_QUERY } from '../../queries';
import { InviteToQuoteFormFieldName } from '../../../builder/features/InviteToQuote/InviteToQuoteSlideout/InviteToQuoteForm/enums';
import { InviteToQuoteFormValues } from '../../../builder/features/InviteToQuote/InviteToQuoteSlideout/InviteToQuoteForm/types';
import { Scope } from '../../types/Scope';
import styles from './styles.scss';

export const IncludeScopeOfWorksField = () => {
  const id = 'invite-to-quote--includeScopeOfWorks';

  const { change: setFieldValue, getState } = useForm<InviteToQuoteFormValues>();
  const [isScopeAvailable, setIsScopeAvailable] = useState(true);
  const { values } = getState();
  const selectedPackage = values?.package;

  const { data, loading, error } = useScopesQuery<Scope>(SCOPE_OF_WORKS_QUERY, {
    skip: !selectedPackage,
    variables: { packageId: selectedPackage?.value.toString() },
  });

  const getScopeFileName = (): string => {
    if (data?.scopeByPackage?.scopeFile) {
      return data.scopeByPackage.scopeFile.fileName;
    }

    return `${selectedPackage.label} Scope of Works`;
  };

  useEffect(() => {
    if (!data) {
      setIsScopeAvailable(false);
      return;
    }

    if (data.scopeByPackage === null) {
      setIsScopeAvailable(false);
      setFieldValue(InviteToQuoteFormFieldName.IncludeScopeOfWorks, false);
      return;
    }

    setIsScopeAvailable(true);
    setFieldValue(InviteToQuoteFormFieldName.ScopeOfWorks, data.scopeByPackage);
  }, [data, setFieldValue]);

  useEffect(() => {
    if (error) {
      setIsScopeAvailable(false);
      setFieldValue(InviteToQuoteFormFieldName.IncludeScopeOfWorks, false);
      reportError(error);
    }
  }, [error, setFieldValue]);

  if (loading) {
    return (
      <div data-name={InviteToQuoteFormFieldName.Package}>
        <Label htmlFor={id}>Include Scope of Works</Label>
        <div className={styles.noScopeText}>
          <LoadingSpinner inline center={false} />
        </div>
      </div>
    );
  }

  if (error) {
    return null;
  }

  if (!selectedPackage) {
    return (
      <div data-name={InviteToQuoteFormFieldName.Package}>
        <Label htmlFor={id}>Include Scope of Works</Label>
        <p className={styles.noScopeText}> Select a package first</p>
      </div>
    );
  }

  return (
    <div data-name={InviteToQuoteFormFieldName.Package}>
      <Label htmlFor={id}>Include Scope of Works</Label>
      {!isScopeAvailable ? (
        <p className={styles.noScopeText}> No scope available</p>
      ) : (
        <CheckboxField
          id={id}
          label={getScopeFileName()}
          fieldName={InviteToQuoteFormFieldName.IncludeScopeOfWorks}
          containerClassName={styles.checkbox}
        />
      )}
    </div>
  );
};
