import { useCallback, useState } from 'react';
import { E1Link } from '@estimateone/frontend-components';
import { ConnectionPreferenceSlider } from '@subbie/modal/ConnectionPreferenceSlider';
import { ConnectionPreferenceFormProvider } from '@subbie/modal/ConnectionPreferenceSlider/ConnectionPreferenceFormContext';
import { useCurrentAccount } from '@subbie/context/AccountProvider/hooks';
import { Action, Event, useAnalytics } from '@ascension/components/hooks/Analytics';
import { InterestLevel } from '@ascension/_gqltypes/subbie.generated';
import { GetConnectionPreference_connectionPreference as ConnectionPreference } from '@subbie/modal/ProjectSlider/ProjectSliderBody/types/GetConnectionPreference';
import styles from './styles.scss';

type EmptySubbieListCardProps = {
  connectionPreference: ConnectionPreference | undefined;
  watchlistStatus: InterestLevel | undefined;
};
export const EmptySubbieListCard = ({
  connectionPreference,
  watchlistStatus,
}: EmptySubbieListCardProps) => {
  const [showShareContactDetails, setShowShareContactDetails] = useState(false);
  const { addEvent } = useAnalytics(Event.INTERACT);
  const account = useCurrentAccount();
  const hasSharedContacts =
    connectionPreference?.deletedAt === null && typeof connectionPreference?.contactId === 'number';

  const onCloseSlider = useCallback(() => {
    setShowShareContactDetails(false);
  }, []);

  const onOpenSlider = useCallback(async () => {
    addEvent({
      action: hasSharedContacts
        ? Action.SSC_SHARE_AND_CONNECT_EDIT_BUTTON_CLICKED
        : Action.SSC_SHARE_AND_CONNECT_BUTTON_CLICKED,
      supplierId: account?.id ?? 0,
      projectId: connectionPreference?.projectId ?? 0,
    });
    setShowShareContactDetails(true);
  }, [hasSharedContacts, connectionPreference, addEvent, account?.id]);

  if (!connectionPreference) {
    return null;
  }

  if (hasSharedContacts) {
    return (
      <div className={styles.emptySubbieListCard}>
        <p>
          0 Subcontractors have viewed your contact details.
          <br />
          You will be notified via your Watchlist email when any subcontractor views your contact
          details.
        </p>
      </div>
    );
  }
  return (
    <>
      <div className={styles.emptySubbieListCard}>
        <p>
          Proactively{' '}
          <E1Link onClick={onOpenSlider}>share your contact information with subcontractors</E1Link>{' '}
          and see who has viewed your details, or, use a credit to gain access to those who have
          already indicated their interest.
        </p>
      </div>
      <ConnectionPreferenceFormProvider
        preference={connectionPreference}
        watchlistStatus={watchlistStatus ?? InterestLevel.UNACTIONED}
      >
        <ConnectionPreferenceSlider
          isPaneOpen={showShareContactDetails}
          handleClose={onCloseSlider}
        />
      </ConnectionPreferenceFormProvider>
    </>
  );
};
