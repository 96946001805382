import { InsightIcon, HelpBanner, HelpBannerContent } from '@estimateone/frontend-components';
import { useTranslator } from '@profiles/providers/TranslationsProvider';
import styles from './ProfileInsights.module.scss';

export const CompleteProfileInsightsAlert = () => {
  const translate = useTranslator();

  return (
    <HelpBanner isHidden={false} toggleHidden={() => {}}>
      <HelpBannerContent>
        <div className={styles.alertHeader}>
          <InsightIcon />
          <h3 className={styles.alertHeaderNoMargin}>
            Get seen by more {`${translate('builderLabel')}s`}
          </h3>
        </div>
        <p>
          Make the first move - request or download documents, submit quotes and respond to
          invitations.
        </p>
        <p>
          A fully completed Profile increases the chance a {translate('builderLabel')} will reach
          out to you. For example, showcase your <strong>Project History</strong> to give{' '}
          {`${translate('builderLabel')}s`} insight into your work history.
        </p>
      </HelpBannerContent>
    </HelpBanner>
  );
};
