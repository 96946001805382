import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { Nav } from '@estimateone/frontend-components';
import styles from './ProfileNavigation.module.scss';
import { ProfileRoute } from '@profiles/apps/CompanyProfile/enums';
import { ProfileLayout } from '@profiles/apps/CompanyProfile/types';

export type ProfileNavigationProps = {
  layout: ProfileLayout;
};

const getActiveRoute = (pathname: string) => {
  switch (pathname) {
    case ProfileRoute.ProfileSettings:
      return ProfileRoute.ProfileSettings;
    case ProfileRoute.ProfileInsights:
      return ProfileRoute.ProfileInsights;
    default:
      return ProfileRoute.CompanyProfile;
  }
};

export const ProfileNavigation = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const activeRoute = getActiveRoute(pathname);

  return (
    <div className={styles.leftNavPanel}>
      <Nav>
        <Nav.Item
          onClick={() => navigate(ProfileRoute.CompanyProfile)}
          isActive={activeRoute === ProfileRoute.CompanyProfile}
          label="Company Profile"
        />
        <div className={styles.insightsNav}>
          <Nav.Item
            onClick={() => navigate(ProfileRoute.ProfileInsights)}
            isActive={activeRoute === ProfileRoute.ProfileInsights}
            label="Insights"
          />
        </div>
        <Nav.Item
          onClick={() => navigate(ProfileRoute.ProfileSettings)}
          isActive={activeRoute === ProfileRoute.ProfileSettings}
          label="Profile Settings"
        />
      </Nav>
    </div>
  );
};
