import $ from 'jquery';
import Routing from 'routing';
import moment from 'moment-timezone';
import _ from 'underscore';
import GenericList from '../classes/GenericList';
import { getUserTimezone } from './timezone';
import { getStageTypeAsString, StageStatus, StageType } from '../../enums';
import {
  appendAwardedIndicator,
  extractTemplate,
  setTenderStageLinkHref,
  setTooltipForRow,
} from './stage_table_helper';

const MAX_TRUNCATE_LENGTH = 45;
const ORDER_COLUMN_INDEX = 2;

const truncateText = (text, maxLength = MAX_TRUNCATE_LENGTH) =>
  text.length > maxLength ? text.substring(0, maxLength - 3) + '...' : text;

const createLinkElement = ({ hasAccess, data, id, stageType }) => {
  const $link = $('<a>').text(truncateText(data));

  if (!hasAccess) {
    $link.addClass('disabled-link');
  } else {
    const stageTypeString = getStageTypeAsString(stageType)?.toLowerCase();
    const href = Routing.generate('app_stage_view', { id, stageType: stageTypeString });
    $link.addClass('link').attr('href', href);
  }

  return $link;
};

const createRendererForProjectName =
  (list) =>
  (data, display, { id, stageType, hasAccess = true, hasTeamAccessControl }, meta) => {
    const $template = extractTemplate(list, meta.col);
    const $container = $('<span>');
    const $link = createLinkElement({
      hasAccess,
      data,
      id,
      stageType,
    });

    $container.append($link.prop('outerHTML'));
    if (hasTeamAccessControl) {
      const $restrictedLabel = $template.find('span.restricted-label');
      $container.append(`&nbsp;${$restrictedLabel.prop('outerHTML')}`);
    }
    return $container.prop('outerHTML');
  };

const createRendererForSuburb =
  () =>
  (data, display, { city, stateAbbrev, province }) => {
    const locationParts = [];
    if (data || city) {
      locationParts.push(data || city);
    }
    if (stateAbbrev || province) {
      locationParts.push(stateAbbrev || province);
    }
    return _.escape(locationParts.join(', '));
  };

const createRendererForQuotesDue =
  () =>
  (data, display, { stageType }) => {
    if (stageType === StageType.TYPE_PROCUREMENT) return ' - ';
    if (data) {
      const serverDate = moment.tz(data.date, data.timezone);
      const date = serverDate.clone().tz(getUserTimezone());
      if (display === 'display') {
        return date.format('ddd, D MMM YYYY');
      }
      return date.format('X');
    }
    return '';
  };

const createRendererForStatus =
  (list) =>
  (data, row, { id, status, linkedProcurementStageId, stageType, hasAccess = true }, meta) => {
    const $template = extractTemplate(list, meta.col);
    const isProcurementType = stageType === StageType.TYPE_PROCUREMENT;

    if (hasAccess === false) {
      $template.find('.badge_stage_status_switch').addClass('disabled');
    }

    if (isProcurementType) {
      $template.find('.badge_stage_status_switch').remove();
      $template.find('.badge_procurement_stage_status_switch').attr('data-stage-id', id);
      $template
        .find(`.badge_procurement_stage_status_switch .option[data-status-id=${data}]`)
        .addClass('active');
    } else {
      $template.find('.badge_procurement_stage_status_switch').remove();
      $template.find('.badge_stage_status_switch').attr('data-stage-id', id);
      $template
        .find(`.badge_stage_status_switch .option[data-status-id=${data}]`)
        .addClass('active');
    }

    const $procurementPrompt = $template.find('.procurement-prompt');
    const $procurementStartedMessage = $template.find('.procurement-started-message');

    if (!$procurementPrompt.length || !(status === StageStatus.TENDER_AWARDED)) {
      $procurementPrompt.remove();
      $procurementStartedMessage.remove();
      return $template.html();
    }

    if (linkedProcurementStageId) {
      $procurementPrompt.remove();
      return $template.html();
    }

    $procurementStartedMessage.remove();
    appendAwardedIndicator($procurementPrompt);
    setTenderStageLinkHref(
      $procurementPrompt.find('.tender-stage-handover-wizard-link'),
      id,
      stageType,
      hasAccess,
    );

    return $template.html();
  };

const createRendererForOptions =
  (list) =>
  (data, row, { id, hasAccess = true }, meta) => {
    const $template = extractTemplate(list, meta.col);
    $template.find('.unarchive-stage-dropdown').attr('data-stage-id', id);

    if (!hasAccess) {
      $template
        .find('button[data-toggle=dropdown]')
        .prop('disabled', true)
        .addClass('disabled-link');
    }

    return $template.html();
  };

$(() => {
  const $closedTenderTable = $('table.closed-tender-table');

  const defineColumnsForClosedTender = (list) => {
    return [
      {
        data: 'projectName',
        render: createRendererForProjectName(list),
      },
      {
        data: 'suburb',
        render: createRendererForSuburb(),
      },
      {
        data: 'tenderQuotesDue',
        class: 'quotes_due',
        render: createRendererForQuotesDue(),
      },
      {
        data: 'status',
        orderable: false,
        render: createRendererForStatus(list),
      },
      {
        data: null,
        orderable: false,
        render: createRendererForOptions(list),
      },
    ];
  };

  const defineDataTableSettingsForClosedTender = (list, $table) => {
    return {
      data: list.data,
      order: [[ORDER_COLUMN_INDEX, 'desc']],
      language: {
        emptyTable: "You don't have any recently closed tenders on E1.",
      },
      createdRow: (row, data, dataIndex) => {
        if (data.hasAccess === false) {
          setTooltipForRow($table.data('access-restricted-tooltip'), row);
        }
      },
      columns: defineColumnsForClosedTender(list),
    };
  };

  const attachEventsForClosedTender = (projectTable) => {
    $(document).on('stage-archive-changed stage_status_updated', document, () => {
      projectTable.updateTable(true);
    });
  };

  if ($closedTenderTable.length > 0) {
    $closedTenderTable.each(function () {
      const $table = $(this);

      const status = $table.attr('data-status') || null;
      const months = $table.attr('data-months') || 12;

      const url = Routing.generate('app_stage_fetch_closed', { status, months });

      const projectTable = new GenericList(
        $table,
        (list) => {
          const dataTableSettings = defineDataTableSettingsForClosedTender(list, $table);
          list.table = list.$target.DataTable(dataTableSettings);
          list.$target.closest('.loading-container').addClass('has-loaded');
        },
        url,
        (data) => data.stages,
        (list, row) => {
          row.DT_RowId = row.id;
          return row;
        },
      );

      attachEventsForClosedTender(projectTable);
    });
  }
});
