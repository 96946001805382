import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { NOTES_FOR_LETTING_SCHEDULE } from '../queries';
import {
  GetNotesForLettingSchedule,
  GetNotesForLettingScheduleVariables,
  GetNotesForLettingSchedule_package_lettingScheduleDetails_notes as LettingScheduleNote,
} from '../types/GetNotesForLettingSchedule';
import { EntityId } from '@ascension/types';

type UseGetNotesForLettingSchedule = {
  loading: boolean;
  notes: LettingScheduleNote[];
};

export const useGetNotesForLettingSchedule = (
  packageId: EntityId,
): UseGetNotesForLettingSchedule => {
  const { data, loading } = useQuery<
    GetNotesForLettingSchedule,
    GetNotesForLettingScheduleVariables
  >(NOTES_FOR_LETTING_SCHEDULE, {
    variables: { packageId },
  });

  const notes = useMemo(
    () => data?.package?.lettingScheduleDetails?.notes ?? [],
    [data?.package?.lettingScheduleDetails?.notes],
  );

  return {
    notes,
    loading,
  };
};
