import $ from 'jquery';
import _ from 'underscore';

function DataTableFilter(id, fieldOptions) {
  this.id = id;
  this.fieldOptions = fieldOptions;
  this.operatorOptions = {
    contains: {
      name: 'Contains',
      type: ['text'],
    },
    contains_all: {
      name: 'Contains all of',
      type: ['list', 'tag'],
    },
    contains_any_of: {
      name: 'Contains any of',
      type: ['list', 'tag'],
    },
    contains_none_of: {
      name: 'Contains none of',
      type: ['list', 'tag'],
    },
    not_contains: {
      name: 'Does not contain',
      type: ['text'],
    },
    '=': {
      name: 'Equals',
      type: ['text'],
    },
    '!=': {
      name: 'Does not equal',
      type: ['text'],
    },
    '<': {
      name: 'Less than',
      type: ['text'],
    },
    '>': {
      name: 'Greater Than',
      type: ['text'],
    },
  };
  this.column = null;
  this.operator = null;
  this.value = null;
  this.valueOptions = [];
}

DataTableFilter.prototype = {
  draw() {
    const self = this;

    const $fieldSelect = self.getFieldInput();
    const $operatorOptions = self.getOperatorInput();
    const $valueInput = self.getValueInput();

    const $row = $('<tr />').append(
      $('<td />').addClass('col-sm-4').append($fieldSelect),
      $('<td />').addClass('col-sm-3').append($operatorOptions),
      $('<td />').addClass('col-sm-4').append($valueInput),
      $('<td />')
        .addClass('col-sm-1')
        .append(
          $('<button />')
            .addClass('btn btn-icon btn-transparent data-table-remove-filter')
            .append($('<i />').addClass('icon icon-lg icon-clear-mini')),
        ),
    );
    $row.addClass('data-table-filter-row').attr('data-id', self.id);
    return $row;
  },
  getType() {
    const self = this;
    const field = _.findWhere(self.fieldOptions, { value: self.column });
    if (typeof field === 'undefined') {
      return null;
    }
    return field.type;
  },
  getValueOptions() {
    const self = this;
    const field = _.findWhere(self.fieldOptions, { value: self.column });
    if (typeof field === 'undefined') {
      return null;
    }
    return field.valueOptions;
  },
  getFieldInput() {
    const self = this;
    const $fieldSelect = $('<select />')
      .addClass('form-control data-table-filter-field')
      .attr('name', 'filter-field');
    $fieldSelect.append($('<option />').text('[Please Select]'));
    $.each(self.fieldOptions, (index, field) => {
      $fieldSelect.append(
        $('<option />')
          .val(field.value)
          .text(field.name)
          .attr('selected', field.value == self.column),
      );
    });

    return $fieldSelect;
  },
  getOperatorInput() {
    const self = this;
    const selectedType = self.getType();
    const $operatorOptions = $('<select />').addClass('form-control data-table-filter-operator');

    $operatorOptions.append($('<option />').text('[Please Select]'));
    if (selectedType !== null) {
      $.each(self.operatorOptions, (key, option) => {
        if (self.operatorOptions.hasOwnProperty(key)) {
          if (_.contains(option.type, selectedType)) {
            $operatorOptions.append(
              $('<option />')
                .val(key)
                .text(option.name)
                .attr('selected', key == self.operator),
            );
          }
        }
      });
    }
    return $operatorOptions;
  },
  getValueInput() {
    const self = this;
    let $input = null;
    const type = self.getType();
    switch (type) {
      case 'list':
      case 'tag':
        $input = $('<select />')
          .addClass('form-control data-table-filter-value select2')
          .attr('multiple', true)
          .append($('<option />').text('Please Select'));
        $.each(self.getValueOptions(), (i, option) => {
          $input.append(
            $('<option />')
              .val(option.id)
              .text(option.name)
              .attr('selected', _.contains(self.value, option.id)),
          );
        });
        break;
      default:
        $input = $('<input />')
          .attr('type', 'text')
          .addClass('form-control data-table-filter-value')
          .attr('value', self.value);
    }
    return $input;
  },
  /**
   * this will eventually check values for validity. for now, making sure they're not null is fine
   * @returns {boolean}
   */
  isValid() {
    const self = this;
    let valid = true;
    if (self.column == null) {
      valid = false;
    }
    if (self.operator == null) {
      valid = false;
    }
    if (self.value == null) {
      valid = false;
    }
    return valid;
  },
  applyToData(data) {
    const filter = this;
    let tableData = data[filter.column];

    let searchValue = filter.value;
    const type = filter.getType();
    if (type == 'text') {
      searchValue = searchValue.toLowerCase();
      tableData = tableData.toLowerCase();
    }

    let passed = true;
    switch (filter.operator) {
      case 'contains':
        passed = tableData.indexOf(searchValue) >= 0;
        break;
      case 'not_contains':
        passed = tableData.indexOf(searchValue) < 0;
        break;
      case '=':
        passed = tableData == searchValue;
        break;
      case '!=':
        passed = tableData != searchValue;
        break;
      case '<':
        passed = parseFloat(tableData) < parseFloat(searchValue);
        break;
      case '>':
        passed = parseFloat(tableData) > parseFloat(searchValue);
        break;
      case 'contains_all':
        var dataArrStrings = tableData.split(',');
        var dataArrInts = _.map(dataArrStrings, (str) => parseInt(str));

        var intersect = _.intersection(searchValue, dataArrInts);
        passed = intersect.length === searchValue.length;
        break;
      case 'contains_any_of':
        var dataArrStrings = tableData.split(',');
        var dataArrInts = _.map(dataArrStrings, (str) => parseInt(str));

        var intersect = _.intersection(searchValue, dataArrInts);
        passed = intersect.length > 0;
        break;
      case 'contains_none_of':
        var dataArrStrings = tableData.split(',');
        var dataArrInts = _.map(dataArrStrings, (str) => parseInt(str));

        var diff = _.difference(searchValue, dataArrInts);
        passed = diff.length === searchValue.length;
        break;
    }
    return passed;
  },
};

export function DataTableFilterForm(dataTable) {
  this.dataTable = dataTable;
  this.fields = [];
  this.filters = [];
  this.filterIds = [];
  this.$container = null;

  const self = this;

  self.init();
}

DataTableFilterForm.prototype = {
  init() {
    const self = this;
    self.dataTable.columns().every(function (i) {
      const col = this;
      const index = col.index();
      const header = col.header();
      if ($(header).hasClass('data-table-searchable')) {
        let type = $(header).attr('data-filter-type');
        if (type == null) {
          type = 'text';
        }
        let options = [];
        const optionAttr = $(header).attr('data-filter-options');
        if (optionAttr != null) {
          options = JSON.parse(optionAttr);
        }
        self.fields.push({
          value: index,
          name: $(header).text(),
          type,
          valueOptions: options,
        });
      }
    });
    const $table = self.initTable();

    self.$container = $('<div />')
      .addClass('data-table-filter-container')
      .append($table)
      .append(
        $('<a />')
          .addClass('btn btn-secondary data-table-add-filter-trigger pull-left')
          .attr('role', 'button')
          .text('Add Filter'),
        $('<div />').addClass('clearfix'),
      );
  },
  initTable() {
    const self = this;
    const $table = $('<table />')
      .addClass('table table-responsive data-table-filter-table')
      .append(
        $('<thead />').append(
          $('<tr />').append(
            $('<th />').text('Field'),
            $('<th />').text('Operator'),
            $('<th />').text('Value'),
            $('<th />'),
          ),
        ),
        $('<tbody />'),
      );
    return $table;
  },
  draw() {
    const self = this;
    const $table = self.drawTable();
    self.$container.find('table').html($table);
  },
  drawTable() {
    const self = this;
    const $table = self.$container.find('table').clone();
    const $tbody = $table.find('tbody');

    $tbody.html('');
    if (self.filters.length > 0) {
      $.each(self.filters, (i, filter) => {
        $tbody.append(filter.draw());
      });
    } else {
      $tbody.append(
        $('<tr />').append(
          $('<td />')
            .attr('colspan', 4)
            .append($('<p />').text('There are currently no applied filters.')),
        ),
      );
    }
    return $table.html();
  },
  repaint($target) {
    const self = this;
    self.draw();
    $target.html(self.getFormContainer().html());
    $target.find('select').select2({
      theme: 'bootstrap',
      minimumResultsForSearch: 10,
    });
  },
  getFormContainer() {
    const self = this;
    return self.$container;
  },
  addFilter() {
    const self = this;
    const filterId = self.getNextFilterId();
    self.filters.push(new DataTableFilter(filterId, self.fields));
    self.filterIds.push(filterId);
  },
  removeFilter(id) {
    const self = this;
    let index = null;
    $.each(self.filters, (i, filter) => {
      if (filter.id == id) {
        index = i;
      }
    });
    if (index != null) {
      self.filters.splice(index, 1);
    }
  },
  getFilter(id) {
    const self = this;
    let returnFilter = null;
    $.each(self.filters, (i, filter) => {
      if (filter.id == id) {
        returnFilter = filter;
      }
    });
    return returnFilter;
  },
  getNextFilterId() {
    const self = this;
    return self.filterIds.length > 0 ? self.filterIds[self.filterIds.length - 1] + 1 : 1;
  },
  getFilters() {
    return this.filters;
  },
  applyFilters() {
    const self = this;
    const newFilters = [];
    const newFilterIds = [];
    $.each(self.filters, (i, filter) => {
      if (filter.isValid()) {
        newFilters.push(filter);
        newFilterIds.push(filter.id);
      }
    });
    self.filters = newFilters;
    self.filterIds = newFilterIds;

    self.dataTable.draw();
  },
  addField(value, name, type) {
    const self = this;
    self.fields.push({
      value,
      name,
      type,
    });
  },
};
