import { reportError } from '../../../../../../helpers/errorReporter';
import { useScopesMutation } from '@scope-of-works/hooks/useScopesMutation';
import { ADD_RFQ_TO_SCOPE } from '@scope-of-works/mutations';
import { ScopeRfqInput } from '@ascension/_gqltypes/scope-of-works.generated';
import { CreateScopeRfq, CreateScopeRfqVariables } from '@scope-of-works/types/CreateScopeRfq';

export const useCreateScopeRfq = () => {
  const [createScopeRfq] = useScopesMutation<CreateScopeRfq, CreateScopeRfqVariables>(
    ADD_RFQ_TO_SCOPE,
  );
  const createScopeRfqWith = (scopeRfqInput: ScopeRfqInput) =>
    createScopeRfq({ variables: { input: scopeRfqInput } }).catch((error: Error) => {
      reportError(error);
    });

  return { createScopeRfq: createScopeRfqWith };
};
