/* eslint-disable fp/no-mutation */
import Routing from 'routing';
import qs from 'query-string';
import E1Request, { E1Response } from '@ascension/js/classes/E1Request';
import SuperSlider from '@ascension/js/classes/SuperSlider';
import quillify from '../../utils/quillify';
import { viewHistory } from '../stage_responses_list';
import NewCorrespondenceAttachmentUploadModule from '../user_correspondence_attachment_upload';
import { UploadParentType } from '@ascension/enums';
import { EntityId } from '@ascension/types';

export const showNotesHistory = async () => {
  const originalQueryStringParts = qs.parse(window.location.search);
  const stageId = Number($('.stage-invite').attr('data-stage-id'));
  const rfqId = Number(originalQueryStringParts.rfq);
  if (!Number.isNaN(rfqId) && !Number.isNaN(stageId)) {
    await viewHistory(stageId, rfqId, 'notes');
  }
};

export async function showRfqEmailErrorModal(stageId: string, rfqId: string) {
  const route = Routing.generate('app_stagerfq_emailerror', {
    id: stageId,
    rfqId,
  });
  const request = new E1Request(route);
  request.show_loading_modal = true;
  await request.submit();
}

const showNoRfqsModal = async (stageId: EntityId) =>
  new E1Request(Routing.generate('app_stagecorrespondence_norfqsmodal', { id: stageId }))
    .setShowLoadingModal()
    .submit();

const showComposeModal = async (stageId: EntityId, rfqIds: EntityId[]) => {
  const url = Routing.generate('app_stagecorrespondence_validate_recipients', { id: stageId });
  const { revoked_rfqs: revokedRfqs } = await new E1Request<
    // eslint-disable-next-line camelcase
    E1Response & { revoked_rfqs: EntityId[] }
  >(url, 'POST', { rfqIds }).submit();

  const recipientsIds = rfqIds.filter((rfqId) => !revokedRfqs.includes(rfqId));

  const slider = new SuperSlider();

  const { success } = await slider
    .setUrl(Routing.generate('app_stagecorrespondence_compose', { id: stageId }))
    .submit();

  if (success) {
    quillify('.rfq_correspondence_content.quill-wrapper', {
      useExistingData: true,
    });
    const plural = recipientsIds.length === 1 ? '' : 's';
    slider.$slider.find('#rfq_correspondence_item_rfqs').val(recipientsIds.join(','));
    slider.$slider
      .find('[data-hook="rfqContactCount"]')
      .text(`${recipientsIds.length} Contact${plural}...`);
    slider.$slider.find('button[type="submit"]').prop('disabled', recipientsIds.length === 0);

    const $ctn = slider.$slider.find('.file-uploader');
    const $target = $ctn.find('.uploader');
    if ($target.length) {
      const $rfqCorrForm = slider.$slider.find('.new-correspondence-form');
      // eslint-disable-next-line no-new
      new NewCorrespondenceAttachmentUploadModule(
        $rfqCorrForm,
        $target,
        $target.data('key'),
        $target.data('stage'),
        UploadParentType.RFQ_CORR_ATTACH,
      );
    }
  }
};

export const showRfqCorrespondenceComposeModal = (stageId: EntityId, rfqIds: EntityId[]) =>
  rfqIds.length === 0 ? showNoRfqsModal(stageId) : showComposeModal(stageId, rfqIds);
