import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  Button,
  ButtonVariant,
  LoadingSpinner,
  Modal,
  ModalSize,
} from '@estimateone/frontend-components';
import { useOperatingCountryId } from '@ascension/components/localisation';
import { CompanyCapabilitiesForm } from '@profiles/features/BuildProfile/CompanyCapabilitiesForm';
import { CompanyProfileForm } from '@profiles/features/BuildProfile/CompanyProfileForm';
import { CompanyDetailsModalFlow } from '@subbie/features/NetworkEnrolmentModals/ConfirmCompanyDetailsModal/CompanyDetailsModalFlow';
import {
  Action as AnalyticsAction,
  useInteractAnalytics,
} from '@ascension/components/hooks/Analytics';
import { GET_PROFILE } from '@profiles/features/BuildProfile/queries';
import { GetCurrentProfileQuery } from '@profiles/features/BuildProfile/queries.generated';
import styles from './styles.scss';

type ConfirmCompanyDetailsModalProps = {
  onComplete: () => void;
  isOpen: boolean;
  flow: CompanyDetailsModalFlow;
};

enum FormId {
  CompanyProfileFormId = 'company-profile-form',
  CompanyCapabilitiesFormId = 'company-capabilities-form',
}

const ModalWrapper = ({ children, isOpen }: { children: React.ReactNode; isOpen: boolean }) => (
  <Modal isOpen={isOpen} onRequestClose={() => null} hideClose size={ModalSize.Small}>
    <Modal.Section>{children}</Modal.Section>
  </Modal>
);

export const ConfirmCompanyDetailsModal = ({
  onComplete,
  isOpen,
  flow,
}: ConfirmCompanyDetailsModalProps) => {
  const { data, loading } = useQuery<GetCurrentProfileQuery>(GET_PROFILE);
  const [activeForm, setActiveForm] = useState(FormId.CompanyProfileFormId);
  const countryId = useOperatingCountryId();
  const { addEvent } = useInteractAnalytics();

  useEffect(() => {
    if (isOpen) {
      addEvent({
        action: AnalyticsAction.SUBBIE_NETWORK_ENROLMENT_MODAL_OPENED,
        flow,
      });
    }
  }, [isOpen]);

  if (loading || !data || !data.currentProfile) {
    return (
      <ModalWrapper isOpen={isOpen}>
        <LoadingSpinner />
      </ModalWrapper>
    );
  }

  const { offices } = data.currentProfile;
  const defaultOffice = offices?.find(
    (office: { isDefaultOffice: boolean }) => office.isDefaultOffice,
  );
  const people = data.currentProfile.people.reduce(
    (acc, { id, firstName, fullName, lastName, isDefaultContact, contactable }) =>
      contactable?.email
        ? [
            ...acc,
            {
              id: Number(id.replace('u-', '')),
              isDefaultContact,
              email: contactable.email,
              firstName,
              fullName,
              lastName,
            },
          ]
        : acc,
    [],
  );

  const handleCancelForm = () => {
    onComplete();
    addEvent({
      action: AnalyticsAction.SUBBIE_NETWORK_ENROLMENT_MODAL_CLOSED,
      flow,
      stage: activeForm === FormId.CompanyProfileFormId ? 'CompanyDetails' : 'CompanyCapabilities',
    });
  };

  const handleShowNextForm = () => {
    setActiveForm(FormId.CompanyCapabilitiesFormId);
    addEvent({
      action: AnalyticsAction.SUBBIE_NETWORK_ENROLMENT_MODAL_NEXT,
      flow,
      stage: activeForm === FormId.CompanyProfileFormId ? 'CompanyDetails' : 'CompanyCapabilities',
    });
  };

  const handleCompleteForm = () => {
    onComplete();
    addEvent({
      action: AnalyticsAction.SUBBIE_NETWORK_ENROLMENT_COMPLETED,
      flow,
    });
  };

  return (
    <ModalWrapper isOpen={isOpen}>
      <div className={styles.header}>
        <h3>Confirm Company Details</h3>

        <p>
          For your Company to be discovered in the Network the following details must be provided.
        </p>
      </div>

      {activeForm === FormId.CompanyProfileFormId ? (
        <CompanyProfileForm
          formId={FormId.CompanyProfileFormId}
          onSuccess={handleShowNextForm}
          countryId={countryId}
          defaultOfficeAddress={defaultOffice?.address ?? null}
          companyNumber={data.currentProfile.companyNumber ?? null}
          companyName={data.currentProfile.name ?? null}
          businessEntityType={data.currentProfile.businessEntityType ?? null}
          showPrimaryContactSelect
          people={people}
        />
      ) : (
        <CompanyCapabilitiesForm
          formId={FormId.CompanyCapabilitiesFormId}
          initialStockTrades={data.currentProfile.trades}
          initialContractSizeMin={data.currentProfile.contractSizeMin}
          initialContractSizeMax={data.currentProfile.contractSizeMax}
          initialServiceArea={defaultOffice?.serviceArea}
          onSuccess={handleCompleteForm}
        />
      )}

      <div className={styles.footer}>
        <div className={styles.buttonContainer}>
          <Button variant={ButtonVariant.Grey} onClick={handleCancelForm}>
            Hide my Profile
          </Button>
          <Button variant={ButtonVariant.Primary} type="submit" form={activeForm}>
            Confirm
          </Button>
        </div>

        <p>You can edit your profile and manage your visibility in Profile Settings.</p>
      </div>
    </ModalWrapper>
  );
};
