import { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { joinClassNames, RadioButton } from '@estimateone/frontend-components';
import { useCloseDrawer } from '@estimateone/frontend-components/src/components/Drawer';
import { FormApi } from 'final-form';
import { useAtom } from 'jotai';
import { selectedFiltersAtom, RelationshipTypeEnum } from '../../atoms';
import { CategoryFilter } from './CategoryFilter';
import { CompanyListFilter } from './CompanyListFilter';
import { ContractSizeFilter, maxOptions, minOptions } from './ContractSizeFilter';
import { DistanceFilter } from './DistanceFilter';
import { LocationFilter } from './LocationFilter';
import { SectionTitle } from './SectionTitle';
import { SubbieNetworkFilterFormSpy } from './SubbieNetworkFilterFormSpy';
import { TradeFilter } from './TradeFilter';
import { WorkforceSizeFilter, WorkforceSizeOpts } from './WorkforceSizeFilter';
import { getDistanceOptions } from './utils';
import { validateForm } from './validator';
import { LabelledOption, DistanceUnit } from '@shared';
import {
  useTradesOptions,
  useCategoryOptions,
  useCompanyTags,
  useUserImperialSetting,
  useAccountPrimaryCountryId,
} from '../../providers/AscensionDataProvider/hooks';
import { useCompanyListFilterVisible } from '@builder/features/SubbieNetwork/components/SubbieNetworkFilterDrawer/hooks';
import { Country } from '@ascension/enums';
import {
  SubbieNetworkFilterFormFields,
  SubbieNetworkFilterFormValues,
  SubbieNetworkFilterFormId,
} from './types';
import styles from './styles.module.scss';

const findOption = (options: LabelledOption<number>[] | null, selectedValue: number | undefined) =>
  selectedValue && options ? options.find(({ value }) => selectedValue === value) : undefined;

/*
  Do not delete as we'll enable Relationship filter in the future
  const getRelationshipType = (
    values: SubbieNetworkFilterFormValues,
  ): RelationshipTypeEnum | undefined => {
    if (
      values[SubbieNetworkFilterFormFields.RelationshipTypeAddressBook] &&
      values[SubbieNetworkFilterFormFields.RelationshipTypeNotInAddressBook]
    ) {
      return RelationshipTypeEnum.All;
    }
    if (values[SubbieNetworkFilterFormFields.RelationshipTypeAddressBook]) {
      return RelationshipTypeEnum.AddressBook;
    }
    if (values[SubbieNetworkFilterFormFields.RelationshipTypeNotInAddressBook]) {
      return RelationshipTypeEnum.NotInAddressBook;
    }
    return undefined;
  };
  */

const addressBookSelected: RelationshipTypeEnum[] = [
  RelationshipTypeEnum.AddressBook,
  RelationshipTypeEnum.All,
];
const notInAddressBookSelected: RelationshipTypeEnum[] = [
  RelationshipTypeEnum.NotInAddressBook,
  RelationshipTypeEnum.All,
];

const isSet = (field: unknown) => field !== undefined && field !== null && field !== false;

export const SubbieNetworkFilterForm = () => {
  const [selectedFilterValues, setSelectedFiltersAtom] = useAtom(selectedFiltersAtom);
  const tradeOpts = useTradesOptions();
  const categoryOptions = useCategoryOptions();
  const companyTagOptions = useCompanyTags();
  const closeTheDrawer = useCloseDrawer();
  const companyListFilterVisible = useCompanyListFilterVisible();
  const primaryCountryId = useAccountPrimaryCountryId();
  const useImperial = useUserImperialSetting();
  const distanceUnit = useImperial ? DistanceUnit.MILES : DistanceUnit.KM;

  const [initialFormValues, setInitialFormValues] = useState<SubbieNetworkFilterFormValues>({
    [SubbieNetworkFilterFormFields.TradeFilterId]: tradeOpts?.find(
      ({ value }) => value === selectedFilterValues.stockTradeId,
    ),
    [SubbieNetworkFilterFormFields.WorkforceFilterId]: WorkforceSizeOpts.find(
      ({ value }) => value === selectedFilterValues.workforceSize,
    ),
    [SubbieNetworkFilterFormFields.Location]:
      selectedFilterValues.lat && selectedFilterValues.lon && selectedFilterValues.locationLabel
        ? {
            serviceArea: selectedFilterValues.serviceArea ?? undefined,
            lat: selectedFilterValues.lat,
            lon: selectedFilterValues.lon,
            locationLabel: selectedFilterValues.locationLabel,
          }
        : undefined,
    [SubbieNetworkFilterFormFields.Distance]: getDistanceOptions(distanceUnit).find(
      ({ value }) => value === selectedFilterValues.distance,
    ),
    [SubbieNetworkFilterFormFields.CategoryFilterId]: findOption(
      categoryOptions,
      selectedFilterValues.categoryId,
    ),
    [SubbieNetworkFilterFormFields.CompanyListFilterId]: companyTagOptions?.find(
      ({ value }) => value === selectedFilterValues.companyListId,
    ),
    [SubbieNetworkFilterFormFields.RelationshipTypeAddressBook]:
      selectedFilterValues.relationshipType &&
      addressBookSelected.includes(selectedFilterValues.relationshipType),
    [SubbieNetworkFilterFormFields.RelationshipTypeNotInAddressBook]:
      selectedFilterValues.relationshipType &&
      notInAddressBookSelected.includes(selectedFilterValues.relationshipType),
    [SubbieNetworkFilterFormFields.ContractSizeMin]: minOptions.find(
      ({ value }) => value === selectedFilterValues.contractSizeMin,
    ),
    [SubbieNetworkFilterFormFields.ContractSizeMax]: maxOptions.find(
      ({ value }) => value === selectedFilterValues.contractSizeMax,
    ),
    [SubbieNetworkFilterFormFields.SearchArea]:
      selectedFilterValues.searchArea ?? 'office-location',
  });

  const handleFormSubmit = (values: SubbieNetworkFilterFormValues): void => {
    setSelectedFiltersAtom({
      stockTradeId: values.tradeFilter?.value,
      workforceSize: values.workforceFilter?.value,
      distance: values.distance?.value,
      distanceLabel: values.distance?.label,
      lat: values.location?.lat,
      lon: values.location?.lon,
      locationLabel: values.location?.locationLabel,
      serviceArea: values.location?.serviceArea,
      categoryId: values.categoryFilter?.value,
      companyListId: values.companyListFilter?.value,
      /*
      Do not delete as we'll enable Relationship filter in the future
      relationshipType: getRelationshipType(values),
      */
      relationshipType: undefined,
      contractSizeMin: values.contractSizeMin?.value,
      contractSizeMax: values.contractSizeMax?.value,
      searchArea: values.searchArea,
    });
    closeTheDrawer();
  };

  const handleReset = (form: FormApi<SubbieNetworkFilterFormValues>) => () => {
    setInitialFormValues({});
    form.restart();
  };

  const officeLocationPlaceholder =
    primaryCountryId === Country.COUNTRY_AUSTRALIA
      ? 'Find a suburb or postcode...'
      : 'Find a city or postcode...';

  const serviceAreaPlaceholder =
    primaryCountryId === Country.COUNTRY_AUSTRALIA
      ? 'Find a state, suburb or postcode...'
      : 'Find a city or postcode...';

  return (
    <Form<SubbieNetworkFilterFormValues>
      onSubmit={handleFormSubmit}
      initialValues={initialFormValues}
      validate={validateForm}
    >
      {({ handleSubmit, values, submitFailed, errors, form }) => (
        <form
          id={SubbieNetworkFilterFormId}
          onSubmit={handleSubmit}
          className={styles.form}
          onReset={handleReset(form)}
        >
          <SubbieNetworkFilterFormSpy />
          <SectionTitle
            valueSelected={isSet(values[SubbieNetworkFilterFormFields.TradeFilterId])}
            hasError={
              submitFailed &&
              !!errors &&
              errors[SubbieNetworkFilterFormFields.TradeFilterId]?.length > 0
            }
          >
            Trades
          </SectionTitle>
          <TradeFilter tradeOptions={tradeOpts} />

          <SectionTitle
            valueSelected={
              isSet(values[SubbieNetworkFilterFormFields.Distance]) &&
              isSet(values[SubbieNetworkFilterFormFields.Location])
            }
            hasError={!!errors && errors[SubbieNetworkFilterFormFields.Location]?.length > 0}
          >
            Search Area
          </SectionTitle>
          <div className={styles.searchArea}>
            <Field
              name={SubbieNetworkFilterFormFields.SearchArea}
              type="radio"
              id="office-location"
              value="office-location"
            >
              {({ input }) => (
                <div>
                  <RadioButton id="office-location-input" className={styles.radioButton} {...input}>
                    Office Location
                  </RadioButton>
                  <div className={styles.labelContent}>
                    <p>Find companies with an office located in the area selected</p>
                    <div
                      className={joinClassNames(
                        styles.filters,
                        styles.colSpan1,
                        !input.checked && styles.hidden,
                      )}
                    >
                      <LocationFilter
                        countryId={primaryCountryId ?? Country.COUNTRY_AUSTRALIA}
                        unit={distanceUnit}
                        placeholder={officeLocationPlaceholder}
                      />
                    </div>
                    <div
                      className={joinClassNames(
                        styles.filters,
                        styles.colSpan1,
                        !input.checked && styles.hidden,
                      )}
                    >
                      <DistanceFilter unit={distanceUnit} />
                    </div>
                  </div>
                </div>
              )}
            </Field>
            <Field
              name={SubbieNetworkFilterFormFields.SearchArea}
              type="radio"
              id="service-area"
              value="service-area"
            >
              {({ input }) => (
                <div>
                  <RadioButton id="service-area-input" className={styles.radioButton} {...input}>
                    Service Area
                  </RadioButton>
                  <div className={styles.labelContent}>
                    <p>Find companies willing to work in the area selected</p>
                    <div
                      className={joinClassNames(styles.filters, !input.checked && styles.hidden)}
                    >
                      <LocationFilter
                        countryId={primaryCountryId ?? Country.COUNTRY_AUSTRALIA}
                        unit={distanceUnit}
                        placeholder={serviceAreaPlaceholder}
                        placePredictionsTypes={
                          primaryCountryId === Country.COUNTRY_AUSTRALIA
                            ? [
                                'locality',
                                'administrative_area_level_3',
                                'postal_code',
                                'administrative_area_level_1',
                              ]
                            : undefined
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
            </Field>
          </div>
          {/* Do not delete as we'll enable Relationship filter in the future
          <SectionTitle
            valueSelected={
              isSet(values[SubbieNetworkFilterFormFields.RelationshipTypeAddressBook]) ||
              isSet(values[SubbieNetworkFilterFormFields.RelationshipTypeNotInAddressBook])
            }
          >
            Relationship
          </SectionTitle>
          <RelationshipTypeFilter /> */}
          <SectionTitle
            valueSelected={
              isSet(values[SubbieNetworkFilterFormFields.ContractSizeMin]) ||
              isSet(values[SubbieNetworkFilterFormFields.ContractSizeMax])
            }
          >
            Contract Size
          </SectionTitle>
          <div>
            <ContractSizeFilter />
          </div>
          <SectionTitle
            valueSelected={isSet(values[SubbieNetworkFilterFormFields.WorkforceFilterId])}
          >
            Employees
          </SectionTitle>
          <div className={styles.colSpan1}>
            <WorkforceSizeFilter />
          </div>
          <SectionTitle
            valueSelected={isSet(values[SubbieNetworkFilterFormFields.CategoryFilterId])}
          >
            Category
          </SectionTitle>
          <CategoryFilter categoryOptions={categoryOptions} dropUp={!companyListFilterVisible} />
          {companyListFilterVisible && (
            <>
              <SectionTitle
                valueSelected={isSet(values[SubbieNetworkFilterFormFields.CompanyListFilterId])}
              >
                Company List
              </SectionTitle>
              <CompanyListFilter companyTagOptions={companyTagOptions} />
            </>
          )}
        </form>
      )}
    </Form>
  );
};
