import $ from 'jquery';
import Routing from 'routing';
import E1Request from '../classes/E1Request';
import { updateProgressBar } from '../utils/helpers';
import { getStageTypeAsString } from '../../enums';
import { fetchMatrixDetails } from './stage_matrix_details';

$(() => {
  $(document).on('mfpOpen', () => {
    const magnificPopupInstance = $.magnificPopup.instance;
    const container = magnificPopupInstance.contentContainer;
    const $modal = $(container);
    const $noticeDiv = $modal.find('.pending_matrix_change_notice');

    if (!$noticeDiv.length) return;

    const redirectOnMatrixSave =
      typeof window.redirectOnMatrixSave === 'boolean' ? window.redirectOnMatrixSave : false;

    const stageId = parseInt($noticeDiv.attr('data-stage-id'), 10);
    const stageType = parseInt($noticeDiv.attr('data-stage-type'), 10);
    const totalNotifications = parseInt($noticeDiv.find('span.total').text(), 10);
    const $gridContainer = $('#gridContainer');

    let addendumId = $gridContainer.attr('data-addendum');
    addendumId =
      addendumId === '' || typeof addendumId === 'undefined' ? null : parseInt(addendumId, 10);

    const route = Routing.generate('app_stagematrix_checkpendingchange', {
      id: stageId,
      redirect: redirectOnMatrixSave,
    });

    const request = new E1Request(route);
    request.passive = true;
    request.extraCallback = (response) => {
      if (response.success) {
        if (response.matrix_change_processed) {
          if (addendumId !== null && !response.should_redirect) {
            const packagesRoute = Routing.generate(response.redirect_route, {
              id: stageId,
              stageType: getStageTypeAsString(stageType)?.toLowerCase() ?? 'tender',
              pendingMatrixChangeProcessed: true,
            });

            window.location = packagesRoute;
            return;
          }

          updateProgressBar($modal, response.processed_notifications, 100);
          if (response.should_redirect) {
            let redirectUrl = $('#grid').attr('data-redirect-url');
            if (redirectUrl == null || redirectUrl == '') {
              redirectUrl = Routing.generate(response.redirect_route, {
                id: stageId,
                stageType: getStageTypeAsString(stageType)?.toLowerCase() ?? 'tender',
              });
            }

            // Emulate all notifications sent, as the matrix change has already
            // been processed and as such we dont have the count of sent notifications.
            updateProgressBar($modal, totalNotifications, 100);

            window.location = redirectUrl;
          } else {
            fetchMatrixDetails(true, false);
            magnificPopupInstance.close();
          }
          return;
        }

        const complete = Math.floor((response.processed_notifications / totalNotifications) * 100);
        updateProgressBar($modal, response.processed_notifications, complete);

        setTimeout(() => {
          request.submit();
        }, 2000); // 2 seconds
      }
    };
    request.submit();
  });
});
