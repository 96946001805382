import { Table, TableColumn } from '@estimateone/frontend-components';
import moment from 'moment-timezone';
import { filterAndScrollToInvitesTable } from './utils';
import { Action, useInteractAnalytics } from '../../../../../hooks/Analytics';
import { GetPackagesForQuoteCoverageReport_activePackages as ActivePackage } from '../../types/GetPackagesForQuoteCoverageReport';
import { Package } from './types';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

const columns: TableColumn<Package>[] = [
  {
    accessor: 'title',
    Header: 'Package Name',
  },
  {
    accessor: ({ discipline }) => (discipline ? discipline.name : '-'),
    Header: 'Discipline',
  },
  {
    accessor: 'quotesDueAt',
    Header: 'Quotes Due',
    Cell: ({ value }) => (value ? moment(value).format('D MMM YYYY') : '-'),
  },
  {
    accessor: 'countOfQuotingRfqs',
    Header: 'Quoting',
  },
  {
    accessor: 'countOfQuotedRfqs',
    Header: 'Quoted',
    Cell: ({ value }) => (value === 0 ? <span className={styles.quotedZero}>{value}</span> : value),
  },
  {
    accessor: 'countOfCompanyInvitesSent',
    Header: 'Companies Invited',
  },
];

export type QuoteCoverageTableProps = {
  stageId: EntityId;
  packages: ActivePackage[];
};

export const QuoteCoverageTable = ({ stageId, packages }: QuoteCoverageTableProps) => {
  const { addEvent } = useInteractAnalytics();

  const handleColumnSortAnalytics = (sortId: string, isDesc: boolean) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    addEvent({
      action: Action.QUOTE_COVERAGE_TABLE_SORT,
      sortColumn: sortId,
      sortDirection: isDesc ? 'descending' : 'ascending',
      stageId,
    });
  };

  const handleRowClick = ({
    id,
    title,
    discipline,
    quotesDueAt,
    countOfQuotedRfqs,
    countOfQuotingRfqs,
    countOfInvitesSent,
  }: Package) => {
    filterAndScrollToInvitesTable(id);

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    addEvent({
      action: Action.QUOTE_COVERAGE_TABLE_CLICK_ROW,
      packageName: title,
      discipline: discipline?.name,
      quotesDueAt: quotesDueAt ?? undefined,
      quotedCount: countOfQuotedRfqs ?? undefined,
      quotingCount: countOfQuotingRfqs ?? undefined,
      invitesCount: countOfInvitesSent ?? undefined,
    });
  };

  return (
    <Table<Package>
      columns={columns}
      data={packages ?? []}
      initSortColumnId="quotesDueAt"
      sortSecondaryColumnId="title"
      maxHeight="500px"
      onSort={handleColumnSortAnalytics}
      onRowClick={handleRowClick}
    />
  );
};
