import { useMemo, useEffect } from 'react';
import { CellProps, Table, TableColumn } from '@estimateone/frontend-components';
import { debounce } from 'lodash';
import { InlineEditableInteger } from '../../../common/InlineEditableInteger/InlineEditableInteger';
import {
  getInputById,
  normaliseNumberInput,
} from '../../ProcurementLettingSchedule/utils/inputHelpers';
import { GetScheduleSettings_scheduleSettings as ScheduleSettings } from '../types/GetScheduleSettings';
import { EntityId, InterfaceToType } from '@ascension/types';
import styles from './styles.scss';

type Settings = InterfaceToType<ScheduleSettings>;

type IntervalsTableProps = {
  settings: ScheduleSettings[];
  onRowUpdated: (
    tradeScheduleSettingsId: EntityId,
    quotesDueByDateInterval: number | null,
    letByDateInterval: number | null,
    startOnSiteDateInterval: number | null,
  ) => void;
};

const getQuotesDueAtIntervalInputValue = (id: EntityId) =>
  normaliseNumberInput(getInputById(`schedule_settings_quotesDueByDateInterval_${id}`).value);

const getStartOnSiteDateIntervalInputValue = (id: EntityId) =>
  normaliseNumberInput(getInputById(`schedule_settings_startOnSiteInterval_${id}`).value);

const getLetByDateIntervalInputValue = (id: EntityId) =>
  normaliseNumberInput(getInputById(`schedule_settings_letByDateInterval_${id}`).value);

export const IntervalsTable = ({ settings, onRowUpdated }: IntervalsTableProps) => {
  const columns: TableColumn<Settings>[] = useMemo(
    () => [
      {
        id: 'trade',
        accessor: ({ trade }) => trade.name,
        Header: () => 'Package/Trade',
      },
      {
        id: 'quotesDueByDateInterval',
        accessor: ({ quotesDueAtInterval }) => quotesDueAtInterval,
        Header: () => (
          <div>
            <div className={styles.headerDaysBetween}>Days between:</div>
            <div>
              Send Invites →<br />
              Quotes Due
              <br />
              <span className={styles.headerExample}>e.g. Tender Period</span>
            </div>
          </div>
        ),
        Cell: ({ value, row: { original } }: CellProps<ScheduleSettings>) => {
          const debouncedOnValueChange = useMemo(
            () =>
              debounce(async ({ id, quotesDueByDateInterval }) => {
                onRowUpdated(
                  id,
                  quotesDueByDateInterval,
                  getLetByDateIntervalInputValue(original.id),
                  getStartOnSiteDateIntervalInputValue(original.id),
                );
              }, 300),
            [onRowUpdated],
          );

          useEffect(
            () => () => {
              debouncedOnValueChange.cancel();
            },
            [debouncedOnValueChange],
          );

          return (
            <InlineEditableInteger
              id={`schedule_settings_quotesDueByDateInterval_${original.id}`}
              className={styles.editableIntegerField}
              data-testid="schedule-settings-quotesDueByDateInterval"
              value={value}
              onValueChange={(updated) => {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                debouncedOnValueChange({ id: original.id, quotesDueByDateInterval: updated });
              }}
            />
          );
        },
      },

      {
        id: 'letByDateInterval',
        accessor: ({ letByDateInterval }) => letByDateInterval,
        Header: () => (
          <div>
            <div className={styles.headerDaysBetween}>Days between:</div>
            <div>
              Quotes Due →<br />
              Let By
              <br />
              &nbsp;
            </div>
          </div>
        ),
        Cell: ({ value, row: { original } }: CellProps<ScheduleSettings>) => {
          const debouncedOnValueChange = useMemo(
            () =>
              debounce(async ({ id, letByDateInterval }) => {
                onRowUpdated(
                  id,
                  getQuotesDueAtIntervalInputValue(original.id),
                  letByDateInterval,
                  getStartOnSiteDateIntervalInputValue(original.id),
                );
              }, 300),
            [onRowUpdated],
          );

          useEffect(
            () => () => {
              debouncedOnValueChange.cancel();
            },
            [debouncedOnValueChange],
          );

          return (
            <InlineEditableInteger
              id={`schedule_settings_letByDateInterval_${original.id}`}
              className={styles.editableIntegerField}
              data-testid="schedule-settings-letByDateInterval"
              value={value}
              onValueChange={(updated) => {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                debouncedOnValueChange({ id: original.id, letByDateInterval: updated });
              }}
            />
          );
        },
      },
      {
        id: 'startOnSiteInterval',
        accessor: ({ startOnSiteDateInterval }) => startOnSiteDateInterval,
        Header: () => (
          <div>
            <div className={styles.headerDaysBetween}>Days between:</div>
            <div>
              Let By →<br />
              Start on Site
              <br />
              <span className={styles.headerExample}>e.g. Lead Time</span>
            </div>
          </div>
        ),
        Cell: ({ value, row: { original } }: CellProps<ScheduleSettings>) => {
          const debouncedOnValueChange = useMemo(
            () =>
              debounce(async ({ id, startOnSiteDateInterval }) => {
                onRowUpdated(
                  id,
                  getQuotesDueAtIntervalInputValue(original.id),
                  getLetByDateIntervalInputValue(original.id),
                  startOnSiteDateInterval,
                );
              }, 300),
            [onRowUpdated],
          );

          useEffect(
            () => () => {
              debouncedOnValueChange.cancel();
            },
            [debouncedOnValueChange],
          );

          return (
            <InlineEditableInteger
              id={`schedule_settings_startOnSiteInterval_${original.id}`}
              className={styles.editableIntegerField}
              data-testid="schedule-settings-startOnSiteInterval"
              value={value}
              onValueChange={(updated) => {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                debouncedOnValueChange({ id: original.id, startOnSiteDateInterval: updated });
              }}
            />
          );
        },
      },
    ],
    [],
  );

  return <Table<Settings> columns={columns} data={settings} initSortColumnId="trade" />;
};
