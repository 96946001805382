import { gql } from '@apollo/client';

export const GET_PRIMARY_COUNTRY = gql`
  query GetCurrentAccountCountry {
    currentUser {
      id
      account {
        id
        primaryCountry {
          id
        }
      }
    }
  }
`;

export const GET_SUBBIE_SETTINGS_ACCESS = gql`
  query GetSubbieSettingsAccess {
    canEditNetworkEnrolment
    canEditSubbieSupplierConnectionSetting
  }
`;
