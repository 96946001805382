import { Button, ButtonSize, ButtonVariant } from '@estimateone/frontend-components';
import styles from './styles.module.scss';

/** SUBBIE_CREDIT_EXPERIMENT_ALPHA_CODE */
type FreeCreditButtonsViewProps = {
  upgradeUrl: string;
  onClick: () => void;
  isUnlockProjectLoading: boolean;
};

export const FreeCreditButtonsView = ({
  upgradeUrl,
  onClick,
  isUnlockProjectLoading,
}: FreeCreditButtonsViewProps) => (
  <div className={styles.buttonsContainer}>
    <Button
      type="button"
      variant={ButtonVariant.Secondary}
      href={upgradeUrl}
      size={ButtonSize.Medium}
      fullWidth
    >
      I want to access all tenders
    </Button>
    <Button
      type="button"
      variant={ButtonVariant.Primary}
      onClick={onClick}
      disabled={isUnlockProjectLoading}
      size={ButtonSize.Medium}
      fullWidth
    >
      Unlock tender for free
    </Button>
  </div>
);
