import { gql } from '@apollo/client';

export const GET_TENDER_DETAILS_HANDOVER = gql`
  query GetTenderDetailsForHandover($stageId: EntityId!) {
    stage(id: $stageId) {
      ... on UnrestrictedStage {
        id
        name
        category
        budgetAmount
        description
        internalRef
        constructionStartDate
        constructionEndDate
        projectId
        projectAddress {
          id
          address1
          address2
          address3
          suburb
          city
          postcode
          state {
            id
          }
          country {
            id
          }
          fullAddress
        }
      }
    }

    currentUser {
      account {
        primaryCountry {
          id
        }
      }
    }
  }
`;

export const GET_ALL_COUNTRIES = gql`
  query GetAllCountries {
    countries {
      id
      name
    }
  }
`;
