import { useMutation } from '@apollo/client';
import { UPDATE_CATEGORIES } from './mutations';
import { UpdateCategoriesMutation, UpdateCategoriesMutationVariables } from './mutations.generated';
import { EditCategoriesFieldName, EditCategoriesFieldValues } from './fields/types';

const transformValues = (values: EditCategoriesFieldValues): UpdateCategoriesMutationVariables => ({
  projectCategories: values[EditCategoriesFieldName.Categories].map(
    (categoryOption) => categoryOption.value,
  ),
});

export const useSubmitForm = () => {
  const [updateCategories, { error }] = useMutation<
    UpdateCategoriesMutation,
    UpdateCategoriesMutationVariables
  >(UPDATE_CATEGORIES);

  const submit = async (values: EditCategoriesFieldValues) => {
    const { errors } = await updateCategories({
      variables: transformValues(values),
    });

    return errors || null;
  };

  return { submit, error: error ?? undefined };
};
