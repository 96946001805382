import { InfoCard } from './InfoCard';
import { IntervalsTable } from './IntervalsTable';
import LoadingSpinner from '@shared/LoadingSpinner';
import { useGetScheduleSettings, useUpdateScheduleSettings } from './hooks';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

export const AccountSettingsSchedule = () => {
  const { settings, loading } = useGetScheduleSettings();
  const { submit, loading: updating } = useUpdateScheduleSettings();

  const onRowUpdatedHandler = (
    tradeScheduleSettingsId: EntityId,
    quotesDueByDateInterval: number | null,
    letByDateInterval: number | null,
    startOnSiteDateInterval: number | null,
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    submit(
      tradeScheduleSettingsId,
      quotesDueByDateInterval,
      letByDateInterval,
      startOnSiteDateInterval,
    );
  };

  return (
    <div className={styles.accountSettingsScheduleContainer}>
      <h3 className={styles.accountSettingsScheduleHeading}>Default Timeframes Between Dates</h3>
      <div className={styles.accountSettingsScheduleDataContainer} aria-busy={loading || updating}>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <IntervalsTable settings={settings} onRowUpdated={onRowUpdatedHandler} />
        )}
        <InfoCard />
      </div>
    </div>
  );
};
