import ProjectHeaderDetails from './ProjectHeaderDetails';
import TruncatedText from '../../../../shared/TruncatedText';
import { PaywalledProject, User } from '../types';
import styles from './styles.scss';

const RedactedSliderHeader = ({ project, user }: { project: PaywalledProject; user: User }) => (
  <div className={styles.sliderHeader}>
    <div className={styles.projectSliderHeader}>
      <div className={`d-flex justify-content-between align-items-center ${styles.hideOnScroll}`}>
        <span className={styles.projectId}>ID #{project.id}</span>
      </div>
      <div className="d-flex justify-content-between align-items-end">
        <div>
          <h2>
            <TruncatedText text={project.name} limit={65} />
          </h2>
          <span>{project.address?.fullAddress}</span>
        </div>
      </div>
    </div>
    <hr />
    <div className={`${styles.projectDetails} ${styles.hideOnScroll}`}>
      <ProjectHeaderDetails project={project} user={user} />
    </div>
  </div>
);

export default RedactedSliderHeader;
