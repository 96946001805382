export enum LettingScheduleStatus {
  Draft = 0,
  Pricing,
  Awarded,
}

type PartialPackage = {
  __typename: 'Package';
  activeRfqs: { __typename: 'Rfq' }[];
  lettingScheduleDetails:
    | { __typename: 'LettingScheduleDetails'; isAwarded: boolean }
    | null
    | undefined;
};

export const getLettingScheduleStatus = (pkg: PartialPackage): LettingScheduleStatus => {
  const awarded = pkg.lettingScheduleDetails?.isAwarded ? LettingScheduleStatus.Awarded : undefined;

  const pricingOrDraft =
    pkg.activeRfqs.length > 0 ? LettingScheduleStatus.Pricing : LettingScheduleStatus.Draft;

  return awarded ?? pricingOrDraft;
};

export const getLettingScheduleStatusCounts = <T extends PartialPackage>(pkg: T[]) => {
  const counts = {
    [LettingScheduleStatus.Pricing]: 0,
    [LettingScheduleStatus.Draft]: 0,
    [LettingScheduleStatus.Awarded]: 0,
  };

  pkg.forEach((p) => {
    const status = getLettingScheduleStatus(p);
    // eslint-disable-next-line fp/no-mutation
    counts[status] += 1;
  });

  return counts;
};
