import { useMutation } from '@apollo/client';
import { TRIGGER_DOCSYNC_RECONCILE } from './mutations';
import {
  TriggerDocsyncReconcile,
  TriggerDocsyncReconcileVariables,
} from './types/TriggerDocsyncReconcile';
import { EntityId } from '@ascension/types';

export const useTriggerDocsyncReconcile = () => {
  const [submit] = useMutation<TriggerDocsyncReconcile, TriggerDocsyncReconcileVariables>(
    TRIGGER_DOCSYNC_RECONCILE,
  );

  const triggerReconcile = async (stageId: EntityId) => {
    const { data } = await submit({
      variables: {
        stageId,
      },
    });

    return { success: data?.triggerDocsyncReconcile };
  };

  return { triggerReconcile };
};
