export enum InviteToQuoteFormFieldName {
  Package = 'package',
  Company = 'company',
  Contact = 'contact',
  DueDate = 'dueDate',
  SendEmail = 'sendEmail',
  PersonalisedMessage = 'personalisedMessage',
  IncludeScopeOfWorks = 'includeScopeOfWorks',
  ScopeOfWorks = 'scopeOfWorks',
  CorrespondenceType = 'correspondenceType',
}

enum InviteToQuoteApiFieldName {
  Package = 'package',
  Contact = 'contact',
}

export const InviteToQuoteFieldNameMap = {
  [InviteToQuoteApiFieldName.Package]: InviteToQuoteFormFieldName.Package,
  [InviteToQuoteApiFieldName.Contact]: InviteToQuoteFormFieldName.Contact,
};
