import styles from './styles.scss';

export type ProfilesAvatarProps = {
  name: string;
};
const ProfilesAvatar = ({ name }: ProfilesAvatarProps) => (
  <div className={styles.avatarContainer}>
    <div className={styles.companyInitial}>{name.charAt(0).toUpperCase()}</div>
  </div>
);

export default ProfilesAvatar;
