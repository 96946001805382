import { useEffect, useMemo, useRef } from 'react';
import { LettingScheduleNotesHistoryItem } from './LettingScheduleNotesHistoryItem';
import { SlideDownAnimation } from './LettingScheduleNotesHistoryItem/SlideDownAnimation/SlideDownAnimation';
import { GetNotesForLettingSchedule_package_lettingScheduleDetails_notes as LettingScheduleNote } from '../LettingScheduleNotesSlideout/types/GetNotesForLettingSchedule';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

export type LettingScheduleNotesHistoryProps = {
  notes: LettingScheduleNote[];
};

const sortByDate = (note1: LettingScheduleNote, note2: LettingScheduleNote) =>
  note1.createdAt === note2.createdAt ? 0 : note1.createdAt > note2.createdAt ? -1 : 1;

export const LettingScheduleNotesHistory = ({ notes }: LettingScheduleNotesHistoryProps) => {
  const localNotes = useMemo(() => [...notes].sort(sortByDate), [notes]);
  const firstRender = useRef(true);
  const renderedItems = useRef<Record<EntityId, boolean>>({});

  useEffect(() => {
    // We track the first render so that we can opt-out of animation during the first render
    if (firstRender.current) {
      firstRender.current = false;
    }

    // We track items that we've already rendered so that we don't
    // re-apply animations to those items.
    localNotes.forEach((note) => {
      renderedItems.current[note.id] = true;
    });
  });

  return localNotes.length > 0 ? (
    <div className={styles.notesHistoryItems}>
      {localNotes.map((note) => (
        <SlideDownAnimation
          animated={!firstRender.current && !renderedItems.current[note.id]}
          key={note.id}
        >
          <LettingScheduleNotesHistoryItem note={note} />
        </SlideDownAnimation>
      ))}
    </div>
  ) : (
    <div className={styles.noNotesText}>No notes have been added yet</div>
  );
};
