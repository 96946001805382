import { useConnectionPreferenceFormContext } from '@subbie/modal/ConnectionPreferenceSlider/ConnectionPreferenceFormContext';
import { SliderBackButton } from '@subbie/modal/ProjectSlider/SliderBackButton';
import { ConnectionPreferenceFormPage } from '@subbie/modal/ConnectionPreferenceSlider/ConnectionPreferenceFormContext/types';
import styles from './styles.scss';

type ConnectionPreferenceSliderHeaderProps = {
  title: string;
  handleClose: () => void;
};

export const ConnectionPreferenceSliderHeader = ({
  title,
  handleClose,
}: ConnectionPreferenceSliderHeaderProps) => {
  const { page } = useConnectionPreferenceFormContext();

  return (
    <div className={styles.sliderHeader}>
      {page === ConnectionPreferenceFormPage.ConnectionPreference ? (
        <SliderBackButton handleClose={handleClose} />
      ) : null}
      <div className={styles.projectSliderHeader}>
        <h2>{title}</h2>
      </div>
    </div>
  );
};
