import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import {
  ContractSizeMaxEnum,
  ContractSizeMinEnum,
  WorkforceSizeEnum,
} from '@ascension/_gqltypes/builder.generated';

export enum RelationshipTypeEnum {
  AddressBook = 'Address Book',
  NotInAddressBook = 'Not in Address Book',
  All = 'All',
}

export type SearchAreaOptions = 'service-area' | 'office-location';

export type SearchServiceArea = {
  country: string;
  state?: string;
};

export type SelectedFiltersAtom = {
  stockTradeId?: number;
  lat?: number;
  lon?: number;
  locationLabel?: string;
  distance?: number;
  distanceLabel?: string;
  workforceSize?: WorkforceSizeEnum;
  categoryId?: number;
  companyListId?: number;
  relationshipType?: RelationshipTypeEnum;
  contractSizeMin?: ContractSizeMinEnum;
  contractSizeMax?: ContractSizeMaxEnum;
  searchArea?: SearchAreaOptions;
  serviceArea?: SearchServiceArea;
};

export const selectedFiltersAtom = atomWithStorage<SelectedFiltersAtom>(
  'network-search-filter',
  {},
  undefined,
  { getOnInit: true },
);

export const selectedFormFiltersCountAtom = atom<number>(0);

export const stockTradeIdAtom = atom(
  (get) => get(selectedFiltersAtom).stockTradeId,
  (get, set, stockTradeId?: number) =>
    set(selectedFiltersAtom, {
      ...get(selectedFiltersAtom),
      stockTradeId,
    }),
);

export const relationshipTypeAtom = atom(
  (get) => get(selectedFiltersAtom).relationshipType,
  (get, set, relationshipType?: RelationshipTypeEnum) =>
    set(selectedFiltersAtom, {
      ...get(selectedFiltersAtom),
      relationshipType,
    }),
);

export const companyListIdAtom = atom(
  (get) => get(selectedFiltersAtom).companyListId,
  (get, set, companyListId?: number) =>
    set(selectedFiltersAtom, {
      ...get(selectedFiltersAtom),
      companyListId,
    }),
);
