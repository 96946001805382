import { renderToStaticMarkup } from 'react-dom/server';
import $ from 'jquery';
import Routing from 'routing';
import { captureException } from '@sentry/browser';
import moment from 'moment-timezone';
import _ from 'underscore';
import E1Request from '../classes/E1Request';
import { PreferredIndicator } from '../../components/builder/common/PreferredIndicator';
import SessionStorageService from '../classes/AppStorage/SessionStorageService';
import ContactTypeIcon from '../classes/ContactTypeIcon';
import Form from '../classes/Form';
import Location from '../classes/Location';
import Modal from '../classes/Modal';
import { getShortAddress } from '../utils/address_form';
import { DataTableFilterForm } from '../utils/table_filtering';
import TradePackageSelector from './classes/TradePackageSelector';
import { Action } from '../../components/hooks/Analytics';
import { getStageTypeAsString, ListenerEvent, StageType } from '../../enums';
import { getFirstName, getFullName, getLastName } from './contact_util';

function InvitationList($container, target, stageId, stageType) {
  this.maxPackageErrorCount = 10;
  this.$container = $container;
  this.$target = target;
  this.stage = stageId;
  this.stageType = getStageTypeAsString(parseInt(stageType))?.toLocaleLowerCase();

  this.$selectAllCheckbox = this.$container.find('.select-all');
  this.$emptyPlaceholder = this.$container.find('.empty-text-placeholder');
  this.$invitationCounter = this.$container.find('.invitationCounter');
  this.$subbieCounter = this.$container.find('.subbieCounter');
  this.$modal = null;
  this.$validationModal = null;
  this.fromLat = this.$target.attr('data-from-lat');
  this.fromLng = this.$target.attr('data-from-lng');
  this.disabledMessage = this.$container.attr('data-disabled-message');
  this.PAYLOAD_MOMENT_FORMAT = 'DD-MM-YYYY';
  this.dueDateHasError = false;

  this.contacts = {};

  /**
   * @typedef {number} SubbieId
   * @type {{ [packageId: string]: SubbieId[] }}
   */
  this.invites = {};

  /**
   * @typedef {object} PackageProperties
   * @property {string} dueDate
   * @property {number[]} invitedSubbieIds
   */

  /**
   * @type {{ [packageId: string]: PackageProperties }}
   */
  this.packageDueDates = {};
  /**
   * @type {{ [packageId: number]: number[] }}
   */
  this.existingInvites = {};
  this.table = null;
  this.filterForm = null;
  this.tableShowing = true;
  this.invitesCache = new SessionStorageService(`subbie-invites-v3-${this.stage}`);

  const self = this;

  this.hasLoaded = (loaded) => {
    self.$target.closest('.loading-container').toggleClass('has-loaded', loaded);
  };

  this.tradePackageSelector = new TradePackageSelector(
    $container,
    () => {
      const tradeId = self.tradePackageSelector.getSelectedTradeId();
      const tradeData = self.getTradeData(tradeId);
      self.updateTable(tradeId, tradeData === null);
      self.prefetchContactsForNextTrade();
      self.dueDateHasError = false;

      // prevents the default date from being set before we've loaded from the API
      if (Object.keys(this.packageDueDates).length !== 0) {
        self.setPackageDueDate();
      }
    },
    /**
     * @param {number} packageId
     * @param {moment.Moment | null} newDueDate
     */
    (packageId, newDueDate) => {
      if (
        this.packageDueDates[packageId] &&
        this.packageDueDates[packageId].dueDate !== newDueDate?.format(this.PAYLOAD_MOMENT_FORMAT)
      ) {
        window.analyticsService.addInteractEvent({
          action: Action.INVITE_WIZARD_PACKAGE_DATE_PICKER_DATE_UPDATE,
          dueDateSaved: newDueDate?.format('ddd DD MMM YYYY HH:mm:ss ZZ'),
        });

        self.updatePackageDueDate(packageId, newDueDate);

        this.invitesCache.persistItem({
          invites: this.invites,
          packageDueDates: this.packageDueDates,
        });
      }
    },
    (dueDateHasError) => {
      this.dueDateHasError = dueDateHasError;
      this.updateValidationState();
    },
  );

  this.tradePackageSelector.init();

  self.loadInvites();
  self.fetchPackageDueDates();

  self.fetchExistingInvites();
  self.fetchContactsForTrade(self.tradePackageSelector.getSelectedTradeId(), self.init);

  self.$selectAllCheckbox.on('click', () => {
    const checked = self.$selectAllCheckbox.is(':checked');
    const selectedPackageId = self.tradePackageSelector.getSelectedPackageId();

    const targetContactsFilter = (row) => {
      if (row.packageIds.includes(selectedPackageId)) return false;
      const changeInvite = checked !== self.invites[selectedPackageId].includes(row.id);
      return changeInvite && row.inviteable;
    };

    self.table
      .rows({ filter: 'applied' })
      .data()
      .filter(targetContactsFilter)
      .toArray()
      .forEach((row) => {
        self.updateInvitation(row.id, selectedPackageId, checked);
      });

    self.updateTable(self.tradePackageSelector.getSelectedTradeId());
    self.updateInvitationCount();
  });

  self.$container.on('click', '.apply-list-trigger', () => {
    const url = Routing.generate('app_tenderstage_applylistmodal', {
      id: self.stage,
    });
    new E1Request(url).setShowLoadingModal().submit();
  });

  self.$container.on('click', '.apply-filters-trigger', () => {
    const filterFormCopy = $.extend(true, {}, self.filterForm);
    filterFormCopy.draw();
    const $tableContainer = filterFormCopy.getFormContainer();
    $tableContainer.addClass('main');
    const $footer = $('<div />').append(
      $('<a />')
        .attr('role', 'button')
        .addClass('btn btn-default e1-modal-close pull-left')
        .text('Cancel'),
      $('<a />')
        .attr('role', 'button')
        .addClass('btn btn-primary pull-right confirm-form')
        .text('Apply Filters'),
    );
    const $modal = Modal.buildModalTemplate(
      'Filter Subcontractors',
      $tableContainer,
      $footer,
      'modal-lg',
    );
    self.$modal = new Modal($modal.prop('outerHTML'));

    self.$modal.$modal.on('click', '.data-table-add-filter-trigger', () => {
      filterFormCopy.addFilter();
      filterFormCopy.repaint(self.$modal.$modal.find('.main'));
    });

    self.$modal.$modal.on('change', '.data-table-filter-field', (clickEvent) => {
      const value = $(clickEvent.currentTarget).val();
      const $row = $(clickEvent.currentTarget).closest('tr');
      if ($row != null && $row.hasClass('data-table-filter-row')) {
        const rowId = $row.attr('data-id');
        const filter = filterFormCopy.getFilter(rowId);
        if (filter != null) {
          filter.column = parseInt(value, 10);
          filterFormCopy.repaint(self.$modal.$modal.find('.main'));
        }
      }
    });

    self.$modal.$modal.on('change', '.data-table-filter-operator', (clickEvent) => {
      const value = $(clickEvent.currentTarget).val();
      const $row = $(clickEvent.currentTarget).closest('tr');
      if ($row != null && $row.hasClass('data-table-filter-row')) {
        const rowId = $row.attr('data-id');
        const filter = filterFormCopy.getFilter(rowId);
        if (filter != null) {
          filter.operator = value;
          filterFormCopy.repaint(self.$modal.$modal.find('.main'));
        }
      }
    });

    self.$modal.$modal.on('change', 'select.data-table-filter-value', (clickEvent) => {
      const values = $(clickEvent.currentTarget).val();
      const $row = $(clickEvent.currentTarget).closest('tr');
      if ($row != null && $row.hasClass('data-table-filter-row')) {
        const rowId = $row.attr('data-id');
        const filter = filterFormCopy.getFilter(rowId);
        if (filter != null) {
          filter.value = _.map(values, (num) => parseInt(num, 10));
        }
      }
    });

    self.$modal.$modal.on('keyup', 'input.data-table-filter-value', (clickEvent) => {
      const value = $(clickEvent.currentTarget).val();
      const $row = $(clickEvent.currentTarget).closest('tr');
      if ($row != null && $row.hasClass('data-table-filter-row')) {
        const rowId = $row.attr('data-id');
        const filter = filterFormCopy.getFilter(rowId);
        if (filter != null) {
          filter.value = value;
        }
      }
    });

    self.$modal.$modal.on('click', '.data-table-remove-filter', (clickEvent) => {
      const $row = $(clickEvent.currentTarget).closest('tr');
      if ($row != null && $row.hasClass('data-table-filter-row')) {
        const rowId = $row.attr('data-id');
        filterFormCopy.removeFilter(rowId);
        filterFormCopy.repaint(self.$modal.$modal.find('.main'));
      }
    });

    self.$modal.$modal.on('click', '.confirm-form', () => {
      self.filterForm = $.extend(true, {}, filterFormCopy);
      self.filterForm.applyFilters();

      window.analyticsService.addEvent('interact', {
        action: 'FilterSubbies',
        stageId: self.stage,
        filterCount: self.filterForm.getFilters().length,
      });

      self.$modal.close();
    });

    self.$modal.show();
  });

  self.$container.on('click', '.confirmInvitations', (clickEvent) => {
    if (!$(clickEvent.currentTarget).attr('disabled')) {
      if (self.stageType === getStageTypeAsString(StageType.TYPE_PROCUREMENT).toLocaleLowerCase()) {
        const packagesMissingDates = self.getPackagesMissingQuotesDueDates();
        if (packagesMissingDates.length > 0) {
          self.showValidationModal(packagesMissingDates);
          return;
        }
      }

      const confirmUrl = Routing.generate('app_stageinvitation_confirm', {
        id: self.stage,
        stageType: self.stageType,
      });
      new E1Request(confirmUrl, 'POST', { count: self.getInvitationCount() })
        .setShowLoadingModal()
        .submit();
    }
  });
  $('body').on('click', '.completeInvitations', (clickEvent) => {
    $(clickEvent.currentTarget).attr('disabled', true);
    self.submitInvitations();
  });

  self.$target.on('change', '.invite-trigger', (clickEvent) => {
    const $checkbox = $(clickEvent.currentTarget);
    const id = parseInt($checkbox.attr('data-contact-id'), 10);
    const companyId = parseInt($checkbox.data('company-id'), 10);

    self.handleCheckboxContactClicked(id, companyId, $checkbox.is(':checked'));
  });

  self.$target.on('click', '.company-invite-trigger', (companyChk) => {
    const $checkbox = $(companyChk.currentTarget);
    const checked = $checkbox.is(':checked');
    const companyId = parseInt($checkbox.data('company-id'), 10);
    const $enabledOptions = self.$target.find(
      `input.invite-trigger:not(:disabled)[data-company-id=${companyId}]`,
    );

    $enabledOptions.toArray().forEach((contactChk) => {
      const $chk = $(contactChk);
      $chk.prop('checked', checked);
      const contactId = parseInt($chk.data('contact-id'), 10);
      self.handleCheckboxContactClicked(contactId, companyId, checked);
    });
  });

  $(document).on('submit', 'form.apply-list-form', (applyListEvent) => {
    const $form = $(applyListEvent.currentTarget);
    const form = new Form($form);

    form.extraCallback = (response) => {
      Object.keys(response.contact_list || {}).forEach((tradeId) => {
        response.contact_list[tradeId].forEach((contactId) => {
          const packageId = self.tradePackageSelector.getMappedPackageFromTradeId(tradeId);
          return packageId && self.updateInvitation(contactId, packageId, true);
        });
      });

      window.analyticsService.addInteractEvent({
        action: 'ApplyContactList',
        contactListId: response.contact_list_id || null,
        selectedPackageId: self.tradePackageSelector.getSelectedPackageId(),
        selectedTrade: self.tradePackageSelector.getSelectedTradeId(),
      });

      self.updateTable(self.tradePackageSelector.getSelectedTradeId());
      self.updateInvitationCount();
    };

    form.submit();
    return false;
  });

  $(document).on(
    `
      ${ListenerEvent.AddressBookSliderContactAdded}
      ${ListenerEvent.AddressBookSliderContactUpdated}
      ${ListenerEvent.AddressBookSliderContactRemoved}
      ${ListenerEvent.AddressBookSliderContactSetPreferred}
    `,
    () => {
      const currentTradeId = self.tradePackageSelector.getSelectedTradeId();

      Object.keys(this.contacts).forEach((tradeId) => {
        if (tradeId !== currentTradeId) self.fetchContactsForTrade(tradeId, null, false);
      });

      self.updateTable(currentTradeId, true);
    },
  );
}

InvitationList.prototype.handleCheckboxContactClicked = function handleCheckboxContactClicked(
  contactId,
  companyId,
  checked,
) {
  this.updateInvitation(contactId, this.tradePackageSelector.getSelectedPackageId(), checked);
  this.updateInvitationCount();
  this.updateCompanyInviteStatus(companyId);
  this.updateSelectAllCheckbox();
  this.invitesCache.persistItem({ invites: this.invites, packageDueDates: this.packageDueDates });
};

InvitationList.prototype.updateInvitation = function (contactId, packageId, checked) {
  const self = this;
  if (self.invites[packageId] === undefined) return;

  const index = self.invites[packageId].indexOf(contactId);
  const alreadyInvited =
    self.existingInvites[packageId] && self.existingInvites[packageId].includes(contactId);

  if (checked && !alreadyInvited && index < 0) {
    self.invites[packageId].push(contactId);
  } else if (!checked && index >= 0) {
    self.invites[packageId].splice(index, 1);
  }
  self.updateValidationState();
};

InvitationList.prototype.updateValidationState = function () {
  const selectedPackageId = this.tradePackageSelector.getSelectedPackageId();
  if (this.invites[selectedPackageId]) {
    const disabledState = this.invites[selectedPackageId].length > 0 && this.dueDateHasError;
    this.$container.find('.confirmInvitations').attr('disabled', disabledState);
    this.$container.find('#trade_list').attr('disabled', disabledState);
    this.$container.find('#package_list').attr('disabled', disabledState);
    this.$container.find('.previousTradeLink').toggleClass('disabled', disabledState);
    this.$container.find('.nextTradeLink').toggleClass('disabled', disabledState);
    this.tradePackageSelector.showValidationError(disabledState);
    return disabledState;
  }
  return false;
};

InvitationList.prototype.getPackagesMissingQuotesDueDates = function () {
  const packagesMissingDueDates = Object.entries(this.invites).filter(([packageId, subbieIds]) => {
    const { dueDate } = this.packageDueDates[packageId];
    if (subbieIds.length > 0 && !dueDate) {
      return true;
    }
    return false;
  });
  return packagesMissingDueDates.map((entry) => entry[0]);
};

InvitationList.prototype.showValidationModal = function (packageIds) {
  const packageIdCount = packageIds.length;
  if (packageIdCount > this.maxPackageErrorCount) {
    packageIds = packageIds.slice(0, this.maxPackageErrorCount);
  }
  const $contentMessage = $(
    '<p>For these packages, add a date (or unselect all subcontractors if you&apos;re not ready to send invitations):</p>',
  );
  const $list = $('<ul />').addClass('col-sm-12');
  packageIds.forEach((packageId) =>
    $list.append(`<li>${this.tradePackageSelector.getPackageNameForId(packageId)}</li>`),
  );
  if (packageIdCount > this.maxPackageErrorCount) {
    $list.append(`<li>and ${packageIdCount - this.maxPackageErrorCount} more packages</li>`);
  }
  const $content = $('<div />').append($contentMessage).append($list);
  const $container = $('<div />').append($content);
  $container.addClass('main');
  const $footer = $('<div />').append(
    $('<a />')
      .attr('role', 'button')
      .addClass('btn btn-primary btn-block e1-modal-close')
      .text('OK'),
  );
  const $modal = Modal.buildModalTemplate(
    `${packageIdCount} package(s) need a Quotes Due Date before issuing invitations`,
    $container,
    $footer,
    'modal-dialog',
  );
  self.$validationModal = new Modal($modal.prop('outerHTML'));
  self.$validationModal.show();
};

InvitationList.prototype.getInvitationCount = function () {
  return Object.values(this.invites).reduce((total, invites) => total + invites.length, 0);
};

InvitationList.prototype.updateInvitationCount = function () {
  this.$invitationCounter.text(this.getInvitationCount());
};

InvitationList.prototype.syncCompanyInviteStatus = function syncCompanyInviteStatus() {
  const self = this;
  new Set(
    self.$target
      .find('input.invite-trigger')
      .toArray()
      .map((chk) => parseInt(chk.dataset.companyId, 10)),
  ).forEach(self.updateCompanyInviteStatus.bind(this));
};

InvitationList.prototype.updateCompanyInviteStatus = function updateCompanyInviteStatus(companyId) {
  const $companyTriggerCheckbox = this.$target.find(
    `input.company-invite-trigger[data-company-id=${companyId}]`,
  );
  const $availableOptions = this.$target.find(`input.invite-trigger[data-company-id=${companyId}]`);
  const userSelectedOptionsCount = $availableOptions.filter(':checked:not(:disabled)').length;
  const allSelectedOptionsCount = $availableOptions.filter(':checked').length;
  const disabledOptionsCount = $availableOptions.filter(':disabled').length;
  const selectableOptionsCount = $availableOptions.length - disabledOptionsCount;

  $companyTriggerCheckbox
    .prop(
      'checked',
      allSelectedOptionsCount &&
        (!selectableOptionsCount || userSelectedOptionsCount === selectableOptionsCount),
    )
    .prop(
      'indeterminate',
      userSelectedOptionsCount && userSelectedOptionsCount < selectableOptionsCount,
    )
    .prop('disabled', !selectableOptionsCount);
};

InvitationList.prototype.updateSelectAllCheckbox = function () {
  const $selectableOptions = this.$target.find('input.invite-trigger:not(:disabled)');
  const $selectedOptions = this.$target.find('input.invite-trigger:not(:disabled):checked');

  this.$selectAllCheckbox
    .prop(
      'indeterminate',
      $selectedOptions.length && $selectedOptions.length !== $selectableOptions.length,
    )
    .prop('disabled', $selectableOptions.length === 0)
    .prop(
      'checked',
      $selectableOptions.length > 0 && $selectableOptions.length === $selectedOptions.length,
    );

  this.syncCompanyInviteStatus();
};

InvitationList.prototype.updateSubbieCounter = function () {
  const tradeId = this.tradePackageSelector.getSelectedTradeId();
  if (this.contacts[tradeId] === undefined) {
    this.$subbieCounter.empty();
    return;
  }

  const count = this.contacts[tradeId].length;
  const trade = this.tradePackageSelector.getSelectedTradeName();
  this.$subbieCounter.text(`${trade} (${count} Subcontractor${count === 1 ? '' : 's'})`);
};

InvitationList.prototype.fetchContactsForTrade = function (tradeId, callback, showLoading = true) {
  if (isNaN(tradeId)) return;

  const self = this;
  const fetchContactsUrl = Routing.generate('app_stagetrade_fetchcontacts', {
    id: self.stage,
    trade_id: tradeId,
  });

  new E1Request(fetchContactsUrl, 'GET')
    .submit()
    .then((response) => {
      self.contacts[tradeId] = response.data.map((val) => self.convertData(val));
    })
    .then(() => {
      callback?.(self);
      if (showLoading) {
        self.hasLoaded(true);
      }
    })
    .catch(() => {
      if (showLoading) {
        self.hasLoaded(true);
      }
    });

  if (showLoading) {
    self.hasLoaded(false);
  }
};

InvitationList.prototype.prefetchContactsForNextTrade = function () {
  const self = this;
  const tradeId = self.tradePackageSelector.getNextTradeId();
  if (!tradeId || self.contacts[tradeId] !== undefined) return;

  self.fetchContactsForTrade(tradeId, null, false);
};

InvitationList.prototype.fetchExistingInvites = function () {
  const existingInvitesUrl = Routing.generate('app_stageinvitation_existinginvitations', {
    id: this.stage,
    stageType: this.stageType,
  });
  /*
   * response is {success: bool, contacts: array<int, int[]>}
   */
  new E1Request(existingInvitesUrl, 'GET').submit().then((response) => {
    Object.assign(this.existingInvites, response.data);
  });
};

InvitationList.prototype.loadInvites = function () {
  const cachedInvites = this.invitesCache.retrieveItem()?.invites || {};
  const invitationCounts = [];

  this.tradePackageSelector.getAllPackageIds().forEach((packageId) => {
    this.invites[packageId] = cachedInvites[packageId] || [];
    this.existingInvites[packageId] = [];
    invitationCounts.push(this.invites[packageId].length);
  });

  if (Object.keys(cachedInvites)) {
    this.updateInvitationCount(invitationCounts.reduce((a, b) => a + b, 0));
  }
};

InvitationList.prototype.updatePackageDueDate = function (packageId, dueDate) {
  this.packageDueDates[packageId].dueDate = dueDate?.format(this.PAYLOAD_MOMENT_FORMAT);
};

InvitationList.prototype.setPackageDueDate = function () {
  const packageId = this.tradePackageSelector.getSelectedPackageId();

  if (this.packageDueDates[packageId]) {
    const { dueDate, letByDate } = this.packageDueDates[packageId];
    this.tradePackageSelector.setPackageDueDate(
      dueDate !== '' ? moment(dueDate, this.PAYLOAD_MOMENT_FORMAT) : null,
    );

    this.tradePackageSelector.setLetByDate(
      letByDate ? moment(letByDate, this.PAYLOAD_MOMENT_FORMAT) : null,
    );
  }
};

/**
 * Fetches end date data from the database and session storage, and prefers the latter
 */
InvitationList.prototype.fetchPackageDueDates = async function () {
  /**
   * The DatePicker forces that we give it dates in the same format that we display it (DD-MM-YYYY).
   * The API returns it in a different format
   * @param {string} apiDate in format YYYY-MM-DD
   */
  const transformApiDateFormatForPayload = (apiDate) =>
    apiDate ? moment(apiDate, 'YYYY-MM-DD').format(this.PAYLOAD_MOMENT_FORMAT) : '';

  const cachedDueDates = this.invitesCache.retrieveItem()?.packageDueDates || {};

  const { data } = await new E1Request(
    Routing.generate('app_stagepackage_fetch', { id: this.stage }),
    'GET',
  ).submit();
  this.packageDueDates = data.reduce((acc, { id, quotesDueDate, letByDate }) => {
    const dueDate = cachedDueDates[id]?.dueDate ?? transformApiDateFormatForPayload(quotesDueDate);
    const packageLetByDate = letByDate ? transformApiDateFormatForPayload(letByDate) : null;
    acc[id] = { dueDate, letByDate: packageLetByDate };
    return acc;
  }, {});

  this.setPackageDueDate();
};

InvitationList.prototype.getTradeData = function (tradeId) {
  return tradeId in this.contacts ? this.contacts[tradeId] : null;
};

InvitationList.prototype.submitInvitations = function () {
  const self = this;
  const submitUrl = Routing.generate('app_stageinvitation_save', {
    id: self.stage,
    stageType: self.stageType,
  });

  /**
   * @typedef {{packageId: string; invitedSubbieIds: number[]; dueDate: string | null}} Invitation
   * @type {Invitation[]}
   */
  const packageInvitations = Object.entries(self.invites)
    .filter(([, ids]) => ids.length)
    .map(([packageId, invitedSubbieIds]) => ({
      packageId,
      invitedSubbieIds,
      dueDate: self.packageDueDates[packageId]?.dueDate,
    }));

  new E1Request(submitUrl, 'POST', { invites: packageInvitations }).submit();
  this.invitesCache.removeItem();
};

InvitationList.prototype.init = function (self) {
  $.fn.dataTable.ext.search.push((settings, data) => {
    let passed = true;

    if (self.filterForm) {
      self.filterForm.getFilters().forEach((filter) => {
        passed = passed && filter.applyToData(data);
      });
    }
    return passed;
  });

  self.table = self.$target.DataTable({
    drawCallback() {
      const api = this.api();
      let lastCompany = null;
      let lastEmail = null;
      let stripe = true;

      api.rows({ page: 'current' }).every(function f(rowIdx) {
        const row = this.row(rowIdx);
        const data = row.data();
        if (!data.company.id) {
          return false;
        }
        const $tr = $(row.node());

        $tr.toggleClass('first-company-row', lastCompany !== data.company.id);
        $tr.toggleClass('duplicate', lastCompany === data.company.id);

        if (lastCompany !== data.company.id) {
          stripe = !stripe;

          const $checkCtn = $('<div>');
          const $checkbox = $('<input>').addClass('company-invite-trigger').attr({
            type: 'checkbox',
            'data-company-id': data.company.id,
          });
          $checkCtn.append($checkbox);
          $(api.cell(rowIdx, 'company-checkbox:name').node()).html($checkCtn);
        } else {
          $(api.cell(rowIdx, 'company-checkbox:name').node()).empty();
        }
        $tr.toggleClass('even', stripe);

        $(api.cell(rowIdx, 'email:name').node()).toggleClass(
          'show-on-duplicate',
          lastEmail !== data.normalisedEmail,
        );

        lastCompany = data.company.id;
        lastEmail = data.normalisedEmail;
      });

      self.$target.find('.label-success').tooltip({ placement: 'right' });
    },
    paging: false,
    data: self.getTradeData(self.tradePackageSelector.getSelectedTradeId()),
    buttons: [
      {
        extend: 'colvis',
        text: 'Hide/Show Columns',
        columns: '.data-table-viewable',
        className: 'btn btn-secondary btn-sm ml-1',
      },
    ],
    order: [
      [1, 'asc'],
      [3, 'asc'],
    ],
    createdRow(row, data, index) {
      if (data.packageIds.includes(self.tradePackageSelector.getSelectedPackageId())) {
        $(row).attr('title', self.disabledMessage);
      }
    },
    columns: self.buildDataTableColumns(),
  });

  self.table.on('draw', () => {
    self.updateSelectAllCheckbox();
  });

  const theTable = self.table;
  self.filterForm = new DataTableFilterForm(theTable);

  self.hasLoaded(true);

  new $.fn.dataTable.FixedHeader(self.table, {});

  self.updateSelectAllCheckbox();
  self.table
    .buttons()
    .container()
    .appendTo(self.$container.find('.btn-container-inline'))
    .find('button')
    .removeClass('dt-button');
};

InvitationList.prototype.buildDataTableColumns = function () {
  const self = this;
  const updateRequestUrl = Routing.generate('app_addressbookcompanyrequest_updates');
  const isPhilippines = self.$target.find('th.ph-field').length;
  const $preferredIndicatorTemplate = $('.preferred-indicator');

  const dataTableColumns = [
    {
      // col 0
      class: 'checkbox-cell',
      orderable: false,
      name: 'company-checkbox',
      render: () => '',
    },
    {
      // col 1
      data: null,
      render(__, renderType, row) {
        if (renderType === 'sort') {
          return `${row.company.name} ${row.company.id}`;
        }

        const $span = $('<span>').addClass('hide-duplicate');
        const $link = $('<button>');

        $link.addClass('btn btn-link vcard').text(row.company.name).attr({
          'data-company-id': row.company.id,
        });

        $span.append($link);
        return $span.prop('outerHTML');
      },
    },
    {
      // col 2
      data: null,
      class: 'checkbox-cell',
      orderable: false,
      render(__, ___, row) {
        const selectedPackageId = self.tradePackageSelector.getSelectedPackageId();
        const $checkCtn = $('<div>');
        const $checkbox = $('<input>')
          .attr({
            type: 'checkbox',
            'data-contact-id': row.id,
            'data-company-id': row.company.id,
          })
          .addClass('invite-trigger');

        if (isNaN(selectedPackageId)) {
          $checkbox.attr({
            disabled: 'disabled',
            title: 'Please select a package',
          });
        } else if (row.packageIds.includes(selectedPackageId)) {
          $checkbox.attr({
            checked: 'checked',
            disabled: 'disabled',
            title: self.disabledMessage,
          });
        } else if (!row.email) {
          $checkbox.attr({
            disabled: 'disabled',
            title: 'This contact requires an email address to be invited',
          });
        } else if (row.unsubscribedAt !== undefined && row.unsubscribedAt != null) {
          $checkbox.attr({
            disabled: 'disabled',
            title: 'This contact has elected not to receive invitations for this project.',
          });
        }

        if (
          self.invites[selectedPackageId] &&
          self.invites[selectedPackageId].includes(row.id) &&
          !$checkbox.attr('disabled')
        ) {
          $checkbox.attr('checked', 'checked');
        }

        $checkCtn.append($checkbox);
        return $checkCtn.html();
      },
    },
    {
      // col 3
      class: 'show-on-duplicate',
      data: 'full_name',
      orderData: [3, 1],
      render(fullName, __, row) {
        const contactTypeArray = row.types.map((val) => val.type);
        const contactTypes = new ContactTypeIcon(contactTypeArray);
        const $contactTypeSpan = contactTypes.getIcons();

        const $pendingUpdateButton =
          row.updateRequests.length > 0 &&
          $('<a>')
            .addClass('btn btn-warning btn-xs p-1 mr-1')
            .toggleClass('ml-1', contactTypeArray.length !== 0)
            .attr({
              target: '_blank',
              href: updateRequestUrl,
              title: 'Pending contact details changes. Click here to review and approve.',
            })
            .append($('<i>').addClass('icon icon-notifications-solid'));

        const $output = $('<div>');

        const outputContent = [];

        if ($preferredIndicatorTemplate) {
          const $preferredIndicator = $preferredIndicatorTemplate.clone();
          $preferredIndicator.append(renderToStaticMarkup(<PreferredIndicator />));
          if (row.isPreferred) {
            $preferredIndicator.removeClass('visibility-hidden');
          }
          outputContent.push($preferredIndicator);
        }

        outputContent.push($contactTypeSpan, $pendingUpdateButton, _.escape(fullName));

        if (row.isNew) {
          outputContent.unshift(
            $('<span class="tag label-success">New</span>')
              .addClass('mr-2')
              .attr('data-toggle', 'tooltip')
              .attr('data-tooltip-content-class', 'tooltip-inner')
              .attr('title', 'Contact added in last 3 months'),
          );
        }

        $output.append(outputContent);

        return $output.prop('outerHTML');
      },
    },
    {
      // col 4
      data: 'responseRate',
      className: 'dt-body-left',
      orderData: [4, 1],
      render: (responseRate, renderType, row) => {
        if (renderType !== 'display') return responseRate ? responseRate : '';

        return $('<span>')
          .text(`${responseRate}% (${row.respondedInvites}/${row.totalInvites})`)
          .prop('outerHTML');
      },
    },
    {
      // col 5
      data: 'position',
      visible: false,
      orderable: false,
      render: $.fn.dataTable.render.text(),
    },
    {
      // col 6
      data: 'normalisedEmail',
      name: 'email',
      orderable: false,
      render: (txt) => $('<span>').addClass('hide-duplicate').text(txt).prop('outerHTML'),
    },
    {
      // col 7
      type: 'number',
      orderData: [7, 1],
      data: 'company.address.shortAddress',
      render: (txt) => $('<span>').addClass('hide-duplicate').text(txt).prop('outerHTML'),
    },
    {
      // col 8 (removed in PH)
      type: 'number',
      orderData: [8, 1],
      data(obj) {
        if (
          obj.company.address.latitude !== undefined &&
          obj.company.address.longitude !== undefined
        ) {
          const fromLocation = new Location(self.fromLat, self.fromLng);
          return fromLocation.distFromLocLng(
            obj.company.address.latitude,
            obj.company.address.longitude,
          );
        }
        return null;
      },
      render(unformattedDistance, renderType) {
        switch (renderType) {
          case 'filter':
          case 'sort':
            return unformattedDistance;
          default: {
            const $distanceContainer = $('<div>');
            $distanceContainer.append(
              $('<span>')
                .addClass('hide-duplicate locator-dist-calc')
                .text(Location.readableDistance(unformattedDistance)),
            );

            return $distanceContainer.html();
          }
        }
      },
    },
    {
      // col 9 (10 in PH)
      type: 'array',
      visible: false,
      orderable: false,
      data(obj) {
        return _.uniq(obj.addressBookContactListEntries, (entry) => entry.list.id)
          .filter((entry) => !entry.list.deletedAt)
          .map((entry) => entry.list);
      },
      render(contactLists, renderType) {
        if (renderType !== 'display') {
          return contactLists.map((list) => list.id);
        }

        return contactLists.map((list) => _.escape(list.name)).join(', ');
      },
    },
    {
      // col 10 (11 in PH)
      type: 'array',
      visible: false,
      orderable: false,
      data(obj) {
        return _.uniq(obj.company.tags, (tag) => tag.id).filter((tag) =>
          tag ? !tag.deletedAt : false,
        );
      },
      render(companyLists, renderType) {
        if (renderType !== 'display') {
          return companyLists.map((tag) => tag.id);
        }

        return $('<span>')
          .addClass('hide-duplicate')
          .text(companyLists.map((tag) => tag.name).join(', '))
          .prop('outerHTML');
      },
    },
  ];

  if (isPhilippines) {
    const phColumns = [
      {
        // col 8
        visible: true,
        data: 'company.address.district',
        orderData: [8, 1],
        render: (district, renderType) => {
          if (renderType !== 'display') {
            return district;
          }

          return $('<span>')
            .addClass('hide-duplicate')
            .text(district || '-')
            .prop('outerHTML');
        },
      },
      {
        // col 9
        visible: true,
        data: 'company.address.province',
        orderData: [9, 1],
        render: (province, renderType) => {
          if (renderType !== 'display') {
            return province;
          }
          return $('<span>')
            .addClass('hide-duplicate')
            .text(province || '-')
            .prop('outerHTML');
        },
      },
    ];
    dataTableColumns.splice(8, 1, ...phColumns);
  }

  // Check if <th> exist for custom fields
  self.$target.find('th.custom-field').each((i, th) => {
    const type = $(th).attr('data-custom-attribute');
    if (type) {
      dataTableColumns.push({
        visible: false,
        orderData: [dataTableColumns.length, 1],
        data: `company.${type}`,
        render: (txt) =>
          $('<span>')
            .addClass('hide-duplicate')
            .text(txt === null ? '' : txt)
            .prop('outerHTML'),
      });
    }
  });

  return dataTableColumns;
};

InvitationList.prototype.convertData = function (data) {
  const formattedData = data;
  formattedData.DT_RowId = `dt_data_${data.id}`;

  formattedData.firstName = getFirstName(data);
  formattedData.lastName = getLastName(data);
  formattedData.full_name = getFullName(data);

  formattedData.company.address.shortAddress = data.company.address
    ? getShortAddress(data.company.address)
    : { shortAddress: 'No Address' };

  formattedData.packageIds = _.map(data.rfqs, (rfq) => rfq.package.id);

  formattedData.normalisedEmail = data.email || '-';

  formattedData.inviteable = data.email && data.unsubscribedAt === null;

  formattedData.respondedInvites = data.rfqCount ? data.rfqCount - data.noResponseCount : 0;
  formattedData.totalInvites = data.rfqCount ?? 0;

  formattedData.responseRate = data.rfqCount
    ? Math.floor(100 * ((data.rfqCount - data.noResponseCount) / data.rfqCount))
    : 0;

  if (
    formattedData.responseRate !== null &&
    (formattedData.responseRate < 0 || formattedData.responseRate > 100)
  ) {
    captureException(
      `Got invalid rfqCount (${data.rfqCount}) vs noResponseCount (${data.noResponseCount}) for AddressBookContact id ${data.id}`,
    );
  }

  return formattedData;
};

InvitationList.prototype.drawTable = function (tradeId) {
  const self = this;
  if (self.table) {
    const tableData = self.getTradeData(tradeId);
    self.table.rows().remove();
    self.toggleTableDisplay(tradeId);
    self.table.rows.add(tableData).draw();
  }
  self.updateSubbieCounter();
  self.$target.trigger('data-updated');
};

InvitationList.prototype.updateTable = function (tradeId, fetch, cb) {
  const self = this;
  if (fetch) {
    self.fetchContactsForTrade(tradeId, () => {
      self.drawTable(tradeId);
    });
  } else {
    self.drawTable(tradeId);
  }
  if (typeof cb !== 'undefined') {
    cb();
  }
};

InvitationList.prototype.toggleTableDisplay = function (tradeId) {
  const self = this;

  const $targetWrapper = self.$target.closest('.dataTables_wrapper');
  const immediate = true;

  if (self.contacts[tradeId].length === 0) {
    if (self.$emptyPlaceholder.length > 0 && self.tableShowing === true) {
      if (immediate) {
        $targetWrapper.hide();
        self.$emptyPlaceholder.show();
      } else {
        $targetWrapper.fadeOut(() => {
          self.$emptyPlaceholder.fadeIn();
        });
      }
      self.tableShowing = false;
    } else if (self.$emptyPlaceholder.length === 0 && self.tableShowing === true) {
      $targetWrapper.show();
      self.tableShowing = true;
    }
  } else if (self.$emptyPlaceholder.length > 0 && self.tableShowing === false) {
    if (immediate) {
      $targetWrapper.show();
      self.$emptyPlaceholder.hide();
    } else {
      self.$emptyPlaceholder.fadeOut(() => {
        $targetWrapper.fadeIn();
      });
    }
    self.tableShowing = true;
  }
};

InvitationList.prototype.nextPreviousTrades = function (actionClass, keyPressed) {
  const selectedPackageId = this.tradePackageSelector.getSelectedPackageId();
  if (this.invites[selectedPackageId]) {
    const disabledState = this.invites[selectedPackageId].length > 0 && this.dueDateHasError;
    if (!disabledState) {
      // eslint-disable-next-line no-unused-expressions
      actionClass === 'previousTradeLink'
        ? this.tradePackageSelector.selectPreviousTrade()
        : this.tradePackageSelector.selectNextTrade();

      window.analyticsService.addEvent('interact', {
        action: 'InviteTableChangeTradeKeyPressed',
        keyPressed,
        actionClass,
      });
    }
  }
};

$(() => {
  $('.invite-module')
    .toArray()
    .forEach((container) => {
      const $container = $(container);
      const invitationList = new InvitationList(
        $container,
        $container.find('.invitation-table'),
        $container.attr('data-stage'),
        $container.attr('data-stage-type'),
      );

      const actionKeyMap = new Map([
        ['nextTradeLink', ['Right', 'ArrowRight', 'd', 'D']],
        ['previousTradeLink', ['Left', 'ArrowLeft', 'a', 'A']],
      ]);

      $('body').on('keyup', (keyEvent) => {
        const $target = $(keyEvent.target);
        const isTargetInContainer = $.contains($container.get(0), keyEvent.target);

        // Should do nothing when: the default action has been cancelled or;
        if (
          keyEvent.defaultPrevented ||
          // The focused element is not inside the container and is not the body
          (!$target.is('body') && !isTargetInContainer)
        ) {
          // Note: if we add a text input inside the container
          // We will likely need to add a new condition
          return;
        }

        actionKeyMap.forEach((keyCodes, actionClass) => {
          if (keyCodes.includes(keyEvent.key)) {
            invitationList.nextPreviousTrades(actionClass, keyEvent.key);
          }
        });
      });
    });
});
