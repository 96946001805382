import $ from 'jquery';
import Routing from 'routing';
import { captureMessage } from '@sentry/browser';
// @ts-ignore
import qq from 'fine-uploader/lib/s3';
import E1Request from '../E1Request';
import PendingUploadManager, { SavedFile, UploadSessionList } from './PendingUploadManager';

const excludedPatterns = [/^\./, /\/\./, /\b__MACOSX\//, /\bThumbs.db\b/];

const MAX_FILE_SIZE_BYTES = 10e9; // 10GB

export const UploadStatus = {
  ADDED: 's3:added',
  COMMIT: 's3:commit',
  COMPLETE: 's3:upload_complete',
  DRAFT: 's3:draft',
  ERROR: 's3:filesError',
  PROGRESS: 's3:progressUpdate',
};

type AwsRegion = string;

type UploaderFile = File; // TODO: resolve type

interface ProgressCallbacks {
  onTotalProgress: (totalUploaded: number, total: number) => void;
  onAllComplete: (succeeded: string[], failed: string[]) => void;
  onSubmit: (id: string, name: string) => qq.Promise;
  onSubmitted: (id: string) => void;
  onError: (id: string, name: string, errorReason: string) => void;
}

export default class ServerlessUploader {
  private readonly uploadButton: HTMLElement | null;
  private readonly extraButton: HTMLElement | null;
  private readonly fineUploader: qq.s3.FineUploader;
  private readonly uploadManager: PendingUploadManager;
  private readonly dropzone: HTMLElement | null;
  private autoUnzip: boolean;
  private readonly endpoint: string;
  private readonly region: AwsRegion;
  private readonly publicKey: string;
  private readonly hostUuid: string;
  private readonly signatureEndpoint: string;

  constructor(
    private $target: JQuery,
    private dropzoneClass: string,
    private buttonId: string,
    private typeKey: string,
    private parentId: number,
    private extraButtonId?: string,
  ) {
    this.$target = $target;
    this.uploadButton = document.getElementById(this.buttonId);
    this.extraButton = this.extraButtonId ? document.getElementById(this.extraButtonId) : null;
    this.dropzone = this.getDropZone(this.dropzoneClass, (files: UploaderFile[]) =>
      this.fineUploader.addFiles(files),
    );
    this.autoUnzip = true;

    const {
      endpoint,
      region,
      publicKey,
      hostUuid,
      signatureEndpoint,
      notifyEndpoint,
      pendingUploadEndpoint,
    } = this.$target.data();

    this.uploadManager = new PendingUploadManager(
      this.parentId,
      this.typeKey,
      notifyEndpoint,
      pendingUploadEndpoint,
    );
    this.endpoint = endpoint;
    this.region = region;
    this.publicKey = publicKey;
    this.hostUuid = hostUuid;
    this.signatureEndpoint = signatureEndpoint;

    this.fineUploader = this.initUploader(this.dropzone);

    this.uploadManager.addSubscriber(
      ({ sessionFinished, completed, drafts, duplicates, invalid, errored }) => {
        const errorCount = duplicates.length + invalid.length + errored.length;

        this.$target.trigger(UploadStatus.PROGRESS);

        if (completed.length) {
          const data = completed.reduce(
            (
              acc,
              { is_archive: isArchive, saved_entity: file, archive_entries: archiveEntries },
            ) => {
              if (!isArchive) {
                if (file) {
                  acc.push(file);
                }
                return acc;
              }
              return acc.concat(
                archiveEntries
                  .filter(({ saved_entity: archiveFile }) => archiveFile !== null)
                  .map(({ saved_entity: archiveFile }) => archiveFile),
              );
            },
            new Array<SavedFile>(),
          );
          this.$target.trigger(UploadStatus.COMMIT, { data }); // UploadModule:109
        }

        if (drafts.length) {
          this.$target.trigger(UploadStatus.DRAFT, { drafts });
        }

        if (errorCount) {
          this.$target.trigger(UploadStatus.ERROR);
        }

        if (sessionFinished) {
          this.$target.trigger(UploadStatus.COMPLETE);
        }
      },
    );
  }

  public getUploadManager(): PendingUploadManager {
    return this.uploadManager;
  }

  public updateConfig(newSetting: { unzip: boolean }) {
    if (!('unzip' in newSetting) && Object.keys(newSetting).length !== 1) {
      throw new Error('Only changing the unzip setting is supported at this time');
    }
    this.autoUnzip = newSetting.unzip;
  }

  public setAutoUnzip(autoUnzip: boolean) {
    this.autoUnzip = autoUnzip;
    return this;
  }

  public enableAutoUnzip() {
    this.autoUnzip = true;
    return this;
  }

  public disableAutoUnzip() {
    this.autoUnzip = false;
    return this;
  }

  private onSubmitted(file: UploaderFile) {
    this.$target.trigger(UploadStatus.ADDED, file);
  }

  private getHostUuid() {
    return this.hostUuid;
  }

  private onTotalProgress(uploaded: number, total: number) {
    this.uploadManager.setBytesUploaded(uploaded);
    this.uploadManager.setBytesTotal(total);
    this.$target.trigger(UploadStatus.PROGRESS, { uploaded, total });
  }

  private onAllComplete() {
    this.uploadManager.setBytesFinished();
    this.$target.trigger(UploadStatus.COMPLETE);
  }

  private static async showErrorModal(message: string, fileName: string) {
    return new E1Request<
      // eslint-disable-next-line camelcase
      { success: true; modal_string: string },
      { message: string; fileName: string }
    >(Routing.generate('app_error_file_upload'), 'POST', { message, fileName }).submit();
  }

  // TODO: at some point we may need to surface upload sessions that have not yet completed
  async getUnresolvedUploads(): Promise<UploadSessionList> {
    return this.uploadManager.getPendingUploadSessionsForAccount();
  }

  private getOptions(dropzone: HTMLElement | null): qq.s3.S3CoreOptions {
    const options = {};
    if (this.uploadButton) {
      // eslint-disable-next-line fp/no-mutating-assign
      Object.assign(options, { button: this.uploadButton });
    }
    if (this.extraButton) {
      $(this.extraButton).addClass('activated');
      // eslint-disable-next-line fp/no-mutating-assign
      Object.assign(options, { extraButtons: [{ element: this.extraButton }] });
    }

    const baseObjectProperties = {
      region: this.region,
    };

    // Fix for localstack bucket
    // FineUploader util function does not parse the bucket name properly
    // then CompleteMultiPartUpload fails with a bucket name mismatch
    const objectProperties = {
      ...baseObjectProperties,
      ...(['localhost', 'localstack'].some((_) => this.endpoint.includes(_))
        ? { bucket: () => this.endpoint.split('/').pop() }
        : {}),
    };

    return {
      request: {
        endpoint: this.endpoint,
        accessKey: this.publicKey,
      },
      ...options,
      signature: {
        version: 4,
        endpoint: this.signatureEndpoint,
      },
      objectProperties,
      cors: {
        expected: true,
      },
      chunking: {
        enabled: true,
        concurrent: {
          enabled: true,
        },
        partSize: 5242880 * 2,
      },
      maxConnections: 3,
      resume: {
        enabled: true,
      },
      validation: {
        sizeLimit: MAX_FILE_SIZE_BYTES,
        stopOnFirstInvalidFile: false,
        emptyError: '{file} is empty and skipped',
        allowedExtensions: [],
      },
      dragAndDrop: {
        reportDirectoryPaths: true,
        dropzone,
      },
      callbacks: this.getCallbacks(),
    };
  }

  private getCallbacks(): ProgressCallbacks {
    const { uploadManager } = this;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const uploader = this;

    return {
      onTotalProgress: function onTotalProgress(totalUploaded: number, total: number) {
        uploader.onTotalProgress(totalUploaded, total);
      },
      onAllComplete: function onAllComplete() {
        uploader.onAllComplete();
      },
      onSubmit: function handleSubmit(id: string, name: string) {
        if (excludedPatterns.find((regex) => regex.test(name))) return false;

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const ul: qq.s3.FineUploader = this;
        const fPromise = new qq.Promise();

        const file = ul.getFile(id);
        const { size } = file;
        const uuid = ul.getUuid(id);
        // memo this in so the user can't change it while we wait for the
        // upload session promise to resolve.
        const { autoUnzip } = uploader;
        /*
         * The documents table has rows representing folders.
         * Each of these has a button that allows files to be added to said folder.
         * Set the directory to the button's path attribute, or else the path provided by FU.
         */
        const button = ul.getButton(id);
        const directory = button ? $(button).data('path') : file.qqPath || null;

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        uploadManager.commit({ uuid, name, size, directory, autoUnzip }).then((uploadSessionId) => {
          const uploadParams = {
            'upload-session-id': uploadSessionId,
            'host-uuid': uploader.getHostUuid(),
            'auto-unzip': autoUnzip,
          };
          ul.setParams(uploadParams, id);

          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          void uploadManager.startPolling();
          fPromise.success();
        });

        return fPromise;
      },
      onError: (id, name, errorReason) => {
        if (errorReason.length) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          ServerlessUploader.showErrorModal(errorReason, name);
          captureMessage(errorReason, {
            tags: { module: 'sfm' },
            extra: { id, name },
          });
        }
      },
      onSubmitted: function handleSubmit(id: string) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const ul: qq.s3.FineUploader = this;
        uploader.onSubmitted(ul.getFile(id));
      },
    };
  }

  private initUploader(dropzone: HTMLElement | null): qq.s3.FineUploaderBasic {
    return new qq.s3.FineUploaderBasic(this.getOptions(dropzone));
  }

  private getDropZone(
    dzClass: string,
    onDrop: (files: UploaderFile[]) => unknown,
  ): HTMLElement | null {
    const $dropzone = this.$target.find(`.${dzClass}`);
    if ($dropzone.length) {
      $dropzone.removeClass('hide');
      qq.DragAndDrop({
        dropZoneElements: [$dropzone[0]],
        classes: {
          dropActive: 'upload-active',
        },
        callbacks: {
          processingDroppedFilesComplete: onDrop,
        },
      });
    }

    return $dropzone[0] || null;
  }
}
