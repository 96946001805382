import { OperationVariables, QueryHookOptions, TypedDocumentNode, useQuery } from '@apollo/client';
import { SCOPES_APOLLO_CLIENT_NAME } from '../constants';

export const useScopesQuery = <
  TData = unknown,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>,
) =>
  useQuery<TData, TVariables>(query, {
    ...options,
    context: { clientName: SCOPES_APOLLO_CLIENT_NAME },
  });
