import { gql } from '@apollo/client';

export const GET_NETWORK_INVITES_STAGE = gql`
  query GetNetworkInvitesStage($stageId: EntityId!) {
    stage(id: $stageId) {
      ... on UnrestrictedStage {
        id
        name
        type
        activePackages {
          id
          title
          quotesDueAt
          fullSet
          countOfCurrentPackageDocuments
          rfqs {
            id
            contact {
              id
              company {
                id
                profileId
              }
            }
          }
        }
        trades {
          id
          name
          stockTrade {
            id
            name
          }
        }
        projectAddress {
          postcode
        }
      }
    }
  }
`;

export const GET_BUILDER_CONTACT_LIST_ENTRIES = gql`
  query GetBuilderContactListEntries {
    defaultAddressBook {
      id
      lists {
        id
        name
        addressBookContactListEntries {
          id
          trade {
            id
            stockTrade {
              id
            }
          }
          contact {
            id
            unsubscribedAt
            company {
              id
            }
          }
        }
      }
    }
  }
`;
