import { DistanceUnit } from '@shared';
import { SelectedPlace } from './types';
import {
  DISTANCE_OPTIONS,
  DistanceValueOption,
  LocationOption,
  SearchServiceArea,
} from '@builder/common/SubbieNetwork/utils/filters/types';

const serviceArea = (
  adminLevel1: string | undefined,
  country: string | undefined,
  resultTypes: string[] | undefined,
): SearchServiceArea | undefined => {
  if (!adminLevel1 || !country || !resultTypes) return undefined;
  const isCountrySearch = resultTypes?.includes('country');
  const isAustralianStateSearch =
    country === 'Australia' && resultTypes?.includes('administrative_area_level_1');

  if (isCountrySearch) {
    return {
      country,
    };
  }

  if (isAustralianStateSearch) {
    return {
      country,
      state: adminLevel1,
    };
  }

  return undefined;
};

export const locationOptions = (selectedPlace: SelectedPlace): LocationOption => {
  const { lat, lon, adminLevel1, country, resultTypes, locationLabel } = selectedPlace;

  return {
    lat,
    lon,
    locationLabel,
    serviceArea: serviceArea(adminLevel1, country, resultTypes),
  };
};

const toKilometersRound = (miles: number) => Math.round(miles * 1.61);

/**
 * Returns the distance options based on the unit. If the unit is miles, the
 * label will stay the same (treated as miles) but the value will be converted
 * to kilometers (for search).
 */
export const getDistanceOptions = (unit: DistanceUnit): DistanceValueOption[] =>
  DISTANCE_OPTIONS.map((value) => ({
    label: `${value.toString()}${unit}`,
    value: unit === DistanceUnit.KM ? value : toKilometersRound(value),
  }));
