import { useQuery } from '@apollo/client';
import { PACKAGES_FOR_EXPORT } from '../queries';
import { EntityId } from '../../../../../../../types';
import { GetPackagesForExport, GetPackagesForExportVariables } from '../types/GetPackagesForExport';

export const useGetPackagesForExport = (stageId: EntityId) => {
  const { data, loading } = useQuery<GetPackagesForExport, GetPackagesForExportVariables>(
    PACKAGES_FOR_EXPORT,
    {
      variables: { stageId },
    },
  );

  return {
    activePackages: data?.activePackages,
    loading,
  };
};
