import { Nav } from '@estimateone/frontend-components';
import { ProfileSection } from '../enums';

type ProfilesNavProps = {
  currentSection: ProfileSection;
  setSection?: (section: ProfileSection) => void;
};

const NavSections = [ProfileSection.SUMMARY];

const ProfilesNav = (
  { currentSection, setSection = () => {} }: ProfilesNavProps, // eslint-disable-line @typescript-eslint/no-empty-function
) => (
  <Nav>
    {NavSections.map((section: ProfileSection) => (
      <Nav.Item
        key={section}
        label={section}
        isActive={currentSection === section}
        onClick={() => setSection(section)}
      />
    ))}
  </Nav>
);

export default ProfilesNav;
