import { useField } from 'react-final-form';
import {
  Fieldset,
  FieldWrapper,
  Legend,
  RequiredIndicator,
  Select,
} from '@estimateone/frontend-components';
import { ContractSizeEnum } from '@ascension/_gqltypes/subbie.generated';
import { ValueLabelOption } from '@estimateone/frontend-components/src/components/Form/Select/types';
import styles from './styles.module.scss';

export type ContractSizeValueOption<T> = { monetaryValue: number } & ValueLabelOption<T>;

export const minOptions: ContractSizeValueOption<ContractSizeEnum>[] = [
  { value: ContractSizeEnum.SIZE_10K, label: '10k', monetaryValue: 10000 },
  { value: ContractSizeEnum.SIZE_50K, label: '50k', monetaryValue: 50000 },
  { value: ContractSizeEnum.SIZE_100K, label: '100k', monetaryValue: 100000 },
  { value: ContractSizeEnum.SIZE_250K, label: '250k', monetaryValue: 250000 },
  { value: ContractSizeEnum.SIZE_500K, label: '500k', monetaryValue: 500000 },
  { value: ContractSizeEnum.SIZE_1M, label: '1M', monetaryValue: 1000000 },
  { value: ContractSizeEnum.SIZE_5M, label: '5M', monetaryValue: 5000000 },
  { value: ContractSizeEnum.SIZE_10M, label: '10M', monetaryValue: 10000000 },
  { value: ContractSizeEnum.SIZE_25M, label: '25M', monetaryValue: 25000000 },
];

export const maxOptions: ContractSizeValueOption<ContractSizeEnum>[] = [
  { value: ContractSizeEnum.SIZE_10K, label: '10k', monetaryValue: 10000 },
  { value: ContractSizeEnum.SIZE_50K, label: '50k', monetaryValue: 50000 },
  { value: ContractSizeEnum.SIZE_100K, label: '100k', monetaryValue: 100000 },
  { value: ContractSizeEnum.SIZE_250K, label: '250k', monetaryValue: 250000 },
  { value: ContractSizeEnum.SIZE_500K, label: '500k', monetaryValue: 500000 },
  { value: ContractSizeEnum.SIZE_1M, label: '1M', monetaryValue: 1000000 },
  { value: ContractSizeEnum.SIZE_5M, label: '5M', monetaryValue: 5000000 },
  { value: ContractSizeEnum.SIZE_10M, label: '10M', monetaryValue: 10000000 },
  { value: ContractSizeEnum.SIZE_25M, label: '25M', monetaryValue: 25000000 },
  { value: ContractSizeEnum.SIZE_50M_PLUS, label: '50M+', monetaryValue: 50000000 },
];

export const getContractSizeMinOptionForValue = (
  value?: ContractSizeEnum | null,
): ContractSizeValueOption<ContractSizeEnum> | null =>
  minOptions.find((option) => value === option.value) ?? null;

export const getContractSizeMaxOptionForValue = (
  value?: ContractSizeEnum | null,
): ContractSizeValueOption<ContractSizeEnum> | null =>
  maxOptions.find((option) => value === option.value) ?? null;

export type ContractSizeFilterFieldTypes = {
  contractSizeMin: ContractSizeValueOption<ContractSizeEnum> | null;
  contractSizeMax: ContractSizeValueOption<ContractSizeEnum> | null;
};

export const ContractSizeFilter = () => {
  const minField = useField('contractSizeMin', {
    allowNull: true,
  });
  const maxField = useField('contractSizeMax', {
    allowNull: true,
  });

  const errors = []
    .concat(
      minField.meta.error,
      maxField.meta.error,
      minField.meta.submitError,
      maxField.meta.submitError,
    )
    .filter((v) => v);

  return (
    <FieldWrapper errors={errors}>
      <Fieldset className={styles.contractSizeWrapper} showChildErrorStyles>
        <Legend>
          Preferred Contract Value <RequiredIndicator />
        </Legend>
        <div data-name="contractSizeMin">
          <Select<ContractSizeValueOption<ContractSizeEnum>>
            id="contractSizeMin"
            inputId="contractSizeMin"
            label="Minimum"
            hideLabel
            options={minOptions}
            isClearable
            {...minField.input}
          />
        </div>
        <div data-name="contractSizeMax">
          <Select<ContractSizeValueOption<ContractSizeEnum>>
            id="contractSizeMax"
            inputId="contractSizeMax"
            label="Maximum"
            hideLabel
            options={maxOptions}
            isClearable
            {...maxField.input}
          />
        </div>
      </Fieldset>
    </FieldWrapper>
  );
};
