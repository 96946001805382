import moment from 'moment-timezone';
import { ProductType } from '@ascension/enums';
import { AccountType } from '@ascension/_gqltypes/admin.generated';

export const isAdditionalState = (
  isMultiState: boolean,
  licensePrice: number,
  productPrice: number,
): boolean => isMultiState && licensePrice < productPrice;

export const getRenewDate = (
  accountType: AccountType,
  isAutoRenew: boolean,
  expiredAt?: string,
): string => {
  const isSubblier =
    accountType === AccountType.SUPPLIER || accountType === AccountType.SUBCONTRACTOR;

  if (isSubblier && expiredAt && isAutoRenew) {
    return moment(expiredAt).format('DD MMM YY');
  }

  const isBuilder = AccountType.BUILDER === accountType;
  if (isBuilder && expiredAt) {
    return moment(expiredAt).format('DD MMM YY');
  }

  return '-';
};

export const getProductName = (productTag: string, productName: string): string =>
  productTag === 'subbie_free'
    ? `${ProductType.SubbieFree} Membership`
    : productTag === 'builder_free'
      ? 'Free Membership'
      : productName;

export const getHasAdditionalStateDiscount = (
  hasAtLeastOneFullPriceActiveMultiStateLicense: boolean,
  isMultiState: boolean,
  licensePrice: number,
  productPrice: number,
): boolean =>
  hasAtLeastOneFullPriceActiveMultiStateLicense &&
  isAdditionalState(isMultiState, licensePrice, productPrice);

export const formatDate = (date: string): string => date;

export const getPaidLicenseProviderName = (
  licensePrice: number,
  paidLicenseProviderName?: string,
): string | null => {
  if (paidLicenseProviderName && licensePrice === 0) {
    return paidLicenseProviderName;
  }

  return null;
};
