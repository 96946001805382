import { useMutation } from '@apollo/client';
import { DELETE_PENDING_ADDENDUM } from '../mutations';
import {
  deletePendingAddendum as DeletePendingAddendum,
  deletePendingAddendumVariables,
} from '../types/deletePendingAddendum';
import { EntityId } from '@ascension/types';

export const useDeletePendingAddendum = (stageId: EntityId) => {
  const [deletePendingAddendum, { loading }] = useMutation<
    DeletePendingAddendum,
    deletePendingAddendumVariables
  >(DELETE_PENDING_ADDENDUM, {
    update: (cache, { data }) => {
      if (data?.deletePendingAddendum?.__typename === 'Addendum') {
        cache.evict({ id: `Addendum:${data.deletePendingAddendum.id}` });
        cache.gc();
      }
    },
  });

  const submit = async (id: EntityId) => {
    const { data } = await deletePendingAddendum({
      variables: { input: { stageId, addendumId: id } },
    });

    return data?.deletePendingAddendum;
  };

  return { submit, loading };
};
