import { useState } from 'react';
import Routing from 'routing';
import {
  Button,
  Modal,
  ModalSize,
  joinClassNames,
  UploadDocumentIcon,
} from '@estimateone/frontend-components';
import { Action, useInteractAnalytics } from '@ascension/components/hooks/Analytics';
import styles from './styles.scss';

type RequestDocsSuccessModalProps = {
  packageName: string;
  builderName: string;
};

const profilePageLink = Routing.generate('app_new_subbie_profile_view');

export const RequestDocsSuccessModal = ({
  packageName,
  builderName,
}: RequestDocsSuccessModalProps) => {
  const [open, setOpen] = useState(true);
  const { addEvent } = useInteractAnalytics();

  const handleClick = () => {
    addEvent({
      action: Action.SUBBIE_COMPLETE_PROFILE_CLICK,
    });
    window.location.assign(profilePageLink);
  };

  return (
    <Modal
      isOpen={open}
      onRequestClose={() => setOpen(false)}
      size={ModalSize.Small}
      rootElement="body"
    >
      <Modal.Section>
        <div className={styles.headerIcon}>
          <UploadDocumentIcon width={40} height={40} />
        </div>
        <h3 className={styles.headerText}>Document Request Sent</h3>
        <div className={styles.body}>
          <div className={styles.content}>
            A request for access to <span className={styles.highlightText}>{packageName} </span>
            package has been sent to<span className={styles.highlightText}> {builderName}</span>.
            You will be notified once it has been approved.
          </div>
          <div className={joinClassNames(styles.content, styles.highlightText)}>
            Increase your chances of getting approved for the package by completing your company
            profile.
          </div>
        </div>
        <Button className="primaryButton" onClick={handleClick}>
          Complete Profile Now
        </Button>
      </Modal.Section>
    </Modal>
  );
};
