import { useReducer } from 'react';
import {
  Modal,
  ModalSize,
  Button,
  ButtonVariant,
  ActionMenu,
  Icon,
  IconName,
  Tooltip,
} from '@estimateone/frontend-components';
import { DeleteDraftPackageModal } from '@builder/features/ProcurementLettingSchedule/DeleteDraftPackageModal';
import {
  getLettingScheduleStatus,
  LettingScheduleStatus,
} from '@builder/features/ProcurementLettingSchedule/LettingScheduleStatus';
import { AwardPackageSlider } from '../AwardPackageSlider/AwardPackageSlider';
import { UpdatePackageTitleModal } from '../UpdatePackageTitleModal';
import useFlashMessage from '../../../../shared/Util/useFlashMessage';
import { useSetAwardedRfqOnLettingSchedule } from '../AwardPackageSlider/hooks/useSetAwardedRfqOnLettingSchedule';
import { EntityId } from '@ascension/types';
import { Package } from '@builder/features/ProcurementLettingSchedule/types';
import styles from './styles.scss';

export type LettingScheduleOptionsProps = {
  originalPackage: Package;
  stageId: EntityId;
};

type State = {
  isSliderOpen: boolean;
  isModalOpen: boolean;
  isMenuOpen: boolean;
  isUpdatePackageNameOpen: boolean;
  isDeleteDraftPackageOpen: boolean;
};

type Action = {
  type: string;
};

const actionTypes = {
  OPEN_SLIDER: 'OPEN_SLIDER',
  CLOSE_SLIDER: 'CLOSE_SLIDER',
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
  OPEN_MENU: 'OPEN_MENU',
  CLOSE_MENU: 'CLOSE_MENU',
  OPEN_UPDATE_PACKAGE_NAME: 'OPEN_UPDATE_PACKAGE_NAME',
  CLOSE_UPDATE_PACKAGE_NAME: 'CLOSE_UPDATE_PACKAGE_NAME',
  OPEN_DELETE_DRAFT_PACKAGE: 'OPEN_DELETE_DRAFT_PACKAGE',
  CLOSE_DELETE_DRAFT_PACKAGE: 'CLOSE_DELETE_DRAFT_PACKAGE',
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case actionTypes.OPEN_SLIDER:
      return { ...state, isSliderOpen: true };
    case actionTypes.CLOSE_SLIDER:
      return { ...state, isSliderOpen: false };
    case actionTypes.OPEN_MODAL:
      return { ...state, isModalOpen: true };
    case actionTypes.CLOSE_MODAL:
      return { ...state, isModalOpen: false };
    case actionTypes.OPEN_MENU:
      return { ...state, isMenuOpen: true };
    case actionTypes.CLOSE_MENU:
      return { ...state, isMenuOpen: false };
    case actionTypes.OPEN_UPDATE_PACKAGE_NAME:
      return { ...state, isUpdatePackageNameOpen: true };
    case actionTypes.CLOSE_UPDATE_PACKAGE_NAME:
      return { ...state, isUpdatePackageNameOpen: false };
    case actionTypes.OPEN_DELETE_DRAFT_PACKAGE:
      return { ...state, isDeleteDraftPackageOpen: true };
    case actionTypes.CLOSE_DELETE_DRAFT_PACKAGE:
      return { ...state, isDeleteDraftPackageOpen: false };
    default:
      return state;
  }
};

export const LettingScheduleOptions = ({
  originalPackage,
  stageId,
}: LettingScheduleOptionsProps) => {
  const initialState: State = {
    isSliderOpen: false,
    isModalOpen: false,
    isMenuOpen: false,
    isUpdatePackageNameOpen: false,
    isDeleteDraftPackageOpen: false,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const closeSlider = () => dispatch({ type: actionTypes.CLOSE_SLIDER });
  const closeModal = () => dispatch({ type: actionTypes.CLOSE_MODAL });

  const { submit } = useSetAwardedRfqOnLettingSchedule();
  const { success: showSuccessMessage } = useFlashMessage();

  const isDraftPackage = LettingScheduleStatus?.Draft === getLettingScheduleStatus(originalPackage);

  const handleOnConfirmUndoAwarded = async () => {
    if (originalPackage.lettingScheduleDetails?.id) {
      await submit(originalPackage.lettingScheduleDetails.id, null, null);

      closeModal();

      showSuccessMessage({
        title: 'Success',
        message: `${originalPackage.title} is no longer marked as Awarded`,
      });
    }
  };

  const meatballMenuButton = (
    <Button
      variant={ButtonVariant.Transparent}
      aria-expanded={state.isMenuOpen}
      aria-label="Toggle Options Menu"
      onClick={() => {
        dispatch({ type: state.isMenuOpen ? actionTypes.CLOSE_MENU : actionTypes.OPEN_MENU });
      }}
    >
      <Icon name={IconName.More} size={{ height: '0.5em', width: '1.5em' }} />
    </Button>
  );

  const openUpdatePackageName = () => {
    dispatch({ type: actionTypes.CLOSE_MENU });
    dispatch({ type: actionTypes.OPEN_UPDATE_PACKAGE_NAME });
  };

  const closeUpdatePackageName = () => {
    dispatch({ type: actionTypes.CLOSE_UPDATE_PACKAGE_NAME });
  };

  const handleOnSuccessUpdatePackageName = () => {
    closeUpdatePackageName();

    showSuccessMessage({
      title: 'Success',
      message: 'The package name has been updated',
    });
  };

  const openDeleteDraftPackage = () => {
    dispatch({ type: actionTypes.CLOSE_MENU });
    dispatch({ type: actionTypes.OPEN_DELETE_DRAFT_PACKAGE });
  };

  const closeDeleteDraftPackage = () => {
    dispatch({ type: actionTypes.CLOSE_DELETE_DRAFT_PACKAGE });
  };

  const handleOnSuccessDeleteDraftPackage = () => {
    closeDeleteDraftPackage();

    showSuccessMessage({
      title: 'Success',
      message: 'This package has been successfully deleted.',
    });
  };

  return (
    <>
      <ActionMenu
        triggerElement={meatballMenuButton}
        visible={state.isMenuOpen}
        onClickOutside={() => dispatch({ type: actionTypes.CLOSE_MENU })}
      >
        <ActionMenu.Item onClick={openUpdatePackageName}>Change Package Name</ActionMenu.Item>
        {originalPackage.lettingScheduleDetails?.isAwarded ? (
          <ActionMenu.Item
            onClick={() => {
              dispatch({ type: actionTypes.CLOSE_MENU });
              dispatch({ type: actionTypes.OPEN_MODAL });
            }}
          >
            Undo Mark Package as Awarded
          </ActionMenu.Item>
        ) : (
          <ActionMenu.Item
            onClick={() => {
              dispatch({ type: actionTypes.CLOSE_MENU });
              dispatch({ type: actionTypes.OPEN_SLIDER });
            }}
          >
            Mark Package as Awarded
          </ActionMenu.Item>
        )}
        <ActionMenu.Item disabled={!isDraftPackage} onClick={openDeleteDraftPackage}>
          <div className={styles.packageDeleteWithTooltip}>
            <div>Delete Package</div>
            <div
              className={styles.iconColumnHeader}
              data-tip
              data-for="draft-package-delete"
              aria-describedby="draft-package-delete"
            >
              <Icon name={IconName.Help} />
            </div>
          </div>
        </ActionMenu.Item>
      </ActionMenu>
      <Tooltip
        className={styles.tooltip}
        text="Only packages with a Draft status can be deleted."
        tooltipId="draft-package-delete"
        place="bottom"
      />
      <Modal size={ModalSize.Small} isOpen={state.isModalOpen} onRequestClose={closeModal}>
        <Modal.Section>
          <h1 className={styles.modalHeading}>Undo Mark Package as Awarded</h1>
          <p className={styles.modalText}>
            After confirming this, future notifications of document revisions will be sent to
            invited subcontractors.
          </p>
          <div className={styles.modalButtonDiv}>
            <Button
              className={styles.modalButton}
              variant={ButtonVariant.Grey}
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button
              className={styles.modalButton}
              variant={ButtonVariant.Primary}
              onClick={() => handleOnConfirmUndoAwarded()}
            >
              Confirm
            </Button>
          </div>
        </Modal.Section>
      </Modal>

      {state.isSliderOpen && originalPackage?.lettingScheduleDetails?.id && (
        <AwardPackageSlider
          isOpen={state.isSliderOpen}
          onRequestClose={closeSlider}
          onMarkPackageAsAwarded={() => {
            showSuccessMessage({
              title: 'Success',
              message: `${originalPackage.title} marked as Awarded`,
            });

            closeSlider();
          }}
          packageId={originalPackage.id}
          packageTitle={originalPackage.title}
          packageAssignedUserName={
            originalPackage.lettingScheduleDetails?.assignedToUser?.fullName || ''
          }
          lettingScheduleId={originalPackage.lettingScheduleDetails.id}
          stageId={stageId}
        />
      )}
      <UpdatePackageTitleModal
        packageId={originalPackage.id}
        currentPackageTitle={originalPackage.title}
        isOpen={state.isUpdatePackageNameOpen}
        onCloseHandler={closeUpdatePackageName}
        onSuccess={handleOnSuccessUpdatePackageName}
      />
      <DeleteDraftPackageModal
        packageId={originalPackage.id}
        currentPackageTitle={originalPackage.title}
        isOpen={state.isDeleteDraftPackageOpen}
        onCloseHandler={closeDeleteDraftPackage}
        onSuccess={handleOnSuccessDeleteDraftPackage}
      />
    </>
  );
};
