import { useMutation } from '@apollo/client';
import { DELETE_PACKAGE } from '../mutations';
import { DeletePackage, DeletePackageVariables } from '../types/DeletePackage';
import { EntityId } from '@ascension/types';

export const useDeletePackage = (onSuccess: () => void) => {
  const [deleteDraftPackage, { loading }] = useMutation<DeletePackage, DeletePackageVariables>(
    DELETE_PACKAGE,
    {
      update(cache, { data }) {
        if (data) {
          const deletedPackage = data.deletePackage;
          if ('id' in deletedPackage) {
            onSuccess();
            cache.modify({
              fields: {
                activePackages(existingPackages = []) {
                  return existingPackages.filter((packageRef: { __ref: string }) => {
                    const deletedPackageRef = `Package:${deletedPackage.id}`;
                    // eslint-disable-next-line no-underscore-dangle
                    return deletedPackageRef !== packageRef.__ref;
                  });
                },
              },
            });
          }
        }
      },
    },
  );

  const submit = async (packageId: EntityId) => {
    const { data } = await deleteDraftPackage({ variables: { input: { packageId } } });
    return data?.deletePackage;
  };

  return { submit, loading };
};
