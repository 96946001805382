import React, { Dispatch, SetStateAction } from 'react';
import { components, MenuProps, OptionProps } from 'react-select';
import { Button, CheckIcon, joinClassNames, Select } from '@estimateone/frontend-components';
import { LabelledOption } from '@shared';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

export type ContactListSelectorOption = LabelledOption<EntityId>;
const ApplyListButton = (
  props: MenuProps<ContactListSelectorOption, false> & {
    onClick: () => void;
  },
) => {
  const { children, onClick, innerProps } = props;

  return (
    <components.Menu {...props} innerProps={innerProps} className={styles.menuCustom}>
      {children}
      <hr className={styles.divider} />
      <div className={styles.applyList}>
        <Button onClick={onClick} className={styles.applyListButton}>
          Apply selected list
        </Button>
      </div>
    </components.Menu>
  );
};

const ContactListSelectorOption = (props: OptionProps<ContactListSelectorOption, false>) => {
  const {
    data: { label, value },
    isSelected,
    setValue,
    innerProps,
  } = props;

  const handleOnClick = () => {
    if (isSelected) {
      setValue(null, 'deselect-option');
      return;
    }
    setValue({ value, label }, 'select-option');
  };

  return (
    <div>
      <components.Option {...props} innerProps={{ ...innerProps, onClick: handleOnClick }}>
        <span className={styles.contactListOption} data-testid={`option-${value}`}>
          <CheckIcon
            className={joinClassNames(styles.checkIcon, isSelected ? '' : styles.hideCheckIcon)}
          />
          <p className={styles.optionLabel}>{label}</p>
        </span>
      </components.Option>
    </div>
  );
};

export type ContactListSelectorProps = {
  onChange: (option: ContactListSelectorOption) => void;
  options: ContactListSelectorOption[];
  selectedOption: ContactListSelectorOption | undefined;
  isLoading: boolean;
  onApplySelectedList: () => void;
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>;
  isMenuOpen?: boolean;
};

const ContactListSelector = ({
  onChange,
  options,
  selectedOption,
  isMenuOpen,
  setIsMenuOpen,
  isLoading,
  onApplySelectedList,
}: ContactListSelectorProps) => (
  <Select<ContactListSelectorOption>
    placeholder="-- Contact List --"
    options={options}
    value={
      selectedOption ? { value: selectedOption.value, label: '-- Contact List --' } : undefined
    }
    onChange={onChange}
    className={styles.selectorContainer}
    components={{
      Option: ContactListSelectorOption,
      Menu: (props) => ApplyListButton({ ...props, onClick: onApplySelectedList }),
    }}
    closeMenuOnSelect={false}
    closeMenuOnScroll={false}
    menuIsOpen={isMenuOpen}
    onMenuOpen={() => {
      setIsMenuOpen(true);
    }}
    isLoading={isLoading}
    isSearchable={false}
    isClearable={false}
    id="unified-invitation-contact-list-selector"
    label="Select Contact List"
    hideLabel
    onBlur={() => setIsMenuOpen(false)}
  />
);

export default ContactListSelector;
