import { useQuery } from '@apollo/client';
import { useFlashMessage } from '@shared';
import { GET_PROJECT_SLIDER_DATA, GET_PROJECT_SUBBIE_SUMMARY } from './queries';
import { GET_COMPANY_PROFILE_BY_ACCOUNT_ID } from '@profiles/providers/ProfileProvider/queries';
import {
  CompanyProfileByAccountIdQuery,
  CompanyProfileByAccountIdQueryVariables,
  CompanyProfileQuery,
} from '@profiles/providers/ProfileProvider/queries.generated';
import { EntityId } from '../../../types';
import {
  GetProjectSliderData,
  GetProjectSliderData_projectForSlider as SliderProject,
  GetProjectSliderDataVariables,
} from './types/GetProjectSliderData';
import {
  ProjectSubbieSummary as ProjectSubbieSummaryPayload,
  ProjectSubbieSummary_projectSubbieSummary as ProjectSubbieSummary,
  ProjectSubbieSummaryVariables,
} from '@subbie/modal/types/ProjectSubbieSummary';

export const useProjectApi = (
  projectId?: EntityId,
): {
  projectSliderData: SliderProject | undefined;
  loading: boolean;
  refetch: () => void;
} => {
  const { warning: showWarning } = useFlashMessage();

  const { loading, data, refetch } = useQuery<GetProjectSliderData, GetProjectSliderDataVariables>(
    GET_PROJECT_SLIDER_DATA,
    {
      variables: { projectId: projectId ?? 0 },
      fetchPolicy: 'no-cache',
      skip: !projectId,
    },
  );

  if (!loading && data && data.projectForSlider === null) {
    showWarning({
      title: 'Project Not Found',
      message: `Project ${projectId} could not be found - it may be private, or all builders may have withdrawn`,
    });
  }

  return {
    projectSliderData: data?.projectForSlider ?? undefined,
    loading,
    refetch,
  };
};

export const useProjectSubbieSummary = (
  projectId?: EntityId,
): {
  projectSubbieSummary: ProjectSubbieSummary | undefined;
  loading: boolean;
} => {
  const { data, loading } = useQuery<ProjectSubbieSummaryPayload, ProjectSubbieSummaryVariables>(
    GET_PROJECT_SUBBIE_SUMMARY,
    {
      variables: { projectId: projectId ?? 0 },
      skip: !projectId,
    },
  );

  return {
    projectSubbieSummary: data?.projectSubbieSummary,
    loading,
  };
};

export const useGetSubbieProfileForAccount = (
  id: number,
): {
  data?: CompanyProfileQuery;
} => {
  const { data } = useQuery<
    CompanyProfileByAccountIdQuery,
    CompanyProfileByAccountIdQueryVariables
  >(GET_COMPANY_PROFILE_BY_ACCOUNT_ID, {
    variables: { id: id.toString() },
  });

  return { data };
};
