import { useFlashMessage } from '@shared';
import { useApplyStatusUpdate } from '@subbie/context/WatchlistProvider/hooks';
import { InterestLevel } from '@ascension/_gqltypes/subbie.generated';

type showProjectUnlockedSuccessMessages = {
  projectId: number;
  projectName: string;
  previousWatchlistStatus: InterestLevel | undefined;
  newWatchlistStatus: InterestLevel | undefined;
};

export const useShowProjectUnlockedSuccessMessages = () => {
  const applyStatusUpdate = useApplyStatusUpdate();
  const { success } = useFlashMessage();

  const showSuccessMessages = ({
    projectId,
    projectName,
    newWatchlistStatus,
    previousWatchlistStatus,
  }: showProjectUnlockedSuccessMessages) => {
    success({
      title: 'Success',
      message: `${projectName} has been unlocked`,
    });

    if (newWatchlistStatus && previousWatchlistStatus !== newWatchlistStatus) {
      success({
        title: 'Watchlist Updated',
        message: 'Interest level changed to Interested',
      });
      applyStatusUpdate(projectId, newWatchlistStatus);
    }
  };

  return { showSuccessMessages };
};
