import { Country } from '@ascension/enums/Country';

// TODO: use fragment again when we can use real E1 Address type
type AddressFragment = {
  id: string;
  address1: string;
  address2: string | null;
  suburb: string | null;
  city: string | null;
  province: string | null;
  postcode: string | null;
  state: null | {
    id: string;
    shortName: string;
  };
  country: null | {
    id: string;
    shortName: string;
  };
};

const isNotNull = (value: unknown): value is string => value !== null;

const australianAddressFormat = (address: AddressFragment) => {
  const { address1, address2, suburb, state, postcode } = address;
  const area = `${suburb ?? ''} ${state?.shortName ?? ''} ${postcode ?? ''}`.replace(/\s+/, ' ');

  return [address1, address2, area];
};

/**
 * The address format has been kept the same for UK and IE for now as:
 * - the general format is the same
 * - there are many inconsistencies with how province is stored
 */
const ukAndIeAddressFormat = (address: AddressFragment) => {
  const { address1, address2, city, province, postcode } = address;

  return [address1, address2, city, province, postcode];
};

export const transformOffice = (
  office: { name: string; address: AddressFragment | null } | null,
): string[] => {
  if (!office?.address) return [''];

  const { address } = office;
  const countryId = address?.country?.id ?? Country.COUNTRY_AUSTRALIA;

  const formattedAddress =
    countryId !== Country.COUNTRY_AUSTRALIA.toString()
      ? ukAndIeAddressFormat(address)
      : australianAddressFormat(address);

  return [office.name, ...formattedAddress].filter<string>(isNotNull);
};
