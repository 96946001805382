import { useQuery } from '@apollo/client';
import { GET_SHAREPOINT_SITES_AND_DRIVES } from '../queries';
import { GetSharePointSitesAndDrives } from '@builder/features/DocSyncIntegration/Sharepoint/SliderSteps/types/GetSharePointSitesAndDrives';

export const useSitesAndDrivesQuery = () => {
  const { data, loading } = useQuery<GetSharePointSitesAndDrives>(GET_SHAREPOINT_SITES_AND_DRIVES);

  if (data?.getSharePointSitesAndDrives.__typename === 'SharePointSites') {
    return { sitesAndDrives: data.getSharePointSitesAndDrives.sites, loading, errors: undefined };
  }
  if (data?.getSharePointSitesAndDrives.__typename === 'Errors') {
    return { sitesAndDrives: undefined, loading, errors: data.getSharePointSitesAndDrives.errors };
  }

  return { sitesAndDrives: undefined, loading, errors: undefined };
};
