import { Dispatch, SetStateAction } from 'react';
import { Checkbox, CheckboxStatus, joinClassNames } from '@estimateone/frontend-components';
import { FileSelectorListItem } from '../../DocumentIntegrationConnectSlider/FileSelection/FileSelectorListItem';
import { SelectedDocuments } from '../ProcoreConnectSlider';
import LoadingSpinner from '@shared/LoadingSpinner';
import { stageProcoreMapForModification as StageProcoreMapForModification } from '../types/stageProcoreMapForModification';
import { stageProcoreMapSetup as StageProcoreMapSetup } from '../types/stageProcoreMapSetup';
import styles from './styles.scss';

interface ProcoreItemsListProps {
  loading: boolean;
  stageProcoreMapSetup:
    | StageProcoreMapSetup['stageProcoreMapSetup']
    | StageProcoreMapForModification['stageProcoreMapForModification']
    | undefined;
  selectedDocuments: SelectedDocuments;
  handleDocumentSelection: Dispatch<SetStateAction<SelectedDocuments>>;
}

export enum ProcoreDocumentAvailability {
  AVAILABLE = 'AVAILABLE',
  LOCKED = 'LOCKED',
}

export const ProcoreItemsList = ({
  loading,
  stageProcoreMapSetup,
  selectedDocuments,
  handleDocumentSelection,
}: ProcoreItemsListProps) => {
  if (loading || !stageProcoreMapSetup) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  const { projectTools, stageProcoreMapOptions } = stageProcoreMapSetup;
  const getDocumentsStatus = () => {
    const {
      documents: { files, folders },
    } = selectedDocuments;
    const {
      documents: { files: totalFiles, folders: totalFolders },
    } = stageProcoreMapOptions;

    const totalAvailableFiles = totalFiles.filter(
      (file) => file.availability === ProcoreDocumentAvailability.AVAILABLE,
    );
    const totalAvailableFolders = totalFolders.filter(
      (folder) => folder.availability === ProcoreDocumentAvailability.AVAILABLE,
    );

    const selectedCount = files.length + folders.length;
    const totalCount = totalAvailableFiles.length + totalAvailableFolders.length;

    if (selectedCount === 0) {
      return CheckboxStatus.Unchecked;
    }

    return selectedCount === totalCount ? CheckboxStatus.Checked : CheckboxStatus.Indeterminate;
  };

  const setDocumentStatus = () => {
    const currentStatus = getDocumentsStatus();

    const status: Partial<SelectedDocuments> = {
      documents: {
        files:
          currentStatus !== CheckboxStatus.Checked
            ? stageProcoreMapOptions.documents.files
                .filter((file) => file.availability === ProcoreDocumentAvailability.AVAILABLE)
                .map((file) => file.id)
            : [],
        folders:
          currentStatus !== CheckboxStatus.Checked
            ? stageProcoreMapOptions.documents.folders
                .filter((folder) => folder.availability === ProcoreDocumentAvailability.AVAILABLE)
                .map((folder) => folder.id)
            : [],
      },
    };

    handleDocumentSelection((prev) => ({ ...prev, ...status }));
  };

  const updateDrawingsSelection = () => {
    handleDocumentSelection((prev) => ({ ...prev, includeDrawings: !prev.includeDrawings }));
  };

  const updateSpecificationsSelection = () => {
    handleDocumentSelection((prev) => ({
      ...prev,
      includeSpecifications: !prev.includeSpecifications,
    }));
  };

  const getFolderCheckedStatusById = (id: string): CheckboxStatus => {
    if (selectedDocuments.documents.folders.includes(id)) {
      return CheckboxStatus.Checked;
    }
    return CheckboxStatus.Unchecked;
  };

  const getFileCheckedStatusById = (id: string): CheckboxStatus => {
    if (selectedDocuments.documents.files.includes(id)) {
      return CheckboxStatus.Checked;
    }
    return CheckboxStatus.Unchecked;
  };

  const updateFolderCheckSelection = (id: string) => {
    if (selectedDocuments.documents.folders.includes(id)) {
      const updatedSelectedFolders = selectedDocuments.documents.folders.filter(
        (folderId) => folderId !== id,
      );
      handleDocumentSelection((prev) => ({
        ...prev,
        documents: { ...prev.documents, folders: updatedSelectedFolders },
      }));
    } else {
      handleDocumentSelection((prev) => ({
        ...prev,
        documents: { ...prev.documents, folders: [...selectedDocuments.documents.folders, id] },
      }));
    }
  };

  const updateFileCheckSelection = (id: string) => {
    if (selectedDocuments.documents.files.includes(id)) {
      const updatedSelectedFiles = selectedDocuments.documents.files.filter(
        (fileId) => fileId !== id,
      );
      handleDocumentSelection((prev) => ({
        ...prev,
        documents: { ...prev.documents, files: updatedSelectedFiles },
      }));
    } else {
      handleDocumentSelection((prev) => ({
        ...prev,
        documents: { ...prev.documents, files: [...selectedDocuments.documents.files, id] },
      }));
    }
  };

  return (
    <div className={styles.documentsContainer}>
      <Checkbox
        id="procore-drawings"
        className={styles.procoreFolder}
        label={
          <span
            className={joinClassNames(
              styles.labelHeavy,
              !projectTools.drawings.isActive ? styles.documentLabelLocked : '',
            )}
          >
            Drawings <span className={styles.labelLight}>(Project Management)</span>
          </span>
        }
        statusCurrent={
          projectTools.drawings.isActive && selectedDocuments.includeDrawings
            ? CheckboxStatus.Checked
            : CheckboxStatus.Unchecked
        }
        disabled={!projectTools.drawings.isActive}
        onClick={updateDrawingsSelection}
      />
      <Checkbox
        id="procore-specifications"
        className={styles.procoreFolder}
        label={
          <span
            className={joinClassNames(
              styles.labelHeavy,
              !projectTools.specifications.isActive ? styles.documentLabelLocked : '',
            )}
          >
            Specifications <span className={styles.labelLight}>(Project Management)</span>
          </span>
        }
        disabled={!projectTools.specifications.isActive}
        statusCurrent={
          projectTools.specifications.isActive && selectedDocuments.includeSpecifications
            ? CheckboxStatus.Checked
            : CheckboxStatus.Unchecked
        }
        onClick={updateSpecificationsSelection}
      />
      <Checkbox
        id="procore-documents"
        label={
          <span
            className={joinClassNames(
              styles.labelHeavy,
              !projectTools.documents.isActive ? styles.documentLabelLocked : '',
            )}
          >
            Documents <span className={styles.labelLight}>(Core Tools)</span>
          </span>
        }
        statusInitial={CheckboxStatus.Unchecked}
        statusCurrent={getDocumentsStatus()}
        onClick={() => setDocumentStatus()}
        disabled={!projectTools.documents.isActive}
      />
      {projectTools.documents.isActive && (
        <ul className={styles.documentItemList}>
          {stageProcoreMapOptions.documents.folders.map((folder) => (
            <FileSelectorListItem
              key={folder.id}
              id={folder.id}
              name={folder.name}
              type="folder"
              isLocked={folder.availability === ProcoreDocumentAvailability.LOCKED}
              status={getFolderCheckedStatusById(folder.id)}
              handleUpdate={updateFolderCheckSelection}
            />
          ))}
          {stageProcoreMapOptions.documents.files.map((file) => (
            <FileSelectorListItem
              key={file.id}
              id={file.id}
              name={file.name}
              type="file"
              isLocked={file.availability === ProcoreDocumentAvailability.LOCKED}
              status={getFileCheckedStatusById(file.id)}
              handleUpdate={updateFileCheckSelection}
            />
          ))}
        </ul>
      )}
    </div>
  );
};
