import { useContext, useState } from 'react';
import {
  Button,
  ButtonVariant,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  DrawerToolbar,
} from '@estimateone/frontend-components';
import { SubbieNetworkFilterForm } from './SubbieNetworkFilterForm';
import { pluralise } from '@shared/Util/pluralise';
import { NetworkSearchContext } from '@builder/features/SubbieNetworkInvitations/context/NetworkSearchProvider';
import { SubbieNetworkFilterFormId } from '@builder/features/SubbieNetworkInvitations/types';
import { ValueLabelOption } from '@estimateone/frontend-components/src/components/Form/Select/types';
import styles from './styles.module.scss';

type SubbieNetworkFilterDrawerProps = {
  stageTradeOptions: ValueLabelOption<number>[] | null;
};

const SubbieNetworkFilterDrawer = ({ stageTradeOptions }: SubbieNetworkFilterDrawerProps) => {
  const [selectedFiltersCount, setSelectedFiltersCount] = useState<number>(0);

  const { isFilterDrawerOpen, onCloseFilterDrawer } = useContext(NetworkSearchContext);

  return (
    <Drawer isOpen={isFilterDrawerOpen} onClose={onCloseFilterDrawer}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerToolbar className={styles.filterToolbar} />
        <DrawerBody className={styles.filterBody}>
          <h1 className={styles.filterHeading}>All Filters</h1>
          <SubbieNetworkFilterForm
            setSelectedFiltersCount={setSelectedFiltersCount}
            stageTradeOptions={stageTradeOptions}
          />
        </DrawerBody>
        <DrawerFooter className={styles.footerButtonContainer}>
          <Button type="reset" variant={ButtonVariant.Grey} form={SubbieNetworkFilterFormId}>
            Reset Filters
          </Button>
          <Button type="submit" variant={ButtonVariant.Primary} form={SubbieNetworkFilterFormId}>
            Confirm {selectedFiltersCount} {pluralise('Filter', selectedFiltersCount)}
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default SubbieNetworkFilterDrawer;
