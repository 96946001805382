import { useMemo, useState } from 'react';
import { Package } from '@builder/features/ProcurementLettingSchedule/types';

export type SortOrderObj = {
  id: number;
  order: number;
};

function useInitialPackageSort(packages: Package[]) {
  // Store the initial sort order of the packages
  const [sortOrderArray, setSortOrderArray] = useState<SortOrderObj[]>([]);

  // Sort the packages based on the initial sort order OR/then by letByDate
  const initPackageSortFn = (a: Package, b: Package) => {
    const aSortObj = sortOrderArray.find((sortObj) => sortObj.id === a.id);
    const bSortObj = sortOrderArray.find((sortObj) => sortObj.id === b.id);
    if (aSortObj && bSortObj) {
      return aSortObj.order - bSortObj.order;
    }

    if (a.lettingScheduleDetails?.letByDate && b.lettingScheduleDetails?.letByDate) {
      const bDate = new Date(b.lettingScheduleDetails.letByDate);
      const aDate = new Date(a.lettingScheduleDetails.letByDate);
      return bDate < aDate ? 1 : -1;
    }

    if (!a.lettingScheduleDetails?.letByDate) {
      return 1;
    }

    return -1;
  };

  const createSortOrder = (sortedArray: Package[]) => {
    const sortOrder = sortedArray.map((currPackage, i) => ({
      id: currPackage.id,
      order: i,
    }));
    return sortOrder;
  };

  // Recurse the sorted packages and set the sortOrder
  const sortedPackages = useMemo(() => {
    // Create a new array of sorted packages
    const sortedArray = packages.sort(initPackageSortFn);
    // Create a new array of sort order objects
    setSortOrderArray(createSortOrder(sortedArray));
    return sortedArray;
  }, [packages]);

  return sortedPackages;
}

export { useInitialPackageSort };
