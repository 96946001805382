import $ from 'jquery';
import Routing from 'routing';
import moment from 'moment-timezone';
import _ from 'underscore';
import E1Request from '../classes/E1Request';
import Form from '../classes/Form';

function UserCorrespondenceFileList(userCorrespondenceId, $target, init_func, fetchUrl) {
  this.correspondenceId = userCorrespondenceId;
  this.$target = $target;
  this.$container = $target.closest('.correspondence-file-container');
  this.$emptyPlaceholder = this.$target
    .closest('.loading-container')
    .find('.empty-text-placeholder');
  this.$downloadAllBtn = this.$container.find('.btn-download-all');
  this.data = [];
  this.editable = $target.attr('data-editable');
  this.tableShowing = true;
  this.init_func = init_func;
  this.fetchUrl = fetchUrl;
  this.$controlPanel = this.$container.find('.table-control-panel-container');
  this.$folderUploadButton = $('a#path-file-uploader');

  const self = this;

  self.fetch(self.init_func);
}

UserCorrespondenceFileList.prototype.drawTable = function () {
  const self = this;
  self.toggleTableDisplay();

  if (self.table != null) {
    self.table.rows().remove();
    self.table.rows.add(self.data).draw();
  }

  self.updateSelectedTotals();
};

UserCorrespondenceFileList.prototype.fetch = function (callback) {
  const self = this;
  const request = new E1Request(self.fetchUrl, 'GET');
  request.extraCallback = (response) => {
    const dataArr = [];
    if (typeof response.data !== 'undefined') {
      $.each(response.data, (index, val) => {
        const data = val;
        data.DT_RowId = `dt_data_${data.id}`;
        dataArr.push(data);
      });
    }

    self.data = dataArr;

    if (typeof callback !== 'undefined') {
      callback(self);
    }
  };
  request.submit();
};

UserCorrespondenceFileList.prototype.updateTable = function (fetch, cb) {
  const self = this;
  if (fetch) {
    self.fetch($.proxy(self.drawTable, self));
  } else {
    self.drawTable();
  }
  if (typeof cb !== 'undefined') {
    cb();
  }
};

UserCorrespondenceFileList.prototype.getData = function () {
  return this.data;
};

UserCorrespondenceFileList.prototype.toggleTableDisplay = function (immediate) {
  const self = this;

  if (immediate == null) {
    immediate = true;
  }

  if (self.data.length == 0) {
    if (self.$emptyPlaceholder.length > 0 && self.tableShowing == true) {
      if (immediate) {
        self.$target.hide();
        self.$downloadAllBtn.hide();
        self.$emptyPlaceholder.show();
      } else {
        self.$target.fadeOut(() => {
          self.$emptyPlaceholder.fadeIn();
        });
      }
      self.tableShowing = false;
    } else if (self.$emptyPlaceholder.length == 0 && self.tableShowing == true) {
      self.$target.show();
      self.$downloadAllBtn.show();

      self.tableShowing = true;
    }
  } else if (self.$emptyPlaceholder.length > 0 && self.tableShowing == false) {
    if (immediate) {
      self.$target.show();
      self.$downloadAllBtn.show();
      self.$emptyPlaceholder.hide();
    } else {
      self.$emptyPlaceholder.fadeOut(() => {
        self.$target.fadeIn();
      });
    }
    self.tableShowing = true;
  }
};

UserCorrespondenceFileList.prototype.init = function (self) {
  const $body = $('body');

  if (self.$controlPanel.length > 0) {
    self.$controlPanel.prependTo('.bottom-bar');
    self.$controlPanel.removeClass('hide');
  }

  self.$target.on('change', '.select-checkbox', function () {
    const id = parseInt($(this).attr('data-id'));
    const checked = $(this).is(':checked');
    const $row = $(this).closest('tr');
    const row = $row.get(0);
    const rowData = self.table.row(row).data();

    rowData.selected = checked;
    self.data = self.table.rows().data();
    self.$target.trigger('data-selected-updated');
    self.updateSelectedTotals();
  });

  self.$target.on('change', '.select-all-rows', function () {
    const checked = $(this).is(':checked');

    $.each(self.data, (i, row) => {
      row.selected = checked;
    });

    self.$target.trigger('data-selected-updated');
    self.updateTable();
    self.updateSelectedTotals();
  });

  self.$controlPanel.on('click', '.download-multiple-file', () => {
    const docIds = self.getSelectedIds();
    window.location = Routing.generate('app_usercorrespondencefile_download', {
      id: self.correspondenceId,
      fileId: docIds,
    });
  });

  if (self.editable) {
    self.$target.on('click', '.remove-file', function () {
      const fileId = $(this).attr('data-upload-id');
      const route = Routing.generate('app_usercorrespondencefile_delete_modal', {
        id: self.correspondenceId,
        fileId,
      });
      const req = new E1Request(route);
      req.submit();
    });
    self.$controlPanel.on('click', '.remove-multiple-file', () => {
      const docIds = self.getSelectedIds();
      const route = Routing.generate('app_usercorrespondencefile_delete_modal', {
        id: self.correspondenceId,
        fileId: docIds,
      });
      const request = new E1Request(route, 'GET');
      request.show_loading_modal = true;
      request.submit();
    });
    $body.on('submit', 'form.rem-file-form', function (e) {
      e.preventDefault();
      const $form = $(this);
      const magicForm = new Form($form);

      magicForm.submit();
      magicForm.extraCallback = function () {
        self.updateTable(true);
      };

      return false;
    });
  }

  self.updateSelectedTotals();
};

UserCorrespondenceFileList.prototype.getSelectedIds = function () {
  const self = this;
  const selected = _.filter(self.data, (obj) => obj.selected == true);
  const docIds = _.pluck(selected, 'id');
  return docIds;
};

UserCorrespondenceFileList.prototype.updateSelectedTotals = function () {
  const self = this;
  const selected = _.filter(self.data, (obj) => obj.selected == true);

  const selectedCount = selected.length;
  const totalCount = self.data.length;

  self.$target.find('.select-all-rows').prop('checked', selectedCount >= totalCount);
  $('.select_count', self.$container).each(function () {
    const text = selectedCount >= totalCount ? $(this).attr('data-all-text') : selectedCount;
    $(this).text(text);
  });

  $('.select_count', self.$controlPanel).each(function () {
    if (selectedCount == 0) {
      self.$controlPanel.addClass('hide');
    }

    const text = selectedCount >= totalCount ? $(this).attr('data-all-text') : selectedCount;
    $(this).text(text);

    if (selectedCount > 0) {
      self.$controlPanel.removeClass('hide');
    }
  });
};

UserCorrespondenceFileList.prototype.canUploadToFolder = function () {
  const self = this;
  return self.$folderUploadButton.length > 0 && self.$folderUploadButton.hasClass('activated');
};

export default function initUserCorrespondenceDocTable(target) {
  const $target = $(target);
  const corrId = $target.attr('data-correspondence');
  const docTable = new UserCorrespondenceFileList(
    corrId,
    $target,
    (list) => {
      list.table = list.$target.DataTable({
        paging: false,
        data: list.data,
        info: false,
        order: [[2, 'desc']],
        columns: [
          {
            data: 'selected',
            orderable: false,
            defaultContent: '',
            render(selected, row, object, info) {
              const $checkbox = $("<input type='checkbox'>")
                .addClass('select-checkbox')
                .attr('data-id', object.id);

              if (object.selected) {
                $checkbox.attr('checked', 'checked');
              }

              list.data[info.row] = object;
              const $ctn = $('<div>').append($checkbox);
              return $ctn.prop('outerHTML');
            },
          },
          {
            data: null,
            render(data, row, object) {
              const $container = $('<div>');
              const $link = $('<a>').text(object.file_name);
              $link.attr('target', '_blank');
              $link.attr(
                'href',
                Routing.generate('app_usercorrespondencefile_stream', {
                  id: corrId,
                  fileId: object.id,
                }),
              );
              $link.attr('data-upload-id', object.id);
              $link.addClass('link upload-link');
              $container.append($link);

              return $container.prop('outerHTML');
            },
          },
          {
            data: null,
            render(data, row, object) {
              const creator = object.created_by_user;
              const account = object.created_by_account;

              const $companySpan = $('<span />').addClass('company-name').text(` (${account})`);
              const name = $('<p />').append(_.escape(creator.trim())).append($companySpan);

              return name.html();
            },
          },
          {
            data: 'created_at',
            render(data) {
              const $span = $('<span />');
              $span.addClass('timestamp');

              if (data != null) {
                const date = moment.unix(data);
                $span.text(date.format('DD MMM YYYY'));
              } else {
                $span.text('-');
              }

              return $span.prop('outerHTML');
            },
          },
          {
            data: 'file_size',
            class: 'filesize',
            render(data) {
              return data;
            },
          },
          {
            data: null,
            orderable: false,
            render(data, row, col) {
              const $container = $('<div>').addClass('btn-container-inline');

              const $downloadLink = $('<a>')
                .addClass('btn btn-icon btn-default')
                .attr(
                  'href',
                  Routing.generate('app_usercorrespondencefile_download', {
                    id: corrId,
                    fileId: col.id,
                  }),
                )
                .attr('data-upload-id', col.id)
                .append($('<i>').addClass('icon icon-download download-file'));

              $container.append($downloadLink);

              if ($target.attr('data-editable')) {
                const $removeBtn = $('<button>')
                  .addClass('btn btn-icon btn-default remove-file')
                  .attr('data-upload-id', col.id);

                const $iconRemove = $('<i>').addClass('icon icon-bin');

                $removeBtn.append($iconRemove);
                $container.append($removeBtn);
              }

              return $container.prop('outerHTML');
            },
          },
        ],
        dom: 'Rrtp',
      });
      list.toggleTableDisplay(true);
      list.$target.closest('.loading-container').addClass('has-loaded');
      list.init(list);
    },
    Routing.generate('app_usercorrespondencefile_fetch', { id: corrId }),
  );
  $target.trigger('data-updated');
  return docTable;
}
