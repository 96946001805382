import { AlertIcon, ContractValueIcon } from '@estimateone/frontend-components';
import { ProfileBadgeCard } from '../../ProfileBadgeCard';
import { ProfileBadgeIconWrapper } from '../../ProfileBadgeIconWrapper';
import styles from './ContractSizeBadge.module.scss';
import { contractSizeLabelMap } from './utils';
import { ContractSizeEnum } from '@ascension/_gqltypes/profiles.generated';

type ContractSizeBadgeProps = {
  contractSizeMin: ContractSizeEnum | null;
  contractSizeMax: ContractSizeEnum | null;
};

export const ContractSizeIcon = () => (
  <ProfileBadgeIconWrapper
    status="info"
    content={<ContractValueIcon viewBox="0 0 20 24" className={styles.contractIcon} />}
  />
);

const MaxContractOption = ContractSizeEnum.SIZE_50M_PLUS;

type ContractRangeInput = {
  contractSizeMin: ContractSizeEnum | null;
  contractSizeMax: ContractSizeEnum | null;
};

export const formatContractRange = (input: ContractRangeInput): string | undefined => {
  const contractSizeMin = input.contractSizeMin
    ? contractSizeLabelMap(input.contractSizeMin)
    : null;
  const contractSizeMax = input.contractSizeMax
    ? contractSizeLabelMap(input.contractSizeMax)
    : null;

  if (contractSizeMin && contractSizeMax) {
    return `${contractSizeMin} - ${contractSizeMax}`;
  }

  if (input.contractSizeMax === MaxContractOption) {
    return `All`;
  }

  if (contractSizeMin) {
    return `Above ${contractSizeMin}`;
  }

  if (contractSizeMax) {
    return `Below ${contractSizeMax}`;
  }

  return undefined;
};

export const formatContractSizeSubHeading = ({
  contractSizeMin,
  contractSizeMax,
}: ContractRangeInput): string => {
  if (contractSizeMax === MaxContractOption && !contractSizeMin) {
    return 'Contract sizes';
  }

  return 'Contract size';
};

export const ContractSizeBadge = ({ contractSizeMax, contractSizeMin }: ContractSizeBadgeProps) => {
  const contractRange = formatContractRange({
    contractSizeMax,
    contractSizeMin,
  });

  const contractSizeSubHeading = formatContractSizeSubHeading({
    contractSizeMax,
    contractSizeMin,
  });

  return (
    <ProfileBadgeCard
      heading={contractRange}
      subHeading={contractSizeSubHeading}
      icon={<ContractSizeIcon />}
    />
  );
};

export const ContractSizeBadgeViewError = () => (
  <div className={styles.errorIconContainer}>
    <AlertIcon title="Contract size error icon" />
  </div>
);
