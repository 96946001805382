import { Tooltip } from '@estimateone/frontend-components';
import { format } from 'date-fns';
import { Indicator, IndicatorStatus } from '../../QuoteCoverageStatus/Indicator';
import { calculatePackageQuoteCoverage } from '../../QuoteCoverageStatus/utils/calculateQuoteCoverageStatus';
import { GetPackageWithRfqs_package_activeRfqs as ActiveRfq } from '../types/GetPackageWithRfqs';
import styles from './styles.scss';

export type PackageDashboardStatisticsProps = {
  activeRfqs: ActiveRfq[];
  rfqsSentByDate: Date | undefined;
  quotesDueAt: Date | undefined;
  letByDate: Date | undefined;
  startOnSiteDate: Date | undefined;
};

export const PackageDashboardStatistics = ({
  activeRfqs,
  rfqsSentByDate,
  quotesDueAt,
  letByDate,
  startOnSiteDate,
}: PackageDashboardStatisticsProps) => {
  const coverage = calculatePackageQuoteCoverage({
    activeRfqs,
  }) ?? { quoted: 0, quoting: 0, notQuoting: 0, noResponse: 0, numberOfQuotes: 0 };

  const rfqsSentByDateFormatted = rfqsSentByDate ? format(rfqsSentByDate, 'd MMM yy') : '-';
  const quotesDueAtFormatted = quotesDueAt ? format(quotesDueAt, 'd MMM yy') : '-';
  const letByDateFormatted = letByDate ? format(letByDate, 'd MMM yy') : '-';
  const startOnSiteDateFormatted = startOnSiteDate ? format(startOnSiteDate, 'd MMM yy') : '-';

  return (
    <div className={styles.packageDatesAndQuotes}>
      <div className={styles.packageDates}>
        <div>
          <h3 className={styles.statTitle}>Send invites</h3>
          <p className={styles.statText}>{rfqsSentByDateFormatted}</p>
        </div>
        <div>
          <h3 className={styles.statTitle}>Quotes due</h3>
          <p className={styles.statText}>{quotesDueAtFormatted}</p>
        </div>

        <div>
          <h3 className={styles.statTitle}>Let by</h3>
          <p className={styles.statText}>{letByDateFormatted}</p>
        </div>

        <div>
          <h3 className={styles.statTitle}>Start on site</h3>
          <p className={styles.statText}>{startOnSiteDateFormatted}</p>
        </div>
      </div>
      <div className={styles.packageQuotes} data-tip data-for="dashboard-statistics-tooltip">
        <div>
          <h3 className={styles.statTitle}>Invites</h3>
          <p className={styles.statText}>{coverage.numberOfQuotes}</p>
        </div>
        <div>
          <h3 className={styles.statTitle}>Quoted</h3>
          <p className={styles.statText}>{coverage.quoted}</p>
          <span className={styles.coverageIndicator}>
            <Indicator status={IndicatorStatus.Quoted} />
          </span>
        </div>
        <div>
          <h3 className={styles.statTitle}>Quoting</h3>
          <p className={styles.statText}>{coverage.quoting}</p>
          <span className={styles.coverageIndicator}>
            <Indicator status={IndicatorStatus.Quoting} />
          </span>
        </div>
        <div>
          <h3 className={styles.statTitle}>Not quoting</h3>
          <p className={styles.statText}>{coverage.notQuoting}</p>
          <span className={styles.coverageIndicator}>
            <Indicator status={IndicatorStatus.NotQuoting} />
          </span>
        </div>
        <div>
          <h3 className={styles.statTitle}>No response</h3>
          <p className={styles.statText}>{coverage.noResponse}</p>
          <span className={styles.coverageIndicator}>
            <Indicator status={IndicatorStatus.NoResponse} />
          </span>
        </div>
      </div>
      <Tooltip
        text="Quote Coverage counts companies, not individual contacts"
        tooltipId="dashboard-statistics-tooltip"
        place="bottom"
      />
    </div>
  );
};
