import { MutableRefObject, useState } from 'react';
import {
  CellProps,
  DatePickerFormat,
  joinClassNames,
  TableInstance,
  Tooltip,
} from '@estimateone/frontend-components';
import { InlineEditableDate } from '@builder/common/InlineEditableDate';
import { Trigger } from '../TimingCalculator/autoCalculateTimings';
import { getMilestoneInfoForLetByDate } from '../TimingMilestoneState';
import {
  CalculateAndUpdateLettingTimingsInput,
  InputIdentifiers,
  TimingErrorMessages,
  getTimings,
  isDateSameOrEarlierThanFutureDates,
  isDateSameOrLaterThanPastDates,
  isLetByClearable,
} from '../utils/lettingTimingHelper';
import { Package } from '@builder/features/ProcurementLettingSchedule/types';
import styles from '../styles.scss';

type CellComponentProps = {
  value: Date;
  original: Package;
  calculateAndUpdateLettingTimings: ({
    trigger,
    ...timings
  }: CalculateAndUpdateLettingTimingsInput) => void;
  tableRef: MutableRefObject<TableInstance<Package> | undefined>;
  enableDefaultTimeframes: boolean;
};

const CellComponent = ({
  value,
  original,
  calculateAndUpdateLettingTimings,
  tableRef,
  enableDefaultTimeframes,
}: CellComponentProps) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const letByDate = value ? new Date(value) : null;

  const { classNames, hasMilestoneText, milestoneText, extraMilestoneText } =
    getMilestoneInfoForLetByDate(letByDate, original.lettingScheduleDetails?.isAwarded);

  const handleOnDateChange = (currentValue: Date | null): void => {
    if (currentValue === null && !enableDefaultTimeframes && !isLetByClearable(original)) {
      setErrorMessage(TimingErrorMessages.NOT_CLEARABLE);
    } else {
      const timings = getTimings(
        currentValue ?? undefined,
        tableRef.current,
        original,
        Trigger.LET_BY_DATE_CHANGE,
        'letByDate',
      );

      const isEarlierThanPastDate =
        !timings?.trigger || !isDateSameOrLaterThanPastDates(timings.trigger, timings);
      const isLaterThanFutureDate =
        !timings?.trigger || !isDateSameOrEarlierThanFutureDates(timings.trigger, timings);

      if (!enableDefaultTimeframes && isEarlierThanPastDate) {
        setErrorMessage(TimingErrorMessages.DATE_TOO_EARLY);
      } else if (isLaterThanFutureDate) {
        setErrorMessage(TimingErrorMessages.DATE_TOO_LATE);
      } else {
        calculateAndUpdateLettingTimings(timings);
      }
    }
  };

  return (
    <div className={joinClassNames(styles.cellAlign, ...classNames)}>
      <InlineEditableDate
        id={`${InputIdentifiers.LET_BY_DATE}${original.id}`}
        className={errorMessage ? styles.dateError : undefined}
        label="Let By date"
        value={letByDate ?? undefined}
        onFocus={() => {
          setErrorMessage(null);
        }}
        onDateChange={handleOnDateChange}
        dateFormat={DatePickerFormat.DateOnlyShortMonth}
      />
      {errorMessage ? (
        <p className={styles.dateErrorMessage}>{errorMessage}</p>
      ) : (
        hasMilestoneText && (
          <Tooltip
            className={styles.tooltipSpacing}
            text={extraMilestoneText}
            tooltipId={`let-by-milestone-tooltip_${original.id}`}
            place="bottom"
          >
            <span className={styles.timeRemaining}>{milestoneText}</span>
          </Tooltip>
        )
      )}
    </div>
  );
};

export const LetByCell =
  (
    calculateAndUpdateLettingTimings: ({
      trigger,
      ...timings
    }: CalculateAndUpdateLettingTimingsInput) => void,
    tableRef: MutableRefObject<TableInstance<Package> | undefined>,
    enableDefaultTimeframes: boolean,
  ) =>
  ({ value, row: { original } }: CellProps<Package, Date>) =>
    CellComponent({
      value,
      original,
      calculateAndUpdateLettingTimings,
      tableRef,
      enableDefaultTimeframes,
    });
