import { useField } from 'react-final-form';
import AlreadyQuotedAlert from './AlreadyQuotedAlert';
import { FileManagerParams } from '@shared/FileUploader/types';
import { QuoteSectionWithExistingQuoteFile } from '@shared/Quotes/Form/QuoteFormQuoteSection';
import { CompleteQuoteFormSpecificFieldName } from '../enums';
import { UploadParentType } from '@ascension/enums';
import { ContactValue, PackageValue } from '../types';
import { DownloadableQuoteFile } from '@api/fragments/Quotes/types';

type CompleteQuoteFormQuoteSectionProps = Omit<FileManagerParams, 'parentType'> & {
  quoteFile: DownloadableQuoteFile;
};

const CompleteQuoteFormQuoteSection = ({
  fileManagerLocale,
  parentId,
  quoteFile,
}: CompleteQuoteFormQuoteSectionProps) => {
  const {
    input: {
      value: { name: packageName },
    },
  } = useField<PackageValue>(CompleteQuoteFormSpecificFieldName.Package);

  const {
    input: {
      value: { mostRecentQuoteId },
    },
  } = useField<ContactValue>(CompleteQuoteFormSpecificFieldName.Contact);

  return mostRecentQuoteId ? (
    <AlreadyQuotedAlert quoteId={mostRecentQuoteId} packageName={packageName} />
  ) : (
    <QuoteSectionWithExistingQuoteFile
      fileManagerLocale={fileManagerLocale}
      quoteFile={quoteFile}
      extraFilesParentType={UploadParentType.STAGE_QUOTE_EXTRA_FILE}
      extraFilesParentId={parentId}
    />
  );
};

export default CompleteQuoteFormQuoteSection;
