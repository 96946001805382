// eslint-disable-next-line import/no-unresolved
import { eWaySecureFieldConfig } from '../../../../js/custom';

export const getEwayFields = (
  publicApiKey: string,
): { [fieldName: string]: eWaySecureFieldConfig } => ({
  'Cardholder name': {
    publicApiKey,
    fieldDivId: 'eway_secure_fields_name',
    fieldType: 'name',
  },
  'Card number': {
    publicApiKey,
    fieldDivId: 'eway_secure_fields_card',
    fieldType: 'card',
  },
  Expiry: {
    publicApiKey,
    fieldDivId: 'eway_secure_fields_expiry',
    fieldType: 'expirytext',
  },
  CVN: {
    publicApiKey,
    fieldDivId: 'eway_secure_fields_cvn',
    fieldType: 'cvn',
  },
});
