import { useState } from 'react';
import { Slider, Button, ButtonVariant, ButtonSize } from '@estimateone/frontend-components';
import { LettingScheduleNotesForm } from '../LettingScheduleNotesForm';
import { SuccessType } from '../LettingScheduleNotesForm/LettingScheduleNotesForm';
import { LettingScheduleNotesHistory } from '../LettingScheduleNotesHistory';
import LoadingSpinner from '@shared/LoadingSpinner';
import { useGetNotesForLettingSchedule } from './hooks/useGetNotesForLettingSchedule';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

export enum LettingScheduleNotesSlideoutSource {
  LETTING_SCHEDULE,
  PACKAGE_DASHBOARD,
}

export type LettingScheduleNotesSlideoutProps = {
  isOpen: boolean;
  source: LettingScheduleNotesSlideoutSource;
  onCloseHandler: () => void;
  isCritical: boolean;
  packageId: EntityId;
  packageTitle: string;
  accountName?: string;
};

export const LettingScheduleNotesSlideout = ({
  isOpen,
  source,
  onCloseHandler,
  isCritical,
  packageId,
  packageTitle,
  accountName,
}: LettingScheduleNotesSlideoutProps) => {
  const { notes, loading } = useGetNotesForLettingSchedule(packageId);
  const [hasAddedNewNote, setHasAddedNewNote] = useState(false);

  const resetAndClose = () => {
    setHasAddedNewNote(false);
    onCloseHandler();
  };

  return (
    <Slider isOpen={isOpen} onRequestClose={resetAndClose}>
      <Slider.Header>
        <h2 className={styles.notesSlideoutHeader}>{`${packageTitle} - Internal Notes`}</h2>
      </Slider.Header>
      <Slider.Body className={styles.addNoteContainer}>
        <p className={styles.addNoteIntroText}>
          {`Internal notes can only be seen by people at ${accountName}, not subcontractors`}
        </p>
        <LettingScheduleNotesForm
          packageId={packageId}
          onFormSuccess={(e) => {
            if (e.type === SuccessType.FLAG_ADDED_WITHOUT_NOTE) {
              resetAndClose();
            } else {
              setHasAddedNewNote(true);
            }
          }}
          initialIsCritical={isCritical}
          source={source}
        />
        <h2 className={styles.notesHistoryHeader}>History</h2>
      </Slider.Body>
      <Slider.Body scrollBodyOnly className={styles.notesHistoryContainer}>
        {loading ? <LoadingSpinner /> : <LettingScheduleNotesHistory notes={notes} />}
      </Slider.Body>
      <Slider.Footer>
        <div className={styles.notesSlideoutFooter}>
          <Button variant={ButtonVariant.Grey} size={ButtonSize.Medium} onClick={resetAndClose}>
            {hasAddedNewNote ? 'Close' : 'Cancel'}
          </Button>
        </div>
      </Slider.Footer>
    </Slider>
  );
};
