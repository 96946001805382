import { Tooltip } from '@estimateone/frontend-components';
import {
  Button,
  ButtonSize,
  ButtonVariant,
} from '@estimateone/frontend-components/src/components/Button';
import { useConnectionPreferenceFormContext } from '@subbie/modal/ConnectionPreferenceSlider/ConnectionPreferenceFormContext';
import { useAccountStockTrades, useIsAdminUser } from '@subbie/context/AccountProvider/hooks';
import {
  ConnectionPreferenceFormPage,
  ConnectionPreferenceFormId,
} from '@subbie/modal/ConnectionPreferenceSlider/ConnectionPreferenceFormContext/types';
import styles from './styles.scss';

type ConnectionPreferenceFooterProps = {
  onCancel?: () => void;
  onRemovePreference?: () => void;
};

const isConnectionPreferencePage = (page: ConnectionPreferenceFormPage) =>
  page === ConnectionPreferenceFormPage.ConnectionPreference;
const isWatchListUpdatePage = (page: ConnectionPreferenceFormPage) =>
  page === ConnectionPreferenceFormPage.WatchlistUpdated;

const getSubmitButtonText = (
  page: ConnectionPreferenceFormPage,
  hasExistingPreference: boolean,
) => {
  if (isWatchListUpdatePage(page)) {
    return 'Ok, got it';
  }
  if (hasExistingPreference) {
    return 'Confirm';
  }
  return 'Promote products';
};

const ConnectionPreferenceFooter = ({
  onCancel,
  onRemovePreference,
}: ConnectionPreferenceFooterProps) => {
  const { page, hasExistingPreference } = useConnectionPreferenceFormContext();
  const isAdmin = useIsAdminUser();
  const accountStockTrades = useAccountStockTrades();
  const hasTrades = accountStockTrades?.length > 0;

  const submitButtonText = getSubmitButtonText(page, hasExistingPreference);
  const submitButtonHover = isAdmin
    ? 'Add trades to your company profile before connecting with tendering subcontractors.'
    : 'An account admin must add trades to your company profile before connecting with tendering subcontractors.';
  const successTooltipId = hasTrades ? '' : 'connectionPreferenceFooterSubmitTooltip';

  return (
    <div className={styles.connectionPreferenceFooter}>
      {isConnectionPreferencePage(page) ? (
        <Button onClick={onCancel} variant={ButtonVariant.Grey} size={ButtonSize.Medium}>
          Cancel
        </Button>
      ) : (
        <div />
      )}

      <div className={styles.adjacentButtonContainer}>
        {hasExistingPreference && isConnectionPreferencePage(page) && (
          <Button
            onClick={onRemovePreference}
            variant={ButtonVariant.Grey}
            size={ButtonSize.Medium}
          >
            Remove my details
          </Button>
        )}
        <Tooltip tooltipId={successTooltipId} text={submitButtonHover}>
          <Button
            color="primary"
            disabled={!hasTrades}
            variant={ButtonVariant.Primary}
            size={ButtonSize.Medium}
            type="submit"
            form={ConnectionPreferenceFormId.Id}
          >
            {submitButtonText}
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};
export default ConnectionPreferenceFooter;
