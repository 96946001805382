import { useCallback } from 'react';
import Routing from 'routing';
import qs from 'query-string';
import { UpsellCard } from '@ascension/components/fec/UpsellCard';
import { Action, Event, useAnalytics } from '@ascension/components/hooks/Analytics';
import { ButtonName } from '@ascension/components/hooks/Analytics/buttonNames';
import { AnalyticsTrackedParam, SourceTrackingToken } from '@ascension/enums/Analytics';
import { AccountType } from '@ascension/_gqltypes/subbie.generated';
import { EntityId } from '@ascension/types';

type SubbiesPaywallProps = {
  projectId: EntityId;
};

export const SubbiesPaywall = ({ projectId }: SubbiesPaywallProps) => {
  const accountType = AccountType.SUPPLIER;
  const { addEvent } = useAnalytics(Event.INTERACT);

  const handlePlanSelectRedirect = useCallback(() => {
    const originalQueryStringParts = qs.parse(window.location.search);

    window.location.assign(
      Routing.generate('app_accountupgrade_index', {
        ...originalQueryStringParts,
        [AnalyticsTrackedParam.IN_APP_SOURCE]: SourceTrackingToken.UPGRADE_ROADBLOCK_SSC_SUBBIES,
      }),
    );
  }, []);

  const handleUpgrade = useCallback(() => {
    addEvent({
      action: Action.BUTTON_CLICKED,
      buttonName: ButtonName.PROFESSIONAL_UPGRADE,
      projectId,
      accountType,
    });

    handlePlanSelectRedirect();
  }, [accountType, addEvent, handlePlanSelectRedirect, projectId]);

  return (
    <UpsellCard
      headerText="Know which subbies are tendering"
      text="See the tendering subcontractors who have indicated their interested in this project."
      primaryButtonAttr={{ children: 'Upgrade now', onClick: handleUpgrade }}
      learnMoreAttr={{ href: 'https://pages.estimateone.com/subbie-connect/', target: '_blank' }}
    />
  );
};
