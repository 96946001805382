import { SharepointConnectionInformationModal } from '@builder/features/DocSyncIntegration/Sharepoint/SharepointConnectionInformationModal';
import { SharepointModifySlider } from '../Sharepoint/SharepointModifySlider/SharepointModifySlider';
import { SharepointSlider } from '../Sharepoint/SharepointSlider';
import { DocSyncPlugin } from './DocSyncPlugin';
import { StageType } from '@ascension/enums';

export const sharepointPlugin: DocSyncPlugin = {
  id: 'sharepoint',
  displayName: 'Sharepoint',
  ConnectionInformationModal: SharepointConnectionInformationModal,
  supportedStageTypes: [StageType.TYPE_TENDER, StageType.TYPE_PROCUREMENT],
  Slider: SharepointSlider,
  supportedStorageRegions: ['ap-southeast-2', 'eu-west-2'],
  ModifySlider: SharepointModifySlider,
};
