import { useCallback, useEffect, useRef } from 'react';
import { debounce } from 'lodash';

export type DebounceId = string | number;
type DebounceHandler = Parameters<typeof debounce>[0];
type DebounceMap = Record<DebounceId, ReturnType<typeof debounce>>;

export const useDebounceList = <ArgumentType,>(handler: DebounceHandler, wait = 300) => {
  const list = useRef<DebounceMap>({});

  useEffect(
    () => () => {
      Object.keys(list.current).forEach((key) => {
        const debounceHandler = list.current[key];
        debounceHandler.cancel();
      });
    },
    [],
  );

  const findOrCreate = useCallback(
    (id: DebounceId) => {
      if (!list.current[id]) {
        list.current[id] = debounce(handler, wait);
      }

      return list.current[id];
    },
    [handler, wait],
  );

  const debounceCallback = useCallback(
    (id: DebounceId, args: ArgumentType) => {
      const debounceHandler = findOrCreate(id);
      debounceHandler(args);
    },
    [findOrCreate],
  );

  return {
    debounce: debounceCallback,
  };
};
