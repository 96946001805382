import { Button, ButtonSize, ButtonVariant } from '@estimateone/frontend-components';
import styles from './styles.module.scss';

type ExhaustedCreditsButtonsViewProps = {
  upgradeUrl: string;
};

/** SUBBIE_CREDIT_EXPERIMENT_ALPHA_CODE */
export const ExhaustedCreditsButtonsView = ({ upgradeUrl }: ExhaustedCreditsButtonsViewProps) => (
  <div className={styles.buttonContainer}>
    <Button
      type="button"
      variant={ButtonVariant.Primary}
      href={upgradeUrl}
      fullWidth
      size={ButtonSize.Large}
    >
      Upgrade Now
    </Button>
  </div>
);
