import { gql } from '@apollo/client';
import Errors from '../../../api/fragments/Errors';

export const UPDATE_OTHER_STAGE_STATUS = gql`
  mutation UpdateOtherStageStatus($input: UpdateOtherStageStatusInput!) {
    updateOtherStageStatus(input: $input) {
      ... on UnrestrictedStage {
        id
      }
      ... on Errors {
        errors {
          ...ApiError
        }
      }
    }
  }
  ${Errors.fragments.error}
`;

export const UPDATE_AWARDED_STAGE_STATUS = gql`
  mutation UpdateAwardedStageStatus($input: UpdateAwardedStageStatusInput!) {
    updateAwardedStageStatus(input: $input) {
      ... on UnrestrictedStage {
        id
      }
      ... on Errors {
        errors {
          ...ApiError
        }
      }
    }
  }
  ${Errors.fragments.error}
`;

export const GET_STAGE = gql`
  query GetStage($id: EntityId!) {
    stage(id: $id) {
      ... on UnrestrictedStage {
        id
        name
        status
        constructionStartDate
        constructionEndDate
      }
    }
  }
`;
