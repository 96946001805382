import { ProfileTrade } from '@profiles/features/CompanyProfile/components/Trades/CoreTrades/types';

export const sortTradesByCoreTrades = (trades: ProfileTrade[]) =>
  [...trades].sort((a, b) => {
    if (a.isCoreTrade && !b.isCoreTrade) {
      return -1;
    }
    if (b.isCoreTrade && !a.isCoreTrade) {
      return 1;
    }
    return 0;
  });
