import Routing from 'routing';
import { QuoteReturnRoute } from '@ascension/enums';
import { RfqQuotingStatus } from '@ascension/_gqltypes/builder.generated';
import { EntityId } from '@ascension/types';

export type LatestQuote = {
  id: EntityId;
};

export type Rfq = {
  quotingStatus: RfqQuotingStatus | null;
  latestQuote: LatestQuote | null;
};

export const showCompanySlideout = (companyId: EntityId, rfqId: EntityId) => {
  document.dispatchEvent(
    new CustomEvent('address_book_slider_open', {
      detail: {
        companyId,
        rfqId,
      },
    }),
  );
};

export const generateQuotesLink = (stageId: EntityId): string =>
  Routing.generate('app_stage_quotes', {
    id: stageId,
    stageType: 'procurement',
  });

export const generateCreateQuoteLink = (
  rfqId: number,
  stageId: number,
  returnUrl?: string,
): string => {
  const route = Routing.generate('app_stage_quote_create', {
    rfqId,
    id: stageId,
    stageType: 'procurement',
  });

  return returnUrl ? `${route}?returnUrl=${encodeURIComponent(returnUrl)}` : route;
};

export const generateViewQuoteLink = (quoteId: EntityId, returnTo: QuoteReturnRoute): string =>
  Routing.generate('app_quote_view', {
    id: quoteId,
    returnTo,
  });

export const generateQuoteLinkFromRfq = (rfq: Rfq, returnTo: QuoteReturnRoute): string | null => {
  if (rfq.latestQuote?.id && rfq.quotingStatus === RfqQuotingStatus.QUOTED) {
    return generateViewQuoteLink(rfq.latestQuote.id, returnTo);
  }
  return null;
};
