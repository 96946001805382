import { Button, ButtonSize } from '@estimateone/frontend-components';
import BillingCycleSelector from '../../../../shared/BillingCycleSelector';
import { BillingCycle } from '@ascension/enums';
import styles from './styles.scss';

type BillingCycleSelectionProps = {
  onAdvancePage: () => void;
  pricingOptions: {
    annualPrice: number;
    monthlyPrice: number;
  };
  setBillingCycle: (length: BillingCycle) => void;
  billingCycle: BillingCycle;
};

const BillingCycleSelection = ({
  onAdvancePage,
  pricingOptions: { monthlyPrice, annualPrice },
  setBillingCycle,
  billingCycle,
}: BillingCycleSelectionProps) => (
  <>
    <h3 className={styles.selectPlan}>Select Plan</h3>
    <BillingCycleSelector
      annualPrice={annualPrice}
      monthlyPrice={monthlyPrice}
      onSelection={setBillingCycle}
      selectedBillingCycle={billingCycle}
    />
    <Button onClick={onAdvancePage} size={ButtonSize.Large} fullWidth>
      Next - Payment details
    </Button>
  </>
);

export default BillingCycleSelection;
