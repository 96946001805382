import { DispatchWithoutAction, useEffect } from 'react';
import { DrawerBody, DrawerFooter, DrawerToolbar } from '@estimateone/frontend-components';
import { OperatingCountryProvider } from '@ascension/components/localisation';
import { OperatingCountry } from '@ascension/components/localisation/operatingCountry';
import { DrawerFooterButtons } from '@profiles/features/CompanyProfile/modules/Drawer/DrawerFooterButtons';
import { sendAppcuesEditProfileEvent } from '@profiles/features/CompanyProfile/utils/appcues';
import { DrawerWrappedComponentProps, withDrawerRoute } from '../../withDrawerRoute';
import { OfficeDetailsFormInitialValues, OfficeDetailsForm } from '../OfficeDetailsForm';
import styles from './EditOfficeDetailsDrawer.module.scss';
import { getServiceAreaRadius, getServiceAreaType, getServiceAreaStates } from './utils';
import { useSubmitEditOfficeDetails } from './hooks';
import { EditOfficeDetailsFieldValues } from '../OfficeDetailsFields/types';
import { StateType } from '@profiles/features/CompanyProfile/types';

export type EditOfficeDetailsProps = {
  isOpen: boolean;
  toggle: DispatchWithoutAction;
  accountOfficeId: number;
  name: string;
  streetAddress: string | null;
  suburb: string | null;
  state: StateType;
  city: string | null;
  province: string | null;
  postcode: string | null;
  officeEmail: string | null;
  phoneNumber: string | null;
  website: string | null;
};

export const EditOfficeDetailsDrawer = withDrawerRoute(
  ({ onClose, profile, params }: DrawerWrappedComponentProps) => {
    const { submit, errors } = useSubmitEditOfficeDetails();
    const formId = 'office-details-form-id';
    const office = profile.offices.find((item) => item.ascensionId === Number(params.officeId));

    useEffect(() => {
      if (!office) {
        onClose();
      }
    }, [office, onClose]);

    const onSubmit = async (values: EditOfficeDetailsFieldValues) => {
      const success = await submit(values);
      if (success) {
        onClose();
      }

      sendAppcuesEditProfileEvent();
    };

    const countryId = profile.account?.primaryCountry?.id ?? OperatingCountry.AU;
    const serviceAreaType = getServiceAreaType(office?.serviceArea);
    const serviceAreaRadius = getServiceAreaRadius(serviceAreaType, office?.serviceArea);
    const serviceAreaStates = getServiceAreaStates(serviceAreaType, office?.serviceArea);

    const initialValues: OfficeDetailsFormInitialValues = {
      accountOfficeId: office?.ascensionId ?? Number(office?.id),
      name: office?.name ?? null,
      streetAddress: office?.address.address1 ?? '',
      suburb: office?.address.suburb ?? '',
      state: office?.address.state
        ? {
            value: office.address.state.id,
            label: office.address.state.shortName,
          }
        : null,
      city: office?.address.city ?? '',
      province: office?.address.province ?? '',
      postcode: office?.address.postcode ?? '',
      officeEmail: office?.contactable?.email ?? null,
      phoneNumber: office?.contactable?.phone ?? null,
      website: office?.contactable?.web ?? null,
      serviceAreaType,
      serviceAreaRadius,
      serviceAreaStates,
    };

    return (
      <>
        <DrawerToolbar />
        <DrawerBody>
          <OperatingCountryProvider countryId={countryId}>
            <h2 className={styles.formHeader}>Edit Office</h2>
            <OfficeDetailsForm
              formId={formId}
              initialValues={initialValues}
              onSubmit={onSubmit}
              errors={errors}
            />
          </OperatingCountryProvider>
        </DrawerBody>
        <DrawerFooter>
          <DrawerFooterButtons formId={formId} close={onClose} />
        </DrawerFooter>
      </>
    );
  },
);
