import {
  OperationVariables,
  TypedDocumentNode,
  QueryHookOptions,
  useLazyQuery,
} from '@apollo/client';
import { getOrCreateClient } from '../../client';

export const useLazyDocSyncQuery = <
  TData = unknown,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>,
) =>
  useLazyQuery<TData, TVariables>(query, {
    client: getOrCreateClient(),
    ...options,
  });
