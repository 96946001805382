import { createContext, ReactNode } from 'react';

export const EwayContext = createContext<{ publicKey: string } | undefined>(undefined);

const { Provider } = EwayContext;

const EwayProvider = ({
  children,
  eWayPublicKey,
}: {
  children?: ReactNode;
  eWayPublicKey: string;
}) => <Provider value={{ publicKey: eWayPublicKey }}>{children}</Provider>;

export default EwayProvider;
