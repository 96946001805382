import {
  FiltersIllustration,
  Button,
  ButtonVariant,
  E1Link,
  LinkVariant,
} from '@estimateone/frontend-components';
import { useSetAtom } from 'jotai/index';
import { RESET } from 'jotai/utils';
import {
  companyListIdAtom,
  relationshipTypeAtom,
  selectedFiltersAtom,
} from '@builder/features/SubbieNetwork/atoms';
import { useActiveConfirmedFilters } from '../../SubbieNetworkFilterDrawer/hooks';
import styles from './styles.module.scss';

const GenericFilterConflict = ({
  handleClearAllFilters,
}: {
  handleClearAllFilters: () => void;
}) => (
  <div className={styles.noResultsView}>
    <FiltersIllustration className={styles.illustration} />
    <p className={styles.headingText}>No results found</p>
    <p className={styles.text}>Try removing or changing filters to broaden your search</p>
    <Button variant={ButtonVariant.Grey} onClick={handleClearAllFilters}>
      Reset Filters
    </Button>
  </div>
);

type CompanyListConnectionTypeConflictProps = {
  handleClearCompanyListFilter: () => void;
  handleClearRelationshipTypeFilter: () => void;
};

const CompanyListConnectionTypeConflict = ({
  handleClearCompanyListFilter,
  handleClearRelationshipTypeFilter,
}: CompanyListConnectionTypeConflictProps) => (
  <div className={styles.noResultsView}>
    <FiltersIllustration className={styles.illustration} />
    <p className={styles.headingText}>No results found</p>
    <p className={styles.text}>
      Only your ‘Address Book’ companies can have a custom Trade or be in a Company List
    </p>
    <p className={styles.text}>Broaden your search with these suggestions:</p>
    <E1Link variant={LinkVariant.Grey} onClick={handleClearRelationshipTypeFilter}>
      Remove the Company List filter
    </E1Link>
    <E1Link variant={LinkVariant.Grey} onClick={handleClearCompanyListFilter}>
      Remove the Relationship filter
    </E1Link>
  </div>
);

type ConnectionTypeConflictProps = {
  handleClearRelationshipTypeFilter: () => void;
};
const ConnectionTypeConflict = ({
  handleClearRelationshipTypeFilter,
}: ConnectionTypeConflictProps) => (
  <div className={styles.noResultsView}>
    <FiltersIllustration className={styles.illustration} />
    <p className={styles.headingText}>No results found</p>
    <p className={styles.text}>
      Only your ‘Address Book’ companies can have a custom Trade or be in a Company List
    </p>
    <p className={styles.text}>
      Broaden your search:&nbsp;
      <E1Link variant={LinkVariant.Grey} onClick={handleClearRelationshipTypeFilter}>
        remove the Relationship filter
      </E1Link>
    </p>
  </div>
);

export const EmptyResultsView = () => {
  const setSelectedFilters = useSetAtom(selectedFiltersAtom);
  const setRelationshipType = useSetAtom(relationshipTypeAtom);
  const setCompanyListId = useSetAtom(companyListIdAtom);
  const activeFilters = useActiveConfirmedFilters();

  const onReset = () => {
    setSelectedFilters(RESET);
  };
  const clearConnectionTypeFilter = () => {
    setRelationshipType(undefined);
  };
  const clearCompanyListFilter = () => {
    setCompanyListId(undefined);
  };

  if (activeFilters.companyListFilter && activeFilters.connectionTypeFilter) {
    return (
      <CompanyListConnectionTypeConflict
        handleClearCompanyListFilter={clearCompanyListFilter}
        handleClearRelationshipTypeFilter={clearConnectionTypeFilter}
      />
    );
  }

  if (activeFilters.connectionTypeFilter) {
    return <ConnectionTypeConflict handleClearRelationshipTypeFilter={clearConnectionTypeFilter} />;
  }

  return <GenericFilterConflict handleClearAllFilters={onReset} />;
};
