import { useMutation } from '@apollo/client';
import { UPDATE_STAGE_SETTING_NOTIFY_AWARDED } from '../mutations';
import {
  UpdateStageSettingNotifyAwarded,
  UpdateStageSettingNotifyAwardedVariables,
} from '../types/UpdateStageSettingNotifyAwarded';

export const useUpdateStageSettingNotifyAwarded = () => {
  const [updateStageSettingNotifyAwarded, { loading }] = useMutation<
    UpdateStageSettingNotifyAwarded,
    UpdateStageSettingNotifyAwardedVariables
  >(UPDATE_STAGE_SETTING_NOTIFY_AWARDED);

  return { updateStageSettingNotifyAwarded, loading };
};
