import { useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { CSSTransition } from 'react-transition-group';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  Icon,
  IconName,
  joinClassNames,
} from '@estimateone/frontend-components';
import NotificationList from './NotificationList';
import { groupNotifications } from './transformers';
import { getUnreadNotificationsSinceLastOpened } from './utils';
import { useNotificationListLastOpened, useNotifications } from './hooks';
import styles from './styles.scss';

const BellIcon = () => <Icon name={IconName.Notifications} size={24} />;

const NotificationsIcon = ({ unreadNotificationCount }: { unreadNotificationCount: number }) => (
  <div
    className={joinClassNames(
      styles.notificationsIconContainer,
      unreadNotificationCount !== 0 && styles.hasUnreadNotifications,
    )}
    data-unread-count={unreadNotificationCount}
  >
    <BellIcon />
  </div>
);

export const NotificationsLoadingIcon = () => (
  <div className={styles.loadingIconContainer}>
    <BellIcon />
  </div>
);

const NotificationsFeature = ({
  defaultPollIntervalSeconds = 60,
}: {
  defaultPollIntervalSeconds?: number;
}) => {
  const [isOpen, setOpen] = useState(false);
  const ref = useOnclickOutside(() => setOpen(false));
  const { update: updateLastOpened, lastOpened } = useNotificationListLastOpened();

  const toggleOpen = () =>
    setOpen((prevOpenState) => {
      const nextOpenState = !prevOpenState;
      if (nextOpenState) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        (async () => updateLastOpened())();
      }

      return nextOpenState;
    });

  const notifications = useNotifications(defaultPollIntervalSeconds);

  if (!notifications) return <NotificationsLoadingIcon />;

  const groupedNotifications = groupNotifications(notifications);

  const unreadNotificationsSinceLastOpened = getUnreadNotificationsSinceLastOpened(
    groupedNotifications,
    lastOpened,
  );

  return (
    <div id="notifications-dropdown" ref={ref} className={styles.notificationFeatureContainer}>
      <Button
        size={ButtonSize.Small}
        variant={ButtonVariant.Transparent}
        onClick={toggleOpen}
        title={isOpen ? 'Close notifications' : 'Open notifications'}
        aria-label={isOpen ? 'Close notifications' : 'Open notifications'}
      >
        <NotificationsIcon
          unreadNotificationCount={isOpen ? 0 : unreadNotificationsSinceLastOpened.length}
        />
      </Button>
      <CSSTransition
        in={isOpen}
        timeout={200}
        classNames={{
          enter: styles.notificationListEnter,
          enterActive: styles.notificationListEnterActive,
          exit: styles.notificationListExit,
          exitActive: styles.notificationListExitActive,
        }}
        unmountOnExit
      >
        <NotificationList
          notifications={groupedNotifications}
          onRequestClose={() => setOpen(false)}
        />
      </CSSTransition>
    </div>
  );
};

export default NotificationsFeature;
