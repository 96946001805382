import { Timeline } from '@estimateone/frontend-components';
import moment from 'moment-timezone';
import { getUserTimezone } from '@ascension/js/app/timezone';
import { groupByKey } from '@ascension/js/utils/array';
import { Log } from '../types';
import styles from './styles.scss';

const ProjectLogs = ({ projectLogs }: { projectLogs: Log[] }) => {
  const projectLogsGrouped = groupByKey<Log>(({ createdAt }) =>
    moment(createdAt).isValid() ? moment(createdAt).format('MMM YYYY') : createdAt,
  )(projectLogs);

  const formatCreatedAtToHumanFriendlyDate = (timestamp: string) =>
    moment(timestamp).isValid()
      ? moment(timestamp).tz(getUserTimezone()).format('DD/MM/YYYY')
      : timestamp;

  return (
    <div id="project-history" className={`${styles.projectLogs} ${styles.projectSection}`}>
      <h3>History</h3>
      {projectLogs.length === 0 ? (
        <span style={{ fontStyle: 'italic' }}>No project logs yet!</span>
      ) : (
        Array.from(projectLogsGrouped, ([date, logs]) => (
          <div key={date}>
            <h5>
              <span>{date}</span>
            </h5>
            <Timeline>
              {logs.map(({ id, message, createdAt }) => (
                <Timeline.Segment key={id} label={formatCreatedAtToHumanFriendlyDate(createdAt)}>
                  <span>{message}</span>
                </Timeline.Segment>
              ))}
            </Timeline>
          </div>
        ))
      )}
    </div>
  );
};

export default ProjectLogs;
