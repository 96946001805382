import Routing from 'routing';
import { joinClassNames } from '@estimateone/frontend-components';
import { getStageTypeAsString, StageType } from '@ascension/enums';
import styles from './styles.scss';

export type InvitesAndResponsesPageSubnavProps = {
  stageId: number;
  stageName: string;
  children: React.ReactNode;
};

export const InvitesAndResponsesPageSubnav = ({
  stageId,
  stageName,
  children,
}: InvitesAndResponsesPageSubnavProps) => {
  const irLink = Routing.generate('app_stage_monitor', {
    id: stageId,
    stageType: getStageTypeAsString(StageType.TYPE_TENDER)?.toLowerCase(),
  });

  return (
    <div className="sub-bar">
      <div className="wrapper">
        <div id="subnav">
          <div className="layout">
            <a className="subnavlink" href={irLink}>
              <i className="subnavicon icon icon-arrow-left" />
              <span className="subnavlink__text">Invites &amp; Responses</span>
            </a>

            <div className={joinClassNames('subnavtitle', styles.titleContainer)}>
              <h2>{stageName}</h2>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
