import {
  Button,
  ButtonSize,
  ButtonVariant,
  CellProps,
  Table,
  TableColumn,
} from '@estimateone/frontend-components';
import { BuilderAccountWithExperiments_currentUser as CurrentUser } from '@builder/context/AccountProvider/types/BuilderAccountWithExperiments';
import { DisplayUser } from './types';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

type RemoveMemberFunction = (userId: EntityId, userName: string | null) => Promise<void>;

const RemoveCell =
  (handleOnClick: RemoveMemberFunction, loading: boolean, currentUser: CurrentUser) =>
  ({ row: { original } }: CellProps<DisplayUser>) =>
    original.id !== currentUser.id ? (
      <Button
        variant={ButtonVariant.Grey}
        size={ButtonSize.Small}
        disabled={loading}
        onClick={() => handleOnClick(original.id, original.fullName)}
      >
        Remove
      </Button>
    ) : (
      <div />
    );

type TeamMembersTableProps = {
  hasStageRestrictedAccess: boolean;
  teamMembers: DisplayUser[];
  emptyStateText?: string;
  handleRemoveMember?: RemoveMemberFunction;
  removeMemberLoading?: boolean;
  currentUser: CurrentUser;
};

export const TeamMembersTable = ({
  hasStageRestrictedAccess,
  teamMembers,
  emptyStateText,
  handleRemoveMember,
  removeMemberLoading,
  currentUser,
}: TeamMembersTableProps) => {
  const columns: TableColumn<DisplayUser>[] = [
    { Header: 'Name', accessor: 'fullName' },
    { Header: 'Email', accessor: 'email', disableSortBy: true },
    { Header: 'Phone', accessor: 'phone', disableSortBy: true },
  ];

  if (handleRemoveMember && removeMemberLoading !== undefined) {
    columns.push({
      id: 'access',
      Header: 'Access',
      Cell: RemoveCell(handleRemoveMember, removeMemberLoading, currentUser),
      disableSortBy: true,
      fitToContent: true,
    });
  }

  return (
    <>
      <Table<DisplayUser> columns={columns} data={teamMembers} initSortColumnId="fullName" />
      {!hasStageRestrictedAccess || teamMembers.length === 0 ? (
        <div className={styles.emptyStateText}>{emptyStateText}</div>
      ) : undefined}
    </>
  );
};
