import { gql } from '@apollo/client';

export const GET_PROCORE_PROJECTS = gql`
  query projects {
    projects {
      id
      company {
        id
        name
      }
      name
      dateCreatedAt
      availability
      address {
        addressLine1
        city
        county
        stateCode
        countryCode
        zip
      }
    }
  }
`;

export const GET_PROCORE_COMPANIES = gql`
  query companies {
    companies {
      id
      name
    }
  }
`;

export const GET_STAGE_PROCORE_MAP_SETUP = gql`
  query stageProcoreMapSetup($projectId: ID!, $companyId: ID!) {
    stageProcoreMapSetup(projectId: $projectId, companyId: $companyId) {
      projectTools {
        drawings {
          isActive
        }
        specifications {
          isActive
        }
        documents {
          isActive
        }
      }
      projectPermissions {
        hasPermissions
      }
      stageProcoreMapOptions {
        includeDrawings
        includeSpecifications
        documents {
          files {
            id
            name
            availability
            isTracked
          }
          folders {
            id
            name
            availability
            isTracked
          }
        }
      }
    }
  }
`;

export const GET_STAGE_PROCORE_MAP_FOR_MODIFICATION = gql`
  query stageProcoreMapForModification($stageId: ID!) {
    stageProcoreMapForModification(stageId: $stageId) {
      projectTools {
        drawings {
          isActive
        }
        specifications {
          isActive
        }
        documents {
          isActive
        }
      }
      stageProcoreMapOptions {
        includeDrawings
        includeSpecifications
        documents {
          files {
            id
            name
            availability
            isTracked
          }
          folders {
            id
            name
            availability
            isTracked
          }
        }
      }
    }
  }
`;

export const GET_INFECTED_FILES = gql`
  query getInfectedFiles($stageId: ID!) {
    stage(stageId: $stageId) {
      infectedFiles {
        id
        name
        folder
        revisionNumber
        infectionData
      }
    }
  }
`;
