import { useQuery } from '@apollo/client';
import ReviseQuoteForm from '../../../features/QuotesFeature/ReviseQuoteForm';
import PageLoadingSpinner from '../../../../shared/LoadingSpinner/PageLoadingSpinner';
import QuotePageLayout from '../../../../shared/Quotes/Page/QuotePageLayout';
import { CurrencyInfoContextProvider } from '@shared';
import { FileManagerParams } from '@shared/FileUploader/types';
import { quoteFileToFile } from '@shared/Quotes/Form/transformers';
import { graphqlTypeToString } from '@shared/Quotes/utils/Address';
import { BUILDER_GET_QUOTE_FOR_REVISION } from './queries';
import { getCurrencyInfo } from '@ascension/enums';
import {
  BuilderGetQuoteForRevision,
  BuilderGetQuoteForRevisionVariables,
} from './types/BuilderGetQuoteForRevision';
import { EntityId } from '@ascension/types';

type ReviseQuotePageProps = Pick<FileManagerParams, 'fileManagerLocale'> & {
  quoteId: EntityId;
  stageId: EntityId;
  returnUrl: string;
};

const PageContent = ({ fileManagerLocale, quoteId, stageId, returnUrl }: ReviseQuotePageProps) => {
  const { data, loading } = useQuery<
    BuilderGetQuoteForRevision,
    BuilderGetQuoteForRevisionVariables
  >(BUILDER_GET_QUOTE_FOR_REVISION, { variables: { quoteId, stageId } });

  if (loading) return <PageLoadingSpinner />;

  const quote = data?.quote;
  const stage = data?.stage.__typename === 'UnrestrictedStage' ? data.stage : undefined;

  if (!quote || !quote.rfq || !stage) {
    throw new Error('Failed to load quote');
  }

  const { projectAddress: address, rfq, additionalFiles, clarifications, version } = quote;

  const currencyInfo = getCurrencyInfo(stage.account?.primaryCountry?.id);

  return (
    <CurrencyInfoContextProvider currencyInfo={currencyInfo}>
      <div data-revising-version={version}>
        <ReviseQuoteForm
          fileManagerLocale={fileManagerLocale}
          quoteId={quoteId}
          projectAddress={address ? graphqlTypeToString(address) : ''}
          stageName={stage.name}
          packageTitle={quote.package?.title ?? ''}
          rfq={rfq}
          extraFiles={additionalFiles.map(quoteFileToFile)}
          quoteDetails={clarifications ?? undefined}
          returnUrl={returnUrl}
        />
      </div>
    </CurrencyInfoContextProvider>
  );
};

const ReviseQuotePage = ({ returnUrl, ...contentProps }: ReviseQuotePageProps) => (
  <QuotePageLayout title="Revise quotes" returnUrl={returnUrl}>
    <PageContent returnUrl={returnUrl} {...contentProps} />
  </QuotePageLayout>
);

export default ReviseQuotePage;
