import { useEffect } from 'react';
import useFlashMessage from '@shared/Util/useFlashMessage';
import { FlashMessageEvent } from './types';

export const FlashMessageListener = () => {
  const { success, warning } = useFlashMessage();

  useEffect(() => {
    const eventListener = (event: FlashMessageEvent) => {
      const { message, title, type } = event.detail;

      if (type === 'success') {
        success({ message, title });
      } else if (type === 'warning') {
        warning({ message, title });
      }
    };

    document.addEventListener('flashMessage', eventListener);

    return () => {
      document.removeEventListener('flashMessage', eventListener);
    };
  }, []);

  return <span />;
};
