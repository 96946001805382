import { FontStyle, Heading } from '../../../../../../fec';
import PaywallAmount from '../../../../../common/PaywallAmount';

export const RoadblockHeaderAdditionalLicense = () => (
  <Heading level={1} renderAs={FontStyle.h2}>
    Add more licenses to access this project
  </Heading>
);

export const RoadblockHeaderAwarded = () => (
  <Heading level={1} renderAs={FontStyle.h2}>
    Upgrade to access awarded projects
  </Heading>
);

export const RoadblockHeaderBudget = ({ projectPaywallValue }: { projectPaywallValue: number }) => (
  <Heading level={1} renderAs={FontStyle.h2}>
    Upgrade to access projects over <PaywallAmount amount={projectPaywallValue} longUnits />
  </Heading>
);

export const RoadblockHeaderNationalLicense = () => (
  <Heading level={1} renderAs={FontStyle.h2}>
    Upgrade to the National License to access this project
  </Heading>
);

export const RoadblockHeaderFiftyPercent = () => (
  <Heading level={1} renderAs={FontStyle.h2}>
    Your current plan has limited access. Upgrade to unlock all features.
  </Heading>
);
