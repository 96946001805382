import { gql } from '@apollo/client';
import Errors from '../../../api/fragments/Errors';
import { PackageLettingSchedule } from '../ProcurementLettingSchedule/fragments';

export const CREATE_PACKAGE_WITH_LETTING_SCHEDULE = gql`
  mutation CreatePackageWithLettingScheduleDetails(
    $input: CreatePackageWithLettingScheduleDetailsInput!
  ) {
    createPackageWithLettingScheduleDetails(input: $input) {
      ...PackageLettingSchedule
      ... on Errors {
        errors {
          ...ApiError
        }
      }
    }
  }
  ${Errors.fragments.error}
  ${PackageLettingSchedule}
`;
