import { useEffect, useState } from 'react';
import $ from 'jquery';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  Col,
  CollapsibleSection,
  Row,
} from '@estimateone/frontend-components';
import CompanyActions, { MERGE_DIRECTION } from '../AddressBookDuplicates/CompanyActions';
import CompanyCard from '../AddressBookDuplicates/CompanyCard';
import ManualMergeActions from './ManualMergeActions';
import ManualMergeSearchCard from './ManualMergeSearchCard';
import LoadingSpinner from '@shared/LoadingSpinner';
import useGetAddressBookCompany, {
  Company,
} from '@builder/common/AddressBook/hooks/useGetAddressBookCompany';
import { SearchedCompany } from '@builder/common/AddressBook/hooks/useSearchAddressBookCompanies';
import styles from './styles.scss';

const ManualContactMerge = () => {
  const [companyA, setCompanyA] = useState<SearchedCompany | null>(null);
  const [companyB, setCompanyB] = useState<SearchedCompany | null>(null);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const clearSelection = () => {
    setCompanyA(null);
    setCompanyB(null);
  };

  useEffect(() => {
    $(document).on('clear-merge-cards', clearSelection);
  }, []);

  const toggleManualMerge = () => {
    if (isCollapsed === true) {
      clearSelection();
    }
    setIsCollapsed(!isCollapsed);
  };

  const getMergeDirection = (companyLeft: Company, companyRight: Company) => {
    if (companyLeft.linkedAccount === null && companyRight.linkedAccount !== null) {
      return MERGE_DIRECTION.RIGHT;
    }
    if (companyLeft.linkedAccount !== null && companyRight.linkedAccount === null) {
      return MERGE_DIRECTION.LEFT;
    }
    if (companyLeft.linkedAccount === null && companyRight.linkedAccount === null) {
      return MERGE_DIRECTION.BOTH;
    }

    return MERGE_DIRECTION.NONE;
  };

  const MergeCompanyCard = ({ company }: { company: SearchedCompany }) => {
    const { data: companyDetails, error, loading } = useGetAddressBookCompany(company.id);

    if (error) throw Error(error.message);
    if (loading) return <LoadingSpinner size="medium" center />;
    if (!companyDetails) throw Error('Failed to fetch company details');

    const { id, name, address, phone, activeContacts, linkedAccount } = companyDetails;

    return (
      <CompanyCard
        id={id}
        name={name}
        address={address?.fullAddress || null}
        phone={phone}
        contacts={activeContacts}
        hasNetworkConnection={linkedAccount !== null}
      />
    );
  };

  const ManualMergePreview = ({
    original,
    duplicate,
  }: {
    original: SearchedCompany;
    duplicate: SearchedCompany;
  }) => {
    const {
      data: originalCompanyData,
      loading: originalCompanyLoading,
      error: originalCompanyError,
    } = useGetAddressBookCompany(original.id);
    const {
      data: duplicateCompanyData,
      loading: duplicateCompanyLoading,
      error: duplicateCompanyError,
    } = useGetAddressBookCompany(duplicate.id);

    if (originalCompanyError) throw Error(originalCompanyError.message);
    if (duplicateCompanyError) throw Error(duplicateCompanyError.message);
    if (originalCompanyLoading || duplicateCompanyLoading)
      return <LoadingSpinner size="medium" center />;
    if (!originalCompanyData || !duplicateCompanyData)
      throw Error('Failed to fetch company details');

    return (
      <Row>
        <Col span={5}>
          <CompanyCard
            id={originalCompanyData.id}
            name={originalCompanyData.name}
            address={originalCompanyData.address?.fullAddress || null}
            phone={originalCompanyData.phone}
            contacts={originalCompanyData.activeContacts}
            hasNetworkConnection={originalCompanyData.linkedAccount !== null}
          />
        </Col>
        <Col span={2}>
          <CompanyActions
            originalId={originalCompanyData.id}
            duplicateId={duplicateCompanyData.id}
            onDismiss={clearSelection}
            dismissText="Clear"
            mergeDirection={getMergeDirection(originalCompanyData, duplicateCompanyData)}
          />
        </Col>
        <Col span={5}>
          <CompanyCard
            id={duplicateCompanyData.id}
            name={duplicateCompanyData.name}
            address={duplicateCompanyData.address?.fullAddress || null}
            phone={duplicateCompanyData.phone}
            contacts={duplicateCompanyData.activeContacts}
            hasNetworkConnection={duplicateCompanyData.linkedAccount !== null}
          />
        </Col>
      </Row>
    );
  };

  return (
    <>
      <div className={styles.manualMergeButton}>
        <Button
          variant={ButtonVariant.Secondary}
          size={ButtonSize.Small}
          onClick={toggleManualMerge}
        >
          {isCollapsed ? 'Merge duplicates manually' : 'Hide manual merge'}
        </Button>
      </div>
      <CollapsibleSection isCollapsed={isCollapsed}>
        <h3>Merge duplicates manually</h3>
        <p className={styles.grey}>Search for duplicate companies that you would like to merge</p>
        <div className={styles.manualMergeItem}>
          {companyA !== null && companyB !== null ? (
            <ManualMergePreview original={companyA} duplicate={companyB} />
          ) : (
            <Row>
              <Col span={5}>
                {companyA ? (
                  <MergeCompanyCard company={companyA} />
                ) : (
                  <ManualMergeSearchCard
                    updateCompany={setCompanyA}
                    excludedCompanyId={companyB?.id}
                  />
                )}
              </Col>
              <Col span={2}>
                <ManualMergeActions clearSelection={clearSelection} />
              </Col>
              <Col span={5}>
                {companyB ? (
                  <MergeCompanyCard company={companyB} />
                ) : (
                  <ManualMergeSearchCard
                    updateCompany={setCompanyB}
                    excludedCompanyId={companyA?.id}
                  />
                )}
              </Col>
            </Row>
          )}
        </div>
      </CollapsibleSection>
    </>
  );
};

export default ManualContactMerge;
