import { useEffect, useMemo } from 'react';
import { Form } from 'react-final-form';
import { Button, ButtonSize, ButtonVariant, Col, Row } from '@estimateone/frontend-components';
import setFieldTouched from 'final-form-set-field-touched';
import { Heading } from '../../../../fec';
import { getViewRouteForQuoteId } from '../utils';
import GlobalErrorList from '../../../../shared/FinalForm/GlobalErrorList';
import { FileManagerParams } from '@shared/FileUploader/types';
import { NonEditablePackageSubbieSection } from '@shared/Quotes/Form/NonEditablePackageContactSection';
import { StageDetails } from '@shared/Quotes/Form/ProjectOrStageDetails';
import { QuoteSectionWithQuoteFileUploader } from '@shared/Quotes/Form/QuoteFormQuoteSection';
import { focusOnQuoteErrorsDecorator } from '@shared/Quotes/Form/decorators';
import { validateQuoteFormCommonFields } from '@shared/Quotes/Form/validators';
import { useCreateQuoteFromRfq } from './hooks';
import { QuoteFormCommonFieldName, UploadParentType } from '@ascension/enums';
import type { CreateQuoteFromRfqFormErrors, QuoteCreateFromRfqFormValues } from './types';
import type { EntityId } from '@ascension/types';
import styles from '../../../../shared/Quotes/Form/styles.scss';

export type CreateQuoteFromRfqFormProps = {
  rfqId: EntityId;
  projectAddress: string;
  stageName: string;
  packageTitle: string;
  subbieCompanyName: string;
  subbieContactName: string;
  cancelUrl: string;
  successUrl: string | null;
} & Pick<FileManagerParams, 'fileManagerLocale'>;

const FORM_ID = 'builder-create-quote-from-rfq-form';

const validate = (values: QuoteCreateFromRfqFormValues): CreateQuoteFromRfqFormErrors =>
  validateQuoteFormCommonFields(values);

const CreateQuoteFromRfqForm = ({
  fileManagerLocale,
  rfqId,
  projectAddress,
  stageName,
  packageTitle,
  subbieCompanyName,
  subbieContactName,
  cancelUrl,
  successUrl,
}: CreateQuoteFromRfqFormProps) => {
  const focusOnErrors = useMemo(
    () =>
      focusOnQuoteErrorsDecorator<QuoteCreateFromRfqFormValues, CreateQuoteFromRfqFormErrors>(
        FORM_ID,
      ),
    [],
  );

  const { submit: createQuoteFromRfq, newQuoteId } = useCreateQuoteFromRfq(rfqId);

  useEffect(() => {
    if (newQuoteId) {
      window.location.replace(successUrl ?? getViewRouteForQuoteId(newQuoteId));
    }
  }, [newQuoteId, successUrl]);

  return (
    <Form<QuoteCreateFromRfqFormValues>
      onSubmit={createQuoteFromRfq}
      initialValues={{
        [QuoteFormCommonFieldName.ExtraFiles]: [],
      }}
      decorators={[focusOnErrors]}
      validate={(values): CreateQuoteFromRfqFormErrors => validate(values)}
      mutators={{ setFieldTouched }}
      keepDirtyOnReinitialize
    >
      {({ handleSubmit, submitting, submitError }) => (
        <form id={FORM_ID} name={FORM_ID} onSubmit={handleSubmit}>
          <Row>
            <Col span={12}>
              {submitError && <GlobalErrorList errors={submitError} />}

              <div className={styles.subHeader}>
                Upload quote files and complete the details below to submit the quote.
              </div>

              <div className={styles.sectionHeading}>
                <Heading level={2}>Project details</Heading>
              </div>
              <StageDetails name={stageName} addressString={projectAddress} />
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <NonEditablePackageSubbieSection
                packageName={packageTitle}
                companyName={subbieCompanyName}
                contactName={subbieContactName}
              />
            </Col>
          </Row>

          <QuoteSectionWithQuoteFileUploader
            fileManagerLocale={fileManagerLocale}
            parentType={UploadParentType.RFQ_QUOTE}
            parentId={rfqId}
            extraFilesParentType={UploadParentType.RFQ_QUOTE_EXTRA_FILE}
            extraFilesParentId={rfqId}
          />

          <Row>
            <Col span={12}>
              <hr />
            </Col>
          </Row>

          <Row>
            <Col span={2}>
              <Button link={cancelUrl} size={ButtonSize.Large} variant={ButtonVariant.Grey}>
                Cancel
              </Button>
            </Col>
            <Col span={4} offset={6}>
              <Button size={ButtonSize.Large} fullWidth isDisabled={submitting}>
                Submit
              </Button>
            </Col>
          </Row>
        </form>
      )}
    </Form>
  );
};

export default CreateQuoteFromRfqForm;
