import { ApolloError, useMutation } from '@apollo/client';
import { reportError } from '@ascension/components/helpers/errorReporter';
import { useProfileContext } from '@profiles/providers/ProfileProvider';
import useFlashMessage from '@shared/Util/useFlashMessage';
import { CHANGE_PRIMARY_CONTACT, HIDE_PROFILE_CONTACT } from './mutations';
import {
  ChangePrimaryContactMutation,
  ChangePrimaryContactMutationVariables,
  HideProfileContactMutationVariables,
  HideProfileContactMutation,
} from './mutations.generated';

export const useChangePrimaryContact = (userId: number) => {
  const { success: showSuccess, warning: showWarning } = useFlashMessage();
  const { refetch } = useProfileContext();

  const [changePrimaryContact] = useMutation<
    ChangePrimaryContactMutation,
    ChangePrimaryContactMutationVariables
  >(CHANGE_PRIMARY_CONTACT, {
    variables: { userId },
    onCompleted: () => {
      refetch();
      showSuccess({
        title: 'Success',
        message: 'The Primary Contact has been updated on your Company Profile',
      });
    },
    onError: (error: ApolloError) => {
      showWarning({ title: 'Error', message: 'Something went wrong. Please try again.' });
      reportError(error);
    },
  });

  return { changePrimaryContact };
};

export const useHideProfileContact = (userId: number, fullName: string) => {
  const { success: showSuccess, warning: showWarning } = useFlashMessage();
  const name = fullName ?? 'This contact';

  const [hideProfileContact] = useMutation<
    HideProfileContactMutation,
    HideProfileContactMutationVariables
  >(HIDE_PROFILE_CONTACT, {
    variables: { userId },
    onCompleted: () => {
      showSuccess({
        title: 'Success',
        message: `${name} is hidden on your Company Profile`,
      });
    },
    onError: (error: ApolloError) => {
      showWarning({ title: 'Error', message: 'Something went wrong. Please try again.' });
      reportError(error);
    },
  });

  return { hideProfileContact };
};
