import { gql } from '@apollo/client';

export const GET_PROJECT_CONSULTANTS = gql`
  query GetProjectConsultants($projectId: EntityId!) {
    project(id: $projectId) {
      ... on ProjectInterface {
        id
        createdAt
      }
      ... on ViewableNoticeboardProject {
        consultants {
          id
          consultant {
            id
            name
            domain
          }
          type {
            id
            name
            type
          }
        }
      }
    }
  }
`;

export const GET_CONNECTION_PREFERENCE = gql`
  query GetConnectionPreference($projectId: EntityId!) {
    connectionPreference(projectId: $projectId) {
      id
      projectId
      projectName
      type
      contactId
      contactName
      note
      viewCount
      deletedAt
    }
  }
`;
