import { useLazyDocSyncQuery } from './useLazyDocSyncQuery';
import { GET_STAGE_PROCORE_MAP_SETUP } from '../queries';
import { stageProcoreMapSetup as StageProcoreMapSetup } from '../types/stageProcoreMapSetup';

export const useGetStageProcoreMapSetup = () => {
  const [getStageProcoreMapSetup, { data, loading }] = useLazyDocSyncQuery<StageProcoreMapSetup>(
    GET_STAGE_PROCORE_MAP_SETUP,
    { fetchPolicy: 'network-only' },
  );

  const getSetupOptions = async (projectId: string, companyId: string) => {
    const { data: lazyData } = await getStageProcoreMapSetup({
      variables: { projectId, companyId },
    });
    return {
      projectTools: lazyData?.stageProcoreMapSetup?.projectTools,
      projectPermissions: lazyData?.stageProcoreMapSetup?.projectPermissions,
      stageProcoreMapOptions: lazyData?.stageProcoreMapSetup?.stageProcoreMapOptions,
    };
  };

  return {
    getStageProcoreMapSetup: getSetupOptions,
    data,
    loading,
  };
};
