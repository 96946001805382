import { gql } from '@apollo/client';

export const PACKAGE_AWARDEE_CANDIDATES = gql`
  query GetAwardeesForPackage($packageId: EntityId!, $stageId: EntityId!) {
    package(id: $packageId) {
      activeRfqs {
        id
        quotingStatus
        contact {
          fullName
          email
          phone
          company {
            name
          }
        }
      }
    }
    stage(id: $stageId) {
      ... on UnrestrictedStage {
        id
        account {
          id
          name
        }
        contactName
        contactPhone
        name
      }
    }
  }
`;
