import { ReactElement } from 'react';
import { CoveragePopup } from './CoveragePopup';
import { Indicator, IndicatorStatus } from './Indicator';
import { QuoteCoverageStatistics } from './utils/calculateQuoteCoverageStatus';
import styles from './styles.scss';

const MAX_INDICATORS_TO_SHOW = 15;

type PackageQuoteCoverageStatistics = QuoteCoverageStatistics & { latestQuoteDueBy: Date };

type QuoteCoverageStatusProps = {
  keyPrefix: string;
  packageDueDate: Date;
  coverageStatus: PackageQuoteCoverageStatistics | null;
};

const renderIndicators = (
  key: string,
  displayedSoFar: number,
  howManyToDisplay: number,
  status: IndicatorStatus,
): ReactElement[] | null => {
  const howManyToActuallyDisplay =
    displayedSoFar + howManyToDisplay <= MAX_INDICATORS_TO_SHOW
      ? howManyToDisplay
      : MAX_INDICATORS_TO_SHOW - displayedSoFar;

  if (howManyToActuallyDisplay <= 0) return null;

  return Array(howManyToActuallyDisplay)
    .fill(1)
    .map((_value, index) => `${key}_${index}`)
    .map((value) => <Indicator key={value} status={status} />);
};

export const QuoteCoverageStatus = ({
  keyPrefix,
  packageDueDate,
  coverageStatus,
}: QuoteCoverageStatusProps) => {
  const coverage = coverageStatus ?? {
    quoted: 0,
    quoting: 0,
    notQuoting: 0,
    noResponse: 0,
    numberOfQuotes: 0,
    latestQuoteDueBy: new Date(0),
  };

  const { latestQuoteDueBy } = coverage;

  return (
    <CoveragePopup
      quoteCoverageBreakdown={{
        quoteCoverage: coverage,
        latestQuoteDueBy,
        packageDueDate,
      }}
    >
      <div className={styles.coverageStatus}>
        {renderIndicators(`${keyPrefix}_quoted`, 0, coverage.quoted, IndicatorStatus.Quoted)}
        {renderIndicators(
          `${keyPrefix}_quoting`,
          coverage.quoted,
          coverage.quoting,
          IndicatorStatus.Quoting,
        )}
        {renderIndicators(
          `${keyPrefix}_notquoting`,
          coverage.quoted + coverage.quoting,
          coverage.notQuoting,
          IndicatorStatus.NotQuoting,
        )}
        {renderIndicators(
          `${keyPrefix}_noresponse`,
          coverage.quoted + coverage.quoting + coverage.notQuoting,
          coverage.noResponse,
          IndicatorStatus.NoResponse,
        )}
        {coverage.numberOfQuotes > MAX_INDICATORS_TO_SHOW && (
          <div key={`${keyPrefix}_number`} className={styles.remainingQuoteCount}>
            +{coverage.numberOfQuotes - MAX_INDICATORS_TO_SHOW}
          </div>
        )}
      </div>
    </CoveragePopup>
  );
};
