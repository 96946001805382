import { TradeOption } from '@profiles/features/CompanyProfile/components/Trades/EditTrades/EditTradesForm/fields/Trades';

export enum EditCoreTradesFieldName {
  CoreTrades = 'CoreTrades',
}

export type EditCoreTradesFieldValues = {
  [EditCoreTradesFieldName.CoreTrades]: number[];
};

export enum EditTradesFieldName {
  Trades = 'Trades',
}

export type EditTradesFieldValues = {
  [EditTradesFieldName.Trades]: TradeOption[];
};
