import Routing from 'routing';
import E1Request from './E1Request';

/**
 * Utility function to refresh an existing Bootstrap notification
 * See dismissible_message.html.twig
 */
export default class Notification {
  private $alert: JQuery;

  /**
   * @param $container Bootstrap notification element
   * @param route Endpoint for the Notification data
   */
  constructor(
    private $container: JQuery,
    private readonly route?: string,
  ) {
    this.$alert = this.getInnerNotification();
    this.route = this.$container.data('update-uri') || route;

    if (!this.route) {
      throw new Error('Cannot initialise Notification: route not defined');
    }
  }

  public async refresh() {
    this.invalidate();

    if (!this.route) {
      throw new Error('Cannot refresh notifications: route not defined');
    }

    const response = await new E1Request<{ success: boolean; notification?: string }>(
      Routing.generate(this.route),
    ).submit();
    if (response.notification) {
      this.$container.empty().html(response.notification);
      this.$alert = this.getInnerNotification();
    }
  }

  public invalidate(): Notification {
    this.$alert.addClass('data-invalid');

    return this;
  }

  private getInnerNotification() {
    return this.$container.children('.alert-dismissible').first();
  }
}
