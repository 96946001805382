import { useCallback } from 'react';
import { FormSpy } from 'react-final-form';
import { FormState } from 'final-form';
import {
  allSubbieNetworkFilterFormFields,
  SubbieNetworkFilterFormValues,
  SubbieNetworkFilterFormFields,
} from '@builder/common/SubbieNetwork/utils/filters/types';

export const SubbieNetworkFilterFormSpy = ({
  setSelectedFiltersCount,
}: {
  setSelectedFiltersCount: (count: number) => void;
}) => {
  const handleFormSpyUpdate = useCallback(
    ({
      values,
    }: FormState<SubbieNetworkFilterFormValues, Partial<SubbieNetworkFilterFormValues>>): void => {
      const selected = allSubbieNetworkFilterFormFields.filter((field) => {
        // These fields are counted with their corresponding partner fields, so we don't want to count them twice
        if (
          field === SubbieNetworkFilterFormFields.SearchArea ||
          field === SubbieNetworkFilterFormFields.Distance ||
          field === SubbieNetworkFilterFormFields.ContractSizeMin
        ) {
          return false;
        }

        // Count contract size based on either min or max being set
        if (field === SubbieNetworkFilterFormFields.ContractSizeMax) {
          return (
            Boolean(values[field]) || Boolean(values[SubbieNetworkFilterFormFields.ContractSizeMin])
          );
        }

        if (field === SubbieNetworkFilterFormFields.Location) {
          if (values[SubbieNetworkFilterFormFields.SearchArea] === 'office-location')
            return (
              values[SubbieNetworkFilterFormFields.Location] &&
              values[SubbieNetworkFilterFormFields.Distance]
            );

          if (values[SubbieNetworkFilterFormFields.SearchArea] === 'service-area') {
            return values[SubbieNetworkFilterFormFields.Location];
          }
        }

        return Boolean(values[field]);
      });
      setSelectedFiltersCount(selected.length);
    },
    [setSelectedFiltersCount],
  );

  return (
    <FormSpy<SubbieNetworkFilterFormValues>
      subscription={{ values: true }}
      onChange={handleFormSpyUpdate}
    />
  );
};
