import { SingleSelectField } from '@shared/FinalForm/Field/SingleSelectField';
import { WorkforceSizeEnum } from '@ascension/_gqltypes/builder.generated';
import { SubbieNetworkFilterFormFields } from '@builder/common/SubbieNetwork/utils/filters/types';
import { ValueLabelOption } from '@estimateone/frontend-components/src/components/Form/Select/types';

export const WorkforceSizeOpts: ValueLabelOption<WorkforceSizeEnum>[] = [
  { value: WorkforceSizeEnum.RANGE_0_5, label: '0 - 5' },
  { value: WorkforceSizeEnum.RANGE_6_20, label: '6 - 20' },
  { value: WorkforceSizeEnum.RANGE_21_50, label: '21 - 50' },
  { value: WorkforceSizeEnum.RANGE_51_250, label: '51 - 250' },
  { value: WorkforceSizeEnum.RANGE_250_PLUS, label: '250+' },
];

export const WorkforceSizeFilter = () => (
  <section data-testid="workforceSize">
    <SingleSelectField<ValueLabelOption<WorkforceSizeEnum>>
      id="workforceSize"
      label="Employees"
      inputId="workforceSize"
      placeholder="Select..."
      hideLabel
      fieldName={SubbieNetworkFilterFormFields.WorkforceFilterId}
      options={WorkforceSizeOpts}
      isClearable
    />
  </section>
);
