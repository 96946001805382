import { InterestLevel } from '@ascension/_gqltypes/subbie.generated';

export const getInterestLevelOrDefault = (
  watchlistInterestLevel: InterestLevel | undefined,
): InterestLevel => {
  if (watchlistInterestLevel === undefined) {
    return InterestLevel.UNACTIONED;
  }
  return watchlistInterestLevel;
};
