import { getBudgetOptions } from '../utils.budget';
import { DatePickerField } from '../../../../shared/FinalForm/Field';
import { SingleSelectField } from '@shared/FinalForm/Field/SingleSelectField';
import { TenderHandoverWizardFieldName } from '../enums';
import { Country } from '@ascension/enums/Country';
import { TenderHandoverWizardValues } from '../types';
import { StageBudget } from '@ascension/_gqltypes/builder.generated';
import styles from './styles.scss';

export type StageBudgetSelectOption = { value: StageBudget; label: string };

export const PhaseDetails = ({ accountCountry }: { accountCountry: Country | undefined }) => {
  const budgetSelectOptions = getBudgetOptions(accountCountry);

  return (
    <div>
      <section className={styles.phaseDetailsSection}>
        <h2>Further Details</h2>

        <div>
          <SingleSelectField<StageBudgetSelectOption>
            id="tender-handover-wizard--project-value"
            label="Approx Budget"
            aria-label="Approx Budget"
            fieldName={TenderHandoverWizardFieldName.ProjectValue}
            validate={(
              value: TenderHandoverWizardValues[TenderHandoverWizardFieldName.ProjectValue],
            ) => (!value ? ['Select an approximate project value range'] : undefined)}
            isRequired
            options={budgetSelectOptions}
          />
        </div>
      </section>
      <section className={styles.phaseDetailsSection}>
        <div>
          <DatePickerField
            id="tender-handover-wizard--start-date"
            label="Anticipated Start Date"
            fieldName={TenderHandoverWizardFieldName.StartDate}
          />
        </div>
        <div>
          <DatePickerField
            id="tender-handover-wizard--end-date"
            label="Anticipated End Date"
            fieldName={TenderHandoverWizardFieldName.EndDate}
          />
        </div>
      </section>
    </div>
  );
};
