import { useAtomValue, useSetAtom } from 'jotai';
import { syncCompanyModalContextAtom } from './atoms';
import { ModalTrigger, ConnectCompanyModalData } from './types';

export const useSyncableCompanyModalContext = () => useAtomValue(syncCompanyModalContextAtom);

export const useOpenSyncableCompanyModal = () => {
  const setModalContext = useSetAtom(syncCompanyModalContextAtom);

  const openModal = ({
    data,
    modalTriggeredBy,
  }: {
    data: ConnectCompanyModalData;
    modalTriggeredBy: ModalTrigger;
  }) => {
    setModalContext({ data, modalTriggeredBy, open: true });
  };
  return { openModal };
};

export const useCloseSyncableCompanyModal = () => {
  const setModalContext = useSetAtom(syncCompanyModalContextAtom);

  const closeModal = () => {
    setModalContext({
      data: null,
      modalTriggeredBy: null,
      open: false,
    });
  };
  return { closeModal };
};
