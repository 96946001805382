import { gql, useQuery } from '@apollo/client';
import {
  GetQuoteFileManagerLocale,
  GetQuoteFileManagerLocaleVariables,
} from './types/GetQuoteFileManagerLocale';
import {
  GetQuoteFileManagerLocaleForStage,
  GetQuoteFileManagerLocaleForStageVariables,
} from './types/GetQuoteFileManagerLocaleForStage';
import {
  GetStageFileManagerLocale,
  GetStageFileManagerLocaleVariables,
} from './types/GetStageFileManagerLocale';

const FM_DETAILS_QUOTE = gql`
  query GetQuoteFileManagerLocale($quoteId: EntityId!) {
    locale: fileManagerLocaleForQuote(quoteId: $quoteId)
  }
`;

const FM_DETAILS_STAGE = gql`
  query GetStageFileManagerLocale($stageId: EntityId!) {
    locale: fileManagerLocaleForStage(stageId: $stageId)
  }
`;

const QUOTES_FM_DETAILS_STAGE = gql`
  query GetQuoteFileManagerLocaleForStage($stageId: EntityId!) {
    locale: quoteFileManagerLocaleForStage(stageId: $stageId)
  }
`;
export const useQuoteLocale = (quoteId?: number) => {
  const { data } = useQuery<GetQuoteFileManagerLocale, GetQuoteFileManagerLocaleVariables>(
    FM_DETAILS_QUOTE,
    quoteId
      ? {
          variables: { quoteId },
        }
      : { skip: true },
  );

  return data?.locale;
};

export const useStageLocale = (stageId?: number) => {
  const { data } = useQuery<GetStageFileManagerLocale, GetStageFileManagerLocaleVariables>(
    FM_DETAILS_STAGE,
    stageId
      ? {
          variables: { stageId },
        }
      : { skip: true },
  );

  return data?.locale;
};

export const useStageQuoteLocale = (stageId?: number) => {
  const { data } = useQuery<
    GetQuoteFileManagerLocaleForStage,
    GetQuoteFileManagerLocaleForStageVariables
  >(QUOTES_FM_DETAILS_STAGE, stageId ? { variables: { stageId } } : { skip: true });

  return data?.locale;
};
