import $ from 'jquery';
import moment from 'moment-timezone';
import { getUserTimezone } from './timezone';

const jQuery = $;

moment.tz.setDefault(getUserTimezone());

$.fn.select2.defaults.set('theme', 'bootstrap');

// Define moment.js datetimes to support for datatables
$.fn.dataTable.moment('DD-MM-YYYY HH:mm');

$.fn.datepicker.defaults.autoclose = true;

$.extend(true, $.fn.dataTable.defaults, {
  paging: true,
  pageLength: 50,
  lengthChange: false,
  pagingType: 'bootstrap',
  autoWidth: false,
  info: false,
  search: {
    caseInsensitive: true,
  },
  dom: 'Rrtp',
});

$.magnificPopup.instance._onFocusIn = function (e) {
  // Since certain select2 elements are rendered outside of their element (and appended to the body instead),
  // magnificPopup blocks the focusing of the element, below is a slight hack to allow us to target these inputs.
  // this ruined my day.

  if ($(e.target).hasClass('select2-input') || $(e.target).hasClass('select2-search__field')) {
    return true;
  }
  $.magnificPopup.proto._onFocusIn.call(this, e);
};

jQuery.extend({
  highlight(node, re, nodeName, className) {
    if (node.nodeType === 3) {
      const match = node.data.match(re);
      if (match) {
        const highlight = document.createElement(nodeName || 'span');
        highlight.className = className || 'highlight';
        const wordNode = node.splitText(match.index);
        wordNode.splitText(match[0].length);
        const wordClone = wordNode.cloneNode(true);
        highlight.appendChild(wordClone);
        wordNode.parentNode.replaceChild(highlight, wordNode);
        return 1; // skip added node in parent
      }
    } else if (
      node.nodeType === 1 &&
      node.childNodes && // only element nodes that have children
      !/(script|style)/i.test(node.tagName) && // ignore script and style nodes
      !(node.tagName === nodeName.toUpperCase() && node.className === className)
    ) {
      // skip if already highlighted
      for (let i = 0; i < node.childNodes.length; i++) {
        i += jQuery.highlight(node.childNodes[i], re, nodeName, className);
      }
    }
    return 0;
  },
});

jQuery.fn.unhighlight = function (options) {
  const settings = { className: 'highlight', element: 'span' };
  jQuery.extend(settings, options);

  return this.find(`${settings.element}.${settings.className}`)
    .each(function () {
      const parent = this.parentNode;
      parent.replaceChild(this.firstChild, this);
      parent.normalize();
    })
    .end();
};

jQuery.fn.highlight = function (words, options) {
  const settings = {
    className: 'highlight',
    element: 'span',
    caseSensitive: false,
    wordsOnly: false,
  };
  jQuery.extend(settings, options);

  if (words.constructor === String) {
    words = [words];
  }

  words = jQuery.grep(words, (word, i) => word != '');
  words = jQuery.map(words, (word, i) => word.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'));
  if (words.length == 0) {
    return this;
  }

  const flag = settings.caseSensitive ? '' : 'i';
  let pattern = `(${words.join('|')})`;
  if (settings.wordsOnly) {
    pattern = `\\b${pattern}\\b`;
  }
  const re = new RegExp(pattern, flag);

  return this.each(function () {
    jQuery.highlight(this, re, settings.element, settings.className);
  });
};
