import { ReactNode } from 'react';
import { capitalise, joinClassNames, Tooltip } from '@estimateone/frontend-components';
import { BillingFrequency } from './PlanDetailsSection';
import styles from '@ascension/components/account-upgrade/modal/styles.scss';

const CalculationTooltipContent = ({ currencySymbol }: { currencySymbol: string }) => (
  <div style={{ textAlign: 'left' }}>
    <span>
      The pro-rata discount is the proportion of time left on the current period, multiplied by the
      current plan amount.
    </span>
    <br />
    <br />
    <span>
      e.g. <strong>Current plan amount</strong> ({currencySymbol}90) divided by{' '}
      <strong>Regular Term</strong> (30 days) multiplied by <strong>Prorated Term</strong> (8 days)
      = <strong>Pro Rata Discount</strong> (-{currencySymbol}24)
    </span>
  </div>
);

const CalculationTooltip = ({
  currencySymbol,
  children,
}: {
  currencySymbol: string;
  children: ReactNode;
}) => (
  <Tooltip
    place="left"
    text={<CalculationTooltipContent currencySymbol={currencySymbol} />}
    tooltipId="pro-rata-calculation-tooltip"
  >
    {children}
  </Tooltip>
);

export const LiteToEssentialCostSummaryTable = ({
  billingFrequency,
  ongoingCost,
  proRataDiscount,
  totalDue,
  currencySymbol = '$',
}: {
  billingFrequency: BillingFrequency;
  ongoingCost: number;
  proRataDiscount: number;
  totalDue: number;
  currencySymbol?: string;
}) => (
  <div className={styles.summaryTableContainer}>
    <table className={styles.summaryTable}>
      <tbody>
        <tr className={styles.chosenSubscriptionRow}>
          <td>{capitalise(billingFrequency)} Subscription</td>
          <td>
            {currencySymbol}
            {ongoingCost.toFixed(2)}
          </td>
        </tr>
        <tr>
          <td colSpan={2} className={styles.breakdownTableContainer}>
            <table className={styles.breakdownTable}>
              <tbody>
                <tr>
                  <td>{capitalise(billingFrequency)} subscription ex. GST</td>
                  <td>
                    {currencySymbol}
                    {ongoingCost.toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td>Pro Rata Discount</td>
                  <td>
                    -{currencySymbol}
                    {proRataDiscount.toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td>Total Due Today incl. GST</td>
                  <td>
                    {currencySymbol}
                    {totalDue.toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr className={styles.totalRow}>
          <td>Total Due Today</td>
          <td>
            {currencySymbol}
            {totalDue.toFixed(2)}
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <div className={styles.tooltipAndInfoCtaContainer}>
              <CalculationTooltip currencySymbol={currencySymbol}>
                <span className={joinClassNames('small', 'muted', styles.calculationInfo)}>
                  How is this calculated?
                </span>
              </CalculationTooltip>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);
