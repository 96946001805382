import { ReactNode, ComponentProps } from 'react';
import { Col, joinClassNames, Row, Tag, TagVariant } from '@estimateone/frontend-components';
import { SubbieNetworkConnectionIndicator } from '@builder/common/SubbieNetwork/SubbieNetworkConnectionIndicator';
import { SubbieTradeTags } from '@builder/features/SubbieNetwork/components/SubbieNetworkTradeTagList';
import {
  CompanyLogo,
  CompanyLogoSize,
} from '@builder/pages/Profiles/UniversalProfileDrawer/components/CompanyLogo';
import { getLabelFromProfilesContractSizes } from '@builder/pages/Profiles/UniversalProfileDrawer/mappers/contractSizeMapper';
import { isConnectedCompany } from '../../utils';
import { CompanyQualifications } from '@shared/Profiles/CompanyQualifications';
import { NetworkCompany } from '@builder/features/SubbieNetwork/components/SubbieNetworkSearchResults/hooks/utils';
import { Country } from '@ascension/enums';
import { ContractSizeEnum } from '@ascension/_gqltypes/profiles.generated';
import styles from './styles.module.scss';

type SubbieNetworkSearchResultsContractSizeProps = {
  contractSizeMin: ContractSizeEnum | null;
  contractSizeMax: ContractSizeEnum | null;
};

const SubbieNetworkSearchResultsContractSize = ({
  contractSizeMin,
  contractSizeMax,
}: SubbieNetworkSearchResultsContractSizeProps) => {
  const tagText = `Contract Size: ${getLabelFromProfilesContractSizes(contractSizeMin, contractSizeMax)}`;
  return <Tag variant={TagVariant.Practical025} text={tagText} />;
};

const CompanyListItem = ({
  company,
  ...props
}: { company: NetworkCompany } & ComponentProps<'li'>) => {
  switch (company.__typename) {
    case 'BuilderNetworkCompany':
    case 'ConnectedE1NetworkCompany':
    case 'ConnectedBuilderNetworkCompany':
      return <li data-company-id={company.id} {...props} />;
    case 'E1NetworkCompany':
    case 'ShadowNetworkCompany':
    default:
      return <li data-profile-id={company.id} {...props} />;
  }
};

const getLocationDisplay = ({ address }: NetworkCompany, isAustralianBuilder: boolean): string => {
  if (!address) {
    return '';
  }

  if (isAustralianBuilder && address.country?.id === Country.COUNTRY_AUSTRALIA) {
    return `${address.suburb ?? ''} ${address.state?.shortName ?? ''}`;
  }

  if (address.city && address.province) {
    return `${address.city}, ${address.province}`;
  }

  return `${address.city ?? ''} ${address.province ?? ''}`;
};

export type ProfileSnapshotProps = {
  company: NetworkCompany;
  isAustralianBuilder: boolean;
  children: ReactNode;
};

const ProfileSnapshot = ({ company, isAustralianBuilder, children }: ProfileSnapshotProps) => {
  const hasCompanyLogo = company.__typename === 'E1NetworkCompany' || isConnectedCompany(company);

  return (
    <CompanyListItem
      className={joinClassNames(styles.resultListItem, 'vcard')}
      company={company}
      tabIndex={0}
    >
      <Row>
        {hasCompanyLogo ? (
          <Col alignContentY="top" alignContentX="left">
            <CompanyLogo
              companyName={company.name}
              url={company.logoUrl}
              size={CompanyLogoSize.SMALL}
            />
          </Col>
        ) : (
          /* the Row component is unnecessarily fussy and forces us to use a fragment here */
          /* eslint-disable-next-line react/jsx-no-useless-fragment */
          <></>
        )}
        <Col span="auto" excludeBottomGutter>
          <Row>
            <Col span={10} alignContentY="center" alignContentX="left" excludeBottomGutter>
              <Row>
                <Col span="auto" alignContentY="center" excludeBottomGutter>
                  <h3 className={styles.companyName}>{company.name}</h3>
                </Col>
                <Col
                  span="auto"
                  alignContentY="center"
                  className={styles.statusIconColumn}
                  excludeBottomGutter
                >
                  {company.__typename !== 'BuilderNetworkCompany' && (
                    <SubbieNetworkConnectionIndicator connected={isConnectedCompany(company)} />
                  )}
                </Col>
                <Col span="auto" alignContentY="center" excludeBottomGutter>
                  <CompanyQualifications qualifications={company.qualifications} />
                </Col>
              </Row>
              <Row>
                <Col span="auto" alignContentY="center">
                  <span className={styles.location}>
                    {getLocationDisplay(company, isAustralianBuilder)}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col span="auto" excludeBottomGutter>
                  <SubbieTradeTags
                    stockTrades={company.stockTrades}
                    coreTrades={company.coreStockTrades}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={2} alignContentY="bottom" alignContentX="right" excludeBottomGutter>
              <Col span="auto" alignContentX="center" excludeBottomGutter>
                {children}
              </Col>
              {company.contractSizeMin === null && company.contractSizeMax === null ? null : (
                <SubbieNetworkSearchResultsContractSize
                  contractSizeMin={company.contractSizeMin}
                  contractSizeMax={company.contractSizeMax}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </CompanyListItem>
  );
};

export { CompanyListItem, ProfileSnapshot };
