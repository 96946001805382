import {
  ActionMenu,
  Button,
  ButtonSize,
  ButtonVariant,
  Ellipsis,
  IconButton,
} from '@estimateone/frontend-components';
import { InviteToQuoteAddressBookMenuButton } from '@builder/features/InviteToQuote/InviteToQuoteAddressBookMenuButton';
import { SubbieNetworkInviteToQuoteButton } from '@builder/features/SubbieNetwork/components/SubbieNetworkInviteToQuoteButton';
import { useOpenRemoveNetworkConnectionModal } from '@builder/features/RemoveNetworkConnection/hooks';
import {
  BuilderNetworkCompanyFragment,
  ConnectedBuilderNetworkCompanyFragment,
  ConnectedE1NetworkCompanyFragment,
  RedactedConnectedBuilderNetworkCompanyFragment,
  RedactedConnectedE1NetworkCompanyFragment,
} from '../../providers/UniversalProfileProvider/queries.generated';
import { IconButtonSize } from '@estimateone/frontend-components/src/components/IconButton/enums';
import { NetworkCompanyState } from '@ascension/_gqltypes/builder.generated';
import styles from './styles.scss';

// Note: there are a lot of buttons in here that do not do anything in React land, but their
// click events bubble up and are captured by handlers in vcard_slider.js.
// It was done this way in the interest of leveraging existing code to get the buttons up and running ASAP.
// The aim is to slowly replace the logic in vcard_slider with handlers in React.
// Important: the time jQuery event handlers are attached doesn't matter as they are "delegated" event
// handlers and don't have to be attached after the button is created.
export const AddressBookCompanyToolbar = ({
  networkCompany,
  hasProcurementPhaseFeature,
}: {
  networkCompany:
    | ConnectedBuilderNetworkCompanyFragment
    | ConnectedE1NetworkCompanyFragment
    | BuilderNetworkCompanyFragment
    | RedactedConnectedBuilderNetworkCompanyFragment
    | RedactedConnectedE1NetworkCompanyFragment;
  hasProcurementPhaseFeature: boolean;
}) => {
  const { openModal: openRemoveNetworkConnectionModal } = useOpenRemoveNetworkConnectionModal();

  if (networkCompany.state === NetworkCompanyState.AWAITING_APPROVAL) {
    return (
      <div>
        <Button
          className="approve-company-trigger"
          variant={ButtonVariant.Secondary}
          size={ButtonSize.Small}
          data-company-id={networkCompany.id}
          data-status="1"
          // Click event captured in vcard_slider.js by hooking into the .approve-company-trigger class
          onClick={() => {}}
        >
          Approve
        </Button>
      </div>
    );
  }

  if (networkCompany.state === NetworkCompanyState.ARCHIVED) {
    return (
      <Button
        className="undelete-company"
        variant={ButtonVariant.Secondary}
        size={ButtonSize.Small}
        data-company-id={networkCompany.id}
        data-status="1"
        // Click event captured in vcard_slider.js by hooking into the .undelete-company class
        onClick={() => {}}
      >
        Unarchive
      </Button>
    );
  }

  return (
    <div className={styles.drawerTopBarActionButtons}>
      <ActionMenu
        placement="bottom-start"
        triggerElement={
          <IconButton
            className={styles.moreActions}
            icon={<Ellipsis />}
            size={IconButtonSize.Small}
            title="universal-drawer-more-actions-menu"
            data-testid="more-action-menu"
          />
        }
      >
        {networkCompany.__typename === 'ConnectedBuilderNetworkCompany' && (
          <ActionMenu.Item
            data-company-id={networkCompany.id}
            onClick={() => {
              openRemoveNetworkConnectionModal({
                data: {
                  addressbookCompanyId: Number(networkCompany.id),
                },
              });
            }}
          >
            Remove Connection
          </ActionMenu.Item>
        )}
        <ActionMenu.Item
          className="delete-company"
          data-testid="action-menu-delete-company"
          data-company-id={networkCompany.id}
          data-status="1"
          // Click event captured in vcard_slider.js by hooking into the .delete-company class
          onClick={() => {}}
        >
          Archive Company
        </ActionMenu.Item>
      </ActionMenu>
      {hasProcurementPhaseFeature ? (
        <div data-testid="procurement-phase-container">
          <InviteToQuoteAddressBookMenuButton
            preselectedCompanyId={Number(networkCompany.id)}
            size={ButtonSize.Small}
          />
        </div>
      ) : (
        <SubbieNetworkInviteToQuoteButton companyId={networkCompany.id} />
      )}
    </div>
  );
};
