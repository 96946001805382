import { useState } from 'react';
import { Button, ButtonSize, ButtonVariant } from '@estimateone/frontend-components';
import { AccountUpgradeModal } from '@ascension/components/account-upgrade/modal';
import { FontStyle, Heading } from '@ascension/components/fec';
import { FooterSection } from './FooterSection';
import { NoTendersGraphic } from './NoTendersGraphic';
import { TradesSection } from '../../shared/RoadblockCreditExperiments/TradesSection';
import { useCanUpgradeLiteToEssential } from '@ascension/components/account-upgrade/modal/hooks/useCanUpgradeLiteToEssential';
import styles from './styles.module.scss';

type NoTendersViewProps = {
  projectId: number;
  upgradeUrl: string;
  creditsRenewalDate: string;
};

type CreditsUpgradeButtonProps = {
  upgradeUrl: string;
  upgradeLabel: string;
};

const CreditsUpgradeButton = ({ upgradeUrl, upgradeLabel }: CreditsUpgradeButtonProps) => {
  const canAccessLiteToEssentialUpgradeFlow = useCanUpgradeLiteToEssential();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleLaunchModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return canAccessLiteToEssentialUpgradeFlow ? (
    <>
      <Button
        type="button"
        variant={ButtonVariant.Electric}
        fullWidth
        onClick={handleLaunchModal}
        size={ButtonSize.Medium}
      >
        {upgradeLabel}
      </Button>
      <AccountUpgradeModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </>
  ) : (
    <Button
      type="button"
      variant={ButtonVariant.Electric}
      fullWidth
      href={upgradeUrl}
      size={ButtonSize.Medium}
    >
      {upgradeLabel}
    </Button>
  );
};

export const NoTendersView = ({
  projectId,
  upgradeUrl,
  creditsRenewalDate,
}: NoTendersViewProps) => (
  <>
    <NoTendersGraphic />
    <Heading level={1} renderAs={FontStyle.h2}>
      You have no more credits this month
    </Heading>
    <TradesSection projectId={projectId} isOutOfUnlocks />
    <div className={styles.buttonContainer}>
      <CreditsUpgradeButton
        upgradeUrl={upgradeUrl}
        upgradeLabel="Upgrade for unlimited project access"
      />
    </div>
    <FooterSection upgradeUrl={upgradeUrl} creditsRenewalDate={creditsRenewalDate} />
  </>
);
