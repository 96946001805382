import { EntityId } from '@ascension/types';

export const RemoveConnectionFormId = 'RemoveConnectionFormId';

export enum DisconnectedReasonFieldName {
  OTHER = 'OTHER',
  MATCH_INCORRECT = 'MATCH_INCORRECT',
  ACCIDENTAL = 'ACCIDENTAL',
  NO_USER_VALUE = 'NO_USER_VALUE',
}

export enum RemoveNetworkConnectionFormFields {
  Reason = 'reason',
  OtherExplanation = 'otherExplanation',
}

export type RemoveNetworkConnectionFormValues = {
  [RemoveNetworkConnectionFormFields.Reason]: DisconnectedReasonFieldName;
  [RemoveNetworkConnectionFormFields.OtherExplanation]?: string;
};

export const reasonOptionsMap = [
  {
    id: DisconnectedReasonFieldName.MATCH_INCORRECT,
    label: 'Match was incorrect',
  },
  {
    id: DisconnectedReasonFieldName.NO_USER_VALUE,
    label: "Information doesn't add value",
  },
  {
    id: DisconnectedReasonFieldName.ACCIDENTAL,
    label: 'Accidentally connected',
  },
  {
    id: DisconnectedReasonFieldName.OTHER,
    label: 'Other',
  },
];

// error message expects type
export type RemoveNetworkConnectionFormErrors = {
  [key in keyof RemoveNetworkConnectionFormValues]?: string[];
};

export type RemoveNetworkConnectionModalContext =
  | {
      data: null;
      open: false;
    }
  | {
      data: { addressbookCompanyId: EntityId };
      open: true;
    };
