import { useQuery } from '@apollo/client';
import { useCoreTradeSubmitForm } from '@profiles/features/CompanyProfile/components/Trades/hooks/useCoreTradeSubmitForm';
import { useEditTradeSubmitForm } from '@profiles/features/CompanyProfile/components/Trades/hooks/useEditTradeSubmitForm';
import { GET_ALL_STOCK_TRADES } from '@profiles/features/CompanyProfile/components/Trades/queries';
import { SubbieStockTradesQuery } from '@profiles/features/CompanyProfile/components/Trades/queries.generated';
import { UpdateTradesMutation } from '@profiles/features/CompanyProfile/components/Trades/mutations.generated';
import {
  EditCoreTradesFieldValues,
  EditTradesFieldValues,
} from '@profiles/features/CompanyProfile/components/Trades/types';
import { ProfileStockTrade } from '@profiles/providers/ProfileProvider/types';

export type TradeFormServiceType = {
  submitTradeForm: (values: EditTradesFieldValues) => Promise<{
    hasErrors: boolean;
    updatedStockTrades: UpdateTradesMutation['changeStockTrades'][number][];
  }>;
  tradeFormInitError: boolean;
  submitCoreTradeForm: (values: EditCoreTradesFieldValues) => Promise<{
    hasErrors: boolean;
    updatedCoreTrades: ProfileStockTrade[];
  }>;
  coreTradeFormInitError: boolean;
  getAllStockTrades: () => {
    loading: boolean;
    data: SubbieStockTradesQuery['stockTrades'][number][];
    hasError: boolean;
  };
};

export const useTradeFormService = (
  skipGetAllTradesQuery: boolean = false,
): TradeFormServiceType => {
  const { submit: submitTradeFormHook, error: tradeFormError } = useEditTradeSubmitForm();
  const { submit: submitCoreTradeFormHook, error: coreTradeFormError } = useCoreTradeSubmitForm();
  const {
    loading: getAllStockTradesLoading,
    data: getAllStockTradesData,
    error: getAllStockTradesQueryError,
  } = useQuery<SubbieStockTradesQuery>(GET_ALL_STOCK_TRADES, { skip: skipGetAllTradesQuery });

  const tradeFormInitError = tradeFormError !== undefined;
  const coreTradeFormInitError = coreTradeFormError !== undefined;

  const getAllStockTrades = () => ({
    loading: getAllStockTradesLoading,
    data: getAllStockTradesData?.stockTrades ?? [],
    hasError: getAllStockTradesQueryError !== undefined,
  });

  const submitTradeForm = async (values: EditTradesFieldValues) => {
    const { errors, data } = await submitTradeFormHook(values);
    return {
      hasErrors: errors !== null,
      updatedStockTrades: data?.changeStockTrades ?? [],
    };
  };

  const submitCoreTradeForm = async (values: EditCoreTradesFieldValues) => {
    const { errors, data } = await submitCoreTradeFormHook(values);
    return {
      hasErrors: data?.updateCoreTrades.__typename !== 'Profile' || errors !== null,
      updatedCoreTrades:
        data?.updateCoreTrades.__typename === 'Profile' && data?.updateCoreTrades.trades
          ? data.updateCoreTrades.trades
          : [],
    };
  };

  return {
    submitTradeForm,
    tradeFormInitError,
    submitCoreTradeForm,
    coreTradeFormInitError,
    getAllStockTrades,
  };
};
