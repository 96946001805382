import { PropsWithChildren } from 'react';
import { useQuery } from '@apollo/client';
import { OperatingCountryProvider } from '@ascension/components/localisation/OperatingCountryProvider';
import { OperatingCountry } from '@ascension/components/localisation/operatingCountry';
import { GET_PRIMARY_COUNTRY } from '@profiles/features/CompanyProfile/views/ProfileSettings/queries';
import { GetCurrentAccountCountryQuery } from '@profiles/features/CompanyProfile/views/ProfileSettings/queries.generated';

export const DefaultOperatingCountryProvider = ({ children }: PropsWithChildren) => {
  const { data } = useQuery<GetCurrentAccountCountryQuery>(GET_PRIMARY_COUNTRY);

  return data ? (
    <OperatingCountryProvider
      countryId={data.currentUser?.account?.primaryCountry?.id ?? OperatingCountry.AU}
    >
      {children}
    </OperatingCountryProvider>
  ) : null;
};
