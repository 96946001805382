import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { FORM_ERROR } from 'final-form';
import moment from 'moment';
import { NotifyOptionType } from './modals/formSections/NotifyOptionsAwarded';
import { FieldError } from '@shared/FinalForm/types';
import { Action, useInteractAnalytics } from '../../../hooks/Analytics';
import { ButtonName } from '@ascension/components/hooks/Analytics/buttonNames';
import { UPDATE_AWARDED_STAGE_STATUS, UPDATE_OTHER_STAGE_STATUS } from './queries';
import { UpdateStageStatusFieldName } from './enums';
import { StageStatus } from '@ascension/enums';
import {
  UpdateStageStatusFieldErrors,
  UpdateAwardedStatusFormValues,
  UpdateOtherStatusFormValues,
  UpdateStageStatusResponseData,
} from './types';
import {
  UpdateAwardedStageStatus,
  UpdateAwardedStageStatusVariables,
} from './types/UpdateAwardedStageStatus';
import {
  UpdateOtherStageStatus,
  UpdateOtherStageStatusVariables,
} from './types/UpdateOtherStageStatus';
import { ApiError } from '@api/fragments/Errors/types';
import {
  UpdateAwardedStageStatusInput,
  UpdateOtherStageStatusInput,
} from '@ascension/_gqltypes/builder.generated';
import { EntityId } from '@ascension/types';

// Transforms the form values for updating non-awarded status form. See getUpdateAwardedStatusValueTransformer for the version for updating the awarded status.
const getUpdateOtherStatusValueTransformer =
  (stageId: EntityId, status: StageStatus) =>
  (values: UpdateOtherStatusFormValues): UpdateOtherStageStatusInput => ({
    id: stageId,
    status,
    notifyInvited: values[UpdateStageStatusFieldName.NotifyInvited],
  });

// TODO: Need to abstract API error transformers into a common file.
//  See /assets/src/components/shared/Quotes/Form/transformers.ts
const transformApiErrors = (errors: ApiError[]): UpdateStageStatusFieldErrors =>
  errors.reduce(
    (allErrors, { field, message }) => {
      const fieldName = Object.values(UpdateStageStatusFieldName).includes(
        field as UpdateStageStatusFieldName,
      )
        ? field
        : FORM_ERROR;

      return {
        ...allErrors,
        [fieldName ?? 'global']: [
          message,
          ...(allErrors[field as UpdateStageStatusFieldName] || []),
        ],
      };
    },
    {} as { [index in UpdateStageStatusFieldName]: FieldError[] },
  );

export const useUpdateOtherStatus = (
  stageId: EntityId,
  status: StageStatus,
  prevStatus: StageStatus,
) => {
  const transformValues = getUpdateOtherStatusValueTransformer(stageId, status);
  const [responseData, setResponseData] = useState<Partial<UpdateStageStatusResponseData>>({
    stageId: undefined,
    notifyOption: undefined,
  });
  const { addEvent } = useInteractAnalytics();

  const [updateOtherStageStatus] = useMutation<
    UpdateOtherStageStatus,
    UpdateOtherStageStatusVariables
  >(UPDATE_OTHER_STAGE_STATUS);

  const submit = async (values: UpdateOtherStatusFormValues) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    addEvent({
      action: Action.TENDER_UPDATE_STATUS_MODAL_SUBMIT,
      stageId,
      newStatus: status,
      prevStatus,
      notifyInvited: values[UpdateStageStatusFieldName.NotifyInvited],
    });

    const { data: response } = await updateOtherStageStatus({
      variables: {
        input: transformValues(values),
      },
    });

    if (response?.updateOtherStageStatus.__typename === 'Errors') {
      return transformApiErrors(response.updateOtherStageStatus.errors);
    }

    const notifyOption = values[UpdateStageStatusFieldName.NotifyInvited]
      ? NotifyOptionType.ALL
      : NotifyOptionType.NONE;
    setResponseData({ stageId, notifyOption });

    return undefined;
  };

  return { submit, responseData };
};

const getUpdateAwardedStatusValueTransformer =
  (stageId: EntityId) =>
  (values: UpdateAwardedStatusFormValues): UpdateAwardedStageStatusInput => ({
    id: stageId,
    awardedAt: moment(values[UpdateStageStatusFieldName.AwardedAt]).format(),
    notifyOption: values[UpdateStageStatusFieldName.NotifyOption],
    constructionStartDate: values[UpdateStageStatusFieldName.ProjectStart]
      ? moment(values[UpdateStageStatusFieldName.ProjectStart]).format()
      : null,
    constructionEndDate: values[UpdateStageStatusFieldName.ProjectCompletion]
      ? moment(values[UpdateStageStatusFieldName.ProjectCompletion]).format()
      : null,
  });

export const useUpdateAwardedStatus = (stageId: EntityId, prevStatus: StageStatus) => {
  const transformValues = getUpdateAwardedStatusValueTransformer(stageId);
  const [responseData, setResponseData] = useState<Partial<UpdateStageStatusResponseData>>({
    stageId: undefined,
    notifyOption: undefined,
  });
  const { addEvent } = useInteractAnalytics();

  const [updateAwardedStageStatus] = useMutation<
    UpdateAwardedStageStatus,
    UpdateAwardedStageStatusVariables
  >(UPDATE_AWARDED_STAGE_STATUS);

  const submit = async (values: UpdateAwardedStatusFormValues) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    addEvent({
      action: Action.TENDER_UPDATE_STATUS_MODAL_SUBMIT,
      stageId,
      newStatus: StageStatus.TENDER_AWARDED,
      prevStatus,
      notifyInvited: values[UpdateStageStatusFieldName.NotifyInvited],
    });

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    addEvent({
      action: Action.BUTTON_CLICKED,
      buttonName: ButtonName.MARK_PROJECT_AWARDED,
      notificationOption: values.notifyOption,
    });

    const { data: response } = await updateAwardedStageStatus({
      variables: {
        input: transformValues(values),
      },
    });

    if (response?.updateAwardedStageStatus.__typename === 'Errors') {
      return transformApiErrors(response.updateAwardedStageStatus.errors);
    }

    setResponseData({
      stageId,
      notifyOption: values[UpdateStageStatusFieldName.NotifyOption],
    });

    return undefined;
  };

  return { submit, responseData };
};
