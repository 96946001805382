import { gql } from '@apollo/client';

export const SET_NOTIFICATIONS_LIST_LAST_OPENED = gql`
  mutation SetNotificationsListLastOpened {
    setNotificationsListLastOpened
  }
`;

export const MARK_NOTIFICATIONS_AS_VIEWED = gql`
  mutation MarkNotificationsAsViewed($input: MarkNotificationsAsViewedInput!) {
    markNotificationsAsViewed(input: $input) {
      id
    }
  }
`;
