import { Form } from 'react-final-form';
import { RadioButton, TextLink } from '@estimateone/frontend-components';
import { useCountryTermTranslator } from '@ascension/components/localisation';
import { OperatingCountry } from '@ascension/components/localisation/operatingCountry';
import styles from './CompanyProfileForm.module.scss';
import { RadioButtonGroupField } from '@shared/FinalForm/Field/RadioButtonGroupField';
import { TextInputField } from '@shared/FinalForm/Field/TextInputField';
import { transformFormApiErrors } from '@shared/FinalForm/transformers';
import { AddressSearchFields } from '@shared/form/AddressSearchFields';
import { FormServerError } from '@shared/form/FormServerError';
import { PrimaryContactSingleSelect } from '@shared/form/PrimaryContactSingleSelect';
import { useSubmitProfileDetailsForm } from './hooks';
import { BusinessEntityType } from '@ascension/_gqltypes/subbie.generated';
import { EntityId } from '@ascension/types';
import {
  CompanyProfileFieldLabels,
  CompanyProfileFieldNames,
  CompanyProfileFieldValues,
} from '@profiles/features/BuildProfile/CompanyProfileForm/types';

type OfficeAddress = {
  address1?: string | null;
  address2?: string | null;
  address3?: string | null;
  postcode?: string | null;
  shortAddress?: string | null;
  district?: string | null;
  province?: string | null;
  suburb?: string | null;
  region?: string | null;
  city?: string | null;
  state?: { id: EntityId; shortName: string } | null;
};

type Person = {
  id: number;
  isDefaultContact: boolean;
  email: string;
  fullName: string;
  firstName: string;
  lastName?: string | null;
};

type CompanyProfileFormProps = {
  formId: string;
  countryId: number;
  defaultOfficeAddress: OfficeAddress | null;
  companyNumber: string | null;
  companyName: string | null;
  businessEntityType: BusinessEntityType | null;
  people: Person[];
  onSuccess: () => void;
  showPrimaryContactSelect?: boolean;
};

export const CompanyProfileForm = ({
  formId,
  onSuccess,
  countryId,
  companyName,
  companyNumber,
  defaultOfficeAddress,
  businessEntityType,
  people,
  showPrimaryContactSelect = false,
}: CompanyProfileFormProps) => {
  const { submit: submitForm, error } = useSubmitProfileDetailsForm();
  const translate = useCountryTermTranslator();
  const hasSoleTraderOption =
    countryId === OperatingCountry.UK || countryId === OperatingCountry.IE;
  const streetAddress = [
    defaultOfficeAddress?.address1,
    defaultOfficeAddress?.address2,
    defaultOfficeAddress?.address3,
  ]
    .filter(Boolean)
    .join(', ');

  const onSubmit = async (values: CompanyProfileFieldValues) => {
    const { data, errors: submitErrors } = await submitForm(values);

    if (submitErrors) {
      return {};
    }

    if (data?.updateProfileDetails && data.updateProfileDetails.__typename === 'Errors') {
      const { errors } = data.updateProfileDetails;
      return transformFormApiErrors(
        {
          name: CompanyProfileFieldNames.CompanyName,
          companyNumber: CompanyProfileFieldNames.CompanyNumber,
          businessEntity: CompanyProfileFieldNames.BusinessEntity,
          address1: 'streetAddress',
          suburb: 'suburb',
          state: 'state',
          city: 'city',
          province: 'province',
          postcode: 'postcode',
          primaryContact: 'primaryContact',
        } satisfies Record<string, keyof CompanyProfileFieldValues>,
        errors,
      );
    }

    return onSuccess();
  };

  const businessEntityTypeInitialValue = hasSoleTraderOption
    ? businessEntityType || BusinessEntityType.REGISTERED_BUSINESS
    : null;

  const state = defaultOfficeAddress?.state;

  const stateInitialValue = state
    ? {
        value: state.id ?? null,
        label: state.shortName ?? null,
      }
    : null;

  const sortedPeople = people.sort((a, b) => a.id - b.id);
  const primaryContact =
    sortedPeople.find(({ isDefaultContact }) => isDefaultContact) ?? sortedPeople[0];

  const initialValues: CompanyProfileFieldValues = {
    [CompanyProfileFieldNames.CompanyName]: companyName ?? '',
    [CompanyProfileFieldNames.CompanyNumber]: companyNumber ?? undefined,
    [CompanyProfileFieldNames.BusinessEntity]: businessEntityTypeInitialValue ?? undefined,
    primaryContact: {
      // label is useless here but react-select demands it
      label: '',
      value: primaryContact,
    },
    streetAddress: streetAddress ?? '',
    suburb: defaultOfficeAddress?.suburb ?? '',
    state: stateInitialValue,
    city: defaultOfficeAddress?.city ?? '',
    province: defaultOfficeAddress?.province ?? '',
    postcode: defaultOfficeAddress?.postcode ?? '',
  };

  return (
    <Form<CompanyProfileFieldValues>
      onSubmit={onSubmit}
      initialValues={initialValues}
      keepDirtyOnReinitialize
    >
      {({ handleSubmit, values }) => {
        const isSoleTrader =
          hasSoleTraderOption && values.businessEntity === BusinessEntityType.SOLE_TRADER;
        return (
          <form id={formId} onSubmit={handleSubmit}>
            {error && <FormServerError />}
            <div className={styles.formContainer}>
              <div>
                <TextInputField
                  id={CompanyProfileFieldNames.CompanyName}
                  label={CompanyProfileFieldLabels.CompanyName}
                  fieldName={CompanyProfileFieldNames.CompanyName}
                  isRequired
                />
              </div>
              <AddressSearchFields formId={formId} countryId={countryId} />
              {showPrimaryContactSelect && <PrimaryContactSingleSelect people={people} />}
              {hasSoleTraderOption && (
                <div>
                  <RadioButtonGroupField
                    legend="Business Structure"
                    fieldName={CompanyProfileFieldNames.BusinessEntity}
                    isRequired
                  >
                    <RadioButton
                      id="business-type-registered"
                      value={BusinessEntityType.REGISTERED_BUSINESS}
                    >
                      Registered Business
                    </RadioButton>
                    <RadioButton
                      id="business-type-sole-trader"
                      value={BusinessEntityType.SOLE_TRADER}
                    >
                      Sole Trader
                    </RadioButton>
                  </RadioButtonGroupField>
                </div>
              )}
              <div>
                <TextInputField
                  id={CompanyProfileFieldNames.CompanyNumber}
                  label={`Company ${translate('businessIdentifier')}`}
                  fieldName={CompanyProfileFieldNames.CompanyNumber}
                  isRequired={!isSoleTrader}
                />
                {translate('businessIdentifier') === 'ABN' && (
                  <span className={styles.abnText}>
                    {"Can't find your ABN? "}
                    <TextLink href="https://abr.business.gov.au/" external>
                      Look up ABN
                    </TextLink>
                  </span>
                )}
              </div>
            </div>
          </form>
        );
      }}
    </Form>
  );
};
