import { useRef } from 'react';
import { Action, useInteractAnalytics } from '@ascension/components/hooks/Analytics';
import {
  GetPackagesForLettingSchedule_activePackages as ActivePackages,
  GetPackagesForLettingSchedule_lettingScheduleCustomColumns as CustomColumn,
} from '../types/GetPackagesForLettingSchedule';
import { LettingScheduleCustomColumnIdentifier } from '@ascension/_gqltypes/builder.generated';

type LettingScheduleDetails = NonNullable<ActivePackages['lettingScheduleDetails']>;

export enum CustomColumLength {
  TITLE_MAX_LENGTH = 12,
  TITLE_MIN_LENGTH = 1,
  VALUE_MAX_LENGTH = 24,
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  VALUE_MIN_LENGTH = 1,
}

export const useTrackCustomColumValueMaxChar = () => {
  const { addEvent } = useInteractAnalytics();
  const maxValueCharTrackedRef = useRef(false);

  const trackCustomColumnValueMaxChar = (updatedValue: string) => {
    if (maxValueCharTrackedRef.current) {
      return;
    }

    if (updatedValue && updatedValue.length > CustomColumLength.VALUE_MAX_LENGTH) {
      maxValueCharTrackedRef.current = true;
      addEvent({
        action: Action.ERROR_GENERATED,
        errorSource: 'lettingSchedule_CustomColCell',
        errorType: 'maxCharExceeded',
      });
    }
  };

  return { trackCustomColumnValueMaxChar };
};

export const useTrackCustomColumTitleMaxChar = () => {
  const { addEvent } = useInteractAnalytics();
  const maxTitleCharTrackedRef = useRef(false);

  const trackCustomColumnTitleMaxChar = (updatedValue: string) => {
    if (maxTitleCharTrackedRef.current) {
      return;
    }

    if (updatedValue && updatedValue.length > CustomColumLength.TITLE_MAX_LENGTH) {
      maxTitleCharTrackedRef.current = true;
      addEvent({
        action: Action.ERROR_GENERATED,
        errorSource: 'lettingSchedule_CustomColTitle',
        errorType: 'maxCharExceeded',
      });
    }
  };

  return { trackCustomColumnTitleMaxChar };
};

export const getCustomColumnName = (
  columns: CustomColumn[],
  identifier: LettingScheduleCustomColumnIdentifier,
): string | null | undefined => {
  if (!columns) return undefined;
  return columns?.find((c) => c.identifier === identifier)?.name;
};

export const getCustomColumnValue = (
  lettingScheduleDetails: LettingScheduleDetails,
  identifier: LettingScheduleCustomColumnIdentifier,
): string | null | undefined => {
  const { customColumnValues } = lettingScheduleDetails;
  if (customColumnValues) {
    return customColumnValues.find((value) => value.column?.identifier === identifier)?.value;
  }

  return null;
};
