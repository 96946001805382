import { E1Link, Icon, IconName } from '@estimateone/frontend-components';
import { StatNumber } from './StatNumber';
import styles from './styles.scss';

const Divider = () => <div className={styles.statsPanelDivider} />;
const canDivide = (number: number) => number !== 0;
const toPercentString = (a: number, b: number) => {
  const percent = canDivide(b) ? Math.floor((a / b) * 100) : 0;
  return `${percent}%`;
};

export type InvitesResponsesStatsPanelProps = {
  contactInviteCount: number;
  contactInviteDocDownloadCount: number;
  companyInviteCount: number;
  quotedCount: number;
  responsesCount: number;
  totalRfqsCount: number;
};
export const InvitesResponsesStatsPanel = ({
  contactInviteCount,
  contactInviteDocDownloadCount,
  companyInviteCount,
  quotedCount,
  responsesCount,
  totalRfqsCount,
}: InvitesResponsesStatsPanelProps) => {
  const overallResponseRate = toPercentString(responsesCount, totalRfqsCount);
  const docDownloadRate = toPercentString(contactInviteDocDownloadCount, contactInviteCount);
  const quotedRate = toPercentString(quotedCount, totalRfqsCount);
  return (
    <>
      <div className={styles.statsPanelContainer}>
        <StatNumber
          value={contactInviteCount.toString()}
          label="Invites sent"
          tooltipText="All contacts invited to quote for all packages"
        />
        <StatNumber
          value={docDownloadRate}
          label="Document download rate"
          tooltipText="Contacts who have downloaded a package ÷ ‘Invites sent’"
        />
        <Divider />
        <StatNumber
          value={companyInviteCount.toString()}
          label="Companies invited"
          tooltipText="All companies invited to quote for all packages"
        />
        <StatNumber
          value={responsesCount.toString()}
          label="Responses"
          tooltipText="Companies with at least one of these responses: ‘Quoted’, ‘Quoting’ or ‘Not quoting’."
        />
        <StatNumber
          value={overallResponseRate}
          label="Response rate"
          tooltipText="‘Responses’ ÷ ‘Companies invited’"
        />
        <StatNumber
          value={quotedCount.toString()}
          label="Quoted"
          tooltipText="Companies with at least one ‘Quoted’ response"
        />
        <StatNumber
          value={quotedRate}
          label="Quoted rate"
          tooltipText="‘Quoted’ ÷ ‘Companies invited’"
        />
      </div>
      <div className={styles.statsPanelHelp}>
        <Icon name={IconName.Help} />
        <E1Link
          type="button"
          className="help-trigger"
          data-help-template="engagement_stats_project"
        >
          How are these calculated?
        </E1Link>
      </div>
    </>
  );
};
