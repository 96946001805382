import { useState } from 'react';
import { E1Address } from '../../../../js/classes/Address';
import AddressDetails from './AddressDetails';
import AddressSearch from './AddressSearch';
import styles from './styles.scss';

enum Mode {
  Search = 'search',
  Fill = 'fill',
}

type AddressFormProps = {
  address: E1Address;
  handleAddressChange: (address: E1Address) => void;
  adjustHeight?: () => void;
};

const AddressForm = ({ address, handleAddressChange, adjustHeight }: AddressFormProps) => {
  const [mode, setMode] = useState(Mode.Search);

  const handleToggleMode = () => {
    if (adjustHeight) {
      adjustHeight();
    }
    if (mode === Mode.Fill) {
      // reset when switching back to search.
      handleAddressChange(new E1Address());
    }
    setMode((prevMode) => (prevMode === Mode.Search ? Mode.Fill : Mode.Search));
  };

  return (
    <section>
      <AddressSearch
        show={mode === Mode.Search}
        label="Address"
        id="address"
        placeholder="Search for an address"
        handleAddressChange={handleAddressChange}
      />
      <AddressDetails
        show={mode === Mode.Fill}
        address={address}
        handleAddressChange={handleAddressChange}
      />
      <button type="button" onClick={handleToggleMode} className={styles['manual-address-trigger']}>
        {mode === Mode.Search ? "Can't find? Add manually" : 'Address Search'} &rarr;
      </button>
    </section>
  );
};

export default AddressForm;
