import { gql } from '@apollo/client';

export const GET_DOCUMENT_INTEGRATION = gql`
  query GetDocumentIntegration($stageId: EntityId!) {
    getDocumentIntegration(stageId: $stageId) {
      id
      connected
      type
    }
  }
`;
