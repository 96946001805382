import { useEffect, useRef } from 'react';
import {
  joinClassNames,
  LoadingSpinner,
  LoadingSpinnerSize,
} from '@estimateone/frontend-components';
import UploadCompanyLogoIconComponent from '@ascension/images/uploadCompanyProfileLogo.png';
import { useGlobalErrorBanner } from '../../GlobalErrorBanner';
import styles from './UploadCompanyLogo.module.scss';
import { LogoUploadStatus, useLogoUploader } from './upload-logo.hook';
import { Image } from '@shared';

type UploadCompanyLogoProps = {
  onLogoUpdated: () => void;
};

export const UploadCompanyLogo = ({ onLogoUpdated }: UploadCompanyLogoProps) => {
  const { uploadStatus, uploadLogo } = useLogoUploader();
  const { openBanner: openErrorBanner } = useGlobalErrorBanner();
  const inputRef = useRef<HTMLInputElement>(null);

  const triggerFileInput = () => {
    inputRef.current?.click();
  };

  useEffect(() => {
    if (uploadStatus === LogoUploadStatus.COMPLETE) {
      // N.b. this makes the parent component swap this for the component
      // that shows the logo and allows for deletion
      onLogoUpdated();
    }
    if (uploadStatus === LogoUploadStatus.FAILED) {
      openErrorBanner();
    }
  }, [uploadStatus, onLogoUpdated, openErrorBanner]);

  const isUploading = uploadStatus === LogoUploadStatus.UPLOADING;

  return (
    <div className={styles.uploadContainer}>
      <button
        type="button"
        onClick={triggerFileInput}
        className={styles.unstyledButton}
        data-testid="upload-logo"
      >
        <Image
          alt="upload company logo"
          className={joinClassNames(styles.logo, isUploading && styles.uploading)}
          src={UploadCompanyLogoIconComponent}
        />
      </button>
      {isUploading && (
        <div className={styles.loadingContainer}>
          <LoadingSpinner size={LoadingSpinnerSize.Small} />
        </div>
      )}
      <input
        accept="image/*"
        title="upload an image"
        type="file"
        onChange={async ({ target: { files } }) =>
          files ? uploadLogo(await files[0].arrayBuffer()) : null
        }
        // A bit odd: interactable but not visible, so we can test uploads in CI
        style={{ opacity: 0, height: 1 }}
        ref={inputRef}
      />
    </div>
  );
};
