import { useEffect, useState } from 'react';
import { Col, LoadingSpinner, Row, Wrapper } from '@estimateone/frontend-components';
import { formatISO } from 'date-fns';
import { isSecretActive } from '@builder/features/SsoSecrets/utils';
import { AddSecretForm } from './AddSecretForm';
import { SsoSecretCard } from './SsoSecretCard';
import { useFlashMessage } from '@shared';
import { useAccountSsoSecretKeys, useCreateAccountSsoSecretKey } from './hooks';
import { AccountSsoSecretKeys_accountSsoSecretKeys as AccountSsoSecretKeys } from './types/AccountSsoSecretKeys';
import { AddSecretFormFieldName, AddSecretFormValues } from '@builder/features/SsoSecrets/types';
import styles from './styles.scss';

export const SECRET_STATUS_ACTIVE_CURRENT = 'active and current';
export const SECRET_STATUS_UPCOMING = 'upcoming';
export const SECRET_STATUS_EXPIRED = 'expired';

export const SsoSecretManagement = () => {
  const { accountSsoSecretKeys, loading, refetch } = useAccountSsoSecretKeys();
  const { createSsoSecretKey } = useCreateAccountSsoSecretKey();

  const [secretsList, setSecretsList] = useState<AccountSsoSecretKeys[]>([]);

  const { warning: showErrorMessage } = useFlashMessage();
  const handleSubmit = async (values: AddSecretFormValues) => {
    const response = await createSsoSecretKey(
      values[AddSecretFormFieldName.Secret],
      formatISO(values[AddSecretFormFieldName.ExpiryDate]),
    );

    await refetch();

    if (response?.errors) {
      response.errors.forEach(({ field, message }) =>
        showErrorMessage({ title: 'Error', message: `${field}: ${message}` }),
      );
    }
  };

  const sortSecrets = (unSortedSecrets: AccountSsoSecretKeys[]) =>
    [...unSortedSecrets].sort(
      (a, b) => new Date(a.expiresAt).getTime() - new Date(b.expiresAt).getTime(),
    );

  const determineStatus = (secret: AccountSsoSecretKeys, secretsArray: AccountSsoSecretKeys[]) => {
    if (!isSecretActive(secret.expiresAt)) {
      return SECRET_STATUS_EXPIRED;
    }
    const firstActiveIndex = secretsArray.findIndex((sec: AccountSsoSecretKeys) =>
      isSecretActive(sec.expiresAt),
    );
    const currentIndex = secretsArray.indexOf(secret);
    return currentIndex === firstActiveIndex
      ? SECRET_STATUS_ACTIVE_CURRENT
      : SECRET_STATUS_UPCOMING;
  };

  useEffect(() => {
    if (accountSsoSecretKeys) {
      setSecretsList(sortSecrets(accountSsoSecretKeys));
    }
    return () => {
      setSecretsList([]);
    };
  }, [accountSsoSecretKeys]);

  if (loading || !accountSsoSecretKeys) {
    return <LoadingSpinner />;
  }

  return (
    <Wrapper className={styles.bottomGutter}>
      <Row>
        <Col span={8}>
          <h3>Secret Management</h3>
          <p className={styles.gutter}>
            After creating a Secret in your Identity Provider (IdP), add your Secret here to inform
            E1.
          </p>
          <p>
            Example: in Microsoft Entra, copy the Secret ‘Value’ (not the ‘Secret ID’). Then add it
            and the expiry date below.
          </p>
        </Col>
      </Row>
      {secretsList.map((secret: AccountSsoSecretKeys) => (
        <SsoSecretCard
          secret={secret.secretRedacted}
          expiry={secret.expiresAt}
          created={secret.createdAt}
          createdBy={secret.createdBy.fullName}
          status={determineStatus(secret, secretsList)}
          key={secret.id}
        />
      ))}
      <Col span={8}>
        <hr />
      </Col>
      <AddSecretForm handleAddSecretSubmit={handleSubmit} />
    </Wrapper>
  );
};

export default SsoSecretManagement;
