import { renderToStaticMarkup } from 'react-dom/server';
import * as D3 from 'd3';
import { mapMonthNumberToText } from '../SingleYAxisLineChart/utils';
import { ToolTipContent } from './ToolTipContent';
import { MARKER_HOVER_COLOUR, MOUSE_POINTER_X_OFFSET_PIXELS } from './constants';
import { AddToolTipParameterType, SingleYAxisDataPoint } from '../SingleYAxisLineChart/types';
import styles from './styles.module.scss';

function sortDataDescByValue<T extends SingleYAxisDataPoint>(input: T[]): T[] {
  return input.sort((x, y) => y.views - x.views);
}

const getMarkerElements = (
  chartId: string,
  dataPoint: SingleYAxisDataPoint,
): D3.Selection<Element, SingleYAxisDataPoint, HTMLElement, never> =>
  D3.selectAll<Element, SingleYAxisDataPoint>(
    `circle.${chartId}.marker.views.${mapMonthNumberToText(dataPoint.month)}${dataPoint.year}`,
  );

function hideToolTip(tooltip: D3.Selection<HTMLDivElement, unknown, HTMLElement, never>) {
  tooltip.html('').style('display', 'none');
  D3.selectAll('.marker').attr('stroke', 'none');
}

const updateToolTip = (
  chartId: string,
  tooltip: D3.Selection<HTMLDivElement, unknown, HTMLElement, never>,
  dataPoint: SingleYAxisDataPoint,
  markerCoordinate: { x: number; y: number },
) => {
  const allMarkerElements = getMarkerElements(chartId, dataPoint).data();
  if (allMarkerElements) {
    const view = renderToStaticMarkup(<ToolTipContent data={allMarkerElements} />);

    tooltip
      .html(view)
      .style('top', `${markerCoordinate.y}px`)
      .style('left', `${markerCoordinate.x + MOUSE_POINTER_X_OFFSET_PIXELS}px`)
      .style('display', 'block');
  }
};

const getMarkerCoordinate = (chartId: string, dataPoint: SingleYAxisDataPoint) => {
  const leftAxisMarker = getMarkerElements(chartId, dataPoint)
    .attr('stroke', MARKER_HOVER_COLOUR)
    .attr('cursor', 'pointer')
    .attr('stroke-width', 2);

  const mostValue = sortDataDescByValue(leftAxisMarker.data())[0];
  const index = leftAxisMarker.data().findIndex(({ views }) => views === mostValue.views);

  const leftMarkerAbsoluteCoordinate = leftAxisMarker.nodes()[index].getBoundingClientRect();

  const { x, y } = leftMarkerAbsoluteCoordinate;
  return {
    x: x + window.scrollX,
    y: y + window.scrollY - 32,
  };
};

function showToolTip(
  chartDataPoint: SingleYAxisDataPoint,
  chartId: string,
  tooltip: D3.Selection<HTMLDivElement, unknown, HTMLElement, never>,
) {
  const { x, y } = getMarkerCoordinate(chartId, chartDataPoint);

  updateToolTip(chartId, tooltip, chartDataPoint, { x, y });
}

export const addTooltipToChart = ({ chartId, chart }: AddToolTipParameterType) => {
  const tooltip = D3.select(`#${chartId}_tooltip`).append('div').attr('class', styles.tooltip);

  const onMouseOver = (
    _event: MouseEvent,
    chartDataPoints: SingleYAxisDataPoint | SingleYAxisDataPoint[],
  ) => {
    if (Array.isArray(chartDataPoints)) {
      return;
    }
    hideToolTip(tooltip);
    showToolTip(chartDataPoints, chartId, tooltip);
  };

  const onMouseOut = () => {
    hideToolTip(tooltip);
  };

  chart.on('mouseover', onMouseOver);
  chart.on('mouseout', onMouseOut);
};
