import { CheckIcon } from '@estimateone/frontend-components/src/components/Graphics/Icons';
import styles from './styles.scss';

export const BenefitsList = () => (
  <ul className={styles.benefitsList}>
    <li>
      <CheckIcon />
      Access an unlimited number of projects in your state
    </li>
    <li>
      <CheckIcon />
      See estimated construction start and end dates on projects
    </li>
    <li>
      <CheckIcon />
      View awarded project and builder information
    </li>
    <li>
      <CheckIcon />
      Be notified of relevant new projects with keyword alerts
    </li>
    <li>
      <CheckIcon />
      Get listed in the Subbie Directory and connect with builders
    </li>
  </ul>
);
