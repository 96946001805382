import { Alert, AlertVariant, ClearIcon, CloseIcon } from '@estimateone/frontend-components';
import { atom, useAtom } from 'jotai';
import styles from './GlobalErrorBanner.module.scss';

const openBannerAtom = atom(false);

export const GlobalErrorBanner = () => {
  const [open, setOpen] = useAtom(openBannerAtom);

  if (!open) {
    return null;
  }

  return (
    <div>
      <Alert variant={AlertVariant.Red} className={styles.alertBanner}>
        <div className={styles.header}>
          <div className={styles.headerLeft}>
            <ClearIcon />
            <span className={styles.headerLeftSpan}>
              Sorry, something went wrong on our end. Please try again
            </span>
          </div>

          <div className={styles.headerRight}>
            <CloseIcon onClick={() => setOpen(false)} />
          </div>
        </div>
      </Alert>
    </div>
  );
};

type UseGlobalErrorBannerPayload = {
  openBanner: () => void;
};

export const useGlobalErrorBanner = (): UseGlobalErrorBannerPayload => {
  const [, setOpen] = useAtom(openBannerAtom);

  return {
    openBanner: () => {
      setOpen(true);
    },
  };
};
