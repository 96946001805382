import { createContext, ReactNode, useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { reportError } from '../../../helpers/errorReporter';
import { BUILDER_ACCOUNT_WITH_EXPERIMENTS } from './queries';
import {
  BuilderAccountWithExperiments,
  BuilderAccountWithExperiments_currentUser as User,
  BuilderAccountWithExperiments_currentUser_account as Account,
  BuilderAccountWithExperiments_currentUser_account_realUsers as RealUser,
} from './types/BuilderAccountWithExperiments';
import { ExperimentName, FeatureName } from '@ascension/_gqltypes/builder.generated';

type ThingExists<T> = (id: T) => boolean;

export type AccountContextProps = {
  account: Account | null;
  currentUser: User | null;
  inExperiment: ThingExists<ExperimentName>;
  hasFeature: ThingExists<FeatureName>;
  realUsers: RealUser[];
} | null;

export const AccountContext = createContext<AccountContextProps>(null);

const { Provider } = AccountContext;

export const BuilderAccountProvider = ({ children }: { children: ReactNode }) => {
  const { data, error } = useQuery<BuilderAccountWithExperiments>(BUILDER_ACCOUNT_WITH_EXPERIMENTS);

  const features = useMemo(
    () =>
      data?.currentUser.account?.licensingInfo?.activeLicenses
        .map((license) => license.product.features)
        .flat() ?? [],
    [data],
  );

  useEffect(() => {
    if (error) {
      reportError(error);
    }
  }, [error]);

  if (error) {
    return null;
  }

  const value: AccountContextProps = {
    account: data?.currentUser.account ?? null,
    currentUser: data?.currentUser ?? null,
    realUsers:
      data?.currentUser.account?.realUsers.filter((realUser) => realUser.deletedAt === null) ?? [],
    inExperiment: (experimentName) =>
      data?.experiments?.map((experiment) => experiment.name).includes(experimentName) ?? false,
    hasFeature: (featureName) => features.includes(featureName) ?? false,
  };

  return <Provider value={value}>{children}</Provider>;
};

export { Account };
