import { useState } from 'react';
import { E1Link } from '@estimateone/frontend-components';
import styles from './CompanyCompliance.module.scss';
import { CompanyComplianceDetails } from './CompanyComplianceDetails';
import { CompanyQualifications } from '@shared/Profiles/CompanyQualifications';
import {
  AccountType,
  QualificationLevel,
  QualificationSource,
  QualificationStatus,
  QualificationType,
} from '@ascension/_gqltypes/profiles.generated';

type CompanyComplianceProps = {
  qualifications: {
    source: QualificationSource;
    type: QualificationType;
    level: QualificationLevel;
    status: QualificationStatus;
    expiryDate: string;
  }[];
  accountType: AccountType;
};

export const CompanyCompliance = ({ qualifications, accountType }: CompanyComplianceProps) => {
  const [showDetails, setShowDetails] = useState(false);
  return (
    <>
      <div className={styles.qualificationsContent}>
        <CompanyQualifications qualifications={qualifications} />
        <E1Link
          onClick={() => {
            setShowDetails(!showDetails);
          }}
          className={styles.showHideDetails}
        >
          {showDetails ? 'Hide details' : 'Show details'}
        </E1Link>
      </div>
      {showDetails && (
        <CompanyComplianceDetails qualifications={qualifications} accountType={accountType} />
      )}
    </>
  );
};
