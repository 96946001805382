import { createContext, ReactNode, useEffect, useMemo, useState } from 'react';
import { useAccountContext } from '@subbie/context/AccountProvider';
import { useIsChargebeeAccount } from '@subbie/context/AccountProvider/hooks';
import { AccountType, FeatureName } from '@ascension/_gqltypes/subbie.generated';

type ConsultantDetailsAccessContextProps = {
  loading: boolean;
  canViewConsultants: boolean;
  canUpgradeToConsultants: boolean;
  provisionedLicenseId: number | undefined;
};

const ConsultantDetailsAccessContext = createContext<
  ConsultantDetailsAccessContextProps | undefined
>(undefined);

const ConsultantDetailsAccessProvider = ({ children }: { children: ReactNode }) => {
  const [provisionedLicenseId, setProvisionedLicenseId] = useState<number>();
  const { account, hasFeature } = useAccountContext();
  const isChargebeeAccount = useIsChargebeeAccount();

  useEffect(() => {
    if (account) {
      const {
        licensingInfo: { provisionedLicenses },
      } = account;
      const license = provisionedLicenses.find(({ product: { features } }) =>
        features.includes(FeatureName.CONSULTANT_DETAILS),
      );
      setProvisionedLicenseId(license ? license.id : undefined);
    }
  }, [account]);

  const contextValue: ConsultantDetailsAccessContextProps = useMemo(() => {
    const hasAddonsFeature = hasFeature ? hasFeature(FeatureName.SUBBIE_ADDONS) : false;
    const hasConsultantDetailsFeature = hasFeature
      ? hasFeature(FeatureName.CONSULTANT_DETAILS)
      : false;

    // Having the SUBBIE_ADDONS feature means that the current account can get the consultant details
    // feature if they have the right license, so roadblocks/paywalls should be displayed
    const accountSupportsConsultantDetails = hasAddonsFeature || hasConsultantDetailsFeature;

    const canUpgradeToConsultants =
      (account?.type === AccountType.SUPPLIER && accountSupportsConsultantDetails) ||
      (account?.type === AccountType.SUBCONTRACTOR &&
        accountSupportsConsultantDetails &&
        !isChargebeeAccount);

    return {
      loading: account === null,
      canViewConsultants: hasConsultantDetailsFeature,
      canUpgradeToConsultants,
      provisionedLicenseId,
    };
  }, [account, hasFeature, provisionedLicenseId, isChargebeeAccount]);

  return (
    <ConsultantDetailsAccessContext.Provider value={contextValue}>
      {children}
    </ConsultantDetailsAccessContext.Provider>
  );
};

export { ConsultantDetailsAccessProvider };

export default ConsultantDetailsAccessContext;
