import { SVGProps, ComponentProps } from 'react';

export const LockedIcon = (props: ComponentProps<'svg'> & SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask id="a" fill="#fff">
      <rect x={5} y={8.001} width={14} height={11} rx={1} />
    </mask>
    <rect
      x={5}
      y={8.001}
      width={14}
      height={11}
      rx={1}
      stroke="#666666"
      strokeWidth={4}
      mask="url(#a)"
    />
    <path d="M15 8.001v-1a3 3 0 1 0-6 0v3" stroke="#666666" strokeWidth={2} />
  </svg>
);
