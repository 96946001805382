import { FieldArray } from 'react-final-form-arrays';
import { CoreTradeTag } from '@profiles/features/CompanyProfile/components/Trades/CoreTrades';
import styles from '../EditCoreTradesForm.module.scss';
import Checkbox from '@shared/Checkbox';
import { UpdateTradesMutation } from '@profiles/features/CompanyProfile/components/Trades/mutations.generated';

type StockTrade = UpdateTradesMutation['changeStockTrades'][number];

type CoreTradesCheckboxGroupProps = {
  coreTradesOptions: StockTrade[];
};

const MAX_NUMBER_OF_CORE_TRADES = 2;
const CoreTradesCheckboxGroup = ({ coreTradesOptions }: CoreTradesCheckboxGroupProps) =>
  coreTradesOptions && (
    <FieldArray name="CoreTrades">
      {({ fields }) =>
        coreTradesOptions.map(({ id: coreTradeId, name }) => {
          const isChecked = fields.value && fields.value.includes(coreTradeId);
          const isReadOnly =
            !isChecked &&
            !!fields?.value?.length &&
            fields.value.length >= MAX_NUMBER_OF_CORE_TRADES;

          return (
            <div key={coreTradeId} className={styles.checkboxGroupContainer}>
              <Checkbox
                id={coreTradeId.toString()}
                name={`${fields.name}[${coreTradeId}]`}
                isChecked={isChecked}
                onChange={() => {
                  if (isChecked) {
                    const index = fields.value.indexOf(coreTradeId);
                    fields.remove(index);
                  } else {
                    fields.push(coreTradeId);
                  }
                }}
                label={name}
                readOnly={isReadOnly}
              />
              {isChecked && <CoreTradeTag text="Core" />}
            </div>
          );
        })
      }
    </FieldArray>
  );

export default CoreTradesCheckboxGroup;
