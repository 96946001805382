import { joinClassNames, Tag, TagVariant } from '@estimateone/frontend-components';
import { BaseIconProps } from '@estimateone/frontend-components/src/components/Graphics/Icons/BaseIcon';
import chasAdvancedLarge from '@ascension/images/qualifications/chas-advanced-lrg.svg';
import chasAdvanced from '@ascension/images/qualifications/chas-advanced.svg';
import chasCommonAssessmentStandardLarge from '@ascension/images/qualifications/chas-cas-lrg.svg';
import chasCommonAssessmentStandard from '@ascension/images/qualifications/chas-cas.svg';
import chasEliteLarge from '@ascension/images/qualifications/chas-elite-lrg.svg';
import chasElite from '@ascension/images/qualifications/chas-elite.svg';
import chasFoundationLarge from '@ascension/images/qualifications/chas-foundation-lrg.svg';
import chasFoundation from '@ascension/images/qualifications/chas-foundation.svg';
import chasStandardLarge from '@ascension/images/qualifications/chas-standard-lrg.svg';
import chasStandard from '@ascension/images/qualifications/chas-standard.svg';
import chasVerifiedSupplierLarge from '@ascension/images/qualifications/chas-verified-supplier-lrg.svg';
import chasVerifiedSupplier from '@ascension/images/qualifications/chas-verified-supplier.svg';
import styles from './CompanyQualifications.module.scss';
import { Image } from '@shared';
import {
  QualificationLevel,
  QualificationSource,
  QualificationType,
} from '@ascension/_gqltypes/builder.generated';

type CompanyQualificationsProps = {
  qualifications: {
    source: QualificationSource;
    type: QualificationType;
    level: QualificationLevel;
  }[];
};

export const getQualificationDetails = (
  qualification: CompanyQualificationsProps['qualifications'][number],
) => {
  switch (qualification.level) {
    case QualificationLevel.ELITE:
      return {
        label: 'CHAS Elite',
        image: chasElite,
        badge: chasEliteLarge,
        description:
          'CHAS Elite offers the highest level of certification, demonstrating compliance with multiple risk management standards, including PAS 91, SSIP, and the Common Assessment Standard, to streamline prequalification for buyers.',
      };
    case QualificationLevel.ADVANCED:
      return {
        label: 'CHAS Advanced',
        image: chasAdvanced,
        badge: chasAdvancedLarge,
        description:
          'CHAS Advanced provides certification that meets the PAS 91 standard helping businesses demonstrate compliance across key risk management areas while simplifying supply chain pre-qualification.',
      };
    case QualificationLevel.STANDARD:
      return {
        label: 'CHAS Standard',
        image: chasStandard,
        badge: chasStandardLarge,
        description:
          'CHAS Standard ensures businesses meet core SSIP health and safety requirements, providing essential certification to demonstrate compliance with industry standards.',
      };
    case QualificationLevel.FOUNDATION:
      return {
        label: 'CHAS Foundation',
        image: chasFoundation,
        badge: chasFoundationLarge,
        description:
          'CHAS Foundation membership provides businesses with enhanced certification, compliance support, and access to exclusive resources to demonstrate their commitment to health, safety, and supply chain best practices.',
      };
    case QualificationLevel.VERIFIED_SUPPLIER:
      return {
        label: 'CHAS Verified Supplier',
        image: chasVerifiedSupplier,
        badge: chasVerifiedSupplierLarge,
        description:
          'CHAS Verified Supplier status showcases businesses as pre-qualified, compliant, and trustworthy suppliers, increasing their visibility and credibility within the CHAS network to attract more procurement opportunities.',
      };
    case QualificationLevel.COMMON_ASSESSMENT_STANDARD:
      return {
        label: 'Common Assessment Standard',
        image: chasCommonAssessmentStandard,
        badge: chasCommonAssessmentStandardLarge,
        description:
          'The Common Assessment Standard (CAS) is a set of health and safety assessment criteria developed by SSIP to streamline the prequalification process for buyers and suppliers. CAS certification demonstrates compliance with core health and safety standards to ensure a safe working environment.',
      };
    default:
      return { label: '', badge: undefined, image: undefined, description: '' };
  }
};

// eslint-disable-next-line react/jsx-no-useless-fragment
const IconWrapper = ({ children }: BaseIconProps) => <>{children}</>;

export const CompanyQualifications = ({ qualifications }: CompanyQualificationsProps) => {
  const qualification = qualifications.find(
    (q) => q.source === QualificationSource.CHAS && q.type === QualificationType.COMPLIANCE,
  );

  if (!qualification) {
    return null;
  }

  const { label, image } = getQualificationDetails(qualification);

  return (
    <div>
      <Tag
        variant={TagVariant.Iron050}
        text={label}
        className={joinClassNames(styles.chasTag)}
        icon={
          <IconWrapper>
            <Image src={image} alt={label} />
          </IconWrapper>
        }
      />
    </div>
  );
};
