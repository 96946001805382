import { ProcoreConnectionInformationModal } from '@builder/features/DocSyncIntegration/ProcoreConnect/ProcoreConnectionInformationModal/ProcoreConnectionInformationModal';
import { ProcoreConnectSlider } from '../ProcoreConnect/ProcoreConnectSlider';
import { ProcoreModifySlider } from '../ProcoreConnect/ProcoreModifySlider/ProcoreModifySlider';
import { DocSyncPlugin } from './DocSyncPlugin';
import { StageType } from '@ascension/enums';

export const procorePlugin: DocSyncPlugin = {
  id: 'procore',
  displayName: 'Procore',
  Slider: ProcoreConnectSlider,
  ConnectionInformationModal: ProcoreConnectionInformationModal,
  supportedStageTypes: [StageType.TYPE_PROCUREMENT],
  supportedStorageRegions: ['ap-southeast-2'],
  ModifySlider: ProcoreModifySlider,
};
