import { useState } from 'react';
import {
  ActionMenu,
  Avatar,
  Ellipsis,
  IconButton,
  Tag,
  TagVariant,
  TextLink,
} from '@estimateone/frontend-components';
import styles from './HiddenContactCard.module.scss';
import { fireAndForget } from '@shared/Util/async';
import { useUnhideProfileContact } from './hooks';
import { AvatarSize } from '@estimateone/frontend-components/src/enums/Avatar';
import { CompanyContact } from '@profiles/providers/ProfileProvider/types';

export type ContactCardProps = {
  companyContactDetails: CompanyContact;
};

export const HiddenContactCard = ({ companyContactDetails }: ContactCardProps) => {
  const { firstName, lastName, fullName, contactable, ascensionId: id } = companyContactDetails;
  const { unhideProfileContact } = useUnhideProfileContact(Number(id), fullName);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const phone = contactable?.phone;
  const email = contactable?.email;

  return (
    <div className={styles.contactContainer} data-testid="contact-card" id="contact-card">
      <div className={styles.contactWrapper}>
        <div className={styles.avatarWrapper}>
          <Avatar
            firstName={firstName}
            lastName={lastName ?? ''}
            userId={Number(id)}
            size={AvatarSize.Network}
          />
        </div>
        <div className={styles.nameColumn}>
          <p className={styles.name}>{fullName}</p>
          <Tag className={styles.tag} variant={TagVariant.Iron050} text="Hidden" />
        </div>
        <div className={styles.contactDetailsColumn}>
          <p className={styles.phone}>{phone ?? ''}</p>
          <TextLink className={styles.email} href={`mailto:${email}`}>
            {email}
          </TextLink>
        </div>
        <ActionMenu
          placement="bottom-end"
          visible={isMenuOpen}
          onClickOutside={() => setIsMenuOpen(false)}
          triggerElement={
            <IconButton
              className={styles.meatballMenu}
              icon={<Ellipsis />}
              title="contact action menu"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            />
          }
        >
          <ActionMenu.Item
            onClick={() => {
              setIsMenuOpen(false);
              fireAndForget(() => unhideProfileContact());
            }}
          >
            Display in Profile
          </ActionMenu.Item>
        </ActionMenu>
      </div>
    </div>
  );
};
