import { Icon, IconName, IconVariant } from '@estimateone/frontend-components';

type NoteIconProps = {
  variant: IconVariant;
  className?: string;
};

export const NoteIcon = ({ variant, className }: NoteIconProps) => (
  <Icon
    name={IconName.AddNotes}
    variant={variant}
    className={className}
    size={{ height: '19px', width: '19px' }}
  />
);
