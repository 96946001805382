import { useMutation } from '@apollo/client';
import { DELETE_HISTORICAL_PROJECT } from './mutations';
import {
  DeleteHistoricalProjectMutation,
  DeleteHistoricalProjectMutationVariables,
} from './mutations.generated';

export const useDeleteHistoricalProject = (id: string) => {
  const [deleteHistoricalProject, { error }] = useMutation<
    DeleteHistoricalProjectMutation,
    DeleteHistoricalProjectMutationVariables
  >(DELETE_HISTORICAL_PROJECT, {
    update: (cache) => {
      cache.evict({ id: `HistoricalProject:${id}` });
      cache.gc();
    },
  });

  const submit = () =>
    deleteHistoricalProject({
      variables: {
        id: parseInt(id),
      },
    });

  return { submit, error };
};
