import { useState } from 'react';
import { Button, ButtonVariant, Modal, ModalSize } from '@estimateone/frontend-components';
import { useCountryTermTranslator } from '@ascension/components/localisation';
import styles from './OptInModal.module.scss';
import { useEditSubbieNetworkOptInSetting, useMarkNetworkReEnrolmentModalAsViewed } from '../hooks';
import { Action, useInteractAnalytics } from '@ascension/components/hooks/Analytics';

export const RE_ENROLMENT_MODAL_FLOW = 'ReEnrolmentModal';

type OptInModalProps = {
  postSubmit: () => void;
};

export const OptInModal = ({ postSubmit }: OptInModalProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const translator = useCountryTermTranslator();
  const { addEvent } = useInteractAnalytics();
  const { markAsViewed } = useMarkNetworkReEnrolmentModalAsViewed();
  const { submit } = useEditSubbieNetworkOptInSetting({
    onCompleted: async () => {
      await markAsViewed();
      setIsOpen(false);
      postSubmit();
    },
  });

  const onSubmit = () => {
    addEvent({
      action: Action.SUBBIE_NETWORK_OPT_IN,
      flow: RE_ENROLMENT_MODAL_FLOW,
    });

    return submit(true);
  };

  const onCancel = async () => {
    addEvent({
      action: Action.SUBBIE_NETWORK_OPT_OUT,
      flow: RE_ENROLMENT_MODAL_FLOW,
    });

    await markAsViewed();

    return setIsOpen(false);
  };

  return (
    <Modal
      size={ModalSize.Small}
      isOpen={isOpen}
      onRequestClose={() => undefined}
      rootElement="body"
      hideClose
    >
      <Modal.Section>
        <section className={styles.wrapper}>
          <div className={styles.content}>
            <h3>Allow {translator('builders')} to find your company in the E1 Network?</h3>
            <p>
              {translator('builders')} use the Network to discover and connect with reliable,
              high-quality businesses.
            </p>
            <p>
              Use your profile to showcase your services, and get more project invites direct from{' '}
              {translator('builders')}.
            </p>
            <p>You can edit your profile and manage your visibility in Profile Settings.</p>
          </div>
          <Button variant={ButtonVariant.Grey} onClick={onCancel}>
            Don&apos;t allow
          </Button>
          <Button variant={ButtonVariant.Primary} onClick={onSubmit}>
            Allow
          </Button>
        </section>
      </Modal.Section>
    </Modal>
  );
};
