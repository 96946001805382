import { StageCategory } from '@ascension/_gqltypes/builder.generated';

const STAGE_CATEGORY_MAP = {
  [StageCategory.AGED_CARE]: 'Aged Care',
  [StageCategory.CIVIL]: 'Civil',
  [StageCategory.COMMERCIAL]: 'Commercial',
  [StageCategory.CORRECTIONS]: 'Corrections',
  [StageCategory.DATA_CENTRE]: 'Data Centre',
  [StageCategory.DEFENCE]: 'Defence',
  [StageCategory.EDUCATION]: 'Education',
  [StageCategory.ENTERTAINMENT]: 'Entertainment',
  [StageCategory.FIT_OUT]: 'Fit-out',
  [StageCategory.GOVERNMENT]: 'Government',
  [StageCategory.HEALTH]: 'Health',
  [StageCategory.HOSPITALITY]: 'Hospitality',
  [StageCategory.INDUSTRIAL_HEAVY]: 'Industrial (Heavy)',
  [StageCategory.INDUSTRIAL_LIGHT]: 'Industrial (Light)',
  [StageCategory.MEDICAL]: 'Medical',
  [StageCategory.PHARMACEUTICAL]: 'Pharmaceutical',
  [StageCategory.RECREATION]: 'Recreation',
  [StageCategory.REFURBISHMENT]: 'Refurbishment',
  [StageCategory.RESIDENTIAL]: 'Residential',
  [StageCategory.RETAIL]: 'Retail',
  [StageCategory.TOURISM]: 'Tourism',
  [StageCategory.OTHER]: 'Other',
  [StageCategory.MIXED_USE]: 'Mixed Use',
};

export const mapStageCategoryEnumToLabel = (stageCategory: StageCategory) =>
  STAGE_CATEGORY_MAP[stageCategory];
