import $ from 'jquery';
import Clipboard from 'clipboard';
import Form from '../classes/Form';

$(() => {
  const $body = $('body');

  $body.on('submit', 'form.edit-account-preferences-form', async (e) => {
    e.preventDefault();

    const magicForm = new Form<{ success: true; requiresTrades: boolean }>($(e.currentTarget));

    const updatePreferencesResult = await magicForm.submit();

    if (updatePreferencesResult.success) {
      $('div.alert-dismissible[data-alert-topic="require_trades"]').toggleClass(
        'hide',
        !updatePreferencesResult.requiresTrades,
      );
    }

    return false;
  });

  $body.on('submit', 'form.edit-calendar-preferences-form', (e) => {
    e.preventDefault();
  });

  if ($('div.section.calendar-sync').length > 0) {
    const $copyTrigger = $('button.calendar-url-copy-trigger').first();
    const tooltipDataAttr = Clipboard.isSupported() ? 'copied-text' : 'unsupported-text';
    const copyResultTooltipText = $copyTrigger.data(tooltipDataAttr);

    $copyTrigger.attr('title', copyResultTooltipText).tooltipster({
      trigger: 'click',
    });

    const trigger = $copyTrigger.get(0);

    if (!trigger) {
      return;
    }

    const calendarClipboard = new Clipboard(trigger);
    calendarClipboard.on('success', (e) => {
      e.clearSelection();
    });

    $('button.calendar-preferences-submit-trigger').on('click', (e) => {
      const $saveBtn = e.currentTarget;
      const form = $saveBtn.closest('form');

      if (!form) {
        return Promise.reject(Error('Could not resolve form element'));
      }

      const $form = $(form) as unknown as JQuery<HTMLFormElement>;
      const magicCalendarForm = new Form($form);

      return magicCalendarForm.submit();
    });
  }
});
