import { gql } from '@apollo/client';
import { ImportedFromPackage, PackageDashboardRfq } from './fragments';

export const PACKAGE_DASHBOARD_RFQS = gql`
  query GetPackageWithRfqs($packageId: EntityId!, $stageId: EntityId!) {
    package(id: $packageId) {
      id
      title
      fullSet
      contentLastAddedAt
      countOfCurrentPackageDocuments
      quotesDueAt
      activeRfqs {
        ...PackageDashboardRfq
      }
      lettingScheduleDetails {
        id
        isAwarded
        rfqsSentByDate
        letByDate
        startOnSiteDate
        noteCount
        latestNote {
          id
          text
        }
        awardedToRfq {
          id
          latestQuote {
            id
            amount
          }
          contact {
            fullName
            phone
            email
            company {
              id
              name
              phone
              address {
                fullAddress
              }
            }
          }
        }
        assignedToUser {
          fullName
        }
      }

      importedFromPackage {
        ...ImportedFromPackage
      }
    }
    stage(id: $stageId) {
      ... on UnrestrictedStage {
        id
        name
        activeDocumentCount
        lastStage {
          ... on BuilderStageInterface {
            id
          }
        }
        account {
          id
          name
          primaryCountry {
            id
          }
        }
      }
    }
  }
  ${PackageDashboardRfq}
  ${ImportedFromPackage}
`;
