import { useCallback } from 'react';
import Routing from 'routing';
import { Button, ButtonSize, ButtonVariant } from '@estimateone/frontend-components';
import { captureException } from '@sentry/browser';
import E1Request from '@ascension/js/classes/E1Request';
import useFlashMessage from '@shared/Util/useFlashMessage';
import { useIsNetworkConnectionsEnabled } from '@builder/context/AccountProvider/hooks';
import { useGetMatchingProfiles } from '../../hooks/useGetMatchingProfiles';
import { useIsShadowProfile } from '../../providers/UniversalProfileProvider/hooks';
import { useOpenSyncableCompanyModal } from '@builder/features/SyncCompanyProfile/hooks';
import { EntityId } from '@ascension/types';
import { ModalTrigger } from '@builder/features/SyncCompanyProfile/types';

export type AddToAddressBookSuccessResponse = {
  success: true;
  companyId: number;
  companyName: string;
  flash_notification: string;
};

export type AddToAddressBookErrorResponse = {
  success: false;
  message: string;
};

export const E1NetworkCompanyToolbar = ({
  canViewAddressBook,
  profileId,
  accountId,
  onConnectSuccess,
}: {
  readonly canViewAddressBook: boolean;
  readonly profileId: string | undefined;
  readonly accountId: EntityId | undefined;
  readonly onConnectSuccess?: (companyId: EntityId) => void;
}) => {
  const isShadowProfile = useIsShadowProfile();
  const { warning: showErrorMessage } = useFlashMessage();
  const isNetworkConnectionsEnabled = useIsNetworkConnectionsEnabled();
  const { openModal: openSyncModal } = useOpenSyncableCompanyModal();
  const { searched, matchingProfiles, matchingType } = useGetMatchingProfiles({
    profileId,
    accountId,
    companyDetailsLoading: false,
    isShadowProfile,
  });

  const addToAddressBook = useCallback(async () => {
    try {
      const route = profileId
        ? Routing.generate('app_addressbookcompany_addfrome1profile', { profileId })
        : Routing.generate('app_addressbookcompany_addfrome1accountprofile', { accountId });

      // E1Request will show the success flash message
      // Set E1Request `passive` param to true to handle error flash messages ourselves
      const result = await new E1Request<
        AddToAddressBookErrorResponse | AddToAddressBookSuccessResponse
      >(route, 'POST', undefined, true).submit();

      if (result.success) {
        if (onConnectSuccess) onConnectSuccess(result.companyId);
        document.dispatchEvent(
          new CustomEvent('add_company_from_e1_profile', {
            detail: {
              companyId: result.companyId,
              accountId,
              profileId,
            },
          }),
        );
      } else {
        showErrorMessage({
          title: 'Error',
          message: 'Something went wrong when trying to Connect. Please try again.',
        });
      }
    } catch (error) {
      captureException(error);
      showErrorMessage({
        title: 'Error',
        message: 'Something went wrong when trying to Connect. Please try again.',
      });
    }
  }, [accountId, onConnectSuccess, profileId, showErrorMessage]);

  if (!canViewAddressBook) {
    return null;
  }
  if (!profileId && !accountId) {
    return null;
  }

  if (
    isNetworkConnectionsEnabled &&
    matchingType !== 'invalid' &&
    searched !== null &&
    matchingProfiles?.length === 1
  ) {
    return (
      <Button
        variant={ButtonVariant.Secondary}
        size={ButtonSize.Small}
        onClick={() => {
          openSyncModal({
            data: {
              searchedCompanyId: searched.id,
            },
            modalTriggeredBy: ModalTrigger.E1_NETWORK_COMPANY,
          });
        }}
      >
        Connect
      </Button>
    );
  }

  return (
    <Button
      variant={ButtonVariant.Secondary}
      size={ButtonSize.Small}
      data-profile-id={profileId}
      data-account-id={accountId}
      onClick={addToAddressBook}
    >
      Connect
    </Button>
  );
};
