import { ContractSizeEnum } from '@ascension/_gqltypes/profiles.generated';

export const contractSizeLabelMap = (contractSizeLabel: ContractSizeEnum): string => {
  const CONTRACT_SIZE_PREFIX = 'SIZE_';
  const CONTRACT_SIZE_PREFIX_LEN = CONTRACT_SIZE_PREFIX.length;
  const CONTRACT_SIZE_PLUS_SUFFIX = '_PLUS';
  const CONTRACT_SIZE_PLUS_SUFFIX_LEN = CONTRACT_SIZE_PLUS_SUFFIX.length;

  if (contractSizeLabel.includes(CONTRACT_SIZE_PLUS_SUFFIX)) {
    // The highest size has PLUS as suffix in the enum
    return `${contractSizeLabel.substring(
      CONTRACT_SIZE_PREFIX_LEN,
      contractSizeLabel.length - CONTRACT_SIZE_PLUS_SUFFIX_LEN,
    )}+`;
  }
  return contractSizeLabel.substring(CONTRACT_SIZE_PREFIX_LEN);
};
