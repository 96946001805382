import { ProjectStatus } from '@ascension/_gqltypes/subbie.generated';

const LOCAL_STORAGE_CON_DEET_RETURN_PROJECT_ID = 'consultants:redirect_project_id';

const PACKAGE_REQUEST_TEXT = {
  NEEDS_REQUEST:
    'The builder has nominated they must approve all document download requests. We will email you as soon as the request has been approved with a link to the documents.',
  REVOKED:
    'The builder has revoked your access to this package for this project. Please contact the builder for more information.',
  DECLINED:
    'The builder has declined your access to this package for this project at this time. Please contact the builder for more information.',
};

const PROJECT_STATUS_LABELS = {
  [ProjectStatus.OPEN]: 'Open',
  [ProjectStatus.AWARDED]: 'Awarded',
  [ProjectStatus.CLOSED]: 'Closed',
};

export { LOCAL_STORAGE_CON_DEET_RETURN_PROJECT_ID, PACKAGE_REQUEST_TEXT, PROJECT_STATUS_LABELS };
