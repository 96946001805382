import { useMutation } from '@apollo/client';
import useFlashMessage from '@shared/Util/useFlashMessage';
import { GET_SSC_OPT_OUT_PREFERENCE } from '@profiles/features/CompanyProfile/modules/ViewerProvider/queries';
import { SscOptOutPreferenceQuery } from '@profiles/features/CompanyProfile/modules/ViewerProvider/queries.generated';
import { EDIT_SSC_OPT_OUT_SETTING } from './mutations';
import {
  EditSSCOptOutSettingMutation,
  EditSSCOptOutSettingMutationVariables,
} from './mutations.generated';

type Options = Parameters<
  typeof useMutation<EditSSCOptOutSettingMutation, EditSSCOptOutSettingMutationVariables>
>[1];

export const useSubmitSSCOptOut = (opts?: Options) => {
  const { success } = useFlashMessage();

  const [editSSCOptOut, { error }] = useMutation<
    EditSSCOptOutSettingMutation,
    EditSSCOptOutSettingMutationVariables
  >(EDIT_SSC_OPT_OUT_SETTING);

  const optOut = async () =>
    editSSCOptOut({
      variables: {
        optOut: true,
      },
      optimisticResponse: {
        editSSCOptOutSetting: {
          id: 999_999,
          attribute: 'ssc_opt_out_status',
          value: 1,
          success: true,
          errors: [],
          __typename: 'EditIntAccountSettingResponse',
        },
      },
      onCompleted: () =>
        success({
          title: 'Success',
          message: 'Your company profile can not be viewed by Suppliers.',
        }),
      update: (cache) => {
        cache.writeQuery<SscOptOutPreferenceQuery>({
          query: GET_SSC_OPT_OUT_PREFERENCE,
          data: {
            hasOptedOutOfSSC: true,
          },
        });
      },
      ...opts,
    });

  const optIn = async () =>
    editSSCOptOut({
      variables: {
        optOut: false,
      },
      optimisticResponse: {
        editSSCOptOutSetting: {
          id: 999_999,
          attribute: 'ssc_opt_out_status',
          value: 0,
          success: true,
          errors: [],
          __typename: 'EditIntAccountSettingResponse',
        },
      },
      onCompleted: () =>
        success({
          title: 'Success',
          message: 'Your company profile can be viewed by Suppliers.',
        }),
      update: (cache) => {
        cache.writeQuery<SscOptOutPreferenceQuery>({
          query: GET_SSC_OPT_OUT_PREFERENCE,
          data: {
            hasOptedOutOfSSC: false,
          },
        });
      },
      ...opts,
    });

  return { optIn, optOut, error: error ?? undefined };
};
