import {
  AlertIcon,
  Button,
  ButtonSize,
  ButtonVariant,
  HelpBanner,
  HelpBannerContent,
  SkeletonLoader,
} from '@estimateone/frontend-components';
import { ShowMoreOrLessContent } from '../ShowMoreOrLessContent';
import styles from './AboutUs.module.scss';
import { EditAboutUs } from './EditAboutUs';
import { MIN_CONTENT_SIZE } from './constants';
import { useOwnAccount } from '@profiles/features/CompanyProfile/hooks';

export const AboutUsLoading = () => (
  <div className={styles.aboutUsSkeleton}>
    <SkeletonLoader width="50%" height="40px" />
    <SkeletonLoader height="16px" count={10} />
  </div>
);

export const AboutUsError = () => (
  <div className={styles.aboutUsSection}>
    <div className={styles.aboutUsHeadingWrapper}>
      <p className={styles.aboutUsHeader}>About Us</p>
    </div>
    <AlertIcon title="about us error icon" />
  </div>
);

type AboutUsProps = {
  data: string | null;
};

export const AboutUs = ({ data }: AboutUsProps) => {
  const { isOwnAccount } = useOwnAccount();

  if (!isOwnAccount) {
    return (
      <div className={styles.aboutUsSection}>
        <div className={styles.aboutUsHeadingWrapper}>
          <p className={styles.aboutUsHeader}>About Us</p>
        </div>
        {data ? (
          <ShowMoreOrLessContent content={data} minContentSize={MIN_CONTENT_SIZE} />
        ) : (
          <p className={styles.aboutUsEmptyContent}>
            This Subcontractor hasn&apos;t added an About Us
          </p>
        )}
      </div>
    );
  }

  return (
    <div className={styles.aboutUsSection}>
      <div className={styles.aboutUsHeadingWrapper}>
        <p className={styles.aboutUsHeader}>About Us</p>
        <EditAboutUs fieldValue={data} />
      </div>
      {data ? (
        <ShowMoreOrLessContent content={data} minContentSize={MIN_CONTENT_SIZE} />
      ) : (
        <div className={styles.aboutUsEmptyContentForOwnAccount}>
          <HelpBanner isHidden={false} toggleHidden={() => {}}>
            <HelpBannerContent>
              <h3 className={styles.heading}>Introduce your business to builders</h3>
              <p className={styles.content}>
                Add a brief description about your business and which services you provide so that
                builders looking at your Company Profile will know what you are capable of.
              </p>
              <p className={styles.subHeading}>
                <strong>It is recommended that you include</strong>
              </p>
              <ul className={styles.lists}>
                <li>How long your business has been operating</li>
                <li>Which services you offer</li>
                <li>Which regions you cover</li>
                <li>Anything else that will help you stand out from your competition</li>
              </ul>
              <EditAboutUs
                triggerElement={
                  <Button variant={ButtonVariant.Grey} size={ButtonSize.Small}>
                    Add about us
                  </Button>
                }
                fieldValue=""
              />
            </HelpBannerContent>
          </HelpBanner>
        </div>
      )}
    </div>
  );
};
