import Routing from 'routing';
import E1Request from '@ascension/js/classes/E1Request';
import { EntityId } from '@ascension/types';

export const useTenderDashboardLinks = ({
  stageId,
  stageType,
}: {
  stageId: EntityId;
  stageType: string;
}) => {
  const getIssueInvitationsLink = () =>
    Routing.generate('app_stage_invitations', {
      id: stageId,
      stageType,
    });

  const openUpgradeAddressBook = async () => {
    await new E1Request(Routing.generate('app_upgrade_addressbook'))
      .setShowLoadingModal(true)
      .submit();
  };

  const getMonitorResponsesLink = () =>
    Routing.generate('app_stage_monitor', { id: stageId, stageType });

  const getIssueCorrespondenceLink = () =>
    Routing.generate('app_stage_monitor', {
      id: stageId,
      stageType,
      correspondence_helper_modal: 1,
    });

  const getSearchCorrespondenceLink = () =>
    Routing.generate('app_stage_correspondence', { id: stageId, stageType });

  const getAddDeleteDocumentsLink = () =>
    Routing.generate('app_stage_documents', { id: stageId, stageType });

  const getViewEditPackagesLink = () =>
    Routing.generate('app_stage_matrix', { id: stageId, stageType });

  const goToCreateNewAddendum = async () => {
    await new E1Request(Routing.generate('app_stageaddendum_newmodal', { stage_id: stageId }))
      .setShowLoadingModal(true)
      .submit();
  };

  const getAddQuotesLink = () => Routing.generate('app_stage_quotes', { id: stageId, stageType });

  const goToDocumentationHistory = async () => {
    await new E1Request(
      Routing.generate('app_stagedocument_viewchangesmodal', { id: stageId }),
      'GET',
    )
      .setShowLoadingModal(true)
      .submit();
  };

  const getEditProjectDetailsLink = () =>
    Routing.generate('app_stage_details', { id: stageId, stageType });

  const getAddRemoveTradesLink = () =>
    Routing.generate('app_stage_trades', { id: stageId, stageType });

  const getManageProjectAccessLink = () => Routing.generate('app_stage_team', { id: stageId });

  const goToHandoverToConstruction = async () => {
    await new E1Request(Routing.generate('app_tenderstagehandover_modal', { stage_id: stageId }))
      .setShowLoadingModal(true)
      .submit();
  };

  const goToReTenderProject = async () => {
    await new E1Request(Routing.generate('app_tenderstage_retendermodal', { id: stageId }))
      .setShowLoadingModal(true)
      .submit();
  };

  return {
    getIssueInvitationsLink,
    openUpgradeAddressBook,
    getMonitorResponsesLink,
    getIssueCorrespondenceLink,
    getSearchCorrespondenceLink,
    getAddDeleteDocumentsLink,
    getViewEditPackagesLink,
    goToCreateNewAddendum,
    getAddQuotesLink,
    goToDocumentationHistory,
    getEditProjectDetailsLink,
    getAddRemoveTradesLink,
    getManageProjectAccessLink,
    goToHandoverToConstruction,
    goToReTenderProject,
  };
};
