import { calculatePackageQuoteCoverage } from '../QuoteCoverageStatus/utils/calculateQuoteCoverageStatus';
import { GetPackagesForLettingSchedule_activePackages as ActivePackage } from '../types/GetPackagesForLettingSchedule';

const computeLatestQuoteDueBy = (activePackage: ActivePackage) =>
  new Date(
    Math.max.apply(
      null,
      activePackage.activeRfqs.map((r) => new Date(r.quoteDueAt ?? 0)),
    ),
  );

export const computePackageQuoteCoverage = (activePackages: ActivePackage[]) =>
  Object.fromEntries(
    activePackages.map((p) => {
      const coverage = calculatePackageQuoteCoverage(p);
      return [
        p.id,
        coverage
          ? {
              ...coverage,
              latestQuoteDueBy: computeLatestQuoteDueBy(p),
            }
          : null,
      ];
    }),
  );
