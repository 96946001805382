import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Button, ButtonSize, ButtonVariant, E1Link } from '@estimateone/frontend-components';
import { Action, useInteractAnalytics } from '@ascension/components/hooks/Analytics';
import { useOpenSyncableCompanyModal } from '@builder/features/SyncCompanyProfile/hooks';
import { GET_MATCHING_PROFILES } from '@subbie-directory/queries';
import { ModalTrigger } from '@builder/features/SyncCompanyProfile/types';
import {
  SearchMatchingProfiles,
  SearchMatchingProfilesVariables,
} from '@subbie-directory/types/SearchMatchingProfiles';
import styles from './styles.scss';

export const MatchingNetworkCompanyInfoBanner = ({ companyId }: { companyId: number }) => {
  const { openModal: openSyncModal } = useOpenSyncableCompanyModal();

  const { data } = useQuery<SearchMatchingProfiles, SearchMatchingProfilesVariables>(
    GET_MATCHING_PROFILES,
    {
      variables: {
        search: {
          companyId: companyId?.toString(),
        },
      },
    },
  );

  const { addEvent } = useInteractAnalytics();
  const searched = data?.searchMatchingProfiles.searched ?? null;
  const matchingProfiles = data?.searchMatchingProfiles.results ?? null;

  useEffect(() => {
    if (matchingProfiles) {
      addEvent({
        action: Action.SUBBIE_MATCHING_PROFILES_FOUND,
        matchesFound: matchingProfiles.length,
        companyId,
      });
    }
  }, [matchingProfiles, companyId, addEvent]);

  if (!searched || !matchingProfiles || matchingProfiles.length === 0) {
    return null;
  }

  return (
    <div className={styles.bannerContainer} data-testid="matching-network-company-info-banner">
      <div className={styles.textContainer}>
        <p className={styles.mainText}>Matching E1 Network company found.</p>
        <E1Link
          className={styles.learnMoreLink}
          href="https://builder.support.estimateone.com/en/articles/1708035476-connecting-a-company-to-the-e1-network-faq"
          target="_blank"
          rel="noreferrer"
        >
          Learn more about connecting with the E1 Network
        </E1Link>
      </div>

      <div className={styles.connectButton}>
        <Button
          variant={ButtonVariant.Primary}
          size={ButtonSize.Small}
          onClick={() => {
            openSyncModal({
              data: {
                searchedCompanyId: companyId?.toString(),
              },
              modalTriggeredBy: ModalTrigger.COMPANY,
            });
          }}
        >
          Review
        </Button>
      </div>
    </div>
  );
};
