import { useMutation } from '@apollo/client';
import { CREATE_STAGE_PROCORE_MAP } from './mutations';
import {
  CreateStageProcoreMap,
  CreateStageProcoreMapVariables,
} from './types/CreateStageProcoreMap';

export const useCreateStageProcoreMap = () => {
  const [createStageProcoreMap, { loading, error }] = useMutation<
    CreateStageProcoreMap,
    CreateStageProcoreMapVariables
  >(CREATE_STAGE_PROCORE_MAP);

  const submit = async (args: CreateStageProcoreMapVariables) => {
    const { data } = await createStageProcoreMap({
      variables: args,
    });

    if (data?.createStageProcoreMap.__typename === 'Stage') {
      return { loading, errors: undefined };
    }

    if (data?.createStageProcoreMap.__typename === 'Errors') {
      return { loading, errors: data.createStageProcoreMap.errors };
    }

    return { loading, errors: undefined };
  };

  return { createStageProcoreMap: submit, loading, hasError: error !== undefined };
};
