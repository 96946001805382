import { useState } from 'react';
import { ActionMenu, Ellipsis, IconButton, IconButtonSize } from '@estimateone/frontend-components';
import { DeleteHistoricalProject } from '../DeleteHistoricalProject';
import { EditHistoricalProject } from '../EditHistoricalProject';
import { HistoricalProject } from '@profiles/providers/ProfileProvider/types';

type HistoricalProjectActionMenuProps = {
  data: HistoricalProject;
};

export const HistoricalProjectActionMenu = ({ data }: HistoricalProjectActionMenuProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  return (
    <>
      <EditHistoricalProject
        data={data}
        isOpen={isEditModalOpen}
        onRequestClose={() => setIsEditModalOpen(false)}
      />

      <DeleteHistoricalProject
        data={data}
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
      />
      <ActionMenu
        visible={isMenuOpen}
        onClickOutside={() => setIsMenuOpen(false)}
        triggerElement={
          <IconButton
            icon={<Ellipsis />}
            title="Toggle Project History Options Menu"
            size={IconButtonSize.Small}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            aria-label="Toggle Project History Options Menu"
            aria-expanded={isMenuOpen}
          />
        }
      >
        <ActionMenu.Item
          onClick={() => {
            setIsMenuOpen(!isMenuOpen);
            setIsEditModalOpen(true);
          }}
        >
          Edit
        </ActionMenu.Item>
        <ActionMenu.Item
          onClick={() => {
            setIsMenuOpen(!isMenuOpen);
            setIsDeleteModalOpen(true);
          }}
        >
          Delete
        </ActionMenu.Item>
      </ActionMenu>
    </>
  );
};
