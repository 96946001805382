import { generalNotificationTransformerVersionMap } from './generalNotificationVersionMap';
import { quoteCreatedNotificationTransformerVersionMap } from './quoteCreatedNotificationVersionMap';
import { quoteRevisedNotificationTransformerVersionMap } from './quoteRevisedNotificationVersionMap';
import { NotificationTypeMap } from './types';

export const notificationTypeMap: NotificationTypeMap = {
  GENERAL: generalNotificationTransformerVersionMap,
  QUOTE_CREATED: quoteCreatedNotificationTransformerVersionMap,
  QUOTE_REVISED: quoteRevisedNotificationTransformerVersionMap,
};
