import { Dispatch, SetStateAction } from 'react';
import { Select } from '@estimateone/frontend-components';
import { ValueLabelOption } from '@estimateone/frontend-components/src/components/Form/Select/types';

const TradeFilter = ({
  stageTradeOptions,
  tradeId,
  onSelectTrade,
}: {
  stageTradeOptions: ValueLabelOption<number>[] | null;
  tradeId?: number;
  onSelectTrade: Dispatch<SetStateAction<number | undefined>>;
}) => {
  if (!stageTradeOptions) {
    return null;
  }

  const selectedValue =
    stageTradeOptions.find((tradeOpt: { value: number }) => tradeOpt.value === tradeId) ?? null;

  return (
    <Select<ValueLabelOption<number>, false>
      id="stockTrades"
      inputId="stockTrades"
      label="Find companies by Trade"
      placeholder="Start typing a Trade name..."
      isRequired
      options={stageTradeOptions}
      onChange={(trade) => onSelectTrade(trade?.value)}
      value={selectedValue}
      isClearable
    />
  );
};

export default TradeFilter;
