import { Icon, IconName, Tooltip } from '@estimateone/frontend-components';
import { TextInputField } from '../../../../shared/FinalForm/Field';
import { TenderHandoverWizardFieldName } from '../enums';
import styles from './styles.scss';

const isValidEmailAddress = (email: string) =>
  !email.match(/.+@.+\..+/) ? ["The email address isn't valid"] : undefined;

export const ContactDetails = () => (
  <section className={styles.contactPersonSection}>
    <div className={styles.headingSection}>
      <h2>Contact Details</h2>
      <Tooltip
        className={styles.tooltip}
        tooltipId="contact-details-tooltip"
        text="This person will receive enquiries and quotes from subbies for this project."
      >
        <Icon name={IconName.Help} />
      </Tooltip>
    </div>

    <div className={styles.name}>
      <TextInputField
        id="tender-handover-wizard--contact-name"
        label="Name"
        fieldName={TenderHandoverWizardFieldName.ContactName}
        validate={(fieldValue) => (!fieldValue ? ["Enter the contact person's name"] : undefined)}
        isRequired
      />
    </div>
    <div className={styles.email}>
      <TextInputField
        id="tender-handover-wizard--contact-email"
        label="Email"
        fieldName={TenderHandoverWizardFieldName.ContactEmail}
        validate={(fieldValue) =>
          !fieldValue
            ? ["Enter the contact person's email address"]
            : isValidEmailAddress(fieldValue)
        }
        isRequired
      />
    </div>
    <div className={styles.phone}>
      <TextInputField
        id="tender-handover-wizard--contact-phone"
        label="Direct Phone Number"
        fieldName={TenderHandoverWizardFieldName.ContactPhone}
      />
    </div>
  </section>
);
