import { InputFieldWrapper } from '@profiles/features/CompanyProfile/modules/FinalFormFields/InputFieldWrapper';
import { emailValidate } from '../OfficeDetailsForm/utils';
import { OfficeDetailsFieldNames, OfficeDetailsFieldLabels } from './types';

export const EmailField = () => (
  <div>
    <InputFieldWrapper<string>
      field={OfficeDetailsFieldNames.OfficeEmail}
      id={OfficeDetailsFieldNames.OfficeEmail}
      label={OfficeDetailsFieldLabels.OfficeEmail}
      validator={emailValidate}
    />
  </div>
);
