import { CellProps } from '@estimateone/frontend-components';
import { AssignedToSelect } from '../AssignedToSelect';
import { GetUsersForAccount_accountUsers as AccountUser } from '../types/GetUsersForAccount';
import { Package } from '@builder/features/ProcurementLettingSchedule/types';
import styles from '../styles.scss';

export const AssignedToCell =
  (accountUsers: AccountUser[]) =>
  ({ value, row: { original } }: CellProps<Package, AccountUser>) => (
    <div className={styles.cellAlign}>
      <AssignedToSelect
        selectedUser={value}
        users={accountUsers}
        originalPackage={original}
        key={`AssignedToSelect-${original.id}`}
      />
    </div>
  );
