import { useCallback, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useHasFixedPriceQuotesFeature } from '@shared';
import { fileToFileInput, transformQuoteFormApiErrors } from '@shared/Quotes/Form/transformers';
import { BUILDER_REVISE_QUOTE } from './mutations';
import { QuoteFormCommonFieldName } from '@ascension/enums';
import { QuoteBuilderReviseFormValues } from './types';
import { BuilderReviseQuote, BuilderReviseQuoteVariables } from './types/BuilderReviseQuote';
import { ReviseQuoteInput } from '@ascension/_gqltypes/builder.generated';
import { EntityId } from '@ascension/types';

export const useReviseQuote = (quoteId: EntityId) => {
  const [revisedQuoteId, setRevisedQuoteId] = useState<EntityId>();
  const hasFixedPriceQuoteFeature = useHasFixedPriceQuotesFeature();

  const transformValues = useCallback(
    (values: QuoteBuilderReviseFormValues): ReviseQuoteInput => {
      const amount = values[QuoteFormCommonFieldName.Amount];
      const quoteFile = values[QuoteFormCommonFieldName.QuoteFile];
      if (amount === undefined || quoteFile === undefined) {
        throw new Error('Incomplete quote form input');
      }

      return {
        quoteId,
        amount,
        quoteFile: fileToFileInput(quoteFile),
        additionalFiles: values[QuoteFormCommonFieldName.ExtraFiles]?.map(fileToFileInput) ?? [],
        details: values[QuoteFormCommonFieldName.Details],
        confirmFixedPrice: hasFixedPriceQuoteFeature
          ? (values[QuoteFormCommonFieldName.ConfirmFixedPrice] ?? false)
          : undefined,
      };
    },
    [hasFixedPriceQuoteFeature, quoteId],
  );

  const [reviseQuote, { data }] = useMutation<BuilderReviseQuote, BuilderReviseQuoteVariables>(
    BUILDER_REVISE_QUOTE,
  );

  const submit = async (values: QuoteBuilderReviseFormValues) => {
    const { data: response } = await reviseQuote({ variables: { input: transformValues(values) } });
    if (response?.reviseQuote.__typename === 'Errors') {
      return transformQuoteFormApiErrors(response.reviseQuote.errors);
    }
    return undefined;
  };

  useEffect(() => {
    if (data) {
      if (data.reviseQuote.__typename === 'Quote') {
        setRevisedQuoteId(data.reviseQuote.id);
      }
    }
  }, [data]);

  return { submit, revisedQuoteId };
};
