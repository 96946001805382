import { useTranslator } from '@profiles/providers/TranslationsProvider';
import { SingleSelectDropdownField } from '../../../modules/FinalFormFields/SingleSelectDropdownField/SingleSelectDropdownField';
import { getContractSizeOptions } from './helpers';
import { ProjectHistoryFieldLabels, ProjectHistoryFieldNames } from '../ProjectHistoryForm/types';

export const ContractSizeSelect = () => {
  const translate = useTranslator();
  const contractSizeOptions = getContractSizeOptions(translate);
  return (
    <div>
      <SingleSelectDropdownField
        id={ProjectHistoryFieldNames.ContractSize}
        label={ProjectHistoryFieldLabels.ContractSize}
        placeholder="Select a value range"
        isRequired
        fieldName={ProjectHistoryFieldNames.ContractSize}
        options={contractSizeOptions}
        menuPortalTarget={document.body}
      />
    </div>
  );
};
