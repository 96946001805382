import { SVGProps } from 'react';

export const FolderIcon = ({
  disabled,
  ...rest
}: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement> & { disabled: boolean }) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="m10 6 2 2h7v8a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6h5Z"
      fill="#5E79FF"
      stroke={disabled ? '#666666' : '#141B4D'}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 8h14v8a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8Z"
      fill="#fff"
      stroke={disabled ? '#666666' : '#141B4D'}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
