import { useMutation, MutationResult } from '@apollo/client';
import { TransformedFile } from '@shared/FileUploader';
import { STAGE_QUOTES_AND_PACKAGES } from '../../context/BuilderQuotesProvider/queries';
import { StageQuotesAndPackages } from '../../context/BuilderQuotesProvider/types/StageQuotesAndPackages';
import useApolloCache from '../../../hooks/ApolloCache';
import { ASSIGN_PACKAGE, COMMIT } from './mutations';
import { AssignQuotePackage, AssignQuotePackageVariables } from './types/AssignQuotePackage';
import {
  CommitUncategorisedQuotes,
  CommitUncategorisedQuotesVariables,
} from './types/CommitUncategorisedQuotes';
import { EntityId } from '@ascension/types';

export const useQuoteList = (stageId: EntityId) => {
  const [assign] = useMutation<AssignQuotePackage, AssignQuotePackageVariables>(ASSIGN_PACKAGE);

  const { appendElementsToCache } = useApolloCache();

  const [commit] = useMutation<CommitUncategorisedQuotes, CommitUncategorisedQuotesVariables>(
    COMMIT,
    {
      update(cache, { data }: MutationResult<CommitUncategorisedQuotes>) {
        const listCacheVariables = {
          query: STAGE_QUOTES_AND_PACKAGES,
          variables: { stageId },
        };

        if (data?.committedQuotes) {
          appendElementsToCache(
            'quotes',
            data.committedQuotes,
          )<StageQuotesAndPackages>(listCacheVariables);
        }
      },
    },
  );

  const assignPackage = async (quoteId: EntityId, packageId: EntityId) =>
    (
      await assign({
        variables: { input: { quoteId, packageId } },
      })
    ).data?.quote;

  return {
    assignPackage,
    assignPackageGetTitle: async (quoteId: EntityId, packageId: EntityId) =>
      (await assignPackage(quoteId, packageId))?.package?.title,
    upload: async (files: TransformedFile[]) =>
      (
        await commit({
          variables: {
            input: {
              stageId,
              files: files
                .filter(({ fileSize }) => fileSize !== undefined)
                .map(({ fileName, fileSize, hash }) => ({ fileName, fileSize, hash })),
            },
          },
        })
      ).data?.committedQuotes,
  };
};
