import { useCallback, useMemo, useState } from 'react';
import Routing from 'routing';
import { Button, ButtonVariant, MatrixIcon } from '@estimateone/frontend-components';
import { DocumentActionsMenu } from '@builder/common/stage/DocumentActionsMenu';
import { DocSyncController } from '@builder/features/DocSyncIntegration/DocSyncController/DocSyncController';
import {
  useAvailablePlugins,
  useHasDocumentIntegration,
} from '@builder/features/DocSyncIntegration/DocumentIntegrationProvider';
import { DocumentAddendumBanner } from '../DocumentAddendumBanner/DocumentAddendumBanner';
import { DocumentSetupBanner } from '../DocumentSetupBanner/DocumentSetupBanner';
import { shouldShowPackageSetupPrompt } from '../DocumentSetupBanner/utils';
import { RevisionFlow } from '../RevisionFlow';
import { RevisionFlowAlertBanner } from '../RevisionFlow/RevisionFlowAlertBanner';
import { useGetDocumentIntegration } from '../../../common/DocumentIntegration/hooks/useGetDocumentIntegration';
import { usePendingAddendum } from '../RevisionFlow/hooks';
import { useGetStageAddenda } from '@builder/common/stage/AddendumRevisionHistorySlider/hooks';
import { getStageTypeAsString, StageType } from '@ascension/enums';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

export type TenderDocumentsHeaderProps = {
  stageId: EntityId;
  stageType: StageType;
  stagePackageCount: number;
  activeDocumentCount: number;
  existingAddendumId: EntityId | null;
  existingAddendumName: string | null;
};

export const TenderDocumentsHeader = ({
  stageId,
  stageType,
  stagePackageCount,
  activeDocumentCount,
  existingAddendumId,
  existingAddendumName,
}: TenderDocumentsHeaderProps) => {
  const hasDocumentIntegration = useHasDocumentIntegration();
  const [showAddendumBanner, setShowAddendumBanner] = useState(true);
  const [showPackageSetupBanner, setShowPackageSetupBanner] = useState(true);

  // Revision Flow
  const { pendingAddendum, refetch: refetchPendingAddendum } = usePendingAddendum(stageId);
  const { documentIntegration } = useGetDocumentIntegration(stageId);
  const { addenda, refetchAddenda } = useGetStageAddenda(stageId);
  const [isRevisionFlowOpen, setIsRevisionFlowOpen] = useState(false);

  // TODO: move this into its own hook
  const availablePlugins = useAvailablePlugins();
  const selectedDocSyncPluginSlider = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const openSliderType = urlParams.get('openDocSyncSlider');
    const isValidSliderType = availablePlugins.some((plugin) => openSliderType === plugin.id);

    return isValidSliderType ? openSliderType : null;
  }, [availablePlugins]);

  const shouldShowSetupPrompt = useCallback(
    () =>
      shouldShowPackageSetupPrompt(
        showPackageSetupBanner,
        !!existingAddendumId,
        stagePackageCount,
        activeDocumentCount,
      ),
    [showPackageSetupBanner, existingAddendumId, stagePackageCount, activeDocumentCount],
  );

  return (
    <>
      <div className={styles.documentsHeader}>
        <div className={styles.documentActionsContainer}>
          <Button
            href={Routing.generate('app_stage_matrix', {
              id: stageId,
              stageType: getStageTypeAsString(stageType)?.toLocaleLowerCase(),
            })}
            variant={ButtonVariant.Primary}
          >
            <MatrixIcon />
            Document Matrix
          </Button>
          {hasDocumentIntegration && (
            <DocumentActionsMenu
              hasDocumentIntegration={hasDocumentIntegration}
              stageId={stageId}
              addenda={addenda}
              existingAddendumId={existingAddendumId}
              onRevisionFlowOpen={() => setIsRevisionFlowOpen(true)}
            />
          )}
        </div>
        <DocSyncController stageId={stageId} selectedDocSyncSlider={selectedDocSyncPluginSlider} />
      </div>
      {!hasDocumentIntegration && (
        <>
          {shouldShowSetupPrompt() && (
            <DocumentSetupBanner
              stageId={stageId}
              stageType={stageType}
              onDismiss={() => setShowPackageSetupBanner(false)}
            />
          )}
          {!shouldShowSetupPrompt() && showAddendumBanner && (
            <DocumentAddendumBanner
              stageId={stageId}
              existingAddendumId={existingAddendumId}
              existingAddendumName={existingAddendumName}
              onDismiss={() => setShowAddendumBanner(false)}
            />
          )}
        </>
      )}
      {documentIntegration && pendingAddendum && (
        <>
          <RevisionFlowAlertBanner
            addendum={pendingAddendum}
            documentIntegrationType={documentIntegration.type}
            onOpen={() => setIsRevisionFlowOpen(true)}
          />
          <RevisionFlow
            stageId={stageId}
            stageType={stageType}
            documentIntegrationType={documentIntegration.type}
            addendum={pendingAddendum}
            isOpen={isRevisionFlowOpen}
            onClose={async () => {
              setIsRevisionFlowOpen(false);
            }}
            onCompletion={async () => {
              await refetchPendingAddendum();
              await refetchAddenda();
            }}
          />
        </>
      )}
    </>
  );
};
