import { ApolloError } from '@apollo/client';
import { ContractSizeEnum } from '../ContractSizeSelect';
import { ProjectStatus, StageCategory } from '@ascension/_gqltypes/profiles.generated';

export type LinkedProjectOption = {
  value: string;
  label: string;
  id: number | null;
  status: ProjectStatus | null;
  state: { __typename: 'State'; id: number; name: string; shortName: string } | null;
  // region: string | null;
  category: string | null;
};

export enum ProjectHistoryFieldNames {
  Name = 'name',
  BuilderName = 'builderName',
  State = 'state',
  Region = 'region',
  CompletionDate = 'completionDate',
  ContractSize = 'contractSize',
  Category = 'category',
  LinkedProjectId = 'linkedProjectId',
  Description = 'description',
}

export enum ProjectHistoryFieldLabels {
  Name = 'Project Name',
  BuilderName = 'Builder or Client Name',
  State = 'State',
  Region = 'region',
  CompletionDate = 'Your Completion Date (estimated)',
  Category = 'Category',
  ContractSize = 'Your Contract Value (approximate)',
  Description = 'Description',
}

export type StateOption = {
  value: number;
  label: string;
};

export type CategoryOption = {
  value: StageCategory;
  label: string;
};

export type ContractSizeOption = {
  value: ContractSizeEnum;
  label: string;
};

export type ProjectHistoryFieldValues = {
  [ProjectHistoryFieldNames.Name]: string;
  [ProjectHistoryFieldNames.BuilderName]: string;
  [ProjectHistoryFieldNames.State]: StateOption;
  [ProjectHistoryFieldNames.Region]: string | null;
  [ProjectHistoryFieldNames.CompletionDate]: Date;
  [ProjectHistoryFieldNames.Category]: CategoryOption;
  [ProjectHistoryFieldNames.ContractSize]: ContractSizeOption;
  [ProjectHistoryFieldNames.LinkedProjectId]: number | null;
  [ProjectHistoryFieldNames.Description]: string | null;
};

export type ProjectHistoryFormErrors = {
  [ProjectHistoryFieldNames.Name]?: string[];
  [ProjectHistoryFieldNames.BuilderName]?: string[];
  [ProjectHistoryFieldNames.State]?: string[];
  [ProjectHistoryFieldNames.Region]?: string[];
  [ProjectHistoryFieldNames.CompletionDate]?: string[];
  [ProjectHistoryFieldNames.ContractSize]?: string[];
  [ProjectHistoryFieldNames.Category]?: string[];
  [ProjectHistoryFieldNames.Description]?: string[];
};

export type DefaultProjectHistoryFieldValues = {
  id: string;
  [ProjectHistoryFieldNames.Name]: string;
  [ProjectHistoryFieldNames.BuilderName]: string;
  [ProjectHistoryFieldNames.State]: StateOption | undefined;
  [ProjectHistoryFieldNames.Region]: string | null;
  [ProjectHistoryFieldNames.CompletionDate]: Date | undefined;
  [ProjectHistoryFieldNames.Category]: CategoryOption | undefined;
  [ProjectHistoryFieldNames.ContractSize]: ContractSizeOption | undefined;
  [ProjectHistoryFieldNames.LinkedProjectId]: number | null;
  [ProjectHistoryFieldNames.Description]: string | null;
};

export type ProjectHistoryFormProps = {
  id: string;
  onSubmit: (values: ProjectHistoryFieldValues) => Promise<unknown>;
  initialValues?: DefaultProjectHistoryFieldValues;
  canSearchProjects?: boolean;
  error?: ApolloError;
  isEdit: boolean;
};
