import { Form } from 'react-final-form';
import { sendAppcuesEditProfileEvent } from '@profiles/features/CompanyProfile/utils/appcues';
import { ModalFooter } from '../../../../modules/Modal/ModalFooter';
import { FormError } from '../../../FormError';
import { getCategoryLabel } from '../../utils';
import { CategoriesMultiSelectField } from './fields/Categories';
import { useSubmitForm } from './hooks';
import { EditCategoriesFieldName, EditCategoriesFieldValues } from './fields/types';
import { StageCategory } from '@ascension/_gqltypes/profiles.generated';

export type EditCategoriesModalProps = {
  categories: StageCategory[];
  close: () => void;
};

export const EditCategoriesForm = ({ categories, close }: EditCategoriesModalProps) => {
  const { submit: submitForm, error } = useSubmitForm();

  const onSubmit = async (values: EditCategoriesFieldValues) => {
    const errors = await submitForm(values);
    if (!errors) {
      close();
    }

    sendAppcuesEditProfileEvent();
  };

  return (
    <Form<EditCategoriesFieldValues>
      keepDirtyOnReinitialize
      onSubmit={onSubmit}
      initialValues={{
        [EditCategoriesFieldName.Categories]: categories
          ? categories.map((category) => ({
              value: category,
              label: getCategoryLabel(category),
            }))
          : [],
      }}
    >
      {({ handleSubmit }) => (
        <form id="EDIT_CATEGORIES_FORM" onSubmit={handleSubmit}>
          {error && <FormError />}
          <CategoriesMultiSelectField />
          <ModalFooter close={close} />
        </form>
      )}
    </Form>
  );
};
