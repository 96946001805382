import { ReactNode, useReducer } from 'react';
import { E1Link, SkeletonLoader, Tooltip, CheckIcon } from '@estimateone/frontend-components';
// import { ReactComponent as ErrorIconComponent } from '@ascension/images/error.svg';
import { useTranslator } from '@profiles/providers/TranslationsProvider';
import { ProfileBadgeCard } from '../../ProfileBadgeCard';
import { ProfileBadgeIconWrapper } from '../../ProfileBadgeIconWrapper';
import styles from './ABNBadge.module.scss';
import { EditABNBadge } from './EditABNBadge';
import { useOwnAccount } from '@profiles/features/CompanyProfile/hooks';

const ErrorIcon = () => (
  // <ProfileBadgeIconWrapper status="error" content={<ErrorIconComponent />} />
  <ProfileBadgeIconWrapper status="error" content="!" className={styles.errorAbnIcon} />
);
const InfoIcon = () => (
  <ProfileBadgeIconWrapper
    status="info"
    content={<CheckIcon className={styles.verifiedAbnIcon} />}
  />
);

type EditABNBadgeProps = {
  icon: ReactNode;
  heading: string;
  subHeading?: string;
};

const EditABNBadgeView = ({ heading, subHeading, icon }: EditABNBadgeProps) => {
  const translate = useTranslator();
  const [isOpen, toggle] = useReducer((curr) => !curr, false);

  return (
    <Tooltip
      text={translate('businessIdentifierTooltipText')}
      tooltipId="abn-badge-tooltip"
      place="top"
    >
      <>
        <div className={`${styles.container} ${styles.hoverEffect}`} onClick={toggle}>
          <ProfileBadgeCard heading={heading} subHeading={subHeading} icon={icon} />
          <div>
            <E1Link>Edit</E1Link>
          </div>
        </div>
        <EditABNBadge fieldValue={subHeading || ''} toggle={toggle} isOpen={isOpen} />
      </>
    </Tooltip>
  );
};

export const ABNBadgeLoading = () => (
  <div className={styles.loadingState}>
    <SkeletonLoader height="16px" />
  </div>
);

export const ABNBadgeError = () => {
  const translate = useTranslator();

  return (
    <div className={styles.container}>
      <ProfileBadgeCard
        heading={translate('missingBusinessIdentifierMessage')}
        icon={<ErrorIcon />}
      />
    </div>
  );
};

type AbnBadgeProps = {
  businessIdentifier: string | null;
};

export const ABNBadge = ({ businessIdentifier }: AbnBadgeProps) => {
  const translate = useTranslator();
  const { isOwnAccount } = useOwnAccount();

  const { heading, subHeading, icon } = businessIdentifier
    ? {
        heading: translate('businessIdentifier'),
        subHeading: businessIdentifier,
        icon: <InfoIcon />,
      }
    : {
        heading: translate('missingBusinessIdentifierMessage'),
        subHeading: undefined,
        icon: <ErrorIcon />,
      };

  if (isOwnAccount) {
    return <EditABNBadgeView heading={heading} subHeading={subHeading} icon={icon} />;
  }

  if (businessIdentifier) {
    return (
      <div className={styles.container}>
        <ProfileBadgeCard heading={heading} subHeading={subHeading} icon={icon} />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <ProfileBadgeCard
        heading={translate('businessIdentifier')}
        subHeading="Not supplied"
        icon={<ErrorIcon />}
      />
    </div>
  );
};
