import { gql } from '@apollo/client';

export const PackageSelectFragment = {
  fragments: {
    packageSelectOption: gql`
      fragment PackageSelectOption on Package {
        value: id
        label: title
      }
    `,
  },
};
