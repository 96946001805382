/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, CSSProperties } from 'react';
import { useField, UseFieldConfig } from 'react-final-form';
import { Fieldset, Legend, TextInput, TextInputProps } from '@estimateone/frontend-components';
import { FieldWrapper } from '@profiles/features/CompanyProfile/modules/FinalFormFields/FieldWrapper';
import styles from './SliderFieldWrapper.module.scss';

type SliderFieldProps = {
  field: string;
  min: number;
  max: number;
  step: number;
  suffix?: string;
  initialValue?: number;
  defaultValue?: number;
  validator?: (value?: number) => string[] | undefined;
} & TextInputProps &
  UseFieldConfig<number>;

export const SliderFieldWrapper = ({
  field,
  id,
  label,
  hideLabel,
  min,
  max,
  step,
  suffix,
  initialValue,
  defaultValue,
  validator,
  parse,
  format,
  ...props
}: SliderFieldProps) => {
  const {
    input,
    meta: { error, submitError, touched },
  } = useField<number>(field, {
    initialValue,
    defaultValue,
    validate: validator ?? undefined,
    parse,
    format,
  });

  const progressStyle = useCallback(() => {
    const value = Math.max(min, Math.min(Number(input.value || 0), max));
    const progress = ((value - min) / (max - min)) * 100;
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return { '--range-progress': `${progress}%` } as CSSProperties;
  }, [min, max, input.value])();

  return (
    <div style={progressStyle}>
      <Fieldset>
        <Legend hidden={hideLabel}>{label}</Legend>
        <FieldWrapper errors={error || submitError} hideErrorMessages={false} showErrors={touched}>
          <div className={styles.sliderFieldContainer}>
            <div className={styles.sliderTextInput}>
              <TextInput type="number" id={id} label={label} hideLabel {...input} {...props} />
            </div>
            <div className={styles.sliderRangeInput}>
              <div>
                <span>
                  {input.value}
                  {suffix}
                </span>
              </div>
              <input type="range" min={min} max={max} step={step} {...input} />
            </div>
          </div>
        </FieldWrapper>
      </Fieldset>
    </div>
  );
};
