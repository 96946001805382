import { gql } from '@apollo/client';
import { addressFragment, addressBookCompanyFragment, profileFragment } from './queries';

export const IGNORE_CONNECTION_SUGGESTION = gql`
  ${addressFragment}
  ${addressBookCompanyFragment}
  ${profileFragment}
  mutation IgnoreConnectionSuggestion($input: NetworkConnectionSuggestionMutationInput!) {
    ignoreConnectionSuggestion(input: $input) {
      ... on ConnectionSuggestion {
        id
        addressBookCompany {
          ...AddressBookCompanyFragment
        }
        profile {
          ...ProfileFragment
        }
        snippets {
          type
          value
        }
        ignoredAt
        confirmedAt
      }
      ... on Errors {
        errors {
          field
          message
        }
      }
    }
  }
`;

export const CONFIRM_CONNECTION_SUGGESTION = gql`
  ${addressFragment}
  ${addressBookCompanyFragment}
  ${profileFragment}
  mutation ConfirmConnectionSuggestion($input: NetworkConnectionSuggestionMutationInput!) {
    confirmConnectionSuggestion(input: $input) {
      ... on ConnectionSuggestion {
        id
        addressBookCompany {
          ...AddressBookCompanyFragment
        }
        profile {
          ...ProfileFragment
        }
        snippets {
          type
          value
        }
        ignoredAt
        confirmedAt
        connectedNetworkCompany {
          __typename
          id
          name
          defaultOffice {
            id
            address {
              id
              suburb
              city
              province
              state {
                id
                shortName
              }
              country {
                id
                shortName
              }
            }
          }
          stockTrades {
            ... on NetworkCompanyTradeInterface {
              id
              name
            }
          }
          contractSizeMin
          contractSizeMax
          logoUrl
        }
      }
      ... on Errors {
        errors {
          field
          message
        }
      }
    }
  }
`;
