import { QuoteCoverageStatistics } from '../QuoteCoverageStatus/utils/calculateQuoteCoverageStatus';

type QuoteCoverageAttributeProps = {
  coverage: QuoteCoverageStatistics | null;
  attribute: keyof QuoteCoverageStatistics;
};

export const QuoteCoverageAttribute = ({ coverage, attribute }: QuoteCoverageAttributeProps) => {
  if (!coverage) {
    return <div>-</div>;
  }

  const attrVal = coverage[attribute];
  return <div>{attrVal}</div>;
};
