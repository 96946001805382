import { useEffect, useState } from 'react';
import { Step } from '@subbie/features/AccountUpgradeFeature/enum';
import { PlanName } from '@plan-select/context/PlanSelectProvider/types';
import { getDropdownDisplayNameFromProductName } from '@plan-select/context/PlanSelectProvider/utils/utils';
import {
  AccountUpgradeContextProps,
  Product,
  useAccountUpgradeContext,
} from '@subbie/features/AccountUpgradeFeature/context';
import { EntityId } from '@ascension/types';
import { ProductAddon, ProductWithPrice } from '@subbie/features/AccountUpgradeFeature/types';

const useProducts = (): Pick<
  AccountUpgradeContextProps,
  'product' | 'availableProducts' | 'recommendedProduct' | 'availableAddons' | 'liteProducts'
> => {
  const { product, availableProducts, availableAddons, recommendedProduct, liteProducts } =
    useAccountUpgradeContext();

  return { product, availableProducts, availableAddons, recommendedProduct, liteProducts };
};

type UseSelectProductId = () => {
  selectedProductId: EntityId | undefined;
  setSelectedProductId: (id: EntityId | undefined) => void;
};

export const useAvailableProducts = (): Product[] => useProducts().availableProducts;
export const useAvailableAddons = (): ProductAddon[] => useProducts().availableAddons;
/** SUBBIE_CREDIT_EXPERIMENT_ALPHA_CODE */
export const useLiteProducts = (): Product[] => useProducts().liteProducts;

export const useSelectProductId: UseSelectProductId = () => {
  const { product, recommendedProduct } = useProducts();
  const [selectedProductId, setSelectedProductId] = useState<EntityId>();

  useEffect(() => {
    if (product) {
      setSelectedProductId(product.id);
    }
    if (recommendedProduct) {
      setSelectedProductId(recommendedProduct.id);
    }
  }, [product, recommendedProduct]);

  return { selectedProductId, setSelectedProductId };
};

/** SUBBIE_CREDIT_EXPERIMENT_ALPHA_CODE */
export const useSelectLiteProductId: UseSelectProductId = () => {
  const { liteProducts, recommendedProduct } = useProducts();
  const [selectedProductId, setSelectedProductId] = useState<EntityId>();

  useEffect(() => {
    if (recommendedProduct) {
      const stateSuffix = getDropdownDisplayNameFromProductName(recommendedProduct.name);
      const defaultLiteProduct = liteProducts.find((p) => p.name.endsWith(stateSuffix));
      setSelectedProductId(defaultLiteProduct?.id ?? liteProducts[0].id);
    } else if (liteProducts.length > 0) {
      setSelectedProductId(liteProducts[0].id);
    }
  }, [liteProducts, recommendedProduct]);

  return { selectedProductId, setSelectedProductId };
};

export const useSelectProductAndAddonForPlanAndContinue = (): ((
  p: ProductWithPrice,
  addons: ProductWithPrice[],
  planName: PlanName,
) => void) => {
  const { setProduct, changeStep, setSelectedPlan, setAddons } = useAccountUpgradeContext();

  return (product: ProductWithPrice, addons: ProductWithPrice[], planName: PlanName) => {
    setProduct(product);
    setSelectedPlan(planName);
    changeStep(Step.UPGRADE_CHECKOUT);
    setAddons(addons);
  };
};
