import { joinClassNames } from '@estimateone/frontend-components';
import { FileIcon, FolderIcon, LockedIcon } from '../DocumentIcons';
import styles from './styles.scss';

interface DocumentLabelProps {
  name: string;
  type: 'file' | 'folder';
  isLocked: boolean;
}

export const DocumentLabel = ({ name, type, isLocked }: DocumentLabelProps) => (
  <div className={joinClassNames(styles.documentLabel, isLocked ? styles.documentLabelLocked : '')}>
    {type === 'file' ? <FileIcon disabled={isLocked} /> : <FolderIcon disabled={isLocked} />}
    <div>{name}</div>
    {isLocked ? <LockedIcon /> : null}
  </div>
);
