import { ReactElement } from 'react';
import { NotificationType } from '@ascension/_gqltypes/builder.generated';

export type NotificationDataKV = Map<string, string>;
export type TopicMap = Map<string, string>;

export interface Transformer {
  buildText: (data: NotificationDataKV) => ReactElement;
  buildLink: (data: NotificationDataKV) => string | null;
}

export interface GroupableTransformer extends Transformer {
  getGroupingKey: (topicMap: TopicMap) => string;
  buildGroupText: (data: NotificationDataKV, count: number) => ReactElement;
  buildGroupLink: (data: NotificationDataKV) => string | undefined;
}

export const isGroupableTransformer = (
  transformer: Transformer,
): transformer is GroupableTransformer =>
  (transformer as GroupableTransformer).getGroupingKey !== undefined;

export type TransformerVersionMap = {
  [K in number]: Transformer;
};

export type NotificationTypeMap = {
  [K in NotificationType]: TransformerVersionMap;
};
