import { Chip } from '@estimateone/frontend-components';
import { ChipGroup } from '@estimateone/frontend-components/src/components/ChipGroup';
import {
  maxOptions,
  minOptions,
} from '@builder/features/SubbieNetwork/components/SubbieNetworkFilterDrawer/ContractSizeFilter';
import { WorkforceSizeOpts } from '@builder/features/SubbieNetwork/components/SubbieNetworkFilterDrawer/WorkforceSizeFilter';
import { SelectedFiltersAtom } from '../../atoms';
import {
  useActiveConfirmedFilters,
  useCompanyListFilterVisible,
  useConfirmedFilterValues,
} from '@builder/features/SubbieNetwork/components/SubbieNetworkFilterDrawer/hooks';
import {
  useCategoryOptions,
  useCompanyTags,
} from '@builder/features/SubbieNetwork/providers/AscensionDataProvider/hooks';
import { ContractSizeMaxEnum, ContractSizeMinEnum } from '@ascension/_gqltypes/builder.generated';

const determineContractSizeText = (
  contractSizeMin: ContractSizeMinEnum | undefined,
  contractSizeMax: ContractSizeMaxEnum | undefined,
) => {
  const minText = minOptions.find(({ value }) => value === contractSizeMin)?.label;
  const maxText = maxOptions.find(({ value }) => value === contractSizeMax)?.label;
  if (minText === undefined && maxText === undefined) {
    return undefined;
  }
  if (minText !== undefined && maxText === undefined) {
    return `At least ${minText}`;
  }
  if (minText === undefined && maxText !== undefined) {
    return `At most ${maxText}`;
  }
  return `${minText} - ${maxText}`;
};

const searchAreaText = ({
  searchArea,
  distanceLabel,
  locationLabel,
}: Pick<SelectedFiltersAtom, 'searchArea' | 'locationLabel' | 'distanceLabel'>) => {
  if (!locationLabel) {
    return '';
  }

  if (searchArea === 'service-area') {
    return locationLabel;
  }

  if (searchArea === 'office-location' && distanceLabel) {
    return `Office within ${distanceLabel} of ${locationLabel}`;
  }

  return '';
};

export type FilterChipsProps = { readonly onFilterChipClicked: () => void };

export const FilterChips = ({ onFilterChipClicked }: FilterChipsProps) => {
  const confirmedFilterValues = useConfirmedFilterValues();
  const categoryOptions = useCategoryOptions();
  const companyTagOptions = useCompanyTags();
  const activeFilters = useActiveConfirmedFilters();
  const companyListFilterVisible = useCompanyListFilterVisible();

  return (
    <ChipGroup>
      <Chip active={activeFilters.searchAreaFilter} onClick={onFilterChipClicked}>
        <span>Search Area</span>
        {searchAreaText(confirmedFilterValues) && (
          <span>{searchAreaText(confirmedFilterValues)}</span>
        )}
      </Chip>
      {/*
        Do not delete as we'll enable Relationship filter in the future
        <Chip active={activeFilters.connectionTypeFilter} onClick={onFilterChipClicked}>
          <span>Relationship</span> <span>{confirmedFilterValues.relationshipType?.toString()}</span>
        </Chip>
      */}
      <Chip active={activeFilters.contractSizeFilter} onClick={onFilterChipClicked}>
        <span>Contract Size</span>
        <span>
          {determineContractSizeText(
            confirmedFilterValues.contractSizeMin,
            confirmedFilterValues.contractSizeMax,
          )}
        </span>
      </Chip>
      <Chip active={activeFilters.workforceSizeFilter} onClick={onFilterChipClicked}>
        <span>Employees</span>
        <span>
          {
            WorkforceSizeOpts.find(({ value }) => value === confirmedFilterValues.workforceSize)
              ?.label
          }
        </span>
      </Chip>
      <Chip active={activeFilters.categoryFilter} onClick={onFilterChipClicked}>
        <span>Category</span>
        <span>
          {confirmedFilterValues.categoryId !== undefined
            ? categoryOptions?.find(({ value }) => value === confirmedFilterValues.categoryId)
                ?.label
            : undefined}
        </span>
      </Chip>
      {companyListFilterVisible && (
        <Chip active={activeFilters.companyListFilter} onClick={onFilterChipClicked}>
          <span>Company List</span>
          <span>
            {
              companyTagOptions?.find(({ value }) => value === confirmedFilterValues.companyListId)
                ?.label
            }
          </span>
        </Chip>
      )}
    </ChipGroup>
  );
};
