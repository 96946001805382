import { GroupBase } from 'react-select';
import {
  comparePackageTitlesAlphaFullSetFirst,
  comparePackageTitlesAlphaFullSetLast,
} from '@shared/Util/sorting';
import { GroupedPackageOption, PackageOption } from './types';
import { EntityId, Package } from '@subbie/modal/ProjectSlider/types';

const shouldDisplayPackage = ({ contentLastAddedAt }: Package): boolean =>
  contentLastAddedAt !== null;

const packageToOption = ({ id, title, customQuotesDueAt }: Package): PackageOption => ({
  value: id,
  label: title,
  quotesDueAt: customQuotesDueAt,
});

export const getPackageSelectOptions = (packages: Package[]): PackageOption[] =>
  packages
    .sort((a, b) => comparePackageTitlesAlphaFullSetFirst(a.title, b.title))
    .filter(shouldDisplayPackage)
    .map(packageToOption);

// The below functions are used for the grouped/suggested package select

const packageRequiresUserStockTrade =
  (userStockTradeIds: EntityId[]) =>
  (pkg: Package): boolean =>
    pkg.trade?.stockTrade?.id !== undefined &&
    userStockTradeIds.includes(pkg.trade?.stockTrade?.id);

const packageToGroupedOption =
  (userStockTradeIds: EntityId[]) =>
  (pkg: Package): GroupedPackageOption => ({
    value: pkg.id,
    label: pkg.title,
    isFullSet: pkg.fullSet,
    quotesDueAt: pkg.customQuotesDueAt,
    matchesUserStockTrade: packageRequiresUserStockTrade(userStockTradeIds)(pkg),
  });

const foldOptionsIntoGroups = (
  acc: GroupBase<GroupedPackageOption>[],
  option: GroupedPackageOption,
) => {
  if (option.matchesUserStockTrade) {
    const options = acc[0].options.concat([option]);
    return [{ ...acc[0], options }, acc[1]];
  }
  const options = acc[1].options.concat([option]);
  return [acc[0], { ...acc[1], options }];
};

export const getGroupedPackageSelectOptions = (
  packages: Package[],
  userStockTradeIds: EntityId[],
) =>
  [...packages]
    .sort((a, b) => comparePackageTitlesAlphaFullSetLast(a.title, b.title))
    .sort((a, b) => {
      const getScore = (pkg: Package) =>
        packageRequiresUserStockTrade(userStockTradeIds)(pkg) ? 1 : 0;

      return getScore(b) - getScore(a);
    })
    .filter(shouldDisplayPackage)
    .map(packageToGroupedOption(userStockTradeIds))
    .reduce(foldOptionsIntoGroups, [
      { label: 'Recommended', options: new Array<GroupedPackageOption>() },
      { label: 'Other Packages', options: new Array<GroupedPackageOption>() },
    ]);
