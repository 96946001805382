import { comparePackageTitlesAlphaFullSetFirst } from '@shared';
import { Package, StageQuote } from '../../../context/BuilderQuotesProvider';
import { EntityId } from '@ascension/types';

export const UNCATEGORISED = -1;

const getTitle = (quote: StageQuote) => quote.title;
const getPackageIdOrDefault = (quote: StageQuote) => quote.package?.id || UNCATEGORISED;

export const sortQuotesAndGroupByPackage = (packages: Package[], quotes: StageQuote[]) => {
  const packageTitlesByPackageId = packages.reduce(
    (acc, pk) => (pk ? acc.set(pk.id, pk.title) : acc),
    new Map<EntityId, string>(),
  );
  const deletedPackageIds = packages.reduce(
    (acc, pk) => (pk.deletedAt ? acc.add(pk.id) : acc),
    new Set<EntityId>(),
  );

  const quotesGroupedByPackage = quotes.reduce((groupsMap, quote) => {
    const packageId = getPackageIdOrDefault(quote);
    const currentPackageQuotes = groupsMap.get(packageId) ?? [];

    return groupsMap.set(packageId, currentPackageQuotes.concat(quote));
  }, new Map<EntityId, StageQuote[]>());

  const sortedPackageGroups = Array.from(quotesGroupedByPackage).sort(([pkA], [pkB]) => {
    if (pkA === UNCATEGORISED) return -1;
    if (pkB === UNCATEGORISED) return 1;

    if (deletedPackageIds.has(pkA)) return 1;
    if (deletedPackageIds.has(pkB)) return -1;

    return comparePackageTitlesAlphaFullSetFirst(
      packageTitlesByPackageId.get(pkA) ?? '',
      packageTitlesByPackageId.get(pkB) ?? '',
    );
  });

  return {
    packageTitlesByPackageId,
    deletedPackageIds,
    sortedPackageGroups,
  };
};

export const sortFilterActivePackages = (packages: Package[]) =>
  packages
    .filter(({ deletedAt }) => deletedAt === null)
    .sort(({ title: titleA }, { title: titleB }) =>
      comparePackageTitlesAlphaFullSetFirst(titleA, titleB),
    );

export const sortQuotesWithinGroup = (a: StageQuote, b: StageQuote) =>
  getTitle(a).localeCompare(getTitle(b));
