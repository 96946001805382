import { useLazyQuery } from '@apollo/client';
import { GET_NOTIFY_PROGRESS } from '../queries';
import { GetNotifyProgress, GetNotifyProgressVariables } from '../types/GetNotifyProgress';
import { EntityId } from '@ascension/types';

export const useGetNotifyProgress = (stageId: EntityId) => {
  const [getNotifyProgress, { data, loading, startPolling, stopPolling }] = useLazyQuery<
    GetNotifyProgress,
    GetNotifyProgressVariables
  >(GET_NOTIFY_PROGRESS, { variables: { stageId } });

  return {
    getNotifyProgress: () => getNotifyProgress({ variables: { stageId } }),
    startPolling,
    stopPolling,
    data,
    loading,
  };
};
