import { gql } from '@apollo/client';

export const GET_SERVICE_AREA_BOUNDARIES_FOR_COUNTRY = gql`
  query GetServiceAreaBoundariesForCountry {
    serviceAreaBoundariesForCountry {
      id
      name
      shortName
      type
      __typename
    }
  }
`;
