/* eslint-disable import/prefer-default-export */
import $ from 'jquery';
import Routing from 'routing';
import { escape } from 'lodash';
import E1Request from '../classes/E1Request';
import Form from '../classes/Form';
import ConstructionDirectoryList from './construct_directory_list';

/**
 * @param {number} stageId
 * @param {number} userId
 */
export function transmitPackageToSingleContact(stageId, userId) {
  const route = Routing.generate('app_constructionstagepackage_transmitsinglemodal', {
    id: stageId,
  });
  const request = new E1Request(route, 'GET', { userId });
  request.submit();
}

function ContactTableActions($target, stageId, contactDirectoryList) {
  this.$target = $target;
  this.contactDirectoryList = contactDirectoryList;
  this.stageId = stageId;
  this.selectedIds = [];
  this.applyListeners();
}

ContactTableActions.prototype = {
  applyListeners() {
    const self = this;
    self.contactDirectoryList.$target.on('selected-count-update', (evt, data) => {
      if (typeof data.selected !== 'undefined') {
        self.updateSelectedIds(data.selected);
      }
    });
  },
  updateSelectedIds(selectedArr) {
    const self = this;
    const selectedIds = [];
    $.each(selectedArr, (i, selected) => {
      selectedIds.push(selected.user_id);
    });
    self.selectedIds = selectedIds;

    self.toggleDisabledStatus();
  },
  toggleDisabledStatus() {
    const self = this;
    if (self.selectedIds.length > 0) {
      self.$target.find('button').removeClass('disabled');
    } else {
      self.$target.find('button').addClass('disabled');
    }
  },
};

$(() => {
  if ($('.construct-directory').length > 0) {
    const $body = $('body');
    const $tables = $('table.contacts-table-dataTable');
    const stageId = $('.construct-directory').attr('data-stage-id');
    const isBuilder = window.global.account.type === 1;

    $tables.toArray().forEach((table) => {
      const $table = $(table);
      const contactType = $table.attr('data-contact-type');
      const requestData = {
        id: stageId,
        category: contactType,
      };
      const checkboxHidden =
        contactType !== 'archived' && (!isBuilder || contactType === 'suppliers');

      const route = Routing.generate('app_constructionstagedirectory_fetch', requestData);
      const $container = $table.closest('.contact-table-container');
      const $placeholderRow = $table.find('tr.data-table-placeholder').first();
      const contactDataTable = new ConstructionDirectoryList(
        $table,
        (list) => {
          list.table = list.$target.DataTable({
            paging: false,
            data: list.data,
            info: false,
            order: [[1, 'asc']],
            columns: [
              {
                data: 'selected',
                orderable: false,
                defaultContent: '',
                visible: checkboxHidden,
                class: 'checkboxCol',
                render(data, display, row, details) {
                  const template = $placeholderRow.find('td').get(details.col);
                  const $template = $(template);
                  const showCheckbox =
                    row.can_issue_correspondence &&
                    $template.attr('data-requires-checkbox') === '1';

                  if (showCheckbox) {
                    const $checkbox = $("<input type='checkbox'>")
                      .addClass('select-checkbox')
                      .attr('data-id', row.id);
                    if (row.selected) {
                      $checkbox.attr('checked', 'checked');
                    }

                    list.data[details.row] = row;
                    const $ctn = $('<div>').append($checkbox);
                    return $ctn.prop('outerHTML');
                  }
                  return '';
                },
              },
              {
                data: null,
                render(data, display, row) {
                  const firstName = row.firstName ? row.firstName : '';
                  const lastName = row.lastName ? row.lastName : '';
                  const companyName = row.account_name ? row.account_name : '';
                  const contactCompanyName = `${escape(firstName)} ${escape(
                    lastName,
                  )}<span class="d-block company-name">${escape(companyName)}</span>`;

                  return contactCompanyName;
                },
              },
              {
                data: null,
                render(data, display, row) {
                  let phone;
                  const $detailsContainer = $('<div>');
                  const $emailContainer = $('<div>');

                  if (contactType === 'suppliers') {
                    phone = row.user_phone || row.office_phone;
                  } else {
                    phone = row.office_phone || row.default_office_phone;
                  }
                  phone = phone || '';

                  const $mailto = $('<a/>');
                  $mailto.addClass('link primary');
                  $mailto.text(row.email);
                  $mailto.attr('href', `mailto:${row.email}`);

                  if (row.has_email_error) {
                    const $errorSpan = $('<span>')
                      .addClass('invitation-error icon icon-attention tooltip-trigger')
                      .attr('title', 'Issue delivering email')
                      .attr('data-stage-id', stageId)
                      .attr('data-entry-id', row.entry_id);
                    $detailsContainer.append($errorSpan);
                  }

                  $detailsContainer.append(
                    $('<span>').text(`${phone}`),
                    $emailContainer.addClass('d-block').append($mailto.prop('outerHTML')),
                  );

                  return $detailsContainer.prop('outerHTML');
                },
              },
              {
                data: null,
                visible: isBuilder && contactType === 'suppliers' && list.stageHasPackageViewAccess,
                orderable: false,
                render(data, display, row) {
                  if (Array.isArray(row.packages) && row.packages.length > 0) {
                    return row.packages.map((p) => escape(p)).join('<br />');
                  }

                  return '-';
                },
              },
              {
                data: null,
                visible: contactType === 'suppliers' && list.stageHasPackageViewAccess,
                orderable: false,
                render(data, display, row) {
                  const template = $placeholderRow.find('td').get(3);
                  const $main = $(template).find('.doc-status-container').clone();
                  if ($main.length === 0) {
                    return '';
                  }

                  const getDocCount = (docCount) => {
                    if (docCount === null) {
                      return 0;
                    }

                    return parseInt(docCount, 10);
                  };

                  const current = getDocCount(row.doc_currentTransmittedDownloaded);
                  const obsolete = getDocCount(row.doc_currentTransmittedObsolete);
                  const unseen = getDocCount(row.doc_currentTransmitted) - current;

                  const $noneSent = $main.find('.none-sent');

                  const createLink = (routeName, selector, count) => {
                    const route = Routing.generate(routeName, {
                      id: stageId,
                      user: row.user_id,
                    });

                    $main
                      .find(selector)
                      .addClass('e1-modal-link')
                      .attr('data-route', route)
                      .append(count);

                    $noneSent.remove();
                  };

                  const removeLink = (selector) => {
                    $main.find(selector).remove();

                    $noneSent
                      .find('.transmit-package-trigger')
                      .attr('data-stage-id', stageId)
                      .attr('data-user-id', row.user_id);
                  };

                  if (current === 0) {
                    removeLink('.current');
                  } else {
                    createLink(
                      'app_constructionstagedirectoryuser_viewdownloadedmodal',
                      '.current',
                      current,
                    );
                  }

                  if (obsolete === 0) {
                    removeLink('.obsolete');
                  } else {
                    createLink(
                      'app_constructionstagedirectoryuser_viewobsoletemodal',
                      '.obsolete',
                      obsolete,
                    );
                  }

                  if (unseen === 0) {
                    removeLink('.unseen');
                  } else {
                    createLink(
                      'app_constructionstagedirectoryuser_viewmissingmodal',
                      '.unseen',
                      unseen,
                    );
                  }

                  return $main.prop('outerHTML');
                },
              },
              {
                data: 'role',
                render: $.fn.dataTable.render.text(),
              },
              {
                data: null,
                orderable: false,
                render(data, display, row) {
                  const template = $placeholderRow.find('td').get(5);
                  const $main = $(template).find('.options-dropdown').clone();

                  if ($main.length === 0) {
                    return '';
                  }

                  $main.first().attr('data-user-id', row.user_id).attr('data-stage-id', stageId);

                  $main
                    .find('.transmit-package-trigger')
                    .attr('data-user-id', row.user_id)
                    .attr('data-stage-id', stageId);

                  if (contactType === 'archived') {
                    $main.find("[data-toggle-action='archive']").parent().remove();
                  } else {
                    $main.find("[data-toggle-action='unarchive']").parent().remove();
                  }

                  if (contactType !== 'suppliers') {
                    $main.find('.removable-parent').parent().remove();
                    $main.find('.supplier-divider').remove();
                  }

                  return $main.prop('outerHTML');
                },
              },
            ],
            dom: 'Rrtp',
          });
          list.toggleTableDisplay(true);
          list.$target.closest('.loading-container').addClass('has-loaded');
          list.init(list);
        },
        route,
      );

      $(document).on('data-colleagues-updated', () => {
        contactDataTable.updateTable(true);
      });

      const $tableActionContainer = $container.find('.table-control-panel-container');
      if ($tableActionContainer.length > 0) {
        $tableActionContainer.detach().prependTo('.bottom-bar');
        $tableActionContainer.toArray().forEach((container) => {
          new ContactTableActions($(container), stageId, contactDataTable); // eslint-disable-line no-new
        });
      }
    });

    $body.on('click', '.add-colleague-project', (e) => {
      const stageId = $(e.currentTarget).attr('data-stage-id');

      const route = Routing.generate('app_constructionstagedirectory_addcolleague', {
        id: stageId,
      });
      const request = new E1Request(route);
      request.show_loading_modal = true;
      request.submit();
    });

    $body.on('submit', 'form.add-colleague-form', (e) => {
      e.preventDefault();

      const $form = $(e.currentTarget);
      const magicForm = new Form($form);

      magicForm.extraCallback = () => {
        $(document).trigger('data-colleagues-updated');
      };

      magicForm.submit();

      return false;
    });

    $body.on('click', '.add-subcontractor-project', (e) => {
      const stageId = $(e.currentTarget).attr('data-stage-id');
      const route = Routing.generate('app_constructionstagedirectory_addsubcontractormodal', {
        id: stageId,
        company: null,
      });
      const request = new E1Request(route);
      request.show_loading_modal = true;
      request.submit();
    });

    $body.on('click', '.edit-user-role', (e) => {
      const dropdown = $(e.currentTarget).parents('.options-dropdown');
      const stageId = dropdown.attr('data-stage-id');
      const userId = dropdown.attr('data-user-id');

      const route = Routing.generate('app_constructionstagedirectoryuser_editrole', {
        id: stageId,
        user: userId,
      });
      const request = new E1Request(route);
      request.submit();
    });

    $body.on('click', '.transmit-package-trigger', (e) => {
      const $target = $(e.currentTarget);
      const stageId = $target.attr('data-stage-id');
      const userId = $target.attr('data-user-id');

      transmitPackageToSingleContact(stageId, userId);
    });

    $body.on('click', '.correspondence-trigger-directory', (e) => {
      const dropdown = $(e.currentTarget).parents('.options-dropdown');
      const stageId = dropdown.attr('data-stage-id');
      const userId = dropdown.attr('data-user-id');

      const route = Routing.generate('app_usercorrespondence_newslider', { stageId });
      const request = new E1Request(route, 'POST', { selected: [userId] });
      request.submit();
    });

    $body.on('click', '.resend-invitation-email', (e) => {
      const dropdown = $(e.currentTarget).parents('.options-dropdown');
      const stageId = dropdown.attr('data-stage-id');
      const userId = dropdown.attr('data-user-id');

      const route = Routing.generate('app_constructionstagedirectory_resendinvitationemail', {
        id: stageId,
        user_id: userId,
      });
      const request = new E1Request(route, 'POST');
      request.submit();
    });

    $body.on('click', '.construct-directory-toggle', (e) => {
      const dropdown = $(e.currentTarget).parents('.options-dropdown');
      const params = {
        id: dropdown.attr('data-stage-id'),
        user: dropdown.attr('data-user-id'),
        action: $(e.currentTarget).attr('data-toggle-action'),
      };

      const route = Routing.generate('app_constructionstagedirectoryuser_toggle', params);
      const request = new E1Request(route, 'POST');
      request.extraCallback = () => {
        $(document).trigger('data-colleagues-updated');
      };
      request.submit();
    });

    $body.on('click', '.invitation-error', (e) => {
      const stageId = $(e.currentTarget).attr('data-stage-id');
      const entryId = $(e.currentTarget).attr('data-entry-id');
      const route = Routing.generate('app_constructionstagedirectoryuser_emailerror', {
        id: stageId,
        entry: entryId,
      });
      const request = new E1Request(route);
      request.show_loading_modal = true;
      request.submit();
    });

    $body.on('submit', 'form.edit-user-role-modal-form', (e) => {
      e.preventDefault();

      const $form = $(e.currentTarget);
      const magicForm = new Form($form);

      magicForm.extraCallback = () => {
        $(document).trigger('data-colleagues-updated');
      };
      magicForm.submit();

      return false;
    });
  }
});
