import { LettingScheduleStatus, getLettingScheduleStatusCounts } from '../LettingScheduleStatus';
import { LettingSchedulePriority } from '@ascension/_gqltypes/builder.generated';
import { Package } from '@builder/features/ProcurementLettingSchedule/types';

type StatusFilterOption = {
  label: string;
  value: LettingScheduleStatus;
};

export const getStatusFilterOptions = (packages: Package[]): StatusFilterOption[] => {
  const statusCounts = getLettingScheduleStatusCounts(packages);
  return [
    {
      label: `Draft (${statusCounts[LettingScheduleStatus.Draft]})`,
      value: LettingScheduleStatus.Draft,
    },
    {
      label: `Pricing (${statusCounts[LettingScheduleStatus.Pricing]})`,
      value: LettingScheduleStatus.Pricing,
    },
    {
      label: `Awarded (${statusCounts[LettingScheduleStatus.Awarded]})`,
      value: LettingScheduleStatus.Awarded,
    },
  ];
};

type PriorityFilterOption = {
  label: string;
  value: LettingSchedulePriority;
};

export const priorityFilterOptions: PriorityFilterOption[] = [
  {
    label: 'Critical',
    value: LettingSchedulePriority.CRITICAL,
  },
  {
    label: 'High',
    value: LettingSchedulePriority.HIGH,
  },
  {
    label: 'Medium',
    value: LettingSchedulePriority.MEDIUM,
  },
  {
    label: 'Low',
    value: LettingSchedulePriority.LOW,
  },
];

export const getAssignedToFilterOptions = (packages: Package[]) => {
  const assignedToUsersMap = new Map();

  // eslint-disable-next-line fp/no-let
  let noneOptionCount = 0;

  packages.forEach((pkg) => {
    const user = pkg.lettingScheduleDetails?.assignedToUser;
    if (user) {
      const existingUser = assignedToUsersMap.get(user.id);
      if (existingUser) {
        // eslint-disable-next-line fp/no-mutation
        existingUser.count += 1;
      } else {
        assignedToUsersMap.set(user.id, {
          id: user.id,
          count: 1,
          fullName: user.fullName,
        });
      }
    } else {
      // eslint-disable-next-line fp/no-mutation
      noneOptionCount += 1;
    }
  });

  const assignedToUserArray = Array.from(assignedToUsersMap.values());
  const assignedToFilterOptions = assignedToUserArray
    .sort((a, b) => a.fullName.localeCompare(b.fullName))
    .map((user) => ({
      label: `${user.fullName} (${user.count})`,
      value: user.id,
    }));

  return [
    {
      label: `None (${noneOptionCount})`,
      value: 'none',
    },
    ...assignedToFilterOptions,
  ];
};
