import { useCallback, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useMutation, useQuery } from '@apollo/client';
import { Action, useInteractAnalytics } from '@ascension/components/hooks/Analytics';
import { NOTIFICATIONS_LIST_LAST_OPENED, RECENT_NOTIFICATIONS } from './queries';
import { MARK_NOTIFICATIONS_AS_VIEWED, SET_NOTIFICATIONS_LIST_LAST_OPENED } from './mutations';
import {
  MarkNotificationsAsViewed,
  MarkNotificationsAsViewedVariables,
} from './types/MarkNotificationsAsViewed';
import { NotificationsListLastOpened } from './types/NotificationsListLastOpened';
import { SetNotificationsListLastOpened } from './types/SetNotificationsListLastOpened';
import { NotificationType } from '@ascension/_gqltypes/builder.generated';
import { GetRecentNotifications } from '@builder/features/NotificationsFeature/types/GetRecentNotifications';

const NOTIFICATION_POLLING_IDLE_TIMEOUT_SECONDS = 60 * 5; // Five minutes

export const useNotifications = (defaultPollIntervalSeconds: number) => {
  const [pollIntervalSeconds, setPollIntervalSeconds] = useState(defaultPollIntervalSeconds);
  const onIdle = () => setPollIntervalSeconds(60 * 60); // One hour
  const onActive = () => setPollIntervalSeconds(defaultPollIntervalSeconds);

  useIdleTimer({
    timeout: 1000 * NOTIFICATION_POLLING_IDLE_TIMEOUT_SECONDS,
    onIdle,
    onActive,
  });

  const { data } = useQuery<GetRecentNotifications>(RECENT_NOTIFICATIONS, {
    pollInterval: pollIntervalSeconds * 1000,
  });

  return data?.recentNotifications;
};

export const useNotificationListLastOpened = (): {
  update: () => Promise<void>;
  lastOpened?: Date;
} => {
  const [lastOpened, setLastOpened] = useState<GraphQLDateTime | undefined>();
  const { data: queryResponse } = useQuery<NotificationsListLastOpened>(
    NOTIFICATIONS_LIST_LAST_OPENED,
  );
  const [update, { data: mutationResponse }] = useMutation<SetNotificationsListLastOpened>(
    SET_NOTIFICATIONS_LIST_LAST_OPENED,
  );
  const { addEvent } = useInteractAnalytics();

  useEffect(() => {
    setLastOpened(
      (previousTimestamp) =>
        mutationResponse?.setNotificationsListLastOpened ||
        previousTimestamp ||
        queryResponse?.notificationsListLastOpened ||
        undefined,
    );
  }, [queryResponse, mutationResponse]);

  const updateLastOpened = useCallback(async () => {
    await update();

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => addEvent({ action: Action.NOTIFICATION_OPEN }))();
  }, [update, addEvent]);

  return {
    update: async () => {
      await updateLastOpened();
    },
    lastOpened: lastOpened ? new Date(lastOpened) : undefined,
  };
};

export const useMarkNotificationsAsViewed = () => {
  const [markNotificationsAsViewed] = useMutation<
    MarkNotificationsAsViewed,
    MarkNotificationsAsViewedVariables
  >(MARK_NOTIFICATIONS_AS_VIEWED);
  return (topicFilter: string, types: NotificationType[]) =>
    markNotificationsAsViewed({
      variables: {
        input: {
          topicFilter,
          types,
        },
      },
    });
};
