import { useEffect } from 'react';
import ReactModal from 'react-modal';
import Routing from 'routing';
import { joinClassNames, Button } from '@estimateone/frontend-components';
import qs from 'query-string';
import PaywallAmount from '../../../../common/PaywallAmount';
import { handlePaywallAnalyticsEvent, reasonDescriptions, trackingToken } from '../const';
import { fireAndForget } from '@shared/Util/async';
import { getTranslation, LangRef } from '@shared/Util/lang';
import {
  useFiftyPercentRedactedCheck,
  useProjectPaywallValue,
} from '@subbie/context/AccountProvider/hooks';
import { Action, Event, useAnalytics } from '../../../../../hooks/Analytics';
import { AnalyticsTrackedParam } from '@ascension/enums/Analytics';
import { ProjectRedactedReason } from '@ascension/_gqltypes/subbie.generated';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

// TODO: dedupe
const getUpgradeUrl = (reason: ProjectRedactedReason) => {
  const originalQueryStringParts = qs.parse(window.location.search);

  return Routing.generate('app_accountupgrade_index', {
    ...originalQueryStringParts,
    [AnalyticsTrackedParam.IN_APP_SOURCE]: trackingToken[reason],
  });
};

// TODO: this will be just the subbie paywall
type RoadblockModalProps = {
  projectId: EntityId;
  removeModal: () => void;
  reason: ProjectRedactedReason;
  disableAnalytics?: boolean;
};

const RoadblockModal = ({
  projectId,
  removeModal,
  reason,
  disableAnalytics,
}: RoadblockModalProps) => {
  const { addEvent } = useAnalytics(Event.INTERACT);

  const projectPaywallValue = useProjectPaywallValue();
  const fiftyPercentRedacted = useFiftyPercentRedactedCheck();
  const buildersLabel = getTranslation(LangRef.BUILDERS);

  // TODO: every subbie will see 50/50 redacted?
  const roadblockDescription =
    projectPaywallValue !== undefined && fiftyPercentRedacted !== undefined ? (
      fiftyPercentRedacted ? (
        `Upgrade your plan to see awarded ${buildersLabel}, and access UNLIMITED projects. `
      ) : (
        <>
          Upgrade your plan to see projects over{' '}
          <PaywallAmount amount={projectPaywallValue} longUnits /> and the winning {buildersLabel}.
          <br />
        </>
      )
    ) : (
      ''
    );

  // TODO: this looks like it's dead code as the analytics is disabled where this component is rendered. We should investigate and remove.
  // TODO: or re-enable it so it can be the same as the supplier paywall
  useEffect(() => {
    const handleOpenCloseAnalyticsEvent = (isOpen: boolean) =>
      addEvent({
        action: isOpen ? Action.PROJECT_SLIDER_OPEN : Action.PROJECT_SLIDER_CLOSE,
        projectId,
      });

    fireAndForget(async () => {
      if (disableAnalytics) return;
      await Promise.all([
        handlePaywallAnalyticsEvent(projectId),
        handleOpenCloseAnalyticsEvent(true),
      ]);
    });
    return function closeEvent() {
      if (disableAnalytics) return;
      handleOpenCloseAnalyticsEvent(false);
    };
  }, [addEvent, projectId, disableAnalytics]);

  const reasonDescription = projectPaywallValue
    ? reasonDescriptions[reason](projectPaywallValue)
    : undefined;

  return reasonDescription ? (
    <ReactModal
      onRequestClose={removeModal}
      isOpen
      className={joinClassNames(styles.modal, styles.inSlider)}
      overlayClassName={styles.modalOverlay}
    >
      <div className={styles.roadblockModal}>
        <div className={styles.roadblockImage} />
        <div className={styles.roadblockBody}>
          <p className={styles.roadblockReason}>{reasonDescription}</p>
          <h2 className="mt-0 mb-3">Upgrade to see project details</h2>
          <p className={styles.roadblockDescription}>
            {roadblockDescription}
            How good is that!?
          </p>
        </div>
        <Button href={getUpgradeUrl(reason)} className={styles.roadblockCta}>
          Check out upgrade options
        </Button>
      </div>
    </ReactModal>
  ) : null;
};

export default RoadblockModal;
