import { gql } from '@apollo/client';
import Quotes from '../../../../api/fragments/Quotes';

export const BUILDER_GET_QUOTE_FOR_REVISION = gql`
  query BuilderGetQuoteForRevision($quoteId: EntityId!, $stageId: EntityId!) {
    quote(id: $quoteId) {
      id
      clarifications
      version

      additionalFiles {
        ...QuoteExtraFile
      }

      package {
        id
        title
      }

      rfq {
        id
        companyName
        contactName
        ...RfqTimestampFields
      }
      projectId
      projectAddress {
        id
        address1
        shortAddress
      }
    }
    stage(id: $stageId) {
      ... on UnrestrictedStage {
        id
        name
        account {
          primaryCountry {
            id
          }
        }
      }
    }
  }
  ${Quotes.fragments.quoteExtraFile}
  ${Quotes.fragments.rfqTimestampFields}
`;
