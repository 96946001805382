/* eslint-disable fp/no-mutation */
import { Form } from 'react-final-form';
import { LoadingSpinner } from '@estimateone/frontend-components';
import { useOperatingCountryId } from '@ascension/components/localisation';
import { OperatingCountry } from '@ascension/components/localisation/operatingCountry';
import { FormError } from '@profiles/features/CompanyProfile/components/FormError';
import { EmailField } from '../OfficeDetailsFields/EmailField';
import { PhoneNumberField } from '../OfficeDetailsFields/PhoneNumberField';
import { WebsiteField } from '../OfficeDetailsFields/WebsiteField';
import styles from './OfficeDetailsForm.module.scss';
import { AddressSearchFields } from '@shared/form/AddressSearchFields';
import { ServiceAreaFields } from '@shared/form/ServiceAreaFields';
import { useUserImperialSetting } from '@subbie/context/AccountProvider/hooks';
import { useServiceAreaBoundaryOptionsForCountry } from './hooks';
import {
  OfficeDetailsFieldValues,
  OfficeDetailsFormErrors,
  EditOfficeDetailsFieldValues,
} from '../OfficeDetailsFields/types';

type OfficeDetailsFormProps = {
  formId: string;
  initialValues?: OfficeDetailsFormInitialValues;
  onSubmit: (values: OfficeDetailsFieldValues | EditOfficeDetailsFieldValues) => Promise<void>;
  errors:
    | {
        __typename: 'Error';
        message: string;
      }[]
    | undefined;
};

export type OfficeDetailsFormInitialValues = {
  accountOfficeId: number | null;
  name: string | null;
} & Omit<OfficeDetailsFieldValues, 'serviceAreaCountry'>;

const defaultInitialValues: { accountOfficeId: null; name: null } & OfficeDetailsFormInitialValues =
  {
    accountOfficeId: null,
    name: null,
    streetAddress: null,
    suburb: null,
    state: null,
    city: null,
    province: null,
    postcode: null,
    officeEmail: null,
    phoneNumber: null,
    website: null,
    serviceAreaRadius: 300_000,
    serviceAreaStates: [],
    // use Australia as the default if none is provided
    serviceAreaType: 'by-travel-distance',
  };

export const OfficeDetailsForm = ({
  formId,
  initialValues = defaultInitialValues,
  onSubmit,
  errors,
}: OfficeDetailsFormProps) => {
  const imperialUnitSetting = useUserImperialSetting();
  const countryId = useOperatingCountryId();
  const { data: serviceAreaBoundaryOptions } = useServiceAreaBoundaryOptionsForCountry();
  if (imperialUnitSetting === undefined) {
    return <LoadingSpinner />;
  }
  const isUKOrIE = countryId === OperatingCountry.UK || countryId === OperatingCountry.IE;
  const unitLabel = imperialUnitSetting ? 'mi' : 'km';

  const validateForm = (values: OfficeDetailsFieldValues): OfficeDetailsFormErrors => {
    const validationErrors: OfficeDetailsFormErrors = {};

    if (isUKOrIE) {
      if (!values.city?.trim()) {
        validationErrors.city = ['Enter a City'];
      }
      if (!values.province?.trim()) {
        validationErrors.province = ['Enter a County'];
      }
    } else {
      if (!values.suburb?.trim()) {
        validationErrors.suburb = ['Enter a Suburb'];
      }
      if (!values.state) {
        validationErrors.state = ['Enter a State'];
      }
    }

    if (!values.postcode?.trim()) {
      validationErrors.postcode = ['Enter a Postcode'];
    }

    if (values.serviceAreaType === 'by-states' && !values.serviceAreaStates?.length) {
      validationErrors.serviceAreaStates = ['Enter states'];
    }

    const maxDistance = imperialUnitSetting ? 500 : 1000;
    const minDistanceInMeters = imperialUnitSetting ? 16093 : 10000;
    const maxDistanceInMeters = imperialUnitSetting ? 804670 : 1000000;

    if (values.serviceAreaRadius < minDistanceInMeters) {
      validationErrors.serviceAreaRadius = [`Distance must be at least 10${unitLabel}`];
    }

    if (values.serviceAreaRadius > maxDistanceInMeters) {
      validationErrors.serviceAreaRadius = [
        `Distance must be less than ${maxDistance}${unitLabel}`,
      ];
    }

    return validationErrors;
  };

  return (
    <Form<OfficeDetailsFieldValues>
      onSubmit={onSubmit}
      initialValues={{
        ...initialValues,
        serviceAreaCountry: countryId,
      }}
      validate={validateForm}
      keepDirtyOnReinitialize
    >
      {({ handleSubmit }) => (
        <form id={formId} onSubmit={handleSubmit}>
          {errors && <FormError errorMessage={errors[0].message} />}
          <div className={styles.formWrapper}>
            <div className={styles.formSection}>
              <h3 className={styles.formSectionTitle}>Location</h3>
              <AddressSearchFields formId={formId} countryId={countryId} />
            </div>
            <div className={styles.formSection}>
              <h3 className={styles.formSectionTitle}>Service Area</h3>
              <div className={styles.serviceAreaContainer}>
                <span className={styles.formSectionSubtitle}>
                  Define the area this office services:
                </span>
                <ServiceAreaFields
                  imperialUnitSetting={imperialUnitSetting}
                  serviceAreaBoundaryOptions={serviceAreaBoundaryOptions}
                  countryId={countryId}
                />
              </div>
            </div>
            <div className={styles.formSection}>
              <h3 className={styles.formSectionTitle}>Contact</h3>
              <div className={styles.adjacentFields}>
                <PhoneNumberField />
                <EmailField />
              </div>
              <WebsiteField />
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};
