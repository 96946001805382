import { gql } from '@apollo/client';

export const SUBBIE_PROFILE_FOR_ACCOUNT = gql`
  query SubbieProfileForAccount($accountId: EntityId!) {
    subbieProfileForAccount(id: $accountId) {
      id
      name
      type
      businessIdentifier
      defaultOffice {
        id
        email
        phone
        address {
          id
          fullAddress
        }
      }
      accountOffices {
        id
        address {
          id
          state {
            id
            name
          }
          suburb
        }
      }
      websiteAddress
      memberSince
      lastActiveAt
      trades {
        id
        name
      }
    }
  }
`;
