import { EntityId } from '@ascension/types';

export const UNIVERSAL_PROFILE_DRAWER_OPEN_EVENT = 'universalprofiledrawer:open';
export const UNIVERSAL_PROFILE_DRAWER_READY_EVENT = 'universalprofiledrawer:ready';
export const UNIVERSAL_PROFILE_DRAWER_REFRESH_EVENT = 'universalprofiledrawer:refresh';

export const refreshUniversalProfileDrawerFromExternalScript = () => {
  document.dispatchEvent(new CustomEvent(UNIVERSAL_PROFILE_DRAWER_REFRESH_EVENT, {}));
};

export type UniversalProfileDrawerIds = {
  readonly companyId?: EntityId;
  readonly accountId?: EntityId;
  readonly profileId?: string;
  readonly enableABNCRNDetails?: boolean;
};

export const showUniversalProfileDrawer = ({
  companyId,
  accountId,
  profileId,
}: UniversalProfileDrawerIds) => {
  // This function is called from JavaScript code with no type checking, so it is possible for
  // invalid types to be provided at runtime. This makes sure that the TypeScript types won't "lie"
  // if a mistake is made in the JavaScript side of the codebase.
  if (
    !['number', 'undefined'].includes(typeof companyId) ||
    !['number', 'undefined'].includes(typeof accountId) ||
    !['string', 'undefined'].includes(typeof profileId)
  ) {
    throw new Error('Invalid types provided at runtime when opening the Universal Profile Drawer');
  }

  // == This is an async process. These are the steps to show the address book drawer: ==
  // 1. Event: vcard_slider.js -->UNIVERSAL_PROFILE_DRAWER_OPEN_EVENT--> UniversalProfileDrawer.tsx
  // 2. UniversalProfileDrawer starts the drawer animation
  // 3. The drawer animation finishes and the DOM is ready again
  // 4. Event: UniversalProfileDrawer.tsx -->UNIVERSAL_PROFILE_DRAWER_READY_EVENT--> vcard_slider.js
  // 5. Tab container is rendered by the backend and HTML is returned via API call
  // 6. Tab container HTML is injected into the drawer
  document.dispatchEvent(
    new CustomEvent(UNIVERSAL_PROFILE_DRAWER_OPEN_EVENT, {
      detail: { companyId, accountId, profileId },
    }),
  );
};

export const EDIT_CONTACT_EVENT_NAME = 'network-profile:edit-contact';
export type EditContactEvent = CustomEvent<string>;
export const emitEditContactEvent = (contactId: string) => {
  const editContactEvent: EditContactEvent = new CustomEvent(EDIT_CONTACT_EVENT_NAME, {
    detail: contactId,
  });

  window.dispatchEvent(editContactEvent);
};

export const ADD_CONTACT_EVENT_NAME = 'network-profile:add-contact';
export type AddContactEvent = CustomEvent<string>;
export const emitAddContactEvent = (contactId: string) => {
  const addContactEvent: AddContactEvent = new CustomEvent(ADD_CONTACT_EVENT_NAME, {
    detail: contactId,
  });

  window.dispatchEvent(addContactEvent);
};

export const DELETE_CONTACT_EVENT_NAME = 'network-profile:delete-contact';
export type DeleteContactEvent = CustomEvent<string>;
export const emitDeleteContactEvent = (contactId: string) => {
  const deleteContactEvent: DeleteContactEvent = new CustomEvent(DELETE_CONTACT_EVENT_NAME, {
    detail: contactId,
  });

  window.dispatchEvent(deleteContactEvent);
};
