import { Row, Col, SubHeader } from '@estimateone/frontend-components';
import { BuilderContactDetails } from '../../ProjectSlider/ProjectSliderBody/ProjectStages/StageRow/BuilderContactDetails';
import BuilderName from '../../ProjectSlider/ProjectSliderBody/ProjectStages/StageRow/BuilderName';
import { StageDocs } from '../../ProjectSlider/ProjectSliderBody/ProjectStages/StageRow/StageDocs';
import { getTranslation, LangRef } from '@shared/Util/lang';
import { DocumentHighlightsStage } from '@subbie/modal/SpeciFinderKeywordMatchesSlider/hooks/useDocumentHighlightsByStage';
import styles from './styles.scss';

type SpeciFinderKeywordMatchesSliderStageDetailsProps = {
  stage: DocumentHighlightsStage;
};

const isIncognitoBuilder = (
  builderDetails: DocumentHighlightsStage['builderDetails'],
): builderDetails is Extract<
  DocumentHighlightsStage['builderDetails'],
  { __typename: 'HiddenBuilderDetails' }
> => builderDetails.__typename === 'HiddenBuilderDetails';

export const SpeciFinderKeywordMatchesSliderStageDetails = ({
  stage,
}: SpeciFinderKeywordMatchesSliderStageDetailsProps) => {
  const { builderDetails, project, name } = stage;

  const builderName = isIncognitoBuilder(builderDetails)
    ? getTranslation(LangRef.INCOGNITO_BUILDER_HEADING)
    : builderDetails.name;

  return (
    <div className={styles.stageSubHeaderWrapper}>
      <SubHeader variant="default">
        <Row>
          <Col span={7}>
            {isIncognitoBuilder(builderDetails) ? (
              <BuilderName name={builderName} />
            ) : (
              <>
                <BuilderName id={builderDetails.id} name={builderDetails.name} />
                <BuilderContactDetails
                  stageName={name}
                  contactName={builderDetails.contactName}
                  contactEmail={builderDetails.contactEmail}
                  contactPhone={builderDetails.contactPhone}
                />
              </>
            )}
          </Col>
          <Col span={5}>
            <StageDocs stage={stage} projectId={project.id} builderName={builderName} />
          </Col>
        </Row>
      </SubHeader>
    </div>
  );
};
