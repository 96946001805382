import Routing from 'routing';
import { Alert, AlertVariant, E1Link, LinkVariant } from '@estimateone/frontend-components';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

type AddendumExistsAlertProps = {
  existingAddendumId: EntityId;
};

export const AddendumExistsAlert = ({ existingAddendumId }: AddendumExistsAlertProps) => {
  const addendumLink: string | undefined = existingAddendumId
    ? Routing.generate('app_stageaddendum_documents', { id: existingAddendumId })
    : undefined;

  return (
    <Alert className={styles.exportPackageRegisterAddendum} variant={AlertVariant.Yellow}>
      <span className={styles.exportPackageRegisterAddendumWarning}>
        Your package register may not be the latest version.
      </span>{' '}
      <span className={styles.exportPackageRegisterAddendumInfo}>
        Complete or cancel the{' '}
        <E1Link href={addendumLink} variant={LinkVariant.Primary}>
          unfinished addendum/revision
        </E1Link>{' '}
        first.
      </span>
    </Alert>
  );
};
