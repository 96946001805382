import { useCallback, useEffect, useState } from 'react';
import { useField, useForm } from 'react-final-form';
import { useQuery } from '@apollo/client';
import { Col, Row } from '@estimateone/frontend-components';
import debounce from 'lodash/debounce';
import RfqStatusAlert, { isNotApprovedRfq } from '../../../RfqStatusAlert';
import AddContactSlider, { AddMode } from '../AddContactSlider';
import CompanySelector, { ADD_NEW_COMPANY_OPTION } from './CompanySelector';
import ContactSelector, { ADD_NEW_CONTACT_OPTION } from './ContactSelector';
import { contactToOption, findRfqForContact, hasActiveQuote, isContactValid } from './utils';
import usePromiseQuery from '../../../../../../shared/Util/usePromiseQuery';
import { Action } from '../../../../../../hooks/Analytics';
import { CONTACTS_FOR_COMPANY, RFQS_FOR_PACKAGE, SEARCH_COMPANIES } from '../queries';
import { CompleteQuoteFormSpecificFieldName } from '../../enums';
import { CompanyValue, CompleteQuoteFormValues, ContactValue, PackageValue } from '../../types';
import {
  GetContactsForCompanyToAssignQuote as ContactsQuery,
  GetContactsForCompanyToAssignQuote_company_activeContacts as ActiveContact,
  GetContactsForCompanyToAssignQuoteVariables as ContactsQueryVars,
} from '../types/GetContactsForCompanyToAssignQuote';
import {
  RfqsForPackage,
  RfqsForPackage_package_rfqs as Rfq,
  RfqsForPackageVariables,
} from '../types/RfqsForPackage';
import {
  SearchCompaniesToAssignQuote as CompaniesQuery,
  SearchCompaniesToAssignQuoteVariables as CompaniesQueryVars,
} from '../types/SearchCompaniesToAssignQuote';
import { AnalyticsAwareFC, EntityId } from '@ascension/types';

type AnalyticsActions =
  | Action.QUOTES_ADD_COMPANY_START
  | Action.QUOTES_ADD_COMPANY_COMPLETE
  | Action.QUOTES_ADD_CONTACT_START
  | Action.QUOTES_ADD_CONTACT_COMPLETE;

const CompanyContactField: AnalyticsAwareFC = ({ onAnalyticsEvent }) => {
  const [selectedContactRfq, setSelectedContactRfq] = useState<Rfq>();
  const [previousSelectedCompany, setPreviousSelectedCompany] = useState<CompanyValue>();
  const [addingNewCompany, setAddingNewCompany] = useState<boolean>(false);
  const [addingNewContact, setAddingNewContact] = useState<boolean>(false);

  const {
    change: updateFieldValue,
    mutators: { setFieldTouched },
  } = useForm<CompleteQuoteFormValues>();

  const {
    input: {
      value: { id: packageId },
    },
  } = useField<PackageValue>(CompleteQuoteFormSpecificFieldName.Package);

  const {
    input: { value: companyValue },
    meta: { touched: companyTouched },
  } = useField<CompanyValue>(CompleteQuoteFormSpecificFieldName.Company);

  const companyId = companyValue.value;

  const {
    input: { value: contactValue },
    meta: { touched: contactTouched },
  } = useField<ContactValue>(CompleteQuoteFormSpecificFieldName.Contact);

  const track = (action: AnalyticsActions) => onAnalyticsEvent && onAnalyticsEvent(action);

  const { data: packageRfqs, loading: rfqsLoading } = useQuery<
    RfqsForPackage,
    RfqsForPackageVariables
  >(RFQS_FOR_PACKAGE, {
    skip: !packageId,
    variables: { packageId },
  });

  const fetchCompanies = usePromiseQuery<CompaniesQuery, CompaniesQueryVars>(SEARCH_COMPANIES);
  const {
    loading: contactsLoading,
    data: contactData,
    refetch: refetchContacts,
  } = useQuery<ContactsQuery, ContactsQueryVars>(CONTACTS_FOR_COMPANY, {
    skip: companyId === undefined,
    variables: {
      id: companyId,
    },
  });

  const handleLoadCompanyOptions = (
    inputValue: string,
    callback: (companies: { value: EntityId; label: string }[]) => void,
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchCompanies({ query: inputValue }).then(({ data: { searchCompanyNames: companies } }) =>
      callback(
        [ADD_NEW_COMPANY_OPTION].concat(
          companies.map(({ id, name }) => ({ value: id, label: name })),
        ),
      ),
    );
  };

  const handleLoadCompanyOptionsDebounced = useCallback(debounce(handleLoadCompanyOptions, 500), [
    handleLoadCompanyOptions,
  ]);

  const handleSelectCompany = () => {
    updateFieldValue(CompleteQuoteFormSpecificFieldName.Contact, undefined);
  };

  const getRfqForContact = useCallback(
    (contact: ActiveContact) => findRfqForContact(contact, packageRfqs?.package.rfqs || []),
    [packageRfqs],
  );

  const selectedCompanyContacts = contactData
    ? contactData.company.activeContacts.filter(isContactValid)
    : [];

  const contactOptions =
    companyId && packageRfqs
      ? [ADD_NEW_CONTACT_OPTION].concat(
          selectedCompanyContacts.map((contact) =>
            contactToOption(contact, getRfqForContact(contact)),
          ),
        )
      : [];

  useEffect(() => {
    const selectedContactOption = contactOptions.find((_) => _?.value === contactValue?.id);

    if (contactValue && selectedContactOption) {
      const { value: selectedContactId, mostRecentQuoteId: selectedContactQuoteId } =
        selectedContactOption;
      const contactHasChanged = contactValue.id !== selectedContactId;
      const contactRfqStatusChanged = contactValue.mostRecentQuoteId !== selectedContactQuoteId;

      if (contactHasChanged || contactRfqStatusChanged) {
        updateFieldValue(CompleteQuoteFormSpecificFieldName.Contact, {
          id: selectedContactId,
          mostRecentQuoteId: selectedContactQuoteId,
        });
      }
    }
  }, [contactOptions, contactValue, updateFieldValue]);

  useEffect(() => {
    const selectedContact = selectedCompanyContacts.find(({ id }) => id === contactValue.id);
    const existingRfq = selectedContact ? getRfqForContact(selectedContact) : undefined;

    setSelectedContactRfq(existingRfq);
  }, [contactValue, selectedCompanyContacts, getRfqForContact, updateFieldValue]);

  const handleCompanyAdded = () => {
    track(Action.QUOTES_ADD_COMPANY_COMPLETE);
  };

  const handleContactAdded = async (
    selectedCompanyId: number,
    addedContactId: number,
    companyName: string,
  ) => {
    // Update company states here as this is always triggered, but for the case of selecting an
    // existing company through adding a new company, `handleCompanyAdded` is not triggered.
    setAddingNewCompany(false);
    updateFieldValue(CompleteQuoteFormSpecificFieldName.Company, {
      value: selectedCompanyId,
      label: companyName,
    });
    await refetchContacts();
    setAddingNewContact(false);
    updateFieldValue(CompleteQuoteFormSpecificFieldName.Contact, {
      id: addedContactId,
    });
    track(Action.QUOTES_ADD_CONTACT_COMPLETE);
  };

  const addingCompanyOrContact = addingNewCompany || addingNewContact;

  const companySelectDisabled = !packageId || addingNewCompany;

  const contactSelectDisabled =
    !packageId || rfqsLoading || contactsLoading || contactData === undefined;

  useEffect(() => {
    if (companyTouched && companySelectDisabled) {
      setFieldTouched(CompleteQuoteFormSpecificFieldName.Company, false);
    }
  }, [companyTouched, companySelectDisabled, setFieldTouched]);

  useEffect(() => {
    if ((contactTouched && addingCompanyOrContact) || contactSelectDisabled) {
      setFieldTouched(CompleteQuoteFormSpecificFieldName.Contact, false);
    }
  }, [contactTouched, addingCompanyOrContact, contactSelectDisabled, setFieldTouched]);

  const getCompanyPlaceholder = (): string | undefined => {
    if (!packageId) {
      return 'Please select a package first';
    }
    if (addingNewCompany) {
      return 'Waiting for new company to be added';
    }
    return undefined;
  };

  const getContactPlaceholder = (): string => {
    if (!packageId) {
      return 'Please select a package first';
    }
    if (addingCompanyOrContact) {
      return 'New contact will appear here once added';
    }
    if (!companyId) {
      return 'Please select a company first';
    }
    if (contactsLoading) {
      return 'Fetching contacts...';
    }
    if (rfqsLoading) {
      return 'Fetching contact quote status...';
    }
    if (contactOptions.length === 1) {
      return 'No eligible contacts - click here to create one';
    }
    return 'Select a contact';
  };

  const showRfqWarning =
    selectedContactRfq &&
    !hasActiveQuote(selectedContactRfq) &&
    isNotApprovedRfq(selectedContactRfq);

  return (
    <>
      <Row>
        <Col span={12}>
          <CompanySelector
            loadOptions={handleLoadCompanyOptionsDebounced}
            onSelectCompany={handleSelectCompany}
            placeholder={getCompanyPlaceholder()}
            onAddCompany={() => {
              setAddingNewCompany(true);
              setPreviousSelectedCompany(companyValue);
              updateFieldValue(CompleteQuoteFormSpecificFieldName.Contact, undefined);
              track(Action.QUOTES_ADD_COMPANY_START);
            }}
            isDisabled={companySelectDisabled}
          />
        </Col>
      </Row>

      <Row>
        <Col span={12} excludeBottomGutter={!showRfqWarning}>
          <ContactSelector
            options={contactOptions}
            placeholder={getContactPlaceholder()}
            onAddContact={() => {
              setAddingNewContact(true);
              track(Action.QUOTES_ADD_CONTACT_START);
            }}
            isDisabled={addingCompanyOrContact || contactSelectDisabled}
          />
        </Col>
      </Row>

      {showRfqWarning && (
        <Row>
          <Col span={12} excludeBottomGutter>
            <RfqStatusAlert rfq={selectedContactRfq} />
          </Col>
        </Row>
      )}

      <AddContactSlider
        mode={
          addingNewCompany ? AddMode.Company : addingNewContact ? AddMode.Contact : AddMode.None
        }
        onAddCompany={handleCompanyAdded}
        onAddContact={handleContactAdded}
        selectedCompanyId={companyId}
        selectedCompanyName={companyValue.label}
        onCancel={() => {
          setAddingNewCompany(false);
          setAddingNewContact(false);
          updateFieldValue(CompleteQuoteFormSpecificFieldName.Company, previousSelectedCompany);
        }}
      />
    </>
  );
};

export default CompanyContactField;
