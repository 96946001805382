import { useField } from 'react-final-form';
import { Fieldset, TextInput } from '@estimateone/frontend-components';
import { FieldWrapper } from '../../../../../../shared/FinalForm/Field';
import { TenderHandoverWizardFieldName } from '../../../enums';
import { Country } from '@ascension/enums';
import styles from './styles.scss';

const AddressLineInput = ({
  id,
  label,
  fieldName,
  placeholder,
}: {
  id: string;
  label: string;
  fieldName: TenderHandoverWizardFieldName;
  placeholder?: string;
}) => {
  const { input } = useField(fieldName);

  return (
    <TextInput
      id={`tender-handover-wizard--${id}`}
      label={label}
      placeholder={placeholder}
      {...input}
      hideLabel
    />
  );
};

export const StreetSelector = ({ country }: { country: Country | undefined }) => {
  const {
    input,
    meta: { error, submitError, touched },
  } = useField(TenderHandoverWizardFieldName.AddressLineOne, {
    validate: (value: string) => (!value ? ['Enter a street address'] : undefined),
  });

  return (
    <div>
      <FieldWrapper errors={error || submitError} showErrors={touched}>
        <Fieldset legend="Street Address">
          <div className={styles.addressLinesContainer}>
            <TextInput
              id="tender-handover-wizard--address-line-1"
              label="Address Line 1"
              hideLabel
              {...input}
              isRequired
              placeholder="Address Line 1"
            />
            <AddressLineInput
              id="address-line-2"
              label="Address Line 2"
              fieldName={TenderHandoverWizardFieldName.AddressLineTwo}
              placeholder="Address Line 2"
            />
            {country === Country.COUNTRY_UK || country === Country.COUNTRY_IE ? (
              <AddressLineInput
                id="address-line-3"
                label="Address Line 3"
                fieldName={TenderHandoverWizardFieldName.AddressLineThree}
                placeholder="Address Line 3"
              />
            ) : null}
          </div>
        </Fieldset>
      </FieldWrapper>
    </div>
  );
};
