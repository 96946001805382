import { useApolloClient } from '@apollo/client';
import { DocumentNode } from 'graphql/language/ast';

const useApolloCache = () => {
  const client = useApolloClient();

  const updateCacheForQuery = <T>(
    queryOptions: { query: DocumentNode; variables?: Record<string, unknown> },
    mutator: (cacheContents: T) => T,
    // For deletions, we're providing a filtered array of the original cache items that is
    // intended to replace the existing cache array. Apollo needs to be informed that it
    // doesn't need to try to manually merge the original and replacement versions of the
    // array - i.e. that replacement is indeed intended. But we don't want to modify the
    // readQuery options, so pulling the overwrite option out of queryOptions to refer to
    // separately for the writeQuery.
    overwrite?: boolean,
  ) => {
    const data = client.readQuery<T>(queryOptions);
    if (data) {
      return client.writeQuery<T>({
        ...queryOptions,
        data: mutator(data),
        overwrite,
      });
    }
    return data;
  };

  const appendElementsToCache =
    <K extends string, ElementType>(k: K, elements: ElementType[]) =>
    <Query extends Record<K, ElementType[]>>(queryOptions: { query: DocumentNode }) =>
      updateCacheForQuery<Query>(queryOptions, (cacheContents) => ({
        ...cacheContents,
        [k]: (cacheContents[k] ?? new Array<ElementType>()).concat(elements),
      }));

  const removeElementsFromCache =
    <K extends string, ElementType>(k: K, filterCallback: (elem: ElementType) => boolean) =>
    <Query extends Record<K, ElementType[]>>(queryOptions: { query: DocumentNode }) =>
      updateCacheForQuery<Query>(
        queryOptions,
        (cacheContents) => ({
          ...cacheContents,
          [k]: (cacheContents[k] ?? new Array<ElementType>()).filter((elem) =>
            filterCallback(elem),
          ),
        }),
        true,
      );

  const updateElementsInCache =
    <K extends string, ElementType>(
      k: K,
      elements: ElementType[],
      filterCallback: (elem: ElementType) => boolean,
    ) =>
    <Query extends Record<K, ElementType[]>>(queryOptions: {
      query: DocumentNode;
      variables?: Record<string, unknown>;
    }) =>
      updateCacheForQuery<Query>(queryOptions, (cacheContents) => ({
        ...cacheContents,
        [k]: (cacheContents[k] ?? new Array<ElementType>())
          .filter((elem) => filterCallback(elem))
          .concat(elements),
      }));

  return {
    updateCacheForQuery,
    appendElementsToCache,
    removeElementsFromCache,
    updateElementsInCache,
  };
};
export default useApolloCache;
