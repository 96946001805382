import { ContactCard } from './ContactCard/ContactCard';
import styles from './Contacts.module.scss';
import { HiddenContactCard } from './HiddenContactCard/HiddenContactCard';
import { useOwnAccount } from '@profiles/features/CompanyProfile/hooks';
import { CompanyContact } from '@profiles/providers/ProfileProvider/types';

type ContactProps = {
  companyContacts: CompanyContact[];
};

export const Contacts = ({ companyContacts }: ContactProps) => {
  const { isOwnAccount } = useOwnAccount();

  // This component is subbie owner view only
  if (!isOwnAccount) return null;

  /**
   * Sorting and assigning these on the frontend is temporary
   * Will move to backend after we update the ProfileDTO and related files
   */
  const primaryContact =
    companyContacts.find((contact) => contact.isDefaultContact) ??
    companyContacts.find((contact) => contact.isVisible === true); // Fallback to first visible contact if no primary set

  const otherVisibleContactsInAlphabeticalOrder = companyContacts
    .filter((contact) => contact.id !== primaryContact?.id && contact.isVisible === true)
    .sort((a, b) => a.fullName.localeCompare(b.fullName));

  const hiddenContacts = companyContacts
    .filter((contact) => contact.isVisible === false)
    .sort((a, b) => a.fullName.localeCompare(b.fullName));

  return (
    <div className={styles.contactsSection}>
      <div className={styles.contactsHeadingWrapper}>
        <span className={styles.contactsHeader}>Contacts</span>
      </div>
      <span className={styles.contactsText}>
        Manage the visibility of your team members to ensure the right people are reached.
      </span>
      {primaryContact && <ContactCard companyContactDetails={primaryContact} isPrimary />}
      {otherVisibleContactsInAlphabeticalOrder.map((contact) => (
        <ContactCard key={contact.id} companyContactDetails={contact} />
      ))}
      {hiddenContacts.map((hiddenContact) => (
        <HiddenContactCard key={hiddenContact.id} companyContactDetails={hiddenContact} />
      ))}
    </div>
  );
};
