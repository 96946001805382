import moment from 'moment';
import { Country } from '@ascension/enums';
import { TenderHandoverWizardValues } from './types';
import { GetTenderDetailsForHandover_stage_UnrestrictedStage as LastStage } from './types/GetTenderDetailsForHandover';
import {
  HandoverToProcurementStageAddressInput,
  HandoverToProcurementStageContactInput,
  HandoverToProcurementStageInput,
  HandoverToProcurementStageSettingsInput,
} from '@ascension/_gqltypes/builder.generated';

type LastAddress = LastStage['projectAddress'];

const transformDate = (date: Date | null) => (date ? moment(date).format() : null);

const mapContactFromValues = (
  values: TenderHandoverWizardValues,
): HandoverToProcurementStageContactInput => ({
  contactName: values.ContactName,
  contactEmail: values.ContactEmail,
  contactPhone: values.ContactPhone,
});

export const mapAddressFromValues = (
  values: TenderHandoverWizardValues,
): HandoverToProcurementStageAddressInput => {
  const commonValues = {
    address1: values.AddressLineOne,
    address2: values.AddressLineTwo,
    postcode: values.AddressPostcode,
    countryId: values.AddressCountry.value,
  };

  if (
    values.AddressCountry.value === Country.COUNTRY_UK ||
    values.AddressCountry.value === Country.COUNTRY_IE
  ) {
    return {
      ...commonValues,
      address3: values.AddressLineThree,
      city: values.AddressCity,
    };
  }
  return {
    ...commonValues,
    suburb: values.AddressSuburb,
    stateId: values.AddressState?.value,
  };
};

const mapAddressFromLastAddress = (
  address: LastAddress | null,
): HandoverToProcurementStageAddressInput => {
  if (!address?.country) {
    throw new Error('Address (and Country) cannot be null');
  }

  return {
    address1: address.address1,
    address2: address.address2,
    suburb: address.suburb,
    postcode: address.postcode,
    stateId: address?.state?.id,
    countryId: address.country.id,
  };
};

const mapSettings = (
  values: TenderHandoverWizardValues,
): HandoverToProcurementStageSettingsInput => ({
  sendNotificationsToAwarded: values.SendNotificationsToAwarded,
  enableDefaultTimeframes: values.EnableDefaultTimeframes,
  fixedPriceQuotes: values.IncludeFixedQuoteConfirmation,
});

export const mapFormValuesToInput = (
  values: TenderHandoverWizardValues,
  stage: LastStage,
): HandoverToProcurementStageInput => {
  const { id, projectAddress: lastAddress } = stage;

  const address = values.AddressLineOne
    ? mapAddressFromValues(values)
    : mapAddressFromLastAddress(lastAddress);
  const contact = mapContactFromValues(values);
  const settings = mapSettings(values);

  return {
    lastStageId: id,
    name: values.ProjectName,
    category: values.ProjectCategory.value,
    internalRef: values.InternalRef,
    budget: values.ProjectValue.value,
    description: values.Description,
    startDate: transformDate(values.StartDate),
    endDate: transformDate(values.EndDate),
    contact,
    address,
    settings,
  };
};
