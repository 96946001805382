import {
  Button,
  ButtonSize,
  ButtonVariant,
  E1Link,
  LinkVariant,
  Modal,
  ModalSize,
} from '@estimateone/frontend-components';
import { ConnectionInformationModalProps } from '@builder/features/DocSyncIntegration/DocSyncPlugin/DocSyncPlugin';
import { useDocumentIntegrationContext } from '@builder/features/DocSyncIntegration/DocumentIntegrationProvider';
import { connectToProcore } from '@builder/features/DocSyncIntegration/ProcoreConnect/connectToProcore';
import styles from './styles.scss';

export const ProcoreConnectionInformationModal = ({
  isOpen,
  onCancel,
}: ConnectionInformationModalProps) => {
  const { stageId, authHost, isInSharepointExperiment } = useDocumentIntegrationContext();
  return (
    <Modal isOpen={isOpen} onRequestClose={onCancel} size={ModalSize.Small}>
      <Modal.Section>
        <div className={styles.procoreConnectModalContainer}>
          <h1 className={styles.procoreConnectModalHeading}>
            Connect Procore documents to this project
          </h1>
          <p className={styles.procoreConnectModalDescription}>
            Enable a <strong>one-way connection</strong> from a Procore project to this E1 project
          </p>
          <p>Continue to:</p>
          <ol className={styles.procoreConnectModalList}>
            <li>Log in to Procore</li>
            <li>Choose the matching Procore project</li>
            <li>Choose documents to make available to E1</li>
            <li>
              Then upload, revise or edit documents <b>on Procore only.</b>
            </li>
          </ol>
          <p className={styles.procoreConnectModalDescriptionLink}>
            Read our&nbsp;
            <E1Link
              variant={LinkVariant.Grey}
              href="https://builder.support.estimateone.com/en/articles/1705698250-procore-installation-and-setup-guides"
              target="_blank"
              rel="noreferrer"
            >
              Knowledge Base article
            </E1Link>
            &nbsp;for more information
          </p>
          <div className={styles.procoreConnectModalButtonContainer}>
            <Button
              size={ButtonSize.Medium}
              variant={ButtonVariant.Grey}
              fullWidth
              onClick={() => onCancel()}
            >
              Cancel
            </Button>
            <Button
              size={ButtonSize.Medium}
              variant={ButtonVariant.Primary}
              fullWidth
              onClick={() => connectToProcore(stageId, authHost)}
            >
              {isInSharepointExperiment ? 'Setup Integration' : 'Continue'}
            </Button>
          </div>
        </div>
      </Modal.Section>
    </Modal>
  );
};
