import { gql } from '@apollo/client';
import { TableProjectFragment } from '@subbie/fragments/Project';

export const BURN_CREDIT_TO_UNLOCK_PROJECT = gql`
  mutation BurnCreditToUnlockProject($projectId: EntityId!) {
    burnCreditToUnlockProject(id: $projectId) {
      ...TableProject
    }
  }
  ${TableProjectFragment}
`;
