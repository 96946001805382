import { useForm } from 'react-final-form';
import { Slider } from '@estimateone/frontend-components';
import ScreenReaderOnly from '../../../../../../../fec/ScreenReaderOnly';
import { EmailPreviewSectionProcurement } from '../EmailPreviewSectionProcurement';
import { InviteSummaryCard } from '../InviteSummaryCard';
import { CheckboxField } from '@shared/FinalForm/Field';
import { InviteToQuoteFormFieldName } from '../../enums';
import { InviteToQuoteFormValues } from '../../types';
import { GetInviteToQuoteStage_stage_UnrestrictedStage as Stage } from '../../types/GetInviteToQuoteStage';
import styles from './styles.scss';

type PaneBodyForContactWithEmailProps = {
  stage: Stage;
};

export const PaneBodyForContactWithEmail = ({ stage }: PaneBodyForContactWithEmailProps) => {
  const { getState } = useForm<InviteToQuoteFormValues>();
  const { values } = getState();

  return (
    <Slider.Body scrollBodyOnly>
      <ScreenReaderOnly>
        <h2>Invite summary</h2>
      </ScreenReaderOnly>
      <InviteSummaryCard stageName={stage.name} />

      <CheckboxField
        id="invite-to-quote--sendEmail"
        label="Yes, I want to send an email invitation"
        fieldName={InviteToQuoteFormFieldName.SendEmail}
      />

      {values[InviteToQuoteFormFieldName.SendEmail] && (
        <>
          <h2 className={styles.emailPreviewHeading}>Preview email invitation and personalise</h2>
          <EmailPreviewSectionProcurement stage={stage} />
        </>
      )}
    </Slider.Body>
  );
};
