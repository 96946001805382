export enum TenderHandoverWizardFieldName {
  ProjectValue = 'ProjectValue',
  ProjectName = 'ProjectName',
  ProjectCategory = 'ProjectCategory',
  InternalRef = 'InternalRef',
  AddressLineOne = 'AddressLineOne',
  AddressLineTwo = 'AddressLineTwo',
  AddressLineThree = 'AddressLineThree',
  AddressSuburb = 'AddressSuburb',
  AddressCity = 'AddressCity',
  AddressPostcode = 'AddressPostcode',
  AddressState = 'AddressState',
  AddressCountry = 'AddressCountry',
  StartDate = 'StartDate',
  EndDate = 'EndDate',
  ContactName = 'ContactName',
  ContactEmail = 'ContactEmail',
  ContactPhone = 'ContactPhone',
  Description = 'Description',
  SendNotificationsToAwarded = 'SendNotificationsToAwarded',
  EnableDefaultTimeframes = 'EnableDefaultTimeframes',
  IncludeFixedQuoteConfirmation = 'IncludeFixedQuoteConfirmation',
}
