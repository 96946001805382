import { useState } from 'react';
import {
  Button,
  ButtonVariant,
  Modal,
  ModalSize,
  RadioButton,
  RadioButtonGroup,
} from '@estimateone/frontend-components';
import useFlashMessage from '../../../shared/Util/useFlashMessage';
import { StageAccess } from './types';
import styles from './styles.scss';

type StageAccessControlSelectorProps = {
  handleSetStageAccessRestricted: (access: boolean) => Promise<void>;
  actualStageAccess: StageAccess;
  setActualStageAccess: (value: StageAccess) => void;
};

export const StageAccessControlSelector = ({
  handleSetStageAccessRestricted,
  actualStageAccess,
  setActualStageAccess,
}: StageAccessControlSelectorProps) => {
  const [isPromptOpen, setPromptOpen] = useState<boolean>(false);
  const [stageAccess, setStageAccess] = useState<StageAccess>(actualStageAccess);

  const { success: showSuccessMessage } = useFlashMessage();

  const submitHandler = async () => {
    setPromptOpen(false);
    setActualStageAccess(stageAccess);
    const selectedValue = stageAccess === StageAccess.RESTRICTED;
    await handleSetStageAccessRestricted(selectedValue);
    showSuccessMessage({
      title: 'Success',
      message:
        stageAccess === StageAccess.UNRESTRICTED
          ? 'Access to this project is now Unrestricted.'
          : 'Access to this project is now Restricted.',
    });
  };

  const cancelHandler = () => {
    setPromptOpen(false);
    setStageAccess(actualStageAccess);
  };

  return (
    <>
      <RadioButtonGroup
        className={styles.radioButtonGroup}
        name="accessControlSetting"
        value={stageAccess}
        onChange={(value: StageAccess) => {
          setStageAccess(value);
          setPromptOpen(true);
        }}
      >
        <RadioButton
          id={StageAccess.UNRESTRICTED}
          value={StageAccess.UNRESTRICTED}
          key={StageAccess.UNRESTRICTED}
        >
          <span className={styles.optionTitle}>Unrestricted</span>
        </RadioButton>
        <div className={styles.optionText}>
          <p>
            Give all users in this account full access to view and edit every aspect of this project
            without any restrictions.
          </p>
        </div>
        <RadioButton
          id={StageAccess.RESTRICTED}
          value={StageAccess.RESTRICTED}
          key={StageAccess.RESTRICTED}
        >
          <span className={styles.optionTitle}>Restricted</span>
        </RadioButton>
        <div className={styles.optionText}>
          <p>
            This project&apos;s access is restricted to specifically invited users with standard
            roles. However, all administrators will have automatic access.
          </p>
        </div>
      </RadioButtonGroup>
      <Modal isOpen={isPromptOpen} onRequestClose={cancelHandler} size={ModalSize.Small}>
        <Modal.Section>
          <h1 className={styles.modalHeading}>
            {actualStageAccess === StageAccess.UNRESTRICTED
              ? 'Enable Restricted Access?'
              : 'Enable Unrestricted Access?'}
          </h1>
          <div>
            {actualStageAccess === StageAccess.UNRESTRICTED
              ? 'Please confirm that you would like to start restricting standard users access to this project.'
              : 'Please confirm that you would like to enable unrestricted access to all users on your E1 account to this project. Standard users who were previously restricted from accessing and editing this project, will now be given unrestricted access.'}
          </div>
          <div className={styles.buttons}>
            <Button
              variant={ButtonVariant.Secondary}
              type="button"
              fullWidth
              onClick={cancelHandler}
            >
              Cancel
            </Button>
            <Button type="button" fullWidth onClick={submitHandler}>
              Confirm
            </Button>
          </div>
        </Modal.Section>
      </Modal>
    </>
  );
};
