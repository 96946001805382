import { WatchlistItemAdded } from '@subbie/modal/ConnectionPreferenceSlider/ConnectionPreferenceForm/WatchlistItemAdded';
import { useConnectionPreferenceFormContext } from '@subbie/modal/ConnectionPreferenceSlider/ConnectionPreferenceFormContext';
import {
  ConnectionPreferenceFormStepper,
  ConnectionPreferenceFormStep,
} from '@subbie/modal/ConnectionPreferenceSlider/ConnectionPreferenceFormStepper/ConnectionPreferenceFormStepper';
import { MissingTradesAlert } from '@subbie/modal/MissingTradesAlert/index';
import { PrimaryContactSection } from './PrimaryContactSection';
import { ProjectInvolvement } from './ProjectInvolvement';
import { GlobalFormFieldErrors } from '@shared/FinalForm/types';
import { useCurrentUser } from '@subbie/context/AccountProvider/hooks';
import { useConnectionPreferenceFormSubmission } from './hooks';
import {
  ConnectionPreferenceFormPage,
  ConnectionPreferenceFormValues,
} from '@subbie/modal/ConnectionPreferenceSlider/ConnectionPreferenceFormContext/types';

type ConnectionPreferenceFormProps = {
  onFormComplete: () => void;
};

export const ConnectionPreferenceForm = ({ onFormComplete }: ConnectionPreferenceFormProps) => {
  const {
    pageIndex,
    pageList,
    showWatchlistPage,
    goToFirstPage,
    goToNextPage,
    hideAddedToWatchlist,
    setHideAddedToWatchlist,
  } = useConnectionPreferenceFormContext();
  const currentUser = useCurrentUser();
  const accountHasTrades = currentUser?.account?.stockTrades.length !== 0;
  const isAdmin = currentUser?.isAdmin;

  const submitPreferenceForm = useConnectionPreferenceFormSubmission();

  const submitForm = async (
    values: ConnectionPreferenceFormValues,
  ): Promise<null | GlobalFormFieldErrors> => {
    const page = pageList[pageIndex];
    const errors =
      page === ConnectionPreferenceFormPage.ConnectionPreference
        ? await submitPreferenceForm(values)
        : null;

    if (errors) {
      return errors;
    }

    if (showWatchlistPage() && !hideAddedToWatchlist && pageIndex < pageList.length - 1) {
      goToNextPage();
      return null;
    }

    onFormComplete();
    goToFirstPage();
    return null;
  };

  const toggleHideAddedToWatchlist = () => setHideAddedToWatchlist(!hideAddedToWatchlist);

  return (
    <ConnectionPreferenceFormStepper onSubmit={submitForm}>
      <ConnectionPreferenceFormStep>
        {!accountHasTrades && <MissingTradesAlert isAdminUser={isAdmin} />}
        <ProjectInvolvement isEnabled={accountHasTrades} />
        <PrimaryContactSection isEnabled={accountHasTrades} />
      </ConnectionPreferenceFormStep>
      <ConnectionPreferenceFormStep>
        <WatchlistItemAdded
          hideAddedToWatchlist={hideAddedToWatchlist}
          toggleHideAddedToWatchlist={toggleHideAddedToWatchlist}
        />
      </ConnectionPreferenceFormStep>
    </ConnectionPreferenceFormStepper>
  );
};
