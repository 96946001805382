import { BrowserRouter, Route, Routes } from 'react-router-dom-v5-compat';
import { AddOfficeDetailsDrawer } from '@profiles/features/CompanyProfile/components/OfficeContactList/AddOfficeDetailsDrawer';
import { EditOfficeDetailsDrawer } from '@profiles/features/CompanyProfile/components/OfficeContactList/EditOfficeDetailsDrawer';
import { SSCOptOutConfirmModalRoute } from '@profiles/features/CompanyProfile/components/ProfileSettings/SSCOptOutConfirmModal';
import { AddHistoricalProject } from '@profiles/features/CompanyProfile/components/ProjectHistory/ProjectHistoryActions';
import { ViewerProvider } from '@profiles/features/CompanyProfile/modules/ViewerProvider';
import { CompanyProfile } from '@profiles/features/CompanyProfile/views/CompanyProfile';
import { ProfileInsightsPage } from '@profiles/features/CompanyProfile/views/ProfileInsights';
import { ProfileSettingsPage } from '@profiles/features/CompanyProfile/views/ProfileSettings';
import { FeatureProvider } from '@profiles/providers/FeatureProvider/FeatureProvider';
import { ProfileProvider } from '@profiles/providers/ProfileProvider';
import { TranslationProvider } from '@profiles/providers/TranslationsProvider';
import styles from './CompanyProfileApp.module.scss';
import { ProfileIndexPage } from './components/ProfileIndexPage';
import { ProfileRoute } from '@profiles/apps/CompanyProfile/enums';

export type CompanyProfileAppProps = {
  profileAccountId: number;
};

export const CompanyProfileApp = ({ profileAccountId }: CompanyProfileAppProps) => (
  // @TODO I'm pretty sure this ID is now just used so we can portal the modals somewhere
  // @TODO later we can remove this ID and probably just portal to document.body or something?
  <div id="MFE_PROFILES_ROOT">
    <ViewerProvider profileAccountId={profileAccountId}>
      <FeatureProvider>
        <TranslationProvider>
          <ProfileProvider accountId={profileAccountId}>
            <BrowserRouter basename="profiles">
              <Routes>
                <Route element={<ProfileIndexPage />}>
                  <Route
                    path={ProfileRoute.ProfileInsights}
                    element={
                      <section className={styles.profileInsights}>
                        <ProfileInsightsPage />
                      </section>
                    }
                  />
                  <Route
                    path={ProfileRoute.ProfileSettings}
                    element={
                      <section className={styles.profileSettings}>
                        <ProfileSettingsPage />
                      </section>
                    }
                  >
                    <Route path="ssc">
                      <Route path="edit" element={<SSCOptOutConfirmModalRoute />} />
                    </Route>
                  </Route>
                  <Route
                    path="/"
                    element={
                      <section className={styles.companyProfile}>
                        <CompanyProfile layout="page" />
                      </section>
                    }
                  >
                    <Route path="projects/add" element={<AddHistoricalProject />} />
                    <Route path="offices">
                      <Route path="add" element={<AddOfficeDetailsDrawer />} />
                      <Route path=":officeId/edit" element={<EditOfficeDetailsDrawer />} />
                    </Route>
                  </Route>
                </Route>
              </Routes>
            </BrowserRouter>
          </ProfileProvider>
        </TranslationProvider>
      </FeatureProvider>
    </ViewerProvider>
  </div>
);
