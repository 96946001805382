import { createContext, useEffect, useContext, ReactNode } from 'react';
import { useLazyQuery } from '@apollo/client';
import { CurrencyInfoContext } from '@shared';
import { STAGE_QUOTES_AND_PACKAGES } from './queries';
import { getCurrencyInfo } from '@ascension/enums';
import {
  StageQuotesAndPackages,
  StageQuotesAndPackagesVariables,
  StageQuotesAndPackages_quotes as StageQuote,
  StageQuotesAndPackages_packages as Package,
} from './types/StageQuotesAndPackages';
import { EntityId } from '@ascension/types';

type CompleteQuoteRfq = StageQuote['rfq'];

type BuilderQuotesContextProps = {
  stageQuotes: StageQuote[];
  stagePackages: Package[];
  stageDataLoading: boolean;
};

export const BuilderQuotesContext = createContext<BuilderQuotesContextProps>(
  undefined as unknown as BuilderQuotesContextProps,
);
const { Provider } = BuilderQuotesContext;

const BuilderQuotesProvider = ({
  children,
  stageId,
}: {
  children: ReactNode;
  stageId?: EntityId;
}) => {
  const [fetchStageQuotesAndPackages, { data: stageData, loading: stageDataLoading }] =
    useLazyQuery<StageQuotesAndPackages, StageQuotesAndPackagesVariables>(
      STAGE_QUOTES_AND_PACKAGES,
    );

  const [, setCurrencyInfoContext] = useContext(CurrencyInfoContext);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      if (stageId) {
        await fetchStageQuotesAndPackages({
          variables: { stageId },
        });
      }
    })();
  }, [stageId, fetchStageQuotesAndPackages]);

  useEffect(() => {
    const currencyInfo = getCurrencyInfo(stageData?.stage.account?.primaryCountry?.id);
    setCurrencyInfoContext({ currencyInfo });
  }, [stageData, setCurrencyInfoContext]);

  const value: BuilderQuotesContextProps = {
    stageQuotes: stageData?.quotes ?? [],
    stagePackages: stageData?.packages ?? [],
    stageDataLoading,
  };

  return <Provider value={value}>{children}</Provider>;
};

export { CompleteQuoteRfq, Package, StageQuote };

export default BuilderQuotesProvider;
