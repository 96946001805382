import { CellProps } from '@estimateone/frontend-components';
import { InlineEditableText } from '@builder/common/InlineEditableText';
import { CustomColumLength } from '../utils/customColumns';
import { CustomColumnValues } from '../hooks/useCustomColumns';
import {
  LettingScheduleCustomColumnIdentifier as ColumnIdentifier,
  UpdateLettingScheduleCustomColumnValueInput,
} from '@ascension/_gqltypes/builder.generated';
import { EntityId } from '@ascension/types';
import { Package } from '@builder/features/ProcurementLettingSchedule/types';
import styles from '../styles.scss';

export const CustomColumnCell =
  (
    ariaLabel: string,
    columnIdentifier: ColumnIdentifier,
    customColumnValues: CustomColumnValues,
    debouncedCustomColumnValueUpdate: (
      packageId: EntityId,
      args: UpdateLettingScheduleCustomColumnValueInput,
    ) => void,
    trackCustomColumnValueMaxChar: (updatedValue: string) => void,
  ) =>
  ({ row: { original } }: CellProps<Package, string>) => (
    <div className={styles.cellAlign}>
      <InlineEditableText
        value={customColumnValues[original.id]?.[columnIdentifier] || ''}
        aria-label={ariaLabel}
        onValueChange={async (updatedValue: string) => {
          if (original.lettingScheduleDetails) {
            const args = {
              identifier: columnIdentifier,
              lettingScheduleId: original.lettingScheduleDetails.id,
              value: updatedValue,
            };
            debouncedCustomColumnValueUpdate(original.id, args);
          }
        }}
        maxLength={CustomColumLength.VALUE_MAX_LENGTH}
        minLength={CustomColumLength.VALUE_MIN_LENGTH}
        onValidate={async (updatedValue: string) => {
          trackCustomColumnValueMaxChar(updatedValue);
        }}
      />
    </div>
  );
