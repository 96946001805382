import $ from 'jquery';

$(() => {
  const $body = $('body');

  const switchMode = function (newMode) {
    $('#new-application-mode-input').val(newMode);
    $('#switch-mode-form').submit();
  };

  $body.on('click', '.switch-construction-mode', () => {
    switchMode(2);
  });

  $body.on('click', '.switch-tendering-mode', () => {
    switchMode(1);
  });

  $body.on('click', '.mode_notification_dismiss_link', () => {
    $body.find('.mode_notification').addClass('hide');
  });
});
