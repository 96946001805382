import { useState, ComponentProps } from 'react';
import { useApolloClient } from '@apollo/client';
import Routing from 'routing';
import { Card, CardVariant, InfoIcon, Modal } from '@estimateone/frontend-components';
import { AccountUpgradeModalCheckoutSection } from './CheckoutSection';
import { AccountUpgradeModalPlanDetailsSection } from './PlanDetailsSection';
import { ThankYouPage } from './ThankYouPage';
import { useLiteToEssentialAnalytics } from './hooks/useLiteToEssentialAnalytics';
import { usePaidToPaidUpgrade } from './hooks/usePaidToPaidUpgrade';
import { useContactUsAboutUpgrade } from '@subbie/features/AccountUpgradeFeature/pages/NewUpgradeSelection/hooks';
import { UPGRADE_TYPE } from '@subbie/features/AccountUpgradeFeature/pages/NewUpgradeSelection/hooks/types';
import styles from './styles.scss';

// TODO: retrieve these using a hook
const supportPhone = '1300 705 035';
const supportEmail = 'support@estimateone.com';

enum Page {
  Payment = 'payment',
  ThankYou = 'thank_you',
}

type AccountUpgradeModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const ErrorBanner = () => (
  <Card variant={CardVariant.Red} className={styles.errorBanner}>
    <InfoIcon />
    <p>
      An error occurred while trying to upgrade the account. Please contact E1 on{' '}
      <strong>{supportPhone}</strong> for support
    </p>
  </Card>
);

const PaymentPage = ({
  loading,
  hasPaymentError,
  onPay,
  onHandleEnquiry,
  onHandleUpdatePaymentMethod,
}: {
  loading: boolean;
} & ComponentProps<typeof AccountUpgradeModalCheckoutSection>) => (
  <>
    <h3 className={styles.modalHeader}>Upgrade from Lite to Essential</h3>
    {hasPaymentError && <ErrorBanner />}
    <AccountUpgradeModalPlanDetailsSection />
    <AccountUpgradeModalCheckoutSection
      onPay={onPay}
      onHandleEnquiry={onHandleEnquiry}
      onHandleUpdatePaymentMethod={onHandleUpdatePaymentMethod}
      isPaymentProcessing={loading}
      hasPaymentError={hasPaymentError}
    />
  </>
);

export const AccountUpgradeModal = ({ isOpen, onClose }: AccountUpgradeModalProps) => {
  const [page, setPage] = useState<Page>(Page.Payment);
  const [hasPaymentError, setHasPaymentError] = useState(false);
  const [upgradeInvoiceUrl, setUpgradeInvoiceUrl] = useState<string | undefined>(undefined);
  const { submit: upgradeLiteToEssential, loading } = usePaidToPaidUpgrade();
  const { triggerLiteToEssentialUpgradeStart } = useLiteToEssentialAnalytics();

  triggerLiteToEssentialUpgradeStart(isOpen);

  const apolloClient = useApolloClient();

  const handleClose = async () => {
    if (page === Page.ThankYou) {
      await apolloClient.refetchQueries({
        include: ['GetProjectSliderData'],
      });
    }
    onClose();
  };

  const handlePayment = async () => {
    const result = await upgradeLiteToEssential();
    if (result?.errors) {
      return setHasPaymentError(true);
    }
    setUpgradeInvoiceUrl(result?.invoiceUrl);
    return setPage(Page.ThankYou);
  };

  const openContactUsForm = useContactUsAboutUpgrade(UPGRADE_TYPE.Essential);
  const handleEnquiry = () => {
    openContactUsForm();
  };
  const handleUpdatePaymentMethod = () => {
    window.location.assign(Routing.generate(`app_account_licenses`));
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={handleClose} rootElement="body">
      {page === Page.Payment ? (
        <PaymentPage
          hasPaymentError={hasPaymentError}
          loading={loading}
          onPay={handlePayment}
          onHandleEnquiry={handleEnquiry}
          onHandleUpdatePaymentMethod={handleUpdatePaymentMethod}
        />
      ) : (
        <ThankYouPage
          supportEmail={supportEmail}
          supportPhone={supportPhone}
          invoiceUrl={upgradeInvoiceUrl ?? ''}
          onClose={handleClose}
        />
      )}
    </Modal>
  );
};
