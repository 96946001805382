import Routing from 'routing';
import E1Request from '../../../../../../../js/classes/E1Request';
import SuperSlider from '@ascension/js/classes/SuperSlider';
import { EntityId } from '../../../../../../../types';

type Tab = 'audit' | 'notes';

export const showHistoryModal = async (
  tabToShow: Tab,
  stageId: EntityId,
  rfqId: EntityId,
  onClose?: () => void,
  notesReadOnly = false,
) => {
  const route = Routing.generate('app_stagerfq_viewhistorymodal', { id: stageId, rfqId });
  const req = new E1Request(route, 'GET', { active: tabToShow, notesReadOnly });
  req.setShowLoadingModal(false);
  const response = await req.submit();

  const slider = new SuperSlider(response.template, {
    name: response.slider_name,
    callbacks: {
      onClose: () => onClose?.(),
    },
    containerSelector: '#rfq-super-slider',
  });
  slider.show();

  return response;
};

export default showHistoryModal;
