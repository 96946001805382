import { ReactNode } from 'react';
import { generateCompleteQuoteLink, generateViewQuoteLink } from './routing';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  E1Link,
  Icon,
  IconName,
} from '@estimateone/frontend-components';
import moment from 'moment';
import { Pricing } from '../../../../fec';
import InlinePackageSelector from './InlinePackageSelector';
import QuoteStatusTag from '../../../../shared/Quotes/QuoteStatusTag';
import CompanyNameOrLink from '../../../../shared/Quotes/View/CompanyNameOrLink';
import { Package, StageQuote } from '../../../context/BuilderQuotesProvider';
import { QuoteStatus, StageType } from '@ascension/enums';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

const CompleteQuotePageLink = ({
  children,
  stageId,
  stageType,
  quoteId,
}: {
  children: ReactNode;
  stageId: EntityId;
  stageType: StageType;
  quoteId: EntityId;
}) => (
  <E1Link link={generateCompleteQuoteLink(stageId, stageType, quoteId)} target="_self">
    {children}
  </E1Link>
);

type StageAssignmentProps = {
  stageId: EntityId;
  stageType: StageType;
  stagePackages: Package[];
};

type CommonRowFunctions = {
  handleDelete: () => void;
  handleDownload: () => void;
};

type AssignedRfqQuote = StageQuote;

// cannot assign package to a completed quote
type CompleteQuoteRowProps = CommonRowFunctions & {
  quote: AssignedRfqQuote;
  handleViewSubbieDetails?: () => void;
};

// incomplete quote does not have an RFQ or an amount
type IncompleteQuoteRowProps = StageAssignmentProps &
  CommonRowFunctions & {
    quote: Omit<StageQuote, 'rfq' | 'amount'>;
    handleAssignPackage: (id: EntityId) => void;
  };

type QuoteRowProps = StageAssignmentProps & {
  quote: AssignedRfqQuote;
  onRequestDelete: (quoteId: EntityId) => void;
  onAssignPackage: (quoteId: EntityId, packageId: EntityId) => void;
  onViewSubbieDetails?: (contactId?: EntityId, accountId?: EntityId) => void;
};

type UploadedByDetailsProps = {
  name: string;
  at: string;
};

type ActionButtonProps = {
  canDelete: boolean;
  onDelete: () => void;
  onDownload: () => void;
};

const UploadedByDetails = ({ name, at }: UploadedByDetailsProps) => (
  <>
    <p className={styles.creatorName}>{name}</p>
    <p className={styles.createdAt}>{moment(at).fromNow()}</p>
  </>
);

const ActionButtons = ({ canDelete, onDelete, onDownload }: ActionButtonProps) => (
  <>
    <Button
      size={ButtonSize.Small}
      tooltip="Delete quote"
      aria-label="Delete quote"
      variant={ButtonVariant.Grey}
      onClick={canDelete ? onDelete : undefined}
      isDisabled={!canDelete}
    >
      <Icon name={IconName.Bin} />
    </Button>
    <Button
      size={ButtonSize.Small}
      tooltip="Download quote"
      aria-label="Download quote"
      variant={ButtonVariant.Grey}
      onClick={onDownload}
    >
      <Icon name={IconName.Download} />
    </Button>
  </>
);

const CompletedQuoteRow = ({
  quote,
  handleDelete,
  handleDownload,
  handleViewSubbieDetails = undefined,
}: CompleteQuoteRowProps) => {
  const {
    id,
    title,
    amount,
    rfq,
    canDelete,
    createdBy,
    createdAt,
    uploadedByCurrentAccount,
    viewUrl,
  } = quote;
  const viewLink = generateViewQuoteLink(id);
  return (
    <tr key={id}>
      <td>
        <E1Link link={viewLink} target="_self">
          {title}
        </E1Link>
      </td>
      <td>
        <span>{quote?.package?.title}</span>
      </td>
      <td>
        <p className={styles.companyName}>
          {rfq?.companyName !== null && rfq?.companyName !== undefined && (
            <CompanyNameOrLink
              companyName={rfq.companyName}
              handleViewContactDetails={handleViewSubbieDetails}
            />
          )}
        </p>
        <p className={styles.contactName}>{rfq?.contactName}</p>
      </td>
      <td>
        <Pricing hideCents={amount === 0} price={amount ?? 0} />
      </td>
      <td>
        <UploadedByDetails name={createdBy?.fullName ?? ''} at={createdAt} />
      </td>
      <td>
        {uploadedByCurrentAccount ? (
          <>&mdash;</>
        ) : (
          <QuoteStatusTag
            status={quote.lastDownloadedAt ? QuoteStatus.Downloaded : QuoteStatus.NotDownloaded}
          />
        )}
      </td>
      <td className={styles.quoteRowActions}>
        <Button
          size={ButtonSize.Small}
          tooltip="View quote file"
          aria-label="View quote file"
          variant={ButtonVariant.Grey}
          link={viewUrl}
          target="_blank"
        >
          <Icon name={IconName.View} />
        </Button>
        <ActionButtons canDelete={canDelete} onDelete={handleDelete} onDownload={handleDownload} />
      </td>
    </tr>
  );
};

const IncompleteQuoteRow = ({
  quote,
  stageId,
  stageType,
  stagePackages,
  handleAssignPackage,
  handleDelete,
  handleDownload,
}: IncompleteQuoteRowProps) => {
  const { id, title, createdBy, createdAt, canDelete } = quote;

  type CompleteQuoteLinkProps = { text: string };

  const CompleteQuoteLink = ({ text }: CompleteQuoteLinkProps) => (
    <CompleteQuotePageLink stageId={stageId} stageType={stageType} quoteId={id}>
      {text}
    </CompleteQuotePageLink>
  );

  return (
    <tr key={id}>
      <td>{title}</td>
      <td>
        <InlinePackageSelector
          packages={stagePackages}
          selectedPackageId={quote.package?.id}
          onSelectPackageId={(packageId) => handleAssignPackage(packageId)}
        />
      </td>
      <td>
        <CompleteQuoteLink text="Add subcontractor" />
      </td>
      <td>
        <CompleteQuoteLink text="Add price" />
      </td>
      <td>
        <UploadedByDetails name={createdBy?.fullName ?? ''} at={createdAt} />
      </td>
      <td>&mdash;</td>
      <td className={styles.quoteRowActions}>
        <Button
          size={ButtonSize.Small}
          tooltip="Complete quote"
          variant={ButtonVariant.Grey}
          link={generateCompleteQuoteLink(stageId, stageType, id)}
          target="_self"
        >
          <Icon name={IconName.Edit} />
        </Button>
        <ActionButtons canDelete={canDelete} onDelete={handleDelete} onDownload={handleDownload} />
      </td>
    </tr>
  );
};

const QuoteRow = ({
  quote,
  quote: { id, rfq, downloadUrl },
  stageId,
  stageType,
  stagePackages,
  onRequestDelete,
  onAssignPackage,
  onViewSubbieDetails = undefined,
}: QuoteRowProps) => {
  const handleDelete = () => onRequestDelete(id);
  const handleDownload = () => window.open(downloadUrl, '_blank');
  const handleAssignPackage = (packageId: EntityId) => onAssignPackage(id, packageId);
  const handleViewSubbieDetails =
    onViewSubbieDetails && (rfq?.contact?.company || rfq?.contactAccount)
      ? () => onViewSubbieDetails(rfq.contact?.company?.id, rfq.contactAccount?.id)
      : undefined;

  return rfq !== null ? (
    <CompletedQuoteRow
      quote={quote}
      handleDelete={handleDelete}
      handleDownload={handleDownload}
      handleViewSubbieDetails={handleViewSubbieDetails}
    />
  ) : (
    <IncompleteQuoteRow
      quote={quote}
      stageId={stageId}
      stageType={stageType}
      stagePackages={stagePackages}
      handleAssignPackage={handleAssignPackage}
      handleDelete={handleDelete}
      handleDownload={handleDownload}
    />
  );
};

export default QuoteRow;
