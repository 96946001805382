import { useMutation } from '@apollo/client';
import { SET_AWARDED_RFQ_ON_LETTING_SCHEDULE } from '../mutations';
import {
  SetAwardedRfqOnLettingSchedule,
  SetAwardedRfqOnLettingScheduleVariables,
  SetAwardedRfqOnLettingSchedule_setAwardedRfqOnLettingSchedule as ResponseData,
} from '../types/SetAwardedRfqOnLettingSchedule';
import { EntityId } from '@ascension/types';

type SetAwardedToRfq = {
  submit: (
    lettingScheduleId: EntityId,
    rfqId: EntityId | null,
    notifyUnsuccessfulRfqIds: number[] | null,
  ) => Promise<ResponseData | undefined>;
};

export const useSetAwardedRfqOnLettingSchedule = (): SetAwardedToRfq => {
  const [setAwardedRfqOnLettingSchedule] = useMutation<
    SetAwardedRfqOnLettingSchedule,
    SetAwardedRfqOnLettingScheduleVariables
  >(SET_AWARDED_RFQ_ON_LETTING_SCHEDULE);
  const submit: SetAwardedToRfq['submit'] = async (
    lettingScheduleId,
    rfqId,
    notifyUnsuccessfulRfqIds,
  ) => {
    const isAwarded = rfqId !== null;
    const awardedRfqResponse = await setAwardedRfqOnLettingSchedule({
      variables: {
        input: {
          lettingScheduleId,
          rfqId,
          notifyUnsuccessfulRfqIds,
        },
      },
      optimisticResponse: {
        setAwardedRfqOnLettingSchedule: {
          __typename: 'LettingScheduleDetails',
          id: lettingScheduleId,
          isAwarded,
          awardedToRfq: isAwarded
            ? {
                __typename: 'Rfq',
                id: rfqId,
                companyName: '',
              }
            : null,
        },
      },
    });

    return awardedRfqResponse.data?.setAwardedRfqOnLettingSchedule;
  };

  return { submit };
};
