import React, { useState } from 'react';
import {
  Alert,
  AlertIcon,
  AlertVariant,
  E1Link,
  LinkVariant,
  LoadingSpinner,
  LoadingSpinnerSize,
  Toggle,
} from '@estimateone/frontend-components';
import { reportError } from '@ascension/components/helpers/errorReporter';
import { useProfileContext } from '@profiles/providers/ProfileProvider';
import { useTranslator } from '@profiles/providers/TranslationsProvider';
import {
  CompanyDetailsModalFlow,
  ConfirmCompanyDetailsModal,
} from '@subbie/features/NetworkEnrolmentModals/ConfirmCompanyDetailsModal';
import { useGlobalErrorBanner } from '../../GlobalErrorBanner';
import styles from '../ProfileSettings.module.scss';
import useFlashMessage from '@shared/Util/useFlashMessage';
import { useSubmitDirectoryOptIn } from './hooks';
import { Action, useInteractAnalytics } from '@ascension/components/hooks/Analytics';

export const DirectoryOptInOption = () => {
  const { profile, loading } = useProfileContext();
  const { openBanner } = useGlobalErrorBanner();
  const { success } = useFlashMessage();
  const { addEvent } = useInteractAnalytics();
  const [isCompanyDetailsModalOpen, setIsCompanyDetailsModalOpen] = useState(false);

  const onComplete = () => {
    setIsCompanyDetailsModalOpen(false);
  };

  const translate = useTranslator();
  const { submit } = useSubmitDirectoryOptIn({
    onCompleted: ({ editDirectoryOptInSetting }) => {
      const message =
        editDirectoryOptInSetting.value === 1
          ? `${translate('builders')} can now discover your company in the E1 Network.`
          : `${translate('builders')} can no longer discover your company in the E1 Network.`;

      success({
        title: 'Success',
        message,
      });
      addEvent({
        action:
          editDirectoryOptInSetting.value === 1
            ? Action.SUBBIE_NETWORK_OPT_IN
            : Action.SUBBIE_NETWORK_OPT_OUT,
        flow: 'ProfileSettingsPage',
      });
    },
    onError: (apolloError) => {
      openBanner();
      reportError(new Error(apolloError.message));
    },
  });

  const openCompanyDetailsModal = () => {
    setIsCompanyDetailsModalOpen(true);

    addEvent({
      action: Action.SUBBIE_CONFIRM_DETAILS_ALERT_CLICKED,
    });
  };

  const isOptedIn = profile?.isOptedIntoDirectory ?? false;
  const isDiscoverable = profile?.isDiscoverable ?? false;
  const showDiscoverabilityAlert = isOptedIn && !isDiscoverable;

  return loading ? (
    <section className={styles.setting}>
      <h2 className={styles.settingHeading}>Network Search Visibility</h2>
      <LoadingSpinner size={LoadingSpinnerSize.Medium} />
    </section>
  ) : (
    <>
      <section className={styles.setting} id="root">
        <h2 className={styles.settingHeading}>Network Search Visibility</h2>
        <div className={styles.toggleWrapper}>
          <span>Allow {translate('builders')} to discover you in the E1 Network</span>
          <Toggle
            label="Allow Builders to discover you in the E1 Network"
            checked={isOptedIn}
            onChange={(ev) => submit(ev.currentTarget.checked)}
            size="Small"
          />
        </div>

        {showDiscoverabilityAlert && (
          <Alert variant={AlertVariant.Yellow} className={styles.alert}>
            <div className={styles.alertContents}>
              <AlertIcon />
              <div>
                <h3>Your company is hidden in the Network due to missing profile details.</h3>
                <E1Link onClick={openCompanyDetailsModal} variant={LinkVariant.Primary}>
                  Confirm company details to become discoverable in the Network
                </E1Link>
              </div>
            </div>
          </Alert>
        )}

        <aside className={styles.explanationCard}>
          <h3>How does this work?</h3>
          <ul>
            <li>The E1 Network is where commercial construction networking happens.</li>
            <li>
              {translate('builders')} use the E1 Network to discover and connect with reliable,
              high-quality businesses.
            </li>
          </ul>
          <h3>When is my profile visible?</h3>
          <ul>
            <li>
              When a {translate('builderLabel')} searches and discovers your profile in the E1
              Network.
            </li>
            <li>
              Even if you opt out of being found in the Network, your Profile will be visible when
              interacting with {translate('builders')}, e.g. when requesting or downloading
              documents.
            </li>
          </ul>
        </aside>
      </section>
      <ConfirmCompanyDetailsModal
        isOpen={isCompanyDetailsModalOpen}
        onComplete={onComplete}
        flow={CompanyDetailsModalFlow.ConfirmDetailsAlert}
      />
    </>
  );
};
