import { SingleSelectField } from '@shared/FinalForm/Field/SingleSelectField';
import { Country, ShortStateNameMap, State } from '@ascension/enums';
import { TenderHandoverWizardFieldName } from '@builder/features/TenderHandoverWizard/enums';

export type StateSelectOption = { value: State; label: string };
export type CountrySelectOption = { value: Country; label: string };

export const AUSTRALIAN_STATES: StateSelectOption[] = Object.values(State).reduce(
  (out: StateSelectOption[], value): StateSelectOption[] => {
    if (typeof value === 'number') {
      out.push({
        value,
        label: ShortStateNameMap[value],
      });
    }
    return out;
  },
  [],
);

type RegionSelectorProps = {
  selectedCountry: Country;
  allCountries: CountrySelectOption[] | undefined;
};

export const RegionSelector = ({ selectedCountry, allCountries }: RegionSelectorProps) => (
  <>
    <div>
      {selectedCountry === Country.COUNTRY_AUSTRALIA ? (
        <SingleSelectField<StateSelectOption>
          id="tender-handover-wizard--address-state"
          label="State"
          aria-label="State"
          fieldName={TenderHandoverWizardFieldName.AddressState}
          options={AUSTRALIAN_STATES}
        />
      ) : null}
    </div>
    <div>
      <SingleSelectField<CountrySelectOption>
        id="tender-handover-wizard--address-country"
        label="Country"
        aria-label="Country"
        fieldName={TenderHandoverWizardFieldName.AddressCountry}
        options={allCountries}
        validate={(country) => (!country ? ['Select a Country'] : undefined)}
        isRequired
      />
    </div>
  </>
);
