import { gql } from '@apollo/client';

export const addressFragment = gql`
  fragment DisplayAddressFragment on Address {
    id
    address1
    address2
    address3
    shortAddress
    postcode
  }
`;

export const addressBookCompanyFragment = gql`
  fragment AddressBookCompanyFragment on AddressBookCompany {
    id
    name
    activeContacts {
      id
      email
      fullName
    }
    address {
      ...DisplayAddressFragment
    }
  }
`;

export const profileFragment = gql`
  fragment ProfileFragment on Profile {
    id
    name
    people {
      id
      fullName
      contactable {
        id
        email
      }
    }
    offices {
      id
      name
      isDefaultOffice
      address {
        ...DisplayAddressFragment
      }
    }
  }
`;

export const GET_CONNECTION_SUGGESTIONS = gql`
  ${addressFragment}
  ${addressBookCompanyFragment}
  ${profileFragment}
  query GetConnectionSuggestions {
    connectionSuggestions {
      id
      addressBookCompany {
        ...AddressBookCompanyFragment
      }
      profile {
        ...ProfileFragment
      }
      snippets {
        type
        value
      }
      ignoredAt
      confirmedAt
    }
  }
`;
