import { useCallback, useEffect, useState } from 'react';
import $ from 'jquery';
import Routing from 'routing';
import { Col, Row } from '@estimateone/frontend-components';
import E1Request from '@ascension/js/classes/E1Request';
import CompanyActions, { MERGE_DIRECTION } from './CompanyActions';
import CompanyCard from './CompanyCard';
import { AB_DUPLICATE_COUNT_UPDATE_EVENT, AbDuplicateCountUpdateEventData } from './events';
import LoadingSpinner from '@shared/LoadingSpinner';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

/* see AddressBookCompanyDuplicateController.php */
type DuplicateEntry = {
  id: EntityId;
  name: string;
  address: string | null;
  phone: string | null;
  contacts: {
    id: EntityId;
    fullName: string;
    email: string | null;
    phone: string | null;
  }[];
  trades: string[];
  hasNetworkConnection: boolean;
};

type Duplicate = {
  id: EntityId;
  original: DuplicateEntry;
  duplicate: DuplicateEntry;
  processing?: boolean;
};

const fetchDuplicatesRequest = async () => {
  const route = Routing.generate('api_addressbook_duplicates');
  const response = await new E1Request<
    { success: true; data: { duplicates: Duplicate[]; hasMore: boolean } },
    Record<string, never>
  >(route).submit();

  if (response.success) {
    return response.data.duplicates.map((duplicate) => ({
      processing: false,
      ...duplicate,
    }));
  }

  return [];
};

type ContentProps = {
  duplicateCount: number;
};

const DuplicateABCompaniesContent = ({ duplicateCount }: ContentProps) =>
  duplicateCount === 1 ? (
    <p>Showing possible address book duplicate. Please review and merge.</p>
  ) : (
    <p className="grey">
      Showing <strong>{duplicateCount}</strong> possible address book duplicates. Please review and
      merge.
    </p>
  );

const dismissDuplicate = async (id: EntityId) => {
  const route = Routing.generate('api_addressbook_duplicates_dismiss', { id });
  return new E1Request<{ success: true }, { id: EntityId }>(route, 'POST').submit();
};

const updateDuplicateCount = (duplicateCount: number) =>
  document.dispatchEvent(
    new CustomEvent<AbDuplicateCountUpdateEventData>(AB_DUPLICATE_COUNT_UPDATE_EVENT, {
      detail: { duplicateCount },
    }),
  );

const getMergeDirection = (leftEntry: DuplicateEntry, rightEntry: DuplicateEntry) => {
  if (!leftEntry.hasNetworkConnection && rightEntry.hasNetworkConnection) {
    return MERGE_DIRECTION.RIGHT;
  }

  if (leftEntry.hasNetworkConnection && !rightEntry.hasNetworkConnection) {
    return MERGE_DIRECTION.LEFT;
  }

  if (!leftEntry.hasNetworkConnection && !rightEntry.hasNetworkConnection) {
    return MERGE_DIRECTION.BOTH;
  }

  return MERGE_DIRECTION.NONE;
};

export const AddressBookDuplicates = () => {
  const [{ duplicates, loading }, setDuplicatesResponse] = useState<{
    duplicates: Duplicate[];
    loading: boolean;
  }>({
    duplicates: [],
    loading: true,
  });

  const dismissDuplicateAction = useCallback(
    (id: number) => async () => {
      if (
        !window.confirm('Are you sure you wish to ignore this duplicate? This cannot be undone.')
      ) {
        return;
      }

      setDuplicatesResponse((state) => ({
        ...state,
        duplicates: state.duplicates.map((dupe) =>
          dupe.id === id
            ? {
                ...dupe,
                processing: true,
              }
            : dupe,
        ),
      }));

      const dismissDuplicateResult = await dismissDuplicate(id);

      if (dismissDuplicateResult.success) {
        setDuplicatesResponse((state) => ({
          ...state,
          duplicates: state.duplicates.filter((dupe) => dupe.id !== id),
        }));
      }
    },
    [],
  );

  useEffect(() => {
    $(document).on('update-addressbook-table', async () => {
      const duplicateResponse = await fetchDuplicatesRequest();
      setDuplicatesResponse({
        duplicates: duplicateResponse,
        loading: false,
      });
    });
    $(document).trigger('update-addressbook-table');
  }, []);

  useEffect(() => {
    updateDuplicateCount(duplicates.length);
  }, [duplicates]);

  if (loading) return <LoadingSpinner />;

  if (duplicates.length === 0) {
    return (
      <>
        <h3 className={styles.duplicatesHeader}>Duplicates</h3>
        <p>There are no duplicates in your address book.</p>
      </>
    );
  }

  return (
    <>
      <h3 className={styles.duplicatesHeader}>Duplicates</h3>
      <DuplicateABCompaniesContent duplicateCount={duplicates.length} />
      <div className={styles.companyList}>
        {duplicates.map(({ id, original, duplicate, processing }) => {
          const classes = [styles.companyListItem];

          if (processing) {
            classes.push(styles.processing);
          }

          return (
            <div key={id} className={classes.join(' ')}>
              <Row>
                <Col span={5}>
                  <CompanyCard {...original} />
                </Col>
                <Col span={2}>
                  <CompanyActions
                    originalId={original.id}
                    duplicateId={duplicate.id}
                    onDismiss={dismissDuplicateAction(id)}
                    mergeDirection={getMergeDirection(original, duplicate)}
                  />
                </Col>
                <Col span={5}>
                  <CompanyCard {...duplicate} />
                </Col>
              </Row>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default AddressBookDuplicates;
