import { useCallback, useState } from 'react';
import { Form, useField } from 'react-final-form';
import {
  Button,
  ButtonVariant,
  FieldWrapper,
  WarningIcon,
  TextArea,
} from '@estimateone/frontend-components';
import { LettingScheduleNotesSlideoutSource } from '../LettingScheduleNotesSlideout/LettingScheduleNotesSlideout';
import {
  LettingScheduleNotesFieldNames,
  LettingScheduleNotesFormValues,
} from './LettingScheduleNotesFieldNames';
import { useAddNoteToLettingScheduleDetails } from './hooks/useAddNoteToLettingScheduleDetails';
import { CriticalStatus } from '@ascension/_gqltypes/builder.generated';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

export const MAX_CHARS = 5000;
const FORM_ID = 'letting-schedule-notes-form';

export enum SuccessType {
  FLAG_ADDED_WITHOUT_NOTE,
  NOTE_ADDED,
}

type LettingScheduleNotesFormTextFieldProps = {
  allowEmpty: boolean;
};
const LettingScheduleNotesFormTextField = ({
  allowEmpty,
}: LettingScheduleNotesFormTextFieldProps) => {
  const { input, meta } = useField(LettingScheduleNotesFieldNames.Note, {
    validate: (value: string) => {
      if (value && value.length >= MAX_CHARS) {
        return [`You have reached the ${MAX_CHARS}-character limit`];
      }

      if (!allowEmpty && !value) {
        return ['Note cannot be blank'];
      }

      return undefined;
    },
  });

  return (
    <FieldWrapper errors={meta.error || meta.submitError} hideErrorMessages={!meta.modified}>
      <TextArea
        id="letting-schedule-note-field"
        hideLabel
        autoFocus
        isRequired
        label="Note"
        maxLength={MAX_CHARS}
        placeholder="Add a general note about this package."
        {...input}
      />
    </FieldWrapper>
  );
};

type LettingScheduleNotesFormProps = {
  packageId: EntityId;
  initialIsCritical: boolean;
  onFormSuccess: ({ type }: { type: SuccessType }) => void;
  source: LettingScheduleNotesSlideoutSource;
};

export const LettingScheduleNotesForm = ({
  packageId,
  initialIsCritical,
  onFormSuccess,
  source,
}: LettingScheduleNotesFormProps) => {
  const { submit: addNote, loading } = useAddNoteToLettingScheduleDetails(source);
  const [isCritical, setIsCritical] = useState(initialIsCritical);

  const disableIsCritical = useCallback(() => {
    if (isCritical) {
      setIsCritical(false);
    }
  }, [isCritical]);

  const handleOnSubmit = async (values: LettingScheduleNotesFormValues) => {
    const text = values[LettingScheduleNotesFieldNames.Note];
    const criticalStatus = isCritical ? CriticalStatus.IS_CRITICAL : CriticalStatus.NO_CHANGE;

    await addNote(packageId, text, criticalStatus);
    disableIsCritical();
    onFormSuccess({ type: SuccessType.NOTE_ADDED });
  };

  return (
    <Form<LettingScheduleNotesFormValues> onSubmit={handleOnSubmit}>
      {({ handleSubmit, form, errors }) => (
        <form
          id={FORM_ID}
          onSubmit={async (event) => {
            event.preventDefault();
            if (errors && Object.keys(errors).length === 0) {
              await handleSubmit();
              form.restart();
            }
          }}
        >
          <LettingScheduleNotesFormTextField allowEmpty={isCritical} />
          <Button
            variant={ButtonVariant.Primary}
            type="submit"
            className={styles.addNoteButton}
            disabled={loading || errors?.[LettingScheduleNotesFieldNames.Note]}
          >
            {isCritical ? (
              <>
                <WarningIcon />
                &nbsp; Add Critical Note
              </>
            ) : (
              'Add Note'
            )}
          </Button>
        </form>
      )}
    </Form>
  );
};
