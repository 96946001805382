import { CTASize } from '@ascension/components/fec/CTA';
import ProvisionedLicenseCTA from '@subbie/common/ProvisionedLicenseCTA';
import { PaywallCTA } from './PaywallCTA';
import { RedactedCell } from './RedactedCell';
import { RequiresProjectId } from '../ConsultantsTable/types';
import { EntityId } from '@ascension/types';

export const ConsultantsPaywall = ({
  projectId,
  provisionedLicenseId,
}: RequiresProjectId<{ provisionedLicenseId?: EntityId }>) => (
  <div>
    <table className="table table-data-align-top">
      <thead>
        <tr>
          <th>Company</th>
          <th>Type(s)</th>
          <th>Website</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <RedactedCell />
          <RedactedCell />
          <RedactedCell />
        </tr>
        <tr>
          <RedactedCell />
          <RedactedCell />
          <RedactedCell />
        </tr>
      </tbody>
    </table>
    {provisionedLicenseId ? (
      <ProvisionedLicenseCTA provisionedLicenseId={provisionedLicenseId} size={CTASize.Small} />
    ) : (
      <PaywallCTA projectId={projectId} />
    )}
  </div>
);
