import { Country } from '@ascension/enums';

export enum LANG_REF {
  BADGE_CONTENT,
  SUBCONTRACTOR,
  SUBHEADING_LINE_1,
  SUBHEADING_LINE_2,
  EMPTY_STATE_PROMPT,
  PLACE_SELECT_LABEL,
  PLACE_SELECT_PLACEHOLDER,
}

export const LANG: Record<number, Record<number, string>> = {
  [Country.COUNTRY_AUSTRALIA]: {
    [LANG_REF.BADGE_CONTENT]: 'NEW',
    [LANG_REF.SUBCONTRACTOR]: 'Subbie',
    [LANG_REF.SUBHEADING_LINE_1]:
      'Find subcontractors who have decided to list their E1 profile in our network.',
    [LANG_REF.SUBHEADING_LINE_2]:
      'You can add new subcontractors to your address book to invite them to projects.',
    [LANG_REF.EMPTY_STATE_PROMPT]:
      'Use the trade and suburb filters above to look for subcontractors near you',
    [LANG_REF.PLACE_SELECT_LABEL]: 'Suburb / Postcode',
    [LANG_REF.PLACE_SELECT_PLACEHOLDER]: 'Start typing a suburb or postcode...',
  },
  [Country.COUNTRY_UK]: {
    [LANG_REF.BADGE_CONTENT]: 'BETA',
    [LANG_REF.SUBCONTRACTOR]: 'Subcontractor',
    [LANG_REF.SUBHEADING_LINE_1]:
      'Use the E1 subcontractor network to find and review companies in specific locations.',
    [LANG_REF.SUBHEADING_LINE_2]:
      'You can add new subcontractors to your address book to invite them to projects.',
    [LANG_REF.EMPTY_STATE_PROMPT]:
      'Use the trade and location filters above to look for subcontractors near you',
    [LANG_REF.PLACE_SELECT_LABEL]: 'Location',
    [LANG_REF.PLACE_SELECT_PLACEHOLDER]: 'Start typing...',
  },
  [Country.COUNTRY_IE]: {
    [LANG_REF.BADGE_CONTENT]: 'BETA',
    [LANG_REF.SUBCONTRACTOR]: 'Subcontractor',
    [LANG_REF.SUBHEADING_LINE_1]:
      'Use the E1 subcontractor network to find and review companies in specific locations.',
    [LANG_REF.SUBHEADING_LINE_2]:
      'You can add new subcontractors to your address book to invite them to projects.',
    [LANG_REF.EMPTY_STATE_PROMPT]:
      'Use the trade and location filters above to look for subcontractors near you',
    [LANG_REF.PLACE_SELECT_LABEL]: 'Location',
    [LANG_REF.PLACE_SELECT_PLACEHOLDER]: 'Start typing...',
  },
};
