import { Button, ButtonSize, ButtonVariant } from '@estimateone/frontend-components';
import styles from './styles.module.scss';

type LitePlanCreditsExhaustedButtonsViewProps = {
  onClick: () => void;
};

/** SUBBIE_CREDIT_EXPERIMENT_ALPHA_CODE */
export const LitePlanCreditsExhaustedButtonsView = ({
  onClick,
}: LitePlanCreditsExhaustedButtonsViewProps) => (
  <div className={styles.buttonContainer}>
    <Button
      type="button"
      variant={ButtonVariant.Primary}
      onClick={onClick}
      fullWidth
      size={ButtonSize.Large}
    >
      Upgrade Now
    </Button>
  </div>
);
