import { ReactNode } from 'react';
import { useQuery } from '@apollo/client';
import { useProfileAccountId } from '../ProfileProvider';
import { FeatureContext, FeatureContextProps } from '@profiles/context/FeatureContext';
import { GET_ACCOUNT_FEATURES } from './queries';
import { AvailableFeaturesQuery as AvailableFeatures } from './queries.generated';

const { Provider } = FeatureContext;

type FeatureProviderProps = {
  children: ReactNode;
};

export const FeatureProvider = ({ children }: FeatureProviderProps) => {
  const { accountId } = useProfileAccountId();
  const { loading, data, error } = useQuery<AvailableFeatures>(
    GET_ACCOUNT_FEATURES,
    accountId
      ? {
          variables: { accountId },
        }
      : { skip: true },
  );

  if (loading || data === undefined) {
    return <Provider value={{ loading, context: null, error }}>{children}</Provider>;
  }

  const { availableFeatures } = data;
  const { companyProfileBasic, companyProfileAdvanced } = availableFeatures;

  const value: FeatureContextProps = {
    loading,
    error,
    context: {
      companyProfileBasic,
      companyProfileAdvanced,
    },
  };

  return <Provider value={value}>{children}</Provider>;
};
