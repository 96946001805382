import { ComponentProps } from 'react';
import { joinClassNames } from '@estimateone/frontend-components';
import styles from './styles.module.scss';

export const CompanyHeader = ({ className, ...props }: ComponentProps<'div'>) => {
  const classNames = joinClassNames(styles.companyHeader, className);
  return <div className={classNames} {...props} />;
};

export const CompanyInfo = ({ className, ...props }: ComponentProps<'div'>) => {
  const classNames = joinClassNames(styles.companyNameColumn, className);
  return <div className={classNames} {...props} />;
};

export const CompanyNameLocation = ({ className, ...props }: ComponentProps<'div'>) => {
  const classNames = joinClassNames(styles.nameAndLocationText, className);
  return <div className={classNames} {...props} />;
};

export const AccountStatusColumn = ({ className, ...props }: ComponentProps<'div'>) => {
  const classNames = joinClassNames(styles.accountStatusColumn, className);
  return <div className={classNames} {...props} />;
};
