import { Col, Row, Wrapper } from '@estimateone/frontend-components';
import { SimpleSummaryRow } from './SummaryRow';
import styles from './styles.scss';

type FooterProps = {
  memberSince?: string | null;
  lastActiveAt?: string | null;
};

const dateOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

const formatDate = (dateString: string | null): string =>
  dateString ? new Date(dateString).toLocaleDateString('en-au', dateOptions) : '';

const Footer = ({ memberSince = null, lastActiveAt = null }: FooterProps) => (
  <div className={styles.footerContainer}>
    <Wrapper>
      <Row>
        <Col span={12}>
          <SimpleSummaryRow title="Member since" value={formatDate(memberSince)} />
          <SimpleSummaryRow title="Last active" value={formatDate(lastActiveAt)} />
        </Col>
      </Row>
    </Wrapper>
  </div>
);

export default Footer;
