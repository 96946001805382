import { TextAreaField } from '@shared/FinalForm/Field';
import { InviteToQuoteFormFieldName } from '../../enums';

export const PersonalisedMessageField = () => (
  <TextAreaField
    fieldName={InviteToQuoteFormFieldName.PersonalisedMessage}
    id="invite-to-quote--personalised-message"
    label="Personalised message"
    hideLabel
    rows={6}
    placeholder="Optional: add a personalised message, instructions, or other information here."
  />
);
