import { WatchlistStatus } from '../../../enums';
import { InterestLevel } from '@ascension/_gqltypes/subbie.generated';

export const getLegacyWatchlistStatus = (level: InterestLevel): WatchlistStatus => {
  switch (level) {
    case InterestLevel.EXPLICITLY_RESET:
      return WatchlistStatus.EXPLICITLY_RESET;
    case InterestLevel.INTERESTED:
      return WatchlistStatus.INTERESTED;
    case InterestLevel.NOT_INTERESTED:
      return WatchlistStatus.NOT_INTERESTED;
    case InterestLevel.QUOTING:
      return WatchlistStatus.QUOTING;
    case InterestLevel.QUOTED:
      return WatchlistStatus.QUOTED;
    case InterestLevel.UNACTIONED:
    default:
      return WatchlistStatus.UNACTIONED;
  }
};

const getLevelNameForInterestLevel = (level: InterestLevel) => {
  const capFirst = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

  return level.toLowerCase().split('_').map(capFirst).join(' ');
};

export const getMessageForWatchlistStatusChange = (newStatus: InterestLevel): string => {
  // Maintain parity with existing functionality for now
  switch (newStatus) {
    case InterestLevel.INTERESTED:
    case InterestLevel.NOT_INTERESTED:
    case InterestLevel.QUOTING:
    case InterestLevel.QUOTED:
      return `Interest level changed to ${getLevelNameForInterestLevel(newStatus)}`;
    default:
      return 'Interest level has been removed';
  }
};
