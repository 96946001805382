import { ReactNode, useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import Routing from 'routing';
import E1Request from '@ascension/js/classes/E1Request';
import {
  getLegacyWatchlistStatus,
  getMessageForWatchlistStatusChange,
} from '@subbie/utils/watchlist-utils';
import useFlashMessage from '@shared/Util/useFlashMessage';
import { createGenericContext } from '@shared/context/createGenericContext';
import { UPDATE_WATCHLIST_PROJECT, WATCHLIST_ASSIGN_PROJECT_TO_TEAMMEMBER } from './mutations';
import { WatchlistStatus } from '@ascension/enums';
import {
  AssignProjectToTeamMember,
  AssignProjectToTeamMember_assignProjectToTeamMember_AccountWatchlistProjectAssignment as WatchlistProjectAssignment,
} from './types/AssignProjectToTeamMember';
import { UpdateWatchlistProject } from './types/UpdateWatchlistProject';
import { InterestLevel } from '@ascension/_gqltypes/subbie.generated';
import { EntityId } from '@ascension/types';
import { ProjectSubbieSummary } from '@subbie/modal/types/ProjectSubbieSummary';

export type WatchlistContextProps = {
  applyStatusUpdate: (projectId: EntityId, status: InterestLevel) => void;
  updateStatus: (projectId: EntityId, status: InterestLevel, source: string) => Promise<void>;
  statusesByProject: { [id: number]: InterestLevel };
  applyTeamMemberAssignment: (
    projectId: EntityId,
    watchlistProjectAssignment: WatchlistProjectAssignment,
  ) => void;
  assignTeamMember: (projectId: EntityId, teamMemberId: EntityId, source: string) => Promise<void>;
  assignmentsByProject: { [id: number]: WatchlistProjectAssignment };
};

type WatchlistUpdateSscStatusEventPayload = {
  readonly updateType: 'ssc_status';
  readonly projectId: number;
  readonly projectSubbieSummary: ProjectSubbieSummary['projectSubbieSummary'];
};
type WatchlistUpdateWatchlistStatusEventPayload = {
  readonly updateType: 'watchlist_status';
  readonly projectId: number;
  readonly status: WatchlistStatus;
};
type WatchlistUpdateWatchlistAssigneeEventPayload = {
  readonly updateType: 'watchlist_assignee';
  readonly projectId: number;
  readonly assignee: WatchlistProjectAssignment;
};

export type WatchlistUpdateEventPayload =
  | WatchlistUpdateSscStatusEventPayload
  | WatchlistUpdateWatchlistStatusEventPayload
  | WatchlistUpdateWatchlistAssigneeEventPayload;

export type WatchlistUpdateEvent = CustomEvent<WatchlistUpdateEventPayload>;

export const WATCHLIST_UPDATE_EVENT = 'watchlist-update-project-status';

const [useGenericContext, Provider] = createGenericContext<WatchlistContextProps>('Watchlist');
export const useWatchlistContext = useGenericContext;
// Used to create MockWatchlistProvider
export const GenericWatchlistProvider = Provider;

const WatchlistProvider = ({ children }: { children: ReactNode }) => {
  const [statusesByProject, updateProjectStatuses] = useState<{ [id: number]: InterestLevel }>({});
  const [assignmentsByProject, updateProjectAssignment] = useState<{
    [id: number]: WatchlistProjectAssignment;
  }>({});

  const [assignProjectToTeamMember] = useMutation<AssignProjectToTeamMember>(
    WATCHLIST_ASSIGN_PROJECT_TO_TEAMMEMBER,
  );
  const [updateWatchlistProject] = useMutation<UpdateWatchlistProject>(UPDATE_WATCHLIST_PROJECT);
  const { success: showWatchlistMessage, warning: showWatchlistError } = useFlashMessage();

  // Distinct to updateStatus in that this only applies a status update that has already happened
  // (for example if the status was updated in the backend as part of a mutation and the new status was returned)
  const applyStatusUpdate = useCallback((projectId: EntityId, updatedStatus: InterestLevel) => {
    updateProjectStatuses((prev) => ({ ...prev, [projectId]: updatedStatus }));

    const payload: WatchlistUpdateEventPayload = {
      updateType: 'watchlist_status',
      projectId,
      status: getLegacyWatchlistStatus(updatedStatus),
    } as const;

    // Tell the non-React elements of the application (e.g. twig/jquery parts)
    document.dispatchEvent(
      new CustomEvent<WatchlistUpdateEventPayload>(WATCHLIST_UPDATE_EVENT, { detail: payload }),
    );
  }, []);

  const updateStatus = useCallback(
    async (projectId: EntityId, status: InterestLevel, source?: string) => {
      const { data } = await updateWatchlistProject({
        variables: {
          projectId,
          status,
          source,
        },
      });

      const updatedStatus = data?.watchProject.watchlistEntry?.status;

      if (data && updatedStatus) {
        showWatchlistMessage({
          title: 'Watchlist Updated',
          message: getMessageForWatchlistStatusChange(updatedStatus),
        });

        const legacyWatchlistStatus = getLegacyWatchlistStatus(updatedStatus);

        // Show the CTA for responding to invites on status change
        // TODO: retrieve data via GraphQL and render a React modal
        await new E1Request(
          Routing.generate('app_project_watch_respond_modal', { id: projectId }),
          'POST',
          { status: legacyWatchlistStatus },
        ).submit();

        applyStatusUpdate(projectId, updatedStatus);
      }
    },
    [updateWatchlistProject, showWatchlistMessage, applyStatusUpdate],
  );

  const applyTeamMemberAssignment = useCallback(
    (projectId: EntityId, watchlistProjectAssignment: WatchlistProjectAssignment) => {
      updateProjectAssignment((prev) => ({ ...prev, [projectId]: watchlistProjectAssignment }));

      const payload: WatchlistUpdateEventPayload = {
        updateType: 'watchlist_assignee',
        projectId,
        assignee: watchlistProjectAssignment,
      } as const;

      // Tell the non-React elements of the application (e.g. twig/jquery parts)
      document.dispatchEvent(
        new CustomEvent<WatchlistUpdateEventPayload>(WATCHLIST_UPDATE_EVENT, { detail: payload }),
      );
    },
    [],
  );

  const assignTeamMember = useCallback(
    async (projectId: EntityId, teamMemberId: EntityId, source?: string) => {
      const { data } = await assignProjectToTeamMember({
        variables: {
          projectId,
          teamMemberId,
          source,
        },
      });

      if (data && data.assignProjectToTeamMember.__typename !== 'Errors') {
        const watchlistProjectAssignee = data.assignProjectToTeamMember;

        showWatchlistMessage({
          title: 'Watchlist Updated',
          message: `${watchlistProjectAssignee.project.name} has been assigned to ${watchlistProjectAssignee.assignee.fullName}`,
        });

        applyTeamMemberAssignment(projectId, watchlistProjectAssignee);
      } else if (data) {
        const watchlistProjectAssignee = data.assignProjectToTeamMember;
        showWatchlistError({
          title: 'Watchlist Update Failed',
          message: 'Could not assign project to team member',
        });

        if ('errors' in watchlistProjectAssignee) {
          // eslint-disable-next-line no-console
          console.log(
            `Failed to assign projectId=${projectId} to teamMemberId=${teamMemberId}: ${watchlistProjectAssignee.errors.reduce(
              (out, error) => (error ? `${out}\n${error.message}` : out),
              '',
            )}`,
          );
        }
      }
    },
    [
      assignProjectToTeamMember,
      showWatchlistMessage,
      showWatchlistError,
      applyTeamMemberAssignment,
    ],
  );

  const value: WatchlistContextProps = {
    applyStatusUpdate,
    updateStatus,
    statusesByProject,
    applyTeamMemberAssignment,
    assignTeamMember,
    assignmentsByProject,
  };

  return <Provider value={value}>{children}</Provider>;
};

export default WatchlistProvider;
