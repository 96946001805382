import { useEffect, useState } from 'react';
import { Button, ButtonSize, Modal } from '@estimateone/frontend-components';
import ModalWithCloseIconOnly from './ModalWithCloseIconOnly';
import OtherSubTradesCategorisedList from './OtherSubTradesCategorisedList';
import SuggestedSubTradesList from './SuggestedSubTradesList';
import { ModalName, useModalContext } from '../../context/ModalContext';
import { useSetSubTrades, useChooseSubTradesModalData } from './hooks';
import { EntityId } from '../../../../types';

const ChooseSubTradesModal = () => {
  const [isOpen, setOpen] = useState(false);
  const {
    name,
    data: modalData,
    addModal,
    removeModal,
  } = useModalContext<{ projectIdToShowOnClose?: EntityId; onSubmit: () => void }>();

  const [selectedSubTradeIds, setSelectedSubTradeIds] = useState<EntityId[]>([]);

  const {
    loaded,
    allSubTrades,
    currentSubTradeIds,
    suggestedSubTradeIds,
    userStockTradeSubTradeMap,
  } = useChooseSubTradesModalData(!isOpen);

  const setSubTrades = useSetSubTrades(selectedSubTradeIds);

  useEffect(() => {
    setOpen(name === ModalName.ChooseSubTrades);
  }, [name]);

  useEffect(() => {
    if (!loaded) return;
    setSelectedSubTradeIds(suggestedSubTradeIds);
  }, [loaded]);

  if (!loaded) {
    return null;
  }

  // Editing is not yet supported
  if (currentSubTradeIds.length !== 0) {
    return null;
  }

  const toggleSubTradeSelection = (subTradeId: EntityId) => {
    if (selectedSubTradeIds.includes(subTradeId)) {
      return setSelectedSubTradeIds((prev) => prev.filter((id) => id !== subTradeId));
    }
    return setSelectedSubTradeIds((prev) => prev.concat([subTradeId]));
  };

  const onRequestClose = () => {
    removeModal();
    // re-open the project slider
    if (modalData?.projectIdToShowOnClose) {
      addModal(ModalName.Project, { projectId: modalData.projectIdToShowOnClose });
    }
  };

  const onSubmit = () =>
    setSubTrades().then(() => {
      onRequestClose();
      modalData.onSubmit();
    });

  return (
    <ModalWithCloseIconOnly isOpen={isOpen} onRequestClose={onRequestClose}>
      <Modal.Section>
        <div style={{ maxHeight: 800 }}>
          <h2 style={{ marginTop: -10 }}>Choose your trade-specific items</h2>
          <p>
            We are working on a new feature of E1 that allows you to get a bit more specific about
            what you do so that we can match you with projects more effectively.
          </p>
          <h4>We have suggested some items below based on your nominated trades.</h4>
          <br />
          {Object.entries(userStockTradeSubTradeMap).map(([stockTradeName, matchedSubTrades]) => (
            <div key={stockTradeName}>
              <SuggestedSubTradesList
                matchedSubTrades={matchedSubTrades}
                selectedSubTradeIds={selectedSubTradeIds}
                onCheckBoxClick={toggleSubTradeSelection}
              />
            </div>
          ))}
          <hr />
          <div>
            <h4>Other trade-specific items</h4>
          </div>
          <OtherSubTradesCategorisedList
            subTrades={allSubTrades}
            selectedSubTradeIds={selectedSubTradeIds}
            onCheckBoxClick={toggleSubTradeSelection}
            userSubTradeIds={suggestedSubTradeIds}
          />
        </div>
      </Modal.Section>
      <Modal.Section>
        <Button
          style={{ width: 320 }}
          className="sub-trades-modal-confirm-trigger"
          onClick={onSubmit}
          size={ButtonSize.Large}
        >
          Choose {selectedSubTradeIds.length} trade-specific{' '}
          {selectedSubTradeIds.length === 1 ? 'item' : 'items'}
        </Button>
      </Modal.Section>
    </ModalWithCloseIconOnly>
  );
};

export default ChooseSubTradesModal;
