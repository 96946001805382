import $ from 'jquery';
import Routing from 'routing';
import E1Request, { E1RequestMethod } from '../classes/E1Request';

$(() => {
  if ($('ul.join-account-suggestions-list').length === 0) {
    return;
  }

  const selectAccountToJoin = (accountId: number) =>
    new E1Request<{ success: boolean; redirect: string }>(
      Routing.generate('app_account_merge_suggestions_join', {
        id: accountId,
      }),
      E1RequestMethod.POST,
    ).submit();

  const continueWithoutJoining = () =>
    new E1Request<{ success: boolean; redirect: string }>(
      Routing.generate('app_account_merge_suggestions_continue'),
      E1RequestMethod.POST,
    ).submit();

  const $okButton = $('button[data-trigger="join-account-select"]');
  const $radioButtons = $('input[name="merge-suggestion"]');

  $okButton.on('click', async () => {
    const accountIdToJoin = parseInt($('input[name="merge-suggestion"]:checked').val()!.toString());

    if (accountIdToJoin > 0) {
      return selectAccountToJoin(accountIdToJoin);
    }
    return continueWithoutJoining();
  });

  $radioButtons.on('click change', () => {
    $okButton.prop('disabled', false);
  });
});
