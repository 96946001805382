import { useState } from 'react';
import { Icon, IconName } from '@estimateone/frontend-components';
import { InlineEditableText } from '@builder/common/InlineEditableText';
import {
  CustomColumLength,
  useTrackCustomColumTitleMaxChar,
} from '@builder/features/ProcurementLettingSchedule/utils/customColumns';
import styles from './styles.scss';

type CustomColumnHeaderProps = {
  name: string;
  onRename?: (newName: string | null) => void;
};

export const CustomColumnHeader = ({
  name,
  onRename = () => undefined,
}: CustomColumnHeaderProps) => {
  const [editing, setEditing] = useState(false);
  const [columnName, setColumnName] = useState(name);
  const { trackCustomColumnTitleMaxChar } = useTrackCustomColumTitleMaxChar();

  return (
    <div
      className={styles.header}
      data-tip
      data-for="custom-column-tooltip"
      aria-describedby="custom-column-tooltip"
    >
      {editing ? (
        <InlineEditableText
          aria-label={name}
          autoFocus
          value={columnName || ''}
          onValueChange={(value) => {
            setColumnName(value ?? '');
            onRename(value);
          }}
          onValidate={async (updatedValue: string) => {
            trackCustomColumnTitleMaxChar(updatedValue);
          }}
          onBlur={() => setEditing(false)}
          maxLength={CustomColumLength.TITLE_MAX_LENGTH}
          minLength={CustomColumLength.TITLE_MIN_LENGTH}
          shrinkSizeOnError
        />
      ) : (
        <span
          role="button"
          tabIndex={0}
          onClick={() => setEditing(true)}
          onKeyDown={() => setEditing(true)}
          onFocus={() => setEditing(true)}
        >
          {columnName}
          <Icon className={styles.edit} name={IconName.Edit} />
        </span>
      )}
    </div>
  );
};
