import { getDistanceOptions } from '@builder/common/SubbieNetwork/utils/searchArea/locationOptions';
import { SingleSelectField } from '@shared/FinalForm/Field/SingleSelectField';
import { DistanceUnit } from '@shared/Util';
import {
  SubbieNetworkFilterFormFields,
  DistanceValueOption,
} from '@builder/common/SubbieNetwork/utils/filters/types';

export const DistanceFilter = ({ unit }: { unit: DistanceUnit }) => (
  <SingleSelectField<DistanceValueOption>
    id="drawer-filter-distance"
    inputId="drawer-filter-distance"
    fieldName={SubbieNetworkFilterFormFields.Distance}
    placeholder="Select..."
    label="Distance"
    hideLabel
    options={getDistanceOptions(unit)}
    isClearable
  />
);
