import { StarSolidIcon, Tag, TagVariant } from '@estimateone/frontend-components';

type CoreTradeTagProps = {
  text: string;
  className?: string;
  variant?: TagVariant;
};

export const CoreTradeTag = ({
  text,
  className,
  variant = TagVariant.Practical025,
}: CoreTradeTagProps) => (
  <Tag
    variant={variant}
    icon={<StarSolidIcon width={12} height={12} />}
    text={text}
    className={className}
  />
);
