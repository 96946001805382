import { CheckboxField } from '../../../../../shared/FinalForm/Field';
import { UpdateStageStatusFieldName } from '../../enums';
import styles from '../../styles.scss';

const NotifyOptionOthers = () => (
  <ul className={styles.checkboxContainer}>
    <li className={styles.checkboxListItem}>
      <CheckboxField
        fieldName={UpdateStageStatusFieldName.NotifyInvited}
        id="notify-option-invited"
        label="Invited subbies and suppliers"
      />
    </li>
  </ul>
);

export default NotifyOptionOthers;
