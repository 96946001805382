import { gql } from '@apollo/client';

export const ACCOUNT_SSO_SECRET_KEYS = gql`
  query AccountSsoSecretKeys {
    accountSsoSecretKeys {
      id
      secretRedacted
      expiresAt
      createdAt
      createdBy {
        fullName
      }
    }
  }
`;
