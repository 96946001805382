import { gql } from '@apollo/client';

export const SET_AWARDED_RFQ_ON_LETTING_SCHEDULE = gql`
  mutation SetAwardedRfqOnLettingSchedule($input: SetAwardedRfqOnLettingScheduleInput!) {
    setAwardedRfqOnLettingSchedule(input: $input) {
      id
      isAwarded
      awardedToRfq {
        id
        companyName
      }
    }
  }
`;
