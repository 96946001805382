import { gql } from '@apollo/client';

export const GET_DISCIPLINES = gql`
  query GetDisciplines($stageId: EntityId!) {
    disciplines(stageId: $stageId) {
      id
      name
    }
  }
`;
