import {
  Alert,
  AlertVariant,
  Button,
  ButtonSize,
  ButtonVariant,
  InfoIcon,
  SuccessIllustration,
  TextLink,
} from '@estimateone/frontend-components';
import { useKnowledgeBaseArticle } from '@ascension/components/localisation';
import {
  CompanyCard,
  CompanyCardClickableWrapper,
  CompanyHeading,
} from '@builder/common/CompanyCardWithHeading';
import { showUniversalProfileDrawer } from '@builder/pages/Profiles/UniversalProfileDrawer';
import { LearnMoreBanner } from './LearnMoreBanner/LearnMoreBanner';
import {
  addressBookContact,
  defaultOffice,
  getEmail,
  officeAddress,
  profileContact,
} from './utils';
import PageLoadingSpinner from '@shared/LoadingSpinner/PageLoadingSpinner';
import { pluralise } from '@shared/Util/pluralise';
import { useCurrentUser } from '@builder/context/AccountProvider/hooks';
import { useConfirmConnection, useGetConnectionSuggestions, useIgnoreConnection } from './hooks';
import { GetConnectionSuggestions_connectionSuggestions as Suggestion } from './types/GetConnectionSuggestions';
import styles from './styles.scss';

export const ConnectionSuggestions = () => {
  const user = useCurrentUser();
  const { suggestions, error, loading } = useGetConnectionSuggestions();
  const ignoreConnection = useIgnoreConnection();
  const confirmConnection = useConfirmConnection();
  const knowledgeBaseArticle = useKnowledgeBaseArticle();

  const handleClickAction = async (
    suggestion: Suggestion,
    ignoreOrConfirmAction: (suggestion: Suggestion) => Promise<void>,
  ) => {
    if (!suggestion.profile || !suggestion.addressBookCompany) {
      return;
    }
    await ignoreOrConfirmAction(suggestion);
  };

  const filteredSuggestions = suggestions?.filter(
    (suggestion) => !suggestion.ignoredAt && !suggestion.confirmedAt,
  );

  return (
    <div className={styles.connectionSuggestionsPage}>
      <h1 className={styles.pageTitle}>Connection Suggestions</h1>
      {user?.id && <LearnMoreBanner userId={user.id} />}
      {error && (
        <Alert variant={AlertVariant.Red}>
          <div className={styles.alertContainer}>
            <InfoIcon className={styles.errorIcon} />
            <span className={styles.alertBoldText}>Something went wrong.</span>
            <span>Please come back later or refresh your browser.</span>
          </div>
        </Alert>
      )}
      {loading ? (
        <PageLoadingSpinner />
      ) : !filteredSuggestions || filteredSuggestions.length === 0 ? (
        <div className={styles.emptyState}>
          <SuccessIllustration
            className={styles.emptyStateIllustration}
            title="no more connection suggestions"
          />
          <div className={styles.emptyStateTextSection}>
            <span className={styles.emptyStateTitle}>Nice work. You’re up to date!</span>
            <span>There are no connection suggestions for you</span>
          </div>
        </div>
      ) : (
        <div className={styles.suggestionsContainer}>
          <div className={styles.secondaryTextContainer}>
            <span>
              If the Address Book company and the E1 Network company match select Confirm
              Connection, otherwise, Ignore.{' '}
              <TextLink href={knowledgeBaseArticle('connectionSuggestionsHelp')} external>
                Learn more about connecting with the E1 Network
              </TextLink>
            </span>
            <span>
              {filteredSuggestions.length} {pluralise('Suggestion', filteredSuggestions.length)}{' '}
              found:
            </span>
          </div>
          {filteredSuggestions.map((suggestion) => (
            <div
              key={`connectionSuggestion-${suggestion.id}`}
              className={styles.matchingCompaniesWithButtonContainer}
            >
              <div className={styles.matchingCompaniesContainer}>
                <div className={styles.companyContainer}>
                  <CompanyHeading headingName="Address Book Company" />
                  <CompanyCardClickableWrapper
                    onClick={() => {
                      showUniversalProfileDrawer({ companyId: suggestion.addressBookCompany.id });
                    }}
                  >
                    <CompanyCard
                      key={suggestion.id}
                      companyName={suggestion.addressBookCompany?.name}
                      office={officeAddress('Head Office', suggestion.addressBookCompany.address)}
                      contact={addressBookContact(
                        suggestion.addressBookCompany.activeContacts,
                        getEmail(suggestion.snippets),
                      )}
                    />
                  </CompanyCardClickableWrapper>
                </div>
                <div className={styles.companyContainer}>
                  <CompanyHeading headingName="E1 Network Company" />
                  <CompanyCardClickableWrapper
                    onClick={() => {
                      showUniversalProfileDrawer({ profileId: suggestion.profile?.id });
                    }}
                  >
                    <CompanyCard
                      companyName={suggestion.profile?.name || ''}
                      office={officeAddress(
                        defaultOffice(suggestion.profile?.offices)?.name || 'Head Office',
                        defaultOffice(suggestion.profile?.offices)?.address || null,
                      )}
                      contact={profileContact(
                        suggestion.profile.people,
                        getEmail(suggestion.snippets),
                      )}
                      showNotConnectedIcon
                    />
                  </CompanyCardClickableWrapper>
                </div>
              </div>
              <div className={styles.buttonContainer}>
                <Button
                  variant={ButtonVariant.Grey}
                  size={ButtonSize.Small}
                  onClick={() => handleClickAction(suggestion, ignoreConnection)}
                >
                  Ignore
                </Button>
                <Button
                  variant={ButtonVariant.Secondary}
                  size={ButtonSize.Small}
                  onClick={() => handleClickAction(suggestion, confirmConnection)}
                >
                  Confirm Connection
                </Button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
