import $ from 'jquery';
import E1Request from '../../classes/E1Request';

export default class UserCorrespondenceDraftModule {
  /**
   * @param {Object}  $form
   * @param {String}  key
   * @param {Boolean} isReply
   */
  constructor($form, key, isReply = false) {
    this.storage = window.localStorage;

    if (this.storage === undefined) {
      // localStorage is possibly disabled
      return;
    }

    this.$form = $form;
    this.key = key;
    this.isReply = isReply;
    this.cache = {};

    this.initializeModule();
  }

  /**
   * @returns {String}
   */
  static get defaultKey() {
    return 'new';
  }

  /**
   * @returns {String}
   */
  static get keyPrefix() {
    return 'user_correspondence_draft_cache_';
  }

  /**
   * @returns {Array}
   */
  static get formFields() {
    return [
      'type',
      'title',
      'itemUsers',
      'ccUsers',
      'content',
      'uploads',
      'response',
      'assignee',
      'dueAt',
      'replyAll',
    ];
  }

  /**
   * @returns {Array}
   */
  static get formArrayFields() {
    return ['itemUsers', 'ccUsers', 'uploads'];
  }

  /**
   * @returns {Number}
   */
  static get cleanUpTTL() {
    return 1000 * 60 * 60 * 24 * 7; // 1 week (milliseconds)
  }

  /**
   * @returns {Number}
   */
  static get uploadTmpTimeout() {
    // NOTE: This value should always be less than the file-manager's upload
    // tmpTimeout configuration. Preferably at least one hour below that value.
    // See https://github.com/estimateone/file-manager/blob/master/config/config.js
    return 1000 * 60 * 60 * 7; // 7 hours (milliseconds)
  }

  get key() {
    return this._key;
  }

  set key(key) {
    const keyType = typeof key;

    switch (keyType) {
      case 'undefined':
        this._key =
          UserCorrespondenceDraftModule.keyPrefix + UserCorrespondenceDraftModule.defaultKey;
        break;
      case 'string':
        this._key = UserCorrespondenceDraftModule.keyPrefix + key;
        break;
      case 'number':
        this._key = UserCorrespondenceDraftModule.keyPrefix + key.toString();
        break;
      default:
        throw new Error(`"${keyType}" is not a supported key type`);
    }
  }

  /**
   * Remove user correspondence drafts that have been dormant for
   * longer than the specified TTL
   */
  static cacheCleanUp() {
    const ls = window.localStorage;

    if (ls === undefined) {
      // localStorage is possibly disabled
      return;
    }

    const { keyPrefix } = UserCorrespondenceDraftModule;
    const lastWeek = Date.now() - UserCorrespondenceDraftModule.cleanUpTTL;

    for (let i = 0; i < ls.length; i += 1) {
      const key = ls.key(i);
      if (key.indexOf(keyPrefix) === 0) {
        const keyValue = JSON.parse(ls.getItem(key));
        if (keyValue.lastUpdatedAt !== undefined && keyValue.lastUpdatedAt <= lastWeek) {
          ls.removeItem(key);
        }
      }
    }
  }

  initializeModule() {
    this.retrieveCacheIfExists();
    this.loadCachedFieldData();
    this.registerFormEventHandlers();
    this.registerFormFieldEventHandlers();
  }

  retrieveCacheIfExists() {
    const storedCache = this.storage.getItem(this.key);

    if (typeof storedCache === 'string' && storedCache.length !== 0) {
      this.cache = JSON.parse(storedCache);
    }
  }

  registerFormFieldEventHandlers() {
    UserCorrespondenceDraftModule.formFields.forEach((field) => {
      const $element = this.getElementByField(field);

      switch (field) {
        case 'type':
          $element.on('change', (e) => {
            this.updateFieldCache(field, $(e.currentTarget).val());
          });
          break;
        case 'title':
        case 'content':
          $element.on('change keyup', (e) => {
            let value = $(e.currentTarget).val();
            value = value === '<p><br></p>' ? '' : value;

            if (value.length !== 0) {
              this.updateFieldCache(field, $(e.currentTarget).val());
            } else {
              this.removeFieldFromCache(field);
            }
          });
          break;
        case 'uploads':
          $element.on('upload_complete', (e, files) => {
            this.updateFieldCache(field, files);
          });
          break;
        default:
          $element.on('change keyup', (e) => {
            this.updateFieldCache(field, $(e.currentTarget).val());
          });
          break;
      }
    });
  }

  loadCachedFieldData() {
    if (!this.cacheHasData()) {
      return;
    }

    UserCorrespondenceDraftModule.formFields.forEach((field) => {
      const $element = this.getElementByField(field);
      const data = this.cache.data[field];

      if ($element.length !== 0 && data !== undefined) {
        switch (field) {
          case 'itemUsers':
          case 'ccUsers':
            // When creating a new piece of correspondence from the Directory page,
            // it is possible to check off the users you want to send correspondence
            // to before opening the correspondence slider. In these circumstances we
            // don't want to pre-populate the itemUsers|ccUsers. Instead, let's update the cache
            // with this new users.
            if (!this.isReply && $($element).val().length !== 0) {
              this.updateFieldCache(field, $($element).val());
            } else {
              $($element).val(this.cache.data[field]).trigger('change');
            }
            break;
          case 'replyAll':
            $element.toArray().forEach((el) => {
              const $el = $(el);
              $el.prop('checked', $el.val() === this.cache.data[field]);
            });
            break;
          case 'response':
            $element.toArray().forEach((el) => {
              const $el = $(el);
              $el.prop('checked', $el.val() === this.cache.data[field]);

              if ($el.val() === '1' && this.cache.data[field] === '1') {
                this.$form.find('.response-controls').removeClass('hide');
              }
            });
            break;
          case 'uploads':
            // Don't load uploads from cache if the cache is older than 7 hours.
            // Uploaded documents are deleted from the file manager server if they
            // are not committed within 8 hours of uploading.
            if (
              this.cache.lastUpdatedAt >=
              Date.now() - UserCorrespondenceDraftModule.uploadTmpTimeout
            ) {
              this.cache.data[field].forEach((file) => {
                const option = new Option(file.name, file.name);

                option.selected = true;
                option.dataset.name = file.name;
                option.dataset.hash = file.hash;
                option.dataset.size = file.size;
                option.dataset.type = file.type;

                $element[0].options[$element[0].options.length] = option;
              });
            } else {
              this.$form.find('.correspondence-discard-draft-upload-reattach').removeClass('hide');
            }
            break;
          default:
            $element.val(this.cache.data[field]);
            break;
        }
      }
    });

    this.$form.find('.correspondence-discard-draft-ctnr').removeClass('hide');
  }

  registerFormEventHandlers() {
    this.$form
      .on('destroy_form_cache', () => {
        this.destroyCache();
      })
      .on('discard_draft', () => {
        this.resetForm();
        this.destroyCache();
      })
      .on('on_slider_close', () => {
        if (typeof this.storage.getItem(this.key) === 'string') {
          E1Request.flashMessage({
            type: 'e1-success',
            title: this.$form.attr('data-correspondence-saved'),
          });

          // We must detach all events under the registerFormEventHandlers when
          // the slider is closed, otherwise, events can be fired more than once
          // if the slider is opened and closed, seveeral time in one page load.
          this.detachFormEvents();
        }
      })
      .on('click', '.correspondence-discard-draft-link', () => {
        this.$form.trigger('discard_draft');
      })
      .on('click', '.correspondence-discard-draft-close', () => {
        this.$form.find('.correspondence-discard-draft-ctnr').addClass('hide');
      });
  }

  detachFormEvents() {
    this.$form
      .off('destroy_form_cache')
      .off('discard_draft')
      .off('on_slider_close')
      .off('click', '.correspondence-discard-draft-link')
      .off('click', '.correspondence-discard-draft-close');
  }

  destroyCache(key) {
    const removeKey = key || this.key;

    this.storage.removeItem(removeKey);
    this.cache = {};
  }

  resetForm() {
    UserCorrespondenceDraftModule.formFields.forEach((field) => {
      const $element = this.getElementByField(field);

      if (this.cache.data === undefined) {
        this.$form.find('.correspondence-discard-draft-ctnr').addClass('hide');
        return;
      }

      switch (field) {
        case 'type':
          $element.val('user_general').trigger('change');
          break;
        case 'title':
          $element.val('');
          break;
        case 'itemUsers':
        case 'ccUsers':
          $element.val([]).trigger('change');
          break;
        case 'content':
          $element.val('');
          break;
        case 'uploads':
          $element
            .find('option')
            .toArray()
            .forEach((option) => {
              $(option).remove();
            });
          $element.select2('destroy');
          $element.select2({
            theme: 'bootstrap',
            minimumResultsForSearch: 10,
          });
          break;
        case 'response':
          $element.toArray().forEach((el) => {
            const $el = $(el);
            $el.prop('checked', $el.val() === '0');
          });
          this.$form.find('.response-controls').addClass('hide');
          break;
        case 'assignee':
          $element.val('').trigger('change');
          break;
        case 'dueAt':
          $element.val('');
          break;
        case 'replyAll':
          $element.toArray().forEach((el) => {
            const $el = $(el);
            $el.prop('checked', $el.val() === '1');
          });
          break;
        default:
          break;
      }

      this.emptyQuillEditor();
    });

    // Remove draft notifications
    ['.correspondence-discard-draft-ctnr', '.correspondence-discard-draft-upload-reattach'].forEach(
      (selector) => {
        this.$form.find(selector).remove();
      },
    );
  }

  /**
   * @param {String} field
   * @returns {String}
   */
  getFieldName(field) {
    let fieldName = `${this.getFieldNamePrefix()}[${field}]`;

    if (UserCorrespondenceDraftModule.formArrayFields.indexOf(field) !== -1) {
      fieldName += '[]';
    }

    return fieldName;
  }

  /**
   * @returns {String}
   */
  getFieldNamePrefix() {
    return this.isReply ? 'user_correspondence_mail' : 'user_correspondence';
  }

  /**
   * @param {String} field
   * @returns {Object}
   */
  getElementByField(field) {
    const fieldName = this.getFieldName(field);

    return this.$form.find(`[name="${fieldName}"]`);
  }

  /**
   * @param {String} field
   * @param {Mixed} fieldData
   */
  updateFieldCache(field, fieldData) {
    if (!this.cacheHasData()) {
      this.cache.data = {};
    }

    this.cache.data[field] = fieldData;
    this.cache.lastUpdatedAt = Date.now();

    this.saveCache();
  }

  /**
   *
   * @param {String} field
   */
  removeFieldFromCache(field) {
    if (!this.cacheHasData()) {
      return;
    }

    if (this.cache.data[field] !== undefined) {
      delete this.cache.data[field];

      if (Object.keys(this.cache.data).length < 1) {
        this.destroyCache();
      } else {
        this.saveCache();
      }
    }
  }

  emptyQuillEditor() {
    this.$form.find('.ql-editor').empty();
  }

  saveCache() {
    const jsonString = JSON.stringify(this.cache);
    this.storage.setItem(this.key, jsonString);
  }

  /**
   * @returns {Boolean}
   */
  cacheHasData() {
    return typeof this.cache.data === 'object';
  }
}
