import { DistanceUnit } from '@shared/Util';
import { DISTANCE_OPTIONS, DistanceValueOption } from './types';

const toKilometersRound = (miles: number) => Math.round(miles * 1.61);

/**
 * Returns the distance options based on the unit. If the unit is miles, the
 * label will stay the same (treated as miles) but the value will be converted
 * to kilometers (for search).
 */
export const getDistanceOptions = (unit: DistanceUnit): DistanceValueOption[] =>
  DISTANCE_OPTIONS.map((value) => ({
    label: `${value.toString()}${unit}`,
    value: unit === DistanceUnit.KM ? value : toKilometersRound(value),
  }));
