import { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import Routing from 'routing';
import { ButtonVariant } from '@estimateone/frontend-components';
import E1Request, { E1Response } from '../../../../../../../../js/classes/E1Request';
import { E1PDFViewerLauncher } from '@ascension/js/utils/pdf_doc_viewer';
import { PACKAGE_REQUEST_TEXT } from '../../../../const';
import { GET_PACKAGE_DOWNLOAD_STATUS } from '@subbie/modal/queries';
import { UserPackageDownloadStatus } from '@ascension/_gqltypes/subbie.generated';
import { EntityId } from '@ascension/types';
import { GetPackageStatus, GetPackageStatusVariables } from '@subbie/modal/types/GetPackageStatus';

type ViewDocsResponse = E1Response & {
  approved?: boolean;
  rfq?: EntityId;
};

export type DownloadRequestResponse = E1Response & {
  // eslint-disable-next-line camelcase
  flash_notification: string;
  approved?: boolean;
};

export type PackageRequestBaseButtonProps = {
  text: string;
  tooltipText: string;
  disabled: boolean;
  buttonType: ButtonVariant;
};

const fetchDocumentViewRequest = async (packageId: EntityId) =>
  new E1Request<ViewDocsResponse>(
    Routing.generate('app_packagerfq_viewerrequest', { id: packageId }),
    'POST',
  ).submit();

const fetchPackageDownloadRequest = async (packageId: EntityId) =>
  new E1Request<DownloadRequestResponse>(
    Routing.generate('app_packagerfq_downloadrequest', { id: packageId }),
    'POST',
  ).submit();

export const useViewDocs = (stageId: EntityId, packageId: EntityId) => {
  const [loading, setLoading] = useState(false);

  const viewDocsRequest = useCallback(async () => {
    setLoading(true);
    try {
      const { approved, rfq } = await fetchDocumentViewRequest(packageId);
      if (!(approved && rfq)) return;

      new E1PDFViewerLauncher(stageId, {
        rfqId: rfq,
      }).launchforrfq();
    } finally {
      setLoading(false);
    }
  }, [stageId, packageId]);

  return { loading, viewDocsRequest };
};

export const useDownloadDocs = (packageId: EntityId) => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<DownloadRequestResponse | undefined>();

  const fetch = useCallback(async () => {
    setLoading(true);
    try {
      const res = await fetchPackageDownloadRequest(packageId);
      setResponse(res);
      return res;
    } finally {
      setLoading(false);
    }
  }, [packageId]);

  return { loading, fetch, response };
};

export const usePackageRequestButtonStatus = (status: string | undefined) => {
  const [requestButton, setRequestButton] = useState<PackageRequestBaseButtonProps>();

  const setPackageRequested = useCallback(
    () =>
      setRequestButton({
        text: 'Awaiting Response',
        tooltipText: PACKAGE_REQUEST_TEXT.NEEDS_REQUEST,
        disabled: true,
        buttonType: ButtonVariant.Grey,
      }),
    [],
  );

  useEffect(() => {
    switch (status) {
      case UserPackageDownloadStatus.CAN_REQUEST:
        setRequestButton({
          text: 'Request package',
          tooltipText: PACKAGE_REQUEST_TEXT.NEEDS_REQUEST,
          disabled: false,
          buttonType: ButtonVariant.Secondary,
        });
        break;
      case UserPackageDownloadStatus.REVOKED:
        setRequestButton({
          text: 'Document Access Revoked',
          tooltipText: PACKAGE_REQUEST_TEXT.REVOKED,
          disabled: true,
          buttonType: ButtonVariant.Grey,
        });
        break;
      case UserPackageDownloadStatus.AWAITING_RESPONSE:
        setPackageRequested();
        break;
      case UserPackageDownloadStatus.DECLINED:
        setRequestButton({
          text: 'Request declined',
          tooltipText: PACKAGE_REQUEST_TEXT.DECLINED,
          disabled: true,
          buttonType: ButtonVariant.Grey,
        });
        break;
      case UserPackageDownloadStatus.APPROVED:
      case UserPackageDownloadStatus.AUTO_APPROVED:
      default:
        break;
    }
  }, [status, setRequestButton, setPackageRequested]);

  return { requestButton, setPackageRequested };
};

export const usePackageDownloadStatus = (stageId: number, packageId?: number) => {
  const { loading, data } = useQuery<GetPackageStatus, GetPackageStatusVariables>(
    GET_PACKAGE_DOWNLOAD_STATUS,
    packageId
      ? {
          variables: {
            stageId,
            packageId,
          },
        }
      : { skip: true },
  );

  return { loading, status: data?.userPackageDownloadStatus };
};
