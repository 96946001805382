import { useCallback, useState } from 'react';
import Routing from 'routing';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  FullScreenModal,
  LoadingSpinner,
  joinClassNames,
} from '@estimateone/frontend-components';
import { NotifyForm, NotifyFormSubmitHandler } from '../NotifyForm/NotifyForm';
import { NotifyFormContent } from '../NotifyForm/NotifyFormContent';
import { PageWrapper } from '../RevisionFlow/PageWrapper';
import { usePendingAddendum } from '../RevisionFlow/hooks';
import { useFinaliseMatrix } from '../RevisionFlow/hooks/useFinaliseMatrix';
import { useGetNotifyProgress } from '../RevisionFlow/hooks/useGetNotifyProgress';
import { StageType, getStageTypeAsString } from '@ascension/enums';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

const NOTIFY_POLLING_INTERVAL = 1000;

export type DocumentMatrixNotifyModalProps = {
  stageId: EntityId;
  stageType: StageType;
  root: HTMLElement;
};
export const DocumentMatrixNotifyModal = ({
  stageId,
  stageType,
  root,
}: DocumentMatrixNotifyModalProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const { pendingAddendum, loading: isLoadingAddendum } = usePendingAddendum(stageId);
  const { getNotifyProgress } = useGetNotifyProgress(stageId);

  const { submit } = useFinaliseMatrix();
  const submitHandler: NotifyFormSubmitHandler = useCallback(
    async ({ selectedPackages, title, message, notifyPreferences }) => {
      await submit({
        stageId,
        selectedPackages,
        addendumId: pendingAddendum?.id,
        title,
        message,
        notifyPreferences,
      });

      const progress = await getNotifyProgress();
      progress.observable.subscribe(({ data: response }) => {
        if (response.notifyProgress.matrixChangeProcessed) {
          progress.stopPolling();
          const redirectUrl = Routing.generate('app_stage_view', {
            id: stageId,
            stageType: getStageTypeAsString(stageType)?.toLocaleLowerCase(),
            showMatrixAppCue: true,
          });

          window.location.assign(redirectUrl);
        }
      });
      progress.startPolling(NOTIFY_POLLING_INTERVAL);
    },
    [pendingAddendum?.id, getNotifyProgress, stageId, stageType, submit],
  );

  if (isLoadingAddendum) {
    return <LoadingSpinner />;
  }

  return (
    <FullScreenModal
      isOpen={isOpen}
      overlayClassName={joinClassNames('helper-aware', styles.overlay)}
      className="helper-aware"
    >
      <NotifyForm
        stageId={stageId}
        addendum={pendingAddendum ?? undefined}
        onSubmit={submitHandler}
      >
        {({
          handleSubmit,
          touched,
          message,
          isLoadingDownloadedPackages,
          filteredPackages,
          notificationCount,
          notifyPreferences,
          setNotifyPreferences,
          setTitle,
          setMessage,
          downloadedPackages,
          selectedPackages,
          setSelectedPackages,
          stageSettingNotifyAwarded,
          submissionTried,
          sendingNotifications,
          totalInvites,
        }) => (
          <form id="notify-form" onSubmit={handleSubmit} className={styles.fullHeightWrapper}>
            <FullScreenModal.Header>
              <PageWrapper>
                <div className={styles.headerContainer}>
                  <h2 className={styles.title}>Notify Contacts Affected by Update</h2>
                </div>
              </PageWrapper>
            </FullScreenModal.Header>

            <FullScreenModal.Content>
              <PageWrapper>
                <NotifyFormContent
                  touched={touched}
                  isLoadingDownloadedPackages={isLoadingDownloadedPackages}
                  filteredPackages={filteredPackages}
                  notificationCount={notificationCount}
                  notifyPreferences={notifyPreferences}
                  hasAttemptedSubmission={submissionTried}
                  setTitle={setTitle}
                  message={message}
                  setMessage={setMessage}
                  setNotifyPreferences={setNotifyPreferences}
                  downloadedPackages={downloadedPackages}
                  selectedPackages={selectedPackages}
                  setSelectedPackages={setSelectedPackages}
                  stageSettingNotifyAwarded={stageSettingNotifyAwarded}
                  totalInvites={totalInvites}
                />
              </PageWrapper>
            </FullScreenModal.Content>

            <FullScreenModal.Footer>
              <PageWrapper>
                <div className={styles.actionContainer}>
                  <div>
                    <Button
                      variant={ButtonVariant.Grey}
                      size={ButtonSize.Medium}
                      style={{ width: '192px' }}
                      onClick={() => {
                        setIsOpen(false);
                        const event = new CustomEvent('finalise-matrix-end');
                        root.dispatchEvent(event);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div>
                    <Button
                      size={ButtonSize.Medium}
                      disabled={sendingNotifications}
                      type="submit"
                      style={{ width: totalInvites && totalInvites > 0 ? 'unset' : '192px' }}
                    >
                      {sendingNotifications ? (
                        <>
                          Completing <LoadingSpinner />
                        </>
                      ) : (
                        <>
                          Complete
                          {totalInvites && totalInvites > 0
                            ? ` and Send ${notificationCount} Notification(s)`
                            : null}
                        </>
                      )}
                    </Button>
                  </div>
                </div>
              </PageWrapper>
            </FullScreenModal.Footer>
          </form>
        )}
      </NotifyForm>
    </FullScreenModal>
  );
};
