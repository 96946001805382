import {
  ProjectHistoryFieldNames,
  ProjectHistoryFieldValues,
} from '@profiles/features/CompanyProfile/components/ProjectHistory/ProjectHistoryForm/types';

export const transformValues = (values: ProjectHistoryFieldValues) => {
  const state =
    values[ProjectHistoryFieldNames.State] === null ||
    values[ProjectHistoryFieldNames.State] === undefined
      ? null
      : values[ProjectHistoryFieldNames.State].value;

  return {
    name: values[ProjectHistoryFieldNames.Name] || '',
    builderName: values[ProjectHistoryFieldNames.BuilderName] || '',
    state,
    region: values[ProjectHistoryFieldNames.Region],
    category: values[ProjectHistoryFieldNames.Category]?.value || undefined,
    completionDate: values[ProjectHistoryFieldNames.CompletionDate].toISOString(),
    contractSize: values[ProjectHistoryFieldNames.ContractSize].value,
    linkedProjectId: values[ProjectHistoryFieldNames.LinkedProjectId],
    description: values[ProjectHistoryFieldNames.Description],
  };
};
