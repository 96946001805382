import { useQuery } from '@apollo/client';
import { ACCOUNT_SSO_SECRET_KEYS } from '../queries';
import {
  AccountSsoSecretKeysQuery,
  AccountSsoSecretKeysQueryVariables,
} from '../queries.generated';

export const useAccountSsoSecretKeys = () => {
  const { data, loading, refetch } = useQuery<
    AccountSsoSecretKeysQuery,
    AccountSsoSecretKeysQueryVariables
  >(ACCOUNT_SSO_SECRET_KEYS);

  return {
    accountSsoSecretKeys: data?.accountSsoSecretKeys,
    loading,
    refetch,
  };
};
