import { useMutation } from '@apollo/client';
import { CREATE_SSO_SECRET_KEY } from '../mutations';
import { CreateSsoSecretKey, CreateSsoSecretKeyVariables } from '../types/CreateSsoSecretKey';

export const useCreateAccountSsoSecretKey = () => {
  const [mutate, { loading }] = useMutation<CreateSsoSecretKey, CreateSsoSecretKeyVariables>(
    CREATE_SSO_SECRET_KEY,
  );

  const createSsoSecretKey = async (secret: string, expiresAt: string) => {
    const response = await mutate({
      variables: {
        input: {
          secret,
          expiresAt,
        },
      },
    });

    if (response?.data?.createSsoSecretKey?.__typename === 'Errors') {
      return {
        errors: response.data.createSsoSecretKey.errors.map(({ field, message }) => ({
          field,
          message,
        })),
      };
    }

    return undefined;
  };

  return { createSsoSecretKey, loading };
};
