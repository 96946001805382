import { useState } from 'react';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import { INVITE_CONTACT_TO_PACKAGE } from '../mutations';
import {
  InviteContactToPackage,
  InviteContactToPackage_inviteContactToPackage_Errors as Errors,
  InviteContactToPackage_inviteContactToPackage_Rfq as Rfq,
  InviteContactToPackageVariables,
} from '../types/InviteContactToPackage';
import { RfqCorrespondenceType } from '@ascension/_gqltypes/builder.generated';

type APIError = Errors['errors'][0];

type UseInviteSubbieToPackage = {
  submit: (
    packageId: number,
    contactId: number,
    quotesDueAt: Date,
    personalisedInviteMessage?: string,
    includeScopeOfWorks?: boolean,
    correspondenceType?: RfqCorrespondenceType,
  ) => Promise<{ data?: Rfq; errors?: APIError[] }>;
  responseData?: Rfq;
  responseErrors?: APIError[];
};

export const useInviteSubbieToPackage = (): UseInviteSubbieToPackage => {
  const [responseData, setResponseData] = useState<Rfq>();
  const [responseErrors, setResponseErrors] = useState<APIError[]>();

  const [handleInviteContactToPackage] = useMutation<
    InviteContactToPackage,
    InviteContactToPackageVariables
  >(INVITE_CONTACT_TO_PACKAGE);

  const submit = async (
    packageId: number,
    contactId: number,
    quotesDueAt: Date,
    personalisedInviteMessage?: string,
    includeScopeOfWorks?: boolean,
    correspondenceType?: RfqCorrespondenceType,
  ): Promise<{ data?: Rfq; errors?: APIError[] }> => {
    const input = {
      packageId,
      contactId,
      personalisedInviteMessage,
      quotesDueAt: moment(quotesDueAt).format(),
      includeScopeOfWorks,
      correspondenceType: correspondenceType ?? RfqCorrespondenceType.REQUEST_FOR_QUOTE,
    };

    const { data } = await handleInviteContactToPackage({ variables: { input } });
    const { inviteContactToPackage } = data ?? {};

    if (inviteContactToPackage?.__typename === 'Errors') {
      setResponseErrors(inviteContactToPackage.errors);
      return { data: undefined, errors: inviteContactToPackage.errors };
    }

    setResponseData(inviteContactToPackage);
    return { data: inviteContactToPackage, errors: undefined };
  };

  return { submit, responseData, responseErrors };
};
