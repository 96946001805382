import { useCallback, useState } from 'react';
import { joinClassNames } from '@estimateone/frontend-components';
import ListToggleButton from '../ListToggleButton';
import { groupStockTradesByStockTradeDiscipline, sortAlphabeticalByKeys } from './util';
import { EntityId, StockTrade } from '../../types';
import { ProjectRedactedReason } from '@ascension/_gqltypes/subbie.generated';
import styles from './styles.scss';

type TradeCountInfo = {
  projectTradeCount: number;
  userTradeCount: number;
  isRedactedForUser: boolean;
};

const TradeCountInfo = ({
  projectTradeCount,
  userTradeCount,
  isRedactedForUser,
}: TradeCountInfo) => {
  const projectHasTrades = projectTradeCount !== 0;

  if (!projectHasTrades) {
    return <p>Builders have yet to create trade packages.</p>;
  }

  const tradeCountInfoClass = joinClassNames(isRedactedForUser && styles.redactedText);

  if (userTradeCount === 0) {
    return (
      <p className={tradeCountInfoClass}>
        <span>{projectTradeCount}</span> trades are involved in this project.
      </p>
    );
  }

  return (
    <p className={tradeCountInfoClass}>
      <span>{projectTradeCount}</span> trades are involved in this project, including{' '}
      <span>{userTradeCount}</span> of your trades.
    </p>
  );
};

type ProjectStockTradesProps = {
  uniqueProjectStockTrades: StockTrade[];
  userStockTradeIds: Set<EntityId>;
  redactedReason?: ProjectRedactedReason;
};

const ProjectStockTrades = ({
  uniqueProjectStockTrades,
  userStockTradeIds,
  redactedReason,
}: ProjectStockTradesProps) => {
  const [showAll, setShowAll] = useState(false);
  const handleToggleShowAll = () => setShowAll((prev) => !prev);

  const groupedTrades = useCallback(
    () => sortAlphabeticalByKeys(groupStockTradesByStockTradeDiscipline(uniqueProjectStockTrades)),
    [uniqueProjectStockTrades],
  )();

  const projectTradeCount = uniqueProjectStockTrades.length;
  const userTradesCount = userStockTradeIds.size;
  const hasTrades = projectTradeCount !== 0;
  const hasUserTrades = userTradesCount !== 0;
  const isRedactedForUser = redactedReason !== undefined;

  const userStockTradeMatches = uniqueProjectStockTrades.filter(({ id }) =>
    userStockTradeIds.has(id),
  );

  return (
    <div id="project-trades" className={`${styles.projectTrades} ${styles.projectSection}`}>
      <h3>Trades</h3>
      <TradeCountInfo
        projectTradeCount={projectTradeCount}
        userTradeCount={userStockTradeMatches.length}
        isRedactedForUser={isRedactedForUser}
      />
      {hasUserTrades && !isRedactedForUser && (
        <div className={`${styles.userTrades} clearfix`}>
          <ul title="Trades on this project matching your trades">
            {userStockTradeMatches.map(({ id, name }) => (
              <li key={id} className={styles.userTrade}>
                <span className="glyphicon glyphicon-ok" aria-hidden="true" /> {name}
              </li>
            ))}
          </ul>
        </div>
      )}
      <hr />
      <div>
        {showAll &&
          Array.from(groupedTrades, ([disciplineName, stockTrades]) => (
            <div key={disciplineName} className={styles.allTrades}>
              <h5>{disciplineName}</h5>
              <div className={`${styles.tradeDiscipline} clearfix`}>
                <ul title="All trades on this project">
                  {stockTrades.map(({ id, name }) =>
                    userStockTradeIds.has(id) ? (
                      <li
                        aria-label="trade matching your trades"
                        key={id}
                        className={joinClassNames(userStockTradeIds.has(id) && styles.userTrade)}
                      >
                        <span className="icon icon-check-mini icon-sm" aria-hidden="true" /> {name}
                      </li>
                    ) : (
                      <li aria-label="trade not matching your trades" key={id}>
                        {name}
                      </li>
                    ),
                  )}
                </ul>
              </div>
            </div>
          ))}
      </div>

      {hasTrades && (
        <ListToggleButton
          showFullList={showAll}
          onToggle={handleToggleShowAll}
          href="#project-trades"
          enabled={!isRedactedForUser}
          showAllPrompt={
            <>
              Show all{' '}
              <span className={joinClassNames(isRedactedForUser && styles.redactedText)}>
                {projectTradeCount}
              </span>{' '}
              trades
            </>
          }
          hidePrompt={<>Hide all trades</>}
        />
      )}
    </div>
  );
};

export default ProjectStockTrades;
