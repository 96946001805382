import { createContext, ReactNode, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useNoteCounts } from '@subbie/context/AccountProvider/hooks';
import { NOTE_COUNTS } from '@subbie/context/queries';
import { NoteCounts } from '@subbie/context/types/NoteCounts';
import { EntityId } from '@ascension/types';

export type NoteCountContextProps = {
  counts: Map<number, number>;
  getCount: (id: number) => number | null;
};

export const PROJECT_NOTE_COUNT_CHANGE = 'project-note-added-removed';
export const NoteCountContext = createContext<NoteCountContextProps | undefined>(undefined);
const { Provider } = NoteCountContext;

export const NoteCountProvider = ({ children }: { children: ReactNode }) => {
  const noteCounts = useNoteCounts();
  const [counts, setCounts] = useState<Map<EntityId, number>>(new Map());
  const [fetch, { loading, called, data }] = useLazyQuery<NoteCounts>(NOTE_COUNTS, {
    fetchPolicy: 'no-cache',
  });

  const getCount = (projectId: EntityId) => counts.get(projectId) || null;

  useEffect(() => {
    document.addEventListener(PROJECT_NOTE_COUNT_CHANGE, fetch as EventListener);
    return () => document.removeEventListener(PROJECT_NOTE_COUNT_CHANGE, fetch as EventListener);
  }, [fetch]);

  useEffect(() => {
    if (noteCounts.size !== 0) setCounts(noteCounts);
  }, [noteCounts]);

  useEffect(() => {
    if (!loading && called && data) {
      const { projectNoteCounts } = data;
      setCounts(new Map(projectNoteCounts.map(({ projectId, count }) => [projectId, count])));
    }
  }, [loading, called, data]);

  const value: NoteCountContextProps = {
    counts,
    getCount,
  };

  return <Provider value={value}>{children}</Provider>;
};
