import { LockIcon } from '@estimateone/frontend-components';
import styles from './styles.module.scss';

export const UnlockTenderGraphic = () => (
  <div className={styles.roadblockGraphicContainer}>
    <div className={styles.graphicIconContainer}>
      <div className={styles.graphicIcon}>
        <LockIcon className={styles.lockIcon} />
      </div>
      <p>
        <strong>Unlock tender</strong>
      </p>
      <div className={styles.redactedBlock} />
      <div className={styles.redactedBlock} />
    </div>
  </div>
);
