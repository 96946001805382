import { useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  getConnectionType,
  NetworkCompany,
  populateNewSearchAnalyticsData,
  shouldSendAnalytics,
} from './utils';
import { useAscensionAccountData } from '../../../providers/AscensionDataProvider/hooks';
import { useInteractAnalytics } from '@ascension/components/hooks/Analytics';
import { useConfirmedFilterValues } from '@builder/features/SubbieNetwork/components/SubbieNetworkFilterDrawer/hooks';
import { SEARCH_NETWORK_COMPANIES } from './queries';
import {
  SearchNetworkCompaniesQuery,
  SearchNetworkCompaniesQueryVariables,
} from './queries.generated';
import { ResultType } from '@ascension/_gqltypes/builder.generated';

const CURRENT_PAGE = 1;
const RESULTS_PER_PAGE = 50;
const DISTANCE_OPTION_MAX = 300;

type Results = SearchNetworkCompaniesQuery['searchNetworkCompanies'];

export const useSubbieNetworkSearchWithAnalytics = (): {
  loading: boolean;
  error: Error | null;
  data: null | (Omit<Results, 'results'> & { results: NetworkCompany[] });
} => {
  const confirmedFilters = useConfirmedFilterValues();
  const [queryFilters, setQueryFilters] = useState(confirmedFilters);
  const { addEvent } = useInteractAnalytics();
  const ascAccountData = useAscensionAccountData();
  const {
    stockTradeId,
    lat,
    lon,
    distance,
    workforceSize,
    categoryId,
    companyListId,
    relationshipType,
    contractSizeMin,
    contractSizeMax,
    searchArea,
    serviceArea,
  } = confirmedFilters;

  const location = {
    lat: lat ?? ascAccountData?.geocoordinates.lat ?? 0.0,
    lon: lon ?? ascAccountData?.geocoordinates.lon ?? 0.0,
  };

  const baseVariables: SearchNetworkCompaniesQueryVariables = {
    workforceSize,
    stockTradeIds: stockTradeId ? [stockTradeId.toString()] : [],
    tradeIds: [],
    resultType: ResultType.BOTH,
    projectCategoryIds: categoryId ? [categoryId.toString()] : [],
    connectionType: getConnectionType(relationshipType),
    contractSizeMin,
    contractSizeMax,
    tagIds: companyListId ? [companyListId.toString()] : [],
    page: CURRENT_PAGE,
    resultsPerPage: RESULTS_PER_PAGE,
  };

  const serviceLocationVariables =
    searchArea === 'service-area' && !serviceArea
      ? ({
          serviceLocation: location,
        } satisfies Pick<SearchNetworkCompaniesQueryVariables, 'serviceLocation'>)
      : null;

  const serviceAreaVariables =
    searchArea === 'service-area' && serviceArea?.country
      ? ({
          serviceArea: {
            country: serviceArea.country,
            state: serviceArea.state ?? null,
          },
        } satisfies Pick<SearchNetworkCompaniesQueryVariables, 'serviceArea'>)
      : null;

  const distanceFromVariables = {
    distanceFrom: {
      distance: distance ?? DISTANCE_OPTION_MAX,
      location,
    },
  } satisfies Pick<SearchNetworkCompaniesQueryVariables, 'distanceFrom'>;

  const searchAreaVariables =
    serviceLocationVariables ?? serviceAreaVariables ?? distanceFromVariables;

  const variables = {
    ...baseVariables,
    ...searchAreaVariables,
  };

  const { data, loading, error } = useQuery<
    SearchNetworkCompaniesQuery,
    SearchNetworkCompaniesQueryVariables
  >(SEARCH_NETWORK_COMPANIES, {
    variables,
    fetchPolicy: 'cache-first',
    skip: !stockTradeId,
    onCompleted: ({ searchNetworkCompanies }) => {
      if (shouldSendAnalytics({ confirmedFilters, queryFilters })) {
        const resultsCount = searchNetworkCompanies.results.length || 0;

        addEvent(
          populateNewSearchAnalyticsData({
            confirmedFilters,
            resultsCount,
            currentPage: CURRENT_PAGE,
          }),
        );
        setQueryFilters(confirmedFilters);
      }
    },
  });

  if (loading) {
    return {
      loading: true,
      error: null,
      data: null,
    } as const;
  }

  if (error || !data) {
    return {
      loading: false,
      error: new Error('something went wrong'),
      data: null,
    } as const;
  }

  return {
    loading: false,
    error: null,
    data: {
      __typename: 'SearchNetworkCompaniesResult',
      count: data.searchNetworkCompanies.count,
      totalCount: data.searchNetworkCompanies.totalCount,
      currentPage: data.searchNetworkCompanies.currentPage,
      results: data.searchNetworkCompanies.results.map((result): NetworkCompany => {
        if (
          result.__typename === 'RedactedE1NetworkCompany' ||
          result.__typename === 'RedactedConnectedE1NetworkCompany' ||
          result.__typename === 'RedactedConnectedBuilderNetworkCompany'
        ) {
          throw new Error('Redacted companies should not be returned in search results');
        }
        const company = {
          __typename: result.__typename,
          id: result.id,
          name: result.name,
          address: result.defaultOffice.address,
        };

        if (result.__typename === 'BuilderNetworkCompany') {
          return {
            ...company,
            stockTrades: result.trades
              .map((trade) => trade.stockTrade)
              .filter(
                (trade): trade is { __typename: 'StockTrade'; id: number; name: string } =>
                  trade !== null,
              ),
            coreStockTrades: [],
            contractSizeMin: null,
            contractSizeMax: null,
            logoUrl: null,
            qualifications: result.qualifications,
          };
        }

        return {
          ...company,
          stockTrades: result.stockTrades
            .filter(({ __typename }) => __typename === 'NetworkCompanyStockTrade')
            .map(({ id, name }) => ({ id: Number(id), name })),
          coreStockTrades: result.stockTrades
            .filter(({ __typename }) => __typename === 'NetworkCompanyCoreStockTrade')
            .map(({ id, name }) => ({ id: Number(id), name })),
          contractSizeMin: result.contractSizeMin,
          contractSizeMax: result.contractSizeMax,
          logoUrl: result.__typename === 'ShadowNetworkCompany' ? null : result.logoUrl,
          qualifications:
            result.__typename === 'ConnectedE1NetworkCompany' ||
            result.__typename === 'ConnectedBuilderNetworkCompany' ||
            result.__typename === 'E1NetworkCompany'
              ? result.qualifications
              : [],
        };
      }),
    },
  };
};
