import { Checkbox, CheckboxStatus } from '@estimateone/frontend-components';
import { DocumentLabel } from './DocumentLabel';
import styles from './styles.scss';

type FileSelectorProps = {
  id: string;
  isLocked: boolean;
  name: string;
  type: 'file' | 'folder';
  status: CheckboxStatus;
  handleUpdate: (id: string) => void;
};

export const FileSelectorListItem = ({
  id,
  isLocked,
  type,
  name,
  status,
  handleUpdate,
}: FileSelectorProps) => (
  <li key={id} className={styles.documentItem}>
    <Checkbox
      id={`${type}-${id}`}
      label={<DocumentLabel name={name} isLocked={isLocked} type={type} />}
      disabled={isLocked}
      statusCurrent={status}
      onClick={() => handleUpdate(id)}
    />
  </li>
);
