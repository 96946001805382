import { gql } from '@apollo/client';

export const GET_SCHEDULE_SETTINGS = gql`
  query GetScheduleSettings {
    scheduleSettings {
      id
      letByDateInterval
      quotesDueAtInterval
      startOnSiteDateInterval
      trade {
        id
        name
      }
    }
  }
`;
