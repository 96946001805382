import { Form } from 'react-final-form';
import { Modal, ModalSize, Button, ButtonVariant } from '@estimateone/frontend-components';
import { FieldErrors } from '@estimateone/frontend-components/src/components/Form/FieldWrapper/FieldErrors/FieldErrors';
import { useDeletePackage } from './hooks/useDeletePackage';
import {
  Action as AnalyticsAction,
  useInteractAnalytics,
} from '@ascension/components/hooks/Analytics';
import { ButtonName } from '@ascension/components/hooks/Analytics/buttonNames';
import { ScreenName } from '@ascension/components/hooks/Analytics/screenName';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

export type DeleteDraftPackageModalProps = {
  packageId: EntityId;
  currentPackageTitle: string;
  isOpen: boolean;
  onCloseHandler: () => void;
  onSuccess: () => void;
};

type FormErrors = Record<string, string[]>;

export const DeleteDraftPackageModal = ({
  packageId,
  currentPackageTitle,
  isOpen,
  onCloseHandler,
  onSuccess,
}: DeleteDraftPackageModalProps) => {
  const { submit, loading } = useDeletePackage(onSuccess);
  const { addEvent } = useInteractAnalytics();
  const trackDraftPackageDelete = () =>
    addEvent({
      action: AnalyticsAction.BUTTON_CLICKED,
      buttonName: ButtonName.PROCUREMENT_LETTING_SCHEDULE_TABLE_DELETE_DRAFT_PACKAGE,
      screenName: ScreenName.PROCUREMENT_LETTING_SCHEDULE,
    });

  const handleFormSubmit = async (): Promise<FormErrors | undefined> => {
    await trackDraftPackageDelete();
    const response = await submit(packageId);

    if (response?.__typename === 'Errors') {
      return {
        deletePackage: response.errors.map((e) => e.message),
      };
    }

    return undefined;
  };

  return (
    <Modal size={ModalSize.Small} isOpen={isOpen} onRequestClose={onCloseHandler}>
      <Modal.Section>
        <h1 className={styles.modalHeading}>Delete {currentPackageTitle}?</h1>
        <Form onSubmit={handleFormSubmit}>
          {({ handleSubmit, submitErrors }) => (
            <form onSubmit={handleSubmit}>
              <div>
                <div>This action cannot be undone:</div>
                <ul className={styles.modalInfo}>
                  <li>All schedule information added to this package will be removed.</li>
                  <li>
                    If this procurement package was handed over from a tender project, all copied
                    tender history will be removed.
                  </li>
                </ul>
              </div>

              <div className={styles.modalButtonDiv}>
                <Button
                  className={styles.modalButton}
                  variant={ButtonVariant.Grey}
                  onClick={onCloseHandler}
                >
                  Cancel
                </Button>
                <Button
                  className={styles.modalButton}
                  variant={ButtonVariant.Red}
                  type="submit"
                  disabled={loading}
                >
                  Delete
                </Button>
              </div>
              {submitErrors && submitErrors.deletePackage && (
                <div>
                  <FieldErrors errors={submitErrors.deletePackage} />
                </div>
              )}
            </form>
          )}
        </Form>
      </Modal.Section>
    </Modal>
  );
};
