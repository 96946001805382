import { ReactElement } from 'react';
import { joinClassNames } from '@estimateone/frontend-components';
import styles from './styles.scss';

type ListToggleButtonProps = {
  showFullList: boolean;
  onToggle: () => void;
  showAllPrompt: ReactElement;
  hidePrompt: ReactElement;
  href: string;
  enabled: boolean;
};

const ListToggleButton = ({
  showFullList,
  onToggle,
  showAllPrompt,
  hidePrompt,
  href,
  enabled,
}: ListToggleButtonProps) => (
  <div className={styles.hideShowWrapper}>
    <a
      href={href}
      className={joinClassNames(styles.hideShow, !enabled && styles.disableHideShowAnchor, 'link')}
      onClick={onToggle}
    >
      {showFullList ? hidePrompt : showAllPrompt}
      <span
        className={`${styles.fadedChevron} icon icon-xs icon-chevron-${
          showFullList ? 'up' : 'down'
        }`}
      />
    </a>
  </div>
);

export default ListToggleButton;
