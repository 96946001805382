import $ from 'jquery';
import Routing from 'routing';
import E1Request from '../classes/E1Request';
import Helper from '../classes/Helper';
import { AccountTypeNumeric } from '../../enums';
import { EnquiryNature } from '@ascension/_gqltypes/subbie.generated';

export const CONTACT_US_OPEN_EVENT = 'CONTACT_US_OPEN_EVENT';
export type ContactUsOpenEventPayload = {
  enquiry?: string;
  enquiryNature?: EnquiryNature;
  extraInfo?: Record<string, string>;
};

$(() => {
  const $body = $('body');
  const $helperContainer = $('.helper-container');

  if ($helperContainer.length) {
    const helper = new Helper($helperContainer);

    $body
      .on('click', '.help-tab', async () =>
        helper
          .setUrl(
            Routing.generate('app_help_contextual', {
              route: $helperContainer.data('route'),
              uri: window.location.pathname + window.location.search + window.location.hash,
            }),
          )
          .show()
          .submit(),
      )
      .on('click', '.help-trigger', async ({ currentTarget }) =>
        helper
          .setUrl(
            Routing.generate('app_help_trigger', {
              template: $(currentTarget).data('help-template'),
            }),
          )
          .show()
          .submit(),
      );
  }

  $body.on('click', '.launch-contact-us-modal', ({ currentTarget }) => {
    const accountType = $(currentTarget).data('account-type');

    switch (accountType) {
      // Use React modal instead
      case AccountTypeNumeric.Subcontractor:
      case AccountTypeNumeric.Supplier:
        return document.dispatchEvent(new CustomEvent(CONTACT_US_OPEN_EVENT));
      default:
        return new E1Request(Routing.generate('app_help_contactusmodal'))
          .setShowLoadingModal()
          .submit();
    }
  });
});
