import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GET_SERVICE_AREA_BOUNDARIES_FOR_COUNTRY } from './queries';
import { GetServiceAreaBoundariesForCountryQuery } from './queries.generated';

export const useServiceAreaBoundaryOptionsForCountry = () => {
  const { data, error } = useQuery<GetServiceAreaBoundariesForCountryQuery>(
    GET_SERVICE_AREA_BOUNDARIES_FOR_COUNTRY,
  );

  useEffect(() => {
    if (error !== undefined) {
      throw new Error('Could not fetch service area boundaries');
    }
  }, [error]);

  return {
    data:
      data?.serviceAreaBoundariesForCountry?.map((boundary) => ({
        value: boundary.id,
        label: boundary.shortName,
      })) ?? [],
  };
};
