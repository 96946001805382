/* eslint-disable camelcase */
import { EntityId } from '../../../types';

export const getInitials = (first?: string | null, last?: string | null) =>
  [first, last]
    .map((_) => (_ || '-').charAt(0))
    .join('')
    .toUpperCase();

type SerialisedBool = 'true' | 'false';
type SerialisedBoolNullable = 'true' | 'false' | 'null';

export type SerialisedUserStage = {
  address1: string;
  address2?: string;
  budget_amount: number;
  builder_id: EntityId;
  builder_name: string;
  contact_email: string;
  description?: string;
  // over-zealous serialisation on the backend
  has_downloaded: SerialisedBool;
  has_latest_docs: SerialisedBool;
  is_quoting: SerialisedBoolNullable;
  package: string;
  project_id: EntityId;
  project_name: string;
  rfq_id: EntityId;
  stage_id: EntityId;
  state: string;
  status: number;
  suburb?: string;
  tender_quotes_due_date: string; // e.g. 2020-06-16T00:00:00+00:00
};

export interface UserStage {
  // extends Omit<
  //   SerialisedUserStage,
  //   'tender_quotes_due_date' | 'is_quoting' | 'has_latest_docs' | 'has_downloaded'
  // > {
  id: EntityId;
  tender_quotes_due_date: Date;
  is_quoting: boolean | null;
  has_latest_docs: boolean;
  has_downloaded: boolean;
}

/*
 * see RfqRepository#findStagesForUser
 */
export const formatTenders = (data: SerialisedUserStage[]): UserStage[] =>
  data.map((row) => ({
    ...row,
    id: row.rfq_id,
    tender_quotes_due_date: new Date(row.tender_quotes_due_date),
    is_quoting: JSON.parse(row.is_quoting.toLowerCase()),
    has_latest_docs: JSON.parse(row.has_latest_docs.toLowerCase()),
    has_downloaded: JSON.parse(row.has_downloaded.toLowerCase()),
    /* eslint-enable */
  }));
