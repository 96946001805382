import { MutableRefObject, useEffect } from 'react';

export const useEventListener = (
  target: MutableRefObject<Element> | Element | Window | Document,
  eventName: string,
  handler: (event: Event) => void,
) => {
  useEffect(() => {
    const targetElement = (target as MutableRefObject<Element>).current ?? target;

    if (!targetElement?.addEventListener) {
      return () => null;
    }

    targetElement.addEventListener(eventName, handler);

    return () => {
      targetElement.removeEventListener(eventName, handler);
    };
  }, [target, eventName, handler]);
};
