import { E1Link } from '@estimateone/frontend-components';
import getSupportDetails from '@ascension/js/utils/support_details';
import { numberToWord } from '../../../util';
import styles from './FooterSection.module.scss';
import { pluralise } from '@shared/Util/pluralise';
import { useHasFeature } from '@subbie/context/AccountProvider/hooks';
import { FeatureName } from '@ascension/_gqltypes/subbie.generated';

type FooterSectionProps = {
  userId: number;
  accountId: number;
  creditsAllowed: number;
};

export const FooterSection = ({ userId, accountId, creditsAllowed }: FooterSectionProps) => {
  const hasLiteTierLicense = useHasFeature(FeatureName.CREDITS_LITE_TIER);

  const getFeedbackUrl = () => {
    if (hasLiteTierLicense) {
      return 'https://e1support.typeform.com/to/Ft5VOuu3#user_id=';
    }
    return creditsAllowed === 1
      ? 'https://e1support.typeform.com/to/LaPgXv7V#user_id='
      : 'https://e1support.typeform.com/to/oSYHUTpH#user_id=';
  };

  const feedbackUrl = getFeedbackUrl();

  const { phone } = getSupportDetails();

  const feedbackText = `What are your thoughts on having full access to ${numberToWord(
    creditsAllowed,
  )} new ${pluralise('tender', creditsAllowed)} per month?`;

  return (
    <div className={styles.footerContentContainerCreditExperiment}>
      <p>
        Need help? Call us on <E1Link link={`tel:${phone}`}>{phone}</E1Link>. To help us identify
        you when you call, please quote the following: Account ID: {accountId} User ID: {userId}
      </p>
      <p>{feedbackText}</p>
      <E1Link target="_blank" href={`${feedbackUrl}${userId}`}>
        <strong>Share Feedback</strong>
      </E1Link>
    </div>
  );
};
