import { ReactNode } from 'react';
import { SuccessIcon } from '@estimateone/frontend-components';
import styles from './styles.module.scss';

export const SectionTitle = ({
  children,
  valueSelected = false,
  hasError = false,
}: {
  children: ReactNode;
  valueSelected: boolean;
  hasError?: boolean;
}) => {
  const iconStyle = valueSelected
    ? styles.filterTitleIconSelected
    : styles.filterTitleIconNotSelected;
  const titleStyle = hasError ? styles.filterTitleError : styles.filterTitle;

  return (
    <div className={titleStyle}>
      <h2>{children}</h2>
      <span className={styles.successIcon}>
        <SuccessIcon className={iconStyle} title="Filter is set" />
      </span>
    </div>
  );
};
