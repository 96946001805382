import $ from 'jquery';
import Routing from 'routing';
import moment from 'moment-timezone';
import _ from 'underscore';
import E1Request from '../classes/E1Request';
import GenericList from '../classes/GenericList';

function PackageChangesList($target) {
  this.route = 'app_stagepackage_changes';
  this.$target = $target;
  this.$table = this.$target.find('table.document-history-table');
  this.$tableArea = this.$target.find('.loading-container');
  this.$emptyArea = this.$target.find('.pre-loading-container');
  this.stageId = this.$target.attr('data-stage');
  this.$fromAddendumSelector = this.$target.find('select.from_addendum');
  this.$toAddendumSelector = this.$target.find('select.to_addendum');
  this.$packageSelector = this.$target.find('select.package_list');
  this.changes = {
    added: [],
    removed: [],
  };
  this.request = null;
  this.list = null;

  const self = this;

  self.$fromAddendumSelector.on('change', () => {
    self.fetch();
  });
  self.$toAddendumSelector.on('change', () => {
    self.fetch();
  });
  self.$packageSelector.on('change', () => {
    self.fetch();
  });

  self.createList();
  self.fetch();
}

PackageChangesList.prototype = {
  createList() {
    const self = this;
    const method = self.getMethod();
    self.list = new GenericList(
      self.$table,
      (list) => {
        list.table = list.$target.DataTable({
          paging: false,
          data: list.data,
          order: [[0, 'asc']],
          columns: [
            {
              data: 'document.title',
              render: $.fn.dataTable.render.text(),
            },
            {
              data: 'document.addendum.name',
              render(data) {
                if (data == null) {
                  return '-';
                }
                return _.escape(data);
              },
            },
            {
              data: 'timestamp',
              render: $.fn.dataTable.render.text(),
            },
            {
              data: 'added',
              render(data, col, obj, details) {
                const placeholderCell = list.$placeholderRow.find('td').get(details.col);
                const $col = $(placeholderCell).clone();
                let $span;
                if (data) {
                  $span = $col.find('.status-added');
                } else {
                  $span = $col.find('.status-removed');
                }
                return $span.prop('outerHTML');
              },
            },
          ],
        });
        list.$target.rowGrouping({
          iGroupingColumnIndex: 2,
          sGroupingColumnSortDirection: 'desc',
          bExpandableGrouping: true,
          bSetGroupingClassOnTR: true,
          fnOnGroupCompleted(group) {
            const elem = group.nGroup;
            const timestamp = group.text;

            const timestampObj = moment(timestamp);
            const now = moment();
            let $span;
            // If the date is in the future, then it's a draft change
            if (timestampObj.isAfter(now)) {
              $span = $('<span />').text('Draft Changes');
              $span.addClass('draft-changes-folder');
            } else {
              const datetime = moment(timestamp);
              $span = $('<span />').text(datetime.format('ddd, Do MMMM YYYY @ h:mma'));
            }
            const $folderIcon = $('<i />').addClass('icon-folder');

            const $contents = $('<div />').append($folderIcon).append($span);
            $(elem).find('td:first').html($contents.html());
          },
        });
        list.toggleTableDisplay(true);
      },
      method,
      (response) => {
        const confirmedChanges = JSON.parse(response.confirmed_changes);
        const pendingChanges = JSON.parse(response.pending_changes);
        const changeData = [];

        $.each(confirmedChanges.added_document_changes, (i, change) => {
          change = self.modifyChangeData(change, true, false);
          changeData.push(change);
        });

        $.each(confirmedChanges.removed_document_changes, (i, change) => {
          change = self.modifyChangeData(change, false, false);

          changeData.push(change);
        });

        // Add the pending changes
        $.each(pendingChanges.added_document_changes, (i, change) => {
          change = self.modifyChangeData(change, true, true);
          changeData.push(change);
        });

        $.each(pendingChanges.removed_document_changes, (i, change) => {
          change = self.modifyChangeData(change, false, true);
          changeData.push(change);
        });

        return changeData;
      },
      (list, data) => data,
    );
  },
  modifyChangeData(change, isAdded, isPending) {
    change.added = isAdded;

    if (isPending) {
      // Because draft changes haven't been confirmed yet, they have no timestamp.
      // As we want them to be considered the most recent changes, we're going to give them a timestamp 1 year in the future
      const futureDate = moment().add(1, 'year');
      change.timestamp = futureDate.format();
    } else {
      const timestamp = moment(change.timestamp);
      timestamp.millisecond(0);
      timestamp.second(0);

      change.timestamp = timestamp.format();
    }
    return change;
  },
  getRef() {
    return `${this.stageId}-${this.addendumId}-${this.packageId}`;
  },
  getMethod() {
    const self = this;
    let method = null;
    if (self.canFetch()) {
      method = Routing.generate(self.route, {
        id: self.stageId,
        fromAddendumId: self.$fromAddendumSelector.val(),
        toAddendumId: self.$toAddendumSelector.val(),
        packageId: self.$packageSelector.val(),
      });
    }
    return method;
  },
  canFetch() {
    const self = this;
    return self.$packageSelector.val() > 0;
  },
  fetch() {
    const self = this;
    if (self.canFetch()) {
      self.$emptyArea.addClass('hide');
      self.$tableArea.removeClass('hide');
      self.list.fetchUrl = self.getMethod();
      self.list.updateTable(true, () => {
        self.$tableArea.addClass('has-loaded');
      });
    } else {
      self.$tableArea.addClass('hide');
      self.$emptyArea.removeClass('hide');
    }
  },
};

$(() => {
  const $body = $('body');

  $body.on('modal-loaded', () => {
    const $table = $('table.changeset-table');
    $table.each(function () {
      const table = $(this).DataTable({
        paging: false,
        lengthChange: false,
        searching: false,
        info: false,
      });
      $(this).closest('.loading-container').addClass('has-loaded');
    });
  });

  $body.on('click', '.view-doc-history-trigger', function () {
    const _addendum_id = $(this).attr('data-addendum');
    const _stage_id = $(this).attr('data-stage');
    const data = {
      id: _stage_id,
    };
    if (typeof _addendum_id !== 'undefined') {
      data.addendumId = _addendum_id;
    }

    const route = Routing.generate('app_stagedocument_viewchangesmodal', data);
    const req = new E1Request(route, 'GET');
    req.show_loading_modal = true;
    req.submit();
  });

  $body.on('modal-loaded', function () {
    const $modalContainer = $(this).find('.package_changes_list_container');
    if ($modalContainer.length) {
      const changeList = new PackageChangesList($modalContainer);
    }
  });
});
