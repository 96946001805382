import { ReactNode, useRef, useEffect } from 'react';
import styles from './styles.scss';

type SlideDownAnimationProps = {
  children: ReactNode;
  animated?: boolean;
  timeInMilliseconds?: number;
};

export const SlideDownAnimation = ({
  children,
  animated = true,
  timeInMilliseconds = 300,
  ...props
}: SlideDownAnimationProps) => {
  const animationContainerRef = useRef<HTMLDivElement | null>(null);
  const hasAnimatedInRef = useRef<boolean>(false);

  useEffect(() => {
    const animationContainer = animationContainerRef.current;
    const hasAnimatedIn = hasAnimatedInRef.current;

    if (animated && !hasAnimatedIn && animationContainer) {
      const eventualHeight = animationContainer.scrollHeight;

      // eslint-disable-next-line fp/no-mutation
      animationContainer.style.transition = `height ${timeInMilliseconds}ms`;
      // eslint-disable-next-line fp/no-mutation
      animationContainer.style.height = `${eventualHeight}px`;
      // eslint-disable-next-line fp/no-mutation
      animationContainer.style.overflowY = 'auto';
      hasAnimatedInRef.current = true;
    }
  }, [animated, timeInMilliseconds]);

  return (
    <div
      className={animated ? styles.slidedownAnimated : undefined}
      ref={animationContainerRef}
      {...props}
    >
      {children}
    </div>
  );
};
