import accounting from 'accounting';

export default class Currency {
  constructor(cents, currency) {
    this.symbols = {
      USD: '$', // US Dollar
      AUD: '$', // Australian Dollar
      GBP: '£', // British Pound
      NZD: '$', // New Zealand Dollar
    };
    this.cents = cents;
    this.currency = currency;
  }

  getPrice() {
    return this.cents / 100;
  }

  getSymbol() {
    if (this.symbols[this.currency] !== undefined) {
      return this.symbols[this.currency];
    }
    return null;
  }

  getPriceInFormat() {
    const symbol = this.getSymbol();
    const price = this.getPrice();
    return accounting.formatMoney(price, symbol);
  }
}
