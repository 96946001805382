/* eslint-disable fp/no-mutation */
import { Form } from 'react-final-form';
import { DatePickerFormat, E1Link } from '@estimateone/frontend-components';
import { categoriesOptions } from '@profiles/features/CompanyProfile/components/Categories/EditCategories/EditCategoriesForm/fields/Categories';
import { FormError } from '@profiles/features/CompanyProfile/components/FormError';
import { DatePickerField } from '@profiles/features/CompanyProfile/modules/FinalFormFields/DatePickerField';
import { InputFieldWrapper } from '@profiles/features/CompanyProfile/modules/FinalFormFields/InputFieldWrapper';
import { SingleSelectDropdownField } from '@profiles/features/CompanyProfile/modules/FinalFormFields/SingleSelectDropdownField/SingleSelectDropdownField';
// import { TextAreaWrapper } from '@profiles/features/CompanyProfile/modules/FinalFormFields/TextAreaWrapper';
import { useTranslator } from '@profiles/providers/TranslationsProvider';
import { useKnowledgeBaseLocale } from '@profiles/providers/TranslationsProvider/TranslationsProvider';
import { ContractSizeSelect } from '../ContractSizeSelect';
import { ProjectName, SearchableProjectName } from './FormFields';
import styles from './ProjectHistoryForm.module.scss';
import { useLocalisedKnowledgeBaseLinks } from '@profiles/features/CompanyProfile/hooks';
import {
  DefaultProjectHistoryFieldValues,
  ProjectHistoryFieldLabels,
  ProjectHistoryFieldNames,
  ProjectHistoryFieldValues,
  ProjectHistoryFormErrors,
  ProjectHistoryFormProps,
} from './types';

export const PROJECT_DESCRIPTION_CONTENT_LIMIT = 2000;

export const validate = (values: ProjectHistoryFieldValues): ProjectHistoryFormErrors => {
  const errors: ProjectHistoryFormErrors = {};
  const name = values[ProjectHistoryFieldNames.Name]?.trim();

  const builderNameInput = values[ProjectHistoryFieldNames.BuilderName];
  const builderName = builderNameInput?.trim();

  const state = values[ProjectHistoryFieldNames.State];
  const region = values[ProjectHistoryFieldNames.Region];

  const category = values[ProjectHistoryFieldNames.Category];

  const completionDate = values[ProjectHistoryFieldNames.CompletionDate];

  const contractSize = values[ProjectHistoryFieldNames.ContractSize];

  const description = values[ProjectHistoryFieldNames.Description]?.trim();

  if (!name) {
    errors[ProjectHistoryFieldNames.Name] = [
      'Required - search by project name or ID, or add manually',
    ];
  }

  if (!builderName) {
    errors[ProjectHistoryFieldNames.BuilderName] = ['Required - select an option or add manually'];
  }

  if (!state && !region) {
    errors[ProjectHistoryFieldNames.State] = ['Required - select a State'];
    errors[ProjectHistoryFieldNames.Region] = ['Required - select a Region'];
  }

  if (!category) {
    errors[ProjectHistoryFieldNames.Category] = ['Required - search for or select a category'];
  }

  if (!completionDate) {
    errors[ProjectHistoryFieldNames.CompletionDate] = ['Required - select a month and year'];
  }

  if (!contractSize) {
    errors[ProjectHistoryFieldNames.ContractSize] = ['Required - select a value range'];
  }

  if (description && description.length > PROJECT_DESCRIPTION_CONTENT_LIMIT) {
    errors[ProjectHistoryFieldNames.Description] = [
      `The length of description content should not exceed ${PROJECT_DESCRIPTION_CONTENT_LIMIT} characters`,
    ];
  }

  return errors;
};

// TODO: get these from the backend
const STATES = ['ACT', 'QLD', 'NSW', 'VIC', 'TAS', 'SA', 'WA', 'NT'];

const stateOptions = STATES.map((label, index) => ({
  value: index + 1,
  label,
}));

const State = () => (
  // Need this extra div wrapper to align field errors properly
  <div>
    <SingleSelectDropdownField
      id={ProjectHistoryFieldNames.State}
      label={ProjectHistoryFieldLabels.State}
      placeholder="Select a State"
      isRequired
      fieldName={ProjectHistoryFieldNames.State}
      options={stateOptions}
      menuPortalTarget={document.body}
    />
  </div>
);

const Category = () => (
  <SingleSelectDropdownField
    id={ProjectHistoryFieldNames.Category}
    label="Category"
    placeholder="Search for or select a category"
    isRequired
    fieldName={ProjectHistoryFieldNames.Category}
    options={categoriesOptions}
    menuPortalTarget={document.body}
  />
);

const BuilderNameUkIe = () => (
  <div>
    <InputFieldWrapper<string>
      field="builderName"
      id="builderName"
      label="Contractor or Client Name"
      placeholder="Select an option or add manually"
      isRequired
    />
  </div>
);

const BuilderName = () => (
  <div>
    <InputFieldWrapper<string>
      field="builderName"
      id="builderName"
      label="Builder or Client Name"
      placeholder="Select an option or add manually"
      isRequired
    />
  </div>
);

const Region = () => (
  <div>
    <InputFieldWrapper<string>
      field="region"
      id="region"
      label="Region"
      placeholder="Select a Region"
      isRequired
    />
    <input type="hidden" name={ProjectHistoryFieldNames.State} value={undefined} />
  </div>
);

export const CompletionDate = () => (
  <div>
    <DatePickerField
      wrapperClassName={styles.dateField}
      id={ProjectHistoryFieldNames.CompletionDate}
      field={ProjectHistoryFieldNames.CompletionDate}
      label={ProjectHistoryFieldLabels.CompletionDate}
      dateFormat={DatePickerFormat.OmitDateShortMonth}
      placeholderText="Select a month and year (Mon YYYY)"
      showMonthYearPicker
      isRequired
    />
  </div>
);

// temporarily commented until displayed in subbie and builder apps.
// const Description = () => {
//   return (
//     <div className={styles.textAreaWrapper}>
//       <TextAreaWrapper<string>
//         field={ProjectHistoryFieldNames.Description}
//         id={ProjectHistoryFieldNames.Description}
//         label={ProjectHistoryFieldLabels.Description}
//         placeholder="Description (2000 characters)"
//       />
//     </div>
//   );
// };

const defaultValues: DefaultProjectHistoryFieldValues = {
  id: '',
  builderName: '',
  name: '',
  state: undefined,
  region: null,
  completionDate: undefined,
  category: undefined,
  contractSize: undefined,
  linkedProjectId: null,
  description: null,
};

export const ProjectHistoryForm = ({
  id,
  initialValues = defaultValues,
  onSubmit,
  canSearchProjects = false,
  error,
  isEdit = false,
}: ProjectHistoryFormProps) => {
  const translate = useTranslator();
  const isUKOrIE = ['gb', 'ie'].includes(translate('location'));
  const locale = useKnowledgeBaseLocale();
  const { projectExperienceLearnMoreArticle } = useLocalisedKnowledgeBaseLinks(locale);

  return (
    <Form<ProjectHistoryFieldValues>
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
    >
      {({ handleSubmit }) => (
        <form id={id} onSubmit={handleSubmit}>
          {error && <FormError />}
          <h3 className={styles.formHeader}>{isEdit ? `Edit Project` : `Add Project`}</h3>
          <p className={styles.formDescription}>
            Add a past or current project to showcase your capability.
            <E1Link
              className={styles.kbArticleLink}
              target="_blank"
              href={projectExperienceLearnMoreArticle}
            >
              Learn more
            </E1Link>
          </p>
          <div className={styles.projectHistoryForm}>
            {canSearchProjects ? <SearchableProjectName /> : <ProjectName />}
            <div className={styles.adjacentFields}>
              <Category />
              {isUKOrIE ? <Region /> : <State />}
            </div>
            {isUKOrIE ? <BuilderNameUkIe /> : <BuilderName />}
            <div className={styles.adjacentFields}>
              <CompletionDate />
              <ContractSizeSelect />
            </div>
            {/* <Description /> */}
          </div>
        </form>
      )}
    </Form>
  );
};
