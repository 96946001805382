import { useCallback, useEffect } from 'react';
import Routing from 'routing';
import {
  Button,
  ButtonSize,
  Card,
  CardSize,
  CardVariant,
  Checkbox,
  CheckboxStatus,
  E1Link,
} from '@estimateone/frontend-components';
import { Pricing } from '../../../../fec';
import EftposForm from '../../../features/AccountUpgradeFeature/forms/LegacyForms/EftposForm';
import { PaymentData } from '..';
import { ErrorObject, isCCFormPopulated } from '../utils';
import BillingContactSelector from './BillingContactSelector';
import CreditCardSelector from './CreditCardSelector';
import PaymentMethodSelector from './PaymentMethodSelector';
import { Action, Event, useAnalytics } from '../../../../hooks/Analytics';
import { BillingCycle, PaymentMethod } from '@ascension/enums';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

export type BillingContact = {
  id: EntityId;
  fullName: string;
};

export type PaymentDetailsCreditCard = {
  id: EntityId;
  maskedNumber: string;
  expiry: string;
};

type PaymentDetailsProps = {
  billingCycle: BillingCycle;
  price: number;
  onPreviousPage: () => void;
  creditCards: PaymentDetailsCreditCard[];
  setPaymentData: (data: (p: PaymentData) => PaymentData) => void;
  paymentData: PaymentData;
  billingContacts: BillingContact[];
  setBillingContact: (contact: BillingContact) => void;
  selectedBillingContact?: BillingContact;
  onSubmit: () => void;
  isSubmitDisabled: boolean;
  purchaseErrors: ErrorObject;
};

const PaymentDetails = ({
  billingCycle,
  price,
  onPreviousPage,
  creditCards,
  paymentData,
  setPaymentData,
  selectedBillingContact,
  billingContacts,
  setBillingContact,
  onSubmit,
  purchaseErrors,
  isSubmitDisabled,
}: PaymentDetailsProps) => {
  const isAnnual = billingCycle === BillingCycle.Months12;
  const { paymentMethod, creditCardId, terms } = paymentData;

  const updatePaymentData = useCallback(
    (data: Partial<PaymentData>) => setPaymentData((p: PaymentData) => ({ ...p, ...data })),
    [setPaymentData],
  );
  const updatePaymentMethod = (method: PaymentMethod) => {
    updatePaymentData({ paymentMethod: method, creditCardId: undefined });
  };
  const toggleTerms = () => updatePaymentData({ terms: !terms });

  const { addEvent } = useAnalytics(Event.INTERACT);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () =>
      addEvent({
        action: Action.ADDON_PURCHASE_PAYMENT_DETAILS,
      }))();
  }, [addEvent]);

  const isCC = paymentMethod === PaymentMethod.CC;
  const isNewCard = creditCardId !== null;
  const canSubmit =
    (!isCC || isCCFormPopulated(paymentData) || isNewCard) &&
    selectedBillingContact !== null &&
    terms &&
    !isSubmitDisabled;

  return (
    <>
      <Card variant={CardVariant.Blue} size={CardSize.Small}>
        <Card.Body>
          <div className={styles.cardContent}>
            <span className={styles.subscriptionLength}>
              {isAnnual ? 'Annual' : 'Monthly'} Subscription{' '}
              <span className={styles.price}>
                <Pricing hideCents={price === 0} price={price} />
              </span>
            </span>
            <E1Link onClick={onPreviousPage}>Change</E1Link>
          </div>
        </Card.Body>
      </Card>
      <h3 className={styles.paymentDetailsHeading}>Payment details</h3>
      {isAnnual && (
        <PaymentMethodSelector paymentMethod={paymentMethod} onSelection={updatePaymentMethod} />
      )}
      <div className={styles.paymentSelector}>
        {isCC ? (
          <CreditCardSelector
            creditCards={creditCards}
            updatePaymentData={updatePaymentData}
            purchaseErrors={purchaseErrors}
          />
        ) : (
          <EftposForm />
        )}
      </div>
      <BillingContactSelector
        billingContacts={billingContacts}
        selectedBillingContact={selectedBillingContact}
        setBillingContact={setBillingContact}
      />
      <div className={styles.termsContainer}>
        <Checkbox
          id="terms"
          label="Terms and conditions"
          statusCurrent={terms ? CheckboxStatus.Checked : CheckboxStatus.Unchecked}
          onClick={toggleTerms}
          hideLabel
        />
        <div className={styles.terms}>
          I certify that I have read, understood and accepted the{' '}
          <E1Link target="_blank" rel="noopener noreferrer" link={Routing.generate('terms_of_use')}>
            Terms of Use
          </E1Link>{' '}
          and{' '}
          <E1Link
            target="_blank"
            rel="noopener noreferrer"
            link={Routing.generate('privacy_policy')}
          >
            Privacy policy
          </E1Link>
          .
        </div>
      </div>
      <Button fullWidth isDisabled={!canSubmit} onClick={onSubmit} size={ButtonSize.Large}>
        Purchase add-on
      </Button>
    </>
  );
};

export default PaymentDetails;
