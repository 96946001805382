import $ from 'jquery';
import Form from '../classes/Form';
// eslint-disable-next-line import/no-unresolved
import { eWayFieldType, eWaySecureFieldConfig, eWaySecureFieldEvent } from '../custom';

const initEwayForm = (isEventHandlerAttached: boolean) => {
  const $form = $('.eway-form');
  const $ewaySubForm = $form.find('div[data-form-name="eway_form"]');

  if (!$form.length) {
    return;
  }

  const isSubForm = $ewaySubForm.length !== 0;

  const $actualForm = isSubForm ? $ewaySubForm : $form;

  const eWayValidFields: { [f in eWayFieldType]: boolean } = {
    name: false,
    card: false,
    expiry: false,
    expirytext: true,
    cvn: false,
  };

  const eWayFormIsValid = (): boolean => Object.values(eWayValidFields).every((_) => _);

  const $submitButton = $form.find('button[type="submit"]');
  const $secureCodeField = $form.find('input[data-eway-field="secureCode"]');

  const eWaySecureFieldCallback = ({
    fieldValid,
    secureFieldCode,
    targetField,
    valueIsSaved,
    valueIsValid,
    errors,
  }: eWaySecureFieldEvent) => {
    if (!fieldValid) {
      throw new Error(errors);
    }

    const $iframe = $(`#eway-secure-${targetField}-field`);
    const $formField = $iframe.parent().siblings('label');

    $formField.removeClass('success danger');

    // eslint-disable-next-line fp/no-mutation
    eWayValidFields[targetField] = valueIsValid;

    if (!valueIsValid) {
      $formField.addClass('danger');
    }

    if (valueIsSaved) {
      $formField.addClass('success');
    }

    $submitButton.prop('disabled', !eWayFormIsValid());
    $secureCodeField.attr('value', secureFieldCode);
  };

  const publicApiKey = $actualForm.data('api-key');

  if (!publicApiKey) {
    throw new Error('API key not found');
  }

  const removeExistingIFrames = () => $actualForm.find('iframe').remove();

  const getEwayFieldId = (field: string): string => {
    const fieldId = $actualForm.find(`div[data-eway-field="${field}"]`).attr('id');

    if (!fieldId) {
      throw Error(`Could not find Eway field id for field ${field}`);
    }

    return fieldId;
  };

  const nameFieldConfig: eWaySecureFieldConfig = {
    publicApiKey,
    fieldDivId: getEwayFieldId('name'),
    fieldType: 'name',
  };

  const cardFieldConfig: eWaySecureFieldConfig = {
    publicApiKey,
    fieldDivId: getEwayFieldId('card'),
    fieldType: 'card',
  };

  const expiryFieldConfig: eWaySecureFieldConfig = {
    publicApiKey,
    fieldDivId: getEwayFieldId('expiry'),
    fieldType: 'expirytext',
  };

  const cvnFieldConfig: eWaySecureFieldConfig = {
    publicApiKey,
    fieldDivId: getEwayFieldId('cvn'),
    fieldType: 'cvn',
  };

  const setUpSecureFields = () => {
    removeExistingIFrames(); // Replace the fields every time so we get a new, valid token
    eWAY.setupSecureField(nameFieldConfig, eWaySecureFieldCallback);
    eWAY.setupSecureField(cardFieldConfig, eWaySecureFieldCallback);
    eWAY.setupSecureField(expiryFieldConfig, eWaySecureFieldCallback);
    eWAY.setupSecureField(cvnFieldConfig, eWaySecureFieldCallback);
  };

  if (!isEventHandlerAttached) {
    $(document).on('show-eway-form', () => {
      $submitButton.prop('disabled', !eWayFormIsValid());
    });

    $form.on('submit', async (submitEvent) => {
      submitEvent.preventDefault();

      return new Form($form)
        .submit()
        .then(({ success }) => {
          if (!success) setUpSecureFields();
        })
        .catch(() => setUpSecureFields());
    });
  }

  setUpSecureFields();
};

$(document).on('init-eway-form', () => {
  initEwayForm(true);
});

$(document).on('mfpOpen', () => {
  const container = $.magnificPopup.instance.contentContainer;
  const $modal = $(container);

  const errorModal = $modal.find('div[data-eway-error]');
  initEwayForm(errorModal.length !== 0);
});

$(() => {
  initEwayForm(false);
});
