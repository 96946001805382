import { FieldError } from '@shared/FinalForm/types';
import { UpdateStageStatusFieldName } from './enums';
import { UpdateStageStatusFieldErrors, UpdateAwardedStatusFormValues } from './types';

export enum UpdateAwardedStatusFormFieldError {
  AwardedAtEmpty = 'You must specify the date the project was awarded to you.',
  IncorrectProjectStartCompletionDate = 'Construction cannot end before it has begun.',
}

export const validateUpdateAwardedStatusFormFields = (
  values: UpdateAwardedStatusFormValues,
): UpdateStageStatusFieldErrors => {
  const errors = new Map<UpdateStageStatusFieldName, FieldError[] | null>();

  if (!values[UpdateStageStatusFieldName.AwardedAt]) {
    errors.set(UpdateStageStatusFieldName.AwardedAt, [
      UpdateAwardedStatusFormFieldError.AwardedAtEmpty,
    ]);
  }

  const projectStart = values[UpdateStageStatusFieldName.ProjectStart];
  const projectCompletion = values[UpdateStageStatusFieldName.ProjectCompletion];

  if (projectStart && projectCompletion && projectStart > projectCompletion) {
    errors.set(UpdateStageStatusFieldName.ProjectCompletion, [
      UpdateAwardedStatusFormFieldError.IncorrectProjectStartCompletionDate,
    ]);
  }

  return Array.from(errors.entries()).reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
};
