import { gql } from '@apollo/client';

export const CREATE_SSO_SECRET_KEY = gql`
  mutation CreateSsoSecretKey($input: SsoSecretKeyInput!) {
    createSsoSecretKey(input: $input) {
      ... on EnterpriseSsoSecret {
        id
        secretRedacted
        expiresAt
      }
      ... on Errors {
        errors {
          field
          message
        }
      }
    }
  }
`;
