import $ from 'jquery';

const E1DataTables = {
  common: {
    columns: {
      project_id: {
        name: 'project_id',
        data: 'id',
        orderable: true,
        searchable: false,
        width: 35,
        render: (data, type, row) => data || row.project_id,
      },
      project_category: {
        name: 'category',
        data: 'category',
        orderable: true,
        searchable: false,
        width: 120,
        render: (data) => (data ? window.global.stageCategories[data] || '-' : '-'),
      },
    },
  },
};
Object.freeze(E1DataTables);

$(() => {
  const currentFilters = {};
  let currentCols = {};
  $.fn.dataTable.ext.search.push((settings, data) => {
    const getIndexByColName = (colName, dtCols) => {
      let colIndex = -1;
      dtCols.forEach((col, index) => {
        if (col.name === colName) {
          colIndex = index;
        }
      });
      return colIndex;
    };
    // Return false if row is to be excluded from table
    let rowIncluded = true;
    const tableId = settings.oInstance[0].id;

    if (!currentFilters[tableId] || currentCols !== settings.aoColumns) {
      currentFilters[tableId] = {};
      currentCols = settings.aoColumns;
      const $instance = settings.oInstance;
      // Finding the container is slow - cache the selects
      const $container = $instance.closest('div.table-container');

      currentFilters[tableId].distance = {
        select: $container.find('select.project-distance-filter').first(),
        index: getIndexByColName('distance', currentCols),
      };

      currentFilters[tableId].interest = {
        select: $container.find('select.project-interest-filter').first(),
        index: getIndexByColName('interest_level', currentCols),
      };
    }

    const $distFilter = currentFilters[tableId].distance.select;
    const $interestFilter = currentFilters[tableId].interest.select;

    if ($distFilter.length !== 0) {
      const distVal = parseInt($distFilter.val(), 10) || null;
      const distance = parseInt(data[currentFilters[tableId].distance.index], 10);
      rowIncluded = rowIncluded && (distVal === null || distance <= distVal / 1000);
    }

    if (rowIncluded && $interestFilter.length !== 0) {
      const $interestLevels = $interestFilter.find('option');
      const $selectedInterestLevels = $interestLevels.filter(':selected');

      // If all or no interest filters are selected we don't want to apply any interest filtering
      if (
        $selectedInterestLevels.length !== $interestLevels.length &&
        $selectedInterestLevels.length !== 0
      ) {
        const interestValues = $.map($interestFilter.find('option:selected'), (opt) =>
          parseInt($(opt).val(), 10),
        );
        const interest = parseInt(data[currentFilters[tableId].interest.index], 10);
        rowIncluded = rowIncluded && $.inArray(interest, interestValues) >= 0;
      }
    }
    return rowIncluded;
  });
});

export default E1DataTables;
