import ScrollSpy from 'react-scrollspy';
import Routing from 'routing';
import { Button, ButtonSize, ButtonVariant } from '@estimateone/frontend-components';
import ProjectHeaderDetails from './ProjectHeaderDetails';
import Icon from '@shared/Icon';
import ProjectWatchlistSelector from '@shared/ProjectWatchlistSelector';
import { getInterestLevelOrDefault } from '@shared/ProjectWatchlistSelector/util';
import TruncatedText from '@shared/TruncatedText';
import { WatchlistSource } from '@ascension/enums';
import { Project, User } from '../types';
import styles from './styles.scss';

type ContentProps = {
  project: Project;
  user: User;
};

const HeaderContent = ({ project, user }: ContentProps) => {
  const { id, name, address, watchlistEntry } = project;
  const watchListStatus = getInterestLevelOrDefault(watchlistEntry?.status);

  return (
    <>
      <div id="project-slider-header" className={styles.projectSliderHeader}>
        <div className={`d-flex justify-content-between align-items-center ${styles.hideOnScroll}`}>
          <span className={styles.projectId}>ID #{id}</span>
          <Button
            link={Routing.generate('app_project_print', { id })}
            target="_blank"
            rel="noopener noreferrer"
            variant={ButtonVariant.Transparent}
            size={ButtonSize.Small}
            className={styles.printBtn}
          >
            <Icon icon="print" extraClass="icon-left" aria-hidden="true" />
            Print
          </Button>
        </div>
        <div className="d-flex justify-content-between align-items-end">
          <div>
            <h2>
              <TruncatedText text={name} limit={65} />
            </h2>
            <span>{address?.fullAddress}</span>
            {address && (
              <a
                href={`https://www.google.com/maps/place/${address.fullAddress}`}
                target="_blank"
                rel="noopener noreferrer"
                className={`link ${styles.viewMap}`}
              >
                View map
              </a>
            )}
          </div>
          <div className={styles.watchlistSelector}>
            <ProjectWatchlistSelector
              status={watchListStatus}
              source={WatchlistSource.PROJECT_SLIDER}
              projectId={id}
            />
          </div>
        </div>
      </div>
      <hr />
      <div className={`${styles.projectDetails} ${styles.hideOnScroll}`}>
        <ProjectHeaderDetails project={project} user={user} />
      </div>
    </>
  );
};

export const ProjectSliderHeaderForRoadblock = ({ project, user }: ContentProps) => (
  <div className={styles.sliderHeader}>
    <HeaderContent user={user} project={project} />
  </div>
);

type ProjectSliderHeaderProps = ContentProps & { scrollSpySections: { tabSuffix: string }[] };

const ProjectSliderHeader = ({ project, user, scrollSpySections }: ProjectSliderHeaderProps) => (
  <ScrollSpy
    rootEl="#scrollSpyRoot"
    items={scrollSpySections.map(({ tabSuffix }) => `project-${tabSuffix}`)}
    scrolledPastClassName={`${styles.sliderHeader} ${styles.compacted}`}
    currentClassName={styles.sliderHeader}
  >
    <ProjectSliderHeaderForRoadblock project={project} user={user} />
  </ScrollSpy>
);

export default ProjectSliderHeader;
