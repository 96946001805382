import { useCallback } from 'react';
import { FormSpy } from 'react-final-form';
import { FormState } from 'final-form';
import { useSetAtom } from 'jotai/index';
import { selectedFormFiltersCountAtom } from '@builder/features/SubbieNetwork/atoms';
import {
  allSubbieNetworkFilterFormFields,
  SubbieNetworkFilterFormValues,
  SubbieNetworkFilterFormFields,
} from './types';

export const SubbieNetworkFilterFormSpy = () => {
  const setSelectedFormFiltersCount = useSetAtom(selectedFormFiltersCountAtom);

  const handleFormSpyUpdate = useCallback(
    ({
      values,
    }: FormState<SubbieNetworkFilterFormValues, Partial<SubbieNetworkFilterFormValues>>): void => {
      const selected = allSubbieNetworkFilterFormFields.filter((field) => {
        // These fields are counted with their corresponding partner fields, so we don't want to count them twice
        if (
          field === SubbieNetworkFilterFormFields.SearchArea ||
          field === SubbieNetworkFilterFormFields.Distance ||
          field === SubbieNetworkFilterFormFields.RelationshipTypeNotInAddressBook ||
          field === SubbieNetworkFilterFormFields.ContractSizeMin
        ) {
          return false;
        }
        // Count contract size based on either min or max being set
        if (field === SubbieNetworkFilterFormFields.ContractSizeMax) {
          return (
            Boolean(values[field]) || Boolean(values[SubbieNetworkFilterFormFields.ContractSizeMin])
          );
        }
        // Count connection type based on either type being set
        if (field === SubbieNetworkFilterFormFields.RelationshipTypeAddressBook) {
          return (
            values[field] || values[SubbieNetworkFilterFormFields.RelationshipTypeNotInAddressBook]
          );
        }
        // Count location if both location & distance are set (must be both in this case)
        if (field === SubbieNetworkFilterFormFields.Location) {
          return values[field] && values[SubbieNetworkFilterFormFields.Distance];
        }
        return Boolean(values[field]);
      });
      setSelectedFormFiltersCount(selected.length);
    },
    [setSelectedFormFiltersCount],
  );

  return (
    <FormSpy<SubbieNetworkFilterFormValues>
      subscription={{ values: true }}
      onChange={handleFormSpyUpdate}
    />
  );
};
