import { useForm } from 'react-final-form';
import { Button, ButtonVariant } from '@estimateone/frontend-components';
import GlobalErrorList from '../../../../../shared/FinalForm/GlobalErrorList';
import { UpdateAwardedStatusFormValues, UpdateOtherStatusFormValues } from '../../types';
import styles from '../../styles.scss';

type ModalFormButtonsProps = {
  statusName: string;
  onCancel: () => void;
};

const ModalFormErrorsAndButtons = ({ statusName, onCancel }: ModalFormButtonsProps) => {
  const { getState } = useForm<UpdateAwardedStatusFormValues | UpdateOtherStatusFormValues>();
  const { submitting, submitError } = getState();

  return (
    <>
      {submitError && (
        <div className={styles.errorMessageContainer}>
          <GlobalErrorList errors={submitError} />
        </div>
      )}
      <div className={styles.buttonContainer}>
        <Button type="submit" isDisabled={submitting}>
          Mark project as {statusName.toLowerCase()}
        </Button>
        <Button type="button" variant={ButtonVariant.Grey} onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </>
  );
};

export default ModalFormErrorsAndButtons;
