import {
  ButtonSize,
  ButtonVariant,
  HelpBanner,
  HelpBannerContent,
  HelpBannerToggle,
  usePersistentIsHidden,
} from '@estimateone/frontend-components';
import styles from './NetworkHelpBanner.module.scss';

type NetworkHelpBannerProps = {
  userId: number;
};

export const NetworkHelpBanner = ({ userId }: NetworkHelpBannerProps) => {
  const { isHidden, toggleHidden } = usePersistentIsHidden(
    `network-help-banner-${userId.toString(10)}`,
  );

  return (
    <HelpBanner isHidden={isHidden} toggleHidden={toggleHidden} className={styles.helpBanner}>
      <HelpBannerContent>
        <h3 className={styles.helpBannerTitle}>Get started with the Network</h3>
        <p>Find the best-fit companies for your project:</p>
        <ul>
          <li>
            Begin by searching for a <span className={styles.highlightedText}>Trade</span> to see
            results
          </li>
          <li>
            Then define a <span className={styles.highlightedText}>Search Area</span> by selecting a{' '}
            <span className={styles.highlightedText}>Project Location</span> and the maximum{' '}
            <span className={styles.highlightedText}>Distance</span> away from the project
          </li>
          <li>Add more filters to narrow your search.</li>
        </ul>
        <div className={styles.helpBannerFooter}>
          <HelpBannerToggle variant={ButtonVariant.Grey} size={ButtonSize.Small}>
            Ok, got it!
          </HelpBannerToggle>
          {/* TODO: add link when we have a url to link to.
           * <a href="https://app.estimateone.com">Learn more</a>
           */}
        </div>
      </HelpBannerContent>
    </HelpBanner>
  );
};
