export enum FlowEvent {
  DISCO_ONBOARDING_SSC_VIEW_SUPPLIER = 'disco:onboarding:ssc-view-suppliers',
  DISCO_ONBOARDING_PROJECT_SLIDER = 'disco:onboarding:project-slider',
  DISCOVER_RFQ_STATUS_UPDATE = 'discover:rfq-quoting-status-update',
  CLARIFY_INVITES_WIZARD_SEND_INVITES_CLICK = 'clarify:invites-wizard:send-invites-click',
  CLARIFY_INVITE_SINGLE_TENDER_SEND_INVITE_CLICK = 'clarify:invite-single-tender:send-invite-click',
  CLARIFY_INVITE_SINGLE_PROCUREMENT_SEND_INVITE_CLICK = 'clarify:invite-single-procurement:send-invite-click',
  CLARIFY_DOCMATRIX_POST_NOTIFY = 'clarify:docmatrix:post-notify',
  PROFILES_EDIT_PROFILE_FIELD = 'profiles:edit-profile-field',
  DISCOVER_PROJECT_SLIDER_DOWNLOAD_DOCS_CLICK = 'discover:project-slider:download-docs-click',
}
