import $ from 'jquery';

$(() => {
  const $body = $('body');

  $body.on('change', '.colleague-send-trigger', function () {
    const $groupSelector = $(this).closest('tr').find('.colleague-group');

    if (this.checked) {
      $groupSelector.show();
    } else {
      $groupSelector.hide();
    }
  });
});
