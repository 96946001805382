export enum ButtonName {
  MARK_PROJECT_AWARDED = 'mark_project_awarded',
  PROCUREMENT_TENDER_RESPONSES_TABLE_VIEW_ADDRESS = 'proc_tender_responses_table_view_addrbk',
  PROCUREMENT_TENDER_RESPONSES_TABLE_VIEW_QUOTE = 'proc_tender_responses_table_view_quote',
  PROCUREMENT_RESPONSES_TABLE_MENU_ADD_QUOTE = 'proc_responses_table_menu_add_quote',
  PROCUREMENT_RESPONSES_TABLE_MENU_VIEW_QUOTE = 'proc_responses_table_menu_view_quote',
  PROCUREMENT_TENDER_RESPONSES_TABLE_VIEW_NOTES = 'proc_tender_responses_table_view_notes',
  PROCUREMENT_RESPONSES_TABLE_ADD_QUOTE = 'proc_responses_table_add_quote',
  PROCUREMENT_LETTING_SCHEDULE_TABLE_DELETE_DRAFT_PACKAGE = 'delete_draft_package',
  RESEND_DOWNLOAD_LINK = 'resend_document_link',
  COPY_DOWNLOAD_LINK = 'copy_document_link',
  CONSULTANT_DETAILS_CTA = 'consultant_details_cta',
  CONSULTANT_DETAILS_UPGRADE = 'consultant_details_upgrade',
  CONSULTANT_DETAILS_GET_ADDON = 'consultant_details_get_addon',
  PROCUREMENT_LETTING_SCHEDULE_EXPORT = 'export_letting_schedule',
  PROCUREMENT_PROJECTS_READY_FOR_HANDOVER_START = 'start_procurement',
  PROFESSIONAL_UPGRADE = 'professional_upgrade',
  PROFESSIONAL_LEARN_MORE = 'professional_learn_more',
  REMIND_NO_RESPONSE = 'remind_no_response',
  SHOW_HIDE_BUSINESS_IDENTITY_DETAILS = 'show_hide_business_identity_details',
  BUSINESS_IDENTITY_SOURCE_RECORD_LINK = 'business_identity_source_record_link',
}
