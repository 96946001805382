import { differenceInCalendarDays } from 'date-fns';
import { MilestoneState } from './milestoneState';

const IMMINENT_DAYS = 7;

const isImminent = (date: Date) => {
  const diff = differenceInCalendarDays(date, new Date());

  return diff >= 0 && diff <= IMMINENT_DAYS;
};

const isInThePast = (date: Date) => {
  const diff = differenceInCalendarDays(date, new Date());

  return diff < 0;
};

export const calculateStatusForSendInvitesDate = (
  date: Date | null,
  numberOfInvitesSent: number,
  isPackageAwarded: boolean,
): MilestoneState => {
  if (isPackageAwarded) {
    return MilestoneState.AWARDED;
  }

  if (!date) {
    return MilestoneState.PENDING;
  }

  if (isImminent(date)) {
    return MilestoneState.IMMINENT;
  }

  if (isInThePast(date)) {
    if (numberOfInvitesSent > 0) {
      return MilestoneState.COMPLETE;
    }
    return MilestoneState.OVERDUE;
  }

  return MilestoneState.PENDING;
};

export const calculateStatusForQuotesDueDate = (
  date: Date | null,
  numberOfInvitesQuoted: number,
  isPackageAwarded: boolean,
): MilestoneState => {
  if (isPackageAwarded) {
    return MilestoneState.AWARDED;
  }

  if (!date) {
    return MilestoneState.PENDING;
  }

  if (isImminent(date)) {
    return MilestoneState.IMMINENT;
  }

  if (isInThePast(date)) {
    if (numberOfInvitesQuoted > 0) {
      return MilestoneState.COMPLETE;
    }
    return MilestoneState.OVERDUE;
  }

  return MilestoneState.PENDING;
};

export const calculateStatusForLetByDate = (
  date: Date | null,
  isPackageAwarded: boolean,
): MilestoneState => {
  if (isPackageAwarded) {
    return MilestoneState.AWARDED;
  }

  if (!date) {
    return MilestoneState.PENDING;
  }

  if (isImminent(date)) {
    return MilestoneState.IMMINENT;
  }

  if (isInThePast(date)) {
    return MilestoneState.OVERDUE;
  }

  return MilestoneState.PENDING;
};

export const calculateStatusForStartOnSiteDate = (
  date: Date | null,
  isPackageAwarded: boolean,
): MilestoneState => {
  if (!date) {
    return MilestoneState.PENDING;
  }

  if (isImminent(date)) {
    return MilestoneState.IMMINENT;
  }

  if (isInThePast(date)) {
    if (isPackageAwarded) {
      return MilestoneState.AWARDED;
    }
    return MilestoneState.OVERDUE;
  }

  return MilestoneState.PENDING;
};
