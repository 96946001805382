import $ from 'jquery';
import Routing from 'routing';
import { escape } from 'lodash';
import moment from 'moment-timezone';
import E1Request from '../classes/E1Request';
import AjaxList from '../classes/AjaxList';
import Form from '../classes/Form';
import GenericList from '../classes/GenericList';
import ServerDataList from '../classes/ServerDataList';
import SuperSlider from '../classes/SuperSlider';
import downloadFromPost from '../utils/download_from_post';
import quillify from '../utils/quillify';
import UserCorrespondenceDraftModule from './classes/UserCorrespondenceDraftModule';
import { activateLoadingButton, deactivateLoadingButton } from './loading_button';
import { pad } from './string_util';
import { getUserTimezone } from './timezone';
import NewCorrespondenceAttachmentUploadModule from './user_correspondence_attachment_upload';
import { UploadParentType } from '../../enums';

function UserCorrespondenceTableActions($target, correspondenceDataTable) {
  this.correspondenceDataTable = correspondenceDataTable;
  this.$target = $target;
  this.$controlPanel = this.$target.find('.table-control-panel-container');
  this.stageId = this.$target.attr('data-stage');
  this.init();
}

UserCorrespondenceTableActions.prototype = {
  init() {
    const self = this;
    if (self.$controlPanel.length > 0) {
      self.$controlPanel.prependTo('.bottom-bar');
      self.$controlPanel.removeClass('hide');
      self.$controlPanel.on('click', '.export-correspondence-trigger', () => {
        const { stageId } = self;
        const selected = self.getSelectedIds();
        const path = Routing.generate('app_usercorrespondence_export', { stageId });
        const data = {
          corrIds: selected,
        };

        downloadFromPost(path, data);
      });

      self.$controlPanel.on('click', '.correspondence-modify-status-trigger', function () {
        const { stageId } = self;
        const selected = self.getSelectedIds();
        const type = $(this).attr('data-status');
        const path = Routing.generate('app_usercorrespondence_updatestatus', { stageId });
        const data = {
          corrIds: selected,
          type,
        };
        const request = new E1Request(path, 'POST', data);
        request.extraCallback = function () {
          self.correspondenceDataTable.table.ajax.reload(() => {
            self.updateCheckBoxes(false);
          }, false);
        };
        request.submit();
      });
    }

    self.$target.on('data-selected-updated', () => {
      self.updateSelectedTotals();
    });

    self.$target.on('change', '.select-checkbox', function () {
      const checked = $(this).is(':checked');
      const $row = $(this).closest('tr');
      const row = $row.get(0);
      const rowData = self.correspondenceDataTable.table.row(row).data();

      rowData.selected = checked;

      self.correspondenceDataTable.table.row(row).data(rowData);
      self.correspondenceDataTable.$target.trigger('data-selected-updated');
    });

    self.$target.on('change', '.select-all-rows', function () {
      const checked = $(this).is(':checked');
      self.updateCheckBoxes(checked);
    });

    self.updateSelectedTotals();
  },
  getSelectedIds() {
    const dt = this.$target;
    const selected = [];
    if (dt != null) {
      $.each(dt.find('input[type=checkbox]:checked'), function () {
        if ($(this).data('id')) {
          selected.push($(this).data('id'));
        }
      });
    }
    return selected;
  },
  updateSelectedTotals() {
    const self = this;
    const selected = self.getSelectedIds();
    const selectedCount = selected.length;
    const totalCount = self.correspondenceDataTable.data.length;

    self.$target
      .find('.select-all-rows')
      .prop('checked', selectedCount >= totalCount && selectedCount > 0);

    $('.select_count', self.$controlPanel).each(function () {
      if (!selectedCount) {
        self.$controlPanel.addClass('hide');
      }

      const text = selectedCount >= totalCount ? $(this).attr('data-all-text') : selectedCount;

      $(this).text(text);

      if (selectedCount > 0) {
        self.$controlPanel.removeClass('hide');
      }
    });
  },
  updateCheckBoxes(checked) {
    const self = this;
    const $checkboxes = self.correspondenceDataTable.$target.find(
      '.select-checkbox:not([disabled])',
    );
    $checkboxes.prop('checked', checked);
    self.correspondenceDataTable.$target.trigger('data-selected-updated');
  },
};

function UserCorrespondenceTable($target) {
  const self = this;
  this.$target = $target;
  this.$container = $target.closest('.user-correspondence-container');
  this.$customTableSearch = self.$container.find('.custom-table-search');
  this.$typeFilter = self.$container.find('.correspondence-type-filter');
  this.$statusFilter = self.$container.find('.correspondence-status-filter');
  this.$unreadFilter = self.$container.find('.correspondence-unread-filter');
  this.$controlPanel = self.$container.find('.table-control-panel-container');
  this.stageId = self.$container.attr('data-stage');
  this.legacy = Boolean(self.$container.attr('data-legacy'));
  this.badge = 'correspondence-register-badge';
  this.dataTable = null;
  self.init();
}

UserCorrespondenceTable.prototype = {
  init() {
    const self = this;
    const { sessionStorage } = window;

    self.dataTable = self.legacy ? self.createLegacyTable() : self.createTable();

    const filter = () => {
      self.filterTable();
    };

    if (self.$customTableSearch.length > 0) {
      self.$customTableSearch.on('change', () => {
        self.persistFilters(sessionStorage);
      });
    }
    if (sessionStorage) {
      const filters = self.retrievePersistedFilters(sessionStorage);
      if (filters && filters.searchTerm) {
        self.$customTableSearch.val(filters.searchTerm);
      }
      if (filters && filters.type) {
        self.$typeFilter.val(filters.type).trigger('change');
      }
      if (filters && filters.status) {
        self.$statusFilter.val(filters.status).trigger('change');
      }
      if (filters && filters.unread === true) {
        self.$unreadFilter.prop('checked', true);
      }
    }

    if (self.$typeFilter.length) {
      self.$typeFilter.on('change', filter);
    }

    if (self.$statusFilter.length) {
      self.$statusFilter.on('change', filter);
    }

    if (self.$unreadFilter.length) {
      self.$unreadFilter.on('change', filter);
    }

    if (self.$controlPanel.length) {
      new UserCorrespondenceTableActions(self.$container, self.dataTable);
    }
  },
  persistFilters(sessionStorage) {
    const self = this;
    if (sessionStorage) {
      let currentPageNum = 0;
      const $pageNum = $('.user-correspondence-container ul.pagination li.active a');
      if ($pageNum.length && parseInt($pageNum.text(), 10) > 0) {
        currentPageNum = parseInt($pageNum.text(), 10);
      }

      const correspondenceFilters = JSON.stringify({
        searchTerm: self.$customTableSearch.val(),
        status: self.$statusFilter.val(),
        type: self.$typeFilter.val(),
        unread: self.$unreadFilter.is(':checked'),
        pageNum: currentPageNum,
      });
      sessionStorage.setItem('con_corr_filters', correspondenceFilters);
    }
  },
  retrievePersistedFilters(sessionStorage) {
    if (sessionStorage) {
      const correspondenceFilters = sessionStorage.getItem('con_corr_filters');
      if (correspondenceFilters) {
        return JSON.parse(correspondenceFilters);
      }
    }
    return false;
  },
  filterTable() {
    const self = this;
    const { sessionStorage } = window;
    self.persistFilters(sessionStorage);

    self.dataTable.fetchUrl = Routing.generate(self.route, {
      stageId: self.stageId,
      status: self.$statusFilter.val(),
      type: self.$typeFilter.val(),
      unread: self.$unreadFilter.is(':checked'),
    });

    self.dataTable.updateTable(true);
  },
  createLegacyTable() {
    const self = this;
    const route = Routing.generate('app_usercorrespondenceitem_fetch', {
      stageId: self.stageId,
    });

    return new ServerDataList(self.$target, route, (list) => {
      const $placeholderRow = list.$target.find('tr.data-table-placeholder').first();
      list.table = list.$target
        .DataTable({
          pageLength: 25,
          processing: true,
          serverSide: true,
          ajax(data, cb, settings) {
            list.serverRequest(data, cb, settings);
          },
          order: [[0, 'desc']],
          columns: [
            {
              data: 'correspondence_item_id',
              name: 'user_correspondence_item.id',
              width: 43,
              render: (itemId) => pad(itemId, 8),
            },
            {
              data: 'correspondence_type',
              name: 'user_correspondence.type',
              render(data) {
                const typeDescription =
                  window.global.userCorrespondenceTypes &&
                  window.global.userCorrespondenceTypes[data]
                    ? window.global.userCorrespondenceTypes[data]
                    : '';
                const $type = $('<span>').text(typeDescription);

                return $type.prop('outerHTML');
              },
            },
            {
              data: 'correspondence_title',
              name: 'user_correspondence.title',
              render: $.fn.dataTable.render.text(),
            },
            {
              data: 'createdBy.lastName',
              name: 'createdBy.lastName',
              render(data, display, obj) {
                const firstName = obj.creator_first_name || '';
                const lastName = obj.creator_last_name || '';
                return escape(`${firstName} ${lastName}`);
              },
            },
            {
              data: 'user_lastname',
              name: 'user.lastName',
              render(data, display, obj) {
                const firstName = obj.user_first_name || '';
                const lastName = obj.user_last_name || '';
                return escape(`${firstName} ${lastName}`);
              },
            },
            {
              data: 'created_at',
              name: 'user_correspondence_item.createdAt',
              render(data, display, obj, details) {
                const template = $placeholderRow.find('td').get(details.col);
                const date = moment.utc(obj.created_at.date);
                date.tz(getUserTimezone());
                $(template)
                  .find('.date')
                  .text(date.format($(template).find('.date').attr('data-format')));
                return $(template).html();
              },
            },
            {
              data: null,
              orderable: false,
              render(data, row, obj, details) {
                const $template = $($placeholderRow.find('td').get(details.col)).clone();
                const $link = $template.find('a');
                $link
                  .addClass('view-correspondence-trigger')
                  .attr('data-user-correspondence-id', obj.correspondence_item_id)
                  .attr('data-stage-id', self.stageId);
                return $link.prop('outerHTML');
              },
            },
          ],
        })
        .on('init.dt', () => {
          list.$container.addClass('has-loaded');
          self.updateCount();
        })
        .on('click', '.view-correspondence-trigger', function () {
          const slider = new SuperSlider();
          const corrId = $(this).attr('data-user-correspondence-id');
          slider.url = Routing.generate('app_usercorrespondenceitem_viewslider', {
            id: corrId,
          });
          slider.submit();
        });
    });
  },
  createTable() {
    const self = this;
    const { sessionStorage } = window;

    let params = {
      stageId: self.stageId,
    };
    const searchFilter = {};
    const filters = self.retrievePersistedFilters(sessionStorage);
    if (filters) {
      params = Object.assign(params, filters);
    }
    if (filters && filters.searchTerm) {
      searchFilter.search = filters.searchTerm;
    }
    self.route = 'app_usercorrespondence_fetch';
    const route = Routing.generate(self.route, params);

    const pageLength = 20;
    const displayStart = filters.pageNum ? (filters.pageNum - 1) * pageLength : 0;

    return new ServerDataList(self.$target, route, (list) => {
      const $placeholderRow = list.$target.find('tr.data-table-placeholder').first();
      list.table = list.$target
        .DataTable({
          displayStart,
          pageLength,
          processing: true,
          serverSide: true,
          search: searchFilter,
          ajax(data, cb, settings) {
            list.serverRequest(data, cb, settings);
          },
          order: [[4, 'desc']],
          columns: [
            {
              data: 'selected',
              orderable: false,
              searchable: false,
              defaultContent: '',
              class: 'checkboxCol',
              render(selected, row, corr, info) {
                const template = $placeholderRow.find('td').get(info.col);
                const $template = $(template);
                const showCheckbox = $template.attr('data-requires-checkbox');
                if (parseInt(showCheckbox, 10) === 1) {
                  const $checkbox = $("<input type='checkbox'>")
                    .addClass('select-checkbox')
                    .attr('data-id', corr.id);

                  if (corr.selected) {
                    $checkbox.attr('checked', 'checked');
                  }
                  list.data[info.row] = corr;
                  const $ctn = $('<div>').append($checkbox);
                  return $ctn.html();
                }
                return '';
              },
            },
            {
              data: 'reference',
              name: 'user_correspondence.reference',
              render(data, display, { id }) {
                if (data === null) {
                  return '-';
                }

                const $link = $('<a>');
                $link
                  .addClass('link view-user-correspondence-trigger')
                  .text(data)
                  .attr(
                    'href',
                    Routing.generate('app_usercorrespondence_details', {
                      stageId: self.stageId,
                      id,
                    }),
                  );

                return $link.prop('outerHTML');
              },
            },
            {
              data: 'view',
              name: 'user_correspondence.view',
              class: 'unread-column',
              orderable: false,
              render(data, display, { unread, id }, { col }) {
                const template = $($placeholderRow.find('td').get(col)).clone();
                if (unread) {
                  $(template).find('a.unread-indicator').remove();
                } else {
                  $(template)
                    .find('a.unread-indicator')
                    .addClass('view-user-correspondence-trigger pointer-cursor')
                    .attr(
                      'href',
                      Routing.generate('app_usercorrespondence_details', {
                        stageId: self.stageId,
                        id,
                      }),
                    );
                }

                return $(template).html();
              },
            },
            {
              data: 'title',
              name: 'user_correspondence.title',
              render(title, display, { has_attachments: hasAttachments }) {
                const $type = $('<span>').text(title);

                if (hasAttachments) {
                  const $attachment = $('<i>').addClass('icon icon-attach');
                  $type.append(document.createTextNode('\u00A0'), $attachment);
                }

                return $type.prop('outerHTML');
              },
            },
            {
              data: 'createdAt',
              name: 'user_correspondence.createdAt',
              render(createdAt, display, corr, { col }) {
                const template = $placeholderRow.find('td').get(col);
                const date = moment.utc(createdAt.date);
                date.tz(getUserTimezone());
                $(template)
                  .find('.date')
                  .addClass('timestamp')
                  .text(date.format($(template).find('.date').attr('data-format')));
                return $(template).html();
              },
            },
            {
              name: 'user_correspondence.issuer',
              data: null,
              orderable: false,
              render(data, display, { createdBy }) {
                const creator = createdBy;
                const { account } = creator;
                const firstName = creator.firstName || '';
                const lastName = creator.lastName || '';

                const fullName = `${firstName} ${lastName}`;
                const $companySpan = $('<div />').addClass('company-name').text(account.name);
                const name = $('<p />').append(escape(fullName.trim())).append($companySpan);

                return name.html();
              },
            },
            {
              data: 'items',
              orderable: false,
              render(items, display, { id }) {
                if (items.length > 1) {
                  const $container = $('<div />');
                  const text = `${items.length} people`;
                  const $link = $('<a />')
                    .addClass('link user-correspondence-recipient-trigger')
                    .attr('role', 'button')
                    .attr('data-user-correspondence-id', id)
                    .attr('data-stage-id', self.stageId)
                    .text(text);
                  return $container.append($link).html();
                }
                if (items.length === 1) {
                  const item = items[0];
                  const { user } = item;

                  const { account } = user;

                  const firstName = user.firstName || '';
                  const lastName = user.lastName || '';
                  const fullName = `${firstName} ${lastName}`;

                  const $companySpan = $('<div />').addClass('company-name').text(account.name);

                  const $name = $('<p />').append(escape(fullName.trim())).append($companySpan);

                  return $name.html();
                }
                return '-';
              },
            },
            {
              data: 'dueAt',
              name: 'user_correspondence.dueAt',
              render(dueAt, display, corr, { col }) {
                if (dueAt === null) {
                  return '-';
                }
                const template = $placeholderRow.find('td').get(col);
                const date = moment.utc(dueAt.date);

                date.tz(getUserTimezone());
                $(template)
                  .find('.date')
                  .addClass('timestamp')
                  .text(date.format($(template).find('.date').attr('data-format')));
                return $(template).html();
              },
            },
            {
              data: null,
              name: 'user_correspondence.assignee',
              orderable: false,
              render(data, display, { assignee }) {
                if (assignee === null) {
                  return '-';
                }

                const { account } = assignee;
                const firstName = assignee.firstName || '';
                const lastName = assignee.lastName || '';

                const fullName = `${firstName} ${lastName}`;
                const $companySpan = $('<div />').addClass('company-name').text(account.name);
                const name = $('<p />').append(escape(fullName.trim())).append($companySpan);

                return name.html();
              },
            },
            {
              data: 'status',
              name: 'user_correspondence.status',
              render(status) {
                const $span = $('<span>');

                const statusClass =
                  window.global.userCorrespondenceStatus &&
                  window.global.userCorrespondenceStatus[status]
                    ? window.global.userCorrespondenceStatus[status]
                    : '';

                if (statusClass) {
                  $span.addClass(`correspondence-status-tag ${statusClass.toLocaleLowerCase()}`);
                }

                return $span.prop('outerHTML');
              },
            },
          ],
        })
        .on('init.dt', () => {
          list.$container.addClass('has-loaded');
          self.updateCount();
        })
        .on('search.dt', () => {
          $('#project-mail .terms-filter').val(list.table.search());
        })
        .on('draw.dt', () => {
          self.persistFilters(sessionStorage);
        });
    });
  },
  updateCount() {
    const self = this;
    let total = 0;
    if (self.dataTable.table.page) {
      const info = self.dataTable.table.page.info();
      total = info.recordsTotal;
    }

    $(`.${self.badge}`).text(total);
  },
};

export default function EditCorrespondence($target) {
  this.$target = $target;
  this.details = '.static-details';
  this.formContainer = '.form-container';
  this.form = '.edit-user-correspondence-form';
  this.submitButton = '.submit-edit-correspondence-trigger';
  this.editButton = '.edit-correspondence-trigger';
  this.cancelEditButton = '.cancel-edit-correspondence-trigger';
  this.init();
}

EditCorrespondence.prototype = {
  init() {
    const self = this;
    self.$target.on('click', this.editButton, () => {
      self.showForm(true);
    });
    self.$target.on('click', this.cancelEditButton, () => {
      self.showForm(false);
    });

    self.$target.on('click', this.submitButton, (e) => {
      e.preventDefault();
      activateLoadingButton($(e.currentTarget));
      $(e.currentTarget).attr('disabled', 'disabled');

      const form = new Form(self.$target.find(self.form));
      form.extraCallback = (response) => {
        const $updatedView = $(response.updated_view);
        deactivateLoadingButton($(e.currentTarget));
        $(e.currentTarget).attr('disabled', null);
        self.$target.find(self.details).replaceWith($updatedView);
        $(document).trigger('correspondence:update-details', {
          view: response.updated_view_corr,
        });
        self.showForm(false);
      };
      form.submit();
      return false;
    });
  },
  showForm(show) {
    const self = this;
    self.$target.find(self.formContainer).toggleClass('hide', !show);
    self.$target.find(self.submitButton).toggleClass('hide', !show);
    self.$target.find(self.cancelEditButton).toggleClass('hide', !show);

    self.$target.find(self.details).toggleClass('hide', !!show);
    self.$target.find(self.editButton).toggleClass('hide', !!show);
  },
};

$(() => {
  const $body = $('body');

  $body.on('modal-loaded slider-loaded', () => {
    const $userCorrespondenceForm = $('form.usercorrespondence-new-form');
    if ($userCorrespondenceForm.length) {
      const stageId = $userCorrespondenceForm.find('button[type="submit"]').attr('data-stage');
      new UserCorrespondenceDraftModule($userCorrespondenceForm, `new_${stageId}`);

      quillify('.user_correspondence_content.quill-wrapper', {
        useExistingData: true,
      });

      const $ctn = $userCorrespondenceForm.find('.file-uploader');
      const $target = $ctn.find('.uploader');

      if ($target.length) {
        new NewCorrespondenceAttachmentUploadModule(
          $userCorrespondenceForm,
          $target,
          $target.data('key'),
          $target.data('stage'),
          UploadParentType.USER_CORR_ATTACH,
        );
      }

      const setupCorrespondenceDropdown = ($dropdown) => {
        let recipients = [];

        $dropdown.on('select2:selecting', (evt) => {
          recipients = $(evt.currentTarget).val();
        });

        $dropdown.on('select2:select', (evt) => {
          const $dropdownTarget = $(evt.currentTarget);
          const $choice = $(evt.params.data.element);

          if ($choice.hasClass('user-group-option')) {
            const $options = $dropdownTarget.find(
              `option[data-user-group=${$choice.attr('data-group-id')}]`,
            );
            const values = $options.map((i, element) => $(element).attr('value'));

            $choice.attr('aria-selected', true);

            recipients = recipients.concat(...values);
            $dropdownTarget.val(recipients).trigger('change');
          }
        });
      };

      setupCorrespondenceDropdown($('#user_correspondence_itemUsers'));
      setupCorrespondenceDropdown($('#user_correspondence_ccUsers'));

      const $responseToggle = $userCorrespondenceForm.find(
        'input[name="user_correspondence[response]"]',
      );
      $responseToggle.on('change', (e) => {
        $userCorrespondenceForm
          .find('.response-controls')
          .toggleClass('hide', $(e.currentTarget).val() === '0');
      });
    }
  });

  const $correspondenceCtn = $('.user-correspondence-container');
  if ($correspondenceCtn.length > 0) {
    let table = null;

    $('table.user-correspondence-table').each(function () {
      const $table = $(this);
      table = new UserCorrespondenceTable($table);
    });

    $body.on('form-submitted-success', 'form.usercorrespondence-new-form', (e) => {
      $(e.currentTarget).trigger('destroy_form_cache');

      if (table) {
        table.dataTable.updateTable(true);
      }
    });
  }

  const $correspondenceWidget = $('.user-correspondence-widget');
  if ($correspondenceWidget.length) {
    const recentStageId = $correspondenceWidget.attr('data-stage');
    const limit = $correspondenceWidget.attr('data-limit');
    const recentMethod = Routing.generate('app_usercorrespondence_recent', {
      stageId: recentStageId,
      limit,
    });
    const $recentTarget = $correspondenceWidget.find('.user-correspondence-widget-table');

    const correspondenceDataTable = new GenericList(
      $recentTarget,
      (list) => {
        const $placeholderRow = list.$target.find('tr.data-table-placeholder').first();
        list.table = list.$target.DataTable({
          paging: false,
          data: list.data,
          info: false,
          order: [[0, 'desc']],
          columns: [
            {
              data: 'reference',
              name: 'user_correspondence.reference',
              width: 60,
              render(data, display, { id }) {
                if (data === null) {
                  return '-';
                }

                const $link = $('<a>');
                const route = Routing.generate('app_usercorrespondence_details', {
                  stageId: recentStageId,
                  id,
                });

                $link
                  .addClass('link view-user-correspondence-trigger')
                  .text(data)
                  .attr('href', route);

                return $link.prop('outerHTML');
              },
            },
            {
              data: 'title',
              name: 'user_correspondence.title',
              width: 300,
              render: $.fn.dataTable.render.text(),
            },
            {
              data: 'createdAt',
              name: 'user_correspondence.createdAt',
              width: 70,
              render(createdAt, display, corr, details) {
                const template = $placeholderRow.find('td').get(details.col);
                const date = moment.utc(createdAt.date);
                date.tz(getUserTimezone());
                $(template)
                  .find('.date')
                  .text(date.format($(template).find('.date').attr('data-format')));
                return $(template).html();
              },
            },
            {
              name: 'user_correspondence.issuer',
              width: 110,
              data: null,
              render(data, display, { createdBy }) {
                const creator = createdBy;
                const { account } = creator;
                const firstName = creator.firstName || '';
                const lastName = creator.lastName || '';

                const fullName = `${firstName} ${lastName}`;
                const $companySpan = $('<div />').addClass('company-name').text(account.name);
                const name = $('<p />').append(escape(fullName.trim())).append($companySpan);

                return name.html();
              },
            },
            {
              data: 'items',
              width: 110,
              orderable: false,
              render(items, display, { id }) {
                if (items.length > 1) {
                  const $container = $('<div />');
                  const text = `${items.length} people`;
                  const $link = $('<a />')
                    .addClass('link user-correspondence-recipient-trigger')
                    .attr('role', 'button')
                    .attr('data-user-correspondence-id', id)
                    .attr('data-stage-id', recentStageId)
                    .text(text);
                  return $container.append($link).html();
                }
                if (items.length === 1) {
                  const item = items[0];
                  const { user } = item;
                  const { account } = user;

                  const firstName = user.firstName || '';
                  const lastName = user.lastName || '';
                  const fullName = `${firstName} ${lastName}`;

                  const $companySpan = $('<div />').addClass('company-name').text(account.name);

                  const name = $('<p />').append(escape(fullName.trim())).append($companySpan);

                  return name.html();
                }
                return '-';
              },
            },
            {
              data: 'dueAt',
              name: 'user_correspondence.dueAt',
              width: 70,
              render(dueAt, display, corr, details) {
                if (dueAt === null) {
                  return '-';
                }
                const template = $placeholderRow.find('td').get(details.col);
                const date = moment.utc(dueAt.date);

                date.tz(getUserTimezone());
                $(template)
                  .find('.date')
                  .text(date.format($(template).find('.date').attr('data-format')));
                return $(template).html();
              },
            },
            {
              data: null,
              name: 'user_correspondence.assignee',
              width: 110,
              render(data, display, { assignee }) {
                if (assignee === null) {
                  return '-';
                }

                const { account } = assignee;
                const firstName = assignee.firstName || '';
                const lastName = assignee.lastName || '';

                const fullName = `${firstName} ${lastName}`;
                const $companySpan = $('<div />').addClass('company-name').text(account.name);
                const name = $('<p />').append(escape(fullName.trim())).append($companySpan);

                return name.html();
              },
            },
            {
              data: 'status',
              name: 'user_correspondence.status',
              width: 80,
              render(status) {
                const $span = $('<span>');

                const statusClass =
                  window.global.userCorrespondenceStatus &&
                  window.global.userCorrespondenceStatus[status]
                    ? window.global.userCorrespondenceStatus[status]
                    : '';

                if (statusClass) {
                  $span.addClass(`correspondence-status-tag ${statusClass.toLocaleLowerCase()}`);
                }

                return $span.prop('outerHTML');
              },
            },
          ],
          dom: 'Rrtp',
        });
        list.toggleTableDisplay(true);
        list.$target.closest('.loading-container').addClass('has-loaded');
      },
      recentMethod,
    );
  }

  $body.on('click', '.new-user-correspondence-trigger', (e) => {
    const stageId = $(e.currentTarget).attr('data-stage-id');
    const route = Routing.generate('app_usercorrespondence_newslider', {
      stageId,
    });

    const request = new E1Request(route);
    request.submit((req, resp) => {
      if (typeof resp === 'object' && resp.success) {
        if (typeof resp.slider_string === 'string') {
          const slider = new SuperSlider(resp.slider_string, {
            callbacks: {
              onClose: (sliderInstance) => {
                sliderInstance.$slider
                  .find('form.usercorrespondence-new-form')
                  .trigger('on_slider_close');
              },
            },
          });
          slider.show();
        }
      }
    });
  });

  $body.on('click', '.user-correspondence-recipient-trigger', async ({ currentTarget }) => {
    const stageId = $(currentTarget).data('stage-id');
    const corrId = $(currentTarget).data('user-correspondence-id');
    const recipientRoute = Routing.generate('app_usercorrespondence_recipients', {
      stageId,
      id: corrId,
    });

    return new E1Request(recipientRoute).submit();
  });

  $body.on('submit', 'form.usercorrespondence-new-form', async (submitEvent) => {
    submitEvent.preventDefault();

    const $form = $(submitEvent.currentTarget);
    const stageId = $form.find('button[type="submit"]').data('stage');
    const $selectedFiles = $form.find('.attachment-search :selected');
    $selectedFiles.prop('selected', false);

    const attachments = JSON.stringify(
      $selectedFiles
        .toArray()
        .map(({ dataset: { id, hash, size, name } }) => ({ id, hash, size, name })),
    );

    const attachmentsField = $form.find("[name='attachments']");

    if (attachmentsField.length) {
      attachmentsField.val(attachments);
    } else {
      $('<input>')
        .attr({
          type: 'hidden',
          name: 'attachments',
          value: attachments,
        })
        .appendTo($form);
    }

    await new Form(
      $form,
      Routing.generate('app_usercorrespondence_newslider', { stageId }),
    ).submit();
  });

  const $editCorrespondenceContainer = $('.edit-correspondence-ctn');
  if ($editCorrespondenceContainer.length > 0) {
    new EditCorrespondence($editCorrespondenceContainer);
  }

  const $history = $('.history');
  if ($history.length) {
    /** @type {AjaxList} historyList */
    let historyList;
    const $historyContainer = $history.find('.history-items-container').first();
    if ($historyContainer.length) {
      const correspondenceId = $historyContainer.attr('data-correspondence-id');
      historyList = new AjaxList(
        $historyContainer,
        null,
        Routing.generate('app_usercorrespondencehistory_fetch', {
          id: correspondenceId,
        }),
        (item, self) => {
          const $placeholder = $(self.$placeholder).clone();
          const $notePlaceHolder = $placeholder.find('.item-note').clone();
          const $logPlaceHolder = $placeholder.find('.item-log').clone();

          if (item.type === 'note') {
            $notePlaceHolder.find('.icon').addClass(item.icon);
            $notePlaceHolder.find('.author').text(item.created_by);
            $notePlaceHolder
              .find('.date')
              .text(moment(item.created_at).format('HH:mm z Do MMMM YYYY'));
            $notePlaceHolder.find('.note-message').text(item.message);
            return $notePlaceHolder.prop('outerHTML');
          }
          if (item.type === 'log') {
            $logPlaceHolder.find('.icon').addClass(item.icon);
            $logPlaceHolder
              .find('.date')
              .text(moment(item.created_at).format('HH:mm z Do MMMM YYYY'));
            $logPlaceHolder.find('.message').html($.parseHTML(item.message));
            return $logPlaceHolder.prop('outerHTML');
          }
        },
      );
    }

    const $noteForm = $('form.add-user-correspondence-note-form');
    if ($noteForm.length) {
      $noteForm.submit(async function (e) {
        e.preventDefault();
        const $form = $(this);
        const magicForm = new Form($form);
        await magicForm.submit();

        const $noteText = $('#note_text');

        if ($noteText.length) {
          $noteText.val('');
        }

        if (historyList) {
          await historyList.fetch();
          historyList.draw();
        }

        return false;
      });
    }

    $body.on('ajax-list-drawn', () => {
      const $dates = $body.find('.due-date');
      $.each($dates, function () {
        if ($(this).attr('data-due-date')) {
          const date = moment.unix($(this).attr('data-due-date'));
          $(this).text(date.tz(getUserTimezone()).format('Do MMMM YYYY'));
        }
      });
    });
  }

  $(document).on('correspondence:update-details', (e, data) => {
    if (data.view) {
      // be sure not to select the slider content here
      $body.find('div.section div.details .static-details').replaceWith($(data.view));
    }
  });

  const $previewPane = $('.user-correspondence-drill .preview');
  if ($previewPane.length) {
    $previewPane
      .on('mouseenter', ({ currentTarget }) => {
        $(currentTarget).find('.print-correspondence').show();
      })
      .on('mouseleave', ({ currentTarget }) => {
        $(currentTarget).find('.print-correspondence').hide();
      });
  }

  $body.on('click', '.attachment-save-trigger', (triggerSaveEvent) => {
    const $trigger = $(triggerSaveEvent.currentTarget);
    const $corr = $trigger.closest('.attachments');
    const correspondenceId = $corr.data('correspondence-id');
    const fileId = $trigger.closest('.attachment-item').data('attachment-id');
    const fileSource = $trigger.data('file-source');

    let { saveRoute, data, containerSelector } = [null, null, null];
    switch (fileSource) {
      // Route param names are slightly different
      case 'reply':
        saveRoute = 'app_usercorrespondencemailattachment_save';
        data = { correspondence: correspondenceId, fileId };
        containerSelector = 'corr-item';
        break;
      case 'email':
        saveRoute = 'app_usercorrespondenceemailfile_save';
        data = { id: correspondenceId, fileId };
        containerSelector = 'email-item';
        break;
      default:
        throw new Error();
    }

    const saveEndpoint = Routing.generate(saveRoute, data);
    const saveRequest = new E1Request(saveEndpoint, 'POST');
    $corr.addClass('busy-file-copying');
    saveRequest.extraCallback = () => {
      $corr.removeClass('busy-file-copying');

      $body
        .find(
          `div.${containerSelector} tr[data-attachment-id="${fileId}"] a.attachment-save-trigger`,
        )
        .add($trigger)
        .addClass('hide')
        .siblings()
        .removeClass('hide');
    };
    saveRequest.submit();
  });
});
