import { Outlet } from 'react-router-dom-v5-compat';
import { joinClassNames } from '@estimateone/frontend-components';
import { Compliance } from '@profiles/features/CompanyProfile/components/Compliance';
import { OfficeContactListNotOwnAccount } from '@profiles/features/CompanyProfile/components/OfficeContactList/OfficeContactList';
import { ProjectsBadge } from '@profiles/features/CompanyProfile/components/ProfileBadgeSection/ProjectsBadge';
import { ProjectHistoryNotOwnAccount } from '@profiles/features/CompanyProfile/components/ProjectHistory/ProjectHistory';
import { useProfileContext } from '@profiles/providers/ProfileProvider';
import { AboutUs } from '../../components/AboutUs';
import { Card, CardBody } from '../../components/Card';
import { Categories } from '../../components/Categories';
import { CompanyInfo } from '../../components/CompanyInfo';
import { Contacts as ContactsOwnAccount } from '../../components/Contacts';
import { GlobalErrorBanner, useGlobalErrorBanner } from '../../components/GlobalErrorBanner';
import { OfficeContactList } from '../../components/OfficeContactList';
import { PrimaryContact as PrimaryContactNotOwnAccount } from '../../components/PrimaryContact';
import { ABNBadge } from '../../components/ProfileBadgeSection/ABNBadge';
import { ContractAndWorkforceSize } from '../../components/ProfileBadgeSection/ContractAndWorkforceSize/ContractAndWorkforceSize';
import { LocationBadge } from '../../components/ProfileBadgeSection/LocationBadge/LocationBadge';
import { ProjectsBadgeWrapper } from '../../components/ProfileBadgeSection/ProjectsBadgeWrapper/ProjectsBadgeWrapper';
import { ProjectHistory } from '../../components/ProjectHistory';
import { Trades } from '../../components/Trades';
import styles from './CompanyProfile.module.scss';
import { CompanyProfileError } from './CompanyProfileError';
import { CompanyProfileSkeletonLoader } from './CompanyProfileSkeletonLoader';
import { Divider } from './Divider';
import { useOwnAccount } from '@profiles/features/CompanyProfile/hooks';
import { Profile, ProfileLayout } from '@profiles/apps/CompanyProfile/types';

/*
 * Temporary slider that only needs a profile and no other contexts, etc
 */
export const CompanyProfileForSSC = ({ profile }: { profile: Profile }) => (
  <>
    <div className={styles.profileBadges}>
      <div className={joinClassNames(styles.container, styles.sliderContainer)}>
        <ABNBadge businessIdentifier={profile.companyNumber} />
        <Divider />
        <ProjectsBadge projectsCount={profile.projectHistory.length} />
        <Divider />
        <ContractAndWorkforceSize
          contractSizeMax={profile.contractSizeMax}
          contractSizeMin={profile.contractSizeMin}
          workforceSize={profile.workforceSize}
        />
        <Divider />
        <LocationBadge
          defaultOffice={profile.offices.find((office) => office.isDefaultOffice) ?? null}
        />
      </div>
    </div>
    <div className={styles.aside}>
      <Card>
        <CardBody>
          <Trades data={profile.trades} />
          <Categories data={profile.projectCategories} />
        </CardBody>
        <PrimaryContactNotOwnAccount
          key="primary-contact"
          primaryContact={
            profile.people.find(({ isDefaultContact }) => isDefaultContact) ??
            profile.people.at(0) ?? // if there isn't a default contact use the first in the list
            null
          }
        />
      </Card>
    </div>
    <div className={joinClassNames(styles.profileContent, styles.sliderProfileContent)}>
      <AboutUs data={profile.aboutUs} />
      <ProjectHistoryNotOwnAccount data={profile.projectHistory} />
      <OfficeContactListNotOwnAccount offices={profile.offices} />
    </div>
  </>
);

export const CompanyProfile = ({ layout }: { layout: ProfileLayout }) => {
  const { openBanner } = useGlobalErrorBanner();
  const { profile, loading, error } = useProfileContext();

  const { isOwnAccount, loading: ownAccountLoading } = useOwnAccount();

  if (loading || ownAccountLoading) {
    return <CompanyProfileSkeletonLoader layout={layout} />;
  }

  if (error || profile === null || profile === undefined) {
    openBanner();
    return <CompanyProfileError layout={layout} />;
  }

  return (
    <>
      {layout === 'page' && (
        <div className={styles.profileHeader}>
          <GlobalErrorBanner />
          <h1 className={styles.title}>Company Profile</h1>
          <CompanyInfo
            companyName={profile.name ?? undefined}
            memberSince={profile.memberSince ?? undefined}
            lastActiveAt={profile.lastActiveAt ?? null}
          />
        </div>
      )}
      <div className={styles.profileBadges}>
        <div
          className={joinClassNames(
            styles.container,
            layout === 'slider' && styles.sliderContainer,
          )}
        >
          <ABNBadge businessIdentifier={profile.companyNumber} />
          <Divider />
          <ProjectsBadgeWrapper count={profile.projectHistory.length} />
          <Divider />
          <ContractAndWorkforceSize
            contractSizeMax={profile.contractSizeMax}
            contractSizeMin={profile.contractSizeMin}
            workforceSize={profile.workforceSize}
          />
          <Divider />
          <LocationBadge
            defaultOffice={profile.offices.find((office) => office.isDefaultOffice) ?? null}
          />
        </div>
      </div>
      <div className={styles.aside}>
        <Card>
          <CardBody>
            <Trades data={profile.trades} />
            <Categories data={profile.projectCategories} />
          </CardBody>
          <PrimaryContactNotOwnAccount
            key="primary-contact"
            primaryContact={
              profile.people.find(({ isDefaultContact }) => isDefaultContact) ??
              profile.people.at(0) ?? // if there isn't a default contact use the first in the list
              null
            }
          />
        </Card>
      </div>
      <div
        className={`${styles.profileContent} ${
          layout === 'slider' ? styles.sliderProfileContent : ''
        }`}
      >
        <AboutUs data={profile.aboutUs} />
        {isOwnAccount && <Compliance qualifications={profile.qualifications} />}
        <ContactsOwnAccount companyContacts={profile.people} />
        <ProjectHistory data={profile.projectHistory} />
        <OfficeContactList offices={profile.offices} />
        <Outlet />
      </div>
    </>
  );
};
