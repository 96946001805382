import { InputFieldWrapper } from '@profiles/features/CompanyProfile/modules/FinalFormFields/InputFieldWrapper';
import { OfficeDetailsFieldNames, OfficeDetailsFieldLabels } from './types';

export const WebsiteField = () => (
  <div>
    <InputFieldWrapper<string>
      field={OfficeDetailsFieldNames.Website}
      id={OfficeDetailsFieldNames.Website}
      label={OfficeDetailsFieldLabels.Website}
    />
  </div>
);
