import { gql } from '@apollo/client';

const CONTACTS_FRAGMENT = gql`
  fragment ContactsFragment on Contact {
    id
    fullName
    email
  }
`;

const NEW_PROFILE_RESULT_FRAGMENT = gql`
  fragment NewProfileResultFragment on NewProfileResult {
    __typename
    id
    accountId
    name
    offices {
      id
      name
      isDefault
      address {
        id
        address1
        address2
        suburb
        city
        province
        state {
          id
          shortName
        }
        postcode
        country {
          id
          shortName
        }
      }
    }
    contacts {
      ...ContactsFragment
    }
  }
`;

const ADDRESS_BOOK_COMPANY_RESULT_FRAGMENT = gql`
  fragment AddressBookCompanyResultFragment on AddressBookCompanyResult {
    __typename
    id
    linkedAccountId
    profileId
    addressBookId
    name
    offices {
      id
      name
      isDefault
      address {
        id
        address1
        address2
        suburb
        city
        province
        state {
          id
          shortName
        }
        postcode
        country {
          id
          shortName
        }
      }
    }
    contacts {
      ...ContactsFragment
    }
  }
`;

export const GET_MATCHING_PROFILES = gql`
  ${CONTACTS_FRAGMENT}
  ${NEW_PROFILE_RESULT_FRAGMENT}
  ${ADDRESS_BOOK_COMPANY_RESULT_FRAGMENT}
  query SearchMatchingProfiles($search: SearchMatchingProfilesInput!) {
    searchMatchingProfiles(search: $search) {
      results {
        ... on NewProfileResult {
          ...NewProfileResultFragment
        }
        ... on AddressBookCompanyResult {
          ...AddressBookCompanyResultFragment
        }
      }
      highlights {
        key
        value
      }
      searched {
        ... on NewProfileResult {
          ...NewProfileResultFragment
        }
        ... on AddressBookCompanyResult {
          ...AddressBookCompanyResultFragment
        }
      }
    }
  }
`;
