import { useCallback, useRef } from 'react';

/**
 * Debounces provided callback so that it is only executed after ${TIME} has passed since the most recent call
 *
 * @example
 * // takes the value of the AsyncSelect input and asks Google to find places matching the input
 * const loadOptionsFromGoogle = useDebouncedCallback(
 *    (inputValue: string, callback: (opts: Option[]) => void) => getGoogleSuggestions(inputValue).then(callback)
 * )
 *
 * return <AsyncSelect loadOptions={loadOptionsFromGoogle} />
 */
export const useDebouncedCallback = <TArgs extends unknown[], TResponse = unknown>(
  cb: (...args: TArgs) => TResponse,
  wait = 750,
) => {
  const timeout = useRef<NodeJS.Timeout | undefined>(undefined);

  return useCallback((...args: TArgs) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      cb(...args);
      clearTimeout(timeout.current);
    }, wait);
  }, []);
};
