import { format, parseISO } from 'date-fns';
import styles from './styles.scss';

type DateTimeCellProps = {
  dateTime: string | null;
};

export const DateTimeCell = ({ dateTime }: DateTimeCellProps) => {
  const parsedAt = dateTime ? parseISO(dateTime) : null;
  const formatedAt = parsedAt ? format(parsedAt, 'd MMM yyyy, h:mmaaa') : null;

  return <span className={styles.dateTimeCell}>{formatedAt ?? '-'}</span>;
};
