/** SUBBIE_CREDIT_EXPERIMENT_ALPHA_CODE */
import { E1Link } from '@estimateone/frontend-components';
import { ExhaustedCreditsButtonsView } from '../../ButtonsSection';
import { LitePlanCreditsExhaustedButtonsView } from '../../ButtonsSection/LitePlanCreditsExhaustedButtonsView';
import {
  SingleFreeCreditExhaustedGraphicSection,
  MultiFreeCreditsExhaustedGraphicSection,
  LitePlanCreditsExhaustedGraphicSection,
} from '../../GraphicSection';
import {
  MultiFreeCreditsExhaustedHeader,
  LitePlanCreditsExhaustedHeader,
} from '../../HeaderSection';
import { TradesSection } from '../../TradesSection';
import { useContactUsAboutUpgrade } from '@subbie/features/AccountUpgradeFeature/pages/NewUpgradeSelection/hooks';
import { UPGRADE_TYPE } from '@subbie/features/AccountUpgradeFeature/pages/NewUpgradeSelection/hooks/types';
import styles from '../styles.module.scss';

type FreeCreditsExhaustedAdditionalContentProps = {
  upgradeUrl: string;
};

const FreeCreditsExhaustedAdditionalContent = ({
  upgradeUrl,
}: FreeCreditsExhaustedAdditionalContentProps) => (
  <div>
    <p>Hold on a minute&hellip;</p>
    <p>
      Your monthly tenders have been unlocked already. You&apos;ll get access to your{' '}
      <strong>next free tender on the 1st day of the next month.</strong>
    </p>
    <br />
    <p>
      <strong>Want to access all open tenders? </strong>By{' '}
      <E1Link href={upgradeUrl}>
        <strong>upgrading your account</strong>
      </E1Link>{' '}
      you&apos;ll get full access across open tenders in your state, awarded work information plus a
      whole lot more!
    </p>
  </div>
);

type LitePlanCreditsExhaustedAdditionalContentProps = {
  handleUpgrade: () => void;
};
const LitePlanCreditsExhaustedAdditionalContent = ({
  handleUpgrade,
}: LitePlanCreditsExhaustedAdditionalContentProps) => (
  <div>
    <p>Hold on a minute&hellip;</p>
    <p>
      Your monthly tenders have been unlocked already. You&apos;ll get access to your{' '}
      <strong>next tender on the 1st day of the next month.</strong>
    </p>
    <br />
    <p>
      <strong>Want to access all open tenders? </strong>By{' '}
      <E1Link onClick={handleUpgrade}>
        <strong>upgrading your account</strong>
      </E1Link>{' '}
      you&apos;ll get full access across open tenders in your state, awarded work information plus a
      whole lot more!
    </p>
  </div>
);

type SingleFreeCreditExhaustedViewProps = FreeCreditsExhaustedAdditionalContentProps & {
  projectId: number;
};

export const SingleFreeCreditExhaustedView = ({
  upgradeUrl,
  projectId,
}: SingleFreeCreditExhaustedViewProps) => (
  <>
    <SingleFreeCreditExhaustedGraphicSection />
    <MultiFreeCreditsExhaustedHeader />
    <TradesSection projectId={projectId} />
    <div className={styles.additionalFeaturesContainerCreditExperiment}>
      <FreeCreditsExhaustedAdditionalContent upgradeUrl={upgradeUrl} />
    </div>
    <ExhaustedCreditsButtonsView upgradeUrl={upgradeUrl} />
  </>
);

export const MultiFreeCreditsExhaustedView = ({
  upgradeUrl,
  projectId,
}: SingleFreeCreditExhaustedViewProps) => (
  <>
    <MultiFreeCreditsExhaustedGraphicSection />
    <MultiFreeCreditsExhaustedHeader />
    <TradesSection projectId={projectId} />
    <div className={styles.additionalFeaturesContainerCreditExperiment}>
      <FreeCreditsExhaustedAdditionalContent upgradeUrl={upgradeUrl} />
    </div>
    <ExhaustedCreditsButtonsView upgradeUrl={upgradeUrl} />
  </>
);

type LitePlanCreditsExhaustedViewProps = {
  projectId: number;
  removeModal: () => void;
};
export const LitePlanCreditsExhaustedView = ({
  projectId,
  removeModal,
}: LitePlanCreditsExhaustedViewProps) => {
  const openContactUsForm = useContactUsAboutUpgrade(UPGRADE_TYPE.Essential);

  const handleUpgrade = () => {
    openContactUsForm();
    removeModal();
  };

  return (
    <>
      <LitePlanCreditsExhaustedGraphicSection />
      <LitePlanCreditsExhaustedHeader />
      <TradesSection projectId={projectId} />
      <div className={styles.additionalFeaturesContainerCreditExperiment}>
        <LitePlanCreditsExhaustedAdditionalContent handleUpgrade={handleUpgrade} />
      </div>
      <LitePlanCreditsExhaustedButtonsView onClick={handleUpgrade} />
    </>
  );
};

type ExhaustedCreditsViewProps = SingleFreeCreditExhaustedViewProps & {
  creditsAllowed: number;
};

export const ExhaustedCreditsView = ({
  upgradeUrl,
  projectId,
  creditsAllowed,
  removeModal,
}: ExhaustedCreditsViewProps & LitePlanCreditsExhaustedViewProps) => {
  if (creditsAllowed === 1) {
    return <SingleFreeCreditExhaustedView upgradeUrl={upgradeUrl} projectId={projectId} />;
  }
  if (creditsAllowed === 3) {
    return <MultiFreeCreditsExhaustedView upgradeUrl={upgradeUrl} projectId={projectId} />;
  }
  if (creditsAllowed === 10) {
    return <LitePlanCreditsExhaustedView projectId={projectId} removeModal={removeModal} />;
  }
  return null;
};
