import { Button, ButtonVariant, FilterIcon, useDrawer } from '@estimateone/frontend-components';
import { useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { FilterChips } from '@builder/features/SubbieNetwork/components/SubbieNetworkFilterChips/FilterChips';
import SubbieNetworkFilterDrawer from '@builder/features/SubbieNetwork/components/SubbieNetworkFilterDrawer';
import { selectedFiltersAtom } from './atoms';
import { NetworkHelpBanner } from './components/NetworkHelpBanner';
import SubbieNetworkSearchResults from './components/SubbieNetworkSearchResults';
import TradeFilter from './components/TradeFilter';
import PageLoadingSpinner from '@shared/LoadingSpinner/PageLoadingSpinner';
import { useAscensionAccountData } from './providers/AscensionDataProvider/hooks';
import styles from './styles.module.scss';

const SubbieNetwork = ({ hasProcurementPhaseFeature }: { hasProcurementPhaseFeature: boolean }) => {
  const ascAccountData = useAscensionAccountData();
  const setSelectedFilters = useSetAtom(selectedFiltersAtom);
  const { isOpen, onOpen, onClose } = useDrawer(false);
  const user = ascAccountData?.currentUser;

  if (ascAccountData === undefined || !user) {
    return (
      <main className={styles.main}>
        <PageLoadingSpinner />
      </main>
    );
  }

  const onReset = () => {
    setSelectedFilters(RESET);
  };

  return (
    <>
      <SubbieNetworkFilterDrawer isOpen={isOpen} onClose={onClose} />
      <main className={styles.main}>
        <div className={styles.filterWrapper}>
          <NetworkHelpBanner userId={user.id} />
          <div className={styles.filterSection}>
            <div>
              <TradeFilter />
            </div>
            <div className={styles.filterButtons}>
              <Button
                variant={ButtonVariant.Grey}
                onClick={onOpen}
                className={styles.buttonWithIcon}
                aria-label="All Filters"
              >
                <FilterIcon className={styles.filterIcon} />
                All Filters
              </Button>
              <Button variant={ButtonVariant.Transparent} onClick={onReset}>
                Reset
              </Button>
            </div>
            <div className={styles.chips}>
              <FilterChips onFilterChipClicked={onOpen} />
            </div>
          </div>
        </div>
        <div className={styles.divider} />
        <div className={styles.resultsSection}>
          <SubbieNetworkSearchResults hasProcurementPhaseFeature={hasProcurementPhaseFeature} />
        </div>
      </main>
    </>
  );
};

export default SubbieNetwork;
