import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import Routing from 'routing';
import { GET_DOCUMENT_INTEGRATION_SYNC_PROGRESS } from './queries';
import { getStageTypeAsString, StageType } from '@ascension/enums';
import {
  GetDocumentIntegrationSyncProgress,
  GetDocumentIntegrationSyncProgressVariables,
} from './types/GetDocumentIntegrationSyncProgress';
import { EntityId } from '@ascension/types';

const POLL_INTERVAL = 5000;

export const useShowSyncProgress = (
  stageId: EntityId,
  shouldCheckProgress: boolean,
  stageType: StageType,
) => {
  const { data, error, loading, startPolling, stopPolling, called } = useQuery<
    GetDocumentIntegrationSyncProgress,
    GetDocumentIntegrationSyncProgressVariables
  >(GET_DOCUMENT_INTEGRATION_SYNC_PROGRESS, {
    variables: { stageId },
    pollInterval: POLL_INTERVAL,
    fetchPolicy: 'no-cache',
    skip: !shouldCheckProgress,
  });

  const progress = data?.documentIntegrationSyncProgress ?? null;

  const [isProgressModalOpen, setIsProgressModalOpen] = useState(false);

  useEffect(() => {
    if (loading || !called) return;

    const isProgressFull =
      progress && progress.taskCount !== -1 && progress.tasksCompleted >= progress.taskCount;

    const completeOnFirstLoad = isProgressFull && !isProgressModalOpen;
    if (progress === null || error || completeOnFirstLoad) {
      stopPolling();
      return;
    }

    if (!isProgressFull) {
      setIsProgressModalOpen(true);
    }

    const isCompleteWhileModalOpen = isProgressFull && isProgressModalOpen;
    if (isCompleteWhileModalOpen) {
      const route = Routing.generate('app_stage_documents', {
        id: stageId,
        stageType: getStageTypeAsString(stageType)?.toLowerCase(),
      });
      window.location.assign(route);
    }
  }, [
    error,
    stopPolling,
    loading,
    stageId,
    shouldCheckProgress,
    progress,
    isProgressModalOpen,
    called,
    stageType,
  ]);

  return {
    progress,
    startPolling: () => startPolling(POLL_INTERVAL),
    isProgressModalOpen,
  };
};
