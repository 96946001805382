import { Outlet } from 'react-router-dom-v5-compat';
import { useQuery } from '@apollo/client';
import { LoadingSpinner, LoadingSpinnerSize } from '@estimateone/frontend-components';
import { reportError } from '@ascension/components/helpers/errorReporter';
import { GlobalErrorBanner, useGlobalErrorBanner } from '../../components/GlobalErrorBanner';
import { DirectoryOptInOption } from '../../components/ProfileSettings/DirectoryOptInOption';
import { SSCOptOutOption } from '../../components/ProfileSettings/SSCOptOutOption';
import styles from './ProfileSettings.module.scss';
import { GET_SUBBIE_SETTINGS_ACCESS } from './queries';
import { GetSubbieSettingsAccessQuery } from './queries.generated';

export const ProfileSettingsPage = () => {
  const { openBanner } = useGlobalErrorBanner();
  const { data, loading } = useQuery<GetSubbieSettingsAccessQuery>(GET_SUBBIE_SETTINGS_ACCESS, {
    onError: (apolloError) => {
      reportError(apolloError);
      openBanner();
    },
  });

  return (
    <>
      <GlobalErrorBanner />
      <h1 className={styles.settingsHeader}>Profile Settings</h1>
      {loading && <LoadingSpinner size={LoadingSpinnerSize.Medium} />}
      {data && (
        <>
          {data.canEditNetworkEnrolment && <DirectoryOptInOption />}
          {data.canEditSubbieSupplierConnectionSetting && <SSCOptOutOption />}
        </>
      )}
      <Outlet />
    </>
  );
};
