import { gql } from '@apollo/client';
import { WatchedProject, WatchlistProjectAssignmentOrErrors } from './fragments';

export const UPDATE_WATCHLIST_PROJECT = gql`
  mutation UpdateWatchlistProject($projectId: EntityId!, $status: InterestLevel!, $source: String) {
    watchProject(input: { projectId: $projectId, status: $status, source: $source }) {
      ...WatchedProject
    }
  }
  ${WatchedProject}
`;

export const WATCHLIST_ASSIGN_PROJECT_TO_TEAMMEMBER = gql`
  mutation AssignProjectToTeamMember(
    $projectId: EntityId!
    $teamMemberId: EntityId!
    $source: String
  ) {
    assignProjectToTeamMember(
      input: { projectId: $projectId, teamMemberId: $teamMemberId, source: $source }
    ) {
      ...WatchlistProjectAssignmentOrErrors
    }
  }
  ${WatchlistProjectAssignmentOrErrors}
`;
