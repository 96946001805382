import {
  Button,
  ButtonVariant,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  DrawerToolbar,
} from '@estimateone/frontend-components';
import { useAtomValue } from 'jotai';
import { selectedFormFiltersCountAtom } from '../../atoms';
import { SubbieNetworkFilterForm } from './SubbieNetworkFilterForm';
import { pluralise } from '@shared/Util/pluralise';
import { SubbieNetworkFilterFormId } from './types';
import styles from './styles.module.scss';

type DrawerProps = Parameters<typeof Drawer>[0];
type SubbieNetworkFilterDrawerProps = Pick<DrawerProps, 'isOpen' | 'onClose'>;

const SubbieNetworkFilterDrawer = ({ isOpen, onClose }: SubbieNetworkFilterDrawerProps) => {
  const count = useAtomValue(selectedFormFiltersCountAtom);

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerToolbar className={styles.filterToolbar} />
        <DrawerBody className={styles.filterBody}>
          <h1 className={styles.filterHeading}>All Filters</h1>
          <SubbieNetworkFilterForm />
        </DrawerBody>
        <DrawerFooter className={styles.footerButtonContainer}>
          <Button type="reset" variant={ButtonVariant.Grey} form={SubbieNetworkFilterFormId}>
            Reset Filters
          </Button>
          <Button type="submit" variant={ButtonVariant.Primary} form={SubbieNetworkFilterFormId}>
            Confirm {count} {pluralise('Filter', count)}
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default SubbieNetworkFilterDrawer;
