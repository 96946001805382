import { DetailsCard } from '@estimateone/frontend-components';
import { useStageNameForDocumentIntegration } from '@builder/features/DocSyncIntegration/DocumentIntegrationProvider';
import { BaseFolder, DriveItemMap, SharePointDrive, SharePointSite } from '../types';
import styles from './styles.scss';

interface SharepointConfirmSelectionProps {
  selectedDriveItems: DriveItemMap;
  selectedDrive: SharePointDrive;
  selectedSite: SharePointSite;
  selectedBaseFolder: BaseFolder;
}

export const SharepointConfirmSelection = ({
  selectedDriveItems,
  selectedDrive: { name: driveName },
  selectedSite: { name: siteName },
  selectedBaseFolder: { path: baseFolderPath },
}: SharepointConfirmSelectionProps) => {
  const stageName = useStageNameForDocumentIntegration();
  const getSelectionContent = () => [
    {
      label: 'E1 Project',
      description: <p>{stageName}</p>,
    },
    {
      label: 'SharePoint Project',
      description: (
        <div>
          <p className={styles.projectLabel}>
            Site: <span className={styles.projectDescription}>{siteName}</span>
          </p>
          <p className={styles.projectLabel}>
            Drive: <span className={styles.projectDescription}>{driveName}</span>
          </p>
          <p className={styles.projectLabel}>
            Base Folder: <span className={styles.projectDescription}>{baseFolderPath}</span>
          </p>
        </div>
      ),
    },
    {
      label: 'Importing',
      description: (
        <div>
          {selectedDriveItems.size > 0 && (
            <ul className={styles.documentsList}>
              {[...selectedDriveItems.values()].map(({ id, name }) => (
                <li key={id}>{name}</li>
              ))}
            </ul>
          )}
        </div>
      ),
    },
  ];
  return (
    <>
      <p className={styles.detailsText}>Confirm the connection details:</p>
      <DetailsCard contents={getSelectionContent()} />
    </>
  );
};
