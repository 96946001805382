import { useApolloClient, useMutation } from '@apollo/client';
import { useCompanyProfileCacheItemId } from '@profiles/providers/ProfileProvider';
import { transformValues } from '../transformFormValues';
import { ADD_PROJECT_TO_HISTORY } from './mutations';
import {
  AddProjectToHistoryMutation,
  AddProjectToHistoryMutationVariables,
} from './mutations.generated';
import { ProjectHistoryFieldValues } from '@profiles/features/CompanyProfile/components/ProjectHistory/ProjectHistoryForm/types';

export const useAddProjectToProjectHistory = () => {
  const { cache } = useApolloClient();
  const id = useCompanyProfileCacheItemId();

  const [addProjectHistoryName, { error, reset }] = useMutation<
    AddProjectToHistoryMutation,
    AddProjectToHistoryMutationVariables
  >(ADD_PROJECT_TO_HISTORY, {
    // TODO: I think we just return ProfileOrErrors instead?
    onCompleted: (data) => {
      if (data.addProjectToHistory.__typename === 'HistoricalProject') {
        cache.modify({
          id,
          fields: {
            projectHistory: (existing = []) => [...existing, data.addProjectToHistory],
          },
        });
      }
    },
  });

  const submit = async (values: ProjectHistoryFieldValues) => {
    const { data, errors } = await addProjectHistoryName({
      variables: { input: transformValues(values) },
    });

    return {
      data,
      errors,
    };
  };

  return { submit, error: error ?? undefined, reset };
};
