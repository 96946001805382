import { MultiSelectDropdownField } from '@profiles/features/CompanyProfile/modules/FinalFormFields/MultiSelectDropdownField/MultiSelectDropdownField';
import { SubbieStockTradesQuery } from '@profiles/features/CompanyProfile/components/Trades/queries.generated';
import { EditTradesFieldName } from '@profiles/features/CompanyProfile/components/Trades/types';

type StockTrades = SubbieStockTradesQuery['stockTrades'][number];

type TradesMultiSelectFieldProps = {
  tradesOptions: StockTrades[];
};

export type TradeOption = {
  value: number;
  label: string;
};

export const TradesMultiSelectField = ({ tradesOptions }: TradesMultiSelectFieldProps) => (
  <MultiSelectDropdownField
    id="trades"
    label=""
    hideLabel
    fieldName={EditTradesFieldName.Trades}
    options={tradesOptions}
  />
);
