import {
  Button,
  E1Link,
  LinkVariant,
  Modal,
  SkeletonLoader,
  Tag,
  TagVariant,
} from '@estimateone/frontend-components';
import { BenefitsList } from './BenefitsList';
import { useBillingContact } from './hooks/useBillingContact';
import styles from './styles.scss';

type ThankYouPageProps = {
  supportEmail: string;
  supportPhone: string;
  invoiceUrl: string;
  onClose: () => void;
};

export const ThankYouPage = ({
  supportEmail,
  supportPhone,
  invoiceUrl,
  onClose,
}: ThankYouPageProps) => {
  const { loading, billingContact } = useBillingContact();

  return (
    <>
      <h3 className={styles.modalHeader}>Your account has been upgraded</h3>
      <Modal.Section span={{ lg: 6 }}>
        <div className={styles.modalSectionTitle}>Features now available to you in Essential</div>
        <BenefitsList />
      </Modal.Section>
      <Modal.Section span={{ lg: 6 }}>
        {loading ? (
          <div role="status" className={styles.billingContactDetailsLoaderContainer} aria-busy>
            <SkeletonLoader widthRandomness={0} heightRandomness={0} count={2} width="90%" />
          </div>
        ) : (
          billingContact && (
            <>
              <div className={styles.modalSectionTitle}>
                A confirmation email &amp; tax invoice has been sent to:
              </div>
              <div className={styles.billingContactDetails}>
                <div className={styles.name}>
                  <p className={styles.billingContactName}>{billingContact.fullName}</p>
                </div>
                <div className={styles.tag}>
                  <Tag variant={TagVariant.Iron050} text="Billing contact" />
                </div>
                <div className={styles.email}>
                  <p>{billingContact.email}</p>
                </div>
              </div>
              {invoiceUrl && (
                <div className={styles.downloadInvoice}>
                  <E1Link variant={LinkVariant.Grey} href={invoiceUrl} id="invoice-link">
                    Download invoice
                  </E1Link>
                </div>
              )}
            </>
          )
        )}
        <Button className={styles.doneButton} onClick={onClose}>
          Done
        </Button>
        <span className={styles.anyProblems}>
          Any problems? <a href={`mailto:${supportEmail}`}>Email support</a> or call {supportPhone}
        </span>
      </Modal.Section>
    </>
  );
};
