import { useState } from 'react';
import { Button, ButtonVariant, Slider, LoadingSpinner } from '@estimateone/frontend-components';
import MemberList from './MemberList';
import { DisplayUser } from '../types';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

type AddMembersSliderProps = {
  membersList: DisplayUser[];
  isOpen: boolean;
  onRequestClose: () => void;
  handleAddMembers: (userIds: EntityId[]) => Promise<void>;
  grantAccessLoading: boolean;
};

export const AddMembersSlider = ({
  membersList,
  isOpen,
  onRequestClose,
  handleAddMembers,
  grantAccessLoading,
}: AddMembersSliderProps) => {
  const [selectedMemberIds, setSelectedMemberIds] = useState<EntityId[]>([]);

  const addMembersHandler = async () => {
    await handleAddMembers(selectedMemberIds);
    setSelectedMemberIds([]);
    onRequestClose();
  };

  return (
    <Slider isOpen={isOpen} onRequestClose={onRequestClose}>
      <Slider.Header variant="primary">
        <h1 className={styles.sliderHeading}>Add Users</h1>
      </Slider.Header>
      <Slider.Body scrollBodyOnly>
        <MemberList
          memberList={membersList}
          setSelectedMemberIds={setSelectedMemberIds}
          selectedMemberIds={selectedMemberIds}
        />
        {grantAccessLoading && <LoadingSpinner />}
      </Slider.Body>
      <Slider.Footer>
        <div className={styles.sliderFooter}>
          <Button
            className={styles.sliderButton}
            onClick={onRequestClose}
            variant={ButtonVariant.Grey}
          >
            Cancel
          </Button>
          <Button
            className={styles.sliderButton}
            onClick={addMembersHandler}
            variant={ButtonVariant.Primary}
            disabled={grantAccessLoading}
          >
            Add
          </Button>
        </div>
      </Slider.Footer>
    </Slider>
  );
};
