import {
  Button,
  ButtonSize,
  ButtonVariant,
  HelpBanner,
  HelpBannerContent,
  HelpBannerVariant,
  HelpBannerVideo,
} from '@estimateone/frontend-components';
import styles from './styles.module.scss';

export const NetworkUpsell = () => (
  <div className={styles.main}>
    <HelpBanner isHidden={false} toggleHidden={() => {}} variant={HelpBannerVariant.Electric}>
      <HelpBannerContent className={styles.contentContainer}>
        <div>
          <h3>Australia’s largest real-time Subbie Network, at your fingertips!</h3>
          <ul>
            <li>
              Identify thousands of new, best-fit subbies to grow your network and expand your
              coverage
            </li>
            <li>
              Access real-time company information across your supply chain, updated by subbies
              themselves
            </li>
            <li>
              Maintain accurate, up-to-date Address Book data by connecting with E1’s Subbie Network
            </li>
            <li>
              Streamline decision-making with a single view of subbie data and existing relationship
              insights
            </li>
          </ul>
        </div>
        <div className={styles.buttonContainer}>
          <Button
            variant={ButtonVariant.Transparent}
            size={ButtonSize.Small}
            href="https://info.estimateone.com/e1-subbie-network"
            target="_blank"
            className={styles.learnMoreButton}
          >
            Learn more
          </Button>
          <Button
            variant={ButtonVariant.Electric}
            size={ButtonSize.Small}
            href="https://calendly.com/d/3yj-m93-cvv/estimateone-network-demonstration"
            target="_blank"
          >
            Contact Sales
          </Button>
        </div>
      </HelpBannerContent>
      <HelpBannerVideo url="https://player.vimeo.com/video/941451527" controls />
    </HelpBanner>
  </div>
);
