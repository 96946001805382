import { ReactElement, ReactHTMLElement } from 'react';
import { useField } from 'react-final-form';
import { Fieldset, RadioButtonGroup } from '@estimateone/frontend-components';
import { RadioButtonGroupProps } from '@estimateone/frontend-components/src/components/Form/RadioButtonGroup/RadioButtonGroup';
import { FieldWrapper } from './FieldWrapper';

export type RadioButtonGroupFieldProps = {
  fieldName: string;
  legend: string;
  isRequired?: boolean;
  children: (ReactElement<HTMLElement> | ReactHTMLElement<HTMLElement>)[];
} & Partial<RadioButtonGroupProps>;

export const RadioButtonGroupField = ({
  children,
  fieldName,
  legend,
  isRequired,
  className,
  ...props
}: RadioButtonGroupFieldProps) => {
  const {
    input,
    meta: { error, submitError, touched },
  } = useField(fieldName);

  return (
    <FieldWrapper errors={error || submitError} showErrors={touched}>
      <div className={className}>
        <Fieldset legend={legend} isRequired={isRequired}>
          <RadioButtonGroup {...input} hasError={(error && touched) || submitError} {...props}>
            {children}
          </RadioButtonGroup>
        </Fieldset>
      </div>
    </FieldWrapper>
  );
};
