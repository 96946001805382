import { useState } from 'react';
import Routing from 'routing';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  Modal,
  ModalSize,
  Select,
  OptionValue,
} from '@estimateone/frontend-components';
import { AddendumExistsAlert } from './AddendumExistsAlert';
import { groupPackagesByDiscipline } from './utils/grouping';
import LoadingSpinnerFloating from '@shared/LoadingSpinner/LoadingSpinnerFloating';
import { LabelledOption } from '@shared/Select';
import { useGetPackagesForExport } from './hooks';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

export type ExportPackageRegisterModalProps = {
  stageId: EntityId;
  existingAddendumId?: EntityId | null;
  isOpen: boolean;
  onRequestClose: () => void;
};

export const ExportPackageRegisterModal = ({
  stageId,
  existingAddendumId,
  isOpen,
  onRequestClose,
}: ExportPackageRegisterModalProps) => {
  const { loading, activePackages } = useGetPackagesForExport(stageId);
  const [selectedPackage, setSelectedPackage] = useState<number>();

  const onSelectedChanged = ({ value }: LabelledOption<OptionValue>) => {
    setSelectedPackage(value as number);
  };

  const onExportHandler = async () => {
    window.location.replace(
      Routing.generate('app_stagedocument_exportpackageregister', { id: selectedPackage }),
    );
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      rootElement="body"
      size={ModalSize.Small}
    >
      {loading ? (
        <Modal.Section>
          <LoadingSpinnerFloating />
        </Modal.Section>
      ) : (
        <Modal.Section>
          <h1 className={styles.exportPackageRegisterHeading}>Export Package Register</h1>
          <div className={styles.exportPackageRegisterBody}>
            {existingAddendumId && <AddendumExistsAlert existingAddendumId={existingAddendumId} />}

            <p className={styles.exportPackageRegisterDescription}>
              Export an Excel spreadsheet of documents assigned to a package, including revision
              history.
            </p>

            <Select
              id="export-package-register-selector"
              data-testid="export-package-register-selector"
              label="Select a Package"
              aria-label="Select a Package"
              options={groupPackagesByDiscipline(activePackages)}
              onChange={onSelectedChanged}
              isRequired
              menuPortalTarget={document.body}
            />
          </div>
          <div className={styles.exportPackageRegisterFooter}>
            <Button size={ButtonSize.Medium} variant={ButtonVariant.Grey} onClick={onRequestClose}>
              Cancel
            </Button>
            <Button
              size={ButtonSize.Medium}
              variant={ButtonVariant.Primary}
              disabled={!selectedPackage}
              onClick={onExportHandler}
            >
              Export
            </Button>
          </div>
        </Modal.Section>
      )}
    </Modal>
  );
};
