import { Form } from 'react-final-form';
import { extractFieldErrors } from '@profiles/features/CompanyProfile/utils';
import { sendAppcuesEditProfileEvent } from '@profiles/features/CompanyProfile/utils/appcues';
import { ModalFooter } from '../../../../modules/Modal/ModalFooter';
import { FormError } from '../../../FormError';
import {
  ContractSize,
  EMPTY_CONTRACT_SIZE,
} from '../../ContractSizeBadge/EditContractSize/fields/ContractSize';
import {
  ContractSizeMaxEnumMap,
  ContractSizeMinEnumMap,
} from '../../ContractSizeBadge/EditContractSize/values';
import { contractSizeLabelMap } from '../../ContractSizeBadge/utils';
import { WorkforceSizeSingleSelect } from '@shared/form/WorkforceSizeSingleSelect';
import {
  getWorkforceSizeOptionFromEnum,
  WorkforceSizeFieldTypes,
} from '@shared/form/WorkforceSizeSingleSelect/WorkforceSize';
import { useSubmitForm } from './hooks';
import {
  EditContractSizeFieldName,
  EditContractSizeFieldValues,
} from '../../ContractSizeBadge/EditContractSize/types';
import { EditContractAndWorkforceSizeFormErrors } from './types';
import { ContractSizeEnum, WorkforceSizeEnum } from '@ascension/_gqltypes/profiles.generated';

type FormFields = WorkforceSizeFieldTypes & EditContractSizeFieldValues;

export type EditContractAndWorkforceSizeFormProps = {
  workforceSize: WorkforceSizeEnum | null;
  contractSizeMin: ContractSizeEnum | null;
  contractSizeMax: ContractSizeEnum | null;
  toggle: () => void;
};

export const EditContractAndWorkforceSizeForm = ({
  workforceSize,
  contractSizeMin,
  contractSizeMax,
  toggle,
}: EditContractAndWorkforceSizeFormProps) => {
  const { submit: submitForm, error } = useSubmitForm();

  const onSubmit = async (values: FormFields) => {
    const { errors: submitErrors, data } = await submitForm(values);

    if (submitErrors) {
      return null;
    }

    if (data?.updateContractSize && data.updateContractSize.__typename === 'Errors') {
      const { errors } = data.updateContractSize;

      const fieldErrors = extractFieldErrors(errors, [
        EditContractSizeFieldName.MinContractSize,
        EditContractSizeFieldName.MaxContractSize,
        EditContractSizeFieldName.MinAndMaxContractSize,
      ]);

      return fieldErrors;
    }

    sendAppcuesEditProfileEvent();

    toggle();

    return null;
  };

  const initialValues = {
    workforceSize: getWorkforceSizeOptionFromEnum(workforceSize),
    [EditContractSizeFieldName.MinContractSize]: contractSizeMin
      ? {
          label: contractSizeLabelMap(contractSizeMin),
          value: contractSizeMin,
        }
      : EMPTY_CONTRACT_SIZE,
    [EditContractSizeFieldName.MaxContractSize]: contractSizeMax
      ? {
          label: contractSizeLabelMap(contractSizeMax),
          value: contractSizeMax,
        }
      : EMPTY_CONTRACT_SIZE,
  } satisfies FormFields;

  const validate = (values: FormFields): EditContractAndWorkforceSizeFormErrors => {
    const errors: EditContractAndWorkforceSizeFormErrors = {};

    const contractMin = values[EditContractSizeFieldName.MinContractSize];
    const contractMax = values[EditContractSizeFieldName.MaxContractSize];

    if (
      contractMin.value &&
      contractMax.value &&
      ContractSizeMinEnumMap[contractMin.value] >= ContractSizeMaxEnumMap[contractMax.value]
    ) {
      // eslint-disable-next-line fp/no-mutation
      errors[EditContractSizeFieldName.MinAndMaxContractSize] = [
        'Min Contract Size should be less than Max Contract Size',
      ];
    }

    return errors;
  };

  return (
    <Form<FormFields>
      keepDirtyOnReinitialize
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={(values): EditContractAndWorkforceSizeFormErrors => validate(values)}
    >
      {({ handleSubmit, submitErrors, errors, hasValidationErrors }) => (
        <form id="EDIT_CONTRACT_AND_WORKFORCE_SIZE_FORM" onSubmit={handleSubmit}>
          {error && <FormError />}
          <ContractSize submitErrors={submitErrors} errors={errors} />
          <WorkforceSizeSingleSelect />
          <ModalFooter close={toggle} hasValidationErrors={hasValidationErrors} />
        </form>
      )}
    </Form>
  );
};
