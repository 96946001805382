import { useEffect } from 'react';
import ProjectSliderGroup from './ProjectSliderGroup';
import { ModalName, useModalContext } from '../context/ModalContext';
import { EntityId } from '../../../types';

type ProjectSliderContainerProps = { projectId?: EntityId | null };

const ProjectSliderContainer = ({ projectId: initialProjectId }: ProjectSliderContainerProps) => {
  const { data, removeModal, addModal } = useModalContext<{
    projectId?: EntityId;
    target?: string;
  }>();

  useEffect(() => {
    if (initialProjectId) {
      addModal(ModalName.Project, { projectId: initialProjectId });
    }
  }, [initialProjectId]);

  const projectId = data.projectId ?? initialProjectId;
  if (typeof projectId !== 'number') {
    return null;
  }

  return <ProjectSliderGroup projectId={projectId} removeModal={removeModal} />;
};

export default ProjectSliderContainer;
