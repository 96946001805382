import { DetailsCard, DetailsCardContent, TextLink } from '@estimateone/frontend-components';
import { format } from 'date-fns';
import styles from './CompanyComplianceDetails.module.scss';
import { Image } from '@shared';
import { getQualificationDetails } from '@shared/Profiles/CompanyQualifications';
import {
  AccountType,
  QualificationLevel,
  QualificationSource,
  QualificationStatus,
  QualificationType,
} from '@ascension/_gqltypes/profiles.generated';

const CHAS_LEARN_MORE_SUBBLIER =
  'https://international.support.estimateone.com/en/articles/1743035413-chas-pre-qualification-integration-for-subcontractors';
const CHAS_LEARN_MORE_BUILDER =
  'https://international.support.estimateone.com/en/articles/1743122352-chas-pre-qualification-integration-for-contractors';

type CompanyComplianceDetailsProps = {
  qualifications: {
    source: QualificationSource;
    type: QualificationType;
    level: QualificationLevel;
    status: QualificationStatus;
    expiryDate: string;
  }[];
  accountType: AccountType;
};

const formatDate = (date: string) => format(new Date(date), 'dd MMM yyyy');

const getQualificationStatusText = (status: QualificationStatus) => {
  switch (status) {
    case QualificationStatus.INCOMPLETE:
      return 'Incomplete';
    case QualificationStatus.EXPIRED:
      return 'Expired';
    case QualificationStatus.ACTIVE:
      return 'Active';
    default:
      return '';
  }
};

const buildQualificationHeader = (
  qualification: CompanyComplianceDetailsProps['qualifications'][number],
  label: string,
) => {
  const statusText = getQualificationStatusText(qualification.status);

  const expiryText = new Date(qualification.expiryDate) < new Date() ? 'Expired' : 'Expires';

  return (
    <>
      <span className={styles.type}>{label}</span>&nbsp;
      <span>
        - Status: <span className={styles.highlight}>{`${statusText}`}</span>
        {qualification.status !== QualificationStatus.INCOMPLETE && `, ${expiryText}: `}
        {qualification.status !== QualificationStatus.INCOMPLETE && (
          <span className={styles.highlight}>{formatDate(qualification.expiryDate)}</span>
        )}
      </span>
    </>
  );
};

export const CompanyComplianceDetails = ({
  qualifications,
  accountType,
}: CompanyComplianceDetailsProps) => {
  const membership = qualifications.find(
    (q) => q.source === QualificationSource.CHAS && q.type === QualificationType.MEMBERSHIP,
  );
  const compliance = qualifications.find(
    (q) => q.source === QualificationSource.CHAS && q.type === QualificationType.COMPLIANCE,
  );

  const membershipDetails: DetailsCardContent[] = [];
  const complianceDetails: DetailsCardContent[] = [];

  if (membership) {
    const { label, badge, description } = getQualificationDetails(membership);
    membershipDetails.push({
      label: 'Membership',
      description: buildQualificationHeader(membership, label),
    });

    membershipDetails.push({
      label: '',
      description: (
        <div className={styles.qualificationDescription}>
          <div>{description}</div>
          <div>
            <Image src={badge} alt={label} />
          </div>
        </div>
      ),
    });
  }

  if (compliance) {
    const { label, badge, description } = getQualificationDetails(compliance);
    complianceDetails.push({
      label: 'Compliance',
      description: buildQualificationHeader(compliance, label),
    });
    complianceDetails.push({
      label: '',
      description: (
        <div className={styles.qualificationDescription}>
          <div>{description}</div>
          <div>
            <Image src={badge} alt={label} />
          </div>
        </div>
      ),
    });
  }

  return (
    <div className={styles.companyComplianceDetails}>
      <DetailsCard className={styles.detailsCard} contents={membershipDetails} />
      <DetailsCard className={styles.detailsCard} contents={complianceDetails} />
      <TextLink
        href={
          accountType === AccountType.BUILDER ? CHAS_LEARN_MORE_BUILDER : CHAS_LEARN_MORE_SUBBLIER
        }
        target="_blank"
        rel="noopener noreferrer"
        className={styles.companyComplianceLearnMore}
      >
        Learn more about CHAS
      </TextLink>
    </div>
  );
};
