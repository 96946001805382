import Routing from 'routing';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  HelpBanner,
  HelpBannerContent,
} from '@estimateone/frontend-components';
import styles from './Compliance.module.scss';
import { CompanyCompliance } from '@shared/Profiles/CompanyCompliance';
import { useInExperiment } from '@subbie/context/AccountProvider/hooks';
import { ProfileFieldsFragment } from '@profiles/providers/ProfileProvider/queries.generated';
import { AccountType } from '@ascension/_gqltypes/profiles.generated';
import { ExperimentName } from '@ascension/_gqltypes/subbie.generated';

type ComplianceProps = {
  qualifications: ProfileFieldsFragment['qualifications'];
};

const linkUrl = Routing.generate('app_veriforce_chas_link');

export const Compliance = ({ qualifications }: ComplianceProps) => {
  const inTrial = useInExperiment(ExperimentName.FUSION_SUBBIE_CHAS_TRIAL);

  if (!inTrial) return null;

  return (
    <div className={styles.complianceSection}>
      <div className={styles.complianceHeadingWrapper}>
        <span className={styles.complianceHeader}>Compliance</span>
      </div>
      {qualifications.length > 0 ? (
        <CompanyCompliance
          qualifications={qualifications}
          accountType={AccountType.SUBCONTRACTOR}
        />
      ) : (
        <HelpBanner isHidden={false} toggleHidden={() => {}}>
          <HelpBannerContent>
            <h3>Promote your CHAS certification in E1</h3>
            <p>
              E1 has partnered with CHAS, the UK leader in compliance and risk management. By
              connecting your CHAS accreditation data with our platform, you’ll strengthen your
              profile and be seen by more main contractors.
            </p>
            <Button
              variant={ButtonVariant.Grey}
              size={ButtonSize.Small}
              aria-label="Link CHAS account"
              onClick={() => window.location.assign(linkUrl)}
              className={styles.complianceLinkButton}
            >
              Link CHAS account
            </Button>
          </HelpBannerContent>
        </HelpBanner>
      )}
    </div>
  );
};
