import { RefObject, useCallback, useEffect, useRef } from 'react';
import Routing from 'routing';
import E1Request from '@ascension/js/classes/E1Request';
import { fetchMatrixDetails } from '@ascension/js/app/stage_matrix_details';
import { StageType, getStageTypeAsString } from '@ascension/enums';
import { DocumentMatrixInstance, EntityId } from '@ascension/types';

type UseDocumentMatrixProps = { stageId: EntityId; editable: boolean; stageType: StageType };
const loadMatrixTemplate = async ({
  stageId,
  editable,
  stageType: stageTypeString,
}: UseDocumentMatrixProps) => {
  const stageType = getStageTypeAsString(stageTypeString);
  if (!stageType) {
    throw new Error(`Unable to find stage type "${stageType}"`);
  }
  const response = await new E1Request(
    Routing.generate('app_stage_revision_flow_matrix', {
      id: stageId,
      stageType: stageType.toLowerCase(),
      editable,
    }),
  ).submit();
  return response.template;
};

export const useDocumentMatrix = (
  { stageId, editable, stageType }: UseDocumentMatrixProps,
  containerRef: RefObject<HTMLDivElement | null>,
) => {
  const docMatrixRef = useRef<DocumentMatrixInstance | undefined>(undefined);

  const loadMatrix = useCallback(async () => {
    const container = containerRef.current;
    if (container) {
      const matrixTemplate = await loadMatrixTemplate({ stageId, editable, stageType });

      if (typeof matrixTemplate === 'string') {
        // eslint-disable-next-line fp/no-mutation
        container.innerHTML = matrixTemplate;
        // eslint-disable-next-line fp/no-mutation
        docMatrixRef.current = await fetchMatrixDetails(true, false);
      }
    }
  }, [containerRef, editable, stageId, stageType]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadMatrix();

    return () => {
      docMatrixRef.current?.detachListeners();
    };
  }, [loadMatrix]);

  return docMatrixRef;
};
