import { gql } from '@apollo/client';

export const INVITE_NETWORK_CONTACTS_TO_PACKAGE = gql`
  mutation InviteNetworkContactsToPackage(
    $packageId: EntityId!
    $quotesDueAt: DateTime!
    $userIds: [ID!]!
  ) {
    inviteNetworkContactsToPackage(
      packageId: $packageId
      quotesDueAt: $quotesDueAt
      userIds: $userIds
    ) {
      ... on Package {
        id
        quotesDueAt
        rfqs {
          id
          contact {
            id
          }
        }
      }
      ... on Errors {
        errors {
          field
          message
        }
      }
    }
  }
`;

export const INVITE_BUILDER_CONTACTS_TO_PACKAGE = gql`
  mutation InviteBuilderContactsToPackage(
    $packageId: EntityId!
    $quotesDueAt: DateTime!
    $addressBookContactIds: [ID!]!
  ) {
    inviteBuilderContactsToPackage(
      packageId: $packageId
      quotesDueAt: $quotesDueAt
      addressBookContactIds: $addressBookContactIds
    ) {
      ... on Package {
        id
        quotesDueAt
        rfqs {
          id
          contact {
            id
          }
        }
      }
      ... on Errors {
        errors {
          field
          message
        }
      }
    }
  }
`;
