export enum CompleteQuoteFormSpecificFieldName {
  Package = 'quotePackage',
  Company = 'quoteCompany',
  Contact = 'quoteContact',
}

export enum CompleteQuoteFormFieldError {
  PackageEmpty = 'Please select a package',
  CompanyEmpty = 'Please select a company',
  ContactEmpty = 'Please select a contact',
}
