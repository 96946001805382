import { useEffect, useState } from 'react';
import { useWindowDimensions } from './useWindowDimensions';

const getTableHeight = (h: number) => {
  if (h >= 1200) {
    return '855px';
  }
  if (h < 1200 && h >= 1000) {
    return '655px';
  }

  return '455px';
};

export const useNotifyTableHeight = () => {
  const { height } = useWindowDimensions();
  const [tableHeight, setTableHeight] = useState(getTableHeight(height));

  useEffect(() => {
    setTableHeight(getTableHeight(height));
  }, [height]);

  return tableHeight;
};
