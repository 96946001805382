import { add, isDate, isFuture, format as formatDate, differenceInCalendarDays } from 'date-fns';
import { AddSecretFormErrors, AddSecretFormFieldName, AddSecretFormValues } from './types';

export const displayHumanReadableDate = (date: string) => formatDate(new Date(date), 'd MMM yyyy');

export const getNumberOfDaysToExpire = (expiresIn: string) =>
  differenceInCalendarDays(new Date(expiresIn), new Date());

export const isSecretActive = (expiry: string) => new Date(expiry) > new Date();

export const getTomorrowsDate = (): Date => {
  // Set today's time to noon before adding a day to prevent time shifting due to Daylight Savings Time
  const today = new Date().setHours(12, 0, 0, 0);
  return add(today, { days: 1 });
};

export const hasEmptySecretError = (errorKey: string, secret?: string) => {
  if (!secret || secret.trim().length === 0) {
    return { [errorKey]: ['A Secret must be added'] };
  }

  return {};
};

export const hasEmptyDateError = (errorKey: string, expiryDate?: Date) => {
  if (!expiryDate || !isDate(expiryDate) || !isFuture(expiryDate)) {
    return { [errorKey]: ['The expiry date for the Secret must be added'] };
  }

  return {};
};

export const validateAddSecretForm = (values: AddSecretFormValues) => {
  type Validator = (values: AddSecretFormValues) => AddSecretFormErrors;

  const hasEmptySecret: Validator = (vals: AddSecretFormValues) =>
    hasEmptySecretError(AddSecretFormFieldName.Secret, vals.secret);
  const hasEmptyDate: Validator = (vals: AddSecretFormValues) =>
    hasEmptyDateError(AddSecretFormFieldName.ExpiryDate, vals.expiryDate);

  return [hasEmptySecret, hasEmptyDate].reduce(
    (acc: AddSecretFormErrors, validate) => ({
      ...acc,
      ...validate(values),
    }),
    {},
  );
};
