import { Col, Row, Heading, E1Link, LinkVariant } from '@estimateone/frontend-components';
import ReturnLink from '../../../ReturnLink';
import OfficeLocation from '../../OfficeLocation';
import ProfilesAvatar from '../../ProfilesAvatar';
import TradeTagGroup from '../../TradeTagGroup';
import { SubbieProfileForAccount_subbieProfileForAccount as SubbieProfile } from '../../../../builder/pages/Profiles/SubbieProfilesPage/types/SubbieProfileForAccount';
import styles from './styles.scss';

type ProfilesHeaderProps = {
  profile: SubbieProfile;
  returnUrl?: string;
};

const ProfilesHeader = ({
  profile: {
    name,
    defaultOffice: { email, phone },
    accountOffices,
    trades,
  },
  returnUrl,
}: ProfilesHeaderProps) => (
  <Row>
    <Col span={6}>
      <div className={styles.returnLinkContainer}>
        <ReturnLink url={returnUrl} />
      </div>
      <Row>
        <Col span={3}>
          <ProfilesAvatar name={name} />
        </Col>
        <Col span={9}>
          <div className={styles.companyInfoContainer}>
            <Heading level={1}>{name}</Heading>
            <div className={styles.officeLocation}>
              <OfficeLocation offices={accountOffices} />
            </div>
            <div>
              <E1Link variant={LinkVariant.Primary} link={`mailto:${email}`}>
                <span className={styles.emailText}>{email}</span>
              </E1Link>
            </div>
            <div>
              <E1Link variant={LinkVariant.Primary} link={`tel:${phone}`}>
                <span className={styles.phoneText}>{phone}</span>
              </E1Link>
            </div>
          </div>
        </Col>
      </Row>
    </Col>
    <Col span={6}>
      <Row>
        <Col span={12}>{/* Placeholder for vetted logo */}</Col>
        <Col span={12} alignContentX="right">
          {/* Placeholder for Action button */}
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <div className={styles.headerSubtitle}>
            {/* Placeholder for categories */}
            {/* <Heading level={2}>Categories</Heading> */}
          </div>
        </Col>
        <Col span={6}>
          <div className={styles.headerSubtitle}>
            <Heading level={2}>Trades</Heading>
          </div>
          <TradeTagGroup trades={trades} />
        </Col>
      </Row>
    </Col>
  </Row>
);

export default ProfilesHeader;
