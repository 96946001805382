import { getDateMonthYearStringOrNull } from '@profiles/features/CompanyProfile/utils';

type MemberSinceProps = {
  memberSince?: string;
};

export const MemberSince = ({ memberSince }: MemberSinceProps) => {
  const date = getDateMonthYearStringOrNull(memberSince);
  return date ? <span>Member Since: {date} </span> : <span />;
};
