import { getUnitForms, roundMoney } from './util';

export enum CurrencySymbol {
  DOLLAR = '$',
  POUND = '£',
  YEN = '¥',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  YUAN = '¥',
}

type MoneyProps = {
  amount: number;
  currencySymbol?: CurrencySymbol;
  longUnits?: boolean;
};

const PaywallAmount = ({
  amount,
  currencySymbol = CurrencySymbol.DOLLAR,
  longUnits = false,
}: MoneyProps) => {
  const roundedAmount = roundMoney(amount);
  const unitForms = getUnitForms(amount);
  const unit = longUnits ? unitForms?.word : unitForms?.unit;
  const spaceIfLongUnit = unit && longUnits ? ' ' : '';

  return (
    <>
      {currencySymbol}
      {roundedAmount}
      {spaceIfLongUnit}
      {unit}
    </>
  );
};

export default PaywallAmount;
