import { RoadblockCopyFiftyPercentAdditionalLicense } from '../RoadblockCopy';
import {
  RoadblockHeaderAdditionalLicense,
  RoadblockHeaderFiftyPercent,
  RoadblockHeaderNationalLicense,
} from '../RoadblockHeaders';
import { RoadblockReasonFiftyPercent } from '../RoadblockReasons';
import {
  RoadblockSectionAdditionalLicense,
  RoadblockSectionPremiumPage,
} from '../RoadblockSections';

type AdditionalLicenseContentProps = {
  requireNationalLicense: boolean;
  licenseName: string;
  projectState: string;
};

const AdditionalLicenseContent = ({
  requireNationalLicense,
  licenseName,
  projectState,
}: AdditionalLicenseContentProps) => (
  <>
    <RoadblockSectionAdditionalLicense licenseName={licenseName} />
    {requireNationalLicense ? (
      <RoadblockHeaderNationalLicense />
    ) : (
      <RoadblockHeaderAdditionalLicense />
    )}
    <RoadblockCopyFiftyPercentAdditionalLicense
      requireNationalLicense={requireNationalLicense}
      projectState={projectState}
    />
  </>
);

type RoadblockFiftyPercentContentProps = {
  requireAdditionalLicense: boolean;
  requireAdditionalLicenseShortState: string;
  projectState: string;
  productName: string;
};

const RoadblockFiftyPercentContent = ({
  requireAdditionalLicense,
  requireAdditionalLicenseShortState,
  projectState,
  productName,
}: RoadblockFiftyPercentContentProps) => {
  const requireNationalLicense = requireAdditionalLicenseShortState === 'National';
  const licenseName = requireNationalLicense ? 'National' : projectState;

  return (
    <>
      <RoadblockReasonFiftyPercent productName={productName} />
      {requireAdditionalLicense ? (
        <AdditionalLicenseContent
          requireNationalLicense={requireNationalLicense}
          licenseName={licenseName}
          projectState={projectState}
        />
      ) : (
        <>
          <RoadblockSectionPremiumPage productName={productName} redactedFifty />
          <RoadblockHeaderFiftyPercent />
        </>
      )}
    </>
  );
};

export default RoadblockFiftyPercentContent;
