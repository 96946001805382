import { joinClassNames } from '@estimateone/frontend-components';
import { addWordBreakOpportunityAtChar } from '@ascension/js/utils/email';
import Link from '../../../../../../../shared/Link';
import styles from './styles.scss';

type BuilderContactDetailsProps = {
  stageName: string;
  contactName: string | null;
  contactEmail: string | null;
  contactPhone: string | null;
};

export const BuilderContactDetails = ({
  stageName,
  contactName,
  contactEmail,
  contactPhone,
}: BuilderContactDetailsProps) => {
  const emailSubject = `${encodeURIComponent('RE: ')}${encodeURIComponent(stageName)} (via E1)`;

  const hasPhone = contactPhone !== null;
  const hasEmail = contactEmail !== null;

  return (
    <div className={styles.contactDetails}>
      {hasPhone && <span className={styles.contactPhone}>{contactPhone}</span>}
      <span>{contactName ?? '-'}</span>
      {hasEmail && (
        <Link
          target="_blank"
          href={`mailto:${contactEmail}?subject=${emailSubject}`}
          extraClasses={joinClassNames('secondary', styles.builderEmail)}
        >
          {addWordBreakOpportunityAtChar(contactEmail)}
        </Link>
      )}
    </div>
  );
};
