import { gql } from '@apollo/client';
import Errors from '../../../../../api/fragments/Errors';

export const INVITE_CONTACT_TO_PACKAGE = gql`
  mutation InviteContactToPackage($input: InviteContactToPackageInput!) {
    inviteContactToPackage(input: $input) {
      __typename
      ... on Rfq {
        id
      }
      ... on Errors {
        errors {
          ...ApiError
        }
      }
    }
  }
  ${Errors.fragments.error}
`;

export const ADD_CONTACT_TO_PACKAGE = gql`
  mutation AddContactToPackage($input: AddContactToPackageInput!) {
    addContactToPackage(input: $input) {
      __typename
      ... on Rfq {
        id
      }
      ... on Errors {
        errors {
          ...ApiError
        }
      }
    }
  }
  ${Errors.fragments.error}
`;
