import { ContractSizeEnum as BuilderContractSizeEnum } from '@ascension/_gqltypes/builder.generated';
import { ContractSizeEnum as ProfilesContractSizeEnum } from '@ascension/_gqltypes/profiles.generated';

const contractSizeMap = {
  [BuilderContractSizeEnum.SIZE_10K]: '10k',
  [BuilderContractSizeEnum.SIZE_50K]: '50k',
  [BuilderContractSizeEnum.SIZE_100K]: '100k',
  [BuilderContractSizeEnum.SIZE_250K]: '250k',
  [BuilderContractSizeEnum.SIZE_500K]: '500k',
  [BuilderContractSizeEnum.SIZE_1M]: '1M',
  [BuilderContractSizeEnum.SIZE_5M]: '5M',
  [BuilderContractSizeEnum.SIZE_10M]: '10M',
  [BuilderContractSizeEnum.SIZE_25M]: '25M',
  [BuilderContractSizeEnum.SIZE_50M_PLUS]: '50M+',
};

const mapProfileToBuilderEnum = {
  [ProfilesContractSizeEnum.SIZE_10K]: BuilderContractSizeEnum.SIZE_10K,
  [ProfilesContractSizeEnum.SIZE_50K]: BuilderContractSizeEnum.SIZE_50K,
  [ProfilesContractSizeEnum.SIZE_100K]: BuilderContractSizeEnum.SIZE_100K,
  [ProfilesContractSizeEnum.SIZE_250K]: BuilderContractSizeEnum.SIZE_250K,
  [ProfilesContractSizeEnum.SIZE_500K]: BuilderContractSizeEnum.SIZE_500K,
  [ProfilesContractSizeEnum.SIZE_1M]: BuilderContractSizeEnum.SIZE_1M,
  [ProfilesContractSizeEnum.SIZE_5M]: BuilderContractSizeEnum.SIZE_5M,
  [ProfilesContractSizeEnum.SIZE_10M]: BuilderContractSizeEnum.SIZE_10M,
  [ProfilesContractSizeEnum.SIZE_25M]: BuilderContractSizeEnum.SIZE_25M,
  [ProfilesContractSizeEnum.SIZE_50M_PLUS]: BuilderContractSizeEnum.SIZE_50M_PLUS,
};

export const getLabelFromContractSizes = (
  minEnum: BuilderContractSizeEnum | null,
  maxEnum: BuilderContractSizeEnum | null,
) => {
  const minLabel = minEnum ? contractSizeMap[minEnum] : null;
  const maxLabel = maxEnum ? contractSizeMap[maxEnum] : null;

  if (minLabel && maxLabel) return `${minLabel} - ${maxLabel}`;
  if (minLabel) return `Above ${minLabel}`;
  if (maxEnum === BuilderContractSizeEnum.SIZE_50M_PLUS) return 'All';
  if (maxLabel) return `Below ${maxLabel}`;

  return '-';
};

export const getLabelFromProfilesContractSizes = (
  minValue: ProfilesContractSizeEnum | null,
  maxValue: ProfilesContractSizeEnum | null,
) => {
  const minEnum = minValue ? mapProfileToBuilderEnum[minValue] : null;
  const maxEnum = maxValue ? mapProfileToBuilderEnum[maxValue] : null;

  return getLabelFromContractSizes(minEnum, maxEnum);
};
