import { useState } from 'react';
import {
  ActionMenu,
  Button,
  ButtonVariant,
  Icon,
  IconName,
} from '@estimateone/frontend-components';
import styles from './styles.scss';

export type ScopeActionItem = {
  name: string;
  label: string;
};

export type ScopesActionMenuProps = {
  label: string;
  items: ScopeActionItem[];
  onItemClick: (actionName: string) => void;
};

export const ScopesActionMenu = ({ label, items, onItemClick }: ScopesActionMenuProps) => {
  const [isScopeMenuVisible, setIsScopeMenuVisible] = useState(false);

  return (
    <ActionMenu
      visible={isScopeMenuVisible}
      className={styles.scopesButton}
      placement="bottom-end"
      onClickOutside={() => setIsScopeMenuVisible(false)}
      triggerElement={
        <Button
          onClick={() => setIsScopeMenuVisible(true)}
          className={styles.scopesButton}
          variant={ButtonVariant.Grey}
        >
          {label}
          <Icon name={IconName.ChevronDown} marginLeft="small" size="0.6em" />
        </Button>
      }
    >
      {items.map((item) => (
        <ActionMenu.Item
          key={item.name}
          onClick={() => {
            setIsScopeMenuVisible(false);
            onItemClick(item.name);
          }}
        >
          {item.label}
        </ActionMenu.Item>
      ))}
    </ActionMenu>
  );
};
