import { useQuery } from '@apollo/client';
import { GET_DOCSYNC_LAST_SYNCED_AT } from './queries';
import {
  GetDocsyncLastSyncedAt,
  GetDocsyncLastSyncedAtVariables,
} from './types/GetDocsyncLastSyncedAt';

const POLL_INTERVAL = 5000;

export const useGetDocsyncLastSyncedAt = (stageId: number, shouldSkip: boolean) => {
  const { data, loading, error, refetch, startPolling, stopPolling } = useQuery<
    GetDocsyncLastSyncedAt,
    GetDocsyncLastSyncedAtVariables
  >(GET_DOCSYNC_LAST_SYNCED_AT, {
    variables: {
      stageId,
    },
    skip: shouldSkip,
  });

  const docsyncMap =
    data?.getDocSyncMap.__typename === 'DocSyncMap' ? data.getDocSyncMap : undefined;

  return {
    docsyncMap,
    isLoading: loading,
    hasError: error !== undefined,
    refetch,
    startPolling: () => startPolling(POLL_INTERVAL),
    stopPolling,
  };
};
