import { StockTrade, StockTradeDiscipline } from '../../types';

type StockTradesByDisciplineMap = Map<StockTradeDiscipline['name'], StockTrade[]>;

export const groupStockTradesByStockTradeDiscipline = (
  stockTrades: StockTrade[],
): StockTradesByDisciplineMap =>
  stockTrades.reduce((acc, stockTrade) => {
    const {
      discipline: { name: disciplineName },
    } = stockTrade;

    return acc.set(
      disciplineName,
      (acc.get(disciplineName) || new Array<StockTrade>()).concat([stockTrade]),
    );
  }, new Map<StockTradeDiscipline['name'], StockTrade[]>());

export const sortAlphabeticalByKeys = <K extends string, V = unknown>(m: Map<K, V>): Map<K, V> => {
  const keys = Array.from(m.keys());
  const sortedKeys = [...keys].sort((a, b) => a.localeCompare(b));

  return sortedKeys.reduce((newMap, k) => {
    const val = m.get(k);
    return val === undefined ? newMap : newMap.set(k, val);
  }, new Map<K, V>());
};
