import { useEffect, useState } from 'react';
import { DatePicker, DatePickerProps, joinClassNames } from '@estimateone/frontend-components';
import styles from './styles.scss';

export type InlineEditableDateProps = Omit<
  DatePickerProps,
  'onChange' | 'onFocus' | 'onCalendarClose' | 'selected' | 'hideLabel' | 'value'
> & {
  value: Date | undefined;
  onDateChange: (value: Date | null) => void;
  onFocus?: () => void;
};

export const InlineEditableDate = ({
  value,
  onDateChange,
  onFocus,
  className,
  ...props
}: InlineEditableDateProps) => {
  const [latestValue, setLatestValue] = useState<Date | undefined>(value);
  useEffect(() => setLatestValue(value), [value]);

  const onCalendarClose = () => {
    if (value?.getTime() !== latestValue?.getTime()) {
      const utcDate = latestValue
        ? new Date(
            Date.UTC(latestValue.getFullYear(), latestValue.getMonth(), latestValue.getDate()),
          )
        : null;
      onDateChange(utcDate);
    }
  };

  return (
    <DatePicker
      className={joinClassNames(styles.editableDate, className)}
      hideLabel
      placeholderText="Select date..."
      closeOnTabKey
      onChange={(v: Date) => setLatestValue(v)}
      onCalendarClose={onCalendarClose}
      onFocus={(event) => {
        event.target.select();
        onFocus?.();
      }}
      selected={latestValue}
      popperProps={{
        positionFixed: true,
      }}
      popperModifiers={[
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            tether: true,
          },
        },
      ]}
      {...props}
    />
  );
};
