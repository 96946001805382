import { useState } from 'react';
import { generateDownloadStageQuotesLink } from './routing';
import {
  Alert,
  AlertVariant,
  Button,
  ButtonSize,
  ButtonVariant,
  Col,
  E1Link,
  Icon,
  IconName,
  Row,
} from '@estimateone/frontend-components';
import SubbieProfilesSlider from '../../features/ProfilesFeature/SubbieProfilesSlider';
import StageQuotesTable from '../../features/QuotesFeature/StageQuotesTable';
import { showUniversalProfileDrawer } from '../Profiles/UniversalProfileDrawer';
import FileUploader, { TransformedFile } from '@shared/FileUploader';
import PageLoadingSpinner from '@shared/LoadingSpinner/PageLoadingSpinner';
import QuoteDeleteModal from '@shared/Quotes/View/QuoteDeleteModal';
import useFlashMessage from '@shared/Util/useFlashMessage';
import { useIsUniversalDrawerEnabled } from '@builder/context/AccountProvider/hooks';
import { useBuilderQuotesContext } from '@builder/context/BuilderQuotesProvider/hooks/useBuilderQuotesContext';
import { StageQuotesAndPackages_quotes as Quote } from '@builder/context/BuilderQuotesProvider/types/StageQuotesAndPackages';
import { useMarkNotificationsAsViewed } from '../../features/NotificationsFeature/hooks';
import { useQuoteFileManagerLocaleForStage } from '../../hooks/FileManager';
import { useQuoteList } from './hooks';
import { UploadCategory, UploadParentType, StageType } from '@ascension/enums';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

const EmptyStateHelpContent = () => (
  <div className={styles.emptyStateHelpContent}>
    <Row>
      <Col span="auto" alignContentX="center">
        <Icon name={IconName.EditAddFile} />
        <h2>Add quotes to your projects</h2>
        <p>
          Now you can upload and store quotes against your projects.{' '}
          <E1Link
            link="https://builder.support.estimateone.com/en/articles/1706125697-managing-quotes-through-estimateone"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </E1Link>
        </p>
      </Col>
    </Row>
  </div>
);

const StageQuotesListPage = ({
  profilesAscensionUrl,
  stageId,
  stageType,
}: {
  profilesAscensionUrl: string;
  stageId: EntityId;
  stageType: StageType;
}) => {
  const [deleteQuoteId, setDeleteQuoteId] = useState<EntityId>();
  const [uploadCount, setUploadCount] = useState(0);
  const [showUploadStatus, setShowUploadStatus] = useState(false);
  const [subbieAccountId, setSubbieAccountId] = useState<EntityId>();
  const [showSubbieProfileSlider, setShowSubbieProfileSlider] = useState(false);
  const { stageDataLoading, stageQuotes, stagePackages } = useBuilderQuotesContext();
  const fileManagerLocale = useQuoteFileManagerLocaleForStage(stageId);
  const { success: showPopupMessage } = useFlashMessage();
  const { assignPackageGetTitle, upload } = useQuoteList(stageId);
  const markNotificationsAsViewed = useMarkNotificationsAsViewed();
  const isUniversalDrawerEnabled = useIsUniversalDrawerEnabled();

  if (!fileManagerLocale || stageDataLoading) return <PageLoadingSpinner />;

  const getQuoteById = (id: keyof typeof stageQuotes) =>
    stageQuotes.find(({ id: qid }) => id === qid) as Quote;

  const handleAssignPackage = async (quoteId: EntityId, packageId: EntityId) => {
    const { title: quoteTitle, package: previousPackage } = getQuoteById(quoteId);

    const assignedPackageTitle = await assignPackageGetTitle(quoteId, packageId);

    const messagePackagePart = previousPackage
      ? `been reassigned from the ${previousPackage.title} package to the ${assignedPackageTitle} package`
      : `been assigned to the ${assignedPackageTitle} package`;

    setShowUploadStatus(false);
    showPopupMessage({
      title: `Package ${previousPackage ? 're' : ''}assigned`,
      message: `Quote ${quoteTitle} has ${messagePackagePart}`,
    });
  };

  const quotes = stageQuotes.filter(({ deletedAt }) => deletedAt === null);

  const stageHasQuotes = quotes.length !== 0;
  const handleUploadFiles = async (files: TransformedFile[]) => {
    const newFileCount = files.length;
    if (!newFileCount) return undefined;

    setUploadCount((prev) => prev + newFileCount);
    setShowUploadStatus(true);

    return upload(files);
  };

  const handleViewSubbieDetails = (
    companyId: EntityId | null = null,
    accountId: EntityId | null = null,
  ) => {
    if (isUniversalDrawerEnabled) {
      showUniversalProfileDrawer({
        accountId: accountId ?? undefined,
        companyId: companyId ?? undefined,
      });
    } else if (companyId) {
      document.dispatchEvent(
        new CustomEvent('address_book_slider_open', { detail: { companyId } }),
      );
    } else if (accountId) {
      setSubbieAccountId(accountId);
      setShowSubbieProfileSlider(true);
    }
  };

  return (
    <>
      <Row>
        <Col span="auto">
          <FileUploader
            fileManagerLocale={fileManagerLocale}
            uppyOptions={{ id: 'quote' }}
            category={UploadCategory.QUOTE}
            parentId={stageId}
            parentType={UploadParentType.STAGE_QUOTE}
            onFileUploaded={handleUploadFiles}
            beforeContent={stageHasQuotes ? undefined : <EmptyStateHelpContent />}
          />
        </Col>
      </Row>
      {showUploadStatus && (
        <Row>
          <Col span="auto">
            <Alert variant={AlertVariant.Green}>
              <div className={styles.successContent}>
                <Icon name={IconName.Check} marginRight="large" />
                <span>{uploadCount} file(s) uploaded successfully</span>
              </div>
            </Alert>
          </Col>
        </Row>
      )}
      {stageHasQuotes && (
        <>
          <Row>
            <Col span="auto" alignContentX="right">
              <Button
                variant={ButtonVariant.Secondary}
                size={ButtonSize.Medium}
                onClick={() =>
                  window.open(generateDownloadStageQuotesLink(stageId, stageType), '_blank')
                }
              >
                Download all quotes
              </Button>
            </Col>
          </Row>
          <Row>
            <Col span="auto">
              <StageQuotesTable
                stageId={stageId}
                stageType={stageType}
                stagePackages={stagePackages}
                quotes={quotes}
                onAssignPackage={handleAssignPackage}
                onRequestDelete={(id) => {
                  setShowUploadStatus(false);
                  setDeleteQuoteId(id);
                }}
                onMarkNotificationsAsViewed={markNotificationsAsViewed}
                onViewSubbieDetails={handleViewSubbieDetails}
              />
            </Col>
          </Row>
        </>
      )}
      {deleteQuoteId !== undefined && (
        <QuoteDeleteModal
          quoteId={deleteQuoteId}
          removeModal={() => setDeleteQuoteId(undefined)}
          onComplete={(deletedId) =>
            showPopupMessage({
              title: 'Quote deleted',
              message: `Quote ${getQuoteById(deletedId).title} has been deleted`,
            })
          }
        />
      )}

      {subbieAccountId !== undefined && (
        <SubbieProfilesSlider
          profilesAscensionUrl={profilesAscensionUrl}
          accountId={subbieAccountId}
          isOpen={showSubbieProfileSlider}
          onRequestClose={() => setShowSubbieProfileSlider(false)}
        />
      )}
    </>
  );
};

export default StageQuotesListPage;
