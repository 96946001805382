import { gql } from '@apollo/client';
import Errors from '../../../../api/fragments/Errors';

export const COMPLETE_QUOTE = gql`
  mutation CompleteQuote($input: CompleteBuilderQuoteInput!) {
    completeQuote(input: $input) {
      __typename
      ... on Quote {
        id
      }
      ... on Errors {
        errors {
          ...ApiError
        }
      }
    }
  }
  ${Errors.fragments.error}
`;
