import { useQuery } from '@apollo/client';
import { PACKAGES_FOR_QUOTE_COVERAGE_REPORT } from '../queries';
import { EntityId } from '../../../../../types';
import {
  GetPackagesForQuoteCoverageReport as QueryData,
  GetPackagesForQuoteCoverageReportVariables as QueryVars,
} from '../types/GetPackagesForQuoteCoverageReport';

export const useQuoteCoverageReport = (stageId: EntityId) => {
  const { data, loading, refetch } = useQuery<QueryData, QueryVars>(
    PACKAGES_FOR_QUOTE_COVERAGE_REPORT,
    {
      variables: { stageId },
    },
  );

  return {
    loading,
    packages: data?.activePackages,
    refetch,
  };
};
