import { Alert, AlertVariant, InfoIcon } from '@estimateone/frontend-components';
import styles from './ConnectedCompanyBanner.module.scss';
import { useBannerControl } from './useBannerControl';

export const ConnectedCompanyBanner = () => {
  const { showBanner, dismissBanner } = useBannerControl();
  const learnMoreUrl =
    'https://builder.support.estimateone.com/en/articles/1708035476-connecting-a-company-to-the-e1-network-faq';

  return showBanner ? (
    <Alert onDismiss={dismissBanner} variant={AlertVariant.Green} inline>
      <div className={styles.connectedBannerContainer}>
        <InfoIcon className={styles.infoIcon} />
        <span className={styles.connectedLabel}>You are now connected to this company.</span>
        <a
          className={styles.connectedLearnMoreLink}
          href={learnMoreUrl}
          target="_blank"
          rel="noreferrer"
        >
          Learn more
        </a>
      </div>
    </Alert>
  ) : null;
};
