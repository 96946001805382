import { useQuery } from '@apollo/client';
import { GET_PENDING_ADDENDUM } from '../queries';
import { GetPendingAddendum, GetPendingAddendumVariables } from '../types/GetPendingAddendum';
import { EntityId } from '@ascension/types';

export const usePendingAddendum = (stageId: EntityId) => {
  const { data, loading, refetch } = useQuery<GetPendingAddendum, GetPendingAddendumVariables>(
    GET_PENDING_ADDENDUM,
    { variables: { stageId } },
  );

  return {
    pendingAddendum: data?.pendingAddendum,
    loading,
    refetch,
  };
};
