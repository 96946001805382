/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { StatusConnectedIcon, StatusNotConnectedIcon } from '@estimateone/frontend-components';
import styles from './styles.module.scss';

type SubbieNetworkConnectionIndicatorProps = {
  connected: boolean;
};

export const SubbieNetworkConnectionIndicator = ({
  connected,
}: SubbieNetworkConnectionIndicatorProps) =>
  connected ? (
    <div data-for="connected-tooltip" data-tip className={styles.statusIcon} tabIndex={0}>
      <StatusConnectedIcon />
    </div>
  ) : (
    <div data-for="not-connected-tooltip" data-tip className={styles.statusIcon} tabIndex={0}>
      <StatusNotConnectedIcon />
    </div>
  );
