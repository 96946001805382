import { Avatar } from '@estimateone/frontend-components';
import { Label } from '@estimateone/frontend-components/src/components/Form/Label';
import styles from './PrimaryContactSingleSelect.module.scss';
import { SingleSelectField } from '@shared/FinalForm/Field/SingleSelectField';
import { ValueLabelOption } from '@estimateone/frontend-components/src/components/Form/Select/types';

type Person = {
  id: number;
  isDefaultContact: boolean;
  email: string;
  fullName: string;
  firstName: string;
  lastName?: string | null;
};

type PersonOption = ValueLabelOption<Person>;

const OptionLabel = ({ value }: PersonOption) => (
  <div className={styles.person}>
    <Avatar firstName={value.firstName} lastName={value.lastName ?? ''} userId={value.id} />
    <span>{value.fullName}</span>
    <span>{value.email}</span>
  </div>
);

export type PrimaryContactSingleSelectFieldTypes = {
  primaryContact: PersonOption;
};

type PrimaryContactSingleSelectProps = {
  people: Person[];
};

export const PrimaryContactSingleSelect = ({ people }: PrimaryContactSingleSelectProps) => (
  <div>
    <Label
      htmlFor="primaryContact"
      tooltip="The Primary Contact is listed on your Profile as the go-to contact for all new correspondence."
      isRequired
    >
      Primary Contact
    </Label>
    <SingleSelectField
      id="primaryContact-container"
      inputId="primaryContact"
      fieldName="primaryContact"
      label="Primary Contact"
      hideLabel
      options={people.map((person) => ({
        value: person,
        label: '',
      }))}
      formatOptionLabel={OptionLabel}
      styles={{
        control: (base) => ({
          ...base,
          margin: '8px 0 0',
        }),
        valueContainer: (base) => ({
          ...base,
          padding: '8px 0 8px 14px',
        }),
      }}
    />
  </div>
);
