import { useQuery } from '@apollo/client';
import { MultiSelectDropdownField } from '@shared/FinalForm/Field/MultiSelectDropdownField';
import { GET_STOCK_TRADES } from './queries';
import { GetStockTradesQuery } from './queries.generated';
import { EntityId } from '@ascension/types';
import { ValueLabelOption } from '@estimateone/frontend-components/src/components/Form/Select/types';

export type StockTradesFieldTypes = {
  stockTrades: ValueLabelOption<EntityId>[];
};

export const StockTradesMultiSelect = () => {
  const { data } = useQuery<GetStockTradesQuery>(GET_STOCK_TRADES);
  const tradeOptions = data?.stockTrades.map(({ id, name }) => ({ value: id, label: name }));

  return (
    <MultiSelectDropdownField
      id="stockTrades"
      inputId="stockTrades"
      label="Your Trades"
      fieldName="stockTrades"
      options={tradeOptions}
      isRequired
      placeholder="Start typing a Trade name..."
      isMulti
    />
  );
};
