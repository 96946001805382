import { useState } from 'react';
import Routing from 'routing';
import { Button, ButtonVariant, LoadingSpinner } from '@estimateone/frontend-components';
import { RevisionFlow } from '@builder/features/Documents/RevisionFlow';
import { EmptyTableGuide, PackageNoDocsImage } from '../EmptyTableGuide';
import { useGetDocumentIntegration } from '@builder/common/DocumentIntegration/hooks/useGetDocumentIntegration';
import { usePendingAddendum } from '@builder/features/Documents/RevisionFlow/hooks';
import { StageType } from '@ascension/enums';
import { StageDocumentIntegrationType } from '@ascension/_gqltypes/builder.generated';
import { EntityId } from '@ascension/types';

export type PackageNoDocsProps = {
  stageId: EntityId;
  hasDocumentIntegration: boolean;
};

const getDocMatrixUrl = (stageId: number) =>
  Routing.generate('app_stage_matrix', {
    id: stageId,
    stageType: 'procurement',
  });

export const PackageNoDocs = ({ stageId, hasDocumentIntegration }: PackageNoDocsProps) => {
  const { pendingAddendum, loading: isPendingAddendumLoading } = usePendingAddendum(stageId);
  const { documentIntegration, loading: isDocumentIntegrationLoading } = useGetDocumentIntegration(
    stageId,
    !hasDocumentIntegration,
  );
  const [isRevisionFlowModalOpen, setIsRevisionModalOpen] = useState(false);

  const loading = isPendingAddendumLoading || isDocumentIntegrationLoading;

  const openRevisionFlowModal = () => {
    setIsRevisionModalOpen(true);
  };
  const closeRevisionFlowModal = async () => {
    setIsRevisionModalOpen(false);
  };
  const handleRevisionFlowCompletion = async () => {
    window.location.reload();
  };

  const shouldShowCreatePackagesButton = !!(hasDocumentIntegration && pendingAddendum?.id);

  return (
    <>
      <EmptyTableGuide
        image={<PackageNoDocsImage />}
        heading="This package has no documents"
        descriptions={['Assign documents to this package in the Document Matrix']}
        button={
          loading ? (
            <LoadingSpinner />
          ) : shouldShowCreatePackagesButton ? (
            <Button variant={ButtonVariant.Primary} onClick={openRevisionFlowModal}>
              Create Packages
            </Button>
          ) : (
            <Button variant={ButtonVariant.Primary} href={getDocMatrixUrl(stageId)}>
              Go to the Document Matrix
            </Button>
          )
        }
      />
      {pendingAddendum ? (
        <RevisionFlow
          stageId={stageId}
          stageType={StageType.TYPE_PROCUREMENT}
          documentIntegrationType={
            documentIntegration?.type ?? StageDocumentIntegrationType.PROCORE
          }
          addendum={pendingAddendum}
          isOpen={shouldShowCreatePackagesButton && isRevisionFlowModalOpen}
          onClose={closeRevisionFlowModal}
          onCompletion={handleRevisionFlowCompletion}
        />
      ) : (
        false
      )}
    </>
  );
};
