import { useField } from 'react-final-form';
import { Fieldset, FieldWrapper, Select } from '@estimateone/frontend-components';
import { ContractSizeValueOption, SubbieNetworkFilterFormFields } from '../types';
import { ContractSizeMaxEnum, ContractSizeMinEnum } from '@ascension/_gqltypes/builder.generated';
import styles from './styles.module.scss';

export const minOptions: ContractSizeValueOption<ContractSizeMinEnum>[] = [
  { value: ContractSizeMinEnum.SIZE_0, label: '0', monetaryValue: 0 },
  { value: ContractSizeMinEnum.SIZE_10k, label: '10k', monetaryValue: 10000 },
  { value: ContractSizeMinEnum.SIZE_50k, label: '50k', monetaryValue: 50000 },
  { value: ContractSizeMinEnum.SIZE_100k, label: '100k', monetaryValue: 100000 },
  { value: ContractSizeMinEnum.SIZE_250k, label: '250k', monetaryValue: 250000 },
  { value: ContractSizeMinEnum.SIZE_500k, label: '500k', monetaryValue: 500000 },
  { value: ContractSizeMinEnum.SIZE_1M, label: '1M', monetaryValue: 1000000 },
  { value: ContractSizeMinEnum.SIZE_5M, label: '5M', monetaryValue: 5000000 },
  { value: ContractSizeMinEnum.SIZE_10M, label: '10M', monetaryValue: 10000000 },
  { value: ContractSizeMinEnum.SIZE_25M, label: '25M', monetaryValue: 25000000 },
  { value: ContractSizeMinEnum.SIZE_50M_PLUS, label: '50M+', monetaryValue: 50000000 },
];

export const maxOptions: ContractSizeValueOption<ContractSizeMaxEnum>[] = [
  { value: ContractSizeMaxEnum.SIZE_10k, label: '10k', monetaryValue: 10000 },
  { value: ContractSizeMaxEnum.SIZE_50k, label: '50k', monetaryValue: 50000 },
  { value: ContractSizeMaxEnum.SIZE_100k, label: '100k', monetaryValue: 100000 },
  { value: ContractSizeMaxEnum.SIZE_250k, label: '250k', monetaryValue: 250000 },
  { value: ContractSizeMaxEnum.SIZE_500k, label: '500k', monetaryValue: 500000 },
  { value: ContractSizeMaxEnum.SIZE_1M, label: '1M', monetaryValue: 1000000 },
  { value: ContractSizeMaxEnum.SIZE_5M, label: '5M', monetaryValue: 5000000 },
  { value: ContractSizeMaxEnum.SIZE_10M, label: '10M', monetaryValue: 10000000 },
  { value: ContractSizeMaxEnum.SIZE_25M, label: '25M', monetaryValue: 25000000 },
  { value: ContractSizeMaxEnum.SIZE_50M_PLUS, label: '50M+', monetaryValue: 50000000 },
];

export const ContractSizeFilter = () => {
  const minField = useField(SubbieNetworkFilterFormFields.ContractSizeMin, {
    allowNull: true,
  });
  const maxField = useField(SubbieNetworkFilterFormFields.ContractSizeMax, {
    allowNull: true,
  });

  const errors = [...(minField.meta.error ?? []), ...(maxField.meta.error ?? [])].flat();

  return (
    <FieldWrapper errors={errors}>
      <Fieldset
        className={styles.contractSizeWrapper}
        legend="Contract size range inputs"
        hideLegend
        showChildErrorStyles
      >
        <div>
          <Select<ContractSizeValueOption<ContractSizeMinEnum>>
            id={SubbieNetworkFilterFormFields.ContractSizeMin}
            inputId={SubbieNetworkFilterFormFields.ContractSizeMin}
            label="Minimum"
            options={minOptions}
            isClearable
            {...minField.input}
          />
        </div>
        <div>
          <Select<ContractSizeValueOption<ContractSizeMaxEnum>>
            id={SubbieNetworkFilterFormFields.ContractSizeMax}
            inputId={SubbieNetworkFilterFormFields.ContractSizeMax}
            label="Maximum"
            options={maxOptions}
            isClearable
            {...maxField.input}
          />
        </div>
      </Fieldset>
    </FieldWrapper>
  );
};
