import Routing from 'routing';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  Icon,
  IconName,
  Tooltip,
} from '@estimateone/frontend-components';
import { StageRow, WithdrawnStageRow } from './StageRow';
import { sortStages } from './sortStages';
import { LangRef, getTranslation } from '@shared/Util/lang';
import { useKeywordsForStageMap } from './hooks/useKeywordsByStage';
import { QuoteReturnRoute, StageStatus } from '@ascension/enums';
import { GetProjectSliderData_projectForSlider_project as Project } from '@subbie/modal/types/GetProjectSliderData';
import style from '../styles.scss';

type ViewableNoticeboardProject = Extract<Project, { __typename: 'ViewableNoticeboardProject' }>;

type ProjectStagesProps = {
  project: ViewableNoticeboardProject;
};
const ProjectStages = ({ project }: ProjectStagesProps) => {
  const { stages: projectStages, id: projectId } = project;
  const keywordsStageMap = useKeywordsForStageMap(projectId);

  const publicStagesWithKeywords = projectStages
    .filter(({ private: isPrivate }) => !isPrivate)
    .map((stage) => ({
      ...stage,
      keywords: keywordsStageMap.get(stage.id),
    }));

  publicStagesWithKeywords.sort(sortStages);

  const canQuote = projectStages.some(
    (stage) => stage.builderDetails.__typename !== 'HiddenBuilderDetails',
  );

  return (
    <div id="project-stages" className={style.projectSection}>
      <div className="d-flex mb-2 justify-content-between align-items-center">
        <h3>{getTranslation(LangRef.BUILDERS_HEADING)} &amp; Documents</h3>
        {!canQuote && (
          <Tooltip
            tooltipId="quoting-disabled-tip"
            text="You must request documents from at least one builder so that you can add a quote"
          />
        )}
        <Button
          data-tip={!canQuote || undefined}
          data-for={!canQuote ? 'quoting-disabled-tip' : undefined}
          size={ButtonSize.Medium}
          variant={ButtonVariant.Secondary}
          href={
            canQuote
              ? Routing.generate('app_quote_addtoproject', {
                  projectId,
                  returnTo: QuoteReturnRoute.ProjectSliderMultiStage,
                })
              : undefined
          }
          disabled={!canQuote}
        >
          <Icon name={IconName.Upload} marginRight="small" />
          Add quotes
        </Button>
      </div>
      <div className="d-flex flex-wrap justify-content-between">
        <table className="table table-data-align-top">
          <thead>
            <tr>
              <th>{getTranslation(LangRef.BUILDER_HEADING)}</th>
              <th>Key Dates</th>
              <th>Project Documents</th>
            </tr>
          </thead>
          <tbody>
            {publicStagesWithKeywords.map((stage) =>
              stage.status === StageStatus.TENDER_WITHDRAWN ? (
                <WithdrawnStageRow key={stage.id} stage={stage} />
              ) : (
                <StageRow
                  key={stage.id}
                  stage={stage}
                  projectId={projectId}
                  keywords={stage.keywords}
                />
              ),
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProjectStages;
