import { BillingCycle } from '@ascension/enums';
import {
  GetAddons_addons as Addon,
  GetAddons_addons_pricingOptions as PricingOption,
} from './types/GetAddons';
import { ProductWithPrice } from '@subbie/features/AccountUpgradeFeature/types';

const transformAddonForCart = (
  addon: Addon,
  billingCycle: BillingCycle,
): ProductWithPrice | null => {
  const pricingOption: PricingOption | undefined = addon.pricingOptions.find(
    (option: PricingOption): boolean => option.billingCycle === billingCycle,
  );

  return pricingOption
    ? {
        id: addon.id,
        name: addon.name,
        price: pricingOption.price.amount,
        priceExGST: pricingOption.priceExGST.amount,
        billingCycle: pricingOption.billingCycle,
        shortDescription: addon.shortDescription ?? '',
        longDescription: addon.longDescription,
        isPurchased: addon.isPurchased,
        isAddon: addon.isAddon,
      }
    : null;
};

const filterAddonsByProductBillingCycle = (
  product: ProductWithPrice,
  addons?: ProductWithPrice[],
): ProductWithPrice[] =>
  addons?.filter((addon: ProductWithPrice) => addon.billingCycle === product.billingCycle) ?? [];

const isAddonInCart = (addon: ProductWithPrice, cart: ProductWithPrice[]): boolean =>
  !!cart.find(({ id }: ProductWithPrice) => id === addon.id);

export { transformAddonForCart, filterAddonsByProductBillingCycle, isAddonInCart };
