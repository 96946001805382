import { useCallback, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { Slider, Stepper, getStatus } from '@estimateone/frontend-components';
import { FlowEvent, getAppcues } from '@ascension/components/helpers/Appcues';
import { reportError } from '@ascension/components/helpers/errorReporter';
import { useScopeOfWorks } from '@scope-of-works/providers/ScopeOfWorksProvider';
import { InvitationPane } from './InvitationPane';
import { PersonalisationPane } from './PersonalisationPane';
import { transformFormApiErrors } from '@shared/FinalForm/transformers';
import useFlashMessage from '@shared/Util/useFlashMessage';
import { useInviteSubbieToPackage, useAddSubbieToPackage } from './hooks';
import { useCreateScopeRfq } from './hooks/useCreateScopeRfq';
import { useGetInviteToQuoteStage } from './hooks/useGetInviteToQuoteStage';
import { InviteToQuoteFormFieldName, InviteToQuoteFieldNameMap } from './enums';
import { ListenerEvent } from '@ascension/enums';
import { InviteToQuoteFormValues } from './types';
import { GetAddressBookCompany_company as CompanySelectOption } from './types/GetAddressBookCompany';
import { GetInviteToQuoteStage_stage_UnrestrictedStage as Stage } from './types/GetInviteToQuoteStage';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

const FORM_ID = 'invite-to-quote-form';

export type InviteToQuoteFormProps = {
  stageId: EntityId | undefined;
  onFormSuccess: (addedRfqId: EntityId) => void;
  preselectedPackageId: EntityId | undefined;
  preselectedCompany: CompanySelectOption | undefined;
};

export const InviteToQuoteForm = ({
  stageId,
  onFormSuccess,
  preselectedPackageId,
  preselectedCompany,
}: InviteToQuoteFormProps) => {
  const [currentPane, setCurrentPane] = useState<number>(1);
  const [stage, setStage] = useState<Stage>();

  const { getInviteToQuoteStage, loading: loadingStage } = useGetInviteToQuoteStage();

  const { submit: submitInvite } = useInviteSubbieToPackage();
  const { submit: submitAdd } = useAddSubbieToPackage();
  const { success: showSuccessMessage } = useFlashMessage();
  const { createScopeRfq } = useCreateScopeRfq();
  const { refetch: refetchScopeOfWorks } = useScopeOfWorks();

  const initialValues = useMemo(
    () => ({
      [InviteToQuoteFormFieldName.SendEmail]: true,
      [InviteToQuoteFormFieldName.IncludeScopeOfWorks]: true,
      [InviteToQuoteFormFieldName.Company]: preselectedCompany,
    }),
    [preselectedCompany],
  );

  const fetchStage = useCallback(
    async (id: EntityId) => {
      const response = await getInviteToQuoteStage({ variables: { stageId: id } });
      setStage(
        response.data?.stage?.__typename === 'UnrestrictedStage' ? response.data.stage : undefined,
      );
    },
    [getInviteToQuoteStage],
  );

  useEffect(() => {
    if (stageId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchStage(stageId);
    }
  }, [fetchStage, stageId]);

  const preselectedPackage = useMemo(() => {
    if (stage && preselectedPackageId) {
      return stage.activePackages.find(
        (activePackage) => activePackage.value === preselectedPackageId,
      );
    }
    return undefined;
  }, [preselectedPackageId, stage]);

  const handleInviteToResponses = async (values: InviteToQuoteFormValues) =>
    submitInvite(
      values.package.value,
      values.contact.contactDetail.id,
      values.dueDate,
      values.personalisedMessage ?? null,
      values.includeScopeOfWorks ?? false,
      values.correspondenceType?.value,
    );

  const handleAddToResponses = async (values: InviteToQuoteFormValues) =>
    submitAdd(values.package.value, values.contact.contactDetail.id, values.dueDate);

  const handleOnSubmit = async (values: InviteToQuoteFormValues) => {
    if (!stage) return undefined;

    if (currentPane === 1) {
      return setCurrentPane(2);
    }

    const appCues = getAppcues();
    if (appCues?.track) {
      appCues.track(FlowEvent.CLARIFY_INVITE_SINGLE_PROCUREMENT_SEND_INVITE_CLICK);
    }

    const { data, errors } = values.sendEmail
      ? await handleInviteToResponses(values)
      : await handleAddToResponses(values);

    if (errors) {
      setCurrentPane(1);

      return transformFormApiErrors(InviteToQuoteFieldNameMap, errors);
    }

    if (!data) return undefined;

    if (values.includeScopeOfWorks && values.scopeOfWorks && values.contact.contactDetail.userId) {
      createScopeRfq({
        scopeId: values.scopeOfWorks.id,
        rfqRecipientId: values.contact.contactDetail.userId.toString(),
        rfqId: data.id.toString(),
      })
        .then(() => refetchScopeOfWorks())
        .catch(reportError);
    }

    showSuccessMessage({
      title: 'Success',
      message: 'Invitation was successfully sent',
    });

    document.dispatchEvent(new CustomEvent(ListenerEvent.InviteToQuoteSuccess));

    onFormSuccess(data?.id);

    return undefined;
  };

  const getStatusForStep = (stepNumber: number) => getStatus(stepNumber, currentPane);

  return (
    <Form<InviteToQuoteFormValues>
      onSubmit={handleOnSubmit}
      keepDirtyOnReinitialize
      initialValues={initialValues}
    >
      {({ handleSubmit }) => (
        <form
          id={FORM_ID}
          name={FORM_ID}
          onSubmit={handleSubmit}
          className={styles.inviteToQuoteForm}
        >
          <Slider.Header variant="secondary" className={styles.stepperHeader}>
            <Stepper>
              <Stepper.Step
                stepNumber={1}
                label="Details"
                status={getStatusForStep(1)}
                onClick={() => setCurrentPane(1)}
              />
              <Stepper.Step stepNumber={2} label="Invitation" status={getStatusForStep(2)} />
            </Stepper>
          </Slider.Header>
          {currentPane === 1 ? (
            <InvitationPane
              stage={stage}
              loadingStage={loadingStage}
              fetchStage={!stageId ? fetchStage : undefined}
              preselectedPackage={preselectedPackage}
              preselectedCompany={preselectedCompany}
            />
          ) : (
            <PersonalisationPane stage={stage} onBack={() => setCurrentPane(1)} />
          )}
        </form>
      )}
    </Form>
  );
};
