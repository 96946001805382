import { useEffect } from 'react';
import { DrawerBody, DrawerToolbar, joinClassNames } from '@estimateone/frontend-components';
import { DefaultOperatingCountryProvider } from '@ascension/components/localisation/DefaultOperatingCountryProvider';
import {
  DrawerEvent,
  UNIVERSAL_PROFILE_DRAWER_READY_EVENT,
  UNIVERSAL_PROFILE_DRAWER_TAB_CONTAINER_ID,
} from '@builder/pages/Profiles/UniversalProfileDrawer';
import {
  AccountStatusColumn,
  CompanyHeader,
  CompanyInfo,
  CompanyNameLocation,
} from '@builder/pages/Profiles/UniversalProfileDrawer/components/DrawerHeader';
import { DrawerLoadingSpinner } from '@builder/pages/Profiles/UniversalProfileDrawer/components/DrawerLoadingSpinner';
import { AccountTypeTag } from './components/AccountTypeTag';
import { CompanyLogo } from './components/CompanyLogo';
import { CompanyNameHeader } from './components/CompanyNameHeader';
import { MatchingAddressBookCompanyInfoBanner } from './components/MatchingAddressBookCompanyInfoBanner';
import { E1NetworkCompanyToolbar } from './components/Toolbars/E1NetworkCompanyToolbar';
import { UniversalNetworkProfile } from './providers/UniversalProfileProvider';
import { useIsNetworkConnectionsEnabled } from '@builder/context/AccountProvider/hooks';
import { useCompanyLogoURL } from './providers/UniversalProfileProvider/hooks';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

export const E1NetworkCompanyDrawerContent = ({
  universalProfile,
  canViewAddressBook,
  onConnectSuccess,
}: {
  readonly universalProfile: UniversalNetworkProfile;
  readonly canViewAddressBook: boolean;
  readonly onConnectSuccess?: (companyId: EntityId) => void;
}) => {
  const isNetworkConnectionsEnabled = useIsNetworkConnectionsEnabled();
  const url = useCompanyLogoURL();

  const { profile } = universalProfile;
  const profileId = universalProfile?.profile.id;
  const accountId = universalProfile?.profile.account?.id;
  const accountType = profile.account?.type;
  const defaultOffice = profile?.offices.find(({ isDefaultOffice }) => isDefaultOffice);
  const companyName = profile?.name ?? '';
  const shortAddress = defaultOffice?.address.shortAddress;

  // tell Jquery that it's time to render the body into the specified div
  useEffect(() => {
    if (profileId || accountId) {
      // This event will cause the jQuery code in vcard_slider.js to make a call to Ascension to
      // render the twig template with the tab container, and then inject that rendered HTML into
      // the container below. It needs to happen after this component has finished rendering.
      const eventData: DrawerEvent = {
        detail: {
          profileId,
          accountId,
        },
      };
      document.dispatchEvent(new CustomEvent(UNIVERSAL_PROFILE_DRAWER_READY_EVENT, eventData));
    }
  }, [profileId, accountId]);

  return (
    <>
      <DrawerToolbar>
        <E1NetworkCompanyToolbar
          canViewAddressBook={canViewAddressBook}
          profileId={profileId}
          accountId={accountId}
          onConnectSuccess={onConnectSuccess}
        />
      </DrawerToolbar>
      <DrawerBody className={joinClassNames(styles.noPadding, styles.drawerBodyContainer)}>
        {isNetworkConnectionsEnabled && accountId && (
          <DefaultOperatingCountryProvider>
            <MatchingAddressBookCompanyInfoBanner accountId={accountId} />
          </DefaultOperatingCountryProvider>
        )}
        <CompanyHeader>
          <CompanyLogo url={url ?? null} companyName={companyName} />
          <CompanyInfo>
            <CompanyNameLocation>
              <CompanyNameHeader companyName={companyName} connected={false} />
              {shortAddress ? <div>{shortAddress}</div> : null}
            </CompanyNameLocation>
            <AccountStatusColumn>
              {accountType ? <AccountTypeTag accountType={accountType} /> : null}
            </AccountStatusColumn>
          </CompanyInfo>
        </CompanyHeader>
        <div
          data-testid={UNIVERSAL_PROFILE_DRAWER_TAB_CONTAINER_ID}
          id={UNIVERSAL_PROFILE_DRAWER_TAB_CONTAINER_ID}
          data-profile-id={profileId}
          data-account-id={accountId}
          className={styles.drawerTabContainer}
        >
          <DrawerLoadingSpinner />
        </div>
      </DrawerBody>
    </>
  );
};
