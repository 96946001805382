import { captureMessage } from '@sentry/browser';
import moment from 'moment-timezone';

export const getDateOrDefault = (dateString: GraphQLDateTime, defaultDate: Date): Date => {
  const date = moment(dateString);

  if (dateString && date.isValid()) {
    return moment(dateString).toDate();
  }

  if (dateString) {
    captureMessage('Unable to convert string to date', {
      level: 'error',
      extra: { dateString },
    });
  }

  return defaultDate;
};
