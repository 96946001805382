import { useMutation } from '@apollo/client';
import { NotifyPreferences } from '../steps';
import { FINALISE_MATRIX_CHANGE } from '../mutations';
import { FinaliseMatrixChange, FinaliseMatrixChangeVariables } from '../types/FinaliseMatrixChange';
import { EntityId } from '@ascension/types';

type SubmitArgs = {
  stageId: EntityId;
  addendumId?: EntityId;
  selectedPackages: EntityId[];
  title: string;
  message: string;
  notifyPreferences: NotifyPreferences;
};

export const useFinaliseMatrix = () => {
  const [finaliseMatrixChange] = useMutation<FinaliseMatrixChange, FinaliseMatrixChangeVariables>(
    FINALISE_MATRIX_CHANGE,
  );

  const submit = async ({
    stageId,
    selectedPackages,
    title,
    message,
    addendumId,
    notifyPreferences,
  }: SubmitArgs) => {
    const { data } = await finaliseMatrixChange({
      variables: {
        input: {
          stageId,
          addendumId,
          selectedPackages,
          title,
          message,
          ...notifyPreferences,
        },
      },
    });

    return data?.finaliseMatrixChange;
  };

  return { submit };
};
