import { useState } from 'react';
import { Action, useInteractAnalytics } from '@ascension/components/hooks/Analytics';
import { BillingCycle } from '@ascension/enums';

export const useLiteToEssentialAnalytics = () => {
  const [upgradeStartTriggered, setUpgradeStartTriggered] = useState(false);
  const { addEvent } = useInteractAnalytics();

  const triggerLiteToEssentialUpgradeStart = (isOpen: boolean) => {
    if (isOpen && !upgradeStartTriggered) {
      addEvent({ action: Action.UPGRADE_START });
      setUpgradeStartTriggered(true);
    }
  };

  const triggerLiteToEssentialPayButtonClicked = (billingCycle: BillingCycle) =>
    addEvent({
      action: Action.UPGRADE_CHARGEBEE_LICENSE,
      billingCycle,
    });

  return { triggerLiteToEssentialUpgradeStart, triggerLiteToEssentialPayButtonClicked };
};
