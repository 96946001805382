import { ReactNode } from 'react';
import styles from './ProfileBadgeIconWrapper.module.scss';

export type ProfileBadgeStatus = 'info' | 'error';

export type ProfileBadgeIconWrapperProps = {
  status: ProfileBadgeStatus;
  content: ReactNode;
  className?: string;
};

export const ProfileBadgeIconWrapper = ({
  status,
  content,
  className,
}: ProfileBadgeIconWrapperProps) => (
  <div
    className={[className, styles.profileBadgeIconWrapper, styles[status]].join(' ')}
    data-testid="profile-badge-card-icon"
  >
    {content}
  </div>
);
