import { GroupBase, OptionsOrGroups } from 'react-select';
import { OptionValue } from '@estimateone/frontend-components';
import { LabelledOption } from '@shared/Select';
import { GetPackagesForExport_activePackages as Package } from '../types/GetPackagesForExport';

type PackageWithOrderIndex = {
  orderIndex: number;
  packages: Package[];
};

const defaultDiscipline = { name: '-', orderIndex: -1 };

const appendPackageToDiscipline = (
  currentPackage: Package,
  disciplines: Map<string, PackageWithOrderIndex>,
) => {
  const { name, orderIndex } = currentPackage.discipline ?? defaultDiscipline;
  const targetDiscipline = disciplines.get(name);
  if (!targetDiscipline) {
    disciplines.set(name, { orderIndex, packages: [currentPackage] });
  } else {
    targetDiscipline.packages.push(currentPackage);
  }
};

export const groupPackagesByDiscipline = (
  activePackages?: Package[],
): OptionsOrGroups<LabelledOption<OptionValue>, GroupBase<LabelledOption<OptionValue>>> => {
  const disciplineGroups = activePackages?.reduce((disciplines, currentPackage: Package) => {
    if (!currentPackage.fullSet) {
      appendPackageToDiscipline(currentPackage, disciplines);
    }
    return disciplines;
  }, new Map<string, PackageWithOrderIndex>());

  const sortedDisciplines = Array.from(disciplineGroups ?? []).sort(
    (a, b) => a[1].orderIndex - b[1].orderIndex,
  );

  const optionsOrGroups = sortedDisciplines.map(([key, value]) => ({
    label: key,
    options: value.packages
      .sort((a, b) => a.title.localeCompare(b.title))
      .map((p) => ({
        label: p.title,
        value: p.id,
      })),
  }));

  return optionsOrGroups;
};
