import { SingleSelectField } from '@shared/FinalForm/Field/SingleSelectField';
import { SubbieNetworkFilterFormFields } from '../types';
import { ValueLabelOption } from '@estimateone/frontend-components/src/components/Form/Select/types';

type CompanyListFilterProps = {
  companyTagOptions?: ValueLabelOption<number>[] | null;
};

export const CompanyListFilter = ({ companyTagOptions }: CompanyListFilterProps) => {
  if (!companyTagOptions) {
    return null;
  }
  return (
    <section data-testid="drawer-filter-company-list">
      <SingleSelectField
        id="drawer-filter-company-list"
        inputId="drawer-filter-company-list"
        fieldName={SubbieNetworkFilterFormFields.CompanyListFilterId}
        label=""
        hideLabel
        options={companyTagOptions}
        isClearable
        menuPlacement="top"
      />
    </section>
  );
};
