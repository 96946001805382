import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { GET_NETWORK_INVITES_STAGE } from './queries';
import {
  GetNetworkInvitesStage,
  GetNetworkInvitesStageVariables,
} from './types/GetNetworkInvitesStage';
import { EntityId } from '@ascension/types';
import { Package } from '@builder/features/SubbieNetworkInvitations/types';

const orderPackagesByFullset = (packageA: Package, packageB: Package): number => {
  if (packageA.fullSet && !packageB.fullSet) {
    return 1;
  }
  if (!packageA.fullSet && packageB.fullSet) {
    return -1;
  }
  return 0;
};

export const useGetStageForNetworkInvites = (stageId: EntityId) => {
  const { data, loading } = useQuery<GetNetworkInvitesStage, GetNetworkInvitesStageVariables>(
    GET_NETWORK_INVITES_STAGE,
    {
      fetchPolicy: 'cache-first',
      variables: {
        stageId,
      },
    },
  );

  const stage = useMemo(() => {
    if (data?.stage?.__typename === 'UnrestrictedStage') {
      return {
        ...data.stage,
        activePackages: [...data.stage.activePackages].sort(orderPackagesByFullset),
      };
    }
    return undefined;
  }, [data?.stage]);

  return {
    stage,
    loading,
  };
};
