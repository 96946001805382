import { useAvailablePlugins } from '@builder/features/DocSyncIntegration/DocumentIntegrationProvider';
import { EntityId } from '@ascension/types';

type DocumentIntegrationConnectSliderProps = {
  stageId: EntityId;
  selectedDocSyncSlider: string | null;
  onConfirmConnection: () => void;
};

export const DocumentIntegrationConnectSlider = ({
  stageId,
  selectedDocSyncSlider,
  onConfirmConnection,
}: DocumentIntegrationConnectSliderProps) => {
  const availablePlugins = useAvailablePlugins();

  if (availablePlugins.length === 0) {
    return false;
  }

  const selectedPlugin = availablePlugins.find((plugin) => plugin.id === selectedDocSyncSlider);

  if (!selectedPlugin) {
    return false;
  }

  return (
    <selectedPlugin.Slider stageId={stageId} isOpen onConfirmConnection={onConfirmConnection} />
  );
};
