import {
  Button,
  ButtonSize,
  ButtonVariant,
  HelpBanner,
  HelpBannerContent,
  HelpBannerToggle,
  usePersistentIsHidden,
} from '@estimateone/frontend-components';
import { useKnowledgeBaseArticle } from '@ascension/components/localisation';
import styles from './LearnMoreBanner.module.scss';

type LearnMoreBannerProps = {
  userId: number;
};

export const LearnMoreBanner = ({ userId }: LearnMoreBannerProps) => {
  const { isHidden, toggleHidden } = usePersistentIsHidden(
    `connection-suggestions-learn-more-banner-${userId.toString()}`,
  );

  const knowledgeBaseArticle = useKnowledgeBaseArticle();

  return (
    <HelpBanner isHidden={isHidden} toggleHidden={toggleHidden} data-testid="helpBanner">
      <HelpBannerContent className={styles.helpBanner}>
        <p className={styles.helpBannerTitle}>🧠 Create a smarter Address Book by connecting</p>
        <p>
          Keep your Address Book enriched and up-to-date by Connecting to the E1 Network. Existing
          details will be retained and private information won&apos;t be shared outside of your
          organisation. We are continually searching for new suggestions - make sure to check back
          regularly!
        </p>
        <div className={styles.helpBannerFooter}>
          <Button
            href={knowledgeBaseArticle('connectionSuggestionsHelp')}
            variant={ButtonVariant.Transparent}
            size={ButtonSize.Small}
            className={styles.learnMoreButton}
            target="_blank"
          >
            Learn more
          </Button>
          <HelpBannerToggle variant={ButtonVariant.Grey} size={ButtonSize.Small}>
            Ok, got it!
          </HelpBannerToggle>
        </div>
      </HelpBannerContent>
    </HelpBanner>
  );
};
