import { E1Link } from '@estimateone/frontend-components';
import { Pricing, PricingVariant } from '@ascension/components/fec';
import { generateQuoteLinkFromRfq } from './utils';
import { QuoteReturnRoute } from '@ascension/enums';
import { RfqQuotingStatus } from '@ascension/_gqltypes/builder.generated';
import { EntityId } from '@ascension/types';

export type LatestQuote = {
  id: EntityId;
  amount: number | null;
};

export type Rfq = {
  quotingStatus: RfqQuotingStatus | null;
  latestQuote: LatestQuote | null;
};

export type QuoteAmountProps = {
  rfq: Rfq;
  returnTo: QuoteReturnRoute;
  openInNewTab?: boolean;
};

export const QuoteAmount = ({ rfq, returnTo, openInNewTab = false }: QuoteAmountProps) => {
  const amount = rfq.latestQuote?.amount ?? null;

  if (amount === null || rfq.quotingStatus !== RfqQuotingStatus.QUOTED) {
    return null;
  }

  const quoteLink = generateQuoteLinkFromRfq(rfq, returnTo);
  return quoteLink ? (
    <E1Link target={openInNewTab ? '_blank' : '_self'} href={quoteLink}>
      <Pricing price={amount} variant={PricingVariant.Spaced} />
    </E1Link>
  ) : (
    <Pricing price={amount} variant={PricingVariant.Spaced} />
  );
};
