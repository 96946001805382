import { gql, useApolloClient, useMutation } from '@apollo/client';
import { useCompanyProfileCacheItemId } from '../../../../../../providers/ProfileProvider';
import { CHANGE_COMPANY_NAME } from './mutations';
import {
  ChangeAccountNameMutation,
  ChangeAccountNameMutationVariables,
} from './mutations.generated';
import { EditCompanyFieldValues } from '../../../EditModal/types';

export const useSubmitForm = () => {
  const { cache } = useApolloClient();
  const id = useCompanyProfileCacheItemId();

  const [handleChangeCompanyName, { error }] = useMutation<
    ChangeAccountNameMutation,
    ChangeAccountNameMutationVariables
  >(CHANGE_COMPANY_NAME, {
    onCompleted: (data) => {
      if (data.changeAccountName?.name) {
        cache.writeFragment({
          id,
          fragment: gql`
            fragment UpdatedCompanyName on Profile {
              name
            }
          `,
          data: {
            name: data.changeAccountName.name,
          },
        });
      }
    },
  });

  const submit = async ({ CompanyName }: EditCompanyFieldValues) => {
    const { errors } = await handleChangeCompanyName({
      variables: { name: CompanyName },
    });
    return errors || null;
  };

  return { submit, error: error ?? undefined };
};
