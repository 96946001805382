export enum AddSecretFormFieldName {
  Secret = 'secret',
  ExpiryDate = 'expiryDate',
}

export type AddSecretFormValues = {
  [AddSecretFormFieldName.Secret]: string;
  [AddSecretFormFieldName.ExpiryDate]: Date;
};

export type AddSecretFormErrors = Partial<Record<keyof AddSecretFormValues, string[]>>;
