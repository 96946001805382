import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_LETTING_TIMINGS } from '../mutations';
import { UpdateLettingTimings, UpdateLettingTimingsVariables } from '../types/UpdateLettingTimings';
import { UpdateLettingTimingsInput } from '@ascension/_gqltypes/builder.generated';
import { EntityId } from '@ascension/types';

export const useUpdateLettingTimings = () => {
  const [updateLettingTimings, { loading }] = useMutation<
    UpdateLettingTimings,
    UpdateLettingTimingsVariables
  >(UPDATE_LETTING_TIMINGS);

  const submit = useCallback(
    async ({
      id,
      packageId,
      sendInvitesByDate,
      quotesDueByDateInterval,
      quotesDueByDate,
      letByDateInterval,
      letByDate,
      startOnSiteDateInterval,
      startOnSiteDate,
    }: UpdateLettingTimingsInput & { id: EntityId }) => {
      const { data } = await updateLettingTimings({
        variables: {
          input: {
            packageId,
            sendInvitesByDate,
            quotesDueByDateInterval,
            quotesDueByDate,
            letByDateInterval,
            letByDate,
            startOnSiteDateInterval,
            startOnSiteDate,
          },
        },
        ignoreResults: true,
        optimisticResponse: {
          updateLettingTimings: {
            __typename: 'LettingScheduleDetails',
            id,
            rfqsSentByDate: sendInvitesByDate ?? null,
            quotesDueAtInterval: quotesDueByDateInterval ?? null,
            quotesDueAt: quotesDueByDate ?? null,
            letByDateInterval: letByDateInterval ?? null,
            letByDate: letByDate ?? null,
            startOnSiteDateInterval: startOnSiteDateInterval ?? null,
            startOnSiteDate: startOnSiteDate ?? null,
          },
        },
      });
      return data?.updateLettingTimings;
    },
    [updateLettingTimings],
  );

  return { updateLettingTimings: submit, loading };
};
