import { InfoCard, ProcurementIllustration } from '@estimateone/frontend-components';
import styles from './styles.scss';

export const WizardInfoCard = () => (
  <InfoCard
    heading="You are creating a Procurement Phase version of this project"
    icon={<ProcurementIllustration />}
  >
    <ul className={styles.listInfo}>
      <li>We’ll copy the Trades and the list of Packages from Tender.</li>
      <li>You can import documents from Tender later.</li>
      <li>You can access the Tender Phase version of the project at any time.</li>
    </ul>
  </InfoCard>
);
