import { useCallback, useMemo, useRef, useState } from 'react';
import {
  ColumnWithLooseAccessor,
  ExitFullscreenIconButton,
  FullscreenContent,
  FullscreenNavbar,
  FullscreenNavbarTitle,
  FullscreenRoot,
  Icon,
  IconName,
  joinClassNames,
  Table,
  Tooltip,
  useTableRef,
} from '@estimateone/frontend-components';
import { escape } from 'lodash';
import { CustomColumnHeader } from '@builder/features/ProcurementLettingSchedule/CustomColumn';
import { generateQuoteCoverageColumns } from '@builder/features/ProcurementLettingSchedule/utils/quoteCoverageColumns';
import { AddendumAlertSchedule } from '../AddendumAlertSchedule';
import { CreateNewPackageFormModal } from '../CreateNewPackageForm/CreateNewPackageFormModal';
import { RevisionFlow } from '../Documents/RevisionFlow';
import { RevisionFlowAlertBanner } from '../Documents/RevisionFlow/RevisionFlowAlertBanner';
import { ImportPreviousDocumentsAlert } from '../ImportPreviousDocumentsAlert';
import { LettingScheduleNotesSlideout } from './LettingScheduleNotesSlideout';
import { LettingScheduleNotesSlideoutSource } from './LettingScheduleNotesSlideout/LettingScheduleNotesSlideout';
import { useNotesSlideout } from './LettingScheduleNotesSlideout/useNotesSlideout';
import { LettingScheduleStatistics } from './LettingScheduleStatistics';
import { getLettingScheduleStatus } from './LettingScheduleStatus';
import { RevisionHistoryContinueButton } from './RevisionFlowContinueButton/RevisionFlowContinueButton';
import { TableActionBar } from './TableActionBar/TableActionBar';
import { Trigger } from './TimingCalculator/autoCalculateTimings';
import {
  AssignedToCell,
  CurrencyCell,
  CustomColumnCell,
  DateIntervalCell,
  LetByCell,
  NotesCell,
  OptionsCell,
  PackageNameCell,
  PriorityCell,
  QuoteCoverageCell,
  QuotesDueByCell,
  QuoteStatusCell,
  SendInvitesByCell,
  StartOnSiteCell,
  VarianceCell,
} from './cells';
import { computePackageQuoteCoverage } from './utils/computePackageQuoteCoverage';
import { useTrackCustomColumValueMaxChar } from './utils/customColumns';
import {
  getAssignedToFilterOptions,
  getStatusFilterOptions,
  priorityFilterOptions,
} from './utils/filterOptions';
import {
  CalculateAndUpdateLettingTimingsInput,
  getCalculatedTimings,
  InputIdentifiers,
  toISOString,
} from './utils/lettingTimingHelper';
import { currencySort, prioritySort, simpleSort } from './utils/sort';
import useFlashMessage from '@shared/Util/useFlashMessage';
import { useGetDocumentIntegration } from '../../common/DocumentIntegration/hooks/useGetDocumentIntegration';
import { usePendingAddendum } from '../Documents/RevisionFlow/hooks';
import { useUpdatePackageFinances } from './hooks';
import { useInitialPackageSort } from './hooks/initialPackageSort';
import { useCustomColumns } from './hooks/useCustomColumns';
import { useDebounceList } from './hooks/useDebounceList';
import { useFilteredPackages } from './hooks/useFilteredPackages';
import { useSortConfig } from './hooks/useSortConfig';
import { useStickyTableHeader } from './hooks/useStickyTableHeader';
import { useUpdateLettingTimings } from './hooks/useUpdateLettingTimings';
import { StageType } from '@ascension/enums';
import { Addendum } from '../Documents/RevisionFlow/types';
import {
  LettingScheduleCustomColumnIdentifier as ColumnIdentifier,
  UpdateActualAmountInput,
  UpdateBudgetAmountInput,
  UpdateLettingScheduleCustomColumnNameInput,
  UpdateLettingScheduleCustomColumnValueInput,
} from '@ascension/_gqltypes/builder.generated';
import {
  Package,
  ProcurementLettingScheduleProps,
} from '@builder/features/ProcurementLettingSchedule/types';
import styles from './styles.scss';

export const DEBOUNCE_PERIOD = 300;

export const ProcurementLettingSchedule = ({
  stageId,
  hasDocumentIntegration,
  activePackages,
  lettingScheduleCustomColumns,
  stage,
  columnStatuses,
  accountUsers,
  enableDefaultTimeframes,
}: ProcurementLettingScheduleProps) => {
  const [tableContainerRef] = useStickyTableHeader();
  const [isNewPackageModalOpen, setIsNewPackageModalOpen] = useState(false);
  const { tableRef } = useTableRef<Package>();
  const { success: showFlashMessage } = useFlashMessage();

  const { updateBudget, updateActual } = useUpdatePackageFinances();

  const {
    isOpen: isNotesSliderOpen,
    isCritical: isNotesSliderCritical,
    packageId: noteSlideoutPackageId,
    packageTitle: noteSlideoutPackageTitle,
    openNotesSlider,
    closeNoteSlider,
  } = useNotesSlideout();
  const { updateLettingTimings } = useUpdateLettingTimings();
  const [filteredPackages, filterApi] = useFilteredPackages(activePackages, stageId);
  const [sortConfig, handleSortChange] = useSortConfig(stageId);

  // Revision Flow
  const { pendingAddendum, refetch: refetchPendingAddendum } = usePendingAddendum(stageId);
  const { documentIntegration } = useGetDocumentIntegration(stageId, !hasDocumentIntegration);
  const [isRevisionFlowOpen, setIsRevisionFlowOpen] = useState(false);
  const [isRevisionBannerDismissed, setIsRevisionBannerDismissed] = useState(false);
  const [completedAddendum, setCompletedAddendum] = useState<Addendum>();

  // Recurse the sorted packages and set the sortOrder
  const initialPackageSort = useInitialPackageSort(filteredPackages);

  const [statusFilterOptions, assignedToFilterOptions] = useMemo(
    () => [getStatusFilterOptions(activePackages), getAssignedToFilterOptions(activePackages)],
    [activePackages],
  );

  const {
    names: customColumnNames,
    values: customColumnValues,
    updateName: updateCustomColumnName,
    updateValue: updateCustomColumnValue,
  } = useCustomColumns(activePackages, lettingScheduleCustomColumns);

  const titleSort = useMemo(() => simpleSort, [activePackages]);
  const statusSort = useMemo(() => simpleSort, [activePackages]);
  const sendInvitesBySort = useMemo(() => simpleSort, [activePackages]);
  const quotesDueBySort = useMemo(() => simpleSort, [activePackages]);
  const letBySort = useMemo(() => simpleSort, [activePackages]);
  const startOnSiteSort = useMemo(() => simpleSort, [activePackages]);
  const flagSort = useMemo(() => simpleSort, [activePackages]);
  const budgetAmountSort = useMemo(() => currencySort, [activePackages]);
  const actualAmountSort = useMemo(() => currencySort, [activePackages]);
  const varianceSort = useMemo(() => currencySort, [activePackages]);
  const { trackCustomColumnValueMaxChar } = useTrackCustomColumValueMaxChar();

  const packageQuoteCoverage = useMemo(
    () => computePackageQuoteCoverage(activePackages),
    [activePackages],
  );

  const calculateAndUpdateLettingTimings = useCallback(
    ({ trigger, ...timings }: CalculateAndUpdateLettingTimingsInput) => {
      const calculatedTimings = enableDefaultTimeframes
        ? getCalculatedTimings(timings, trigger ?? null)
        : timings;

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      updateLettingTimings({
        id: timings.id,
        packageId: timings.packageId,
        sendInvitesByDate: toISOString(calculatedTimings.sendInvitesByDate),
        quotesDueByDateInterval: calculatedTimings.quotesDueByDateInterval,
        quotesDueByDate: toISOString(calculatedTimings.quotesDueByDate),
        letByDateInterval: calculatedTimings.letByDateInterval,
        letByDate: toISOString(calculatedTimings.letByDate),
        startOnSiteDateInterval: calculatedTimings.startOnSiteDateInterval,
        startOnSiteDate: toISOString(calculatedTimings.startOnSiteDate),
      });
    },
    [updateLettingTimings, enableDefaultTimeframes],
  );

  const debounceHandler = useCallback(
    (lazyLoadDOMValues: () => CalculateAndUpdateLettingTimingsInput) => {
      calculateAndUpdateLettingTimings(lazyLoadDOMValues());
    },
    [calculateAndUpdateLettingTimings],
  );
  const { debounce: debouncedIntervalChangeHandler } = useDebounceList(
    debounceHandler,
    DEBOUNCE_PERIOD,
  );

  const { debounce: debouncedBudgetAmountUpdate } = useDebounceList<UpdateBudgetAmountInput>(
    updateBudget,
    DEBOUNCE_PERIOD,
  );
  const { debounce: debouncedActualAmountUpdate } = useDebounceList<UpdateActualAmountInput>(
    updateActual,
    DEBOUNCE_PERIOD,
  );

  const { debounce: debouncedCustomColumnValueUpdate } =
    useDebounceList<UpdateLettingScheduleCustomColumnValueInput>(
      updateCustomColumnValue,
      DEBOUNCE_PERIOD,
    );

  const { debounce: debouncedCustomColumnNameUpdate } =
    useDebounceList<UpdateLettingScheduleCustomColumnNameInput>(
      updateCustomColumnName,
      DEBOUNCE_PERIOD,
    );

  const renameColumn = (customColumnName: string | null, identifier: ColumnIdentifier) => {
    debouncedCustomColumnNameUpdate(stageId, {
      stageId,
      identifier,
      name: customColumnName ?? '',
    });
  };

  const columns: ColumnWithLooseAccessor<Package>[] = useMemo(
    () => [
      {
        accessor: ({ title }) => title,
        Header: 'Package',
        Cell: PackageNameCell(stageId),
        sortType: titleSort,
      },
      {
        id: 'status',
        accessor: (pkg) => getLettingScheduleStatus(pkg),
        Header: 'Status',
        Cell: QuoteStatusCell,
        sortType: statusSort,
      },
      {
        id: 'priority',
        Header: 'Priority',
        accessor: ({ lettingScheduleDetails }) => lettingScheduleDetails?.priority,
        Cell: PriorityCell(openNotesSlider),
        sortType: prioritySort,
      },
      {
        id: 'quoteCoverage',
        Header: (
          <span
            className={styles.iconColumnHeader}
            data-tip
            data-for="quote-coverage-tooltip"
            aria-describedby="quote-coverage-tooltip"
            aria-label="Quote Coverage"
          >
            Quote Coverage
          </span>
        ),
        Cell: QuoteCoverageCell(packageQuoteCoverage),
        disableSortBy: true,
      },
      ...generateQuoteCoverageColumns((rowOriginal) => packageQuoteCoverage[rowOriginal.id]),
      {
        id: 'sendInvitesByDate',
        accessor: ({ lettingScheduleDetails }) => lettingScheduleDetails?.rfqsSentByDate,
        Header: <span className={styles.editableColumnHeader}>Send Invites</span>,
        Cell: SendInvitesByCell(calculateAndUpdateLettingTimings, tableRef),
        sortType: sendInvitesBySort,
      },
      {
        id: 'quotesDueByDateInterval',
        accessor: ({ lettingScheduleDetails }) =>
          lettingScheduleDetails?.quotesDueAtInterval ?? undefined,
        Header: (
          <span
            className={styles.iconColumnHeader}
            data-tip
            data-for="qdb-interval-tooltip"
            aria-describedby="qdb-interval-tooltip"
            aria-label="Quotes Due By Interval"
          >
            Days →
          </span>
        ),
        Cell: DateIntervalCell(
          tableRef,
          debouncedIntervalChangeHandler,
          InputIdentifiers.QUOTES_DUE_BY_DATE_INTERVAL,
          Trigger.QUOTES_DUE_BY_INTERVAL_CHANGE,
          'Quotes due by date interval',
          'quotesDueByDateInterval',
        ),
        disableSortBy: true,
      },
      {
        id: 'quotesDueByDate',
        accessor: ({ lettingScheduleDetails }) => lettingScheduleDetails?.quotesDueAt,
        Header: <span className={styles.editableColumnHeader}>Quotes Due</span>,
        Cell: QuotesDueByCell(calculateAndUpdateLettingTimings, tableRef, enableDefaultTimeframes),
        sortType: quotesDueBySort,
      },
      {
        id: 'letByDateInterval',
        accessor: ({ lettingScheduleDetails }) =>
          lettingScheduleDetails?.letByDateInterval ?? undefined,
        Header: (
          <span
            className={styles.iconColumnHeader}
            data-tip
            data-for="lb-interval-tooltip"
            aria-describedby="lb-interval-tooltip"
            aria-label="Let by Interval"
          >
            Days →
          </span>
        ),
        Cell: DateIntervalCell(
          tableRef,
          debouncedIntervalChangeHandler,
          InputIdentifiers.LET_BY_DATE_INTERVAL,
          Trigger.LET_BY_INTERVAL_CHANGE,
          'Let by date interval',
          'letByDateInterval',
        ),
        disableSortBy: true,
      },
      {
        id: 'letBy',
        accessor: ({ lettingScheduleDetails }) => lettingScheduleDetails?.letByDate,
        Header: <span className={styles.editableColumnHeader}>Let By</span>,
        Cell: LetByCell(calculateAndUpdateLettingTimings, tableRef, enableDefaultTimeframes),
        sortType: letBySort,
      },
      {
        id: 'startOnSiteDateInterval',
        accessor: ({ lettingScheduleDetails }) =>
          lettingScheduleDetails?.startOnSiteDateInterval ?? undefined,
        Header: (
          <span
            className={styles.iconColumnHeader}
            data-tip
            data-for="sos-interval-tooltip"
            aria-describedby="sos-interval-tooltip"
            aria-label="Start on Site Interval"
          >
            Days →
          </span>
        ),
        Cell: DateIntervalCell(
          tableRef,
          debouncedIntervalChangeHandler,
          InputIdentifiers.START_ON_SITE_DATE_INTERVAL,
          Trigger.START_ON_SITE_INTERVAL_CHANGE,
          'Start on Site date interval',
          'startOnSiteDateInterval',
        ),
        disableSortBy: true,
      },
      {
        id: 'startOnSiteDate',
        accessor: ({ lettingScheduleDetails }) => lettingScheduleDetails?.startOnSiteDate,
        Header: 'Start on Site',
        Cell: StartOnSiteCell(calculateAndUpdateLettingTimings, tableRef, enableDefaultTimeframes),
        sortType: startOnSiteSort,
      },
      {
        id: 'budgetAmount',
        accessor: ({ lettingScheduleDetails }) => lettingScheduleDetails?.budgetAmount ?? undefined,
        Header: <div className={styles.financeHeader}>Budget</div>,
        Cell: CurrencyCell('budget_amount_', 'Budget Amount', debouncedBudgetAmountUpdate),
        sortType: budgetAmountSort,
      },
      {
        id: 'actualAmount',
        accessor: ({ lettingScheduleDetails }) => lettingScheduleDetails?.actualAmount ?? undefined,
        Header: <div className={styles.financeHeader}>Actual</div>,
        Cell: CurrencyCell('actual_amount_', 'Actual Amount', debouncedActualAmountUpdate),
        sortType: actualAmountSort,
      },
      {
        id: 'amountVariance',
        accessor: ({ lettingScheduleDetails }) =>
          lettingScheduleDetails?.actualAmount && lettingScheduleDetails?.budgetAmount
            ? Number(lettingScheduleDetails.budgetAmount) -
              Number(lettingScheduleDetails.actualAmount)
            : undefined,
        Header: (
          <div className={styles.financeHeaderWithTooltip}>
            <div>Variance</div>
            <div
              className={styles.iconColumnHeader}
              data-tip
              data-for="finance-variance-tooltip"
              aria-describedby="finance-variance-tooltip"
            >
              <Icon name={IconName.Help} />
            </div>
          </div>
        ),
        Cell: VarianceCell,
        sortType: varianceSort,
      },
      {
        id: 'assignedTo',
        accessor: ({ lettingScheduleDetails }) => lettingScheduleDetails?.assignedToUser,
        Header: <span className={styles.editableColumnHeader}>Assigned To</span>,
        Cell: AssignedToCell(accountUsers),
      },
      {
        id: 'notes',
        accessor: getLettingScheduleStatus,
        Header: 'Notes',
        Cell: NotesCell(openNotesSlider),
        disableSortBy: true,
      },
      {
        id: 'customColumn1',
        disableSortBy: true,
        Header: (
          <CustomColumnHeader
            name={customColumnNames[ColumnIdentifier.CUSTOM_COLUMN_1]}
            onRename={(name: string | null) => renameColumn(name, ColumnIdentifier.CUSTOM_COLUMN_1)}
          />
        ),
        Cell: CustomColumnCell(
          'Custom Column 1',
          ColumnIdentifier.CUSTOM_COLUMN_1,
          customColumnValues,
          debouncedCustomColumnValueUpdate,
          trackCustomColumnValueMaxChar,
        ),
      },
      {
        id: 'customColumn2',
        disableSortBy: true,
        Header: (
          <CustomColumnHeader
            name={customColumnNames[ColumnIdentifier.CUSTOM_COLUMN_2]}
            onRename={(name: string | null) => renameColumn(name, ColumnIdentifier.CUSTOM_COLUMN_2)}
          />
        ),
        Cell: CustomColumnCell(
          'Custom Column 2',
          ColumnIdentifier.CUSTOM_COLUMN_2,
          customColumnValues,
          debouncedCustomColumnValueUpdate,
          trackCustomColumnValueMaxChar,
        ),
      },
      {
        id: 'options',
        Header: () => 'Options',
        Cell: OptionsCell(stageId),
        disableSortBy: true,
        fitToContent: true,
      },
    ],
    [
      accountUsers,
      calculateAndUpdateLettingTimings,
      debouncedIntervalChangeHandler,
      flagSort,
      letBySort,
      openNotesSlider,
      quotesDueBySort,
      stageId,
      startOnSiteSort,
      statusSort,
      titleSort,
      sendInvitesBySort,
      lettingScheduleCustomColumns,
    ],
  );

  const fullscreenContainerRef = useRef<HTMLDivElement | null>(null);

  return (
    <>
      <Tooltip
        className={styles.tooltipSpacing}
        text="Click to edit column title"
        tooltipId="custom-column-tooltip"
        place="top"
      />
      <Tooltip
        className={styles.tooltipSpacing}
        text={
          <>
            Target days between:
            <br />
            <strong>Send Invites</strong> → <strong>Quote Due</strong> dates
          </>
        }
        tooltipId="qdb-interval-tooltip"
        place="top"
      />
      <Tooltip
        className={styles.tooltipSpacing}
        text={
          <>
            Target days between:
            <br />
            <strong>Quotes Due</strong> → <strong>Let By</strong> dates
          </>
        }
        tooltipId="lb-interval-tooltip"
        place="top"
      />
      <Tooltip
        className={styles.tooltipSpacing}
        text={
          <>
            Target days between:
            <br />
            <strong>Let By</strong> → <strong>Start on Site</strong> dates
          </>
        }
        tooltipId="sos-interval-tooltip"
        place="top"
      />
      <Tooltip
        className={styles.tooltipSpacing}
        text="Variance = Budget - Actual"
        tooltipId="finance-variance-tooltip"
        place="top"
      />
      <Tooltip
        className={styles.tooltipSpacing}
        text="Quote Coverage counts companies, not individual contacts"
        tooltipId="quote-coverage-tooltip"
        place="top"
      />
      <section className={styles.statsHeader}>
        <div className="wrapper">
          <LettingScheduleStatistics activePackages={activePackages} />
        </div>
      </section>

      {pendingAddendum && (
        <div className={joinClassNames('fluid-wrapper', styles.alertBanner)}>
          <div className="row">
            <div className="col-sm-12">
              {hasDocumentIntegration ? (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                  {documentIntegration && (
                    <>
                      {!completedAddendum && !isRevisionBannerDismissed ? (
                        <RevisionFlowAlertBanner
                          addendum={pendingAddendum}
                          documentIntegrationType={documentIntegration.type}
                          onOpen={() => setIsRevisionFlowOpen(true)}
                          onClose={() => setIsRevisionBannerDismissed(true)}
                        />
                      ) : (
                        false
                      )}
                      <RevisionFlow
                        stageId={stageId}
                        stageType={StageType.TYPE_PROCUREMENT}
                        documentIntegrationType={documentIntegration.type}
                        addendum={pendingAddendum}
                        isOpen={isRevisionFlowOpen}
                        onClose={async () => {
                          setIsRevisionFlowOpen(false);
                        }}
                        onCompletion={async () => {
                          setCompletedAddendum({ ...pendingAddendum });
                          await refetchPendingAddendum();
                        }}
                      />
                    </>
                  )}
                </>
              ) : (
                <AddendumAlertSchedule existingAddendum={pendingAddendum} />
              )}
            </div>
          </div>
        </div>
      )}
      <RevisionHistoryContinueButton
        pendingAddendum={pendingAddendum ?? undefined}
        completedAddendum={completedAddendum}
        stageId={stageId}
        onClick={() => {
          if (pendingAddendum && !completedAddendum) {
            setIsRevisionFlowOpen(true);
          }
        }}
      />

      <div className="fluid-wrapper">
        {stage && stage.canImportDocuments && (
          <div className="row">
            <div className="col-sm-12">
              <ImportPreviousDocumentsAlert
                stageId={stageId}
                stageType={StageType.TYPE_PROCUREMENT}
              />
            </div>
          </div>
        )}
        <FullscreenRoot
          ref={fullscreenContainerRef}
          className={styles.fullscreenLettingScheduleRoot}
        >
          <FullscreenNavbar>
            <FullscreenNavbarTitle>{stage.name}</FullscreenNavbarTitle>
            <ExitFullscreenIconButton />
          </FullscreenNavbar>
          <FullscreenContent>
            <TableActionBar
              filterApi={filterApi}
              assignedToFilterOptions={assignedToFilterOptions}
              statusFilterOptions={statusFilterOptions}
              priorityFilterOptions={priorityFilterOptions}
              stageId={stageId}
              tableRef={tableRef}
              fullscreenContainerRef={fullscreenContainerRef}
              columnStatuses={columnStatuses}
              setIsNewPackageModalOpen={setIsNewPackageModalOpen}
              customColumnNames={customColumnNames}
              enableDefaultTimeframes={enableDefaultTimeframes}
            />

            <CreateNewPackageFormModal
              stageId={stageId}
              onCancel={() => setIsNewPackageModalOpen(false)}
              onSuccess={(packageWithDetails) => {
                setIsNewPackageModalOpen(false);

                const htmlEncodedPackageName = escape(packageWithDetails?.title);

                showFlashMessage({
                  title: 'Success',
                  message: `A <strong>${htmlEncodedPackageName}</strong> package has been created.`,
                  dangerouslyAllowHTML: true,
                });
              }}
              isOpen={isNewPackageModalOpen}
            />
            <section
              ref={tableContainerRef}
              className={styles.tableContainer}
              data-testid="letting-schedule-table"
            >
              <Table<Package>
                // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions
                tableRef={tableRef as any}
                columns={columns}
                data={initialPackageSort}
                {...(sortConfig && sortConfig.sortId
                  ? {
                      initSortColumnId: sortConfig.sortId,
                      isInitSortDesc: sortConfig.isSortedDesc,
                    }
                  : {})}
                onSort={handleSortChange}
                initHiddenColumns={['customColumn1', 'customColumn2']}
              />
              {filteredPackages.length === 0 ? (
                <div className={styles.noDataText}>No packages found</div>
              ) : undefined}
            </section>
            {isNotesSliderOpen &&
              noteSlideoutPackageId !== undefined &&
              noteSlideoutPackageTitle !== undefined && (
                <LettingScheduleNotesSlideout
                  isOpen={isNotesSliderOpen}
                  onCloseHandler={closeNoteSlider}
                  packageId={noteSlideoutPackageId}
                  packageTitle={noteSlideoutPackageTitle}
                  accountName={stage?.account?.name ?? ''}
                  isCritical={isNotesSliderCritical}
                  source={LettingScheduleNotesSlideoutSource.LETTING_SCHEDULE}
                />
              )}
          </FullscreenContent>
        </FullscreenRoot>
      </div>
    </>
  );
};

export default ProcurementLettingSchedule;
