import { TextInputField } from '../../../../../../shared/FinalForm/Field';
import { TenderHandoverWizardFieldName } from '../../../enums';
import { Country } from '@ascension/enums';

const getCityProps = (country: Country | undefined) => {
  switch (country) {
    case Country.COUNTRY_UK:
    case Country.COUNTRY_IE:
      return {
        id: 'tender-handover-wizard--address-city',
        label: 'City',
        fieldName: TenderHandoverWizardFieldName.AddressCity,
      };
    case Country.COUNTRY_AUSTRALIA:
    default:
      return {
        id: 'tender-handover-wizard--address-suburb',
        label: 'Suburb',
        fieldName: TenderHandoverWizardFieldName.AddressSuburb,
      };
  }
};

export const CitySelector = ({ country }: { country: Country | undefined }) => {
  const cityProps = getCityProps(country);

  return (
    <>
      <div>
        <TextInputField {...cityProps} />
      </div>
      <div>
        <TextInputField
          id="tender-handover-wizard--address-postcode"
          label="Postcode"
          fieldName={TenderHandoverWizardFieldName.AddressPostcode}
          validate={(postcode) => (!postcode ? ['Enter a postcode'] : undefined)}
          isRequired
        />
      </div>
    </>
  );
};
