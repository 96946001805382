import { createContext, ReactNode } from 'react';

const CONTACT_LISTS_FEATURE_FLAG_ON = false;

export const ContactListsFeatureFlagContext = createContext(CONTACT_LISTS_FEATURE_FLAG_ON);

const ContactListsFeatureFlagProvider = ({ children }: { children: ReactNode }) => (
  <ContactListsFeatureFlagContext.Provider value={CONTACT_LISTS_FEATURE_FLAG_ON}>
    {children}
  </ContactListsFeatureFlagContext.Provider>
);

export default ContactListsFeatureFlagProvider;
