import { joinClassNames } from '@estimateone/frontend-components';
import { LettingScheduleStatus } from '../LettingScheduleStatus';
import { LettingScheduleStatusLabel } from '../LettingScheduleStatusLabel';
import { LettingStatusTimelineItem } from './LettingStatusTimelineItem';
import styles from './styles.scss';

export type LettingStatusTimelineProps = {
  lettingStatus: LettingScheduleStatus;
  awardedCompanyName?: string;
};

const TriangleDown = () => (
  <svg className={joinClassNames(styles.icon, styles.triangleIcon)}>
    <path d="M1 1 L5 9 L9 1 Z" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const CircleIcon = () => <div className={joinClassNames(styles.icon, styles.circleIcon)} />;

export const LettingStatusTimeline = ({
  lettingStatus,
  awardedCompanyName,
}: LettingStatusTimelineProps) => {
  const isDraftSelected = lettingStatus === LettingScheduleStatus.Draft;
  const isPricingSelected = lettingStatus === LettingScheduleStatus.Pricing;
  const isAwardedSelected = lettingStatus === LettingScheduleStatus.Awarded;

  return (
    <div className={joinClassNames(styles.timeline, styles.tooltip)}>
      <LettingStatusTimelineItem
        className={joinClassNames(isDraftSelected && styles.selected)}
        bullet={<TriangleDown />}
        heading={
          <LettingScheduleStatusLabel
            status={LettingScheduleStatus.Draft}
            isActive={isDraftSelected}
          />
        }
        description={
          <div className={styles.description}>
            <p>Preparing package documents</p>
            <p>No RFQs sent</p>
          </div>
        }
      />
      <LettingStatusTimelineItem
        className={isPricingSelected ? styles.selected : undefined}
        bullet={<TriangleDown />}
        heading={
          <LettingScheduleStatusLabel
            status={LettingScheduleStatus.Pricing}
            isActive={isPricingSelected}
          />
        }
        description={
          <div className={styles.description}>
            <p>RFQs sent</p>
            <p>Receiving and vetting quotes</p>
          </div>
        }
      />
      <LettingStatusTimelineItem
        className={isAwardedSelected ? styles.selected : undefined}
        bullet={<CircleIcon />}
        heading={
          <LettingScheduleStatusLabel
            status={LettingScheduleStatus.Awarded}
            isActive={isAwardedSelected}
          />
        }
        description={
          <div className={styles.description}>
            <p>To: {awardedCompanyName}</p>
          </div>
        }
      />
    </div>
  );
};
