import { useMutation } from '@apollo/client';
import { UPDATE_PACKAGE_TITLE } from '../mutations';
import { UpdatePackageTitle, UpdatePackageTitleVariables } from '../types/UpdatePackageTitle';
import { EntityId } from '@ascension/types';

export const useUpdatePackageTitle = () => {
  const [updatePackageTitle, { loading }] = useMutation<
    UpdatePackageTitle,
    UpdatePackageTitleVariables
  >(UPDATE_PACKAGE_TITLE);

  const submit = async (packageId: EntityId, title: string) => {
    const { data } = await updatePackageTitle({ variables: { input: { packageId, title } } });
    return data?.updatePackageTitle;
  };

  return { submit, loading };
};
