import { gql } from '@apollo/client';

export const SUBBIE_NETWORK_FILTER_DATA = gql`
  query SubbieNetworkFilterData {
    currentUser {
      id
      address {
        latitude
        longitude
      }
      imperialUnitsSetting
      account {
        primaryCountry {
          id
        }
      }
    }
    stockTrades {
      id
      name
    }
    stageCategories {
      id
      name
    }
    trades {
      id
      name
    }
    companyTags {
      id
      name
    }
  }
`;
