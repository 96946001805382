import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { joinClassNames } from '@estimateone/frontend-components';
import { useCurrencyFormatter, useCurrencySymbol } from '@shared';
import styles from './styles.scss';

export type InlineEditableCurrencyProps = {
  value: number | undefined;
  onValueChange: (value: number | null) => void;
  errorMessage?: string;
  minValue?: number;
  maxValue?: number;
} & JSX.IntrinsicElements['input'];

const isNumber = (value: unknown) => !Number.isNaN(value);

const isInRange = (value: number, min: number, max: number) => value >= min && value <= max;

const valueAsString = (value: number | undefined): string =>
  value === undefined ? '' : String(value);

export const InlineEditableCurrency = ({
  value,
  onValueChange,
  errorMessage,
  minValue = 0,
  maxValue = 999999999999.99,
  className,
  ...props
}: InlineEditableCurrencyProps) => {
  const [latestValue, setLatestValue] = useState<string>(valueAsString(value));
  const [isValid, setIsValid] = useState(true);
  const isFocused = useRef(false);
  const currencyFormatter = useCurrencyFormatter();
  const displayCurrency = useCurrencySymbol();

  useEffect(() => {
    if (!isFocused.current) {
      setLatestValue(
        value ? `${displayCurrency} ${currencyFormatter.toString(value)}` : valueAsString(value),
      );
    }
  }, [currencyFormatter, displayCurrency, value]);

  const isValidAmount = useCallback(
    (input: HTMLInputElement) => {
      const valueNumber = currencyFormatter.toNumber(input.value);
      return input.checkValidity() && isInRange(valueNumber, minValue, maxValue);
    },
    [currencyFormatter, minValue, maxValue],
  );

  return (
    <div className={styles.currencyInputContainer}>
      <input
        className={joinClassNames(
          styles.editableNumber,
          className,
          !isValid ? styles.errorState : '',
        )}
        value={latestValue}
        type="text"
        placeholder="Add amount..."
        pattern="[0-9,.e]+"
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const isEmpty = e.target.value === '';
          const isValidAfterChange = isEmpty || isValidAmount(e.target);
          setIsValid(isValidAfterChange);
          setLatestValue(isEmpty ? '' : e.target.value);
          if (isValidAfterChange) {
            onValueChange(isEmpty ? null : currencyFormatter.toNumber(e.target.value));
          }
        }}
        onBlur={(e) => {
          isFocused.current = false;
          if (e.target.value?.length > 0 && isValidAmount(e.target)) {
            const displayValue = currencyFormatter.toString(
              currencyFormatter.toNumber(e.target.value),
            );
            setLatestValue(`${displayCurrency} ${displayValue}`);
          }
        }}
        onFocus={(e) => {
          isFocused.current = true;
          if (e.target.value?.length > 0) {
            const updatedValue = currencyFormatter.toNumber(e.target.value);
            if (isNumber(updatedValue) && isInRange(updatedValue, minValue, maxValue)) {
              const displayValue = currencyFormatter.toString(updatedValue);
              setLatestValue(displayValue);
            }
          }
        }}
        {...props}
      />
      {errorMessage && !isValid && <div className={styles.errorMessage}>{errorMessage}</div>}
    </div>
  );
};
