import { useMutation } from '@apollo/client';
import { PackageLettingSchedule } from '../../ProcurementLettingSchedule/fragments';
import { CREATE_PACKAGE_WITH_LETTING_SCHEDULE } from '../mutations';
import { ApiError } from '../../../../api/fragments/Errors/types';
import {
  CreatePackageWithLettingScheduleDetails,
  CreatePackageWithLettingScheduleDetailsVariables,
  CreatePackageWithLettingScheduleDetails_createPackageWithLettingScheduleDetails as Details,
} from '../types/CreatePackageWithLettingScheduleDetails';

type Package = Extract<Details, { __typename: 'Package' }>;

type CreatePackageWithLettingSchedule = {
  submit: (
    stageId: number,
    tradeDisciplineId: number,
    packageName: string,
    startOnSiteDate: string | null,
  ) => Promise<SubmissionResponse>;
};

export type SubmissionResponse = {
  errors: {
    field: string | null;
    message: ApiError['message'];
  }[];
  package: Package | undefined;
};

export const useCreatePackageWithLettingScheduleDetails = (): CreatePackageWithLettingSchedule => {
  const [handleCreatePackageWithLettingScheduleDetails] = useMutation<
    CreatePackageWithLettingScheduleDetails,
    CreatePackageWithLettingScheduleDetailsVariables
  >(CREATE_PACKAGE_WITH_LETTING_SCHEDULE, {
    update(cache, { data }) {
      if (data) {
        cache.modify({
          fields: {
            activePackages(existingPackages = []) {
              const newPackageRef = cache.writeFragment({
                data: data.createPackageWithLettingScheduleDetails,
                fragment: PackageLettingSchedule,
              });
              return existingPackages.concat(newPackageRef);
            },
          },
        });
      }
    },
  });

  const submit = async (
    stageId: number,
    tradeDisciplineId: number | undefined,
    packageName: string,
    startOnSiteDate: string | null,
  ): Promise<SubmissionResponse> => {
    const input = {
      stageId,
      tradeDisciplineId,
      title: packageName,
      startOnSiteDate,
    };

    const response = await handleCreatePackageWithLettingScheduleDetails({
      variables: { input },
      optimisticResponse: {
        createPackageWithLettingScheduleDetails: {
          __typename: 'Package',
          id: performance.now(),
          title: packageName,
          fullSet: false,
          quotesDueAt: null,
          activeRfqs: [],
          lettingScheduleDetails: {
            __typename: 'LettingScheduleDetails',
            id: performance.now(),
            rfqsSentByDate: null,
            quotesDueAtInterval: null,
            letByDate: null,
            letByDateInterval: null,
            quotesDueAt: null,
            startOnSiteDateInterval: null,
            startOnSiteDate,
            isAwarded: false,
            latestNote: null,
            assignedToUser: null,
            noteCount: 0,
            awardedToRfq: null,
            budgetAmount: null,
            actualAmount: null,
            customColumnValues: [],
            priority: null,
          },
        },
      },
    });

    if (response.data?.createPackageWithLettingScheduleDetails.__typename === 'Errors') {
      return {
        errors: response.data.createPackageWithLettingScheduleDetails.errors.map((error) => ({
          field: error.field,
          message: error.message,
        })),
        package: undefined,
      };
    }

    return {
      errors: [],
      package: response.data?.createPackageWithLettingScheduleDetails,
    };
  };

  return { submit };
};
