import { Tag, TagVariant } from '@estimateone/frontend-components';
import { truncate } from '../../../../js/utils/truncate';
import { SubbieProfileForAccount_subbieProfileForAccount_trades as Trade } from '../../../builder/pages/Profiles/SubbieProfilesPage/types/SubbieProfileForAccount';
import styles from './styles.scss';

export type TradeTagGroupProps = {
  trades: Trade[];
  maxTrades?: number;
  isAbbreviated?: boolean;
};

const MAX_TRADES = 3;
const MAX_TRADE_CHARACTERS = 13;

const TradeTagGroup = ({
  trades,
  maxTrades = MAX_TRADES,
  isAbbreviated = false,
}: TradeTagGroupProps) => {
  const countMoreTrades = trades.length - maxTrades;

  return (
    <div className={styles.tradesContainer}>
      <ul className={styles.tradeTagGroupList}>
        {trades.slice(0, maxTrades).map((trade) => (
          <li key={trade.id}>
            <Tag
              variant={TagVariant.Practical900}
              text={truncate(trade.name, MAX_TRADE_CHARACTERS)}
            />
          </li>
        ))}
        {countMoreTrades > 0 && isAbbreviated && (
          <li className={styles.moreTrades}>+{trades.length - maxTrades}</li>
        )}
      </ul>

      {countMoreTrades > 0 && !isAbbreviated && (
        <div className={styles.moreTrades}>
          +{trades.length - maxTrades} more trade{countMoreTrades > 1 && 's'}
        </div>
      )}
    </div>
  );
};

export default TradeTagGroup;
