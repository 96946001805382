import $ from 'jquery';
import Routing from 'routing';
import moment from 'moment-timezone';
import E1Request from '../classes/E1Request';
import { getUserTimezone } from './timezone';

$(() => {
  if ($('.stage-rfq-pdf-export').length > 0) {
    const self = $('.stage-rfq-pdf-export');
    const $body = self;

    let MaxHeight = 855;
    let RunningHeight = 0;
    let PageNo = 0;

    let $lastTrade = '';
    const $lastDisc = '';

    const $html = '';
    const $pageHtml = '';
    let $mode = 'TOC';

    const currentdate = new Date();
    const $rightNow = moment().format('MMMM Do YYYY, h:mm a');

    const stage_name = self.attr('data-stage-name');
    const stage_id = self.attr('data-stage-id');
    const stage_timestamp = self.attr('data-closing-date');
    const include_supp = self.attr('data-include-supp');
    const userTimeZone = getUserTimezone();

    function getHeader() {
      let $h = `<div class="pageOuter" id="page${PageNo}"><div class="pgHeader"><h1>${stage_name} - E1 Responses Export</h1><h2>Closing: ${stage_timestamp} </h2><div class="line-one"></div><div class="line-two"></div></div><div class="pageInner"><table id="tbl${PageNo}"><tbody>`;

      $h += getTableHeading_List();

      if ($lastTrade != '') {
        $h += `<tr class="discHeading"><td colspan="5">${$lastTrade}</td></tr>`;
      }

      $h += '</tbody></table>';

      return $h + getFooter();
    }

    function getFooter() {
      const $h = `</div><div class="footer"><div class="logo"></div><div class="pageDate">${$rightNow}<br/>Page ${PageNo}</div></div></div>`;

      return $h;
    }

    function getHeight($html) {
      const $ts = $('#tempSpace table tbody');
      $ts.html($html);
      return $ts.outerHeight();
    }

    function getTableHeading_List() {
      return '<tr class="indexTableHeader"><td class="companyName">Company Name &amp; Details</td><td class="contactDetails">Contact Details</td><td class="documentStatus">Document Status</td><td class="addendumStatus">Addendum Status</td><td class="quoteResponse">Quote Response</td><td class="comments">Comments</td></tr>';
    }

    function startPage() {
      PageNo++;
      addHtml(getHeader());
      RunningHeight = 0;

      if ($mode == 'TOC') {
        addRow(getTableHeading_TOC());
      } else {
        //                addRow(getTableHeading_List());
      }
    }

    function addRow($row) {
      $(`#page${PageNo} table tbody`).append($row);
      RunningHeight = $(`#page${PageNo} table`).outerHeight();

      const $lastRunningHeight = RunningHeight;

      if (RunningHeight > MaxHeight) {
        $(`#page${PageNo} table tbody tr:last`).remove();

        RunningHeight = $lastRunningHeight;

        startPage();
        addRow($row);
      }
    }

    function endPage() {}

    function addHtml($h) {
      $('#output').append($h);
    }

    let $responses = [];

    $(document).ready(() => {
      // get JSON objects
      const data = { id: stage_id, supp: include_supp };
      // trades:trades,packages:packages,

      if (typeof trades !== 'undefined') {
        data.trades = trades;
      } else {
        data.packages = packages;
      }

      const url = Routing.generate('app_stagerfq_exporttojson', data);
      const request = new E1Request(url, 'GET');
      request.extraCallback = (response) => {
        $responses = response;
        $('#pre_load').hide();
        $('#paper_select').removeClass('hide');
      };
      request.submit();
    });

    $('#generate_report').on('click', () => {
      adjustPaper();

      $('#paper_select').hide();
      $('#pre_generate').show();

      setTimeout(buildReport(), 100);
    });

    function adjustPaper() {
      const $orientation = $('#paper_orient').val();
      const $size = $('#paper_size').val();

      $body.removeClass('orient-portrait');
      $body.removeClass('orient-landscape');
      $body.removeClass('size-A4');
      $body.removeClass('size-A3');

      if ($orientation == 'Portrait' && $size == 'A4') {
        $body.addClass('orient-portrait');
        $body.addClass('size-A4');

        var $css = '@page {size:8.27in 11.69in;margin: 0.5cm 1cm 0.5cm 1cm;}';
        $('#css_element').append($css);

        MaxHeight = 855;
      } else if ($orientation == 'Landscape' && $size == 'A4') {
        $body.addClass('orient-landscape');
        $body.addClass('size-A4');

        var $css = '@page {size:11.69in 8.27in;margin: 0.5cm 1cm 0.5cm 1cm;}';
        $('#css_element').append($css);

        MaxHeight = 575;
      } else if ($orientation == 'Landscape' && $size == 'A3') {
        $body.addClass('orient-landscape');
        $body.addClass('size-A3');

        var $css = '@page {size:16.53in 11.69in;margin: 1cm 1cm 1cm 1cm;}';
        $('#css_element').append($css);

        MaxHeight = 855;
      } else if ($orientation == 'Portrait' && $size == 'A3') {
        $body.addClass('orient-portrait');
        $body.addClass('size-A3');

        var $css = '@page {size:11.69in 16.53in;margin: 1cm 1cm 1cm 1cm;}';
        $('#css_element').append($css);

        MaxHeight = 1270;
      }
    }

    function buildReport() {
      const $h = '';

      $mode = 'trades';
      $lastTrade = '';

      startPage();

      $.each($responses, ($package_name, $arr) => {
        const discipline =
          typeof $arr.discipline_name === 'string' && $arr.discipline_name.length > 0
            ? ` (${$arr.discipline_name})`
            : '';

        addRow(`<tr class="discHeading"><td colspan="6">${$package_name + discipline}</td></tr>`);

        $.each($arr.invited, ($i) => {
          const $s = $arr.invited[$i];

          let $t = `<tr class="subbie"><td><strong>${$s.company_name}</strong><br/>`;

          $t += $s.street_address ? `${$s.street_address} ` : ' ';
          $t += $s.street_address == '' ? '' : '<br/>';
          $t += $s.suburb ? `${$s.suburb} ` : ' ';
          $t += $s.town_city ? `${$s.town_city} ` : ' ';
          $t += $s.state ? `${$s.state} ` : ' ';
          $t += $s.postcode ? $s.postcode : ' ';

          $t += '</td>';

          // Contact details

          $t += '<td><br/>';
          $t += `<div class="contactName">${$s.contact_name}`;

          if ($s.phone || $s.parent_phone) {
            $t += `<br/>${$s.phone ? $s.phone : $s.parent_phone}`;
          }

          $t += '</div>';

          if ($s.email[0]) {
            $t += `<div class="contactEmail">${maxlen($s.email[0], 25)}</div>`;
          } else {
            $t += '<div class="contactEmail"></div>';
          }

          $t += '</td>';

          // Document Status

          $t += '<td><br/>';

          if ($s.download_status == 'Not Downloaded') {
            $t += '<span class="br">Not Downloaded</span>';
          } else if ($s.download_status == 'Email Error') {
            $t += '<span class="br">Email Error</span>';
          } else if ($s.is_obsolete == 1) {
            $t += `<span class="by">${getDownloadStatus($s.download_status)}</span>`;
          } else {
            $t += `<span class="bg">${getDownloadStatus($s.download_status)}</span>`;
          }
          $t += '</td>';

          // Addendum

          $t += '<td>';

          $.each($s.addenda, ($a, $name) => {
            $t += `${$name}<br/>`;
          });

          $t += '</td>';

          // Quote status

          $t += '<td><br/>';

          if ($s.quote_status == 'Not Quoting') {
            $t += '<span class="br">Not Quoting</span>';
          } else if ($s.quote_status == 'Quoted') {
            $t += '<span class="bg">Quoted</span>';
          } else if ($s.quote_status == 'Quoting') {
            $t += '<span class="bb">Quoting</span>';
          } else if ($s.quote_status == 'No Response') {
            $t += '<span class="by">No Response</span>';
          } else {
            $t += '';
          }

          $t += '</td>';

          // Notes

          if ($s.note) {
            $t += `<td>${$s.note}</td>`;
          } else {
            $t += '<td> </td>';
          }

          $t += '</tr>';

          addRow($t);
        });

        // now, sups

        if ($arr.sups.length > 0) {
          addRow(
            `<tr class="discHeading supsHeading"><td colspan="6">SUPPLEMENTARY SUBCONTRACTORS: ${$package_name}</td></tr>`,
          );

          $.each($arr.sups, ($i) => {
            const $s = $arr.sups[$i];

            let $t = `<tr class="subbie sup"><td><strong>${$s.company_name}</strong><br/>`;

            $t += $s.street_address;
            $t += $s.street_address == '' ? '' : '<br/>';
            $t += $s.suburb ? `${$s.suburb} ` : ' ';
            $t += $s.town_city ? `${$s.town_city} ` : ' ';
            $t += $s.state ? `${$s.state} ` : ' ';
            $t += $s.postcode ? $s.postcode : ' ';

            $t += `<br/>${$s.phone}`;

            $t += '</td>';

            // Contact details

            $t += '<td><br/>';

            $.each($s.contacts, ($a, $c) => {
              $t += `<div style="clear:both;"><div class="contactName">${$c.name}`;
              $t += '</div>';
              $t += `<div class="contactEmail">${maxlen($c.email, 25)}</div></div>`;
            });

            // The rest are bank

            $t += '<td></td>';
            $t += '<td></td>';
            $t += '<td></td>';
            $t += '<td></td>';

            $t += '</tr>';

            addRow($t);
          });
        }
      });

      endPage();

      $('#tempSpace').remove();
      $('#progress').remove();

      // Now we need to print dialog!!

      $('#pre_generate').hide();
      $('#post_generate').hide();

      setTimeout(() => {
        $('#post_generate').hide();
        window.print();
      }, 500);
    }

    function listOut($in) {
      let $g = '';
      $.each($in, ($e) => {
        $g += `${maxlen($in[$e], 38)}<br/>`;
      });

      return $g;
    }

    function trim($in) {
      return $in.trim();
    }

    function maxlen($in, $len) {
      if ($in.length <= $len) {
        return $in;
      }
      return `${$in.substring(0, $len)}..`;
    }

    function getDownloadStatus($downloadStatus) {
      if (isNaN($downloadStatus)) {
        return $downloadStatus;
      }

      const day = moment.unix($downloadStatus);
      const time = moment.tz(day, userTimeZone);

      return time.format('MMM D');
    }
  }
});
