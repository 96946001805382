import { useField } from 'react-final-form';
import { Tag, TagVariant } from '@estimateone/frontend-components';
import {
  SingleSelectField,
  SingleSelectFieldProps,
} from '@shared/FinalForm/Field/SingleSelectField';
import { AwardeeCandidateOption } from './types/AwardeeCandidateOption';
import { RfqQuotingStatus } from '@ascension/_gqltypes/builder.generated';
import styles from '../styles.scss';

export type PackageAwardeeSelectFieldProps = SingleSelectFieldProps<AwardeeCandidateOption> & {
  rfqOptions: AwardeeCandidateOption[];
  haveAllDeclined: boolean;
  defaultValue?: AwardeeCandidateOption;
  isDisabled: boolean;
};

export const PackageAwardeeSelectField = ({
  fieldName,
  rfqOptions,
  isRequired,
  haveAllDeclined,
  defaultValue,
  isDisabled,
  ...props
}: PackageAwardeeSelectFieldProps) => {
  const { input: packageAwardeeInputProps } = useField<AwardeeCandidateOption>(fieldName, {
    defaultValue,
    validate: (option) =>
      isRequired ? (option?.value ? undefined : ['Choose a subcontractor']) : undefined,
  });

  const getTagVariantDetails = (
    quotingStatus: RfqQuotingStatus | null,
  ): { tagText: string; tagVariant: TagVariant } => {
    switch (quotingStatus) {
      case RfqQuotingStatus.QUOTING:
        return { tagText: 'Quoting', tagVariant: TagVariant.RfqStatusQuoting };
      case RfqQuotingStatus.QUOTED:
        return { tagText: 'Quoted', tagVariant: TagVariant.RfqStatusQuoted };
      case RfqQuotingStatus.DECLINED:
        return { tagText: 'Not Quoting', tagVariant: TagVariant.RfqStatusNotQuoting };
      default:
        return { tagText: 'No Response', tagVariant: TagVariant.RfqStatusNoResponse };
    }
  };
  const formatOptionLabel = (option: AwardeeCandidateOption) => {
    const { tagVariant, tagText } = getTagVariantDetails(option.quotingStatus);
    return (
      <div className={styles.optionContent}>
        {option.label} <Tag className={styles.optionTag} variant={tagVariant} text={tagText} />
      </div>
    );
  };

  return (
    <SingleSelectField<AwardeeCandidateOption>
      fieldName={fieldName}
      placeholder=""
      options={rfqOptions}
      formatOptionLabel={formatOptionLabel}
      noOptionsMessage={() => (
        <div className={styles.noOptionsText}>
          {haveAllDeclined
            ? 'All invited subcontractors are not quoting.'
            : 'No RFQs have been sent yet.'}
        </div>
      )}
      isRequired={isRequired}
      isDisabled={isDisabled}
      {...props}
      {...packageAwardeeInputProps}
    />
  );
};
