import { FieldError } from '../../../../shared/FinalForm/types';
import { CompleteQuoteFormFieldError, CompleteQuoteFormSpecificFieldName } from './enums';
import { valueOf } from '../../../../../types';
import {
  CompanyValue,
  CompleteQuoteFormErrors,
  CompleteQuoteFormValues,
  ContactValue,
  PackageValue,
} from './types';

const validatePackage = (value: PackageValue): CompleteQuoteFormFieldError[] | null =>
  !value ? [CompleteQuoteFormFieldError.PackageEmpty] : null;

const validateCompany = (value: CompanyValue): CompleteQuoteFormFieldError[] | null =>
  !value ? [CompleteQuoteFormFieldError.CompanyEmpty] : null;

const validateContact = (value: ContactValue): CompleteQuoteFormFieldError[] | null =>
  !value ? [CompleteQuoteFormFieldError.ContactEmpty] : null;

export const validatePackageContactFields = (
  values: CompleteQuoteFormValues,
): CompleteQuoteFormErrors => {
  const errors = new Map<keyof CompleteQuoteFormValues, FieldError[] | null>();

  const validateSetErrorsIfExists = (
    field: keyof CompleteQuoteFormValues,
    validatorFunction: (value: valueOf<CompleteQuoteFormValues>) => FieldError[] | null,
  ) => {
    const fieldErrors = validatorFunction(values[field] as valueOf<CompleteQuoteFormValues>);
    if (fieldErrors) errors.set(field, fieldErrors);
  };

  validateSetErrorsIfExists(CompleteQuoteFormSpecificFieldName.Package, validatePackage);
  validateSetErrorsIfExists(CompleteQuoteFormSpecificFieldName.Company, validateCompany);
  validateSetErrorsIfExists(CompleteQuoteFormSpecificFieldName.Contact, validateContact);

  return Array.from(errors.entries()).reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
};
