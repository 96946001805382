import { ReactNode } from 'react';
import Moment from 'moment';
import { CreditsUpgradeLink } from '@subbie/modal/ProjectSlider/ProjectPaywalls/ProjectPaywall/CreditsUpgradeLink';
import styles from './FooterSection.module.scss';
import { pluralise } from '@shared/Util/pluralise';
import { useCurrentAccount, useHasFeature } from '@subbie/context/AccountProvider/hooks';
import { useCreditAllowance } from '@subbie/context/CreditAllowanceProvider/hooks';
import { FeatureName } from '@ascension/_gqltypes/subbie.generated';

const FreePlanInfo = () => (
  <span className={styles.highlightText}>Free plans include access to one tender per month.</span>
);

const LitePlanInfo = ({
  litePlanCreditAllowance,
  remainingCredits,
}: {
  litePlanCreditAllowance: number;
  remainingCredits: number;
}) => (
  <>
    <p className={styles.creditBalance}>
      {remainingCredits} {pluralise('Credit', remainingCredits)} remaining
    </p>
    <span>E1 Lite includes {litePlanCreditAllowance} credits per month.</span>
  </>
);

type FooterTextProps = {
  upgradeUrl: string;
  renewalDate: string;
};

const FooterText = ({ upgradeUrl, renewalDate }: FooterTextProps) => (
  <div>
    Credits reset on {renewalDate}. Can&apos;t wait?{' '}
    <CreditsUpgradeLink
      upgradeUrl={upgradeUrl}
      upgradeLiteToEssentialLabel="Upgrade for unlimited project access."
      upgradeLabel="View all plans"
    />
  </div>
);

const FooterSectionNoCreditWrapper = ({ children }: { children: ReactNode | undefined }) => (
  <div className={styles.footerSectionLiteToEssentialWrapper}>{children}</div>
);

const FooterSectionDefaultWrapper = ({ children }: { children: ReactNode | undefined }) => (
  <div className={styles.footerSectionDefaultWrapper}>{children}</div>
);

type FooterSectionProps = {
  upgradeUrl: string;
  creditsRenewalDate: string;
  remainingCredits?: number;
};

export const FooterSection = ({
  upgradeUrl,
  creditsRenewalDate,
  remainingCredits = 0,
}: FooterSectionProps) => {
  const hasLiteTierLicense = useHasFeature(FeatureName.CREDITS_LITE_TIER);
  const litePlanCreditAllowance = useCreditAllowance();
  const account = useCurrentAccount();
  if (!account) {
    return null;
  }

  if (hasLiteTierLicense && !litePlanCreditAllowance) {
    return null;
  }

  const content = (
    <div>
      {hasLiteTierLicense ? (
        litePlanCreditAllowance && (
          <LitePlanInfo
            litePlanCreditAllowance={litePlanCreditAllowance}
            remainingCredits={remainingCredits}
          />
        )
      ) : (
        <FreePlanInfo />
      )}{' '}
      {creditsRenewalDate && (
        <FooterText
          upgradeUrl={upgradeUrl}
          renewalDate={Moment(creditsRenewalDate).format('MMMM Do, YYYY')}
        />
      )}
    </div>
  );

  return remainingCredits > 0 ? (
    <FooterSectionDefaultWrapper>{content}</FooterSectionDefaultWrapper>
  ) : (
    <FooterSectionNoCreditWrapper>{content}</FooterSectionNoCreditWrapper>
  );
};
