import { useField } from 'react-final-form';
import { AsyncSelect, prefixMenuOptionsWithCheckIcon } from '@estimateone/frontend-components';
import { FieldWrapper } from '@shared/FinalForm/Field';
import { CompleteQuoteFormSpecificFieldName } from '../../enums';
import { CompanyOption, CompanyValue } from '../../types';
import styles from './styles.scss';

type CompanySelectorProps = {
  loadOptions: (inputValue: string, callback: (companies: CompanyOption[]) => void) => void;
  onSelectCompany: () => void;
  onAddCompany: () => void;
  defaultOptions?: CompanyOption[];
  placeholder?: string;
  isDisabled?: boolean;
};

const ADD_NEW_VAL = -1;

export const ADD_NEW_COMPANY_OPTION: CompanyOption = {
  value: ADD_NEW_VAL,
  label: 'Add a new company',
};

const formatCompanyLabel = ({ label, value }: CompanyOption) => (
  <div className={value === ADD_NEW_VAL ? styles.addNewOption : styles.companyContactLabel}>
    <div>{label}</div>
  </div>
);

const CompanySelector = ({
  loadOptions,
  onSelectCompany,
  onAddCompany,
  defaultOptions = [ADD_NEW_COMPANY_OPTION],
  placeholder = 'Start typing to search your address book...',
  isDisabled = false,
}: CompanySelectorProps) => {
  const {
    input: { name, onChange, ...companyInputProps },
    meta: { error, submitError, touched },
  } = useField<CompanyValue>(CompleteQuoteFormSpecificFieldName.Company);

  const handleChange = (selectedOption: CompanyOption) => {
    const { value: id } = selectedOption;
    onChange(id === ADD_NEW_VAL ? null : selectedOption);
    return id === ADD_NEW_VAL ? onAddCompany() : onSelectCompany();
  };

  const errors = error || submitError;

  return (
    <FieldWrapper errors={errors} showErrors={!isDisabled && touched}>
      <div className={styles.selectContainer} data-name={name}>
        <AsyncSelect<CompanyOption>
          {...companyInputProps}
          id="quote-ab-company"
          inputId="quote-ab-company-input"
          label="Company"
          name={name}
          placeholder={placeholder}
          defaultOptions={defaultOptions}
          loadingMessage={() => 'Fetching your address book companies...'}
          loadOptions={loadOptions}
          formatOptionLabel={prefixMenuOptionsWithCheckIcon(formatCompanyLabel)}
          onChange={handleChange}
          isDisabled={isDisabled}
        />
      </div>
    </FieldWrapper>
  );
};

export default CompanySelector;
