import {
  LoadingSpinner,
  LoadingSpinnerSize,
  LoadingSpinnerTheme,
  joinClassNames,
} from '@estimateone/frontend-components';
import styles from './styles.module.scss';

type DrawerLoadingSpinnerProps = {
  refetchLoading?: boolean;
};

export const DrawerLoadingSpinner = ({ refetchLoading = false }: DrawerLoadingSpinnerProps) => (
  <div
    className={joinClassNames(
      styles.loadingSpinnerWrapper,
      refetchLoading && styles.refetchLoading,
    )}
  >
    <LoadingSpinner theme={LoadingSpinnerTheme.Light} size={LoadingSpinnerSize.Medium} />
  </div>
);
