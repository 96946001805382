import { Button, ButtonSize, Tooltip } from '@estimateone/frontend-components';
import { DownloadRequestResponse, PackageRequestBaseButtonProps, useDownloadDocs } from '../hooks';
import { useAnalytics, Action, Event } from '@ascension/components/hooks/Analytics';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

export type PackageRequestButtonProps = PackageRequestBaseButtonProps & {
  packageId: EntityId;
  requestPackage: (id: EntityId) => void;
  successCallback: (response: DownloadRequestResponse) => void;
};

export const RequestDocsStatusButton = ({
  text,
  tooltipText,
  disabled,
  packageId,
  requestPackage,
  successCallback,
  buttonType,
}: PackageRequestButtonProps) => {
  const { addEvent } = useAnalytics(Event.INTERACT);
  const { loading, fetch } = useDownloadDocs(packageId);

  const handleClick = async () => {
    addEvent({
      action: Action.PACKAGE_REQUESTED,
    });
    successCallback(await fetch());
    requestPackage(packageId);
  };

  return (
    <Tooltip
      tooltipId="request-docs-button"
      className={styles.requestDocsTooltip}
      text={tooltipText}
    >
      <Button
        onClick={handleClick}
        disabled={disabled || loading}
        tooltip={tooltipText}
        size={ButtonSize.Small}
        variant={buttonType}
        fullWidth
      >
        {text}
      </Button>
    </Tooltip>
  );
};
