import { Form, FormSpy } from 'react-final-form';
import { useMutation } from '@apollo/client';
import { Fieldset, Legend, RequiredIndicator } from '@estimateone/frontend-components';
import { Label } from '@estimateone/frontend-components/src/components/Form/Label';
import { Config } from 'final-form';
import { useOperatingCountryId } from '@ascension/components/localisation';
import {
  getServiceAreaRadius,
  getServiceAreaStates,
  getServiceAreaType,
} from '@profiles/features/CompanyProfile/components/OfficeContactList/EditOfficeDetailsDrawer/utils';
import styles from './CompanyCapabilitiesForm.module.scss';
import { MultiSelectDropdownField } from '@shared/FinalForm/Field/MultiSelectDropdownField';
import { transformFormApiErrors } from '@shared/FinalForm/transformers';
import {
  ContractSizeFilter,
  ContractSizeFilterFieldTypes,
  getContractSizeMaxOptionForValue,
  getContractSizeMinOptionForValue,
} from '@shared/form/ContractSizeFilter';
import { ServiceAreaFields } from '@shared/form/ServiceAreaFields';
import { ServiceAreaFieldTypes } from '@shared/form/ServiceAreaFields/ServiceAreaFields';
import { StockTradesFieldTypes, StockTradesMultiSelect } from '@shared/form/StockTradesMultiSelect';
import { useUserImperialSetting } from '@subbie/context/AccountProvider/hooks';
import { useServiceAreaBoundaryOptionsForCountry } from '@profiles/features/CompanyProfile/components/OfficeContactList/OfficeDetailsForm/hooks';
import { UPDATE_COMPANY_CAPABILITIES } from './mutations';
import {
  UpdateCompanyCapabilitiesMutation,
  UpdateCompanyCapabilitiesMutationVariables,
} from './mutations.generated';
import { ContractSizeEnum } from '@ascension/_gqltypes/profiles.generated';
import { EntityId } from '@ascension/types';
import { ValueLabelOption } from '@estimateone/frontend-components/src/components/Form/Select/types';
import { CompanyOffice } from '@profiles/providers/ProfileProvider/types';

type FormType = {
  coreStockTrades: ValueLabelOption<EntityId>[];
} & StockTradesFieldTypes &
  ServiceAreaFieldTypes &
  ContractSizeFilterFieldTypes;

type CompanyCapabilitiesFormProps = {
  initialStockTrades: {
    id: number;
    name: string;
    isCoreTrade: boolean;
  }[];
  initialContractSizeMin?: ContractSizeEnum | null;
  initialContractSizeMax?: ContractSizeEnum | null;
  initialServiceArea?: CompanyOffice['serviceArea'];
  onSuccess: () => void;
  formId: string;
};

const getServiceAreaInput = (
  values: FormType,
): UpdateCompanyCapabilitiesMutationVariables['input']['serviceArea'] => {
  switch (values.serviceAreaType) {
    case 'by-travel-distance':
      return {
        radius: values.serviceAreaRadius,
      };
    case 'by-states':
      return {
        boundaryIds: values.serviceAreaStates
          .filter((option) => option !== null)
          .map((option) => option.value),
      };
    case 'by-country':
      return {
        countryId: values.serviceAreaCountry,
      };
    default:
      throw new Error('Could not determine service area input');
  }
};

export const CompanyCapabilitiesForm = ({
  onSuccess,
  initialStockTrades,
  initialContractSizeMin,
  initialContractSizeMax,
  initialServiceArea,
  formId,
}: CompanyCapabilitiesFormProps) => {
  const imperialUnitSetting = useUserImperialSetting();
  const countryId = useOperatingCountryId();
  const { data: serviceAreaBoundaryOptions } = useServiceAreaBoundaryOptionsForCountry();
  const [update] = useMutation<
    UpdateCompanyCapabilitiesMutation,
    UpdateCompanyCapabilitiesMutationVariables
  >(UPDATE_COMPANY_CAPABILITIES);

  const validateForm = (values: FormType) => {
    /* eslint-disable fp/no-mutation */
    const validationErrors: Partial<Record<keyof FormType, string[]>> = {};
    const unitLabel = imperialUnitSetting ? 'mi' : 'km';
    const maxDistance = imperialUnitSetting ? 500 : 1000;
    const minDistanceInMeters = imperialUnitSetting ? 16093 : 10000;
    const maxDistanceInMeters = imperialUnitSetting ? 804670 : 1000000;

    if (values.serviceAreaRadius < minDistanceInMeters) {
      validationErrors.serviceAreaRadius = [`Distance must be at least 10${unitLabel}`];
    }

    if (values.serviceAreaRadius > maxDistanceInMeters) {
      validationErrors.serviceAreaRadius = [
        `Distance must be less than ${maxDistance}${unitLabel}`,
      ];
    }

    return validationErrors;
  };

  const onSubmit: Config<FormType>['onSubmit'] = async (values) => {
    const variables: UpdateCompanyCapabilitiesMutationVariables = {
      input: {
        stockTradeIds: values.stockTrades.map(({ value }) => value),
        contractSizeMax: values.contractSizeMax?.value,
        contractSizeMin: values.contractSizeMin?.value,
        coreTradeIds:
          values.stockTrades.length <= 2
            ? values.stockTrades.map(({ value }) => value)
            : values.coreStockTrades.map(({ value }) => value),
        serviceArea: getServiceAreaInput(values),
      },
    };

    const { data, errors } = await update({
      variables,
    });

    if (errors) {
      return null;
    }

    const result = data?.updateCompanyCapabilities;
    if (result?.__typename === 'Errors') {
      return transformFormApiErrors(
        {
          serviceArea: 'serviceAreaType',
          maxContractSize: 'contractSizeMax',
          minContractSize: 'contractSizeMin',
          stockTrades: 'stockTrades',
          coreTrades: 'coreStockTrades',
        } satisfies Record<string, keyof FormType>,
        result.errors,
      );
    }

    return onSuccess();
  };

  const serviceAreaType = initialServiceArea
    ? getServiceAreaType(initialServiceArea)
    : 'by-travel-distance';
  const serviceAreaRadius = getServiceAreaRadius(serviceAreaType, initialServiceArea);
  const serviceAreaStates = getServiceAreaStates(serviceAreaType, initialServiceArea);

  return (
    <Form<FormType>
      onSubmit={onSubmit}
      initialValues={
        {
          stockTrades: initialStockTrades.map(({ id, name }) => ({ value: id, label: name })),
          coreStockTrades: initialStockTrades
            .filter(({ isCoreTrade }) => isCoreTrade)
            .map(({ id, name }) => ({ value: id, label: name })),
          contractSizeMin: getContractSizeMinOptionForValue(initialContractSizeMin),
          contractSizeMax: getContractSizeMaxOptionForValue(initialContractSizeMax),
          serviceAreaCountry: countryId,
          serviceAreaType,
          serviceAreaRadius,
          serviceAreaStates,
        } satisfies FormType
      }
      keepDirtyOnReinitialize
      validate={validateForm}
    >
      {({ handleSubmit, values, form }) => (
        <form id={formId} onSubmit={handleSubmit} className={styles.form}>
          <FormSpy<FormType>
            subscription={{ values: true }}
            onChange={(formState) => {
              // if two or fewer stock trades, empty out the core stock trade field
              if (
                formState.values.stockTrades.length <= 2 &&
                formState.values.coreStockTrades.length !== 0
              ) {
                form.change('coreStockTrades', []);
              }

              const selectedCoreStockTrades = formState.values.coreStockTrades.filter(
                (coreStockTrade) =>
                  formState.values.stockTrades.find(
                    (stockTrade) => coreStockTrade.value === stockTrade.value,
                  ),
              );
              // if you removed a stock trade that was ALSO a core stock trade, remove the core stock trade
              if (selectedCoreStockTrades.length !== formState.values.coreStockTrades.length) {
                form.change('coreStockTrades', selectedCoreStockTrades);
              }
            }}
          />
          <div>
            <StockTradesMultiSelect />
          </div>
          {values.stockTrades.length > 2 && (
            <div className={styles.coreTrades}>
              {/* TODO: add a tooltip prop to the multi select on frontend components */}
              <Label
                htmlFor="coreStockTrades"
                tooltip="Core Trades highlight the trades you specialise in"
                isRequired
              >
                Core Trades
              </Label>
              <MultiSelectDropdownField
                id="coreStockTrades"
                name="coreStockTrades"
                inputId="coreStockTrades"
                fieldName="coreStockTrades"
                label="Core Trades"
                hideLabel
                options={values.stockTrades ?? []}
                isRequired
                placeholder="Select up to 2 trades..."
              />
            </div>
          )}
          <div>
            <ContractSizeFilter />
          </div>
          <Fieldset className={styles.serviceArea}>
            <Legend>
              Service Area <RequiredIndicator />
            </Legend>
            <ServiceAreaFields
              imperialUnitSetting={imperialUnitSetting ?? false}
              serviceAreaBoundaryOptions={serviceAreaBoundaryOptions}
              countryId={countryId}
            />
          </Fieldset>
        </form>
      )}
    </Form>
  );
};
