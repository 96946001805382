import { useQuery } from '@apollo/client';
import { useProjectApi } from '@subbie/modal/hooks';
import { DOCUMENT_HIGHLIGHTS_FOR_STAGE_KEYWORD_MATCHES } from '@subbie/modal/queries';
import { EntityId } from '@ascension/types';
import {
  DocumentHighlightsForStageKeywordMatches,
  DocumentHighlightsForStageKeywordMatchesVariables,
  DocumentHighlightsForStageKeywordMatches_documentHighlightsForStageKeywordMatches_stage as Stage,
  DocumentHighlightsForStageKeywordMatches_documentHighlightsForStageKeywordMatches_keywordWithDocumentHighlights as KeywordWithDocumentHighlights,
} from '@subbie/modal/types/DocumentHighlightsForStageKeywordMatches';

export type DocumentHighlightsProject = Pick<
  NonNullable<ReturnType<typeof useProjectApi>['projectSliderData']>['project'],
  'id' | 'watchlistEntry'
>;

export type DocumentHighlightsStage = Omit<Stage, 'projectId'> & {
  project: DocumentHighlightsProject;
};

type HookResponse = {
  loading: boolean;
  stage: DocumentHighlightsStage | null;
  keywordWithDocumentHighlights: KeywordWithDocumentHighlights[];
};

export const useDocumentHighlightsForStageMap = (stageId?: EntityId): HookResponse => {
  const { loading, data } = useQuery<
    DocumentHighlightsForStageKeywordMatches,
    DocumentHighlightsForStageKeywordMatchesVariables
  >(
    DOCUMENT_HIGHLIGHTS_FOR_STAGE_KEYWORD_MATCHES,
    stageId
      ? {
          variables: { stageId },
          fetchPolicy: 'no-cache',
        }
      : { skip: true },
  );

  const stage = data?.documentHighlightsForStageKeywordMatches.stage;
  const { projectSliderData } = useProjectApi(stage?.projectId);

  const stageWithProject =
    stage && projectSliderData
      ? {
          ...stage,
          project: projectSliderData.project,
        }
      : undefined;

  return {
    stage: stageWithProject ?? null,
    keywordWithDocumentHighlights:
      data?.documentHighlightsForStageKeywordMatches.keywordWithDocumentHighlights ?? [],
    loading,
  };
};
