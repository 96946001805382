import { useCallback } from 'react';
import { useField } from 'react-final-form';
import { AsyncSelect, AsyncSelectProps } from '@estimateone/frontend-components';
import { debounce } from 'lodash';
import { FieldWrapper } from '@shared/FinalForm/Field';
import { usePromiseQuery } from '@shared/Util';
import { SEARCH_ADDRESS_BOOK_COMPANIES } from './queries';
import {
  SearchAddressBookCompanies as CompaniesQuery,
  SearchAddressBookCompaniesVariables as CompaniesQueryVars,
  SearchAddressBookCompanies_searchCompanyNames as AddressBookCompany,
} from './types/SearchAddressBookCompanies';

export type AddressBookCompanyOption = Pick<AddressBookCompany, 'label' | 'value'>;

type CompanySelectorProps = AsyncSelectProps<AddressBookCompanyOption, false> & {
  fieldName: string;
  onSelectCompany?: () => void;
  debounceWait?: number;
};

export const AddressBookCompanySelectField = ({
  fieldName,
  id,
  label,
  debounceWait = 500,
  isRequired,
  className,
  ...props
}: CompanySelectorProps) => {
  const {
    input: { name, ...companyInputProps },
    meta: { error, submitError, touched },
  } = useField<AddressBookCompany>(fieldName, {
    validate: (value) =>
      isRequired
        ? value
          ? undefined
          : ['Start typing the name of a company in your Address Book']
        : undefined,
  });

  const fetchCompanies = usePromiseQuery<CompaniesQuery, CompaniesQueryVars>(
    SEARCH_ADDRESS_BOOK_COMPANIES,
  );

  const handleLoadCompanyOptions = (
    inputValue: string,
    callback: (companies: AddressBookCompany[]) => void,
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchCompanies({ query: inputValue }).then(({ data: { searchCompanyNames: companies } }) =>
      callback(companies),
    );
  };

  const handleLoadCompanyOptionsDebounced = useCallback(
    debounce(handleLoadCompanyOptions, debounceWait),
    [handleLoadCompanyOptions],
  );

  const errors = error || submitError;

  return (
    <FieldWrapper errors={errors} showErrors={touched}>
      <div data-name={name} className={className}>
        <AsyncSelect<AddressBookCompanyOption, false>
          {...companyInputProps}
          id={id}
          label={label}
          inputId={`${id}--input`}
          name={name}
          placeholder="Type at least 3 characters to search your Address Book"
          loadingMessage={() => 'Fetching your address book companies...'}
          loadOptions={handleLoadCompanyOptionsDebounced}
          isRequired={isRequired}
          {...props}
        />
      </div>
    </FieldWrapper>
  );
};
