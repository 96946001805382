import { PriorityCriticalIcon } from '@estimateone/frontend-components';
import { format as formatDate } from 'date-fns';
import { htmlDecode } from '@builder/utils/htmlDecode';
import { GetNotesForLettingSchedule_package_lettingScheduleDetails_notes as LettingScheduleNote } from '../../LettingScheduleNotesSlideout/types/GetNotesForLettingSchedule';
import { CriticalStatus } from '@ascension/_gqltypes/builder.generated';
import styles from './styles.scss';

export type LettingScheduleNotesHistoryItemProps = {
  note: LettingScheduleNote;
};

export const LettingScheduleNotesHistoryItem = ({ note }: LettingScheduleNotesHistoryItemProps) => (
  <div className={styles.noteHistoryItemContainer}>
    <div className={styles.noteHistoryItemNoteTextContainer}>
      {note.isCritical === CriticalStatus.IS_CRITICAL && (
        <div className={styles.noteHistoryItemFlag}>
          <PriorityCriticalIcon />
        </div>
      )}
      <p className={styles.noteHistoryItemNoteText}>{htmlDecode(note.text)}</p>
    </div>
    <div className={styles.noteHistoryItemNoteDetails}>{`${note.authorName} · ${formatDate(
      new Date(note.createdAt),
      'h:mmaaa, d MMM yyyy',
    )}`}</div>
  </div>
);
