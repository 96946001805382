/**
 * A generic implementation of Web Storage API to be extended
 */
export default abstract class AppStorageService<T extends string | number | boolean | object> {
  private readonly primaryKey: string;

  private readonly storage: Storage;

  private readonly hasStorage: boolean;

  protected constructor(primaryKey: string, storage: Storage) {
    this.primaryKey = primaryKey;
    this.storage = storage;

    this.hasStorage = ((s) => {
      const test = 'test';
      try {
        s.setItem(test, test);
        s.removeItem(test);
        return true;
      } catch {
        return false;
      }
    })(this.storage);
  }

  private getStorageKey = (secondaryKey: string | null): string =>
    secondaryKey ? `${this.primaryKey}-${secondaryKey}` : this.primaryKey;

  private setItem(secondaryKey: string | null, value: string): void {
    this.storage.setItem(this.getStorageKey(secondaryKey), btoa(value));
  }

  private getItem(secondaryKey: string | null): string | undefined {
    const rawValue = this.storage.getItem(this.getStorageKey(secondaryKey));
    if (!rawValue) return undefined;
    if (rawValue === '[object Object]') return undefined;

    return atob(rawValue);
  }

  public persistItem(newValue: T, secondaryKey: string | null = null): void {
    if (!this.hasStorage) return;
    const value = typeof newValue === 'object' ? JSON.stringify(newValue) : newValue.toString();
    this.setItem(secondaryKey, value);
  }

  /**
   * Returns a serialized JSON object or a stored value as a string
   * @param secondaryKey
   */
  public retrieveItem(secondaryKey: string | null = null): T | undefined {
    if (!this.hasStorage) return undefined;
    const rawValue = this.getItem(secondaryKey);

    if (rawValue === undefined) {
      return undefined;
    }

    try {
      return JSON.parse(rawValue) as T;
    } catch (err) {
      if (err instanceof SyntaxError) return rawValue as unknown as T;
      return undefined;
    }
  }

  public removeItem = (secondaryKey: string | null = null): void => {
    if (!this.hasStorage) return;
    this.storage.removeItem(this.getStorageKey(secondaryKey));
  };
}
