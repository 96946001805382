import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import {
  FullScreenModal,
  Stepper,
  getStatus,
  StepperVariant,
  joinClassNames,
} from '@estimateone/frontend-components';
import { PageWrapper } from './PageWrapper';
import { RevisionFlowStep } from './RevisionFlowStep';
import { NotifyStep, AllocateStep, ReviewStep } from './steps';
import { StageType } from '@ascension/enums';
import { Addendum } from './types';
import { StageDocumentIntegrationType } from '@ascension/_gqltypes/builder.generated';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

type RevisionFlowContextState = {
  stageId: EntityId;
  stageType: StageType;
  documentIntegrationType: StageDocumentIntegrationType;
  close: () => void;
  complete: () => void;
  setCurrentStep: (step: RevisionFlowStep) => void;
  goToPreviousStep: () => void;
  addendum: Addendum;
};

const RevisionFlowContext = createContext<RevisionFlowContextState>(
  null as unknown as RevisionFlowContextState,
);

export const useRevisionFlow = () => useContext(RevisionFlowContext);

export type RevisionFlowProps = {
  stageId: EntityId;
  stageType: StageType;
  documentIntegrationType: StageDocumentIntegrationType;
  addendum: Addendum;
  isOpen: boolean;
  onClose: () => Promise<void>;
  onCompletion: () => Promise<void>;
};

export const RevisionFlow = ({
  stageId,
  stageType,
  documentIntegrationType,
  addendum,
  isOpen,
  onClose,
  onCompletion,
}: RevisionFlowProps) => {
  const [currentStep, setCurrentStep] = useState<RevisionFlowStep>(RevisionFlowStep.REVIEW);

  const resetAndClose = useCallback(async () => {
    setCurrentStep(RevisionFlowStep.REVIEW);
    await onClose();
  }, [onClose]);

  const completeAndClose = useCallback(async () => {
    await onCompletion();
    await resetAndClose();
  }, [onCompletion, resetAndClose]);

  const title =
    currentStep === RevisionFlowStep.NOTIFY
      ? 'Notify'
      : currentStep === RevisionFlowStep.ALLOCATE
        ? 'Allocate to Packages'
        : 'Review Files';

  const goToPreviousStepHandler = useCallback(() => {
    if (currentStep - 1 > 0) {
      setCurrentStep(currentStep - 1);
    }
  }, [currentStep]);

  const defaultValue = useMemo<RevisionFlowContextState>(
    () => ({
      stageId,
      stageType,
      documentIntegrationType,
      addendum,
      close: resetAndClose,
      complete: completeAndClose,
      setCurrentStep,
      goToPreviousStep: goToPreviousStepHandler,
    }),
    [
      addendum,
      completeAndClose,
      goToPreviousStepHandler,
      resetAndClose,
      stageId,
      stageType,
      documentIntegrationType,
    ],
  );

  const fileSource =
    documentIntegrationType === StageDocumentIntegrationType.PROCORE ? 'Procore' : 'SharePoint';

  return (
    <RevisionFlowContext.Provider value={defaultValue}>
      <FullScreenModal
        isOpen={isOpen}
        overlayClassName={joinClassNames('helper-aware', styles.overlay)}
        className="helper-aware"
      >
        <FullScreenModal.Header>
          <PageWrapper>
            <div className={styles.headerContainer}>
              <h2 className={styles.title}>
                Updates from {fileSource}: {title}
              </h2>
              <Stepper variant={StepperVariant.Light}>
                <Stepper.Step
                  stepNumber={RevisionFlowStep.REVIEW}
                  label="Review"
                  status={getStatus(RevisionFlowStep.REVIEW, currentStep)}
                  onClick={() => setCurrentStep(RevisionFlowStep.REVIEW)}
                />
                <Stepper.Step
                  stepNumber={RevisionFlowStep.ALLOCATE}
                  label="Allocate"
                  status={getStatus(RevisionFlowStep.ALLOCATE, currentStep)}
                  onClick={() => setCurrentStep(RevisionFlowStep.ALLOCATE)}
                />
                <Stepper.Step
                  stepNumber={RevisionFlowStep.NOTIFY}
                  label="Notify"
                  status={getStatus(RevisionFlowStep.NOTIFY, currentStep)}
                  onClick={() => setCurrentStep(RevisionFlowStep.NOTIFY)}
                />
              </Stepper>
            </div>
          </PageWrapper>
        </FullScreenModal.Header>

        {currentStep === RevisionFlowStep.NOTIFY ? (
          <NotifyStep />
        ) : currentStep === RevisionFlowStep.ALLOCATE ? (
          <AllocateStep />
        ) : (
          <ReviewStep />
        )}
      </FullScreenModal>
    </RevisionFlowContext.Provider>
  );
};

// eslint-disable-next-line fp/no-mutation
RevisionFlow.displayName = 'RevisionFlow';
