import { ChangeEvent, useEffect, useState } from 'react';
import { joinClassNames } from '@estimateone/frontend-components';
import styles from './styles.scss';

export type InlineEditableIntegerProps = {
  value: number | undefined;
  onValueChange: (value: number | null) => void;
} & JSX.IntrinsicElements['input'];

const valueAsString = (value: number | undefined): string =>
  value === undefined ? '' : String(value);

export const InlineEditableInteger = ({
  value,
  onValueChange,
  className,
  ...props
}: InlineEditableIntegerProps) => {
  const [latestValue, setLatestValue] = useState<string>(valueAsString(value));
  const [isValid, setIsValid] = useState(true);
  useEffect(() => setLatestValue(valueAsString(value)), [value]);

  return (
    <>
      <input
        className={joinClassNames(
          styles.editableNumber,
          className,
          !isValid ? styles.errorState : '',
        )}
        value={latestValue}
        placeholder="Add..."
        type="number"
        min="0"
        max="999"
        step="1"
        onWheel={(e) => {
          e.currentTarget.blur();
        }}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const isEmpty = e.target.value === '';
          const isValidAfterChange = isEmpty || e.target.checkValidity();
          setIsValid(isValidAfterChange);
          setLatestValue(isEmpty ? '' : valueAsString(parseFloat(e.target.value)));
          if (isValidAfterChange) {
            onValueChange(isEmpty ? null : parseFloat(e.target.value));
          }
        }}
        onBlur={(e) => {
          if (e.target.value === '') {
            setLatestValue(valueAsString(value));
          }
        }}
        {...props}
      />
      {!isValid && <div className={styles.errorMessage}>Enter a whole number between 0-999</div>}
    </>
  );
};
