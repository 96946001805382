import Routing from 'routing';
import {
  ButtonSize,
  ButtonVariant,
  HelpBanner,
  HelpBannerContent,
  HelpBannerToggle,
  Tag,
  TagVariant,
  TextLink,
  usePersistentIsHidden,
} from '@estimateone/frontend-components';
import styles from './NetworkInvitationsHelpBanner.module.scss';

type NetworkHelpBannerProps = {
  userId: number;
  stageId: number;
};

export const NetworkInvitationsHelpBanner = ({ userId, stageId }: NetworkHelpBannerProps) => {
  const { isHidden, toggleHidden } = usePersistentIsHidden(
    `unified-invitation-flow-help-banner-${userId.toString(10)}`,
  );

  return (
    <HelpBanner isHidden={isHidden} toggleHidden={toggleHidden} className={styles.helpBanner}>
      <HelpBannerContent>
        <div className={styles.helpBannerTitle}>
          Invite contractors from your Address Book and the E1 Network{' '}
          <Tag variant={TagVariant.Practical500} text="BETA" />
        </div>

        <p>
          This beta version is an early release, so you might notice a few quirks as we continue to
          refine and <br />
          improve the experience.
        </p>
        <p>
          <span className={styles.highlightedText}>Note:</span> You can still use the{' '}
          <TextLink
            href={Routing.generate('app_stage_invitations', {
              stageType: 'tender',
              id: stageId,
            })}
            external
          >
            classic invite experience
          </TextLink>{' '}
          via the project dashboard.
        </p>
        <div className={styles.helpBannerFooter}>
          <HelpBannerToggle variant={ButtonVariant.Grey} size={ButtonSize.Small}>
            Ok, got it!
          </HelpBannerToggle>
        </div>
      </HelpBannerContent>
    </HelpBanner>
  );
};
