import { gql } from '@apollo/client';

export const UPGRADE_LITE_TO_ESSENTIAL = gql`
  mutation UpgradeLiteToEssential($input: UpgradeLiteToEssentialInput!) {
    upgradeLiteToEssential(input: $input) {
      ... on UpgradedLicenseResult {
        from {
          id
        }
        to {
          license {
            id
          }
          invoiceUrl
        }
      }
      ... on Errors {
        errors {
          message
          field
        }
      }
    }
  }
`;
