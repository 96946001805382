import { useEffect, useState } from 'react';
import { Slider as UISlider } from '@estimateone/frontend-components';
import variables from '../../../../../css/js-variables.scss';
import SubbieProfilesSliderView from './SubbieProfilesSliderView';
import { BuilderAccountProvider } from '@builder/context/AccountProvider/BuilderAccountProvider';
import { EntityId } from '@ascension/types';

type SubbieProfilesSliderProps = {
  profilesAscensionUrl: string;
  accountId: EntityId;
  isOpen: boolean;
  onRequestClose: () => void;
};

const Slider = ({
  profilesAscensionUrl,
  accountId,
  isOpen,
  onRequestClose,
}: SubbieProfilesSliderProps) => (
  <UISlider isOpen={isOpen} onRequestClose={() => onRequestClose()} width={variables.sliderWidth}>
    <SubbieProfilesSliderView accountId={accountId} profilesAscensionUrl={profilesAscensionUrl} />
  </UISlider>
);

const SubbieProfilesSlider = ({
  profilesAscensionUrl,
  accountId,
  isOpen,
  onRequestClose,
}: SubbieProfilesSliderProps) => (
  <BuilderAccountProvider>
    <Slider
      profilesAscensionUrl={profilesAscensionUrl}
      isOpen={isOpen}
      accountId={accountId}
      onRequestClose={onRequestClose}
    />
  </BuilderAccountProvider>
);

type ProfileSliderOpenEvent = {
  detail: {
    accountId: EntityId;
  };
} & Event;

const SubbieProfilesSliderWithEventListener = ({
  profilesAscensionUrl,
}: {
  profilesAscensionUrl: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [accountId, setAccountId] = useState<EntityId>();

  const handleOpen = (e: ProfileSliderOpenEvent) => {
    setAccountId(e.detail.accountId);
    setIsOpen(true);
  };

  useEffect(() => {
    document.addEventListener('profilesSliderOpen', handleOpen);
    return () => {
      document.removeEventListener('profilesSliderOpen', handleOpen);
    };
  });

  return accountId ? (
    <SubbieProfilesSlider
      profilesAscensionUrl={profilesAscensionUrl}
      accountId={accountId}
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
    />
  ) : null;
};

export { SubbieProfilesSliderWithEventListener };
export default SubbieProfilesSlider;
