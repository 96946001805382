import { Dispatch, SetStateAction } from 'react';
import { Col, DatePicker, DatePickerFormat, Row } from '@estimateone/frontend-components';
import styles from './styles.module.scss';

export type PackageActionsBarProps = {
  packageTitle: string | undefined;
  quoteDueAt: Date | null;
  setQuoteDueAt: Dispatch<SetStateAction<Date | null>>;
};

const PackageActionsBar = ({ quoteDueAt, setQuoteDueAt, packageTitle }: PackageActionsBarProps) => {
  const onQuoteDueChange = (date: Date) => {
    setQuoteDueAt(date);
  };

  return (
    <div className={styles.packageActionsBarContainer}>
      <form>
        <Row>
          <Col span={6} alignContentY="center" excludeBottomGutter>
            <div className={styles.packageTitle}>{packageTitle ?? ''}</div>
          </Col>
          <Col
            span={6}
            alignContentX="right"
            alignContentY="center"
            excludeBottomGutter
            className={styles.packageQoutesDueDatePicker}
          >
            <Row>
              <Col alignContentY="center" excludeBottomGutter>
                <label className={styles.quoteDueLabel} htmlFor="subbie-network-quotes-due">
                  Quotes Due
                </label>
              </Col>
              <Col alignContentY="center" excludeBottomGutter>
                <DatePicker
                  id="subbie-network-quotes-due"
                  label="Quotes Due"
                  hideLabel
                  placeholderText="Select quote due date"
                  onChange={onQuoteDueChange}
                  selected={quoteDueAt}
                  isRequired
                  popperPlacement="bottom"
                  dateFormat={DatePickerFormat.DateOnlyShortMonth}
                  popperModifiers={[
                    { name: 'flip', enabled: true, options: { fallbackPlacements: ['bottom'] } },
                  ]}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    </div>
  );
};
export { PackageActionsBar };
