import { ReactNode } from 'react';
import {
  AlertIcon,
  Avatar,
  joinClassNames,
  SkeletonLoader,
  TextLink,
} from '@estimateone/frontend-components';
import { TagGroup } from '@profiles/features/CompanyProfile/components/NewTagGroup';
import { CardBody } from '../Card';
import styles from './PrimaryContact.module.scss';
import { useOwnAccount } from '@profiles/features/CompanyProfile/hooks';
import { CompanyContact } from '@profiles/providers/ProfileProvider/types';

export const PrimaryContactViewWrapper = ({ children }: { children: ReactNode }) => (
  <CardBody>
    <div className={styles.primaryContactWrapper}>{children}</div>
  </CardBody>
);

export const PrimaryContactErrorView = () => (
  <PrimaryContactViewWrapper>
    <TagGroup header="Contact">
      <AlertIcon title="Contact error icon" />
    </TagGroup>
  </PrimaryContactViewWrapper>
);

export const PrimaryContactLoader = () => (
  <PrimaryContactViewWrapper>
    <TagGroup header="Contact">
      <SkeletonLoader height="16px" count={2} />
    </TagGroup>
  </PrimaryContactViewWrapper>
);

type PrimaryContactProps = {
  primaryContact: CompanyContact | null;
};

export const PrimaryContact = ({ primaryContact }: PrimaryContactProps) => {
  const { isOwnAccount } = useOwnAccount();

  // Contacts for subbie owner view are shown in a different component
  if (isOwnAccount || primaryContact === null) return null;

  const { id, firstName, lastName, contactable } = primaryContact;
  const { email, phone } = contactable ?? {};

  return (
    <PrimaryContactViewWrapper>
      <div className={styles.primaryContactHeadingContainer}>
        <h4 className={styles.heading}>Contact</h4>
      </div>
      <Avatar
        firstName={firstName ?? ''}
        lastName={lastName ?? ''}
        userId={Number(id)}
        size={64}
        className={styles.avatar}
      />
      {(firstName || lastName) && (
        <p className={styles.name}>{joinClassNames(firstName, lastName)}</p>
      )}
      {email && (
        <div>
          <TextLink className={styles.email} href={`mailto:${email}`}>
            {email}
          </TextLink>
        </div>
      )}
      {phone && <p className={styles.phone}>{phone}</p>}
    </PrimaryContactViewWrapper>
  );
};
