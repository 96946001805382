/* eslint-disable no-console */

export const getAppcues = (): typeof Appcues | undefined => {
  if (typeof window.Appcues !== 'undefined') {
    return window.Appcues;
  }

  if (process.env.NODE_ENV !== 'production') {
    return {
      show: (flowId: string) => console.log('Appcues.show, flowId: ', flowId),
      track: (event: string, data?: Record<string, unknown>) => {
        console.log('Appcues.track, trackingEvent:', event);
        console.log('Appcues.track, data: ', data);
      },
    };
  }

  return undefined;
};
