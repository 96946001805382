import { useMutation } from '@apollo/client';
import { reportError } from '@ascension/components/helpers/errorReporter';
import { WorkforceSizeFieldTypes } from '@shared/form/WorkforceSizeSingleSelect/WorkforceSize';
import { UPDATE_CONTRACT_AND_WORKFORCE_SIZE } from './mutations';
import {
  UpdateContractAndWorkforceSizeMutation,
  UpdateContractAndWorkforceSizeMutationVariables,
} from './mutations.generated';
import { EditContractSizeFieldValues } from '../../ContractSizeBadge/EditContractSize/types';

const transformValues = ({
  workforceSize,
  contractSizeMin,
  contractSizeMax,
}: WorkforceSizeFieldTypes &
  EditContractSizeFieldValues): UpdateContractAndWorkforceSizeMutationVariables => ({
  workforceSize: workforceSize?.value ?? null,
  contractSizeMin: contractSizeMin.value,
  contractSizeMax: contractSizeMax.value,
});

export const useSubmitForm = () => {
  const [updateContractAndWorkforceSize, { error }] = useMutation<
    UpdateContractAndWorkforceSizeMutation,
    UpdateContractAndWorkforceSizeMutationVariables
  >(UPDATE_CONTRACT_AND_WORKFORCE_SIZE, {
    context: { hasCustomNetworkErrorHandler: true },
    onError: (apolloError) => reportError(apolloError),
  });

  const submit = async (values: WorkforceSizeFieldTypes & EditContractSizeFieldValues) => {
    const { errors, data } = await updateContractAndWorkforceSize({
      variables: transformValues(values),
    });
    return { data, errors };
  };

  return { submit, error };
};
