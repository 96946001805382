import { useCallback } from 'react';
import {
  LazyQueryExecFunction,
  MutationResult,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import { useFlashMessage } from '@shared';
import { useInExperiment } from '@subbie/context/AccountProvider/hooks';
import {
  useWatchlistContext,
  WATCHLIST_UPDATE_EVENT,
  WatchlistUpdateEventPayload,
} from '@subbie/context/WatchlistProvider';
import { Action, Event, useAnalytics } from '@ascension/components/hooks/Analytics';
import useApolloCache from '@ascension/components/hooks/ApolloCache';
import {
  useHasSubbieSupplierConnectionAccess,
  useSubbieConnectionCreditBalance,
} from '@subbie/common/CreditBalance/hooks';
import { useShowProjectUnlockedSuccessMessages } from '@subbie/modal/ProjectSlider/hooks/useShowProjectUnlockedSuccessMessages';
import { GET_CONNECTION_PREFERENCE } from '../queries';
import { GET_PROJECT_INTERESTED_SUBBIES } from '@subbie/modal/SubbieListSlider/SubbieListSliderContent/queries';
import { UNLOCK_PROJECT_SUBBIES } from './mutations';
import { ProjectSubbiesUnlock, ProjectSubbiesUnlockVariables } from './types/ProjectSubbiesUnlock';
import { ExperimentName } from './types/global';
import { EntityId } from '@ascension/types';
import {
  GetConnectionPreference,
  GetConnectionPreference_connectionPreference as ConnectionPreference,
  GetConnectionPreferenceVariables,
} from '@subbie/modal/ProjectSlider/ProjectSliderBody/types/GetConnectionPreference';
import { Project } from '@subbie/modal/ProjectSlider/types';
import {
  SubbieListProjectInterestedSubbies_projectInterestedSubbies as ProjectInterestedSubbie,
  SubbieListProjectInterestedSubbies,
} from '@subbie/modal/SubbieListSlider/SubbieListSliderContent/types/SubbieListProjectInterestedSubbies';

export const useInSSCTrialExperiment = (): boolean => {
  const sscTrialLimit = 1;
  const inExperiment = useInExperiment(ExperimentName.SUPPLIER_SSC_TRIAL);
  const { subbieConnectionCreditBalance } = useSubbieConnectionCreditBalance();

  return inExperiment && subbieConnectionCreditBalance?.allowed === sscTrialLimit;
};

const useUnlockProjectSubbies = () => {
  const { updateElementsInCache } = useApolloCache();
  const [projectSubbiesUnlock, { loading }] = useMutation<
    ProjectSubbiesUnlock,
    ProjectSubbiesUnlockVariables
  >(UNLOCK_PROJECT_SUBBIES, {
    update(_, { data }: MutationResult<ProjectSubbiesUnlock>) {
      if (data) {
        const { projectSubbiesUnlock: unlockedSubbies } = data;
        if (unlockedSubbies.__typename === 'ProjectSubbiesUnlockSuccess') {
          const ids = unlockedSubbies.projectInterestedSubbies.map(({ id }) => id);
          updateElementsInCache(
            'projectInterestedSubbies',
            unlockedSubbies.projectInterestedSubbies,
            ({ id }: ProjectInterestedSubbie) => !ids.includes(id),
          )<SubbieListProjectInterestedSubbies>({
            query: GET_PROJECT_INTERESTED_SUBBIES,
            variables: {
              projectId: unlockedSubbies.currentSummary.projectId,
            },
          });
        }
      }
    },
  });

  const submit = async (projectId: EntityId) => {
    const { data } = await projectSubbiesUnlock({ variables: { projectId } });
    return data?.projectSubbiesUnlock;
  };

  return { submit, loading };
};

export const useUnlockSubcontractorsHandler = (
  project: Project,
): {
  unlockInProgress: boolean;
  unlockSubcontractorsHandler: () => void;
} => {
  const { submit, loading: unlockInProgress } = useUnlockProjectSubbies();
  const { warning: showWarningMessage } = useFlashMessage();
  const { showSuccessMessages } = useShowProjectUnlockedSuccessMessages();
  const { addEvent } = useAnalytics(Event.INTERACT);
  const watchlistContext = useWatchlistContext();
  const { id: projectId, name: projectName, watchlistEntry } = project;
  const previousWatchlistStatus =
    watchlistContext.statusesByProject[projectId] ?? watchlistEntry?.status;

  const unlockSubcontractorsHandler = useCallback(async () => {
    const response = await submit(projectId);
    if (response === undefined) {
      return;
    }
    const responseType = response.__typename;
    // Don't need a default case if the switch-exhaustiveness-check rule is already checking that each value in the union is covered
    // eslint-disable-next-line default-case
    switch (responseType) {
      case 'ProjectSubbiesUnlockSuccess':
        showSuccessMessages({
          projectId,
          projectName,
          previousWatchlistStatus,
          newWatchlistStatus: response.currentWatchlistInterestLevel,
        });

        addEvent({
          action: Action.SSC_SUBBIE_LIST_MODAL_UNLOCK_BUTTON_CLICKED,
          projectId: project.id,
        });
        // Ensure that the watchlist table HTML (rendered on the server via TWIG) is updated
        document.dispatchEvent(
          new CustomEvent<WatchlistUpdateEventPayload>(WATCHLIST_UPDATE_EVENT, {
            detail: {
              updateType: 'ssc_status',
              projectId,
              projectSubbieSummary: response.currentSummary,
            },
          }),
        );

        break;
      case 'ProjectSubbiesUnlockErrorNoCreditsLeft':
        showWarningMessage({
          title: 'Error',
          message: 'You do not have enough credits to unlock this project',
        });
        break;
    }
  }, [
    submit,
    projectId,
    showSuccessMessages,
    projectName,
    previousWatchlistStatus,
    showWarningMessage,
  ]);

  return {
    unlockInProgress,
    unlockSubcontractorsHandler,
  };
};

type ConnectionPreferenceResult = {
  readonly connectionPreference: ConnectionPreference | undefined;
  readonly loading: boolean;
};

type ConnectionPreferenceLazyResult = ConnectionPreferenceResult & {
  readonly getConnectionPreference:
    | LazyQueryExecFunction<GetConnectionPreference, GetConnectionPreferenceVariables>
    | undefined;
};

export const useConnectionPreference = (projectId: number): ConnectionPreferenceResult => {
  const canViewSubbiesSection = useHasSubbieSupplierConnectionAccess();
  const { data, loading } = useQuery<GetConnectionPreference, GetConnectionPreferenceVariables>(
    GET_CONNECTION_PREFERENCE,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        projectId,
      },
      skip: !canViewSubbiesSection,
    },
  );

  return {
    connectionPreference: data?.connectionPreference,
    loading,
  };
};

export const useConnectionPreferenceLazy = (): ConnectionPreferenceLazyResult => {
  const canViewSubbiesSection = useHasSubbieSupplierConnectionAccess();
  const [getConnection, { data, loading }] = useLazyQuery<
    GetConnectionPreference,
    GetConnectionPreferenceVariables
  >(GET_CONNECTION_PREFERENCE, {
    fetchPolicy: 'cache-and-network',
  });

  if (!canViewSubbiesSection) {
    return {
      connectionPreference: undefined,
      loading: false,
      getConnectionPreference: undefined,
    };
  }

  return {
    connectionPreference: data?.connectionPreference,
    loading,
    getConnectionPreference: getConnection,
  };
};
