import { gql } from '@apollo/client';

export const NOTES_FOR_LETTING_SCHEDULE = gql`
  query GetNotesForLettingSchedule($packageId: EntityId!) {
    package(id: $packageId) {
      id
      lettingScheduleDetails {
        id
        notes {
          id
          text
          authorName
          isCritical
          createdAt
        }
      }
    }
  }
`;
