import React, { useContext, useEffect, useState } from 'react';
import { Col, LoadingSpinner, Row, SkeletonLoader } from '@estimateone/frontend-components';
import { captureException } from '@sentry/browser';
import { getLocationForPostcode } from '@builder/common/SubbieNetwork/utils/searchArea/google';
import CompaniesResults from '@builder/features/SubbieNetworkInvitations/components/CompaniesResults';
import { FullSetOnlyAlert } from '@builder/features/SubbieNetworkInvitations/components/CompaniesResults/FullSetOnlyAlert';
import { ConfirmInvitesModal } from '@builder/features/SubbieNetworkInvitations/components/ConfirmInvitesModal';
import { FilterActionsBar } from '@builder/features/SubbieNetworkInvitations/components/FilterActionsBar';
import { placePredictionsDefaultTypes } from '@builder/features/SubbieNetworkInvitations/components/FilterActionsBar/FilterDrawer/PlaceSuggestingSelect';
import { InvitesErrorModal } from '@builder/features/SubbieNetworkInvitations/components/InvitesProgressModal/InvitesErrorModal/InvitesErrorModal';
import { NetworkInvitationsHelpBanner } from '@builder/features/SubbieNetworkInvitations/components/NetworkInvitationsHelpBanner';
import { PackageActionsBar } from '@builder/features/SubbieNetworkInvitations/components/PackageActionsBar';
import { PackageSelectionSidebar } from '@builder/features/SubbieNetworkInvitations/components/PackageSelectionSidebar';
import {
  isPackageEmpty,
  matchPackageToTrade,
  hasOnlyFullSetPackage,
} from '@builder/features/SubbieNetworkInvitations/utils';
import { InvitesProgressModal } from '../components/InvitesProgressModal/InvitesProgressModal';
import { useFlashMessage } from '@shared/Util';
import { pluralise } from '@shared/Util/pluralise';
import { useAccountPrimaryCountryId } from '@builder/context/SubbieNetworkFilterDataProvider/hooks';
import { NetworkSearchContext } from '@builder/features/SubbieNetworkInvitations/context/NetworkSearchProvider';
import { SelectedContactsContext } from '@builder/features/SubbieNetworkInvitations/context/SelectedContactsProvider';
import {
  useCurrentUser,
  useGetStageForNetworkInvites,
  useInviteContactsToPackage,
} from '@builder/features/SubbieNetworkInvitations/hooks';
import { Country } from '@ascension/enums';
import { ID } from '@builder/common/SubbieNetwork/types';
import { Package } from '@builder/features/SubbieNetworkInvitations/types';
import styles from './styles.module.scss';

type UnifiedInvitesProps = {
  stageId: number;
  isConfirmInvitesModalOpen: boolean;
  setIsConfirmInvitesModalOpen: (isOpen: boolean) => void;
};

export const UnifiedInvites = ({
  stageId,
  isConfirmInvitesModalOpen,
  setIsConfirmInvitesModalOpen,
}: UnifiedInvitesProps) => {
  const { stage, loading: loadingPackages } = useGetStageForNetworkInvites(stageId);
  const getPackageNameById = (packageId: ID): string =>
    stage?.activePackages.find((pkg) => pkg.id === packageId)?.title ?? '';

  const [selectedPackage, setSelectedPackage] = useState<Package | undefined>(undefined);
  const [quoteDue, setQuoteDue] = useState<Date | null>(
    selectedPackage?.quotesDueAt ? new Date(selectedPackage.quotesDueAt) : null,
  );

  const { submit: submitInvitations, loading: requestingInvitations } =
    useInviteContactsToPackage();
  const { success: notifyInvitationSuccess } = useFlashMessage();
  const [isInvitesProgressModalOpen, setIsInvitesProgressModalOpen] = useState(false);
  const [isInvitesErrorModalOpen, setIsInvitesErrorModalOpen] = useState(false);
  const [sentInvitationsCount, setSentInvitationsCount] = useState(0);
  const [sendInvitationsErrors, setSendInvitationsErrors] = useState<string[]>([]);
  const [packagesWithInvitationErrors, setPackagesWithInvitationErrors] = useState<ID[]>([]);

  const currentUser = useCurrentUser();
  const primaryCountryId = useAccountPrimaryCountryId();

  const {
    selectedContacts,
    resetSelectedContacts,
    resetSelectedContactsExcludingPackages,
    totalNumberOfSelectedContacts,
    numberOfSelectedContactsForPackage,
  } = useContext(SelectedContactsContext);

  const {
    tradeId,
    setTradeId,
    location,
    setLocation,
    networkSearchResults,
    loadingNetworkSearch,
    refetchNetworkSearch,
  } = useContext(NetworkSearchContext);

  // set the first valid package as selected on page load.
  useEffect(() => {
    if (selectedPackage === undefined) {
      const firstNonEmptyPackage = stage?.activePackages.find((pkg) => !isPackageEmpty(pkg));

      setSelectedPackage(firstNonEmptyPackage);

      if (firstNonEmptyPackage && stage?.trades) {
        const initSelectedTrade = matchPackageToTrade(firstNonEmptyPackage.title, stage.trades);

        setTradeId(initSelectedTrade?.stockTrade?.id);
      }

      setQuoteDue(
        firstNonEmptyPackage?.quotesDueAt ? new Date(firstNonEmptyPackage.quotesDueAt) : null,
      );
    }
  }, [stage, selectedPackage]);

  // set the trade based on the selected package (if we can find a match...).
  useEffect(() => {
    if (selectedPackage && stage?.trades) {
      const selectedTrade = matchPackageToTrade(selectedPackage.title, stage?.trades);
      setTradeId(selectedTrade?.stockTrade?.id);
    }
  }, [selectedPackage, stage]);

  // set the project's address as the default location for searching.
  useEffect(() => {
    if (location === undefined && stage?.projectAddress.postcode != null && !loadingPackages) {
      getLocationForPostcode(
        stage.projectAddress.postcode,
        primaryCountryId ?? Country.COUNTRY_AUSTRALIA,
        placePredictionsDefaultTypes,
      )
        .then((loc) => setLocation(loc))
        .catch((e: Error) => captureException(e));
    }
  }, [loadingPackages, location, stage, primaryCountryId]);

  if (loadingPackages || currentUser?.id === undefined) {
    return (
      <div className={styles.loading}>
        <LoadingSpinner />
      </div>
    );
  }

  const handleCloseInvitesProgressModal = () => {
    setIsInvitesProgressModalOpen(false);
    setSentInvitationsCount(0);
    resetSelectedContacts();
    notifyInvitationSuccess({
      title: `${totalNumberOfSelectedContacts()} ${pluralise('invite', totalNumberOfSelectedContacts())} successfully sent`,
    });
  };

  const handleCloseInvitesErrorModal = () => {
    setIsInvitesErrorModalOpen(false);
    setSentInvitationsCount(0);
    resetSelectedContactsExcludingPackages(packagesWithInvitationErrors);
    setPackagesWithInvitationErrors([]);
    setSendInvitationsErrors([]);
  };

  const sendInvitesByPackage = async (packageId: number): Promise<boolean> => {
    if (selectedContacts.size === 0 || quoteDue === null) {
      throw Error('Package invites');
    }

    const selectedE1NetworkCompanyContactIds: string[] = [];
    const selectedBuilderCompanyContactIds: string[] = [];

    const selectedContactsForPackage = selectedContacts.get(packageId);

    if (selectedContactsForPackage === undefined || selectedContactsForPackage.size === 0) {
      return false;
    }

    selectedContactsForPackage.forEach((companyContacts) => {
      switch (companyContacts?.companyType) {
        case 'E1NetworkCompany': {
          selectedE1NetworkCompanyContactIds.push(
            ...Array.from(companyContacts.contactIds.map(String)),
          );
          break;
        }
        case 'BuilderNetworkCompany':
        case 'ConnectedE1NetworkCompany':
        case 'ConnectedBuilderNetworkCompany': {
          selectedBuilderCompanyContactIds.push(
            ...Array.from(companyContacts.contactIds.map(String)),
          );
          break;
        }
        default: {
          throw new Error(`Unsupported company type: ${companyContacts?.companyType}`);
        }
      }
    });

    const { errors } = await submitInvitations({
      packageId,
      userIds: selectedE1NetworkCompanyContactIds,
      addressBookContactIds: selectedBuilderCompanyContactIds,
      quotesDueAt: quoteDue.toISOString(),
    });

    if (errors) {
      setSendInvitationsErrors((prevErrors) => [
        ...prevErrors,
        `Invites for package ${getPackageNameById(packageId)} failed to send: ${errors}`,
      ]);
      return false;
    }

    return true;
  };

  const handleInvites = async () => {
    setIsConfirmInvitesModalOpen(false);
    setIsInvitesProgressModalOpen(true);

    const requests = await Promise.all(
      Array.from(selectedContacts.keys()).map(async (packageId): Promise<[boolean, ID]> => {
        const success = await sendInvitesByPackage(Number(packageId));
        if (success) {
          setSentInvitationsCount(
            (prevCount) => prevCount + numberOfSelectedContactsForPackage(packageId),
          );
        }
        return [success, packageId];
      }),
    );

    const packagesWithErrors = requests
      .filter(([success]) => !success)
      .map(([, packageId]) => packageId);
    setPackagesWithInvitationErrors(packagesWithErrors);

    await refetchNetworkSearch();

    if (packagesWithErrors.length === 0) {
      handleCloseInvitesProgressModal();
    } else {
      setIsInvitesProgressModalOpen(false);
      setIsInvitesErrorModalOpen(true);
    }
  };

  const packageSelectHandler = (pkg: Package) => {
    setSelectedPackage(pkg);
    setQuoteDue(pkg.quotesDueAt ? new Date(pkg.quotesDueAt) : null);
  };

  const invitedAddressBookContactIds =
    stage?.activePackages
      .find((p) => p.id === selectedPackage?.id)
      ?.rfqs.filter((rfq) => rfq.contact !== null)
      .map((rfq) => rfq.contact!.id) ?? [];

  const hasOnlyFullSet = hasOnlyFullSetPackage(stage?.activePackages ?? []);

  // these must be mutually exclusive:
  const isLoading = loadingNetworkSearch || selectedPackage === undefined;
  const hasSearchResults = !isLoading;

  return (
    <div className="wrapper">
      <NetworkInvitationsHelpBanner userId={currentUser.id} stageId={stageId} />

      <Row>
        <Col span={3} excludeBottomGutter>
          <PackageSelectionSidebar
            packages={stage?.activePackages}
            numberOfSelectedContactsForPackage={numberOfSelectedContactsForPackage}
            selectedPackage={selectedPackage}
            onPackageSelect={packageSelectHandler}
          />
        </Col>

        <Col span={9} excludeBottomGutter>
          <PackageActionsBar
            quoteDueAt={quoteDue}
            setQuoteDueAt={setQuoteDue}
            packageTitle={selectedPackage?.title}
          />

          <FilterActionsBar trades={stage?.trades ?? []} />

          {hasOnlyFullSet && <FullSetOnlyAlert />}

          {isLoading && <SkeletonLoader count={5} height="16px" />}

          {hasSearchResults && (
            <CompaniesResults
              networkCompanies={networkSearchResults?.results ?? null}
              currentPackageId={selectedPackage.id}
              invitedAddressBookContacts={invitedAddressBookContactIds}
              isTradeSelected={!!tradeId}
            />
          )}
        </Col>
      </Row>

      <ConfirmInvitesModal
        isOpen={isConfirmInvitesModalOpen}
        inviteCount={totalNumberOfSelectedContacts()}
        onRequestClose={() => setIsConfirmInvitesModalOpen(false)}
        onSendInvites={handleInvites}
        isSending={requestingInvitations}
      />
      <InvitesProgressModal
        isOpen={isInvitesProgressModalOpen}
        sentInvitationsCount={sentInvitationsCount}
        totalInvitationsCount={totalNumberOfSelectedContacts()}
        onRequestClose={handleCloseInvitesProgressModal}
      />
      <InvitesErrorModal
        isOpen={isInvitesErrorModalOpen}
        sentInvitationsCount={sentInvitationsCount}
        totalInvitationsCount={totalNumberOfSelectedContacts()}
        onRequestClose={handleCloseInvitesErrorModal}
        errorMessages={sendInvitationsErrors}
      />
    </div>
  );
};
