import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Slider,
  ButtonVariant,
  Button,
  LoadingSpinner,
  Alert,
  AlertVariant,
  Icon,
  IconName,
  ModalSize,
  Modal,
  AlertIcon,
} from '@estimateone/frontend-components';
import { SelectedDocuments } from '../ProcoreConnectSlider';
import { ProcoreItemsList } from '../ProcoreItemsList';
import { useGetStageProcoreMapForModification } from '../hooks/useGetStageProcoreMapForModification';
import { useModifyStageProcoreMap } from '../hooks/useModifyStageProcoreMap';
import { stageProcoreMapForModification_stageProcoreMapForModification_projectTools as GQLProjectTools } from '../types/stageProcoreMapForModification';
import { stageProcoreMapSetup_stageProcoreMapSetup_stageProcoreMapOptions as StageProcoreMapOptions } from '../types/stageProcoreMapSetup';
import { EntityId, FromGraphQL } from '@ascension/types';
import styles from './styles.scss';

type StageProcoreMapWithFileDetails = FromGraphQL<StageProcoreMapOptions>;
type File = StageProcoreMapWithFileDetails['documents']['files'][0];
type Folder = StageProcoreMapWithFileDetails['documents']['folders'][0];
type ProjectTools = FromGraphQL<GQLProjectTools>;

const getRemovedFiles = (
  initial: StageProcoreMapWithFileDetails,
  current: SelectedDocuments,
): StageProcoreMapWithFileDetails => {
  const hasRemovedDrawings = initial.includeDrawings && !current.includeDrawings;
  const hasRemovedSpecifications = initial.includeSpecifications && !current.includeSpecifications;

  const removedFiles = initial.documents.files.filter(
    (initialFile) =>
      !current.documents.files.find((currentFileId) => initialFile.id === currentFileId),
  );
  const removedFolders = initial.documents.folders.filter(
    (initialFolder) =>
      !current.documents.folders.find((currentFolderId) => initialFolder.id === currentFolderId),
  );

  return {
    includeDrawings: hasRemovedDrawings,
    includeSpecifications: hasRemovedSpecifications,
    documents: {
      files: removedFiles,
      folders: removedFolders,
    },
  };
};

const booleanStageProcoreMap = (selected: StageProcoreMapWithFileDetails): boolean =>
  selected.includeDrawings ||
  selected.includeSpecifications ||
  selected.documents.files.length > 0 ||
  selected.documents.folders.length > 0;

export const ProcoreModifySlider = ({
  isOpen,
  onRequestClose,
  stageId,
}: {
  isOpen: boolean;
  onRequestClose: () => void;
  stageId: EntityId;
}) => {
  const {
    stageProcoreMapForModification,
    loading,
    refetch: refetchInitiallySelected,
  } = useGetStageProcoreMapForModification(stageId);
  const { submit: modifyStageProcoreMap, loading: isModifying } = useModifyStageProcoreMap();
  const [selectedDocuments, setSelectedDocuments] = useState<SelectedDocuments | undefined>(
    undefined,
  );
  const hasLoaded = useRef(false);
  const [showWarningModal, setShowWarningModal] = useState(false);

  const [projectTools, initiallySelectedDocuments]:
    | [ProjectTools, StageProcoreMapWithFileDetails]
    | [undefined, undefined] = useMemo(() => {
    if (stageProcoreMapForModification) {
      const {
        stageProcoreMapOptions: { includeDrawings, includeSpecifications, documents },
        projectTools: stageProjectTools,
      } = stageProcoreMapForModification;

      return [
        stageProjectTools,
        {
          includeDrawings,
          includeSpecifications,
          documents: {
            files: documents.files.filter((file) => file.isTracked),
            folders: documents.folders.filter((folder) => folder.isTracked),
          },
        },
      ];
    }

    return [undefined, undefined];
  }, [stageProcoreMapForModification]);

  useEffect(() => {
    if (!hasLoaded.current && !!initiallySelectedDocuments && !!projectTools) {
      hasLoaded.current = true;
      setSelectedDocuments({
        includeDrawings:
          projectTools.drawings.isActive && initiallySelectedDocuments.includeDrawings,
        includeSpecifications:
          projectTools.specifications.isActive && initiallySelectedDocuments.includeSpecifications,
        documents: projectTools.documents.isActive
          ? {
              files: initiallySelectedDocuments.documents.files.map((file) => file.id),
              folders: initiallySelectedDocuments.documents.folders.map((folder) => folder.id),
            }
          : {
              files: [],
              folders: [],
            },
      });
    }
  }, [initiallySelectedDocuments, projectTools]);

  const isContentReady =
    !loading &&
    !!selectedDocuments &&
    !!initiallySelectedDocuments &&
    !!stageProcoreMapForModification;

  const selectionRemovals = isContentReady
    ? getRemovedFiles(initiallySelectedDocuments, selectedDocuments)
    : undefined;

  const hasRemovals = selectionRemovals ? booleanStageProcoreMap(selectionRemovals) : false;

  const handleSubmit = async () => {
    if (selectedDocuments) {
      await modifyStageProcoreMap({
        variables: {
          modifyStageProcoreMapInput: {
            stageId: String(stageId),
            ...selectedDocuments,
          },
        },
      });
      setShowWarningModal(false);
      onRequestClose();

      hasLoaded.current = false;
      await refetchInitiallySelected();
    }
  };

  return (
    <Slider isOpen={isOpen} onRequestClose={() => onRequestClose()}>
      <Slider.Header>
        <h2 className={styles.sliderHeading}>Change File Selection</h2>
      </Slider.Header>
      <Slider.Body scrollBodyOnly>
        {isContentReady ? (
          <>
            <div>
              <div className={styles.procoreItemsDescription}>
                <p>
                  Tick Procore Tools or folders to add them to this project. Untick to delete them.
                </p>
              </div>
              <ProcoreItemsList
                stageProcoreMapSetup={stageProcoreMapForModification}
                loading={loading}
                selectedDocuments={selectedDocuments}
                handleDocumentSelection={(selection) => {
                  setSelectedDocuments(selection);
                }}
              />
            </div>
            <Alert variant={AlertVariant.Blue} className={styles.alert}>
              <div className={styles.alertContents}>
                <Icon
                  name={IconName.Info}
                  size={{ height: '1.5em', width: '1.5em' }}
                  className={styles.tipIcon}
                />
                <div>
                  <b>Tip:</b> to stop a Procore Documents folder being added, you can make it
                  private in Procore. Its files will be deleted from E1.
                </div>
              </div>
            </Alert>
          </>
        ) : (
          <LoadingSpinner />
        )}
      </Slider.Body>

      {!loading && stageProcoreMapForModification && (
        <Slider.Footer>
          <div className={styles.sliderFooter}>
            <Button variant={ButtonVariant.Grey} fullWidth onClick={() => onRequestClose()}>
              Cancel
            </Button>
            <Button
              variant={hasRemovals ? ButtonVariant.Red : ButtonVariant.Primary}
              onClick={async () => {
                if (hasRemovals) {
                  setShowWarningModal(true);
                } else {
                  await handleSubmit();
                }
              }}
              fullWidth
              disabled={isModifying}
            >
              Save and Close
            </Button>
          </div>
        </Slider.Footer>
      )}

      <Modal
        isOpen={showWarningModal}
        onRequestClose={() => {
          setShowWarningModal(false);
        }}
        size={ModalSize.Small}
        reactModalProps={{ contentLabel: 'Delete files from E1?' }}
      >
        <Modal.Section>
          <div className={styles.modalTextContainer}>
            <h3 className={styles.modalHeading}>Delete files from E1?</h3>
            <div className={styles.modalAlertContainer}>
              <AlertIcon className={styles.modalAlertIcon} />
              <p>
                Files in the Procore Tools or folders below will no longer be checked for updates,
                and will be deleted.
              </p>
            </div>
            <div className={styles.modalAlertContainer}>
              <AlertIcon className={styles.modalAlertIcon} />
              <p>
                If you continue, subcontractors will no longer be able to download these files. They
                won’t be automatically notified of this change.
              </p>
            </div>
            <div className={styles.modalFileList}>
              <p>Files will be deleted from:</p>
              <ul>
                {selectionRemovals?.includeDrawings && <li>Drawings</li>}
                {selectionRemovals?.includeSpecifications && <li>Specifications</li>}
                {selectionRemovals?.documents.folders.map((folder: Folder) => (
                  <li key={folder.id}>{folder.name}</li>
                ))}
                {selectionRemovals?.documents.files.map((file: File) => (
                  <li key={file.id}>{file.name}</li>
                ))}
              </ul>
            </div>
            <p>You can add these Tools or folders to this project later again.</p>
          </div>
          <div className={styles.modalButtonContainer}>
            <Button
              variant={ButtonVariant.Grey}
              fullWidth
              onClick={() => setShowWarningModal(false)}
            >
              No, Go Back
            </Button>
            <Button
              variant={ButtonVariant.Red}
              fullWidth
              disabled={isModifying}
              onClick={handleSubmit}
            >
              Yes, Delete These Files
            </Button>
          </div>
        </Modal.Section>
      </Modal>
    </Slider>
  );
};
