import $ from 'jquery';
import Routing from 'routing';
import moment from 'moment-timezone';
import _ from 'underscore';
import E1Request from '../classes/E1Request';
import GenericList from '../classes/GenericList';
import { isValidKeyup } from '../utils/helpers';
import { truncate } from '../utils/truncate';
import { getUserTimezone } from './timezone';

function MailTable($target) {
  const self = this;
  this.$target = $target;
  this.dataTable = null;
  this.$container = $target.closest('.construction-mail-container');
  this.$clearFilters = self.$container.find('.clear-filter-trigger');
  this.stageId = parseInt(self.$container.attr('data-stage'), 10);
  this.$typeFilter = self.$container.find('.correspondence-type-filter');
  this.$recipientFilter = self.$container.find('.recipients-select-filter');
  this.$senderFilter = self.$container.find('.sender-select-filter');
  this.$termsFilter = self.$container.find('.terms-filter');
  this.$dateMin = self.$container.find('.input-daterange .date-min');
  this.$dateMax = self.$container.find('.input-daterange .date-max');
  this.$unreadFilter = self.$container.find('.mail-unread-filter');
  this.badge = 'correspondence-mail-badge';
  this.recipients = [];
  this.senders = [];

  self.init();
}
MailTable.prototype = {
  init() {
    const self = this;
    const { sessionStorage } = window;

    if (self.retrievePersistedFilters(sessionStorage) !== false) {
      // hide/show to avoid ui flicker
      $('table.construction-mail-table').hide();
    }

    self.dataTable = self.createTable();

    if (self.$typeFilter.length > 0) {
      self.$typeFilter.on('change', (e) => {
        const val = $(e.currentTarget).val();
        self.dataTable.table.column('type:name').search(val).draw();
        self.persistFilters(sessionStorage);
      });
    }

    if (self.$senderFilter.length > 0) {
      self.$senderFilter.on('change', (e) => {
        const val = $(e.currentTarget).val();
        self.dataTable.table.column('createdBy:name').search(val).draw();
        self.persistFilters(sessionStorage);
      });
    }

    if (self.$recipientFilter.length > 0) {
      self.$recipientFilter.on('change', (e) => {
        const val = $(e.currentTarget).val();
        self.dataTable.table.column('recipients:name').search(val).draw();
        self.persistFilters(sessionStorage);
      });
    }

    if (self.$termsFilter.length > 0) {
      self.$termsFilter.on(
        'keyup',
        _.debounce((e) => {
          const searchTerm = $(e.currentTarget).val();
          const keyUp = e.keyCode || e.charCode;
          if (isValidKeyup(keyUp)) {
            if (searchTerm.length >= 3) {
              self.dataTable.table.search(searchTerm).draw();
              self.surfaceSnippet(searchTerm);
            } else {
              self.dataTable.table.search('').draw();
              self.clearSnippet();
            }
          }
          self.persistFilters(sessionStorage);
        }, 100),
      );
    }

    if (self.$dateMin.length > 0 && self.$dateMax.length > 0) {
      $.fn.dataTable.ext.search.push((settings, data) => {
        const min =
          self.$dateMin.val() !== '' ? moment(self.$dateMin.val(), 'DD-MM-YYYY').unix() : 0;
        const max =
          self.$dateMax.val() !== ''
            ? moment(self.$dateMax.val(), 'DD-MM-YYYY').unix()
            : Number.MAX_VALUE;
        const date = parseFloat(data[6]) || 0;

        return (
          (isNaN(min) && isNaN(max)) ||
          (isNaN(min) && date <= max) ||
          (min <= date && isNaN(max)) ||
          (min <= date && date <= max)
        );
      });

      self.$dateMin.on('changeDate clearDate', () => {
        self.dataTable.table.draw();
        self.persistFilters(sessionStorage);
      });

      self.$dateMax.on('changeDate clearDate', () => {
        self.dataTable.table.draw();
        self.persistFilters(sessionStorage);
      });
    }

    if (self.$unreadFilter.length > 0) {
      self.$unreadFilter.on('change', () => {
        const displayAll = !self.$unreadFilter.is(':checked');
        const val = displayAll ? '' : false;
        self.dataTable.table.column('hasUnread:name').search(val).draw();
        self.persistFilters(sessionStorage);
      });
    }

    self.$clearFilters.on('click', () => {
      self.clearTable();
    });
  },
  persistFilters(sessionStorage) {
    const self = this;
    if (sessionStorage) {
      const corresponenceFilters = JSON.stringify({
        searchTerm: self.$termsFilter.val(),
        type: self.$typeFilter.val(),
        sender: self.$senderFilter.val(),
        recipient: self.$recipientFilter.val(),
        dateMin: self.$dateMin.val(),
        dateMax: self.$dateMax.val(),
        unread: self.$unreadFilter.is(':checked'),
      });
      sessionStorage.setItem('con_corr_mail_filters', corresponenceFilters);
    }
  },
  retrievePersistedFilters(sessionStorage) {
    if (sessionStorage) {
      const corresponenceFilters = sessionStorage.getItem('con_corr_mail_filters');
      if (corresponenceFilters) {
        return JSON.parse(corresponenceFilters);
      }
    }
    return false;
  },
  createTable() {
    const self = this;
    const $placeholderRow = self.$target.find('tr.data-table-placeholder').first();
    return new GenericList(
      self.$target,
      (list) => {
        list.table = list.$target
          .DataTable({
            paging: true,
            createdRow(row, data) {
              $(row).attr('data-mail-id', data.correspondence_id);
            },
            data: list.data,
            info: false,
            searchable: true,
            searchDelay: 350,
            mark: { className: 'highlight', element: 'span' },
            order: [
              [6, 'desc'],
              [0, 'asc'],
            ],
            columns: [
              {
                data: 'parent_correspondence_ref',
                name: 'user_correspondence.reference',
                width: 60,
                render(data, display, obj) {
                  if (data === null) {
                    return '-';
                  }

                  const status = obj.parent_correspondence_status;
                  const statusClass =
                    window.global.userCorrespondenceStatus &&
                    window.global.userCorrespondenceStatus[status]
                      ? window.global.userCorrespondenceStatus[status]
                      : '';

                  const $status = $('<span>');

                  if (statusClass) {
                    $status.addClass(
                      `correspondence-status-tag ${statusClass.toLocaleLowerCase()}`,
                    );
                  }

                  const route = Routing.generate('app_usercorrespondence_details', {
                    stageId: self.stageId,
                    id: obj.parent_correspondence_id,
                  });

                  const $link = $('<a>')
                    .addClass('link view-user-correspondence-trigger pointer-cursor')
                    .attr('href', route)
                    .text(data);

                  return $('<div>')
                    .addClass('parent-reference')
                    .append($link, '<br/>', $status)
                    .prop('outerHTML');
                },
              },
              {
                data: 'type',
                name: 'type',
                visible: false,
                render(data, display, obj) {
                  return window.global.userCorrespondenceTypes &&
                    window.global.userCorrespondenceTypes[obj.type]
                    ? window.global.userCorrespondenceTypes[obj.type]
                    : '';
                },
              },
              {
                data: 'view',
                name: 'hasUnread',
                class: 'unread-column',
                width: 5,
                orderable: false,
                render(data, display, obj, details) {
                  if (display === 'display') {
                    const template = $($placeholderRow.find('td').get(details.col));
                    const $tdContent = $(template).clone();
                    const route = Routing.generate('app_usercorrespondence_details', {
                      stageId: self.stageId,
                      id: obj.parent_correspondence_id,
                    });

                    $tdContent
                      .find('a.unread-indicator')
                      .addClass('link view-user-correspondence-trigger pointer-cursor')
                      .attr('href', route)
                      .attr('data-read', Math.min(data, 1));

                    return $tdContent.html();
                  }
                  return data;
                },
              },
              {
                data: 'title',
                name: 'user_correspondence.title',
                render(data, display, obj, details) {
                  const template = $placeholderRow.find('td').get(details.col);
                  const $mailDetails = $(template).find('.mail-details').clone();
                  const content = obj.content.trim();
                  const $title = $('<a>')
                    .addClass('link')
                    .attr('role', 'button')
                    .text(truncate(_.escape(obj.title), 70));

                  if (obj.has_attachments) {
                    $title.append(
                      document.createTextNode('\u00A0'),
                      $('<i>').addClass('icon icon-attach'),
                    );
                  }

                  if (obj.imported_email_id) {
                    $mailDetails
                      .addClass('imported-email-slider-trigger pointer-cursor')
                      .attr('data-correspondence-id', obj.parent_correspondence_id)
                      .attr('data-email-id', obj.imported_email_id);
                  } else {
                    $mailDetails
                      .addClass('view-user-correspondence-mail-trigger pointer-cursor')
                      .attr('data-correspondence-id', obj.parent_correspondence_id)
                      .attr('data-mail-id', obj.correspondence_id);
                  }

                  $mailDetails.find('.mail-title').append($title);

                  if (obj.imported_email_id) {
                    $mailDetails
                      .find('.mail-title')
                      .append($('<span>').addClass('tag tag-info ml-1').text('Imported'));
                  }

                  $mailDetails.find('.mail-content .snippet-default').text(truncate(content, 150));

                  return $mailDetails.prop('outerHTML');
                },
              },
              {
                name: 'createdBy',
                width: 120,
                orderable: false,
                render(data, display, obj) {
                  if (display === 'display') {
                    if (!obj.createdBy) {
                      return '-';
                    }
                    const creator = obj.createdBy;
                    let account = '';
                    let fullName = '';

                    if (obj.correspondence_id) {
                      const firstName = creator.firstName || '';
                      const lastName = creator.lastName || '';
                      account = creator.account.name;
                      fullName = `${firstName} ${lastName}`;
                    }
                    if (obj.imported_email_id) {
                      account = obj.createdBy.address;
                      fullName = obj.createdBy.name;
                    }

                    const $companySpan = $('<div />').addClass('company-name').text(account);

                    const name = $('<p />').append(_.escape(fullName.trim()), $companySpan);

                    return name.html();
                  }
                  return obj.correspondence_id ? obj.createdBy.email : obj.createdBy.address;
                },
              },
              {
                name: 'recipients',
                width: 120,
                orderable: false,
                render(data, display, obj) {
                  if (display === 'display') {
                    if (obj.correspondence_id) {
                      const $unreadCount = $('<span />');
                      if (obj.unread_recipient_count > 0) {
                        $unreadCount
                          .addClass('unread-count')
                          .text(`(${obj.unread_recipient_count} Unread)`);
                      }

                      if (obj.recipients.length > 1) {
                        const $container = $('<div />');
                        const text = `${obj.recipients.length} people`;
                        const $link = $('<a />')
                          .addClass('link user-correspondence-recipient-trigger ')
                          .attr('role', 'button')
                          .attr('data-user-correspondence-id', obj.correspondence_id)
                          .attr('data-stage-id', self.stageId)
                          .text(text);

                        return $container.append($link, $unreadCount).html();
                      }
                      if (obj.recipients.length === 1) {
                        const item = obj.recipients[0];
                        const { user } = item;
                        const { account } = user;

                        const firstName = user.firstName || '';
                        const lastName = user.lastName || '';
                        const fullName = `${firstName} ${lastName}`;

                        const $companySpan = $('<span />')
                          .addClass('d-block company-name')
                          .text(account.name);

                        const name = _.escape(fullName.trim());

                        const $container = $('<div/>');

                        const $link = $('<a />')
                          .addClass('link user-correspondence-recipient-trigger')
                          .attr('role', 'button')
                          .attr('data-user-correspondence-id', obj.correspondence_id)
                          .attr('data-stage-id', self.stageId)
                          .append(name);

                        return $container.append($link, $companySpan, $unreadCount).html();
                      }
                    }
                    if (obj.imported_email_id) {
                      let recipients = _.union(
                        obj.recipients.to,
                        obj.recipients.cc,
                        obj.recipients.bcc,
                      );

                      recipients = _.uniq(recipients, (item) => item.address);

                      if (recipients.length > 1) {
                        const $container = $('<div />');
                        const text = `${recipients.length} people`;
                        const $link = $('<a />')
                          .addClass('link imported-email-slider-trigger')
                          .attr('role', 'button')
                          .attr('data-correspondence-id', obj.parent_correspondence_id)
                          .attr('data-email-id', obj.imported_email_id)
                          .text(text);
                        return $container.append($link).html();
                      }
                      if (recipients.length === 1) {
                        const user = recipients[0];

                        const fullName = user.name;

                        const $companySpan = $('<div />')
                          .addClass('company-name')
                          .text(user.address);

                        const name = $('<p />').append(_.escape(fullName.trim()), $companySpan);

                        return name.html();
                      }
                    }
                    return '-';
                  }
                  let emails = [];
                  if (obj.correspondence_id) {
                    emails = obj.recipients.map((recipient) => recipient.user.email);
                  } else if (obj.imported_email_id) {
                    let recipients = _.union(
                      obj.recipients.to,
                      obj.recipients.cc,
                      obj.recipients.bcc,
                    );

                    recipients = _.uniq(recipients, (item) => item.address);

                    emails = _.pluck(recipients, 'address');
                  }
                  return emails;
                },
              },
              {
                data: 'createdAt',
                name: 'createdAt',
                width: 150,
                render(data, display, obj, details) {
                  const date = moment.utc(obj.createdAt.date);
                  date.tz(getUserTimezone());

                  if (display !== 'display') {
                    return date.format('X');
                  }
                  const template = $($placeholderRow.find('td').get(details.col)).clone();
                  const $timeStamp = template.find('.mail-date .date-timestamp');
                  const $timeAgo = template.find('.mail-date .date-ago');

                  $timeStamp.text(date.format($timeStamp.attr('data-format')));
                  $timeAgo.text(date.fromNow());
                  return $(template).html();
                },
              },
              {
                data: 'content',
                name: 'content',
                visible: false,
                render(data, display, obj) {
                  return _.escape(obj.content.trim());
                },
              },
            ],
            dom: 'Rrtp',
            initComplete: () => {
              self.updateDropDowns();
              self.updateCount();

              const initSessionFiltering = () => {
                if ($('.construction-mail-container.has-loaded').length) {
                  const { sessionStorage } = window;
                  if (sessionStorage) {
                    const filters = self.retrievePersistedFilters(sessionStorage);
                    if (filters && filters.searchTerm) {
                      self.$termsFilter.val(filters.searchTerm);
                      self.dataTable.table.search(filters.searchTerm).draw();
                    }
                    if (filters && filters.type) {
                      self.$typeFilter.val(filters.type).trigger('change');
                      self.dataTable.table.column('type:name').search(filters.type).draw();
                    }
                    if (filters && filters.sender) {
                      self.$senderFilter.val(filters.sender).trigger('change');
                      self.dataTable.table.column('createdBy:name').search(filters.sender).draw();
                    }
                    if (filters && filters.recipient) {
                      self.$recipientFilter.val(filters.recipient).trigger('change');
                      self.dataTable.table
                        .column('recipients:name')
                        .search(filters.recipient)
                        .draw();
                    }
                    if (filters && filters.dateMin) {
                      self.$dateMin.val(filters.dateMin);
                      self.dataTable.table.draw();
                    }
                    if (filters && filters.dateMax) {
                      self.$dateMax.val(filters.dateMax);
                      self.dataTable.table.draw();
                    }
                    if (filters && filters.unread === true) {
                      self.$unreadFilter.prop('checked', true);
                      self.dataTable.table.column('hasUnread:name').search(false).draw();
                    }
                    // hide/show to avoid ui flicker
                    if (filters !== false) {
                      $('table.construction-mail-table').show();
                    }
                  }
                }
              };

              const pauseUntilDefinitelyLoaded = setTimeout(initSessionFiltering, 500);
            },
          })
          .on('search.dt', () => {
            self.$clearFilters.removeClass('disabled');
          });
        list.toggleTableDisplay(true);
        list.$target.closest('.loading-container').addClass('has-loaded');
      },
      Routing.generate('app_constructionmail_fetch', { stageId: self.stageId }),
      null,
      (list, data) => {
        const creator = data.createdBy;
        if (data.correspondence_id) {
          self.senders.push({
            name: `${creator.firstName || ''} ${creator.lastName || ''}`,
            email: creator.email,
            account: creator.account.name,
          });

          $.each(data.recipients, (key, recipient) => {
            self.recipients.push({
              name: `${recipient.user.firstName || ''} ${recipient.user.lastName || ''}`,
              email: recipient.user.email,
              account: recipient.user.account.name,
            });
          });
        }
        if (data.imported_email_id) {
          self.senders.push({
            name: creator.name,
            email: creator.address,
          });

          let recipients = _.union(data.recipients.to, data.recipients.cc, data.recipients.bcc);

          recipients = _.uniq(recipients, (item) => item.address);

          $.each(recipients, (key, recipient) => {
            self.recipients.push({
              name: recipient.name,
              email: recipient.address,
            });
          });
        }

        return data;
      },
    );
  },
  surfaceSnippet(term) {
    const self = this;
    // only get the rows on the current page, surfacing snippets for other pages is useless
    self.dataTable.table.rows({ page: 'current' }).every((rowIdx) => {
      // This gets all data in the row's object (including data that's not shown)
      const data = self.dataTable.table.row(rowIdx).data();
      const { content } = data;
      const snippet = self.searchContentForSnippet(term, content);
      const contentCell = self.dataTable.table.cell(rowIdx, 3).node();

      const $element = $(contentCell);

      if (snippet.length > 0) {
        $element
          .find('.snippet')
          .text(snippet)
          .removeClass('hide')
          .mark(term, { className: 'highlight', element: 'span' });

        $element.find('.snippet-default').addClass('hide');
      }
    });
  },
  clearSnippet() {
    const self = this;
    self.$target.find('.snippet').html('').addClass('hide');
    self.$target.find('.snippet-default').removeClass('hide');
    $(self.$target).unmark({});
  },
  clearTable() {
    this.$typeFilter.val('').trigger('change');
    this.$recipientFilter.val('').trigger('change');
    this.$senderFilter.val('').trigger('change');
    this.$termsFilter.val('');
    this.$dateMin.datepicker('setDate', null);
    this.$dateMax.datepicker('setDate', null);
    this.$unreadFilter.prop('checked', false);
    this.dataTable.table.search('').columns().search('').draw();
    this.$clearFilters.addClass('disabled');
  },
  searchContentForSnippet(term, content) {
    const self = this;
    const maxPreviewLength = 150;
    const beforeAfter = 50;
    const words = term.split(' ');
    const contentLength = content.length;

    let pos = 0;
    let space = -1;
    let snippet = '';
    let firstSpace = '';
    let lastSpace = '';

    $.each(words, () => {
      let nextOccurrencePos = self.nextOccurrenceOf(words, content, pos);
      while (
        nextOccurrencePos >= 0 &&
        nextOccurrencePos < contentLength &&
        snippet.length < maxPreviewLength
      ) {
        if (nextOccurrencePos >= 0) {
          if (nextOccurrencePos < beforeAfter) {
            space = self.nextOccurrenceOf([' '], content, nextOccurrencePos + 10 + beforeAfter * 2);
            snippet += `${content.substring(0, space >= 0 ? space : beforeAfter).trim()} ... `;
            pos = contentLength;
          } else if (nextOccurrencePos > contentLength - beforeAfter) {
            space = self.nextOccurrenceOf([' '], content, nextOccurrencePos - beforeAfter);
            snippet += ` ... ${content.substring(firstSpace, lastSpace).trim()}`;
            pos = contentLength;
          } else {
            firstSpace = self.nextOccurrenceOf([' '], content, nextOccurrencePos - beforeAfter);
            lastSpace = self.nextOccurrenceOf(
              [' '],
              content,
              nextOccurrencePos + 10 + beforeAfter * 2,
            );
            if (snippet.length === 0) {
              snippet += ' ... ';
            }
            snippet += `${content.substring(firstSpace, lastSpace).trim()} ... `;
            pos = lastSpace;
          }
        }
        nextOccurrencePos = self.nextOccurrenceOf(words, content, pos);
      }
    });

    return snippet;
  },
  nextOccurrenceOf(words, content, start) {
    let nextOccurrencePos = -1;

    $.each(words, (i, word) => {
      const positionOfWord = content.toLowerCase().indexOf(word.toLowerCase(), start);
      if (positionOfWord < nextOccurrencePos || nextOccurrencePos === -1) {
        nextOccurrencePos = positionOfWord;
      }
    });

    return nextOccurrencePos;
  },
  formatOptions(option) {
    if (!option.id) {
      return option.text;
    }
    const data = option.element.dataset;
    let contactDetails = `${data.name || ''}`;
    if (data.account) {
      contactDetails += ` (${data.account})`;
    }

    const $contactName = $('<span>').text(contactDetails).addClass('contact-details');
    const $contactEmail = $('<span>').text(data.email).addClass('contact-email');
    return $('<div>').append($contactName).append($contactEmail);
  },
  updateDropDowns() {
    const self = this;

    self.senders = _.uniq(self.senders, (item) => item.email);
    self.recipients = _.uniq(self.recipients, (item) => item.email);

    $.each(self.recipients, (index, recipient) => {
      self.$recipientFilter.append(
        $('<option>')
          .attr('data-email', recipient.email)
          .attr('data-name', recipient.name)
          .attr('data-account', recipient.account)
          .val(recipient.email)
          .text(recipient.email),
      );
    });

    $.each(self.senders, (index, sender) => {
      self.$senderFilter.append(
        $('<option>')
          .attr('data-email', sender.email)
          .attr('data-name', sender.name)
          .attr('data-account', sender.account)
          .val(sender.email)
          .text(sender.email),
      );
    });

    self.$recipientFilter.select2({ templateResult: self.formatOptions });
    self.$senderFilter.select2({ templateResult: self.formatOptions });
  },
  updateCount() {
    const self = this;
    const total = self.dataTable.data.length || 0;
    $(`.${self.badge}`).text(total);
  },
};

$(() => {
  const $correspondenceCtn = $('.construction-mail-container ');
  if ($correspondenceCtn.length > 0) {
    const $table = $('table.construction-mail-table');
    const mailDataTable = new MailTable($table);

    /* Setting up search filters */
    $correspondenceCtn.on('click', '.view-user-correspondence-mail-trigger', (e) => {
      const $this = $(e.currentTarget);
      const correspondenceId = $this.attr('data-correspondence-id');
      const mailId = $this.attr('data-mail-id');
      const route = Routing.generate('app_usercorrespondencemail_viewslider', {
        correspondence: correspondenceId,
        id: mailId,
      });
      const request = new E1Request(route);
      request.show_loading_modal = true;
      request.loadingModal = 'slider';
      request.extraCallback = (response) => {
        if (response.success) {
          $(document).trigger('update-mail-tables', {
            mailId,
            view: true,
          });
        }
      };
      request.submit();
    });

    $(document).on('update-mail-tables', (e, data) => {
      if (data.reload) {
        mailDataTable.dataTable.updateTable(true);
        return;
      }
      const invalidatedRow = mailDataTable.dataTable.table.row(`[data-mail-id=${data.mailId}]`);
      const oldData = invalidatedRow.data();
      invalidatedRow.data($.extend(oldData, data));
    });
  }
});
