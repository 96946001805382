import { Form } from 'react-final-form';
import { sendAppcuesEditProfileEvent } from '@profiles/features/CompanyProfile/utils/appcues';
import { TextAreaWrapper } from '../../../../modules/FinalFormFields/TextAreaWrapper';
import { ModalFooter } from '../../../../modules/Modal/ModalFooter';
import { extractFieldErrors } from '../../../../utils';
import { FormError } from '../../../FormError';
import styles from './EditAboutUsForm.module.scss';
import { useSubmitForm } from './hooks';
import { EditAboutUsFormProps, EditCompanyFieldNames, EditCompanyFieldValues } from './types';

export const EditAboutUsForm = ({
  fieldName,
  fieldLabel,
  fieldValue,
  toggle,
}: EditAboutUsFormProps) => {
  const { submit: submitForm, error } = useSubmitForm();

  const onSubmit = async (values: EditCompanyFieldValues) => {
    const { data, errors: submitErrors } = await submitForm(values);

    if (submitErrors) {
      return;
    }

    if (data?.updateAboutUs && data.updateAboutUs.__typename === 'Errors') {
      const { errors } = data.updateAboutUs;

      // eslint-disable-next-line consistent-return
      return extractFieldErrors(errors, [EditCompanyFieldNames.CompanyAboutUs]);
    }

    sendAppcuesEditProfileEvent();

    toggle();
  };

  return (
    <Form<EditCompanyFieldValues>
      keepDirtyOnReinitialize
      onSubmit={onSubmit}
      initialValues={{
        [fieldName]: fieldValue || '',
      }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          {error && <FormError />}
          <div className={styles.textAreaWrapper}>
            <TextAreaWrapper<string> field={fieldName} id={fieldName} label={fieldLabel} />
          </div>
          <ModalFooter close={toggle} />
        </form>
      )}
    </Form>
  );
};
