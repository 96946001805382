import { forwardRef, useImperativeHandle, useRef } from 'react';
import { useDocumentMatrix } from './useDocumentMatrix';
import { StageType } from '@ascension/enums';
import { EntityId, DocumentMatrixInstance } from '@ascension/types';
import styles from './styles.scss';

export type DocumentMatrixRef = Pick<DocumentMatrixInstance, 'sendChanges'>;

type DocumentMatrixProps = { stageId: EntityId; editable: boolean; stageType: StageType };

/**
 * To make this component work properly, we need to load client side js (matrix.js) and css (matrix.css)
 * into the page on which this component is rendered.
 */

export const DocumentMatrix = forwardRef<DocumentMatrixRef | undefined, DocumentMatrixProps>(
  ({ stageId, editable, stageType }, ref) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const documentMatrixRef = useDocumentMatrix({ stageId, editable, stageType }, containerRef);

    useImperativeHandle(ref, () => ({
      sendChanges: (cb: () => void) => documentMatrixRef.current?.sendChanges(cb),
    }));

    return <div className={styles.matrixContainer} ref={containerRef} />;
  },
);
