import $ from 'jquery';
import { captureException } from '@sentry/browser';
import moment from 'moment-timezone';
import { getTranslation, LangRef } from '../../components/shared/Util/lang';
import E1Request from '../classes/E1Request';
import { getAddressString } from '../utils/address_form';
import DOMPurify from 'dompurify';

const australiaCentre = { lat: -25.274398, lng: 133.775136 };

const budgetUpgradeClass = 'restricted-budget-trigger';
const awardedUpgradeClass = 'upgrade-awarded-trigger';
const fiftyUpgradeClass = 'restricted-fifty-trigger';

class ProjectMap {
  constructor($target, currentProjectsUrl, awardedProjectsUrl) {
    this.projects = {};
    this.$target = $target;
    this.currentProjectsUrl = currentProjectsUrl;
    this.awardedProjectsUrl = awardedProjectsUrl;
    this.geocoder = null;
    this.infowindow = null;
    this.map = null;

    this.startLatitude = this.$target.attr('data-loc-lat');
    this.startLongitude = this.$target.attr('data-loc-lng');
    this.isGeolocated = this.startLatitude !== '' && this.startLatitude !== '0';

    this.init();
    this.fetch().then((responses) => {
      this.projects.current = responses[0].projects;
      this.projects.awarded = responses[1].projects;
      this.draw();
    });
  }

  init() {
    try {
      let zoom;
      let centre;

      if (this.isGeolocated) {
        centre = new google.maps.LatLng(this.startLatitude, this.startLongitude);
        zoom = 10;
      } else {
        centre = new google.maps.LatLng(australiaCentre);
        zoom = 4;
      }

      this.map = new google.maps.Map(document.getElementById('map-view'), {
        center: centre,
        zoom,
        mapTypeId: google.maps.MapTypeId.TERRAIN,
      });

      this.infowindow = new google.maps.InfoWindow({
        Width: 100,
      });

      if (this.isGeolocated) {
        const locationCaption = 'You are (approximately) here.';
        this.showAddress(this.startLatitude, this.startLongitude, locationCaption, 'blue');
      }
    } catch (err) {
      // Map failed to load. Normally because google is blocked (eg Great Firewall of China)
      captureException(err);
    }
  }

  async fetch() {
    return Promise.all(
      [this.currentProjectsUrl, this.awardedProjectsUrl].map((url) =>
        new E1Request(url, 'GET').submit(),
      ),
    );
  }

  static getMarkerColourForTenderAge(daysPassed) {
    return daysPassed > 4 ? 'green' : 'red';
  }

  static getMarkerColour(project) {
    const maxQuotesDue = project.stages.reduce((accMax, stage) => {
      const quotesDue = moment(stage.tenderQuotesDue.date);
      return !accMax || quotesDue.isAfter(accMax) ? quotesDue : accMax;
    }, null);

    const daysPassed = maxQuotesDue.diff(moment(), 'days');

    return this.getMarkerColourForTenderAge(daysPassed);
  }

  static getHeader(project) {
    const { id, project_id: awardedProjectId, name, project_name: awardedProjectName } = project;
    return $('<h3>').append(
      $('<a>')
        .addClass('project-modal-trigger')
        .attr('data-project-id', id || awardedProjectId)
        .attr('role', 'button')
        .text(name || awardedProjectName),
    );
  }

  static getAddress(address) {
    return $('<div>')
      .addClass('loc')
      .append('<p>')
      .html(getAddressString(address).replace(', ', ''));
  }

  draw() {
    Object.keys(this.projects).forEach((status) => {
      const isAwarded = status === 'awarded';
      this.projects[status].forEach((project) => {
        if (isAwarded) {
          Object.assign(project, {
            state: { shortName: project.state_short_name },
            country: { id: project.country_id },
          });
        }

        const description = isAwarded
          ? project.description || ''
          : project.stages.reduce((accDesc, stage) => accDesc || stage.description, null);
        const cleanedDesc = DOMPurify.sanitize(description, { ALLOWED_TAGS: [] });

        const $caption = $('<div>')
          .addClass('project-infowindow')
          .append(
            this.constructor.getHeader(project),
            this.constructor.getAddress(isAwarded ? project : project.address),
            $('<p>').addClass('description').text(cleanedDesc),
            this.constructor.getBuilderListForStatus(status)(project),
          );

        const markerCol =
          status === 'awarded' ? 'yellow' : this.constructor.getMarkerColour(project);

        this.showAddress(
          isAwarded ? project.latitude : project.address.latitude,
          isAwarded ? project.longitude : project.address.longitude,
          $caption[0].outerHTML,
          markerCol,
        );
      });
    });
  }

  static getBuilderListForStatus(status) {
    return status === 'current' ? this.getCurrentBuilderList : this.getAwardedBuilderList;
  }

  static getCurrentBuilderList(project) {
    if (project.filterBudget || project.filterFifty) {
      return $('<ul>').append(
        $('<li>').append(
          $('<a>')
            .attr('role', 'button')
            .attr('data-project-id', project.id)
            .attr('data-paywall-trigger', 'map')
            .addClass(project.filterBudget ? budgetUpgradeClass : fiftyUpgradeClass)
            .text('View Builders'),
        ),
      );
    }

    return $('<ul>', {
      class: 'tenders',
    }).append(
      ...project.stages.map((stage) => {
        const $builderLink = stage.isIncognito
          ? getTranslation(LangRef.INCOGNITO_BUILDER_HEADING)
          : $('<a>')
              .attr('role', 'button')
              .addClass('builder-modal-trigger')
              .attr('data-builder-id', stage.account.id)
              .text(stage.account.abbrev);

        return $('<li>').append(
          $builderLink,
          $('<span>')
            .addClass('map-info-panel-date map-info-panel-date-quotes-due')
            .text(moment(stage.tenderQuotesDue.date).format('MMM D')),
        );
      }),
    );
  }

  static getAwardedBuilderList(project) {
    return $('<ul>', {
      class: 'tenders',
    }).append(
      $('<li>').append(
        $('<a>')
          .attr('role', 'button')
          .addClass(project.account_id ? 'builder-modal-trigger' : awardedUpgradeClass)
          .attr('data-project-id', project.project_id)
          .attr('data-paywall-trigger', 'map')
          .attr('data-builder-id', project.account_id)
          .text(project.account_id ? project.account_name : 'View Winner'),
        $('<span>')
          .addClass('map-info-panel-date map-info-panel-date-awarded')
          .text(moment(project.awarded_at.date).format('MMM D')),
      ),
    );
  }

  showAddress(lat, lng, caption, col) {
    if (!this.map) {
      return;
    }

    const markerImg = $('.map_legend').find(`.marker-${col}:first`);

    const marker = new google.maps.Marker({
      position: new google.maps.LatLng(lat, lng),
      map: this.map,
      icon: markerImg.attr('src'),
    });
    google.maps.event.addListener(marker, 'click', () => {
      this.infowindow.setContent(caption);
      this.infowindow.open(this.map, marker);
    });
  }
}

$(() => {
  const $map = $('#map-view');
  return $map.length
    ? new ProjectMap($map, $map.data('current-src'), $map.data('awarded-src'))
    : false;
});
