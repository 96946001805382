export type ConnectCompanyModalData = {
  searchedCompanyId: string;
  name?: string;
  email?: string;
};

export enum ModalTrigger {
  /**
   * The modal was triggred when looking at a company
   */
  COMPANY = 'company',
  /**
   * The modal was triggred when looking at an E1 Network company
   */
  E1_NETWORK_COMPANY = 'e1NetworkCompany',
}

export type ConnectCompanyModalContext =
  | {
      data: null;
      modalTriggeredBy: null;
      open: false;
    }
  | {
      data: ConnectCompanyModalData;
      modalTriggeredBy: ModalTrigger;
      open: true;
    };
