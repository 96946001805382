import { useState } from 'react';
import { Form } from 'react-final-form';
import { TextLink } from '@estimateone/frontend-components';
import arrayMutators from 'final-form-arrays';
import CoreTradesCheckboxGroup from '@profiles/features/CompanyProfile/components/Trades/EditTrades/EditCoreTradesForm/fields/CoreTrades';
import { KnowledgeBaseLocale } from '@profiles/providers/TranslationsProvider';
import { ModalFooter } from '../../../../modules/Modal/ModalFooter';
import { FormError } from '../../../FormError';
import styles from './EditCoreTradesForm.module.scss';
import { useLocalisedKnowledgeBaseLinks } from '@profiles/features/CompanyProfile/hooks';
import { UpdateTradesMutation } from '@profiles/features/CompanyProfile/components/Trades/mutations.generated';
import {
  EditCoreTradesFieldName,
  EditCoreTradesFieldValues,
} from '@profiles/features/CompanyProfile/components/Trades/types';

type StockTrade = UpdateTradesMutation['changeStockTrades'][number];

type EditCoreTradesFormProps = {
  coreTrades: number[];
  coreTradesOptions: StockTrade[];
  backToEditTrades: () => void;
  onCoreTradesSubmit: (values: EditCoreTradesFieldValues) => Promise<boolean>;
  formInitError: boolean;
  knowledgeBaseLocale: KnowledgeBaseLocale;
};

export const EditCoreTradesForm = ({
  coreTrades,
  coreTradesOptions,
  backToEditTrades,
  onCoreTradesSubmit,
  formInitError,
  knowledgeBaseLocale,
}: EditCoreTradesFormProps) => {
  const { tradesArticle } = useLocalisedKnowledgeBaseLinks(knowledgeBaseLocale);
  const [isCoreTradeSelectionError, setIsCoreTradeSelectionError] = useState<boolean>(false);
  const [isCoreTradeSubmissionError, setIsCoreTradeSubmissionError] = useState<boolean>(false);
  const onSubmitWithValidation = async (values: EditCoreTradesFieldValues): Promise<void> => {
    if (!values.CoreTrades?.length || values.CoreTrades?.length < 1) {
      setIsCoreTradeSelectionError(true);
    } else {
      setIsCoreTradeSelectionError(false);
      const isSubmissionSuccessful = await onCoreTradesSubmit(values);
      setIsCoreTradeSubmissionError(!isSubmissionSuccessful);
    }
  };

  return (
    <div className={styles.contentContainer}>
      <div className={styles.headingWrapper}>
        <p className={styles.heading}>Edit Core Trades</p>
        <p className={styles.subHeading}>
          <strong>Core Trades</strong> highlight the trades you specialise in (
          <TextLink href={tradesArticle} external>
            learn more
          </TextLink>
          ).
        </p>
        <p
          className={`${styles.optionHeading} ${isCoreTradeSelectionError ? styles.validationError : ''}`}
        >
          Select 1 or 2 Core Trades {isCoreTradeSelectionError ? 'to continue ' : ''}
          <span className="asterisk">*</span>
        </p>
      </div>
      <Form<EditCoreTradesFieldValues>
        keepDirtyOnReinitialize
        onSubmit={onSubmitWithValidation}
        initialValues={{
          [EditCoreTradesFieldName.CoreTrades]: coreTrades || [],
        }}
        mutators={{ ...arrayMutators }}
      >
        {({ handleSubmit }) => (
          <form id="EDIT_CORETRADES_FORM" onSubmit={handleSubmit} className={styles.formContainer}>
            {(formInitError || isCoreTradeSubmissionError) && <FormError />}
            <CoreTradesCheckboxGroup coreTradesOptions={coreTradesOptions} />
            <ModalFooter close={backToEditTrades} cancelButtonText="Back" />
          </form>
        )}
      </Form>
    </div>
  );
};

export default EditCoreTradesForm;
