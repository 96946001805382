import $ from 'jquery';
import Routing from 'routing';
import E1Request from '../classes/E1Request';
import { addThousandsSeparators } from '../../components/fec/utils';

const urlParams = new URLSearchParams(window.location.search);

$(() => {
  const launchPendingImportModal = (stageId: number) =>
    new E1Request(
      Routing.generate('app_stagedocument_pendingimportmodal', {
        id: stageId,
      }),
    )
      .setShowLoadingModal()
      .submit();

  const $body = $('body');

  const $docTable = $body.find('table.constructDocTable, table.tenderDocTable');

  if (!$docTable) return;

  const stage = $docTable.data('stage');

  const isViewingDocsyncSlider = urlParams.get('openDocSyncSlider');
  if (!isViewingDocsyncSlider) {
    if ($docTable.hasClass('trigger-doc-import')) {
      try {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        new E1Request(Routing.generate('app_stagedocument_import', { id: stage }))
          .setShowLoadingModal()
          .setPassive()
          .submit();
      } catch (importModalFailedToLoad) {
        window.location.reload();
      }
    } else if ($docTable.hasClass('pending-doc-import')) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      launchPendingImportModal(stage);
    }
  }

  $(document).on('mfpOpen', () => {
    const container = $.magnificPopup.instance.contentContainer;
    const $modal = $(container);

    const $noticeDiv = $modal.find('.pending_import_notice');
    if ($noticeDiv.length) {
      const stageId = parseInt($noticeDiv.data('stage-id'));

      if (!stageId) return;

      const route = Routing.generate<{ id: number }>('app_stagedocument_checkpendingimport', {
        id: stageId,
      });

      const delay = 2000; // 2 seconds

      // eslint-disable-next-line camelcase
      const request = new E1Request<{ success: boolean; new_count?: number; old_count?: number }>(
        route,
      );

      const updateImportProgressBar = (
        $progressContainer: JQuery,
        count: number,
        target: number,
        percent: number,
      ) => {
        $progressContainer
          .find('.progress-import .progress-bar')
          .attr('aria-valuenow', count)
          .css('width', `${percent}%`);

        $progressContainer.find('.progress-percentage').text(`${percent}%`);

        const countWithCommas = addThousandsSeparators(count.toString());
        $progressContainer.find('.progress-target .processed').text(countWithCommas);

        const targetWithCommas = addThousandsSeparators(target.toString());
        $progressContainer.find('.progress-target .total').text(targetWithCommas);
      };

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      request
        .setExtraCallback((response) => {
          if (response.new_count && response.old_count) {
            const complete = Math.floor((response.new_count / response.old_count) * 100);
            updateImportProgressBar($modal, response.new_count, response.old_count, complete);
          }
          setTimeout(async () => request.submit(), delay);
        })
        .submit();
    }
  });
});
