import { Select } from '@estimateone/frontend-components';
import { useAtom } from 'jotai';
import { stockTradeIdAtom } from '../../atoms';
import PageLoadingSpinner from '@shared/LoadingSpinner/PageLoadingSpinner';
import { useTradesOptions } from '../../providers/AscensionDataProvider/hooks';
import { ValueLabelOption } from '@estimateone/frontend-components/src/components/Form/Select/types';

const TradeFilter = () => {
  const [stockTradeId, setStockTradeId] = useAtom(stockTradeIdAtom);
  const tradeOpts = useTradesOptions();

  if (!tradeOpts) {
    return <PageLoadingSpinner />;
  }

  const selectedValue = tradeOpts.find((trade) => trade.value === stockTradeId) ?? null;
  return (
    <Select<ValueLabelOption<number>, false>
      id="stockTrades"
      inputId="stockTrades"
      label="Find companies by Trade"
      placeholder="Start typing a Trade name..."
      isRequired
      options={tradeOpts}
      onChange={(trade) => setStockTradeId(trade?.value)}
      value={selectedValue}
      isClearable
    />
  );
};

export default TradeFilter;
