import { gql } from '@apollo/client';

export const BUILDER_ACCOUNT_WITH_EXPERIMENTS = gql`
  query BuilderAccountWithExperiments {
    experiments(filter: USER) {
      id
      name
    }
    currentUser {
      id
      imperialUnitsSetting
      account {
        name
        id
        realUsers {
          id
          fullName
          deletedAt
        }
        licensingInfo {
          id
          activeLicenses {
            id
            product {
              id
              features
            }
          }
        }
        primaryCountry {
          id
        }
      }
    }
  }
`;
