import { useRef } from 'react';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  FullScreenModal,
  Icon,
  IconName,
} from '@estimateone/frontend-components';
import { DocumentMatrix, DocumentMatrixRef } from '../DocumentMatrix/DocumentMatrix';
import { PageWrapper } from '../PageWrapper';
import { useRevisionFlow } from '../RevisionFlow';
import { RevisionFlowStep } from '../RevisionFlowStep';
import styles from './styles.scss';

export const AllocateStep = () => {
  const { close, setCurrentStep, stageId, stageType, goToPreviousStep } = useRevisionFlow();
  const documentMatrixRef = useRef<DocumentMatrixRef | undefined>(undefined);
  return (
    <>
      <div className={styles.allocateContent}>
        <DocumentMatrix stageId={stageId} stageType={stageType} editable ref={documentMatrixRef} />
      </div>

      <FullScreenModal.Footer>
        <PageWrapper>
          <div className={styles.actionContainer}>
            <Button
              variant={ButtonVariant.Grey}
              size={ButtonSize.Medium}
              fullWidth
              onClick={close}
              style={{ width: '192px' }}
            >
              Cancel
            </Button>
            <div className={styles.submitButtonContainer}>
              <Button
                variant={ButtonVariant.Secondary}
                size={ButtonSize.Medium}
                fullWidth
                style={{ width: '192px' }}
                onClick={() => {
                  goToPreviousStep();
                }}
              >
                Back
              </Button>
              <Button
                size={ButtonSize.Medium}
                onClick={() => {
                  const documentMatrix = documentMatrixRef.current;
                  if (documentMatrix) {
                    documentMatrix.sendChanges(() => {
                      setCurrentStep(RevisionFlowStep.NOTIFY);
                    });
                  }
                }}
              >
                Next: Notify Subcontractors <Icon name={IconName.ArrowRight} marginLeft="small" />
              </Button>
            </div>
          </div>
        </PageWrapper>
      </FullScreenModal.Footer>
    </>
  );
};
