import { ReactNode } from 'react';
import { HelpIcon, IconSize, TextLink } from '@estimateone/frontend-components';
import { KnowledgeBaseLocale } from '@profiles/providers/TranslationsProvider';
import styles from './TradesTagGroupWithKnowledgeBaseLink.module.scss';
import { useLocalisedKnowledgeBaseLinks } from '@profiles/features/CompanyProfile/hooks';

type TradesTagGroupWithKnowledgeBaseLinkProps = {
  children: ReactNode;
  knowledgeBaseLocale: KnowledgeBaseLocale;
};

export const TradesTagGroupWithKnowledgeBaseLink = ({
  children,
  knowledgeBaseLocale,
}: TradesTagGroupWithKnowledgeBaseLinkProps) => {
  const { tradesArticle } = useLocalisedKnowledgeBaseLinks(knowledgeBaseLocale);
  return (
    <div>
      <div className={styles.tradesHeaderContainer}>
        <h4 className={styles.tradesHeader}>Trades</h4>
        <TextLink href={tradesArticle} external className={styles.knowledgeBaseLink}>
          <HelpIcon
            desc="Trades Knowledge Base article"
            size={IconSize.Standard}
            title="Trades Knowledge Base article"
          />
        </TextLink>
      </div>
      {children}
    </div>
  );
};
