import { Field } from 'react-final-form';
import { joinClassNames, RadioButton } from '@estimateone/frontend-components';
import { OperatingCountry } from '@ascension/components/localisation/operatingCountry';
import { SliderFieldWrapper } from '@profiles/features/CompanyProfile/modules/FinalFormFields/SliderFieldWrapper';
import { MultiSelectDropdownField } from '@profiles/features/ProfileOnboardingForm/modules/FinalFormFields/MultiSelectDropdownField';
import styles from './ServiceAreaFields.module.scss';
import { EntityId } from '@ascension/types';

export type ServiceAreaFieldTypes = {
  serviceAreaType: 'by-travel-distance' | 'by-states' | 'by-country';
  serviceAreaRadius: number;
  serviceAreaStates: {
    value: EntityId;
    label: string;
  }[];
  serviceAreaCountry: EntityId;
};

export type ServiceAreaFieldProps = {
  imperialUnitSetting: boolean;
  isRequired?: boolean;
  serviceAreaBoundaryOptions: { value: number; label: string }[];
  countryId: number;
};

export const ServiceAreaFields = ({
  imperialUnitSetting,
  isRequired = false,
  serviceAreaBoundaryOptions,
  countryId,
}: ServiceAreaFieldProps) => {
  const multiplier = imperialUnitSetting ? 1609.34 : 1000;
  const toMeters = (milesOrKilometers: number) =>
    Math.round(milesOrKilometers ? milesOrKilometers * multiplier : 0);
  const toMilesOrKilometers = (meters: number) => Math.round(meters ? meters / multiplier : 0);
  const unitLabel = imperialUnitSetting ? 'mi' : 'km';

  return (
    <div className={styles.wrapper}>
      <Field name="serviceAreaType" type="radio" id="by-travel-distance" value="by-travel-distance">
        {({ input }) => (
          <div>
            <RadioButton id="by-travel-distance-input" {...input}>
              Set by distance willing to travel from this office location ({unitLabel})
            </RadioButton>
            <div
              className={joinClassNames(styles.radioButtonContent, input.checked && styles.show)}
            >
              <SliderFieldWrapper
                field="serviceAreaRadius"
                id="serviceAreaRadius"
                label="Set by distance willing to travel from this office location"
                hideLabel
                min={10}
                max={imperialUnitSetting ? 500 : 1000}
                defaultValue={imperialUnitSetting ? 321869 : 300000}
                step={10}
                suffix={unitLabel}
                parse={toMeters}
                format={toMilesOrKilometers}
                isRequired={isRequired}
              />
            </div>
          </div>
        )}
      </Field>
      {countryId === OperatingCountry.AU && (
        <Field name="serviceAreaType" type="radio" id="by-states" value="by-states">
          {({ input }) => (
            <div>
              <RadioButton id="by-states-input" {...input}>
                Select states
              </RadioButton>
              <div
                className={joinClassNames(styles.radioButtonContent, input.checked && styles.show)}
              >
                <MultiSelectDropdownField
                  fieldName="serviceAreaStates"
                  id="serviceAreaStates"
                  label="Select states"
                  hideLabel
                  options={serviceAreaBoundaryOptions}
                  autoFocus
                  menuPortalTarget={document.body}
                />
              </div>
            </div>
          )}
        </Field>
      )}
      <Field name="serviceAreaType" type="radio" id="by-country" value="by-country">
        {({ input }) => (
          <RadioButton id="by-country-input" {...input}>
            {(() => {
              switch (countryId) {
                case 4:
                  return 'All of the United Kingdom';
                case 11:
                  return 'All of Republic of Ireland and Northern Ireland';
                case 1:
                default:
                  return 'All of Australia';
              }
            })()}
          </RadioButton>
        )}
      </Field>
    </div>
  );
};
