import { Checkbox, CheckboxStatus, joinClassNames } from '@estimateone/frontend-components';
import { CompanyContact } from '@builder/features/SubbieNetworkInvitations/types';
import styles from './styles.module.scss';

type ContactCheckboxLabelProps = {
  fullName: string;
  email: string;
  unSubscribed: boolean;
};

const UN_SUBSCRIBED_LABEL = '(Unsubscribed)';

const ContactCheckboxLabel = ({ fullName, email, unSubscribed }: ContactCheckboxLabelProps) => (
  <p className={joinClassNames(styles.contactCheckboxLabel, unSubscribed && styles.unSubscribed)}>
    <span>{fullName}</span>
    <span className={styles.labelSeparator}> &#x2022; </span>
    <span>{email}</span>
    <span>{unSubscribed && UN_SUBSCRIBED_LABEL}</span>
  </p>
);

type ContactCheckboxProps = {
  contact: CompanyContact;
  isSelected: boolean;
  onSelectionChange: (isChecked: boolean) => void;
  disabled: boolean;
};

export const ContactCheckbox = ({
  contact,
  isSelected,
  onSelectionChange,
  disabled,
}: ContactCheckboxProps) => (
  <Checkbox
    id={`${contact.id}`}
    containerClassName={styles.contactCheckboxContainer}
    className={styles.contactCheckbox}
    name="Select Contact"
    label={
      <ContactCheckboxLabel
        fullName={contact.fullName}
        email={contact?.maybeEmail ?? ''}
        unSubscribed={contact.unSubscribed}
      />
    }
    statusCurrent={isSelected ? CheckboxStatus.Checked : CheckboxStatus.Unchecked}
    disabled={disabled || contact.unSubscribed}
    onClick={(event) => {
      event.stopPropagation();
      onSelectionChange(!isSelected);
    }}
  />
);
