import { ViewerProvider } from '@profiles/features/CompanyProfile/modules/ViewerProvider';
import {
  CompanyProfile,
  CompanyProfileForSSC,
} from '@profiles/features/CompanyProfile/views/CompanyProfile';
import { FeatureProvider } from '@profiles/providers/FeatureProvider/FeatureProvider';
import { ProfileProvider } from '@profiles/providers/ProfileProvider';
import { TranslationProvider } from '@profiles/providers/TranslationsProvider';
import styles from './CompanyProfileApp.module.scss';
import { Profile } from '.';

/*
 * @TODO I think we can converge on the "profile id" way of doing this, since we can always return an account's profile id
 */

export const CompanyInSliderForAccountId = ({ profileAccountId }: { profileAccountId: number }) => (
  <div id="MFE_PROFILES_ROOT">
    <TranslationProvider>
      <ProfileProvider accountId={profileAccountId}>
        <FeatureProvider>
          <ViewerProvider profileAccountId={profileAccountId}>
            <div className={`${styles.companyProfile} ${styles.sliderCompanyProfile}`}>
              <CompanyProfile layout="slider" />
            </div>
          </ViewerProvider>
        </FeatureProvider>
      </ProfileProvider>
    </TranslationProvider>
  </div>
);

/**
 * Never viewing own profile in this context
 */
export const CompanyInSliderForProfileId = ({ profileId }: { profileId: string }) => (
  <div id="MFE_PROFILES_ROOT">
    <TranslationProvider>
      <ProfileProvider companyProfileId={profileId}>
        <FeatureProvider>
          <ViewerProvider profileAccountId={null}>
            <div className={`${styles.companyProfile} ${styles.sliderCompanyProfile}`}>
              <CompanyProfile layout="slider" />
            </div>
          </ViewerProvider>
        </FeatureProvider>
      </ProfileProvider>
    </TranslationProvider>
  </div>
);

export const CompanyInSliderForSSC = ({ profile }: { profile: Profile }) => (
  <div className={`${styles.companyProfile} ${styles.sliderCompanyProfile}`}>
    <CompanyProfileForSSC profile={profile} />
  </div>
);
