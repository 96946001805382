import { useContext } from 'react';
import { Tooltip } from '@estimateone/frontend-components';
import CompanyListItem from './CompanyListItem/CompanyListItem';
import { EmptyResultsView } from './EmptyResultsView';
import { SelectedContactsContext } from '@builder/features/SubbieNetworkInvitations/context/SelectedContactsProvider';
import { ID, NetworkCompany } from '@builder/common/SubbieNetwork/types';
import styles from './styles.module.scss';

/* @TODO we are going to disable this for now for performance reasons, we will re-enable it later
    data-for={`network-result-checkbox-tooltip-${company.id}`}
    data-tip
    <Tooltip
      text={disabled ? 'Already invited' : 'Add to invite list'}
      tooltipId={`network-result-checkbox-tooltip-${company.id}`}
      place="top"
    />
  */

export type CompaniesResultsProps = {
  networkCompanies: NetworkCompany[] | null;
  invitedAddressBookContacts: ID[];
  isTradeSelected: boolean;
  currentPackageId: ID;
};

const CompaniesResults = ({
  networkCompanies,
  invitedAddressBookContacts,
  currentPackageId,
  isTradeSelected,
}: CompaniesResultsProps) => {
  const { selectedContacts, addContact, removeContact } = useContext(SelectedContactsContext);

  if (!networkCompanies || networkCompanies.length === 0) {
    return <EmptyResultsView isTradeSelected={isTradeSelected} />;
  }

  const networkCompaniesWithContacts = networkCompanies.filter(
    (company) => company.contacts.length > 0,
  );

  return (
    <>
      <div className={styles.resultDescription}>Results sorted by most relevant:</div>

      <ul className={styles.resultList}>
        {networkCompaniesWithContacts.map((networkCompany) => {
          const toggleContactSelection = (contactId: ID, isChecked: boolean) => {
            if (isChecked) {
              addContact(currentPackageId, networkCompany.id, {
                companyType: networkCompany.__typename,
                contactId,
              });
            } else {
              removeContact(currentPackageId, networkCompany.id, contactId);
            }
          };

          return (
            <CompanyListItem
              key={`company-${networkCompany.__typename}::${networkCompany.id}-package-${currentPackageId}`}
              company={networkCompany}
              toggleContactSelection={toggleContactSelection}
              invitedContacts={invitedAddressBookContacts}
              selectedContacts={Array.from(
                selectedContacts.get(currentPackageId)?.get(networkCompany.id)?.contactIds ?? [],
              )}
            />
          );
        })}
      </ul>

      <Tooltip tooltipId="connected-tooltip" text="You are Connected to this E1 Network Company" />
      <Tooltip tooltipId="not-connected-tooltip" text="E1 Network Company" />
    </>
  );
};

export default CompaniesResults;
