import { useCallback, useEffect, useState } from 'react';
import { CellProps, Table } from '@estimateone/frontend-components';
import {
  Button,
  ButtonSize,
  ButtonVariant,
} from '@estimateone/frontend-components/src/components/Button';
import CreditBalance from '@subbie/common/CreditBalance';
import { MissingTradesAlert } from '@subbie/modal/MissingTradesAlert/MissingTradesAlert';
import { makePlural } from '@subbie/modal/ProjectSlider/ProjectSliderBody/ProjectSubbies/util';
import { SubbieProfileSlider } from '@subbie/modal/SubbieProfileSlider';
import { CompanyDetailsTableCell } from './CompanyDetailsTableCell';
import { DateTimeCell } from './DateTimeCell';
import { EmptySubbieListCard } from './EmptySubbieListCard';
import { SubbieListSliderRoadblockModal } from './SubbieListSliderRoadblockModal';
import LoadingSpinnerFloating from '@shared/LoadingSpinner/LoadingSpinnerFloating';
import { useAccountHasStockTrades, useCurrentUser } from '@subbie/context/AccountProvider/hooks';
import { useProjectInterestedSubbies } from './hooks';
import { Action, Event, useAnalytics } from '@ascension/components/hooks/Analytics';
import {
  SubbieConnectionCreditBalanceDetails,
  useSubbieConnectionCreditBalance,
} from '@subbie/common/CreditBalance/hooks';
import {
  useConnectionPreference,
  useInSSCTrialExperiment,
} from '@subbie/modal/ProjectSlider/ProjectSliderBody/ProjectSubbies/hooks';
import { useProjectSubbieSummary } from '@subbie/modal/hooks';
import { ProjectInterestedSubbie } from './types';
import { InterestLevel } from '@ascension/_gqltypes/subbie.generated';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

type SubbieListSliderContentProps = {
  readonly projectId: EntityId;
  readonly watchlistStatus: InterestLevel | undefined;
  readonly onUnlock: () => void;
  readonly unlockInProgress: boolean;
  closeSliderContent: () => void;
};
type SubbieListSliderContentLockedCardProps = {
  readonly lockedSubbieCount: number;
  readonly onUnlockClicked: () => void;
  readonly creditBalanceSummary: SubbieConnectionCreditBalanceDetails;
};
const SubbieListSliderContentLockedCard = ({
  lockedSubbieCount,
  onUnlockClicked,
  creditBalanceSummary,
}: SubbieListSliderContentLockedCardProps) => {
  const subbieText = `${lockedSubbieCount} ${makePlural(
    lockedSubbieCount,
    'subcontractor',
    'subcontractors',
  )} ${makePlural(lockedSubbieCount, 'is', 'are')} locked. Use a credit to access them.`;

  return (
    <div>
      <div className={styles.lockedCardContainer}>
        <div className={styles.lockedCardContainerRow}>
          <div className={styles.textContainer}>
            <div className={styles.header}>{subbieText}</div>
          </div>

          <div className={styles.buttonContainer}>
            <Button
              variant={ButtonVariant.Secondary}
              size={ButtonSize.Small}
              onClick={onUnlockClicked}
            >
              Unlock
            </Button>
          </div>
        </div>

        <CreditBalance creditBalanceSummary={creditBalanceSummary} />
      </div>
    </div>
  );
};

const DateTimeTableCell = ({ value }: CellProps<ProjectInterestedSubbie>) => (
  <DateTimeCell dateTime={value} />
);

export const SubbieListSliderContent = ({
  projectId,
  watchlistStatus,
  onUnlock,
  unlockInProgress,
  closeSliderContent,
}: SubbieListSliderContentProps) => {
  const creditBalanceSummary = useSubbieConnectionCreditBalance();
  const { projectSubbieSummary } = useProjectSubbieSummary(projectId);
  const { connectionPreference } = useConnectionPreference(projectId);
  const [sliderOpen, setSliderOpen] = useState(false);
  const [accountId, setAccountId] = useState(0);
  const hasStockTrades = useAccountHasStockTrades();
  const { addEvent } = useAnalytics(Event.INTERACT);

  const { projectInterestedSubbies } = useProjectInterestedSubbies(projectId);

  const inTrialExperiment = useInSSCTrialExperiment();

  const currentUser = useCurrentUser();
  const [unlockModalRequested, setUnlockModalRequested] = useState(false);

  const onSubbieSelected = useCallback(
    (selectedAccountId: number) => {
      addEvent({
        action: Action.SSC_SUBBIE_PROFILE_VIEWED,
        projectId,
        subbieAccountId: selectedAccountId,
      });
      setAccountId(selectedAccountId);
      setSliderOpen(true);
    },
    [addEvent, projectId],
  );

  const inTrialAndProjectIsLocked = inTrialExperiment && !projectSubbieSummary?.unlocked;

  useEffect(() => {
    if (inTrialAndProjectIsLocked) {
      setUnlockModalRequested(true);
    }
  }, [inTrialAndProjectIsLocked]);

  if (
    projectInterestedSubbies === undefined ||
    projectSubbieSummary === undefined ||
    creditBalanceSummary === undefined
  ) {
    return <LoadingSpinnerFloating />;
  }

  const onUnlockClicked = () => {
    setUnlockModalRequested(true);
    addEvent({
      action: Action.SSC_SUBBIE_LIST_UNLOCK_BUTTON_CLICKED,
      projectId,
      subbieCountInterested: projectSubbieSummary?.subbieCount ?? 0,
      subbieCountViewed: connectionPreference?.viewCount ?? 0,
    });
  };

  const onCancelRoadblockModal = () => {
    setUnlockModalRequested(false);
    if (inTrialExperiment) {
      closeSliderContent();
    }
  };

  const { lockedSubbieCount } = projectSubbieSummary;
  const subbies = projectInterestedSubbies ?? [];
  const shouldShowUnlockModal = unlockModalRequested && !projectSubbieSummary.unlocked;

  return (
    <div id="subbie-list-slider-content">
      {shouldShowUnlockModal && (
        <SubbieListSliderRoadblockModal
          unlockInProgress={unlockInProgress}
          onUnlock={onUnlock}
          onCancel={onCancelRoadblockModal}
          creditBalanceSummary={creditBalanceSummary}
        />
      )}
      <div className={styles.container}>
        <div>
          {!hasStockTrades && <MissingTradesAlert isAdminUser={currentUser?.isAdmin} />}

          <Table<ProjectInterestedSubbie>
            columns={[
              {
                Header: 'Company',
                accessor: 'name',
                Cell: ({ row: { original: subbie } }: CellProps<ProjectInterestedSubbie>) => (
                  <CompanyDetailsTableCell subbie={subbie} onClickName={onSubbieSelected} />
                ),
                disableSortBy: true,
              },
              {
                Header: 'Viewed your details',
                accessor: 'viewedAt',
                Cell: DateTimeTableCell,
                disableSortBy: true,
                fitToContent: true,
              },
              {
                Header: 'Last active date',
                accessor: 'lastActiveAt',
                disableSortBy: true,
                Cell: DateTimeTableCell,
                fitToContent: true,
              },
            ]}
            data={subbies}
          />

          {subbies.length === 0 && (
            <EmptySubbieListCard
              connectionPreference={connectionPreference}
              watchlistStatus={watchlistStatus}
            />
          )}

          {lockedSubbieCount > 0 && (
            <SubbieListSliderContentLockedCard
              lockedSubbieCount={lockedSubbieCount}
              onUnlockClicked={onUnlockClicked}
              creditBalanceSummary={creditBalanceSummary}
            />
          )}
          <SubbieProfileSlider
            isOpen={sliderOpen}
            accountId={accountId}
            handleClose={() => setSliderOpen(false)}
          />
        </div>
      </div>
    </div>
  );
};
