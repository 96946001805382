import { useContext } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import {
  AlertIcon,
  Button,
  ButtonSize,
  ButtonVariant,
  E1Link,
  HelpBanner,
  HelpBannerContent,
  SkeletonLoader,
} from '@estimateone/frontend-components';
import { useKnowledgeBaseLocale } from '@profiles/providers/TranslationsProvider/TranslationsProvider';
import { ViewerContext } from '../../modules/ViewerProvider/ViewerProvider';
import styles from './ProjectHistory.module.scss';
import { ProjectHistoryCard } from './ProjectHistoryCard';
import {
  useLocalisedKnowledgeBaseLinks,
  useOwnAccount,
} from '@profiles/features/CompanyProfile/hooks';
import { HistoricalProject } from '@profiles/providers/ProfileProvider/types';

const MAXIMUM_PROJECTS = 20;

export type ProjectHistorySectionProps = {
  data: HistoricalProject[];
};

type ProjectHistoryProps = {
  data: HistoricalProject[];
};

export const ProjectHistoryNotOwnAccount = ({ data }: ProjectHistoryProps) => {
  const filteredData = data.filter((project) => project.deletedAt === null);

  return (
    <div className={styles.projectHistorySection}>
      <div className={styles.projectHistoryHeadingWrapper}>
        <p className={styles.projectHistoryHeader}>Project Experience</p>
      </div>
      {filteredData.length === 0 ? (
        <p className={styles.projectHistoryEmptyContent}>
          This subcontractor has no projects to view.
        </p>
      ) : (
        <div className={styles.historyContainer}>
          {filteredData.slice(0, MAXIMUM_PROJECTS).map((project) => (
            <ProjectHistoryCard key={project.id} project={project} />
          ))}
        </div>
      )}
    </div>
  );
};

const ProjectHistoryForOwnAccount = ({ data }: ProjectHistoryProps) => {
  const navigate = useNavigate();
  const filteredData = data.filter((project) => !project.deletedAt);
  const maxProjectsReached = filteredData.length >= MAXIMUM_PROJECTS;
  const locale = useKnowledgeBaseLocale();
  const { projectExperienceLearnMoreArticle } = useLocalisedKnowledgeBaseLinks(locale);

  return (
    <div className={styles.projectHistorySection}>
      <div className={styles.projectHistoryHeadingWrapper}>
        <p className={styles.projectHistoryHeader}>Project Experience</p>
        {!maxProjectsReached && (
          <E1Link aria-label="Add project" onClick={() => navigate('projects/add')}>
            Add
          </E1Link>
        )}
      </div>
      <p className={styles.projectHistorySubheader}>
        {`Add up to ${MAXIMUM_PROJECTS} projects to showcase your capability.`}
        <E1Link
          className={styles.kbArticleLink}
          target="_blank"
          href={projectExperienceLearnMoreArticle}
        >
          Learn more
        </E1Link>
      </p>
      {filteredData.length === 0 ? (
        <div className={styles.projectHistoryEmptyContentForOwnAccount}>
          <HelpBanner isHidden={false} toggleHidden={() => {}}>
            <HelpBannerContent>
              <h3>Showcase your best work and win more jobs</h3>
              <p className={styles.content}>Adding a project is quick and easy.</p>
              <Button
                variant={ButtonVariant.Grey}
                size={ButtonSize.Small}
                onClick={() => navigate('projects/add')}
                className={styles.projectHistoryAddButton}
              >
                Add a project
              </Button>
            </HelpBannerContent>
          </HelpBanner>
        </div>
      ) : (
        <div className={styles.historyContainer}>
          {filteredData.slice(0, MAXIMUM_PROJECTS).map((project) => (
            <ProjectHistoryCard key={project.id} project={project} showActionMenu />
          ))}
        </div>
      )}
    </div>
  );
};

export const ProjectHistory = ({ data }: ProjectHistoryProps) => {
  const { loading } = useContext(ViewerContext);
  const { isOwnAccount } = useOwnAccount();

  if (loading) {
    return null;
  }

  return isOwnAccount ? (
    <ProjectHistoryForOwnAccount data={data} />
  ) : (
    <ProjectHistoryNotOwnAccount data={data} />
  );
};

export const ProjectHistoryErrorIcon = () => (
  <div className={styles.projectHistorySection}>
    <div className={styles.projectHistoryHeadingWrapper}>
      <p className={styles.projectHistoryHeader}>Project Experience</p>
    </div>
    <AlertIcon title="project history error icon" />
  </div>
);

export const ProjectHistoryLoader = () => (
  <div className={styles.projectHistorySkeleton}>
    <SkeletonLoader width="50%" height="40px" />
    <SkeletonLoader height="16px" count={4} />
  </div>
);
