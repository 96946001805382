import $ from 'jquery';
import Routing from 'routing';
import moment from 'moment-timezone';
import _ from 'underscore';
import GenericList from '../classes/GenericList';
import { truncateMiddle } from '../utils/truncate';
import { setConstructionStageLinkHref, updateDataTableListeners } from './stage_table_helper';
import { getUserTimezone } from './timezone';

$(() => {
  const $currentCSTable = $('table.current-construction-table');
  if ($currentCSTable.length > 0) {
    $currentCSTable.each(function () {
      const $table = $(this);
      const $container = $(this).closest('.table-container');

      const url = Routing.generate('app_constructionstage_fetch');
      const projectTable = new GenericList(
        $table,
        (list) => {
          list.table = list.$target.DataTable({
            paging: false,
            data: list.data,
            lengthChange: false,
            info: false,
            order: [[0, 'asc']],
            columnDefs: [
              {
                targets: 'no-sort',
                orderable: false,
              },
            ],
            columns: [
              {
                data: 'projectName',
                render(data, row, col, details) {
                  const $template = $(list.$placeholderRow.find('td').get(details.col)).clone();
                  const $options = $template.find('.project_options');
                  const $title = $('<h5 />').text(truncateMiddle(data, 65));

                  $options.find('.tab-link').each((i, link) => {
                    setConstructionStageLinkHref($(link), col.id);
                  });

                  const route = Routing.generate('app_constructionstage_view', {
                    id: row.id,
                  });

                  return $title.prop('outerHTML') + $options.prop('outerHTML');
                },
              },
              {
                data: 'internal_ref',
                render: $.fn.dataTable.render.text(),
              },
              {
                data: 'suburb',
                render(data, display, row, details) {
                  let string = data;
                  if (row.stateAbbrev != null) {
                    string += `, ${row.stateAbbrev}`;
                  } else if (row.province != null) {
                    string += `, ${row.province}`;
                  }
                  return _.escape(string);
                },
              },
              {
                data: 'constructionEndDate',
                render(data, display, row, details) {
                  if (data == null) {
                    return '-';
                  }
                  const serverDate = moment.tz(data.date, data.timezone);
                  const date = serverDate.clone().tz(getUserTimezone());
                  if (display == 'display') {
                    return date.format('D MMM YYYY');
                  }
                  return date.format('X');
                },
              },
              {
                data: null,
                render(data, display, row, details) {
                  const $link = $('<a />').addClass('btn btn-block btn-sm');

                  let route;
                  if (row.constructionEndDate == null) {
                    route = Routing.generate('app_constructionstagewizard_details', {
                      id: row.id,
                    });
                    $link.addClass('btn-primary').html('Continue Setup');
                  } else {
                    route = Routing.generate('app_constructionstage_view', {
                      id: row.id,
                    });

                    $link.addClass('btn-secondary').html('Open Project');
                  }
                  $link.prop('href', route);
                  return $link.prop('outerHTML');
                },
              },
            ],
            dom: 'Rrtp',
          });
          list.toggleTableDisplay(true);
          list.$target.closest('.loading-container').addClass('has-loaded');
          updateDataTableListeners(list.$target);
        },
        url,
        (data) => data.stages,
      );

      $(document).on('stage-archive-changed', document, () => {
        projectTable.updateTable(true);
      });
    });
  }

  const $archivedConstructionTable = $('table.archived-construction-table');
  if ($archivedConstructionTable.length > 0) {
    $archivedConstructionTable.each(function () {
      const $table = $(this);
      const $container = $(this).closest('.table-container');

      const url = Routing.generate('app_constructionstage_fetch_closed');
      const projectTable = new GenericList(
        $table,
        (list) => {
          list.table = list.$target.DataTable({
            data: list.data,
            order: [[2, 'desc']],
            columns: [
              {
                data: 'projectName',
                render(data, display, row, details) {
                  const route = Routing.generate('app_constructionstage_view', {
                    id: row.id,
                  });
                  const $link = $('<a />')
                    .prop('title', data)
                    .addClass('project-link')
                    .text(truncateMiddle(data, 70));
                  $link.prop('href', route);
                  return $link.prop('outerHTML');
                },
              },
              {
                data: 'suburb',
                render(data, display, object, details) {
                  let string = data;
                  if (object.stateAbbrev != null) {
                    string += `, ${object.stateAbbrev}`;
                  } else if (object.province != null) {
                    string += `, ${object.province}`;
                  }
                  return _.escape(string);
                },
              },
              {
                data: 'constructionEndDate',
                render(data, display, row, details) {
                  if (data == null) {
                    return '-';
                  }
                  const serverDate = moment.tz(data.date, data.timezone);
                  const date = serverDate.clone().tz(getUserTimezone());
                  if (display == 'display') {
                    return date.format('D MMM YYYY');
                  }
                  return date.format('X');
                },
              },
              {
                data: 'builderName',
                render: $.fn.dataTable.render.text(),
              },
              {
                data: null,
                orderable: false,
                render(data, row, col, details) {
                  const $template = $(list.$placeholderRow.find('td').get(details.col)).clone();
                  $template.find('.unarchive-stage-dropdown').attr('data-stage-id', col.id);
                  return $template.html();
                },
              },
            ],
          });
          list.toggleTableDisplay(true);
          list.$target.closest('.loading-container').addClass('has-loaded');
        },
        url,
        (data) => data.stages,
        (list, row) => {
          row.DT_RowId = row.id;
          return row;
        },
      );

      $(document).on('stage-archive-changed', document, () => {
        projectTable.updateTable(true);
      });
    });
  }
});
