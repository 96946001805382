import { useCallback, useState } from 'react';
import { Button, ButtonSize, ButtonVariant } from '@estimateone/frontend-components';
import { SubbieAccountItemTagsList } from '@subbie/modal/ProjectSlider/ProjectSliderBody/ProjectSuppliers/SubbieAccountItemTagsList';
import { ProjectConnectionPreferenceType } from '../ProjectConnectionPreferenceType';
import { SupplierConnectionDetails } from '../SupplierConnectionDetails';
import { ProjectInterestedSuppliers_projectInterestedSuppliers as ProjectInterestedSupplier } from '@subbie/modal/ProjectSlider/ProjectSliderBody/ProjectSuppliers/types/ProjectInterestedSuppliers';
import styles from './styles.scss';

type ProjectInterestedSupplierCardProps = {
  interestedSupplier: ProjectInterestedSupplier;
  onContactClicked: (supplierId: number) => Promise<void>;
};

export const ProjectInterestedSupplierCard = ({
  interestedSupplier,
  onContactClicked,
}: ProjectInterestedSupplierCardProps) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const onClick = useCallback(async () => {
    setIsDisabled(true);
    await onContactClicked(interestedSupplier.accountId);
    setIsDisabled(false);
  }, [onContactClicked, interestedSupplier.accountId]);

  return (
    <div className={styles.supplierItemContainer}>
      <div className={styles.infoContainer}>
        <div className={styles.infoContainerHeader}>
          <p className={styles.supplierName}>{interestedSupplier.name}</p>
          <ProjectConnectionPreferenceType type={interestedSupplier.type} />
        </div>
        {interestedSupplier.location && (
          <p className={styles.addressInfo}>
            <span>{interestedSupplier.location}</span>
          </p>
        )}
      </div>
      <SubbieAccountItemTagsList stockTrades={interestedSupplier.stockTrades} />
      {interestedSupplier.viewed && interestedSupplier.contactAndNote ? (
        <SupplierConnectionDetails contactAndNote={interestedSupplier.contactAndNote} />
      ) : (
        <Button
          variant={ButtonVariant.Grey}
          size={ButtonSize.Small}
          className={styles.showContactButton}
          disabled={isDisabled}
          onClick={onClick}
        >
          Show contact details
        </Button>
      )}
    </div>
  );
};
