import $ from 'jquery';

$(() => {
  const $body = $('body');
  $body.on('click', '.nps-score', ({ currentTarget }) => {
    const $elem = $(currentTarget);
    const $li = $elem.parent();
    const $inputResponse = $('#user_nps_response_score');
    const $fbContainer = $('.nps-feedback-container');

    $li.siblings().removeClass('active');
    $li.addClass('active');
    $inputResponse.val($elem.data('nps-score'));
    $fbContainer.removeClass('hide');
    $fbContainer.find('textarea').trigger('focus');
  });
});
