import { useState } from 'react';
import { E1Link } from '@estimateone/frontend-components';
import { AccountUpgradeModal } from '@ascension/components/account-upgrade/modal';
import { useCanUpgradeLiteToEssential } from '@ascension/components/account-upgrade/modal/hooks/useCanUpgradeLiteToEssential';

type CreditsUpgradeLinkProps = {
  upgradeUrl: string;
  upgradeLabel: string;
  upgradeLiteToEssentialLabel?: string;
};

const CreditsUpgradeLink = ({
  upgradeUrl,
  upgradeLabel,
  upgradeLiteToEssentialLabel = upgradeLabel,
}: CreditsUpgradeLinkProps) => {
  const canAccessLiteToEssentialUpgradeFlow = useCanUpgradeLiteToEssential();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleLaunchModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return canAccessLiteToEssentialUpgradeFlow ? (
    <>
      <E1Link onClick={handleLaunchModal}>{upgradeLiteToEssentialLabel}</E1Link>
      <AccountUpgradeModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </>
  ) : (
    <E1Link href={upgradeUrl}>{upgradeLabel}</E1Link>
  );
};

export { CreditsUpgradeLink };
