import { AlertIcon, SkeletonLoader } from '@estimateone/frontend-components';
import {
  CoreTradeTag,
  sortTradesByCoreTrades,
  TradesTagGroupWithKnowledgeBaseLink,
} from '@profiles/features/CompanyProfile/components/Trades/CoreTrades';
import { useKnowledgeBaseLocale } from '@profiles/providers/TranslationsProvider/TranslationsProvider';
import { TagGroup as NewTagGroup, TagGroup } from '../NewTagGroup';
import { NoContentPlaceholder } from '../NoContentPlaceholder';
import { EditTrades } from './EditTrades';
import styles from './Trades.module.scss';
import { useTradeFormService } from '@profiles/features/CompanyProfile/components/Trades/hooks/useTradeFormService';
import { useOwnAccount } from '@profiles/features/CompanyProfile/hooks';
import { CompanyProfileQuery } from '@profiles/providers/ProfileProvider/queries.generated';

export type ProfileStockTrade = NonNullable<
  CompanyProfileQuery['companyProfile']
>['trades'][number];

export const TradesErrorView = () => (
  <div className={styles.tradesContainer}>
    <div>
      <h4 className={styles.tradesHeader}>Trades</h4>
      <AlertIcon title="trades error icon" />
    </div>
  </div>
);

const extractTradesNamesAndIds = (
  acc: {
    ids: number[];
    names: string[];
  },
  trade: ProfileStockTrade | null,
): {
  ids: number[];
  names: string[];
} => {
  const names = trade?.name ? [...acc.names, trade.name] : acc.names;
  const ids = trade?.id ? [...acc.ids, trade.id] : acc.ids;

  return {
    names,
    ids,
  };
};

export const TradesLoadingView = () => (
  <NewTagGroup header="Trades">
    <SkeletonLoader height="16px" count={2} />
  </NewTagGroup>
);

type TradesProps = {
  data: ProfileStockTrade[];
};

export const Trades = ({ data }: TradesProps) => {
  const { isOwnAccount } = useOwnAccount();
  const tradeFormService = useTradeFormService(!isOwnAccount);
  const knowledgeBaseLocale = useKnowledgeBaseLocale();

  const tradesSortedByCoreTrades = sortTradesByCoreTrades(data);

  /**
   * TODO: wat?
   * Extracting trades names and ids array separately from trades array to pass them as props to child elements
   */
  const parsedTrades = data.reduce(extractTradesNamesAndIds, {
    ids: [],
    names: [],
  });

  const { names } = parsedTrades;

  if (isOwnAccount) {
    return (
      <div className={styles.tradesContainer}>
        <TradesTagGroupWithKnowledgeBaseLink knowledgeBaseLocale={knowledgeBaseLocale}>
          {tradesSortedByCoreTrades.length > 0 ? (
            tradesSortedByCoreTrades.map((trade) =>
              trade.isCoreTrade ? (
                <CoreTradeTag
                  key={`trades-core-trade-${trade.id}`}
                  text={trade.name}
                  className={styles.coreTradeTag}
                />
              ) : (
                <TagGroup.Tag key={`trades-stock-trade-${trade.id}`} text={trade.name} />
              ),
            )
          ) : (
            <NoContentPlaceholder />
          )}
        </TradesTagGroupWithKnowledgeBaseLink>
        <EditTrades
          trades={data}
          tradeFormService={tradeFormService}
          knowledgeBaseLocale={knowledgeBaseLocale}
        />
      </div>
    );
  }

  return (
    <div className={styles.tradesContainer}>
      <TagGroup header="Trades">
        {names.length > 0 ? (
          names.map((name) => <TagGroup.Tag key={`trades-${name}`} text={name} />)
        ) : (
          <NoContentPlaceholder />
        )}
      </TagGroup>
    </div>
  );
};
