import Select from 'react-select';
import reactSelectStyles from './reactSelectStyles';
import { LabelledOption } from '../../../../../shared/Select';
import { EntityId } from '../../../../../../types';
import styles from './styles.scss';

type BillingContact = { id: EntityId; fullName: string };

type BillingContactSelectorProps = {
  billingContacts: BillingContact[];
  selectedBillingContact?: BillingContact;
  setBillingContact: (bc: BillingContact) => void;
};

const BillingContactSelector = ({
  billingContacts,
  selectedBillingContact,
  setBillingContact,
}: BillingContactSelectorProps) => {
  const billingContactOptions = billingContacts.map(({ id: userId, fullName }) => ({
    value: userId,
    label: fullName,
  }));

  const handleSelection = ({ label, value }: LabelledOption<EntityId>) =>
    setBillingContact({ id: value, fullName: label });
  const reactSelectValue =
    selectedBillingContact === undefined
      ? null
      : { value: selectedBillingContact.id, label: selectedBillingContact.fullName };

  return (
    <div className={styles.billingContactSelector}>
      <p className={styles.label}>Select billing contact</p>
      <Select
        value={reactSelectValue}
        placeholder="- Please select -"
        onChange={handleSelection}
        styles={reactSelectStyles}
        options={billingContactOptions}
      />
    </div>
  );
};

export default BillingContactSelector;
