import { E1Link, LinkVariant } from '@estimateone/frontend-components';
import styles from './styles.scss';

type EmptyStateProps = {
  isHandoverStage: boolean;
};

const NoTenderInvitesEmptyState = () => (
  <>No subcontractors were invited to quote during the Tender phase.</>
);

const NotHandoverStageEmptyState = () => (
  <>
    <p>
      This Procurement project is not linked to a Tender project, so no subcontractors invited to
      quote by estimators can be shown.
    </p>
    <E1Link
      variant={LinkVariant.Primary}
      href="https://builder.support.estimateone.com/en/articles/1705655881-how-to-handover-a-tender-project"
      target="_blank"
      className={styles.nonItalic}
    >
      Learn more about handing over projects on E1.
    </E1Link>
  </>
);

export const TenderResponsesEmptyState = ({ isHandoverStage }: EmptyStateProps) => (
  <div className={styles.noDataText}>
    {isHandoverStage ? <NoTenderInvitesEmptyState /> : <NotHandoverStageEmptyState />}
  </div>
);
