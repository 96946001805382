import $ from 'jquery';
import Routing from 'routing';
import E1Request from '../classes/E1Request';
import gtmDataLayerPush from '../utils/google_tag_manager_helper';
import { getUserId } from '../utils/helpers';
import setLocation from '../utils/locator';
import initialiseSortableTable from '../utils/sortable_table';
import { WATCHLIST_UPDATE_EVENT, WatchlistUpdateEvent } from '@subbie/context/WatchlistProvider';
import { AssignProjectToTeamMember_assignProjectToTeamMember_AccountWatchlistProjectAssignment as WatchlistProjectAssignment } from '@subbie/context/WatchlistProvider/types/AssignProjectToTeamMember';
import { WatchlistStatus } from '../../enums';
import { ProjectSubbieSummary } from '@subbie/modal/types/ProjectSubbieSummary';

const WATCHLIST_DROPDOWN_CLASS = '.interest-levels-dropdown.watchlist-dropdown';
const WATCHLIST_SSC_COUNT_CLASS = '.subbies-count-column .subbies-count';
const WATCHLIST_SSC_LOCK_STATUS_CLASS = '.subbies-count-column';

export const addProjectToWatchList = async (
  projectId: number,
  status: WatchlistStatus,
  watchlistSource: string,
  showModal = false,
) =>
  new E1Request<
    {
      success: boolean;
      // eslint-disable-next-line camelcase
      watchlistEntry?: { id: number; status: WatchlistStatus; created_at: string };
    },
    { status: WatchlistStatus; watchlistSource: string }
  >(
    Routing.generate('app_project_watch', { id: projectId }),
    'POST',
    { status, watchlistSource },
    false,
  )
    .setShowLoadingModal(showModal)
    .submit();

const getWatchedProjectsCount = async () =>
  (
    await new E1Request<{
      success: boolean;
      watchedProjectsCount: number;
    }>(Routing.generate('app_watchlist_count')).submit()
  ).watchedProjectsCount;

/*
 * This is very expensive for accounts with lots of watchlist entries
 * Run it separately
 */
const updateWatchlistCountInMenu = () => {
  const $watchlistCounter = $('.watchlistCounter');

  if (!$watchlistCounter.length) return;

  $watchlistCounter.addClass('loading');

  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  getWatchedProjectsCount().then((count) => {
    $watchlistCounter.text(count).removeClass('loading');
  });
};

export const setInterestLevelSelectorStatus = ($dropdown: JQuery, status: WatchlistStatus) => {
  if (!$dropdown.length) {
    // Project has been removed from the page, so there's
    // no need to try and update the dropdown status.
    return;
  }

  const dataAttrName = 'data-status';
  const getStatusClass = (str: string) => str.replace(' ', '-').toLowerCase();

  const statusLabel =
    status >= 0
      ? window.global!.watchlistStatuses![status]
      : $dropdown.data('interest-levels-default-status');

  $dropdown
    .attr(dataAttrName, getStatusClass(statusLabel))
    .find('.project-interest-levels-status')
    .text(statusLabel);
};

const updateWatchlistDropdown = (projectId: number, status: number) => {
  const $watchlistDropdown = $(`${WATCHLIST_DROPDOWN_CLASS}[data-project-id="${projectId}"]`);
  return setInterestLevelSelectorStatus($watchlistDropdown, status);
};

export const setAssigneeDataAttributes = (
  $dropdown: JQuery,
  assignee: WatchlistProjectAssignment,
) => {
  if (!$dropdown.length) {
    // Project has been removed from the page, so there's
    // no need to try and update the dropdown status.
    return;
  }
  $dropdown.attr('data-assigned-at', assignee.createdAt).attr('data-user-id', assignee.assignee.id);
};

const updateWatchlistAssigneeDataAttributes = (
  projectId: number,
  assignee: WatchlistProjectAssignment,
) => {
  const $watchlistAssigneeElement = $(
    `[data-hook="assign-project"][data-project-id="${projectId}"]`,
  );
  return setAssigneeDataAttributes($watchlistAssigneeElement, assignee);
};

const updateSscComponents = (
  projectId: number,
  { subbieCount, unlocked }: ProjectSubbieSummary['projectSubbieSummary'],
) => {
  const $sscCountClass = $(`${WATCHLIST_SSC_COUNT_CLASS}[data-project-id="${projectId}"]`);
  if ($sscCountClass.length) {
    $sscCountClass
      .children('span')
      .text(`${subbieCount} Subcontractor${subbieCount > 1 ? 's' : ''}`);
  }
  const $sscLockStatusClass = $(
    `${WATCHLIST_SSC_LOCK_STATUS_CLASS}[data-project-id="${projectId}"]`,
  );
  if ($sscLockStatusClass.length) {
    $sscLockStatusClass.children('.count').text(subbieCount.toString());
    $sscLockStatusClass.children('.locked-container').toggle(!unlocked);
    $sscLockStatusClass.children('.unlocked-container').toggle(unlocked);
  }
};

const filterTable = (tableClass: string) => {
  const $tableToFilter = $(tableClass);

  const $userFilter = $('select.watchlist-user-filter[multiple="multiple"]').filter(
    `select[data-filter-target='${tableClass}']`,
  );
  const $selectedUserFilters = $userFilter.find('option:selected');
  const chosenUserIds = $selectedUserFilters.toArray().reduce<number[]>((acc, opt) => {
    const id = $(opt).val();
    return id ? acc.concat([parseInt(id.toString())]) : acc;
  }, new Array<number>());

  const $allUserOptions = $userFilter.find('option');

  /*
   * Make sure we show everything so projects watched by deleted users are not hidden
   */
  const showAllUsers = $allUserOptions.length === $selectedUserFilters.length;

  const $interestFilter = $('select.project-interest-filter[multiple="multiple"]').filter(
    `select[data-filter-target='${tableClass}']`,
  );
  const $selectedInterestFilters = $interestFilter.find('option:selected');
  const chosenInterestLevels = $selectedInterestFilters
    .toArray()
    .map((opt) => $(opt).text().toLowerCase());

  $tableToFilter
    .find('tbody tr')
    .toArray()
    .forEach((tr) => {
      const $watchedProjectRow = $(tr);
      const userIdForProjectRow = $watchedProjectRow.data('created-by');
      const assigneeId = parseInt(
        $watchedProjectRow.find('div[data-hook="assign-project"]').data('user-id'),
      );

      const isAssigneeIdInTheChosenUsers =
        !Number.isNaN(assigneeId) && chosenUserIds.includes(assigneeId);
      const isUserRowIdInTheChosenUsers =
        chosenUserIds.includes(userIdForProjectRow) && Number.isNaN(assigneeId);

      const interestLevelForProjectRow = $watchedProjectRow
        .find('.interest-levels-dropdown')
        .data('status');

      const isHidden =
        !chosenInterestLevels.includes(interestLevelForProjectRow) ||
        !(showAllUsers || isAssigneeIdInTheChosenUsers || isUserRowIdInTheChosenUsers);

      $watchedProjectRow.toggleClass('hide', isHidden);
    });
};

const initInterestFilter = (filter: HTMLElement) => {
  const $select = $(filter);
  $select
    .multiselect({
      includeSelectAllOption: false,
      onChange() {
        // @ts-ignore
        this.updateButtonText();
        filterTable($select.data('filter-target'));
      },
    })
    .multiselect('selectAll', false)
    .multiselect('updateButtonText');
};

const isUserIdInUserFilter = ($select: JQuery, userId: number) =>
  $select.find(`option[value='${userId}']`).length !== 0;

const initUserFilter = (filter: HTMLElement) => {
  const currentUserId = getUserId();
  const $select = $(filter).multiselect({
    includeSelectAllOption: true,
    onChange(option, checked) {
      if (
        (option && option.val() === undefined && checked === false) ||
        $select.find('option:selected').length === 0
      ) {
        // @ts-ignore
        this.select(currentUserId, true);
      }

      if (option === undefined) {
        if (checked) {
          // @ts-ignore
          this.selectAll(false, true);
        } else {
          // @ts-ignore
          this.deselectAll(false, true);
        }
      }
      // @ts-ignore
      this.updateButtonText();
      filterTable($select.data('filter-target'));
    },
  });

  if (currentUserId && isUserIdInUserFilter($select, currentUserId)) {
    $select.multiselect('select', currentUserId);
  } else {
    $select.multiselect('selectAll', false);
  }
  $select.multiselect('updateButtonText');
};

const applyInitialFilters = (filter: HTMLElement) => {
  const { filterTarget } = filter.dataset;
  if (filterTarget) filterTable(filterTarget);
};

$(() => {
  const $watchlistTables = $('table.watchlist-events-enabled');
  $(document).on('watchlist:postdraw', (__, projectId, status) => {
    const menuSelector = `.interest-levels-dropdown[data-project-id=${projectId}]`;

    $watchlistTables.toArray().forEach((tbl) => {
      const $table = $(tbl);
      const $updatedRow = $table.find('tr').has(menuSelector);

      const restrictAbove = $table.data('restrict-interest-above');
      const dt = $table.DataTable();

      if (!$updatedRow.length) return;

      const dtRow = dt.row($updatedRow);

      if (restrictAbove !== undefined && status <= parseInt(restrictAbove)) {
        dtRow.remove();
        dtRow.draw();
      }
    });
  });

  $('select.project-interest-filter[multiple="multiple"]').toArray().forEach(initInterestFilter);

  const userFilters = $('select.watchlist-user-filter[multiple="multiple"]').toArray();

  userFilters.forEach(initUserFilter);
  userFilters.forEach(applyInitialFilters);

  $('.locator-dist-calc-watchlist')
    .toArray()
    .forEach((loc) => setLocation($(loc)));

  $('table.sortable-table-watchlist').toArray().forEach(initialiseSortableTable);

  const $body = $('body');
  const budgetUpgradeClass = 'restricted-budget-trigger';
  const awardedUpgradeClass = 'upgrade-awarded-trigger';
  const fiftyUpgradeClass = 'restricted-fifty-trigger';
  const pricintExperimentUpgradeClass = 'restricted-pricing-experiment-trigger';

  $body.on('click change', '.modify-project-interest-level', async ({ currentTarget, type }) => {
    const $trigger = $(currentTarget);
    const projectIdAttr = 'project-id';
    const watchlistSource = $trigger.closest(WATCHLIST_DROPDOWN_CLASS).data('source');

    // eslint-disable-next-line fp/no-let
    let status: WatchlistStatus | null = null;

    switch (type) {
      case 'click':
        if ($trigger.is('select') || $trigger.hasClass('select2')) {
          return;
        }
        // eslint-disable-next-line fp/no-mutation
        status = parseInt($trigger.data('interest-level-status'));
        break;
      case 'change':
        // eslint-disable-next-line fp/no-mutation
        status = (
          $trigger.hasClass('select2') ? $trigger.children('select').val() : $trigger.val()
        ) as WatchlistStatus;
        break;
      default:
        return;
    }

    if (
      status === null ||
      $trigger.hasClass(budgetUpgradeClass) ||
      $trigger.hasClass(awardedUpgradeClass) ||
      $trigger.hasClass(fiftyUpgradeClass) ||
      $trigger.hasClass(pricintExperimentUpgradeClass)
    ) {
      return;
    }

    const projectId =
      $trigger.data(projectIdAttr) ||
      $trigger.closest('tr[data-project-id]').data(projectIdAttr) ||
      $trigger.closest(WATCHLIST_DROPDOWN_CLASS).data(projectIdAttr);

    if (window.global.account?.watchlistIds[projectId] === status) {
      return;
    }

    // Statuses: 1=Interested | 3=Quoting | 4=Quoted
    if ([1, 2, 4].includes(status)) {
      gtmDataLayerPush({ event: 'watchlist-add' });
    }

    const watchlistAddResponse = await addProjectToWatchList(projectId, status, watchlistSource);

    if (watchlistAddResponse.watchlistEntry) {
      const watchStatus = watchlistAddResponse.watchlistEntry.status;

      if (window.global?.account) {
        // eslint-disable-next-line fp/no-mutation
        window.global.account.watchlistIds[projectId] = watchStatus;
      }
      $(document).trigger('watchlist:update', [projectId, watchStatus]);

      updateWatchlistDropdown(projectId, status);
    }
  });

  // listen for event fired from React
  document.addEventListener(WATCHLIST_UPDATE_EVENT, async ({ detail }: WatchlistUpdateEvent) => {
    // eslint-disable-next-line default-case
    switch (detail.updateType) {
      case 'ssc_status': {
        const { projectId, projectSubbieSummary } = detail;
        updateSscComponents(projectId, projectSubbieSummary);
        break;
      }
      case 'watchlist_status': {
        const { projectId, status } = detail;
        if (window.global?.account) {
          const existingStatus = window.global.account.watchlistIds[projectId];
          if (existingStatus === status) {
            return;
          }
          // eslint-disable-next-line fp/no-mutation
          window.global.account.watchlistIds[projectId] = status;
        }

        $(document).trigger('watchlist:update', [projectId, status]);
        updateWatchlistCountInMenu();
        updateWatchlistDropdown(projectId, status);
        break;
      }

      case 'watchlist_assignee': {
        const { projectId, assignee } = detail;

        // We need this to update the "data-" attributes on the root element that the React component
        // lives in so that the table sorting/filtering works properly.
        // When the WatchlistUpdate event is dispatched it causes the React component to update
        updateWatchlistAssigneeDataAttributes(projectId, assignee);
        const watchStatus = window?.global?.account?.watchlistIds[projectId];
        if (watchStatus) {
          $(document).trigger('watchlist:update', [projectId, watchStatus]);
        }
      }
    }
  });

  updateWatchlistCountInMenu();
});
