import { ComponentProps } from 'react';
import ProjectNotes from '@subbie/modal/ProjectSlider/ProjectSliderBody/ProjectNotes';
import addUserLinks from '@subbie/modal/ProjectSlider/ProjectSliderBody/ProjectNotes/addUserLinks';

type ProjectNotesExperimentSwitchProps = Omit<
  ComponentProps<typeof ProjectNotes>,
  'noteContentTransformer'
>;

const ProjectNotesExperiment = ({
  projectNotes,
  deleteProjectNote,
}: ProjectNotesExperimentSwitchProps) => (
  <ProjectNotes
    projectNotes={projectNotes}
    deleteProjectNote={deleteProjectNote}
    noteContentTransformer={addUserLinks}
  />
);

export default ProjectNotesExperiment;
