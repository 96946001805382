import { StylesConfig } from 'react-select';

const reactSelectStyles = (targetHeight: number): StylesConfig => ({
  container: (base) => ({
    ...base,
    fontSize: '.8rem',
    width: '160px',
    border: 'none',
  }),
  control: (base) => ({
    ...base,
    minHeight: 'initial',
    fontSize: '.8rem',
    cursor: 'pointer',
    borderRadius: '4px',
    borderWidth: '1px',
    borderStyle: 'solid',
    boxShadow: 'none !important',
  }),
  valueContainer: (base) => ({
    ...base,
    height: `${targetHeight - 1 - 1}px`,
    padding: '0 8px',
  }),
  singleValue: (base) => ({
    ...base,
    padding: '4px',
    fontSize: '.8rem',
    fontWeight: 500,
    alignItems: 'center',
  }),
  clearIndicator: (base) => ({
    ...base,
    padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
  }),
  option: (base) => ({
    ...base,
    fontSize: '.8rem',
  }),
});

export default reactSelectStyles;
