import { gql } from '@apollo/client';

export const UNLOCK_PROJECT_SUBBIES = gql`
  mutation ProjectSubbiesUnlock($projectId: EntityId!) {
    projectSubbiesUnlock(projectId: $projectId) {
      __typename
      ... on ProjectSubbiesUnlockSuccess {
        currentBalance {
          id
          allowed
          used
          startDate
          renewalDate
        }
        currentSummary {
          id
          projectId
          subbieCount
          lockedSubbieCount
          unlocked
          totalSubbieCount
        }
        currentWatchlistInterestLevel
        projectInterestedSubbies {
          __typename
          id
          accountId
          name
          mainLocation {
            id
            accountOfficeId
            suburb
            state
          }
          numberOfOtherLocations
          stockTrades {
            id
            name
          }
          viewedAt
          lastActiveAt
        }
      }
      ... on ProjectSubbiesUnlockErrorNoCreditsLeft {
        currentBalance {
          id
          allowed
          used
          startDate
          renewalDate
        }
      }
    }
  }
`;
