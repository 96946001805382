import { memo } from 'react';
import {
  ButtonSize,
  LoadingSpinner,
  LoadingSpinnerSize,
  Tooltip,
} from '@estimateone/frontend-components';
import { DefaultOperatingCountryProvider } from '@ascension/components/localisation/DefaultOperatingCountryProvider';
import NoTradeSelected from '@builder/common/SubbieNetwork/NoTradeSelectedView';
import { InviteToQuoteAddressBookMenuButton } from '@builder/features/InviteToQuote/InviteToQuoteAddressBookMenuButton';
import { ProfileSnapshot } from '@builder/features/SubbieNetwork/components/ProfileSnapshot';
import { SubbieNetworkConnectButton } from '@builder/features/SubbieNetwork/components/SubbieNetworkConnectButton';
import { SubbieNetworkInviteToQuoteButton } from '@builder/features/SubbieNetwork/components/SubbieNetworkInviteToQuoteButton';
import { SyncCompanyModal } from '@builder/features/SyncCompanyProfile';
import { isConnectedCompany } from '../../utils';
import { EmptyResultsView } from './EmptyResultsView';
import { useIsAustralianAccount } from '../../providers/AscensionDataProvider/hooks';
import { useConfirmedFilterValues } from '../SubbieNetworkFilterDrawer/hooks';
import { useSubbieNetworkSearchWithAnalytics } from './hooks';
import { NetworkCompany } from '@builder/features/SubbieNetwork/components/SubbieNetworkSearchResults/hooks/utils';
import styles from './styles.module.scss';

export const reloadPage = () => {
  if (window?.location) {
    window.location.reload();
  }
};

type ProfileSnapshotActionsProps = {
  company: NetworkCompany;
  hasProcurementPhaseFeature: boolean;
};

const ProfileSnapshotActions = ({
  company,
  hasProcurementPhaseFeature,
}: ProfileSnapshotActionsProps) => {
  const isANetworkProfile =
    company.__typename === 'BuilderNetworkCompany' || isConnectedCompany(company);

  if (!isANetworkProfile) {
    return <SubbieNetworkConnectButton name={company.name} profileId={String(company.id)} />;
  }

  if (hasProcurementPhaseFeature) {
    return (
      <div data-testid="procurement-phase-container">
        <InviteToQuoteAddressBookMenuButton
          preselectedCompanyId={parseInt(company.id.toString())}
          size={ButtonSize.Small}
        />
      </div>
    );
  }

  return <SubbieNetworkInviteToQuoteButton companyId={company.id} />;
};

const SubbieNetworkSearchResults = memo(
  ({ hasProcurementPhaseFeature }: { hasProcurementPhaseFeature: boolean }) => {
    const confirmedFilters = useConfirmedFilterValues();
    const { data, loading } = useSubbieNetworkSearchWithAnalytics();
    const isAustralianBuilder = useIsAustralianAccount();

    if (loading) {
      return <LoadingSpinner size={LoadingSpinnerSize.Medium} />;
    }

    if (!confirmedFilters.stockTradeId) {
      return <NoTradeSelected />;
    }

    if (!data) {
      return null;
    }

    const { results: networkCompanies } = data;

    if (networkCompanies.length === 0) {
      return <EmptyResultsView />;
    }

    return (
      <>
        <div className={styles.resultDescription}>Results sorted by most relevant:</div>
        <ul className={styles.resultList}>
          {networkCompanies.map((networkCompany) => (
            <ProfileSnapshot
              key={`company-${networkCompany.__typename}::${networkCompany.id}`}
              company={networkCompany}
              isAustralianBuilder={isAustralianBuilder}
            >
              <ProfileSnapshotActions
                company={networkCompany}
                hasProcurementPhaseFeature={hasProcurementPhaseFeature}
              />
            </ProfileSnapshot>
          ))}
        </ul>
        <DefaultOperatingCountryProvider>
          <SyncCompanyModal />
        </DefaultOperatingCountryProvider>
        <Tooltip
          tooltipId="connected-tooltip"
          text="You are Connected to this E1 Network Company"
        />
        <Tooltip tooltipId="not-connected-tooltip" text="E1 Network Company" />
      </>
    );
  },
);

export default SubbieNetworkSearchResults;
