import { useCallback, useState } from 'react';
import { EntityId } from '@ascension/types';

export const useNotesSlideout = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCritical, setIsCritical] = useState(false);
  const [packageId, setPackageId] = useState<number>();
  const [packageTitle, setPackageTitle] = useState<string>();

  const openNotesSlider = useCallback((pkgId: EntityId, pkgTitle: string, critical: boolean) => {
    setPackageId(pkgId);
    setPackageTitle(pkgTitle);
    setIsCritical(critical);
    setIsOpen(true);
  }, []);

  const closeNoteSlider = useCallback(() => {
    setIsOpen(false);
  }, []);

  return {
    isOpen,
    isCritical,
    packageId,
    packageTitle,
    openNotesSlider,
    closeNoteSlider,
  };
};
