import { RoadblockCopyBudget, RoadblockCopyBudgetAdditionalLicense } from '../RoadblockCopy';
import {
  RoadblockHeaderAdditionalLicense,
  RoadblockHeaderBudget,
  RoadblockHeaderNationalLicense,
} from '../RoadblockHeaders';
import { RoadblockReasonBudget } from '../RoadblockReasons';
import {
  RoadblockSectionAdditionalLicense,
  RoadblockSectionPremiumPage,
} from '../RoadblockSections';
import { useProjectPaywallValue } from '@subbie/context/AccountProvider/hooks';

type AdditionalLicenseContentProps = {
  requireNationalLicense: boolean;
  licenseName: string;
  projectState: string;
  projectPaywallValue: number;
};

const AdditionalLicenseContent = ({
  requireNationalLicense,
  licenseName,
  projectState,
  projectPaywallValue,
}: AdditionalLicenseContentProps) => (
  <>
    <RoadblockSectionAdditionalLicense licenseName={licenseName} />
    {requireNationalLicense ? (
      <RoadblockHeaderNationalLicense />
    ) : (
      <RoadblockHeaderAdditionalLicense />
    )}
    <RoadblockCopyBudgetAdditionalLicense
      requireNationalLicense={requireNationalLicense}
      projectState={projectState}
      projectPaywallValue={projectPaywallValue}
    />
  </>
);

type RoadblockBudgetContentProps = {
  requireAdditionalLicense: boolean;
  requireAdditionalLicenseShortState: string;
  projectState: string;
  productName: string;
};

const RoadblockBudgetContent = ({
  requireAdditionalLicense,
  requireAdditionalLicenseShortState,
  projectState,
  productName,
}: RoadblockBudgetContentProps) => {
  const projectPaywallValue = useProjectPaywallValue();
  const requireNationalLicense = requireAdditionalLicenseShortState === 'National';
  const licenseName = requireNationalLicense ? 'National' : projectState;

  if (projectPaywallValue === undefined) {
    return null;
  }

  return (
    <>
      <RoadblockReasonBudget projectPaywallValue={projectPaywallValue} />
      {requireAdditionalLicense ? (
        <AdditionalLicenseContent
          requireNationalLicense={requireNationalLicense}
          licenseName={licenseName}
          projectState={projectState}
          projectPaywallValue={projectPaywallValue}
        />
      ) : (
        <>
          <RoadblockSectionPremiumPage productName={productName} />
          <RoadblockHeaderBudget projectPaywallValue={projectPaywallValue} />
          <RoadblockCopyBudget
            projectState={projectState}
            projectPaywallValue={projectPaywallValue}
          />
        </>
      )}
    </>
  );
};

export default RoadblockBudgetContent;
