import { BuilderContactDetails } from './BuilderContactDetails';
import BuilderName from './BuilderName';
import ConstructionDates from './ConstructionDates';
import { QuotesDueBy } from './QuotesDueBy';
import { StageDocs } from './StageDocs';
import { getTranslation, LangRef } from '@shared/Util/lang';
import { SliderStageFragment as SliderStage } from '@subbie/modal/queries.generated';
import { StageStatus } from '@ascension/enums';
import { EntityId } from '../../../types';
import styles from './styles.scss';

export const isIncognitoBuilder = (
  builderDetails: SliderStage['builderDetails'],
): builderDetails is Extract<
  SliderStage['builderDetails'],
  { __typename: 'HiddenBuilderDetails' }
> => builderDetails.__typename === 'HiddenBuilderDetails';

type StageRowProps = {
  stage: SliderStage;
  projectId: EntityId;
  keywords?: string[];
};

export const StageRow = ({ stage, projectId, keywords = [] }: StageRowProps) => {
  const {
    name,
    status,
    tenderQuotesDue,
    constructionStartDate,
    constructionEndDate,
    hasMultipleQuoteDueDates,
    activePackages,
    builderDetails,
    mostRecentQuote,
  } = stage;

  const isAwarded = status === StageStatus.TENDER_AWARDED;

  const hasPackageWithContent = activePackages.some(
    ({ contentLastAddedAt }) => contentLastAddedAt !== null,
  );

  return (
    <tr className={isAwarded ? styles.stageAwarded : undefined}>
      <td>
        {isIncognitoBuilder(builderDetails) ? (
          <BuilderName
            name={getTranslation(LangRef.INCOGNITO_BUILDER_HEADING)}
            isAwarded={isAwarded}
          />
        ) : (
          <BuilderName id={builderDetails?.id} name={builderDetails?.name} isAwarded={isAwarded} />
        )}
        {!isIncognitoBuilder(builderDetails) && name !== null && (
          <BuilderContactDetails
            stageName={name}
            contactName={builderDetails.contactName}
            contactEmail={builderDetails.contactEmail}
            contactPhone={builderDetails.contactPhone}
          />
        )}
      </td>
      <td>
        <div className={styles.quotesDueCell}>
          <QuotesDueBy
            tenderQuotesDue={tenderQuotesDue}
            mostRecentQuoteId={mostRecentQuote?.id}
            showEndDateDisclaimer={hasMultipleQuoteDueDates}
          />
          <ConstructionDates start={constructionStartDate} end={constructionEndDate} />
        </div>
      </td>
      <td className={styles.stageDocs}>
        {hasPackageWithContent ? (
          <StageDocs
            stage={stage}
            projectId={projectId}
            keywords={keywords}
            builderName={
              isIncognitoBuilder(builderDetails)
                ? getTranslation(LangRef.INCOGNITO_BUILDER_HEADING)
                : builderDetails?.name
            }
          />
        ) : (
          <div className={styles.sdRow}>
            <span className={styles.label}>No Packages Available</span>
            <span>
              {isIncognitoBuilder(builderDetails)
                ? getTranslation(LangRef.INCOGNITO_BUILDER_HEADING)
                : builderDetails?.name}{' '}
              has not uploaded any documents yet. Please check back later.
            </span>
          </div>
        )}
      </td>
    </tr>
  );
};
