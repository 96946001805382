import {
  E1NetworkCompany,
  NetworkCompanyResponse,
  ShadowNetworkCompany,
  UniversalConnectedProfile,
  UniversalNetworkProfile,
  UniversalProfileResponse,
  UniversalSyncedProfiles,
} from './UniversalProfileProvider';
import { UniversalProfilePersonFieldsFragment } from './queries.generated';

export const isAddressBookCompany = (
  // ConnectedProfile is the old langugage for an Address Book Company
  input: UniversalProfileResponse,
): input is UniversalConnectedProfile => input.__typename === 'ConnectedProfile';

export const isE1NetworkCompany = (
  input: UniversalProfileResponse,
): input is UniversalNetworkProfile => input.__typename === 'ProfileResponse';

export const isE1NetworkCompanyNetworkCompany = (
  input: NetworkCompanyResponse,
): input is E1NetworkCompany => input.__typename === 'E1NetworkCompany';

export const isShadowNetworkCompany = (
  input: NetworkCompanyResponse,
): input is ShadowNetworkCompany => input.__typename === 'ShadowNetworkCompany';

export const isConnectedCompany = (
  // SyncedProfiles is the new language for a Connected Company
  input: UniversalProfileResponse,
): input is UniversalSyncedProfiles => input.__typename === 'SyncedProfiles';

export const isShadowProfile = (
  input: UniversalProfileResponse,
): input is UniversalNetworkProfile =>
  input.__typename === 'ProfileResponse' && input.profile.account === null;

const mapContactFromUnifiedProfilePerson = (person: UniversalProfilePersonFieldsFragment) => ({
  id: person.id,
  firstName: person.firstName,
  lastName: person.lastName,
  fullName: person.fullName,
  isPreferred: person.isPreferred,
  positionTitle: person.positionTitle ?? null,
  phone: person.contactable?.phone ?? null,
  email: person.contactable?.email ?? null,
  web: person.contactable?.email ?? null,
  hasEmailError: person.hasEmailError ?? null,
  types: person.types,
});

const sortPeopleByPreferredAndAlphanumeric = (
  {
    isPreferred: person1Preferred,
    fullName: person1FullName,
  }: { isPreferred: boolean; fullName: string },
  {
    isPreferred: person2Preferred,
    fullName: person2FullName,
  }: { isPreferred: boolean; fullName: string },
) => {
  if (person1Preferred && !person2Preferred) {
    return -1;
  }
  if (!person1Preferred && person2Preferred) {
    return 1;
  }
  return person1FullName.localeCompare(person2FullName);
};

export const getMappedContactsFromProfilePeople = (
  people: UniversalProfilePersonFieldsFragment[],
) => {
  if (people.length === 0) {
    return [];
  }

  return people.map(mapContactFromUnifiedProfilePerson).sort(sortPeopleByPreferredAndAlphanumeric);
};

export const mapContactFromProfilePerson = (person: UniversalProfilePersonFieldsFragment) => ({
  id: person.id,
  firstName: person.firstName,
  lastName: person.lastName,
  fullName: person.fullName,
  positionTitle: person.positionTitle ?? null,
  phone: person.contactable?.phone ?? null,
  email: person.contactable?.email ?? null,
  web: person.contactable?.web ?? null,
  isDefaultContact: person.isDefaultContact,
  types: [],
});

export const getProfilePrimaryContact = (people: UniversalProfilePersonFieldsFragment[]) => {
  if (people.length === 0) {
    return null;
  }

  const defaultPerson = people.find(({ isDefaultContact }) => isDefaultContact);

  return mapContactFromUnifiedProfilePerson(defaultPerson ?? people[0]);
};
