import { CONTACT_US_OPEN_EVENT, ContactUsOpenEventPayload } from '@ascension/js/app/help';
import { useUserPhone } from '@subbie/context/AccountProvider/hooks';
import { UPGRADE_TYPE } from './types';
import { EnquiryNature } from '@ascension/_gqltypes/subbie.generated';

const CONTACT_US_TEXT = {
  [UPGRADE_TYPE.Default]: 'I am interested in learning more about the upgraded license options',
  [UPGRADE_TYPE.NationalOrMultiState]:
    'I’m interested in purchasing a national or multi state license.',
  [UPGRADE_TYPE.Professional]: 'I’m interested in upgrading to Professional.',
  [UPGRADE_TYPE.ProfessionalPlus]: 'I’m interested in upgrading to Professional Plus.',
  [UPGRADE_TYPE.ProfessionalPlusMoreCredits]:
    'I’m interested in acquiring more credits for my account.',
  [UPGRADE_TYPE.Standard]: 'I’m interested in customising my Standard plan.',
  [UPGRADE_TYPE.EssentialNational]: 'I’m interested in upgrading to Essential National.',
  [UPGRADE_TYPE.Essential]: 'I’m interested in upgrading to Essential.',
  [UPGRADE_TYPE.Lite]: 'I’m interested in customising my Lite plan.',
  [UPGRADE_TYPE.TrialSSC]:
    'I’m interested in accessing more credits to unlock subcontractors on my account.',
};

export const useEnquiryDetails = (upgradeType: UPGRADE_TYPE = UPGRADE_TYPE.Default): string => {
  const userPhone = useUserPhone();
  const phoneText = userPhone ? `\n\nMy contact number is ${userPhone}.` : '';
  return `${CONTACT_US_TEXT[upgradeType]}${phoneText}`;
};

export const useContactUsAboutUpgrade = (
  upgradeType: UPGRADE_TYPE = UPGRADE_TYPE.Default,
): (() => boolean) => {
  const enquiry = useEnquiryDetails(upgradeType);
  return () =>
    document.dispatchEvent(
      new CustomEvent<ContactUsOpenEventPayload>(CONTACT_US_OPEN_EVENT, {
        detail: {
          enquiry,
          enquiryNature: EnquiryNature.UPGRADE,
        },
      }),
    );
};
