import { useQuery } from '@apollo/client';
import { GET_PROFILE_INSIGHTS_DATA } from './queries';
import { GetProfileInsightsDataQuery } from './queries.generated';
import { ProfileViewLogSource } from '@ascension/_gqltypes/subbie.generated';

type InsightsStats = {
  totalViews: number;
  totalDirectoryViews: number;
  totalOtherViews: number;
  totalThisMonthViews: number;
};

type MonthlyProfileView = GetProfileInsightsDataQuery['monthlyProfileViews'][0];

const getPastYearViewTotals = (monthlyProfileViews: MonthlyProfileView[]): InsightsStats => {
  const thisMonth = new Date().getMonth() + 1;

  return monthlyProfileViews.reduce(
    (acc, { source, month, count }) => {
      const isDirectoryView = source === ProfileViewLogSource.DIRECTORY;
      const isCurrentMonthView = month === thisMonth;

      const addCountWhen = (pred: boolean) => (curr: number) => (pred ? curr + count : curr);

      return {
        totalViews: acc.totalViews + count,
        totalDirectoryViews: addCountWhen(isDirectoryView)(acc.totalDirectoryViews),
        totalOtherViews: addCountWhen(!isDirectoryView)(acc.totalOtherViews),
        totalThisMonthViews: addCountWhen(isCurrentMonthView)(acc.totalThisMonthViews),
      };
    },
    {
      totalViews: 0,
      totalDirectoryViews: 0,
      totalOtherViews: 0,
      totalThisMonthViews: 0,
    },
  );
};

export const useProfileInsights = () => {
  const { data, loading, error } = useQuery<GetProfileInsightsDataQuery>(GET_PROFILE_INSIGHTS_DATA);

  if (!data) {
    return {
      loading: false,
      error: undefined,
      rawData: null,
      totalViews: null,
      totalDirectoryViews: null,
      totalOtherViews: null,
      totalThisMonthViews: null,
    };
  }

  const { totalViews, totalDirectoryViews, totalOtherViews, totalThisMonthViews } =
    getPastYearViewTotals(data.monthlyProfileViews);

  return {
    loading,
    error,
    rawData: data,
    totalViews,
    totalDirectoryViews,
    totalOtherViews,
    totalThisMonthViews,
  };
};

export { GetProfileInsightsDataQuery };
