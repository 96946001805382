import { ReactNode } from 'react';
import { Col, Row, E1Link } from '@estimateone/frontend-components';
import styles from './styles.scss';

type SummaryRowProps = {
  title: string;
};

type SimpleSummaryRowProps = SummaryRowProps & {
  value?: string | null;
};

type LinkSummaryRowProps = SimpleSummaryRowProps & {
  link?: string | null;
};

const SummaryRow = ({ children, title }: { children: ReactNode } & SummaryRowProps) => (
  <Row>
    <Col span={3}>
      <div className={styles.summaryRowTitle}>{title}</div>
    </Col>
    <Col span={9}>
      <div>{children}</div>
    </Col>
  </Row>
);

const SimpleSummaryRow = ({ title, value = null }: SimpleSummaryRowProps) => {
  if (value === null) {
    return null;
  }

  return <SummaryRow title={title}>{value}</SummaryRow>;
};

const LinkSummaryRow = ({ title, value = null, link = null }: LinkSummaryRowProps) => (
  <SummaryRow title={title}>
    {link !== null && value !== null && (
      <E1Link target="_blank" rel="noopener noreferrer" link={link}>
        {value}
      </E1Link>
    )}
  </SummaryRow>
);

export { LinkSummaryRow, SummaryRow, SimpleSummaryRow };
