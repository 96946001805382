import { CellProps } from '@estimateone/frontend-components';
import { PrioritySelect } from '../PrioritySelect';
import { EntityId } from '@ascension/types';
import { Package } from '@builder/features/ProcurementLettingSchedule/types';
import styles from '../styles.scss';

export const PriorityCell =
  (openNotesSlider: (pkgId: EntityId, pkgName: string, isCritical: boolean) => void) =>
  ({
    row: {
      original: { id: packageId, title, lettingScheduleDetails },
    },
  }: CellProps<Package>) => {
    const openNotesSliderHandler = (isCritical: boolean) =>
      openNotesSlider(packageId, title, isCritical);
    return lettingScheduleDetails ? (
      <div className={styles.cellAlign}>
        <PrioritySelect
          lettingScheduleId={lettingScheduleDetails.id}
          priority={lettingScheduleDetails.priority}
          openNotesSliderHandler={openNotesSliderHandler}
        />
      </div>
    ) : null;
  };
