import useOnclickOutside from 'react-cool-onclickoutside';
import { Icon, IconName } from '@estimateone/frontend-components';
import {
  Button,
  ButtonSize,
  ButtonVariant,
} from '@estimateone/frontend-components/src/components/Button';
import CreditBalance from '@subbie/common/CreditBalance';
import { SubbieConnectionCreditBalanceDetails } from '@subbie/common/CreditBalance/hooks';
import { useContactUsAboutUpgrade } from '@subbie/features/AccountUpgradeFeature/pages/NewUpgradeSelection/hooks';
import { UPGRADE_TYPE } from '@subbie/features/AccountUpgradeFeature/pages/NewUpgradeSelection/hooks/types';
import styles from './styles.scss';

type SubbieListSliderRoadblockModalProps = {
  readonly unlockInProgress: boolean;
  readonly onUnlock: () => void;
  readonly onCancel: () => void;
  readonly creditBalanceSummary: SubbieConnectionCreditBalanceDetails;
};

const creditBalanceMessages = {
  'no-credits': {
    title: 'You have 0 credits remaining',
    content: null,
    buttonTitle: 'Get more credits',
  },
  'has-credits': {
    title: 'Use 1 credit to unlock subcontractors for this project',
    content: (
      <p className={styles.roadblockModalDescription}>
        Use a credit to gain access to the tendering subcontractors on this project that match your
        current trades.
      </p>
    ),
    buttonTitle: 'Unlock Subcontractors',
  },
};

export const SubbieListSliderRoadblockModal = ({
  unlockInProgress,
  onUnlock: handleUnlockSubbies,
  onCancel: handleCancelUnlock,
  creditBalanceSummary,
}: SubbieListSliderRoadblockModalProps) => {
  const openContactUsForm = useContactUsAboutUpgrade(UPGRADE_TYPE.ProfessionalPlus);
  const { subbieConnectionCreditBalance } = creditBalanceSummary;
  const ref = useOnclickOutside(handleCancelUnlock);

  const handleGetMoreCreditsButtonClick = () => {
    openContactUsForm();
  };

  if (!subbieConnectionCreditBalance) {
    return null;
  }

  const hasNoCredits =
    subbieConnectionCreditBalance.allowed - subbieConnectionCreditBalance.used === 0;

  const creditBalanceMessage = hasNoCredits ? 'no-credits' : 'has-credits';

  const { title, content, buttonTitle } = creditBalanceMessages[creditBalanceMessage];

  const handleButtonClick = hasNoCredits ? handleGetMoreCreditsButtonClick : handleUnlockSubbies;

  return (
    <>
      <div className={styles.roadblockModalUnderlay} />
      <div className={styles.roadblockModalContainer}>
        <div ref={ref} className={styles.roadblockModal}>
          <Button
            onClick={handleCancelUnlock}
            variant={ButtonVariant.Transparent}
            className={styles.roadblockCloseButton}
          >
            Close
            {/* TODO: Use <CloseIcon /> but it doesn't work the same */}
            <Icon name={IconName.Close} marginLeft="small" size=".85em" />
          </Button>
          <div className={styles.roadblockModalTitle}>
            <h3>{title}</h3>
          </div>
          {content}
          <div className={styles.unlockButtonContainer}>
            <Button
              variant={ButtonVariant.Primary}
              size={ButtonSize.Medium}
              fullWidth
              disabled={unlockInProgress}
              onClick={handleButtonClick}
            >
              {buttonTitle}
            </Button>
            <CreditBalance creditBalanceSummary={creditBalanceSummary} />
          </div>
        </div>
      </div>
    </>
  );
};
