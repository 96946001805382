/* eslint-disable class-methods-use-this */
/* eslint-disable fp/no-mutation */
/* eslint-disable fp/no-let */
import $ from 'jquery';
import Routing from 'routing';
import moment from 'moment-timezone';
import E1Request, { E1Response } from '../classes/E1Request';
import { getUserTimezone } from '@ascension/js/app/timezone';
import AjaxList from '@ascension/js/classes/AjaxList';
import SuperSlider from '@ascension/js/classes/SuperSlider';
// TODO: move to common place if we decide to run with this experiment
import addUserLinks from '@subbie/modal/ProjectSlider/ProjectSliderBody/ProjectNotes/addUserLinks';
import {
  renderRfqNoteWithMentionsComponent,
  unmountRfqNoteWithMentionsComponent,
} from '../../../entrypoint/builder/rfq-notes';
import Form from '../classes/Form';
import { ResponseList, viewHistory } from './stage_responses_list';
import { escapeRegExp } from './string_util';
import {
  showNotesHistory,
  showRfqCorrespondenceComposeModal,
  showRfqEmailErrorModal,
} from './utils/response_list.utils';
import { ListenerEvent } from '@ascension/enums';
import { AddNoteResponse, ListNote, RowInvite } from './types/stage_responses_list.types';
import { EntityId } from '@ascension/types';

export class StageResponses {
  contactDataTable: ResponseList | null;
  $body: JQuery<HTMLElement>;
  $inviteTable: JQuery<HTMLElement>;

  constructor() {
    this.contactDataTable = null;
    this.$body = $('body');
    this.$inviteTable = $('.stage-invite');

    // Init data table
    if (this.$inviteTable.length > 0) {
      this.$inviteTable.each((index: number, element: HTMLElement) => {
        const $container = $(element);
        this.contactDataTable = new ResponseList($container);

        this.contactDataTable.listsTable?.$target.on('data-selected-updated', () => {
          const visibleRowData =
            this.contactDataTable?.table?.rows({ search: 'applied' }).data() ?? [];

          const selected = visibleRowData.filter(
            // eslint-disable-next-line eqeqeq
            (response: RowInvite) => response.selected == true,
          );
          const disabled = visibleRowData.filter(
            (response: RowInvite) => response.unsubscribedAt !== null,
          );

          const selectedCount = selected.length;
          const disabledCount = disabled.length;
          const totalCount = visibleRowData.length;

          this.contactDataTable?.$selectAllCheckbox.prop(
            'checked',
            selectedCount + disabledCount >= totalCount,
          );
        });
      });

      // Setup events
      $(document).on('update-rfq-table', () => {
        this.contactDataTable?.listsTable?.updateTable(true);
      });

      $(document).on(ListenerEvent.InviteToQuoteSuccess, () => {
        this.contactDataTable?.listsTable?.updateTable(true);
      });

      this.$body
        .on('click', '.export-to-excel', async () => {
          const id = Number($('.stage-invite').attr('data-stage-id'));
          await this.addExportResponsesEvent('Excel', id);
          window.location.assign(Routing.generate('app_stagerfq_exporttoexcel', { id }));
        })
        .on('click', '.export-to-pdf', async () => {
          const id = Number($('.stage-invite').attr('data-stage-id'));
          const route = Routing.generate('app_stagerfq_exporttopdf', { id });
          window.open(route, '_blank');
          await this.addExportResponsesEvent('PDF', id);
        });

      this.$body.on('click', '.correspondence-trigger-broadcast', async (clickEvent) => {
        const stageId = Number($(clickEvent.currentTarget).attr('data-stage'));
        const dt = $('.stage-monitor-dataTable');

        let selected: EntityId[] = [];
        if (dt != null) {
          selected = (dt.DataTable().rows().data() as unknown as RowInvite[]).reduce(
            (result: EntityId[], row: RowInvite) => {
              if (row.selected) {
                result.push(row.id);
              }
              return result;
            },
            [],
          );
        }

        await showRfqCorrespondenceComposeModal(stageId, selected);
      });

      const subjectMap: Record<string, string> = {
        rfq_addendum: 'Notification of Addendum: [insert addendum name]',
        rfq_revision: 'Notification of Documentation Revisions',
        rfq_site_inspection: 'Site Inspection Details',
        rfq_time_extension: 'Extension of Time',
        rfq_general: '',
      };

      this.$body.on(
        'change',
        '#rfq_correspondence_type',
        ({ currentTarget }: JQuery.ChangeEvent<HTMLElement, unknown, HTMLElement, HTMLElement>) => {
          const type = $(currentTarget).val()?.toString() ?? '';
          $('#rfq_correspondence_title').val(subjectMap[type] || '');
        },
      );

      this.$body.on('click', '.rel-status.obsolete:not(.no-modal)', async (clickEvent) => {
        const $ctn = $(clickEvent.currentTarget).closest('.icons');
        const stageId = $('.stage-invite').attr('data-stage-id');
        const requestId = $ctn.attr('data-request-id');
        const route = Routing.generate('app_stagerfq_diff', {
          id: stageId,
          rfqId: requestId,
        });
        const request = new E1Request(route);
        request.show_loading_modal = true;
        await request.submit();
      });

      this.$body.on('click', '.rel-status.email_error:not(.no-modal)', async (clickEvent) => {
        const $ctn = $(clickEvent.currentTarget).closest('.icons');
        const stageId = $('.stage-invite').attr('data-stage-id') ?? '';
        const requestId = $ctn.attr('data-request-id') ?? '';
        await showRfqEmailErrorModal(stageId, requestId);
      });

      this.$body.on('click', '.add-tender-specific-note', async (clickEvent) => {
        const stageId = Number($('.stage-invite').attr('data-stage-id'));
        const rfqId = Number($(clickEvent.currentTarget).attr('data-rfq'));

        const slider = new SuperSlider();
        slider.close();
        await viewHistory(stageId, rfqId, 'notes', {
          onClose: () => {
            unmountRfqNoteWithMentionsComponent();
            return undefined;
          },
        });
      });
    }

    this.$body.on('submit', 'form.revoke-unrevoke-rfq-form', async (e) => {
      e.preventDefault();
      const $form = $(e.currentTarget);
      const magicForm = new Form($form);

      const response = await magicForm.submit();
      if (response.success) {
        $(document).trigger('update-rfq-table');
      }
      return false;
    });

    this.$body.on('submit', 'form.email-error-form', async (e) => {
      e.preventDefault();
      const $form = $(e.currentTarget);
      const magicForm = new Form<E1Response & { rfq: { id: EntityId } }>($form);

      const response = await magicForm.submit();
      if (response.success && response.rfq) {
        if (this.contactDataTable !== null) {
          const dataTable = this.contactDataTable.listsTable;
          if (dataTable !== null) {
            dataTable.updateTable(true);
          }
        }
      }
      return false;
    });

    this.$body.on('click', '.unrevoke-trigger', async (clickEvent) => {
      const stage = $(clickEvent.currentTarget).attr('data-stage');
      const route = Routing.generate('app_stageinvitation_unrevokemodal', { id: stage });

      const request = new E1Request(route);
      request.show_loading_modal = true;
      await request.submit();
    });

    this.$body.on('click', '.stage-note-toggle', () => {
      const dataTable = this.contactDataTable?.listsTable;
      dataTable?.$target.toggleClass('notes-visible');
      dataTable?.drawTable();
    });

    this.$body.on('click', '.stage-only-awarded-toggle', () => {
      const dataTable = this.contactDataTable?.listsTable;
      const status = this.contactDataTable?.awardedStatus ?? '';
      const colNumber = this.contactDataTable?.filterAwardedColumn;

      const filterCol = dataTable?.table.column(colNumber);
      dataTable?.$target.toggleClass('show-only-awarded');

      if (dataTable?.$target.hasClass('show-only-awarded')) {
        filterCol?.search(`^${escapeRegExp(status)}$`, true, false, true);
      } else {
        filterCol?.search('');
      }
      dataTable?.table.draw();
    });

    $(document).on('super-slider-shown', () => {
      const $sliderBody = $('.super-slider-body');
      const $rfqSlider = $('.rfq-slider');
      const $notesCtn = $rfqSlider.find('#rfq-notes');
      const $noteContainer = $notesCtn.find('.note-list-container');
      let noteList: AjaxList<ListNote> | null = null;
      if ($noteContainer.length) {
        const stageId = $noteContainer.attr('data-stage-id');
        const rfqId = $noteContainer.attr('data-rfq-id');
        const $listContainer = $noteContainer.find('.note-list');
        const $pageContainer = $noteContainer.find('.list-pagination');

        noteList = new AjaxList(
          $listContainer,
          $pageContainer,
          Routing.generate('app_stagerfq_fetchnotes', {
            id: stageId,
            rfqId,
          }),
          (noteItem, self) => {
            const $note = $('<div />').addClass('note green');
            const $noteText = $('<div class="d-flex justify-content-between align-items-start" />');
            $note.append($noteText);
            const $placeholder = $(self.$placeholder).clone();

            const $authorText = $('<h6 />').text(noteItem.author_name);
            const createdAt = moment.tz(noteItem.created_at, getUserTimezone());

            const $timestamp = $('<span />')
              .addClass('timestamp ml-2')
              .text(createdAt.format('MMM Do YYYY, H:mm a'));

            const noteHtml = addUserLinks(noteItem.text);

            $noteText.append($placeholder.find('.note-text')).find('.note-text').html(noteHtml);

            const { stage } = noteItem.subject;
            const canDeleteNote = stage && stage.hasAccess;
            if (canDeleteNote) {
              const deleteNoteConfirmationUrl = Routing.generate('app_stagerfq_deletenotemodal', {
                id: stage.id,
                noteId: noteItem.id,
              });

              const deleteNoteActionUrl = Routing.generate('app_stagerfq_deletenote', {
                id: stage.id,
                noteId: noteItem.id,
              });

              const noteOptionsMenu = `
                <div class="d-flex justify-content-end dropdown">
                  <button
                    aria-label="Note Options"
                    class="noteMenu btn btn-xs btn-icon btn-transparent dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    <i class="icon icon-more"></i>
                  </button>
                  <ul role="menu" class="dropdown-menu dropdown-menu-right">
                    <li class="dropdown-menu-item" role="menuitem">
                      <button
                        class="deleteNote dropdown-danger"
                        data-delete-confirmation-url="${deleteNoteConfirmationUrl}"
                        data-delete-action-url="${deleteNoteActionUrl}"
                      >
                        Delete note
                      </button>
                    </li>
                  </ul>
                </div>
              `;

              $noteText.append(noteOptionsMenu);
            }

            $note
              .append($placeholder.find('.note-author'))
              .find('.note-author')
              .append($authorText)
              .append($timestamp);

            return $note.prop('outerHTML');
          },
        );

        $sliderBody
          .find('form.add-rfq-note-form')
          .on('form-submitted-success', async (event, response) => {
            if (response.note !== null) {
              $rfqSlider.find('#note_text').val('');

              noteList?.toggleLoading(true);
              await noteList?.fetch();
              noteList?.toggleLoading(false);
            }

            return this.updateTableAfterNoteAdd(response);
          });
      }

      renderRfqNoteWithMentionsComponent(async (response: AddNoteResponse) => {
        noteList?.toggleLoading(true);
        await noteList?.fetch();
        noteList?.toggleLoading(false);
        this.updateTableAfterNoteAdd(response);
      });
    });
  }

  async addExportResponsesEvent(exportDestination: string, stageId: EntityId) {
    await window.analyticsService?.addInteractEvent({
      action: `ExportResponsesTo${exportDestination}`,
      stageId,
    });
  }

  updateTableAfterNoteAdd(response: AddNoteResponse) {
    if (response.rfq != null && this.contactDataTable != null) {
      const dataTable = this.contactDataTable.table;
      response.rfq.forEach((rfq) => {
        const rfqIdForRowId = `#${rfq.id}`;
        const row = dataTable?.row(rfqIdForRowId);
        if (row) {
          const data = row.data() as RowInvite;
          data.noteCount += 1;
          data.lastNote = response.note;
          row.data(data).draw();
        }
      });
    }
  }
}

$(() => {
  new StageResponses();
});

$(document).ready(async () => {
  const $alcTrigger = $('.autolaunch-correspondence-instruct');

  if ($alcTrigger.length > 0) {
    const stage = $alcTrigger.data('stage');
    const request = Routing.generate('app_stagecorrespondence_intromodal', { id: stage });
    const req = new E1Request(request, 'GET');
    req.show_loading_modal = true;
    await req.submit();
  }

  await showNotesHistory();

  const quotesChannel = new BroadcastChannel('quotes');

  quotesChannel.onmessage = (
    event: MessageEvent<{ eventName: 'CREATED_FROM_RFQ'; rfqId: number }>,
  ) => {
    if (event.data.eventName === 'CREATED_FROM_RFQ') {
      // Tell the quote stats and coverage panels
      document.dispatchEvent(new CustomEvent(ListenerEvent.QuoteStatusUpdated));
      const { rfqId } = event.data;
      // We could be clever and fetch the individual row here, but this works for now
      $(document).trigger('update-rfq-table', { rfqId });
    }
  };
});
