import { useCallback } from 'react';
import { useSSCWatchlistModal } from './useSSCWatchlistModal';
import { useConnectionPreferenceLazy } from '@subbie/modal/ProjectSlider/ProjectSliderBody/ProjectSubbies/hooks';

export const useSSCHandleWatchlistStatusUpdate = () => {
  const { getConnectionPreference } = useConnectionPreferenceLazy();
  const { open: showSSCModal } = useSSCWatchlistModal();

  const showSSCModalForWatchlistStatusUpdate = useCallback(
    async (projectId: number) => {
      if (getConnectionPreference) {
        const { data } = await getConnectionPreference({
          variables: {
            projectId,
          },
        });

        if (
          data?.connectionPreference.contactId === null &&
          data?.connectionPreference.contactName === null
        ) {
          showSSCModal({ selectedProjectId: projectId });
        }
      }
    },
    [getConnectionPreference, showSSCModal],
  );

  return {
    showSSCModalForWatchlistStatusUpdate,
  };
};
