import { MutableRefObject, useState } from 'react';
import {
  CellProps,
  DatePickerFormat,
  joinClassNames,
  TableInstance,
  Tooltip,
} from '@estimateone/frontend-components';
import { InlineEditableDate } from '@builder/common/InlineEditableDate';
import { Trigger } from '../TimingCalculator/autoCalculateTimings';
import { getMilestoneInfoForStartOnSiteDate } from '../TimingMilestoneState';
import {
  CalculateAndUpdateLettingTimingsInput,
  InputIdentifiers,
  TimingErrorMessages,
  getTimings,
  isDateSameOrLaterThanPastDates,
} from '../utils/lettingTimingHelper';
import { Package } from '@builder/features/ProcurementLettingSchedule/types';
import styles from '../styles.scss';

type CellComponentProps = {
  value: Date;
  original: Package;
  calculateAndUpdateLettingTimings: ({
    trigger,
    ...timings
  }: CalculateAndUpdateLettingTimingsInput) => void;
  tableRef: MutableRefObject<TableInstance<Package> | undefined>;
  enableDefaultTimeframes: boolean;
};

const CellComponent = ({
  value,
  original,
  calculateAndUpdateLettingTimings,
  tableRef,
  enableDefaultTimeframes,
}: CellComponentProps) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const startOnSiteDate = value ? new Date(value) : null;

  const { classNames, hasMilestoneText, milestoneText, extraMilestoneText } =
    getMilestoneInfoForStartOnSiteDate(startOnSiteDate, original.lettingScheduleDetails?.isAwarded);

  const handleOnDateChange = (newStartDate: Date | null): void => {
    const timings = getTimings(
      newStartDate ?? undefined,
      tableRef.current,
      original,
      Trigger.START_ON_SITE_DATE_CHANGE,
      'startOnSiteDate',
    );

    const isEarlierThanPastDate =
      !timings?.trigger || !isDateSameOrLaterThanPastDates(timings.trigger, timings);

    if (!timings || (!enableDefaultTimeframes && isEarlierThanPastDate)) {
      setErrorMessage(TimingErrorMessages.DATE_TOO_EARLY);
    } else {
      calculateAndUpdateLettingTimings(timings);
    }
  };

  return (
    <div className={joinClassNames(styles.cellAlign, ...classNames)}>
      <InlineEditableDate
        id={`${InputIdentifiers.START_ON_SITE_DATE}${original.id}`}
        className={errorMessage ? styles.dateError : undefined}
        label="Start on Site date"
        value={startOnSiteDate ?? undefined}
        onFocus={() => {
          setErrorMessage(null);
        }}
        onDateChange={handleOnDateChange}
        dateFormat={DatePickerFormat.DateOnlyShortMonth}
      />
      {errorMessage ? (
        <p className={styles.dateErrorMessage}>{errorMessage}</p>
      ) : (
        hasMilestoneText && (
          <Tooltip
            className={styles.tooltipSpacing}
            text={extraMilestoneText}
            tooltipId={`start-on-site-milestone-tooltip${original.id}`}
            place="bottom"
          >
            <span className={styles.timeRemaining}>{milestoneText}</span>
          </Tooltip>
        )
      )}
    </div>
  );
};

export const StartOnSiteCell =
  (
    calculateAndUpdateLettingTimings: ({
      trigger,
      ...timings
    }: CalculateAndUpdateLettingTimingsInput) => void,
    tableRef: MutableRefObject<TableInstance<Package> | undefined>,
    enableDefaultTimeframes: boolean,
  ) =>
  ({ value, row: { original } }: CellProps<Package, Date>) =>
    CellComponent({
      value,
      original,
      calculateAndUpdateLettingTimings,
      tableRef,
      enableDefaultTimeframes,
    });
