import { useEffect } from 'react';
import { captureException } from '@sentry/browser';
import {
  CompanyInSliderForAccountId,
  CompanyInSliderForProfileId,
} from '@profiles/apps/CompanyProfile/CompanyProfileSlider';
import { FlashMessageListener } from '@shared/FlashMessageListener';
import { Action, useInteractAnalytics } from '../../../hooks/Analytics';

type ProfilesProps = {
  accountId?: number;
  profileId?: string;
};

const SliderProfile = ({ accountId, profileId }: ProfilesProps) => {
  const { addEvent } = useInteractAnalytics();

  useEffect(() => {
    addEvent({ action: Action.SUBBIE_PROFILE_VIEWED, subbieAccountId: accountId });
  }, [addEvent, accountId]);

  if (profileId === undefined && accountId === undefined) {
    captureException('tried to load a profile without an accountId or profileId', {
      tags: {
        team: 'connect',
      },
    });

    throw Error('tried to load a profile without an accountId or profileId');
  }

  if (accountId) {
    return (
      <section>
        <CompanyInSliderForAccountId profileAccountId={accountId} />
        <FlashMessageListener />
      </section>
    );
  }

  if (profileId) {
    return (
      <section>
        <CompanyInSliderForProfileId profileId={profileId} />
        <FlashMessageListener />
      </section>
    );
  }

  return null;
};

export default SliderProfile;
