import { gql } from '@apollo/client';

export const VIEW_SUPPLIER_ON_PROJECT = gql`
  mutation ViewSupplierOnProject($input: LogSupplierContactViewInput!) {
    viewSupplierOnProject(input: $input) {
      ... on ProjectInterestedSupplier {
        id
        accountId
        name
        location
        stockTrades {
          id
          name
        }
        type
        viewed
        contactAndNote {
          id
          name
          email
          phoneNumber
          note
        }
      }
      ... on Errors {
        errors {
          message
        }
      }
    }
  }
`;
