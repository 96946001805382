import { gql } from '@apollo/client';
import Errors from '@api/fragments/Errors';
import { PendingAddendumDetails } from './fragments';

export const UPDATE_PENDING_ADDENDUM_DETAILS = gql`
  mutation UpdatePendingAddendumDetails($input: UpdatePendingAddendumDetailsInput!) {
    updatePendingAddendumDetails(input: $input) {
      ... on Addendum {
        ...PendingAddendumDetails
      }
      ... on Errors {
        errors {
          ...ApiError
        }
      }
    }
  }
  ${Errors.fragments.error}
  ${PendingAddendumDetails}
`;

export const FINALISE_MATRIX_CHANGE = gql`
  mutation FinaliseMatrixChange($input: FinaliseMatrixChangeInput!) {
    finaliseMatrixChange(input: $input) {
      ... on MatrixChange {
        id
      }
      ... on Errors {
        errors {
          message
        }
      }
    }
  }
`;
