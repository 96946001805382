import { useState } from 'react';

type ModalState = {
  editTrades: boolean;
  editCoreTrades: boolean;
};

const useModalState = () => {
  const [modalState, setModalState] = useState<ModalState>({
    editTrades: false,
    editCoreTrades: false,
  });

  const handleOpenEditTrades = () => {
    setModalState({ editTrades: true, editCoreTrades: false });
  };

  const handleCloseEditTrades = () => {
    setModalState({ editTrades: false, editCoreTrades: false });
  };

  const handleOpenCoreTradesModal = () => {
    setModalState({ editTrades: false, editCoreTrades: true });
  };

  const handleCloseCoreTradesModal = () => {
    setModalState({ editTrades: false, editCoreTrades: false });
  };

  const handleBackToEditTrades = () => {
    setModalState({ editTrades: true, editCoreTrades: false });
  };

  return {
    modalState,
    handleOpenEditTrades,
    handleCloseEditTrades,
    handleOpenCoreTradesModal,
    handleCloseCoreTradesModal,
    handleBackToEditTrades,
  };
};

export default useModalState;
