import { ComponentProps, useState } from 'react';
import Routing from 'routing';
import { ActionMenu, Button, Icon, IconName } from '@estimateone/frontend-components';
import { MultiInviteWarningModal } from '@builder/features/InviteToQuote/MultiInviteWarningModal';
import { InviteToQuoteSlideout } from '../InviteToQuoteSlideout';
import { EntityId } from '@ascension/types';

export type InviteToQuoteMenuButtonProps = {
  stageId: EntityId;
  preselectedPackageId?: EntityId;
  onAddedRfq: (rfqId: EntityId) => void;
  menuPlacement?: ComponentProps<typeof ActionMenu>['placement'];
};

export const InviteToQuoteMenuButton = ({
  stageId,
  preselectedPackageId,
  onAddedRfq,
  menuPlacement,
}: InviteToQuoteMenuButtonProps) => {
  const [isSlideoutOpen, setIsSlideoutOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isWarningModalOpen, setWarningModalOpen] = useState<boolean>(false);

  const triggerElement = (
    <Button onClick={() => setIsMenuOpen(!isMenuOpen)}>
      Invite to Quote
      <Icon name={IconName.ChevronDown} marginLeft="small" size="0.6em" />
    </Button>
  );

  const redirectToMultipleInvitePage = () => {
    const pathParams = {
      id: stageId,
      stageType: 'procurement',
    };
    const queryParams = preselectedPackageId ? { packageId: preselectedPackageId } : {};

    const target = Routing.generate('app_stage_invitations', {
      ...pathParams,
      ...queryParams,
    });
    window.location.replace(target);
  };

  return (
    <>
      <ActionMenu
        triggerElement={triggerElement}
        onClickOutside={() => setIsMenuOpen(false)}
        visible={isMenuOpen}
        placement={menuPlacement}
      >
        <>
          <ActionMenu.Item
            onClick={() => {
              setIsSlideoutOpen(true);
              setIsMenuOpen(false);
            }}
          >
            Invite one subcontractor
          </ActionMenu.Item>
          <ActionMenu.Item
            onClick={() => {
              setIsMenuOpen(false);
              setWarningModalOpen(true);
            }}
          >
            Invite multiple subcontractors
          </ActionMenu.Item>
        </>
      </ActionMenu>
      <InviteToQuoteSlideout
        stageId={stageId}
        isOpen={isSlideoutOpen}
        onRequestClose={(addedRfqId) => {
          setIsSlideoutOpen(false);
          if (addedRfqId) onAddedRfq(addedRfqId);
        }}
        preselectedPackageId={preselectedPackageId}
        preselectedCompany={undefined}
      />

      <MultiInviteWarningModal
        isOpen={isWarningModalOpen}
        onClose={() => setWarningModalOpen(false)}
        onSingleInviteClick={() => {
          setWarningModalOpen(false);
          setIsSlideoutOpen(true);
        }}
        onContinueClick={redirectToMultipleInvitePage}
      />
    </>
  );
};
