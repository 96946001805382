import { useEffect, useState } from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import { Button } from '@estimateone/frontend-components';
import LoadingSpinnerFloating from '../../../../../shared/LoadingSpinner/LoadingSpinnerFloating';
import { useAccountUserList } from '@subbie/context/AccountProvider/hooks';
import { useValidatedMutation } from '@api/e1-hooks';
import { ADD_NOTE } from './mutations';
import { EntityId } from '../../types';
import {
  AddProjectNote,
  AddProjectNote_addNoteToProject as MutatedNote,
  AddProjectNoteVariables,
} from './types/AddProjectNote';
import styles from './styles.scss';

type NotesFormSectionProps = {
  loading: boolean;
  noteText: string;
  formError: string | null;
  setNoteText: (t: string) => void;
  persistNote: () => void;
};

const NotesFormSectionWithMentions = ({
  loading,
  noteText,
  formError,
  persistNote,
  setNoteText,
}: NotesFormSectionProps) => {
  const accountUsers = useAccountUserList();

  const usersToSuggest = accountUsers.map(({ id, fullName }) => ({
    id,
    display: fullName ?? undefined,
  }));

  if (!usersToSuggest.length) return null;

  const hasErrors = formError !== null;

  return (
    <>
      <div className="d-flex mb-2 justify-content-between align-items-center">
        <h3>Notes</h3>
        <div className={`${styles.yourTeamOnly} pull-right`}>
          <strong>Tip:</strong> You can mention your teammates by typing <strong>@</strong>
        </div>
      </div>
      {loading && <LoadingSpinnerFloating />}
      <div className={`${styles.notesForm} ${hasErrors && 'has-error'}`}>
        <MentionsInput
          className="project-note-mentions-input"
          onChange={(event) => setNoteText(event.target.value)}
          value={noteText}
          style={{ height: 100, marginBottom: 20 }}
        >
          <Mention
            data={usersToSuggest}
            trigger="@"
            displayTransform={(id, display) => `@${display}`}
          />
        </MentionsInput>
        {hasErrors && (
          <span id="helpBlock2" className="help-block">
            {formError}
          </span>
        )}
        <p className="small" style={{ marginTop: -20 }}>
          Project notes are only visible to you and your teammates
        </p>
        <Button disabled={!noteText.length} onClick={persistNote}>
          Save Note
        </Button>
      </div>
    </>
  );
};

const ProjectNotesForm = ({
  projectId,
  addProjectNote,
}: {
  projectId: EntityId;
  addProjectNote?: (response: MutatedNote) => void;
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [noteText, setNoteText] = useState('');

  const [persistNote, { loading, responseData, validationErrors }] = useValidatedMutation<
    AddProjectNote,
    AddProjectNoteVariables,
    MutatedNote
  >(ADD_NOTE, {
    variables: { input: { projectId, text: noteText } },
  });

  useEffect(() => {
    if (responseData) setSubmitting(true);
  }, [responseData]);

  useEffect(() => {
    if (submitting) {
      if (addProjectNote && responseData) {
        addProjectNote(responseData);
      }
      setNoteText('');
      setSubmitting(false);
    }
  }, [submitting, addProjectNote, responseData]);

  const formError = validationErrors?.map(({ message }) => message).join(', ') || null;
  const props: NotesFormSectionProps = {
    loading,
    noteText,
    formError,
    setNoteText,
    persistNote,
  };

  return NotesFormSectionWithMentions(props);
};

export default ProjectNotesForm;
