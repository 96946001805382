import { MultiSelectDropdownField } from '../../../../../modules/FinalFormFields/MultiSelectDropdownField/MultiSelectDropdownField';
import { getCategoryLabel } from '../../../utils';
import { EditCategoriesFieldName } from './types';
import { StageCategory } from '@ascension/_gqltypes/profiles.generated';

export type CategoryOption = {
  value: StageCategory;
  label: string;
};

export const categoriesOptions = Object.keys(StageCategory)
  .map((value: unknown) => value as keyof typeof StageCategory)
  .map((value: keyof typeof StageCategory) => StageCategory[value])
  .map((value: StageCategory) => ({
    value,
    label: getCategoryLabel(value),
  }));

export const CategoriesMultiSelectField = () => (
  <MultiSelectDropdownField
    id="categories"
    label="Categories"
    fieldName={EditCategoriesFieldName.Categories}
    options={categoriesOptions}
  />
);
