import { useCallback, useEffect, useState } from 'react';
import { Drawer, DrawerContent, DrawerOverlay, useDrawer } from '@estimateone/frontend-components';
import { DefaultOperatingCountryProvider } from '@ascension/components/localisation/DefaultOperatingCountryProvider';
import { RemoveNetworkConnectionModal } from '@builder/features/RemoveNetworkConnection';
import { SyncCompanyModal } from '@builder/features/SyncCompanyProfile/SyncCompanyModal';
import { DrawerLoadingSpinner } from '@builder/pages/Profiles/UniversalProfileDrawer/components/DrawerLoadingSpinner';
import {
  UNIVERSAL_PROFILE_DRAWER_OPEN_EVENT,
  UniversalProfileDrawerIds,
} from '@builder/pages/Profiles/UniversalProfileDrawer/events';
import { shouldLogBuilderView, createBuilderViewLog } from '@subbie/pages/Profiles/builderViewLog';
import { UniversalProfileDrawerSubpage } from '@subbie/pages/Profiles/universalProfileDrawerSubpage';
import { AddressBookCompanyDrawerContent } from './AddressBookCompanyDrawerContent';
import { ConnectedCompanyDrawerContent } from './ConnectedCompanyDrawerContent';
import { E1NetworkCompanyDrawerContent } from './E1NetworkCompanyDrawerContent';
import { ShadowProfileDrawerContent } from './ShadowProfileDrawerContent';
import { DrawerError } from './components/DrawerErrror';
import {
  UniversalProfileContext,
  UniversalProfileProvider,
} from './providers/UniversalProfileProvider';
import {
  isConnectedCompany,
  isE1NetworkCompany,
  isShadowProfile,
} from './providers/UniversalProfileProvider/utils';
import { fireAndForget } from '@shared/Util/async';
import { Action, useInteractAnalytics } from '@ascension/components/hooks/Analytics';
import { EntityId } from '@ascension/types';

export const UNIVERSAL_PROFILE_DRAWER_TAB_CONTAINER_ID = 'network-profile-twig-container';

export type DrawerEvent = {
  detail: UniversalProfileDrawerIds;
};
export type DrawerDomEvent = DrawerEvent & Event;

type UniversalProfileDrawerProps = {
  readonly hasProcurementPhaseFeature: boolean;
  readonly canViewAddressBook: boolean;
  readonly activeSubpage: UniversalProfileDrawerSubpage;
};

const getProfileConnectionType = ({ companyId, profileId, accountId }: DrawerEvent['detail']) =>
  !companyId ? 'not-connected' : profileId || accountId ? 'synced' : 'connected';

export const UniversalProfileDrawer = ({
  canViewAddressBook,
  hasProcurementPhaseFeature,
  activeSubpage,
}: UniversalProfileDrawerProps) => {
  const [companyId, setCompanyId] = useState<EntityId | undefined>(undefined);
  const [accountId, setAccountId] = useState<EntityId | undefined>(undefined);
  const [profileId, setProfileId] = useState<string | undefined>(undefined);

  const { isOpen, onOpen, onClose } = useDrawer(false);
  const { addEvent } = useInteractAnalytics();

  const drawerOpened = useCallback(
    ({ detail }: DrawerDomEvent) => {
      setCompanyId(detail.companyId);
      setAccountId(detail.accountId);
      setProfileId(detail.profileId);

      const hasAddressBookCompanyId = detail.companyId !== undefined;
      if (
        detail.accountId !== undefined &&
        shouldLogBuilderView(activeSubpage, hasAddressBookCompanyId)
      ) {
        fireAndForget(() => createBuilderViewLog(detail.accountId ?? 0, activeSubpage));
      }

      addEvent({
        action: Action.SUBBIE_PROFILE_VIEWED,
        activeSubpage,
        connectionType: getProfileConnectionType(detail),
        addressBookCompanyId: detail.companyId,
        subbieAccountId: detail.accountId,
        subbieProfileId: detail.profileId,
      });
      onOpen();
    },
    [activeSubpage, addEvent, onOpen],
  );

  const onSuccess = ({
    connectedCompanyId,
    connectedProfileId,
    connectedAccountId,
  }: {
    connectedCompanyId?: EntityId;
    connectedProfileId?: string;
    connectedAccountId?: EntityId;
  }) => {
    setCompanyId(connectedCompanyId);
    setProfileId(connectedProfileId);
    setAccountId(connectedAccountId);
  };

  useEffect(() => {
    document.addEventListener(UNIVERSAL_PROFILE_DRAWER_OPEN_EVENT, drawerOpened);

    return () => {
      document.removeEventListener(UNIVERSAL_PROFILE_DRAWER_OPEN_EVENT, drawerOpened);
    };
  }, [drawerOpened]);

  const handleConnectSuccess = (id: number) => setCompanyId(id);

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <UniversalProfileProvider
          accountId={accountId}
          addressBookCompanyId={companyId}
          companyProfileId={profileId}
        >
          <UniversalProfileContext.Consumer>
            {({ loading, error, universalProfile, networkCompany }) => {
              if (loading && !universalProfile) {
                return <DrawerLoadingSpinner />;
              }

              if (error || !universalProfile || !networkCompany) {
                return <DrawerError />;
              }

              if (
                isConnectedCompany(universalProfile) &&
                (networkCompany.__typename === 'ConnectedBuilderNetworkCompany' ||
                  networkCompany.__typename === 'ConnectedE1NetworkCompany' ||
                  networkCompany.__typename === 'RedactedConnectedBuilderNetworkCompany' ||
                  networkCompany.__typename === 'RedactedConnectedE1NetworkCompany')
              ) {
                return (
                  <ConnectedCompanyDrawerContent
                    networkCompany={networkCompany}
                    universalProfile={universalProfile}
                    hasProcurementPhaseFeature={hasProcurementPhaseFeature}
                  />
                );
              }

              if (networkCompany.__typename === 'BuilderNetworkCompany') {
                return (
                  <AddressBookCompanyDrawerContent
                    networkCompany={networkCompany}
                    hasProcurementPhaseFeature={hasProcurementPhaseFeature}
                  />
                );
              }

              if (isShadowProfile(universalProfile)) {
                return (
                  <ShadowProfileDrawerContent
                    universalProfile={universalProfile}
                    canViewAddressBook={canViewAddressBook}
                    onConnectSuccess={handleConnectSuccess}
                  />
                );
              }

              if (isE1NetworkCompany(universalProfile)) {
                return (
                  <E1NetworkCompanyDrawerContent
                    universalProfile={universalProfile}
                    canViewAddressBook={canViewAddressBook}
                    onConnectSuccess={handleConnectSuccess}
                  />
                );
              }

              return <DrawerError />;
            }}
          </UniversalProfileContext.Consumer>
          <DefaultOperatingCountryProvider>
            <SyncCompanyModal onSyncSuccess={onSuccess} />
          </DefaultOperatingCountryProvider>
          <RemoveNetworkConnectionModal onSuccess={onSuccess} />
        </UniversalProfileProvider>
      </DrawerContent>
    </Drawer>
  );
};
