import { useMutation } from '@apollo/client';
import { UPDATE_CORE_TRADES } from '@profiles/features/CompanyProfile/components/Trades/mutations';
import {
  UpdateCoreTradesMutation,
  UpdateCoreTradesMutationVariables,
} from '@profiles/features/CompanyProfile/components/Trades/mutations.generated';
import { EditCoreTradesFieldValues } from '@profiles/features/CompanyProfile/components/Trades/types';

const transformValues = (values: EditCoreTradesFieldValues): UpdateCoreTradesMutationVariables => ({
  coreTradeIds: values.CoreTrades,
});

export const useCoreTradeSubmitForm = () => {
  const [updateCoreTrades, { error }] = useMutation<
    UpdateCoreTradesMutation,
    UpdateCoreTradesMutationVariables
  >(UPDATE_CORE_TRADES);

  const submit = async (values: EditCoreTradesFieldValues) => {
    const { errors, data } = await updateCoreTrades({
      variables: transformValues(values),
    });

    return { errors: errors || null, data };
  };

  return { submit, error: error ?? undefined };
};
