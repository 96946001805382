import { gql } from '@apollo/client';

export const ADD_RFQ_TO_SCOPE = gql`
  mutation CreateScopeRfq($input: ScopeRfqInput!) {
    createScopeRfq(input: $input) {
      id
      rfqId
    }
  }
`;
