import $ from 'jquery';
import Routing from 'routing';
import E1Request from '../../js/classes/E1Request';
import { clientDeviceIsMobileOrTablet, openDocumentInNewTab } from '../../js/app/doc_viewer';
import { monitorTrades } from '../../js/app/stage_matrix_trades';
import Form from '../../js/classes/Form';
import LoadingModal from '../../js/classes/LoadingModal';
import Modal from '../../js/classes/Modal';
import { E1PDFViewerLauncher } from '../../js/utils/pdf_doc_viewer';
import { getStageTypeAsString } from '../../enums';

function MatrixWrapper(options) {
  this.$target = options.target;
  this.stageId = options.stageId;
  this.stageType = options.stageType;
  this.packages = options.packages;
  this.documents = options.documents;
  this.continueEditing = options.continueEditing;
  this.matrix = null;
  this.proxy = null;
  this.dataBeingSent = false;
  this.readOnly = true;
  this.addendumId = null;
  this.connectionDown = false;
  this.saveChangesInterval = null;
  this.fetchChangesInterval = null;
  window.redirectOnMatrixSave = false;

  this.editUrl = this.getEditUrl();
  this.redirectUrl = this.getRedirectUrl();

  this.init();
  this.attachListeners();
}

MatrixWrapper.prototype = {
  init() {
    const self = this;

    let addendumId = self.$target.attr('data-addendum');
    if (typeof addendumId === 'undefined' || addendumId === '') {
      addendumId = null;
    }
    self.addendumId = addendumId;
    self.readOnly = parseInt(self.$target.attr('data-readonly'), 10);

    const proxy = self.createProxy();
    proxy.setColumns(self.packages);
    proxy.setRows(self.documents);
    self.proxy = proxy;

    self.matrix = new E1.Matrix('#grid', self.getConfig(), proxy);

    self.matrix.setResponsive();
    self.matrix.setEditable(!self.readOnly);

    $('#filterDiscipline').append(...Object.keys(self.matrix.getDisciplines()).map((disc) => $('<option>', {
        value: disc,
        text: disc,
      }))
    ).select2();

    this.updatePackageSelectionOptions();

    self.$errorModal = $('.e1-modal.matrix-error-modal');
    self.errorModal = new Modal(self.$errorModal.prop('outerHTML'), {});
  },

  updatePackageSelectionOptions() {
    const self = this;
    const disciplineNames = Object.keys(self.matrix.getDisciplines());
    const packages = self.matrix.getPackages();

    const groupedPackages = disciplineNames.map((disciplineName) => ({
      text: disciplineName,
      children: packages.filter(({ disc }) => disc === disciplineName).map(({ id, name }) => ({ id, text: name })),
    })).filter((g) => g.children.length);

    $('#filterPackages')
      .empty()
      .append('<option value="">(All Packages)</option>')
      .select2({ data: groupedPackages })
      .val('')
      .change();
  },

  getEditUrl() {
    const self = this;
    const editUrl = self.$target.data('edit-url');
    if (editUrl !== null && editUrl !== '') {
      return editUrl;
    }
    return null;
  },

  getRedirectUrl() {
    const self = this;
    const redirectUrl = $('#grid').attr('data-redirect-url');
    if (redirectUrl !== null && redirectUrl !== '') {
      return redirectUrl;
    }
    return self.getDefaultRedirectUrl();
  },

  getDefaultRedirectUrl() {
    const self = this;
    const params = {
      id: self.stageId,
      stageType: getStageTypeAsString(self.stageType)?.toLowerCase(),
    };
    return Routing.generate('app_stage_view', params);
  },

  getConfig() {
    const self = this;
    /**
     * Start Matrix related code
     */
    const config = {
      headerMenu: [
        {
          iconImage: '',
          title: 'Edit Package',
          command: 'rename',
        },
        {
          iconImage: '',
          title: 'Select All Files',
          command: 'selectAll',
        },
        {
          iconImage: '',
          title: 'Deselect All Files',
          command: 'deselectAll',
        },
        { title: 'Copy Selections', command: 'copy', divider: true },
        {
          iconImage: '',
          title: 'Copy Package',
          command: 'copy',
        },
        {
          title: 'Paste Selections',
          command: 'paste',
          disabled: true,
          tooltip: 'Please copy a package first',
        },
        {
          title: "<span class='delete-warn'>Delete Package</span>",
          command: 'delete',
          divider: true,
        },
        {
          iconImage: '',
          title: 'View Change History',
          command: 'history',
        },
      ],
      headerMenuReadOnly: [
        {
          iconImage: '',
          title: 'Copy Package',
          command: 'copy',
        },
        {
          iconImage: '',
          title: 'View Change History',
          command: 'history',
        },
      ],
      addendaId: self.addendumId,
    };

    return config;
  },

  createProxy() {
    const self = this;
    const data = {
      id: self.stageId,
      addendumId: self.addendumId,
      read_only: self.readOnly,
    };

    const proxyData = {
      dataUrl: Routing.generate('app_stagematrix_fetch', data),
    };

    return new E1.MatrixDataProxy(proxyData);
  },

  getSaveMethod() {
    const self = this;
    const params = {
      id: self.stageId,
      addendaId: self.addendumId,
    };
    return Routing.generate('app_stagematrix_save', params);
  },

  viewDocument(documentId) {
    const self = this;

    if (clientDeviceIsMobileOrTablet()) {
      openDocumentInNewTab(self.stageId, documentId);
      return;
    }

    const viewerLauncher = new E1PDFViewerLauncher(self.stageId, {
      docId: documentId,
      editPackages: true,
    });
    viewerLauncher.trigger();
  },

  getChunk() {
    const self = this;
    const changes = self.matrix.getChanges();
    const chunkSize = 5000;
    let chunk = null;
    if (changes.add.length > 0) {
      chunk = { add: changes.add.splice(0, chunkSize), del: [] };
    } else if (changes.del.length > 0) {
      chunk = { add: [], del: changes.del.splice(0, chunkSize) };
    }
    return chunk;
  },

  async sendChunk(chunk, cb) {
    const self = this;

    const method = self.getSaveMethod();
    const request = new E1Request(method, 'POST', chunk);
    const onSuccess = (req, resp) => {
      if (resp.success) {
        if (self.connectionDown) {
          self.connectionDown = false;
          self.hideErrorModal();
        }
        self.matrix.commitChanges(chunk);
        self.dataBeingSent = false;

        self.manageChunkedChanges(cb);
      } else {
        self.dataBeingSent = false;
        const changes = self.matrix.getChanges();
        self.showErrorModal(changes.add.length + changes.del.length);
        self.connectionDown = true;
      }
    };
    const onError = () => {
      self.dataBeingSent = false;
      const changes = self.matrix.getChanges();
      self.showErrorModal(changes.add.length + changes.del.length);
      self.connectionDown = true;
    };

    return request.submit(onSuccess, onError);
  },

  manageChunkedChanges(cb) {
    const self = this;
    const chunk = self.getChunk();
    self.dataBeingSent = true;
    self.proxy.issueTimestamp(); // Increment timestamp so we don't hide the changes being saved if there is a fetch in flight when we save
    if (chunk !== null) {
      self.sendChunk(chunk, cb);
    } else {
      self.dataBeingSent = false;
      $('.header-button').removeClass('disabled');
      $('.syncing-text').addClass('hide');
      if (typeof cb !== 'undefined') {
        cb();
      }
    }
  },

  sendChanges(cb) {
    const self = this;
    if (!self.dataBeingSent && self.matrix.hasChanges()) {
      $('.header-button').addClass('disabled');
      $('.syncing-text').removeClass('hide');
      self.manageChunkedChanges(cb);
    } else if (typeof cb !== 'undefined') {
      cb();
    }
  },

  // get the most recent version of the matrix from the server
  // due to the nature of the truth table, we don't have to take into
  // consideration local changes that have been made since the reload
  // started
  getChanges() {
    const self = this;
    if (!self.dataBeingSent && !self.connectionDown) {
      self.matrix.reloadData();
    }
  },

  finaliseMatrix() {
    const self = this;

    const notifyModalRoot = document.getElementById('matrix-notify-modal');
    if (notifyModalRoot) {
      self.sendChanges(() => {
        const event = new CustomEvent('finalise-matrix-start', {
          detail: {
            stageId: self.stageId,
            stageType: self.stageType,
          }
        });
        notifyModalRoot.dispatchEvent(event);
      })
      return;
    }

    window.redirectOnMatrixSave = true;
    const loadingModal = new LoadingModal();
    loadingModal.show();
    self.sendChanges(() => {
      const saveMatrixMethod = Routing.generate('app_stagematrix_commitmodal', {
        id: self.stageId,
        addendumId: self.addendumId,
      });

      const req = new E1Request(saveMatrixMethod);
      req.show_loading_modal = true;
      req.submit(null, null, req);
    });
  },

  attachListeners() {
    const self = this;
    const $body = $('body');

    self.$target.on('click', '.matrixFileName', (e) => {
      const id = parseInt($(e.currentTarget).attr('id'), 10);
      self.viewDocument(id);
    });

    const $paModal = $('.e1-modal.pending-addendum-modal');
    if ($paModal.length > 0) {
      const pamodal = new Modal($paModal, {
        modal: true,
      });
      pamodal.show();
    }

    if (self.continueEditing) {
      const $pcModal = $('.e1-modal.pending-changes-modal');
      if ($pcModal.length > 0) {
        const pcmodal = new Modal($pcModal);
        pcmodal.show();
      }
    }

    $body.on('submit', 'form.edit-package-form', (e) => {
      e.preventDefault();

      const form = new Form($(e.currentTarget));
      form.extraCallback = (response) => {
        const savedPackage = JSON.parse(response.package);
        if (response.created) {
          self.matrix.addColumn({
            id: savedPackage.id,
            disc: savedPackage.discipline_name,
            name: savedPackage.title,
          });
        } else {
          self.matrix.renameColumn(savedPackage.id, savedPackage.title);
        }
      };
      form.submit();
    });

    $body.on('submit', 'form.delete-package-form', (e) => {
      e.preventDefault();

      const form = new Form($(e.currentTarget));
      form.extraCallback = (response) => {
        const removedPackage = response.package;
        self.matrix.removeColumn(removedPackage.id);
      };
      form.submit();
    });

    $('.btn-group ul.dropdown-menu a').on('click', (e) => {
      $(e.currentTarget).closest('.btn-group').removeClass('open');
    });

    $('#filterDocName').on('keyup', (e) => {
      self.matrix.updateFilter('documentTitle', $(e.currentTarget).val());
    });

    $('#filterAddendumDocsOnly').on('click', (e) => {
      self.matrix.updateFilter('addendumDocs', $(e.currentTarget).is(':checked'));
    });

    $('#filterDiscipline').on('change', (e) => {
      if ($('#filterDiscipline').val() === '') {
        self.matrix.updateFilter('discipline', false);
      } else {
        self.matrix.updateFilter('discipline', $(e.currentTarget).val());
      }
      this.updatePackageSelectionOptions();
    });

    $('#filterPackages').on('change', (e) => {
      const val = $(e.currentTarget).val();
      self.matrix.updateFilter('packages', val === '' ? false : val);
    });

    $body.on('submit', 'form.commit-matrix-form', (e) => {
      e.preventDefault();

      const callback = () => {
        const form = new Form($(e.currentTarget));
        form.extraCallback = (response) => {
          if (!response.success) {
            return;
          }

          if (response.matrix_change_published) {
            // No directory contacts to notify, wait for matrix change to be processed
            const req = new E1Request(
              Routing.generate('app_stagematrix_pendingchangemodal', { id: self.stageId }),
            );
            req.extraCallback = (res) => {
              if (res.success && res.matrix_change_processed) {
                window.location = self.redirectUrl;
              }
            };
            req.submit();
          } else if (response.is_construction_stage) {
            // Display the modal for selecting which directory contacts to notify
            const route = Routing.generate('app_stagematrix_commitmodalcontacts', {
              id: self.stageId,
              matrixChangeId: response.matrix_id,
            });

            const req = new E1Request(route);
            req.show_loading_modal = true;
            req.submit();
          }
        };
        form.submit();
      };

      if (!!self.dataBeingSent || self.matrix.hasChanges()) {
        self.sendChanges(callback);
      } else {
        callback();
      }
    });

    const matrixContactsFormSelector = 'form.commit-matrix-contacts-form';

    $body.on('submit', matrixContactsFormSelector, (e) => {
      e.preventDefault();

      const form = new Form($(e.currentTarget));
      form.extraCallback = (resp) => {
        if (resp.success && resp.matrix_change_published) {
          const req = new E1Request(
            Routing.generate('app_stagematrix_pendingchangemodal', { id: self.stageId }),
          );
          req.extraCallback = (response) => {
            if (response.success && response.matrix_change_processed) {
              window.location = self.redirectUrl;
              // return;
            }
          };
          req.submit();
        }
      };
      form.submit();
    });

    const matrixContactsFormCheckboxSelectors = `${matrixContactsFormSelector} input[type="checkbox"]`;

    $body.on('change', matrixContactsFormCheckboxSelectors, () => {
      const $notifySubmitSelector = $(`${matrixContactsFormSelector} .notify-submit`);
      const $noNotifySubmitSelector = $(`${matrixContactsFormSelector} .no-notify-submit`);

      if ($(`${matrixContactsFormCheckboxSelectors}:checked`).length < 1) {
        $notifySubmitSelector.addClass('hide');
        $noNotifySubmitSelector.removeClass('hide');
      } else {
        $notifySubmitSelector.removeClass('hide');
        $noNotifySubmitSelector.addClass('hide');
      }
    });

    $body.on('click', `${matrixContactsFormSelector} .back-btn`, () => {
      self.finaliseMatrix();
    });

    $body.on('click', '.close-matrix-button', () => {
      self.sendChanges(() => {
        window.location = self.redirectUrl;
      });
    });

    $body.on('click', '.confirm-exit-matrix', () => {
      window.location = self.getDefaultRedirectUrl();
    });

    $body.on('submit', 'form.delete-draft-changes-form', (e) => {
      e.preventDefault();

      const form = new Form($(e.currentTarget));
      form.submit();
    });

    $body.on('reload-matrix-changes', () => {
      self.getChanges();
    });

    /**
     * Start of Page related code that interacts with the matrix instance
     */
    // Expand all directories
    $('#expDir').on('click', () => {
      self.matrix.expandDirs();

      return false;
    });

    // Collapse all directories
    $('#colDir').on('click', () => {
      self.matrix.collapseDirs();

      return false;
    });

    // Start creating a new package
    $('#newPack').on('click', () => {
      self.createPackage();

      return false;
    });

    // Start creating a new package
    $('#refreshTrades').on('click', () => {
      if (!self.readOnly) {
        const editPackage = Routing.generate('app_stagepackage_maptradesmodal', {
          id: self.stageId,
        });
        const req = new E1Request(editPackage);
        req.extraCallback = () => {
          const $tradeForm = $('.trade-select');
          if ($tradeForm.length > 0) {
            monitorTrades($tradeForm);
          }
        };
        req.submit(null, null, req);
      }
      return false;
    });

    // Undo last change
    $('#undo').on('click', () => {
      if (!self.readOnly) {
        self.matrix.undoLastChange();
        if (!self.matrix.hasChanges()) {
          $('#undo').addClass('disabled');
        }
      }

      return false;
    });

    // Refresh relationship data from remote server
    $('#refresh').on('click', () => {
      self.getChanges();
    });

    $body.on('click', '.matrix-edit-mode-trigger', () => {
      if (self.readOnly && self.editUrl != null) {
        window.location = self.editUrl;
      }
      return false;
    });

    $('.upload_documents_trigger').on('click', (e) => {
      const location = $(e.currentTarget).attr('data-href');
      self.sendChanges(() => {
        window.location = location;
      });
    });

    $('.save-matrix-trigger').on('click', () => {
      if (self.matrix.hasChanges()) {
        return;
      }
      self.finaliseMatrix();
    });

    $('.close_matrix_trigger').on('click', () => {
      if (self.matrix.hasChanges()) {
        return;
      }
      if (self.readOnly) {
        window.location = self.redirectUrl;
      } else if (self.addendumId !== null) {
        self.getChanges();
        self.sendChanges(() => {
          window.location = self.redirectUrl;
        });
      } else {
        self.getChanges();
        self.sendChanges(() => {
          const closeMatrixMethod = Routing.generate('app_stagematrix_closemodal', {
            id: self.stageId,
            addendaId: self.addendumId,
          });
          const req = new E1Request(closeMatrixMethod);
          req.show_loading_modal = true;
          req.extraCallback = (res) => {
            if (res.required_redirect) {
              window.location = self.redirectUrl;
            }
          };
          req.submit(null, null, req);
        });
      }
    });

    $('.excel_export_trigger').on('click', () => {
      self.sendChanges(() =>
        new E1Request(Routing.generate('app_stagematrix_export', { id: self.stageId })).submit(),
      );
    });

    $('#finishNotify').on('click', () => {
      self.finishAndNotify();
    });

    /**
     * Start handling Events emitted from the matrix instance
     */

    let timeoutHover = null;

    // Cell hovering, can be used to show full doc and package details
    self.matrix.on('hover', (e, data) => {
      clearTimeout(timeoutHover);

      $('#hovering').hide();

      timeoutHover = setTimeout(() => {
        const s = `<span>${data.column.rawName}</span><br/>${data.row.name}`;
        let newTop = data.pos.top;
        if (data.pos.top > $(window).height() - 75) {
          newTop = data.pos.top - 22;
        }

        if (data.pos.left > $(window).width() - 200) {
          $('#hovering')
            .html(s)
            .css({
              top: `${newTop}px`,
              right: `${$(window).width() - data.pos.left + 25}px`,
              left: 'auto',
            })
            .show();
        } else {
          $('#hovering')
            .html(s)
            .css({
              top: `${newTop}px`,
              left: `${data.pos.left + 50}px`,
              right: 'auto',
            })
            .show();
        }
      }, 250);
    });

    setTimeout(() => {
      $('.slick-viewport').trigger('focus');
    }, 300);

    $('.slick-viewport').on('mouseleave', () => {
      clearTimeout(timeoutHover);
      $('#hovering').css({ left: '-10000px', right: 'auto' });
    });

    self.matrix.on('edit', () => {
      const route = Routing.generate('stagematrix_readonly', {
        id: self.stageId,
      });
      const request = new E1Request(route);
      request.show_loading_modal = true;
      request.submit();
    });

    // Fires when user makes a change to the matrix.
    self.matrix.on('modify', () => {
      $('#undo').removeClass('disabled');
    });

    // Fires when a menu item is click
    self.matrix.on('menu', (e, data) => {
      const s = `${data.column.rawName} :: ${data.command}`;
      $('#menu').text(s);
      switch (data.command) {
        case 'rename':
          self.updatePackage(data.column.id);
          break;
        case 'selectAll':
          self.matrix.toggleColumn(data.column.id, true);
          break;
        case 'deselectAll':
          self.matrix.toggleColumn(data.column.id, false);
          break;
        case 'copy':
          self.matrix.copySelectionsToClipboard(data.column.id);
          break;
        case 'paste':
          self.matrix.pasteSelectionsFromClipboard(data.column.id);
          break;
        case 'delete':
          self.removePackage(data.column.id);
          break;
        case 'history':
          self.viewPackageHistory(data.column.id);
          break;
        default:
          break;
      }
    });

    // 10 second intervals on saving changes if they exists.
    // 20 second intervals on retrieving changes
    self.saveChangesInterval = setInterval(() => {
      self.sendChanges();
    }, 2500);
    self.fetchChangesInterval = setInterval(() => {
      self.getChanges();
    }, 25000);

    window.onbeforeunload = () => {
      if (self.matrix.hasChanges() && !self.dataBeingSent) {
        self.sendChanges();
        self.getChanges();
        return 'You have attempted to leave this page. If you have made any changes to the fields without clicking the Save button, your changes will be lost.';
      }
      return undefined;
    };
  },

  detachListeners() {
    const self = this;
    if (self.saveChangesInterval) {
      clearInterval(self.saveChangesInterval);
    }
    if (self.fetchChangesInterval) {
      clearInterval(self.fetchChangesInterval);
    }
  },

  createPackage() {
    const self = this;
    const editPackage = Routing.generate('app_stagepackage_newmodal', { id: self.stageId });
    const req = new E1Request(editPackage);
    req.show_loading_modal = true;
    req.submit(null, null, req);
  },

  updatePackage(id) {
    const self = this;
    const editPackage = Routing.generate('app_stagepackage_editmodal', {
      id: self.stageId,
      packageId: id,
    });
    const req = new E1Request(editPackage);
    req.show_loading_modal = true;
    req.submit(null, null, req);
  },

  removePackage(id) {
    const self = this;
    const editPackage = Routing.generate('app_stagepackage_deletemodal', {
      id: self.stageId,
      packageId: id,
    });
    const req = new E1Request(editPackage);
    req.show_loading_modal = true;
    req.submit(null, null, req);
  },

  viewPackageHistory(id) {
    const self = this;
    const editPackage = Routing.generate('app_stagedocument_viewchangesmodal', {
      id: self.stageId,
      packageId: id,
    });
    const req = new E1Request(editPackage);
    req.show_loading_modal = true;
    req.submit(null, null, req);
  },
  showErrorModal(msg) {
    const self = this;
    self.errorModal.$modal.find('.modal_error_network_count').text(msg);
    self.errorModal.show();
  },
  hideErrorModal() {
    const self = this;
    self.errorModal.close();
  },
};

export default function matrixInit(options) {
  if (options.stageType === 2) {
    MatrixWrapper.prototype.getDefaultRedirectUrl = function () {
      return Routing.generate('app_constructionstage_view', { id: options.stageId });
    };
  }

  return new MatrixWrapper(options);
}
