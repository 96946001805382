import { QueryResult, useQuery } from '@apollo/client';
import { useAccountData } from '@builder/context/SubbieNetworkFilterDataProvider/hooks';
import { NETWORK_INVITATIONS_SEARCH_COMPANIES } from './queries';
import {
  NetworkInvitationsSearchCompaniesQuery,
  NetworkInvitationsSearchCompaniesQueryVariables,
} from './queries.generated';
import { SubbieNetworkInvitationsFilters } from '../../../types';
import { ResultType } from '@ascension/_gqltypes/builder.generated';
import { NetworkCompany } from '@builder/common/SubbieNetwork/types';

const CURRENT_PAGE = 1;
const RESULTS_PER_PAGE = 50;
export const DISTANCE_OPTION_MAX = 300;

type Results = NetworkInvitationsSearchCompaniesQuery['searchNetworkCompaniesForInvite'];

export const useSearchNetworkCompanies = (
  filters: SubbieNetworkInvitationsFilters,
): {
  loading: boolean;
  error: Error | null;
  refetchNetworkResults: QueryResult<
    NetworkInvitationsSearchCompaniesQuery,
    NetworkInvitationsSearchCompaniesQueryVariables
  >['refetch'];
  data: null | (Omit<Results, 'results'> & { results: NetworkCompany[] });
} => {
  const ascAccountData = useAccountData();

  const {
    tradeId,
    searchArea,
    contractSize,
    workforceSize,
    categoryId,
    distance,
    location: locationFilter,
    showE1Network,
  } = filters;

  const location = {
    lat: locationFilter?.lat ?? ascAccountData?.geocoordinates.lat ?? 0.0,
    lon: locationFilter?.lon ?? ascAccountData?.geocoordinates.lon ?? 0.0,
  };

  const { min: contractSizeMin, max: contractSizeMax } = contractSize;

  const baseVariables: NetworkInvitationsSearchCompaniesQueryVariables = {
    workforceSize,
    stockTradeIds: tradeId ? [tradeId.toString()] : [],
    tradeIds: [],
    resultType: showE1Network ? ResultType.BOTH : ResultType.AB_COMPANY,
    projectCategoryIds: categoryId ? [categoryId.toString()] : [],
    // connectionType: getConnectionType(relationshipType),
    connectionType: undefined,
    contractSizeMin,
    contractSizeMax,
    // tagIds: companyListId ? [companyListId.toString()] : [],
    tagIds: [],
    page: CURRENT_PAGE,
    resultsPerPage: RESULTS_PER_PAGE,
  };

  // See `locationOptions.ts` for when the `searchArea` field for `LocationOptions` can be undefined.
  const serviceLocationVariables =
    searchArea === 'service-area' && !locationFilter?.serviceArea
      ? ({
          serviceLocation: location,
        } satisfies Pick<NetworkInvitationsSearchCompaniesQueryVariables, 'serviceLocation'>)
      : null;

  const serviceAreaVariables =
    searchArea === 'service-area' && locationFilter?.serviceArea?.country
      ? ({
          serviceArea: {
            country: locationFilter?.serviceArea.country,
            state: locationFilter?.serviceArea.state ?? null,
          },
        } satisfies Pick<NetworkInvitationsSearchCompaniesQueryVariables, 'serviceArea'>)
      : null;

  const distanceFromVariables = {
    distanceFrom: {
      distance: distance ?? DISTANCE_OPTION_MAX,
      location,
    },
  } satisfies Pick<NetworkInvitationsSearchCompaniesQueryVariables, 'distanceFrom'>;

  const searchAreaVariables =
    serviceLocationVariables ?? serviceAreaVariables ?? distanceFromVariables;

  const variables = {
    ...baseVariables,
    ...searchAreaVariables,
  };

  const { data, loading, error, refetch } = useQuery<
    NetworkInvitationsSearchCompaniesQuery,
    NetworkInvitationsSearchCompaniesQueryVariables
  >(NETWORK_INVITATIONS_SEARCH_COMPANIES, {
    variables,
    fetchPolicy: 'cache-first',
    skip: !tradeId,
    // TODO: We probably need our own event
    // onCompleted: ({ searchNetworkCompanies }) => {
    //   if (shouldSendAnalytics({ confirmedFilters: filters, queryFilters })) {
    //     const resultsCount = searchNetworkCompanies.results.length || 0;
    //
    //     addEvent(
    //       populateNewSearchAnalyticsData({
    //         confirmedFilters: filters,
    //         resultsCount,
    //         currentPage: CURRENT_PAGE,
    //       }),
    //     );
    //     setQueryFilters(filters);
    //   }
    // },
  });

  if (loading) {
    return {
      loading: true,
      error: null,
      data: null,
      refetchNetworkResults: refetch,
    } as const;
  }

  if (error || !data) {
    return {
      loading: false,
      error: new Error('something went wrong'),
      data: null,
      refetchNetworkResults: refetch,
    } as const;
  }
  return {
    loading: false,
    error: null,
    refetchNetworkResults: refetch,
    data: {
      __typename: 'SearchNetworkCompaniesResult',
      count: data.searchNetworkCompaniesForInvite.count,
      totalCount: data.searchNetworkCompaniesForInvite.totalCount,
      currentPage: data.searchNetworkCompaniesForInvite.currentPage,
      results: data.searchNetworkCompaniesForInvite.results.map((result): NetworkCompany => {
        if (
          result.__typename === 'RedactedE1NetworkCompany' ||
          result.__typename === 'RedactedConnectedE1NetworkCompany' ||
          result.__typename === 'RedactedConnectedBuilderNetworkCompany'
        ) {
          throw new Error('Redacted companies should not be returned in search results');
        }

        const baseCompany = {
          __typename: result.__typename,
          id: result.id,
          name: result.name,
          address: result.defaultOffice.address,
        };

        switch (result.__typename) {
          case 'ShadowNetworkCompany':
            throw new Error(
              'Unexpected company type encountered while processing network search results.',
            );

          case 'BuilderNetworkCompany':
            return {
              ...baseCompany,
              stockTrades: result.trades
                .map((trade) => trade.stockTrade)
                .filter(
                  (trade): trade is { __typename: 'StockTrade'; id: number; name: string } =>
                    trade !== null,
                ),
              coreStockTrades: [],
              contractSizeMin: null,
              contractSizeMax: null,
              logoUrl: null,
              contacts: result.contacts
                .filter((contact) => contact?.maybeEmail)
                .map((contact) => ({
                  __typename: contact.__typename,
                  id: contact.id,
                  fullName: contact.fullName,
                  maybeEmail: contact.maybeEmail,
                  unSubscribed: contact.unSubscribedAt !== null,
                })),
              qualifications: result.qualifications,
            };

          case 'E1NetworkCompany':
            return {
              ...baseCompany,
              stockTrades: result.stockTrades
                .filter(({ __typename }) => __typename === 'NetworkCompanyStockTrade')
                .map(({ id, name }) => ({ id: Number(id), name })),
              coreStockTrades: result.stockTrades
                .filter(({ __typename }) => __typename === 'NetworkCompanyCoreStockTrade')
                .map(({ id, name }) => ({ id: Number(id), name })),
              contractSizeMin: result.contractSizeMin,
              contractSizeMax: result.contractSizeMax,
              logoUrl: result.logoUrl,
              contacts: [
                {
                  id: result.primaryContact.id,
                  fullName: result.primaryContact.fullName,
                  maybeEmail: result.primaryContact.email,
                  unSubscribed: false,
                },
              ],
              qualifications: result.qualifications,
            };

          case 'ConnectedE1NetworkCompany':
          case 'ConnectedBuilderNetworkCompany':
          default:
            return {
              ...baseCompany,
              stockTrades: result.stockTrades
                .filter(({ __typename }) => __typename === 'NetworkCompanyStockTrade')
                .map(({ id, name }) => ({ id: Number(id), name })),
              coreStockTrades: result.stockTrades
                .filter(({ __typename }) => __typename === 'NetworkCompanyCoreStockTrade')
                .map(({ id, name }) => ({ id: Number(id), name })),
              contractSizeMin: result.contractSizeMin,
              contractSizeMax: result.contractSizeMax,
              logoUrl: result.logoUrl,
              contacts: result.contacts
                .map((contact) => {
                  if (contact.__typename === 'BuilderContact') {
                    return {
                      __typename: contact.__typename,
                      id: contact.id,
                      fullName: contact.fullName,
                      maybeEmail: contact.maybeEmail,
                      unSubscribed: contact.unSubscribedAt !== null,
                    };
                  }

                  return {
                    __typename: contact.__typename,
                    id: contact.id,
                    fullName: contact.fullName,
                    maybeEmail: contact.email,
                    unSubscribed: contact.unSubscribedAt !== null,
                  };
                })
                .filter((contact) => contact?.maybeEmail),
              qualifications: result.qualifications,
            };
        }
      }),
    },
  };
};
