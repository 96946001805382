import {
  OperationVariables,
  MutationHookOptions,
  TypedDocumentNode,
  useMutation,
} from '@apollo/client';
import { SCOPES_APOLLO_CLIENT_NAME } from '../constants';

export const useScopesMutation = <TData = unknown, TVariables = OperationVariables>(
  mutation: TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables>,
) =>
  useMutation<TData, TVariables>(mutation, {
    ...options,
    context: { clientName: SCOPES_APOLLO_CLIENT_NAME },
  });
