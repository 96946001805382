import { PayloadExtras, AnalyticsHookInterface, useInteractAnalytics } from '.';

interface NotificationAnalyticsEventPayload extends PayloadExtras {
  notificationId: string;
  isUnread: boolean;
  notificationItemLink: string | undefined;
}

const useNotificationAnalytics = <
  T = NotificationAnalyticsEventPayload,
>(): AnalyticsHookInterface<T> => useInteractAnalytics();

export { useNotificationAnalytics };
