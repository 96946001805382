import { useMutation } from '@apollo/client';
import { UPDATE_STAGE_SHAREPOINT_MAP } from './mutations';
import {
  UpdateStageSharepointMap,
  UpdateStageSharepointMapVariables,
} from './types/UpdateStageSharepointMap';
import { UpdateStageSharepointMapInput } from '@ascension/_gqltypes/builder.generated';

export const useUpdateStageSharepointMap = () => {
  const [updateStageSharepointMap, { loading, error }] = useMutation<
    UpdateStageSharepointMap,
    UpdateStageSharepointMapVariables
  >(UPDATE_STAGE_SHAREPOINT_MAP);

  const submit = async (args: UpdateStageSharepointMapInput) => {
    await updateStageSharepointMap({
      variables: {
        input: args,
      },
    });

    return { loading, errors: undefined };
  };

  return { submit, isLoading: loading, hasError: error !== undefined };
};
