import { useEffect } from 'react';
import Routing from 'routing';
import { Button, ButtonSize, E1Link } from '@estimateone/frontend-components';
import NiceOne from '../../../../shared/brand-moments/NiceOne';
import { Action, Event, useAnalytics } from '../../../../hooks/Analytics';
import { AnalyticsEventParam, SourceTrackingToken } from '@ascension/enums/Analytics';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

type ConfirmationProps = {
  addonName: string;
  billingContactEmail: string;
  invoiceId: EntityId;
  isPaid: boolean;
  onClose: () => void;
  supportDetails: {
    email: string;
    phone: string;
  };
  source?: SourceTrackingToken;
};

const Confirmation = ({
  addonName,
  billingContactEmail,
  invoiceId,
  isPaid,
  onClose,
  supportDetails: { email, phone },
  source,
}: ConfirmationProps) => {
  const { addEvent } = useAnalytics(Event.INTERACT);

  useEffect(() => {
    if (source) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      (async () =>
        addEvent({
          action: Action.ADDON_PURCHASE_COMPLETE,
          [AnalyticsEventParam.IN_APP_SOURCE]: source,
        }))();
    }
  }, [addEvent]);

  return (
    <div className={styles.confirmationModal}>
      <div className={styles.content}>
        <h1 className={styles.heading}>
          <NiceOne />
        </h1>
        <div className={styles.subHeader}>
          <h2>
            {isPaid ? (
              `You now have access to the ${addonName}`
            ) : (
              <>
                You&apos;re one step away from getting access to the {addonName}.<br />
                Once we receive payment, you will get immediate access.
              </>
            )}
          </h2>
        </div>
        <div className={styles.confirmationInfo}>
          <p>
            A confirmation email has been sent to <strong>{billingContactEmail}</strong> along with
            a tax invoice.
          </p>
          <p>
            <E1Link
              link={Routing.generate('app_invoice_view', { id: invoiceId })}
              target="_blank"
              rel="noopener noreferrer"
            >
              You can also download your tax invoice now.
            </E1Link>
          </p>
          <h3 className={styles.supportHeading}>Any problems?</h3>
          <p>
            Email <E1Link link={`mailto:${email}`}>{email}</E1Link>
            <br />
            or call <E1Link link={`tel:${phone.replace(/\s/g, '')}`}>{phone}</E1Link>
          </p>
        </div>

        <Button fullWidth size={ButtonSize.Large} onClick={onClose}>
          Done
        </Button>
      </div>
    </div>
  );
};

export default Confirmation;
