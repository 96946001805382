import { useCallback, useRef } from 'react';

function useStickyTableHeader(
  tableElementQuerySelector = 'table',
  headerElementQuerySelector = 'thead',
) {
  const targetElementRef = useRef<HTMLElement | null>(null);

  // https://stackoverflow.com/questions/60476155/is-it-safe-to-use-ref-current-as-useeffects-dependency-when-ref-points-to-a-dom
  // https://gist.githubusercontent.com/thebuilder/fb07c989093d4a82811625de361884e7/raw/378bde47267f970e924248b76ac923fed0567d32/hookWithRef.js
  const createTargetElementRef = useCallback((node: HTMLElement | null) => {
    if (!node) {
      return;
    }

    const tableElement = node.querySelector<HTMLElement>(tableElementQuerySelector);
    const headerElement = node.querySelector<HTMLElement>(headerElementQuerySelector);

    const scrollHandler = () => {
      if (!tableElement || !headerElement) {
        return;
      }
      const tableBounds = tableElement.getBoundingClientRect();
      const tableHeight = tableBounds.height;
      const tableTop = tableBounds.top;
      const maxPos = tableHeight - headerElement.clientHeight;
      const pos = tableTop < 0 ? -1 * tableTop : 0;

      if (pos < maxPos) {
        // eslint-disable-next-line fp/no-mutation
        headerElement.style.transform = `translate3d(0px, ${pos}px, 0px)`;
      }
    };

    // Remove any existing instances of the scroll event listener
    document.removeEventListener('scroll', scrollHandler);
    document.addEventListener('scroll', scrollHandler);

    targetElementRef.current = node;
  }, []);

  return [createTargetElementRef];
}

export { useStickyTableHeader };
