import { gql } from '@apollo/client';

export const MATCHING_TRADES = gql`
  query MatchingStockTrades($projectId: EntityId!) {
    projectStockTradesMatchingCurrentAccountsTrades(projectId: $projectId) {
      stockTradeCount
      matchingStockTradeCount
      matchingStockTrades {
        id
        name
      }
    }
  }
`;
