import { Form } from 'react-final-form';
import Routing from 'routing';
import { Button, ButtonVariant, Wrapper } from '@estimateone/frontend-components';
import { mapCategoryToSelectOption } from '../../common/FinalForm/StageCategorySelectField/utils';
import { ContactDetails } from './ContactDetails';
import { DescriptionDetails } from './DescriptionDetails';
import { PhaseDetails } from './PhaseDetails';
import { ProjectDetails } from './ProjectDetails';
import { AUSTRALIAN_STATES, CountrySelectOption } from './ProjectDetails/AddressInputField/fields';
import { ProjectSettingsDetails } from './ProjectSettingsDetails';
import { WizardInfoCard } from './WizardInfoCard';
import { mapFormValuesToInput } from './utils';
import { getInitialBudget } from './utils.budget';
import LoadingSpinner from '../../../shared/LoadingSpinner';
import {
  useCreateHandoverProcurementStage,
  useGetAllCountries,
  useGetTenderDetailsForHandover,
} from './hooks';
import { TenderHandoverWizardFieldName } from './enums';
import { TenderHandoverWizardValues } from './types';
import { GetTenderDetailsForHandover_stage_UnrestrictedStage as HandoverStage } from './types/GetTenderDetailsForHandover';
import styles from './styles.scss';

const FORM_ID = 'builder-tender-handover-wizard-form';

export const TenderHandoverWizard = ({ stageId }: { stageId: number }) => {
  const { loading, stage, accountCountry } = useGetTenderDetailsForHandover(stageId);
  const { loading: countriesLoading, countries } = useGetAllCountries();
  const { submit, responseData } = useCreateHandoverProcurementStage();

  const handleOnSubmit =
    (handoverStage: HandoverStage) => async (values: TenderHandoverWizardValues) => {
      const input = mapFormValuesToInput(values, handoverStage);
      return submit(input);
    };

  if (responseData && responseData.__typename === 'UnrestrictedStage') {
    const url = Routing.generate('app_stage_view', {
      id: responseData.id,
      stageType: responseData.type.toLowerCase(),
    });
    window.location.assign(url);
  }

  const cancelWizard = () => window.location.assign(Routing.generate('app_default_index'));

  if (loading || countriesLoading) {
    return (
      <div className={styles.loadingSpinner}>
        <LoadingSpinner />
      </div>
    );
  }

  if (!stage || stage?.__typename !== 'UnrestrictedStage') {
    throw new Error('A valid stage has not been returned');
  }

  const {
    name,
    category,
    budgetAmount,
    description,
    internalRef,
    projectAddress,
    constructionStartDate,
    constructionEndDate,
  } = stage;

  const allCountries: CountrySelectOption[] | undefined = countries?.map((country) => ({
    value: country.id,
    label: country.name,
  }));

  const getInitialCountry = () =>
    allCountries?.find((countryOption) => countryOption.value === projectAddress.country.id) ??
    undefined;

  return (
    <Form<TenderHandoverWizardValues>
      onSubmit={handleOnSubmit(stage)}
      keepDirtyOnReinitialize
      initialValues={{
        [TenderHandoverWizardFieldName.ProjectName]: name ?? '',
        [TenderHandoverWizardFieldName.AddressLineOne]: projectAddress.address1,
        [TenderHandoverWizardFieldName.AddressLineTwo]: projectAddress.address2,
        [TenderHandoverWizardFieldName.AddressLineThree]: projectAddress.address3,
        [TenderHandoverWizardFieldName.AddressSuburb]: projectAddress.suburb,
        [TenderHandoverWizardFieldName.AddressCity]: projectAddress.city,
        [TenderHandoverWizardFieldName.AddressPostcode]: projectAddress.postcode,
        [TenderHandoverWizardFieldName.AddressState]: AUSTRALIAN_STATES.find(
          (state) => projectAddress.state?.id === state.value,
        ),
        [TenderHandoverWizardFieldName.AddressCountry]: getInitialCountry(),
        [TenderHandoverWizardFieldName.InternalRef]: internalRef,
        [TenderHandoverWizardFieldName.StartDate]: constructionStartDate
          ? new Date(constructionStartDate)
          : null,
        [TenderHandoverWizardFieldName.EndDate]: constructionEndDate
          ? new Date(constructionEndDate)
          : null,
        [TenderHandoverWizardFieldName.ProjectCategory]: mapCategoryToSelectOption(category),
        [TenderHandoverWizardFieldName.ProjectValue]: getInitialBudget(
          budgetAmount,
          accountCountry,
        ),
        [TenderHandoverWizardFieldName.Description]: description ?? undefined,
        [TenderHandoverWizardFieldName.IncludeFixedQuoteConfirmation]: false,
        [TenderHandoverWizardFieldName.SendNotificationsToAwarded]: false,
        [TenderHandoverWizardFieldName.EnableDefaultTimeframes]: true,
      }}
    >
      {({ handleSubmit, values }) => (
        <form id={FORM_ID} onSubmit={handleSubmit}>
          <Wrapper>
            <main className={styles.grid}>
              <aside>
                <WizardInfoCard />
              </aside>

              <div>
                <ProjectDetails
                  selectedCountry={values[TenderHandoverWizardFieldName.AddressCountry]?.value}
                  allCountries={allCountries}
                />
                <PhaseDetails accountCountry={accountCountry} />
                <ContactDetails />
                <DescriptionDetails />
                <ProjectSettingsDetails />
              </div>
            </main>
          </Wrapper>

          <footer className={styles.footer}>
            <Wrapper className={styles.footerWrapper}>
              <Button
                type="button"
                variant={ButtonVariant.Secondary}
                className={styles.footerButton}
                onClick={cancelWizard}
              >
                Cancel
              </Button>
              <Button type="submit" variant={ButtonVariant.Primary} className={styles.footerButton}>
                Start Procurement Phase
              </Button>
            </Wrapper>
          </footer>
        </form>
      )}
    </Form>
  );
};
