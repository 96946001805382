import { useEffect } from 'react';
import { DrawerBody, DrawerToolbar, joinClassNames } from '@estimateone/frontend-components';
import {
  DrawerEvent,
  UNIVERSAL_PROFILE_DRAWER_READY_EVENT,
  UNIVERSAL_PROFILE_DRAWER_TAB_CONTAINER_ID,
} from '@builder/pages/Profiles/UniversalProfileDrawer';
import {
  AccountStatusColumn,
  CompanyHeader,
  CompanyInfo,
  CompanyNameLocation,
} from '@builder/pages/Profiles/UniversalProfileDrawer/components/DrawerHeader';
import { DrawerLoadingSpinner } from '@builder/pages/Profiles/UniversalProfileDrawer/components/DrawerLoadingSpinner';
import { AccountTypeTag } from './components/AccountTypeTag';
import { CompanyNameHeader } from './components/CompanyNameHeader';
import { MatchingNetworkCompanyInfoBanner } from './components/MatchingNetworkCompanyInfoBanner';
import { AddressBookCompanyToolbar } from './components/Toolbars/AddressBookCompanyToolbar';
import { useIsNetworkConnectionsEnabled } from '@builder/context/AccountProvider/hooks';
import { BuilderNetworkCompanyFragment } from './providers/UniversalProfileProvider/queries.generated';
import { AccountType } from '@ascension/_gqltypes/builder.generated';
import styles from './styles.scss';

export const AddressBookCompanyDrawerContent = ({
  networkCompany,
  hasProcurementPhaseFeature,
}: {
  readonly networkCompany: BuilderNetworkCompanyFragment;
  readonly hasProcurementPhaseFeature: boolean;
}) => {
  const isNetworkConnectionsEnabled = useIsNetworkConnectionsEnabled();
  const accountType = AccountType.SUBCONTRACTOR;
  const companyName = networkCompany.name;
  const shortAddress = networkCompany.defaultOffice.addressLocality;
  const companyId = Number(networkCompany.id);

  // tell Jquery that it's time to render the body into the specified div
  useEffect(() => {
    if (companyId) {
      // This event will cause the jQuery code in vcard_slider.js to make a call to Ascension to
      // render the twig template with the tab container, and then inject that rendered HTML into
      // the container below. It needs to happen after this component has finished rendering.
      const eventData: DrawerEvent = {
        detail: {
          companyId,
        },
      };
      document.dispatchEvent(new CustomEvent(UNIVERSAL_PROFILE_DRAWER_READY_EVENT, eventData));
    }
  }, [companyId]);

  return (
    <>
      <DrawerToolbar>
        <AddressBookCompanyToolbar
          networkCompany={networkCompany}
          hasProcurementPhaseFeature={hasProcurementPhaseFeature}
        />
      </DrawerToolbar>
      <DrawerBody className={joinClassNames(styles.noPadding, styles.drawerBodyContainer)}>
        {isNetworkConnectionsEnabled && <MatchingNetworkCompanyInfoBanner companyId={companyId} />}
        <CompanyHeader>
          <CompanyInfo>
            <CompanyNameLocation>
              <CompanyNameHeader companyName={companyName} />
              {shortAddress ? <div>{shortAddress}</div> : null}
            </CompanyNameLocation>
            <AccountStatusColumn>
              {accountType ? <AccountTypeTag accountType={accountType} editable /> : null}
            </AccountStatusColumn>
          </CompanyInfo>
        </CompanyHeader>
        <div
          data-testid={UNIVERSAL_PROFILE_DRAWER_TAB_CONTAINER_ID}
          id={UNIVERSAL_PROFILE_DRAWER_TAB_CONTAINER_ID}
          data-company-id={companyId}
          className={styles.drawerTabContainer}
        >
          <DrawerLoadingSpinner />
        </div>
      </DrawerBody>
    </>
  );
};
