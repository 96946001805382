import Routing from 'routing';
import { Button, ButtonSize, ButtonVariant, UploadIcon } from '@estimateone/frontend-components';
import { QuoteReturnRoute } from '@ascension/enums';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

type AddQuoteButtonProps = {
  projectId: EntityId;
  packageId: EntityId;
  stageId: EntityId;
};

const AddQuoteButton = ({ projectId, packageId, stageId }: AddQuoteButtonProps) => {
  const addQuoteRedirectPath = Routing.generate('app_quote_addtoproject', {
    projectId,
    returnTo: QuoteReturnRoute.ProjectSliderSingleStage,
    packageId,
    stageId,
  });

  return (
    <Button variant={ButtonVariant.Transparent} size={ButtonSize.Small} href={addQuoteRedirectPath}>
      <UploadIcon className={styles.uploadIcon} />
      Add quote
    </Button>
  );
};

export default AddQuoteButton;
