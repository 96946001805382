import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { E1Link } from '@estimateone/frontend-components';
import { useKnowledgeBaseArticle } from '@ascension/components/localisation';
import { Action, useInteractAnalytics } from '@ascension/components/hooks/Analytics';
import { GET_MATCHING_PROFILES } from '@subbie-directory/queries';
import { EntityId } from '@ascension/types';
import {
  SearchMatchingProfiles,
  SearchMatchingProfilesVariables,
} from '@subbie-directory/types/SearchMatchingProfiles';
import styles from './styles.scss';

export const MATCHING_COMPANY_INFO_BANNER_ID = 'matching-company-info-banner';

export const MatchingAddressBookCompanyInfoBanner = ({ accountId }: { accountId: EntityId }) => {
  const { data } = useQuery<SearchMatchingProfiles, SearchMatchingProfilesVariables>(
    GET_MATCHING_PROFILES,
    {
      variables: {
        search: {
          accountId: accountId.toString(),
        },
      },
    },
  );

  const { addEvent } = useInteractAnalytics();
  const searched = data?.searchMatchingProfiles.searched ?? null;
  const matchingProfiles = data?.searchMatchingProfiles.results ?? null;
  const knowledgeBaseArticle = useKnowledgeBaseArticle();

  useEffect(() => {
    if (matchingProfiles) {
      addEvent({
        action: Action.SUBBIE_MATCHING_PROFILES_FOUND,
        matchesFound: matchingProfiles.length,
        accountId,
      });
    }
  }, [matchingProfiles, accountId, addEvent]);

  if (!searched || !matchingProfiles || matchingProfiles.length === 0) {
    return null;
  }

  return (
    <div className={styles.bannerContainer} data-testid={MATCHING_COMPANY_INFO_BANNER_ID}>
      <div className={styles.textContainer}>
        <p className={styles.mainText}>
          Matching company found in your Address Book. Connect to review the match.
        </p>
        <E1Link
          className={styles.learnMoreLink}
          target="_blank"
          href={knowledgeBaseArticle('matchingNetworkCompanyHelp')}
        >
          Learn more about connecting with the E1 Network
        </E1Link>
      </div>
    </div>
  );
};
