import React, { useContext } from 'react';
import { Button, ButtonVariant, FilterIcon } from '@estimateone/frontend-components';
import { FilterChips } from '@builder/features/SubbieNetworkInvitations/components/FilterActionsBar/FilterChips';
import FilterDrawer from '@builder/features/SubbieNetworkInvitations/components/FilterActionsBar/FilterDrawer';
import TradeFilter from '@builder/features/SubbieNetworkInvitations/components/TradeFilter';
import { getTradeOptionsFromStageTradeList } from '@builder/features/SubbieNetworkInvitations/utils';
import { NetworkSearchContext } from '@builder/features/SubbieNetworkInvitations/context/NetworkSearchProvider';
import { GetNetworkInvitesStage_stage_UnrestrictedStage as Stage } from '@builder/features/SubbieNetworkInvitations/hooks/types/GetNetworkInvitesStage';
import styles from './styles.module.scss';

const FilterActionsBar = ({ trades }: { trades: Stage['trades'] }) => {
  const stageTradeOptions = getTradeOptionsFromStageTradeList(trades);

  const { onResetFilters, onOpenFilterDrawer, tradeId, setTradeId } =
    useContext(NetworkSearchContext);

  return (
    <div className={styles.filterSection}>
      <div>
        <TradeFilter
          stageTradeOptions={stageTradeOptions}
          tradeId={tradeId}
          onSelectTrade={setTradeId}
        />
      </div>
      <div className={styles.filterButtons}>
        <Button
          variant={ButtonVariant.Grey}
          onClick={onOpenFilterDrawer}
          className={styles.buttonWithIcon}
          aria-label="All Filters"
        >
          <FilterIcon className={styles.filterIcon} />
          All Filters
        </Button>
        <Button variant={ButtonVariant.Transparent} onClick={onResetFilters}>
          Reset
        </Button>
      </div>
      <div className={styles.chips}>
        <FilterChips onFilterChipClicked={onOpenFilterDrawer} />
      </div>

      <FilterDrawer stageTradeOptions={stageTradeOptions} />
    </div>
  );
};

export { FilterActionsBar };
