import { Dispatch, SetStateAction, useMemo } from 'react';
import {
  CellProps,
  Checkbox,
  CheckboxStatus,
  Table,
  TableColumn,
} from '@estimateone/frontend-components';
import { DisplayUser } from '../types';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

type MemberListProps = {
  memberList: DisplayUser[];
  setSelectedMemberIds: Dispatch<SetStateAction<EntityId[]>>;
  selectedMemberIds: EntityId[];
};

const MemberList = ({
  memberList = [],
  setSelectedMemberIds,
  selectedMemberIds,
}: MemberListProps) => {
  const allSelected = useMemo(
    () => memberList.length !== 0 && selectedMemberIds.length === memberList.length,
    [selectedMemberIds],
  );

  const toggleSelectAll = () => {
    if (allSelected) {
      setSelectedMemberIds([]);
    } else {
      setSelectedMemberIds(memberList.map((member) => member.id));
    }
  };

  const toggleSelectMember = (id: EntityId) => {
    if (selectedMemberIds.includes(id)) {
      setSelectedMemberIds((current) => current.filter((memberId) => memberId !== id));
    } else {
      setSelectedMemberIds((current) => [...current, id]);
    }
  };

  const columns: TableColumn<DisplayUser>[] = [
    {
      id: 'selected',
      Header: () => (
        <Checkbox
          className={styles.masterCheckbox}
          id="selectAll"
          label="Select All"
          hideLabel
          statusCurrent={allSelected ? CheckboxStatus.Checked : CheckboxStatus.Unchecked}
          onClick={toggleSelectAll}
        />
      ),
      Cell: ({ row: { original: member } }: CellProps<DisplayUser>) => (
        <Checkbox
          id={`member-${member.id}`}
          label="Select Member"
          hideLabel
          statusCurrent={
            selectedMemberIds.includes(member.id)
              ? CheckboxStatus.Checked
              : CheckboxStatus.Unchecked
          }
          onClick={() => toggleSelectMember(member.id)}
        />
      ),
    },
    {
      id: 'name',
      Header: 'Name',
      accessor: 'fullName',
    },
    {
      id: 'email',
      Header: 'Email',
      accessor: 'email',
    },
    {
      id: 'phone',
      Header: 'Phone',
      accessor: 'phone',
    },
  ];

  return (
    <div className={styles.addMemberTableContainer}>
      <p>Get in touch with an administrator to add any missing standard users.</p>
      <h3>Users</h3>
      {memberList?.length > 0 ? (
        <Table<DisplayUser> columns={columns} data={memberList} initSortColumnId="name" />
      ) : (
        <p>There are no more members to add</p>
      )}
    </div>
  );
};

export default MemberList;
