import Routing from 'routing';
import TokenCache from '@api/GatewayProvider/TokenCache';
import { sharepointPlugin } from '../DocSyncPlugin/SharepointPlugin';
import { getStageTypeAsString, StageType } from '@ascension/enums';
import { EntityId } from '@ascension/types';

export const connectToSharepoint = async (
  stageId: EntityId,
  stageType: StageType,
  docsyncAuthHost: string,
) => {
  const stageUrl = Routing.generate('app_stage_documents', {
    id: stageId,
    stageType: getStageTypeAsString(stageType)?.toLowerCase(),
  });
  const redirectUri = `${window.location.protocol}//${window.location.host}${stageUrl}?openDocSyncSlider=${sharepointPlugin.id}`;
  const idpUrl = Routing.generate('gateway_jwt_authorise');
  const tokenCache = new TokenCache(idpUrl);

  const response = await fetch(`${docsyncAuthHost}/claims`, {
    headers: {
      Authorization: `Bearer ${await tokenCache.getToken()}`,
    },
  });

  if (response.ok) {
    const { claims }: { claims: string } = await response.json();

    const authUrl = `${docsyncAuthHost}/sharepoint/auth?redirectUri=${redirectUri}&claims=${claims}`;

    window.location.assign(authUrl);
  }
};
