import Masonry from 'react-masonry-css';
import { Checkbox, CheckboxStatus } from '@estimateone/frontend-components';
import { SubTrade } from './hooks';
import { EntityId } from '../../../../types';
import styles from './styles.scss';

type OtherSubTradesCategorisedListProps = {
  subTrades: SubTrade[];
  selectedSubTradeIds: EntityId[];
  onCheckBoxClick: (id: EntityId) => void;
  userSubTradeIds?: EntityId[];
};

const OtherSubTradesCategorisedList = ({
  subTrades,
  selectedSubTradeIds,
  onCheckBoxClick,
  userSubTradeIds = [],
}: OtherSubTradesCategorisedListProps) => {
  const subTradesByStockTradeWithMatchedSubTradesRemoved = subTrades.reduce((acc, subTrade) => {
    subTrade.stockTrades.forEach((stockTrade) => {
      const current = acc.get(stockTrade.name) || [];
      acc.set(
        stockTrade.name,
        current.concat(userSubTradeIds.includes(subTrade.id) ? [] : [subTrade]),
      );
    });

    return acc;
  }, new Map<string, SubTrade[]>());

  return (
    <Masonry
      breakpointCols={2}
      className={styles.otherSubTradesContainer}
      columnClassName={styles.subTradesMasonryColumn}
    >
      {Array.from(
        subTradesByStockTradeWithMatchedSubTradesRemoved,
        ([stockTradeName, matchedSubTrades]) =>
          matchedSubTrades.length !== 0 && (
            <div key={stockTradeName}>
              <br />
              <h5>{stockTradeName}</h5>
              <ul style={{ listStyle: 'none' }}>
                {matchedSubTrades.map((subTrade) => (
                  <li key={subTrade.id}>
                    <Checkbox
                      id={subTrade.id.toString()}
                      label={subTrade.name}
                      statusCurrent={
                        selectedSubTradeIds.includes(subTrade.id)
                          ? CheckboxStatus.Checked
                          : CheckboxStatus.Unchecked
                      }
                      onClick={() => onCheckBoxClick(subTrade.id)}
                    />
                  </li>
                ))}
              </ul>
            </div>
          ),
      )}
    </Masonry>
  );
};

export default OtherSubTradesCategorisedList;
