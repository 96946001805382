import { MouseEvent, KeyboardEvent } from 'react';
import { Slider } from '@estimateone/frontend-components';
import { formatDistance } from 'date-fns';
import { CompanyInSliderForSSC } from '@profiles/apps/CompanyProfile';
import { TranslationProvider } from '@profiles/providers/TranslationsProvider';
import { SliderBackButton } from '@subbie/modal/ProjectSlider/SliderBackButton';
import LoadingSpinnerFloating from '@shared/LoadingSpinner/LoadingSpinnerFloating';
import { useModalContext } from '@subbie/context/ModalContext';
import { useGetSubbieProfileForAccount } from '../hooks';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

type ProfilesHeaderProps = {
  name: string;
  memberSince: string | null;
  lastActiveAt: string | null;
};

const SubbieProfilesSliderHeader = ({ name, memberSince, lastActiveAt }: ProfilesHeaderProps) => {
  const formatDate = (dateString?: string): string =>
    dateString
      ? formatDistance(new Date(dateString), new Date(), { addSuffix: true, includeSeconds: false })
      : '';
  return (
    <div>
      <h2>{name}</h2>
      <div className={styles.summaryRow}>
        {memberSince && <span>Joined E1:&nbsp;{formatDate(memberSince)}</span>}
        {lastActiveAt && <span>&nbsp;| Last Active:&nbsp;{formatDate(lastActiveAt)}</span>}
      </div>
    </div>
  );
};

type SubbieProfileSliderViewProps = {
  accountId: EntityId;
  handleClose: ((event: MouseEvent | KeyboardEvent) => void) & (() => void);
};

const SubbieProfileSliderView = ({ accountId, handleClose }: SubbieProfileSliderViewProps) => {
  const { data } = useGetSubbieProfileForAccount(accountId);
  const profile = data?.companyProfile;

  if (!profile) return <LoadingSpinnerFloating />;

  return (
    <>
      <SliderBackButton handleClose={handleClose} />
      <div className={styles.headerContainer}>
        <SubbieProfilesSliderHeader
          name={profile.name ?? ''}
          lastActiveAt={profile.lastActiveAt}
          memberSince={profile.memberSince}
        />
      </div>
      <div className={styles.profileContentStyle}>
        <TranslationProvider>
          <CompanyInSliderForSSC profile={profile} />
        </TranslationProvider>
      </div>
    </>
  );
};

type SubbieProfileSliderProps = {
  isOpen: boolean;
  accountId: EntityId;
  handleClose: ((event: MouseEvent | KeyboardEvent) => void) & (() => void);
};

export const SubbieProfileSlider = ({
  accountId,
  isOpen,
  handleClose,
}: SubbieProfileSliderProps) => {
  const { removeModal } = useModalContext();
  const closeParentAndSelf = () => {
    handleClose();
    removeModal();
  };
  return (
    <Slider isOpen={isOpen} onRequestClose={closeParentAndSelf}>
      <SubbieProfileSliderView accountId={accountId} handleClose={handleClose} />
    </Slider>
  );
};
