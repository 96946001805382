import { useEffect, useRef } from 'react';
import { MicroFrontendLoader } from '../../../shared/MicroFrontend';
import { EntityId } from '@ascension/types';

export type ScopesUploadModalProps = {
  scopesUiUrl: string | null;
  packageId: EntityId;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

export const ScopesUploadModal = ({
  packageId,
  scopesUiUrl,
  isOpen,
  onClose,
  onSuccess,
}: ScopesUploadModalProps) => {
  const scopesUploadElRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const uploadModal = scopesUploadElRef.current;
    // event listener passes an event param. But these callbacks need 0 params. So we're wrapping them in callback functions
    const onCloseCb = () => onClose();
    const onSuccessCb = () => onSuccess();

    if (uploadModal) {
      // Subscribe to the MF events
      uploadModal.addEventListener('close', onCloseCb);
      // MF does the mutation, so Ascension needs to refetch queries to get the latest SOW state
      uploadModal.addEventListener('uploadSuccess', onSuccessCb);
    }

    // cleanup function
    return () => {
      if (uploadModal) {
        uploadModal.removeEventListener('close', onCloseCb);
        uploadModal.removeEventListener('uploadSuccess', onSuccessCb);
      }
    };
  }, [onSuccess, onClose]);

  const scopesMicrofrontendUploadUrl = () => `${scopesUiUrl}/micro-frontend/upload-mf.umd.js`;

  return (
    <micro-frontend-loader src={scopesMicrofrontendUploadUrl()}>
      <scopes-upload-mf
        ref={scopesUploadElRef}
        package-id={packageId.toString()}
        open={`${isOpen}`}
      />
    </micro-frontend-loader>
  );
};

if (!customElements.get('micro-frontend-loader')) {
  customElements.define('micro-frontend-loader', MicroFrontendLoader);
}
