import { useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { ActionMenu, Ellipsis, IconButton, IconButtonSize } from '@estimateone/frontend-components';
import { DeleteOfficeConfirmation } from '../DeleteOfficeConfirmation';

export type ActionableOfficeListItemProps = {
  id: number;
  name: string;
  defaultOfficeId: GraphQLEntityId;
};

export const ActionableOfficeListItem = ({
  id,
  name,
  defaultOfficeId,
}: ActionableOfficeListItemProps) => {
  const [isDeleteModelOpen, toggleDeleteModal] = useReducer((curr: boolean) => !curr, false);
  const navigate = useNavigate();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <>
      <DeleteOfficeConfirmation
        id={id}
        name={name}
        isOpen={isDeleteModelOpen}
        closeModal={toggleDeleteModal}
      />
      <ActionMenu
        visible={isMenuOpen}
        onClickOutside={() => setIsMenuOpen(false)}
        triggerElement={
          <IconButton
            icon={<Ellipsis />}
            title="Toggle Office Options Menu"
            size={IconButtonSize.Small}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            aria-label="Toggle Office Options Menu"
            aria-expanded={isMenuOpen}
          />
        }
      >
        <ActionMenu.Item
          onClick={() => {
            setIsMenuOpen(false);
            navigate(`offices/${id}/edit`);
          }}
        >
          Edit
        </ActionMenu.Item>
        {defaultOfficeId !== id && (
          <ActionMenu.Item
            onClick={() => {
              setIsMenuOpen(false);
              toggleDeleteModal();
            }}
          >
            Delete
          </ActionMenu.Item>
        )}
      </ActionMenu>
    </>
  );
};
