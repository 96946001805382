import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Routing from 'routing';
import { Slider } from '@estimateone/frontend-components';
import qs from 'query-string';
import RedactedSliderHeader from '@subbie/modal/ProjectSlider/ProjectSliderHeader/RedactedSliderHeader';
import { handlePaywallAnalyticsEvent, trackingToken } from '../const';
import SubblierRoadblockAdditionalFeatures from './SubblierRoadblockAdditionalFeatures';
import { fireAndForget } from '@shared/Util/async';
import { useCurrentUser } from '@subbie/context/AccountProvider/hooks';
import { Action, Event, useAnalytics } from '@ascension/components/hooks/Analytics';
import { ProductType } from '@ascension/enums';
import { AnalyticsTrackedParam } from '@ascension/enums/Analytics';
import { AccountType, ProjectRedactedReason } from '@ascension/_gqltypes/subbie.generated';
import { PaywalledProject } from '@subbie/modal/ProjectSlider/types';
import styles from './styles.scss';

const getUpgradeUrl = (reason: ProjectRedactedReason) => {
  const originalQueryStringParts = qs.parse(window.location.search);

  return Routing.generate('app_accountupgrade_index', {
    ...originalQueryStringParts,
    [AnalyticsTrackedParam.IN_APP_SOURCE]: trackingToken[reason],
  });
};

type ProjectSliderProps = {
  project: PaywalledProject;
  removeModal: () => void;
  reason: ProjectRedactedReason;
};

const ProjectPaywall = ({ project, removeModal, reason }: ProjectSliderProps) => {
  const [isPaneOpen, setIsPaneOpen] = useState(true);
  const { addEvent } = useAnalytics(Event.INTERACT);
  const {
    id: projectId,
    requiredProductForRoadblock,
    requireAdditionalLicense,
    address,
    hasDocs,
  } = project;

  const user = useCurrentUser();

  const productName =
    user?.account?.type === AccountType.SUPPLIER
      ? ProductType.SupplierStandard
      : ProductType.SubbieEssential;
  useEffect(() => {
    Modal.setAppElement('body');

    const handleOpenCloseAnalyticsEvent = async (isOpen: boolean) =>
      addEvent({
        action: isOpen ? Action.PROJECT_SLIDER_OPEN : Action.PROJECT_SLIDER_CLOSE,
        projectId,
        projectRedactedReason: reason,
        hasDocs: hasDocs ?? false,
        variation: 'additionalFeatures',
      });

    fireAndForget(() =>
      Promise.all([handlePaywallAnalyticsEvent(projectId), handleOpenCloseAnalyticsEvent(true)]),
    );
    return function closeEvent() {
      fireAndForget(() => handleOpenCloseAnalyticsEvent(false));
    };
  }, [addEvent, projectId]);

  if (!user) return null;

  const handleClose = () => {
    setIsPaneOpen(false);
    removeModal();
  };

  // TODO: the API returning null for these makes no sense
  const requiredState = requiredProductForRoadblock?.shortStates ?? 'unknown state';
  const projectState = address?.state?.shortName ?? 'unknown state';

  return (
    <Slider rootAppElement="#root" isOpen={isPaneOpen} onRequestClose={handleClose}>
      <div className={styles.roadblockSlider}>
        <RedactedSliderHeader user={user} project={project} />
        <div className={styles.redactedProjectDetails} />
        <div className={styles.roadblockContainer}>
          <SubblierRoadblockAdditionalFeatures
            productName={productName}
            reason={reason}
            removeModal={handleClose}
            upgradeUrl={getUpgradeUrl(reason)}
            requireAdditionalLicenseShortState={requiredState}
            requireAdditionalLicense={requireAdditionalLicense}
            projectState={projectState}
            userPhone={user.phone ?? undefined}
            project={project}
          />
        </div>
      </div>
      <div className={styles.roadblockSliderOverlay} />
    </Slider>
  );
};

export default ProjectPaywall;
