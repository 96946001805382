import { useState } from 'react';
import { Icon, IconName, LoadingSpinner } from '@estimateone/frontend-components';
import {
  Tree,
  TreeEventNodeEvent,
  TreeSelectionEvent,
  TreeTogglerTemplateOptions,
} from 'primereact/tree';
// eslint-disable-next-line import/no-unresolved
import { TreeNode } from 'primereact/treenode';
import { v4 as uuidV4 } from 'uuid';
import { useLazyGetDriveItems } from '@builder/features/DocSyncIntegration/Sharepoint/SliderSteps/hooks/useGetDriveItems';
import { BaseFolder, SharePointDriveItem } from '../types';
import { SharePointDriveItemType } from '@ascension/_gqltypes/builder.generated';
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import styles from './styles.scss';

type DriveItemSelectionProps = {
  driveId: string;
  rootDriveItems: SharePointDriveItem[];
  selectedBaseFolder?: BaseFolder;
  onSelectionChange: (selectedBaseFolder?: BaseFolder) => void;
};

// eslint-disable-next-line react/no-unused-prop-types
type TreeNodePlusDriveItemData = TreeNode & { data: SharePointDriveItem };

export const BaseFolderSelection = ({
  driveId,
  rootDriveItems,
  selectedBaseFolder,
  onSelectionChange,
}: DriveItemSelectionProps) => {
  const mappedDriveItemNodes: TreeNodePlusDriveItemData[] = [
    {
      key: 'root',
      label: 'All files & folders',
      children: [
        ...rootDriveItems
          .filter(({ type }) => type === SharePointDriveItemType.FOLDER)
          .map((item) => ({
            key: item.id,
            label: item.name,
            children: [],
            data: { ...item },
            leaf: false,
          })),
      ],
      data: {
        id: 'root',
        name: 'All files & folders',
        __typename: 'SharePointDriveItem',
        type: SharePointDriveItemType.FOLDER,
      },
      expanded: true,
      leaf: false,
    },
  ];

  const [currentNodes, setCurrentNodes] =
    useState<TreeNodePlusDriveItemData[]>(mappedDriveItemNodes);
  const [expandLoading, setExpandLoading] = useState({ loading: false, key: '' });
  const { handleLazyQuery } = useLazyGetDriveItems(driveId);

  const appendExpandedFolderChildrenRecursively = (
    nodes: TreeNodePlusDriveItemData[],
    key: string,
    newChildren: TreeNodePlusDriveItemData[],
  ) =>
    nodes.map((node): TreeNodePlusDriveItemData => {
      const hasFoundNodeToAppendChildrenTo = node.key === key;
      if (hasFoundNodeToAppendChildrenTo) {
        const hasNestedFolders = newChildren.some(
          (n) => n.data.type === SharePointDriveItemType.FOLDER,
        );

        if (hasNestedFolders) {
          return { ...node, children: newChildren, leaf: false };
        }

        const dummyNodeNoChildren = [
          {
            key: `${key}-dummy`,
            label: 'No selectable folders',
            leaf: true,
            selectable: false,
            checked: false,
            data: { id: uuidV4() },
          },
        ];
        return { ...node, children: dummyNodeNoChildren, leaf: false };
      }

      if (node.children) {
        return {
          ...node,
          children: appendExpandedFolderChildrenRecursively(
            node.children as TreeNodePlusDriveItemData[],
            key,
            newChildren,
          ),
        };
      }

      return node;
    });

  const flattenNodesToDrives = (nodes: TreeNodePlusDriveItemData[]) => {
    const flatDriveItems: SharePointDriveItem[] = [];

    const traverse = (node: TreeNodePlusDriveItemData) => {
      const nodeKey = node.key as string;
      if (nodeKey.endsWith('dummy')) {
        return;
      }

      flatDriveItems.push(node.data);

      // Recursively traverse the children
      const children = node.children as TreeNodePlusDriveItemData[];
      if (children) {
        children.forEach((child) => {
          traverse(child);
        });
      }
    };

    nodes.forEach((node) => traverse(node));

    return flatDriveItems;
  };

  const findPathToNode = (nodes: TreeNode[], nodeId: string): SharePointDriveItem[] =>
    nodes.flatMap((node) => {
      if (node.data.id === nodeId) {
        return [node.data];
      }
      if (node.children) {
        const found = findPathToNode(node.children, nodeId);
        if (found.length > 0) {
          return [node.data, ...found];
        }
      }
      return [];
    });

  const buildPath = (tree: TreeNodePlusDriveItemData[], nodeId: string) => {
    const directoryPathToNode = findPathToNode(tree, nodeId);

    const isRoot = directoryPathToNode.length === 1;

    if (isRoot) {
      return directoryPathToNode.at(0)!.name;
    }

    return directoryPathToNode
      .filter(({ id }) => id !== 'root')
      .map((item) => item.name)
      .join(' / ');
  };

  const handleSelection = (event: TreeSelectionEvent) => {
    const flattenedDriveItems = flattenNodesToDrives(currentNodes);
    const selectedDriveItem = flattenedDriveItems.find((item) => item.id === event.value);
    if (!selectedDriveItem) {
      onSelectionChange(undefined);
      return;
    }

    onSelectionChange({
      driveItemId: selectedDriveItem.id,
      path: buildPath(currentNodes, selectedDriveItem.id),
    });
  };

  const handleExpand = async (event: TreeEventNodeEvent) => {
    const folderId = String(event.node.key);
    setExpandLoading({ loading: true, key: folderId });
    const result = await handleLazyQuery(folderId);
    if (result) {
      const childDriveItemNodes = result.map<TreeNodePlusDriveItemData>((item) => ({
        key: item.id,
        label: item.name,
        children: [],
        leaf: false,
        data: { ...item },
      }));

      setCurrentNodes((prevState) =>
        appendExpandedFolderChildrenRecursively(prevState, folderId, childDriveItemNodes),
      );
    }
    setExpandLoading({ loading: false, key: '' });
  };

  const Toggler = (
    { key }: TreeNodePlusDriveItemData,
    { onClick, props }: TreeTogglerTemplateOptions,
  ) => {
    if (expandLoading.key === key) {
      return (
        <div className={styles.loadingSpinnerContainer}>
          <LoadingSpinner center={false} />
        </div>
      );
    }

    const expandedKeys = props?.expandedKeys;
    const isExpanded = !expandedKeys ? false : Object.keys(expandedKeys).includes(key as string);

    const chevronDirection = isExpanded === true ? IconName.ChevronDown : IconName.ChevronRight;
    return (
      <button
        type="button"
        onClick={(event) => onClick(event)}
        className={styles.togglerButton}
        data-dummy={String(key).endsWith('dummy')}
      >
        <Icon name={chevronDirection} />
      </button>
    );
  };

  return (
    <div>
      <div className={styles.driveItemsDescription}>
        <p>Select base folder for SharePoint (this can not be changed later)</p>
      </div>
      <Tree
        pt={{
          root: { className: styles.root },
          node: { className: styles.node },
          content: { className: styles.content },
          subgroup: { className: styles.subgroup },
        }}
        nodeTemplate={(node) => (
          <span data-dummy={String(node.key).endsWith('dummy')}>{node.label}</span>
        )}
        togglerTemplate={Toggler}
        value={currentNodes}
        selectionMode="single"
        selectionKeys={selectedBaseFolder?.driveItemId}
        onSelectionChange={handleSelection}
        onExpand={handleExpand}
        unstyled
      />
    </div>
  );
};
