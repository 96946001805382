import { gql } from '@apollo/client';

export const UPDATE_ABOUT_US = gql`
  mutation UpdateAboutUs($aboutUs: String!) {
    updateAboutUs(aboutUs: $aboutUs) {
      ... on Profile {
        id
        aboutUs
        updatedAt
        updatedById
      }
      ... on Errors {
        errors {
          field
          message
        }
      }
    }
  }
`;
