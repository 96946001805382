import { createContext, ReactNode, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { reportError } from '../../../helpers/errorReporter';
import { GET_CREDIT_ALLOWANCE } from './queries';
import { CreditAllowanceContextQuery } from './types/CreditAllowanceContextQuery';

export type CreditAllowanceContextProps = {
  loading: boolean;
  litePlanCreditAllowance: number | undefined;
};

export const CreditAllowanceContext = createContext<CreditAllowanceContextProps | undefined>(
  undefined,
);

const { Provider } = CreditAllowanceContext;

const CreditAllowanceProvider = ({ children }: { children?: ReactNode }) => {
  const [loading, setLoading] = useState(true);
  const [litePlanCreditAllowance, setCreditAllowance] = useState<number>();

  const {
    loading: queryLoading,
    data,
    error,
  } = useQuery<CreditAllowanceContextQuery>(GET_CREDIT_ALLOWANCE);

  useEffect(() => {
    if (queryLoading) setLoading(true);
    if (!data || queryLoading) return;

    const { getCreditAllowanceForLiteTier } = data;
    setCreditAllowance(getCreditAllowanceForLiteTier);

    setLoading(false);
  }, [queryLoading, data]);

  if (error) {
    reportError(error);
    return null;
  }

  const value: CreditAllowanceContextProps = {
    loading,
    litePlanCreditAllowance,
  };

  return <Provider value={value}>{children}</Provider>;
};

export default CreditAllowanceProvider;
