import { Tag, TagVariant } from '@estimateone/frontend-components';
import { ConnectionPreferenceType } from '@ascension/_gqltypes/subbie.generated';
import styles from './styles.scss';

type ProjectConnectionPreferenceTypeProps = {
  type: ConnectionPreferenceType;
};

const tagTypes = {
  equivalent: {
    text: 'Equivalent',
    variant: TagVariant.Practical200,
  },
  specified: {
    text: 'Specified',
    variant: TagVariant.Magic200,
  },
} as const;

type TagType = keyof typeof tagTypes;

export const ProjectConnectionPreferenceType = ({ type }: ProjectConnectionPreferenceTypeProps) => {
  const showEquivalent = [
    ConnectionPreferenceType.EQUIVALENT,
    ConnectionPreferenceType.SPECIFIED_AND_EQUIVALENT,
  ].includes(type);
  const showSpecified = [
    ConnectionPreferenceType.SPECIFIED,
    ConnectionPreferenceType.SPECIFIED_AND_EQUIVALENT,
  ].includes(type);

  const renderTag = (tagType: TagType) => (
    <Tag
      className={styles.connectionPreferenceTypeTag}
      text={tagTypes[tagType].text}
      variant={tagTypes[tagType].variant}
    />
  );

  return (
    <div className={styles.connectionPreferenceTypeContainer}>
      {showEquivalent && renderTag('equivalent')}
      {showSpecified && renderTag('specified')}
    </div>
  );
};
