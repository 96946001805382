import { isMatch, parse, parseISO } from 'date-fns';

export const getInputById = (id: string): { value: HTMLInputElement['value'] | null } => {
  const input = document.getElementById(id);
  return input instanceof HTMLInputElement ? input : { value: null };
};

/**
 * Number inputs return empty string instead of null, so we turn these into null values
 */
export const normaliseNumberInput = (value: unknown) =>
  value === '' || value === null ? null : parseInt(String(value));

export const normaliseDateInput = (value: unknown) => {
  if (typeof value === 'string' && value !== '') {
    const date = isMatch(value, 'dd MMM yyyy')
      ? parse(value, 'dd MMM yyyy', new Date())
      : parseISO(value);

    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  }

  return null;
};
