import { gql } from '@apollo/client';
import Quotes from '../../../../../api/fragments/Quotes';

export const RFQS_FOR_PACKAGE = gql`
  query RfqsForPackage($packageId: EntityId!) {
    package(id: $packageId) {
      rfqs {
        id
        contact {
          id
        }
        user {
          id
        }
        quotes {
          id
          revisingQuote {
            id
          }
          deletedAt
        }
        ...RfqTimestampFields
      }
    }
  }
  ${Quotes.fragments.rfqTimestampFields}
`;

export const SEARCH_COMPANIES = gql`
  query SearchCompaniesToAssignQuote($query: String!) {
    searchCompanyNames(query: $query) {
      id
      name
    }
  }
`;

export const CONTACTS_FOR_COMPANY = gql`
  query GetContactsForCompanyToAssignQuote($id: EntityId!) {
    company(id: $id) {
      id
      activeContacts {
        id
        fullName
        user {
          id
        }
        types {
          id
          type
        }
        approvedAt
      }
    }
  }
`;
