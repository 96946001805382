import { memo } from 'react';
import { ContactCheckbox } from '@builder/features/SubbieNetworkInvitations/components/CompaniesResults/ContactCheckbox/ContactCheckbox';
import { ProfileSnapshot } from '@builder/features/SubbieNetworkInvitations/components/CompaniesResults/ProfileSnapshot';
import { ID, NetworkCompany } from '@builder/common/SubbieNetwork/types';

type CompanyListItemProps = {
  company: NetworkCompany;
  toggleContactSelection: (contactId: ID, isChecked: boolean) => void;
  invitedContacts: ID[];
  selectedContacts: ID[];
};

const propsAreEqual = (prevProps: CompanyListItemProps, nextProps: CompanyListItemProps) =>
  prevProps.company.id === nextProps.company.id &&
  prevProps.selectedContacts.length === nextProps.selectedContacts.length &&
  prevProps.invitedContacts.length === nextProps.invitedContacts.length;

export const CompanyListItem = ({
  company,
  invitedContacts,
  selectedContacts,
  toggleContactSelection,
}: CompanyListItemProps) => (
  <ProfileSnapshot company={company}>
    {company.contacts.map((contact) => {
      const isInvited = invitedContacts.indexOf(Number(contact.id)) !== -1;
      const isSelected = selectedContacts.indexOf(contact.id) !== -1;

      return (
        <ContactCheckbox
          key={`contact-checkbox-${company.id}::${contact.id}`}
          contact={contact}
          isSelected={isSelected || isInvited}
          onSelectionChange={(isChecked: boolean) => {
            toggleContactSelection(contact.id, isChecked);
          }}
          disabled={isInvited}
        />
      );
    })}
  </ProfileSnapshot>
);

export default memo(CompanyListItem, propsAreEqual);
