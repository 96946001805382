import $ from 'jquery';
import Routing from 'routing';
import E1Request from '../classes/E1Request';

$(() => {
  const $body = $('body');

  $body.on('click', '.delete-addendum-trigger', (e) => {
    const addendumId = $(e.currentTarget).attr('data-addendum');
    if (typeof addendumId !== 'undefined') {
      const route = Routing.generate('app_stageaddendum_deletemodal', { id: addendumId });
      const req = new E1Request(route, 'GET');
      req.show_loading_modal = true;
      req.submit();
    }
  });

  $body.on('form-submitted-success', 'form.delete-addendum-form', (event, data) => {
    const $addendumTable = $('table.addendumTable');
    if ($addendumTable.length > 0) {
      if (data != null) {
        const deleted = JSON.parse(data.addendum);
        const $row = $addendumTable.find(`tr[data-addendum-id="${deleted.id}"]`);
        if ($row.length > 0) {
          $row.remove();
        }
      }
    }
  });
});
