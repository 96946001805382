import $ from 'jquery';
import Routing from 'routing';
import E1Request from '../classes/E1Request';
import Modal from '../classes/Modal';

$(() => {
  $('body').on('click', 'a[data-trigger="export-matrix"]', (clickEvent) => {
    const $trigger = $(clickEvent.currentTarget);
    const stageId: number = $trigger.data('stage-id');

    return new E1Request(Routing.generate('app_stagematrix_export', { id: stageId })).submit();
  });

  $(document).on('mfpOpen', () => {
    const magnificPopupInstance = $.magnificPopup.instance;
    const container = magnificPopupInstance.contentContainer;
    const $modal = $(container);
    const $exportDiv = $modal.find('.pending_matrix_export_notice');

    if (!$exportDiv.length) return undefined;

    const stageId: number = $exportDiv.data('stage-id');
    const exportId: number = $exportDiv.data('export-id');

    const matrixExportStatusRoute = Routing.generate('app_stagematrix_export', {
      exportId,
      id: stageId,
    });

    const getExportResponse = () =>
      new E1Request<{
        success: boolean;
        error: string | null;
        presignedLink: string | null;
      }>(matrixExportStatusRoute)
        .submit()
        .then(({ presignedLink }) => {
          if (presignedLink) {
            window.open(presignedLink, '_blank');
            Modal.closeAll();
            return;
          }
          setTimeout(() => getExportResponse(), 1000);
        });

    return getExportResponse();
  });
});
