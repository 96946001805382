import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Modal, ModalSize } from '@estimateone/frontend-components';
import UpdateAwardedStatusContent from './modals/UpdateAwardedStatusContent';
import UpdateOtherStatusContent from './modals/UpdateOtherStatusContent';
import { NotifyOptionType } from './modals/formSections/NotifyOptionsAwarded';
import { setStageStatus } from './utils';
import LoadingSpinnerFloating from '../../../shared/LoadingSpinner/LoadingSpinnerFloating';
import useFlashMessage from '../../../shared/Util/useFlashMessage';
import { Action, useInteractAnalytics } from '../../../hooks/Analytics';
import { GET_STAGE } from './queries';
import { ListenerEvent, StageStatus, StageStatusMap } from '@ascension/enums';
import { StageDetails, UpdateStageStatusResponseData } from './types';
import { GetStage, GetStageVariables } from './types/GetStage';
import { EntityId } from '@ascension/types';

const getNotificationMessage = (notifyOption: NotifyOptionType, statusName: string) => {
  if (notifyOption === NotifyOptionType.NONE) {
    return `Project status updated to ${statusName}. No notifications were emailed.`;
  }

  return `Project status updated to ${statusName}. Notifications were sent to invited contacts.`;
};

const UpdateTenderStatusModal = () => {
  const [stageId, setStageId] = useState<EntityId>();
  const [newStageStatus, setNewStageStatus] = useState<StageStatus>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { addEvent } = useInteractAnalytics();
  const { success: showSuccess } = useFlashMessage();

  const { loading, data } = useQuery<GetStage, GetStageVariables>(GET_STAGE, {
    variables: { id: stageId ?? 0 },
    fetchPolicy: 'no-cache',
    skip: !stageId,
  });

  const setStageDetails = (e: CustomEvent) => {
    const { stageId: newStageId, status: newStatus }: StageDetails = e.detail;
    setNewStageStatus(newStatus);
    setStageId(newStageId);
    setIsModalOpen(true);
  };

  useEffect(() => {
    // See stage.js (legacy jQuery)
    document.addEventListener('getStageStatusResult', setStageDetails);

    return () => {
      document.removeEventListener('getStageStatusResult', setStageDetails);
    };
  }, []);

  if (loading) return <LoadingSpinnerFloating />;
  if (!data || !stageId || data.stage?.__typename !== 'UnrestrictedStage') return null; // Undefined stage id implies unset data here

  const {
    stage: {
      name: stageName,
      status: prevStatus,
      constructionStartDate: stageProjectStart,
      constructionEndDate: stageProjectCompletion,
    },
  } = data;

  const handleModalClose = () => {
    setIsModalOpen(false);
    setStageId(undefined);
  };

  const handleCancel = () => {
    setStageStatus(stageId, prevStatus);

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    addEvent({
      action: Action.TENDER_UPDATE_STATUS_MODAL_CLOSE,
      stageId,
      prevStatus,
      newStatus: newStageStatus,
    });

    handleModalClose();
  };

  const handleFormSubmit = (responseData: UpdateStageStatusResponseData) => {
    if (!newStageStatus) {
      return;
    }

    const statusName = StageStatusMap[newStageStatus].toLowerCase();
    showSuccess({
      title: getNotificationMessage(responseData.notifyOption, statusName),
    });

    document.dispatchEvent(new CustomEvent(ListenerEvent.StageStatusUpdated));
    handleModalClose();
  };

  // Legacy jQuery interop is awkward
  // maybe this component has too many responsibilities?
  if (!stageName || !newStageStatus) {
    return null;
  }

  return (
    <Modal isOpen={isModalOpen} onRequestClose={handleCancel} size={ModalSize.Small}>
      <Modal.Section>
        {newStageStatus === StageStatus.TENDER_AWARDED ? (
          <UpdateAwardedStatusContent
            stageId={stageId}
            stageName={stageName}
            stageProjectStart={stageProjectStart ?? undefined}
            stageProjectCompletion={stageProjectCompletion ?? undefined}
            onFormSubmit={handleFormSubmit}
            onCancel={handleCancel}
            prevStatus={prevStatus}
          />
        ) : (
          <UpdateOtherStatusContent
            stageId={stageId}
            status={newStageStatus}
            onFormSubmit={handleFormSubmit}
            onCancel={handleCancel}
            prevStatus={prevStatus}
          />
        )}
      </Modal.Section>
    </Modal>
  );
};

export default UpdateTenderStatusModal;
