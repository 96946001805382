/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable fp/no-let */
/* eslint-disable fp/no-mutation */
/* eslint-disable max-statements */
import $ from 'jquery';
import Routing from 'routing';
import moment from 'moment-timezone';
import E1Request from '../classes/E1Request';
import { FlowEvent, getAppcues } from '@ascension/components/helpers/Appcues';
import SuperSlider from '@ascension/js/classes/SuperSlider';
import GenericList from '../classes/GenericList';
import { getFullName, getPhone } from './contact_util';
import { appendQualifications } from './stage_responses_helpers';
import { escapeRegExp } from './string_util';
import { getUserTimezone } from './timezone';
import { Action } from '@ascension/components/hooks/Analytics';
import { ButtonName } from '@ascension/components/hooks/Analytics/buttonNames';
import {
  AccountTypeValue,
  getStageTypeAsString,
  ListenerEvent,
  QuoteReturnRoute,
  StageType,
} from '../../enums';
import {
  AddressBookContact,
  ApprovedInvite,
  ApprovedInviteResponse,
  ModifyStatusResponse,
  ResendRfqResponse,
  RowInvite,
  SerialisedDate,
  User,
} from './types/stage_responses_list.types';
import { AccountType, QualificationLevel } from '@ascension/_gqltypes/builder.generated';
import { EntityId } from '@ascension/types';

const isAction = (action: string | undefined): action is 'add' | 'rem' =>
  action !== undefined && (action === 'add' || action === 'rem');

// TODO: This funciton is a duplicate of showHistoryModal. One should be removed.
export async function viewHistory(
  stage: EntityId,
  rid: EntityId,
  type: string,
  options = { onClose: () => void 0 },
) {
  const route = Routing.generate('app_stagerfq_viewhistorymodal', { id: stage, rfqId: rid });
  const req = new E1Request(route, 'GET', { active: type });
  req.setShowLoadingModal(false);
  const response = await req.submit();

  const slider = new SuperSlider(response.template, {
    name: response.slider_name,
    callbacks: {
      onClose: options.onClose,
    },
    containerSelector: '#rfq-super-slider',
  });
  slider.show();

  return response;
}

const appendCompanyLink = (
  searchableText: string,
  obj: RowInvite,
  userId: number,
  accountId: number,
  $noteContainer: JQuery<HTMLElement>,
) => {
  const $link = $('<a/>').addClass('link vcard').attr('role', 'button').text(searchableText);

  if (obj.contact) {
    $link.attr('data-company-id', obj.contact.company.id).attr('data-rfq-id', obj.id);
  } else {
    $link.attr('data-user-id', userId).attr('data-account-id', accountId);
  }

  if (obj.stage) {
    $link.attr('data-stage-id', obj.stage.id);
  }

  $noteContainer.append($('<div>').append($link));

  return $link;
};

const appendIsAwardedTag = (
  obj: RowInvite,
  awardedRfqIconPath: string | undefined,
  $noteContainer: JQuery<HTMLElement>,
) => {
  const isAwarded =
    obj.contact?.company.id &&
    obj.contact.company.id === obj.package.lettingScheduleDetails?.awardedToRfq?.contact?.companyId;

  if (isAwarded) {
    const $awardedTag = $('<span class="tag procurement-awarded">').html(
      `<img class="icon-left" src="${awardedRfqIconPath}"/>Awarded</span>`,
    );

    $noteContainer.append($awardedTag);
  }
};

const appendNotes = (
  obj: RowInvite,
  showNotes: boolean,
  $noteContainer: JQuery<HTMLElement>,
  noteText: string,
  noNotesText: string,
) => {
  if (showNotes) {
    const $lastNote = $('<p>').addClass('recent-note last-note').attr('max-text-lines', 4);

    if (obj.lastNote) {
      $noteContainer.append(
        $('<p>')
          .addClass('recent-note note-updated-relative')
          .text(moment.utc((obj.lastNote.updatedAt || obj.lastNote.createdAt).date).fromNow()),
      );
      $lastNote.html(noteText);
    } else {
      $lastNote.addClass('default-note').text(noNotesText);
    }
    $noteContainer.append($lastNote);
  }
};

const REQUEST_E1 = 1;
const e1Req = 'e1-request';

export class ResponseList {
  $container: JQuery<HTMLElement>;
  $selectAllCheckbox: JQuery<HTMLElement>;
  $quoteStatusFilters: JQuery<HTMLElement> | null;
  $documentStatusFilters: JQuery<HTMLElement> | null;
  $quoteMultiselect: JQuery<HTMLElement> | null;
  $documentMultiselect: JQuery<HTMLElement> | null;

  stageId: number;
  stageType: string;
  slug: string | undefined;
  listsTable: GenericList<ApprovedInviteResponse, RowInvite[]> | null;
  table: DataTables.Api | null;
  filterQuoteStatusColumn: number;
  filterDocumentStatusColumn: number;
  filterTradesColumn: number;
  filterPackagesColumn: number;
  filterAwardedColumn: number;
  awardedStatus: string;
  trackAppCues: string;

  constructor($container: JQuery<HTMLElement>) {
    this.$container = $container;
    this.$selectAllCheckbox = $container.find('.select-all-rows');
    this.stageId = Number(this.$container.attr('data-stage-id'));
    this.stageType = this.$container.attr('data-stage-type') ?? '';
    this.slug = this.$container.attr('data-slug');
    this.listsTable = null;
    this.table = null;
    this.filterQuoteStatusColumn = 9;
    this.filterDocumentStatusColumn = 10;
    this.filterTradesColumn = 11;
    this.filterPackagesColumn = 5;
    this.filterAwardedColumn = 12;
    this.awardedStatus = 'Awarded';
    this.$quoteStatusFilters = null;
    this.$documentStatusFilters = null;
    this.$quoteMultiselect = null;
    this.$documentMultiselect = null;
    this.trackAppCues = this.$container.attr('data-track-appcues') ?? '';

    if (this.trackAppCues === '1') {
      const appCues = getAppcues();
      if (appCues?.track) {
        appCues.track(FlowEvent.CLARIFY_INVITES_WIZARD_SEND_INVITES_CLICK);
      }
    }

    const changingList = (element: JQuery<HTMLElement>, $undo: JQuery<HTMLElement>) => {
      $(element).attr('data-action', 'rem');
      $(element).addClass('rem');
      $(element).parent().detach().insertAfter($undo);
      $undo.show();
    };

    const $listsTable = $('table.stage-monitor-dataTable');
    const $placeholderRow = $listsTable.find('tr.data-table-placeholder').first();

    const $checkboxTemplate = $("<input type='checkbox'>")
      .addClass('select-checkbox')
      .attr('data-id', null);
    const $noteTemplate = $placeholderRow.find('div.note-template').first();

    const $quoteStatusContainer = $placeholderRow.find('.quote-status-container').first();
    const $documentStatusContainer = $placeholderRow.find('.document-status-container').first();
    const $dropdownMenu = $placeholderRow.find('ul.dropdown-menu').first();

    const tooltipAddNoteText = $listsTable.attr('data-add-note-tooltip');
    const tooltipViewNotesText = $listsTable.attr('data-view-notes-tooltip');
    const noNotesText = $listsTable.attr('data-note-placeholder') ?? '';
    const awardedRfqIconPath = $listsTable.attr('data-awarded-rfq-icon-path');
    const notQuotingReasonIconPath = $listsTable.attr('data-not-quoting-reason-icon-path');
    const profileIconPath = $listsTable.attr('data-profile-icon-path');
    const tooltipProfileTag = $listsTable.attr('data-profile-tag-tooltip') ?? '';
    const qualChasEliteIconPath = $listsTable.attr('data-qual-chas-elite-icon-path');
    const qualChasAdvancedIconPath = $listsTable.attr('data-qual-chas-advanced-icon-path');
    const qualChasStandardIconPath = $listsTable.attr('data-qual-chas-standard-icon-path');
    const qualChasFoundationIconPath = $listsTable.attr('data-qual-chas-foundation-icon-path');
    const qualChasVerifiedSupplierIconPath = $listsTable.attr(
      'data-qual-chas-verified-supplier-icon-path',
    );
    const qualChasCasIconPath = $listsTable.attr('data-qual-chas-cas-icon-path');

    const qualificationIconPaths = (level: QualificationLevel): string | undefined => {
      switch (level) {
        case QualificationLevel.ELITE:
          return qualChasEliteIconPath;
        case QualificationLevel.ADVANCED:
          return qualChasAdvancedIconPath;
        case QualificationLevel.STANDARD:
          return qualChasStandardIconPath;
        case QualificationLevel.FOUNDATION:
          return qualChasFoundationIconPath;
        case QualificationLevel.VERIFIED_SUPPLIER:
          return qualChasVerifiedSupplierIconPath;
        case QualificationLevel.COMMON_ASSESSMENT_STANDARD:
          return qualChasCasIconPath;
        default:
          return undefined;
      }
    };

    const getResponseListColumns = () => [
      {
        // Select
        data: 'selected',
        orderable: false,
        searchable: false,
        defaultContent: '',
        class: 'checkboxCol',
        render: (selected: boolean, type: string, object: RowInvite, info: { row: number }) => {
          const $checkbox = $checkboxTemplate.clone().attr('data-id', object.id);

          if (object.unsubscribedAt || object.contact?.unsubscribedAt) {
            $checkbox
              .prop('disabled', true)
              .attr(
                'title',
                'This contact has elected not to receive notifications for this project.',
              );
          }

          if (object.selected) {
            $checkbox.attr('checked', 'checked');
          }

          if (this.listsTable) {
            this.listsTable.data[info.row] = object;
          }
          return $checkbox.prop('outerHTML');
        },
      },
      // Notes
      {
        data: null,
        orderable: false,
        searchable: false,
        render: (data: ApprovedInvite, type: string, row: RowInvite) => {
          const $noteContainer = $noteTemplate.clone().attr('data-request-id', row.id);
          const $note = $noteContainer.children('a').first();

          $note
            .attr('data-note-count', row.noteCount)
            .attr('title', (row.noteCount === 0 ? tooltipAddNoteText : tooltipViewNotesText) ?? '');

          $noteContainer.append($note);

          return $noteContainer.prop('outerHTML');
        },
      },
      // More notes
      {
        data: null,
        render: (data: ApprovedInvite, type: string, obj: RowInvite) => {
          const searchableText = obj.contact ? obj.contact.company.name : obj.user?.account?.name;
          const noteText = obj.lastNote?.text ? obj.lastNote.text : '';
          const searchableWithNotes = [searchableText, noteText].join(' ');
          const userId = obj.user?.id;
          const accountId = obj.user?.account?.id;
          const accountType = obj.user?.account?.type;
          const accountIsSubcontractor =
            accountType === AccountTypeValue[AccountType.SUBCONTRACTOR];

          const showNotes = $listsTable.hasClass('notes-visible');

          switch (type) {
            case 'display': {
              const $noteContainer = $('<div>');
              const $link = appendCompanyLink(
                searchableText,
                obj,
                userId,
                accountId,
                $noteContainer,
              );

              appendQualifications(obj, $link.parent(), qualificationIconPaths);

              appendIsAwardedTag(obj, awardedRfqIconPath, $noteContainer);

              const nonAddressBookProfile =
                !obj.contact && userId && accountId && accountIsSubcontractor;

              if (nonAddressBookProfile) {
                const $profileTag = $('<br/><div class="tag profile-tag">').html(
                  `<img src="${profileIconPath}" alt="profile tag"/>Profile</div>`,
                );
                $profileTag.attr('title', tooltipProfileTag);
                $noteContainer.append($profileTag);
                $link.attr('data-company-profile-exists', 'true');
              }

              appendNotes(obj, showNotes, $noteContainer, noteText, noNotesText);

              return $noteContainer.prop('outerHTML');
            }
            case 'filter':
              return showNotes ? searchableWithNotes : searchableText;
            default:
              // do not include notes in sort
              return searchableText;
          }
        },
      },
      // Name
      {
        data: null,
        render: (
          _: ApprovedInvite,
          __: string,
          { contact, user }: { contact: AddressBookContact; user: User },
        ) => {
          if (contact) {
            return getFullName(contact);
          }

          return `${user?.firstName || ''} ${user?.lastName || ''}`;
        },
      },
      {
        data: null,
        orderable: false,
        render: (
          data: ApprovedInvite,
          type: string,
          { contact, user }: { contact: AddressBookContact; user: User },
        ) => {
          if (contact) {
            return getPhone(contact) ?? contact.company?.phone ?? '';
          }
          return user?.office?.phone ?? '';
        },
      },
      {
        data: 'package.title',
        render: $.fn.dataTable.render.text(),
      },
      {
        data: 'quoteDueAt',
        render: (data: SerialisedDate, type: string) => {
          const quoteDueAt = data?.date;
          if (type === 'display' || type === 'filter') {
            return quoteDueAt ? moment(quoteDueAt).format('DD MMM YYYY') : '-';
          }
          return quoteDueAt;
        },
      },
      {
        data: null,
        orderable: false,
        searchable: false,
        render: (data: ApprovedInvite, type: string, row: RowInvite) => {
          const documentStatus = [];
          const $icons = $documentStatusContainer
            .clone()
            .attr('data-request-id', row.id)
            .attr('data-stage-id', this.stageId);

          if (row.hardCopyAt) {
            const $status = this.addStatusIcon('hard_copy');
            $icons.append($status);
            documentStatus.push('hard_copy');
          }

          if (row.hasDocsAt) {
            const $status = this.addStatusIcon('has-docs');
            $icons.append($status);
            documentStatus.push('has-docs');
          }

          if (row.downloadedLastAt !== undefined && row.downloadedLastAt !== null) {
            let packageStatus = 'downloaded';
            const downloadedLastAtMoment = moment.utc(row.downloadedLastAt.date);

            if (row.package.contentLastAddedAt !== null) {
              const contentLastAddedMoment = moment.utc(row.package.contentLastAddedAt.date);

              if (contentLastAddedMoment.isAfter(downloadedLastAtMoment)) {
                // unless content was added since last download
                packageStatus = 'obsolete';
                documentStatus.push(packageStatus);
              } else {
                documentStatus.push('latest');
              }
            }

            const $packageStatus = this.addStatusIcon(packageStatus);
            $packageStatus.text(downloadedLastAtMoment.tz(getUserTimezone()).format('MMM D'));
            $icons.append($packageStatus);
          }

          if (documentStatus.length === 0) {
            const $status = this.addStatusIcon('not-accessed');
            $icons.append($status);
            documentStatus.push('no-docs');
          }
          row.documentStatus = documentStatus;

          return $icons.prop('outerHTML');
        },
      },
      {
        data: null,
        searchable: false,
        type: 'quoteStatus',
        render: (data: ApprovedInvite, type: string, row: RowInvite) => {
          const { id, quotes } = row;
          const $main = $quoteStatusContainer.clone();

          $main.attr('data-request-id', id).attr('data-stage-id', this.stageId);

          $main.children().first().attr('data-request-id', id).attr('data-stage-id', this.stageId);

          const statusMap: Record<string, keyof typeof row> = {
            declined: 'notQuotingAt',
            quoted: 'quotedAt',
            quoting: 'quotingAt',
            lost: 'lostAt',
            awarded: 'awardedAt',
          };

          const quoteStatus = [];
          Object.keys(statusMap).forEach((statusType) => {
            const rowPropertyKey = statusMap[statusType];
            const rowProperty = row[rowPropertyKey];
            if (rowProperty !== null) {
              quoteStatus.push(statusType);

              let $status = this.addStatusIcon(statusType);

              if (statusType === 'quoted' && quotes?.length > 0) {
                const lastQuoteId = quotes[quotes.length - 1].id;

                const viewQuoteUrl = Routing.generate('app_quote_view', {
                  id: lastQuoteId,
                  returnTo: QuoteReturnRoute.BuilderInvites,
                });

                $status = $('<a class="tag rel-status tag-actionable">')
                  .addClass(statusType)
                  .attr('href', viewQuoteUrl)
                  .html('<i class="icon icon-file" />&nbsp;');
              }

              if (statusType === 'declined' && data.notQuotingReason) {
                const nqReason = data.notQuotingReason;
                $status
                  .attr('title', nqReason)
                  .addClass('tooltip-trigger')
                  .html(
                    `<img class="icon-left" src="${notQuotingReasonIconPath}" alt="${nqReason}"/>`,
                  );
              }

              $main.append($status);
            }
          });

          if (row.emailError) {
            const emailErrorStatus = 'email_error';
            const $statusIcon = this.addStatusIcon(emailErrorStatus);
            quoteStatus.push(emailErrorStatus);
            $main.append($statusIcon);
          }

          if (data.requestedChannel === REQUEST_E1) {
            $main.prepend(this.addStatusIcon(e1Req));
            quoteStatus.push(e1Req);
          }

          if (quoteStatus.length === 0) {
            const $reqStatus = $('<span>')
              .addClass('tag rel-status')
              .addClass(data.requestedChannel ? 'request_approved' : 'no_response')
              .text(data.requestedChannel ? 'Rqst Approved' : 'No Response');

            $main.append($reqStatus);
          }
          row.response = quoteStatus;

          // This blob of code below is what configures the actions in the meatball menu.
          // It adds 'add' metadata which gets picked up by jQuery events
          const actionStatusMap: Record<string, keyof typeof row> = {
            ...statusMap,
            'has-docs': 'hasDocsAt',
            hard_copy: 'hardCopyAt',
          };
          const $actions = $dropdownMenu.find('a[data-status-type]');
          $.each($actions, function () {
            const statusType = $(this).attr('data-status-type') ?? '';
            const isAddAction =
              !(statusType in actionStatusMap) || row[actionStatusMap[statusType]] === null;

            if (isAddAction) {
              $(this).attr('data-action', 'add');
              $(this).addClass('add');
            }
          });

          return $main.prop('outerHTML');
        },
      },
      {
        data: 'response[, ]',
        visible: false,
        render: $.fn.dataTable.render.text(),
      },
      {
        data: 'documentStatus[, ]',
        visible: false,
        render: $.fn.dataTable.render.text(),
      },
      {
        data: null,
        render(data: ApprovedInvite, row: RowInvite, obj: ApprovedInvite) {
          if (obj.contact) {
            return $.map(obj.contact.company.trades, (a) => [a.name]);
          }
          return [];
        },
        visible: false,
      },
      {
        // Filter Column "Awarded" (Column 10)
        data: null,
        render: (data: ApprovedInvite) => {
          const isAwarded =
            data.contact?.company.id &&
            data.contact.company.id ===
              data.package.lettingScheduleDetails?.awardedToRfq?.contact?.companyId;

          return isAwarded ? 'Awarded' : '';
        },
        visible: false,
      },
    ];

    const buildContextMenu = ($target: JQuery<HTMLElement>) => {
      if (!this.table) {
        return;
      }

      const ddId = 'status-dropdown';
      $(`#${ddId}`).remove();

      const $menu = $dropdownMenu.clone().attr('id', ddId);
      const $undo = $menu.find('.undo');
      const $undoDivider = $menu.find('.undo-divider');
      const $resendAddenda = $menu.find('.resend-addenda');
      const $inviteOnly = $menu.find('.invite-only');

      const dtRow = this.table?.row($target.closest('tr'));
      const {
        id: rfqId,
        response: responses,
        documentStatus,
        activityLogs,
        quotes,
      } = dtRow.data() as RowInvite;

      const $statusItems = $menu.find('.modify-status');
      const availableStatuses: string[] = [];
      $.each($statusItems, function () {
        availableStatuses.push($(this).data('status-type'));
      });

      let undoStatus = false;
      const allResponseTypes = [...responses, ...documentStatus];
      availableStatuses.reverse().forEach((response) => {
        if (allResponseTypes.includes(response)) {
          const $undoElement = $menu.find(`a[data-status-type="${response}"]`);
          changingList($undoElement, $undo);
          undoStatus = true;
        }
      });

      const revision = activityLogs.length !== 0;
      const hasQuotes = quotes.length !== 0;

      const $viewQuoteButton = $menu.find('.view-quote-button');
      const $addQuoteButton = $menu.find('.add-quote-button');

      if (hasQuotes) {
        $addQuoteButton.remove();

        const lastQuoteId = quotes[quotes.length - 1].id;

        $viewQuoteButton.find('a').attr(
          'href',
          Routing.generate('app_quote_view', {
            id: lastQuoteId,
            returnTo: QuoteReturnRoute.BuilderInvites,
          }),
        );
      } else {
        $viewQuoteButton.remove();

        $addQuoteButton
          .find('a')
          .attr(
            'href',
            Routing.generate('app_stage_quote_create', {
              rfqId,
              id: this.stageId,
              stageType: getStageTypeAsString(parseInt(this.stageType))?.toLowerCase(),
            }),
          )
          .attr('target', '_blank');
      }

      if (!revision) {
        $resendAddenda.remove();
      }
      if ($.inArray(e1Req, responses) >= 0) {
        $inviteOnly.remove();
      }
      if ($.inArray('obsolete', documentStatus) >= 0) {
        $resendAddenda.show();
      }

      if (!undoStatus) {
        $undo.remove();
        $undoDivider.remove();
      }

      $target.append($menu);
    };

    if ($listsTable.length > 0) {
      this.listsTable = new GenericList<ApprovedInviteResponse, RowInvite[]>(
        $listsTable,
        (list: GenericList<ApprovedInviteResponse, RowInvite[]>) => {
          list.table = list.$target.DataTable({
            rowCallback(row) {
              $(row).find('.invite_note').tooltip({ placement: 'right' });
            },
            paging: false,
            data: list.data,
            info: false,
            order: [
              [5, 'asc'],
              [2, 'asc'],
            ],
            searchDelay: 350,
            columns: getResponseListColumns(),
            dom: 'Rrtp',
          });
          this.table = list.table;

          list.$target.trigger('data-selected-updated');

          list.toggleTableDisplay(true);
          list.$target.closest('.loading-container').addClass('has-loaded');

          this.initMultiSelect();
          this.setUpDropUps();
        },
        Routing.generate('app_stagerfq_fetchapprovedrequests', { id: this.stageId }),
        undefined,
        (list: GenericList<ApprovedInviteResponse, RowInvite[]>, data: RowInvite) => {
          const existing = this.listsTable?.data.find((obj: RowInvite) => obj.id === data.id);
          if (typeof existing !== 'undefined') {
            data.selected = existing.selected;
          } else {
            data.selected = false;
          }
          data.DT_RowId = data.id;
          data.documentStatus = [];
          return data;
        },
      );
    }

    this.listsTable?.$target.on('change', '.select-checkbox', (e) => {
      if (!this.listsTable) {
        return;
      }

      const checked = $(e.currentTarget).is(':checked');
      const $row = $(e.currentTarget).closest('tr');
      const row = $row.get(0);
      const rowData = this.listsTable.table.row(row).data() as RowInvite;

      rowData.selected = checked;
      this.listsTable.table.row(row).data(rowData);

      this.listsTable.data = this.listsTable.table.rows().data() as unknown as RowInvite[];

      this.listsTable.$target.trigger('data-selected-updated');
    });

    this.$selectAllCheckbox.on('click', (e) => {
      const checked = $(e.currentTarget).is(':checked');
      const $checkboxes = this.listsTable?.$target.find('.select-checkbox:not([disabled])');

      $checkboxes?.prop('checked', checked).trigger('change');

      window.analyticsService?.addInteractEvent({
        action: 'ToggleSelectAllCompaniesToInvite',
        selectedCompaniesCount: checked && $checkboxes ? $checkboxes.length : 0,
        isDeselect: !checked,
      });
    });

    this.$container.on('click', '.stage-monitor-dataTable .dropdown-toggle', (e) => {
      const $this = $(e.currentTarget);
      buildContextMenu($this.parent());
    });

    this.$container.on('click', '.modify-status', async (evt) => {
      if (!this.table) {
        return;
      }
      const $this = $(evt.currentTarget);
      const stageIdString = $this.closest('div').attr('data-stage-id');
      const rfqIdString = $this.closest('div').attr('data-request-id');
      const action: string | undefined = $this.attr('data-action');
      const type = $this.attr('data-status-type');
      if (!stageIdString || !rfqIdString || !type || !isAction(action)) {
        throw new Error('Could not get required data to modify rfq status');
      }
      const stageId = parseInt(stageIdString);
      const rfqId = parseInt(rfqIdString);
      const dtRow = this.table.row($this.closest('tr'));
      await this.modifyStatus(rfqId, stageId, type, action, dtRow);
    });

    this.$container.on('change', '.stage-trade-filter', (e) => {
      const $this = $(e.currentTarget);
      const $selected = $this.find('option:selected');

      const status = $selected.text();
      const value = $selected.val();

      const filterCol = this.table?.column(this.filterTradesColumn);

      if (filterCol !== undefined) {
        if (Number(value) === 0) {
          // remove filter
          filterCol.search('');
        } else if (status === 'awaiting') {
          // When there is no status we must use a regex to search for an empty string
          filterCol.search('^$', true, false, false);
        } else {
          filterCol.search(status);
        }
      }
      this.table?.draw();
    });

    const responseStatusSearchValues: Record<string, string> = {
      awaiting: '^$',
      email_error: 'email_error',
      quoting: 'quoting',
      quoted: 'quoted',
      declined: 'declined',
      'has-docs': 'has-docs',
      hard_copy: 'hard_copy',
      obsolete: 'obsolete',
      latest: 'latest',
      'no-docs': 'no-docs',
    };

    this.$container.on('change', '.document-response-filter', () => {
      const filterCol = this.table?.column(this.filterDocumentStatusColumn);
      const selectedValue = this.$documentMultiselect?.val();
      const selectedOptions = Array.isArray(selectedValue)
        ? selectedValue
        : [selectedValue?.toString()];

      const searchString = selectedOptions
        ?.map((statusName: string) => responseStatusSearchValues[statusName])
        .join('|');
      filterCol?.search(searchString, true, false, false);

      this.table?.draw();
    });

    this.$container.on('change', '.stage-response-filter', () => {
      const filterCol = this.table?.column(this.filterQuoteStatusColumn);
      const selectedValue = this.$quoteMultiselect?.val();
      const selectedOptions = Array.isArray(selectedValue)
        ? selectedValue
        : [selectedValue?.toString()];

      const searchString = selectedOptions
        ?.map((statusName: string) => responseStatusSearchValues[statusName])
        .join('|');
      filterCol?.search(searchString, true, false, false);

      this.table?.draw();
    });

    this.$container.on('change', '.stage-package-filter', (e) => {
      if (!this.table) {
        return;
      }

      const $this = $(e.currentTarget);
      const $selected = $this.find('option:selected');

      const selectedPackage = $selected.text();
      const selectedValue = $selected.val();

      const filterCol = this.table.column(this.filterPackagesColumn);

      if (Number(selectedValue) === 0) {
        // remove filter
        filterCol.search('');
      } else if (Number(selectedValue) === 1) {
        filterCol?.search(`^(?!(Full Set)$).*$`, true, false, true);
      } else {
        // Name of package must perfectly match, careful if package has &/() in it
        filterCol.search(`^${escapeRegExp(selectedPackage)}$`, true, false, false);
      }
      this.table.draw();
    });

    this.$container.on('click', '.invite-multiple', () => {
      if (parseInt(this.stageType) === StageType.TYPE_PROCUREMENT) {
        document.dispatchEvent(new CustomEvent(ListenerEvent.InviteToQuoteMultiple));
        return;
      }

      window.location.assign(
        Routing.generate('app_stage_invitations', {
          id: this.stageId,
          stageType: getStageTypeAsString(parseInt(this.stageType))?.toLowerCase(),
        }),
      );
    });

    this.$container.on('click', '.view-history', async (e) => {
      const rfqId = $(e.currentTarget).closest('div').attr('data-request-id');
      const historyType = $(e.currentTarget).attr('data-history-type');

      if (!rfqId || !historyType) {
        throw new Error('Cannot get required properties to view history');
      }

      await viewHistory(this.stageId, parseInt(rfqId), historyType);
    });

    this.$container.on('click', '.copy-download-link', async (e) => {
      const rfqId = $(e.currentTarget).closest('div').attr('data-request-id');

      const route = Routing.generate('app_stagerfq_viewrfqlink', { id: this.stageId, rfqId });
      const req = new E1Request(route, 'POST');
      req.show_loading_modal = true;
      await req.submit();
      setTimeout(() => {
        $('input#download-link').select();
      }, 100);
    });

    this.$container.on('click', '.resend-invitation', async (e) => {
      if (!this.table) {
        return;
      }
      const dtRow = this.table.row($(e.currentTarget).closest('tr'));
      const rfqId = $(e.currentTarget).closest('div').attr('data-request-id');

      const route = Routing.generate('app_stagerfq_resend', { id: this.stageId, rfqId });
      const req = new E1Request<ResendRfqResponse>(route, 'POST');
      const response = await req.submit();
      if (response.rfq) {
        const rowData = dtRow.data() as RowInvite;
        const updatedRowData: RowInvite = {
          ...response.rfq[0],
          documentStatus: rowData.documentStatus,
          DT_RowId: rowData.DT_RowId,
          selected: rowData.selected,
          lastNote: rowData.lastNote,
          notQuotingReason: rowData.notQuotingReason,
          qualifications: rowData.qualifications,
        };
        dtRow.data(updatedRowData).draw();
        document.dispatchEvent(new CustomEvent(ListenerEvent.InviteToQuoteSuccess));
      }
    });

    this.$container.on('click', '.revoke-invitation', async (e) => {
      const rfqId = $(e.currentTarget).closest('div').attr('data-request-id');
      const route = Routing.generate('app_stagerfq_revoke', { id: this.stageId, rfqId });
      const req = new E1Request(route, 'POST');
      req.show_loading_modal = true;
      await req.submit();
    });

    this.$container.on('click', '.resend-addenda', async (e) => {
      const rfqId = $(e.currentTarget).closest('div').attr('data-request-id');
      const route = Routing.generate('app_stagerfq_resendaddenda', { id: this.stageId, rfqId });
      const req = new E1Request(route, 'POST');
      await req.submit();
    });

    this.$container.on('click', '.remind-no-response', async () => {
      const projectType = getStageTypeAsString(parseInt(this.stageType))?.toLowerCase() ?? '';
      window.analyticsService?.addInteractEvent({
        action: Action.BUTTON_CLICKED,
        buttonName: ButtonName.REMIND_NO_RESPONSE,
        projectType,
      });
      const route = Routing.generate('app_stagerfq_remind', { id: this.stageId });
      const req = new E1Request(route, 'POST');
      await req.submit();
    });
  }

  generateDocumentResponseMultiSelectOptions() {
    const noPackageTag = `<span class="tag rel-status not-accessed no-modal tag-actionable"><span class="sr-only">Not Accessed</span></span>`;
    const obsoleteTag = `<span class="tag rel-status obsolete no-modal tag-actionable">Out of Date Package</span>`;
    const currentPackageTag = `<span class="tag rel-status downloaded tag-actionable">Current Package</span>`;
    const hasDocsTag = `<span class="tag rel-status has-docs tag-actionable"><span class="sr-only">Has Docs</span></span>`;
    const hardCopyTag = `<span class="tag rel-status hard_copy tag-actionable"><span class="sr-only">Hard Copy</span></span>`;

    this.$documentStatusFilters = this.$container.find('#document-status-filter');

    const filterColData = this.table
      ? this.table.column(this.filterDocumentStatusColumn).data()
      : [];

    this.$documentStatusFilters.append(
      $('<option/>').attr('value', 'no-docs').text(`${noPackageTag} (0)`),
    );
    this.$documentStatusFilters.append(
      $('<option/>').attr('value', 'obsolete').text(`${obsoleteTag} (0)`),
    );
    this.$documentStatusFilters.append(
      $('<option/>').attr('value', 'latest').text(`${currentPackageTag} (0)`),
    );
    this.$documentStatusFilters.append(
      $('<option/>').attr('value', 'has-docs').text(`${hasDocsTag} (0)`),
    );
    this.$documentStatusFilters.append(
      $('<option/>').attr('value', 'hard_copy').text(`${hardCopyTag} (0)`),
    );

    if (filterColData.length > 0) {
      const hasDocsCount = filterColData.filter((value) => value.includes('has-docs')).length;
      const hardCopyCount = filterColData.filter((value) => value.includes('hard_copy')).length;
      const outOfDateCount = filterColData.filter((value) => value.includes('obsolete')).length;
      const currentPackageCount = filterColData.filter((value) => value.includes('latest')).length;
      const noPackageCount = filterColData.filter((value) => value.includes('no-docs')).length;

      if (this.$documentStatusFilters) {
        this.$documentStatusFilters
          .find('[value="no-docs"]')
          .text(`${noPackageTag} (${noPackageCount})`);
        this.$documentStatusFilters
          .find('[value="obsolete"]')
          .text(`${obsoleteTag} (${outOfDateCount})`);
        this.$documentStatusFilters
          .find('[value="hard_copy"]')
          .text(`${hardCopyTag} (${hardCopyCount})`);
        this.$documentStatusFilters
          .find('[value="has-docs"]')
          .text(`${hasDocsTag} (${hasDocsCount})`);
        this.$documentStatusFilters
          .find('[value="latest"]')
          .text(`${currentPackageTag} (${currentPackageCount})`);
      }
    }
  }

  generateQuoteResponseMultiSelectOptions() {
    this.$quoteStatusFilters = this.$container.find('#response-status-filter');
    const filterColData = this.table ? this.table.column(this.filterQuoteStatusColumn).data() : [];

    const quotedTag = `<span class="tag rel-status quoted tag-actionable"><span class="sr-only">Quoted</span></span>`;
    const quotingTag = `<span class="tag rel-status quoting tag-actionable"><span class="sr-only">Quoting</span></span>`;
    const notQuotingTag = `<span class="tag rel-status declined tag-actionable"><span class="sr-only">Not Quoting</span></span>`;
    const noResponseTag = `<span class="tag rel-status no_response tag-actionable">No Response</span>`;
    const emailErrorTag = `<span class="tag rel-status email_error no-modal tag-actionable"><span class="sr-only">Email Error</span></span>`;

    this.$quoteStatusFilters.append(
      $('<option/>').attr('value', 'quoted').text(`${quotedTag} (0)`),
    );
    this.$quoteStatusFilters.append(
      $('<option/>').attr('value', 'quoting').text(`${quotingTag} (0)`),
    );
    this.$quoteStatusFilters.append(
      $('<option/>').attr('value', 'declined').text(`${notQuotingTag} (0)`),
    );
    this.$quoteStatusFilters.append(
      $('<option/>').attr('value', 'awaiting').text(`${noResponseTag} (0)`),
    );
    this.$quoteStatusFilters.append(
      $('<option/>').attr('value', 'email_error').text(`${emailErrorTag} (0)`),
    );

    if (filterColData.length > 0) {
      const awaitingCount = filterColData.filter((value) => value.length === 0).length;
      const emailErrorCount = filterColData.filter(
        (value) => value.indexOf('email_error') >= 0,
      ).length;
      const notQuotingCount = filterColData.filter(
        (value) => value.indexOf('declined') >= 0,
      ).length;
      const quotedCount = filterColData.filter((value) => value.indexOf('quoted') >= 0).length;
      const quotingCount = filterColData.filter((value) => value.indexOf('quoting') >= 0).length;

      if (this.$quoteStatusFilters) {
        this.$quoteStatusFilters.find('[value="quoted"]').text(`${quotedTag} (${quotedCount})`);
        this.$quoteStatusFilters.find('[value="quoting"]').text(`${quotingTag} (${quotingCount})`);
        this.$quoteStatusFilters
          .find('[value="declined"]')
          .text(`${notQuotingTag} (${notQuotingCount})`);
        this.$quoteStatusFilters
          .find('[value="awaiting"]')
          .text(`${noResponseTag} (${awaitingCount})`);
        this.$quoteStatusFilters
          .find('[value="email_error"]')
          .text(`${emailErrorTag} (${emailErrorCount})`);
      }
    }
  }

  generateResponseTypeMultiSelectOptions() {
    this.generateDocumentResponseMultiSelectOptions();
    this.generateQuoteResponseMultiSelectOptions();

    if (!this.table) return;

    new $.fn.dataTable.FixedHeader(this.table, {});
  }

  setUpDropUps() {
    if (this.table) {
      const rows = this.table.rows().nodes().reverse().to$();

      let rowHeight = 0;
      if (rows.length > 0) {
        $.each(rows, (i, row) => {
          if (rowHeight <= 6) {
            // profile tag increases row height, approximated to 1.5 rows.
            // needs approx 6 rows clearance to fit above the footer.
            rowHeight += $(row).find('.profile-tag').length ? 1.5 : 1;
            $(row).find('.dropdown').addClass('dropup').removeClass('dropdown');
          }
        });
      }
    }
  }

  initMultiSelect() {
    this.$quoteMultiselect = this.$container.find('#response-status-filter').multiselect({
      enableHTML: true,
      includeSelectAllOption: true,
      nonSelectedText: 'Select Quote Status',
      selectAllText: `All (${this.table?.data().length})`,
      onChange: (option: JQuery<HTMLElement>, checked: boolean) => {
        if (option === undefined) {
          if (checked) {
            this.$quoteMultiselect?.multiselect('selectAll', false);
          } else {
            this.$quoteMultiselect?.multiselect('deselectAll', false);
          }
          if (this.table) {
            const filterCol = this.table?.column(this.filterQuoteStatusColumn);
            filterCol.search('', true, false, false);
            this.table.draw();
            this.$quoteMultiselect?.multiselect('updateButtonText');
            this.$quoteMultiselect?.multiselect('rebuild');
          }
        }
      },
    });
    this.$documentMultiselect = this.$container.find('#document-status-filter').multiselect({
      enableHTML: true,
      includeSelectAllOption: true,
      nonSelectedText: 'Select Document Status',
      selectAllText: `All (${this.table?.data().length})`,
      onChange: (option, checked) => {
        if (option === undefined) {
          if (checked) {
            this.$documentMultiselect?.multiselect('selectAll', false);
          } else {
            this.$documentMultiselect?.multiselect('deselectAll', false);
          }

          if (this.table) {
            const filterCol = this.table.column(this.filterDocumentStatusColumn);
            filterCol.search('', true, false, false);
            this.table.draw();
          }

          this.$documentMultiselect?.multiselect('updateButtonText');
          this.$documentMultiselect?.multiselect('rebuild');
        }
      },
    });

    this.generateResponseTypeMultiSelectOptions();
    this.$container.find('.response-status-spinner').remove();
    this.$container.find('.document-status-spinner').remove();
    this.updateMultiSelectValues();
  }

  updateMultiSelectValues() {
    this.$quoteMultiselect?.multiselect('rebuild');
    this.$documentMultiselect?.multiselect('rebuild');
  }

  // eslint-disable-next-line class-methods-use-this
  async modifyStatus(
    rid: EntityId,
    stageId: EntityId,
    type: string,
    action: 'add' | 'rem',
    dtRow: DataTables.RowMethods,
  ) {
    const route = Routing.generate('app_stagerfq_modifyresponse', { id: stageId, rfqId: rid });
    const data = { responseType: type, responseAction: action };

    const request = new E1Request<ModifyStatusResponse>(route, 'POST', data);
    const response = await request.submit();

    const { lastNote, selected, DT_RowId, qualifications } = dtRow.data() as RowInvite;

    if (response.change) {
      const updatedRowData: RowInvite = {
        documentStatus: [],
        ...response.rfq[0],
        lastNote,
        selected,
        DT_RowId,
        qualifications,
      };
      dtRow.data(updatedRowData).draw();
      document.dispatchEvent(new CustomEvent(ListenerEvent.QuoteStatusUpdated));
    }
  }

  // eslint-disable-next-line class-methods-use-this
  addStatusIcon(statusType: string) {
    return $('<span class="tag rel-status">').addClass(statusType);
  }

  // eslint-disable-next-line class-methods-use-this
  remStatusIcon(element: JQuery<HTMLElement>, statusType: string) {
    const $element = $(element).closest('td');
    $element.find(`.${statusType}`).detach();
  }
}
