import { QuoteCoverageAttribute } from '../QuoteCoverageAttribute';
import { Indicator, IndicatorStatus } from '../QuoteCoverageStatus/Indicator';
import { QuoteCoverageStatistics } from '../QuoteCoverageStatus/utils/calculateQuoteCoverageStatus';
import { Package } from '@builder/features/ProcurementLettingSchedule/types';
import styles from '../styles.scss';

type QuoteCoverageColumn = {
  id: string;
  status?: IndicatorStatus;
  headerText: string;
  attribute: keyof QuoteCoverageStatistics;
};

const quoteCoverageColumnsData: QuoteCoverageColumn[] = [
  {
    id: 'invited',
    headerText: 'Invited',
    attribute: 'numberOfQuotes',
  },
  {
    id: 'quoted',
    status: IndicatorStatus.Quoted,
    headerText: 'Quoted',
    attribute: 'quoted',
  },
  {
    id: 'quoting',
    status: IndicatorStatus.Quoting,
    headerText: 'Quoting',
    attribute: 'quoting',
  },
  {
    id: 'notQuoting',
    status: IndicatorStatus.NotQuoting,
    headerText: 'Not Quoting',
    attribute: 'notQuoting',
  },
  {
    id: 'noResponse',
    status: IndicatorStatus.NoResponse,
    headerText: 'No Response',
    attribute: 'noResponse',
  },
];

type GetQuoteCoverageStatistics = (rowOriginal: Package) => QuoteCoverageStatistics | null;

export const generateQuoteCoverageColumns = (
  getQuoteCoverageStatistics: GetQuoteCoverageStatistics,
) =>
  quoteCoverageColumnsData.map(({ id, status, headerText, attribute }) => ({
    id,
    Header: () => (
      <span
        className={styles.iconColumnHeader}
        data-tip
        data-for="quote-coverage-tooltip"
        aria-describedby="quote-coverage-tooltip"
        aria-label={`Quote Coverage - ${headerText}`}
      >
        <div className={styles.quoteCoverageAttrHeader}>
          {status !== undefined && <Indicator status={status} />}
          <span>{headerText}</span>
        </div>
      </span>
    ),
    Cell: ({ row: { original: rowOriginal } }: { row: { original: Package } }) => (
      <div className={styles.quoteCoverageAttrContent}>
        <QuoteCoverageAttribute
          coverage={getQuoteCoverageStatistics(rowOriginal)}
          attribute={attribute}
        />
      </div>
    ),
    disableSortBy: true,
  }));
