import { ConnectionPreferenceSlider } from '@subbie/modal/ConnectionPreferenceSlider';
import { ConnectionPreferenceFormProvider } from '@subbie/modal/ConnectionPreferenceSlider/ConnectionPreferenceFormContext';
import { useWatchlistStatusByProject } from '@subbie/context/WatchlistProvider/hooks';
import { useConnectionPreferenceSlider } from './hooks/useConnectionPreferenceSlider';
import { useConnectionPreference } from '@subbie/modal/ProjectSlider/ProjectSliderBody/ProjectSubbies/hooks';

type StandaloneConnectionPreferenceSliderProps = {
  projectId: number;
  isOpen: boolean;
  onClose: () => void;
};

const StandaloneConnectionPreferenceSlider = ({
  projectId,
  isOpen,
  onClose,
}: StandaloneConnectionPreferenceSliderProps) => {
  const { connectionPreference, loading: preferenceLoading } = useConnectionPreference(projectId);
  const statusesByProject = useWatchlistStatusByProject();
  const watchlistStatus = statusesByProject[projectId];

  if (connectionPreference === undefined || preferenceLoading) {
    return null;
  }

  return (
    <ConnectionPreferenceFormProvider
      preference={connectionPreference}
      watchlistStatus={watchlistStatus}
    >
      <ConnectionPreferenceSlider isPaneOpen={isOpen} handleClose={onClose} />
    </ConnectionPreferenceFormProvider>
  );
};

export const ConnectionPreferenceSliderWrapper = () => {
  const { isOpen, projectId, close } = useConnectionPreferenceSlider();
  return isOpen && projectId ? (
    <StandaloneConnectionPreferenceSlider isOpen={isOpen} projectId={projectId} onClose={close} />
  ) : null;
};
