import $ from 'jquery';
import Routing from 'routing';
import { escape } from 'lodash';
import moment from 'moment-timezone';
import Form from '../classes/Form';
import ServerDataList from '../classes/ServerDataList';
import SuperSlider from '../classes/SuperSlider';
import { truncate } from '../utils/truncate';
import UserCorrespondenceDraftModule from './classes/UserCorrespondenceDraftModule';
import { getFullName } from './contact_util';
import { pad } from './string_util';
import { getUserTimezone } from './timezone';

$(() => {
  const $correspondenceCtn = $('.correspondence-container');
  const stageId = $correspondenceCtn.attr('data-stage');
  const inDrawerExperiment = $('body').find('#render-drawer-ui-container').length > 0;

  if ($correspondenceCtn.length) {
    const method = Routing.generate('app_stagecorrespondence_fetch', {
      id: stageId,
    });
    const $target = $correspondenceCtn.find('.correspondence-table');

    const $projectFilter = $('select#correspondence_table_project_filter');
    const projectIdColIndex = 5;

    const correspondenceTable = new ServerDataList($target, method, (list) => {
      const $placeholderRow = list.$target.find('tr.data-table-placeholder').first();
      list.table = list.$target
        .DataTable({
          pageLength: 25,
          processing: true,
          serverSide: true,
          ajax(data, cb, settings) {
            list.serverRequest(data, cb, settings);
          },
          searchCols: [null, null, null, null, null, { search: $projectFilter.val() }],
          order: [[0, 'desc']],
          columns: [
            {
              data: 'id',
              name: 'item.id',
              render(data) {
                return pad(data, 8);
              },
            },
            {
              name: 'correspondence.type',
              data: 'correspondence.type',
              render(data) {
                return window.global.correspondenceTypes && window.global.correspondenceTypes[data]
                  ? window.global.correspondenceTypes[data]
                  : '';
              },
            },
            {
              data: 'rfq.contact.company.name',
              name: 'company.name',
              orderable: false,
              render(data, display, obj) {
                if (obj.rfq.contact && inDrawerExperiment) {
                  const link = $('<a>')
                    .addClass('link vcard')
                    .text(data)
                    .attr('data-company-id', obj.rfq.contact.company.id);
                  return link.prop('outerHTML');
                }

                return escape(data || obj.rfq.user.account.name);
              },
            },
            {
              data: 'rfq.contact.lastName',
              name: 'contact.lastName',
              orderable: false,
              render(data, display, obj) {
                if (obj.rfq.contact === null && obj.rfq.user === null) {
                  return '';
                }
                return escape(getFullName(obj.rfq.contact ?? obj.rfq.user));
              },
            },
            {
              data: 'createdAt',
              name: 'item.createdAt',
              render(data, display, obj, details) {
                const template = $placeholderRow.find('td').get(details.col);
                const date = moment.utc(data.date);
                date.tz(getUserTimezone());
                $(template)
                  .find('.date')
                  .text(date.format($(template).find('.date').attr('data-format')));
                return $(template).html();
              },
            },
            {
              data: null,
              orderable: false,
              render(data, row, obj, details) {
                const $template = $($placeholderRow.find('td').get(details.col)).clone();
                const $link = $template.find('a');
                $link
                  .addClass('view-correspondence-trigger')
                  .attr('data-correspondence-id', obj.id)
                  .attr('data-stage-id', stageId);
                return $link.prop('outerHTML');
              },
            },
            {
              name: 'project.id',
              data: 'rfq.stage.project.id',
              visible: false,
              render() {
                return '-';
              },
            },
          ],
        })
        .on('init.dt', () => {
          list.$container.addClass('has-loaded');
          new $.fn.dataTable.FixedHeader(list.table, {});
        });
    });

    if ($projectFilter.length > 0) {
      $projectFilter.on('change', () => {
        correspondenceTable.table.column(projectIdColIndex).search($projectFilter.val()).draw();
      });
    }

    $correspondenceCtn.on('click', '.view-correspondence-trigger', async (clickEvent) => {
      const $this = $(clickEvent.currentTarget);
      const slider = new SuperSlider();

      slider.url = Routing.generate('app_stagecorrespondence_viewslider', {
        id: $this.data('stage-id'),
        correspondenceItemId: $this.data('correspondence-id'),
      });

      await slider.submit();

      slider.$slider.find('.email-frame').on('load', (loadEvent) => {
        const $frame = $(loadEvent.currentTarget);
        $frame.height(`${$frame.contents().height()}px`);
      });
    });
  }

  $('.print-correspondence-trigger').on('click', () => {
    window.print();
  });
});

$(() => {
  const $correspondenceCtn = $('.subcontractor-correspondence-container');

  if ($correspondenceCtn.length > 0) {
    const table = null;
    const method = Routing.generate('app_rfqcorrespondence_fetch');
    const $target = $correspondenceCtn.find('.subcontractor-correspondence-table');

    const $projectFilter = $('select#correspondence_table_project_filter');
    const projectIdColIndex = 5;

    const correspondenceTable = new ServerDataList($target, method, (list) => {
      const $placeholderRow = list.$target.find('tr.data-table-placeholder').first();
      list.table = list.$target
        .DataTable({
          pageLength: 25,
          processing: true,
          serverSide: true,
          ajax(data, cb, settings) {
            list.serverRequest(data, cb, settings);
          },
          searchCols: [null, null, null, null, null, { search: $projectFilter.val() }],
          order: [[3, 'desc']],
          columns: [
            {
              name: 'builder.name',
              data: 'rfq.stage.account.name',
              render(data) {
                return escape(truncate(data, 30));
              },
            },
            {
              name: 'project.name',
              data: null,
              render(data, display, obj) {
                let projectName;
                if (obj.rfq.stage.projectName) {
                  projectName = obj.rfq.stage.projectName;
                } else {
                  projectName = obj.rfq.stage.project.name;
                }
                return escape(truncate(projectName, 50));
              },
            },
            {
              name: 'correspondence.title',
              data: 'correspondence.title',
              render: $.fn.dataTable.render.text(),
            },
            {
              data: 'createdAt',
              name: 'correspondenceItem.createdAt',
              render(data, display, obj, details) {
                const template = $placeholderRow.find('td').get(details.col);
                const date = moment.utc(data.date);
                date.tz(getUserTimezone());
                $(template)
                  .find('.date')
                  .text(date.format($(template).find('.date').attr('data-format')));
                return $(template).html();
              },
            },
            {
              data: null,
              orderable: false,
              render(data, row, obj, details) {
                const $template = $($placeholderRow.find('td').get(details.col)).clone();
                const $link = $template.find('a');
                const route = Routing.generate('app_rfqcorrespondence_view', { id: obj.id });
                $link.attr('target', '_tab');
                $link.attr('href', route);
                return $link.prop('outerHTML');
              },
            },
            {
              name: 'project.id',
              data: 'rfq.stage.project.id',
              visible: false,
              render: $.fn.dataTable.render.text(),
            },
          ],
        })
        .on('init.dt', () => {
          list.$container.addClass('has-loaded');
        });
    });

    if ($projectFilter.length > 0) {
      $projectFilter.on('change', () => {
        correspondenceTable.table.column(projectIdColIndex).search($projectFilter.val()).draw();
      });
    }
  }

  const $rfqDataTable = $('.stage-monitor-dataTable');
  if ($rfqDataTable.length) {
    $('body').on('submit', 'form.new-correspondence-form', async (submitEvent) => {
      submitEvent.preventDefault();

      const $form = $(submitEvent.currentTarget);
      const stageId = $form.find('button[type="submit"]').data('stage-id');
      const $selectedFiles = $form.find('.attachment-search :selected');

      // De-select files before submitting so it won't submit duplicated data
      $selectedFiles.prop('selected', false);

      const attachments = JSON.stringify(
        $selectedFiles
          .toArray()
          .map(({ dataset: { id, hash, size, name } }) => ({ id, hash, size, name })),
      );

      const attachmentsField = $form.find("[name='attachments']");

      if (attachmentsField.length) {
        attachmentsField.val(attachments);
      } else {
        $('<input>')
          .attr({
            type: 'hidden',
            name: 'attachments',
            value: attachments,
          })
          .appendTo($form);
      }

      const { success } = await new Form(
        $form,
        Routing.generate('app_stagecorrespondence_send', { id: stageId }),
      ).submit();

      if (success) {
        // Deselect all checkboxes
        $rfqDataTable.find('input[type=checkbox]:checked').prop('checked', false).trigger('change');
      }

      // Re-select files if form is not valid
      $selectedFiles.prop('selected', !success);
    });
  }

  // Remove old user correspondence drafts
  UserCorrespondenceDraftModule.cacheCleanUp();
});
