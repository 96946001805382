import { useQuery } from '@apollo/client';
import { useAtomValue } from 'jotai';
import { selectedFiltersAtom } from '@builder/features/SubbieNetwork/atoms';
import { useCompanyTags } from '@builder/features/SubbieNetwork/providers/AscensionDataProvider/hooks';
import { DEFAULT_OFFICE_QUERY } from './queries';
import {
  DefaultOfficeForDirectoryFilters,
  DefaultOfficeForDirectoryFilters_currentUser_account_defaultOffice_address as DefaultOfficeAddress,
} from './types/DefaultOfficeForDirectoryFilters';

type RecursiveNonNullable<T> = { [K in keyof T]-?: RecursiveNonNullable<NonNullable<T[K]>> };
type WellDefinedAddress = RecursiveNonNullable<DefaultOfficeAddress>;

const isValidAddress = (address: DefaultOfficeAddress): address is WellDefinedAddress =>
  (address.suburb !== null || address.city !== null) &&
  address.longitude !== null &&
  address.latitude !== null;

export const useDefaultOfficeAddress = () => {
  const { data } = useQuery<DefaultOfficeForDirectoryFilters>(DEFAULT_OFFICE_QUERY);

  const currentAccount = data?.currentUser.account;
  const address = currentAccount?.defaultOffice?.address;

  return address && isValidAddress(address) ? address : null;
};

export const useConfirmedFilterValues = () => useAtomValue(selectedFiltersAtom);

export const useActiveConfirmedFilters = () => {
  const selectedFilterValues = useConfirmedFilterValues();

  return {
    searchAreaFilter:
      selectedFilterValues.locationLabel !== undefined &&
      (selectedFilterValues.searchArea === 'service-area' ||
        (selectedFilterValues.searchArea === 'office-location' &&
          selectedFilterValues.distance !== undefined)),
    connectionTypeFilter: selectedFilterValues.relationshipType !== undefined,
    contractSizeFilter:
      selectedFilterValues.contractSizeMin !== undefined ||
      selectedFilterValues.contractSizeMax !== undefined,
    workforceSizeFilter: selectedFilterValues.workforceSize !== undefined,
    categoryFilter: selectedFilterValues.categoryId !== undefined,
    companyListFilter: selectedFilterValues.companyListId !== undefined,
  };
};

export const useCompanyListFilterVisible = () => {
  const companyTagOptions = useCompanyTags();

  return companyTagOptions && companyTagOptions.length >= 1;
};
