import { capitalise, LockIcon } from '@estimateone/frontend-components';
import { numberToWord } from '../../../util';
import { pluralise } from '@shared/Util/pluralise';
import styles from './styles.module.scss';

type FreeCreditsGraphicSectionProps = {
  allowedCredits: number;
};

/** SUBBIE_CREDIT_EXPERIMENT_ALPHA_CODE */
export const FreeCreditsGraphicSection = ({ allowedCredits }: FreeCreditsGraphicSectionProps) => {
  const remainingFreeTenders = `${capitalise(numberToWord(allowedCredits))} free ${pluralise(
    'tender',
    allowedCredits,
  )}`;

  return (
    <div className={styles.roadblockGraphicContainer}>
      <div className={styles.graphicIconContainer}>
        <div className={styles.graphicIcon}>
          <LockIcon className={styles.lockIcon} />
        </div>
        <p>
          <strong>{remainingFreeTenders}</strong>
        </p>
        <div className={styles.redactedBlock} />
        <div className={styles.redactedBlock} />
      </div>
    </div>
  );
};
