import { SortByFn } from '@estimateone/frontend-components';
import { LettingSchedulePriority } from '@ascension/_gqltypes/builder.generated';
import { Package } from '@builder/features/ProcurementLettingSchedule/types';

const isNullOrUndefined = (thing: unknown): thing is null | undefined =>
  thing === undefined || thing === null;

export const simpleSort: SortByFn<Package> = (rowA, rowB, columnId) => {
  const rowAValue = rowA.values[columnId];
  const rowBValue = rowB.values[columnId];

  if (rowAValue === rowBValue) {
    return 0;
  }

  if (isNullOrUndefined(rowAValue)) {
    return 1;
  }

  if (isNullOrUndefined(rowBValue)) {
    return -1;
  }

  return rowAValue > rowBValue ? 1 : -1;
};

export const currencySort: SortByFn<Package> = (rowA, rowB, columnId) => {
  const rowAValue = rowA.values[columnId];
  const rowBValue = rowB.values[columnId];

  if (Number(rowAValue) === Number(rowBValue)) {
    return 0;
  }

  if (isNullOrUndefined(rowAValue)) {
    return 1;
  }

  if (isNullOrUndefined(rowBValue)) {
    return -1;
  }

  return Number(rowAValue) > Number(rowBValue) ? 1 : -1;
};

export const prioritySort: SortByFn<Package> = (rowA, rowB, columnId) => {
  const rowAValue = rowA.values[columnId];
  const rowBValue = rowB.values[columnId];

  // Have to reorder enum generated from GraphQL as it insists on being in alphabetical order
  const priorityOrder = [
    LettingSchedulePriority.CRITICAL,
    LettingSchedulePriority.HIGH,
    LettingSchedulePriority.MEDIUM,
    LettingSchedulePriority.LOW,
  ];

  if (rowAValue === rowBValue) {
    return 0;
  }

  if (isNullOrUndefined(rowAValue)) {
    return 1;
  }

  if (isNullOrUndefined(rowBValue)) {
    return -1;
  }

  return priorityOrder.indexOf(rowAValue) > priorityOrder.indexOf(rowBValue) ? 1 : -1;
};
