import {
  OperationVariables,
  MutationHookOptions,
  TypedDocumentNode,
  useMutation,
} from '@apollo/client';
import { getOrCreateClient } from '../../client';

export const useDocSyncMutation = <TData = unknown, TVariables = OperationVariables>(
  mutation: TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables>,
) =>
  useMutation<TData, TVariables>(mutation, {
    client: getOrCreateClient(),
    ...options,
  });
