export type BudgetType = {
  min: number;
  name: string;
};

export default class Budget {
  categories: BudgetType[];

  constructor() {
    this.categories = window.global.budgetRanges;
  }

  // Return the first category with a minimum over the input
  public getBudgetName(budget: number): string | null {
    return this.categories.reduce<string | null>((catName, { min, name }) => {
      if (catName !== null) return catName;
      return min >= budget ? name : catName;
    }, null);
  }
}
