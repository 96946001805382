import { gql } from '@apollo/client';

const Errors = {
  fragments: {
    error: gql`
      fragment ApiError on Error {
        field
        message
      }
    `,
  },
};

export const UPDATE_COMPANY_CAPABILITIES = gql`
  mutation UpdateCompanyCapabilities($input: UpdateCompanyCapabilitiesInput!) {
    updateCompanyCapabilities(input: $input) {
      ... on Errors {
        errors {
          ...ApiError
        }
      }
      ... on Profile {
        id
        offices {
          id
          serviceArea {
            id
            type
            radius
            boundaries {
              id
              type
              name
              shortName
              country {
                states {
                  id
                  name
                  shortName
                }
              }
            }
          }
        }
        trades {
          id
          name
          isCoreTrade
        }
        contractSizeMin
        contractSizeMax
        isDiscoverable
      }
    }
  }
  ${Errors.fragments.error}
`;
