import { useQuoteLocale, useStageLocale, useStageQuoteLocale } from './queries';
import { EntityId } from '@ascension/types';

interface FileManagerEntity {
  id: EntityId;
  __typename: 'Stage' | 'Quote';
}

export const useFileManagerLocale = (managerAware?: FileManagerEntity): string | undefined => {
  const quoteFmLocale = useQuoteLocale(
    managerAware && managerAware.__typename === 'Quote' ? managerAware.id : undefined,
  );
  const stageFmLocale = useStageLocale(
    managerAware && managerAware.__typename === 'Stage' ? managerAware.id : undefined,
  );

  // Out of an excess of caution
  switch (managerAware?.__typename) {
    case 'Quote':
      return quoteFmLocale;
    case 'Stage':
      return stageFmLocale;
    case undefined:
    default:
      return undefined;
  }
};

export const useQuoteFileManagerLocaleForStage = useStageQuoteLocale;
