import { gql } from '@apollo/client';

const NETWORK_COMPANY_FRAGMENT = gql`
  fragment SharedFields on NetworkCompanyInterface {
    __typename
    id
    name
    defaultOffice {
      id
      address {
        id
        suburb
        city
        province
        state {
          id
          shortName
        }
        country {
          id
          shortName
        }
      }
    }
  }
`;

export const SEARCH_NETWORK_COMPANIES = gql`
  ${NETWORK_COMPANY_FRAGMENT}
  query SearchNetworkCompanies(
    $resultType: ResultType!
    $query: String
    $stockTradeIds: [ID!]!
    $tradeIds: [ID!]!
    $projectCategoryIds: [ID!]!
    $distanceFrom: DistanceFromInput
    $serviceLocation: LocationInput
    $serviceArea: ServiceAreaSearchInput
    $tagIds: [ID!]
    $listIds: [ID!]
    $contractSizeMin: ContractSizeMinEnum
    $contractSizeMax: ContractSizeMaxEnum
    $workforceSize: WorkforceSizeEnum
    $connectionType: ConnectionType
    $page: Int!
    $resultsPerPage: Int!
  ) {
    searchNetworkCompanies(
      input: {
        resultType: $resultType
        query: $query
        stockTradeIds: $stockTradeIds
        tradeIds: $tradeIds
        tagIds: $tagIds
        serviceLocation: $serviceLocation
        serviceArea: $serviceArea
        listIds: $listIds
        contractSizeMin: $contractSizeMin
        contractSizeMax: $contractSizeMax
        workforceSize: $workforceSize
        connectionType: $connectionType
        projectCategoryIds: $projectCategoryIds
        distance: $distanceFrom
        sorting: { sortBy: DISTANCE }
        pagination: { page: $page, resultsPerPage: $resultsPerPage }
      }
    ) {
      count
      totalCount
      currentPage
      results {
        ... on BuilderNetworkCompany {
          ...SharedFields
          trades {
            id
            name
            stockTrade {
              id
              name
            }
          }
          qualifications {
            source
            type
            level
            status
          }
        }
        ... on ConnectedE1NetworkCompany {
          ...SharedFields
          stockTrades {
            ... on NetworkCompanyTradeInterface {
              id
              name
            }
          }
          contractSizeMin
          contractSizeMax
          logoUrl
          qualifications {
            source
            type
            level
            status
          }
        }
        ... on ConnectedBuilderNetworkCompany {
          ...SharedFields
          stockTrades {
            ... on NetworkCompanyTradeInterface {
              id
              name
            }
          }
          contractSizeMin
          contractSizeMax
          logoUrl
          qualifications {
            source
            type
            level
            status
          }
        }
        ... on E1NetworkCompany {
          ...SharedFields
          stockTrades {
            ... on NetworkCompanyTradeInterface {
              id
              name
            }
          }
          contractSizeMin
          contractSizeMax
          account {
            id
          }
          logoUrl
          qualifications {
            source
            type
            level
            status
          }
        }
        ... on ShadowNetworkCompany {
          ...SharedFields
          stockTrades {
            ... on NetworkCompanyTradeInterface {
              id
              name
            }
          }
          contractSizeMin
          contractSizeMax
        }
      }
    }
  }
`;
