import isEqual from 'lodash/isEqual';
import { RelationshipTypeEnum, SelectedFiltersAtom } from '@builder/features/SubbieNetwork/atoms';
import { Action } from '@ascension/components/hooks/Analytics';
import {
  ConnectionType,
  ContractSizeEnum,
  QualificationLevel,
  QualificationSource,
  QualificationStatus,
  QualificationType,
} from '@ascension/_gqltypes/builder.generated';

// currently maps the relationshipType to the old connectionType for search api
export const getConnectionType = (
  relationshipType?: RelationshipTypeEnum,
): ConnectionType | undefined => {
  if (relationshipType === RelationshipTypeEnum.AddressBook) {
    return ConnectionType.CONNECTED;
  }
  return relationshipType === RelationshipTypeEnum.NotInAddressBook
    ? ConnectionType.NOT_CONNECTED
    : undefined;
};

export type ID = string | number;

export type NetworkCompany = {
  id: ID;
  name: string;
  __typename:
    | 'BuilderNetworkCompany'
    | 'ConnectedE1NetworkCompany'
    | 'ConnectedBuilderNetworkCompany'
    | 'E1NetworkCompany'
    | 'ShadowNetworkCompany'
    | 'RedactedE1NetworkCompany'
    | 'RedactedConnectedE1NetworkCompany'
    | 'RedactedConnectedBuilderNetworkCompany';
  // The default office address
  address: null | {
    id: ID;
    suburb: string | null;
    city: string | null;
    province: string | null;
    state: null | {
      id: ID;
      shortName: string;
    };
    country: null | {
      id: ID;
      shortName: string;
    };
  };
  stockTrades: {
    id: number;
    name: string;
  }[];
  coreStockTrades: {
    id: number;
    name: string;
  }[];
  contractSizeMin: ContractSizeEnum | null;
  contractSizeMax: ContractSizeEnum | null;
  logoUrl: string | null;
  qualifications: {
    source: QualificationSource;
    type: QualificationType;
    level: QualificationLevel;
    status: QualificationStatus;
  }[];
};

export const populateNewSearchAnalyticsData = ({
  confirmedFilters,
  resultsCount,
  currentPage,
}: {
  confirmedFilters: SelectedFiltersAtom;
  resultsCount: number;
  currentPage: number;
}) => ({
  action: Action.SUBBIE_NETWORK_SEARCH,
  trade: confirmedFilters.stockTradeId,
  suburb: confirmedFilters.locationLabel,
  distance: confirmedFilters.distance,
  workforceSize: confirmedFilters.workforceSize,
  projectCategoryIds: confirmedFilters.categoryId,
  relationshipType: confirmedFilters.relationshipType,
  contractSizeMin: confirmedFilters.contractSizeMin,
  contractSizeMax: confirmedFilters.contractSizeMax,
  lat: confirmedFilters.lat,
  lon: confirmedFilters.lon,
  tagIds: confirmedFilters.companyListId,
  searchArea: confirmedFilters.searchArea,
  resultsCount,
  currentPage,
});

export const shouldSendAnalytics = ({
  confirmedFilters,
  queryFilters,
}: {
  confirmedFilters: SelectedFiltersAtom;
  queryFilters: SelectedFiltersAtom;
}) => {
  const definedFilters = Object.keys(confirmedFilters).reduce((acc, key) => {
    const filterKey = key as keyof SelectedFiltersAtom;
    if (confirmedFilters[filterKey] !== undefined) {
      return { ...acc, [filterKey]: confirmedFilters[filterKey] };
    }
    return acc;
  }, {});

  return !isEqual(queryFilters, definedFilters);
};
