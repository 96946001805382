import { Tag, TagVariant, Tooltip, CommentIcon, IconSize } from '@estimateone/frontend-components';

type NotQuotingStatusTagProps = {
  uniqueId: number;
  reason: string;
  className?: string;
};

export const NotQuotingStatusTag = ({ uniqueId, reason, className }: NotQuotingStatusTagProps) => (
  <Tooltip place="left" text={reason} tooltipId={`nq-reason-tooltip-${uniqueId}`}>
    <Tag
      className={className}
      variant={TagVariant.RfqStatusNotQuoting}
      text="NQ"
      icon={<CommentIcon size={IconSize.Mini} />}
    />
  </Tooltip>
);
