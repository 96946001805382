import { ComponentProps } from 'react';
import { ModalSize, Modal } from '@estimateone/frontend-components';
import { CreateNewPackageForm } from './CreateNewPackageForm';
import styles from './styles.scss';

type CreateNewPackageModalProps = {
  isOpen: boolean;
} & ComponentProps<typeof CreateNewPackageForm>;

export const CreateNewPackageFormModal = ({
  stageId,
  isOpen,
  onSuccess,
  onCancel,
}: CreateNewPackageModalProps) => (
  <Modal isOpen={isOpen} onRequestClose={onCancel} size={ModalSize.Small}>
    <Modal.Section>
      <h1 className={styles.modalHeading}>Create New Package</h1>
      <CreateNewPackageForm stageId={stageId} onCancel={onCancel} onSuccess={onSuccess} />
    </Modal.Section>
  </Modal>
);
