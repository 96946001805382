import { SingleValue } from 'react-select';
import { Select } from '@estimateone/frontend-components';
import LoadingSpinner from '@shared/LoadingSpinner';
import { useGetStages } from './hooks';
import { EntityId } from '@ascension/types';

export type StageSelectOption = {
  value: number;
  label: string;
};

type StageSelectProps = {
  stageId: EntityId | undefined;
  onChange: (option?: SingleValue<StageSelectOption>) => void;
};

export const StageSelect = ({ stageId, onChange }: StageSelectProps) => {
  const { unrestrictedStages, loading } = useGetStages();

  if (loading) {
    return <LoadingSpinner />;
  }

  const activeStages = unrestrictedStages?.map((s) => ({ value: s.id, label: s.name ?? '' })) ?? [];

  const defaultStage = activeStages.find((s) => s.value === stageId);

  return (
    <Select<StageSelectOption>
      id="stage-selector"
      classNamePrefix="stageSelect"
      label="Select Project"
      options={activeStages}
      defaultValue={defaultStage}
      onChange={onChange}
      isRequired
    />
  );
};
