import { Button } from '@estimateone/frontend-components';
import styles from './styles.scss';

type CheckoutButtonProps = {
  amount: number;
  currencySymbol: string;
  onClick: () => void;
  paymentMethod: 'CC' | 'EFT';
  isDisabled?: boolean;
};

export const CheckoutButton = ({
  amount,
  currencySymbol,
  onClick,
  paymentMethod,
  isDisabled = false,
}: CheckoutButtonProps) => {
  const label =
    paymentMethod === 'CC' ? `Pay ${currencySymbol}${amount.toFixed(2)}` : 'Enquire now';

  return (
    <Button className={styles.payNowButton} disabled={isDisabled} onClick={onClick}>
      {label}
    </Button>
  );
};
