import { gql } from '@apollo/client';

export const GET_PROJECT_INTERESTED_SUPPLIERS = gql`
  query ProjectInterestedSuppliers($projectId: EntityId!) {
    projectInterestedSuppliers(projectId: $projectId) {
      id
      accountId
      name
      location
      stockTrades {
        id
        name
      }
      type
      viewed
      contactAndNote {
        id
        name
        email
        phoneNumber
        note
      }
    }
  }
`;

export const GET_PROJECT_SUPPLIER_SUMMARY = gql`
  query ProjectSupplierSummary($projectId: EntityId!) {
    projectSupplierSummary(projectId: $projectId) {
      id
      projectId
      supplierCount
      hasOptedOutOfSSC
    }
  }
`;
