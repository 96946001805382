import { useQuery } from '@apollo/client';
import { GET_COMPANY } from '@builder/common/AddressBook/hooks/queries';
import {
  GetCompany,
  GetCompanyVariables,
} from '@builder/common/AddressBook/hooks/types/GetCompany';

type Company = GetCompany['company'];

const useGetAddressBookCompany = (id: number) => {
  const { data, error, loading } = useQuery<GetCompany, GetCompanyVariables>(GET_COMPANY, {
    variables: { id },
  });

  return {
    error,
    loading,
    data: data?.company,
  };
};

export type { Company };

export default useGetAddressBookCompany;
