import { BuilderContactDetails } from './BuilderContactDetails';
import BuilderName from './BuilderName';
import { QuotesDueBy } from './QuotesDueBy';
import { isIncognitoBuilder } from './StageRow';
import { getTranslation, LangRef } from '@shared/Util/lang';
import { SliderStageFragment as SliderStage } from '@subbie/modal/queries.generated';
import styles from './styles.scss';

export const WithdrawnStageRow = ({ stage }: { stage: SliderStage }) => {
  const { name, tenderQuotesDue, builderDetails, mostRecentQuote } = stage;

  return (
    <tr className={styles.withdrawnStageRow}>
      <td>
        <div className={styles.builderNameContainer}>
          <span className={styles.builderName}>
            {isIncognitoBuilder(builderDetails) ? (
              <BuilderName
                name={`${getTranslation(LangRef.INCOGNITO_BUILDER_HEADING)} (Withdrawn)`}
                isAwarded={false}
              />
            ) : (
              <BuilderName
                id={builderDetails?.id}
                name={`${builderDetails?.name} (Withdrawn)`}
                isAwarded={false}
              />
            )}
          </span>
        </div>
        {!isIncognitoBuilder(builderDetails) &&
        name !== null &&
        builderDetails.contactName !== null ? (
          <BuilderContactDetails
            stageName={name}
            contactName={builderDetails.contactName}
            contactEmail={builderDetails.contactEmail}
            contactPhone={builderDetails.contactPhone}
          />
        ) : null}
      </td>
      <td>
        <QuotesDueBy tenderQuotesDue={tenderQuotesDue} mostRecentQuoteId={mostRecentQuote?.id} />
      </td>
      <td>
        <div className={styles.label}>Withdrawn from tender</div>
      </td>
    </tr>
  );
};
