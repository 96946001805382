import { ReactElement } from 'react';
import styles from './styles.scss';

export type EmptyTableGuideProps = {
  image: ReactElement;
  heading: string;
  descriptions: string[];
  button: ReactElement;
};

export const EmptyTableGuide = ({ image, heading, descriptions, button }: EmptyTableGuideProps) => (
  <div className={styles.container}>
    <div className={styles.image}>{image}</div>
    <h3 className={styles.heading}>{heading}</h3>
    <div className={styles.description}>
      {descriptions.map((descriptionText) => (
        <p key={descriptionText}>{descriptionText}</p>
      ))}
    </div>
    <div className={styles.button}>{button}</div>
  </div>
);
