import { useState } from 'react';
import Routing from 'routing';
import {
  Alert,
  AlertVariant,
  WarningIcon,
  Icon,
  IconName,
  Button,
  ButtonVariant,
  E1Link,
  LinkVariant,
} from '@estimateone/frontend-components';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

export type AddendumAlertScheduleProps = {
  existingAddendum: Addendum;
};

export type Addendum = {
  id: EntityId;
  name: string | null;
};

export const AddendumAlertSchedule = ({ existingAddendum }: AddendumAlertScheduleProps) => {
  const [isOpen, setIsOpen] = useState(true);

  const addendumLink: string | null = Routing.generate('app_stageaddendum_documents', {
    id: existingAddendum.id,
  });

  const closeAlertWarning = () => {
    setIsOpen(false);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Alert variant={AlertVariant.Yellow}>
      <div className={styles.alert}>
        <WarningIcon />
        <E1Link href={addendumLink} variant={LinkVariant.Primary}>
          <span>
            Complete the unfinished addendum/revision
            {existingAddendum.name ? <strong>&#39;{existingAddendum.name}&#39;</strong> : null}
          </span>
        </E1Link>{' '}
        <Button
          className={styles.closeButton}
          variant={ButtonVariant.Transparent}
          onClick={closeAlertWarning}
        >
          <Icon name={IconName.Close} />
        </Button>
      </div>
    </Alert>
  );
};
