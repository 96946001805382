import { useDocSyncMutation } from './useDocSyncMutation';
import { MODIFY_STAGE_PROCORE_MAP } from '../mutations';
import {
  modifyStageProcoreMap as ModifyStageProcoreMap,
  modifyStageProcoreMapVariables as ModifyStageProcoreMapVariables,
} from '../types/modifyStageProcoreMap';

export const useModifyStageProcoreMap = () => {
  const [submit, { loading }] = useDocSyncMutation<
    ModifyStageProcoreMap,
    ModifyStageProcoreMapVariables
  >(MODIFY_STAGE_PROCORE_MAP);

  return { submit, loading };
};
