import { FORM_ERROR } from 'final-form';
import { FieldError, FormFieldErrors, FormFieldName, GlobalFormFieldErrors } from '../types';
import { ApiError } from '@api/fragments/Errors/types';

const addErrorToArray = (
  existingErrors: FieldError[] | undefined,
  newError: FieldError,
): FieldError[] => Array.from(new Set<FieldError>(existingErrors || []).add(newError));

const mapErrorsToField = (
  fieldNameMap: Record<string, FormFieldName>,
  apiErrors: ApiError[],
): FormFieldErrors =>
  apiErrors.reduce((acc, apiError) => {
    const field = apiError.field ?? '_form_error_';
    const fieldName = Object.keys(fieldNameMap).includes(field) ? fieldNameMap[field] : FORM_ERROR;

    acc.set(fieldName, addErrorToArray(acc.get(fieldName), apiError.message));

    return acc;
  }, new Map<string, FieldError[]>());

export const transformFormApiErrors = (
  fieldNameMap: Record<string, FormFieldName>,
  apiErrors: ApiError[],
): GlobalFormFieldErrors => {
  const fieldErrors = mapErrorsToField(fieldNameMap, apiErrors);

  return [FORM_ERROR, ...Object.values(fieldNameMap)].reduce((acc, fieldName) => {
    if (fieldErrors.get(fieldName)) return { ...acc, [fieldName]: fieldErrors.get(fieldName) };
    return acc;
  }, {});
};
