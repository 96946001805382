import { renderToStaticMarkup } from 'react-dom/server';
import $ from 'jquery';
import Routing from 'routing';
import escape from 'lodash/escape';
import E1Request from '../classes/E1Request';
import { PreferredIndicator } from '../../components/builder/common/PreferredIndicator';
import ContactTypeIcon from '../classes/ContactTypeIcon';
import { getShortAddress } from '../utils/address_form';
import { getFirstName, getFullName, getLastName } from './contact_util';

function ContactList($container, target) {
  this.contacts = {};
  this.$target = target;
  this.$container = $container;
  this.table = null;
  this.list = parseInt($container.attr('data-list-id'));
  this.listContacts = {};
  this.existingInvites = {};
  this.$tradeSelector = this.$container.find('.trade_list');
  this.selectedTrade = parseInt(this.$tradeSelector.val());
  this.$buttonContainer = this.$container.find('.container-operations');
  this.$submit = this.$container.find('.completelist');
  this.$selectAllCheckbox = this.$container.find('.select-all');
  this.filterForm = null;
  this.allowedFilter = 'filterable';
  this.loadingClass = 'loading-indicator glyphicon glyphicon-refresh glyphicon-spin';
  this.isDirty = false;

  this.$modal = null;

  const self = this;

  self.$tradeSelector.find('option').each((i, obj) => {
    self.listContacts[$(obj).val()] = {};
  });

  self.existingContacts();

  self.$tradeSelector.on('change', (e) => {
    self.selectedTrade = parseInt($(e.currentTarget).val());

    const tradeData = self.getTradeData(self.selectedTrade);
    if (tradeData == null) {
      self.fetchTrade(self.selectedTrade, () => {
        self.$tradeSelector.trigger('change');
      });
    } else {
      self.updateButtonList();
      self.updateTable(self.selectedTrade);
    }
  });

  self.$selectAllCheckbox.on('click', () => {
    const checked = self.$selectAllCheckbox.is(':checked');
    const rows = self.table.rows({ filter: 'applied' }).data();
    $.each(rows, (i, val) => {
      const { id } = val;
      self.updateList(id, checked);
    });
    self.updateTable(self.selectedTrade);
  });

  self.$container.on('click', '.completelist', () => {
    self.submitList();
  });

  self.$container.on('click', '.changeTradeLink', function () {
    const $clickedOption = $(this);
    // Get current position in trade list
    const $selectedOption = self.$tradeSelector.find('option:selected');
    const selectedIndex = $selectedOption.index('option');

    // Get the total number of options
    const optionSize = self.$tradeSelector.find('option').length;
    const maxIndex = optionSize - 1;

    let newIndex;
    if ($clickedOption.hasClass('nextTradeLink')) {
      newIndex = Math.min(selectedIndex + 1, maxIndex);
    } else if ($clickedOption.hasClass('previousTradeLink')) {
      newIndex = Math.max(selectedIndex - 1, 0);
    }

    let count = 10;
    while (
      count > 0 &&
      $(self.$tradeSelector.find('option')[newIndex]).attr('disabled') == 'disabled'
    ) {
      if ($clickedOption.hasClass('nextTradeLink')) {
        newIndex = Math.min(newIndex + 1, maxIndex);
      } else if ($clickedOption.hasClass('previousTradeLink')) {
        newIndex = Math.max(newIndex - 1, 0);
      }
      count--;
    }

    // If the new index and the previous index are not the same, update the trade list
    if (newIndex != selectedIndex) {
      self.$tradeSelector.find('option')[newIndex].selected = true;
      self.$tradeSelector.trigger('change');
    }
  });

  self.$target.on('change', '.list-trigger', function () {
    const $checkbox = $(this);
    const id = parseInt($checkbox.attr('data-contact-id'));

    self.updateList(id, $checkbox.is(':checked'));
  });

  self.updateButtonList();
}

ContactList.prototype.existingContacts = function () {
  const self = this;
  const existingUrl = Routing.generate('app_addressbooklist_fetchlistcontacts', { id: self.list });
  const request = new E1Request(existingUrl, 'GET');
  request.extraCallback = (response) => {
    self.data = [];
    if (typeof response.data !== 'undefined') {
      self.existingInvites = response.data;

      $.each(self.existingInvites, (trade_id, contacts) => {
        $.each(contacts, (index, contact_id) => {
          if (self.listContacts[trade_id]) {
            self.listContacts[trade_id][contact_id] = 1;
          }
        });
      });
    }

    self.fetchTrade(self.selectedTrade, self.init);
  };
  request.submit();
};

ContactList.prototype.submitList = async function () {
  const self = this;
  self.$submit.append($('<i>').addClass(self.loadingClass)).addClass('disabled');

  const submitUrl = Routing.generate('app_addressbooklist_save', { id: self.list });
  const request = new E1Request(submitUrl, 'POST', {
    changes: self.listContacts,
  });

  return request.submit((req, response) => {
    if (response.success) {
      self.isDirty = false;
      const redirect = self.$container.attr('data-redirect');

      if (redirect) {
        window.location = self.$container.attr('data-redirect');
      } else if (response.flash_notification) {
        E1Request.flashNotification(response.flash_notification);
        self.updateTable(self.selectedTrade, true);
      }
    } else if (response.flash_notification) {
      E1Request.flashNotification(response.flash_notification);
    }

    self.$submit.find('.loading-indicator').remove();
  });
};

ContactList.prototype.updateButtonList = function () {
  const self = this;

  // Get current position in trade list
  const $selectedOption = self.$tradeSelector.find('option:selected');
  const selectedIndex = $selectedOption.index('option');

  // Get the total number of options
  const optionSize = self.$tradeSelector.find('option').length;
  const maxIndex = optionSize - 1;

  self.$buttonContainer.find('li.disabled').removeClass('disabled');
  if (selectedIndex === 0) {
    self.$buttonContainer.find('.previousTradeLink').closest('li').addClass('disabled');
  }
  if (selectedIndex === maxIndex) {
    self.$buttonContainer.find('.nextTradeLink').closest('li').addClass('disabled');
  }

  // Swap the primary button to the 'complete' option if all trades have been cycled
  const $dropdown = $('.container-operations');
  let $optionToCopy = null;

  if (selectedIndex === maxIndex) {
    $optionToCopy = $dropdown.find('li.alternate-main').find('a');
  } else if (selectedIndex < maxIndex) {
    $optionToCopy = $dropdown.find('li.default-main').find('a');
  }

  if ($optionToCopy != null) {
    $dropdown
      .find('.primary')
      .attr('class', `primary btn btn-default btn-large ${$optionToCopy.attr('class')}`)
      .html($optionToCopy.html());
  }
};

ContactList.prototype.init = function (self) {
  window.onbeforeunload = function () {
    if (self.isDirty) {
      return 'You have attempted to leave this page. If you have made any changes to the fields without clicking the Save Contact List button, your changes will be lost.';
    }
    return undefined;
  };

  self.table = self.$target.DataTable({
    paging: false,
    data: self.getTradeData(self.selectedTrade),
    info: false,

    order: [[1, 'asc']],
    columns: [
      {
        data: null,
        class: 'checkbox-cell',
        orderable: false,
        render(data, row, obj) {
          const $checkCtn = $('<div>');
          const $checkbox = $('<input>')
            .attr('type', 'checkbox')
            .attr('data-contact-id', obj.id)
            .addClass('list-trigger');

          if (self.listContacts[self.selectedTrade][obj.id]) {
            $checkbox.attr('checked', 'checked');
          }

          $checkCtn.append($checkbox);
          return $checkCtn.html();
        },
      },
      {
        data: null,
        render(data, row, obj) {
          const $link = $('<a>');

          $link
            .addClass('vcard link')
            .text(obj.company.name)
            .attr('role', 'button')
            .attr('data-company-id', obj.company.id);
          return $link.prop('outerHTML');
        },
      },
      {
        data: 'full_name',
        render(fullName, type, row) {
          const $preferredIcon = $('<span>')
            .addClass('mr-2')
            .append(renderToStaticMarkup(<PreferredIndicator />));

          if (!row.isPreferred) {
            $preferredIcon.addClass('visibility-hidden');
          }

          const typeArray = row.types.map((val) => val.type);
          const $contactTypeIcons = new ContactTypeIcon(typeArray).getIcons();
          return $('<div>')
            .append($preferredIcon)
            .append($contactTypeIcons, escape(fullName))
            .prop('outerHTML');
        },
      },
      {
        data(obj) {
          if (typeof obj.phone !== 'undefined') {
            return obj.phone;
          }
          if (typeof obj.company.phone !== 'undefined') {
            return obj.company.phone;
          }
          return '-';
        },
        render: $.fn.dataTable.render.text(),
      },
      {
        data: 'company.address.short_address',
        render: $.fn.dataTable.render.text(),
      },
    ],
    dom: 'Rrtp',
  });
  self.$target.closest('.loading-container').addClass('has-loaded');
  self.$tradeSelector.val($('select.trade_list').val()).trigger('change');
};

ContactList.prototype.convertData = function (data) {
  data.DT_RowId = `dt_data_${data.id}`;

  data.firstName = getFirstName(data);
  data.lastName = getLastName(data);
  data.full_name = escape(getFullName(data));

  if (data.company.address) {
    data.company.address.short_address = getShortAddress(data.company.address);
  } else {
    data.company.address = { short_address: 'No Address' };
  }

  if (data.user == null) {
    data.user = {
      rfqs: [],
    };
  }
  return data;
};

ContactList.prototype.fetchTrade = function (tradeId, callback) {
  const self = this;
  const method = Routing.generate('app_addressbooktrade_fetchcontacts', {
    trade_id: tradeId,
  });

  const request = new E1Request(method, 'GET');
  request.extraCallback = (response) => {
    self.contacts[tradeId] = [];
    if (typeof response.data !== 'undefined') {
      const parsedData = response.data;
      $.each(parsedData, (index, val) => {
        self.contacts[tradeId].push(self.convertData(val));
      });
    }
    self.$target.closest('.loading-container').addClass('has-loaded');
    if (typeof callback !== 'undefined') {
      callback(self);
    }
  };

  self.$target.closest('.loading-container').removeClass('has-loaded');
  request.submit();
};

ContactList.prototype.getTradeData = function (tradeId) {
  const self = this;
  if (self.contacts.hasOwnProperty(tradeId)) {
    return self.contacts[tradeId];
  }
  return null;
};

ContactList.prototype.drawTable = function (tradeId) {
  const self = this;
  if (self.table != null) {
    const tableData = self.getTradeData(tradeId);
    self.table.rows().remove();
    self.table.rows.add(tableData).draw();
  }
  self.$target.trigger('data-updated');
};

ContactList.prototype.updateTable = function (tradeId, fetch, cb) {
  const self = this;
  if (fetch) {
    self.fetchTrade(tradeId, () => {
      self.drawTable(tradeId);
    });
  } else {
    self.drawTable(tradeId);
  }
  if (typeof cb !== 'undefined') {
    cb();
  }
};

ContactList.prototype.updateList = function (contactId, checked) {
  const self = this;

  if (!self.isDirty) {
    self.$submit.removeClass('disabled');
  }

  if (typeof self.listContacts[self.selectedTrade] === 'undefined') {
    self.listContacts[self.selectedTrade] = {};
  }

  const index = $.inArray(contactId, self.listContacts[self.selectedTrade]);
  if (index < 0) {
    self.listContacts[self.selectedTrade][contactId] = checked == true ? 1 : 0;
  }

  self.isDirty = true;
};

$(() => {
  const $listTable = $('.contact-list-module');
  $listTable.each(function () {
    const $container = $(this);
    const contactDataTable = new ContactList($container, $container.find('.contact-list-table'));
  });
});
