import { gql } from '@apollo/client';
import { PackageSelectFragment } from '@builder/common/FinalForm/PackageSelectField/fragments';

export const INVITE_TO_QUOTE_STAGE = gql`
  query GetInviteToQuoteStage($stageId: EntityId!) {
    stage(id: $stageId) {
      ... on UnrestrictedStage {
        id
        name
        activePackages {
          ...PackageSelectOption
          quotesDueAt
          importedFromPackage {
            id
          }
          lettingScheduleDetails {
            id
            letByDate
            assignedToUser {
              id
              fullName
              email
            }
          }
        }
        tenderQuotesDue
        builderContactName: contactName
        builderContactEmail: contactEmail
        builderContactPhone: contactPhone
        budgetAmount
        projectId
        account {
          id
          name
          primaryCountry {
            id
          }
        }
      }
    }
  }
  ${PackageSelectFragment.fragments.packageSelectOption}
`;

export const INVITE_TO_QUOTE_PRESELECTED_COMPANY = gql`
  query GetAddressBookCompany($companyId: EntityId!) {
    company(id: $companyId) {
      value: id
      label: name
    }
  }
`;
