import { gql } from '@apollo/client';

export const INVITE_TO_QUOTE_ACTIVE_STAGES = gql`
  query GetInviteToQuoteActiveStages {
    activeStages(stageTypes: [PROCUREMENT]) {
      ... on BuilderStageInterface {
        id
        name
        type
      }
    }
  }
`;
