import { gql } from '@apollo/client';

export const ADDRESS_BOOK_CONTACTS_BY_COMPANY_AND_PACKAGE = gql`
  query GetContactsByCompanyAndPackage($companyId: EntityId!, $packageId: EntityId!) {
    contactsToInvite(companyId: $companyId, packageId: $packageId) {
      contactDetail {
        userId
        id
        fullName
        firstName
        isPreferred
        email
        phone
      }
      isInvited
    }
  }
`;
