import { PureComponent, createRef, Fragment, RefObject } from 'react';
import debounce from 'lodash/debounce';
import { requestCompanySuggestions, CompanyResult } from './utils';
import { AddNewContactFormField } from '.';
import Icon from '../../../shared/Icon';
import Input from '../../../shared/Input';
import LoadingSpinner from '../../../shared/LoadingSpinner';
import styles from './styles.scss';

type CompanyProps = { name: string; phone?: string; address?: string };

const Company = ({ name, phone = '', address = '' }: CompanyProps) => (
  <div className={styles.company}>
    <div className={styles.company__name}>{name}</div>
    {phone && (
      <div className={styles.company__phone}>
        <Icon icon="phone" extraClass={styles.company__icon} />
        {phone}
      </div>
    )}
    <div className={styles.company__address}>
      <Icon icon="location-1" extraClass={styles.company__icon} />
      {address}
    </div>
  </div>
);

type SelectedCompanyProps = { selectedCompany: CompanyProps; onDeselect: () => void };

const SelectedCompany = ({ selectedCompany, onDeselect }: SelectedCompanyProps) => (
  <div className="form-group">
    <label className="control-label" htmlFor="companyName">
      Company Name
    </label>
    <div className={styles['selected-company']}>
      <div className={styles['selected-company__company']}>
        <Company {...selectedCompany} />
      </div>
      <button
        type="button"
        className={styles['selected-company__dismiss']}
        onClick={onDeselect}
        aria-label="Clear"
      >
        <Icon icon="close" />
      </button>
    </div>
  </div>
);

type CompanySearchProps = {
  companyName: string;
  emailAddress: string;
  errorText?: string;
  isNewCompany?: boolean;
  onChange: ({ name, value }: { name: AddNewContactFormField; value: string }) => void;
  onCompanySelection: (selection: CompanyResult) => void;
  onCompanyDeselection: () => void;
  onSwitchToNewCompany: () => void;
  hasSelectedCompany: boolean;
  selectedCompany?: Pick<CompanyResult, 'id' | 'name'>;
};

type CompanySearchState = {
  potentialCompanies: CompanyResult[];
  focused: boolean;
  fetchingSuggestions: boolean;
};

class CompanySearch extends PureComponent<CompanySearchProps, CompanySearchState> {
  static defaultProps: Partial<CompanySearchProps> = {
    errorText: undefined,
    isNewCompany: false,
    selectedCompany: undefined,
  };

  private readonly companyListRequest: JQueryXHR | null;

  private readonly containerRef: RefObject<HTMLDivElement>;

  constructor(props: CompanySearchProps) {
    super(props);
    this.companyListRequest = null;
    this.containerRef = createRef();
    this.state = {
      potentialCompanies: [],
      focused: false,
      fetchingSuggestions: false,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.adjustFocus);
  }

  componentDidUpdate(prevProps: Readonly<CompanySearchProps>) {
    const { companyName, emailAddress } = this.props;

    const companyNameUpdated = companyName.length > 0 && companyName !== prevProps.companyName;

    const addressUpdated = emailAddress.length > 0 && emailAddress !== prevProps.emailAddress;

    if (companyNameUpdated || addressUpdated) {
      this.setStateToFetching();
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.throttledRequestCompanyList();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.adjustFocus);
  }

  getExactMatchFromSuggestions = () => {
    const { potentialCompanies } = this.state;
    const { companyName } = this.props;

    return potentialCompanies.find(
      (company) => company.name.toLowerCase() === companyName.toLowerCase(),
    );
  };

  setStateToFetching = () => {
    const { fetchingSuggestions } = this.state;
    if (!fetchingSuggestions) {
      this.setState({ fetchingSuggestions: true });
    }
  };

  requestCompanyList = async () => {
    if (this.companyListRequest) {
      this.companyListRequest.abort();
    }

    const { companyName, emailAddress } = this.props;

    if (companyName.length < 3 && emailAddress.length < 10) {
      return;
    }

    await requestCompanySuggestions({ emailAddress, companyName }).then((values) => {
      this.setState({ potentialCompanies: values, fetchingSuggestions: false });
    });
  };

  // eslint-disable-next-line react/sort-comp
  throttledRequestCompanyList = debounce<() => Promise<void>>(this.requestCompanyList, 250);

  handleBlur = () => {
    const { focused } = this.state;
    const { onCompanySelection, onSwitchToNewCompany } = this.props;

    if (focused) {
      const matchingCompany = this.getExactMatchFromSuggestions();

      if (matchingCompany) {
        onCompanySelection(matchingCompany);
      } else {
        setTimeout(() => {
          const { hasSelectedCompany } = this.props;
          if (this.userHasStartedSearchingForCompany() && !hasSelectedCompany) {
            onSwitchToNewCompany();
          }

          this.setState({ focused: false });
        }, 200);
      }
    }
  };

  handleFocus = () => {
    const { focused } = this.state;

    if (!focused) {
      this.setState({ focused: true });
    }
  };

  adjustFocus = (event: MouseEvent) => {
    // @ts-ignore
    if (this.containerRef.current && this.containerRef.current.contains(event.current)) {
      this.handleFocus();
    } else {
      this.handleBlur();
    }
  };

  userHasStartedSearchingForCompany = () => {
    const { isNewCompany, companyName } = this.props;

    return !isNewCompany && companyName.length > 0;
  };

  shouldRenderDropdown = () => {
    const { focused, potentialCompanies } = this.state;
    const { hasSelectedCompany } = this.props;

    return (
      focused &&
      !hasSelectedCompany &&
      (potentialCompanies.length > 0 || this.userHasStartedSearchingForCompany())
    );
  };

  handleSwitchToNewCompany = () => {
    const { onSwitchToNewCompany } = this.props;

    this.setState({ focused: false });
    onSwitchToNewCompany();
  };

  renderCompanyInput = () => {
    const {
      hasSelectedCompany,
      selectedCompany,
      onCompanyDeselection,
      companyName,
      onChange,
      errorText,
    } = this.props;

    if (hasSelectedCompany && selectedCompany) {
      return (
        <SelectedCompany selectedCompany={selectedCompany} onDeselect={onCompanyDeselection} />
      );
    }

    const shouldRenderDropdown = this.shouldRenderDropdown();

    return (
      <Input
        className={shouldRenderDropdown ? 'squared-off-bottom-border' : ''}
        id="companyName"
        name="companyName"
        type="text"
        value={companyName}
        onChange={({ name, value }) => onChange({ name: name as AddNewContactFormField, value })}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        label="Company Name"
        errorText={shouldRenderDropdown ? '' : errorText}
      />
    );
  };

  renderPotentialCompanyList = () => {
    if (!this.shouldRenderDropdown()) {
      return null;
    }

    const { isNewCompany } = this.props;

    return (
      <div className={styles['potential-company']}>
        {this.renderCompanyList()}
        {!isNewCompany && this.renderNewCompanyOption()}
      </div>
    );
  };

  renderCompanyList = () => {
    const { fetchingSuggestions, potentialCompanies } = this.state;

    if (fetchingSuggestions) {
      return (
        <div className={styles['potential-company__header']}>
          <div className={styles['potential-company__loader']}>
            <div className={styles['potential-company__loader-text']}>
              Searching for existing companies&hellip;
            </div>
            <LoadingSpinner size="minute" inline />
          </div>
        </div>
      );
    }

    return potentialCompanies.length > 0 ? (
      this.renderPotentialCompanies()
    ) : (
      <div className={styles['potential-company__header']}>No matching companies found</div>
    );
  };

  renderPotentialCompanies = () => {
    const { potentialCompanies } = this.state;
    const { onCompanySelection } = this.props;

    return (
      <Fragment>
        <div className={styles['potential-company__header']}>
          Existing companies in your address book
        </div>
        {potentialCompanies.map((company) => (
          <button
            className={styles['potential-company__item']}
            key={company.id}
            type="button"
            onClick={(e) => {
              e.preventDefault();
              onCompanySelection(company);
            }}
          >
            <Company
              name={company.name}
              address={company.address}
              phone={company.phone ?? undefined}
            />
          </button>
        ))}
      </Fragment>
    );
  };

  renderNewCompanyOption = () => (
    <button
      id="add-new-company"
      type="button"
      className={styles['potential-company__new-company']}
      onClick={this.handleSwitchToNewCompany}
    >
      <Icon icon="plus" /> This is a new company
    </button>
  );

  render() {
    return (
      <section className="company-search" ref={this.containerRef}>
        {this.renderCompanyInput()}
        {this.renderPotentialCompanyList()}
      </section>
    );
  }
}

export default CompanySearch;
