import { Tag, TagVariant, FileIcon } from '@estimateone/frontend-components';
import { RfqQuotingStatus } from '@ascension/_gqltypes/builder.generated';

type TagDetails = {
  variant: TagVariant;
  label: string;
};

const getTagDetails = (quotingStatus: RfqQuotingStatus | null): TagDetails => {
  switch (quotingStatus) {
    case RfqQuotingStatus.DECLINED:
      return { variant: TagVariant.RfqStatusNotQuoting, label: 'NQ' };
    case RfqQuotingStatus.QUOTED:
      return { variant: TagVariant.RfqStatusQuoted, label: 'Quoted' };
    case RfqQuotingStatus.QUOTING:
      return { variant: TagVariant.RfqStatusQuoting, label: 'Quoting' };
    default:
      return { variant: TagVariant.RfqStatusNoResponse, label: 'No Response' };
  }
};

type QuotingStatusTagProps = {
  quotingStatus: RfqQuotingStatus | null;
  showIcon?: boolean;
  className?: string;
};

export const QuotingStatusTag = ({ quotingStatus, showIcon, className }: QuotingStatusTagProps) => {
  const { variant, label } = getTagDetails(quotingStatus);

  return (
    <Tag
      className={className}
      variant={variant}
      text={label}
      icon={variant === TagVariant.RfqStatusQuoted && showIcon ? <FileIcon /> : undefined}
    />
  );
};
