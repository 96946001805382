import { gql } from '@apollo/client';

export const CREATE_STAGE_PROCORE_MAP = gql`
  mutation CreateStageProcoreMap($input: CreateStageProcoreMapInput!) {
    createStageProcoreMap(input: $input) {
      ... on Stage {
        id
        name
      }
      ... on Errors {
        errors {
          message
        }
      }
    }
  }
`;

export const CREATE_STAGE_SHAREPOINT_MAP = gql`
  mutation CreateStageSharepointMap($input: CreateStageSharepointMapInput!) {
    createStageSharepointMap(input: $input) {
      ... on Stage {
        id
        name
      }
      ... on Errors {
        errors {
          message
        }
      }
    }
  }
`;

export const UPDATE_STAGE_SHAREPOINT_MAP = gql`
  mutation UpdateStageSharepointMap($input: UpdateStageSharepointMapInput!) {
    updateStageSharepointMap(input: $input)
  }
`;

export const TRIGGER_DOCSYNC_RECONCILE = gql`
  mutation TriggerDocsyncReconcile($stageId: EntityId!) {
    triggerDocsyncReconcile(stageId: $stageId)
  }
`;
