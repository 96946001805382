import { useField } from 'react-final-form';
import { RadioButton } from '@estimateone/frontend-components';
import { PreferredIndicator } from '@builder/common/PreferredIndicator';
import { RadioButtonGroupField } from '@shared/FinalForm/Field/RadioButtonGroupField';
import LoadingSpinner from '@shared/LoadingSpinner';
import { useAddressBookCompanyContacts } from './hooks';
import { InviteToQuoteFormFieldName } from '../../enums';
import { InviteToQuoteFormValues } from '../../types';
import styles from './styles.scss';

export const AddressBookContactsField = () => {
  const {
    input: { value: selectedCompany },
  } = useField<InviteToQuoteFormValues[InviteToQuoteFormFieldName.Company]>(
    InviteToQuoteFormFieldName.Company,
  );

  const {
    input: { value: selectedPackage },
  } = useField<InviteToQuoteFormValues[InviteToQuoteFormFieldName.Package]>(
    InviteToQuoteFormFieldName.Package,
  );

  const {
    input: { onChange, value: contactValue },
  } = useField<InviteToQuoteFormValues[InviteToQuoteFormFieldName.Contact]>(
    InviteToQuoteFormFieldName.Contact,
    {
      validate: (value) => (value ? undefined : ['Select one contact to invite to quote']),
    },
  );

  const { contacts, loading } = useAddressBookCompanyContacts(
    selectedCompany.value,
    selectedPackage.value,
  );

  if (!selectedCompany || !selectedPackage) return null;

  if (loading)
    return (
      <div className={styles.formField}>
        <LoadingSpinner />
      </div>
    );

  const handleChange = (selectedId: string) => {
    onChange(contacts?.find((contact) => contact?.contactDetail.id === parseInt(selectedId)));
  };

  return (
    <RadioButtonGroupField
      fieldName={InviteToQuoteFormFieldName.Contact}
      legend="Select Contact"
      onChange={handleChange}
      value={contactValue.contactDetail?.id.toString() ?? ''}
      isRequired
      className={styles.formField}
    >
      {contacts?.map(
        ({ contactDetail: { id, fullName, isPreferred, email, phone }, isInvited }) => (
          <RadioButton key={id} id={id.toString()} value={id.toString()} disabled={isInvited}>
            {isPreferred && <PreferredIndicator className={styles.preferredIndicator} />}
            <span className={styles.fullName}>
              {isInvited && 'Already invited: '}
              {fullName}
            </span>
            {email && <span className={styles.email}>{email}</span>}
            {phone && <span className={styles.phone}>{phone}</span>}
          </RadioButton>
        ),
      ) ?? []}
    </RadioButtonGroupField>
  );
};
