import { Button, ButtonSize, ButtonVariant } from '@estimateone/frontend-components';
import { useFlashMessage } from '@shared';
import { useIsNetworkConnectionsEnabled } from '@builder/context/AccountProvider/hooks';
import { useSearchForMatchingAddressBookCompany } from '@builder/features/SubbieNetwork/hooks';
import { useConnectProfileToAddressBook } from '@builder/features/SubbieNetwork/hooks/useConnectProfile';
import { useOpenSyncableCompanyModal } from '@builder/features/SyncCompanyProfile/hooks';
import { ModalTrigger } from '@builder/features/SyncCompanyProfile/types';

export type SubbieNetworkConnectProps = {
  profileId: string;
  name: string;
};

export const SubbieNetworkConnectButton = ({ profileId, name }: SubbieNetworkConnectProps) => {
  const { searchMatchingCompanies, searchMatchingCompaniesLoading } =
    useSearchForMatchingAddressBookCompany();
  const { connectProfileToAddressBook, connectProfileToAddressBookLoading } =
    useConnectProfileToAddressBook();
  const isNetworkConnectionsEnabled = useIsNetworkConnectionsEnabled();
  const { openModal: openSyncModal } = useOpenSyncableCompanyModal();
  const { success, warning } = useFlashMessage();

  const addToAddressBook = async () => {
    const result = await connectProfileToAddressBook(profileId, name);

    if (result !== null && result !== undefined) {
      success({
        title: 'Success!',
        message: 'You have successfully connected with this company.',
      });
      // reloadPage();
    } else {
      warning({
        title: 'Error',
        message: 'Something went wrong when trying to Connect. Please try again.',
      });
    }
  };

  const onClickHandler = async (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    e.stopPropagation(); /* prevent the vcard slider from opening */

    const { data, loading } = await searchMatchingCompanies({
      variables: {
        search: {
          profileId,
        },
      },
    });

    const matchingCompanies = data?.searchMatchingProfiles.results;

    const searched = data?.searchMatchingProfiles.searched;

    if (loading) return;

    const hasAddressBookMatch = searched && matchingCompanies?.length === 1;

    if (hasAddressBookMatch) {
      openSyncModal({
        data: {
          searchedCompanyId: searched.id,
        },
        modalTriggeredBy: ModalTrigger.E1_NETWORK_COMPANY,
      });
      return;
    }

    await addToAddressBook();
  };

  return (
    <div>
      {isNetworkConnectionsEnabled && (
        <Button
          variant={ButtonVariant.Secondary}
          size={ButtonSize.Small}
          onClick={(e) => onClickHandler(e)}
          disabled={searchMatchingCompaniesLoading || connectProfileToAddressBookLoading}
        >
          Connect
        </Button>
      )}
    </div>
  );
};
