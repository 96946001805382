import { useCallback, useEffect } from 'react';
import Routing from 'routing';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  DrawerBody,
  DrawerToolbar,
  joinClassNames,
} from '@estimateone/frontend-components';
import { captureException } from '@sentry/browser';
import E1Request from '@ascension/js/classes/E1Request';
import {
  DrawerEvent,
  UNIVERSAL_PROFILE_DRAWER_READY_EVENT,
  UNIVERSAL_PROFILE_DRAWER_TAB_CONTAINER_ID,
} from '@builder/pages/Profiles/UniversalProfileDrawer';
import {
  CompanyHeader,
  CompanyInfo,
  CompanyNameLocation,
} from '@builder/pages/Profiles/UniversalProfileDrawer/components/DrawerHeader';
import { DrawerLoadingSpinner } from '@builder/pages/Profiles/UniversalProfileDrawer/components/DrawerLoadingSpinner';
import { CompanyLogo } from './components/CompanyLogo';
import { CompanyNameHeader } from './components/CompanyNameHeader';
import { UniversalNetworkProfile } from './providers/UniversalProfileProvider';
import useFlashMessage from '@shared/Util/useFlashMessage';
import { useCompanyLogoURL } from './providers/UniversalProfileProvider/hooks';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

type AddToAddressBookSuccessResponse = {
  success: true;
  companyId: number;
  companyName: string;
  flash_notification: string;
};

type AddToAddressBookErrorResponse = {
  success: false;
  message: string;
};

export const ShadowProfileDrawerContent = ({
  universalProfile,
  canViewAddressBook,
  onConnectSuccess,
}: {
  readonly universalProfile: UniversalNetworkProfile;
  readonly canViewAddressBook: boolean;
  readonly onConnectSuccess?: (companyId: EntityId) => void;
}) => {
  const { warning: showErrorMessage } = useFlashMessage();

  const url = useCompanyLogoURL();

  const { profile } = universalProfile;
  const profileId = universalProfile?.profile.id;

  const defaultOffice = profile?.offices.find(({ isDefaultOffice }) => isDefaultOffice);

  const companyName = profile?.name ?? '';
  const shortAddress = defaultOffice?.address.shortAddress;

  // tell Jquery that it's time to render the body into the specified div
  useEffect(() => {
    if (profileId) {
      // This event will cause the jQuery code in vcard_slider.js to make a call to Ascension to
      // render the twig template with the tab container, and then inject that rendered HTML into
      // the container below. It needs to happen after this component has finished rendering.
      const eventData: DrawerEvent = {
        detail: {
          profileId,
        },
      };
      document.dispatchEvent(new CustomEvent(UNIVERSAL_PROFILE_DRAWER_READY_EVENT, eventData));
    }
  }, [profileId]);

  const addToAddressBook = useCallback(async () => {
    try {
      const route = Routing.generate('app_addressbookcompany_addfrome1profile', { profileId });
      // E1Request will show the success flash message
      // Set E1Request `passive` param to true to handle error flash messages ourselves
      const result = await new E1Request<
        AddToAddressBookErrorResponse | AddToAddressBookSuccessResponse
      >(route, 'POST', undefined, true).submit();

      if (result.success) {
        if (onConnectSuccess) onConnectSuccess(result.companyId);
        document.dispatchEvent(
          new CustomEvent('add_company_from_e1_profile', {
            detail: {
              companyId: result.companyId,
              profileId,
            },
          }),
        );
      } else {
        showErrorMessage({
          title: 'Error',
          message: 'Something went wrong when trying to Connect. Please try again.',
        });
      }
    } catch (error) {
      captureException(error);
      showErrorMessage({
        title: 'Error',
        message: 'Something went wrong when trying to Connect. Please try again.',
      });
    }
  }, [onConnectSuccess, profileId, showErrorMessage]);

  return (
    <>
      <DrawerToolbar>
        {canViewAddressBook && (
          <Button
            variant={ButtonVariant.Secondary}
            size={ButtonSize.Small}
            data-profile-id={profileId}
            onClick={addToAddressBook}
          >
            Connect
          </Button>
        )}
      </DrawerToolbar>
      <DrawerBody className={joinClassNames(styles.noPadding, styles.drawerBodyContainer)}>
        <CompanyHeader>
          <CompanyLogo url={url ?? null} companyName={companyName} />
          <CompanyInfo>
            <CompanyNameLocation>
              <CompanyNameHeader companyName={companyName} connected={false} />
              {shortAddress ? <div>{shortAddress}</div> : null}
            </CompanyNameLocation>
          </CompanyInfo>
        </CompanyHeader>
        <div
          data-testid={UNIVERSAL_PROFILE_DRAWER_TAB_CONTAINER_ID}
          id={UNIVERSAL_PROFILE_DRAWER_TAB_CONTAINER_ID}
          data-profile-id={profileId}
          className={styles.drawerTabContainer}
        >
          <DrawerLoadingSpinner />
        </div>
      </DrawerBody>
    </>
  );
};
