import { ValueLabelOption } from '@estimateone/frontend-components/src/components/Form/Select/types';

export type SelectedPlace = {
  lat: number;
  lon: number;
  locationLabel: string;
  adminLevel1?: string;
  country?: string;
  resultTypes?: string[];
};

export type GoogleResultOption = {
  placeId: string;
};

export type E1ResultOption = {
  lat: number;
  lon: number;
};

export type LocationResultOption = GoogleResultOption | E1ResultOption;

export const isGoogleResultOption = (option: LocationResultOption): option is GoogleResultOption =>
  (option as GoogleResultOption).placeId !== undefined;

export const selectedPlaceToOption = (
  option: SelectedPlace,
): ValueLabelOption<LocationResultOption> => ({
  value: {
    lat: option.lat,
    lon: option.lon,
  },
  label: option.locationLabel,
});
