import { StylesConfig, GroupBase } from 'react-select';
import colours from '@ascension/css/js-colours.scss';
import { InterestLevel } from '@ascension/_gqltypes/subbie.generated';

const { INTERESTED, QUOTED, QUOTING, NOT_INTERESTED, UNACTIONED } = InterestLevel;

const colourStatusMap = new Map([
  [INTERESTED, colours.interested],
  [QUOTING, colours.quoting], // orange
  [QUOTED, colours.quoted], // green
  [NOT_INTERESTED, colours.notInterested], // red
]);

const iconStatusMap = new Map([
  [INTERESTED, '\\ea39'],
  [QUOTING, '\\ea35'],
  [QUOTED, '\\ea36'],
  [NOT_INTERESTED, '\\ea37'],
]);

const getColourWithDefault = (status: InterestLevel) =>
  colourStatusMap.get(status) || colours.unactioned;
const getIconWithDefault = (status: InterestLevel) => iconStatusMap.get(status) || '';

export const hex2rgba = (hex: string, alpha = 1.0) => {
  const matches = hex.match(/[0-9A-Fa-f]{2}/g);
  if (matches && matches.length === 3) {
    const [r, g, b] = matches.map((x) => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
  }
  throw Error(`Invalid colour hex string provided: '${hex}'`);
};

const calcStyles = <Option, IsMulti extends boolean>(
  selected: InterestLevel,
): StylesConfig<Option, IsMulti, GroupBase<Option>> => {
  const statusColour = getColourWithDefault(selected);
  const backgroundColour = selected !== UNACTIONED ? hex2rgba(statusColour, 0.1) : colours.white;
  const backgroundHoverColour = hex2rgba(statusColour, 0.25);
  const icon = getIconWithDefault(selected);

  return {
    container: (base) => ({
      ...base,
      minWidth: '160px',
    }),
    control: (base) => ({
      ...base,
      cursor: 'pointer',
      backgroundColor: backgroundColour,
      borderColor: statusColour,
      '&:hover': {
        borderColor: statusColour,
        backgroundColor: backgroundHoverColour,
      },
    }),
    singleValue: (base) => ({
      ...base,
      fontSize: '.75rem',
      color: selected !== UNACTIONED ? statusColour : colours.iron600,
      alignItems: 'center',
      display: 'flex',
      ':before': {
        fontSize: '1.2em',
        fontWeight: 'normal',
        marginRight: '0.5rem',
        fontFamily: 'nucleo',
        content: `'${icon}'`,
      },
    }),
    valueContainer: (base) => ({
      ...base,
      paddingRight: '0px',
      paddingLeft: '8px',
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingLeft: '5px',
      paddingRight: '8px',
      color: selected !== UNACTIONED ? statusColour : colours.iron600,
      '&:hover': {
        color: selected !== UNACTIONED ? statusColour : colours.iron600,
      },
    }),
    option: (base) => ({
      ...base,
      fontSize: '.8rem',
    }),
  };
};

export default calcStyles;
