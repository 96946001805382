import {
  GetConnectionSuggestions_connectionSuggestions_addressBookCompany_address as AddressBookCompanyAddress,
  GetConnectionSuggestions_connectionSuggestions_profile_offices_address as ProfileOfficeAddress,
  GetConnectionSuggestions_connectionSuggestions_snippets as Snippets,
  GetConnectionSuggestions_connectionSuggestions_addressBookCompany_activeContacts as AddressBookCompanyContacts,
  GetConnectionSuggestions_connectionSuggestions_profile_offices as ProfileOffice,
  GetConnectionSuggestions_connectionSuggestions_profile_people as ProfilePeople,
} from './types/GetConnectionSuggestions';

export const getEmail = (snippets: Snippets[]): string | null => {
  const email = snippets.find((snippet) => snippet.type === 'USER_EMAIL');

  return email?.value[0] ?? null;
};

export const addressBookContact = (
  contacts: AddressBookCompanyContacts[],
  snippetEmail: string | null,
) => {
  if (!contacts.length || !snippetEmail) return null;
  const foundContact = contacts.find((contact) => contact.email === snippetEmail);

  if (typeof foundContact?.email !== 'string' || typeof foundContact?.fullName !== 'string') {
    return null;
  }

  return {
    email: foundContact.email,
    fullName: foundContact.fullName,
  };
};

export const profileContact = (contacts: ProfilePeople[], snippetEmail: string | null) => {
  if (!contacts || !snippetEmail) return null;

  const foundContact = contacts.find((contact) => contact.contactable?.email === snippetEmail);

  if (
    typeof foundContact?.contactable?.email !== 'string' ||
    typeof foundContact?.fullName !== 'string'
  ) {
    return null;
  }

  return {
    email: foundContact.contactable.email,
    fullName: foundContact.fullName,
  };
};

const isNotNull = (value: unknown): value is string => value !== null;

export const officeAddress = (
  officeName: string,
  address: AddressBookCompanyAddress | ProfileOfficeAddress | null,
) => {
  if (!address) return null;

  const { address1, address2, address3, shortAddress, postcode } = address;
  const area = `${shortAddress ?? ''} ${postcode ?? ''}`.replace(/\s+/, ' ');

  return [officeName, address1, address2, address3, area].filter<string>(isNotNull);
};

export const defaultOffice = (offices: ProfileOffice[]): ProfileOffice | null => {
  if (!offices?.length) return null;

  return offices.find((office) => office.isDefaultOffice) ?? offices[0];
};
