import { SortByFn } from '@estimateone/frontend-components';
import { Rfq } from '../../types';
import { RfqQuotingStatus } from '@ascension/_gqltypes/builder.generated';

export const quoteStatusSort: SortByFn<Rfq> = (rowA, rowB) => {
  const rowAQuoteStatus = rowA.values.quotingStatus;
  const rowBQuoteStatus = rowB.values.quotingStatus;

  const quoteStatusOrder = [
    RfqQuotingStatus.QUOTED,
    RfqQuotingStatus.QUOTING,
    RfqQuotingStatus.DECLINED,
    null,
  ];

  if (rowAQuoteStatus === rowBQuoteStatus) {
    return 0;
  }

  if (quoteStatusOrder.indexOf(rowAQuoteStatus) < quoteStatusOrder.indexOf(rowBQuoteStatus)) {
    return 1;
  }

  return -1;
};
