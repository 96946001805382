import { ReactNode } from 'react';
import { Wrapper } from '@estimateone/frontend-components';
import ProfilesNav from '../ProfilesNav';
import ProfilesHeader from './ProfilesHeader';
import { ProfileSection } from '../enums';
import { SubbieProfileForAccount_subbieProfileForAccount as SubbieProfile } from '../../../builder/pages/Profiles/SubbieProfilesPage/types/SubbieProfileForAccount';
import styles from './styles.scss';

export type ProfilesPageLayoutProps = {
  children: ReactNode;
  profile: SubbieProfile;
  currentSection: ProfileSection;
  setSection: (section: ProfileSection) => void;
  returnUrl: string;
};

const ProfilesPageLayout = ({
  children,
  profile,
  currentSection,
  setSection,
  returnUrl,
}: ProfilesPageLayoutProps) => (
  <>
    <div className={styles.headerContainer}>
      <Wrapper>
        <ProfilesHeader profile={profile} returnUrl={returnUrl} />
        <ProfilesNav currentSection={currentSection} setSection={setSection} />
      </Wrapper>
    </div>
    {children}
  </>
);

export default ProfilesPageLayout;
