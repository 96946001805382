import { useQuery } from '@apollo/client';
import { CheckIcon, ClearIcon } from '@estimateone/frontend-components';
import styles from './TradesSection.module.scss';
import { useIsInCreditLiteExperiment } from '@subbie/context/AccountProvider/hooks';
import { MATCHING_TRADES } from './queries';
import { MatchingStockTrades, MatchingStockTradesVariables } from './types/MatchingStockTrades';

const MAX_MATCHING_TRADES_TO_VIEW = 3;

type TradesSectionProps = {
  projectId: number;
  isOutOfUnlocks?: boolean;
};

export const TradesSection = ({ projectId, isOutOfUnlocks = false }: TradesSectionProps) => {
  const { data } = useQuery<MatchingStockTrades, MatchingStockTradesVariables>(MATCHING_TRADES, {
    variables: { projectId },
    fetchPolicy: 'no-cache',
  });
  const isInSubbieCreditLiteExperiment = useIsInCreditLiteExperiment();

  if (data === undefined) {
    return <span />;
  }

  const { matchingStockTradeCount, matchingStockTrades, stockTradeCount } =
    data.projectStockTradesMatchingCurrentAccountsTrades;

  return (
    <div className={styles.tradeSection}>
      <div className={styles.tradesHeaderCreditExperiment}>Trades</div>
      <p className={styles.tradesInvolved}>
        {matchingStockTradeCount > 0
          ? `${stockTradeCount} trades are involved in this project, including ${matchingStockTradeCount} of your trades.`
          : `${stockTradeCount} trades are involved in this project.`}
      </p>
      <ul className={styles.matchingTrades}>
        {matchingStockTrades.slice(0, MAX_MATCHING_TRADES_TO_VIEW).map((trade) => (
          <li
            key={trade.name}
            className={isOutOfUnlocks ? styles.outofUnlockMatching : styles.matchingTrade}
          >
            <CheckIcon
              className={isOutOfUnlocks ? styles.outOfUnlocksIcon : styles.hasUnlocksIcon}
            />
            <span className={styles.matchingTradeName}>{trade.name}</span>
          </li>
        ))}
      </ul>
      {matchingStockTradeCount > MAX_MATCHING_TRADES_TO_VIEW && (
        <p className={styles.andMore}>And more...</p>
      )}
      {isInSubbieCreditLiteExperiment && matchingStockTradeCount === 0 && (
        <div className={styles.noTradeMatches}>
          <ClearIcon />
          <span className={styles.noTradeMatchesText}>No trade matches</span>
        </div>
      )}
    </div>
  );
};
