import { ReactElement } from 'react';
import { Icon, IconName, Tag, TagProps, TagVariant } from '@estimateone/frontend-components';
import { LettingScheduleStatus } from '../LettingScheduleStatus';

type LettingScheduleStatusLabelProps = {
  status: LettingScheduleStatus;
  isActive?: boolean;
  isScheduleTag?: boolean;
};

export const LettingScheduleStatusLabel = ({
  status,
  isActive,
  isScheduleTag,
}: LettingScheduleStatusLabelProps) => {
  const showLettingScheduleStatus = (): ReactElement<TagProps> => {
    switch (status) {
      case LettingScheduleStatus.Pricing: {
        const variant = isScheduleTag
          ? TagVariant.Practical100
          : isActive
            ? TagVariant.Practical900
            : TagVariant.RfqStatusNoResponse;

        return <Tag variant={variant} text="Pricing" />;
      }
      case LettingScheduleStatus.Awarded: {
        const variant = isActive ? TagVariant.Magic200 : TagVariant.RfqStatusNoResponse;

        return (
          <Tag variant={variant} text="Awarded" icon={<Icon name={IconName.Awarded} size="12" />} />
        );
      }
      case LettingScheduleStatus.Draft:
      default: {
        const variant = isActive ? TagVariant.Practical900 : TagVariant.RfqStatusNoResponse;

        return <Tag variant={variant} text="Draft" />;
      }
    }
  };

  return <>{showLettingScheduleStatus()}</>;
};
