import { gql } from '@apollo/client';

export const PackageDashboardRfq = gql`
  fragment PackageDashboardRfq on Rfq {
    id
    contact {
      phone
      fullName
      company {
        id
        name
      }
    }
    quoteDueAt
    quotingStatus
    notQuotingReasonText
    downloadedLastAt
    noteCount
    latestQuote {
      id
      amount
      updatedAt
    }
    emailError
  }
`;

export const ImportedFromPackage = gql`
  fragment ImportedFromPackage on Package {
    id
    contentLastAddedAt
    activeRfqs {
      id
      emailError
      stageId
      latestQuote {
        id
        amount
        updatedAt
      }
      companyName
      contact {
        id
        fullName
        phone
        email
        company {
          id
          name
        }
      }
      quotingStatus
      notQuotingReasonText
      downloadedLastAt
      noteCount
    }
  }
`;
