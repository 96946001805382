import $ from 'jquery';
import Routing from 'routing';
import { escape } from 'lodash';
import E1Request from '../classes/E1Request';
import Form from '../classes/Form';
import Notification from '../classes/Notification';

function UserList(target) {
  this.data = [];
  this.$target = target;
  this.table = null;

  const self = this;

  self.fetch().then(() => self.init(self));
}

$(() => {
  const $usersTable = $('table.usersTable');
  let usersTable = null;
  if ($usersTable.length) {
    usersTable = new UserList($usersTable);
  }

  const $emailNotification = $('div.emailNotification');

  const notification = $emailNotification.length ? new Notification($emailNotification) : null;

  const $body = $('body');

  const getRequestForEvent = (route, event) => {
    const id = $(event.currentTarget).data('id');
    const url = Routing.generate(route, { id });

    return new E1Request(url);
  };

  /**
   * @param {string} fullName
   */
  const updateDropdownMenuUserDetails = (fullName) =>
    $body.find('.userinfo-username').text(fullName);

  $body
    .on('click', '.add-user-modal', async () => {
      const route = Routing.generate('app_accountuser_newmodal');
      const req = new E1Request(route);
      await req.setShowLoadingModal().submit();
    })
    .on('click', '.edit-user-modal', async (editUserClick) => {
      const req = getRequestForEvent('app_accountuser_editmodal', editUserClick);
      await req.setShowLoadingModal().submit();
    })
    .on('click', '.delete-user-modal', async (deleteUserClick) => {
      const req = getRequestForEvent('app_accountuser_deletemodal', deleteUserClick);
      await req.setShowLoadingModal().submit();
    })
    .on('click', '.resend-user-link', async (resendLinkClick) => {
      const req = getRequestForEvent('app_accountuser_resend', resendLinkClick);
      await req.submit();
    })
    .on('submit', 'form.add-user-form', async (addUserFormSubmitEvent) => {
      addUserFormSubmitEvent.preventDefault();

      const magicForm = new Form($(addUserFormSubmitEvent.currentTarget));
      magicForm.extraCallback = () => {};
      await magicForm.submit();
      if (usersTable) {
        usersTable.updateTable(true);
      }
      return false;
    })
    .on('submit', 'form.edit-user-form', async (editUserFormSubmitEvent) => {
      editUserFormSubmitEvent.preventDefault();

      const $form = $(editUserFormSubmitEvent.currentTarget);
      const magicForm = new Form($form);
      const $emailField = $form.find('.displayed-email');

      const getFullName = () =>
        ['first', 'last'].map((pre) => $form.find(`.${pre}-name`).val()).join(' ');

      const response = await magicForm.submit();
      if (response.success) {
        // reset the email address to its previous value
        const currentEmail = $form.find('.current-email').val();

        if ($emailField.val() !== currentEmail) {
          await notification.refresh();
          $emailField.parent().addClass('form-group-email-change-pending');
          $emailField.val(currentEmail);
        }
      }
      notification.invalidate();
      updateDropdownMenuUserDetails(getFullName());
      return false;
    });
});

UserList.prototype.init = (self) => {
  const nameRender = (data) => (data === null ? '_' : escape(data));

  const accessModeRender = (data, type) => {
    // Return a numeric value for sorting purposes
    if (type === 'sort') {
      return (data.tendering_mode_granted ? 1 : 0) + (data.construction_mode_granted ? 2 : 0);
    }

    const userModes = [];

    if (data.tendering_mode_granted) {
      userModes.push('estimating');
    }

    if (data.construction_mode_granted) {
      userModes.push('construction');
    }

    if (userModes.length) {
      return `<span class="contact-type-indicator ${userModes.join(
        ' ',
      )}" data-tip-suffix=""></span>`;
    }

    return '';
  };

  const actionsRender = (data, display, user) => {
    const userId = user.id;
    const $actionList = $('<ul>')
      .addClass('dropdown-menu')
      .append(
        $('<li>').append(
          $('<a>')
            .attr('role', 'button')
            .addClass('edit-user-modal')
            .attr('data-id', userId)
            .text('Edit Profile'),
        ),
      );
    if (!user.has_sso) {
      $actionList.append(
        $('<li>').append(
          $('<a>')
            .attr('role', 'button')
            .addClass('resend-user-link')
            .attr('data-id', userId)
            .text('Resend Access Link'),
        ),
      );
    }
    $actionList
      .append($('<li>').addClass('divider'))
      .append(
        $('<li>').append(
          $('<a>')
            .attr('role', 'button')
            .addClass('delete-user-modal')
            .attr('data-id', userId)
            .text('Delete User'),
        ),
      );
    const $buttonGroup = $('<div>')
      .addClass('dropdown pull-right')
      .append(
        $('<button>')
          .addClass('btn btn-icon btn-transparent dropdown-toggle')
          .attr('data-toggle', 'dropdown')
          .append($('<i>').addClass('icon icon-more')),
      )
      .append($actionList);

    return $buttonGroup.prop('outerHTML');
  };

  const emailRender = (data, type, row) => {
    if (type === 'display') {
      const $email = $('<span>').text(data);
      if (row.hasPendingEmailChange) {
        $email.append(
          $('<span class="label label-default">').text(
            self.$target.data('email-changed-placeholder'),
          ),
        );
      }

      return $email.html();
    }

    return data;
  };

  const columnsWithModes = [
    {
      data: 'full_name',
      render: nameRender,
    },
    {
      data: 'accessLevel',
      render: $.fn.dataTable.render.text(),
    },
    {
      data: null,
      class: 'text-center',
      render: accessModeRender,
    },
    {
      data: 'email',
      class: 'user-email',
      render: emailRender,
    },
    {
      data: null,
      class: 'options',
      orderable: false,
      render: actionsRender,
    },
  ];

  const normalColumns = [
    {
      data: 'full_name',
      render: nameRender,
    },
    {
      data: 'accessLevel',
      render: $.fn.dataTable.render.text(),
    },
    {
      data: 'email',
      class: 'user-email',
      render: emailRender,
    },
    {
      data: null,
      class: 'options',
      orderable: false,
      render: actionsRender,
    },
  ];

  self.table = self.$target.DataTable({
    paging: false,
    data: self.data,
    columns: self.$target.find('th.active-modes').length > 0 ? columnsWithModes : normalColumns,
    dom: 'Rrtp',
  });

  $('body').on('keyup', '.custom-table-search', (e) => {
    const $searchInput = $(e.currentTarget);
    self.table.search($searchInput.val()).draw();
  });

  self.$target.closest('.loading-container').addClass('has-loaded');
};

UserList.prototype.fetch = async function (callback) {
  const self = this;

  const route = Routing.generate('app_accountuser_fetch');
  const request = new E1Request(route, 'GET');
  request.extraCallback = (response) => {
    self.data = [];

    response.users.forEach((user) => {
      self.data.push({
        ...user,
        DT_RowId: `dt_user_${user.id}`,
        hasPendingEmailChange: (response.email_changes || []).includes(user.id),
      });
    });

    if (typeof callback !== 'undefined') {
      callback(self);
    }
  };
  await request.submit();
};

UserList.prototype.drawTable = function () {
  const self = this;
  this.table.rows().remove();

  this.table.rows.add(self.data).draw();
};

UserList.prototype.updateTable = async function (fetch) {
  const self = this;
  if (fetch) {
    await self.fetch($.proxy(self.drawTable, self));
  } else {
    self.drawTable();
  }
};
