import { useEffect } from 'react';
import LicenseRowsWrapper from './LicenseRowsWrapper';
import PageLoadingSpinner from '../../../../shared/LoadingSpinner/PageLoadingSpinner';
import { useGetLicensesTableResultQuery } from '../shared/hooks';
import { ListenerEvent } from '@ascension/enums';
import { AccountType } from '@ascension/_gqltypes/subbie.generated';
import styles from '../shared/styles.scss';

const LicenseTable = () => {
  const { data, refetch } = useGetLicensesTableResultQuery();

  useEffect(() => {
    // to be replaced after we React-ify the update credit card modal
    document.addEventListener(ListenerEvent.AccountLicenseCreditCardUpdated, () => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      refetch();
    });
  }, []);

  if (data === undefined) return <PageLoadingSpinner />;

  const { currentAccount, activeLicenses, sharedLicenses } = data;

  const {
    type: accountType,
    licensingInfo: { paidLicenseProvider, hasAtLeastOneFullPriceActiveMultiStateLicense },
    isMultiState,
  } = currentAccount;

  const yourLicenseText = `Your license${activeLicenses.length > 1 ? 's' : ''}`;
  const sharedLicenseText = `Shared license${sharedLicenses.length > 1 ? 's' : ''}`;

  const renewThText =
    accountType === AccountType.SUBCONTRACTOR || accountType === AccountType.SUPPLIER
      ? 'Auto Renew Date'
      : 'Renewal Date';

  return (
    <table className={styles.licenseTable}>
      <thead>
        <tr>
          <th>Product</th>
          <th>Price (inc. Tax)</th>
          <th>Billing Cycle</th>
          <th>Start Date</th>
          <th>{renewThText}</th>
          <th>Credit Card</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colSpan={6}>
            <h4>{yourLicenseText}</h4>
          </td>
        </tr>
        <LicenseRowsWrapper
          licenses={activeLicenses}
          accountType={accountType}
          hasAtLeastOneFullPriceActiveMultiStateLicense={
            hasAtLeastOneFullPriceActiveMultiStateLicense
          }
          isMultiState={isMultiState}
          paidLicenseProviderName={paidLicenseProvider?.name}
        />
        {sharedLicenses.length !== 0 && (
          <>
            <tr>
              <td colSpan={6}>
                <h4 className={styles.sharedLicenseHeading}>{sharedLicenseText}</h4>
                <span>Paid for by this account</span>
              </td>
            </tr>
            <LicenseRowsWrapper
              licenses={sharedLicenses}
              showSharedLicenseAccountName
              accountType={accountType}
              hasAtLeastOneFullPriceActiveMultiStateLicense={
                hasAtLeastOneFullPriceActiveMultiStateLicense
              }
              isMultiState={isMultiState}
              paidLicenseProviderName={paidLicenseProvider?.name}
            />
          </>
        )}
      </tbody>
    </table>
  );
};

export default LicenseTable;
