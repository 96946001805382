import { useContext } from 'react';
import { idAndNameToOption } from '../../utils';
import { NetworkV2AscensionDataContext } from './context';
import { Country } from '@ascension/enums';

export const useAscensionAccountData = () => useContext(NetworkV2AscensionDataContext);

export const useUserImperialSetting = () => {
  const ascAccountData = useAscensionAccountData();
  return ascAccountData?.currentUser.imperialUnitsSetting;
};

export const useTradesOptions = () => {
  const ascAccountData = useAscensionAccountData();
  return ascAccountData ? ascAccountData.stockTrades.map(idAndNameToOption) : null;
};

export const useStockTradeList = () => {
  const ctx = useAscensionAccountData();

  return ctx ? ctx.stockTrades : undefined;
};

export const useCategoryOptions = () => {
  const ascAccountData = useAscensionAccountData();
  return ascAccountData ? ascAccountData.stageCategories.map(idAndNameToOption) : null;
};

export const useCompanyTags = () => {
  const ascAccountData = useAscensionAccountData();
  return ascAccountData ? ascAccountData.companyTags?.map(idAndNameToOption) : null;
};

export const useAccountPrimaryCountryId = () => {
  const ascAccountData = useAscensionAccountData();
  return ascAccountData ? ascAccountData?.currentUser.account?.primaryCountry?.id : null;
};

export const useInCountry = (id: number): boolean => {
  const ascAccountData = useAscensionAccountData();
  return ascAccountData?.currentUser.account?.primaryCountry?.id === id || false;
};

export const useIsAustralianAccount = (): boolean => useInCountry(Country.COUNTRY_AUSTRALIA);
