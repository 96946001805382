/* eslint-disable fp/no-mutation */
/* eslint-disable fp/no-let */
import $ from 'jquery';
import Routing from 'routing';
import E1Request, { E1Response } from '../classes/E1Request';
import { E1PDFViewerLauncher } from '../utils/pdf_doc_viewer';
import { sendSpeciFinderInvitationAnalytics } from './utils/sf_invitations_analytics';

class RfqDownload {
  rfqId: number;
  downloadFrom: number | undefined;
  $modal: JQuery<HTMLElement> | null;
  correspondence_id: number | null;
  download_url: string | null;

  constructor(rfqId: number, downloadFrom?: number) {
    this.rfqId = rfqId;
    this.downloadFrom = downloadFrom;
    this.$modal = null;
    this.correspondence_id = null;
    this.download_url = null;
  }

  submit = async () => {
    await this.startModal();
    await this.startDownload();
  };

  startDownload = async () => {
    const downloadReqUrl = Routing.generate(
      'app_rfq_download',
      this.downloadFrom ? { id: this.rfqId, downloadFrom: this.downloadFrom } : { id: this.rfqId },
    );
    const downloadReq = new E1Request<
      E1Response & { downloadUrl?: string; correspondence_id?: number }
    >(downloadReqUrl);
    const downloadResp = await downloadReq.submit();

    if (typeof downloadResp.downloadUrl !== 'undefined') {
      this.download_url = downloadResp.downloadUrl;
    }
    if (typeof downloadResp.correspondence_id !== 'undefined') {
      this.correspondence_id = downloadResp.correspondence_id;
    }
    this.complete();
  };

  startModal = async () => {
    const modalReqUrl = Routing.generate('app_rfq_downloadmodal', {
      id: this.rfqId,
    });
    const modalReq = new E1Request(modalReqUrl);
    modalReq.show_loading_modal = true;
    const modalResp = await modalReq.submit();

    if (modalResp.success) {
      this.$modal = $('.e1-modal.e1-download-modal');
      this.complete();
    }
  };

  complete = () => {
    if (this.$modal != null) {
      const leadsPageContainer = document.getElementById('leads-management');
      if (leadsPageContainer) {
        const event = new CustomEvent('leads-upgrade-hook-start');
        leadsPageContainer.dispatchEvent(event);
      }

      if (this.download_url != null) {
        window.location.href = this.download_url;
      }
      const $alert = this.$modal.find('.transmittal-alert-ctn');
      const $headline = this.$modal.find('.headline');
      const $icon = this.$modal.find('i.loading');
      $headline.text('Your download has begun.');
      $icon.attr('class', '');
      $icon.addClass('icon icon-xxl icon-download info');

      if (this.correspondence_id != null) {
        const $corrOptions = $alert.find('.correspondence-issue-options');
        const correspondenceUrl = Routing.generate('app_rfqcorrespondence_view', {
          id: this.correspondence_id,
        });
        $corrOptions.find('.transmittal-link').attr('href', correspondenceUrl);
        $corrOptions
          .find('.email-transmittal-trigger')
          .attr('data-correspondence-id', this.correspondence_id);
        $corrOptions.removeClass('hide');
      }
    }
  };
}

$(async () => {
  const $body = $('body');

  $body.on('click', '.rfq-confirm-download-link-trigger', async function getConfirmModal() {
    const rfqId = $(this).attr('data-rfq-id');

    const modalReqUrl = Routing.generate('app_rfq_confirm_downloadmodal', {
      id: rfqId,
    });
    const modalReq = new E1Request(modalReqUrl);
    modalReq.show_loading_modal = true;
    await modalReq.submit();
  });

  $body.on('click', '.rfq-download-link-trigger', async function donwloadRfq() {
    const rfqIdValue = $(this).attr('data-rfq-id');
    const rfqId = parseInt(rfqIdValue ?? '0');
    sendSpeciFinderInvitationAnalytics(rfqId, 'SF_DocumentsDownload', window.location.pathname);

    const rfqDownload = new RfqDownload(rfqId);
    await rfqDownload.submit();

    let docStatusElem = $(this).closest('tr').find('.doc_status');
    if (!docStatusElem.length) {
      docStatusElem = $(`#rfq_doc_status_${rfqId}`);
    }
    if (docStatusElem.length) {
      docStatusElem.removeClass('obsolete');
      docStatusElem.removeClass('not-downloaded');
      docStatusElem.addClass('current');
      docStatusElem.text('Current');

      const documentCurrentTitle = docStatusElem
        .closest('table')
        .attr('data-document-current-title');

      if (documentCurrentTitle) {
        docStatusElem.attr('title', documentCurrentTitle);
      }
    }
    // dispatch an event that can be listened for from react/vanilla js
    const event = new CustomEvent('rfq-update-doc-status', {
      detail: {
        rfqId,
        hasDownloaded: true,
        hasLatestDocs: true,
      },
    });
    document.dispatchEvent(event);
  });

  $body.on('click', '.fullset-download-link-trigger', async function downloadFullSetRfq() {
    const fullSetPackageId = $(this).attr('data-fullset-package-id');

    const createRequestUrl = Routing.generate('app_packagerfq_fullsetrequest', {
      id: fullSetPackageId,
    });

    const createReq = new E1Request<E1Response & { approved?: boolean; rfq?: number }>(
      createRequestUrl,
      'POST',
    );
    const resp = await createReq.submit();

    if (resp.success && resp.approved && resp.rfq) {
      const fullSetRfqId = resp.rfq;
      const rfqDownload = new RfqDownload(fullSetRfqId);
      await rfqDownload.submit();
    }
  });

  $body.on('click', '.email-transmittal-trigger', async (event) => {
    const emailTransmittalButton = $(event.currentTarget);
    const correspondenceId = emailTransmittalButton.attr('data-correspondence-id');
    const $loading = $('<i>').addClass(
      'loading-indicator glyphicon glyphicon-refresh glyphicon-spin',
    );
    const url = Routing.generate('app_rfqcorrespondence_email', { id: correspondenceId });
    const request = new E1Request<E1Response & { message: string }>(url);

    emailTransmittalButton.append($loading);
    emailTransmittalButton.attr('disabled', 'true');

    const response = await request.submit();
    emailTransmittalButton.find('.loading-indicator').remove();
    if (response.success) {
      if (response.message) {
        emailTransmittalButton.text(response.message);
      }
    }
  });

  $body.on('click', '.rfq-revision-link-trigger', async function downloadRfqRevision() {
    const rfqIdValue = $(this).attr('data-rfq-id');
    const rfqId = parseInt(rfqIdValue ?? '0');
    const downloadFromValue = $(this).attr('data-last-at');
    const downloadFrom = downloadFromValue ? parseInt(downloadFromValue) : undefined;
    const rfqDownload = new RfqDownload(rfqId, downloadFrom);
    await rfqDownload.submit();
  });

  const $rfqTrigger: JQuery<HTMLInputElement> = $('input#rfq_id_trigger');

  if ($rfqTrigger.length > 0) {
    const rfqIdValue: string | undefined = $rfqTrigger.val();
    const rfqId = parseInt(rfqIdValue ?? '0');
    const lastAt = $rfqTrigger.attr('data-last-at');
    const stageId = $rfqTrigger.data('stage-id');
    const viewDocuments = $rfqTrigger.data('view-docs');

    const route = Routing.generate(
      'app_rfq_viewslider',
      lastAt ? { id: rfqId, lastAt } : { id: rfqId },
    );

    const req = new E1Request(route);
    await req.submit();

    if (viewDocuments) {
      const viewerLauncher = new E1PDFViewerLauncher(stageId, {
        rfqId,
      });
      viewerLauncher.launchforrfq();
    }
  }
});
