import { Alert, AlertVariant, Button, ButtonVariant } from '@estimateone/frontend-components';
import { useGlobalErrorBanner } from '../../GlobalErrorBanner';
import { withModalRoute } from '../../withModalRoute';
import styles from './SSCOptOutConfirmModal.module.scss';
import { useSubmitSSCOptOut } from '../SSCOptOutOption/hooks';

export const SSCOptOutConfirmModalRoute = withModalRoute(
  ({ onClose }) => {
    const { openBanner } = useGlobalErrorBanner();
    const { optOut } = useSubmitSSCOptOut({
      onError: (apolloError) => {
        openBanner();
        reportError(new Error(apolloError.message));
      },
    });

    return (
      <div className={styles.modalSection}>
        <h1>Are you sure you want to continue?</h1>
        <div className={styles.modalBody}>
          <Alert variant={AlertVariant.Yellow}>
            By proceeding you will no longer have access to proactive requests from Suppliers to
            assist you with quoting a project
          </Alert>
          <p>
            Your Company Profile can <strong>not</strong> be found by Suppliers on projects that
            have been added to your Watchlist as &apos;interested&apos;, &apos;quoting&apos; or
            &apos;quoted&apos;.
          </p>
        </div>
        <div className={styles.modalButtonOptions}>
          <Button onClick={onClose} variant={ButtonVariant.Grey}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              onClose();
              return optOut();
            }}
            variant={ButtonVariant.Primary}
          >
            Confirm
          </Button>
        </div>
      </div>
    );
  },
  {
    onCloseNavigateTo: '/settings',
  },
);
