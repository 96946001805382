export enum AddressBookDataSource {
  IMPORT = 1,
  BUILDER_CREATED = 2,
  RFQ = 3,
  PROFILE = 4,
  ITP = 5,
  SHADOW_PROFILE = 6,
  NETWORK_INVITATION_PROFILE = 7,
  BUILDER_AND_PROFILE = 8,
}
