import { GetPackagesForLettingSchedule_activePackages as ActivePackages } from '../../types/GetPackagesForLettingSchedule';
import { RfqQuotingStatus } from '@ascension/_gqltypes/builder.generated';

type ActiveRfq = ActivePackages['activeRfqs'][0];

export type QuoteCoverageStatistics = {
  quoted: number;
  quoting: number;
  notQuoting: number;
  noResponse: number;
  numberOfQuotes: number;
};

const filterByStatusAndUsedCompanies = (
  activeRfqs: ActiveRfq[],
  status: RfqQuotingStatus | null,
  usedCompanyIds: (number | undefined)[],
): number[] => [
  ...new Set(
    activeRfqs
      .filter((r) => !usedCompanyIds.includes(r.contact?.company?.id))
      .filter((r) => r.quotingStatus === status)
      .map((r) => r.contact?.company?.id)
      .filter((id): id is number => !!id),
  ),
];

export const calculateQuoteCoverageStatusByCompany = (
  activeRfqs: ActiveRfq[],
): QuoteCoverageStatistics => {
  const numberOfQuotes = activeRfqs.length;
  const usedCompanyIds: (number | undefined)[] = [];

  const quoted = filterByStatusAndUsedCompanies(
    activeRfqs,
    RfqQuotingStatus.QUOTED,
    usedCompanyIds,
  );

  usedCompanyIds.push(...quoted);

  const quoting = filterByStatusAndUsedCompanies(
    activeRfqs,
    RfqQuotingStatus.QUOTING,
    usedCompanyIds,
  );

  usedCompanyIds.push(...quoting);

  const notQuoting = filterByStatusAndUsedCompanies(
    activeRfqs,
    RfqQuotingStatus.DECLINED,
    usedCompanyIds,
  );

  usedCompanyIds.push(...notQuoting);

  const noResponse = filterByStatusAndUsedCompanies(activeRfqs, null, usedCompanyIds);

  return {
    quoted: quoted.length,
    quoting: quoting.length,
    notQuoting: notQuoting.length,
    noResponse: noResponse.length,
    numberOfQuotes,
  };
};

export const calculatePackageQuoteCoverage = ({
  activeRfqs,
}: {
  activeRfqs: ActiveRfq[];
}): QuoteCoverageStatistics | null => {
  if (activeRfqs.length === 0) return null;

  return calculateQuoteCoverageStatusByCompany(activeRfqs);
};
