import { Select } from '@estimateone/frontend-components';
import {
  WATCHLIST_OPTION_RESET,
  WATCHLIST_OPTION_UNSELECTED,
  WATCHLIST_OPTIONS,
  WatchlistOption,
} from './const';
import { InterestLevel } from '@ascension/_gqltypes/subbie.generated';
import { EntityId } from '@ascension/types';
import calcStyles from './styles';

const findOption = (value: InterestLevel) => WATCHLIST_OPTIONS.find(({ value: _ }) => _ === value);

type WatchlistSelectProps = {
  projectId: EntityId;
  status?: InterestLevel | null;
  disabled?: boolean;
  onStatusChange?: (status: InterestLevel) => void;
};

const WatchlistSelect = ({
  projectId,
  status = null,
  disabled = false,
  onStatusChange,
}: WatchlistSelectProps) => {
  const validStatus =
    status &&
    [
      InterestLevel.INTERESTED,
      InterestLevel.QUOTING,
      InterestLevel.QUOTED,
      InterestLevel.NOT_INTERESTED,
    ].includes(status);

  const styles = calcStyles<WatchlistOption, false>(
    validStatus ? status : InterestLevel.UNACTIONED,
  );
  const selectedOption = validStatus ? findOption(status) : WATCHLIST_OPTION_UNSELECTED;
  const currentOptions = validStatus
    ? [WATCHLIST_OPTION_RESET, ...WATCHLIST_OPTIONS]
    : [WATCHLIST_OPTION_UNSELECTED, ...WATCHLIST_OPTIONS];

  const handleChange = (opt: WatchlistOption) => {
    const { value: newStatus } = opt;
    if (
      newStatus !== undefined &&
      newStatus !== InterestLevel.UNACTIONED &&
      newStatus !== status &&
      onStatusChange
    ) {
      onStatusChange(newStatus);
    }
  };

  return (
    <Select<WatchlistOption>
      id={`projectId_${projectId}`}
      label=""
      name="watchlist_status"
      value={
        selectedOption ?? currentOptions.find(({ value }) => value === InterestLevel.UNACTIONED)
      }
      onChange={handleChange}
      options={currentOptions}
      styles={styles}
      isDisabled={disabled}
      isSearchable={false}
      className="interestLevelDropdown"
      classNamePrefix="reactSelect"
    />
  );
};

export default WatchlistSelect;
