import { InputFieldWrapper } from '@profiles/features/CompanyProfile/modules/FinalFormFields/InputFieldWrapper';
import { OfficeDetailsFieldNames, OfficeDetailsFieldLabels } from './types';

export const PhoneNumberField = () => (
  <div>
    <InputFieldWrapper<string>
      field={OfficeDetailsFieldNames.PhoneNumber}
      id={OfficeDetailsFieldNames.PhoneNumber}
      label={OfficeDetailsFieldLabels.PhoneNumber}
    />
  </div>
);
