import { memo, useCallback } from 'react';
import { useField, useForm } from 'react-final-form';
import {
  locationOptions,
  getDistanceOptions,
} from '@builder/common/SubbieNetwork/utils/searchArea/locationOptions';
import { PlaceSuggestingSelect } from '../PlaceSuggestingSelect';
import { FieldWrapper } from '@shared/FinalForm/Field/FieldWrapper';
import { DistanceUnit } from '@shared/Util';
import {
  LocationOption,
  SubbieNetworkFilterFormFields,
  SubbieNetworkFilterFormValues,
} from '@builder/common/SubbieNetwork/utils/filters/types';
import { SelectedPlace } from '@builder/common/SubbieNetwork/utils/searchArea/types';
import { SubbieNetworkFilterFormId } from '@builder/features/SubbieNetworkInvitations/types';

type Props = {
  countryId: number;
  unit: DistanceUnit;
  placeholder: string;
  initialValue?: LocationOption;
  placePredictionsTypes?: string[];
};

const getMaxDistanceOption = (unit: DistanceUnit) => getDistanceOptions(unit).pop();

export const LocationFilter = memo(
  ({ countryId, unit, placeholder, placePredictionsTypes, initialValue }: Props) => {
    const {
      input,
      meta: { error, submitError },
    } = useField<SubbieNetworkFilterFormValues['location']>('location', {
      initialValue,
    });

    const networkDrawerForm = useForm();

    const onChange = useCallback(
      (selectedPlace: SelectedPlace | null) => {
        const options = selectedPlace ? locationOptions(selectedPlace) : null;
        input.onChange(options);

        if (selectedPlace) {
          const distanceField = networkDrawerForm.getFieldState(
            SubbieNetworkFilterFormFields.Distance,
          );

          if (distanceField && !distanceField.value) {
            distanceField.change(getMaxDistanceOption(unit));
          }
        }
      },
      [input, networkDrawerForm, unit],
    );

    return (
      <FieldWrapper errors={error || submitError} showErrors>
        <PlaceSuggestingSelect
          countryId={countryId}
          label="Location"
          placeholder={placeholder}
          isRequired={false}
          onLocationSelected={onChange}
          hideLabel
          formId={SubbieNetworkFilterFormId}
          value={input.value ?? null}
          placePredictionsTypes={placePredictionsTypes}
        />
      </FieldWrapper>
    );
  },
);
