import { E1Link, FieldWrapper, LinkVariant, TextInput } from '@estimateone/frontend-components';
import { Image } from '@shared';
import { PaymentMethodError } from './enums';
import { PrimaryCreditCard_primaryCreditCard as CreditCardDetails } from './types/PrimaryCreditCard';
import { CreditCardCompany } from '@ascension/_gqltypes/subbie.generated';
import styles from './styles.scss';

type CreditCardDetailsSectionProps = {
  handleEnquiry: () => void;
  handleUpdatePaymentMethod: () => void;
  creditCardDetails: Omit<CreditCardDetails, '__typename'>;
  errors: PaymentMethodError[];
};

const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

const CreditCardLogo = ({ ccCompany }: { ccCompany: CreditCardCompany }) => (
  <Image className={styles.creditCardBrandImage} src={[ccCompany.toLowerCase(), 'png'].join('.')} />
);

export const CreditCardDetailsSection = ({
  handleEnquiry,
  handleUpdatePaymentMethod,
  creditCardDetails,
  errors,
}: CreditCardDetailsSectionProps) => (
  <div>
    <FieldWrapper errors={errors.length > 0 ? [errors[0]] : []}>
      <TextInput
        id="input-id"
        label=""
        value={`${capitalize(creditCardDetails.brand.toLowerCase())} ending in ${
          creditCardDetails.lastFourDigits
        }`}
        readOnly
        startAdornment={<CreditCardLogo ccCompany={creditCardDetails.brand} />}
        endAdornment={
          <span className={styles.creditCardExpiry}>
            Exp date {creditCardDetails.expiryMonth.toString().padStart(2, '0')}/
            {creditCardDetails.expiryYear.toString().slice(2)}
          </span>
        }
      />
    </FieldWrapper>
    {errors?.length > 0 ? (
      <div className={styles.updatePaymentMethod}>
        <E1Link variant={LinkVariant.Primary} onClick={() => handleUpdatePaymentMethod()}>
          Update payment method
        </E1Link>
      </div>
    ) : (
      <div className={styles.creditCardEnquire}>
        Not the correct payment method?{' '}
        <E1Link variant={LinkVariant.Grey} onClick={() => handleEnquiry()}>
          Enquire now to complete upgrade
        </E1Link>
      </div>
    )}
  </div>
);
