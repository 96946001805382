import { Alert, AlertVariant } from '@estimateone/frontend-components';
import styles from './FullSetOnlyAlert.module.scss';

export const FullSetOnlyAlert = () => (
  <Alert variant={AlertVariant.Blue} inline className={styles.fullSetOnlyAlert}>
    <p className={styles.alertTitle}>Get Clearer Quote Coverage</p>
    <p>
      Splitting full set documents into trade-specific packages gives you a clearer view of
      responses per trade, making it easier to manage coverage and avoid gaps.
    </p>
  </Alert>
);
