import PaywallAmount from '../../../../../common/PaywallAmount';
import { RoadblockLockIcon } from './RoadblockIcons';
import styles from '../styles.scss';

export const RoadblockReasonAwarded = () => (
  <div className={styles.titleReasonContainer}>
    <div className={styles.titleContent}>
      <RoadblockLockIcon />
      <span>This project has been awarded</span>
    </div>
  </div>
);

export const RoadblockReasonBudget = ({ projectPaywallValue }: { projectPaywallValue: number }) => (
  <div className={styles.titleReasonContainer}>
    <div className={styles.titleContent}>
      <RoadblockLockIcon />
      <span>
        This project&apos;s budget is over <PaywallAmount amount={projectPaywallValue} longUnits />
      </span>
    </div>
  </div>
);

export const RoadblockReasonFiftyPercent = ({ productName }: { productName: string }) => (
  <div className={styles.titleReasonContainer}>
    <div className={styles.titleContent}>
      <RoadblockLockIcon />
      <span>This project is reserved for {productName} members only</span>
    </div>
  </div>
);
