import { useQuery } from '@apollo/client';
import { GET_PROVISIONED_LICENSE_FOR_CTA } from './queries';
import { EntityId } from '../../../../types';
import {
  GetProvisionedLicenseForCta as GetLicense,
  GetProvisionedLicenseForCtaVariables as GetLicenseVars,
} from './types/GetProvisionedLicenseForCta';

export const useProvisionedLicense = (licenseId: EntityId) => {
  const { data, loading } = useQuery<GetLicense, GetLicenseVars>(GET_PROVISIONED_LICENSE_FOR_CTA, {
    variables: { id: licenseId },
  });

  return {
    loading,
    productName: data?.license.product.name,
    invoiceItemId: data?.license.invoiceItem?.id,
    invoiceId: data?.license.invoiceItem?.invoice?.id,
    invoiceNumber: data?.license.invoiceItem?.invoice?.invoiceNumber,
  };
};
