import { OperationVariables, TypedDocumentNode, QueryHookOptions, useQuery } from '@apollo/client';
import { getOrCreateClient } from '../../client';

export const useDocSyncQuery = <
  TData = unknown,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>,
) =>
  useQuery<TData, TVariables>(query, {
    client: getOrCreateClient(),
    ...options,
  });
