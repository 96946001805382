import $ from 'jquery';
import Routing from 'routing';
import E1Request from '../classes/E1Request';

$(() => {
  const $body = $('body');
  const $importedEmailFrames = $body.find('div.email-item iframe.email-frame');

  $.fn.resizeFrame = function () {
    const $iframes = $(this);
    if ($iframes.length === 0) {
      return;
    }
    $.each($iframes, (i, iframe) => {
      const resizeHeight = iframe.contentWindow.document.body.scrollHeight + 50;
      $(iframe).height(resizeHeight);
    });
  };

  $body.on('click', '.imported-email-slider-trigger', async (triggerSliderEvent) => {
    const $trigger = $(triggerSliderEvent.currentTarget);
    const correspondenceId =
      $trigger.data('correspondence-id') || $trigger.closest('ul').data('correspondence-id');
    const emailId = $trigger.data('email-id') || $trigger.closest('li').data('email-id');

    const sliderEndpoint = Routing.generate('app_usercorrespondenceemail_view', {
      id: correspondenceId,
      emailId,
    });
    const sliderRequest = new E1Request(sliderEndpoint, 'GET');

    $trigger.addClass('busy-slider-loading');
    await sliderRequest.submit();
    $trigger.removeClass('busy-slider-loading');
    $('.email-slider iframe').on('load', (e) => {
      $(e.currentTarget).resizeFrame();
    });
  });

  $importedEmailFrames.each((i, frame) => {
    $(frame).on('load', (e) => {
      $(e.currentTarget).resizeFrame();
    });
  });
});
