import { gql } from '@apollo/client';

export const RFQ_AND_STAGE = gql`
  query RfqForQuoteCreate($rfqId: EntityId!, $stageId: EntityId!) {
    rfq(id: $rfqId) {
      id
      companyName
      contactName
      package {
        id
        title
      }
    }

    stage(id: $stageId) {
      ... on UnrestrictedStage {
        id
        name
        projectId
        projectAddress {
          id
          address1
          shortAddress
        }
        account {
          primaryCountry {
            id
          }
        }
      }
    }
  }
`;
