import { useMutation } from '@apollo/client';
import { transformValues } from '../transformFormValues';
import { EDIT_HISTORICAL_PROJECT } from './mutations';
import {
  UpdateHistoricalProjectMutation,
  UpdateHistoricalProjectMutationVariables,
} from './mutations.generated';
import { ProjectHistoryFieldValues } from '@profiles/features/CompanyProfile/components/ProjectHistory/ProjectHistoryForm/types';

export const useEditHistoricalProject = (id: string) => {
  const [updateHistoricalProject, { error, reset }] = useMutation<
    UpdateHistoricalProjectMutation,
    UpdateHistoricalProjectMutationVariables
  >(EDIT_HISTORICAL_PROJECT);

  const submit = (values: ProjectHistoryFieldValues) =>
    updateHistoricalProject({
      variables: {
        input: transformValues(values),
        id,
      },
    });

  return { submit, error, reset };
};
