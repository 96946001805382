import { useModalContext } from '@subbie/context/ModalContext';

export const CONNECTION_PREFERENCE_SLIDER = 'connection-preference-slider';

export type ConnectionPreferenceSliderContextProps = {
  selectedProjectId: number;
};

export const useConnectionPreferenceSlider = () => {
  const { addModal, name, removeModal, data } =
    useModalContext<ConnectionPreferenceSliderContextProps>();
  const isOpen = name === CONNECTION_PREFERENCE_SLIDER;
  return {
    isOpen,
    open: (props: ConnectionPreferenceSliderContextProps) =>
      addModal(CONNECTION_PREFERENCE_SLIDER, props),
    close: () => removeModal(),
    projectId: data.selectedProjectId,
  };
};
