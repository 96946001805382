import { useMemo, useState } from 'react';
import {
  Alert,
  AlertVariant,
  Button,
  ButtonVariant,
  LoadingSpinner,
  Slider,
} from '@estimateone/frontend-components';
import { BaseFolderSelection } from '@builder/features/DocSyncIntegration/Sharepoint/SliderSteps/BaseFolderSelection';
import { SharepointConfirmSelection } from '@builder/features/DocSyncIntegration/Sharepoint/SliderSteps/SharepointConfirmSelection';
import { SharepointSliderStepper } from '@builder/features/DocSyncIntegration/Sharepoint/SliderSteps/SharepointSliderStepper';
import { DocSyncSliderProps } from '../DocSyncPlugin/DocSyncPlugin';
import { DriveItemSelection } from './SliderSteps/DriveItemSelection';
import { SiteSelection } from './SliderSteps/SiteSelection';
import { useGetDriveItems } from './SliderSteps/hooks/useGetDriveItems';
import { useCreateStageSharepointMap } from '@builder/features/DocSyncIntegration/hooks/useCreateStageSharepointMap';
import { BaseFolder, DriveItemMap, SharePointDrive, SharePointSite } from './types';
import { SharePointDriveItemType } from '@ascension/_gqltypes/builder.generated';
import styles from './styles.scss';

type SharepointSliderBaseFolderSelectionProps = {
  driveId: string;
  selectedBaseFolder?: BaseFolder;
  onSelectionChange: (selectedBaseFolder?: BaseFolder) => void;
};

const SharepointSliderBaseFolderSelection = ({
  driveId,
  selectedBaseFolder,
  onSelectionChange,
}: SharepointSliderBaseFolderSelectionProps) => {
  const { driveItems: rootDriveItems, errors, loading } = useGetDriveItems(driveId, 'root');

  if (errors) {
    return <Alert variant={AlertVariant.Red}>{errors[0].message}</Alert>;
  }

  if (loading || !rootDriveItems) {
    return <LoadingSpinner />;
  }

  return (
    <BaseFolderSelection
      driveId={driveId}
      rootDriveItems={rootDriveItems}
      selectedBaseFolder={selectedBaseFolder}
      onSelectionChange={onSelectionChange}
    />
  );
};

type SharepointDriveItemSelectionProps = {
  driveId: string;
  selectedBaseFolder: BaseFolder;
  onSelectionChange: (selectedDriveItems: DriveItemMap) => void;
  selectedDriveItems: DriveItemMap;
};

const SharepointDriveItemSelection = ({
  driveId,
  selectedBaseFolder,
  onSelectionChange,
  selectedDriveItems,
}: SharepointDriveItemSelectionProps) => {
  const { driveItems, errors, loading } = useGetDriveItems(driveId, selectedBaseFolder.driveItemId);

  if (errors) {
    return <Alert variant={AlertVariant.Red}>{errors[0].message}</Alert>;
  }

  if (loading || !driveItems) {
    return <LoadingSpinner />;
  }

  if (driveItems.length === 0) {
    return (
      <div className={styles.noDriveItemsText}>
        There are no items in this folder. Please select a different folder.
      </div>
    );
  }

  return (
    <DriveItemSelection
      driveItems={driveItems}
      onSelectionChange={onSelectionChange}
      selectedDriveItems={selectedDriveItems}
    />
  );
};
export const SharepointSlider = ({ isOpen, stageId, onConfirmConnection }: DocSyncSliderProps) => {
  const [isSliderOpen, setIsSliderOpen] = useState(isOpen);
  const [selectedSite, setSelectedSite] = useState<SharePointSite | undefined>();
  const [selectedDrive, setSelectedDrive] = useState<SharePointDrive | undefined>();
  const [selectedBaseFolder, setSelectedBaseFolder] = useState<BaseFolder | undefined>();
  const [selectedDriveItems, setSelectedDriveItems] = useState<DriveItemMap>(new Map());
  const [currentStep, setCurrentStep] = useState<number>(1);
  const { submit, isLoading: isCreateSharepointMapLoading } = useCreateStageSharepointMap();

  const handleSiteSelection = (site: SharePointSite) => {
    if (JSON.stringify(selectedSite) !== JSON.stringify(site)) {
      setSelectedDrive(undefined);
      setSelectedDriveItems(new Map());
      setSelectedBaseFolder(undefined);
    }
    setSelectedSite(site);
  };

  const handleDriveSelection = (drive: SharePointDrive) => {
    if (JSON.stringify(selectedDrive) !== JSON.stringify(drive)) {
      setSelectedBaseFolder(undefined);
      setSelectedDriveItems(new Map());
    }
    setSelectedDrive(drive);
  };

  const handleBaseFolderSelection = (baseFolder: BaseFolder) => {
    if (JSON.stringify(selectedBaseFolder) !== JSON.stringify(baseFolder)) {
      setSelectedDriveItems(new Map());
    }
    setSelectedBaseFolder(baseFolder);
  };

  const handleConfirmButton = async () => {
    switch (currentStep) {
      case 1:
        setCurrentStep(2);
        break;
      case 2:
        setCurrentStep(3);
        break;
      case 3:
        setCurrentStep(4);
        break;
      case 4:
        await submit({
          stageId,
          driveId: selectedDrive!.id,
          siteId: selectedSite!.id,
          baseFolderId: selectedBaseFolder!.driveItemId,
          files: [...selectedDriveItems.values()]
            .filter((driveItem) => driveItem.type === SharePointDriveItemType.FILE)
            .map((driveItem) => driveItem.id),
          folders: [...selectedDriveItems.values()]
            .filter((driveItem) => driveItem.type === SharePointDriveItemType.FOLDER)
            .map((driveItem) => driveItem.id),
        });
        onConfirmConnection();
        break;
      default:
        break;
    }
  };

  const handleCloseButton = () => {
    if (currentStep === 1) {
      setIsSliderOpen(false);
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const isStep1 = currentStep === 1;
  const isStep2 = currentStep === 2;
  const canGoToStep2 = selectedDrive !== undefined && selectedSite !== undefined;
  const canGoToStep3 = selectedBaseFolder !== undefined;
  const canGoToStep4 = selectedDriveItems.size > 0;

  const isNextButtonEnabled = useMemo(() => {
    switch (currentStep) {
      case 1:
        return canGoToStep2;
      case 2:
        return canGoToStep3;
      case 3:
        return canGoToStep4;
      case 4:
        return !isCreateSharepointMapLoading;
      default:
        return false;
    }
  }, [currentStep, canGoToStep2, canGoToStep3, canGoToStep4, isCreateSharepointMapLoading]);

  return (
    <Slider isOpen={isSliderOpen} onRequestClose={() => setIsSliderOpen(false)}>
      <Slider.Header>
        <h2 className={styles.sliderHeading}>Connect to SharePoint</h2>
      </Slider.Header>
      <SharepointSliderStepper currentStep={currentStep} setCurrentStep={setCurrentStep} />
      <Slider.Body scrollBodyOnly>
        {isStep1 && (
          <SiteSelection
            onDriveSelection={handleDriveSelection}
            onSiteSelection={handleSiteSelection}
            selectedSite={selectedSite}
            selectedDrive={selectedDrive}
          />
        )}
        {isStep2 && selectedDrive && (
          <SharepointSliderBaseFolderSelection
            driveId={selectedDrive.id}
            selectedBaseFolder={selectedBaseFolder}
            onSelectionChange={handleBaseFolderSelection}
          />
        )}
        {currentStep === 3 && selectedDrive && selectedBaseFolder && (
          <SharepointDriveItemSelection
            driveId={selectedDrive.id}
            selectedBaseFolder={selectedBaseFolder}
            onSelectionChange={setSelectedDriveItems}
            selectedDriveItems={selectedDriveItems}
          />
        )}
        {currentStep === 4 && (
          <SharepointConfirmSelection
            selectedDriveItems={selectedDriveItems}
            selectedDrive={selectedDrive!}
            selectedBaseFolder={selectedBaseFolder!}
            selectedSite={selectedSite!}
          />
        )}
      </Slider.Body>
      <Slider.Footer>
        <div className={styles.sliderFooter}>
          <Button variant={ButtonVariant.Grey} fullWidth onClick={handleCloseButton}>
            {currentStep === 1 ? 'Cancel' : 'Previous'}
          </Button>
          <Button
            variant={ButtonVariant.Primary}
            fullWidth
            disabled={!isNextButtonEnabled}
            onClick={handleConfirmButton}
          >
            {currentStep === 4 ? 'Confirm and Start Importing' : 'Next'}
          </Button>
        </div>
      </Slider.Footer>
    </Slider>
  );
};
