import { useState } from 'react';
import { Slider, Stepper, getStatus } from '@estimateone/frontend-components';
import { AwardPackageForm } from './AwardPackageForm';
import { NotifyUnsuccessfulForm } from './NotifyUnsuccessfulForm';
import LoadingSpinner from '@shared/LoadingSpinner';
import { useGetAwardeesForPackage } from './hooks/useGetAwardeesForPackage';
import { useSetAwardedRfqOnLettingSchedule } from './hooks/useSetAwardedRfqOnLettingSchedule';
import { useGetStageSettings } from '@builder/features/StageSettings/hooks';
import { SetAwardedRfqOnLettingSchedule_setAwardedRfqOnLettingSchedule as SetAwardedResponseData } from './types/SetAwardedRfqOnLettingSchedule';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

export type AwardPackageSliderProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  onMarkPackageAsAwarded: (response: SetAwardedResponseData) => void;
  packageId: EntityId;
  packageTitle: string;
  packageAssignedUserName?: string;
  preselectedRfqId?: EntityId;
  lettingScheduleId: EntityId;
  stageId: EntityId;
};

export const AwardPackageSlider = ({
  isOpen,
  onRequestClose,
  onMarkPackageAsAwarded,
  packageId,
  packageTitle,
  packageAssignedUserName,
  preselectedRfqId,
  lettingScheduleId,
  stageId,
}: AwardPackageSliderProps) => {
  const [currentPane, setCurrentPane] = useState<number>(1);
  const [awardedRfqId, setAwardedRfqId] = useState<EntityId | undefined>(preselectedRfqId);
  const { haveAllDeclined, loading, activeRfqs, stageDetails } = useGetAwardeesForPackage(
    packageId,
    stageId,
  );
  const { submit } = useSetAwardedRfqOnLettingSchedule();
  const { stageSettingNotifyAwarded } = useGetStageSettings(stageId);

  const getStatusForStep = (stepNumber: number) => getStatus(stepNumber, currentPane);
  const onBack = () => setCurrentPane(1);

  return (
    <Slider isOpen={isOpen} onRequestClose={onRequestClose}>
      <Slider.Header variant="primary">
        <h1 className={styles.sliderHeading}>Mark Package as Awarded</h1>
      </Slider.Header>
      <Slider.Header variant="secondary" className={styles.stepperHeader}>
        <Stepper>
          <Stepper.Step
            stepNumber={1}
            label="Confirm Awarded"
            status={getStatusForStep(1)}
            onClick={() => setCurrentPane(1)}
          />
          <Stepper.Step stepNumber={2} label="Notify Unsuccessful" status={getStatusForStep(2)} />
        </Stepper>
      </Slider.Header>
      {loading ? (
        <LoadingSpinner />
      ) : currentPane === 1 ? (
        <AwardPackageForm
          activeRfqs={activeRfqs}
          haveAllDeclined={haveAllDeclined}
          packageName={packageTitle}
          onCancel={onRequestClose}
          onFormSuccess={(rfqId: EntityId) => {
            setAwardedRfqId(rfqId);
            setCurrentPane(2);
          }}
          awardedRfqId={awardedRfqId}
          preselected={!!preselectedRfqId}
          notifyAwarded={stageSettingNotifyAwarded}
          stageId={stageId}
        />
      ) : awardedRfqId && stageDetails ? (
        <NotifyUnsuccessfulForm
          activeRfqs={activeRfqs}
          awardedRfqId={awardedRfqId}
          packageName={packageTitle}
          packageAssignedUserName={packageAssignedUserName}
          stageDetails={stageDetails}
          onCancel={onRequestClose}
          onBack={onBack}
          onFormSuccess={async (rfqId, notifyUnsuccessfulRfqIds) => {
            // TODO: this should update state rather than calling an async function directly
            const response = await submit(lettingScheduleId, rfqId, notifyUnsuccessfulRfqIds);
            if (response) {
              onMarkPackageAsAwarded(response);
            }
          }}
        />
      ) : null}
    </Slider>
  );
};
