import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { captureException } from '@sentry/browser';
import { createSubbieClient } from '@api/E1ApolloProvider';
import { GET_PROJECTS_KEYWORDS } from '@subbie/common/ProjectsKeywords/queries';
import {
  KeywordsMatchingProject,
  KeywordsMatchingProjectVariables,
} from '@subbie/common/ProjectsKeywords/types/KeywordsMatchingProject';

export default class KeywordFetcher {
  private client: ApolloClient<NormalizedCacheObject>;
  constructor() {
    this.client = createSubbieClient();
  }

  public async getKeywordMatches(projectIds: number[]) {
    if (!projectIds.length) {
      return {};
    }

    const { errors, data } = await this.client.query<
      KeywordsMatchingProject,
      KeywordsMatchingProjectVariables
    >({
      query: GET_PROJECTS_KEYWORDS,
      variables: { projectIds },
      errorPolicy: 'all',
    });

    if (errors?.length) {
      // E.g. user does not have access (becomes irrelevant when everyone has access?)
      // Ask for forgiveness rather than permission (i.e. don't do a feature check, just ask the API)
      if (errors.map((err) => err.message).includes('Access denied')) {
        return {};
      }

      // But if anything else goes wrong we want to know about it
      errors.map((err) => captureException(err));
      return {};
    }

    return data.keywordsMatchingProjectForNoticeboardUser.reduce(
      (acc: Record<number, string[]>, { projectId, keywords }) => ({
        ...acc,
        [projectId]: keywords,
      }),
      {},
    );
  }
}
