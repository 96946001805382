import { useForm } from 'react-final-form';
import moment from 'moment';
import { PersonalisedMessageField } from '../PersonalisedMessageField';
import BudgetRange from '@shared/BudgetRange';
import { InviteToQuoteFormFieldName } from '../../enums';
import { Country, getCurrencyInfo } from '@ascension/enums';
import { InviteToQuoteFormValues, InviteToQuoteStage } from '../../types';
import { RfqCorrespondenceType } from '@ascension/_gqltypes/builder.generated';
import { FromGraphQL } from '@ascension/types';
import styles from './styles.scss';

type PersonalisedMessageFieldProps = {
  stage: FromGraphQL<InviteToQuoteStage>;
};

export const EmailPreviewSectionProcurement = ({ stage }: PersonalisedMessageFieldProps) => {
  const { getState } = useForm<InviteToQuoteFormValues>();
  const { values } = getState();

  const packageName = values[InviteToQuoteFormFieldName.Package].label;
  const companyName = values[InviteToQuoteFormFieldName.Company].label;
  const contact = values[InviteToQuoteFormFieldName.Contact].contactDetail;
  const quotesDueDate = values[InviteToQuoteFormFieldName.DueDate];
  const { lettingScheduleDetails } = values[InviteToQuoteFormFieldName.Package];

  const { builderContactName, builderContactEmail, builderContactPhone, budgetAmount, projectId } =
    stage;
  const countryId = stage.account?.primaryCountry?.id;
  const useInformalLanguage = countryId === Country.COUNTRY_AUSTRALIA;
  const senderContactName = lettingScheduleDetails?.assignedToUser?.fullName ?? builderContactName;
  const senderContactEmail = lettingScheduleDetails?.assignedToUser?.email ?? builderContactEmail;
  const isConfirmQuoteCorrespondence =
    values[InviteToQuoteFormFieldName.CorrespondenceType]?.value ===
    RfqCorrespondenceType.CONFIRM_QUOTE;

  return (
    <div className={styles.emailPreview}>
      {/* If updating the email content, also update it in `w017_invite_to_quote.html.twig`/`w_019_confirm_quote.html.twig` */}
      <p className={styles.greeting}>
        {useInformalLanguage ? 'Hi' : 'Dear'} {contact.firstName},
      </p>

      <PersonalisedMessageField />

      <div className={styles.detailsContainer}>
        {isConfirmQuoteCorrespondence ? (
          <>
            <p className={styles.lineBreakAfter}>
              Thank you for your previous quote for the <strong>{packageName}</strong> package.
            </p>
            <p className={styles.lineBreakAfter}>
              The documentation may have changed since then - access the latest docs.
            </p>
            <p className={styles.lineBreakAfter}>
              Please confirm your quote by{' '}
              <strong>{moment(quotesDueDate).format('dddd, D MMMM YYYY')}</strong>.
            </p>
          </>
        ) : (
          <>
            <p className={styles.lineBreakAfter}>
              We&#39;re inviting {companyName} to quote on the <strong>{packageName}</strong>{' '}
              package.
            </p>

            <p className={styles.lineBreakAfter}>
              Click the button above to access the documentation.
            </p>

            <p className={styles.lineBreakAfter}>
              Upload your quote and any supporting documents by{' '}
              <strong>{moment(quotesDueDate).format('dddd, D MMMM YYYY')}</strong>.
            </p>
          </>
        )}

        <p className={styles.detailItem}>Contact person: {senderContactName}</p>
        <p className={styles.detailItem}>Email: {senderContactEmail}</p>
        <p className={styles.detailItem}>Phone: {builderContactPhone}</p>
        <p className={styles.detailItem}>
          Approx. project value:{' '}
          <BudgetRange currency={getCurrencyInfo(countryId)} budget={budgetAmount} />
        </p>
        <p className={styles.detailItem}>Project ID: #{projectId}</p>
      </div>

      <p className={styles.lineBreakAfter}>{useInformalLanguage ? 'Cheers,' : 'Kind regards,'}</p>

      <p className={styles.signature}>
        <strong>{senderContactName}</strong>
        <br />
        {stage.account?.name}
      </p>
    </div>
  );
};
