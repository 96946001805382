import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { GET_PROJECT_INTERESTED_SUBBIES } from '@subbie/modal/SubbieListSlider/SubbieListSliderContent/queries';
import { EntityId } from '@ascension/types';
import {
  SubbieListProjectInterestedSubbies as ProjectInterestedSubbiesPayload,
  SubbieListProjectInterestedSubbies_projectInterestedSubbies as ProjectInterestedSubbie,
  SubbieListProjectInterestedSubbiesVariables,
} from '@subbie/modal/SubbieListSlider/SubbieListSliderContent/types/SubbieListProjectInterestedSubbies';

export const useProjectInterestedSubbies = (
  projectId: EntityId,
): {
  projectInterestedSubbies: ProjectInterestedSubbie[] | undefined;
} => {
  const { data } = useQuery<
    ProjectInterestedSubbiesPayload,
    SubbieListProjectInterestedSubbiesVariables
  >(GET_PROJECT_INTERESTED_SUBBIES, {
    variables: { projectId },
    skip: (projectId ?? 0) <= 0,
  });

  return {
    projectInterestedSubbies: data?.projectInterestedSubbies ?? undefined,
  };
};

export const useRestrictedItemList = <T extends { name: string }>(items: T[], maxChars = 80) => {
  const { letterCount, itemCount } = items.reduce(
    (acc, cur) => {
      if (acc.letterCount < maxChars) {
        return {
          letterCount: acc.letterCount + cur.name.length,
          itemCount: acc.itemCount + 1,
        };
      }
      return acc;
    },
    {
      letterCount: 0,
      itemCount: 0,
    },
  );
  const maxTagCount = letterCount > maxChars ? itemCount - 1 : itemCount;
  const firstItems = useMemo(() => items.slice(0, maxTagCount), [items, maxTagCount]);
  const restOfItems = useMemo(() => items.slice(maxTagCount), [items, maxTagCount]);
  const notShownCount = items.length - maxTagCount;

  return {
    firstItems,
    restOfItems,
    notShownCount,
  };
};
