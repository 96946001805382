import { useCallback, useState } from 'react';
import { reportError } from '@ascension/components/helpers/errorReporter';
import { useUpdateCustomColumnName } from './useUpdateCustomColumnName';
import { useUpdateCustomColumnValue } from './useUpdateCustomColumnValue';
import {
  GetPackagesForLettingSchedule_activePackages as Package,
  GetPackagesForLettingSchedule_lettingScheduleCustomColumns as LettingScheduleCustomColumn,
} from '../types/GetPackagesForLettingSchedule';
import {
  LettingScheduleCustomColumnIdentifier as Identifier,
  UpdateLettingScheduleCustomColumnNameInput,
  UpdateLettingScheduleCustomColumnValueInput,
} from '@ascension/_gqltypes/builder.generated';
import { EntityId } from '@ascension/types';

type PackageId = EntityId;

export type CustomColumnNames = Record<Identifier, string>;
export type CustomColumnValues = Record<PackageId, Record<Identifier, string | null>>;

export type CustomColumns = {
  names: CustomColumnNames;
  values: CustomColumnValues;
  updateName: (input: UpdateLettingScheduleCustomColumnNameInput) => void;
  updateValue: (input: UpdateLettingScheduleCustomColumnValueInput) => void;
};

const COLUMN1_DEFAULT_NAME = 'My Column 1';
const COLUMN2_DEFAULT_NAME = 'My Column 2';

export const useCustomColumns = (
  activePackages: Package[],
  customColumns: LettingScheduleCustomColumn[],
): CustomColumns => {
  const { updateValue } = useUpdateCustomColumnValue();
  const { updateName } = useUpdateCustomColumnName();
  const [renamedColumn1, setRenamedColumn1] = useState<string | null>(null);
  const [renamedColumn2, setRenamedColumn2] = useState<string | null>(null);

  const columnNames = {
    [Identifier.CUSTOM_COLUMN_1]:
      renamedColumn1 ||
      customColumns.find((column) => column.identifier === Identifier.CUSTOM_COLUMN_1)?.name ||
      COLUMN1_DEFAULT_NAME,
    [Identifier.CUSTOM_COLUMN_2]:
      renamedColumn2 ||
      customColumns.find((column) => column.identifier === Identifier.CUSTOM_COLUMN_2)?.name ||
      COLUMN2_DEFAULT_NAME,
  };

  const columnValues = activePackages.reduce((packages, activePackage) => {
    const customColumnValues = activePackage.lettingScheduleDetails?.customColumnValues || [];
    return {
      [activePackage.id]: customColumnValues.reduce(
        (values, value) => ({
          [value.column.identifier]: value.value,
          ...values,
        }),
        {},
      ),
      ...packages,
    };
  }, {});

  const updateColumnName = useCallback(
    (input: UpdateLettingScheduleCustomColumnNameInput) => {
      if (input.identifier === Identifier.CUSTOM_COLUMN_1) {
        setRenamedColumn1(input.name || null);
      }

      if (input.identifier === Identifier.CUSTOM_COLUMN_2) {
        setRenamedColumn2(input.name || null);
      }

      updateName(input).catch(reportError);
    },
    [updateName],
  );

  return {
    names: columnNames,
    values: columnValues,
    updateName: updateColumnName,
    updateValue,
  };
};
