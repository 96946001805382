import { gql } from '@apollo/client';
import { TableProjectFragment } from '../fragments/Project';

export const NOTE_COUNTS = gql`
  query NoteCounts {
    projectNoteCounts {
      projectId
      count
    }
  }
`;

export const PROJECTS_LIST_QUERY = gql`
  query ProjectsQuery($ids: [EntityId!]!) {
    projects(ids: $ids) {
      ...TableProject
    }
  }
  ${TableProjectFragment}
`;
