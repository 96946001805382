import { Component } from 'react';
import { FieldWrapper } from '@estimateone/frontend-components';
import { getBoundsForLocaleBias } from '@ascension/js/utils/address_form';
import { E1Address } from '../../../../js/classes/Address';
import { reportError } from '../../../helpers/errorReporter';

type Props = {
  id: string;
  placeholder: string;
  label: string;
  handleAddressChange: (address: E1Address) => void;
  show: boolean;
};

class AddressSearch extends Component<Props> {
  private googleSearchBoxElement: HTMLInputElement | undefined | null;
  private searchBox: google.maps.places.SearchBox | null | undefined;
  private placeSelectListener: google.maps.MapsEventListener | undefined;

  componentDidMount() {
    this.setupAddressSearch();
  }

  componentDidUpdate() {
    if (!this.googleSearchBoxElement) {
      this.searchBox = null;
    }

    if (this.googleSearchBoxElement && !this.searchBox) {
      this.setupAddressSearch();
    }
  }

  componentWillUnmount() {
    if (this.placeSelectListener) {
      this.placeSelectListener.remove();
    }
  }

  setupAddressSearch = () => {
    try {
      const bounds = getBoundsForLocaleBias();
      if (this.googleSearchBoxElement && bounds) {
        this.searchBox = new google.maps.places.SearchBox(this.googleSearchBoxElement, {
          // types: ['geocode'],
          bounds,
        });
      }
      this.placeSelectListener = this.searchBox?.addListener(
        'places_changed', // fired when user selects an option
        this.handleSelectedPlaceChanged,
      );
    } catch (err) {
      reportError(err as Error);
    }
  };

  handleSelectedPlaceChanged = () => {
    const { handleAddressChange } = this.props;
    const places = this.searchBox?.getPlaces();
    const place = places ? places.shift() : null;

    const newAddress =
      place && place.address_components ? E1Address.fromGoogleResult(place) : new E1Address();

    handleAddressChange(newAddress);
  };

  handleClearSelectedPlace = () => {
    const { handleAddressChange } = this.props;

    handleAddressChange(new E1Address());
  };

  render() {
    const { id, placeholder, label, show } = this.props;

    return (
      show && (
        <FieldWrapper>
          <label htmlFor={id}>{label}</label>
          <input
            type="text"
            className="form-control mb-3"
            id={id}
            placeholder={placeholder}
            onChange={this.handleClearSelectedPlace}
            ref={(ref) => {
              this.googleSearchBoxElement = ref;
            }}
          />
        </FieldWrapper>
      )
    );
  }
}

export default AddressSearch;
