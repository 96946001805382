import { joinClassNames } from '@estimateone/frontend-components';
import { ProfileLayout } from '@profiles/apps/CompanyProfile';
import { AboutUsError } from '@profiles/features/CompanyProfile/components/AboutUs';
import { Card, CardBody } from '@profiles/features/CompanyProfile/components/Card';
import { CategoriesErrorView } from '@profiles/features/CompanyProfile/components/Categories';
import { CompanyInfoError } from '@profiles/features/CompanyProfile/components/CompanyInfo';
import { GlobalErrorBanner } from '@profiles/features/CompanyProfile/components/GlobalErrorBanner';
import { OfficeContactListErrorView } from '@profiles/features/CompanyProfile/components/OfficeContactList';
import { PrimaryContactErrorView } from '@profiles/features/CompanyProfile/components/PrimaryContact';
import { ABNBadgeError } from '@profiles/features/CompanyProfile/components/ProfileBadgeSection/ABNBadge';
import { ContractAndWorkforceSizeError } from '@profiles/features/CompanyProfile/components/ProfileBadgeSection/ContractAndWorkforceSize/ContractAndWorkforceSize';
import { LocationBadgeError } from '@profiles/features/CompanyProfile/components/ProfileBadgeSection/LocationBadge/LocationBadge';
import { ProjectsBadgeError } from '@profiles/features/CompanyProfile/components/ProfileBadgeSection/ProjectsBadgeWrapper/ProjectsBadgeWrapper';
import { ProjectHistoryErrorIcon } from '@profiles/features/CompanyProfile/components/ProjectHistory';
import { TradesErrorView } from '@profiles/features/CompanyProfile/components/Trades';
import styles from './CompanyProfile.module.scss';
import { Divider } from './Divider';

export const CompanyProfileError = ({ layout }: { layout: ProfileLayout }) => (
  <>
    <div className={styles.profileHeader}>
      <GlobalErrorBanner />
      <h1 className={styles.title}>Company Profile</h1>
      <CompanyInfoError />
    </div>

    <div className={styles.profileBadges}>
      <div
        className={joinClassNames(styles.container, layout === 'slider' && styles.sliderContainer)}
      >
        <ABNBadgeError />
        <Divider />
        <ProjectsBadgeError />
        <Divider />
        <ContractAndWorkforceSizeError />
        <Divider />
        <LocationBadgeError />
      </div>
    </div>
    <div className={styles.aside}>
      <Card>
        <CardBody>
          <TradesErrorView />
          <CategoriesErrorView />
        </CardBody>
        <PrimaryContactErrorView />
      </Card>
    </div>
    <div
      className={`${styles.profileContent} ${
        layout === 'slider' ? styles.sliderProfileContent : ''
      }`}
    >
      <AboutUsError />
      <OfficeContactListErrorView />
      <ProjectHistoryErrorIcon />
    </div>
  </>
);
