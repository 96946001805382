/* eslint-disable fp/no-mutation */

import $ from 'jquery';
import Routing from 'routing';
import E1Request, { E1Response } from '../classes/E1Request';

export default class DocumentDownload {
  stageId: number;
  docIds: number[];
  $modal: JQuery<HTMLElement> | null;
  downloadUrl: string | null;

  constructor(stageId: number, docIds: number[]) {
    this.stageId = stageId;
    this.docIds = docIds;
    this.$modal = null;
    this.downloadUrl = null;
  }

  public async submit() {
    await this.startModal();
    await this.startDownload();
  }

  public async startModal() {
    const modalReqUrl = Routing.generate('app_stage_downloadmodal', {
      id: this.stageId,
    });
    const modalReq = new E1Request(modalReqUrl);
    modalReq.show_loading_modal = true;
    await modalReq.submit();
    this.$modal = $('.e1-modal.e1-download-modal');
    this.complete();
  }

  public async startDownload() {
    const downloadReqUrl = Routing.generate('app_stagedocument_download', {
      id: this.stageId,
    });
    const data = {
      docId: this.docIds,
    };
    const downloadReq = new E1Request<E1Response & { success: boolean; downloadUrl: string }>(
      downloadReqUrl,
      'POST',
      data,
    );
    const downloadResp = await downloadReq.submit();
    this.downloadUrl = downloadResp.downloadUrl;
    this.complete();
  }

  public complete() {
    if (this.$modal && this.downloadUrl) {
      window.location.href = this.downloadUrl;

      const $headline = this.$modal.find('.download-message');
      const $icon = this.$modal.find('.loading-spinner-wb');

      $headline.text('Your download has begun.');
      $icon.removeClass();
      $icon.addClass('icon icon-xxl icon-download success');
    }
  }
}
