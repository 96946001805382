import { ReactNode } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  Icon,
  IconName,
  Heading,
  joinClassNames,
  UnreadIndicator,
} from '@estimateone/frontend-components';
import { Action } from '../../../../hooks/Analytics';
import { useNotificationAnalytics } from '../../../../hooks/Analytics/notification';
import { DisplayNotification } from '../types';
import styles from './styles.scss';

const EmptyState = () => <div className={styles.empty}>No notifications to show</div>;

const NotificationItem = ({
  children,
  key,
  isUnread = false,
  link,
}: {
  children: ReactNode;
  key: string;
  link: string | undefined;
  isUnread?: boolean;
}) => {
  const { addEvent } = useNotificationAnalytics();

  const trackInteraction = (action: Action) =>
    addEvent({
      action,
      notificationId: key,
      isUnread,
      notificationItemLink: link,
    });

  const notificationItemClickHandler = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => trackInteraction(Action.NOTIFICATION_ITEM_CLICK))();
    if (link) {
      window.location.replace(link);
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <li
      className={joinClassNames(styles.notification, isUnread && styles.unread)}
      onClick={notificationItemClickHandler}
    >
      <div className={styles.unreadIndicator}>
        <UnreadIndicator />
      </div>
      <div>{children}</div>
    </li>
  );
};

type NotificationsTransitionGroupProps = {
  notifications: DisplayNotification[];
};

const NotificationsTransitionGroup = ({ notifications }: NotificationsTransitionGroupProps) => (
  <TransitionGroup component="ul">
    {notifications.map(({ key, isUnread, text, link }) => (
      <CSSTransition
        timeout={500}
        classNames={{
          enter: styles.notificationEnter,
          enterActive: styles.notificationEnterActive,
        }}
        key={key}
      >
        <NotificationItem link={link} isUnread={isUnread} key={key}>
          {text}
        </NotificationItem>
      </CSSTransition>
    ))}
  </TransitionGroup>
);

type NotificationListProps = {
  notifications: DisplayNotification[];
  onRequestClose?: () => void;
};

const NotificationList = ({ notifications, onRequestClose }: NotificationListProps) => (
  <div className={styles.notificationListContainer}>
    <div className={styles.notificationList}>
      <div className={styles.notificationListHeader}>
        <Button
          size={ButtonSize.Medium}
          variant={ButtonVariant.Transparent}
          onClick={onRequestClose}
          aria-label="Close"
        >
          <Icon name={IconName.Close} size={12} />
        </Button>
        <Heading level={4}>Notifications</Heading>
      </div>
      {notifications.length ? (
        <NotificationsTransitionGroup notifications={notifications} />
      ) : (
        <EmptyState />
      )}
    </div>
  </div>
);

export default NotificationList;
