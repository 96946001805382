import { Country } from '@ascension/enums';

const getCountryShortNameForCountryId = (countryId: number) => {
  switch (countryId) {
    case Country.COUNTRY_UK:
      return 'uk';
    case Country.COUNTRY_IE:
      return 'ie';
    case Country.COUNTRY_AUSTRALIA:
    default:
      return 'au';
  }
};

export const getGoogleSuggestions = (
  text: string,
  countryId: number,
  placePredictionsTypes: string[],
): Promise<{ description: string; placeId: string }[]> =>
  new Promise((resolve, reject) => {
    new window.google.maps.places.AutocompleteService().getPlacePredictions(
      {
        input: text,
        componentRestrictions: { country: getCountryShortNameForCountryId(countryId) },
        // Town/City/Suburb/Postcode
        types: placePredictionsTypes,
      },
      (results, status) => {
        if (status === 'ZERO_RESULTS') {
          return resolve([]);
        }
        if (status === 'OK') {
          return resolve(
            results.map(({ description, place_id: placeId }) => ({ description, placeId })),
          );
        }
        return reject(Error(`Place prediction status was ${status}`));
      },
    );
  });

export const getLocationForPlace = (
  placeId: string,
): Promise<{
  lat: number;
  lon: number;
  country: string | undefined;
  adminLevel1: string | undefined;
  resultTypes: string[];
}> =>
  new Promise((resolve, reject) => {
    const geocoder = new google.maps.Geocoder();

    geocoder.geocode({ placeId }, ([response], status) => {
      if (status === 'OK') {
        const loc = response.geometry.location;
        const addressComponents = response.address_components;
        const country = addressComponents?.find((component) =>
          component.types.includes('country'),
        )?.long_name;
        const adminLevel1 = addressComponents?.find((component) =>
          component.types.includes('administrative_area_level_1'),
        )?.long_name;

        return resolve({
          lat: loc.lat(),
          lon: loc.lng(),
          country,
          adminLevel1,
          resultTypes: response.types,
        });
      }
      return reject(Error(`Geocode status was ${status}`));
    });
  });
