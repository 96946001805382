import ImageO from '../../../../images/O_nut.png';
import Image from '../../Image';
import styles from './styles.scss';

const NiceOne = () => (
  <span className={styles.niceOne} aria-labelledby="nice-one-label">
    <span id="nice-one-label" hidden>
      NICE ONE!
    </span>
    NICE <Image src={ImageO} alt="O" className={styles.image} aria-hidden="true" />
    NE!
  </span>
);

export default NiceOne;
