import { gql } from '@apollo/client';

export const UPDATE_CONTRACT_AND_WORKFORCE_SIZE = gql`
  mutation UpdateContractAndWorkforceSize(
    $workforceSize: WorkforceSizeEnum
    $contractSizeMin: ContractSizeEnum
    $contractSizeMax: ContractSizeEnum
  ) {
    updateWorkforceSize(workforceSize: $workforceSize) {
      ... on Profile {
        id
        workforceSize
        updatedAt
        updatedById
      }
    }
    updateContractSize(contractSizeMin: $contractSizeMin, contractSizeMax: $contractSizeMax) {
      ... on Profile {
        id
        contractSizeMin
        contractSizeMax
        updatedAt
        updatedById
      }
      ... on Errors {
        errors {
          field
          message
        }
      }
    }
  }
`;
