import $ from 'jquery';
import { Moment } from 'moment';
import PackageDueDatePicker from './PackageDueDatePicker';
import { Action } from '../../../components/hooks/Analytics';
import { EntityId } from '@ascension/types';

export default class TradePackageSelector {
  $container: JQuery<HTMLElement>;
  $datePickerInfo: JQuery<HTMLElement>;
  $packageSelectorForm: JQuery<HTMLElement>;
  $tradeSelector: JQuery<HTMLElement>;
  $tradeOptions: JQuery<HTMLElement>;
  $packageSelector: JQuery<HTMLElement>;
  $previousTradeButton: JQuery<HTMLElement>;
  $nextTradeButton: JQuery<HTMLElement>;
  $tradeCounter: JQuery<HTMLElement>;
  onTradeChange: () => void;
  selectedTradeId!: EntityId;
  selectedTradeName: string;
  selectedPackageId!: EntityId;
  numTrades: number;
  tradePackageMap: Record<EntityId, EntityId>;
  defaultPackageVal: string;
  fullSetId: string;
  packageDatePicker: PackageDueDatePicker;

  constructor(
    $container: JQuery<HTMLElement>,
    onTradeChangeCallback: () => void,
    onDateChangeCallback: (packageId: EntityId, newFormattedDate: Moment) => void,
    onShowErrorCallback: (hasError: boolean) => void,
  ) {
    this.$container = $container;
    this.onTradeChange = onTradeChangeCallback;

    this.$packageSelectorForm = this.$container.find('#trade_controls_form');
    this.$tradeSelector = this.$container.find('.trade_list');
    this.$tradeOptions = this.$tradeSelector.find('option:not(:disabled)');
    this.$packageSelector = this.$container.find('.package_list');
    this.$previousTradeButton = this.$container.find('.previousTradeLink');
    this.$nextTradeButton = this.$container.find('.nextTradeLink');
    this.$tradeCounter = this.$container.find('.trade_counter');

    const selectedTradeId = this.$tradeSelector.val();
    if (typeof selectedTradeId === 'string') {
      this.selectedTradeId = parseInt(selectedTradeId);
    }
    this.selectedTradeName = this.$tradeSelector.find('option:selected').text();
    const selectedPackageId = this.$packageSelector.val();
    if (typeof selectedPackageId === 'string') {
      this.selectedPackageId = parseInt(selectedPackageId);
    }
    this.numTrades = this.$tradeSelector.find('option')?.length;
    this.tradePackageMap = {};
    this.defaultPackageVal = '';
    this.fullSetId = '';

    this.$datePickerInfo = this.$container.find('.procurement-letbydate-info');

    const $datePickerContainer = this.$container.find('.package-date-picker-container');

    const onDateChange = (newDueDate: Moment) => {
      onDateChangeCallback(this.getSelectedPackageId(), newDueDate);
    };

    const onDatePickerToggle = (showInfo: boolean) => {
      this.$datePickerInfo.toggleClass('show-info', showInfo);
    };

    this.packageDatePicker = new PackageDueDatePicker(
      $datePickerContainer,
      onDateChange,
      onDatePickerToggle,
      onShowErrorCallback,
    );
  }

  getSelectedTradeId() {
    return this.selectedTradeId;
  }

  getSelectedTradeName() {
    return this.selectedTradeName;
  }

  getNextTradeId() {
    const $current = this.$tradeSelector.find('option:selected');
    const currentIndex = this.$tradeOptions.index($current);
    if (Number.isNaN(currentIndex)) {
      return null;
    }

    const $nextTrade = this.$tradeOptions.eq(currentIndex + 1);
    const nextTradeValue = $nextTrade.val();
    if (typeof nextTradeValue === 'string') {
      return parseInt(nextTradeValue, 10) || null;
    }
    return null;
  }

  getSelectedPackageId() {
    return this.selectedPackageId;
  }

  getPackageNameForId(packageId: EntityId) {
    const packageOption = this.$packageSelector.find(`option[value=${packageId}]`);
    if (!packageOption) {
      return '';
    }
    return packageOption.text();
  }

  getAllPackageIds() {
    return this.$packageSelector
      .find('option')
      .toArray()
      .filter((option) => $(option).val() && !Number.isNaN($(option).val()))
      .map((option) => parseInt($(option).val() as string, 10));
  }

  init() {
    const $firstFullSetOption = this.$packageSelector.find('option[data-full-set=1]:first');
    if ($firstFullSetOption?.length > 0) {
      this.fullSetId = $firstFullSetOption.attr('value') || '';
      this.defaultPackageVal = this.fullSetId;
    }

    // Since updating the trade dropdown triggers a potential change to the package dropdown,
    // we may get into an infinite loop. That's why this code runs before we setup the event
    // bindings below.
    const defaultPackageId = new URLSearchParams(window.location.search).get('packageId');
    if (defaultPackageId !== null) {
      const $defaultOption = this.$packageSelector.find(
        `option[value="${parseInt(defaultPackageId, 10)}"]`,
      );
      if ($defaultOption?.length > 0) {
        this.defaultPackageVal = $defaultOption.attr('value') || '';

        const $matchingTrade = this.findMatchingTrade(this.defaultPackageVal);
        if ($matchingTrade && $matchingTrade?.length > 0) {
          const matchingTradeValue = $matchingTrade.attr('value');
          if (matchingTradeValue) {
            this.$tradeSelector.val(matchingTradeValue);
            this.updateSelectedTrade();
          }
        }
      }
    }

    this.updateTradeCounter();
    this.bindEvents();
    this.autoSetPackageSelector();
    this.updatePrevAndNextButtons();
    this.packageDatePicker.init();
  }

  bindEvents() {
    this.$packageSelectorForm.on('submit', () => false);
    this.$tradeSelector.on('change', this.updateSelectedTrade.bind(this));
    this.$packageSelector.on('change', this.updateSelectedPackage.bind(this));
    this.$previousTradeButton.on('click', this.selectPreviousTrade.bind(this));
    this.$nextTradeButton.on('click', this.selectNextTrade.bind(this));
  }

  updateSelectedTrade() {
    const selectedTradeValue = this.$tradeSelector.val();
    if (typeof selectedTradeValue !== 'string') {
      return;
    }
    this.selectedTradeId = parseInt(selectedTradeValue, 10);
    this.selectedTradeName = this.$tradeSelector.find('option:selected').text();

    if (this.tradePackageMap[this.selectedTradeId]) {
      this.$packageSelector.val(this.tradePackageMap[this.selectedTradeId]);
      this.$packageSelector.trigger('change');
    } else {
      this.autoSetPackageSelector();
    }
    this.updatePrevAndNextButtons();
    this.updateTradeCounter();
  }

  updateTradeCounter() {
    if (!this.numTrades) return;
    const currentNum = this.$tradeSelector.prop('selectedIndex') + 1;
    this.$tradeCounter.text(
      `${currentNum} of ${this.numTrades} Trade${this.numTrades === 1 ? '' : 's'}`,
    );
  }

  selectNextTrade() {
    this.incrementTrade(1);
  }

  selectPreviousTrade() {
    this.incrementTrade(-1);
  }

  incrementTrade(delta: number) {
    const $current = this.$tradeSelector.find('option:selected');
    const currentIndex = this.$tradeOptions.index($current);
    if (isNaN(currentIndex)) return;

    const $newTradeOption = this.$tradeOptions.eq(Math.max(currentIndex + delta, 0));
    if ($newTradeOption) {
      $newTradeOption.prop('selected', true);
      this.$tradeSelector.trigger('change');
    }
    const action =
      delta > 0 ? Action.INVITE_WIZARD_NEXT_TRADE_CLICK : Action.INVITE_WIZARD_PREVIOUS_TRADE_CLICK;
    window.analyticsService?.addInteractEvent({
      action,
      tradeId: this.selectedTradeId,
    });
  }

  updatePrevAndNextButtons() {
    const selectedIndex = this.$tradeSelector.prop('selectedIndex');
    this.$previousTradeButton.toggleClass('disabled', selectedIndex === 0);
    this.$nextTradeButton.toggleClass('disabled', selectedIndex === this.numTrades - 1);
  }

  updateSelectedPackage() {
    const selectedPackageValue = this.$packageSelector.val();
    if (typeof selectedPackageValue === 'string') {
      this.selectedPackageId = parseInt(selectedPackageValue, 10);
    }
    this.tradePackageMap[this.selectedTradeId] = this.selectedPackageId;
    this.toggleFullsetStatus();

    this.onTradeChange();
  }

  autoSetPackageSelector() {
    const $matches = this.findMatchingPackage(this.selectedTradeId);
    if ($matches) {
      $matches.prop('selected', true);
    } else if (this.defaultPackageVal) {
      this.$packageSelector.val(this.defaultPackageVal);
    }
    this.$packageSelector.trigger('change');
    this.toggleFullsetStatus();
  }

  getMappedPackageFromTradeId(tradeId: EntityId) {
    // First, make sure the trade is mapped to the stage.
    const $tradeOption = this.$tradeSelector.find(`option[value=${tradeId}]`);
    if (!$tradeOption?.length) {
      return null;
    }
    if (this.tradePackageMap[tradeId]) {
      return this.tradePackageMap[tradeId];
    }

    const $packageOption = this.findMatchingPackage(tradeId);
    if ($packageOption) {
      return $packageOption.val();
    }

    return this.defaultPackageVal;
  }

  toggleFullsetStatus() {
    const isFullSet = parseInt(this.fullSetId, 10) === this.selectedPackageId;

    this.$packageSelector
      .parent('.package-control-container')
      .toggleClass('has-warning', isFullSet);

    this.packageDatePicker.setDisabled(isFullSet);
  }

  findMatchingPackage(tradeId: EntityId | string) {
    const text = this.$tradeSelector.find(`option[value=${tradeId}]`).first().text().trim();
    const $matches = this.$packageSelector.find('option').filter(function () {
      return $(this).text() === text;
    });
    return $matches?.length > 0 ? $matches : null;
  }

  findMatchingTrade(packageId: EntityId | string) {
    const text = this.$packageSelector.find(`option[value=${packageId}]`).first().text().trim();
    const $matches = this.$tradeSelector.find('option').filter(function () {
      return $(this).text() === text;
    });
    return $matches?.length > 0 ? $matches : null;
  }

  getDefaultPackageDueDate() {
    return this.packageDatePicker.defaultDueDate;
  }

  setPackageDueDate(newDueDate: Moment) {
    this.packageDatePicker.setPackageDueDate(newDueDate);
  }

  setLetByDate(letByDate: Moment) {
    this.packageDatePicker.setLetByDate(letByDate);
  }

  showValidationError(show: boolean) {
    this.packageDatePicker.showValidationError(show);
  }
}
