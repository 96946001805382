import { reportError } from '@ascension/components/helpers/errorReporter';
import { TendersReadyForHandoverAccordion } from './TendersReadyForHandoverAccordion';
import { TendersReadyForHandoverTable } from './TendersReadyForHandoverTable';
import { useGetTendersReadyForHandover } from './hooks';
import { TenderStagesReadyForHandover_tenderStagesReadyForHandover as Stage } from './types/TenderStagesReadyForHandover';
import styles from './styles.scss';

const EmptyStateMessage = () => (
  <div className={styles.noDataText}>
    <p>There are no tender projects ready for handover.</p>
  </div>
);

type TendersReadyForHandoverContainerProps = {
  stages: Stage[];
  accessRestrictedTooltip: string;
};

export const TendersReadyForHandoverContainer = ({
  stages,
  accessRestrictedTooltip,
}: TendersReadyForHandoverContainerProps) => (
  <TendersReadyForHandoverAccordion tenderCount={stages.length}>
    <TendersReadyForHandoverTable
      tenders={stages}
      accessRestrictedTooltip={accessRestrictedTooltip}
    />

    {stages.length === 0 && <EmptyStateMessage />}
  </TendersReadyForHandoverAccordion>
);

export const TendersReadyForHandoverList = ({
  accessRestrictedTooltip,
}: {
  accessRestrictedTooltip: string;
}) => {
  const { loading, stages, error } = useGetTendersReadyForHandover();
  if (loading) {
    return null;
  }

  if (error) {
    reportError(error);
  }

  if (stages === undefined) {
    throw new Error('Error retrieving stages ready for handover');
  }

  return (
    <TendersReadyForHandoverContainer
      stages={stages}
      accessRestrictedTooltip={accessRestrictedTooltip}
    />
  );
};
