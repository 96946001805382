import { gql } from '@apollo/client';
import { RestrictedStageAccess } from './fragments';

export const LIST_RESTRICTED_STAGE_ACCESS = gql`
  query ListRestrictedStageAccess($stageId: EntityId!) {
    listRestrictedStageAccess(stageId: $stageId) {
      ...RestrictedStageAccess
    }
  }
  ${RestrictedStageAccess}
`;
