import { useEffect } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom-v5-compat';
import { useMutation } from '@apollo/client';
import { ChevronLeftIcon } from '@estimateone/frontend-components';
import { reportError } from '@ascension/components/helpers/errorReporter';
import { ProfileNavigation } from '../ProfileNavigation';
import styles from './ProfileIndexPage.module.scss';
import { fireAndForget } from '@shared/Util/async';
import { MARK_NETWORK_PROFILE_UPDATE_REQUEST_AS_VIEWED } from './mutations';
import {
  MarkNetworkProfileUpdateRequestAsViewedMutation,
  MarkNetworkProfileUpdateRequestAsViewedMutationVariables,
} from './mutations.generated';

export const ProfileIndexPage = () => {
  const [mutate] = useMutation<
    MarkNetworkProfileUpdateRequestAsViewedMutation,
    MarkNetworkProfileUpdateRequestAsViewedMutationVariables
  >(MARK_NETWORK_PROFILE_UPDATE_REQUEST_AS_VIEWED, {
    onError: (error) => reportError(error),
  });

  const [params] = useSearchParams();

  const requestId = params.get('request_id');

  useEffect(() => {
    if (requestId) {
      fireAndForget(() =>
        mutate({
          variables: {
            requestId,
          },
        }),
      );
    }
  }, [requestId]);

  return (
    <div className={styles.profilePage}>
      <ProfileNavigation />

      <div className={styles.profilePanel}>
        <div className={styles.breadcrumb}>
          <a className={styles.alignItems} href="/find-tenders">
            <ChevronLeftIcon
              className={styles.backButton}
              viewBox="0 0 5 8"
              height="8px"
              width="8px"
            />
            <span className={styles.text}>Tenders</span>
          </a>
        </div>

        <Outlet />
      </div>
    </div>
  );
};
