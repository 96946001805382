import { gql } from '@apollo/client';

export const MAX_SPECI_FINDER_KEYWORDS_FOR_USER = gql`
  query MaxSpeciFinderKeywordsForUser {
    maxSpeciFinderKeywordsForUser
  }
`;

export const KEYWORDS_MATCHING_PROJECT_STAGES = gql`
  query KeywordsMatchingProjectStages($projectId: EntityId!) {
    keywordsMatchingProjectStages(projectId: $projectId) {
      stageId
      keywords
    }
  }
`;

export const GET_EMAIL_PREFERENCE_FOR_EMAIL_SUBSCRIPTION = gql`
  query EmailPreferenceByEmailSubscription($emailSubscriptionType: EmailSubscriptionType!) {
    emailPreferenceByEmailSubscription(emailSubscriptionId: $emailSubscriptionType) {
      ... on EmailPreference {
        id
        emailSubscription {
          id
          subscriptionOptions
        }
        option
      }
      ... on Errors {
        errors {
          field
          message
        }
      }
    }
  }
`;

export const SPECI_FINDER_KEYWORDS_FOR_USER = gql`
  query SpeciFinderKeywordsForUser {
    speciFinderKeywordsForUser {
      id
      phrase
      category
      createdAt
      variations {
        id
        phrase
      }
      includes {
        id
        phrase
      }
      excludes {
        id
        phrase
      }
    }
    speciFinderKeywordCategoriesForUser
  }
`;

export const MAX_POSSIBLE_SPECI_FINDER_KEYWORDS_FOR_ACCOUNT_TYPE = gql`
  query MaxPossibleSpeciFinderKeywordsForAccountType {
    maxPossibleSpeciFinderKeywordsForAccountType
  }
`;
