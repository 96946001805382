import { getRelativeDurationToNowOrNull } from '../../utils/dateUtils';
import styles from './AccountActivityInfo.module.scss';

type AccountActivityInfoProps = {
  profile?: {
    memberSince?: string | null;
    lastActiveAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export const AccountActivityInfo = ({ profile }: AccountActivityInfoProps) => {
  if (!profile) {
    return null;
  }

  const { memberSince, lastActiveAt, updatedAt } = profile;
  const memberSinceDisplayValue = getRelativeDurationToNowOrNull(memberSince);
  const lastActiveAtDisplayValue = getRelativeDurationToNowOrNull(lastActiveAt) ?? 'unknown';
  const updatedAtDisplayValue = getRelativeDurationToNowOrNull(updatedAt) ?? 'unknown';

  if (!memberSinceDisplayValue) {
    return null;
  }

  return (
    <div className={styles.accountActivityInfoWrapper}>
      <div className={styles.activityInfoText}>
        {`Joined E1: ${memberSinceDisplayValue} | Last active: ${lastActiveAtDisplayValue} | Profile updated: ${updatedAtDisplayValue}`}
      </div>
    </div>
  );
};
