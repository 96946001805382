import { Row, Col, Fieldset } from '@estimateone/frontend-components';
import CompanyContactField from './CompanyContactField';
import PackageSelectorField, { PackageSelectorFieldProps } from './PackageSelectorField';
import { AnalyticsAwareFC } from '../../../../../../types';

type PackageContactFieldProps = PackageSelectorFieldProps;

const PackageContactField: AnalyticsAwareFC<PackageContactFieldProps> = ({
  packages,
  onAnalyticsEvent,
}) => (
  <Fieldset id="quote-package-contact" legend="Package and contact selector" hideLegend>
    <Row>
      <Col span={12}>
        <PackageSelectorField packages={packages} />
      </Col>
    </Row>

    <Row>
      <Col span={12} excludeBottomGutter>
        <CompanyContactField onAnalyticsEvent={onAnalyticsEvent} />
      </Col>
    </Row>
  </Fieldset>
);

export default PackageContactField;
