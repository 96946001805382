import { Row, Col } from '@estimateone/frontend-components';
import { AddNewContactFormField } from '.';
import Checkbox from '../../../shared/Checkbox';
import CheckboxGroup from '../../../shared/CheckboxGroup';
import Input from '../../../shared/Input';

type ContactDetails = {
  firstName: string;
  lastName: string;
  position: string;
  phone: string;
  email: string;
  estimating: boolean;
  construction: boolean;
};

type Options = {
  showContactTypes: boolean;
};

type Handlers = {
  handleChange: ({ name, value }: { name: AddNewContactFormField; value: string }) => void;
  handleCheckboxChange: ({ name, isChecked }: { name: string; isChecked: boolean }) => void;
  getErrorText: (fieldName: string) => string;
};

const ContactDetails = ({
  firstName,
  lastName,
  position,
  phone,
  email,
  showContactTypes,
  estimating,
  construction,
  getErrorText,
  handleChange,
  handleCheckboxChange,
}: ContactDetails & Options & Handlers) => {
  const handleChangeWithTypeAssert = ({ name, value }: { name: string; value: string }) =>
    handleChange({ name: name as AddNewContactFormField, value });

  return (
    <section className="contact-details">
      <Row>
        <Col span={6}>
          <Input
            id="firstName"
            name="firstName"
            type="text"
            value={firstName}
            onChange={handleChangeWithTypeAssert}
            label="First Name"
            errorText={getErrorText('firstName')}
            autoFocus
          />
        </Col>
        <Col span={6}>
          <Input
            id="lastName"
            name="lastName"
            type="text"
            value={lastName}
            onChange={handleChangeWithTypeAssert}
            label="Last Name"
          />
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <Input
            id="contactPosition"
            name="contactPosition"
            type="text"
            value={position}
            onChange={handleChangeWithTypeAssert}
            label="Position"
          />
        </Col>
        <Col span={6}>
          <Input
            id="contactPhone"
            name="contactPhone"
            type="text"
            value={phone}
            onChange={handleChangeWithTypeAssert}
            label="Direct / Mobile Number"
          />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Input
            id="contactEmail"
            name="contactEmail"
            type="email"
            value={email}
            onChange={handleChangeWithTypeAssert}
            label="Email"
            errorText={getErrorText('contactEmail')}
          />
        </Col>
      </Row>
      {showContactTypes && (
        <Row>
          <Col span={12}>
            <CheckboxGroup label="Contact Type" errorText={getErrorText('contactTypes')}>
              <Checkbox
                id="estimating"
                name="estimating"
                isChecked={estimating}
                onChange={handleCheckboxChange}
                label="Estimating"
              />
              <Checkbox
                id="construction"
                name="construction"
                isChecked={construction}
                onChange={handleCheckboxChange}
                label="Construction"
              />
            </CheckboxGroup>
          </Col>
        </Row>
      )}
    </section>
  );
};

export default ContactDetails;
