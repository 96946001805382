import { Dispatch, ReactNode, createContext, useContext, useReducer } from 'react';
import { Button, ButtonVariant, Modal, ModalSize } from '@estimateone/frontend-components';
import styles from './styles.scss';

type DeleteModalState =
  | {
      isOpen: false;
    }
  | {
      isOpen: true;
      onConfirm: () => undefined;
    };

type DeleteAction = {
  type: 'delete_note';
  onConfirm: () => unknown;
};

type CloseAction = {
  type: 'close_modal';
};

type Actions = DeleteAction | CloseAction;

export const DeleteProjectNotesContext = createContext<Dispatch<Actions> | undefined>(undefined);

type DeleteProjectNoteProviderProps = {
  children?: ReactNode;
};

const DeleteProjectNoteProvider = ({ children }: DeleteProjectNoteProviderProps) => {
  const reducer = (_: DeleteModalState, action: Actions) => {
    switch (action.type) {
      case 'delete_note':
        return {
          isOpen: true,
          onConfirm: action.onConfirm,
        };
      case 'close_modal':
        return {
          isOpen: false,
        };
      default:
        throw new Error('invalid action passed');
    }
  };

  const initialState = {
    isOpen: false,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const handleCloseModal = () => {
    dispatch({ type: 'close_modal' });
  };

  const handleConfirm = () => {
    if (state.onConfirm) {
      state.onConfirm();
    }

    handleCloseModal();
  };

  return (
    <DeleteProjectNotesContext.Provider value={dispatch}>
      {children}
      <Modal isOpen={state.isOpen} onRequestClose={handleCloseModal} size={ModalSize.Small}>
        <Modal.Section>
          <h3 className={styles.modalHeader}>Delete Note</h3>
          <p className={styles.modalContent}>Are you sure you want to delete this note?</p>
          <Button type="button" variant={ButtonVariant.Red} onClick={handleConfirm}>
            Delete
          </Button>
        </Modal.Section>
      </Modal>
    </DeleteProjectNotesContext.Provider>
  );
};

export const useDeleteProjectNotesContext = () => {
  const ctx = useContext(DeleteProjectNotesContext);
  if (!ctx) throw Error('DeleteProjectNotesProvider not a parent');
  return ctx;
};

export default DeleteProjectNoteProvider;
