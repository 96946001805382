import $ from 'jquery';

export default class ContactTypeIcon {
  constructor(types) {
    this.types = types;
    this.icons = {
      1: 'estimating',
      2: 'construction',
    };
  }

  getIcons() {
    const $icon = $('<span>');
    const globalObj = window.global;

    if (globalObj && globalObj.account && globalObj.account.contactTypes) {
      $icon.addClass('contact-type-indicator');

      this.types.forEach((value) => {
        $icon.addClass(this.icons[value]);
      });
    }

    return $icon;
  }
}
