import {
  Button,
  ButtonSize,
  ButtonVariant,
  joinClassNames,
  SpeciFinderIcon,
} from '@estimateone/frontend-components';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

type KeywordsButtonProps = {
  stageId: EntityId;
  onClick: () => void;
};

const KeywordsButton = ({ stageId, onClick }: KeywordsButtonProps) => (
  <Button
    data-tip
    data-for={`keywordsTooltip-${stageId}`}
    variant={ButtonVariant.Transparent}
    size={ButtonSize.Small}
    className={joinClassNames(styles.speciFinderButton, onClick !== undefined && styles.clickable)}
    onClick={(e) => {
      e.currentTarget.blur();
      onClick();
    }}
  >
    <SpeciFinderIcon className={styles.speciFinderIcon} />
    View keywords
  </Button>
);

export default KeywordsButton;
