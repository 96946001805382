import Footer from './Footer';
import Summary from './Summary';
import { SubbieProfileForAccount_subbieProfileForAccount as SubbieProfile } from '../../../builder/pages/Profiles/SubbieProfilesPage/types/SubbieProfileForAccount';

type ProfilesSummaryProps = {
  profile: Omit<SubbieProfile, 'defaultOffice'> & {
    defaultOffice: SubbieProfile['defaultOffice'] | null;
  };
};

const ProfilesSummary = ({ profile }: ProfilesSummaryProps) => (
  <>
    <Summary profile={profile} />

    <Footer memberSince={profile.memberSince} lastActiveAt={profile.lastActiveAt} />
  </>
);

export default ProfilesSummary;
