import { useState } from 'react';
import { ActionMenu, ColumnInstance, Toggle } from '@estimateone/frontend-components';
import { Package } from '@builder/features/ProcurementLettingSchedule/types';
import styles from './styles.scss';

type ColumnActionMenuItemProps = {
  columns: ColumnInstance<Package>[];
  displayName: string;
  isShown: boolean;
};

export const ColumnActionMenuItem = ({
  columns,
  displayName,
  isShown,
}: ColumnActionMenuItemProps) => {
  const [columnShown, setColumnShown] = useState(isShown);

  return (
    <ActionMenu.Item
      className={styles.columnMenuItem}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();

        setColumnShown(!columnShown);
        columns.forEach((column) => column.toggleHidden());
      }}
    >
      <span>{displayName}</span>
      <Toggle
        label={`Show ${displayName}`}
        checked={columnShown}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onChange={(e) => {
          setColumnShown(e.target.checked);
          columns.forEach((column) => column.toggleHidden());
        }}
      />
    </ActionMenu.Item>
  );
};
