import {
  Alert,
  AlertVariant,
  Button,
  ButtonSize,
  ButtonVariant,
  CloseIcon,
  E1Link,
  IntegrateIcon,
} from '@estimateone/frontend-components';
import { Addendum } from './types';
import { StageDocumentIntegrationType } from '@ascension/_gqltypes/builder.generated';
import styles from './styles.scss';

export type RevisionFlowAlertBannerProps = {
  addendum: Addendum;
  documentIntegrationType: StageDocumentIntegrationType;
  onOpen: () => void;
  onClose?: () => void;
};
export const RevisionFlowAlertBanner = ({
  addendum,
  documentIntegrationType,
  onOpen,
  onClose,
}: RevisionFlowAlertBannerProps) =>
  addendum.documents.length > 0 ? (
    <Alert variant={AlertVariant.Yellow}>
      <div className={styles.notificationContent}>
        <IntegrateIcon className={styles.notificationIcon} />
        <div className={styles.notificationText}>
          {addendum.documents.length} new or revised files from{' '}
          {documentIntegrationType === StageDocumentIntegrationType.PROCORE
            ? 'Procore'
            : 'SharePoint'}
          . <E1Link onClick={onOpen}>Review updates and notify affected subcontractors.</E1Link>
        </div>
        {onClose ? (
          <Button
            variant={ButtonVariant.Transparent}
            size={ButtonSize.Small}
            onClick={onClose}
            aria-label="Close revision alert"
          >
            <CloseIcon />
          </Button>
        ) : (
          false
        )}
      </div>
    </Alert>
  ) : null;
