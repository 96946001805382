import { Checkbox, CheckboxStatus } from '@estimateone/frontend-components';
import { FileSelectorListItem } from '../../DocumentIntegrationConnectSlider/FileSelection/FileSelectorListItem';
import { SharePointDriveItem } from '../types';
import { SharePointDriveItemType } from '@ascension/_gqltypes/builder.generated';
import styles from './styles.scss';

type DriveItemSelectionProps = {
  driveItems: SharePointDriveItem[];
  selectedDriveItems: Map<string, SharePointDriveItem>;
  onSelectionChange: (selectedDriveItems: Map<string, SharePointDriveItem>) => void;
};

export const DriveItemSelection = ({
  driveItems,
  selectedDriveItems,
  onSelectionChange,
}: DriveItemSelectionProps) => {
  const handleDriveItemSelection = (driveItemId: string) => {
    const updatedSelectedDriveItems = new Map(selectedDriveItems);
    if (updatedSelectedDriveItems.has(driveItemId)) {
      updatedSelectedDriveItems.delete(driveItemId);
    } else {
      const driveItem = driveItems.find((item) => item.id === driveItemId);
      updatedSelectedDriveItems.set(driveItemId, driveItem!);
    }
    onSelectionChange(updatedSelectedDriveItems);
  };

  const toggleSelectAll = () => {
    const allDrivesItemsAlreadySelected = selectedDriveItems.size === driveItems.length;
    if (allDrivesItemsAlreadySelected) {
      onSelectionChange(new Map());
    } else {
      onSelectionChange(new Map(driveItems.map((item) => [item.id, item])));
    }
  };

  const isAllSelected = selectedDriveItems.size === driveItems.length;

  return (
    <div>
      <div className={styles.driveItemsDescription}>
        <p>Select items to import from SharePoint (this can be changed later)</p>
        <p>Choose the whole directory or specific top-level items.</p>
      </div>
      <Checkbox
        id="all-documents"
        label={<span className={styles.labelHeavy}>All Documents</span>}
        statusCurrent={isAllSelected ? CheckboxStatus.Checked : CheckboxStatus.Unchecked}
        onClick={toggleSelectAll}
      />
      <ul className={styles.driveItemList}>
        {driveItems.map((item) => (
          <FileSelectorListItem
            key={item.id}
            id={item.id}
            name={item.name}
            type={item.type === SharePointDriveItemType.FILE ? 'file' : 'folder'}
            isLocked={false}
            status={
              selectedDriveItems.has(item.id) ? CheckboxStatus.Checked : CheckboxStatus.Unchecked
            }
            handleUpdate={handleDriveItemSelection}
          />
        ))}
      </ul>
    </div>
  );
};
