import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_CUSTOM_COLUMN_VALUE } from '../mutations';
import {
  UpdateCustomColumnValue,
  UpdateCustomColumnValueVariables,
} from '../types/UpdateCustomColumnValue';
import { UpdateLettingScheduleCustomColumnValueInput } from '@ascension/_gqltypes/builder.generated';

export const useUpdateCustomColumnValue = () => {
  const [updateCustomColumnValue] = useMutation<
    UpdateCustomColumnValue,
    UpdateCustomColumnValueVariables
  >(UPDATE_CUSTOM_COLUMN_VALUE);

  const updateValue = useCallback(
    async ({
      identifier,
      lettingScheduleId,
      value,
    }: UpdateLettingScheduleCustomColumnValueInput) => {
      await updateCustomColumnValue({
        variables: {
          input: {
            identifier,
            lettingScheduleId,
            value,
          },
        },
        ignoreResults: true,
      });
    },
    [updateCustomColumnValue],
  );

  return { updateValue };
};
