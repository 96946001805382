import { useMutation } from '@apollo/client';
import { UPDATE_ABOUT_US } from '../mutations';
import { UpdateAboutUsMutation, UpdateAboutUsMutationVariables } from '../mutations.generated';
import { EditCompanyFieldValues } from './types';

export const useSubmitForm = () => {
  const [handleUpdateAboutUs, { error }] = useMutation<
    UpdateAboutUsMutation,
    UpdateAboutUsMutationVariables
  >(UPDATE_ABOUT_US);

  const submit = async ({ aboutUs }: EditCompanyFieldValues) => {
    const { data, errors } = await handleUpdateAboutUs({
      variables: { aboutUs: aboutUs ?? '' },
    });

    return {
      data,
      errors,
    };
  };

  return { submit, error: error ?? undefined };
};
