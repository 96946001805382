import { MaxSpeciFinderKeywords, PageType, FeatureDto } from './types';
import { AccountType } from '@ascension/_gqltypes/subbie.generated';

const getProjectPaywallValueOrPlaceHolder = (projectPaywallValue?: number) =>
  projectPaywallValue ? projectPaywallValue / 1e6 : '-';

const getProjectValueFeature = (
  accessAbovePaywallValue: boolean,
  fiftyPercentRedacted: boolean,
  projectPaywallValue: number,
) => {
  if (accessAbovePaywallValue) {
    return fiftyPercentRedacted
      ? 'Access to all tenders'
      : `Access projects above $${getProjectPaywallValueOrPlaceHolder(
          projectPaywallValue,
        )}m in value`;
  }

  return fiftyPercentRedacted
    ? 'Access to a LIMITED number of projects from over 1,000 builders'
    : `Access projects up to $${getProjectPaywallValueOrPlaceHolder(
        projectPaywallValue,
      )}m in value`;
};

const createFeatureDto = (
  text: string,
  term = '',
  types: PageType[] = [PageType.Selection, PageType.Checkout],
): FeatureDto => ({
  text,
  types,
  term,
});

export const getBaseFeatures = (
  projectPaywallValue: number | undefined,
  accountType: AccountType | undefined,
  fiftyPercentRedacted: boolean | undefined,
  accessAbovePaywallValue: boolean,
  numOfKeywords: number,
): FeatureDto[] => {
  if (projectPaywallValue === undefined || fiftyPercentRedacted === undefined) return [];

  const getSupplierFeature = () =>
    accountType === AccountType.SUPPLIER
      ? [
          createFeatureDto(
            `Speci-Finder notifications for up to ${numOfKeywords} saved products`,
            numOfKeywords.toString(),
          ),
        ]
      : [];

  return [
    createFeatureDto(
      getProjectValueFeature(accessAbovePaywallValue, fiftyPercentRedacted, projectPaywallValue),
      'above',
    ),
    createFeatureDto('Manage invites from multiple builders', '', [PageType.Selection]),
    ...getSupplierFeature(),
    createFeatureDto('A directory of active builders to grow your network', '', [
      PageType.Selection,
    ]),
    createFeatureDto('A watchlist to track projects of interest', '', [PageType.Selection]),
  ];
};

const getStandardFeatures = (type: PageType = PageType.Selection): FeatureDto[] => {
  const features = [
    createFeatureDto('Sync deadlines to your work calendar'),
    createFeatureDto('Awarded builder information'),
  ];

  return features.filter((feature) => feature.types.includes(type));
};

export const getStandardAndBaseFeatures = (
  projectPaywallValue: number | undefined,
  accountType: AccountType | undefined,
  fiftyPercentRedacted: boolean | undefined,
  type: PageType = PageType.Selection,
): FeatureDto[] => {
  if (
    projectPaywallValue === undefined ||
    accountType === undefined ||
    fiftyPercentRedacted === undefined
  )
    return [];

  const baseFeatures = getBaseFeatures(
    projectPaywallValue,
    accountType,
    fiftyPercentRedacted,
    true,
    MaxSpeciFinderKeywords.Standard,
  );

  const features = [...baseFeatures, ...getStandardFeatures()];

  return features.filter((feature) => feature.types.includes(type));
};

export const getProfessionalLicenseFeatures = (
  projectPaywallValue: number | undefined,
  accountType: AccountType | undefined,
  fiftyPercentRedacted: boolean | undefined,
  type: PageType = PageType.Selection,
): FeatureDto[] => {
  if (
    projectPaywallValue === undefined ||
    accountType === undefined ||
    fiftyPercentRedacted === undefined
  )
    return [];

  const baseFeatures = getBaseFeatures(
    projectPaywallValue,
    accountType,
    fiftyPercentRedacted,
    true,
    MaxSpeciFinderKeywords.Professional,
  );

  const features = [
    ...baseFeatures,
    ...getStandardFeatures(type),
    createFeatureDto('Comprehensive directory of consultants'),
    createFeatureDto('See working consultants for each project'),
  ];

  if (accountType === AccountType.SUPPLIER) {
    features.push(createFeatureDto('A comparative report identifying specifiers of your product'));
  }

  return features.filter((feature) => feature.types.includes(type));
};
