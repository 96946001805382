type UniversalProfileDrawerSubpage_Quote = 'app_quote_view' | 'stage_quotes';
type UniversalProfileDrawerSubpage_AddressBook =
  | 'addressbook_connectionsuggestions'
  | 'addressbook_request'
  | 'addressbook_search'
  | 'addressbook_duplicates'
  | 'add-company-form'
  | 'stage_request';
type UniversalProfileDrawerSubpage_Directory = 'e1_app_subbie_network';
type UniversalProfileDrawerSubpage_NetworkInvites =
  | 'stagewizard_invitations'
  | 'app_subbie_network_invitations_index';
type UniversalProfileDrawerSubpage_RFQ =
  | 'stage_monitor'
  | 'stage_invitations'
  | 'constructionstage_correspondence'
  | 'stage_correspondence'
  | 'packagedashboard_view';
type UniversalProfileDrawerSubpage =
  | UniversalProfileDrawerSubpage_Quote
  | UniversalProfileDrawerSubpage_AddressBook
  | UniversalProfileDrawerSubpage_Directory
  | UniversalProfileDrawerSubpage_NetworkInvites
  | UniversalProfileDrawerSubpage_RFQ;

function isAddressBookSubpage(
  subpage: UniversalProfileDrawerSubpage,
): subpage is UniversalProfileDrawerSubpage_AddressBook {
  return [
    'addressbook_connectionsuggestions',
    'addressbook_request',
    'addressbook_search',
    'addressbook_duplicates',
    'add-company-form',
    'stage_request',
  ].includes(subpage);
}

function isDirectorySubpage(
  subpage: UniversalProfileDrawerSubpage,
): subpage is UniversalProfileDrawerSubpage_Directory {
  return subpage === 'e1_app_subbie_network';
}

function isNetworkInvitesSubpage(
  subpage: UniversalProfileDrawerSubpage,
): subpage is UniversalProfileDrawerSubpage_NetworkInvites {
  return (
    subpage === 'stagewizard_invitations' || subpage === 'app_subbie_network_invitations_index'
  );
}

function isQuoteSubpage(
  subpage: UniversalProfileDrawerSubpage,
): subpage is UniversalProfileDrawerSubpage_Quote {
  return subpage === 'app_quote_view' || subpage === 'stage_quotes';
}

function isRFQSubpage(
  subpage: UniversalProfileDrawerSubpage,
): subpage is UniversalProfileDrawerSubpage_RFQ {
  return [
    'stage_monitor',
    'stage_invitations',
    'constructionstage_correspondence',
    'stage_correspondence',
    'packagedashboard_view',
  ].includes(subpage);
}

export {
  UniversalProfileDrawerSubpage,
  isAddressBookSubpage,
  isDirectorySubpage,
  isNetworkInvitesSubpage,
  isQuoteSubpage,
  isRFQSubpage,
};
