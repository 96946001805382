import { Card, CardSize, CardVariant } from '@estimateone/frontend-components';
import SearchCompany from './SearchCompany';
import { SearchedCompany } from '@builder/common/AddressBook/hooks/useSearchAddressBookCompanies';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

type ManualMergeSearchCardProps = {
  updateCompany: (company: SearchedCompany) => void;
  excludedCompanyId?: EntityId;
};

const ManualMergeSearchCard = ({
  updateCompany,
  excludedCompanyId,
}: ManualMergeSearchCardProps) => (
  <div className={styles.manualMergeSearchCard}>
    <Card variant={CardVariant.Grey} size={CardSize.Small}>
      <Card.Body isWhite>
        <SearchCompany updateCompany={updateCompany} excludedCompanyId={excludedCompanyId} />
      </Card.Body>
    </Card>
  </div>
);

export default ManualMergeSearchCard;
