import { gql } from '@apollo/client';

export const SCOPE_OF_WORKS_QUERY = gql`
  query Scope($packageId: ID!) {
    scopeByPackage(packageId: $packageId) {
      id
      scopeFile {
        id
        fileName
      }
      scopeContent {
        id
      }
      scopeRfqStatuses {
        rfqId
        status
        updatedAt
      }
    }
  }
`;
