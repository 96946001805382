import { useState } from 'react';
import {
  joinClassNames,
  RadioButton,
  ScreenReaderOnly,
  Table,
  TableColumn,
  Tooltip,
  Select,
  E1Link,
  LinkVariant,
  AlertVariant,
  Alert,
  InfoIcon,
  TableRow,
  CellProps,
} from '@estimateone/frontend-components';
import { format } from 'date-fns';
import { getAddressString } from '../procore.utils';
import { companies_companies as Companies } from '../types/companies';
import { projects_projects as Projects } from '../types/projects';
import { InterfaceToType } from '@ascension/types';
import styles from './styles.scss';

export enum Availability {
  AVAILABLE = 'AVAILABLE',
  NO_ACCESS = 'NO_ACCESS',
  ALREADY_MAPPED = 'ALREADY_MAPPED',
}

export type ProcoreProject = InterfaceToType<Projects>;
export type ProcoreCompany = InterfaceToType<Companies>;
interface ProcoreProjectListProps {
  stageName: string;
  projects: ProcoreProject[];
  companies: ProcoreCompany[];
  selectedProjectId: string | null;
  handleProjectSelection: (id: string) => void;
}

export const ProcoreProjectList = ({
  stageName,
  projects,
  companies,
  selectedProjectId,
  handleProjectSelection,
}: ProcoreProjectListProps) => {
  const [selectedCompany, setSelectedCompany] = useState<ProcoreCompany | null>(
    companies.length === 1 ? companies[0] : null,
  );

  const getProjectsForSelectedCompany = () =>
    projects.filter((p) => p.company.id === selectedCompany?.id);

  const tableColumns: TableColumn<ProcoreProject>[] = [
    {
      id: 'checkbox',
      Header: <ScreenReaderOnly>Select Project</ScreenReaderOnly>,
      tooltipTitle: 'Select Project',
      accessor: 'id',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value, row: { original } }: CellProps<ProcoreProject>) => (
        <RadioButton
          id={value}
          onChange={() => {
            handleProjectSelection(value);
          }}
          disabled={
            original.availability === Availability.NO_ACCESS ||
            original.availability === Availability.ALREADY_MAPPED
          }
          checked={selectedProjectId === value}
        />
      ),
      disableSortBy: true,
      fitToContent: true,
    },
    {
      id: 'id',
      Header: 'Project #',
      tooltipTitle: 'Project ID',
      accessor: 'id',
    },
    {
      id: 'name',
      Header: 'Project Name',
      tooltipTitle: 'Project Name',
      accessor: 'name',
    },
    {
      id: 'address',
      Header: 'Address',
      tooltipTitle: 'Project Address',
      accessor: ({ address }) => (address ? getAddressString(address) : false),
    },
    {
      id: 'dateCreated',
      Header: 'Date Created',
      tooltipTitle: 'Project Created Date',
      accessor: ({ dateCreatedAt }) =>
        dateCreatedAt ? format(new Date(dateCreatedAt), 'd MMM yyyy') : false,
    },
  ];

  const getRowProps = (row: TableRow<ProcoreProject>) => {
    const isUnavailable =
      row.original.availability === Availability.NO_ACCESS ||
      row.original.availability === Availability.ALREADY_MAPPED;
    const className = joinClassNames(
      styles.row,
      isUnavailable && styles.disabledRow,
      row.original.id === selectedProjectId && styles.selectedRow,
    );

    if (isUnavailable) {
      return {
        className,
        'data-tip': true,
        'data-for':
          row.original.availability === Availability.NO_ACCESS
            ? 'disabled-project-info'
            : 'already-mapped-project-info',
        'aria-describedby':
          row.original.availability === Availability.NO_ACCESS
            ? 'disabled-project-info'
            : 'already-mapped-project-info',
      };
    }

    return { className };
  };
  return (
    <>
      <Tooltip
        tooltipId="disabled-project-info"
        text="Ask your Procore Admin to grant permission to connect this Procore project to E1"
      />
      <Tooltip
        tooltipId="already-mapped-project-info"
        text="This Procore project is already connected to another E1 project"
      />
      <p className={styles.selectInstruction}>
        Select a company, then the Procore project that matches: <b>{stageName}</b>
      </p>
      <Select
        className={styles.selectCompany}
        id="procore-company-select"
        aria-label="Select Company"
        label="Select Company"
        hideLabel
        isRequired
        defaultValue={
          selectedCompany ? { label: selectedCompany.name, value: selectedCompany.id } : undefined
        }
        placeholder="Select a company..."
        options={companies.map((company) => ({
          label: company.name,
          value: company.id,
        }))}
        onChange={(value) => {
          setSelectedCompany(companies.find((c) => c.id === value?.value) || null);
        }}
      />
      <Table<ProcoreProject>
        columns={tableColumns}
        data={selectedCompany ? getProjectsForSelectedCompany() : []}
        rowProps={getRowProps}
      />
      {projects?.length === 0 && (
        <div className={styles.noProjectsText}>
          You don’t have permission to see any Procore projects with this Procore account
        </div>
      )}
      {selectedCompany && !getProjectsForSelectedCompany().length && (
        <>
          <p className={styles.noProjectsText}>
            No projects were found in this company, select another one.
          </p>
          <Alert variant={AlertVariant.Blue}>
            <div className={styles.noProjectsAlertContainer}>
              <InfoIcon className={styles.alertIcon} />
              <div className={styles.noProjectsAlertText}>
                <p>
                  If you believe projects should be available, check the following with your team’s
                  Procore Admin:
                  <li>the E1 app has been installed </li>
                  <li>
                    Procore Permissions have been set for E1 (
                    <E1Link
                      variant={LinkVariant.Primary}
                      href="https://builder.support.estimateone.com/en/articles/1705715936-giving-procore-permission-to-connect-to-estimateone"
                      target="_blank"
                      rel="noreferrer"
                    >
                      help article
                    </E1Link>
                    ).
                  </li>
                </p>
                <p>
                  If you’re still having issues, contact our Support team:{' '}
                  <E1Link
                    variant={LinkVariant.Primary}
                    href="mailto:support@estimateone.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    support@estimateone.com
                  </E1Link>
                  , or call us on 1300 705 035.
                </p>
              </div>
            </div>
          </Alert>
        </>
      )}
    </>
  );
};
