import Routing from 'routing';
import E1Request, { E1RequestMethod } from '@ascension/js/classes/E1Request';
import { reportError } from '@ascension/components/helpers/errorReporter';
import {
  isAddressBookSubpage,
  isDirectorySubpage,
  isNetworkInvitesSubpage,
  isQuoteSubpage,
} from './universalProfileDrawerSubpage';
import type { UniversalProfileDrawerSubpage } from './universalProfileDrawerSubpage';
import { EntityId } from '@ascension/types';

enum BuilderViewSource {
  ADDRESSBOOK = 'addressbook',
  DIRECTORY = 'directory',
  PAGE = 'page',
  QUOTE = 'quote',
  RFQ = 'rfq',
}

/**
 * we don't necessarily want to log all builder views of a profile...
 * if a builder is viewing a profile of a subbie they have in their Address Book
 * then we aren't going to tell the subbie about it.
 */
function shouldLogBuilderView(
  subpage: UniversalProfileDrawerSubpage,
  hasAddressBookCompanyId: boolean,
): boolean {
  switch (subpage) {
    case 'stage_monitor':
    case 'app_quote_view':
    case 'stage_quotes':
    case 'e1_app_subbie_network':
    case 'stagewizard_invitations':
    case 'app_subbie_network_invitations_index':
    case 'addressbook_connectionsuggestions':
    case 'addressbook_request':
    case 'stage_request':
    case 'add-company-form':
      return !hasAddressBookCompanyId;

    default:
      return false;
  }
}

function subPageToBuilderViewSource(subpage: UniversalProfileDrawerSubpage): BuilderViewSource {
  if (isAddressBookSubpage(subpage)) {
    return BuilderViewSource.ADDRESSBOOK;
  }

  if (isDirectorySubpage(subpage) || isNetworkInvitesSubpage(subpage)) {
    return BuilderViewSource.DIRECTORY;
  }

  if (isQuoteSubpage(subpage)) {
    return BuilderViewSource.QUOTE;
  }

  return BuilderViewSource.RFQ;
}

function createBuilderViewLog(subbieAccountId: EntityId, subpage: UniversalProfileDrawerSubpage) {
  const source = subPageToBuilderViewSource(subpage);

  return new E1Request<{ success: true }>(
    Routing.generate('app_subbie_profile_log_view', {
      id: subbieAccountId,
    }),
    E1RequestMethod.POST,
    { source },
  )
    .submit()
    .catch((error) => {
      reportError(error);
    });
}

export { UniversalProfileDrawerSubpage, createBuilderViewLog, shouldLogBuilderView };
