import { ServiceAreaBoundaryType, ServiceAreaType } from '@ascension/_gqltypes/profiles.generated';
import { CompanyOffice } from '@profiles/providers/ProfileProvider/types';

export const getServiceAreaType = (serviceArea?: CompanyOffice['serviceArea']) => {
  if (serviceArea?.type === ServiceAreaType.RADIAL) {
    return 'by-travel-distance';
  }

  if (serviceArea?.boundaries![0].type === ServiceAreaBoundaryType.STATE) {
    return 'by-states';
  }

  return 'by-country';
};

export const getServiceAreaRadius = (
  serviceAreaType: string,
  serviceArea?: CompanyOffice['serviceArea'],
) =>
  serviceAreaType === 'by-travel-distance' && serviceArea?.radius ? serviceArea.radius : 300_000;

export const getServiceAreaStates = (
  serviceAreaType: string,
  serviceArea?: CompanyOffice['serviceArea'],
) =>
  serviceAreaType === 'by-states' && serviceArea?.boundaries
    ? serviceArea.boundaries?.map((boundary) => ({
        value: boundary.id,
        label: boundary.shortName,
      }))
    : [];
