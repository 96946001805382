import moment from 'moment';
import { OrderItemVariant } from '@shared/Card/OrderSummaryCard';
import { AccountUpgradeContextProps, requiredAccountFields } from './context';
import { BillingCycle, PaymentMethod } from '@ascension/enums';
import { Account, AccountOffer, DiscountedPricingOption, Product, ProductWithPrice } from './types';
import { GetProductsAndRecommend_products_pricingOptions as PricingOption } from './types/GetProductsAndRecommend';
import { EntityId } from '@ascension/types';

export const getErrorMessages = (errors: { field: string | null; message: string }[]) =>
  errors.reduce(
    (allErrors, { field, message }) => ({
      ...allErrors,
      [field ?? 'global']: message,
    }),
    {},
  );

export const getDiscountDurationDescription = ({
  offer: { monthDuration },
}: AccountOffer): string =>
  monthDuration ? ` for ${monthDuration} month${monthDuration === 1 ? '' : 's'}` : '';

export const getEndOfDiscountDescription = (
  undiscountedPrice: number,
  { offer: { monthDuration } }: AccountOffer,
): string | null => {
  if (!monthDuration) return null;

  const endDate = moment().add(monthDuration, 'months').format('DD/MM/YYYY');
  const endPrice = Math.ceil(undiscountedPrice / 100);

  return `Regular price $${endPrice} begins ${endDate}`;
};

export const getPricingForBillingCycle = (
  product: Product,
  billingCycle: BillingCycle,
): PricingOption | undefined =>
  product.pricingOptions.find(({ billingCycle: optionCycle }) => optionCycle === billingCycle);

// Work to support selection of multiple products
//
// export const getPricingMapForBillingCycle = (
//   products: Product[],
//   billingCycle: BillingCycle,
// ): Map<EntityId, PricingOption> =>
//   new Map<EntityId, PricingOption>(
//     products.map((product) => [
//       product.id,
//       product.pricingOptions.find(({ billingCycle: optionCycle }) => optionCycle === billingCycle),
//     ]),
//   );
//
// export const getProductsAndPricingForBillingCycle = (
//   products: Product[],
//   billingCycle: BillingCycle,
// ): Map<EntityId, ProductWithPrice> =>
//   new Map<EntityId, ProductWithPrice>(
//     products.map((product) => {
//       const pricing = getPricingForBillingCycle(product, billingCycle);
//
//       return [
//         product.id,
//         {
//           id: product.id,
//           name: product.name,
//           isAddon: product.isAddon,
//           price: pricing.price.amount,
//           priceExGST: pricing.priceExGST.amount,
//           billingCycle: pricing.billingCycle,
//           savings: pricing.savings.amount,
//           shortDescription: null,
//           longDescription: null,
//         },
//       ];
//     }),
//   );

export const getPricingForProductIdAndOffer = (
  productId: EntityId,
  accountOffer: AccountOffer,
  billingCycle = BillingCycle.Months1,
): PricingOption | undefined =>
  accountOffer.offer.discountedPricingOptions.find(
    (po: DiscountedPricingOption) =>
      po.billingCycle === billingCycle && po.product.id === productId,
  );

export const getProductAndPricingForBillingCycle = (
  product: Product,
  billingCycle: BillingCycle,
): ProductWithPrice => {
  const pricing = getPricingForBillingCycle(product, billingCycle);

  return {
    id: product.id,
    name: product.name,
    isAddon: product.isAddon,
    price: pricing?.price?.amount ?? 0,
    priceExGST: pricing?.priceExGST?.amount ?? 0,
    billingCycle: pricing?.billingCycle ?? billingCycle,
    savings: pricing?.savings?.amount,
    shortDescription: null,
    longDescription: null,
  };
};

export const getProductAndAccountOfferDetails = (
  product: ProductWithPrice,
  accountOffer: AccountOffer,
  discount: PricingOption,
): ProductWithPrice => ({
  id: accountOffer.id,
  name: `Your discount ${getDiscountDurationDescription(accountOffer)}`,
  price: ((product.price || 0) - (discount.price?.amount || 0)) * -1,
  priceExGST: ((product.priceExGST || 0) - (discount.priceExGST?.amount || 0)) * -1,
  billingCycle: discount.billingCycle,
  savings: discount.savings?.amount,
  shortDescription: getEndOfDiscountDescription(product.priceExGST || 0, accountOffer),
  longDescription: null,
  variant: OrderItemVariant.Green,
  isAddon: product.isAddon,
});

export const isAccountOfferForProduct = (
  accountOffer: AccountOffer,
  productId: number,
  billingCycle: BillingCycle = BillingCycle.Months1,
): boolean => {
  if (!accountOffer.offer.products.find(({ id }) => id === productId)) {
    return false;
  }

  return getPricingForProductIdAndOffer(productId, accountOffer, billingCycle) !== undefined;
};

export const getShoppingCartProductsForOffer = (
  product: ProductWithPrice,
  accountOffer: AccountOffer,
): ProductWithPrice[] => {
  if (!isAccountOfferForProduct(accountOffer, product.id)) {
    return [];
  }

  const pricing = getPricingForProductIdAndOffer(product.id, accountOffer);

  return pricing ? [getProductAndAccountOfferDetails(product, accountOffer, pricing)] : [];
};

export const getOfferExpiresInDescription = (offerLapsesAt: string | null): string | null => {
  if (!offerLapsesAt || !moment(offerLapsesAt).isValid()) {
    return null;
  }

  const lapsesAt = moment(offerLapsesAt);
  const now = moment();

  if (lapsesAt.diff(now, 'weeks') >= 2) {
    return `on ${lapsesAt.format('Do MMMM')}`;
  }

  if (lapsesAt.diff(now, 'days') >= 3) {
    return `in ${lapsesAt.diff(now, 'days').toString()} days`;
  }

  if (lapsesAt.diff(now, 'hours') >= 1) {
    return `in ${lapsesAt.diff(now, 'hours').toString()} hours`;
  }

  return `in ${lapsesAt.diff(now, 'minutes').toString()} minutes`;
};

export const noCreditCardError = 'Please add a credit card';

export const validateUpgradeData = (
  accountUpgradeData: Pick<
    AccountUpgradeContextProps,
    'account' | 'paymentDataWithExistingCreditCardOrEFT'
  >,
): { [p: string]: string } => {
  const { account, paymentDataWithExistingCreditCardOrEFT: paymentData } = accountUpgradeData;

  if (!account) return {};

  const accountInfoErrors = requiredAccountFields.reduce((accountErrors, field: keyof Account) => {
    if (!account[field]) {
      return { ...accountErrors, ...{ [field]: 'This field should not be blank' } };
    }
    return accountErrors;
  }, {});

  const creditCardErrors =
    paymentData.paymentMethod === PaymentMethod.CC && !paymentData.creditCardId
      ? {
          globalCreditCard: noCreditCardError,
        }
      : undefined;

  const termsError = paymentData.terms
    ? undefined
    : { terms: 'You must accept the Terms and Conditions to continue.' };

  return { ...accountInfoErrors, ...creditCardErrors, ...termsError };
};
