const findModalEntry = (myArray: number[]): number | null =>
  myArray.reduce(
    (a, b, i, arr) =>
      arr.filter((v) => v === a).length >= arr.filter((v) => v === b).length ? a : b,
    null,
  );

const groupByKey =
  <T>(transform: (entry: T) => string) =>
  (collection: T[]) =>
    collection.reduce((groups, entry: T) => {
      const key = transform(entry);
      return groups.set(key, groups.get(key)?.concat([entry]) ?? [entry]);
    }, new Map<string, T[]>());

export { findModalEntry, groupByKey };
