/* eslint-disable react/jsx-props-no-spreading */
import {
  FieldWrapper as FecFieldWrapper,
  FieldWrapperProps as FecFieldWrapperProps,
} from '@estimateone/frontend-components/src/components/Form/FieldWrapper/FieldWrapper';

export type FieldWrapperProps = FecFieldWrapperProps & {
  showErrors?: boolean;
};

export const FieldWrapper = ({
  children,
  errors,
  showErrors = false,
  ...fecFieldWrapperProps
}: FieldWrapperProps) => (
  <FecFieldWrapper errors={showErrors && errors ? errors : undefined} {...fecFieldWrapperProps}>
    {children}
  </FecFieldWrapper>
);
