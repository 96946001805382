import { useEffect } from 'react';
import { Wrapper } from '@estimateone/frontend-components';
import { renderSubbieProfileInSliderForBuilder } from '../../../../../../../entrypoint/builder-subbie-network';
import { reportError } from '../../../../../helpers/errorReporter';
import {
  SubbieProfilesSliderHeader,
  OldSubbieProfilesSliderHeader,
} from '../SubbieProfilesSliderView/SubbieProfilesSliderHeader';
import ProfilesNav from '../../../../../shared/Profiles/ProfilesNav';
import ProfilesSummary from '../../../../../shared/Profiles/ProfilesSummary';
import { ProfileSection } from '@shared/Profiles/enums';
import { SubbieProfileForAccount_subbieProfileForAccount as SubbieProfile } from '../../../../pages/Profiles/SubbieProfilesPage/types/SubbieProfileForAccount';
import { AccountType } from '@ascension/_gqltypes/admin.generated';
import styles from './styles.scss';

export type SubbieProfilesSliderLayoutProps = {
  profile: SubbieProfile;
  profilesAscensionUrl: string;
};

const ProfileSliderLayout = ({
  profile,
  profilesAscensionUrl,
}: SubbieProfilesSliderLayoutProps) => {
  // The classic profile id is... the account id?
  useEffect(() => {
    const error = renderSubbieProfileInSliderForBuilder(profile.id);
    if (error) {
      reportError(error);
    }
  }, [profile.id]);

  // TODO: heard you like indirection so I put some indirection in your indirection
  return (
    <>
      <div className={styles.headerContainer}>
        <SubbieProfilesSliderHeader profile={profile} />
      </div>
      <div
        id="subbie-profiles-slider-container-for-builder"
        // Try not to have a little cry
        data-profiles-ascension-url={profilesAscensionUrl}
        className={styles.profileContentStyle}
      />
    </>
  );
};

const OldProfileSliderLayout = ({ profile }: { profile: SubbieProfile }) => (
  <>
    <div className={styles.oldHeaderContainer}>
      <Wrapper>
        <OldSubbieProfilesSliderHeader profile={profile} />
        <ProfilesNav currentSection={ProfileSection.SUMMARY} />
      </Wrapper>
    </div>

    <ProfilesSummary profile={profile} />
  </>
);

const SubbieProfilesSliderLayout = ({
  profile,
  profilesAscensionUrl,
}: SubbieProfilesSliderLayoutProps) => {
  const isSubbieProfile = profile.type === AccountType.SUBCONTRACTOR;
  return isSubbieProfile ? (
    <ProfileSliderLayout profile={profile} profilesAscensionUrl={profilesAscensionUrl} />
  ) : (
    <OldProfileSliderLayout profile={profile} />
  );
};

export default SubbieProfilesSliderLayout;
