import { ReactElement, cloneElement, useReducer } from 'react';
import { E1Link, Modal, ModalSize } from '@estimateone/frontend-components';
import { EditAboutUsForm } from './EditAboutUsForm';
import { EditCompanyFieldLabels, EditCompanyFieldNames } from './EditAboutUsForm/types';

type EditAboutUsProps = {
  fieldValue: string | null;
  triggerElement?: ReactElement;
};

export const EditAboutUs = ({ fieldValue, triggerElement }: EditAboutUsProps) => {
  const [isOpen, toggle] = useReducer((curr) => !curr, false);

  return (
    <div>
      {triggerElement ? (
        cloneElement(triggerElement, { onClick: toggle })
      ) : (
        <E1Link onClick={toggle}>Edit</E1Link>
      )}

      <Modal
        rootElement="#MFE_PROFILES_ROOT"
        isOpen={isOpen}
        onRequestClose={() => toggle()}
        size={ModalSize.Small}
      >
        <Modal.Section>
          <EditAboutUsForm
            fieldName={EditCompanyFieldNames.CompanyAboutUs}
            fieldLabel={EditCompanyFieldLabels.CompanyAboutUs}
            fieldValue={fieldValue}
            toggle={toggle}
          />
        </Modal.Section>
      </Modal>
    </div>
  );
};
