/** SUBBIE_CREDIT_EXPERIMENT_ALPHA_CODE */
import { E1Link } from '@estimateone/frontend-components';
import { LitePlanButtonsView } from '../ButtonsSection';
import { LitePlanGraphicSection } from '../GraphicSection';
import { LitePlanHeader } from '../HeaderSection';
import { TradesSection } from '../TradesSection';
import { useContactUsAboutUpgrade } from '@subbie/features/AccountUpgradeFeature/pages/NewUpgradeSelection/hooks';
import { UPGRADE_TYPE } from '@subbie/features/AccountUpgradeFeature/pages/NewUpgradeSelection/hooks/types';
import styles from './styles.module.scss';

const ReminderContent = () => (
  <>
    Just a reminder, the Lite plan includes access to <strong>10 tenders per month. </strong>
    Once you have used all your tenders, you won&apos;t have access to your next tender until the
    1st of next month.
  </>
);

type AdditionalContentProps = {
  handleUpgrade: () => void;
};

const AdditionalContent = ({ handleUpgrade }: AdditionalContentProps) => (
  <div>
    <p>
      <strong>Nice, found a winning tender?</strong>
    </p>
    <p>
      <ReminderContent />{' '}
    </p>
    <br />
    <p>
      <strong>Want to access all open tenders? </strong>By{' '}
      <E1Link onClick={handleUpgrade}>upgrading your account</E1Link> you&apos;ll get full access
      across open tenders in your state, awarded work information plus a whole lot more!
    </p>
  </div>
);

type LitePlanCreditsContentViewProps = {
  onClick: () => void;
  isUnlockProjectLoading: boolean;
  projectId: number;
  allowedCredits: number;
  usedCredits: number;
  removeModal: () => void;
};

export const LitePlanCreditsContentView = ({
  onClick,
  isUnlockProjectLoading,
  projectId,
  allowedCredits,
  usedCredits,
  removeModal,
}: LitePlanCreditsContentViewProps) => {
  const openContactUsForm = useContactUsAboutUpgrade(UPGRADE_TYPE.Essential);

  const handleUpgrade = () => {
    openContactUsForm();
    removeModal();
  };

  return (
    <>
      <LitePlanGraphicSection />
      <LitePlanHeader allowedCredits={allowedCredits} usedCredits={usedCredits} />
      <TradesSection projectId={projectId} />
      <div className={styles.additionalFeaturesContainerCreditExperiment}>
        <AdditionalContent handleUpgrade={handleUpgrade} />
      </div>
      <LitePlanButtonsView
        handleUpgrade={handleUpgrade}
        handleUnlockTender={onClick}
        isUnlockProjectLoading={isUnlockProjectLoading}
      />
    </>
  );
};
