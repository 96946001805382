/* eslint-disable react/no-unstable-nested-components */
import { useCallback } from 'react';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  FullScreenModal,
  LoadingSpinner,
} from '@estimateone/frontend-components';
import {
  NotifyForm,
  NotifyFormSubmitHandler,
} from '@builder/features/Documents/NotifyForm/NotifyForm';
import { NotifyFormContent } from '@builder/features/Documents/NotifyForm/NotifyFormContent';
import { PageWrapper } from '../../PageWrapper';
import { useRevisionFlow } from '../../RevisionFlow';
import { useFinaliseMatrix } from '../../hooks/useFinaliseMatrix';
import { useGetNotifyProgress } from '../../hooks/useGetNotifyProgress';
import { GetDownloadedPackages_downloadedPackages as DownloadedPackage } from '../../types/GetDownloadedPackages';
import { InterfaceToType } from '@ascension/types';
import styles from './styles.scss';

export type NotifyDownloadedPackage = InterfaceToType<DownloadedPackage>;

export type NotifyPreferences = {
  notifyDownloaded: boolean;
  notifyNotDownloadedResponded: boolean;
  notifyNotDownloadedNoResponse: boolean;
};

const NOTIFY_POLLING_INTERVAL = 1000;

export const NotifyStep = () => {
  const { stageId, addendum, close, complete, goToPreviousStep } = useRevisionFlow();
  const { submit } = useFinaliseMatrix();
  const { getNotifyProgress } = useGetNotifyProgress(stageId);

  const onSubmitHandler: NotifyFormSubmitHandler = useCallback(
    async ({ title, message, selectedPackages, notifyPreferences }) => {
      await submit({
        stageId,
        addendumId: addendum.id,
        selectedPackages,
        title,
        message,
        notifyPreferences,
      });
      const progress = await getNotifyProgress();
      progress.observable.subscribe(({ data: response }) => {
        if (response.notifyProgress.matrixChangeProcessed) {
          progress.stopPolling();
          complete();
        }
      });
      progress.startPolling(NOTIFY_POLLING_INTERVAL);
    },
    [addendum.id, complete, getNotifyProgress, stageId, submit],
  );

  return (
    <NotifyForm stageId={stageId} addendum={addendum} onSubmit={onSubmitHandler}>
      {({
        handleSubmit,
        touched,
        message,
        isLoadingDownloadedPackages,
        filteredPackages,
        notificationCount,
        notifyPreferences,
        setNotifyPreferences,
        setTitle,
        setMessage,
        downloadedPackages,
        selectedPackages,
        setSelectedPackages,
        stageSettingNotifyAwarded,
        submissionTried,
        sendingNotifications,
        totalInvites,
      }) => (
        <form id="notify-step-form" onSubmit={handleSubmit} className={styles.fullHeightWrapper}>
          <FullScreenModal.Content>
            <PageWrapper>
              <NotifyFormContent
                touched={touched}
                isLoadingDownloadedPackages={isLoadingDownloadedPackages}
                filteredPackages={filteredPackages}
                notificationCount={notificationCount}
                notifyPreferences={notifyPreferences}
                hasAttemptedSubmission={submissionTried}
                setTitle={setTitle}
                message={message}
                setMessage={setMessage}
                setNotifyPreferences={setNotifyPreferences}
                downloadedPackages={downloadedPackages}
                selectedPackages={selectedPackages}
                setSelectedPackages={setSelectedPackages}
                stageSettingNotifyAwarded={stageSettingNotifyAwarded}
                totalInvites={totalInvites}
              />
            </PageWrapper>
          </FullScreenModal.Content>

          <FullScreenModal.Footer>
            <PageWrapper>
              <div className={styles.actionContainer}>
                <Button
                  variant={ButtonVariant.Grey}
                  size={ButtonSize.Medium}
                  fullWidth
                  onClick={close}
                  style={{ width: '192px' }}
                >
                  Cancel
                </Button>
                <div className={styles.submitButtonContainer}>
                  <Button
                    variant={ButtonVariant.Secondary}
                    size={ButtonSize.Medium}
                    style={{ width: '192px' }}
                    onClick={() => {
                      goToPreviousStep();
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    size={ButtonSize.Medium}
                    disabled={sendingNotifications}
                    type="submit"
                    style={{ width: totalInvites && totalInvites > 0 ? 'unset' : '192px' }}
                  >
                    {sendingNotifications ? (
                      <>
                        Completing <LoadingSpinner />
                      </>
                    ) : (
                      <>
                        Complete
                        {totalInvites && totalInvites > 0
                          ? ` and Send ${notificationCount} Notification(s)`
                          : null}
                      </>
                    )}
                  </Button>
                </div>
              </div>
            </PageWrapper>
          </FullScreenModal.Footer>
        </form>
      )}
    </NotifyForm>
  );
};
