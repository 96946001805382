import { Alert, AlertProps, AlertVariant, InfoIcon } from '@estimateone/frontend-components';
import styles from './styles.scss';

type WarningAlertProps = AlertProps;

export const WarningAlert = ({ children, ...props }: WarningAlertProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Alert variant={AlertVariant.Yellow} {...props}>
    <div className={styles.rowContainer}>
      <div className={styles.iconContainer}>
        <InfoIcon />
      </div>
      <div>{children}</div>
    </div>
  </Alert>
);
