import { gql } from '@apollo/client';
import { LicensesTableResult } from './fragments';

export const GET_LICENSES_TABLE_RESULT = gql`
  query GetLicenseTableResult {
    getLicensesForTable {
      currentAccount {
        type
        isMultiState
        licensingInfo {
          paidLicenseProvider {
            name
          }
          hasAtLeastOneFullPriceActiveMultiStateLicense
        }
      }
      activeLicenses {
        ...LicensesTableResult
      }
      sharedLicenses {
        ...LicensesTableResult
      }
    }
  }
  ${LicensesTableResult}
`;
