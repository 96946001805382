import { gql } from '@apollo/client';

export const RECENT_NOTIFICATIONS = gql`
  query GetRecentNotifications {
    recentNotifications {
      id
      type
      topic {
        key
        value
      }
      version
      data {
        name
        value
      }
      viewedAt
      createdAt
    }
  }
`;

export const NOTIFICATIONS_LIST_LAST_OPENED = gql`
  query NotificationsListLastOpened {
    notificationsListLastOpened
  }
`;
