import { gql } from '@apollo/client';

export const TableProjectFragment = gql`
  fragment TableProject on NoticeboardProject {
    ... on ProjectInterface {
      id
      name
      hasDocs
      createdAt
      unlockedAt
      stageCategoryName
      status
      awardedAt
      minTenderBudget
      maxTenderBudget
      awardedTenderBudget
      minQuotesDueForCreatorTimezone
      maxQuotesDueForCreatorTimezone
      address {
        id
        address1
        shortAddress
        latitude
        longitude
        suburb
        postcode
        state {
          shortName
        }
      }
      countryId
      distanceFromDefaultOffice
      activeTenderStageCount
      grossFloorArea
    }
    ... on WatchableProject {
      watchlistEntry {
        id
        status
        updatedAt
      }
    }
    ... on ViewableNoticeboardProject {
      stages {
        id
        name
        tenderQuotesDue
        tenderQuotesDueForCreatorTimezone
        budgetAmount
        awardedAt
        hasMultipleQuoteDueDates
        builderDetails {
          ... on BuilderDetails {
            id
            abbrev
          }
          ... on HiddenBuilderDetails {
            isIncognito
          }
        }
      }
    }
    ... on PaywalledNoticeboardProject {
      redactedReason
      requiredProductForRoadblock {
        shortStates
        region {
          id
        }
      }
      requireAdditionalLicense
      redactedStages: stages {
        id
      }
      redactedReason
    }
  }
`;

export const HighlightFragment = gql`
  fragment Highlight on Highlight {
    projectId
    field
    subField
    fullPath
    rawText
    term
    ref
  }
`;
