import $ from 'jquery';
import { captureException } from '@sentry/browser';
import { escape } from 'lodash';
import moment from 'moment-timezone';
import KeywordFetcher from '@ascension/js/app/NetworkBuilderTable/keyword_fetcher';
import getSpecifinderIconMarkup from '@ascension/js/app/NetworkBuilderTable/specifinder_icon';
import Budget from '../../classes/Budget';
import Location from '../../classes/Location';
import ServerDataList from '../../classes/ServerDataList';
import { DataTableRenderType, pickDataAndCellMetadata } from '../../utils/datatable';
import { setInterestLevelSelectorStatus } from '../watchlist';
import { WatchlistStatus } from '../../../enums';

type DirectoryResponseDatum = {
  /* eslint-disable camelcase */
  stage_id: number;
  stage_budget: number;
  stage_builder_code: 0 | 1;
  stage_retender: boolean;
  stage_category: number;
  stage_awarded: string;
  stage_tender_quotes_due: string;
  trades: number[];
  project_id: number;
  project_name: string | null;
  address_suburb: string;
  address_province: string;
  address_latitude: number;
  address_longitude: number;
  distance: number;
  state_short_name: string;
  has_docs: boolean;
  has_notes: boolean;
  /* eslint-enable */
  filterAwarded: boolean;
  filterBudget: boolean;
  filterPricingExp?: boolean;
  filterFifty: boolean;
};

type DirectoryResponseWithKeywordsDatum = DirectoryResponseDatum & { keywordMatches: string[] };

type DataTableResponse<T> = {
  success: true;
  recordsTotal: number;
  recordsFiltered: number;
  data: T[];
};

type DirectoryDataTableResponse = DataTableResponse<DirectoryResponseDatum>;
type DirectoryDataTableResponseWithKeywords = DataTableResponse<DirectoryResponseWithKeywordsDatum>;

const budgetUpgradeClass = 'restricted-budget-trigger';
const awardedUpgradeClass = 'upgrade-awarded-trigger';
const fiftyUpgradeClass = 'restricted-fifty-trigger';
const pricingExperimentUpgradeClass = 'restricted-pricing-experiment-trigger';

const hasMatchingKeywordIconMarkup = getSpecifinderIconMarkup();

const getLockMarkup =
  ($placeholderRow: JQuery) =>
  (
    {
      filterAwarded,
      filterBudget,
      filterFifty,
      project_id: projectId,
      filterPricingExp,
    }: DirectoryResponseDatum,
    col: number,
    trigger: string,
  ) => {
    const cellTemplate = $placeholderRow.find('td').get(col);
    if (!cellTemplate) {
      throw Error(`Could not find template for column ${col}`);
    }
    const template = $(cellTemplate).clone();
    const $lockedLabel = $(template).find('.locked-project-label');

    $lockedLabel.attr('data-project-id', projectId).attr('data-paywall-trigger', trigger);

    const getLabelMarkup = (cls: string) => $lockedLabel.addClass(cls).prop('outerHTML');

    if (filterAwarded) {
      return getLabelMarkup(awardedUpgradeClass);
    }

    if (filterBudget) {
      return getLabelMarkup(budgetUpgradeClass);
    }

    if (filterFifty) {
      return getLabelMarkup(fiftyUpgradeClass);
    }

    if (filterPricingExp) {
      return getLabelMarkup(pricingExperimentUpgradeClass);
    }

    return null;
  };

const render = <T = string>(
  fn: (d: DirectoryResponseWithKeywordsDatum, col: number, context: DataTableRenderType) => T,
) =>
  pickDataAndCellMetadata<DirectoryResponseWithKeywordsDatum, T>((d, { col }, context) =>
    fn(d, col, context),
  );

const hasPaywall = ({
  filterAwarded,
  filterBudget,
  filterFifty,
  filterPricingExp,
}: DirectoryResponseWithKeywordsDatum) =>
  filterAwarded || filterBudget || filterFifty || filterPricingExp;

const renderFlags = ($placeholderRow: JQuery) =>
  render((d, col) => {
    if (hasPaywall(d)) {
      return '-';
    }

    const {
      project_id: projectId,
      has_docs: hasDocs,
      has_notes: hasNotes,
      stage_builder_code: stageBuilderCode,
      keywordMatches,
    } = d;

    const cellTemplate = $placeholderRow.find('td').get(col);
    if (!cellTemplate) {
      throw Error(`Could not find template for column ${col}`);
    }
    const $contents = $('<div class="tenders-table-flag-wrapper">');

    if (hasDocs) {
      const $docIconContainer = $('<div>');
      const $docIcon = $(cellTemplate).find('.docs-icon').first();
      $docIconContainer.append($docIcon.clone().attr('data-project-id', projectId));
      $contents.append($docIconContainer);
    }

    if (stageBuilderCode) {
      const $builderCodeIconContainer = $('<div>');
      $builderCodeIconContainer
        .addClass('builder-code-icon-container project-modal-trigger')
        .attr('data-project-id', projectId);

      const $builderCodeTemplate = $(cellTemplate).find('.builderCode').first();
      $builderCodeIconContainer.append($builderCodeTemplate.clone());
      $contents.append($builderCodeIconContainer);
    }

    if (hasNotes) {
      const $noteIconContainer = $('<div>');
      $noteIconContainer
        .addClass('note-icon-container project-modal-trigger')
        .attr('data-target', 'notes')
        .attr('data-project-id', projectId)
        .attr('title', 'Notes available');

      const $noteIcon = $('<i />').addClass('icon icon-notes tender_note');

      $contents.append($noteIconContainer.append($noteIcon));
    }

    if (keywordMatches.length) {
      const $keywordIconContainer = $('<div>');
      $keywordIconContainer
        .addClass('keyword-matches-icon-container project-modal-trigger')
        .attr('data-project-id', projectId)
        .attr('title', keywordMatches.join(', '));

      $contents.append($keywordIconContainer.append($(hasMatchingKeywordIconMarkup)));
    }

    return $contents.prop('outerHTML');
  });

const renderProjectId = ($placeholderRow: JQuery) =>
  render((d, col) => {
    const { project_id: projectId, filterAwarded, filterBudget, filterFifty, filterPricingExp } = d;

    const cellTemplate = $placeholderRow.find('td').get(col);
    if (!cellTemplate) {
      throw Error(`Could not find template for column ${col}`);
    }

    const template = $(cellTemplate).clone();
    const $rowCtn = $('<div />');
    const $lockedLabel = $(template).find('.locked-project-label');
    const $helperLabel = $(template).find('.locked-help-link');

    const applyRoadblockFilter = (filterClass: string) => {
      $lockedLabel.addClass(filterClass);
      $helperLabel.addClass(filterClass);
      [$lockedLabel, $helperLabel].forEach(($item) => {
        $item.attr('data-paywall-trigger', 'id').attr('data-project-id', projectId);
      });
      $rowCtn.append($lockedLabel).append($helperLabel);
    };

    if (filterAwarded) {
      applyRoadblockFilter(awardedUpgradeClass);
    } else if (filterBudget) {
      applyRoadblockFilter(budgetUpgradeClass);
    } else if (filterFifty) {
      applyRoadblockFilter(fiftyUpgradeClass);
    } else if (filterPricingExp) {
      applyRoadblockFilter(pricingExperimentUpgradeClass);
    } else {
      $rowCtn.append(projectId.toString());
    }

    return $rowCtn.html();
  });

const renderProjectName = ($placeholderRow: JQuery) =>
  render((d, col) => {
    const {
      project_id: projectId,
      project_name: projectName,
      trades: projectTradeIds,
      stage_retender: stageRetender,
      filterAwarded,
      filterBudget,
      filterFifty,
      filterPricingExp,
    } = d;

    const cellTemplate = $placeholderRow.find('td').get(col);
    if (!cellTemplate) {
      throw Error(`Could not find template for column ${col}`);
    }

    const template = $(cellTemplate).clone();
    const $rowCtn = $('<div />');
    const $lockedLabel = $(template).find('.locked-project-label');
    const $helperLabel = $(template).find('.locked-help-link');

    const applyRoadblockFilter = (filterClass: string) => {
      [$lockedLabel, $helperLabel].forEach(($item) => {
        $item.addClass(filterClass).attr('data-project-id', projectId);
      });
      $rowCtn.append($lockedLabel, $helperLabel);
    };

    if (filterAwarded) {
      applyRoadblockFilter(awardedUpgradeClass);
    } else if (filterBudget) {
      applyRoadblockFilter(budgetUpgradeClass);
    } else if (filterFifty) {
      applyRoadblockFilter(fiftyUpgradeClass);
    } else if (filterPricingExp) {
      applyRoadblockFilter(pricingExperimentUpgradeClass);
    } else {
      const $link = $(template).find('a').first();

      if (projectName === null) {
        throw Error('Project name missing?');
      }

      $link.attr('data-project-id', projectId).text(projectName);

      const userTrades = window?.global?.account?.tradeIds || [];

      const projectRequiresUserTrade = (projectTradeIds || []).reduce(
        (acc, trade) => acc || userTrades.includes(trade),
        false,
      );

      // Bold if project has a matching trade
      if (projectRequiresUserTrade) {
        $link.addClass('trade-match');
        $link.attr('title', $link.attr('data-trade-match-title') as string);
      }

      $rowCtn.append($link);

      if (stageRetender) {
        const $retenderLabel = $(template).find('.stage-label.retender-label');
        $rowCtn.append($retenderLabel);
      }
    }

    return $rowCtn.html();
  });

const renderStageCategory = render(({ stage_category: stageCategoryId }) =>
  escape(window.global.stageCategories[stageCategoryId] || ''),
);

const renderSuburbAndState = ($placeholderRow: JQuery) =>
  render((d, col) => {
    const lock = getLockMarkup($placeholderRow)(d, col, 'address');
    if (lock !== null) return lock;

    const { address_suburb: suburb, state_short_name: state } = d;

    return escape([suburb].concat(state ? [state] : []).join(', '));
  });

const renderDistanceFromUser = ($placeholderRow: JQuery) =>
  render<string | number>((d, col, context) => {
    const lock = getLockMarkup($placeholderRow)(d, col, 'address');
    if (lock !== null) return lock;

    const { distance } = d;

    switch (context) {
      case 'filter':
      case 'sort':
        return distance;
      default: {
        const $spanCtn = $('<div>');
        const $span = $('<span>').addClass('locator-dist-calc');

        $span.text(Location.readableDistance(distance / 1000));
        $spanCtn.append($span);

        return $spanCtn.html();
      }
    }
  });

const renderBudget = render<string | number | null>(({ stage_budget: budget }, _, context) => {
  switch (context) {
    case 'filter':
    case 'sort':
      return budget;
    default:
      return new Budget().getBudgetName(budget);
  }
});

const renderQuotesDue = render(({ stage_tender_quotes_due: quotesDue }) =>
  quotesDue ? moment(quotesDue).fromNow() : '-',
);

const renderInterestSelector = ($placeholderRow: JQuery) =>
  render<string | WatchlistStatus>((d, col, context) => {
    const lock = getLockMarkup($placeholderRow)(d, col, 'watchlist');
    if (lock !== null) return lock;

    const { project_id: projectId } = d;

    const watchedProjects = window.global.account!.watchlistIds;

    const status =
      watchedProjects && !Number.isNaN(watchedProjects[projectId])
        ? watchedProjects[projectId]
        : -1;

    if (context === 'display') {
      const cellTemplate = $placeholderRow.find('td').get(col);

      if (!cellTemplate) {
        throw Error(`Could not find template for column ${col}`);
      }

      const $template = $(cellTemplate).clone();
      const $menu = $template.children().first();

      setInterestLevelSelectorStatus($menu, status);

      return $menu.attr('data-project-id', projectId).prop('outerHTML');
    }

    return status || -1;
  });

class NetworkBuilderTable {
  private readonly route: string;
  private table: ServerDataList<DirectoryDataTableResponse>;

  constructor(
    private $target: JQuery<HTMLTableElement>,
    private readonly keywordFetcher: KeywordFetcher,
  ) {
    this.route = $target.attr('data-url') as string;

    this.table = new ServerDataList(
      this.$target,
      this.route,
      (list: ServerDataList<DirectoryDataTableResponse>) => {
        const $placeholderRow = list.$target.find('tr.data-table-placeholder').first();

        // eslint-disable-next-line fp/no-mutation, no-param-reassign
        list.table = list.$target
          .DataTable({
            paging: false,
            processing: true,
            serverSide: true,
            searchCols: [{}, {}, {}, {}, {}, {}, {}, {}],
            ajax: (
              data: DirectoryDataTableResponse,
              cb: (d: DirectoryDataTableResponseWithKeywords) => void,
              settings,
            ) => {
              list.serverRequest(
                data,
                (directoryResponse) => {
                  const projectIds = directoryResponse.data.map(
                    ({ project_id: projectId }) => projectId,
                  );
                  this.keywordFetcher
                    .getKeywordMatches(projectIds)
                    .then((keywordMatches) => {
                      const responseWithKeywordMatches = directoryResponse.data.map(
                        (responseDatum) => ({
                          ...responseDatum,
                          keywordMatches: keywordMatches[responseDatum.project_id] ?? [],
                        }),
                      );

                      cb({
                        ...directoryResponse,
                        data: responseWithKeywordMatches,
                      });
                    })
                    .catch(captureException);

                  return undefined;
                },
                settings,
              );
            },
            info: false,
            order: [[6, 'desc']],
            columns: [
              {
                data: null,
                orderable: false,
                width: '80',
                render: renderFlags($placeholderRow),
              },
              {
                name: 'project_id',
                data: 'id',
                orderable: true,
                width: '35',
                render: renderProjectId($placeholderRow),
              },
              {
                data: 'project_name',
                name: 'project.name',
                render: renderProjectName($placeholderRow),
              },
              {
                data: 'stage_category',
                name: 'stage.category',
                render: renderStageCategory,
              },
              {
                data: 'address_suburb',
                name: 'address.suburb',
                render: renderSuburbAndState($placeholderRow),
              },
              {
                data: 'distance',
                name: 'distance',
                type: 'num',
                render: renderDistanceFromUser($placeholderRow),
              },
              {
                data: 'stage_budget',
                name: 'stage.budgetAmount',
                type: 'num',
                render: renderBudget,
              },
              {
                data: 'stage_tender_quotes_due',
                name: 'stage.tenderQuotesDue',
                type: 'date',
                render: renderQuotesDue,
              },
              {
                data: null,
                orderable: true,
                render: renderInterestSelector($placeholderRow),
              },
            ],
          })
          .on('init.dt', () => {
            list.$container.addClass('has-loaded');
          });
      },
    );
  }
}

$(() => {
  const networkTables = $('table.fetch-network-stage-table').toArray();

  if (!networkTables.length) {
    return;
  }

  const keywordFetcher = new KeywordFetcher();
  networkTables.forEach(
    (table: HTMLTableElement) => new NetworkBuilderTable($(table), keywordFetcher),
  );
});
