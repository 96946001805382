import {
  useHasFeature,
  useInExperiment,
  useIsChargebeeAccount,
} from '@subbie/context/AccountProvider/hooks';
import { ExperimentName, FeatureName } from '@ascension/_gqltypes/subbie.generated';

export const useCanUpgradeLiteToEssential = () => {
  const isChargebeeAccount = useIsChargebeeAccount();
  const isInLiteToEssentialExperiment = useInExperiment(
    ExperimentName.ADOPT_LITE_TO_ESSENTIAL_UPGRADE,
  );
  const isInLiteTier = useHasFeature(FeatureName.CREDITS_LITE_TIER);
  return isInLiteToEssentialExperiment && isInLiteTier && isChargebeeAccount;
};
