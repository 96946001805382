import { useMutation } from '@apollo/client';
import { Button, ButtonVariant } from '@estimateone/frontend-components';
import Banner, { BannerVariant } from '../../../../shared/Banner';
import { DISMISS_TERMS_BANNER } from './queries';
import { DismissTermsBanner } from './types/DismissTermsBanner';
import styles from '../../../../shared/Banner/styles.scss';

type TermsBannerProps = {
  title: string;
  details: string;
  termsUrl: string;
  privacyUrl: string;
};

export const TermsBanner = ({
  title,
  details,
  termsUrl: termsOfUseURL,
  privacyUrl: privacyPoliciesURL,
}: TermsBannerProps) => {
  const [dismissBanner] = useMutation<DismissTermsBanner>(DISMISS_TERMS_BANNER);

  return (
    <Banner variant={BannerVariant.Success} onDismiss={dismissBanner}>
      <div>
        <p className={styles.title}>{title}</p>
        <p className={styles.details}>{details}</p>
      </div>
      <div className={styles.buttonContainer}>
        <Button
          variant={ButtonVariant.Secondary}
          target="_blank"
          rel="noopener noreferrer"
          link={termsOfUseURL}
          fullWidth={false}
        >
          Review Terms of Use
        </Button>
        <Button
          variant={ButtonVariant.Secondary}
          target="_blank"
          rel="noopener noreferrer"
          link={privacyPoliciesURL}
          fullWidth={false}
        >
          Review Privacy Policy
        </Button>
      </div>
    </Banner>
  );
};
