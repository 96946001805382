import { useEffect } from 'react';
import { useAccountType } from '@subbie/context/AccountProvider/hooks';
import useLocalStorage from '@ascension/components/hooks/LocalStorage';
import { AccountType } from '@ascension/_gqltypes/subbie.generated';

const LOCALSTORAGE_PROJECT_SLIDER_ROAD_BLOCK_KEY = 'subbie-consultant-road-block-prefs';

export enum DisplayFeatures {
  SUBCONTRACTORS = 'subcontractors',
  SUPPLIERS = 'suppliers',
  CONSULTANTS = 'consultants',
}

export const useSupplierPaywallDecider = (): DisplayFeatures | undefined => {
  const isSupplier = useAccountType() === AccountType.SUPPLIER;
  const {
    set: setPreviousRoadBlock,
    value: previousRoadBlockPref,
    loaded,
  } = useLocalStorage<DisplayFeatures>(LOCALSTORAGE_PROJECT_SLIDER_ROAD_BLOCK_KEY);

  useEffect(() => {
    if (!loaded) {
      return;
    }

    setPreviousRoadBlock(
      previousRoadBlockPref === DisplayFeatures.CONSULTANTS
        ? DisplayFeatures.SUBCONTRACTORS
        : DisplayFeatures.CONSULTANTS,
    );
  }, [loaded]);

  if (!isSupplier) {
    return DisplayFeatures.CONSULTANTS;
  }

  return previousRoadBlockPref;
};
