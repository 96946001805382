import { useMutation } from '@apollo/client';
import { SET_STAGE_ACCESS_RESTRICTED } from '../mutations';
import {
  SetStageAccessRestricted,
  SetStageAccessRestrictedVariables,
} from '../types/SetStageAccessRestricted';
import { EntityId } from '@ascension/types';

export const useSetStageAccessRestricted = (stageId: EntityId) => {
  const [mutate] = useMutation<SetStageAccessRestricted, SetStageAccessRestrictedVariables>(
    SET_STAGE_ACCESS_RESTRICTED,
  );

  const setStageAccessRestricted = async (access: boolean) =>
    mutate({
      variables: {
        input: {
          stageId,
          access,
        },
      },
    });

  return { setStageAccessRestricted };
};
