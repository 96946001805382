import { joinClassNames } from '@estimateone/frontend-components';
import { differenceInDays, format, isValid } from 'date-fns';
import { Indicator, IndicatorStatus } from '../Indicator';
import { QuoteCoverageStatistics } from '../utils/calculateQuoteCoverageStatus';
import styles from './styles.scss';

export type QuoteCoverageBreakdown = {
  packageDueDate: Date;
  latestQuoteDueBy: Date;
  quoteCoverage: QuoteCoverageStatistics;
};

type CoverageBreakdownProps = QuoteCoverageBreakdown;

export const CoverageBreakdown = ({
  quoteCoverage,
  latestQuoteDueBy,
  packageDueDate,
}: CoverageBreakdownProps) => {
  const getDueDateMessage = (): string => {
    const now = new Date();
    const today = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()));

    if (today.valueOf() === packageDueDate.valueOf()) return 'Due today';
    if (today > packageDueDate) return 'Due date passed';

    const diff = differenceInDays(packageDueDate, today);
    return `${diff} day${diff > 1 ? 's' : ''} left`;
  };

  const formattedDate = isValid(latestQuoteDueBy) ? format(latestQuoteDueBy, 'd MMM yyyy') : '-';

  return (
    <div className={styles.tooltip}>
      <div className={styles.period}>{getDueDateMessage()}</div>
      <div className={styles.dueDate}>Quotes due: {formattedDate}</div>
      <div className={joinClassNames(styles.indicator, styles.indicatorTextHighlight)}>
        <div>
          <Indicator status={IndicatorStatus.Quoted} />
          <span>Quoted</span>
        </div>
        <div className={styles.indicatorCount}>{quoteCoverage.quoted}</div>
      </div>
      <div className={joinClassNames(styles.indicator, styles.indicatorText)}>
        <div>
          <Indicator status={IndicatorStatus.Quoting} />
          <span>Quoting</span>
        </div>
        <div className={styles.indicatorCount}>{quoteCoverage.quoting}</div>
      </div>
      <div className={joinClassNames(styles.indicator, styles.indicatorText)}>
        <div>
          <Indicator status={IndicatorStatus.NotQuoting} />
          <span>Not quoting</span>
        </div>
        <div className={styles.indicatorCount}>{quoteCoverage.notQuoting}</div>
      </div>
      <div className={joinClassNames(styles.indicator, styles.indicatorText)}>
        <div>
          <Indicator status={IndicatorStatus.NoResponse} />
          <span>No response</span>
        </div>
        <div className={styles.indicatorCount}>{quoteCoverage.noResponse}</div>
      </div>
      <div className={joinClassNames(styles.subTotal, styles.indicatorText)}>
        <span>Companies Invited</span>
        <div className={styles.indicatorCount}>{quoteCoverage.numberOfQuotes}</div>
      </div>
    </div>
  );
};
