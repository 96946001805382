import { ProjectInterestedSuppliers_projectInterestedSuppliers_contactAndNote as InteresetedSupplierContactAndNote } from '@subbie/modal/ProjectSlider/ProjectSliderBody/ProjectSuppliers/types/ProjectInterestedSuppliers';
import styles from './styles.scss';

type SupplierConnectionDetailsProps = {
  readonly contactAndNote: InteresetedSupplierContactAndNote;
};

export const SupplierConnectionDetails = ({ contactAndNote }: SupplierConnectionDetailsProps) => (
  <div className={styles.supplierConnectionDetailsContainer}>
    <div className={styles.label}>Contact</div>
    <div className={styles.supplierConnectionContact}>
      <span className={styles.name}>{contactAndNote.name}</span>
      {contactAndNote.phoneNumber && (
        <span className={styles.phoneNumber}>{contactAndNote.phoneNumber}</span>
      )}
      <span className={styles.email}>{contactAndNote.email}</span>
    </div>
    {contactAndNote.note && (
      <>
        <div className={styles.label}>Note</div>
        <div className={styles.supplierConnectionContactNote}>{contactAndNote.note}</div>
      </>
    )}
  </div>
);
