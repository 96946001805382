import { useLazyQuery } from '@apollo/client';
import { INVITE_TO_QUOTE_PRESELECTED_COMPANY } from '../queries';
import {
  GetAddressBookCompany,
  GetAddressBookCompanyVariables,
} from '../types/GetAddressBookCompany';

export const useGetAddressBookCompany = () => {
  const [getAddressBookCompany, { data, loading }] = useLazyQuery<
    GetAddressBookCompany,
    GetAddressBookCompanyVariables
  >(INVITE_TO_QUOTE_PRESELECTED_COMPANY);

  return {
    company: data?.company,
    loading,
    getAddressBookCompany,
  };
};
