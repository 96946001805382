import { Component } from 'react';
import { GroupBase, StylesConfig } from 'react-select';
import { Col, Row, SelectSize } from '@estimateone/frontend-components';
import { E1Address, E1AddressFields } from '../../../../js/classes/Address';
import Input from '../../Input';
import { LabelledOption, SingleSelect, SingleSelectionHandler } from '../../Select';
import requestCountryData, { AddressFormCountry } from './utils';

const australia = 'Australia';

const selectStyles: StylesConfig<
  LabelledOption<string>,
  false,
  GroupBase<LabelledOption<string>>
> = {
  control: (base) => ({ ...base, padding: 0 }),
};

type Props = {
  address: E1Address;
  handleAddressChange: (address: E1Address) => void;
  show?: boolean;
};

class AddressDetails extends Component<Props, { countryData: AddressFormCountry[] }> {
  private defaultCountryName = '';

  static defaultProps: Partial<Props> = {
    show: true,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      countryData: [],
    };
  }

  async componentDidMount(): Promise<void> {
    const {
      data: { allCountries, defaultCountryId },
    } = await requestCountryData();
    this.setState({ countryData: allCountries });

    const defaultCountry = allCountries.find((country) => country.id === defaultCountryId);
    this.defaultCountryName = defaultCountry ? defaultCountry.name : australia;

    this.handleChange({ name: 'country', value: this.defaultCountryName });
  }

  getStatesForCountry = (countryName: string) => {
    const { countryData } = this.state;
    const currentCountry = countryData.find((country) => country.name === countryName);
    return currentCountry ? currentCountry.states : [];
  };

  handleOptionChange: SingleSelectionHandler<string> = ({ name, selectedOption }) => {
    const { address, handleAddressChange } = this.props;
    const isCountryChange = name === 'country';
    const updatedAddress = address.clone();

    if (isCountryChange) {
      updatedAddress.setProperty('state', '');
    }

    updatedAddress.setProperty(name as keyof E1AddressFields, selectedOption?.value ?? null);
    handleAddressChange(updatedAddress);
  };

  handleChange = ({ name, value }: { name: string; value: string }) => {
    const { address, handleAddressChange } = this.props;
    const updatedAddress = address.clone();
    updatedAddress.setProperty(name as keyof E1AddressFields, value);
    handleAddressChange(updatedAddress);
  };

  renderStateSelector = (selectedCountry: string) => {
    const { address } = this.props;
    const states = this.getStatesForCountry(selectedCountry);

    if (!states.length) return null;

    const { state } = address.toObject();

    return (
      <SingleSelect<string>
        styles={selectStyles}
        id="state"
        label="State"
        name="state"
        onChange={this.handleOptionChange}
        value={state}
        options={states.map(({ name }) => ({ label: name, value: name }))}
        menuPlacement="top"
        size={SelectSize.Small}
      />
    );
  };

  render() {
    const { show, address } = this.props;
    const { countryData } = this.state;
    if (!show) {
      return null;
    }

    const countryOptions = countryData.map((country) => ({
      label: country.name,
      value: country.name,
    }));

    const {
      address: address1,
      address2,
      suburb,
      postcode,
      province,
      country,
      city,
    } = address.toObject();

    const selectedCountry = country || this.defaultCountryName;

    return (
      <section className="react-address-form">
        <Row>
          <Col span={12}>
            <Input
              id="address"
              name="address"
              placeholder="Address Line 1"
              type="text"
              value={address1 || ''}
              onChange={this.handleChange}
              label="Street Address"
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Input
              id="address2"
              name="address2"
              placeholder="Address Line 2"
              type="text"
              value={address2 || ''}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        {selectedCountry !== australia ? (
          <>
            <Row>
              <Col span={12}>
                <Input
                  id="city"
                  name="city"
                  label="City"
                  placeholder="City"
                  type="text"
                  value={city || ''}
                  onChange={this.handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Input
                  id="province"
                  name="province"
                  label="County"
                  placeholder="County"
                  type="text"
                  value={province || ''}
                  onChange={this.handleChange}
                />
              </Col>
            </Row>
          </>
        ) : (
          <Row>
            <Col span={{ md: 6, lg: 6 }}>
              <Input
                id="suburb"
                name="suburb"
                placeholder="Suburb"
                type="text"
                value={suburb || ''}
                onChange={this.handleChange}
                label="Suburb"
              />
            </Col>
            <Col span={{ md: 6, lg: 6 }}>{this.renderStateSelector(selectedCountry)}</Col>
          </Row>
        )}
        <Row>
          <Col span={{ md: 6, lg: 6 }}>
            <Input
              id="postcode"
              name="postcode"
              placeholder="Postcode"
              type="text"
              value={postcode || ''}
              onChange={this.handleChange}
              label="Postcode"
            />
          </Col>
          <Col span={{ md: 6, lg: 6 }}>
            <SingleSelect<string>
              styles={selectStyles}
              id="country"
              label="Country"
              name="country"
              onChange={this.handleOptionChange}
              value={selectedCountry}
              options={countryOptions}
              menuPlacement="top"
              size={SelectSize.Small}
            />
          </Col>
        </Row>
      </section>
    );
  }
}

export default AddressDetails;
