import { useState } from 'react';
import {
  ActionMenu,
  Avatar,
  Ellipsis,
  IconButton,
  Tag,
  TagVariant,
  TextLink,
  Tooltip,
} from '@estimateone/frontend-components';
import styles from './ContactCard.module.scss';
import { fireAndForget } from '@shared/Util/async';
import { useChangePrimaryContact, useHideProfileContact } from './hooks';
import { AvatarSize } from '@estimateone/frontend-components/src/enums/Avatar';
import { CompanyContact } from '@profiles/providers/ProfileProvider/types';

export type ContactCardProps = {
  companyContactDetails: CompanyContact;
  isPrimary?: boolean;
};

export const ContactCard = ({ companyContactDetails, isPrimary = false }: ContactCardProps) => {
  const { firstName, lastName, fullName, contactable, ascensionId: id } = companyContactDetails;
  const { changePrimaryContact } = useChangePrimaryContact(Number(id));
  const { hideProfileContact } = useHideProfileContact(Number(id), fullName);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const phone = contactable?.phone;
  const email = contactable?.email;

  return (
    <div className={styles.contactContainer} data-testid="contact-card" id="contact-card">
      <div className={styles.contactWrapper}>
        <div className={styles.avatarWrapper}>
          <Avatar
            firstName={firstName}
            lastName={lastName ?? ''}
            userId={Number(id)}
            size={AvatarSize.Network}
          />
        </div>
        <div className={styles.nameColumn}>
          <p className={styles.name}>{fullName}</p>
          {isPrimary && (
            <Tooltip
              text="The Primary Contact is your go-to contact for all new correspondence."
              tooltipId="primary-contact-tooltip"
              place="bottom"
              className={styles.tooltip}
            >
              <Tag variant={TagVariant.Practical025} text="Primary Contact" />
            </Tooltip>
          )}
        </div>
        <div className={styles.contactDetailsColumn}>
          {phone ?? <p className={styles.phone}>{phone}</p>}
          <TextLink className={styles.email} href={`mailto:${email}`}>
            {email}
          </TextLink>
        </div>
        <ActionMenu
          placement="bottom-end"
          visible={isMenuOpen}
          onClickOutside={() => setIsMenuOpen(false)}
          triggerElement={
            <IconButton
              className={styles.meatballMenu}
              icon={<Ellipsis />}
              title="contact action menu"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            />
          }
        >
          {isPrimary ? (
            <ActionMenu.Item disabled>Hide from Profile</ActionMenu.Item>
          ) : (
            <>
              <ActionMenu.Item
                onClick={() => {
                  setIsMenuOpen(false);
                  fireAndForget(() => changePrimaryContact());
                }}
              >
                Set Primary Contact
              </ActionMenu.Item>
              <ActionMenu.Item
                onClick={() => {
                  setIsMenuOpen(false);
                  fireAndForget(() => hideProfileContact());
                }}
              >
                Hide from Profile
              </ActionMenu.Item>
            </>
          )}
        </ActionMenu>
      </div>
    </div>
  );
};
