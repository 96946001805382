import $ from 'jquery';
import Routing from 'routing';
import moment from 'moment-timezone';
import _ from 'underscore';
import E1Request from '../classes/E1Request';
import Form from '../classes/Form';
import { UploadStatus } from '../classes/file_manager/ServerlessUploader';
import { readableFileSize } from '../utils/filesize';
import { E1PDFViewerLauncher, addOrRemoveMobileViewportAttributes } from '../utils/pdf_doc_viewer';
import { truncateMiddle } from '../utils/truncate';
import { clientDeviceIsMobileOrTablet, openDocumentInNewTab } from './doc_viewer';
import DocumentList from './documents-list';
import DocumentUploadModule from './documents-upload';
import { getUserTimezone } from './timezone';
import { renderUppy } from '../../../entrypoint/builder-react';

/**
 * @param {jQuery} $target
 * @param {boolean} constructionStage
 * @returns {DocumentList}
 */
function initDocTable($target, constructionStage = false) {
  const readOnly = $target.attr('data-readonly') === '1';
  const stageId = $target.attr('data-stage');
  const addendumId = parseInt($target.attr('data-addendum'), 10) || null;
  const approvedParams = { id: stageId };
  let $transmittalToggle;
  let isTransmitToggleOn;

  if (addendumId !== null) {
    approvedParams.addendumId = addendumId;
  }

  if (constructionStage) {
    $transmittalToggle = $target.closest('.doc-container').find('input.transmittal_docs_toggle');
    isTransmitToggleOn = () => $transmittalToggle.length !== 0 && $transmittalToggle.is(':checked');
    approvedParams.transmittal = isTransmitToggleOn() ? 1 : 0;
  }

  const docTable = new DocumentList(
    stageId,
    addendumId,
    $target,
    (list) => {
      list.table = list.$target.DataTable({
        data: list.data,
        info: false,
        paging: false,
        lengthChange: false,
        order: [[2, 'asc']],
        columns: [
          {
            data: 'selected',
            orderable: false,
            defaultContent: '',
            class: 'checkboxCol',
            render(selected, row, object, info) {
              if (!readOnly) {
                if (typeof selected === 'undefined') {
                  object.selected = true;
                }
                const $checkbox = $("<input type='checkbox'>")
                  .addClass('select-checkbox')
                  .attr('data-id', object.id);
                if (object.selected) {
                  $checkbox.attr('checked', 'checked');
                }
                list.data[info.row] = object;
                const $ctn = $('<div>').append($checkbox);
                return $ctn.html();
              }
            },
          },
          {
            title: '',
            visible: true,
            orderable: false,
            defaultContent: '',
            data: 'addendum',
            render(data) {
              if (data && addendumId !== null && data.id === addendumId) {
                const $flag = $('<span />').addClass('tag label-success').text('NEW');
                return $flag.prop('outerHTML');
              }
              return '';
            },
          },
          {
            title: 'Filename',
            data: 'fileName',
            class: 'doc-filename',
            type: 'natural',
            render(filename, type, object) {
              if (type === 'sort') {
                return filename;
              }
              const $name = $('<a />');
              $name
                .attr('role', 'button')
                .attr('data-document-id', object.id)
                .attr('data-stage-id', stageId)
                .attr('data-mode-construction', constructionStage)
                .attr('title', filename)
                .addClass(
                  constructionStage
                    ? 'construction-document-viewer-trigger'
                    : 'document-viewer-trigger',
                )
                .addClass('link');
              $name.text(truncateMiddle(filename, 44));

              const isNew = object.syncStatus === 'New';
              const isRevised = object.syncStatus === 'Revised';

              const $wrapper = $('<div></div>');
              $wrapper.append($name);

              if (isNew) {
                const newTag = $('<span class="tag newOrRevisedFile icon-right">New</span>');
                $wrapper.append(newTag);
              } else if (isRevised) {
                const revisedTag = $(
                  '<span class="tag newOrRevisedFile icon-right">Revised</span>',
                );
                $wrapper.append(revisedTag);
              }

              return $wrapper.prop('outerHTML');
            },
          },
          {
            data: 'revise',
            class: 'supersedes',
            visible: list.addendumId !== null,
            orderable: true,
            render(data, filter, col) {
              if (col.addendum === null || addendumId === null || col.addendum.id !== addendumId) {
                return '-';
              }
              const $cell = $('<span />');
              if (!readOnly) {
                if (col.pendingRevision && col.pendingRevision.supersededDocument) {
                  const $supersededFile = $('<span/>')
                    .text(truncateMiddle(col.pendingRevision.supersededDocument.title, 35))
                    .addClass('small');
                  const $undoLink = $('<a />')
                    .addClass('btn btn-xs btn-secondary removeSupersedeLink ml-2')
                    .attr('data-id', col.id)
                    .text('Undo');
                  $cell.append($supersededFile, $undoLink);
                } else {
                  const $link = $('<a />')
                    .addClass('btn btn-block btn-xs btn-secondary supersedeLink')
                    .attr('data-id', col.id);
                  $link.text('Select');
                  $cell.append($link);
                }
              }
              return $cell.prop('outerHTML');
            },
          },
          {
            data: 'drawingId',
            type: 'docref',
            render(data) {
              return _.escape(data || '-');
            },
          },
          {
            title: 'Title',
            data: 'title',
            type: 'natural',
            render(data, type) {
              if (type === 'sort') {
                return data;
              }
              return data === null ? '-' : _.escape(truncateMiddle(data, 44));
            },
          },
          {
            title: 'Revision',
            data: 'revision',
            render(data) {
              return _.escape(data || '-');
            },
          },
          {
            title: constructionStage ? 'Revision Date' : 'Addendum',
            width: constructionStage ? '140' : '90',
            visible: !constructionStage || list.addendumId !== null,
            data: null,
            class: 'addendum',
            render(title, col, object) {
              if (typeof object.addendum === 'object' && object.addendum != null) {
                return _.escape(
                  constructionStage
                    ? moment
                        .tz(object.addendum.createdAt.date, object.addendum.createdAt.timezone)
                        .clone()
                        .tz(getUserTimezone())
                        .format('D-MM-YYYY hh:mma')
                    : object.addendum.name,
                );
              }
              return '-';
            },
          },
          {
            title: 'Directory',
            data(data) {
              return data.currentDirectoryName
                ? _.escape(data.currentDirectoryName.replace(/[^\x20-\x7E]/g, ''))
                : '';
            },
          },
          {
            title: 'Size',
            data: 'fileSize',
            visible: list.addendumId === null,
            class: 'filesize',
            render(data, type) {
              return type === 'display' ? readableFileSize(data) : data;
            },
          },
        ],
        dom: 'Rrtp',
      });
      list.$target.rowGrouping({
        iGroupingColumnIndex: 8,
        bExpandableGrouping: true,
        bSetGroupingClassOnTR: true,
        fnOnGroupCompleted(group) {
          const elem = group.nGroup;
          const path = group.text;
          const $targetTd = $(elem).find('td:first').empty();
          const $groupContainer = $('<div />').addClass(
            'd-flex justify-content-between align-items-center',
          );

          const $folderContainer = $('<div />')
            .addClass('d-flex align-items-center')
            .append(path || '-');

          const $arrowIcon = $('<i />').addClass('icon-arrow');
          const $folderIcon = $('<i />').addClass('icon-folder');
          const $selectCheck = $('<input />')
            .attr('type', 'checkbox')
            .attr('data-path', path)
            .addClass('select-folder-check mt-0 mr-3');

          const $folderChildren = list.$target.find(group.groupItemClass);
          const $selected = $folderChildren.find('td.checkboxCol input.select-checkbox:checked');
          if ($folderChildren.length === $selected.length) {
            $selectCheck.prop('checked', 'checked');
            $selectCheck.prop('indeterminate', false);
          } else if ($selected.length > 0) {
            $selectCheck.prop('checked', false);
            $selectCheck.prop('indeterminate', true);
          } else {
            $selectCheck.prop('checked', false);
            $selectCheck.prop('indeterminate', false);
          }

          if (!readOnly) {
            const $uploadCtn = $('<div />').addClass('upload-folder-ctn');

            const $renameLink = $('<button />')
              .addClass('rename-folder-trigger')
              .addClass('btn btn-xs btn-default')
              .attr('data-path', path)
              .text('Rename folder');

            $uploadCtn.append($renameLink);

            if (list.canUploadToFolder()) {
              const $uploadIcon = $('<i />').addClass('icon icon-sm icon-upload icon-left');

              const $uploadLink = $('<button />')
                .addClass('upload-folder-trigger')
                .addClass('btn btn-xs btn-default ml-2')
                .attr('data-path', path)
                .text('Upload to folder')
                .prepend($uploadIcon);

              $uploadCtn.append($uploadLink);
            }

            $folderContainer.prepend($selectCheck, $arrowIcon, $folderIcon);
            $groupContainer.append($folderContainer, $uploadCtn);
          } else {
            $folderContainer.prepend($arrowIcon, $folderIcon);
            $groupContainer.append($folderContainer);
          }

          $targetTd.append($groupContainer);

          list.$target.trigger('datatable-row-init', $(elem));
        },
      });
      list.init(list);
      list.newCol = 7;
    },
    Routing.generate('app_stagedocument_approved', approvedParams),
  );
  $target.trigger('data-updated');

  if (constructionStage) {
    $transmittalToggle.on('change', () => {
      approvedParams.transmittal = isTransmitToggleOn() ? 1 : 0;
      docTable.fetchUrl = Routing.generate('app_stagedocument_approved', approvedParams);
      docTable.updateTable(true);
    });

    $target.on('click', 'a.construction-document-viewer-trigger', (e) => {
      const docId = $(e.currentTarget).attr('data-document-id');

      if (clientDeviceIsMobileOrTablet()) {
        openDocumentInNewTab(stageId, docId);
        return false;
      }

      new E1PDFViewerLauncher(stageId, {
        docId,
        transmittal: isTransmitToggleOn() ? 1 : 0,
        forceConstructionMode: true,
      }).trigger();
    });
  }

  return docTable;
}

$(() => {
  const $body = $('body');

  /** Document list * */
  const $docTable = $('table.docDataTable');

  const docTable =
    $docTable.length !== 0
      ? initDocTable($docTable, !!$docTable.hasClass('constructDocTable'))
      : null;

  let loadedDocTables = [docTable];
  loadedDocTables = _.filter(loadedDocTables, (table) => table != null);

  $body.on('click', '.addendum-matrix-trigger', (e) => {
    const addendumId = $(e.currentTarget).attr('data-addendum-id');
    // We may or may not add extra functionality for the addendum-matrix trigger,
    // so let's leave this here for when we want to do something else with the click.
    window.location = Routing.generate('app_stageaddendum_matrix', {
      id: addendumId,
    });
  });

  // Catch the supersede modal being submitted, and update the pending doc list
  $body.on('supersede-form-submitted', () => {
    $.each(loadedDocTables, (i, table) => {
      table.updateTable(true);
    });
  });

  $body.on('doc-viewer-close', () => {
    addOrRemoveMobileViewportAttributes('remove');

    $.each(loadedDocTables, (i, table) => {
      if (table != null) {
        table.updateTable(true);
      }
    });
  });

  const $ctn = $('.file-uploader');
  const $fineUploaderContainer = $ctn.find('.uploader');
  const $uppyContainer = $ctn.find('#uppy-ui-container');

  if ($('.stage-doc-container').length) {
    const unzip = !($.cookie('auto_unzip') === 'off');

    if ($fineUploaderContainer.length) {
      const uploader = new DocumentUploadModule(
        $ctn,
        $fineUploaderContainer,
        docTable,
        $fineUploaderContainer.data('stage'),
        $fineUploaderContainer.data('addendum'),
        $fineUploaderContainer.data('key'),
        unzip,
      );

      $ctn.find('.auto_unzip').prop('checked', unzip);

      $('body').on('change', '.auto_unzip', ({ currentTarget }) => {
        const isChecked = !!$(currentTarget).is(':checked');
        $.cookie('auto_unzip', isChecked ? 'on' : 'off', {
          expires: 1,
          path: '/',
          domain: 'estimateone.com',
        });
        uploader.setAutoUnzip(isChecked);
      });
    }

    if ($uppyContainer.length) {
      console.log('render uppy!');
      let fetchLock = null;

      renderUppy(
        'uppy-ui-container',
        (files) => {
          console.info('upload complete', files);
          docTable.newFiles.push(...files.map(({ id }) => parseInt(id)));

          if (fetchLock === null) {
            fetchLock = setTimeout(() => {
              docTable.sortByNew();
              docTable.updateTable(true, () => {
                clearTimeout(fetchLock);
                fetchLock = null;
              });
            }, 1000);
          }
        },
        (file) => {
          console.info('all uploads complete', file);
          $body.trigger(UploadStatus.COMPLETE);
        },
        true,
        unzip,
      );
    }

    // temp duplication
    $('body').on('change', '.auto_unzip', ({ currentTarget }) => {
      const isChecked = !!$(currentTarget).is(':checked');
      $.cookie('auto_unzip', isChecked ? 'on' : 'off', {
        expires: 1,
        path: '/',
        domain: 'estimateone.com',
      });
    });
  }

  $body.on('mouseover', '.auto-supersede-trigger[disabled]', ({ currentTarget }) => {
    const $supersedeTrigger = $(currentTarget);
    $supersedeTrigger
      .tooltipster({
        content: $fineUploaderContainer.data('supersede-disabled-tooltip'),
        multiple: false,
        position: 'bottom',
      })
      .tooltipster('show');
    $supersedeTrigger.addClass('tooltipstered');
  });

  // Update the state of the auto-supersede button
  $body.on(`${UploadStatus.COMPLETE} upload_complete`, '.uploader', () => {
    const $supersedeBtn = $body.find('.auto-supersede-trigger');
    $supersedeBtn.addClass('e1-modal-link').attr('disabled', false);
    if ($supersedeBtn.hasClass('tooltipstered')) {
      $supersedeBtn.tooltipster('disable');
    }
  });

  $body.on('submit', 'form.delete-document-form', (e) => {
    const form = new Form($(e.currentTarget));
    form.extraCallback = (response) => {
      $.each(loadedDocTables, (i, table) => {
        table.deselectAll();
        if (typeof response.document_ids !== 'undefined') {
          if (response.document_ids.length < 10) {
            $.each(response.document_ids, (i, id) => {
              table.removeDocument(id);
            });
          }
        }
        table.updateTable(true);
      });
    };
    form.submit();
    return false;
  });

  $body.on('submit', 'form.move-document-form', (e) => {
    const form = new Form($(e.currentTarget));
    form.extraCallback = (response) => {
      $.each(loadedDocTables, (i, table) => {
        table.deselectAll();
        if (
          typeof response.document_ids !== 'undefined' &&
          typeof response.folder !== 'undefined'
        ) {
          if (response.document_ids.length < 10) {
            $.each(response.document_ids, (i, id) => {
              table.moveDocument(id, response.folder);
            });
          }
        }
        table.updateTable(true);
      });
    };
    form.submit();
    return false;
  });

  $body.on('submit', 'form.bulk-edit-documents-form', (e) => {
    e.preventDefault();
    const magicForm = new Form($(e.currentTarget));

    magicForm.extraCallback = (response) => {
      $.each(loadedDocTables, (i, table) => {
        table.deselectAll();
        table.updateTable(true);
      });
    };
    magicForm.submit();
    return false;
  });

  $.each(loadedDocTables, (i, loadedDocTable) => {
    loadedDocTable.$target.on('datatable-row-init', (e, row) => {
      const $link = $(row).find('.upload-folder-trigger');
      $link.on('click', (e) => {
        e.stopPropagation();
        const $button = $('a#path-file-uploader');
        $button.attr('data-path', $link.attr('data-path'));
        $button.find('input').trigger('click');
      });

      const $renameLink = $(row).find('.rename-folder-trigger');
      $renameLink.on('click', (e) => {
        e.stopPropagation();
        const path = _.unescape($renameLink.attr('data-path').trim());

        const folderDocs = _.filter(
          loadedDocTable.data,
          (obj) => obj.currentDirectoryName === path,
        );
        const docIds = _.pluck(folderDocs, 'id');

        if (docIds.length <= 0) {
          // TODO - translate notification
          $.notify('There are no documents to move', {
            type: 'danger',
            placement: {
              from: 'bottom',
              align: 'right',
            },
            allow_dismiss: false,
          });
        } else {
          const method = Routing.generate('app_stagedocument_movemodal', {
            id: loadedDocTable.stageId,
            folder: path,
            addendumId: loadedDocTable.addendumId,
          });

          const request = new E1Request(method, 'POST', {
            docId: docIds,
          });

          request.show_loading_modal = true;
          request.submit();
        }
      });

      const $selectCheck = $(row).find('input.select-folder-check');
      $selectCheck.on('click', (e) => {
        e.stopPropagation();
        loadedDocTable.togglePath(
          $(e.currentTarget).attr('data-path').trim(),
          $(e.currentTarget).is(':checked'),
        );
      });
    });
  });

  $body.on('change', '.existingRadioOptions input.folder-type-switcher', (e) => {
    const $container = $(e.currentTarget).closest('.existingRadioOptions');
    const $option = $(e.currentTarget).closest('div.option');

    $container.find('.option').removeClass('active');
    $option.addClass('active');
  });
});
