import { Tag, TagVariant } from '@estimateone/frontend-components';
import { localisedDistanceString } from '@shared';
import { Address, getLocalArea } from '@shared/Util/address';
import { ServiceAreaBoundaryType, ServiceAreaType } from '@ascension/_gqltypes/profiles.generated';
import styles from './styles.scss';

export type OfficeServiceAreaTagProps = {
  serviceArea: {
    type: ServiceAreaType;
    radius: number | null;
    boundaries:
      | {
          id: number;
          type: ServiceAreaBoundaryType;
          name: string;
          shortName: string;
        }[]
      | null;
  };
  address: Address;
  imperialUnitSetting: boolean;
  isBuilderTag?: boolean;
};

export const OfficeServiceAreaTag = ({
  serviceArea,
  address,
  imperialUnitSetting,
  isBuilderTag = false,
}: OfficeServiceAreaTagProps) => {
  const tagVariant = isBuilderTag ? TagVariant.NetworkReadonly : TagVariant.Practical025;

  if (serviceArea.type === ServiceAreaType.RADIAL) {
    if (!serviceArea?.radius) {
      return null;
    }

    const distance = localisedDistanceString(serviceArea?.radius, imperialUnitSetting, 0);
    const localArea = getLocalArea(address);
    const location = localArea ? ` of ${localArea}` : '';

    return <Tag text={`Within ${distance}${location}`} variant={tagVariant} />;
  }

  if (!serviceArea?.boundaries?.length) {
    return null;
  }

  return (
    <div className={styles.inlineTagContainer}>
      {serviceArea.boundaries.map(({ id, name, shortName, type }) => (
        <Tag
          key={id}
          text={`${type === ServiceAreaBoundaryType.COUNTRY ? name : shortName}`}
          variant={tagVariant}
        />
      ))}
    </div>
  );
};
