import { Tooltip } from '@estimateone/frontend-components';
import styles from './styles.scss';

export const StatNumber = ({
  value,
  label,
  tooltipText,
}: {
  value: string | null;
  label: string;
  tooltipText?: string;
}) => {
  const displayedValue = value ?? '?';
  const tooltipId = `${label.replace(/\s/g, '')}-stat-tooltip`;
  return (
    <>
      <div
        className={styles.statNumberContainer}
        data-tip
        data-for={tooltipText ? tooltipId : undefined}
        aria-describedby={tooltipText ? tooltipId : undefined}
      >
        <div className={styles.statNumber} aria-label={`${label} ${displayedValue}`}>
          {displayedValue}
        </div>
        <div className={styles.statNumberLabel}>{label}</div>
      </div>
      {tooltipText ? <Tooltip text={tooltipText} tooltipId={tooltipId} place="bottom" /> : null}
    </>
  );
};
