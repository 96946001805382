import { useQuery } from '@apollo/client';
import { GET_STAGE_SETTINGS } from '../queries';
import { StageSettings, StageSettingsVariables } from '../types/StageSettings';
import { EntityId } from '@ascension/types';

export const useGetStageSettings = (stageId: EntityId) => {
  const { data, loading } = useQuery<StageSettings, StageSettingsVariables>(GET_STAGE_SETTINGS, {
    variables: {
      stageId,
    },
  });
  return {
    stageSettingNotifyAwarded: data?.stageSettingNotifyAwarded,
    stageSettingEnableDefaultTimeframes: data?.stageSettingEnableDefaultTimeframes ?? true,
    loading,
  };
};
