import { Country } from '@ascension/enums';

export const getLocationForPlace = async (placeId: string): Promise<unknown> =>
  new Promise((resolve, reject) => {
    const placesService = new google.maps.places.PlacesService(document.createElement('div'));
    placesService.getDetails({ fields: ['address_components'], placeId }, (result, status) => {
      if (status === 'OK') {
        return resolve({ result });
      }
      return reject(Error(`PlacesService.getDetails status was ${status}`));
    });
  });

const getCountryShortNameForCountryId = (countryId: number) => {
  switch (countryId) {
    case Country.COUNTRY_UK:
      return 'uk';
    case Country.COUNTRY_IE:
      return 'ie';
    case Country.COUNTRY_AUSTRALIA:
    default:
      return 'au';
  }
};

export const getGoogleSuggestions = async (
  text: string,
  countryId: number,
): Promise<{ description: string; placeId: string }[]> =>
  new Promise((resolve, reject) => {
    try {
      new window.google.maps.places.AutocompleteService().getPlacePredictions(
        {
          input: text,
          componentRestrictions: { country: getCountryShortNameForCountryId(countryId) },
        },
        (results, status) => {
          if (status === 'ZERO_RESULTS') {
            return resolve([]);
          }
          if (status === 'OK') {
            return resolve(
              results.map(({ description, place_id: placeId }) => ({ description, placeId })),
            );
          }
          return reject(Error(`Place prediction status was ${status}`));
        },
      );
    } catch (e) {
      reject(e);
    }
  });
