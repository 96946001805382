import { gql } from '@apollo/client';

export const GET_USER_CONTEXT = gql`
  query UserContextQuery {
    currentUser {
      ...UserDetails
    }
  }

  fragment UserDetails on User {
    id
    isAdmin
    account {
      id
      type
    }
  }
`;

export const GET_SSC_OPT_OUT_PREFERENCE = gql`
  query SscOptOutPreference {
    hasOptedOutOfSSC
  }
`;

export const GET_IS_AUTHORISED_TO_OPT_INTO_DIRECTORY = gql`
  query isAuthorisedToOptIntoDirectory {
    isAuthorisedToOptIntoDirectory
  }
`;
