import { Form } from 'react-final-form';
import { Modal, ModalSize, Button, ButtonVariant } from '@estimateone/frontend-components';
import { TextInputField } from '../../../../shared/FinalForm/Field/TextInputField';
import { useUpdatePackageTitle } from './hooks/useUpdatePackageTitle';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

export type UpdatePackageTitleModalProps = {
  packageId: EntityId;
  currentPackageTitle: string;
  isOpen: boolean;
  onCloseHandler: () => void;
  onSuccess: () => void;
};

export enum UpdatePackageTitleFormFieldName {
  PackageTitle = 'title',
  OtherErrors = 'otherErrors',
}

export type UpdatePackageTitleFormValues = {
  [UpdatePackageTitleFormFieldName.PackageTitle]: string;
};

type FormErrors = Record<string, string[]>;

export const UpdatePackageTitleModal = ({
  packageId,
  currentPackageTitle,
  isOpen,
  onCloseHandler,
  onSuccess,
}: UpdatePackageTitleModalProps) => {
  const { submit, loading } = useUpdatePackageTitle();
  const handleFormSubmit = async (
    values: UpdatePackageTitleFormValues,
  ): Promise<FormErrors | undefined> => {
    const response = await submit(packageId, values[UpdatePackageTitleFormFieldName.PackageTitle]);
    if (response?.__typename === 'Errors') {
      return {
        [UpdatePackageTitleFormFieldName.PackageTitle]: response.errors.map((e) => e.message),
      };
    }

    onSuccess();
    return undefined;
  };

  return (
    <Modal size={ModalSize.Small} isOpen={isOpen} onRequestClose={onCloseHandler}>
      <Modal.Section>
        <h1 className={styles.modalHeading}>Change Package Name</h1>
        <Form<UpdatePackageTitleFormValues>
          initialValues={{ [UpdatePackageTitleFormFieldName.PackageTitle]: currentPackageTitle }}
          onSubmit={handleFormSubmit}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <TextInputField
                id="package-name-input"
                label="Package Name"
                isRequired
                fieldName={UpdatePackageTitleFormFieldName.PackageTitle}
                validate={(name) =>
                  (name?.trim().length ?? 0) === 0
                    ? ['Choose a new name for the package']
                    : undefined
                }
              />
              <div className={styles.modalButtonDiv}>
                <Button
                  className={styles.modalButton}
                  variant={ButtonVariant.Grey}
                  onClick={onCloseHandler}
                >
                  Cancel
                </Button>
                <Button
                  className={styles.modalButton}
                  variant={ButtonVariant.Primary}
                  type="submit"
                  disabled={loading}
                >
                  Confirm
                </Button>
              </div>
            </form>
          )}
        </Form>
      </Modal.Section>
    </Modal>
  );
};
