import {
  Button,
  ButtonVariant,
  Modal,
  ModalProps,
  ModalSize,
} from '@estimateone/frontend-components';
import { Icon, IconName } from '@estimateone/frontend-components/src/components/Graphics';
import styles from '@estimateone/frontend-components/src/components/Modal/styles.module.scss';

const ModalWithCloseIconOnly = ({
  isOpen,
  onRequestClose,
  children,
}: Pick<ModalProps, 'isOpen' | 'onRequestClose' | 'children'>) => (
  <Modal
    isOpen={isOpen}
    rootElement="body"
    size={ModalSize.Medium}
    onRequestClose={onRequestClose}
    hideClose
  >
    <>
      <div className={styles.close}>
        <Button
          className="close-sub-trades-modal-trigger"
          aria-label="Close"
          onClick={onRequestClose}
          variant={ButtonVariant.Transparent}
        >
          <Icon name={IconName.Close} marginLeft="small" size=".85em" />
        </Button>
      </div>
      {children}
    </>
  </Modal>
);

export default ModalWithCloseIconOnly;
