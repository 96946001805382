import { useCallback, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useHasFixedPriceQuotesFeature } from '@shared';
import { fileToFileInput, transformQuoteFormApiErrors } from '@shared/Quotes/Form/transformers';
import { COMPLETE_QUOTE } from './mutations';
import { CompleteQuoteFormSpecificFieldName } from './enums';
import { QuoteFormCommonFieldName } from '@ascension/enums';
import { CompleteQuoteFormValues } from './types';
import { CompleteQuote, CompleteQuoteVariables } from './types/CompleteQuote';
import { CompleteBuilderQuoteInput } from '@ascension/_gqltypes/builder.generated';
import { EntityId } from '@ascension/types';

export const useCompleteQuote = (quoteId: EntityId) => {
  const [completedQuoteId, setCompletedQuoteId] = useState<EntityId>();
  const hasFixedPriceFeature = useHasFixedPriceQuotesFeature();

  const transformValues = useCallback(
    (values: CompleteQuoteFormValues): CompleteBuilderQuoteInput => {
      if (
        values[CompleteQuoteFormSpecificFieldName.Package]?.id === undefined ||
        values[CompleteQuoteFormSpecificFieldName.Contact]?.id === undefined ||
        values[QuoteFormCommonFieldName.Amount] === undefined
      ) {
        throw new Error('Incomplete quote form input');
      }

      return {
        quoteId,
        packageId: values[CompleteQuoteFormSpecificFieldName.Package].id,
        contactId: values[CompleteQuoteFormSpecificFieldName.Contact].id,
        amount: values[QuoteFormCommonFieldName.Amount],
        additionalFiles: values[QuoteFormCommonFieldName.ExtraFiles]?.map(fileToFileInput) ?? [],
        details: values[QuoteFormCommonFieldName.Details],
        confirmFixedPrice: hasFixedPriceFeature
          ? (values[QuoteFormCommonFieldName.ConfirmFixedPrice] ?? false)
          : undefined,
      };
    },
    [hasFixedPriceFeature, quoteId],
  );

  const [completeQuote, { data }] = useMutation<CompleteQuote, CompleteQuoteVariables>(
    COMPLETE_QUOTE,
  );

  const submit = async (values: CompleteQuoteFormValues) => {
    const { data: response } = await completeQuote({
      variables: { input: transformValues(values) },
    });

    if (response?.completeQuote.__typename === 'Errors') {
      return transformQuoteFormApiErrors(response.completeQuote.errors);
    }
    return undefined;
  };

  useEffect(() => {
    if (data) {
      if (data.completeQuote.__typename === 'Quote') {
        setCompletedQuoteId(data.completeQuote.id);
      }
    }
  }, [data]);

  return { submit, completedQuoteId };
};
