import { ComponentType } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { LoadingSpinner, Modal, ModalSize } from '@estimateone/frontend-components';
import { useProfileContext } from '@profiles/providers/ProfileProvider';
import { ProfileContextProps } from '@profiles/providers/ProfileProvider/ProfileProvider';

export type ModalWrappedComponentProps = {
  onClose: () => void;
  profile: NonNullable<ProfileContextProps['profile']>;
};

type Options = {
  onCloseNavigateTo: string;
};

export function withModalRoute(
  WrappedComponent: ComponentType<ModalWrappedComponentProps>,
  options: Partial<Options> = {},
) {
  const componentOptions: Options = {
    onCloseNavigateTo: '/',
    ...options,
  };

  return () => {
    const navigate = useNavigate();
    const onClose = () => navigate(componentOptions.onCloseNavigateTo);

    const { profile } = useProfileContext();

    return (
      <Modal rootElement="body" isOpen onRequestClose={onClose} size={ModalSize.Small}>
        <Modal.Section>
          {profile ? <WrappedComponent profile={profile} onClose={onClose} /> : <LoadingSpinner />}
        </Modal.Section>
      </Modal>
    );
  };
}
