import { ProfileStatus } from './types';
import { CompanyProfile } from '@profiles/providers/ProfileProvider/types';

export const getProfileStatus = (profile?: CompanyProfile | null): ProfileStatus => {
  if (!profile) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return {} as ProfileStatus;
  }

  const EXCESSIVE_TRADES_AMOUNT = 16;

  return {
    hasCompanyNumber: profile.companyNumber !== null,
    hasOptimalTradesAmount:
      profile.trades.length > 0 && profile.trades.length <= EXCESSIVE_TRADES_AMOUNT,
    hasContractSize: profile.contractSizeMin !== null && profile.contractSizeMax !== null,
    hasContacts: profile.people.length > 0,
  };
};

export const hasCompletedProfile = (profileStatus: ProfileStatus): boolean => {
  const { hasCompanyNumber, hasOptimalTradesAmount, hasContractSize, hasContacts } = profileStatus;

  return hasCompanyNumber && hasOptimalTradesAmount && hasContractSize && hasContacts;
};
