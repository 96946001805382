import { gql } from '@apollo/client';

export const GET_PACKAGE_DOWNLOAD_STATUS = gql`
  query GetPackageStatus($stageId: EntityId!, $packageId: EntityId!) {
    userPackageDownloadStatus(stageId: $stageId, packageId: $packageId)
  }
`;

const SLIDER_STAGE_FRAGMENT = gql`
  fragment SliderStage on Stage {
    id
    name
    status
    description
    isBuilderCode
    autoApproveDocs
    private
    tenderQuotesDue
    constructionStartDate
    constructionEndDate
    budgetAmount
    hasMultipleQuoteDueDates
    builderDetails {
      ... on BuilderDetails {
        id
        name
        abbrev
        contactName
        contactPhone
        contactEmail
      }
      ... on HiddenBuilderDetails {
        isIncognito
      }
    }
    activePackages {
      id
      title
      fullSet
      contentLastAddedAt
      customQuotesDueAt
      trade {
        id
        stockTrade {
          id
        }
      }
      discipline {
        id
        name
      }
    }
    mostRecentQuote {
      id
    }
    trades {
      stockTrade {
        id
        name
        discipline {
          id
          name
        }
      }
    }
  }
`;

export const GET_PROJECT_SLIDER_DATA = gql`
  query GetProjectSliderData($projectId: EntityId!) {
    projectForSlider(id: $projectId) {
      project {
        ... on ProjectInterface {
          id
          name
          stageCategoryId
          stageCategoryName
          minTenderBudget
          maxTenderBudget
          awardedTenderBudget
          tenderQuoteDueAt
          status
          hasDocs
          address {
            id
            fullAddress
            shortAddress
            latitude
            longitude
            state {
              shortName
            }
          }
          countryId
        }
        ... on WatchableProject {
          watchlistEntry {
            id
            status
            updatedAt
          }
        }
        ... on PaywalledNoticeboardProject {
          redactedReason
          requiredProductForRoadblock {
            shortStates
            region {
              id
            }
          }
          requireAdditionalLicense
          redactedStages: stages {
            id
          }
        }
        ... on ViewableNoticeboardProject {
          stages {
            ...SliderStage
          }
        }
      }
      notes {
        id
        authorName
        text
        createdAt
      }
      projectActivityLogs {
        __typename
        ... on StageActivityLog {
          id
          message
          messageType
          createdAt
        }
        ... on PackageActivityLog {
          id
          message
          messageType
          createdAt
        }
      }
    }
  }
  ${SLIDER_STAGE_FRAGMENT}
`;

export const RESEND_LINK_QUERY = gql`
  query ResendLink($userId: EntityId!) {
    resendAccessLink(userId: $userId)
  }
`;

export const DOCUMENT_HIGHLIGHTS_FOR_STAGE_KEYWORD_MATCHES = gql`
  query DocumentHighlightsForStageKeywordMatches($stageId: EntityId!) {
    documentHighlightsForStageKeywordMatches(stageId: $stageId) {
      keywordWithDocumentHighlights {
        keyword
        documentHighlights {
          documentId
          name
          highlight
        }
      }
      stage {
        id
        name
        builderDetails {
          ... on BuilderDetails {
            id
            name
            contactName
            contactPhone
            contactEmail
          }
          ... on HiddenBuilderDetails {
            isIncognito
          }
        }
        activePackages {
          id
          title
          fullSet
          contentLastAddedAt
          customQuotesDueAt
          trade {
            id
            stockTrade {
              id
            }
          }
          discipline {
            id
            name
          }
        }
        projectId
      }
    }
  }
`;

export const GET_PROJECT_SUBBIE_SUMMARY = gql`
  query ProjectSubbieSummary($projectId: EntityId!) {
    projectSubbieSummary(projectId: $projectId) {
      id
      projectId
      subbieCount
      lockedSubbieCount
      unlocked
      totalSubbieCount
    }
  }
`;
