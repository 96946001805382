import { gql } from '@apollo/client';

export const GET_SHAREPOINT_SITES_AND_DRIVES = gql`
  query GetSharePointSitesAndDrives {
    getSharePointSitesAndDrives {
      ... on SharePointSites {
        sites {
          id
          name
          displayName
          drives {
            id
            name
          }
        }
      }
      ... on Errors {
        errors {
          field
          message
        }
      }
    }
  }
`;

export const GET_SHAREPOINT_DRIVE_ITEMS = gql`
  query GetDriveItems($driveId: ID!, $driveItemId: ID!) {
    getSharePointDriveItems(driveId: $driveId, driveItemId: $driveItemId) {
      ... on SharePointDriveItems {
        driveItems {
          id
          name
          type
        }
      }
      ... on Errors {
        errors {
          field
          message
        }
      }
    }
  }
`;
