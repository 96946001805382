import { gql } from '@apollo/client';

export const UPDATE_CORE_TRADES = gql`
  mutation UpdateCoreTrades($coreTradeIds: [EntityId!]!) {
    updateCoreTrades(coreTradeIds: $coreTradeIds) {
      ... on Profile {
        id
        trades {
          id
          name
          isCoreTrade
        }
      }
      ... on Errors {
        errors {
          field
          message
        }
      }
    }
  }
`;

export const UPDATE_TRADES = gql`
  mutation UpdateTrades($stockTradeIds: [EntityId!]!) {
    changeStockTrades(stockTradeIds: $stockTradeIds) {
      id
      name
    }
  }
`;
