import { memo, useCallback } from 'react';
import { useField, useForm } from 'react-final-form';
import { PlaceSuggestingSelect, SelectedPlace } from '../PlaceSuggestingSelect';
import { getDistanceOptions } from '../utils';
import { locationOptions } from './utils';
import { FieldWrapper } from '@shared/FinalForm/Field/FieldWrapper';
import { DistanceUnit } from '@shared/Util';
import {
  SubbieNetworkFilterFormFields,
  SubbieNetworkFilterFormId,
  SubbieNetworkFilterFormValues,
} from '../types';

type Props = {
  countryId: number;
  unit: DistanceUnit;
  placeholder: string;
  placePredictionsTypes?: string[];
};

const getMaxDistanceOption = (unit: DistanceUnit) => getDistanceOptions(unit).pop();

export const LocationFilter = memo(
  ({ countryId, unit, placeholder, placePredictionsTypes }: Props) => {
    const {
      input,
      meta: { error, submitError },
    } = useField<SubbieNetworkFilterFormValues['location']>(SubbieNetworkFilterFormFields.Location);

    const networkDrawerForm = useForm();

    const onChange = useCallback(
      (selectedPlace: SelectedPlace | null) => {
        const options = selectedPlace ? locationOptions(selectedPlace) : null;
        input.onChange(options);

        if (selectedPlace) {
          const distanceField = networkDrawerForm.getFieldState(
            SubbieNetworkFilterFormFields.Distance,
          );

          if (distanceField && !distanceField.value) {
            distanceField.change(getMaxDistanceOption(unit));
          }
        }
      },
      [input, networkDrawerForm],
    );

    return (
      <FieldWrapper errors={error || submitError} showErrors>
        <PlaceSuggestingSelect
          countryId={countryId}
          label="Location"
          placeholder={placeholder}
          isRequired={false}
          onLocationSelected={onChange}
          hideLabel
          formId={SubbieNetworkFilterFormId}
          value={input.value ?? null}
          placePredictionsTypes={placePredictionsTypes}
        />
      </FieldWrapper>
    );
  },
);
