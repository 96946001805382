import { formatDateForStageRow } from '../util';
import { QuotedTagAndQuoteLink } from './QuotedTagAndQuoteLink';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

type QuotesDueByProps = {
  tenderQuotesDue: string | null;
  mostRecentQuoteId?: EntityId;
  showEndDateDisclaimer?: boolean;
};

const EndDateDisclaimer = () => (
  <p className={styles.dueDateDisclaimer}>*some packages due earlier</p>
);

export const QuotesDueBy = ({
  tenderQuotesDue,
  mostRecentQuoteId,
  showEndDateDisclaimer = false,
}: QuotesDueByProps) => {
  const quotesDueText = tenderQuotesDue
    ? `${formatDateForStageRow(tenderQuotesDue)}${showEndDateDisclaimer ? '*' : ''}`
    : 'Unknown';

  return (
    <>
      <div className={styles.quotesDueDate}>
        <p>
          <span>Quotes Due</span>
          <span className={styles.quotesDueText}>{quotesDueText}</span>
        </p>
      </div>
      {showEndDateDisclaimer && <EndDateDisclaimer />}
      {mostRecentQuoteId && <QuotedTagAndQuoteLink mostRecentQuoteId={mostRecentQuoteId} />}
    </>
  );
};
