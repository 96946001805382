import { Alert, AlertVariant, WarningIcon } from '@estimateone/frontend-components';
import styles from './styles.scss';

export const NoEmailAlert = () => (
  <Alert variant={AlertVariant.Yellow}>
    <div className={styles.alert}>
      <WarningIcon />
      <p>
        <strong>
          This contact needs an email address if you want to send an invitation to quote.
        </strong>{' '}
        You can still add them to your list of Responses (without sending an invitation).
      </p>
    </div>
  </Alert>
);
