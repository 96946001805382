import { gql } from '@apollo/client';

export const GET_STOCK_TRADES = gql`
  query GetStockTrades {
    stockTrades {
      id
      name
    }
  }
`;
