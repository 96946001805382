import { SingleSelectField } from '@shared/FinalForm/Field/SingleSelectField';
import { SubbieNetworkFilterFormFields } from '@builder/common/SubbieNetwork/utils/filters/types';
import { ValueLabelOption } from '@estimateone/frontend-components/src/components/Form/Select/types';

type TradeFilterProps = {
  tradeOptions: ValueLabelOption<number>[] | null;
};

export const TradeFilter = ({ tradeOptions }: TradeFilterProps) => {
  if (!tradeOptions) {
    return null;
  }

  return (
    <section data-testid="drawerTradesFilter">
      <SingleSelectField
        id="drawer-filter-trades"
        inputId="drawer-filter-trades"
        fieldName={SubbieNetworkFilterFormFields.TradeFilterId}
        label=""
        options={tradeOptions}
        hideLabel
        isRequired
        placeholder="Start typing a Trade name..."
        isClearable
        autoFocus
      />
    </section>
  );
};
