import { AlertIcon, EmployeeIcon } from '@estimateone/frontend-components';
import { ProfileBadgeCard } from '../../ProfileBadgeCard';
import { ProfileBadgeIconWrapper } from '../../ProfileBadgeIconWrapper';
import styles from './EmployeeBadge.module.scss';
import { getWorkforceSizeLabel } from './utils';
import { WorkforceSizeEnum } from '@ascension/_gqltypes/profiles.generated';

type WorkforceSizeProps = {
  workforceSize: WorkforceSizeEnum | null;
};

export const EmployeeBadge = ({ workforceSize }: WorkforceSizeProps) => {
  const workforceSizeLabel = workforceSize ? getWorkforceSizeLabel(workforceSize) : null;

  return (
    <ProfileBadgeCard
      heading={workforceSizeLabel}
      subHeading="Employees"
      icon={
        <ProfileBadgeIconWrapper
          status="info"
          content={<EmployeeIcon className={styles.employeeIcon} />}
        />
      }
    />
  );
};

export const EmployeeBadgeViewError = () => (
  <div className={styles.errorIconContainer}>
    <AlertIcon title="employee size error icon" />
  </div>
);
