import { Button, Modal, ModalSize } from '@estimateone/frontend-components';
import styles from './styles.module.scss';

export type ConfirmInvitesModalProps = {
  isOpen: boolean;
  inviteCount: number;
  onRequestClose: () => void;
  onSendInvites: () => void;
  isSending: boolean;
};

export const ConfirmInvitesModal = ({
  isOpen,
  inviteCount,
  onRequestClose,
  onSendInvites,
  isSending,
}: ConfirmInvitesModalProps) => {
  const handleSendInvitations = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    await onSendInvites();
  };

  return (
    <Modal size={ModalSize.Small} isOpen={isOpen} onRequestClose={onRequestClose}>
      <Modal.Section>
        <p className={styles.confirmInvites}>{`Confirm ${inviteCount} Invitation(s)`}</p>
        <p className={styles.addressBookMessage}>
          Subbies invited from the Network will be added to your Address Book.
        </p>

        <Button className={styles.sendInvites} onClick={handleSendInvitations} disabled={isSending}>
          Send Invitation(s) to Quote
        </Button>
      </Modal.Section>
    </Modal>
  );
};
