import Routing from 'routing';
import E1Request from '@ascension/js/classes/E1Request';
import { EntityId } from '@ascension/types';

export const useAddendumActions = (stageId: EntityId) => {
  const viewAddendumChanges = async (addendumId: EntityId) => {
    await new E1Request(
      Routing.generate('app_stagedocument_viewchangesmodal', { id: stageId, addendumId }),
      'GET',
    )
      .setShowLoadingModal(true)
      .submit();
  };

  const getContinueAddendumLink = (id: EntityId) =>
    Routing.generate('app_stageaddendum_index', { id });

  return {
    viewAddendumChanges,
    getContinueAddendumLink,
  };
};
