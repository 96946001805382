import $ from 'jquery';
import Routing from 'routing';
import E1Request from '../classes/E1Request';

$(() => {
  const $body = $('body');

  $body.on('change', '.handover-user-box', () => {
    const count = $('#handover-users-table')
      .find('input[type="checkbox"]:checked')
      .not(':disabled').length;
    $('#handover-user-count').text(count);
  });
});
