import Routing from 'routing';
import { Tag, TagVariant } from '@estimateone/frontend-components';
import E1Request from '@ascension/js/classes/E1Request';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

const showObsoleteDocsModal = (stageId: EntityId, rfqId: EntityId) => {
  const route = Routing.generate('app_stagerfq_diff', { id: stageId, rfqId });
  const req = new E1Request(route, 'GET');
  // eslint-disable-next-line fp/no-mutation
  req.show_loading_modal = true;
  return req.submit();
};

type DocumentStatusTagProps = {
  stageId: EntityId;
  rfqId: EntityId;
  packageContentLastAddedAt: Date | undefined;
  rfqLastDownloadedAt?: Date;
};

export const DocumentStatusTag = ({
  stageId,
  rfqId,
  packageContentLastAddedAt,
  rfqLastDownloadedAt,
}: DocumentStatusTagProps) => {
  if (!rfqLastDownloadedAt) {
    return null;
  }
  const isObsolete =
    packageContentLastAddedAt !== undefined && packageContentLastAddedAt > rfqLastDownloadedAt;
  const variant = isObsolete ? TagVariant.RfqDocsObsolete : TagVariant.RfqDocsDownloaded;
  const shortMonth = rfqLastDownloadedAt?.toLocaleString('default', {
    month: 'short',
  });
  const text = `${shortMonth} ${rfqLastDownloadedAt?.getDate()}`;
  return isObsolete ? (
    <Tag
      className={styles.obsoleteDocs}
      onClick={() => showObsoleteDocsModal(stageId, rfqId)}
      text={text}
      variant={variant}
      aria-label="obsolete"
    />
  ) : (
    <Tag text={text} variant={variant} aria-label="up to date" />
  );
};
