import {
  CellProps,
  Table,
  TableColumn,
  ToggleFilter,
  useTableRef,
} from '@estimateone/frontend-components';
import { QuotingStatusTag } from '@builder/common/QuotingStatusTag';
import { Truncate } from '../Truncate';
import { GetTenderResponsesForPackage_package_activeRfqs as ActiveRfq } from '../types/GetTenderResponsesForPackage';
import { RfqQuotingStatus } from '@ascension/_gqltypes/builder.generated';
import { InterfaceToType } from '@ascension/types';
import styles from './styles.scss';

type Rfq = InterfaceToType<ActiveRfq>;

const columns: TableColumn<Rfq>[] = [
  {
    accessor: 'companyName',
    Header: 'Company Name',
    Cell: ({ value, row: { original } }) => (
      <Truncate tooltipId={`tender-responses--company-${original.id}`} text={value ?? ''} />
    ),
  },
  {
    accessor: ({ contact }) => contact?.fullName ?? '-',
    Header: 'Contact Name',
    Cell: ({ value, row: { original } }: CellProps<Rfq>) => (
      <Truncate tooltipId={`tender-responses--contact-${original.id}`} text={value} />
    ),
  },
  {
    accessor: ({ contact }) => contact?.email ?? '-',
    Header: 'Email',
    Cell: ({ value, row: { original } }: CellProps<Rfq>) => (
      <Truncate
        tooltipId={`tender-responses--email-${original.id}`}
        text={value}
        className={styles.email}
      />
    ),
  },
  {
    accessor: ({ contact }) => contact?.phone ?? '-',
    Header: 'Phone',
    Cell: ({ value }: CellProps<Rfq>) => <div className={styles.phone}>{value}</div>,
  },
  {
    accessor: 'quotingStatus',
    Header: 'Status',
    Cell: ({ value }) => <QuotingStatusTag quotingStatus={value} />,
  },
];

export const TenderResponsesTable = ({ rfqs }: { rfqs: Rfq[] }) => {
  const { tableRef, tableInstance } = useTableRef<Rfq>();

  return (
    <div className={styles.tenderResponsesTable}>
      {tableInstance ? (
        <ToggleFilter<Rfq>
          tableInstance={tableInstance}
          id="table-filter--quoted"
          label={
            <>
              Filter by <span className={styles.filterValue}>Quoted</span> only
            </>
          }
          columnId="quotingStatus"
          filterValue={RfqQuotingStatus.QUOTED}
          containerClassName={styles.filter}
        />
      ) : null}
      <Table<Rfq>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        tableRef={tableRef as any}
        columns={columns}
        data={rfqs}
        initSortColumnId="companyName"
        maxHeight="342px"
      />
    </div>
  );
};
