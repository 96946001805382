import { useForm } from 'react-final-form';
import { Button, ButtonVariant, Col, Row, Slider } from '@estimateone/frontend-components';
import { PaneBodyForContactWithEmail } from './PaneBodyForContactWithEmail';
import { PaneBodyForContactWithoutEmail } from './PaneBodyForContactWithoutEmail';
import { InviteToQuoteFormFieldName } from '../enums';
import { InviteToQuoteFormValues } from '../types';
import { GetInviteToQuoteStage_stage_UnrestrictedStage as Stage } from '../types/GetInviteToQuoteStage';

type PersonalisationPaneProps = {
  stage?: Stage;
  onBack: () => void;
};

export const PersonalisationPane = ({ stage, onBack }: PersonalisationPaneProps) => {
  const { getState } = useForm<InviteToQuoteFormValues>();
  const { values } = getState();

  const contactHasEmail = values[InviteToQuoteFormFieldName.Contact].contactDetail.email !== null;

  if (!stage) return null;

  return (
    <>
      {contactHasEmail ? (
        <PaneBodyForContactWithEmail stage={stage} />
      ) : (
        <PaneBodyForContactWithoutEmail stage={stage} />
      )}

      <Slider.Footer>
        <Row>
          <Col excludeBottomGutter span={6}>
            <Button type="button" variant={ButtonVariant.Secondary} onClick={onBack} fullWidth>
              Back
            </Button>
          </Col>
          <Col excludeBottomGutter span={6}>
            <Button type="submit" fullWidth>
              {contactHasEmail ? 'Send Invitation' : 'Add to Responses'}
            </Button>
          </Col>
        </Row>
      </Slider.Footer>
    </>
  );
};
