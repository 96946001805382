import { ContactOption } from '../../types';
import { GetContactsForCompanyToAssignQuote_company_activeContacts as ActiveContact } from '../types/GetContactsForCompanyToAssignQuote';
import {
  RfqsForPackage_package_rfqs as Rfq,
  RfqsForPackage_package_rfqs_quotes as Quote,
} from '../types/RfqsForPackage';

export const getMostRecentActiveQuote = (rfq: Rfq): Quote | undefined =>
  rfq.quotes
    .filter(({ deletedAt }) => deletedAt === null)
    .find(({ revisingQuote }) => revisingQuote === null);

export const hasActiveQuote = (rfq: Rfq) => getMostRecentActiveQuote(rfq) !== undefined;

/*
 * We require that the contact has an associated user,
 * otherwise we make the process of trying to create an RFQ very
 * difficult for ourselves.
 */
export const isContactValid = ({ user, approvedAt }: ActiveContact) =>
  user !== null && approvedAt !== null;

export const findRfqForContact = ({ id, user }: ActiveContact, rfqs: Rfq[]): Rfq | undefined =>
  rfqs.find(
    ({ user: rfqUser, contact }) => contact?.id === id || (user && user.id === rfqUser?.id),
  );

export const contactToOption = (
  contact: ActiveContact,
  contactRfqForSelectedPackage?: Rfq,
): ContactOption => {
  const invited = contactRfqForSelectedPackage !== undefined;

  return {
    value: contact.id,
    label: contact.fullName,
    invited,
    mostRecentQuoteId: invited
      ? getMostRecentActiveQuote(contactRfqForSelectedPackage)?.id
      : undefined,
  };
};
