import { Button, ButtonSize, ButtonVariant } from '@estimateone/frontend-components';
import styles from './styles.module.scss';

/** SUBBIE_CREDIT_EXPERIMENT_ALPHA_CODE */
type LitePlanButtonsViewProps = {
  handleUpgrade: () => void;
  handleUnlockTender: () => void;
  isUnlockProjectLoading: boolean;
};

export const LitePlanButtonsView = ({
  handleUpgrade,
  handleUnlockTender,
  isUnlockProjectLoading,
}: LitePlanButtonsViewProps) => (
  <div className={styles.buttonsContainer}>
    <Button
      type="button"
      variant={ButtonVariant.Secondary}
      onClick={handleUpgrade}
      size={ButtonSize.Medium}
      fullWidth
    >
      I want to access all tenders
    </Button>
    <Button
      type="button"
      variant={ButtonVariant.Primary}
      onClick={handleUnlockTender}
      disabled={isUnlockProjectLoading}
      size={ButtonSize.Medium}
      fullWidth
    >
      Unlock tender
    </Button>
  </div>
);
