import { ReactNode } from 'react';
import { NoContentPlaceholder } from '@profiles/features/CompanyProfile/components/NoContentPlaceholder';
import styles from './ProfileBadgeCard.module.scss';

export type ProfileBadgeCardProps = {
  icon: ReactNode;
  heading: string | null | undefined;
  subHeading?: string;
};

export const ProfileBadgeCard = ({ icon, heading, subHeading }: ProfileBadgeCardProps) => (
  <div className={styles.profileBadgeCardWrapper}>
    {icon}
    <div className={styles.profileContentWrapper}>
      {heading ? <div className={styles.heading}>{heading}</div> : <NoContentPlaceholder />}
      {subHeading && <div className={styles.subHeading}>{subHeading}</div>}
    </div>
  </div>
);
