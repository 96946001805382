/* eslint-disable react/no-unstable-nested-components */
import { useMemo } from 'react';
import {
  Button,
  ButtonVariant,
  CellProps,
  E1Link,
  Icon,
  IconName,
  IconVariant,
  NotificationCounter,
  Table,
  TableColumn,
  Tag,
  TagVariant,
} from '@estimateone/frontend-components';
import { format } from 'date-fns';
import { QuoteAmount } from '@builder/features/ProcurementLettingSchedule/PackageDashboard/QuoteAmount';
import { showUniversalProfileDrawer } from '@builder/pages/Profiles/UniversalProfileDrawer';
import { useScopeOfWorks } from '@scope-of-works/providers/ScopeOfWorksProvider';
import { DocumentStatusTag } from '../DocumentStatusTag';
import { NoteIcon } from '../NoteIcon';
import { ResponseStatus } from '../ResponseStatus';
import { generateCreateQuoteLink, showCompanySlideout } from '../utils';
import { AwardedState, PackageDashboardTableOptions } from './PackageDashboardTableOptions';
import showHistoryModal from './PackageDashboardTableOptions/showHistoryModal';
import { ScopeStatusTag } from './ScopeStatusTag';
import { quoteStatusSort } from './utils/sort';
import { useIsUniversalDrawerEnabled } from '@builder/context/AccountProvider/hooks';
import { useGetPackageWithRfqs } from '../hooks/useGetPackageWithRfqs';
import { Action, useInteractAnalytics } from '@ascension/components/hooks/Analytics';
import { ButtonName } from '@ascension/components/hooks/Analytics/buttonNames';
import { ScreenName } from '@ascension/components/hooks/Analytics/screenName';
import { QuoteReturnRoute } from '@ascension/enums';
import { Rfq } from '../types';
import { GetPackageWithRfqs_package_activeRfqs as ActiveRfq } from '../types/GetPackageWithRfqs';
import { RfqQuotingStatus } from '@ascension/_gqltypes/builder.generated';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

export type PackageDashboardTableProps = {
  packageData: ReturnType<typeof useGetPackageWithRfqs>['packageData'];
  stageId: EntityId;
  activeRfqs: ActiveRfq[];
  packageContentLastAddedAt: Date | undefined;
  awardedToCompanyId: number | undefined;
  refetch: ReturnType<typeof useGetPackageWithRfqs>['refetch'];
};

const getAwardedState = (
  awardedToCompanyId: number | undefined,
  contactCompanyId: number | undefined,
): AwardedState => {
  switch (awardedToCompanyId) {
    case undefined:
      return AwardedState.UnAwarded;
    case contactCompanyId:
      return AwardedState.AwardedToThisCompany;
    default:
      return AwardedState.AwardedToAnotherCompany;
  }
};

export const PackageDashboardTable = ({
  packageData,
  stageId,
  activeRfqs,
  packageContentLastAddedAt,
  awardedToCompanyId,
  refetch,
}: PackageDashboardTableProps) => {
  const { status: scopeRfqStatus, scope } = useScopeOfWorks();
  const quoteStatusSorting = useMemo(() => quoteStatusSort, []);
  const { addEvent } = useInteractAnalytics();
  const isUniversalDrawerEnabled = useIsUniversalDrawerEnabled();

  const trackAddQuote = () =>
    addEvent({
      action: Action.BUTTON_CLICKED,
      buttonName: ButtonName.PROCUREMENT_RESPONSES_TABLE_ADD_QUOTE,
      screenName: ScreenName.PROCUREMENT_PACKAGE_DASHBOARD,
    });

  const columns: TableColumn<Rfq>[] = useMemo(
    () => [
      {
        id: 'company',
        accessor: ({ contact }: Rfq) => contact?.company?.name,
        Header: 'Company',
        Cell: ({ row: { original } }: CellProps<Rfq>) => {
          const company = original.contact?.company;
          if (!company) return null;
          return (
            <>
              <E1Link
                className={styles.companyName}
                onClick={() => {
                  if (isUniversalDrawerEnabled) {
                    showUniversalProfileDrawer({ companyId: company.id });
                  } else {
                    showCompanySlideout(company.id, original.id);
                  }
                }}
              >
                {company.name}
              </E1Link>
              {company.id === awardedToCompanyId && (
                <Tag
                  className={styles.awardedTag}
                  variant={TagVariant.Magic200}
                  text="Awarded"
                  icon={<Icon name={IconName.Awarded} size="12" />}
                />
              )}
            </>
          );
        },
      },
      {
        id: 'contact',
        accessor: ({ contact }: Rfq) => contact?.fullName,
        Header: 'Contact',
      },
      {
        id: 'phone',
        accessor: ({ contact }: Rfq) => contact?.phone,
        Header: 'Phone',
        disableSortBy: true,
      },
      {
        id: 'quoteDueAt',
        accessor: 'quoteDueAt',
        Cell: ({ row: { original } }: CellProps<Rfq>) =>
          original.quoteDueAt ? format(new Date(original.quoteDueAt), 'd MMM yyyy') : '',
        Header: 'Quotes Due',
      },
      {
        id: 'documentStatus',
        accessor: 'downloadedLastAt',
        Header: 'Document Status',
        disableSortBy: true,
        Cell: ({ row: { original } }: CellProps<Rfq>) => (
          <DocumentStatusTag
            stageId={stageId}
            rfqId={original.id}
            packageContentLastAddedAt={packageContentLastAddedAt}
            rfqLastDownloadedAt={
              original.downloadedLastAt ? new Date(original.downloadedLastAt) : undefined
            }
          />
        ),
      },
      {
        id: 'scopeStatus',
        accessor: () => 'ScopeStatus',
        Header: 'Scope Status',
        disableSortBy: true,
        Cell: ({ row: { original } }: CellProps<Rfq>) => (
          <ScopeStatusTag rfqId={original.id} scope={scope} scopeRfqStatus={scopeRfqStatus} />
        ),
      },
      {
        id: 'quotingStatus',
        accessor: 'quotingStatus',
        Cell: ({ row: { original: rfq } }: CellProps<Rfq>) => (
          <ResponseStatus
            returnTo={QuoteReturnRoute.BuilderPackageDashboard}
            stageId={stageId}
            rfq={rfq}
          />
        ),
        Header: 'Quote Status',
        sortType: quoteStatusSorting,
      },
      {
        id: 'quoteAmount',
        Header: () => <span className={styles.quoteAmountHeader}>Quote Amount</span>,
        disableSortBy: true,
        Cell: ({ row: { original } }: CellProps<Rfq>) => {
          const canAddQuote =
            original.quotingStatus === RfqQuotingStatus.QUOTED && !original.latestQuote;

          return (
            <div className={styles.quoteAmount}>
              {canAddQuote ? (
                <E1Link
                  onClick={async () => {
                    await trackAddQuote();
                    window.location.assign(
                      generateCreateQuoteLink(original.id, stageId, window.location.pathname),
                    );
                  }}
                >
                  Add a quote
                </E1Link>
              ) : (
                <QuoteAmount rfq={original} returnTo={QuoteReturnRoute.BuilderPackageDashboard} />
              )}
            </div>
          );
        },
      },
      {
        id: 'notes',
        Header: 'Notes',
        disableSortBy: true,
        Cell: ({ row: { original } }: CellProps<Rfq>) => (
          <Button
            aria-label={`View or add notes. Currently has ${original.noteCount} notes.`}
            variant={ButtonVariant.Transparent}
            onClick={() => showHistoryModal('notes', stageId, original.id, refetch)}
          >
            {original.noteCount > 0 ? (
              <NotificationCounter count={original.noteCount}>
                <NoteIcon variant={IconVariant.Default} />
              </NotificationCounter>
            ) : (
              <NoteIcon variant={IconVariant.Grey} />
            )}
          </Button>
        ),
      },
      {
        id: 'options',
        Cell: ({ row: { original } }: CellProps<Rfq>) => (
          <PackageDashboardTableOptions
            quotingStatus={original.quotingStatus}
            rfq={original}
            stageId={stageId}
            packageData={packageData}
            refetch={refetch}
            awardedState={getAwardedState(awardedToCompanyId, original.contact?.company?.id)}
          />
        ),
        Header: 'Options',
        disableSortBy: true,
      },
    ],
    [
      packageData,
      quoteStatusSorting,
      refetch,
      stageId,
      packageContentLastAddedAt,
      awardedToCompanyId,
      scopeRfqStatus,
    ],
  );

  return (
    <>
      <Table<Rfq> columns={columns} data={activeRfqs} initSortColumnId="company" />
      {activeRfqs.length === 0 ? (
        <div className={styles.noDataText}>
          No subcontractors have been invited to quote. Documents have been allocated to this
          package.
        </div>
      ) : null}
    </>
  );
};
