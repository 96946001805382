export const ProjectNoDocsImage = () => (
  <svg
    width="120"
    height="120"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.73205 52.4641C3.17977 51.5075 3.50752 50.2843 4.4641 49.7321L37.6702 30.5605C38.1296 30.2953 38.6755 30.2234 39.1879 30.3607L57.8435 35.3594C58.3558 35.4967 58.7927 35.8319 59.0579 36.2913L88.2295 86.8179C88.7818 87.7745 88.454 88.9977 87.4974 89.55L42.4641 115.55C41.5075 116.102 40.2843 115.775 39.7321 114.818L3.73205 52.4641Z"
      fill="white"
      stroke="#5E79FF"
      strokeWidth="4"
      strokeLinejoin="round"
    />
    <path
      d="M37.5178 30.1816L43.5178 40.574C44.6224 42.4871 47.0688 43.1426 48.9819 42.0381L59.3742 36.038"
      stroke="#5E79FF"
      strokeWidth="4"
      strokeLinejoin="round"
    />
    <path
      d="M18.9671 26.8754C18.6812 25.8084 19.3144 24.7118 20.3813 24.4259L62.7306 13.0784C63.2429 12.9411 63.7888 13.013 64.2482 13.2782L83.4239 24.3493C83.8832 24.6145 84.2184 25.0513 84.3557 25.5637L101.527 89.6463C101.812 90.7132 101.179 91.8099 100.112 92.0958L42.6398 107.495C41.5728 107.781 40.4762 107.148 40.1903 106.081L18.9671 26.8754Z"
      fill="white"
      stroke="#5E79FF"
      strokeWidth="4"
      strokeLinejoin="round"
    />
    <path
      d="M62.5398 12.918L66.1633 26.4409C66.735 28.5748 68.9284 29.8411 71.0622 29.2694L84.5852 25.6459"
      stroke="#5E79FF"
      strokeWidth="4"
      strokeLinejoin="round"
    />
    <path
      d="M43 6C43 4.89543 43.8954 4 45 4H94.3431C94.8736 4 95.3823 4.21071 95.7574 4.58579L113.414 22.2426C113.789 22.6177 114 23.1264 114 23.6569V98C114 99.1046 113.105 100 112 100H45C43.8954 100 43 99.1046 43 98V6Z"
      fill="white"
      stroke="#5E79FF"
      strokeWidth="4"
      strokeLinejoin="round"
    />
    <path
      d="M94 4V22C94 23.1046 94.8954 24 96 24H114"
      stroke="#5E79FF"
      strokeWidth="4"
      strokeLinejoin="round"
    />
    <path
      d="M72.9679 43.4453C72.953 43.4677 72.9389 43.4908 72.9258 43.5144L71.9213 45.3225C71.6484 45.8137 71.0245 45.9837 70.5402 45.6987L67.189 43.7274C66.7212 43.4523 66.5583 42.8543 66.8218 42.3799L67.8742 40.4856C69.695 37.2082 73.3573 35 77.4 35C83.6477 35 88.4 39.7523 88.4 46C88.4 48.7737 87.5771 50.5467 86.5296 51.8124C85.5697 52.9724 84.4025 53.7411 83.2792 54.481C83.1403 54.5725 83.0021 54.6635 82.865 54.7548C81.8772 55.3487 81.2166 55.8363 80.8363 56.3688C80.3985 56.9817 80.3994 57.5618 80.4 57.9574C80.4 57.9719 80.4 57.9861 80.4 58V60C80.4 60.5523 79.9523 61 79.4 61H75.4C74.8477 61 74.4 60.5523 74.4 60V58C74.4 53.3497 77.1907 51.2096 79.5351 49.6452C80.4955 49.0684 81.2338 48.5942 81.7026 48.0585C82.2661 47.4145 82.4 46.7452 82.4 46C82.4 44.5728 81.9199 43.3057 81.0071 42.3929C80.0943 41.4801 78.8272 41 77.4 41C75.4121 41 73.9216 42.0148 72.9679 43.4453ZM77.4 73C76.4272 73 75.6943 72.6801 75.2071 72.1929C74.7199 71.7057 74.4 70.9728 74.4 70C74.4 69.0272 74.7199 68.2943 75.2071 67.8071C75.6943 67.3199 76.4272 67 77.4 67C78.3728 67 79.1057 67.3199 79.5929 67.8071C80.0801 68.2943 80.4 69.0272 80.4 70C80.4 70.9728 80.0801 71.7057 79.5929 72.1929C79.1057 72.6801 78.3728 73 77.4 73Z"
      stroke="#5E79FF"
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
);
