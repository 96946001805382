import $ from 'jquery';
import Routing from 'routing';
import E1Request from '../classes/E1Request';

$(() => {
  function callArchiveStage(id, route, callback) {
    const req = new E1Request(Routing.generate(`app_stagearchive_${route}`, { id }), 'POST');
    req.extraCallback = callback;
    req.submit();
  }

  const $body = $('body');

  $body.on('click', '.unarchive-stage-dropdown', function (e) {
    const id = $(this).attr('data-stage-id');
    callArchiveStage(id, 'remove', () => {
      $(document).trigger('stage-archive-changed');
    });
  });

  $body.on('click', '.archive-project-button', (e) => {
    $('#archive-project-form').submit();
  });

  $body.on('click', '.unarchive-project-button', (e) => {
    $('#unarchive-project-form').submit();
  });
});
