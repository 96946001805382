import { useMutation } from '@apollo/client';
import { useGetCurrentLiteLicenseDetails } from './useGetCurrentLiteLicenseDetails';
import { useLiteToEssentialAnalytics } from './useLiteToEssentialAnalytics';
import { UPGRADE_LITE_TO_ESSENTIAL } from '../mutations';
import { BillingCycle as IntegerBillingCycle } from '@ascension/enums';
import {
  UpgradeLiteToEssential,
  UpgradeLiteToEssentialVariables,
} from '../types/UpgradeLiteToEssential';
import { BillingCycle } from '@ascension/_gqltypes/subbie.generated';

export const usePaidToPaidUpgrade = () => {
  const currentLicenseDetails = useGetCurrentLiteLicenseDetails();
  const { triggerLiteToEssentialPayButtonClicked } = useLiteToEssentialAnalytics();

  const [upgradeLiteToEssential, { data: upgradeData, loading }] = useMutation<
    UpgradeLiteToEssential,
    UpgradeLiteToEssentialVariables
  >(UPGRADE_LITE_TO_ESSENTIAL, { refetchQueries: ['NoticeboardProjects'] });

  const submit = async () => {
    if (!currentLicenseDetails?.billingCycle) {
      throw new Error('Billing cycle is not defined');
    }

    const { billingCycle } = currentLicenseDetails;
    const integerBillingCycle =
      billingCycle === BillingCycle.ONE_MONTH
        ? IntegerBillingCycle.Months1
        : IntegerBillingCycle.Months12;

    triggerLiteToEssentialPayButtonClicked(integerBillingCycle);

    const { data: response } = await upgradeLiteToEssential({
      variables: { input: { billingCycle } },
    });

    if (response?.upgradeLiteToEssential?.__typename === 'Errors') {
      return { errors: response.upgradeLiteToEssential.errors, invoiceUrl: null };
    }

    return {
      errors: null,
      invoiceUrl: response?.upgradeLiteToEssential?.to?.invoiceUrl,
    };
  };

  return { submit, upgradeData, loading };
};
