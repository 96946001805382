import React from 'react';
import { Button, ButtonSize, ButtonVariant } from '@estimateone/frontend-components';
import { ID } from '@builder/features/SubbieNetwork/components/SubbieNetworkSearchResults/hooks/utils';

export type SubbieNetworkInviteToQuoteButtonProps = {
  companyId: ID;
};

export const SubbieNetworkInviteToQuoteButton = ({
  companyId,
}: SubbieNetworkInviteToQuoteButtonProps) => (
  <Button
    className="invite-single-addressbook"
    variant={ButtonVariant.Secondary}
    size={ButtonSize.Small}
    data-company-id={companyId}
    data-status="1"
    // Click event captured in vcard_slider.js by hooking into the .invite-single-addressbook class
    onClick={() => {}}
  >
    Invite to Quote
  </Button>
);
