import { ProductId } from '@ascension/enums';
import { EntityId } from '@ascension/types';

export const getDropdownDisplayNameFromProductName = (productName: string) => {
  if (productName.includes('State License')) {
    const split = productName.split('-');
    return split[split.length - 1].trim();
  }

  if (
    productName.includes('Professional Plus Bundle') ||
    productName.includes('Professional Bundle')
  ) {
    const split = productName.split('-');
    return split[split.length - 1].trim();
  }

  if (productName === 'Professional Plus') {
    return 'National';
  }

  if (productName === 'National License') {
    return 'National';
  }

  return productName;
};

export const getFeatureDisplayNameFromProductName = (productName: string) => {
  const allStatesProducts = [
    'National License',
    'Professional Bundle - National',
    'Professional Plus Bundle - National',
  ];
  if (allStatesProducts.some((p) => p === productName)) {
    return 'All states';
  }

  if (
    productName.includes('State License') ||
    productName.includes('Professional Bundle') ||
    productName.includes('Professional Plus Bundle')
  ) {
    const split = productName.split('-');
    return split[split.length - 1].trim();
  }

  return productName;
};

export const getDisplayNameForSubbieDirectoryListingFeature = (productId: EntityId) => {
  const currentlyAvailableStates = [
    ProductId.National,
    ProductId.VicTas,
    ProductId.NswAct,
    ProductId.QldNt,
    ProductId.SaNt,
    ProductId.WaNt,
  ];

  return currentlyAvailableStates.includes(productId) ? true : 'Coming soon';
};

export const getSubbieSupplierConnectionLimitFeature = (productId: number) => {
  const has5CreditLimit = [
    ProductId.ProfessionalVicTas,
    ProductId.ProfessionalNswAct,
    ProductId.ProfessionalQldNt,
    ProductId.ProfessionalSaNt,
    ProductId.ProfessionalWaNt,
  ];
  const has15CreditLimit = [ProductId.ProfessionalNational];
  const has30CreditLimit = [
    ProductId.ProfessionalPlusWaNt,
    ProductId.ProfessionalPlusVicTas,
    ProductId.ProfessionalPlusNswAct,
    ProductId.ProfessionalPlusQldNt,
    ProductId.ProfessionalPlusSaNt,
  ];
  const has50CreditLimit = [
    ProductId.ProfessionalPlusNational,
    ProductId.ProfessionalPlusNationalLegacy,
  ];

  const creditLimit = (sscLimit: number) => `${sscLimit} credit/month`;

  if (has5CreditLimit.includes(productId)) {
    return creditLimit(5);
  }
  if (has15CreditLimit.includes(productId)) {
    return creditLimit(15);
  }
  if (has30CreditLimit.includes(productId)) {
    return creditLimit(30);
  }
  if (has50CreditLimit.includes(productId)) {
    return creditLimit(50);
  }

  return false;
};
