import { useCallback } from 'react';
import { gql, Reference, useMutation } from '@apollo/client';
import Routing from 'routing';
import { E1Link, Modal, ModalSize, Button, ButtonVariant } from '@estimateone/frontend-components';
import { captureException } from '@sentry/browser';
import E1Request from '@ascension/js/classes/E1Request';
import { useKnowledgeBaseArticle } from '@ascension/components/localisation';
import { CompanyHeading, CompanyCard } from '../../common/CompanyCardWithHeading';
import { transformOffice } from './utils';
import { useFlashMessage } from '@shared/Util';
import { useCloseSyncableCompanyModal, useSyncableCompanyModalContext } from './hooks';
import { Action, useInteractAnalytics } from '@ascension/components/hooks/Analytics';
import { useGetMatchingProfiles } from '@builder/pages/Profiles/UniversalProfileDrawer/hooks/useGetMatchingProfiles';
import {
  AddressBookCompanyResultFragment,
  ContactsFragment,
  NewProfileResultFragment,
} from '@subbie-directory/queries.generated';
import { CONFIRM_CONNECTION_SUGGESTION } from '../ConnectionSuggestions/mutations';
import {
  ConfirmConnectionSuggestion,
  ConfirmConnectionSuggestionVariables,
} from '../ConnectionSuggestions/types/ConfirmConnectionSuggestion';
import { ModalTrigger } from './types';
import { EntityId } from '@ascension/types';
import styles from './styles.module.scss';

type SyncCompanyModalProps = {
  onSyncSuccess?: (ids: { connectedCompanyId: EntityId; connectedProfileId: string }) => void;
};

const addFromProfileRequest = (networkCompanyId: string) => {
  const route = Routing.generate('app_addressbookcompany_addfrome1profile', {
    profileId: networkCompanyId,
  });

  // E1Request will show the success flash message
  // Set E1Request `passive` param to true to handle error flash messages ourselves
  return new E1Request<{ success: boolean; companyId: EntityId }>(
    route,
    'POST',
    undefined,
    true,
  ).submit();
};

const getEmail = (highlights: { key: string; value: string[] }[]): string | null => {
  // @TODO update the key to match something not linked to opensearch
  const email = highlights.find((highlight) => highlight.key === 'USER_EMAIL');

  return email?.value[0] ?? null;
};

type Contact = Pick<ContactsFragment, 'email' | 'fullName'>;
const matchContact = (
  contacts: Contact[],
  highlightsEmail: string | null,
): { email: string; fullName: string } | null => {
  if (!contacts.length || !highlightsEmail) return null;
  const foundContact = contacts.find((contact) => contact.email === highlightsEmail);

  if (typeof foundContact?.email !== 'string' || typeof foundContact?.fullName !== 'string') {
    return null;
  }

  return {
    email: foundContact.email,
    fullName: foundContact.fullName,
  };
};

const isNewProfileResult = (result: unknown): result is NewProfileResultFragment =>
  (result as NewProfileResultFragment)?.__typename === 'NewProfileResult';

const getDefaultOffice = <T extends { isDefault: boolean }>(offices: T[]): T | null => {
  if (!offices?.length) return null;
  if (offices.length === 1) return offices[0];

  return offices.find((office) => office.isDefault) ?? null;
};

const getIds = (entity: NewProfileResultFragment | AddressBookCompanyResultFragment) => {
  if (!isNewProfileResult(entity)) {
    return { companyId: entity.id };
  }

  return entity.accountId
    ? { profileId: entity.id, accountId: entity.accountId }
    : { profileId: entity.id };
};

export const SyncCompanyModal = ({ onSyncSuccess }: SyncCompanyModalProps) => {
  const { warning: showErrorMessage } = useFlashMessage();

  const { open, data, modalTriggeredBy } = useSyncableCompanyModalContext();

  const { closeModal } = useCloseSyncableCompanyModal();

  const { searched, matchingProfiles, highlights } = useGetMatchingProfiles({
    profileId:
      modalTriggeredBy === ModalTrigger.E1_NETWORK_COMPANY ? data?.searchedCompanyId : undefined,
    // SKIP if this is undefined
    companyDetailsLoading: data?.searchedCompanyId === undefined,
    companyId:
      modalTriggeredBy === ModalTrigger.COMPANY ? Number(data?.searchedCompanyId) : undefined,
    // shadow profile can't open this modal anyway
    isShadowProfile: false,
  });

  const { addEvent } = useInteractAnalytics();

  const knowledgeBaseArticle = useKnowledgeBaseArticle();

  const [connectProfile] = useMutation<
    ConfirmConnectionSuggestion,
    ConfirmConnectionSuggestionVariables
  >(CONFIRM_CONNECTION_SUGGESTION, {
    update(cache, { data: result }, { variables }) {
      // Satisfy TS. This error will be handled in the click handler
      if (!result || (result && result.confirmConnectionSuggestion.__typename === 'Errors')) return;

      // Ssatisfy TS. These variables will exist as the input type and fields are all non-null
      if (!variables?.input.accountProfileId || !variables?.input.addressBookCompanyId) return;

      const { accountProfileId, addressBookCompanyId } = variables.input;

      const connectedNetworkCompany =
        result.confirmConnectionSuggestion.__typename === 'ConnectionSuggestion' &&
        result.confirmConnectionSuggestion.connectedNetworkCompany;

      cache.modify({
        fields: {
          searchNetworkCompanies(fields, { readField }) {
            // `results` holds the results of the query on `searchNetworkCompanies`.
            const { results } = fields;

            // Looks suspicious but this is the way Apollo suggests to obtain
            // a reference for a value already in the cache as the result of a mutation
            const connectedRef = cache.writeFragment({
              data: connectedNetworkCompany,
              fragment: gql`
                fragment NewlyConnected on ConnectedNetworkCompany {
                  __typename
                }
              `,
            });

            // Locate the profile the connection was initiated from and swap
            // it for the `ConnectedNetworkCompany` we received from the `connectProfile` mutation
            // which will cause the re-render and show the "Invite to quote" button
            const updatedResults = results.map((ref: Reference) => {
              // Apollo stores objects in normalised form which means the items in `results`
              // are referenecs not the actual data. In order to read the id of any item in `results`
              // we use the `readField` which looks up a field for a given reference (here 'id' and 'ref')
              const profileId = readField('id', ref);

              // Once we find the profile which was just connected, we simply swap out the ref for
              // the one returned from our `writeFragment` call above.
              return profileId === variables.input.accountProfileId ? connectedRef : ref;
            });

            // The results list now includes our newly connected profile and address book entry (represented as
            // a ConnectedNetworkCompany). Any queries on the `searchNetworkCompanies` will be notified and re-rendered
            return {
              ...fields,
              results: updatedResults,
            };
          },
        },
      });

      cache.evict({ id: `BuilderNetworkCompany:${addressBookCompanyId}` });
      cache.evict({ id: `E1NetworkCompany:${accountProfileId}` });
    },
  });

  const createConnectedCompany = useCallback(
    async (networkCompanyId: string) => {
      try {
        const result = await addFromProfileRequest(networkCompanyId);

        if (!result.success) {
          throw new Error('failed to create new connected company');
        }

        if (onSyncSuccess) {
          onSyncSuccess({
            connectedCompanyId: result.companyId,
            connectedProfileId: networkCompanyId,
          });
        }
        closeModal();
      } catch (error) {
        captureException(error);
        showErrorMessage({
          title: 'Error',
          message: 'Something went wrong when trying to Connect. Please try again.',
        });
      }
    },
    [closeModal, onSyncSuccess, showErrorMessage],
  );

  const connectCompanyToNetworkCompany = async (
    addressBookCompanyId: string,
    networkCompanyId: string,
  ) => {
    const { data: connectionResult, errors } = await connectProfile({
      variables: {
        input: { accountProfileId: networkCompanyId, addressBookCompanyId, snippets: [] },
      },
    });

    if (errors || !connectionResult)
      throw new Error(
        'Unexpected error occurred when attempting to connect address book to profile',
      );

    if (connectionResult.confirmConnectionSuggestion.__typename === 'Errors') {
      const error = connectionResult.confirmConnectionSuggestion.errors.at(0);
      const title = `Error with field: ${error?.field}`;
      const message = error?.message;
      return showErrorMessage({ title, message });
    }

    if (onSyncSuccess)
      onSyncSuccess({
        connectedCompanyId: Number(addressBookCompanyId),
        connectedProfileId: networkCompanyId,
      });

    return closeModal();
  };

  if (!open || !searched || !highlights || matchingProfiles?.length !== 1) return null;

  const matchingEmail = getEmail(highlights);
  const searchedCompanyMatchingContact = matchContact(searched.contacts, matchingEmail);
  const searchedCompanyOffice = transformOffice(getDefaultOffice(searched.offices));
  const searchedIds = getIds(searched);
  const matchingCompany = matchingProfiles[0];
  const matchingCompanyMatchingContact = matchContact(matchingCompany.contacts, matchingEmail);
  const matchingCompanyOffice = transformOffice(getDefaultOffice(matchingCompany.offices));
  const matchedIds = getIds(matchingCompany);

  const sendAnalytics = (action: Action) =>
    addEvent({
      action,
      searchedProfileId: searchedIds.profileId,
      searchedAccountId: searchedIds.accountId,
      searchedCompanyId: searchedIds.companyId,
      matchedProfileId: matchedIds.profileId,
      matchedCompanyId: matchedIds.companyId,
      matchedAccountId: matchedIds.accountId,
    });

  sendAnalytics(Action.SUBBIE_MATCHING_PROFILE_VIEWED);

  const supportConnectingCompanyArticle = knowledgeBaseArticle('matchingNetworkCompanyHelp');

  return (
    <Modal isOpen={open} onRequestClose={closeModal} size={ModalSize.Small}>
      {modalTriggeredBy === ModalTrigger.COMPANY ? (
        <Modal.Section>
          <div className={styles.modalSection}>
            <div className={styles.modalHeader}>Review and confirm</div>
            <div className={styles.textContainer}>
              <p>Matching E1 Network company found.</p>
              <p>
                Create a more powerful Address Book entry by connecting to the E1 Network. Review
                the match and select Connect. Otherwise, Cancel.
              </p>
              <E1Link
                className={styles.learnMoreLink}
                href={supportConnectingCompanyArticle}
                target="_blank"
                rel="noreferrer"
              >
                Learn more about connecting with the E1 Network
              </E1Link>
            </div>
            <div className={styles.matchingCompaniesContainer}>
              <CompanyHeading headingName="Address Book Company" />
              <CompanyCard
                companyName={searched.name}
                office={searchedCompanyOffice}
                contact={searchedCompanyMatchingContact}
              />
              <CompanyHeading headingName="E1 Network Company" />
              <CompanyCard
                companyName={matchingCompany.name}
                office={matchingCompanyOffice}
                contact={matchingCompanyMatchingContact}
                showNotConnectedIcon
              />
            </div>
            <div className={styles.connectedProfileButtons}>
              <Button variant={ButtonVariant.Grey} onClick={closeModal} fullWidth>
                Cancel
              </Button>
              <Button
                variant={ButtonVariant.Primary}
                onClick={() => {
                  sendAnalytics(Action.SUBBIE_MATCHING_PROFILE_ACCEPTED);
                  connectCompanyToNetworkCompany(data.searchedCompanyId, matchingCompany.id).catch(
                    captureException,
                  );
                }}
                fullWidth
              >
                Connect
              </Button>
            </div>
          </div>
        </Modal.Section>
      ) : (
        <Modal.Section>
          <div className={styles.modalSection}>
            <div className={styles.modalHeader}>Review and confirm</div>
            <div className={styles.textContainer}>
              <p>Matching company found in your Address Book.</p>
              <p>
                To avoid creating a duplicate company, review the match and select Confirm
                Connection. Otherwise, select Create New Connection.
              </p>
              <E1Link
                className={styles.learnMoreLink}
                href={supportConnectingCompanyArticle}
                target="_blank"
                rel="noreferrer"
              >
                Learn more about connecting with the E1 Network
              </E1Link>
            </div>
            <div className={styles.matchingCompaniesContainer}>
              <CompanyHeading headingName="E1 Network Company" />
              <CompanyCard
                companyName={searched.name}
                office={searchedCompanyOffice}
                contact={searchedCompanyMatchingContact}
                showNotConnectedIcon
              />
              <CompanyHeading headingName="Address Book Company" />
              <CompanyCard
                companyName={matchingCompany.name}
                office={matchingCompanyOffice}
                contact={matchingCompanyMatchingContact}
              />
            </div>
            <div className={styles.buttonContainer}>
              <Button
                variant={ButtonVariant.Primary}
                onClick={() => {
                  sendAnalytics(Action.SUBBIE_MATCHING_PROFILE_ACCEPTED);
                  connectCompanyToNetworkCompany(matchingCompany.id, data.searchedCompanyId).catch(
                    captureException,
                  );
                }}
                fullWidth
              >
                Confirm Connection
              </Button>
              <div className={styles.textOr}>Or</div>
              <Button
                variant={ButtonVariant.Secondary}
                onClick={() => {
                  sendAnalytics(Action.SUBBIE_MATCHING_PROFILE_NEW_CONNECTION);
                  createConnectedCompany(data.searchedCompanyId).catch(captureException);
                }}
                fullWidth
              >
                Create New Connection
              </Button>
              <Button variant={ButtonVariant.Grey} onClick={closeModal} fullWidth>
                Cancel
              </Button>
            </div>
          </div>
        </Modal.Section>
      )}
    </Modal>
  );
};
