import { Tooltip } from '@estimateone/frontend-components';
import styles from './styles.scss';

export type TruncateProps = {
  tooltipId: string;
  text: string;
  className?: string;
};

export const Truncate = ({ tooltipId, text, className }: TruncateProps) => (
  <Tooltip tooltipId={tooltipId} text={text} className={className}>
    <p className={styles.truncate}>{text}</p>
  </Tooltip>
);
