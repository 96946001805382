import { Context, createContext, useContext } from 'react';
import { EntityId } from '@ascension/types';

export enum ModalName {
  Project = 'project',
  ChooseSubTrades = 'choose_sub_trades',
}

export type ModalContextProps<T extends Record<string, unknown> = Record<string, unknown>> = {
  name: string | null;
  data: T;
  addModal: (name: string, data: Record<string, unknown>) => void;
  removeModal: () => void;
};

const defaultValue: ModalContextProps = {
  name: null,
  data: {},
  addModal: () => {
    /* noop */
  },
  removeModal: () => {
    /* noop */
  },
};
const ModalContext = createContext<ModalContextProps>(defaultValue);

export const useModalContext = <T extends Record<string, unknown>>(): ModalContextProps<T> =>
  useContext<ModalContextProps<T>>(ModalContext as Context<ModalContextProps<T>>);

export const useModal = <T extends Record<string, unknown>>(
  name: string,
  data?: T,
): (() => void) => {
  const { addModal } = useModalContext<T>();

  return () => addModal(name, data || {});
};

export type ProjectSliderContext = { readonly projectId: EntityId };

export const useProjectSlider = (): ((projectId: number) => void) => {
  const { addModal } = useModalContext<ProjectSliderContext>();

  return (projectId: number) => {
    addModal(ModalName.Project, { projectId });
  };
};

export default ModalContext;
