import { ContractSizeEnum } from '@ascension/_gqltypes/profiles.generated';

export const ContractSizeMinEnumMap = {
  [ContractSizeEnum.SIZE_10K]: 10000,
  [ContractSizeEnum.SIZE_50K]: 50000,
  [ContractSizeEnum.SIZE_100K]: 100000,
  [ContractSizeEnum.SIZE_250K]: 250000,
  [ContractSizeEnum.SIZE_500K]: 500000,
  [ContractSizeEnum.SIZE_1M]: 1000000,
  [ContractSizeEnum.SIZE_5M]: 5000000,
  [ContractSizeEnum.SIZE_10M]: 10000000,
  [ContractSizeEnum.SIZE_25M]: 25000000,
  [ContractSizeEnum.SIZE_50M_PLUS]: 50000000,
};

export const ContractSizeMaxEnumMap = {
  [ContractSizeEnum.SIZE_10K]: 10000,
  [ContractSizeEnum.SIZE_50K]: 50000,
  [ContractSizeEnum.SIZE_100K]: 100000,
  [ContractSizeEnum.SIZE_250K]: 250000,
  [ContractSizeEnum.SIZE_500K]: 500000,
  [ContractSizeEnum.SIZE_1M]: 1000000,
  [ContractSizeEnum.SIZE_5M]: 5000000,
  [ContractSizeEnum.SIZE_10M]: 10000000,
  [ContractSizeEnum.SIZE_25M]: 25000000,
  [ContractSizeEnum.SIZE_50M_PLUS]: 50000000,
};
