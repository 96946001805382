import Routing from 'routing';
import { Alert, AlertVariant, E1Link, WarningIcon } from '@estimateone/frontend-components';
import { getStageTypeAsString, StageType } from '@ascension/enums';
import { EntityId } from '@ascension/types';
import styles from './styles.module.scss';

export type DocumentSetupBannerProps = {
  stageId: EntityId;
  stageType: StageType;
  onDismiss: () => void;
};

export const DocumentSetupBanner = ({
  stageId,
  stageType,
  onDismiss,
}: DocumentSetupBannerProps) => (
  <div className="flex-grow-1">
    <Alert variant={AlertVariant.Blue} onDismiss={onDismiss}>
      <div className={styles.setupBannerContent}>
        <WarningIcon />{' '}
        <div className={styles.setupBannerText}>
          This project has no packages.{' '}
          <E1Link
            href={Routing.generate('app_stage_matrix', {
              id: stageId,
              editable: true,
              stageType: getStageTypeAsString(stageType)?.toLocaleLowerCase(),
            })}
          >
            Create packages in the Document Matrix.
          </E1Link>
        </div>
      </div>
    </Alert>
  </div>
);
