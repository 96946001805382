import { useField, useForm } from 'react-final-form';
import { packageToOption } from './utils';
import { LabelledOption, comparePackageTitlesAlphaFullSetFirst } from '@shared';
import { SingleSelectField } from '@shared/FinalForm/Field';
import { CompleteQuoteFormSpecificFieldName } from '../enums';
import { CompleteQuoteFormValues, PackageOptionValue, PackageValue } from '../types';
import { ActivePackage } from './types';

export type PackageSelectorFieldProps = {
  packages: ActivePackage[];
};

const PackageSelectorField = ({ packages }: PackageSelectorFieldProps) => {
  const { change: updateFieldValue } = useForm<CompleteQuoteFormValues>();

  const {
    input: {
      value: { id: packageId },
      onChange,
      ...packageInputProps
    },
  } = useField<PackageValue>(CompleteQuoteFormSpecificFieldName.Package);

  const sortedPackages = [...packages].sort((a, b) =>
    comparePackageTitlesAlphaFullSetFirst(a.title, b.title),
  );

  const handleChange = (selectedOption: LabelledOption<PackageOptionValue>) => {
    onChange(selectedOption);

    const { value: id, label } = selectedOption;
    updateFieldValue(CompleteQuoteFormSpecificFieldName.Package, { id, name: label });
  };

  return (
    <SingleSelectField<PackageOptionValue>
      fieldName={CompleteQuoteFormSpecificFieldName.Package}
      id="quote-package"
      label="Package"
      value={packageId}
      placeholder="Select package..."
      options={sortedPackages.map(packageToOption)}
      onChange={({ selectedOption }) => (selectedOption ? handleChange(selectedOption) : null)}
      {...packageInputProps}
    />
  );
};

export default PackageSelectorField;
