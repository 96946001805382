/* eslint-disable react/no-unstable-nested-components */
import { ReactNode, useMemo, useState } from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { useGetDownloadedPackages } from '../RevisionFlow/hooks/useGetDownloadedPackages';
import { useGetStageSettings } from '@builder/features/StageSettings/hooks';
import { Addendum } from '../RevisionFlow/types';
import {
  GetDownloadedPackages_downloadedPackages as DownloadedPackage,
  GetDownloadedPackages,
} from '../RevisionFlow/types/GetDownloadedPackages';
import { EntityId, InterfaceToType } from '@ascension/types';

export type NotifyDownloadedPackage = InterfaceToType<DownloadedPackage>;
export type NotifyPreferences = {
  notifyDownloaded: boolean;
  notifyNotDownloadedResponded: boolean;
  notifyNotDownloadedNoResponse: boolean;
};

export type SubmitHandlerArgs = {
  title: string;
  message: string;
  selectedPackages: EntityId[];
  notifyPreferences: NotifyPreferences;
};
export type NotifyFormSubmitHandler = (args: SubmitHandlerArgs) => Promise<void>;

export enum NotifyMessageFieldName {
  Title = 'Title',
  Message = 'Message',
}

type NotifyMessageFieldValues = {
  [NotifyMessageFieldName.Title]: string;
  [NotifyMessageFieldName.Message]: string;
};

type Set<T> = (value: T) => void;
type NotifyFormChildrenProps = {
  title: string;
  setTitle: Set<string>;
  message: string;
  setMessage: Set<string>;
  notifyPreferences: NotifyPreferences;
  setNotifyPreferences: Set<NotifyPreferences>;
  downloadedPackages: GetDownloadedPackages | undefined;
  isLoadingDownloadedPackages: boolean;
  filteredPackages: DownloadedPackage[];
  stageSettingNotifyAwarded: boolean | undefined;
  notificationCount: number;
  selectedPackages: EntityId[];
  setSelectedPackages: (ids: EntityId[]) => void;
  submissionTried: boolean;
  sendingNotifications: boolean;
  totalInvites: number | undefined;
} & FormRenderProps<NotifyMessageFieldValues, Partial<NotifyMessageFieldValues>>;

const isEmpty = (value: unknown) => typeof value !== 'string' || value.trim().length === 0;

export type NotifyFormProps = {
  stageId: EntityId;
  addendum?: Addendum;
  children: (props: NotifyFormChildrenProps) => ReactNode;
  onSubmit: (args: SubmitHandlerArgs) => Promise<void>;
};

export const NotifyForm = ({ stageId, addendum, children, onSubmit }: NotifyFormProps) => {
  const [selectedPackages, setSelectedPackages] = useState<EntityId[]>([]);
  const [notifyPreferences, setNotifyPreferences] = useState<NotifyPreferences>({
    notifyDownloaded: true,
    notifyNotDownloadedNoResponse: false,
    notifyNotDownloadedResponded: false,
  });
  const [title, setTitle] = useState<string>(addendum?.name ?? 'Notification of Revisions');
  const [message, setMessage] = useState<string>(addendum?.description ?? '');
  const [submissionTried, setSubmissionTried] = useState(false);
  const [sendingNotifications, setSendingNotifications] = useState<boolean>(false);

  const { data: downloadedPackages, loading: isLoadingDownloadedPackages } =
    useGetDownloadedPackages(stageId, addendum?.id);
  const { stageSettingNotifyAwarded } = useGetStageSettings(stageId);
  const filteredPackages = useMemo(() => {
    if (downloadedPackages?.downloadedPackages) {
      const packages = stageSettingNotifyAwarded
        ? downloadedPackages.downloadedPackages
        : downloadedPackages.downloadedPackages.filter((p) => !p.awardedAt);
      setSelectedPackages(packages.map((dp) => dp.id));
      return packages;
    }

    return [];
  }, [downloadedPackages, stageSettingNotifyAwarded]);

  const notificationCount = useMemo(() => {
    /* eslint-disable fp/no-mutation */
    /* eslint-disable no-param-reassign */
    const count = filteredPackages.reduce((counter, downloadedPackage) => {
      if (selectedPackages.includes(downloadedPackage.id)) {
        if (notifyPreferences.notifyDownloaded) {
          counter += downloadedPackage.numToBeNotified;
        }
        if (notifyPreferences.notifyNotDownloadedNoResponse && !downloadedPackage.awardedAt) {
          counter += downloadedPackage.rfqNotDownloadedNoResponse;
        }
        if (notifyPreferences.notifyNotDownloadedResponded && !downloadedPackage.awardedAt) {
          counter += downloadedPackage.rfqNotDownloadedResponded;
        }
      }
      return counter;
    }, 0);

    return count;
  }, [
    filteredPackages,
    notifyPreferences.notifyDownloaded,
    notifyPreferences.notifyNotDownloadedNoResponse,
    notifyPreferences.notifyNotDownloadedResponded,
    selectedPackages,
  ]);

  const initialValues: NotifyMessageFieldValues = useMemo(
    () => ({
      [NotifyMessageFieldName.Title]: title,
      [NotifyMessageFieldName.Message]: message,
    }),
    [title, message],
  );

  const totalInvites: number | undefined = downloadedPackages?.downloadedPackages.reduce(
    (count, dp) =>
      count + dp.numToBeNotified + dp.rfqNotDownloadedResponded + dp.rfqNotDownloadedNoResponse,
    0,
  );

  return (
    <Form<NotifyMessageFieldValues>
      onSubmit={() => {
        setSubmissionTried(true);
        const hasErrors = isEmpty(title) || isEmpty(message);
        const canSubmit = !hasErrors || notificationCount === 0;
        if (canSubmit) {
          setSendingNotifications(true);
          return onSubmit({
            title,
            message,
            selectedPackages,
            notifyPreferences,
          });
        }
        return undefined;
      }}
      initialValues={initialValues}
    >
      {(formChildrenProps) => {
        const childrenProps: NotifyFormChildrenProps = {
          title,
          setTitle,
          message,
          setMessage,
          notifyPreferences,
          setNotifyPreferences,
          downloadedPackages,
          filteredPackages,
          stageSettingNotifyAwarded,
          isLoadingDownloadedPackages,
          notificationCount,
          selectedPackages,
          setSelectedPackages,
          submissionTried,
          sendingNotifications,
          totalInvites,
          ...formChildrenProps,
        };

        return children(childrenProps);
      }}
    </Form>
  );
};
