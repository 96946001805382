import { gql } from '@apollo/client';

export const CREATE_SYNCED_PROFILE_UPDATE_REQUEST = gql`
  mutation CreateSyncedProfileUpdateRequest($addressBookCompanyId: EntityId!) {
    createSyncedProfileUpdateRequest(addressBookCompanyId: $addressBookCompanyId) {
      id
      fromAccount {
        id
        name
      }
      toAccountProfile {
        id
        name
      }
      createdAt
      __typename
    }
  }
`;

export const CREATE_NETWORK_PROFILE_UPDATE_REQUEST = gql`
  mutation CreateNetworkProfileUpdateRequest($accountProfileId: EntityId!) {
    createNetworkProfileUpdateRequest(accountProfileId: $accountProfileId) {
      id
      fromAccount {
        id
        name
      }
      toAccountProfile {
        id
        name
      }
      createdAt
      __typename
    }
  }
`;
