import { ReactNode } from 'react';
import { Button, ButtonGroup, ButtonVariant } from '@estimateone/frontend-components';
import { PaymentMethod } from '@ascension/enums';

const ToggleButton = ({
  children,
  isActive,
  ...buttonProps
}: {
  children: ReactNode;
  isActive: boolean;
  onClick: () => void;
}) => (
  <Button variant={isActive ? ButtonVariant.Secondary : ButtonVariant.Grey} {...buttonProps}>
    {children}
  </Button>
);

const PaymentMethodSelector = ({
  paymentMethod,
  onSelection,
}: {
  paymentMethod: PaymentMethod;
  onSelection: (method: PaymentMethod) => void;
}) => (
  <ButtonGroup fullWidth>
    <ToggleButton
      isActive={paymentMethod === PaymentMethod.CC}
      onClick={() => onSelection(PaymentMethod.CC)}
    >
      Credit Card
    </ToggleButton>
    <ToggleButton
      isActive={paymentMethod === PaymentMethod.EFT}
      onClick={() => onSelection(PaymentMethod.EFT)}
    >
      EFT
    </ToggleButton>
  </ButtonGroup>
);

export default PaymentMethodSelector;
