import {
  getBaseFeatures,
  getProfessionalLicenseFeatures,
  getStandardAndBaseFeatures,
} from './utils';
import {
  useAccountType,
  useFiftyPercentRedactedCheck,
  useProjectPaywallValue,
} from '@subbie/context/AccountProvider/hooks';
import { FeatureDto, MaxSpeciFinderKeywords, PageType } from './types';

const useCommonValuesForFeatures = () => ({
  projectPaywallValue: useProjectPaywallValue(),
  accountType: useAccountType(),
  fiftyPercentRedacted: useFiftyPercentRedactedCheck(),
});

export const useFreeLicenseFeatures = (): FeatureDto[] => {
  const { projectPaywallValue, accountType, fiftyPercentRedacted } = useCommonValuesForFeatures();
  if (accountType === undefined) return [];

  return getBaseFeatures(
    projectPaywallValue,
    accountType,
    fiftyPercentRedacted,
    false,
    MaxSpeciFinderKeywords.Starter,
  );
};

export const useStandardLicenseFeaturesForUpgradeSelectionPage = (): FeatureDto[] => {
  const { projectPaywallValue, accountType, fiftyPercentRedacted } = useCommonValuesForFeatures();
  if (accountType === undefined) return [];

  return getStandardAndBaseFeatures(projectPaywallValue, accountType, fiftyPercentRedacted);
};

export const useStandardLicenseFeaturesForCheckoutPage = (): string[] => {
  const { projectPaywallValue, accountType, fiftyPercentRedacted } = useCommonValuesForFeatures();
  if (accountType === undefined) return [];

  const features = getStandardAndBaseFeatures(
    projectPaywallValue,
    accountType,
    fiftyPercentRedacted,
    PageType.Checkout,
  );
  return features.map(({ text }) => text);
};

export const useProfessionalLicenseFeaturesForUpgradeSelectionPage = (): FeatureDto[] => {
  const { projectPaywallValue, accountType, fiftyPercentRedacted } = useCommonValuesForFeatures();
  if (accountType === undefined) return [];

  return getProfessionalLicenseFeatures(
    projectPaywallValue,
    accountType,
    fiftyPercentRedacted,
    PageType.Selection,
  );
};

export const useProfessionalLicenseFeaturesForCheckoutPage = (): string[] => {
  const { projectPaywallValue, accountType, fiftyPercentRedacted } = useCommonValuesForFeatures();
  if (accountType === undefined) return [];

  const features = getProfessionalLicenseFeatures(
    projectPaywallValue,
    accountType,
    fiftyPercentRedacted,
    PageType.Checkout,
  );
  return features.map(({ text }) => text);
};
