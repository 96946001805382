import { gql } from '@apollo/client';

const CONTACTABLE_FRAGMENT = gql`
  fragment CoreContactableFields on Contactable {
    id
    phone
    email
    web
  }
`;

const PROJECT_HISTORY_FRAGMENT = gql`
  fragment HistoricalProjectFields on HistoricalProject {
    id
    name
    builderName
    state {
      id
      shortName
    }
    region
    category
    contractSize
    completionDate
    deletedAt
    linkedProjectId
    description
  }
`;

const PROFILE_FRAGMENT = gql`
  ${CONTACTABLE_FRAGMENT}
  ${PROJECT_HISTORY_FRAGMENT}
  fragment ProfileFields on Profile {
    id
    createdById
    updatedById
    createdAt
    updatedAt
    lastActiveAt
    memberSince
    name
    workforceSize
    projectCategories
    aboutUs
    contractSizeMin
    contractSizeMax
    completionStatus
    completedPercent
    companyNumber
    isOptedIntoDirectory
    isDiscoverable
    logoUrl
    account {
      id
      primaryCountry {
        id
      }
    }
    offices {
      id
      ascensionId
      name
      isDefaultOffice
      deletedAt
      serviceArea {
        type
        radius
        boundaries {
          id
          type
          name
          shortName
        }
      }
      contactable {
        ...CoreContactableFields
      }
      address {
        id
        address1
        address2
        address3
        fullAddress
        city
        suburb
        province
        state {
          id
          shortName
        }
        postcode
        country {
          id
          name
          shortName
        }
      }
    }
    trades {
      id
      name
      isCoreTrade
    }
    people {
      id
      firstName
      lastName
      fullName
      isDefaultContact
      ascensionId
      isVisible
      contactable {
        id
        phone
        email
        web
      }
    }
    qualifications {
      source
      type
      level
      status
      expiryDate
    }
    projectHistory {
      ...HistoricalProjectFields
    }
  }
`;

export const GET_COMPANY_PROFILE = gql`
  ${PROFILE_FRAGMENT}
  query CompanyProfile($id: ID!) {
    companyProfile: profileById(id: $id) {
      ...ProfileFields
    }
  }
`;

export const GET_COMPANY_PROFILE_BY_ACCOUNT_ID = gql`
  ${PROFILE_FRAGMENT}
  query CompanyProfileByAccountId($id: ID!) {
    companyProfile: profileByAccountId(id: $id) {
      ...ProfileFields
    }
  }
`;
