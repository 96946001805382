import Routing from 'routing';
import { QuoteReturnRoute, StageType, getStageTypeAsString } from '../../../../../enums';
import { EntityId } from '../../../../../types';

export const generateCompleteQuoteLink = (
  stageId: EntityId,
  stageType: StageType,
  quoteId: EntityId,
): string =>
  Routing.generate('app_stage_quote_complete', {
    quoteId,
    id: stageId,
    stageType: getStageTypeAsString(stageType)?.toLowerCase(),
  });

export const generateViewQuoteLink = (quoteId: EntityId): string =>
  Routing.generate('app_quote_view', {
    id: quoteId,
    returnTo: QuoteReturnRoute.BuilderQuotesTable,
  });

export const generateDownloadPackageQuotesLink = (packageId: EntityId): string =>
  Routing.generate('app_quote_package_download', { id: packageId });

export const generateDownloadUncategorisedQuotesLink = (
  stageId: EntityId,
  stageType: StageType,
): string =>
  Routing.generate('app_stage_quotes_download_uncategorised', {
    id: stageId,
    stageType: getStageTypeAsString(stageType)?.toLowerCase(),
  });
