import { gql } from '@apollo/client';
import Errors from '../../../../api/fragments/Errors';

export const CREATE_QUOTE_FROM_RFQ = gql`
  mutation CreateQuoteFromRfq($input: CreateQuoteFromRfqInput!) {
    createQuoteFromRfq(input: $input) {
      __typename
      ... on Quote {
        id
      }
      ... on Errors {
        errors {
          ...ApiError
        }
      }
    }
  }
  ${Errors.fragments.error}
`;
