import { useEffect, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  Button,
  ButtonSize,
  Col,
  CollapsibleSection,
  E1Link,
  joinClassNames,
  Row,
} from '@estimateone/frontend-components';
import { Action, Event, useAnalytics } from '../../../../hooks/Analytics';
import { SUGGEST_CONSULTANT_CORRECTION, SUGGEST_PROJECT_CORRECTION } from './queries';
import { CorrectionType } from './enums';
import {
  SuggestConsultantCorrection,
  SuggestConsultantCorrectionVariables,
} from './types/SuggestConsultantCorrection';
import {
  SuggestProjectCorrection,
  SuggestProjectCorrectionVariables,
} from './types/SuggestProjectCorrection';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

const ThankYouMessage = () => (
  <div className={styles.suggestCorrectionSection}>
    <Row>
      <Col excludeBottomGutter>Thanks - we&apos;ll review and update this information shortly.</Col>
    </Row>
  </div>
);

type SuggestCorrectionSectionProps = { id: EntityId; type: CorrectionType };

const SuggestCorrectionSection = ({ id, type }: SuggestCorrectionSectionProps) => {
  const { addEvent } = useAnalytics(Event.INTERACT);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const isProjectCorrection = type === CorrectionType.Project;

  const [suggestCorrection, { loading: suggestionLoading }] = useMutation<
    SuggestConsultantCorrection | SuggestProjectCorrection,
    SuggestConsultantCorrectionVariables | SuggestProjectCorrectionVariables
  >(isProjectCorrection ? SUGGEST_PROJECT_CORRECTION : SUGGEST_CONSULTANT_CORRECTION);

  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const submitCorrection = async () => {
    if (!textareaRef.current) return;

    await suggestCorrection({ variables: { input: { id, message: textareaRef.current.value } } });
    setIsSubmitted(true);
  };

  const getAction = (): Action => {
    if (isProjectCorrection) {
      return isCollapsed
        ? Action.PROJECT_CONSULTANT_CORRECTION_OPEN
        : Action.PROJECT_CONSULTANT_CORRECTION_CLOSE;
    }
    return isCollapsed
      ? Action.CONSULTANT_DETAILS_CORRECTION_OPEN
      : Action.CONSULTANT_DETAILS_CORRECTION_CLOSE;
  };

  const handleSectionToggle = () => {
    addEvent({
      action: getAction(),
      [isProjectCorrection ? 'projectId' : 'consultantId']: id,
    });
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    if (!isCollapsed && !isSubmitted) {
      const timeout = setTimeout(() => {
        if (textareaRef.current) {
          textareaRef.current.focus();
        }
      }, 100);

      return () => {
        clearTimeout(timeout);
      };
    }

    return undefined;
  }, [isCollapsed, isSubmitted, textareaRef]);

  if (isSubmitted) {
    return <ThankYouMessage />;
  }

  return (
    <div className={styles.suggestCorrectionSection}>
      <Row>
        <Col excludeBottomGutter={isCollapsed}>
          <span>Spot something not quite right?</span>
        </Col>
        <Col
          span="auto"
          alignContentY="center"
          alignContentX={isCollapsed ? 'left' : 'right'}
          excludeBottomGutter={isCollapsed}
        >
          <E1Link onClick={handleSectionToggle}>
            {isCollapsed ? 'suggest correction' : 'Cancel'}
          </E1Link>
        </Col>
      </Row>

      <CollapsibleSection isCollapsed={isCollapsed}>
        <div
          className={joinClassNames(styles.collapsibleContent, isCollapsed && styles.isCollapsed)}
        >
          <Row>
            <Col span={12}>
              <textarea
                id="suggest-correction-textarea"
                ref={textareaRef}
                className={styles.textarea}
                placeholder="Please suggest what's wrong with the consultant information above."
                rows={5}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                size={ButtonSize.Small}
                onClick={submitCorrection}
                isDisabled={suggestionLoading}
              >
                {suggestionLoading ? 'Sending' : 'Submit'}
              </Button>
            </Col>
          </Row>
        </div>
      </CollapsibleSection>
    </div>
  );
};

export default SuggestCorrectionSection;

export { CorrectionType };
