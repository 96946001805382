import { gql } from '@apollo/client';
import { PackageLettingSchedule } from './fragments';

export const SET_PACKAGE_LETTING_SCHEDULE_ASSIGNED_TO_USER = gql`
  mutation SetPackageLettingScheduleAssignedToUser(
    $input: SetPackageLettingScheduleAssignedToUserInput!
  ) {
    setPackageLettingScheduleAssignedToUser(input: $input) {
      ...PackageLettingSchedule
    }
  }
  ${PackageLettingSchedule}
`;

export const UPDATE_LETTING_TIMINGS = gql`
  mutation UpdateLettingTimings($input: UpdateLettingTimingsInput!) {
    updateLettingTimings(input: $input) {
      ... on LettingScheduleDetails {
        id
        rfqsSentByDate
        quotesDueAtInterval
        quotesDueAt
        letByDateInterval
        letByDate
        startOnSiteDateInterval
        startOnSiteDate
      }
      ... on Errors {
        errors {
          field
          message
        }
      }
    }
  }
`;

export const UPDATE_PACKAGE_BUDGET_AMOUNT = gql`
  mutation UpdateBudgetAmount($input: UpdateBudgetAmountInput!) {
    updateBudgetAmount(input: $input) {
      ... on LettingScheduleDetails {
        id
        budgetAmount
      }
      ... on Errors {
        errors {
          field
          message
        }
      }
    }
  }
`;

export const UPDATE_PACKAGE_ACTUAL_AMOUNT = gql`
  mutation UpdateActualAmount($input: UpdateActualAmountInput!) {
    updateActualAmount(input: $input) {
      ... on LettingScheduleDetails {
        id
        actualAmount
      }
      ... on Errors {
        errors {
          field
          message
        }
      }
    }
  }
`;

export const UPDATE_CUSTOM_COLUMN_VALUE = gql`
  mutation UpdateCustomColumnValue($input: UpdateLettingScheduleCustomColumnValueInput!) {
    updateLettingScheduleCustomColumnValue(input: $input) {
      value
    }
  }
`;

export const UPDATE_CUSTOM_COLUMN_NAME = gql`
  mutation UpdateCustomColumnName($input: UpdateLettingScheduleCustomColumnNameInput!) {
    updateLettingScheduleCustomColumnName(input: $input) {
      name
    }
  }
`;

export const SET_LETTING_SCHEDULE_PRIORITY = gql`
  mutation SetLettingSchedulePriority($input: SetLettingSchedulePriorityInput!) {
    setLettingSchedulePriority(input: $input) {
      id
      priority
    }
  }
`;
