import { AddressInputField } from './AddressInputField';
import { CountrySelectOption } from './AddressInputField/fields';
import { ProjectDetailsFields } from './ProjectDetailsFields';
import { Country } from '@ascension/enums';
import styles from './styles.scss';

export const ProjectDetails = ({
  selectedCountry,
  allCountries,
}: {
  selectedCountry: Country;
  allCountries: CountrySelectOption[] | undefined;
}) => (
  <section className={styles.projectDetails}>
    <span className={styles.detailsHeading}>
      <h2>Project Details</h2>
    </span>

    <ProjectDetailsFields />

    <span className={styles.detailsHeading}>
      <h2>Site Details</h2>
    </span>
    <AddressInputField selectedCountry={selectedCountry} allCountries={allCountries} />
  </section>
);
