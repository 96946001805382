import { useQuery } from '@apollo/client';
import { GET_LETTING_COLUMN_STATUS } from '../queries';
import {
  GetLettingColumnStatus,
  GetLettingColumnStatusVariables,
} from '../types/GetLettingColumnStatus';
import { EntityId } from '@ascension/types';

export const useGetLettingColumnStatus = (stageId: EntityId) => {
  const { data, loading } = useQuery<GetLettingColumnStatus, GetLettingColumnStatusVariables>(
    GET_LETTING_COLUMN_STATUS,
    {
      variables: { stageId },
    },
  );

  return { data, loading };
};
