type Error = {
  field: string | null;
  message: string;
};

export type FormFieldErrors = { [key: string]: string[] };

export const extractFieldErrors = (fieldErrors: Error[], fieldNames: string[]) =>
  fieldErrors.reduce((errors: FormFieldErrors, fieldError) => {
    if (fieldError.field === null) {
      return errors;
    }

    if (fieldNames.includes(fieldError.field)) {
      const updatedErrors = errors[fieldError.field]
        ? [...errors[fieldError.field], fieldError.message]
        : [fieldError.message];

      return { ...errors, [fieldError.field]: updatedErrors };
    }

    return errors;
  }, {});
