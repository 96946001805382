import { Col, Row, Heading, Tag, TagVariant, Wrapper } from '@estimateone/frontend-components';
import { LinkSummaryRow, SummaryRow, SimpleSummaryRow } from './SummaryRow';
import { SubbieProfileForAccount_subbieProfileForAccount as SubbieProfile } from '../../../builder/pages/Profiles/SubbieProfilesPage/types/SubbieProfileForAccount';
import styles from './styles.scss';

type SummaryProps = {
  profile: Omit<SubbieProfile, 'defaultOffice'> & {
    defaultOffice: SubbieProfile['defaultOffice'] | null;
  };
};

const Summary = ({
  profile: { name, defaultOffice, trades, businessIdentifier, websiteAddress },
}: SummaryProps) => (
  <div className={styles.summaryContainer}>
    <Wrapper>
      <Row>
        <Col span={12}>
          <Heading level={2}>Summary</Heading>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <SimpleSummaryRow title="Company name" value={name} />

          {websiteAddress && (
            <LinkSummaryRow
              title="Company website"
              link={`//${websiteAddress}`}
              value={websiteAddress}
            />
          )}

          <SimpleSummaryRow title="Head office" value={defaultOffice?.address?.fullAddress} />

          {defaultOffice?.phone && (
            <LinkSummaryRow
              title="Phone"
              link={`tel:${defaultOffice.phone}`}
              value={defaultOffice.phone}
            />
          )}

          <LinkSummaryRow
            title="Email"
            link={defaultOffice?.email ? `mailto:${defaultOffice.email}` : ''}
            value={defaultOffice?.email}
          />

          <SimpleSummaryRow title="ABN" value={businessIdentifier} />

          <SummaryRow title="Trades">
            <div className={styles.tradesList}>
              {trades.map((trade) => (
                <Tag key={trade.id} variant={TagVariant.Practical900} text={trade.name} />
              ))}
            </div>
          </SummaryRow>
        </Col>
      </Row>
    </Wrapper>
  </div>
);

export default Summary;
