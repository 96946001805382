import { Button, ButtonSize, ButtonVariant } from '@estimateone/frontend-components';
import { getAppcues, FlowEvent } from '@ascension/components/helpers/Appcues';
import { useDownloadDocs } from '../hooks';
import { EntityId } from '@ascension/types';

type DownloadDocsButtonProps = {
  packageId: EntityId;
};

const DownloadDocsButton = ({ packageId }: DownloadDocsButtonProps) => {
  const { loading, fetch } = useDownloadDocs(packageId);
  const handleClick = async () => {
    const appcues = getAppcues();
    if (appcues?.track) {
      appcues.track(FlowEvent.DISCOVER_PROJECT_SLIDER_DOWNLOAD_DOCS_CLICK);
    }
    await fetch();
  };

  return (
    <Button
      variant={ButtonVariant.Grey}
      size={ButtonSize.Small}
      onClick={handleClick}
      className="fs-ignore-dead-clicks"
      disabled={loading}
    >
      Download
    </Button>
  );
};

export default DownloadDocsButton;
