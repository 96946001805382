import { KnowledgeBaseLocale } from '@profiles/providers/TranslationsProvider';

export const PROJECT_HISTORY_KNOWLEDGE_BASE_DEFAULT_LINK =
  'https://subcontractor.support.estimateone.com/en/articles/1705366182-your-guide-to-creating-your-company-profile';

export const PROJECT_HISTORY_KNOWLEDGE_BASE_UK_IE_LINK =
  'https://international.support.estimateone.com/en/articles/1707417509-your-guide-to-creating-your-company-profile';

export const TRADES_KNOWLEDGE_BASE_DEFAULT_LINK =
  'https://subcontractor.support.estimateone.com/en/articles/1717578460-trade-selection-on-e1';

export const TRADES_KNOWLEDGE_BASE_UK_IE_LINK =
  'https://international.support.estimateone.com/en/articles/1719620397-trade-selection-on-e1';

export const PROJECT_EXPERIENCE_LEARN_MORE_DEFAULT_LINK =
  'https://subcontractor.support.estimateone.com/en/articles/1705366182-your-guide-to-creating-your-company-profile';

export const PROJECT_EXPERIENCE_LEARN_MORE_UK_IE_LINK =
  'https://international.support.estimateone.com/en/articles/1707417509-your-guide-to-creating-your-company-profile';

export const useLocalisedKnowledgeBaseLinks = (locale: KnowledgeBaseLocale) => {
  switch (locale) {
    case KnowledgeBaseLocale.EN_GB:
    case KnowledgeBaseLocale.EN_IE:
      return {
        projectHistoryArticle: PROJECT_HISTORY_KNOWLEDGE_BASE_UK_IE_LINK,
        tradesArticle: TRADES_KNOWLEDGE_BASE_UK_IE_LINK,
        projectExperienceLearnMoreArticle: PROJECT_EXPERIENCE_LEARN_MORE_UK_IE_LINK,
      };
    default:
      return {
        projectHistoryArticle: PROJECT_HISTORY_KNOWLEDGE_BASE_DEFAULT_LINK,
        tradesArticle: TRADES_KNOWLEDGE_BASE_DEFAULT_LINK,
        projectExperienceLearnMoreArticle: PROJECT_EXPERIENCE_LEARN_MORE_DEFAULT_LINK,
      };
  }
};
