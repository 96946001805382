import { Fragment, useEffect, useState } from 'react';
import {
  generateDownloadPackageQuotesLink,
  generateDownloadUncategorisedQuotesLink,
} from './routing';
import { QuoteGroupHeaderRow } from './QuoteGroupHeaderRow';
import QuoteRow from './QuoteRow';
import {
  sortFilterActivePackages,
  sortQuotesAndGroupByPackage,
  sortQuotesWithinGroup,
  UNCATEGORISED,
} from './sort';
import { Package, StageQuote } from '../../../context/BuilderQuotesProvider';
import { StageType } from '@ascension/enums';
import { NotificationType } from '@ascension/_gqltypes/builder.generated';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

export type StageQuotesTableProps = {
  stageId: EntityId;
  stageType: StageType;
  stagePackages: Package[];
  quotes: StageQuote[];
  onRequestDelete: (quoteId: EntityId) => void;
  onAssignPackage: (quoteId: EntityId, packageId: EntityId) => void;
  onMarkNotificationsAsViewed?: (topicFilter: string, types: NotificationType[]) => void;
  onViewSubbieDetails?: (companyId?: EntityId, accountId?: EntityId) => void;
};

const StageQuotesTable = ({
  stageId,
  stageType,
  stagePackages,
  quotes,
  onRequestDelete,
  onAssignPackage,
  onMarkNotificationsAsViewed,
  onViewSubbieDetails = undefined,
}: StageQuotesTableProps) => {
  const [collapsedIds, setCollapsedIds] = useState(new Set<EntityId>());
  const isCollapsed = (id: EntityId) => collapsedIds.has(id);

  useEffect(() => {
    if (onMarkNotificationsAsViewed && stageId !== undefined) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      (async () =>
        onMarkNotificationsAsViewed(`stage-${stageId}`, [
          NotificationType.QUOTE_CREATED,
          NotificationType.QUOTE_REVISED,
        ]))();
    }
  }, [stageId]);

  const toggleSectionCollapse = (id: EntityId) =>
    setCollapsedIds((prev) => {
      const newState = new Set(prev);
      if (prev.has(id)) {
        newState.delete(id);
        return newState;
      }

      return newState.add(id);
    });

  const { packageTitlesByPackageId, deletedPackageIds, sortedPackageGroups } =
    sortQuotesAndGroupByPackage(stagePackages, quotes);
  const activeStagePackages = sortFilterActivePackages(stagePackages);

  return (
    <table className="table stage-quotes-table">
      <thead>
        <tr>
          <th>Title</th>
          <th>Package</th>
          <th>Subcontractor</th>
          <th>Price</th>
          <th>Uploaded By</th>
          <th>Status</th>
          <th className={styles.stageQuotesTableHeaderActions}>Actions</th>
        </tr>
      </thead>
      <tbody>
        {sortedPackageGroups.map(([packageId, groupedQuotes]) => (
          <Fragment key={packageId}>
            <QuoteGroupHeaderRow
              packageName={packageTitlesByPackageId.get(packageId)}
              packageDeleted={deletedPackageIds.has(packageId)}
              isCollapsed={isCollapsed(packageId)}
              onToggleCollapse={() => toggleSectionCollapse(packageId)}
              onDownloadAll={() =>
                window.open(
                  packageId !== UNCATEGORISED
                    ? generateDownloadPackageQuotesLink(packageId)
                    : generateDownloadUncategorisedQuotesLink(stageId, stageType),
                  '_blank',
                )
              }
            />
            {!isCollapsed(packageId) &&
              groupedQuotes
                .sort(sortQuotesWithinGroup)
                .map((quote) => (
                  <QuoteRow
                    key={quote.id}
                    quote={quote}
                    stageId={stageId}
                    stageType={stageType}
                    stagePackages={activeStagePackages}
                    onAssignPackage={onAssignPackage}
                    onRequestDelete={onRequestDelete}
                    onViewSubbieDetails={onViewSubbieDetails}
                  />
                ))}
          </Fragment>
        ))}
      </tbody>
    </table>
  );
};

export default StageQuotesTable;
