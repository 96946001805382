import { useEffect, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { fileToFileInput, transformQuoteFormApiErrors } from '@shared/Quotes/Form/transformers';
import { CREATE_QUOTE_FROM_RFQ } from './mutations';
import { QuoteFormCommonFieldName } from '@ascension/enums';
import { QuoteCreateFromRfqFormValues } from './types';
import { CreateQuoteFromRfq, CreateQuoteFromRfqVariables } from './types/CreateQuoteFromRfq';
import { CreateQuoteFromRfqInput } from '@ascension/_gqltypes/builder.generated';
import { EntityId } from '@ascension/types';

const getValueTransformer =
  (rfqId: EntityId) =>
  (values: QuoteCreateFromRfqFormValues): CreateQuoteFromRfqInput => {
    const amount = values[QuoteFormCommonFieldName.Amount];
    const quoteFile = values[QuoteFormCommonFieldName.QuoteFile];

    if (amount === undefined || quoteFile === undefined) {
      throw new Error('Incomplete quote form input');
    }

    return {
      rfqId,
      amount,
      quoteFile: fileToFileInput(quoteFile),
      additionalFiles: values[QuoteFormCommonFieldName.ExtraFiles]?.map(fileToFileInput) ?? [],
      details: values[QuoteFormCommonFieldName.Details],
      confirmFixedPrice: values[QuoteFormCommonFieldName.ConfirmFixedPrice],
    };
  };

export const useCreateQuoteFromRfq = (rfqId: EntityId) => {
  const transformValues = getValueTransformer(rfqId);
  const [newQuoteId, setNewQuoteId] = useState<EntityId>();
  const broadcastChannel = useRef(new BroadcastChannel('quotes'));

  const [createQuote, { data }] = useMutation<CreateQuoteFromRfq, CreateQuoteFromRfqVariables>(
    CREATE_QUOTE_FROM_RFQ,
  );

  const submit = async (values: QuoteCreateFromRfqFormValues) => {
    const { data: response } = await createQuote({ variables: { input: transformValues(values) } });
    if (response?.createQuoteFromRfq.__typename === 'Errors') {
      return transformQuoteFormApiErrors(response.createQuoteFromRfq.errors);
    }
    return undefined;
  };

  useEffect(() => {
    if (data) {
      if (data.createQuoteFromRfq.__typename === 'Quote') {
        setNewQuoteId(data.createQuoteFromRfq.id);
        broadcastChannel.current.postMessage({ eventName: 'CREATED_FROM_RFQ', rfqId });
      }
    }
  }, [data, rfqId]);

  return { submit, newQuoteId };
};
