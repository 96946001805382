import { SubbieProfileForAccount_subbieProfileForAccount_accountOffices as AccountOffice } from '../../../builder/pages/Profiles/SubbieProfilesPage/types/SubbieProfileForAccount';
import styles from './styles.scss';

export type OfficeLocationProps = {
  offices: AccountOffice[];
};

const OfficeLocation = ({ offices }: OfficeLocationProps) => {
  const states = Array.from(
    new Set((offices || []).map((office) => office?.address?.state?.name).filter((name) => name)),
  );

  return (
    <ul className={styles.officeLocationContainer}>
      {states.map((state) => (
        <li key={state}>{state}</li>
      ))}
    </ul>
  );
};

export default OfficeLocation;
