import { useState } from 'react';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  E1Link,
  getStatus,
  LinkVariant,
  Modal,
  ModalSize,
  Slider,
  Stepper,
} from '@estimateone/frontend-components';
import { useStageNameForDocumentIntegration } from '../../DocumentIntegrationProvider';
import { ProcoreConfirmSelection } from '../ProcoreConfirmSelection';
import { ProcoreItemsList } from '../ProcoreItemsList';
import { ProcoreProjectList } from '../ProcoreProjectList';
import { useCreateStageProcoreMap } from '../../hooks/useCreateStageProcoreMap';
import { useGetStageProcoreMapSetup, useGetProcoreProjects } from '../hooks';
import { useGetProcoreCompanies } from '../hooks/useGetProcoreCompanies';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

export type SelectedDocuments = {
  includeDrawings: boolean;
  includeSpecifications: boolean;
  documents: {
    files: string[];
    folders: string[];
  };
};

type ProcoreConnectSliderProps = {
  stageId: EntityId;
  isOpen: boolean;
  onConfirmConnection: () => void;
};

export const ProcoreConnectSlider = ({
  stageId,
  isOpen,
  onConfirmConnection,
}: ProcoreConnectSliderProps) => {
  const stageName = useStageNameForDocumentIntegration();
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [isSliderOpen, setIsSliderOpen] = useState<boolean>(isOpen);
  const [selectedProjectId, setSelectedProjectId] = useState<string | null>(null);
  const [selectedDocuments, setSelectedDocuments] = useState<SelectedDocuments>({
    includeDrawings: false,
    includeSpecifications: false,
    documents: { files: [], folders: [] },
  });
  const [isPermissionsErrorModalOpen, setIsPermissionsErrorOpen] = useState<boolean>(false);

  const { projects, loading: projectsLoading } = useGetProcoreProjects();
  const { companies, loading: companiesLoading } = useGetProcoreCompanies();
  const {
    getStageProcoreMapSetup: getStageProcoreMapOptions,
    loading: mapOptionsLoading,
    data: stageProcoreMapData,
  } = useGetStageProcoreMapSetup();
  const { createStageProcoreMap, loading: createMapLoading } = useCreateStageProcoreMap();

  const getStatusForStep = (stepNumber: number) => getStatus(stepNumber, currentStep);

  const closeSlider = () => {
    setIsSliderOpen(false);
  };

  const switchToStep2 = async (projectId: string) => {
    const companyId = projects?.find((p) => p.id === projectId)?.company.id;
    if (companyId) {
      setCurrentStep(2);
      const { stageProcoreMapOptions, projectPermissions } = await getStageProcoreMapOptions(
        projectId,
        companyId,
      );
      if (!projectPermissions?.hasPermissions) {
        setIsPermissionsErrorOpen(true);
        setSelectedProjectId(null);
        setCurrentStep(1);
      } else {
        setSelectedDocuments({
          ...selectedDocuments,
          includeDrawings: stageProcoreMapOptions?.includeDrawings ?? false,
          includeSpecifications: stageProcoreMapOptions?.includeSpecifications ?? false,
        });
      }
    }
  };
  const switchToStep3 = () => setCurrentStep(3);
  const confirmConnection = async (projectId: string) => {
    const {
      documents: { files, folders },
      ...rest
    } = selectedDocuments;

    await createStageProcoreMap({
      input: { stageId, files, folders, ...rest, projectId },
    });

    onConfirmConnection();
    setIsSliderOpen(false);
  };

  const isDocumentSelected = () => {
    if (
      selectedDocuments.includeDrawings ||
      selectedDocuments.includeSpecifications ||
      selectedDocuments.documents.files.length ||
      selectedDocuments.documents.folders.length
    ) {
      return true;
    }
    return false;
  };

  const handleCloseButton = () => {
    if (currentStep === 1) {
      closeSlider();
    } else setCurrentStep(currentStep - 1);
  };

  if (projectsLoading || companiesLoading || !projects || !companies) {
    return null;
  }

  const renderStep = () => {
    const isStep1 = currentStep === 1;
    const isStep2 = currentStep === 2 && selectedProjectId;
    const isStep3 =
      currentStep === 3 &&
      stageProcoreMapData?.stageProcoreMapSetup.stageProcoreMapOptions &&
      selectedProjectId;

    if (isStep1) {
      return (
        <ProcoreProjectList
          stageName={stageName}
          projects={projects}
          companies={companies}
          selectedProjectId={selectedProjectId}
          handleProjectSelection={setSelectedProjectId}
        />
      );
    }

    if (isStep2) {
      return (
        <div>
          <div className={styles.procoreItemsDescription}>
            <p>Select items to import from Procore (this can be changed later)</p>
            <p>
              For &apos;<b>Documents</b> (Core Tools)&apos;, choose the whole directory or specific
              top-level items. Private (locked) items won&apos;t be imported.
            </p>
          </div>
          <ProcoreItemsList
            stageProcoreMapSetup={stageProcoreMapData?.stageProcoreMapSetup}
            loading={mapOptionsLoading}
            selectedDocuments={selectedDocuments}
            handleDocumentSelection={setSelectedDocuments}
          />
        </div>
      );
    }

    if (isStep3) {
      const project = projects.find((p) => p?.id === selectedProjectId);
      if (project) {
        return (
          <ProcoreConfirmSelection
            selectedProjectDetails={project}
            selectedDocuments={selectedDocuments}
            stageProcoreMapOptions={stageProcoreMapData.stageProcoreMapSetup.stageProcoreMapOptions}
            stageName={stageName}
          />
        );
      }
    }

    return null;
  };

  const returnToDocumentsPage = () => {
    setIsPermissionsErrorOpen(false);
    setIsSliderOpen(false);
  };

  const canGoToStep2 = currentStep === 1 && selectedProjectId;
  const canGoToStep3 = currentStep === 2 && isDocumentSelected();
  const canConfirmConnection = currentStep === 3 && selectedProjectId;

  return (
    <>
      <Slider isOpen={isSliderOpen} onRequestClose={() => setIsSliderOpen(false)}>
        <Slider.Header>
          <h2 className={styles.sliderHeading}>Connect to Procore</h2>
        </Slider.Header>
        <Slider.Header variant="secondary">
          <div className={styles.sliderSteps}>
            <Stepper>
              <Stepper.Step
                stepNumber={1}
                label="Select Project"
                status={getStatusForStep(1)}
                onClick={() => setCurrentStep(1)}
              />
              <Stepper.Step
                stepNumber={2}
                label="Select Items"
                status={getStatusForStep(2)}
                onClick={() => setCurrentStep(2)}
              />
              <Stepper.Step
                stepNumber={3}
                label="Confirm Connection"
                status={getStatusForStep(3)}
                onClick={() => setCurrentStep(3)}
              />
            </Stepper>
          </div>
        </Slider.Header>
        <Slider.Body scrollBodyOnly>{renderStep()}</Slider.Body>
        <Slider.Footer>
          <div className={styles.sliderFooter}>
            <Button variant={ButtonVariant.Grey} fullWidth onClick={handleCloseButton}>
              {currentStep === 1 ? 'Cancel' : 'Previous'}
            </Button>
            <Button
              variant={ButtonVariant.Primary}
              fullWidth
              disabled={!(canGoToStep2 || canGoToStep3 || canConfirmConnection) || createMapLoading}
              onClick={() =>
                canGoToStep2
                  ? switchToStep2(selectedProjectId)
                  : canGoToStep3
                    ? switchToStep3()
                    : canConfirmConnection
                      ? confirmConnection(selectedProjectId)
                      : void 0
              }
            >
              {currentStep === 3 ? 'Confirm and Start Importing' : 'Next'}
            </Button>
          </div>
        </Slider.Footer>
      </Slider>
      <Modal
        size={ModalSize.Small}
        isOpen={isPermissionsErrorModalOpen}
        onRequestClose={() => setIsPermissionsErrorOpen(false)}
      >
        <Modal.Section>
          <div className={styles.procorePermissionsErrorModalContainer}>
            <h1 className={styles.procorePermissionsErrorModalHeading}>
              This project needs permission to connect with E1
            </h1>
            <p className={styles.procorePermissionsErrorModalDescription}>
              Add project permissions in Procore before connecting.
            </p>
            <p className={styles.procorePermissionsErrorModalDescriptionLink}>
              <E1Link
                variant={LinkVariant.Primary}
                href="https://builder.support.estimateone.com/en/articles/1705698250-procore-installation-and-setup-guides"
                target="_blank"
                rel="noreferrer"
              >
                Learn how with our Knowledge Base article.
              </E1Link>
            </p>
            <div className={styles.procorePermissionsErrorModalButtonContainer}>
              <Button
                size={ButtonSize.Medium}
                variant={ButtonVariant.Grey}
                fullWidth
                onClick={() => setIsPermissionsErrorOpen(false)}
              >
                Cancel
              </Button>
              <Button
                size={ButtonSize.Medium}
                variant={ButtonVariant.Primary}
                fullWidth
                onClick={returnToDocumentsPage}
              >
                OK
              </Button>
            </div>
          </div>
        </Modal.Section>
      </Modal>
    </>
  );
};
