import { useMemo } from 'react';
import { createPortal } from 'react-dom';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  joinClassNames,
} from '@estimateone/frontend-components';
import { Addendum } from '@builder/features/AddendumAlertSchedule';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

export type RevisionHistoryContinueButtonProps = {
  stageId: EntityId;
  pendingAddendum: Addendum | undefined;
  completedAddendum: Addendum | undefined;
  onClick: () => void;
};
export const RevisionHistoryContinueButton = ({
  stageId,
  pendingAddendum,
  completedAddendum,
  onClick,
}: RevisionHistoryContinueButtonProps) => {
  const revisionContinueButtonPortal = useMemo(
    () => document.getElementById('revision-flow-continue-button-portal'),
    [],
  );

  if (!revisionContinueButtonPortal) {
    return null;
  }

  const button = pendingAddendum ? (
    <Button
      onClick={onClick}
      variant={ButtonVariant.Secondary}
      size={ButtonSize.Small}
      fullWidth
      className={styles.revisionFlowContinueButton}
    >
      Continue
    </Button>
  ) : completedAddendum ? (
    <Button
      variant={ButtonVariant.Secondary}
      size={ButtonSize.Small}
      fullWidth
      className={joinClassNames(styles.revisionFlowContinueButton, 'view-doc-history-trigger')}
      data-addendum={completedAddendum.id}
      data-stage={stageId}
    >
      View Changes
    </Button>
  ) : null;

  return createPortal(button, revisionContinueButtonPortal);
};
