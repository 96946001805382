import { LoadingSpinner } from '@estimateone/frontend-components';
import { ProcurementLettingSchedule } from './ProcurementLettingSchedule';
import { CurrencyInfoContextProvider } from '@shared/context';
import { useGetLettingColumnStatus } from './ColumnActionMenu/hooks/useGetLettingColumnStatus';
import { useGetPackagesForLettingSchedule, useGetUsersForCurrentAccount } from './hooks';
import { useGetStageSettings } from '@builder/features/StageSettings/hooks';
import { getCurrencyInfo } from '@ascension/enums';
import { ProcurementLettingScheduleControllerProps } from '@builder/features/ProcurementLettingSchedule/types';

export const ProcurementLettingScheduleController = ({
  stageId,
  hasDocumentIntegration,
}: ProcurementLettingScheduleControllerProps) => {
  const { loading, stage, activePackages, lettingScheduleCustomColumns } =
    useGetPackagesForLettingSchedule(stageId);

  const { data: columnStatuses, loading: columnStatusLoading } = useGetLettingColumnStatus(stageId);

  const { accountUsers, loading: loadingUsers } = useGetUsersForCurrentAccount();

  const {
    stageSettingEnableDefaultTimeframes: enableDefaultTimeframes,
    loading: stageSettingsLoading,
  } = useGetStageSettings(stageId);

  if (loading || columnStatusLoading || loadingUsers || stageSettingsLoading) {
    return <LoadingSpinner />;
  }

  if (!columnStatuses) {
    throw new Error('No data for column statuses has been returned');
  }

  if (!stage) {
    throw new Error('No data for stage has been returned');
  }

  const countryId = stage?.account?.primaryCountry?.id;
  const currencyInfo = getCurrencyInfo(countryId);

  return (
    <CurrencyInfoContextProvider currencyInfo={currencyInfo}>
      <ProcurementLettingSchedule
        stageId={stageId}
        hasDocumentIntegration={hasDocumentIntegration}
        activePackages={activePackages}
        lettingScheduleCustomColumns={lettingScheduleCustomColumns}
        stage={stage}
        columnStatuses={columnStatuses}
        accountUsers={accountUsers}
        enableDefaultTimeframes={enableDefaultTimeframes}
      />
    </CurrencyInfoContextProvider>
  );
};
