import { useCurrentAccount } from '@subbie/context/AccountProvider/hooks';
import { BillingCycle } from '@ascension/_gqltypes/subbie.generated';

type LiteLicenseDetails = {
  billingCycle: BillingCycle | null;
  renewalDate: GraphQLDateTime | null;
  productName: string;
};

const getBillingCycle = (billingCycle: number): BillingCycle | null => {
  switch (billingCycle) {
    case 1:
      return BillingCycle.ONE_MONTH;
    case 12:
      return BillingCycle.TWELVE_MONTHS;
    default:
      return null;
  }
};

export const useGetCurrentLiteLicenseDetails = (): LiteLicenseDetails | null => {
  const activeLicenses = useCurrentAccount()?.licensingInfo?.activeLicenses;

  const liteLicense = activeLicenses?.find(({ product }) => product.name.includes('Lite'));

  return liteLicense
    ? {
        billingCycle: getBillingCycle(liteLicense.billingCycle),
        renewalDate: liteLicense.expiredAt,
        productName: liteLicense.product.name,
      }
    : null;
};
