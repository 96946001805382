import { gql } from '@apollo/client';

const PEOPLE_FRAGMENT = gql`
  fragment Person on Person {
    id
    fullName
    firstName
    lastName
    isDefaultContact
    contactable {
      id
      email
    }
  }
`;

export const GET_PROFILE = gql`
  query GetCurrentProfile {
    currentProfile {
      id
      name
      offices {
        id
        isDefaultOffice
        address {
          id
          address1
          address2
          address3
          suburb
          state {
            id
            shortName
          }
          postcode
          city
          province
          country {
            id
            name
          }
        }
        serviceArea {
          id
          type
          radius
          boundaries {
            id
            type
            name
            shortName
            country {
              states {
                id
                name
                shortName
              }
            }
          }
        }
      }
      contractSizeMin
      contractSizeMax
      companyNumber
      businessEntityType
      trades {
        id
        name
        isCoreTrade
      }
      people {
        ...Person
      }
    }
  }
  ${PEOPLE_FRAGMENT}
`;
