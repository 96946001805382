import { useField } from 'react-final-form';
import { Select, SelectProps } from '@estimateone/frontend-components';
import { FieldWrapper } from './FieldWrapper';
import { ValueLabelOption } from '@estimateone/frontend-components/src/components/Form/Select/types';

export type SingleSelectFieldProps<TOption extends ValueLabelOption> = SelectProps<
  TOption,
  false
> & {
  fieldName: string;
  hideErrors?: boolean;
  defaultValue?: TOption;
  validate?: (value: TOption) => string[] | undefined;
};

export const SingleSelectField = <TOption extends ValueLabelOption>({
  fieldName,
  hideErrors = false,
  className,
  defaultValue,
  validate,
  ...props
}: SingleSelectFieldProps<TOption>) => {
  const {
    input,
    meta: { error, submitError, touched },
  } = useField(fieldName, { validate, defaultValue });

  return (
    <FieldWrapper errors={error || submitError} showErrors={!hideErrors && touched}>
      <div data-name={fieldName} className={className}>
        <Select<TOption, false> {...input} {...props} />
      </div>
    </FieldWrapper>
  );
};
