import { ReactNode } from 'react';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';

export const EmotionCacheProvider = ({
  cacheKey,
  nonce,
  children,
}: {
  cacheKey: string;
  nonce: string;
  children?: ReactNode;
}) => {
  const styleCache = createCache({
    key: cacheKey,
    nonce,
  });

  return <CacheProvider value={styleCache}>{children}</CacheProvider>;
};
