import { MouseEventHandler } from 'react';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  Icon,
  IconName,
  IconVariant,
  joinClassNames,
} from '@estimateone/frontend-components';
import styles from './styles.scss';

export type QuoteGroupHeaderRowProps = {
  isCollapsed: boolean;
  packageName?: string;
  onDownloadAll?: () => void;
  onToggleCollapse: () => void;
  packageDeleted?: boolean;
};

type CollapsedIndicatorProps = { isCollapsed: boolean };

const CollapsedIndicator = ({ isCollapsed }: CollapsedIndicatorProps) => (
  <>
    <Icon
      name={isCollapsed ? IconName.ChevronRight : IconName.ChevronDown}
      size="0.6em"
      marginRight="small"
    />
    <Icon
      name={isCollapsed ? IconName.Folder : IconName.FolderOpen}
      variant={isCollapsed ? IconVariant.Default : IconVariant.Blue}
      size="1.2em"
      marginRight="small"
      title={isCollapsed ? 'Closed' : 'Open'}
    />
  </>
);

const UncategorisedQuotesHeading = () => (
  <>
    Uncategorised Quotes
    <span className={styles.packageNameDescription}>
      - assign these quotes to their packages and subcontractors
    </span>
  </>
);

type PackageActionsProps = { onDownloadAll: MouseEventHandler<HTMLButtonElement> };

const PackageActions = ({ onDownloadAll }: PackageActionsProps) => (
  <Button
    tooltip="Download all the quotes in this package"
    size={ButtonSize.Small}
    variant={ButtonVariant.Secondary}
    onClick={onDownloadAll}
  >
    Download quotes
  </Button>
);

export const QuoteGroupHeaderRow = ({
  isCollapsed,
  packageName,
  onDownloadAll,
  onToggleCollapse,
  packageDeleted = false,
}: QuoteGroupHeaderRowProps) => (
  <tr className="group" onClick={onToggleCollapse}>
    <td colSpan={99}>
      <div className={styles.quoteGroupHeaderContent}>
        <div
          className={joinClassNames(styles.quoteGroupHeaderInfo, packageDeleted && styles.deleted)}
        >
          <CollapsedIndicator isCollapsed={isCollapsed} />
          {packageName ? (
            <span className={styles.packageNameDescription}>
              {packageName}
              {packageDeleted && ' - package has been deleted'}
            </span>
          ) : (
            <UncategorisedQuotesHeading />
          )}
        </div>
        <PackageActions
          onDownloadAll={(e) => {
            e.stopPropagation();
            return onDownloadAll && onDownloadAll();
          }}
        />
      </div>
    </td>
  </tr>
);
