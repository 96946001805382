import { PaymentData } from '@shared/form/EwaySecureFieldsForm/types';
import { BillingCycle, PaymentMethod } from '@ascension/enums';
import {
  PurchaseAddonEFTInput,
  PurchaseAddonExistingCCInput,
  PurchaseAddonNewCCInput,
} from '@ascension/_gqltypes/subbie.generated';
import { EntityId } from '@ascension/types';

const isCCFormPopulated = (paymentData: PaymentData): boolean => !!paymentData?.secureCard;

type PurchaseErrors = { field: string | null; message: string }[];
export type ErrorObject = { [field: string]: string };

const getErrorMessages = (errors: PurchaseErrors): ErrorObject =>
  errors.reduce((allErrors, { field, message }) => {
    if (!field) return allErrors;

    return {
      ...allErrors,
      [field]: message,
    };
  }, {});

const transformInputForPurchase = ({
  addonId,
  billingCycle,
  creditCardId,
  paymentMethod,
  userId,
  secureCard,
  terms,
}: PaymentData & { addonId: EntityId; userId: EntityId; billingCycle: BillingCycle }):
  | PurchaseAddonEFTInput
  | PurchaseAddonExistingCCInput
  | PurchaseAddonNewCCInput => {
  const isEFT = paymentMethod === PaymentMethod.EFT;

  const transformedData = {
    productId: addonId,
    billingCycle,
    terms,
    userId,
  };

  if (!isEFT) {
    if (creditCardId) {
      return { ...transformedData, ...{ creditCardId } } as PurchaseAddonExistingCCInput;
    }
    return {
      ...transformedData,
      ...{
        secureCard,
      },
    } as PurchaseAddonNewCCInput;
  }

  return transformedData as PurchaseAddonEFTInput;
};

export { isCCFormPopulated, transformInputForPurchase, getErrorMessages };
