import { MutableRefObject, useState } from 'react';
import {
  CellProps,
  DatePickerFormat,
  joinClassNames,
  TableInstance,
  Tooltip,
} from '@estimateone/frontend-components';
import { InlineEditableDate } from '@builder/common/InlineEditableDate';
import { Trigger } from '../TimingCalculator/autoCalculateTimings';
import { getMilestoneInfoForSendInvitesDate } from '../TimingMilestoneState';
import {
  CalculateAndUpdateLettingTimingsInput,
  getTimings,
  InputIdentifiers,
  isDateSameOrEarlierThanFutureDates,
  TimingErrorMessages,
} from '../utils/lettingTimingHelper';
import { Package } from '@builder/features/ProcurementLettingSchedule/types';
import styles from '../styles.scss';

type CellComponentProps = {
  value: Date;
  original: Package;
  calculateAndUpdateLettingTimings: ({
    trigger,
    ...timings
  }: CalculateAndUpdateLettingTimingsInput) => void;
  tableRef: MutableRefObject<TableInstance<Package> | undefined>;
};

const CellComponent = ({
  value,
  original,
  calculateAndUpdateLettingTimings,
  tableRef,
}: CellComponentProps) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const sendInvitesByDate = value ? new Date(value) : null;

  const { classNames, hasMilestoneText, milestoneText, extraMilestoneText } =
    getMilestoneInfoForSendInvitesDate(
      sendInvitesByDate,
      original.countOfInvitesSent,
      original.lettingScheduleDetails?.isAwarded,
    );

  const handleOnDateChange = (currentValue: Date | null): void => {
    const timings = getTimings(
      currentValue ?? undefined,
      tableRef.current,
      original,
      Trigger.SEND_INVITES_BY_DATE_CHANGE,
      'sendInvitesByDate',
    );

    const isLaterThanFutureDate =
      !timings?.trigger || !isDateSameOrEarlierThanFutureDates(timings.trigger, timings);

    if (isLaterThanFutureDate) {
      setErrorMessage(TimingErrorMessages.DATE_TOO_LATE);
    } else {
      calculateAndUpdateLettingTimings(timings);
    }
  };

  return (
    <div className={joinClassNames(styles.cellAlign, ...classNames)}>
      <InlineEditableDate
        id={`${InputIdentifiers.SEND_INVITES_BY_DATE}${original.id}`}
        className={errorMessage ? styles.dateError : undefined}
        label="Send Invites By date"
        value={sendInvitesByDate ?? undefined}
        onFocus={() => setErrorMessage(null)}
        onDateChange={handleOnDateChange}
        dateFormat={DatePickerFormat.DateOnlyShortMonth}
      />

      {errorMessage ? (
        <p className={styles.dateErrorMessage}>{errorMessage}</p>
      ) : (
        hasMilestoneText && (
          <Tooltip
            className={styles.tooltipSpacing}
            text={extraMilestoneText}
            tooltipId={`send-invites-milestone-tooltip_${original.id}`}
            place="bottom"
          >
            <span className={styles.timeRemaining}>{milestoneText}</span>
          </Tooltip>
        )
      )}
    </div>
  );
};

export const SendInvitesByCell =
  (
    calculateAndUpdateLettingTimings: ({
      trigger,
      ...timings
    }: CalculateAndUpdateLettingTimingsInput) => void,
    tableRef: MutableRefObject<TableInstance<Package> | undefined>,
  ) =>
  ({ value, row: { original } }: CellProps<Package, Date>) =>
    CellComponent({
      value,
      original,
      calculateAndUpdateLettingTimings,
      tableRef,
    });
