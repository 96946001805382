import { useQuery } from '@apollo/client';
import { GET_SCHEDULE_SETTINGS } from '../queries';
import {
  GetScheduleSettings,
  GetScheduleSettings_scheduleSettings as ScheduleSettings,
} from '../types/GetScheduleSettings';

type UseGetSettingSchedule = {
  loading: boolean;
  settings: ScheduleSettings[];
};

export const useGetScheduleSettings = (): UseGetSettingSchedule => {
  const { data, loading } = useQuery<GetScheduleSettings, ScheduleSettings>(GET_SCHEDULE_SETTINGS);

  return {
    settings: data?.scheduleSettings ?? [],
    loading,
  };
};
