import { ReactNode } from 'react';
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
  NormalizedCacheObject,
  ApolloProvider,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import Routing from 'routing';
import { trackErrorLink } from '@api/E1ApolloProvider';
import TokenCache from '@api/GatewayProvider/TokenCache';

type GatewayClientOptions = {
  endpoint: string;
  idpLoginUri: string;
};

// Duplication!

const createClient = ({
  endpoint,
  idpLoginUri,
}: GatewayClientOptions): ApolloClient<NormalizedCacheObject> => {
  const tokenCache = new TokenCache(idpLoginUri);
  // Auth with the IDP
  const authLink = setContext(async (_, { headers }) => ({
    headers: {
      authorization: `Bearer ${await tokenCache.getToken()}`,
      ...headers,
    },
  }));

  const subbieProfilesHttpLink = new HttpLink({ uri: endpoint });

  return new ApolloClient({
    link: ApolloLink.from([authLink, trackErrorLink, subbieProfilesHttpLink]),
    cache: new InMemoryCache(),
  });
};

export const ProfilesApolloProvider = ({
  children,
  endpoint,
}: {
  children?: ReactNode;
  endpoint: string;
}) => (
  <ApolloProvider
    client={createClient({
      endpoint,
      idpLoginUri: Routing.generate('gateway_jwt_authorise'),
    })}
  >
    {children}
  </ApolloProvider>
);
