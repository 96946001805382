import { Slider } from '@estimateone/frontend-components';
import ScreenReaderOnly from '../../../../../../../fec/ScreenReaderOnly';
import { InviteSummaryCard } from '../InviteSummaryCard';
import { NoEmailAlert } from '../NoEmailAlert';
import { GetInviteToQuoteStage_stage_UnrestrictedStage as Stage } from '../../types/GetInviteToQuoteStage';

type PaneBodyForContactWithoutEmailProps = {
  stage: Stage;
};

export const PaneBodyForContactWithoutEmail = ({ stage }: PaneBodyForContactWithoutEmailProps) => (
  <Slider.Body scrollBodyOnly>
    <ScreenReaderOnly>
      <h2>Invite summary</h2>
    </ScreenReaderOnly>
    <InviteSummaryCard stageName={stage.name} />
    <NoEmailAlert />
  </Slider.Body>
);
