import { ConnectionPreferenceType } from '@ascension/_gqltypes/subbie.generated';

export type ConnectionPreferenceFormValues = {
  id: string;
  projectId: number;
  type: ConnectionPreferenceType;
  note?: string;
  contact?: {
    value: number;
    label: string;
  };
};

export enum ConnectionPreferenceFormPage {
  ConnectionPreference = 'ConnectionPreference',
  WatchlistUpdated = 'WatchlistUpdated',
}

export enum ConnectionPreferenceFormId {
  Id = 'connection-preference-form',
}

export enum ConnectionPreferenceField {
  Id = 'id',
  ProjectId = 'projectId',
  Type = 'type',
  Note = 'note',
  Contact = 'contact',
}

export enum ConnectionPreferenceFormLabels {
  ProjectInvolvementTitle = 'Project Involvement',
  ProjectInvolvementLegend = 'What is your involvement in this project',
  SpecifiedOption = 'I am a specified supplier on this project',
  EquivalentOption = 'I can offer equivalent products for this project',
  SpecifiedAndEquivalentOption = 'I am a specified supplier and can also offer equivalent products for this project',
  NoteLabel = 'Note',
  PrimaryContactTitle = 'Primary contact',
  PrimaryContactLegend = 'Select the primary contact for this project',
  PrimaryContactHelpText = 'The primary contact will be shared with subcontractors and assigned the project on the Watchlist.',
}
