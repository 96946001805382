import { Select, SelectSize } from '@estimateone/frontend-components';
import { useAssignPackageToUser, AssignedToOption } from './useAssignPackageToUser';
import { GetUsersForAccount_accountUsers as AccountUser } from '../types/GetUsersForAccount';
import { Package } from '@builder/features/ProcurementLettingSchedule/types';
import styles from './styles.scss';

type AssignedToSelectProps = {
  selectedUser: AccountUser | null;
  users: AccountUser[];
  originalPackage: Package;
};

export const AssignedToSelect = ({
  selectedUser,
  users,
  originalPackage,
}: AssignedToSelectProps) => {
  const { assignPackageToUser } = useAssignPackageToUser(originalPackage);

  const selectedOption = selectedUser
    ? { value: selectedUser.id, label: selectedUser.fullName ?? '' }
    : undefined;

  const optionsList =
    users &&
    users
      .map((user) => ({
        label: user.fullName ?? '',
        value: user.id,
      }))
      .sort((optionA, optionB) => optionA.label.localeCompare(optionB.label));

  return (
    <Select<AssignedToOption>
      className={styles.assignToSelect}
      classNamePrefix="assignToSelect"
      id={`assignedToSelect${originalPackage.id}`}
      label=""
      hideLabel
      size={SelectSize.Small}
      placeholder="Select user..."
      blurInputOnSelect
      menuPlacement="auto"
      maxMenuHeight={250}
      onChange={(value) => assignPackageToUser(value)}
      defaultValue={selectedOption}
      styles={{
        control: (provided) => ({ ...provided, transition: 'none', border: 'none' }),
      }}
      options={optionsList}
      isClearable
    />
  );
};
