import {
  Button,
  ButtonVariant,
  CellProps,
  E1Link,
  IconVariant,
  NotificationCounter,
  Table,
  TableColumn,
} from '@estimateone/frontend-components';
import showHistoryModal from '@builder/features/ProcurementLettingSchedule/PackageDashboard/PackageDashboardTable/PackageDashboardTableOptions/showHistoryModal';
import { QuoteAmount } from '@builder/features/ProcurementLettingSchedule/PackageDashboard/QuoteAmount';
import { showCompanySlideout } from '@builder/features/ProcurementLettingSchedule/PackageDashboard/utils';
import { showUniversalProfileDrawer } from '@builder/pages/Profiles/UniversalProfileDrawer';
import { DocumentStatusTag } from '../../DocumentStatusTag';
import { NoteIcon } from '../../NoteIcon';
import { ResponseStatus } from '../../ResponseStatus';
import { useIsUniversalDrawerEnabled } from '@builder/context/AccountProvider/hooks';
import { Action, useInteractAnalytics } from '@ascension/components/hooks/Analytics';
import { ButtonName } from '@ascension/components/hooks/Analytics/buttonNames';
import { QuoteReturnRoute } from '@ascension/enums';
import { GetPackageWithRfqs_package_importedFromPackage as Package } from '../../types/GetPackageWithRfqs';
import { InterfaceToType } from '@ascension/types';
import styles from './styles.scss';

type ActiveRfq = Package['activeRfqs'][0];
export type Rfq = InterfaceToType<ActiveRfq>;

const useColumns = (lastAddedDate: Date | undefined) => {
  const { addEvent } = useInteractAnalytics();
  const isUniversalDrawerEnabled = useIsUniversalDrawerEnabled();

  const trackCompanyNameClick = () => {
    addEvent({
      action: Action.BUTTON_CLICKED,
      buttonName: ButtonName.PROCUREMENT_TENDER_RESPONSES_TABLE_VIEW_ADDRESS,
    });
  };

  const trackQuoteStatusClick = () => {
    addEvent({
      action: Action.BUTTON_CLICKED,
      buttonName: ButtonName.PROCUREMENT_TENDER_RESPONSES_TABLE_VIEW_QUOTE,
    });
  };

  const trackNotesClick = () => {
    addEvent({
      action: Action.BUTTON_CLICKED,
      buttonName: ButtonName.PROCUREMENT_TENDER_RESPONSES_TABLE_VIEW_NOTES,
    });
  };

  const columns: TableColumn<Rfq>[] = [
    {
      accessor: 'companyName',
      Header: 'Company',
      Cell: ({ row: { original: rfq }, value: companyName }: CellProps<Rfq>) => {
        const company = rfq?.contact?.company;

        if (!company) {
          return companyName;
        }

        return (
          <E1Link
            className={styles.companyName}
            onClick={() => {
              if (isUniversalDrawerEnabled) {
                showUniversalProfileDrawer({ companyId: company.id });
              } else {
                showCompanySlideout(company.id, rfq.id);
              }
              trackCompanyNameClick();
            }}
          >
            {companyName}
          </E1Link>
        );
      },
    },
    {
      accessor: ({ contact }) => contact?.fullName ?? '-',
      Header: 'Contact',
    },
    {
      accessor: ({ contact }) => contact?.phone ?? '-',
      Header: 'Phone',
      Cell: ({ value }: CellProps<Rfq>) => <div className={styles.phone}>{value}</div>,
    },
    {
      id: 'documentStatus',
      accessor: 'downloadedLastAt',
      Header: 'Document Status',
      disableSortBy: true,
      Cell: ({ row: { original: rfq } }: CellProps<Rfq>) => (
        <DocumentStatusTag
          stageId={rfq.stageId}
          rfqId={rfq.id}
          packageContentLastAddedAt={lastAddedDate}
          rfqLastDownloadedAt={rfq.downloadedLastAt ? new Date(rfq.downloadedLastAt) : undefined}
        />
      ),
    },
    {
      accessor: 'quotingStatus',
      Header: 'Quote Status',
      Cell: ({ row: { original: rfq } }) => (
        <ResponseStatus
          stageId={rfq.stageId}
          onQuoteOpen={trackQuoteStatusClick}
          returnTo={QuoteReturnRoute.BuilderImportedPackageDashboard}
          rfq={rfq}
          openInNewTab
        />
      ),
    },
    {
      id: 'quoteAmount',
      Header: () => <span className={styles.quoteAmountHeader}>Quote Amount</span>,
      disableSortBy: true,
      Cell: ({ row: { original: rfq } }: CellProps<Rfq>) => (
        <div className={styles.quoteAmount}>
          <QuoteAmount
            rfq={rfq}
            returnTo={QuoteReturnRoute.BuilderImportedPackageDashboard}
            openInNewTab
          />
        </div>
      ),
    },
    {
      id: 'notes',
      Header: 'Notes',
      disableSortBy: true,
      Cell: ({ row: { original: rfq } }: CellProps<Rfq>) => (
        <Button
          aria-label={`View or add notes. Currently has ${rfq.noteCount} notes.`}
          variant={ButtonVariant.Transparent}
          onClick={async () => {
            await showHistoryModal('notes', rfq.stageId, rfq.id, () => undefined, true);
            trackNotesClick();
          }}
        >
          {rfq.noteCount > 0 ? (
            <NotificationCounter count={rfq.noteCount}>
              <NoteIcon variant={IconVariant.Default} />
            </NotificationCounter>
          ) : (
            <NoteIcon variant={IconVariant.Grey} />
          )}
        </Button>
      ),
    },
  ];

  return columns;
};

export type TenderResponsesTableProps = {
  tenderPackage: Package | null;
};

export const TenderResponsesTable = ({ tenderPackage }: TenderResponsesTableProps) => {
  const rfqs = tenderPackage?.activeRfqs ?? [];
  const lastAddedDate = tenderPackage?.contentLastAddedAt
    ? new Date(tenderPackage.contentLastAddedAt)
    : undefined;
  const columns = useColumns(lastAddedDate);

  return (
    <div className={styles.tenderResponsesTable}>
      <Table<Rfq> columns={columns} data={rfqs} initSortColumnId="companyName" />
    </div>
  );
};
