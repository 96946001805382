import { gql } from '@apollo/client';

export const RestrictedStageAccess = gql`
  fragment RestrictedStageAccess on RestrictedStageAccess {
    user {
      id
      fullName
      phone
      email
      isAdmin
    }
    access
  }
`;
