import $ from 'jquery';
import DocumentUploadStatus from '../classes/DocumentUploadStatus';
import ServerlessUploader, { UploadStatus } from '../classes/file_manager/ServerlessUploader';
import initUserCorrespondenceDocTable from './user_correspondence_file_list';
import { UploadParentType } from '../../enums';

class UserCorrespondenceFileUploadModule {
  constructor($ctn, $target, list, correspondenceId, apiKey, attachment, unzip) {
    this.correspondenceId = parseInt(correspondenceId, 10) || null;
    this.$target = $target;
    this.authKey = apiKey;
    this.list = list;
    this.attachment = attachment;
    this.files = [];
    this.endpoint = this.$target.data('file-endpoint');

    this.isUploading = false;
    this.fetchLock = null;

    this.$uploaderCtn = $ctn;
    this.$uploadStatusCtn = $ctn.parent().parent().find('.upload-status');

    this.uploader = new ServerlessUploader(
      this.$uploaderCtn,
      'drag_target',
      'document_upload_button',
      UploadParentType.USER_CORR_FILE,
      this.correspondenceId,
    );
    this.uploader.setAutoUnzip(unzip);
    this.uploadManager = this.uploader.getUploadManager();
    this.uploadStatusContainer = new DocumentUploadStatus(
      this.$uploadStatusCtn,
      this.uploadManager,
    );
    this.initEvents();
  }

  setAutoUnzip(autoUnzip) {
    this.uploader.setAutoUnzip(autoUnzip);
  }

  updateTable() {
    if (this.fetchLock === null) {
      this.fetchLock = setTimeout(() => {
        if (this.list !== null) {
          this.list.updateTable(true, () => {
            clearTimeout(this.fetchLock);
            this.fetchLock = null;
          });
        }
      }, 1000);
    }
  }

  initEvents() {
    this.$uploaderCtn.on(UploadStatus.COMMIT, () => {
      this.updateTable();
      this.updateUploadStatusDiv();
    });

    this.$uploaderCtn.on(UploadStatus.ERROR, () => this.updateUploadStatusDiv());

    this.$uploaderCtn.on(UploadStatus.ADDED, () => {
      if (!this.isUploading) {
        this.startUpload();
      }
      this.updateUploadStatusDiv();
    });

    this.$uploaderCtn.on(UploadStatus.PROGRESS, () => this.updateUploadStatusDiv());

    this.$uploaderCtn.on(UploadStatus.COMPLETE, () => {
      this.uploadStatusContainer.clearUiUpdateLock();
      this.completeUpload();
    });
  }

  startUpload() {
    // this.documentQueue.startUpload();
    this.uploadStatusContainer.reset();
    this.isUploading = true;
  }

  updateUploadStatusDiv() {
    this.uploadStatusContainer.update();
  }

  completeUpload() {
    this.isUploading = false;
    this.uploadStatusContainer.complete();
  }
}

$(() => {
  const $correspondenceFileContainer = $('.correspondence-file-container');

  if ($correspondenceFileContainer.length) {
    const $docTable = $('table.docUserCorrespondenceDataTable');
    const $target = $correspondenceFileContainer.find('.correspondence-file-uploader');
    let docTable = null;

    $.each($docTable, (i, table) => {
      const dataTable = initUserCorrespondenceDocTable(table);
      if ($(table).hasClass('qq-upload-list')) {
        docTable = dataTable;
      }
    });
    if ($target.length && $docTable.length) {
      const unzip = !($.cookie('auto_unzip') === 'off');

      const uploader = new UserCorrespondenceFileUploadModule(
        $target,
        $target,
        docTable,
        $target.data('correspondence'),
        $target.data('key'),
        null,
        unzip,
      );

      $correspondenceFileContainer.find('.auto_unzip').prop('checked', unzip);

      $('body').on('change', '.auto_unzip', ({ currentTarget }) => {
        const isChecked = !!$(currentTarget).is(':checked');
        $.cookie('auto_unzip', isChecked ? 'on' : 'off', {
          expires: 1,
          path: '/',
          domain: 'estimateone.com',
        });
        uploader.setAutoUnzip(isChecked);
      });
    }
  }
});
