import {
  StageCategorySelectField,
  StageCategorySelectOption,
} from '../../../../common/FinalForm/StageCategorySelectField';
import { TextInputField } from '../../../../../shared/FinalForm/Field';
import { TenderHandoverWizardFieldName } from '../../enums';
import styles from './styles.scss';

export const ProjectDetailsFields = () => (
  <div className={styles.projectDetailsFields}>
    <div>
      <TextInputField
        id="tender-handover-wizard--project-name"
        label="Project Name"
        fieldName={TenderHandoverWizardFieldName.ProjectName}
        validate={(value) => (!value ? ['Enter a project name'] : undefined)}
        isRequired
      />
    </div>
    <div>
      <StageCategorySelectField
        id="tender-handover-wizard--project-category"
        label="Category"
        fieldName={TenderHandoverWizardFieldName.ProjectCategory}
        validate={(value: StageCategorySelectOption) =>
          !value ? ['Select a category'] : undefined
        }
        isRequired
      />
    </div>
    <div>
      <TextInputField
        id="tender-handover-wizard--internal-ref"
        label="Internal Ref #"
        fieldName={TenderHandoverWizardFieldName.InternalRef}
      />
    </div>
  </div>
);
