import { useEffect, useMemo } from 'react';
import { Form } from 'react-final-form';
import { Button, ButtonSize, ButtonVariant, Col, Row } from '@estimateone/frontend-components';
import setFieldTouched from 'final-form-set-field-touched';
import omit from 'lodash/omit';
import { Heading } from '../../../../fec';
import { getViewRouteForQuoteId } from '../utils';
import CompleteQuoteFormQuoteSection from './CompleteQuoteFormQuoteSection';
import PackageContactField from './PackageContactField';
import { validatePackageContactFields } from './validators';
import GlobalErrorList from '../../../../shared/FinalForm/GlobalErrorList';
import { FileManagerParams } from '@shared/FileUploader/types';
import { StageDetails } from '@shared/Quotes/Form/ProjectOrStageDetails';
import { focusOnQuoteErrorsDecorator } from '@shared/Quotes/Form/decorators';
import { validateQuoteFormCommonFields } from '@shared/Quotes/Form/validators';
import { Action, Event, useAnalytics } from '../../../../hooks/Analytics';
import { useCompleteQuote } from './hooks';
import { CompleteQuoteFormSpecificFieldName } from './enums';
import { QuoteFormCommonFieldName } from '@ascension/enums';
import { QuoteToComplete_quote_package as Package } from '../../../pages/Quotes/CompleteQuotePage/types/QuoteToComplete';
import { ActivePackage } from './PackageContactField/types';
import { CompleteQuoteFormErrors, CompleteQuoteFormValues } from './types';
import { DownloadableQuoteFile } from '@api/fragments/Quotes/types';
import { EntityId } from '@ascension/types';
import styles from '../../../../shared/Quotes/Form/styles.scss';

type CompleteQuoteFormProps = {
  quoteId: EntityId;
  existingPackage?: Package;
  projectAddress: string;
  stageId: EntityId;
  stageName: string;
  packages: ActivePackage[];
  returnUrl: string;
} & Pick<FileManagerParams, 'fileManagerLocale'> & { quoteFile: DownloadableQuoteFile };

const FORM_ID = 'builder-complete-quote-form';

const validate = (values: CompleteQuoteFormValues): CompleteQuoteFormErrors => {
  const commonErrors = omit(
    validateQuoteFormCommonFields(values),
    QuoteFormCommonFieldName.QuoteFile,
  );
  const packageContactErrors = validatePackageContactFields(values);

  return { ...commonErrors, ...packageContactErrors };
};

const CompleteQuoteForm = ({
  fileManagerLocale,
  quoteFile,
  quoteId,
  existingPackage,
  projectAddress,
  stageId,
  stageName,
  packages,
  returnUrl,
}: CompleteQuoteFormProps) => {
  const focusOnErrors = useMemo(
    () =>
      focusOnQuoteErrorsDecorator<CompleteQuoteFormValues, CompleteQuoteFormErrors>(FORM_ID, {
        customOffsets: {
          [CompleteQuoteFormSpecificFieldName.Package]: 10,
          [CompleteQuoteFormSpecificFieldName.Company]: 10,
          [CompleteQuoteFormSpecificFieldName.Contact]: 10,
        },
      }),
    [],
  );

  const { addEvent } = useAnalytics(Event.INTERACT);
  const trackInteraction = (action: Action) =>
    addEvent({ action, stage_id: stageId, quote_id: quoteId });

  const { submit: completeQuote, completedQuoteId } = useCompleteQuote(quoteId);

  useEffect(() => {
    if (completedQuoteId) {
      window.location.replace(getViewRouteForQuoteId(completedQuoteId));
    }
  }, [completedQuoteId]);

  return (
    <Form<CompleteQuoteFormValues>
      onSubmit={completeQuote}
      initialValues={{
        [CompleteQuoteFormSpecificFieldName.Package]: existingPackage
          ? {
              id: existingPackage.id,
              name: existingPackage.title,
            }
          : undefined,
        [QuoteFormCommonFieldName.ExtraFiles]: [],
      }}
      decorators={[focusOnErrors]}
      mutators={{ setFieldTouched }}
      validate={(values): CompleteQuoteFormErrors => validate(values)}
      keepDirtyOnReinitialize
    >
      {({ handleSubmit, submitting, submitError, values }) => (
        <form id={FORM_ID} onSubmit={handleSubmit}>
          <Row>
            <Col span={12}>
              {submitError && <GlobalErrorList errors={submitError} />}

              <div className={styles.subHeader}>
                Upload quote files and complete the details below to submit the quote.
              </div>

              <div className={styles.sectionHeading}>
                <Heading level={2}>Project details</Heading>
              </div>
              <StageDetails name={stageName} addressString={projectAddress} />
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <PackageContactField packages={packages} onAnalyticsEvent={trackInteraction} />
            </Col>
          </Row>

          <CompleteQuoteFormQuoteSection
            fileManagerLocale={fileManagerLocale}
            parentId={stageId}
            quoteFile={quoteFile}
          />

          <Row>
            <Col span={12}>
              <hr />
            </Col>
          </Row>

          <Row>
            <Col span={2}>
              <Button href={returnUrl} size={ButtonSize.Large} variant={ButtonVariant.Grey}>
                Cancel
              </Button>
            </Col>
            <Col span={4} offset={6}>
              <Button
                size={ButtonSize.Large}
                fullWidth
                disabled={
                  submitting ||
                  Boolean(values[CompleteQuoteFormSpecificFieldName.Contact]?.mostRecentQuoteId)
                }
              >
                Submit
              </Button>
            </Col>
          </Row>
        </form>
      )}
    </Form>
  );
};

export default CompleteQuoteForm;
