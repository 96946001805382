import { useMemo, useContext } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { UniversalProfileContext } from './UniversalProfileProvider';
import {
  CREATE_NETWORK_PROFILE_UPDATE_REQUEST,
  CREATE_SYNCED_PROFILE_UPDATE_REQUEST,
} from './mutation';
import { isAddressBookCompany, isShadowProfile } from './utils';
import useFlashMessage from '@shared/Util/useFlashMessage';
import { GET_SYNCED_PROFILE_UPDATE_REQUEST, GET_NETWORK_PROFILE_UPDATE_REQUEST } from './queries';
import {
  CreateNetworkProfileUpdateRequest,
  CreateNetworkProfileUpdateRequestVariables,
} from '@builder/pages/Profiles/UniversalProfileDrawer/providers/UniversalProfileProvider/types/CreateNetworkProfileUpdateRequest';
import {
  CreateSyncedProfileUpdateRequest,
  CreateSyncedProfileUpdateRequestVariables,
} from '@builder/pages/Profiles/UniversalProfileDrawer/providers/UniversalProfileProvider/types/CreateSyncedProfileUpdateRequest';
import {
  ProfileUpdateRequestByAccountProfileId,
  ProfileUpdateRequestByAccountProfileIdVariables,
} from '@builder/pages/Profiles/UniversalProfileDrawer/providers/UniversalProfileProvider/types/ProfileUpdateRequestByAccountProfileId';
import {
  ProfileUpdateRequestByAddressBookCompanyId,
  ProfileUpdateRequestByAddressBookCompanyIdVariables,
} from '@builder/pages/Profiles/UniversalProfileDrawer/providers/UniversalProfileProvider/types/ProfileUpdateRequestByAddressBookCompanyId';

export const useUniversalProfileContext = () => {
  const ctx = useContext(UniversalProfileContext);
  if (!ctx) {
    throw new Error('useUniversalProfileContext must be used within a UniversalProfileProvider');
  }

  return ctx;
};

export const useCompanyLogoURL = () => {
  const hash = useMemo(() => Date.now(), []);
  const ctx = useUniversalProfileContext();

  if (!ctx.universalProfile || isAddressBookCompany(ctx.universalProfile)) {
    return undefined;
  }

  return `${ctx.universalProfile.profile.logoUrl}?t=${hash}`;
};

export const useIsShadowProfile = () => {
  const { universalProfile } = useUniversalProfileContext();
  if (!universalProfile) {
    return undefined;
  }
  return isShadowProfile(universalProfile);
};

export const useSyncedProfileUpdateRequest = (addressBookCompanyId: number) => {
  const { data, error, loading } = useQuery<
    ProfileUpdateRequestByAddressBookCompanyId,
    ProfileUpdateRequestByAddressBookCompanyIdVariables
  >(GET_SYNCED_PROFILE_UPDATE_REQUEST, {
    variables: { addressBookCompanyId },
    skip: !addressBookCompanyId,
  });

  if (loading) {
    return {
      profileUpdateRequest: null,
      loading,
    };
  }

  if (error) {
    return {
      error: new Error('Failed to load profile update request'),
      loading: false,
    };
  }

  return {
    profileUpdateRequest: data?.profileUpdateRequestByAddressBookCompanyId || null,
    loading: false,
  };
};

export const useNetworkProfileUpdateRequest = (accountProfileId: number) => {
  const { data, error, loading } = useQuery<
    ProfileUpdateRequestByAccountProfileId,
    ProfileUpdateRequestByAccountProfileIdVariables
  >(GET_NETWORK_PROFILE_UPDATE_REQUEST, {
    variables: { accountProfileId },
    skip: !accountProfileId,
  });

  if (loading) {
    return {
      profileUpdateRequest: null,
      loading,
    };
  }

  if (error) {
    return {
      error: new Error('Failed to load profile update request'),
      loading: false,
    };
  }

  return {
    profileUpdateRequest: data?.profileUpdateRequestByAccountProfileId || null,
    loading: false,
  };
};

export const useCreateSyncedProfileUpdateRequest = () => {
  const { success: showSuccess, warning: showError } = useFlashMessage();
  const [createSyncedProfileUpdateRequest, { error, loading }] = useMutation<
    CreateSyncedProfileUpdateRequest,
    CreateSyncedProfileUpdateRequestVariables
  >(CREATE_SYNCED_PROFILE_UPDATE_REQUEST, {
    update(cache, { data }, { variables }) {
      if (data && variables) {
        const networkProfileRequest = data.createSyncedProfileUpdateRequest;
        const { addressBookCompanyId } = variables;

        if (networkProfileRequest?.__typename === 'NetworkProfileRequest') {
          // Note: it is likely that there will be no original record in the cache for this query
          // as the query will return null if no profile update request exists. This means we cannot
          // use the E1 updateCacheForQuery hook as that checks that the cached data to update
          // already exists, whereas we need to insert a new record into the cache representing the
          // new profile update request created in this mutation.
          cache.writeQuery<
            ProfileUpdateRequestByAddressBookCompanyId,
            ProfileUpdateRequestByAddressBookCompanyIdVariables
          >({
            query: GET_SYNCED_PROFILE_UPDATE_REQUEST,
            variables: { addressBookCompanyId },
            data: {
              profileUpdateRequestByAddressBookCompanyId: networkProfileRequest,
            },
          });
        }
      }
    },
  });

  const handleCreateSyncedProfileUpdateRequest = async (addressBookCompanyId: number) => {
    const result = await createSyncedProfileUpdateRequest({
      variables: {
        addressBookCompanyId,
      },
    });

    if (result && result.data) {
      const requestResult = result.data.createSyncedProfileUpdateRequest;
      if (requestResult) {
        showSuccess({
          title: 'Success',
          message: `You have requested company information from ${requestResult.toAccountProfile.name}`,
        });
      }
      return;
    }
    showError({
      title: 'Error',
      message:
        'Something went wrong when requesting information from this company. Please try again.',
    });
  };
  return {
    createSyncedProfileUpdateRequest: handleCreateSyncedProfileUpdateRequest,
    error,
    loading,
  };
};

export const useCreateNetworkProfileUpdateRequest = () => {
  const { success: showSuccess, warning: showError } = useFlashMessage();
  const [createNetworkProfileUpdateRequest, { error, loading }] = useMutation<
    CreateNetworkProfileUpdateRequest,
    CreateNetworkProfileUpdateRequestVariables
  >(CREATE_NETWORK_PROFILE_UPDATE_REQUEST, {
    update(cache, { data }, { variables }) {
      if (data && variables) {
        const networkProfileRequest = data.createNetworkProfileUpdateRequest;
        const { accountProfileId } = variables;

        if (networkProfileRequest?.__typename === 'NetworkProfileRequest') {
          cache.writeQuery<
            ProfileUpdateRequestByAccountProfileId,
            ProfileUpdateRequestByAccountProfileIdVariables
          >({
            query: GET_NETWORK_PROFILE_UPDATE_REQUEST,
            variables: { accountProfileId },
            data: {
              profileUpdateRequestByAccountProfileId: networkProfileRequest,
            },
          });
        }
      }
    },
  });

  const handleCreateNetworkProfileUpdateRequest = async (accountProfileId: number) => {
    const result = await createNetworkProfileUpdateRequest({
      variables: {
        accountProfileId,
      },
    });

    if (result && result.data) {
      const requestResult = result.data.createNetworkProfileUpdateRequest;
      if (requestResult) {
        showSuccess({
          title: 'Success',
          message: `You have requested company information from ${requestResult.toAccountProfile.name}`,
        });
      }
      return;
    }
    showError({
      title: 'Error',
      message:
        'Something went wrong when requesting information from this company. Please try again.',
    });
  };
  return {
    createNetworkProfileUpdateRequest: handleCreateNetworkProfileUpdateRequest,
    error,
    loading,
  };
};
