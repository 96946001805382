import { useField } from 'react-final-form';
import { RadioButton } from '@estimateone/frontend-components';
import { RadioButtonGroupField } from '@shared/FinalForm/Field/RadioButtonGroupField';
import { UpdateStageStatusFieldName } from '@builder/features/UpdateTenderStatusModal/enums';
import { UpdateAwardedStatusFormValues } from '@builder/features/UpdateTenderStatusModal/types';
import styles from '../../styles.scss';

export enum NotifyOptionType {
  ALL = 'all',
  QUOTED = 'quoted',
  NONE = 'none',
}
const NotifyOptionsAwarded = () => {
  const optionsMap = [
    {
      id: NotifyOptionType.ALL,
      label: 'All subcontractors and suppliers',
    },
    {
      id: NotifyOptionType.QUOTED,
      label: 'Only subcontractors and suppliers marked as ‘Quoted’',
    },
    {
      id: NotifyOptionType.NONE,
      label: 'Don’t send any notification',
    },
  ];

  const {
    input: { onChange, value: notifyOption },
  } = useField<UpdateAwardedStatusFormValues[UpdateStageStatusFieldName.NotifyOption]>(
    UpdateStageStatusFieldName.NotifyOption,
  );
  const handleChange = (selectedOption: string) => {
    onChange(selectedOption);
  };

  return (
    <ul className={styles.checkboxContainer}>
      <li className={styles.checkboxListItem}>
        <RadioButtonGroupField
          fieldName={UpdateStageStatusFieldName.NotifyOption}
          legend=""
          value={notifyOption}
          onChange={handleChange}
          className={styles.awardedSubbieRadioButtonGroupWrapper}
        >
          {optionsMap.map(({ id, label }) => (
            <RadioButton key={id} id={id} value={id}>
              {label}
            </RadioButton>
          ))}
        </RadioButtonGroupField>
      </li>
    </ul>
  );
};

export default NotifyOptionsAwarded;
