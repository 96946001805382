import { Button, ButtonVariant, Icon, IconName, Row } from '@estimateone/frontend-components';
import styles from './styles.scss';

type ManualMergeActionsProps = { clearSelection: () => void };

const ManualMergeActions = ({ clearSelection }: ManualMergeActionsProps) => (
  <div className={styles.manualMergeActions}>
    <Row>
      <Button fullWidth isDisabled>
        Preview merge
        <Icon name={IconName.ArrowRight} marginLeft="small" size="0.8em" />
      </Button>
    </Row>
    <Row>
      <Button fullWidth isDisabled>
        <Icon name={IconName.ArrowLeft} marginRight="small" size="0.8em" />
        Preview merge
      </Button>
    </Row>
    <Row>
      <Button variant={ButtonVariant.RedOutline} fullWidth onClick={clearSelection}>
        Clear
      </Button>
    </Row>
  </div>
);

export default ManualMergeActions;
