import { gql } from '@apollo/client';

export const ADD_LETTING_SCHEDULE_NOTE = gql`
  mutation AddNoteToLettingScheduleDetails($input: AddNoteToLettingScheduleDetailsInput!) {
    addNoteToLettingScheduleDetails(input: $input) {
      ... on LettingScheduleNote {
        id
        text
        createdAt
        authorName
        isCritical
      }
      ... on Errors {
        errors {
          field
          message
        }
      }
    }
  }
`;
