import { PROJECT_STATUS_LABELS } from '../const';
import { getRange } from '@shared/BudgetRange';
import {
  calcUserProjectDistance,
  getUserDisplayUnits,
  Locatable,
  LocatableUser,
} from '@shared/Util/distance';
import { getCurrencyInfo } from '@ascension/enums';
import { Project } from '../types';
import { ProjectStatus } from '@ascension/_gqltypes/subbie.generated';
import styles from './styles.scss';

type ProjectHeaderDetailsProps = {
  project: Pick<
    Project,
    'status' | 'awardedTenderBudget' | 'countryId' | 'maxTenderBudget' | 'stageCategoryName'
  > &
    Locatable;
  user: LocatableUser;
};

const ProjectHeaderDetails = ({ project, user }: ProjectHeaderDetailsProps) => {
  const { status, awardedTenderBudget, countryId, maxTenderBudget, stageCategoryName } = project;
  const statusLabel = PROJECT_STATUS_LABELS[status];

  const budgetLabel = status === ProjectStatus.AWARDED ? 'Budget' : 'Max Budget';

  const displayedBudget =
    status === ProjectStatus.AWARDED && typeof awardedTenderBudget === 'number'
      ? awardedTenderBudget
      : maxTenderBudget;

  return (
    <div className={styles.projectDetailsContainer}>
      <div>
        <span>Tender Status</span>
        <h3>{statusLabel}</h3>
      </div>
      <div>
        <span>Distance</span>
        <h3>
          {calcUserProjectDistance(user, project)}
          {getUserDisplayUnits(user)}
        </h3>
      </div>
      <div>
        <span>{budgetLabel}</span>
        <h3>{getRange(displayedBudget, getCurrencyInfo(countryId).currencySymbol)}</h3>
      </div>
      <div>
        <span>Category</span>
        <h3>{stageCategoryName}</h3>
      </div>
    </div>
  );
};

export default ProjectHeaderDetails;
