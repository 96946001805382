import { useMutation } from '@apollo/client';
import { REVOKE_RESTRICTED_STAGE_ACCESS } from '../mutations';
import {
  RevokeRestrictedStageAccess,
  RevokeRestrictedStageAccessVariables,
} from '../types/RevokeRestrictedStageAccess';
import { EntityId } from '@ascension/types';

export const useRevokeRestrictedStageAccess = (stageId: EntityId) => {
  const [mutate, { loading }] = useMutation<
    RevokeRestrictedStageAccess,
    RevokeRestrictedStageAccessVariables
  >(REVOKE_RESTRICTED_STAGE_ACCESS);

  const revokeRestrictedStageAccess = async (userId: number) => {
    const result = await mutate({
      variables: {
        input: {
          stageId,
          userId,
        },
      },
    });

    return result?.data?.revokeRestrictedStageAccess;
  };

  return { revokeRestrictedStageAccess, loading };
};
