import { ReactNode } from 'react';
import Tippy from '@tippyjs/react';
import { LettingScheduleStatus } from '../LettingScheduleStatus';
import { LettingStatusTimeline } from './LettingStatusTimeline';
import styles from './styles.scss';

type LettingStatusPopupProps = {
  children?: ReactNode;
  lettingStatus: LettingScheduleStatus;
  awardedCompanyName?: string;
};

export const LettingStatusPopup = ({
  children,
  lettingStatus,
  awardedCompanyName,
}: LettingStatusPopupProps) => (
  <Tippy
    content={
      <LettingStatusTimeline
        lettingStatus={lettingStatus}
        awardedCompanyName={awardedCompanyName}
      />
    }
    placement="right-start"
    delay={300}
    arrow={false}
    offset={[-24, 8]}
    popperOptions={{
      modifiers: [
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['left-start'],
          },
        },
      ],
    }}
  >
    <div className={styles.timelinePopover}>{children}</div>
  </Tippy>
);
