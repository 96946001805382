import { gql } from '@apollo/client';

export const GET_SUB_TRADES_AND_ACCOUNT_TRADES = gql`
  query GetSubTradesAndAccountStockTradesForChooseSubTrades {
    allSubTradeCategories {
      name
      subTrades {
        id
        name
        stockTrades {
          id
          name
        }
        category {
          name
        }
      }
    }
    currentUser {
      account {
        stockTrades {
          id
          name
        }
      }
    }
    subTradesForCurrentAccount {
      id
    }
  }
`;

export const SET_SUB_TRADES_FOR_ACCOUNT = gql`
  mutation SetSubTradesForCurrentAccount($subTradeIds: [EntityId!]!) {
    setSubTradesForCurrentAccount(subTradeIds: $subTradeIds) {
      id
    }
  }
`;

export const GET_SUB_TRADES_ONBOARDING_MODAL_USER_SETTING = gql`
  query GetSubTradesOnboardingSetting {
    userSetting(settingName: SUB_TRADES_ONBOARDING_MODAL_LAST_DISMISSED) {
      ... on UserSettingNotFoundError {
        __typename
      }
      ... on DateTimeUserSetting {
        value
      }
      ... on UnsetUserSetting {
        __typename
      }
    }
  }
`;

export const SET_SUB_TRADES_ONBOARDING_MODAL_USER_SETTING = gql`
  mutation SetSubTradesOnboardingSetting($setAt: DateTime!) {
    setUserSetting(
      input: {
        settingName: SUB_TRADES_ONBOARDING_MODAL_LAST_DISMISSED
        unset: false
        dateTimeValue: $setAt
      }
    ) {
      ... on DateTimeUserSetting {
        value
      }
    }
  }
`;
