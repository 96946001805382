import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import Routing from 'routing';
import TokenCache from '@api/GatewayProvider/TokenCache';

// eslint-disable-next-line fp/no-let
let client: ApolloClient<NormalizedCacheObject> | undefined;

export const getOrCreateClient = () => {
  if (client) {
    return client;
  }

  const docsyncBffUrl = document.getElementById('docsync-bff-url')?.dataset.docsyncBffUrl;
  if (!docsyncBffUrl) {
    return undefined;
  }

  const idpUrl = Routing.generate('gateway_jwt_authorise');
  const tokenCache = new TokenCache(idpUrl);
  const authLink = setContext(async (_, { headers }) => ({
    headers: {
      authorization: `Bearer ${await tokenCache.getToken()}`,
      ...headers,
    },
  }));

  const docsyncBffHttpLink = new HttpLink({
    uri: docsyncBffUrl,
    fetch,
  });

  // eslint-disable-next-line fp/no-mutation
  client = new ApolloClient({
    link: ApolloLink.from([authLink, docsyncBffHttpLink]),
    cache: new InMemoryCache(),
  });

  return client;
};
