import { E1Link, InfoIcon, Tooltip } from '@estimateone/frontend-components';
import { makePlural } from '@subbie/modal/ProjectSlider/ProjectSliderBody/ProjectSubbies/util';
import { ProjectInterestedSupplierCard } from '../ProjectInterestedSupplierCard';
import { useProjectInterestedSuppliers } from '@subbie/modal/ProjectSlider/ProjectSliderBody/ProjectSuppliers/hooks';
import styles from './styles.scss';

type SubbiesPaywallProps = {
  readonly projectId: number;
  readonly supplierCount: number;
  readonly onSupplierContactClicked: (supplierId: number) => Promise<void>;
  readonly hasOptedOutOfSSC: boolean;
};

const UpdateProfileSettingsLink = () => (
  <div className={styles.optedOutLink}>
    <E1Link href="/profiles#/settings">Update your profile settings to view suppliers</E1Link>
  </div>
);

export const SupplierMatchesList = ({
  projectId,
  supplierCount,
  onSupplierContactClicked,
  hasOptedOutOfSSC,
}: SubbiesPaywallProps) => {
  const projectInterestedSuppliers = useProjectInterestedSuppliers(projectId);

  if (supplierCount === 0) {
    if (hasOptedOutOfSSC) {
      return <UpdateProfileSettingsLink />;
    }

    return (
      <div>
        <p className={styles.projectSuppliersEmpty}>
          Find out when Suppliers indicate their interest in this project by adding it to your
          Watchlist and checking back later.
        </p>
      </div>
    );
  }

  if (projectInterestedSuppliers === undefined) {
    return null;
  }

  const supplierCountSummary = `${supplierCount} ${makePlural(
    supplierCount,
    'supplier',
    'suppliers',
  )} ${makePlural(
    supplierCount,
    'is',
    'are',
  )} ready to assist you with pricing and product information.`;

  const supplierCountTooltip =
    'Suppliers who have nominated themselves on E1 as a specified supplier and/or equivalent supplier on this project. You are responsible for reviewing the suitability of these suppliers.';

  return (
    <>
      <div className={styles.supplierCount}>
        {supplierCountSummary}
        <Tooltip tooltipId="supplier-count-tooltip" place="right" text={supplierCountTooltip}>
          <InfoIcon />
        </Tooltip>
      </div>
      <div className={styles.supplierList}>
        {hasOptedOutOfSSC ? (
          <UpdateProfileSettingsLink />
        ) : (
          projectInterestedSuppliers.map((interestedSupplier) => (
            <ProjectInterestedSupplierCard
              key={interestedSupplier.accountId}
              interestedSupplier={interestedSupplier}
              onContactClicked={onSupplierContactClicked}
            />
          ))
        )}
      </div>
    </>
  );
};
