import $ from 'jquery';
import Routing from 'routing';
import moment from 'moment-timezone';
import _ from 'underscore';
import E1Request from '../classes/E1Request';
import GenericList from '../classes/GenericList';
import Location from '../classes/Location';
import ServerDataList from '../classes/ServerDataList';
import { getShortAddress } from '../utils/address_form';
import { findModalEntry } from '../utils/array';
import { TimezoneAwareDateHelpers } from '../utils/date';
import { truncate } from '../utils/truncate';

$(() => {
  const $projectRestrictedBadge = $('div.restricted-market-intel').clone().removeClass('hide');
  const $marketTables = $('table.project-market-table').add($('table.account-project-table'));

  const showLink = (stages = []) =>
    stages.reduce(
      (acc, stage) => acc && window.global.account.id !== (stage.account ? stage.account.id : 0),
      true,
    );

  if ($marketTables.length > 0) {
    $marketTables.each(function () {
      const $table = $(this);
      const $container = $table.closest('.table-container');
      const $distFilter = $container.find('select.project-distance-filter');

      const $distCol = $table.find('.distance_col');
      const distColIndex = $table.first('tr').find('th').index($distCol);

      const userLat = $table.attr('data-from-lat');
      const userLng = $table.attr('data-from-lng');
      const omitCompetitorDetailsAttr = $table.attr('data-omit-competitor-details');

      const omitCompetitorDetails = parseInt(omitCompetitorDetailsAttr) === 1;
      const { stageCategories } = window.global;

      const url = Routing.generate(
        $table.hasClass('account-project-table')
          ? 'app_market_fetchaccountprojects'
          : 'app_market_fetch',
      );

      const projectTable = new GenericList(
        $table,
        (list) => {
          list.table = list.$target.DataTable({
            locationCol: distColIndex,
            paging: false,
            data: list.data,
            lengthChange: false,
            info: false,
            order: [[1, 'asc']],
            columns: [
              {
                data: null,
                orderable: false,
                class: 'pr-0',
                render(data, row, col, details) {
                  const $template = $(list.$placeholderRow.find('td').get(details.col)).clone();
                  if (showLink(data.stages) && !data.details_incomplete) {
                    const $link = $template.find('a').first();
                    $link.attr(
                      'href',
                      Routing.generate('app_tenderstagewizard_index', { projectId: col.id }),
                    );
                    return $link.prop('outerHTML');
                  }
                  return '';
                },
              },
              {
                data: 'name',
                render: $.fn.dataTable.render.text(),
              },
              {
                data: null,
                render: (data) =>
                  findModalEntry(
                    data.stages
                      .map((stage) => stageCategories[stage.category] || false)
                      .filter(Boolean),
                  ),
              },
              {
                data: 'address',
                render: (data) => getShortAddress(data),
              },
              {
                name: 'distance',
                data: 'distance',
                type: 'num',
                render(data, display) {
                  switch (display) {
                    case 'filter':
                    case 'sort':
                      return data;
                    default:
                      const $spanCtn = $('<div>');
                      const $span = $('<span>').addClass('locator-dist-calc');

                      $span.text(Location.readableDistance(data));

                      $spanCtn.append($span);
                      return $spanCtn.html();
                  }
                },
              },
              {
                data: null,
                orderable: false,
                visible: !omitCompetitorDetails,
                class: 'inline-stages',
                render(data, display, row, details) {
                  switch (display) {
                    case 'sort':
                      return Math.min(
                        data.stages.map((stage) => moment(stage.tenderQuotesDue.date).format('X')),
                      );
                    case 'filter':
                      return data.stages
                        .map((stage) => (stage.account ? stage.account.abbrev : ''))
                        .join(' ');
                    default:
                      break;
                  }

                  const $template = $(list.$placeholderRow.find('td').get(details.col)).clone();
                  const $list = $('<ul>');
                  $list.addClass('small');

                  data.stages.forEach((stage) => {
                    const $listItem = $template.find('li').clone();
                    if (typeof stage.account !== 'undefined') {
                      $listItem
                        .find('span.inline-tenderer')
                        .attr('data-builder-id', stage.account.id)
                        .text(stage.account.abbrev);
                    }

                    $listItem
                      .find('div.dateDue')
                      .append(
                        TimezoneAwareDateHelpers.getInlineQuoteDateSpan(stage.tenderQuotesDue.date),
                      );

                    $list.append($listItem);
                  });

                  const onlyUserAccountOnProject =
                    data.details_incomplete && data.stages.length === 1;

                  if (data.stage_info_restricted && !onlyUserAccountOnProject) {
                    const $restrictedStagesContainer =
                      $('<div>').addClass('stages-vertical-centre');
                    const $upgradeLink = $projectRestrictedBadge.clone();
                    $upgradeLink
                      .find('a.restricted-market-intel-trigger')
                      .attr('data-incomplete', data.details_incomplete)
                      .attr('data-awarded', false)
                      .attr('data-project-id', data.id);

                    $restrictedStagesContainer.append($upgradeLink, $list);
                    return $restrictedStagesContainer.prop('outerHTML');
                  }

                  return $list.prop('outerHTML');
                },
              },
              {
                data: null,
                orderable: false,
                visible: omitCompetitorDetails,
                class: 'small',
                render(data, display, row, details) {
                  switch (display) {
                    case 'sort':
                      return Math.max(
                        data.stages.map((stage) => moment(stage.tenderQuotesDue.date).format('X')),
                      );
                    default:
                      break;
                  }
                  const $dueDateSpan = $('<span>');
                  const maxDate = moment.max(
                    data.stages.map((stage) => moment(stage.tenderQuotesDue.date)),
                  );

                  if (maxDate) {
                    $dueDateSpan.append(TimezoneAwareDateHelpers.getInlineQuoteDateSpan(maxDate));
                  }

                  return $dueDateSpan.prop('outerHTML');
                },
              },
            ],
            dom: 'Rrtp',
          });
          list.toggleTableDisplay(true);
          list.$target.closest('.loading-container').addClass('has-loaded');
        },
        url,
        (data) => data.projects,
        (list, data) => {
          let rawDistance = null;
          if (
            typeof data.address.latitude !== 'undefined' &&
            typeof data.address.longitude !== 'undefined'
          ) {
            const fromLocation = new Location(userLat, userLng);
            rawDistance = fromLocation.distFromLocLng(
              data.address.latitude,
              data.address.longitude,
            );
          } else {
            rawDistance = null;
          }
          data.distance = rawDistance;
          return data;
        },
      );

      if ($distFilter.length > 0) {
        $distFilter.on('change', () => {
          if (projectTable !== null) {
            projectTable.table.draw();
          }
        });
      }
    });
  }

  const $projectTables = $('table.are-you-tendering-table');
  if ($projectTables.length > 0) {
    $projectTables.each(function () {
      const $table = $(this);
      const $container = $table.closest('.table-container');
      const $distFilter = $container.find('select.project-distance-filter');

      const $distCol = $table.find('.distance_col');
      const distColIndex = $table.first('tr').find('th').index($distCol);

      const userLat = $table.attr('data-from-lat');
      const userLng = $table.attr('data-from-lng');
      const omitCompetitorDetailsAttr = $table.attr('data-omit-competitor-details');

      const omitCompetitorDetails = parseInt(omitCompetitorDetailsAttr) === 1;

      const { stageCategories } = window.global;

      const url = $table.attr('data-src');

      const projectTable = new GenericList(
        $table,
        (list) => {
          list.table = list.$target.DataTable({
            locationCol: distColIndex,
            paging: false,
            data: list.data,
            lengthChange: false,
            info: false,
            order: [[1, 'ASC']],
            language: {
              emptyTable: 'There are no active tender suggestions at the moment.',
            },
            columns: [
              {
                visible: !$table.hasClass('account-project-table'),
                data: null,
                orderable: false,
                render(data, row, col, details) {
                  const $template = $(list.$placeholderRow.find('td').get(details.col)).clone();
                  if (showLink(data.stages)) {
                    const $link = $template.find('a').first();
                    $link.attr(
                      'href',
                      Routing.generate('app_tenderstagewizard_index', { projectId: col.id }),
                    );
                    return $link.prop('outerHTML');
                  }
                  return '';
                },
              },
              {
                data: 'name',
                class: 'p-link',
              },
              {
                data: null,
                render: (data) =>
                  findModalEntry(
                    data.stages
                      .map((stage) => stageCategories[stage.category] || false)
                      .filter(Boolean),
                  ),
              },
              {
                data: 'address',
                render: (data) => getShortAddress(data),
              },
              {
                name: 'distance',
                data: 'distance',
                type: 'num',
                render(data, display) {
                  switch (display) {
                    case 'filter':
                    case 'sort':
                      return data;
                    default:
                      const $spanCtn = $('<div>');
                      const $span = $('<span>').addClass('locator-dist-calc');

                      $span.text(Location.readableDistance(data));

                      $spanCtn.append($span);
                      return $spanCtn.html();
                  }
                },
              },
              {
                data: null,
                orderable: false,
                visible: !omitCompetitorDetails,
                class: 'inline-stages',
                render(data, display, row, details) {
                  switch (display) {
                    case 'sort':
                      return data.stages.map((stage) => stage.account.abbrev).join(' ');
                    case 'filter':
                      return Math.min(
                        data.stages.map((stage) => moment(stage.tenderQuotesDue.date).format('X')),
                      );
                    default:
                      break;
                  }

                  if (data.stage_info_restricted) {
                    const $upgradeLink = $projectRestrictedBadge.clone();
                    $upgradeLink
                      .find('a.restricted-market-intel-trigger')
                      .attr('data-awarded', false)
                      .attr('data-project-id', data.id);

                    return $upgradeLink.prop('outerHTML');
                  }

                  const $template = $(list.$placeholderRow.find('td').get(details.col)).clone();
                  const $list = $('<ul>');
                  $list.addClass('small');

                  data.stages.forEach((stage) => {
                    const $listItem = $template.find('li').clone();
                    $listItem
                      .find('span')
                      .attr('data-builder-id', stage.account.id)
                      .text(stage.account.abbrev);

                    $listItem
                      .find('div.dateDue')
                      .append(
                        TimezoneAwareDateHelpers.getInlineQuoteDateSpan(stage.tenderQuotesDue.date),
                      );

                    $list.append($listItem);
                  });

                  return $list.prop('outerHTML');
                },
              },
              {
                data: null,
                orderable: false,
                visible: omitCompetitorDetails,
                class: 'small',
                render(data, display, row, details) {
                  switch (display) {
                    case 'sort':
                      return Math.max(
                        data.stages.map((stage) => moment(stage.tenderQuotesDue.date).format('X')),
                      );
                    default:
                      break;
                  }
                  const $dueDateSpan = $('<span>');
                  const maxDate = moment.max(
                    data.stages.map((stage) => moment(stage.tenderQuotesDue.date)),
                  );

                  if (maxDate) {
                    $dueDateSpan.append(TimezoneAwareDateHelpers.getInlineQuoteDateSpan(maxDate));
                  }

                  return $dueDateSpan.prop('outerHTML');
                },
              },
            ],
            dom: 'Rrtp',
          });
          list.$target.closest('.loading-container').addClass('has-loaded');
        },
        url,
        (data) => data.projects,
        (list, data) => {
          data.distance = null;
          if (
            typeof data.address.latitude !== 'undefined' &&
            typeof data.address.longitude !== 'undefined'
          ) {
            data.distance = new Location(userLat, userLng).distFromLocLng(
              data.address.latitude,
              data.address.longitude,
            );
          }

          return data;
        },
      );

      if ($distFilter.length > 0) {
        $distFilter.on('change', () => {
          projectTable.table.draw();
        });
      }
    });
  }

  const $awardedTables = $('table.market-awarded-project-table');
  if ($awardedTables.length > 0) {
    $awardedTables.each(function () {
      const $table = $(this);
      const $container = $table.closest('.table-container');
      const $distFilter = $container.find('select.project-distance-filter');

      const $distCol = $table.find('.distance_col');
      const distColIndex = $table.first('tr').find('th').index($distCol);

      const url = $table.attr('data-src');

      const awardedTable = new ServerDataList($table, url, (list) => {
        list.table = list.$target
          .DataTable({
            locationCol: distColIndex,
            pageLength: 25,
            processing: true,
            serverSide: true,
            searchCols: [null, null, { search: $distFilter.val() }, null, null],
            ajax(data, cb, settings) {
              list.serverRequest(data, cb, settings);
            },
            order: [[4, 'desc']],
            columns: [
              {
                data: 'p.name',
                name: 'project.name',
                width: 270,
                render: (data) => _.escape(data),
              },
              {
                data: 'p.address',
                name: 'address.suburb',
                width: 170,
                render: (data) => getShortAddress(data),
              },
              {
                data: 'distance',
                name: 'distance',
                type: 'num',
                width: 70,
                render(data, display) {
                  switch (display) {
                    case 'filter':
                    case 'sort':
                      return data;
                    default:
                      if (data) {
                        const $spanCtn = $('<div>');
                        const $span = $('<span>').addClass('locator-dist-calc');

                        $span.text(Location.readableDistance(data / 1000));

                        $spanCtn.append($span);
                        return $spanCtn.html();
                      }
                      return '-';
                  }
                },
              },
              {
                data: null,
                orderable: false,
                width: 170,
                render(data) {
                  if (data.p.stage_info_restricted) {
                    const $awardedRestrictedBadge = $projectRestrictedBadge.clone();

                    $awardedRestrictedBadge
                      .find('a.restricted-market-intel-trigger')
                      .attr('data-awarded', true)
                      .attr('data-project-id', data.p.id);

                    return $awardedRestrictedBadge.prop('outerHTML');
                  }

                  return (
                    data.p.stages
                      // eslint-disable-next-line no-confusing-arrow
                      .map((stage) => (stage.status === 2 ? _.escape(stage.account.name) : null))
                      .filter(Boolean)
                      .join(', ')
                  );
                },
              },
              {
                data: 'p.awarded_at',
                width: 100,
                name: 'stage.awardedAt',
                orderable: true,
                render(data, display) {
                  const utcDate = moment.utc(data);
                  return display === 'display' ? utcDate.fromNow() : utcDate.format('X');
                },
              },
            ],
          })
          .on('init.dt', () => {
            list.$container.addClass('has-loaded');
          });
      });
      if ($distFilter.length > 0) {
        $distFilter.on('change', () => {
          awardedTable.table.column(distColIndex).search($distFilter.val()).draw();
        });
      }
    });
  }

  $('body').on('click', '.restricted-market-intel-trigger', (upgradeTriggered) => {
    const $trigger = $(upgradeTriggered.currentTarget);
    const projectId = $trigger.data('project-id');
    const marketIntelCompleteDetailsModalUrl = Routing.generate(
      'app_upgrade_marketintel_complete_details',
      { id: projectId },
    );
    const marketIntelUpgradeModalUrl = Routing.generate('app_upgrade_marketintel', {
      id: projectId,
    });
    const showModalRequest = new E1Request(
      $trigger.data('incomplete') ? marketIntelCompleteDetailsModalUrl : marketIntelUpgradeModalUrl,
    );
    showModalRequest.submit();
  });
});
