import {
  IconSize,
  InfoIcon,
  Modal,
  ModalSize,
  ProgressBar,
} from '@estimateone/frontend-components';
import styles from './styles.scss';

export type InvitesProgressModalProps = {
  isOpen: boolean;
  sentInvitationsCount: number;
  totalInvitationsCount: number;
  onRequestClose: () => void;
};
export const InvitesProgressModal = ({
  isOpen,
  totalInvitationsCount,
  sentInvitationsCount,
  onRequestClose,
}: InvitesProgressModalProps) => {
  const progress = Number(((sentInvitationsCount / totalInvitationsCount) * 100).toFixed(0));

  return (
    <Modal
      size={ModalSize.Small}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      hideClose
      reactModalProps={{ shouldCloseOnOverlayClick: false, shouldCloseOnEsc: false }}
    >
      <Modal.Section>
        <p className={styles.title}>{`Sending ${totalInvitationsCount} invitations...`}</p>
        <p className={styles.addressBookMessage}>
          <InfoIcon size={IconSize.Mini} />
          Subbies invited from the Network will be added to your Address Book.
        </p>
        <ProgressBar
          title="Progress packages"
          percentage={progress}
          text={`${sentInvitationsCount} of ${totalInvitationsCount} invitations`}
        />
      </Modal.Section>
    </Modal>
  );
};
