import { Col, Row, Heading, E1Link, LinkVariant } from '@estimateone/frontend-components';
import moment from 'moment';
import OfficeLocation from '../../../../../../shared/Profiles/OfficeLocation';
import ProfilesAvatar from '../../../../../../shared/Profiles/ProfilesAvatar';
import TraedeTagGroup from '../../../../../../shared/Profiles/TradeTagGroup';
import { SubbieProfileForAccount_subbieProfileForAccount as SubbieProfile } from '../../../../../pages/Profiles/SubbieProfilesPage/types/SubbieProfileForAccount';
import styles from './styles.scss';

type ProfilesHeaderProps = {
  profile: SubbieProfile;
};

export const SubbieProfilesSliderHeader = ({
  profile: { name, memberSince, lastActiveAt },
}: ProfilesHeaderProps) => {
  const dateOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  const formatDate = (dateString?: string): string =>
    dateString
      ? moment(new Date(dateString).toLocaleDateString('en-au', dateOptions)).fromNow()
      : '';
  return (
    <div>
      <Heading level={1}>{name}</Heading>
      <div className={styles.summaryRow}>
        <span>Joined E1:&nbsp;{formatDate(memberSince)}</span>
        {lastActiveAt && <span>&nbsp;| Last Active:&nbsp;{formatDate(lastActiveAt)}</span>}
      </div>
    </div>
  );
};

export const OldSubbieProfilesSliderHeader = ({
  profile: {
    name,
    defaultOffice: { email, phone },
    accountOffices,
    trades,
  },
}: ProfilesHeaderProps) => (
  <Row>
    <Col span={2}>
      <ProfilesAvatar name={name} />
    </Col>
    <Col span={7}>
      <div className={styles.companyInfoContainer}>
        <Heading level={1}>{name}</Heading>
        <div className={styles.officeLocation}>
          <OfficeLocation offices={accountOffices} />
        </div>
        <div>
          <E1Link variant={LinkVariant.Primary} link={`mailto:${email}`}>
            <span className={styles.emailText}>{email}</span>
          </E1Link>
        </div>
        <div>
          <E1Link variant={LinkVariant.Primary} link={`tel:${phone}`}>
            <span className={styles.phoneText}>{phone}</span>
          </E1Link>
        </div>
      </div>
    </Col>

    <Col span={3}>
      <TraedeTagGroup trades={trades} maxTrades={2} isAbbreviated />
    </Col>
  </Row>
);
