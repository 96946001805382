/* eslint-disable react/jsx-props-no-spreading */
import { useField } from 'react-final-form';
import { TextInput, TextInputProps } from '@estimateone/frontend-components';
import { FieldWrapper } from '../FieldWrapper';

export type InputFieldWrapperProps<T extends string> = {
  field: string;
  initialValue?: T;
  validator?: (value?: T) => string[] | undefined;
} & TextInputProps;

export const InputFieldWrapper = <T extends string>({
  field,
  id,
  label,
  initialValue,
  validator,
  ...props
}: InputFieldWrapperProps<T>) => {
  const {
    input,
    meta: { error, submitError, touched },
  } = useField(field, {
    initialValue,
    validate: validator ?? undefined,
  });

  return (
    <FieldWrapper errors={error || submitError} hideErrorMessages={false} showErrors={touched}>
      <TextInput id={id} label={label} {...input} {...props} />
    </FieldWrapper>
  );
};
