import Routing from 'routing';
import { Icon, IconName, Tag, TagVariant } from '@estimateone/frontend-components';
import Link from '../../../../../../../../shared/Link';
import { QuoteReturnRoute } from '@ascension/enums';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

export const QuotedTagAndQuoteLink = ({ mostRecentQuoteId }: { mostRecentQuoteId: EntityId }) => (
  <div className={styles.quotedTagAndLink}>
    <span>
      <Tag
        variant={TagVariant.RfqStatusQuoted}
        text="Quoted"
        icon={<Icon name={IconName.File} />}
      />
    </span>
    <span className={styles.link}>
      <Link
        href={Routing.generate('app_quote_view', {
          id: mostRecentQuoteId,
          returnTo: QuoteReturnRoute.ProjectSliderSingleStage,
        })}
      >
        view quote
      </Link>
    </span>
  </div>
);
