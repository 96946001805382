import { useMutation } from '@apollo/client';
import { GraphQLFormattedError } from 'graphql/error';
import {
  INVITE_BUILDER_CONTACTS_TO_PACKAGE,
  INVITE_NETWORK_CONTACTS_TO_PACKAGE,
} from './mutations';
import {
  InviteBuilderContactsToPackageMutationVariables,
  InviteNetworkContactsToPackageMutation,
  InviteNetworkContactsToPackageMutationVariables,
} from './mutations.generated';
import {
  InviteBuilderContactsToPackage,
  InviteBuilderContactsToPackage_inviteBuilderContactsToPackage as BuilderInviteData,
  InviteBuilderContactsToPackage_inviteBuilderContactsToPackage_Errors as BuilderErrors,
} from './types/InviteBuilderContactsToPackage';
import {
  InviteNetworkContactsToPackage_inviteNetworkContactsToPackage as NetworkInviteData,
  InviteNetworkContactsToPackage_inviteNetworkContactsToPackage_Package as Package,
  InviteNetworkContactsToPackage_inviteNetworkContactsToPackage_Errors as NetworkErrors,
} from './types/InviteNetworkContactsToPackage';

type CombinedInviteVariables = InviteNetworkContactsToPackageMutationVariables &
  InviteBuilderContactsToPackageMutationVariables;
type ErrorWithMessage = Pick<GraphQLFormattedError, 'message'>;

const isNetworkInviteErrors = (data: NetworkInviteData | undefined): data is NetworkErrors =>
  data?.__typename === 'Errors';
const isBuilderInviteErrors = (data: BuilderInviteData | undefined): data is BuilderErrors =>
  data?.__typename === 'Errors';
const concatenateErrors = (...errors: ErrorWithMessage[]) => errors.map((e) => e.message).join(' ');

export const useInviteContactsToPackage = () => {
  const [sendNetworkInvitations, { loading: loadingNetworkInvitations }] = useMutation<
    InviteNetworkContactsToPackageMutation,
    InviteNetworkContactsToPackageMutationVariables
  >(INVITE_NETWORK_CONTACTS_TO_PACKAGE);

  const [sendBuilderInvitations, { loading: loadingBuilderInvitations }] = useMutation<
    InviteBuilderContactsToPackage,
    InviteBuilderContactsToPackageMutationVariables
  >(INVITE_BUILDER_CONTACTS_TO_PACKAGE);

  const submitNetworkInvitations = async (
    variables: InviteNetworkContactsToPackageMutationVariables,
  ) => {
    const shouldInviteProfiles = variables.userIds.length > 0;

    if (!shouldInviteProfiles) {
      return { data: null, errors: null };
    }

    const { data, errors } = await sendNetworkInvitations({ variables });
    return { data, errors };
  };

  const submitBuilderInvitations = async (
    variables: InviteBuilderContactsToPackageMutationVariables,
  ) => {
    const shouldInviteAddressBooks = variables.addressBookContactIds.length > 0;

    if (!shouldInviteAddressBooks) {
      return { data: null, errors: null };
    }

    const { data, errors } = await sendBuilderInvitations({ variables });
    return { data, errors };
  };

  const submit = async (
    variables: CombinedInviteVariables,
  ): Promise<{ data: Package | null; errors: string | null }> => {
    const { data: networkInvitesData, errors: networkInvitesErrors } =
      await submitNetworkInvitations(variables);
    const { data: builderInvitesData, errors: builderInvitesErrors } =
      await submitBuilderInvitations(variables);

    if (
      networkInvitesErrors ||
      builderInvitesErrors ||
      isNetworkInviteErrors(networkInvitesData?.inviteNetworkContactsToPackage) ||
      isBuilderInviteErrors(builderInvitesData?.inviteBuilderContactsToPackage)
    ) {
      const errorMessage = concatenateErrors(
        ...(networkInvitesErrors ?? []),
        ...(builderInvitesErrors ?? []),
        ...(isNetworkInviteErrors(networkInvitesData?.inviteNetworkContactsToPackage)
          ? networkInvitesData.inviteNetworkContactsToPackage.errors
          : []),
        ...(isBuilderInviteErrors(builderInvitesData?.inviteBuilderContactsToPackage)
          ? builderInvitesData.inviteBuilderContactsToPackage.errors
          : []),
      );

      return { data: null, errors: errorMessage };
    }

    return {
      data: {
        __typename: 'Package',
        id: variables.packageId,
        quotesDueAt: variables.quotesDueAt,
        rfqs: [
          ...(networkInvitesData?.inviteNetworkContactsToPackage.rfqs ?? []),
          ...(builderInvitesData?.inviteBuilderContactsToPackage.rfqs ?? []),
        ],
      },
      errors: null,
    };
  };

  return { submit, loading: loadingNetworkInvitations || loadingBuilderInvitations };
};
