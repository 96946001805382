import { useEffect, useRef, useState } from 'react';
import $ from 'jquery';
import { UploadStatus } from '@ascension/js/classes/file_manager/ServerlessUploader';

type UploadStatusValues = (typeof UploadStatus)[keyof typeof UploadStatus];
type Handler = {
  type: UploadStatusValues;
  handler: <T>(event: T) => void;
};

const useUploader = () => {
  const uploaderRef = useRef<JQuery<HTMLElement>>();
  const handlers = useRef<Handler[]>([]);
  const api = useRef({
    subscribe: (type: UploadStatusValues, handler: <T>(event: T) => void) => {
      const uploader = uploaderRef.current;
      if (uploader) {
        handlers.current.push({ type, handler });
        uploader.on(type, handler);
      }
    },
    unsubscribeAll: () => {
      const uploader = uploaderRef.current;
      if (uploader) {
        handlers.current.forEach(({ type, handler }) => {
          uploader.off(type, handler);
        });
      }
      handlers.current = [];
    },
  });

  useEffect(() => {
    const uploader = $('.file-uploader .uploader');
    if (uploader) {
      uploaderRef.current = uploader;
    }
  }, []);

  return api;
};

export const useHasUploadStarted = () => {
  const [hasStartedUpload, setHasStartedUpload] = useState(false);

  const uploaderRef = useUploader();
  useEffect(() => {
    const uploader = uploaderRef.current;
    if (uploader) {
      uploader.subscribe(UploadStatus.ADDED, () => {
        setHasStartedUpload(true);
      });
    }

    return () => {
      if (uploader) {
        uploader.unsubscribeAll();
      }
    };
  }, [uploaderRef]);

  return hasStartedUpload;
};
