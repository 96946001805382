import { TableInstance } from '@estimateone/frontend-components';
import { isAfter, isSameDay, isValid } from 'date-fns';
import {
  getLettingScheduleStatus,
  LettingScheduleStatus,
} from '@builder/features/ProcurementLettingSchedule/LettingScheduleStatus';
import {
  autoCalculateTimings,
  Timings,
  Trigger,
} from '@builder/features/ProcurementLettingSchedule/TimingCalculator/autoCalculateTimings';
import {
  getInputById,
  normaliseDateInput,
  normaliseNumberInput,
} from '@builder/features/ProcurementLettingSchedule/utils/inputHelpers';
import { UpdateLettingTimingsInput } from '@ascension/_gqltypes/builder.generated';
import { EntityId } from '@ascension/types';
import { Package } from '@builder/features/ProcurementLettingSchedule/types';
import { GetPackagesForLettingSchedule_activePackages as ActivePackages } from '@builder/features/ProcurementLettingSchedule/types/GetPackagesForLettingSchedule';

type LettingScheduleDetails = NonNullable<ActivePackages['lettingScheduleDetails']>;

export type CalculateAndUpdateLettingTimingsInput = Omit<
  Required<UpdateLettingTimingsInput>,
  'letByDate' | 'startOnSiteDate' | 'quotesDueByDate' | 'sendInvitesByDate'
> & {
  id: EntityId;
  sendInvitesByDate: Date | null;
  quotesDueByDate: Date | null;
  letByDate: Date | null;
  startOnSiteDate: Date | null;
  trigger: Trigger | null;
};

export enum InputIdentifiers {
  SEND_INVITES_BY_DATE = 'send_invites_by_date_',
  QUOTES_DUE_BY_DATE_INTERVAL = 'quotes_due_by_date_interval_',
  QUOTES_DUE_BY_DATE = 'quotes_due_by_date_',
  LET_BY_DATE_INTERVAL = 'let_by_date_interval_',
  LET_BY_DATE = 'let_by_date_',
  START_ON_SITE_DATE_INTERVAL = 'start_on_site_date_interval_',
  START_ON_SITE_DATE = 'start_on_site_date_',
}

export enum TimingErrorMessages {
  DATE_TOO_EARLY = 'Enter a date after past key dates',
  DATE_TOO_LATE = 'Enter a date before future key dates',
  NOT_CLEARABLE = 'After an invitation has been sent, only editing is allowed',
}

const getSendInvitesByDateInputValue = (packageId: EntityId) =>
  normaliseDateInput(getInputById(`${InputIdentifiers.SEND_INVITES_BY_DATE}${packageId}`).value);

const getQuotesDueByDateIntervalInputValue = (packageId: EntityId) =>
  normaliseNumberInput(
    getInputById(`${InputIdentifiers.QUOTES_DUE_BY_DATE_INTERVAL}${packageId}`).value,
  );

const getQuotesDueByDateInputValue = (packageId: EntityId) =>
  normaliseDateInput(getInputById(`${InputIdentifiers.QUOTES_DUE_BY_DATE}${packageId}`).value);

export const getLetByDateIntervalInputValue = (packageId: EntityId) =>
  normaliseNumberInput(getInputById(`${InputIdentifiers.LET_BY_DATE_INTERVAL}${packageId}`).value);

export const getLetByDateInputValue = (packageId: EntityId) =>
  normaliseDateInput(getInputById(`${InputIdentifiers.LET_BY_DATE}${packageId}`).value);

const getStartOnSiteDateIntervalInputValue = (packageId: EntityId) =>
  normaliseNumberInput(
    getInputById(`${InputIdentifiers.START_ON_SITE_DATE_INTERVAL}${packageId}`).value,
  );

const getStartOnSiteDateInputValue = (packageId: EntityId) =>
  normaliseDateInput(getInputById(`${InputIdentifiers.START_ON_SITE_DATE}${packageId}`).value);

const getColumnShownStatus = (currentTableRef: TableInstance<Package>, columnName: string) =>
  currentTableRef.columns.find((column) => column.id === columnName)?.isVisible ?? false;

const getBaseTimingValues = (
  currentTableRef: TableInstance<Package>,
  packageId: number,
  lettingScheduleDetails: LettingScheduleDetails,
  timingOverrides: Partial<CalculateAndUpdateLettingTimingsInput>,
): CalculateAndUpdateLettingTimingsInput => {
  const baseTimings: CalculateAndUpdateLettingTimingsInput = {
    id: lettingScheduleDetails.id,
    sendInvitesByDate: getColumnShownStatus(currentTableRef, 'sendInvitesByDate')
      ? getSendInvitesByDateInputValue(packageId)
      : normaliseDateInput(lettingScheduleDetails.rfqsSentByDate),
    quotesDueByDateInterval: getColumnShownStatus(currentTableRef, 'quotesDueByDateInterval')
      ? getQuotesDueByDateIntervalInputValue(packageId)
      : lettingScheduleDetails.quotesDueAtInterval,
    quotesDueByDate: getColumnShownStatus(currentTableRef, 'quotesDueByDate')
      ? getQuotesDueByDateInputValue(packageId)
      : normaliseDateInput(lettingScheduleDetails.quotesDueAt),
    letByDateInterval: getColumnShownStatus(currentTableRef, 'letByDateInterval')
      ? getLetByDateIntervalInputValue(packageId)
      : lettingScheduleDetails.letByDateInterval,
    letByDate: getColumnShownStatus(currentTableRef, 'letBy')
      ? getLetByDateInputValue(packageId)
      : normaliseDateInput(lettingScheduleDetails.letByDate),
    startOnSiteDateInterval: getColumnShownStatus(currentTableRef, 'startOnSiteDateInterval')
      ? getStartOnSiteDateIntervalInputValue(packageId)
      : lettingScheduleDetails.startOnSiteDateInterval,
    startOnSiteDate: getColumnShownStatus(currentTableRef, 'startOnSiteDate')
      ? getStartOnSiteDateInputValue(packageId)
      : normaliseDateInput(lettingScheduleDetails.startOnSiteDate),
    packageId,
    trigger: null,
  };

  return { ...baseTimings, ...timingOverrides };
};

export const isQuotesDueClearable = (pkg: Package, isDefaultTimeframesEnabled: boolean) => {
  const letByDate = getLetByDateInputValue(pkg.id);
  const letByDateInterval = getLetByDateIntervalInputValue(pkg.id);
  const scheduleStatus = getLettingScheduleStatus(pkg);
  if (
    scheduleStatus !== LettingScheduleStatus.Draft &&
    letByDate === null &&
    (!isDefaultTimeframesEnabled || letByDateInterval === null)
  ) {
    return false;
  }
  return true;
};

export const isLetByClearable = (pkg: Package) => {
  const quotesDueDate = getQuotesDueByDateInputValue(pkg.id);
  const scheduleStatus = getLettingScheduleStatus(pkg);
  if (scheduleStatus !== LettingScheduleStatus.Draft && quotesDueDate === null) {
    return false;
  }
  return true;
};

const isSameDayOrEarlierThan = (date: Date | null, compareDate: Date | null) => {
  if (date && isValid(date) && compareDate && isValid(compareDate) && isAfter(date, compareDate)) {
    return false;
  }
  return true;
};

const isSameDayOrLaterThan = (date: Date | null, compareDate: Date | null) => {
  if (
    date &&
    isValid(date) &&
    compareDate &&
    isValid(compareDate) &&
    !isAfter(date, compareDate) &&
    !isSameDay(date, compareDate)
  ) {
    return false;
  }
  return true;
};

export const isDateSameOrEarlierThanFutureDates = (trigger: Trigger, state: Timings) => {
  if (trigger === Trigger.SEND_INVITES_BY_DATE_CHANGE) {
    if (!isSameDayOrEarlierThan(state.sendInvitesByDate, state.quotesDueByDate)) return false;
    if (!isSameDayOrEarlierThan(state.sendInvitesByDate, state.letByDate)) return false;
    if (!isSameDayOrEarlierThan(state.sendInvitesByDate, state.startOnSiteDate)) return false;
  }
  if (trigger === Trigger.QUOTES_DUE_BY_DATE_CHANGE) {
    if (!isSameDayOrEarlierThan(state.quotesDueByDate, state.letByDate)) return false;
    if (!isSameDayOrEarlierThan(state.quotesDueByDate, state.startOnSiteDate)) return false;
  }
  if (trigger === Trigger.LET_BY_DATE_CHANGE) {
    if (!isSameDayOrEarlierThan(state.letByDate, state.startOnSiteDate)) return false;
  }
  return true;
};

export const isDateSameOrLaterThanPastDates = (trigger: Trigger, state: Timings) => {
  if (trigger === Trigger.START_ON_SITE_DATE_CHANGE) {
    if (!isSameDayOrLaterThan(state.startOnSiteDate, state.quotesDueByDate)) return false;
    if (!isSameDayOrLaterThan(state.startOnSiteDate, state.letByDate)) return false;
    if (!isSameDayOrLaterThan(state.startOnSiteDate, state.sendInvitesByDate)) return false;
  }
  if (trigger === Trigger.QUOTES_DUE_BY_DATE_CHANGE) {
    if (!isSameDayOrLaterThan(state.quotesDueByDate, state.sendInvitesByDate)) return false;
  }
  if (trigger === Trigger.LET_BY_DATE_CHANGE) {
    if (!isSameDayOrLaterThan(state.letByDate, state.quotesDueByDate)) return false;
    if (!isSameDayOrLaterThan(state.letByDate, state.sendInvitesByDate)) return false;
  }
  return true;
};

export const getCalculatedTimings = (timings: Timings, trigger: Trigger | null) =>
  autoCalculateTimings(
    {
      sendInvitesByDate: timings.sendInvitesByDate,
      quotesDueByDateInterval: timings.quotesDueByDateInterval ?? null,
      quotesDueByDate: timings.quotesDueByDate,
      letByDateInterval: timings.letByDateInterval ?? null,
      letByDate: timings.letByDate,
      startOnSiteDateInterval: timings.startOnSiteDateInterval ?? null,
      startOnSiteDate: timings.startOnSiteDate,
    },
    trigger,
  );

export const toISOString = (date: Date | null) =>
  date && isValid(date) ? date.toISOString() : null;

export const getTimings = (
  currentValue: Date | number | undefined,
  table: TableInstance<Package> | undefined,
  original: Package,
  trigger: Trigger,
  inputName: string,
) => {
  const customTimings = {
    [inputName]: currentValue,
    trigger,
  };

  const timings =
    table && original.lettingScheduleDetails
      ? getBaseTimingValues(table, original.id, original.lettingScheduleDetails, customTimings)
      : undefined;

  return timings;
};
