import { TagVariant } from '@estimateone/frontend-components';
import { HeightCorrectedTag } from '../HeightCorrectedTag';
import styles from './AccountTypeTag.module.scss';

type AccountTypeTagProps = {
  accountType: string;
  editable?: boolean;
};

export const AccountTypeTag = ({ accountType, editable }: AccountTypeTagProps) => {
  const variant = editable ? TagVariant.NetworkWritable : TagVariant.NetworkReadonly;

  const accountTypeText = accountType.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
  );

  return (
    <div className={styles.accountTypeTag}>
      <HeightCorrectedTag variant={variant} text={accountTypeText} />
    </div>
  );
};
