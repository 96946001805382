import { gql } from '@apollo/client';

export const ADD_PROFILE_TO_ADDRESS_BOOK = gql`
  mutation AddProfileToAddressBook($profileId: ID!) {
    addProfileToAddressBook(profileId: $profileId) {
      __typename
      ... on ConnectedBuilderNetworkCompany {
        __typename
        id
        name
        defaultOffice {
          id
          address {
            id
            suburb
            city
            province
            state {
              id
              shortName
            }
            country {
              id
              shortName
            }
          }
        }
        stockTrades {
          ... on NetworkCompanyTradeInterface {
            id
            name
          }
        }
        contractSizeMin
        contractSizeMax
        logoUrl
      }
      ... on ConnectedE1NetworkCompany {
        __typename
        id
        name
        defaultOffice {
          id
          address {
            id
            suburb
            city
            province
            state {
              id
              shortName
            }
            country {
              id
              shortName
            }
          }
        }
        stockTrades {
          ... on NetworkCompanyTradeInterface {
            id
            name
          }
        }
        contractSizeMin
        contractSizeMax
        logoUrl
      }
      ... on BuilderNetworkCompany {
        __typename
        id
        name
        defaultOffice {
          id
          address {
            id
            suburb
            city
            province
            state {
              id
              shortName
            }
            country {
              id
              shortName
            }
          }
        }
        trades {
          id
          name
          stockTrade {
            id
            name
          }
        }
      }
    }
  }
`;
