import { GetNetworkInvitesStage_stage_UnrestrictedStage as Stage } from '@builder/features/SubbieNetworkInvitations/hooks/types/GetNetworkInvitesStage';
import { WorkforceSizeEnum } from '@ascension/_gqltypes/builder.generated';
import { ID } from '@builder/common/SubbieNetwork/types';
import {
  ContractSize,
  LocationOption,
  SearchAreaOptions,
} from '@builder/common/SubbieNetwork/utils/filters/types';

export type Package = Stage['activePackages'][number];

export const SubbieNetworkFilterFormId = 'SubbieNetworkFilterForm';

export type SubbieNetworkInvitationsFilters = {
  tradeId?: number;
  categoryId?: number;
  contractSize: ContractSize;
  workforceSize?: WorkforceSizeEnum;
  searchArea?: SearchAreaOptions;
  location?: LocationOption;
  distance?: number;
  distanceLabel?: string;
  showE1Network: boolean;
};

export type CompanyContact = {
  id: ID;
  fullName: string;
  maybeEmail: string | null;
  unSubscribed: boolean;
};
