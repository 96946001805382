import { useField } from 'react-final-form';
import { HelpIcon, RadioButton } from '@estimateone/frontend-components';
import { useHasFixedPriceQuotesFeature } from '@shared';
import { RadioButtonGroupField } from '@shared/FinalForm/Field/RadioButtonGroupField';
import { HelpSliderTrigger } from '@shared/HelpSliderTrigger';
import { TenderHandoverWizardFieldName } from '../enums';
import { TenderHandoverWizardValues } from '../types';
import styles from './styles.scss';

export const ProjectSettingsDetails = () => {
  const hasFixedPriceQuotesFeature = useHasFixedPriceQuotesFeature();
  const {
    input: { onChange: onChangeFixedPriceQuotes, value: fixedPriceQuotes },
  } = useField<
    TenderHandoverWizardValues[TenderHandoverWizardFieldName.IncludeFixedQuoteConfirmation]
  >(TenderHandoverWizardFieldName.IncludeFixedQuoteConfirmation);

  const {
    input: { onChange: onChangeNotifyOption, value: notifyOption },
  } = useField<
    TenderHandoverWizardValues[TenderHandoverWizardFieldName.SendNotificationsToAwarded]
  >(TenderHandoverWizardFieldName.SendNotificationsToAwarded);

  const {
    input: { onChange: onChangeDefaultTimeframes, value: defaultTimeframesOption },
  } = useField<TenderHandoverWizardValues[TenderHandoverWizardFieldName.EnableDefaultTimeframes]>(
    TenderHandoverWizardFieldName.EnableDefaultTimeframes,
  );

  const onFixedPriceQuotesChange = (option: string) => {
    onChangeFixedPriceQuotes(option === 'true');
  };

  const onNotifyOptionChange = (option: string) => {
    onChangeNotifyOption(option === 'true');
  };

  const onDefaultTimeframesChange = (option: string) => {
    onChangeDefaultTimeframes(option === 'true');
  };

  return (
    <section className={styles.projectSettingsSection}>
      {hasFixedPriceQuotesFeature ? (
        <div className={styles.optionsContainer}>
          <div className={styles.headingSection}>
            <h2>Include Fixed Quote Confirmation</h2>
            <HelpSliderTrigger template="fixed_price_quote_project_setup_builder">
              <HelpIcon />
            </HelpSliderTrigger>
          </div>
          <p className={styles.settingsTitle}>
            If your tender requires the use of fixed quotes and you would like your subcontractors
            to indicate that they are submitting a fixed quote.
          </p>
          <RadioButtonGroupField
            name="fixedPriceQuotes"
            fieldName={TenderHandoverWizardFieldName.IncludeFixedQuoteConfirmation}
            legend=""
            isRequired={false}
            onChange={onFixedPriceQuotesChange}
            value={fixedPriceQuotes?.toString() ?? 'false'}
          >
            <RadioButton id="fixedPriceQuotesYes" className={styles.optionButton} value="true">
              <span className={styles.optionLabel}>
                Yes, I would like subcontractors to indicate that they are submitting a fixed quote.
              </span>
            </RadioButton>
            <RadioButton id="fixedPriceQuotesNo" className={styles.optionButton} value="false">
              <span className={styles.optionLabel}>
                No, I don&apos;t need subcontractors to indicate that they are submitting a fixed
                quote.
              </span>
            </RadioButton>
          </RadioButtonGroupField>
        </div>
      ) : null}
      <div className={styles.headingSection}>
        <h2>Project Settings</h2>
      </div>
      <div>
        <div className={styles.optionsContainer}>
          <p className={styles.settingsTitle}>
            Send notifications about document revisions to awarded subcontractors?
          </p>
          <RadioButtonGroupField
            name="sendNotifications"
            value={notifyOption?.toString() ?? 'false'}
            onChange={onNotifyOptionChange}
            legend=""
            fieldName={TenderHandoverWizardFieldName.SendNotificationsToAwarded}
          >
            <RadioButton
              id="notifications-yes"
              value="true"
              key="notifications-yes"
              className={styles.optionButton}
            >
              <span className={styles.optionLabel}>Yes, notify awarded subcontractors</span>
            </RadioButton>
            <RadioButton
              id="notifications-no"
              value="false"
              key="notifications-no"
              className={styles.optionButton}
            >
              <span className={styles.optionLabel}>No, do not notify awarded subcontractors</span>
            </RadioButton>
          </RadioButtonGroupField>
          <p className={styles.settingsTitle}>
            Enable default timeframes between dates on project schedule?
          </p>
          <RadioButtonGroupField
            name="enableDefaultTimeframes"
            value={defaultTimeframesOption?.toString() ?? 'false'}
            onChange={onDefaultTimeframesChange}
            legend=""
            fieldName={TenderHandoverWizardFieldName.EnableDefaultTimeframes}
          >
            <RadioButton
              id="default-timeframes-yes"
              value="true"
              key="default-timeframes-yes"
              className={styles.optionButton}
            >
              <span className={styles.optionLabel}>
                Yes, enable default timeframes between dates
              </span>
            </RadioButton>
            <RadioButton
              id="default-timeframes-no"
              value="false"
              key="default-timeframes-no"
              className={styles.optionButton}
            >
              <span className={styles.optionLabel}>
                No, disable default timeframes between dates
              </span>
            </RadioButton>
          </RadioButtonGroupField>
        </div>
      </div>
    </section>
  );
};
