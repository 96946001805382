import { useState, createContext, ReactNode } from 'react';
import { QueryResult } from '@apollo/client';
import { useDrawer } from '@estimateone/frontend-components';
import { useGetDistanceLabel } from '@builder/context/SubbieNetworkFilterDataProvider/hooks';
import {
  DISTANCE_OPTION_MAX,
  useSearchNetworkCompanies,
} from '@builder/features/SubbieNetworkInvitations/components/CompaniesResults/hooks';
import {
  NetworkInvitationsSearchCompaniesQuery,
  NetworkInvitationsSearchCompaniesQueryVariables,
} from '@builder/features/SubbieNetworkInvitations/components/CompaniesResults/hooks/queries.generated';
import { WorkforceSizeEnum } from '@ascension/_gqltypes/builder.generated';
import { NetworkCompany } from '@builder/common/SubbieNetwork/types';
import {
  ContractSize,
  LocationOption,
  SearchAreaOptions,
} from '@builder/common/SubbieNetwork/utils/filters/types';

type SearchResults = { results: NetworkCompany[] } | null;
type SearchRefetch = QueryResult<
  NetworkInvitationsSearchCompaniesQuery,
  NetworkInvitationsSearchCompaniesQueryVariables
>['refetch'];

export type NetworkSearchContextProps = {
  networkSearchResults: SearchResults;
  refetchNetworkSearch: SearchRefetch;
  loadingNetworkSearch: boolean;
  isFilterDrawerOpen: boolean;
  onOpenFilterDrawer: () => void;
  onCloseFilterDrawer: () => void;
  onResetFilters: () => void;
  tradeId: number | undefined;
  setTradeId: (tradeId: number | undefined) => void;
  categoryId: number | undefined;
  setCategoryId: (categoryId: number | undefined) => void;
  contractSize: ContractSize;
  setContractSize: (contractSize: ContractSize) => void;
  workforceSize: WorkforceSizeEnum | undefined;
  setWorkforceSize: (workforceSize: WorkforceSizeEnum | undefined) => void;
  searchArea: SearchAreaOptions;
  setSearchArea: (searchArea: SearchAreaOptions) => void;
  location: LocationOption | undefined;
  setLocation: (location: LocationOption | undefined) => void;
  distance: number | undefined;
  setDistance: (distance: number | undefined) => void;
  distanceLabel: string | undefined;
  setDistanceLabel: (distanceLabel: string | undefined) => void;
  showE1Network: boolean;
  setShowE1Network: (showE1Network: boolean) => void;
};

export const NetworkSearchContext = createContext<NetworkSearchContextProps>({
  /* eslint-disable @typescript-eslint/no-unused-vars */
  isFilterDrawerOpen: false,
  onOpenFilterDrawer: () => {},
  onCloseFilterDrawer: () => {},
  tradeId: undefined,
  setTradeId: () => {},
  categoryId: undefined,
  setCategoryId: () => {},
  contractSize: { min: undefined, max: undefined },
  setContractSize: () => {},
  workforceSize: undefined,
  setWorkforceSize: () => {},
  searchArea: 'office-location',
  setSearchArea: () => {},
  location: undefined,
  setLocation: () => {},
  distance: undefined,
  setDistance: () => {},
  distanceLabel: undefined,
  setDistanceLabel: () => {},
  showE1Network: false,
  setShowE1Network: () => {},
  networkSearchResults: null,
  loadingNetworkSearch: false,
  onResetFilters: () => {},
  refetchNetworkSearch: () =>
    Promise.resolve({
      loading: false,
      networkStatus: 4,
      data: {
        searchNetworkCompaniesForInvite: {
          __typename: 'SearchNetworkCompaniesResult',
          count: 0,
          totalCount: 0,
          currentPage: 1,
          results: [],
        },
      },
    }),
});

const { Provider } = NetworkSearchContext;

export const NetworkSearchProvider = ({ children }: { children: ReactNode }) => {
  const DEFAULT_DISTANCE = DISTANCE_OPTION_MAX;
  const DEFAULT_DISTANCE_LABEL = useGetDistanceLabel(DEFAULT_DISTANCE);

  const { isOpen, onOpen, onClose } = useDrawer(false);

  const [tradeId, setTradeId] = useState<number>();
  const [categoryId, setCategoryId] = useState<number>();
  const [contractSize, setContractSize] = useState<ContractSize>({
    min: undefined,
    max: undefined,
  });
  const [workforceSize, setWorkforceSize] = useState<WorkforceSizeEnum>();
  const [searchArea, setSearchArea] = useState<SearchAreaOptions>('office-location');
  const [location, setLocation] = useState<LocationOption>();
  const [distance, setDistance] = useState<number | undefined>(DEFAULT_DISTANCE);
  const [distanceLabel, setDistanceLabel] = useState<string | undefined>(DEFAULT_DISTANCE_LABEL);
  const [showE1Network, setShowE1Network] = useState(false);

  const onReset = () => {
    setTradeId(undefined);
    setCategoryId(undefined);
    setContractSize({ min: undefined, max: undefined });
    setWorkforceSize(undefined);
    setSearchArea('office-location');
    setLocation(undefined);
    setDistance(undefined);
    setDistanceLabel(undefined);
  };

  const { data, loading, refetchNetworkResults } = useSearchNetworkCompanies({
    tradeId,
    categoryId,
    location,
    contractSize,
    distance,
    searchArea,
    workforceSize,
    distanceLabel,
    showE1Network,
  });

  const value = {
    isFilterDrawerOpen: isOpen,
    onOpenFilterDrawer: onOpen,
    onCloseFilterDrawer: onClose,

    onResetFilters: onReset,
    tradeId,
    setTradeId,
    categoryId,
    setCategoryId,
    contractSize,
    setContractSize,
    workforceSize,
    setWorkforceSize,
    searchArea,
    setSearchArea,
    location,
    setLocation,
    distance,
    setDistance,
    distanceLabel,
    setDistanceLabel,
    showE1Network,
    setShowE1Network,

    networkSearchResults: data,
    refetchNetworkSearch: refetchNetworkResults,
    loadingNetworkSearch: loading,
  };

  return <Provider value={value}>{children}</Provider>;
};
