import { ChangeEvent, useState } from 'react';
import { E1Link } from '@estimateone/frontend-components';
import { PaymentDetailsCreditCard } from '..';
import { PaymentData } from '../../index';
import EwaySecureFieldsForm from '../../../../../shared/form/EwaySecureFieldsForm';
import { CreditCardFormErrors } from '@shared/form/EwaySecureFieldsForm/types';
import styles from './styles.scss';

type CreditCardPickerProps = {
  creditCards: PaymentDetailsCreditCard[];
  updatePaymentData: (pd: Partial<PaymentData>) => void;
};

const CreditCardPicker = ({ creditCards, updatePaymentData }: CreditCardPickerProps) => {
  const handleSelection = (e: ChangeEvent<HTMLInputElement>) =>
    updatePaymentData({ creditCardId: parseInt(e.target.value) });

  return (
    <>
      <span>Select a credit card or add a new one</span>
      {creditCards.map(({ id, maskedNumber, expiry }) => (
        <div key={id} className={styles.creditCard}>
          <input type="radio" name="creditCard" value={id} onChange={handleSelection} />
          <div className={styles.cardLabel}>
            <span>{maskedNumber}</span> <span>Exp {expiry}</span>
          </div>
        </div>
      ))}
    </>
  );
};

type CreditCardSelectorProps = {
  creditCards: PaymentDetailsCreditCard[];
  updatePaymentData: (pd: Partial<PaymentData>) => void;
  purchaseErrors: CreditCardFormErrors;
};

const CreditCardSelector = ({
  creditCards,
  updatePaymentData,
  purchaseErrors,
}: CreditCardSelectorProps) => {
  const hasExistingCC = creditCards.length !== 0;
  const [showNewCCForm, setShowNewCCForm] = useState(!hasExistingCC);

  const handleNewCCToggle = () => {
    updatePaymentData({ creditCardId: undefined, secureCard: null });
    setShowNewCCForm(!showNewCCForm);
  };

  return (
    <div className={styles.cardPicker}>
      {showNewCCForm ? (
        <EwaySecureFieldsForm updatePaymentData={updatePaymentData} errors={purchaseErrors} />
      ) : (
        <CreditCardPicker creditCards={creditCards} updatePaymentData={updatePaymentData} />
      )}
      {hasExistingCC && (
        <E1Link onClick={handleNewCCToggle}>
          {showNewCCForm ? 'Use an existing card' : 'Add a new card'}
        </E1Link>
      )}
    </div>
  );
};

export default CreditCardSelector;
