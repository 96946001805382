import { useState } from 'react';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import { ADD_CONTACT_TO_PACKAGE } from '../mutations';
import {
  AddContactToPackage,
  AddContactToPackageVariables,
  AddContactToPackage_addContactToPackage_Rfq as Rfq,
  AddContactToPackage_addContactToPackage_Errors as Errors,
} from '../types/AddContactToPackage';

type APIError = Errors['errors'][0];

type AddSubbieToPackage = {
  submit: (
    packageId: number,
    contactId: number,
    quotesDueAt: Date,
  ) => Promise<{ data?: Rfq; errors?: APIError[] }>;
  responseData?: Rfq;
  responseErrors?: APIError[];
};

export const useAddSubbieToPackage = (): AddSubbieToPackage => {
  const [responseData, setResponseData] = useState<Rfq>();
  const [responseErrors, setResponseErrors] = useState<APIError[]>();

  const [handleAddContactToPackage] = useMutation<
    AddContactToPackage,
    AddContactToPackageVariables
  >(ADD_CONTACT_TO_PACKAGE);

  const submit = async (
    packageId: number,
    contactId: number,
    quotesDueAt: Date,
  ): Promise<{ data?: Rfq; errors?: APIError[] }> => {
    const input = {
      packageId,
      contactId,
      quotesDueAt: moment(quotesDueAt).format(),
    };

    const { data } = await handleAddContactToPackage({ variables: { input } });
    const { addContactToPackage } = data ?? {};

    if (addContactToPackage?.__typename === 'Errors') {
      setResponseErrors(addContactToPackage.errors);
      return { data: undefined, errors: addContactToPackage.errors };
    }

    setResponseData(addContactToPackage);
    return { data: addContactToPackage, errors: undefined };
  };

  return { submit, responseData, responseErrors };
};
