import { gql } from '@apollo/client';

export const PACKAGES_FOR_EXPORT = gql`
  query GetPackagesForExport($stageId: EntityId!) {
    activePackages(stageId: $stageId) {
      id
      title
      fullSet
      discipline {
        name
        orderIndex
      }
    }
  }
`;
