import { useMemo } from 'react';
import { GetPackagesForQuoteCoverageReport_activePackages as ActivePackage } from '../types/GetPackagesForQuoteCoverageReport';

export const useInviteAndResponseStats = (packages: ActivePackage[] | undefined) =>
  useMemo(() => {
    const counts = {
      contactInviteCount: 0,
      contactInviteDocDownloadCount: 0,
      companyInviteCount: 0,
      quotedCount: 0,
      responsesCount: 0,
      totalRfqsCount: 0,
    };
    if (packages) {
      return packages.reduce<typeof counts>(
        (currentCounts, currentPackage) => ({
          contactInviteCount: currentCounts.contactInviteCount + currentPackage.countOfInvitesSent,
          contactInviteDocDownloadCount:
            currentCounts.contactInviteDocDownloadCount + currentPackage.countOfDocDownloads,
          companyInviteCount:
            currentCounts.companyInviteCount + currentPackage.countOfCompanyInvitesSent,
          quotedCount: currentCounts.quotedCount + currentPackage.countOfQuotedRfqs,
          responsesCount:
            currentCounts.responsesCount +
            currentPackage.countOfQuotedRfqs +
            currentPackage.countOfQuotingRfqs +
            currentPackage.countOfNotQuotingRfqs,
          totalRfqsCount: currentCounts.totalRfqsCount + currentPackage.countOfTotalRfqs,
        }),
        counts,
      );
    }

    return counts;
  }, [packages]);
