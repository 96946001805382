import { useEffect, useState } from 'react';
import { Button, Slider } from '@estimateone/frontend-components';
import { Heading } from '../../../../../../fec/Typography';
import AddNewContactForm from '../../../../../form/AddNewContactForm';
import { EntityId } from '../../../../../../../types';
import styles from './styles.scss';

export enum AddMode {
  None,
  Contact,
  Company,
}

type AddContactSliderProps = {
  mode: AddMode;
  onAddCompany: () => void;
  onAddContact: (companyId: EntityId, contactId: EntityId, companyName: string) => void;
  onCancel: () => void;
  selectedCompanyId?: EntityId;
  selectedCompanyName?: string;
};

const AddContactSlider = ({
  mode,
  onAddCompany,
  onAddContact,
  onCancel,
  selectedCompanyId,
  selectedCompanyName,
}: AddContactSliderProps) => {
  const [onSubmit, setOnSubmit] = useState<() => void>(() => undefined);
  const [isOpen, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setOpen(mode !== AddMode.None);
  }, [mode]);

  const handleAddCompany = () => {
    onAddCompany();
    setOpen(false);
  };
  const handleAddContact = (companyId: EntityId, contactId: EntityId, companyName: string) => {
    onAddContact(companyId, contactId, companyName);
    setOpen(false);
  };

  const handleCancel = () => {
    onCancel();
    setOpen(false);
  };

  return (
    <Slider isOpen={isOpen} onRequestClose={handleCancel}>
      <Slider.Header>
        <Heading level={1}>Add New {mode === AddMode.Contact ? 'Contact' : 'Company'}</Heading>
        {mode === AddMode.Contact && (
          <div className={styles.sliderSubHeader}>
            You are adding a new contact to {selectedCompanyName}
          </div>
        )}
      </Slider.Header>
      <Slider.Body scrollBodyOnly>
        <AddNewContactForm
          stage="estimating"
          setSubmitHandler={(submit: () => void) => {
            setOnSubmit(() => submit);
          }}
          selectedCompanyId={selectedCompanyId}
          selectedCompanyName={selectedCompanyName}
          viewCompanySliderFunc={handleAddContact}
          updateAddressBookTableFunc={handleAddCompany}
          requireContactEmail
          canChooseContactType
        />
      </Slider.Body>
      <Slider.Footer>
        <div className={styles.sliderFooter}>
          <Button onClick={() => onSubmit()}>
            Add New {mode === AddMode.Contact ? 'Contact' : 'Company'}
          </Button>
        </div>
      </Slider.Footer>
    </Slider>
  );
};

export default AddContactSlider;
