import { Checkbox, CheckboxStatus } from '@estimateone/frontend-components';
import { SubTrade } from './hooks';
import { EntityId } from '../../../../types';

type SuggestedSubTradesListProps = {
  matchedSubTrades: SubTrade[];
  selectedSubTradeIds: EntityId[];
  onCheckBoxClick: (id: EntityId) => void;
};

const SuggestedSubTradesList = ({
  matchedSubTrades,
  selectedSubTradeIds,
  onCheckBoxClick,
}: SuggestedSubTradesListProps) => (
  <ul style={{ listStyle: 'none' }}>
    {matchedSubTrades.map((matchedSubTrade) => (
      <li key={matchedSubTrade.id}>
        <Checkbox
          id={matchedSubTrade.id.toString()}
          label={
            <>
              <strong>{matchedSubTrade.category.name}</strong> &mdash; {matchedSubTrade.name}
            </>
          }
          statusCurrent={
            selectedSubTradeIds.includes(matchedSubTrade.id)
              ? CheckboxStatus.Checked
              : CheckboxStatus.Unchecked
          }
          onClick={() => onCheckBoxClick(matchedSubTrade.id)}
        />
      </li>
    ))}
  </ul>
);

export default SuggestedSubTradesList;
