import {
  Button,
  ButtonVariant,
  Checkbox,
  CheckboxStatus,
  Modal,
  ModalSize,
} from '@estimateone/frontend-components';
import { ConnectionPreferenceSliderWrapper } from '../ConnectionPreferenceSliderWrapper';
import { useConnectionPreferenceSlider } from '../ConnectionPreferenceSliderWrapper/hooks/useConnectionPreferenceSlider';
import { useSSCModalOptOut } from './hooks/useSSCModalOptOut';
import { useSSCWatchlistModal } from './hooks/useSSCWatchlistModal';
import styles from './styles.scss';

export const SSCWatchlistModal = () => {
  const { isOptedOut, toggleOptOut } = useSSCModalOptOut();
  const { open: openConnectionPreferenceSlider } = useConnectionPreferenceSlider();
  const { isOpen, close, data } = useSSCWatchlistModal();

  const handlePromoteButtonClick = () => {
    if (isOpen) {
      openConnectionPreferenceSlider({ selectedProjectId: data.selectedProjectId });
      close();
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={close}
        size={ModalSize.Small}
        reactModalProps={{
          style: {
            content: { zIndex: 1201 },
            overlay: { zIndex: 1200 },
          },
        }}
      >
        <Modal.Section>
          <h3 className={styles.sscWatchlistModalHeader}>
            Do you want to promote your products to subcontractors interested in this project?
          </h3>

          <div className={styles.sscWatchlistModalBody}>
            <p>
              Let subcontractors know you&apos;re the go-to for specified or equivalent products on
              this project.
            </p>

            <Checkbox
              id="toggleOptOut"
              label="Don't show this again"
              onClick={toggleOptOut}
              statusCurrent={isOptedOut() ? CheckboxStatus.Checked : CheckboxStatus.Unchecked}
            />
          </div>

          <div className={styles.sscWatchlistModalFooter}>
            <Button variant={ButtonVariant.Grey} onClick={close}>
              Close
            </Button>
            <Button onClick={handlePromoteButtonClick}>Yes, promote my products</Button>
          </div>
        </Modal.Section>
      </Modal>
      <ConnectionPreferenceSliderWrapper />
    </>
  );
};
