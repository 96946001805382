import { useState } from 'react';
import { Slider } from '@estimateone/frontend-components';
import ProjectSliderBody from './ProjectSliderBody';
import ProjectSliderHeader from './ProjectSliderHeader';
import { getUniqueStockTradesForProject } from './util';
import LoadingSpinner from '../../../shared/LoadingSpinner';
import { useCurrentUser } from '@subbie/context/AccountProvider/hooks';
import { useScrollSpySections } from '@subbie/modal/ProjectSlider/hooks/useScrollSpySections';
import {
  GetProjectSliderData_projectForSlider_notes as Note,
  GetProjectSliderData_projectForSlider_projectActivityLogs as Log,
  GetProjectSliderData_projectForSlider_project as Project,
} from '../types/GetProjectSliderData';
import { ProjectSubbieSummary_projectSubbieSummary as ProjectSubbieSummary } from '@subbie/modal/types/ProjectSubbieSummary';
import styles from './styles.scss';

type ViewableNoticeboardProject = Extract<Project, { __typename: 'ViewableNoticeboardProject' }>;

export type ProjectWithNotesLogs = {
  project: ViewableNoticeboardProject;
  projectNotes: Note[];
  projectLogs: Log[];
  readonly projectSubbieSummary: ProjectSubbieSummary | undefined;
};

type ProjectSliderProps = ProjectWithNotesLogs & {
  removeModal: () => void;
};

const ProjectSlider = ({
  project,
  projectNotes,
  projectLogs,
  projectSubbieSummary,
  removeModal,
}: ProjectSliderProps) => {
  const user = useCurrentUser();

  const [isPaneOpen, setIsPaneOpen] = useState(true);

  const handleClose = () => {
    setIsPaneOpen(false);
    removeModal();
  };

  const uniqueProjectStockTrades = getUniqueStockTradesForProject(project);
  const userStockTradeIds = new Set(user?.account?.stockTrades.map(({ id }) => id));
  const scrollSpySections = useScrollSpySections(project, user, projectNotes, projectSubbieSummary);

  return (
    <Slider rootAppElement="#root" isOpen={isPaneOpen} onRequestClose={handleClose}>
      {user ? (
        <ProjectSliderHeader user={user} project={project} scrollSpySections={scrollSpySections} />
      ) : null}
      {user ? (
        <ProjectSliderBody
          project={project}
          uniqueProjectStockTrades={uniqueProjectStockTrades}
          userStockTradeIds={userStockTradeIds}
          projectNotes={projectNotes}
          projectLogs={projectLogs}
          projectSubbieSummary={projectSubbieSummary}
          scrollSpySections={scrollSpySections}
          user={user}
        />
      ) : (
        <div className={styles.bodyLoadingContainer}>
          <LoadingSpinner size="medium" center />
        </div>
      )}
    </Slider>
  );
};

export default ProjectSlider;
