import { useForm } from 'react-final-form';
import { DetailsCard } from '@estimateone/frontend-components';
import { PreferredIndicator } from '../../../../../../common/PreferredIndicator';
import { InviteToQuoteFormFieldName } from '../../enums';
import { InviteToQuoteFormValues } from '../../types';
import styles from './styles.scss';

type InviteeDetailsProps = {
  company: InviteToQuoteFormValues[InviteToQuoteFormFieldName.Company];
  contact: InviteToQuoteFormValues[InviteToQuoteFormFieldName.Contact];
};

const InviteeDetails = ({ company, contact: { contactDetail } }: InviteeDetailsProps) => (
  <>
    <p className={styles.description}>
      {contactDetail.isPreferred && <PreferredIndicator className={styles.preferredIndicator} />}
      {contactDetail.fullName}
    </p>
    <p className={styles.description}>{contactDetail.email}</p>
    <p className={styles.description}>{contactDetail.phone}</p>
    <p className={styles.description}>{company.label}</p>
  </>
);

type InviteSummaryCardProps = {
  stageName: string | null;
};

export const InviteSummaryCard = ({ stageName }: InviteSummaryCardProps) => {
  const { getState } = useForm<InviteToQuoteFormValues>();
  const { values } = getState();

  const {
    package: { label: packageName },
    company,
    contact,
    correspondenceType,
    includeScopeOfWorks,
    scopeOfWorks,
  } = values;

  const getScopeFileName = (): string => {
    if (scopeOfWorks?.scopeFile) {
      return scopeOfWorks.scopeFile.fileName;
    }

    return `${packageName} Scope of Works`;
  };

  const contentsMap = [
    // The component does not accept just a string
    /* eslint-disable react/jsx-no-useless-fragment */
    { label: 'Project Name', description: <>{stageName}</> },
    { label: 'Package', description: <>{packageName}</> },
    ...(includeScopeOfWorks ? [{ label: 'Scope', description: <>{getScopeFileName()}</> }] : []),
    {
      label: 'Send Invite To',
      description: <InviteeDetails company={company} contact={contact} />,
    },
  ];

  if (correspondenceType) {
    contentsMap.splice(1, 0, {
      label: 'Correspondence Type',
      description: <>{correspondenceType.label}</>,
    });
  }

  return <DetailsCard className={styles.inviteSummaryCard} contents={contentsMap} />;
};
