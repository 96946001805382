import { useDocSyncQuery } from './useDocSyncQuery';
import { GET_INFECTED_FILES } from '../queries';
import { EntityId } from '../../../../../../types';
import { getInfectedFiles } from '../types/getInfectedFiles';

// TODO: move to DocSync REST API
export const useGetDocSyncInfectedFiles = (stageId: EntityId, shouldSkip: boolean) => {
  const { data, loading } = useDocSyncQuery<getInfectedFiles>(GET_INFECTED_FILES, {
    variables: { stageId },
    skip: shouldSkip,
  });

  return {
    infectedFiles: data?.stage?.infectedFiles ?? [],
    loading,
  };
};
