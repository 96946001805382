import { useEffect } from 'react';
import { Form } from 'react-final-form';
import { Icon, IconName, IconVariant } from '@estimateone/frontend-components';
import moment from 'moment';
import { FontStyle, Heading } from '../../../../fec';
import { validateUpdateAwardedStatusFormFields } from '../validators';
import { AwardedDateOptions } from './formSections/AwardedDateOptions';
import ModalFormErrorsAndButtons from './formSections/ModalFormErrorsAndButtons';
import NotifyOptionsAwarded, { NotifyOptionType } from './formSections/NotifyOptionsAwarded';
import { useUpdateAwardedStatus } from '../hooks';
import { UpdateStageStatusFieldName } from '../enums';
import { StageStatus, StageStatusMap } from '@ascension/enums';
import { UpdateAwardedStatusFormValues, UpdateStageStatusResponseData } from '../types';
import { EntityId } from '@ascension/types';
import styles from '../styles.scss';

export type UpdateAwardedStatusContentProps = {
  stageId: EntityId;
  stageName: string;
  stageProjectStart?: string;
  stageProjectCompletion?: string;
  onFormSubmit: (responseData: UpdateStageStatusResponseData) => void;
  onCancel: () => void;
  prevStatus: StageStatus;
};

const UpdateAwardedStatusContent = ({
  stageId,
  stageName,
  stageProjectStart,
  stageProjectCompletion,
  onFormSubmit,
  onCancel,
  prevStatus,
}: UpdateAwardedStatusContentProps) => {
  const { submit: updateAwardedStageStatus, responseData } = useUpdateAwardedStatus(
    stageId,
    prevStatus,
  );

  useEffect(() => {
    const { stageId: responseStageId, notifyOption } = responseData;
    if (responseStageId && notifyOption) {
      onFormSubmit({ stageId: responseStageId, notifyOption });
    }
  }, [onFormSubmit, responseData.stageId]);

  return (
    <div className={styles.modalContent}>
      <div className={styles.modalTitle}>
        <Icon name={IconName.Awarded} size="2em" variant={IconVariant.Yellow} />
        <Heading level={1} renderAs={FontStyle.h2}>
          Mark project as awarded for
          <br />
          <span className={styles.headingProjectName}>{stageName}</span>
        </Heading>
      </div>

      <Heading level={2} renderAs={FontStyle.h3}>
        Notify subcontractors and suppliers you invited?
      </Heading>
      <p className={styles.descriptionText}>
        They will be notified by email that this project has been awarded.
      </p>
      <Form<UpdateAwardedStatusFormValues>
        onSubmit={updateAwardedStageStatus}
        validate={validateUpdateAwardedStatusFormFields}
        initialValues={{
          [UpdateStageStatusFieldName.NotifyInvited]: false,
          [UpdateStageStatusFieldName.NotifyOption]: NotifyOptionType.ALL,
          [UpdateStageStatusFieldName.AwardedAt]: moment().startOf('day').toDate(),
          [UpdateStageStatusFieldName.ProjectStart]: stageProjectStart
            ? moment(stageProjectStart).toDate()
            : undefined,
          [UpdateStageStatusFieldName.ProjectCompletion]: stageProjectCompletion
            ? moment(stageProjectCompletion).toDate()
            : undefined,
        }}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit }) => (
          <form id="builder-update-awarded-stage-status-form" onSubmit={handleSubmit}>
            <NotifyOptionsAwarded />
            <AwardedDateOptions />
            <ModalFormErrorsAndButtons
              statusName={StageStatusMap[StageStatus.TENDER_AWARDED]}
              onCancel={onCancel}
            />
          </form>
        )}
      </Form>
    </div>
  );
};

export default UpdateAwardedStatusContent;
