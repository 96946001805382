import $ from 'jquery';
import Routing from 'routing';
import E1Request from '../classes/E1Request';
import { dispatchProjectModalEvent } from '@subbie/legacy/GlobalOverlayListener';

$(async () => {
  const $body = $('body');

  const id = parseInt($body.attr('data-project-id') ?? '0');

  if (id) dispatchProjectModalEvent(id);

  $body.on('click', '.project-modal-trigger', (projectModalTriggerClickEvent) =>
    dispatchProjectModalEvent($(projectModalTriggerClickEvent.currentTarget).data('project-id')),
  );

  $body.on('click', '.builder-modal-trigger', async (showModalEvent) => {
    const builderId = $(showModalEvent.currentTarget).data('builder-id');
    return new E1Request(Routing.generate('app_network_vcardmodal', { id: builderId }))
      .setShowLoadingModal()
      .submit();
  });

  const getRouteForPaywallTriggerClass = ($target: JQuery) => {
    const projectId = $target.data('project-id');
    const trigger = $target.data('paywall-trigger') || null;
    const routeParams = { id: projectId, trigger };

    if ($target.hasClass('upgrade-awarded-trigger')) {
      return Routing.generate('app_upgrade_awarded', routeParams);
    }

    if ($target.hasClass('restricted-budget-trigger')) {
      return Routing.generate('app_upgrade_budget', routeParams);
    }

    if ($target.hasClass('restricted-fifty-trigger')) {
      return Routing.generate('app_upgrade_fifty', routeParams);
    }

    if ($target.hasClass('restricted-pricing-experiment-trigger')) {
      return Routing.generate('app_upgrade_pricing_exp', routeParams);
    }

    return null;
  };

  $body.on(
    'click',
    '.upgrade-awarded-trigger, .restricted-budget-trigger, .restricted-fifty-trigger, .restricted-pricing-experiment-trigger',
    ({ currentTarget }) => {
      const upgradePaywallRoute = getRouteForPaywallTriggerClass($(currentTarget));

      if (upgradePaywallRoute) {
        return new E1Request(upgradePaywallRoute).setShowLoadingModal().submit();
      }
      return undefined;
    },
  );
});
