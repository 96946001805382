import { Component } from 'react';
import Routing from 'routing';
import { Row, Col, Alert, AlertVariant } from '@estimateone/frontend-components';
import E1Request from '../../../../js/classes/E1Request';
import RequestFailedError from '@ascension/js/classes/error/RequestFailedError';
import { E1Address, E1AddressFields } from '../../../../js/classes/Address';
import SuperSlider from '../../../../js/classes/SuperSlider';
import CompanyDetails from './CompanyDetails';
import CompanySearch from './CompanySearch';
import ContactDetails from './ContactDetails';
import AddressForm from '../../../shared/form/AddressForm';
import { EntityId } from '@ascension/types';

type SubmitCallback = (companyId: number, contactId: number, companyName: string) => void;

type Props = {
  slider?: SuperSlider;
  stage: string;
  canChooseContactType: boolean;
  viewCompanySliderFunc: SubmitCallback;
  updateAddressBookTableFunc: SubmitCallback;
  setSubmitHandler: (f: () => unknown) => void;
  selectedCompanyName?: string;
  selectedCompanyId?: number;
  requireContactEmail?: boolean;
};

type Address = Omit<E1AddressFields, 'address3'>;

type ContactTypes = {
  estimating: boolean;
  construction: boolean;
};

type Form = {
  firstName: string;
  lastName: string;
  contactPosition: string;
  contactPhone: string;
  contactEmail: string;
  contactTypes: ContactTypes;
  // Company Fields
  selectedCompany?: { id: EntityId; name: string };
  companyName: string;
  officePhone: string;
  officeFax: string;
  tradeIds: EntityId[];
  companyListIds: EntityId[];
} & Address;

export type ValidationResults = {
  firstName: boolean;
  contactEmail: boolean;
  contactTypes: boolean;
  selectedCompany: boolean;
  companyName: boolean;
};

export type AddNewContactFormField = keyof ValidationResults;

type State = {
  companySelectedViaProps: boolean;
  form: Form;
  address: E1Address;
  validationResults: ValidationResults;
  isNewCompany: boolean;
  hasSelectedCompany: boolean;
  submitted: boolean;
  globalErrorMessage?: string;
};

const isValidationPredicate = (_: ValidationPredicate | RegExp): _ is ValidationPredicate =>
  typeof _ === 'function';

type ValidationPredicate = (args?: {
  name?: string;
  value: unknown;
  node?: HTMLElement;
}) => boolean;

type ValidationSchema = {
  [field in AddNewContactFormField]: {
    errorText: string;
    validationCondition: ValidationPredicate | RegExp;
  };
};

class AddNewContactForm extends Component<Props, State> {
  validation: ValidationSchema = {
    firstName: {
      errorText: 'First name should not be blank',
      validationCondition: /.+/,
    },
    companyName: {
      errorText: 'Company name must be at least three letters long',
      validationCondition: /.{3,}/,
    },
    contactEmail: {
      errorText: 'Invalid email address',
      validationCondition: ({ value, node }: { value: string; node: HTMLInputElement }) => {
        const { requireContactEmail } = this.props;
        if (requireContactEmail && value.length === 0) {
          return false;
        }
        if (node && typeof node.checkValidity === 'function') {
          return node.checkValidity();
        }
        return true;
      },
    },
    contactTypes: {
      errorText: 'A contact must have at least one type',
      validationCondition: (
        target: { name: string; value: ContactTypes; node: HTMLInputElement } | undefined,
      ) => {
        const value = target?.value;

        return (value?.estimating || value?.construction) ?? false;
      },
    },
    selectedCompany: {
      errorText: 'Please select a company',
      validationCondition: () => {
        const {
          form: { selectedCompany },
        } = this.state;
        return selectedCompany !== undefined;
      },
    },
  };

  static defaultProps: Partial<Props> = {
    slider: undefined,
    selectedCompanyName: undefined,
    selectedCompanyId: undefined,
    requireContactEmail: false,
  };

  constructor(props: Props) {
    super(props);
    const { stage, selectedCompanyId, selectedCompanyName, requireContactEmail } = props;

    if (
      (selectedCompanyId && !selectedCompanyName) ||
      (selectedCompanyName && !selectedCompanyId)
    ) {
      throw Error('You must supply company name and id, or neither');
    }

    const companySelectedViaProps =
      selectedCompanyId !== undefined && selectedCompanyName !== undefined;

    this.state = {
      companySelectedViaProps,
      form: {
        firstName: '',
        lastName: '',
        contactPosition: '',
        contactPhone: '',
        contactEmail: '',
        contactTypes: {
          estimating: stage !== 'construction',
          construction: stage === 'construction',
        },

        // Company Fields
        selectedCompany: companySelectedViaProps
          ? { id: selectedCompanyId, name: selectedCompanyName }
          : undefined,
        companyName: selectedCompanyName || '',
        officePhone: '',
        officeFax: '',
        tradeIds: [],
        companyListIds: [],

        // Address Fields
        address: '',
        address2: '',
        suburb: '',
        city: '',
        province: '',
        state: '',
        postcode: '',
        country: '',
        longitude: null,
        latitude: null,
      },
      address: new E1Address(),
      validationResults: {
        firstName: false,
        contactEmail: !requireContactEmail,
        contactTypes: true,
        selectedCompany: companySelectedViaProps,
        companyName: false,
      },
      isNewCompany: false,
      hasSelectedCompany: companySelectedViaProps,
      submitted: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { setSubmitHandler } = this.props;
    setSubmitHandler(this.handleSubmit);
  }

  getCompanyNameErrorText = () => {
    const { isNewCompany } = this.state;
    return this.getErrorText(isNewCompany ? 'companyName' : 'selectedCompany');
  };

  getErrorText = (fieldName: AddNewContactFormField) => {
    const { submitted, validationResults } = this.state;
    if (submitted && validationResults[fieldName] !== undefined) {
      if (!validationResults[fieldName]) {
        return this.validation[fieldName].errorText;
      }
    }

    return '';
  };

  isValid = () => {
    const { validationResults } = this.state;
    return this.usedValidationFields().every((name) => validationResults[name]);
  };

  isFieldValid = ({
    name,
    value,
    node,
  }: {
    name: AddNewContactFormField;
    value: string | ContactTypes;
    node?: HTMLElement;
  }) => {
    if (this.validation[name]) {
      const { validationCondition } = this.validation[name];

      return isValidationPredicate(validationCondition)
        ? validationCondition({ name, value, node })
        : validationCondition.test(value.toString());
    }
    return true;
  };

  usedValidationFields = (): AddNewContactFormField[] => {
    const { isNewCompany } = this.state;

    return [
      'firstName',
      'contactEmail',
      'contactTypes',
      isNewCompany ? 'companyName' : 'selectedCompany',
    ];
  };

  handleSubmit = async () => {
    this.setState({ submitted: true, globalErrorMessage: undefined });
    if (this.isValid()) {
      const { form, isNewCompany } = this.state;
      const { updateAddressBookTableFunc, viewCompanySliderFunc } = this.props;

      try {
        const response = await new E1Request<
          { success: true; data: { companyId: number; companyName: string; contactId: number } },
          Form
        >(Routing.generate('api_address_book_contact_create'), 'POST', form, true).submit();

        const { companyId, companyName, contactId } = response.data;

        if (isNewCompany) {
          updateAddressBookTableFunc(companyId, contactId, companyName);
        }

        viewCompanySliderFunc(companyId, contactId, companyName);
      } catch (error) {
        this.setState({
          globalErrorMessage:
            error instanceof RequestFailedError && Object.keys(error.getErrors()).length > 0
              ? error.getErrors().toString()
              : 'An error occurred while creating the contact.',
        });
      }
    }
  };

  onDetailsChange = (data: { name: AddNewContactFormField; value: string | ContactTypes }) => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [data.name]: data.value,
      },
      validationResults: {
        ...prevState.validationResults,
        [data.name]: this.isFieldValid(data),
      },
    }));
  };

  handleDetailsChange = (data: { name: AddNewContactFormField; value: string | ContactTypes }) =>
    this.onDetailsChange(data);

  onAddressChange = (address: E1Address) => {
    this.setState((prevState) => ({
      form: { ...prevState.form, ...address.toObject() },
      address,
    }));
  };

  handleMultiSelectInputChange = (name: string, selectedIds: number[]) => {
    this.setState((prevState) => ({
      form: { ...prevState.form, [name]: selectedIds },
    }));
  };

  onCheckboxChange = ({ name, isChecked }: { name: string; isChecked: boolean }) => {
    const {
      form: { contactTypes },
    } = this.state;
    const newContactTypes = { ...contactTypes, [name]: isChecked };

    this.onDetailsChange({ name: 'contactTypes', value: newContactTypes });
  };

  handleCompanySelection = (newlySelectedCompany: { id: number; name: string }) => {
    this.adjustSliderHeight();
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        selectedCompany: newlySelectedCompany,
      },
      validationResults: { ...prevState.validationResults, selectedCompany: true },
      hasSelectedCompany: true,
      isNewCompany: false,
    }));
  };

  handleCompanyDeselection = () => {
    this.setState((prevState) => ({
      form: { ...prevState.form, selectedCompany: undefined },
      validationResults: { ...prevState.validationResults, selectedCompany: false },
      hasSelectedCompany: false,
      isNewCompany: false,
    }));
  };

  handleNewCompany = () => {
    this.adjustSliderHeight();
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        selectedCompany: undefined,
      },
      hasSelectedCompany: false,
      isNewCompany: true,
    }));
  };

  adjustSliderHeight = () => {
    const { slider } = this.props;
    if (slider) {
      slider.setHeight.call(slider);
    }
  };

  renderCompanyDetails = () => {
    const { isNewCompany, form, address } = this.state;

    if (!isNewCompany) {
      return null;
    }

    const { officePhone, officeFax, tradeIds, companyListIds } = form;

    return (
      <>
        <Row>
          <Col span={12}>
            <Alert variant={AlertVariant.Blue}>
              This is a new company. Please enter company details.
            </Alert>
          </Col>
        </Row>
        <CompanyDetails
          officePhone={officePhone}
          officeFax={officeFax}
          onChange={this.handleDetailsChange}
          selectedTradeIds={tradeIds}
          selectedCompanyListIds={companyListIds}
          onMultiSelectChange={this.handleMultiSelectInputChange}
        />
        <AddressForm
          address={address}
          handleAddressChange={this.onAddressChange}
          adjustHeight={this.adjustSliderHeight}
        />
      </>
    );
  };

  renderGlobalError() {
    const { globalErrorMessage } = this.state;

    if (!globalErrorMessage) {
      return null;
    }

    return (
      <Row>
        <Col span={12}>
          <Alert variant={AlertVariant.Red}>{globalErrorMessage}</Alert>
        </Col>
      </Row>
    );
  }

  render() {
    const { companySelectedViaProps, form, validationResults, hasSelectedCompany, isNewCompany } =
      this.state;
    const { canChooseContactType } = this.props;
    const {
      firstName,
      lastName,
      contactPosition,
      contactPhone,
      contactEmail,
      companyName,
      contactTypes,
      selectedCompany,
    } = form;

    const email = validationResults.contactEmail ? contactEmail : '';

    return (
      <form className="new-contact-form">
        {this.renderGlobalError()}
        <ContactDetails
          firstName={firstName}
          lastName={lastName}
          position={contactPosition}
          phone={contactPhone}
          email={contactEmail}
          showContactTypes={canChooseContactType}
          estimating={contactTypes.estimating}
          construction={contactTypes.construction}
          handleChange={this.onDetailsChange}
          getErrorText={this.getErrorText}
          handleCheckboxChange={this.onCheckboxChange}
        />
        {!companySelectedViaProps && (
          <section>
            <Row>
              <Col span={12}>
                <CompanySearch
                  companyName={companyName}
                  emailAddress={email}
                  errorText={this.getCompanyNameErrorText()}
                  hasSelectedCompany={hasSelectedCompany}
                  isNewCompany={isNewCompany}
                  onChange={this.handleDetailsChange}
                  onCompanySelection={this.handleCompanySelection}
                  onCompanyDeselection={this.handleCompanyDeselection}
                  onSwitchToNewCompany={this.handleNewCompany}
                  selectedCompany={selectedCompany}
                />
              </Col>
            </Row>
          </section>
        )}
        {this.renderCompanyDetails()}
      </form>
    );
  }
}

export default AddNewContactForm;
