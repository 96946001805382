import { ReactNode } from 'react';
import { Accordion, AccordionTitle } from '@estimateone/frontend-components';
import styles from './styles.scss';

type TitleProps = {
  tenderCount: number;
};

type TendersReadyForHandoverAccordionProps = TitleProps & {
  children: ReactNode;
};

const Title = ({ tenderCount }: TitleProps) => (
  <div className={styles.accordionTitleContainer}>
    <AccordionTitle titleText="Projects ready for handover" />
    <span>({`${tenderCount} project${tenderCount === 1 ? '' : 's'}`})</span>
  </div>
);

export const TendersReadyForHandoverAccordion = ({
  children,
  tenderCount,
}: TendersReadyForHandoverAccordionProps) => (
  <Accordion accordionTitle={<Title tenderCount={tenderCount} />} className={styles.accordion}>
    {children}
  </Accordion>
);
