import { useDocSyncQuery } from './useDocSyncQuery';
import { GET_STAGE_PROCORE_MAP_FOR_MODIFICATION } from '../queries';
import { stageProcoreMapForModification as StageProcoreMapForModification } from '../types/stageProcoreMapForModification';
import { EntityId } from '@ascension/types';

export const useGetStageProcoreMapForModification = (stageId: EntityId) => {
  const { data, loading, refetch } = useDocSyncQuery<StageProcoreMapForModification>(
    GET_STAGE_PROCORE_MAP_FOR_MODIFICATION,
    {
      variables: { stageId: String(stageId) },
    },
  );

  return {
    stageProcoreMapForModification: data?.stageProcoreMapForModification,
    refetch,
    loading,
  };
};
