import { useStockTradeList } from '@builder/features/SubbieNetwork/providers/AscensionDataProvider/hooks';
import { SubbieNetworkFilterDataQuery as STT } from '@builder/features/SubbieNetwork/providers/AscensionDataProvider/queries.generated';

/**
 * Returns an object of `stockTradeIds` and `coreStockTradeIds`
 * in which an id from any list is unique among all lists
 *
 * This function is specific to displaying a profiles trades in
 * network search results and because a) ascension stores stock
 * and core trade selections as separate lists of trade ids and
 * b) core trades are a subset of stock trades we can't simply
 * render both lists to generate our tags as this would produce
 * duplicates. Instead we de-dupe between the lists (the lists
 * themselves are already unique) to generate our final list of
 * trade ids.
 *
 * @pre `coreStockTradeIds` is a subset of `stockTradeIds`
 */
export const uniqueTradeIds = (stockTradeIds: number[], coreTradeIds: number[]) => {
  const stockTradeIdsWithoutCoreTradeIds = stockTradeIds.filter(
    (stockTradeId) => !coreTradeIds.includes(stockTradeId),
  );

  return {
    stockTradeIds: stockTradeIdsWithoutCoreTradeIds,
    coreTradeIds,
  };
};

type StockAndCoreTrades = {
  stockTrades: STT['stockTrades'];
  coreTrades: STT['stockTrades'];
};

export const useTradesLookup = (
  stockTradeIds: number[],
  coreTradeIds: number[],
): StockAndCoreTrades => {
  const allTrades = useStockTradeList() || [];

  const profileTradeIds = uniqueTradeIds(stockTradeIds, coreTradeIds);

  const stockAndCoreTrades: StockAndCoreTrades = {
    stockTrades: [],
    coreTrades: [],
  };

  return allTrades.reduce(({ stockTrades, coreTrades }, trade) => {
    if (profileTradeIds.stockTradeIds.includes(trade.id)) {
      stockTrades.push(trade);
    }

    if (profileTradeIds.coreTradeIds.includes(trade.id)) {
      coreTrades.push(trade);
    }

    return { stockTrades, coreTrades };
  }, stockAndCoreTrades);
};
