import $ from 'jquery';
import Routing from 'routing';
import E1Request from '../classes/E1Request';

$(() => {
  const $body = $('body');
  $body.on('click', '.office-select-trigger', () => {
    const route = Routing.generate('app_accountoffice_selectmodal');
    const req = new E1Request(route, 'GET');
    req.show_loading_modal = true;
    req.submit();
  });

  const officeFormDescriptor = '.add-office-form';
  const officeNewOfficeCheckDescriptor = '.new_office_option';
  const radioListDescriptor = '.radio-list';
  const autocompleteDescriptor = '.location_autocomplete';
  const timezoneDescriptor = '.timezone_hidden';

  $body.on('change', `${officeFormDescriptor} ${radioListDescriptor} input[type=radio]`, () => {
    $(officeNewOfficeCheckDescriptor).prop('checked', false);
  });

  $body.on('click', `${officeFormDescriptor} ${officeNewOfficeCheckDescriptor}`, function () {
    if ($(this).is(':checked')) {
      $(`${officeFormDescriptor} ${radioListDescriptor} input[type=radio]`).prop('checked', false);
    }
  });

  $body.on('keyup', `${officeFormDescriptor} ${autocompleteDescriptor}`, () => {
    $(`${officeFormDescriptor} ${radioListDescriptor} input[type=radio]`).prop('checked', false);
    $(officeNewOfficeCheckDescriptor).prop('checked', true);
  });
});
