import { gql } from '@apollo/client';
import { ColumnStatusItem } from './fragments';

export const UPDATE_LETTING_COLUMN_STATUS = gql`
  mutation UpdateLettingColumnStatus($input: UpdateLettingColumnStatusesInput!) {
    updateLettingColumnStatuses(input: $input) {
      ...ColumnStatusItem
    }
  }
  ${ColumnStatusItem}
`;
