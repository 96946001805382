import Routing from 'routing';
import { E1Link } from '@estimateone/frontend-components';
import { WarningAlert } from '@ascension/components/fec/WarningAlert/WarningAlert';
import styles from './styles.scss';

const alertContent = {
  admin: {
    callToActionText: 'Add a trade to your profile to connect with tendering subcontractors.',
    additionalText: 'Your trade is used to connect you to best fit subcontractors.',
    linkHref: Routing.generate('app_account_config'),
    linkText: 'Add a trade',
  },
  standardUser: {
    callToActionText:
      'An account admin must add a trade to your profile to connect with tendering subcontractors.',
    additionalText: 'Your trade is used to connect you to best fit subcontractors.',
    linkHref: Routing.generate('app_myteam_index'),
    linkText: 'Find an account admin',
  },
} as const;

type MissingTradesAlertProps = {
  readonly isAdminUser: boolean | undefined;
};

export const MissingTradesAlert = ({ isAdminUser = false }: MissingTradesAlertProps) => {
  const alertType = isAdminUser ? 'admin' : 'standardUser';
  const { callToActionText, additionalText, linkText, linkHref } = alertContent[alertType];

  return (
    <WarningAlert className={styles.alertContainer}>
      <div className={styles.wrapper}>
        <div className={styles.alertTitle}>{callToActionText}</div>
        <div>
          {additionalText}
          <E1Link
            className={styles.alertLink}
            href={linkHref}
            target="_blank"
            rel="noopener noreferrer"
          >
            {linkText}
          </E1Link>
        </div>
      </div>
    </WarningAlert>
  );
};
