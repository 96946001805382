import { CellProps } from '@estimateone/frontend-components';
import { LettingScheduleStatus, getLettingScheduleStatus } from '../LettingScheduleStatus';
import { QuoteCoverageStatus } from '../QuoteCoverageStatus';
import { computePackageQuoteCoverage } from '../utils/computePackageQuoteCoverage';
import { Package } from '@builder/features/ProcurementLettingSchedule/types';
import styles from '../styles.scss';

export const QuoteCoverageCell =
  (packageQuoteCoverage: ReturnType<typeof computePackageQuoteCoverage>) =>
  ({ row: { original } }: CellProps<Package>) => {
    const { id: packageId, title, quotesDueAt } = original;
    const status = getLettingScheduleStatus(original);
    return (
      <div className={styles.cellAlign}>
        {status !== LettingScheduleStatus.Draft ? (
          <QuoteCoverageStatus
            keyPrefix={`${title}_${packageId}`}
            packageDueDate={new Date(quotesDueAt ?? 0)}
            coverageStatus={packageQuoteCoverage[packageId]}
          />
        ) : (
          '-'
        )}
      </div>
    );
  };
