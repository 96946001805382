import useLocalStorage from '@ascension/components/hooks/LocalStorage';

const SSC_WATCHLIST_MODAL_OPT_OUT_LOCAL_STORAGE_KEY = 'ssc_watchlist_status_modal_opt_out';

export const useSSCModalOptOut = () => {
  const { get: isOptedOut, set: setIsOptedOut } = useLocalStorage<boolean>(
    SSC_WATCHLIST_MODAL_OPT_OUT_LOCAL_STORAGE_KEY,
  );

  return { isOptedOut, toggleOptOut: () => setIsOptedOut(!isOptedOut()) };
};
