import { useContext } from 'react';
import { BuilderQuotesContext } from '../index';

export const useBuilderQuotesContext = () => {
  const context = useContext(BuilderQuotesContext);
  if (!context) {
    throw new Error(
      'useBuilderQuotesContext should only be called within <BuilderQuotesProvider />',
    );
  }

  return context;
};
