import { useContext, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { joinClassNames, RadioButton } from '@estimateone/frontend-components';
import { useCloseDrawer } from '@estimateone/frontend-components/src/components/Drawer';
import { FormApi } from 'final-form';
import { validateForm } from '@builder/common/SubbieNetwork/utils/filters/validator';
import { getDistanceOptions } from '@builder/common/SubbieNetwork/utils/searchArea/locationOptions';
import { CategoryFilter } from './CategoryFilter';
import { ContractSizeFilter, maxOptions, minOptions } from './ContractSizeFilter';
import { DistanceFilter } from './DistanceFilter';
import { LocationFilter } from './LocationFilter';
import { SectionTitle } from './SectionTitle';
import { SubbieNetworkFilterFormSpy } from './SubbieNetworkFilterFormSpy';
import { TradeFilter } from './TradeFilter';
import { WorkforceSizeFilter, WorkforceSizeOpts } from './WorkforceSizeFilter';
import { DistanceUnit, LabelledOption } from '@shared';
import {
  useAccountPrimaryCountryId,
  useCategoryOptions,
  useUserImperialSetting,
} from '@builder/context/SubbieNetworkFilterDataProvider/hooks';
import { NetworkSearchContext } from '@builder/features/SubbieNetworkInvitations/context/NetworkSearchProvider';
import { Country } from '@ascension/enums';
import {
  SubbieNetworkFilterFormFields,
  SubbieNetworkFilterFormValues,
} from '@builder/common/SubbieNetwork/utils/filters/types';
import { SubbieNetworkFilterFormId } from '@builder/features/SubbieNetworkInvitations/types';
import { ValueLabelOption } from '@estimateone/frontend-components/src/components/Form/Select/types';
import styles from './styles.module.scss';

const findOption = (options: LabelledOption<number>[] | null, selectedValue?: number) =>
  selectedValue && options ? options.find(({ value }) => selectedValue === value) : undefined;

const isSet = (field: unknown) => field !== undefined && field !== null && field !== false;

type SubbieNetworkFilterFormProps = {
  setSelectedFiltersCount: (num: number) => void;
  stageTradeOptions: ValueLabelOption<number>[] | null;
};

export const SubbieNetworkFilterForm = ({
  setSelectedFiltersCount,
  stageTradeOptions,
}: SubbieNetworkFilterFormProps) => {
  const {
    tradeId,
    setTradeId,
    categoryId,
    setCategoryId,
    location,
    setLocation,
    contractSize,
    setContractSize,
    workforceSize,
    setWorkforceSize,
    searchArea,
    setSearchArea,
    distance,
    setDistance,
    setDistanceLabel,
  } = useContext(NetworkSearchContext);

  const categoryOptions = useCategoryOptions();
  const closeTheDrawer = useCloseDrawer();
  const primaryCountryId = useAccountPrimaryCountryId();
  const useImperial = useUserImperialSetting();
  const distanceUnit = useImperial ? DistanceUnit.MILES : DistanceUnit.KM;

  const [initialFormValues, setInitialFormValues] = useState<SubbieNetworkFilterFormValues>({
    [SubbieNetworkFilterFormFields.TradeFilterId]: findOption(stageTradeOptions, tradeId),

    [SubbieNetworkFilterFormFields.WorkforceFilterId]: WorkforceSizeOpts.find(
      ({ value }) => value === workforceSize,
    ),

    [SubbieNetworkFilterFormFields.Location]: location,

    [SubbieNetworkFilterFormFields.Distance]: getDistanceOptions(distanceUnit).find(
      ({ value }) => value === distance,
    ),

    [SubbieNetworkFilterFormFields.CategoryFilterId]: findOption(categoryOptions, categoryId),

    [SubbieNetworkFilterFormFields.ContractSizeMin]: minOptions.find(
      ({ value }) => value === contractSize.min,
    ),

    [SubbieNetworkFilterFormFields.ContractSizeMax]: maxOptions.find(
      ({ value }) => value === contractSize.max,
    ),

    [SubbieNetworkFilterFormFields.SearchArea]: searchArea ?? 'office-location',
  });

  const handleFormSubmit = (values: SubbieNetworkFilterFormValues): void => {
    setTradeId(values.tradeFilter?.value);
    setCategoryId(values.categoryFilter?.value);
    setContractSize({
      min: values.contractSizeMin?.value,
      max: values.contractSizeMax?.value,
    });
    setWorkforceSize(values.workforceFilter?.value);

    setSearchArea(values.searchArea ?? 'office-location');
    setDistance(values.distance?.value);
    setDistanceLabel(values.distance?.label);

    if (values.location?.lat && values.location?.lon && values.location?.locationLabel) {
      setLocation({
        lat: values.location.lat,
        lon: values.location.lon,
        locationLabel: values.location.locationLabel,
        serviceArea: values.location.serviceArea,
      });
    } else {
      setLocation(undefined);
    }

    closeTheDrawer();
  };

  const handleReset = (form: FormApi<SubbieNetworkFilterFormValues>) => () => {
    setInitialFormValues({});
    form.restart();
  };

  const officeLocationPlaceholder =
    primaryCountryId === Country.COUNTRY_AUSTRALIA
      ? 'Find a suburb or postcode...'
      : 'Find a city or postcode...';

  const serviceAreaPlaceholder =
    primaryCountryId === Country.COUNTRY_AUSTRALIA
      ? 'Find a state, suburb or postcode...'
      : 'Find a city or postcode...';

  return (
    <Form<SubbieNetworkFilterFormValues>
      onSubmit={handleFormSubmit}
      initialValues={initialFormValues}
      validate={validateForm}
    >
      {({ handleSubmit, values, submitFailed, errors, form }) => (
        <form
          id={SubbieNetworkFilterFormId}
          className={styles.form}
          onSubmit={handleSubmit}
          onReset={handleReset(form)}
        >
          <SubbieNetworkFilterFormSpy setSelectedFiltersCount={setSelectedFiltersCount} />

          <SectionTitle
            valueSelected={isSet(values[SubbieNetworkFilterFormFields.TradeFilterId])}
            hasError={
              submitFailed &&
              !!errors &&
              errors[SubbieNetworkFilterFormFields.TradeFilterId]?.length > 0
            }
          >
            Trades
          </SectionTitle>

          <TradeFilter tradeOptions={stageTradeOptions} />

          <SectionTitle
            valueSelected={
              isSet(values[SubbieNetworkFilterFormFields.Distance]) &&
              isSet(values[SubbieNetworkFilterFormFields.Location])
            }
            hasError={!!errors && errors[SubbieNetworkFilterFormFields.Location]?.length > 0}
          >
            Search Area
          </SectionTitle>
          <div className={styles.searchArea}>
            <Field
              name={SubbieNetworkFilterFormFields.SearchArea}
              type="radio"
              id="office-location"
              value="office-location"
            >
              {({ input }) => (
                <div>
                  <RadioButton id="office-location-input" className={styles.radioButton} {...input}>
                    Office Location
                  </RadioButton>
                  <div className={styles.labelContent}>
                    <p>Find companies with an office located in the area selected</p>
                    <div
                      className={joinClassNames(
                        styles.filters,
                        styles.colSpan1,
                        !input.checked && styles.hidden,
                      )}
                    >
                      <LocationFilter
                        countryId={primaryCountryId ?? Country.COUNTRY_AUSTRALIA}
                        unit={distanceUnit}
                        placeholder={officeLocationPlaceholder}
                      />
                    </div>
                    <div
                      className={joinClassNames(
                        styles.filters,
                        styles.colSpan1,
                        !input.checked && styles.hidden,
                      )}
                    >
                      <DistanceFilter unit={distanceUnit} />
                    </div>
                  </div>
                </div>
              )}
            </Field>
            <Field
              name={SubbieNetworkFilterFormFields.SearchArea}
              type="radio"
              id="service-area"
              value="service-area"
            >
              {({ input }) => (
                <div>
                  <RadioButton id="service-area-input" className={styles.radioButton} {...input}>
                    Service Area
                  </RadioButton>
                  <div className={styles.labelContent}>
                    <p>Find companies willing to work in the area selected</p>
                    <div
                      className={joinClassNames(styles.filters, !input.checked && styles.hidden)}
                    >
                      <LocationFilter
                        countryId={primaryCountryId ?? Country.COUNTRY_AUSTRALIA}
                        unit={distanceUnit}
                        placeholder={serviceAreaPlaceholder}
                        initialValue={initialFormValues[SubbieNetworkFilterFormFields.Location]}
                        placePredictionsTypes={
                          primaryCountryId === Country.COUNTRY_AUSTRALIA
                            ? [
                                'locality',
                                'administrative_area_level_3',
                                'postal_code',
                                'administrative_area_level_1',
                              ]
                            : undefined
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
            </Field>
          </div>
          <SectionTitle
            valueSelected={
              isSet(values[SubbieNetworkFilterFormFields.ContractSizeMin]) ||
              isSet(values[SubbieNetworkFilterFormFields.ContractSizeMax])
            }
          >
            Contract Size
          </SectionTitle>
          <div>
            <ContractSizeFilter />
          </div>
          <SectionTitle
            valueSelected={isSet(values[SubbieNetworkFilterFormFields.WorkforceFilterId])}
          >
            Employees
          </SectionTitle>
          <div className={styles.colSpan1}>
            <WorkforceSizeFilter />
          </div>
          <SectionTitle
            valueSelected={isSet(values[SubbieNetworkFilterFormFields.CategoryFilterId])}
          >
            Category
          </SectionTitle>
          <CategoryFilter categoryOptions={categoryOptions} dropUp />
        </form>
      )}
    </Form>
  );
};
