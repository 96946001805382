import { FormEvent } from 'react';
import { Form } from 'react-final-form';
import { Button, Col, DatePickerFormat, Row } from '@estimateone/frontend-components';
import { getTomorrowsDate, validateAddSecretForm } from '@builder/features/SsoSecrets/utils';
import { DatePickerField, TextInputField } from '@shared/FinalForm/Field';
import { AddSecretFormFieldName, AddSecretFormValues } from '@builder/features/SsoSecrets/types';
import styles from './styles.scss';

const ADD_SECRET_FORM_ID = 'add-secret-form';

type AddSecretFormLayoutProps = {
  onSubmit: (event: FormEvent) => void;
  isAddActiveSecretButtonDisabled: boolean;
};

const AddSecretFormLayout = ({
  onSubmit,
  isAddActiveSecretButtonDisabled,
}: AddSecretFormLayoutProps) => (
  <form onSubmit={onSubmit} id={ADD_SECRET_FORM_ID}>
    <Row>
      <Col span={8}>
        <h4 className={styles.gutter}>Add a Secret</h4>
        <p className={styles.gutter}>
          Add a new active Secret if none exists, or add an upcoming Secret (to replace an active
          Secret only when it expires).
        </p>
      </Col>
      <Col span={8}>
        <TextInputField
          id="secret-input"
          fieldName={AddSecretFormFieldName.Secret}
          label="Secret"
          placeholder="Add a Secret here"
          className="fs-hide"
          isRequired
        />
      </Col>
      <Col span={8} className={styles.gutter}>
        <DatePickerField
          id="secret-expiry-date"
          fieldName={AddSecretFormFieldName.ExpiryDate}
          label="Expiry Date"
          placeholderText="DD-MM-YY"
          dateFormat={DatePickerFormat.DateOnlyShortMonth}
          minDate={getTomorrowsDate()}
          popperPlacement="bottom"
          popperModifiers={[
            { name: 'flip', enabled: true, options: { fallbackPlacements: ['bottom'] } },
          ]}
          isRequired
        />
      </Col>
      <Col span={8} alignContentX="right">
        <Button type="submit" disabled={isAddActiveSecretButtonDisabled}>
          Add Active Secret
        </Button>
      </Col>
    </Row>
  </form>
);

type AddSecretKeyFinalFormProps = { handleAddSecretSubmit: (values: AddSecretFormValues) => void };

export const AddSecretForm = ({ handleAddSecretSubmit }: AddSecretKeyFinalFormProps) => (
  <Form<AddSecretFormValues> onSubmit={handleAddSecretSubmit} validate={validateAddSecretForm}>
    {({ handleSubmit, form, hasValidationErrors, values }) => {
      const handleOnSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        await handleSubmit(e);
        form.restart();
      };

      const isPreventSubmit =
        !values[AddSecretFormFieldName.Secret] ||
        !values[AddSecretFormFieldName.ExpiryDate] ||
        hasValidationErrors;

      return (
        <AddSecretFormLayout
          onSubmit={handleOnSubmit}
          isAddActiveSecretButtonDisabled={isPreventSubmit}
        />
      );
    }}
  </Form>
);
