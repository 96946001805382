import Routing from 'routing';
import { captureMessage } from '@sentry/browser';
import getSupportDetails from '../../../../js/utils/support_details';
import { CTA, CTASize, CTAVariant } from '../../../fec/CTA';
import { useProvisionedLicense } from './hooks';
import { EntityId } from '../../../../types';

const { phone } = getSupportDetails();

type ProvisionedLicenseCTAProps = { provisionedLicenseId: EntityId; size?: CTASize };

const ProvisionedLicenseCTA = ({ provisionedLicenseId, size }: ProvisionedLicenseCTAProps) => {
  const { loading, productName, invoiceItemId, invoiceId, invoiceNumber } =
    useProvisionedLicense(provisionedLicenseId);

  if (loading) return null;

  if (!invoiceId) {
    captureMessage(`Invoice missing for invoice item id ${invoiceItemId}`, {
      extra: {
        provisionedLicenseId,
        productName,
      },
    });

    return (
      <>
        There has been an issue accessing the Consultants feature, please contact support on {phone}{' '}
        to resolve this.
      </>
    );
  }

  return (
    <CTA
      text={`Once payment is received, you will get immediate access to ${productName}.`}
      variant={CTAVariant.Yellow}
      size={size}
      primaryButtonAttr={{
        children: 'Pay now',
        href: Routing.generate('app_pay_invoice', { invoice_number: invoiceNumber ?? undefined }),
        target: '_blank',
        rel: 'noopener noreferrer',
      }}
      secondaryButtonAttr={{
        children: 'View invoice',
        href: Routing.generate('app_invoice_view', { id: invoiceId }),
        target: '_blank',
        rel: 'noopener noreferrer',
      }}
    />
  );
};

export default ProvisionedLicenseCTA;
