import { gql } from '@apollo/client';

export const SEARCH_ADDRESS_BOOK_COMPANIES = gql`
  query SearchAddressBookCompanies($query: String!) {
    searchCompanyNames(query: $query) {
      value: id
      label: name
    }
  }
`;
