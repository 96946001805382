import { format, formatDistanceToNowStrict } from 'date-fns';

export const getRelativeDurationToNowOrNull = (date: Date | string | undefined | null) => {
  if (!date) {
    return null;
  }

  try {
    if (date instanceof Date) {
      return formatDistanceToNowStrict(date, { addSuffix: true });
    }
    return formatDistanceToNowStrict(new Date(date), { addSuffix: true });
  } catch (error) {
    return null;
  }
};

export const getMonthYearOrNull = (date?: string | Date) => {
  if (!date) {
    return null;
  }

  try {
    if (date instanceof Date) {
      return format(date, 'MMM yyyy');
    }
    return format(new Date(date), 'MMM yyyy');
  } catch (error) {
    return null;
  }
};

const getMonthYear = (date: string) => format(new Date(date), 'MMM yyyy');

export const getDateMonthYearStringOrNull = (date?: string | Date) => {
  if (!date) {
    return null;
  }

  try {
    return getMonthYear(typeof date === 'string' ? date : date.toDateString());
  } catch (error) {
    return null;
  }
};
