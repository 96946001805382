import Routing from 'routing';
import {
  CellProps,
  E1Link,
  Table,
  TableColumn,
  LinkVariant,
} from '@estimateone/frontend-components';
import addProtocolToURL from '../../../../../shared/Util/addProtocolToURL';
import { Action, Event, useAnalytics } from '@ascension/components/hooks/Analytics';
import { GetProjectConsultants_project as Project } from '../types/GetProjectConsultants';
import { RequiresProjectId } from './types';
import { EntityId } from '@ascension/types';

type ProjectConsultant = Extract<
  Project,
  { __typename: 'ViewableNoticeboardProject' }
>['consultants'][0];

type Consultant = {
  id: EntityId;
  name: string;
  types: Set<string>;
  domain?: string | null;
};

const groupAndMergeDetails = (
  acc: Map<number, Consultant>,
  { consultant, type }: ProjectConsultant,
) => {
  const { id: consultantId, name: consultantName, domain } = consultant;
  const { name: typeName } = type;

  if (!acc.has(consultantId)) {
    acc.set(consultantId, {
      id: consultantId,
      name: consultantName,
      domain,
      types: new Set([typeName]),
    });
  } else {
    const existing = acc.get(consultantId);
    if (existing) {
      existing.types.add(typeName);
    }
  }

  return acc;
};

const getProfileUrl = (consultantId: EntityId) =>
  Routing.generate('app_addressbook_consultant_profile', { id: consultantId });

export const ConsultantsTable = ({
  projectConsultants,
  projectId,
}: RequiresProjectId<{ projectConsultants: ProjectConsultant[] }>) => {
  const { addEvent } = useAnalytics(Event.INTERACT);
  const consultants: Map<number, Consultant> = projectConsultants.reduce(
    groupAndMergeDetails,
    new Map(),
  );

  const handleWebsiteClick = (domain: string, consultantId: EntityId) => {
    addEvent({
      action: Action.CONSULTANT_WEBSITE_CLICK,
      projectId,
      consultantId,
    });
    window.open(addProtocolToURL(domain), '_blank', 'noopener,noreferrer');
  };

  const columns: TableColumn<Consultant>[] = [
    {
      Header: 'Company',
      Cell: ({
        row: {
          original: { id, name },
        },
      }: CellProps<Consultant>) => (
        <E1Link
          variant={LinkVariant.Grey}
          link={getProfileUrl(id)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {name}
        </E1Link>
      ),
      disableSortBy: true,
    },
    {
      Header: 'Type(s)',
      Cell: ({ row: { original } }: CellProps<Consultant>) => (
        <span>{Array.from(original.types).join(', ')}</span>
      ),
      disableSortBy: true,
    },
    {
      Header: 'Website',
      Cell: ({ row: { original } }: CellProps<Consultant>) =>
        original?.domain ? (
          <E1Link onClick={() => handleWebsiteClick(original.domain ?? '', original.id)}>
            {original.domain}
          </E1Link>
        ) : (
          <span>-</span>
        ),
      disableSortBy: true,
    },
  ];

  return <Table<Consultant> columns={columns} data={Array.from(consultants.values())} />;
};
