import DefaultScheduleSettingsImg from '../../../../../images/default-schedule-settings.png';
import { Image } from '@shared';
import styles from './styles.scss';

export const InfoCard = () => (
  <div className={styles.accountSettingsInfoCard}>
    <div className={styles.accountSettingsInfoCardHeading}>Save time with default timeframes</div>
    <div>
      <Image
        src={DefaultScheduleSettingsImg}
        width={272}
        alt="An example of a Schedule, showing the Let By and Start on Site date columns, and the Timeframe column in between, which is circled."
      />
    </div>
    <div>
      Add your company’s default timeframes once. Then enter Start on Site dates on project
      Schedules to automatically calculate key dates (like Let By dates).
    </div>
    <div>These timeframes apply to new projects only, and can be updated on the Schedule.</div>
  </div>
);
