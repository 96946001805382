import { gql } from '@apollo/client';

export const AVAILABLE_TYPES = gql`
  query AvailableConsultantTypes {
    consultantTypes {
      id
      name
    }
  }
`;

export const GET_CONSULTANT_DETAILS_ADDON = gql`
  query GetConsultantDetailsAddon {
    addon(name: CONSULTANT_DETAILS) {
      id
      name
      shortDescription
      longDescription
      pricingOptions {
        billingCycle
        priceExGST {
          amount
        }
      }
    }
  }
`;
