import { useQuery } from '@apollo/client';
import SuggestCorrectionSection, {
  CorrectionType,
} from '@subbie/features/ConsultantFeature/SuggestCorrectionSection';
import { ConsultantsTableEmpty, ConsultantsTable } from './ConsultantsTable';
import { CONDEETS_START_OF_DATA_DATE } from './ConsultantsTable/const';
import { getDateOrDefault } from './ConsultantsTable/util';
import LoadingSpinner from '@shared/LoadingSpinner';
import { GET_PROJECT_CONSULTANTS } from './queries';
import { RequiresProjectId } from './ConsultantsTable/types';
import {
  GetProjectConsultants,
  GetProjectConsultantsVariables,
} from './types/GetProjectConsultants';
import styles from './styles.scss';

export const ConsultantsSection = ({ projectId }: RequiresProjectId) => {
  const { data, loading } = useQuery<GetProjectConsultants, GetProjectConsultantsVariables>(
    GET_PROJECT_CONSULTANTS,
    { variables: { projectId } },
  );

  if (loading || !data) {
    return <LoadingSpinner />;
  }

  const { project } = data;

  if (project.__typename !== 'ViewableNoticeboardProject') {
    return null;
  }

  const { createdAt, consultants: consultantRelationships } = project;

  if (!consultantRelationships.length) {
    return (
      <ConsultantsTableEmpty createdAt={getDateOrDefault(createdAt, CONDEETS_START_OF_DATA_DATE)} />
    );
  }

  return (
    <section className={styles.consultantsContainer}>
      <ConsultantsTable projectConsultants={consultantRelationships} projectId={projectId} />
      <SuggestCorrectionSection id={projectId} type={CorrectionType.Project} />
    </section>
  );
};
