import { MutableRefObject } from 'react';
import { CellProps, TableInstance } from '@estimateone/frontend-components';
import { InlineEditableInteger } from '@builder/common/InlineEditableInteger';
import { Trigger } from '../TimingCalculator/autoCalculateTimings';
import { InputIdentifiers, getTimings } from '../utils/lettingTimingHelper';
import { DebounceId } from '../hooks/useDebounceList';
import { Package } from '@builder/features/ProcurementLettingSchedule/types';
import styles from '../styles.scss';

export const DateIntervalCell =
  (
    tableRef: MutableRefObject<TableInstance<Package> | undefined>,
    debouncedIntervalChangeHandler: (id: DebounceId, args: unknown) => void,
    inputIdentifier: InputIdentifiers,
    trigger: Trigger,
    ariaLabel: string,
    inputName: string,
  ) =>
  ({ value, row: { original } }: CellProps<Package, number | undefined>) => (
    <div className={styles.cellAlign}>
      <InlineEditableInteger
        id={`${inputIdentifier}${original.id}`}
        aria-label={ariaLabel}
        className={styles.dateInterval}
        value={value}
        onValueChange={(currentValue) => {
          const timings = getTimings(
            currentValue ?? undefined,
            tableRef.current,
            original,
            trigger,
            inputName,
          );
          debouncedIntervalChangeHandler(original.id, () => timings);
        }}
      />
    </div>
  );
