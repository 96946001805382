import { joinClassNames } from '@estimateone/frontend-components';
import { ProfileLayout } from '@profiles/apps/CompanyProfile';
import { AboutUsLoading } from '@profiles/features/CompanyProfile/components/AboutUs';
import { Card, CardBody } from '@profiles/features/CompanyProfile/components/Card';
import { CategoriesLoader } from '@profiles/features/CompanyProfile/components/Categories';
import { CompanyInfoLoading } from '@profiles/features/CompanyProfile/components/CompanyInfo';
import { GlobalErrorBanner } from '@profiles/features/CompanyProfile/components/GlobalErrorBanner';
import { OfficeContactListLoader } from '@profiles/features/CompanyProfile/components/OfficeContactList';
import { PrimaryContactLoader } from '@profiles/features/CompanyProfile/components/PrimaryContact';
import { ABNBadgeLoading } from '@profiles/features/CompanyProfile/components/ProfileBadgeSection/ABNBadge';
import { ContractAndWorkforceSizeLoading } from '@profiles/features/CompanyProfile/components/ProfileBadgeSection/ContractAndWorkforceSize/ContractAndWorkforceSize';
import { LocationBadgeLoader } from '@profiles/features/CompanyProfile/components/ProfileBadgeSection/LocationBadge/LocationBadge';
import { ProjectsBadgeWrapperLoading } from '@profiles/features/CompanyProfile/components/ProfileBadgeSection/ProjectsBadgeWrapper/ProjectsBadgeWrapper';
import { ProjectHistoryLoader } from '@profiles/features/CompanyProfile/components/ProjectHistory';
import { TradesLoadingView } from '@profiles/features/CompanyProfile/components/Trades';
import styles from './CompanyProfile.module.scss';
import { Divider } from './Divider';

export const CompanyProfileSkeletonLoader = ({ layout }: { layout: ProfileLayout }) => (
  <>
    {layout === 'page' && (
      <div className={styles.profileHeader}>
        <GlobalErrorBanner />
        <h1 className={styles.title}>Company Profile</h1>
        <CompanyInfoLoading />
      </div>
    )}
    <div className={styles.profileBadges}>
      <div
        className={joinClassNames(styles.container, layout === 'slider' && styles.sliderContainer)}
      >
        <ABNBadgeLoading />
        <ProjectsBadgeWrapperLoading />
        <Divider />
        <ContractAndWorkforceSizeLoading />
        <Divider />
        <LocationBadgeLoader />
      </div>
    </div>
    <div className={styles.aside}>
      <Card>
        <CardBody>
          <TradesLoadingView />
          <CategoriesLoader />
        </CardBody>
        <PrimaryContactLoader />
      </Card>
    </div>
    <div
      className={joinClassNames(
        styles.profileContent,
        layout === 'slider' && styles.sliderProfileContent,
      )}
    >
      <AboutUsLoading />
      <OfficeContactListLoader />
      <ProjectHistoryLoader />
    </div>
  </>
);
