import $ from 'jquery';
import Routing from 'routing';
import E1Request from '../classes/E1Request';

$(async () => {
  const $body = $('body');

  const submitRequestWithLoadingModal = (url: string) =>
    new E1Request(url).setShowLoadingModal().submit();

  $body.on('click', '.update-password-trigger', () =>
    submitRequestWithLoadingModal(Routing.generate('app_account_updatepassword')),
  );

  $body.on('click', '.create-password-trigger', () =>
    submitRequestWithLoadingModal(Routing.generate('app_account_createpassword')),
  );

  $body.on('click', '.enable-2fa', () =>
    submitRequestWithLoadingModal(Routing.generate('app_account_enable2fa')),
  );

  $body.on('click', '.re-enable-2fa', async () => {
    await new E1Request(Routing.generate('app_account_reenable2fa')).submit();
    window.location.reload(); // It's not the most elegant, but it's easier than messing with the DOM
  });

  $body.on('click', '.disable-2fa', () =>
    submitRequestWithLoadingModal(Routing.generate('app_account_disable2fa')),
  );

  if (parseInt($body.data('from-forgot-token'), 10)) {
    await submitRequestWithLoadingModal(
      Routing.generate('app_account_createpassword', { isRequired: true }),
    );
  }
});
