import Routing from 'routing';
import { Icon, IconName, Wrapper } from '@estimateone/frontend-components';
import { TenderHandoverWizard } from '../../features/TenderHandoverWizard';
import styles from './styles.scss';

export const TenderHandoverPage = ({ stageId }: { stageId: number }) => (
  <>
    <header className={styles.header}>
      <Wrapper>
        <div className={styles.breadcrumb}>
          <Icon name={IconName.ArrowLeft} />
          <a href={Routing.generate('app_default_index')}>Your Projects</a>
        </div>
        <h1>Start Procurement Phase</h1>
      </Wrapper>
    </header>

    <TenderHandoverWizard stageId={stageId} />
  </>
);
