import { AlertIcon, joinClassNames, Tag, TagVariant } from '@estimateone/frontend-components';
import { isPackageEmpty } from '../../utils';
import { NumberOfSelectedContactsForPackage } from '@builder/features/SubbieNetworkInvitations/context/SelectedContactsProvider';
import { Package } from '@builder/features/SubbieNetworkInvitations/types';
import styles from './styles.scss';

type PackageStatusTagProps = {
  isSelectedPackage: boolean;
  isEmptyPackage: boolean;
  invitedContactsCount: number;
  selectedContactsCount: number;
};

const PackageStatusTag = ({
  isSelectedPackage,
  isEmptyPackage,
  invitedContactsCount,
  selectedContactsCount,
}: PackageStatusTagProps) => {
  if (isEmptyPackage) {
    return (
      <Tag
        variant={TagVariant.Warning}
        text="Empty package"
        icon={<AlertIcon className={styles.alertIcon} />}
        className={styles.emptyPackageTag}
      />
    );
  }

  if (selectedContactsCount > 0) {
    return <Tag variant={TagVariant.Plain} text={`${selectedContactsCount} selected`} />;
  }

  if (invitedContactsCount > 0) {
    return (
      <Tag
        variant={TagVariant.Plain}
        text={`${invitedContactsCount} invited`}
        className={styles.invitedTag}
      />
    );
  }

  return isSelectedPackage ? <Tag variant={TagVariant.Plain} text="0 selected" /> : <span>-</span>;
};

export type PackageSelectionSidebarProps = {
  packages: Package[] | undefined;
  selectedPackage: Package | undefined;
  onPackageSelect: (option: Package) => void;
  numberOfSelectedContactsForPackage: NumberOfSelectedContactsForPackage;
};

export const PackageSelectionSidebar = ({
  packages,
  selectedPackage,
  onPackageSelect,
  numberOfSelectedContactsForPackage,
}: PackageSelectionSidebarProps) => (
  <div className={styles.packageSidebarContainer}>
    <span className={styles.packageSidebarTitle}>Packages</span>
    {packages?.map((pkg) => {
      const isSelectedPackage = selectedPackage?.id === pkg.id;
      const isEmptyPackage = isPackageEmpty(pkg);
      const selectedContactsCount = numberOfSelectedContactsForPackage(pkg.id);

      return (
        <button
          key={pkg.id}
          type="submit"
          aria-label={pkg.title}
          disabled={isEmptyPackage}
          className={joinClassNames(
            styles.packageSelection,
            isSelectedPackage ? styles.activePackageSelection : '',
            isEmptyPackage ? styles.disabledPackageSelection : '',
          )}
          onClick={(event) => {
            event.preventDefault();
            onPackageSelect(pkg);
          }}
        >
          {isSelectedPackage && <span className={styles.selectedPackageIndicator} />}
          <span
            className={joinClassNames(
              styles.packageLabel,
              isSelectedPackage ? styles.selectedPackageLabel : '',
              isEmptyPackage ? styles.disabledPackageLabel : '',
            )}
          >
            {pkg.title}
          </span>
          <PackageStatusTag
            isSelectedPackage={isSelectedPackage}
            isEmptyPackage={isEmptyPackage}
            invitedContactsCount={pkg.rfqs.length}
            selectedContactsCount={selectedContactsCount}
          />
        </button>
      );
    })}
  </div>
);
