import { useMutation } from '@apollo/client';
import { E1Link } from '@estimateone/frontend-components';
import moment from 'moment-timezone';
import DeleteProjectNoteProvider, {
  useDeleteProjectNotesContext,
} from './DeleteProjectNoteProvider';
import useFlashMessage from '../../../../../shared/Util/useFlashMessage';
import { useCurrentUser } from '@subbie/context/AccountProvider/hooks';
import { DELETE_NOTE } from './mutations';
import { Note } from '../../types';
import { DeleteNote, DeleteNoteVariables } from './types/DeleteNote';
import styles from './styles.scss';

type ProjectNoteProps = {
  deleteProjectNote?: (response: Pick<Note, 'id'>) => void;
} & Omit<Note, '__typename' | 'deletedAt'>;

const ProjectNote = ({ id, text, authorName, createdAt, deleteProjectNote }: ProjectNoteProps) => {
  const currentUser = useCurrentUser();
  const isAdmin = currentUser?.isAdmin ?? false;
  const { success, warning } = useFlashMessage();
  const dispatch = useDeleteProjectNotesContext();

  const [submit] = useMutation<DeleteNote, DeleteNoteVariables>(DELETE_NOTE);

  const onDeleteConfirmation = async () => {
    const { data } = await submit({ variables: { projectNoteId: id } });

    if (data?.deleteNoteOnProject.__typename === 'ProjectNote') {
      success({ title: 'The note was deleted' });

      if (deleteProjectNote) {
        deleteProjectNote(data.deleteNoteOnProject);
      }
    } else {
      warning({ title: 'Could not delete the note' });
    }
  };

  const deleteNote = () =>
    dispatch({
      type: 'delete_note',
      onConfirm: onDeleteConfirmation,
    });

  return (
    <div className="note" data-id={id}>
      <div className="note-text" dangerouslySetInnerHTML={{ __html: `${text}` }} />
      <div className={`note-details ${styles.projectNoteFooter}`}>
        <h6>{authorName}</h6>
        <span className="timestamp ml-2">{moment(createdAt).format('MMM Do YYYY, h:mma')}</span>
        {isAdmin && <E1Link onClick={deleteNote}>Delete</E1Link>}
      </div>
    </div>
  );
};

type ProjectNotesProps = {
  projectNotes: Note[];
  deleteProjectNote?: (response: Pick<Note, 'id'>) => void;
  noteContentTransformer?: (text: string) => string;
};

const ProjectNotes = ({
  projectNotes,
  deleteProjectNote,
  noteContentTransformer = (s) => s,
}: ProjectNotesProps) => (
  <div>
    <DeleteProjectNoteProvider>
      {projectNotes.length === 0 ? (
        <span style={{ fontStyle: 'italic' }}>No notes against this project yet.</span>
      ) : (
        projectNotes
          .sort(
            ({ createdAt: createdAtA }, { createdAt: createdAtB }) =>
              parseInt(createdAtB) - parseInt(createdAtA),
          )
          .map(({ id, text, authorName, createdAt }) => (
            <ProjectNote
              id={id}
              key={id}
              text={noteContentTransformer(text)}
              authorName={authorName}
              createdAt={createdAt}
              deleteProjectNote={deleteProjectNote}
            />
          ))
      )}
    </DeleteProjectNoteProvider>
  </div>
);

export default ProjectNotes;
