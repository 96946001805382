import { ReactElement } from 'react';
import { joinClassNames } from '@estimateone/frontend-components';
import styles from './styles.scss';

export type LettingStatusTimelineItemProps = {
  bullet: ReactElement;
  heading: ReactElement;
  description: ReactElement;
} & Partial<HTMLDivElement>;

export const LettingStatusTimelineItem = ({
  bullet,
  heading,
  description,
  ...props
}: LettingStatusTimelineItemProps) => (
  <div className={joinClassNames(styles.item, props.className)}>
    <div className={styles.timelineRender}>
      <div className={styles.bullet}>{bullet}</div>
      <div className={styles.line} />
    </div>
    <div className={styles.timelineContent}>
      {heading}
      {description}
    </div>
  </div>
);
