import { useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { showUniversalProfileDrawer } from '@builder/pages/Profiles/UniversalProfileDrawer';
import useFlashMessage from '../../../shared/Util/useFlashMessage';
import { Action, Event, useAnalytics } from '@ascension/components/hooks/Analytics';
import { GET_CONNECTION_SUGGESTIONS } from './queries';
import { IGNORE_CONNECTION_SUGGESTION, CONFIRM_CONNECTION_SUGGESTION } from './mutations';
import {
  ConfirmConnectionSuggestion,
  ConfirmConnectionSuggestionVariables,
} from './types/ConfirmConnectionSuggestion';
import {
  GetConnectionSuggestions,
  GetConnectionSuggestions_connectionSuggestions as ConnectionSuggestion,
} from './types/GetConnectionSuggestions';
import {
  IgnoreConnectionSuggestion,
  IgnoreConnectionSuggestionVariables,
} from './types/IgnoreConnectionSuggestion';

export const useGetConnectionSuggestions = () => {
  const { data, error, loading } = useQuery<GetConnectionSuggestions>(GET_CONNECTION_SUGGESTIONS);
  const { addEvent } = useAnalytics(Event.INTERACT);

  useEffect(() => {
    if (!loading && !error) {
      addEvent({
        action: Action.SUBBIE_MATCHING_PROFILES_FOUND_BULK,
        matchesFound: data?.connectionSuggestions.length,
      });
    }
  }, [loading, error]);

  if (loading) {
    return {
      suggestions: null,
      loading,
    };
  }

  if (error) {
    return {
      error: new Error('Failed to load connection suggestions'),
      loading: false,
    };
  }

  return {
    suggestions: data?.connectionSuggestions,
    loading,
  };
};

enum ActionType {
  Ignore = 'ignore',
  Confirm = 'confirm',
}

export type ConnectionSuggestionMutationResult =
  | IgnoreConnectionSuggestion
  | ConfirmConnectionSuggestion;
export type ConnectionSuggestionMutationVariables =
  | IgnoreConnectionSuggestionVariables
  | ConfirmConnectionSuggestionVariables;

const getMutationBasedOnAction = (actionType: ActionType) => {
  if (actionType === ActionType.Ignore) {
    return IGNORE_CONNECTION_SUGGESTION;
  }
  return CONFIRM_CONNECTION_SUGGESTION;
};

const getMutationResult = (data: ConnectionSuggestionMutationResult) => {
  if ('ignoreConnectionSuggestion' in data) {
    return data.ignoreConnectionSuggestion;
  }
  return data.confirmConnectionSuggestion;
};

const useHandleIgnoreOrConfirmConnectionSuggestion = (actionType: ActionType) => {
  const { success: showSuccess, warning: showError } = useFlashMessage();
  const { addEvent } = useAnalytics(Event.INTERACT);
  const mutation = getMutationBasedOnAction(actionType);

  const [ignoreOrConfirmConnectionSuggestion] = useMutation<
    ConnectionSuggestionMutationResult,
    ConnectionSuggestionMutationVariables
  >(mutation);

  const handleIgnoreOrConfirmConnectionSuggestion = async ({
    profile,
    addressBookCompany,
    snippets,
  }: ConnectionSuggestion) => {
    const result = await ignoreOrConfirmConnectionSuggestion({
      variables: {
        input: {
          accountProfileId: profile.id,
          addressBookCompanyId: addressBookCompany.id.toString(10),
          snippets: snippets.map(({ type, value }) => ({
            type,
            value,
          })),
        },
      },
    });

    if (result.data) {
      const suggestionOrErrors = getMutationResult(result.data);

      if (suggestionOrErrors.__typename === 'ConnectionSuggestion') {
        addEvent({
          action:
            actionType === ActionType.Ignore
              ? Action.SUBBIE_MATCHING_PROFILE_IGNORED
              : Action.SUBBIE_MATCHING_PROFILE_ACCEPTED,
          searchedCompanyId: addressBookCompany.id.toString(),
          matchedProfileId: profile.id.toString(),
        });
        showSuccess({
          title: 'Success',
          message: `You have ${actionType === ActionType.Ignore ? 'ignored the Connection suggestion for' : 'connected with'} ${addressBookCompany.name}.`,
        });

        if (actionType === ActionType.Confirm) {
          showUniversalProfileDrawer({ companyId: addressBookCompany.id, profileId: profile.id });
        }
      } else {
        showError({
          title: 'Error',
          message: 'Something went wrong. Please try again.',
        });
      }
      return;
    }

    showError({
      title: 'Error',
      message: 'Something went wrong. Please try again.',
    });
  };

  return handleIgnoreOrConfirmConnectionSuggestion;
};

export const useIgnoreConnection = () =>
  useHandleIgnoreOrConfirmConnectionSuggestion(ActionType.Ignore);

export const useConfirmConnection = () =>
  useHandleIgnoreOrConfirmConnectionSuggestion(ActionType.Confirm);
