import { useQuery } from '@apollo/client';
import { useHasSpeciFinderFeature } from '@specifinder/hooks/useHasSpeciFinderFeature';
import { KEYWORDS_MATCHING_PROJECT_STAGES } from '@specifinder/queries';
import { EntityId } from '@ascension/types';
import {
  KeywordsMatchingProjectStages,
  KeywordsMatchingProjectStagesVariables,
} from '@specifinder/types/KeywordsMatchingProjectStages';

export const useKeywordsForStageMap = (projectId: EntityId): Map<EntityId, string[]> => {
  const hasKeywordsFeature = useHasSpeciFinderFeature();

  const { data: stagesData } = useQuery<
    KeywordsMatchingProjectStages,
    KeywordsMatchingProjectStagesVariables
  >(KEYWORDS_MATCHING_PROJECT_STAGES, {
    variables: { projectId },
    fetchPolicy: 'no-cache',
    skip: !hasKeywordsFeature,
  });

  return (stagesData?.keywordsMatchingProjectStages || []).reduce(
    (acc, { keywords, stageId }) => acc.set(stageId, keywords),
    new Map<EntityId, string[]>(),
  );
};
