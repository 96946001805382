import { useState } from 'react';
import { DocumentIntegrationConnectButton } from '@builder/features/DocSyncIntegration/DocumentIntegrationConnectButton';
import { DocumentIntegrationConnectSlider } from '../DocumentIntegrationConnectSlider';
import {
  useDocumentIntegrationContext,
  useHasDocumentIntegration,
} from '../DocumentIntegrationProvider';
import { DocumentIntegrationSyncProgress } from '../DocumentIntegrationSyncProgress';
import { DocumentIntegrationSyncStatus } from '../DocumentIntegrationSyncStatus';
import { useGetDocSyncInfectedFiles } from '../ProcoreConnect/hooks/useGetDocSyncInfectedFiles';
import { useGetDocSyncStage } from '../hooks/useGetDocSyncStage';
import { useGetDocsyncLastSyncedAt } from '../hooks/useGetDocsyncLastSyncedAt';
import { useShowSyncProgress } from '../hooks/useShowSyncProgress';
import { EntityId } from '@ascension/types';

type DocSyncControllerProps = {
  stageId: EntityId;
  selectedDocSyncSlider: string | null;
};

export const DocSyncController = ({ stageId, selectedDocSyncSlider }: DocSyncControllerProps) => {
  const hasDocumentIntegration = useHasDocumentIntegration();
  const [shouldCheckProgress, setShouldCheckProgress] = useState(hasDocumentIntegration);
  const { stageType } = useDocumentIntegrationContext();
  const { progress, startPolling, isProgressModalOpen } = useShowSyncProgress(
    stageId,
    shouldCheckProgress,
    stageType,
  );

  const shouldSkipQueries = !hasDocumentIntegration;

  const {
    data: { stage, documentIntegration },
  } = useGetDocSyncStage(stageId, shouldSkipQueries);
  const {
    docsyncMap,
    startPolling: startPollingMap,
    stopPolling: stopPollingMap,
  } = useGetDocsyncLastSyncedAt(stageId, shouldSkipQueries);
  const { infectedFiles } = useGetDocSyncInfectedFiles(stageId, shouldSkipQueries);

  const onConfirmConnection = () => {
    setShouldCheckProgress(true);
    startPolling();
  };

  return (
    <>
      {!hasDocumentIntegration ? <DocumentIntegrationConnectButton /> : null}
      {hasDocumentIntegration && stage && docsyncMap && documentIntegration ? (
        <DocumentIntegrationSyncStatus
          fileCount={stage.activeDocumentCount}
          lastSynced={docsyncMap.lastSyncedAt}
          stageId={stageId}
          startCheckLastSynced={startPollingMap}
          stopCheckLastSynced={stopPollingMap}
          integrationType={documentIntegration?.type}
          infectedFiles={infectedFiles}
        />
      ) : null}
      {hasDocumentIntegration || selectedDocSyncSlider ? (
        <DocumentIntegrationConnectSlider
          stageId={stageId}
          selectedDocSyncSlider={selectedDocSyncSlider}
          onConfirmConnection={onConfirmConnection}
        />
      ) : null}
      {progress ? (
        <DocumentIntegrationSyncProgress
          taskCount={progress.taskCount}
          tasksCompleted={progress.tasksCompleted}
          isProgressModalOpen={isProgressModalOpen}
        />
      ) : null}
    </>
  );
};
