import { FontStyle, Heading } from '@ascension/components/fec';

export const LitePlanHeader = ({
  allowedCredits,
  usedCredits,
}: {
  allowedCredits: number;
  usedCredits: number;
}) => (
  <Heading level={1} renderAs={FontStyle.h2}>
    Unlock tender {usedCredits + 1} of {allowedCredits}
  </Heading>
);
