import $ from 'jquery';
import { captureException, setExtra } from '@sentry/browser';
import PendingUploadManager, {
  ArchiveUpload,
  CommitStatus,
  FmErr,
} from './file_manager/PendingUploadManager';

const archiveErrorToHumanReadable = (err: string | null, infectionData: string | null) => {
  if (infectionData !== null) {
    return `virus detected - ${infectionData}`;
  }

  switch (err) {
    case 'NO_VALID_FILES':
      return 'it does not contain any valid files';
    case 'INVALID_CENTRAL_DIR':
      return 'it does not appear to be a valid zip file';
    default:
      return 'unknown error';
  }
};

export default class DocumentUploadStatus {
  private readonly $progressSection: JQuery;
  private readonly $filesStatusSection: JQuery;
  private readonly $archiveStatusSection: JQuery;
  private readonly $archiveStatusList: JQuery;
  private readonly $completedSection: JQuery;
  private readonly $uploadStatus: JQuery;
  private readonly $errorTable: JQuery;
  private readonly $errorTableBody: JQuery;
  private readonly $dismissBtn: JQuery;
  private uiUpdateLock: number | null;
  private logErrorStartingIndex: number;

  constructor(
    private $ctn: JQuery,
    private uploadManager: PendingUploadManager,
  ) {
    this.$ctn = $ctn;
    this.$progressSection = this.$ctn.find('.progress-section');

    this.$filesStatusSection = this.$ctn.find('.files-status-section');
    this.$archiveStatusSection = this.$ctn.find('.archive-status-section');
    this.$archiveStatusList = this.$ctn.find('.archive-status-list');

    this.$completedSection = this.$ctn.find('.completed-section');
    this.$uploadStatus = this.$completedSection.find('.upload-summary').clone();
    this.$errorTable = this.$completedSection.find('.error-table').clone();
    this.$errorTableBody = this.$errorTable.find('tbody');
    this.$dismissBtn = this.$ctn.find('button.close').clone();

    this.uiUpdateLock = null;

    this.logErrorStartingIndex = 0;

    this.$completedSection.on('click', '.error-ctn-trigger', ({ currentTarget }): void => {
      const $link = $(currentTarget);
      if (this.$errorTable.hasClass('hide')) {
        this.$errorTable.removeClass('hide');
        $link.text('Hide errors');
      } else {
        this.$errorTable.addClass('hide');
        $link.text('View errors');
      }
    });

    this.$archiveStatusSection.on('click', 'button.close', () =>
      this.$archiveStatusSection.addClass('hide'),
    );

    this.$filesStatusSection.on('click', 'button.close', () =>
      this.$filesStatusSection.addClass('hide'),
    );

    this.$completedSection.on('click', 'button.close', () => this.$errorTable.addClass('hide'));
  }

  private static getHumanReadableTime(estimatedTime: number): string {
    const seconds = Math.round(estimatedTime / 1000);

    if (seconds <= 50) return '< 1min';
    if (seconds <= 65) return '~ 1min';

    const minutes = Math.floor(seconds / 60);

    if (minutes <= 120) {
      const remainingSeconds = seconds - minutes * 60;
      return `~${minutes}min ${remainingSeconds}secs`;
    }

    const hours = Math.floor(minutes / 60);

    return `~ ${hours}hours`;
  }

  private refreshDisplay(): void {
    const percent = this.uploadManager.getPercentCompleted();
    const errors: FmErr[] = this.uploadManager.getErrors();

    this.updateProgressCountDiv(
      this.uploadManager.getCompletedCount(),
      this.uploadManager.getAddedCount(),
    );

    this.updateFilesStatus();
    this.updateArchiveStatus();
    this.updateProgressPercentageDiv(percent);
    this.updateEstimatedTimeDiv(percent);
    this.updateErrorCountDiv(errors.length);
    this.logErrors(errors);
  }

  public reset(): void {
    this.clearErrorTable();
    this.$archiveStatusSection.addClass('hide');
    this.$filesStatusSection.addClass('hide');
    this.$completedSection.addClass('hide');

    this.refreshDisplay();

    this.uiUpdateLock = window.setTimeout(() => {
      this.clearUiUpdateLock();
    }, 200);
    this.$progressSection.removeClass('hide');
    this.logErrorStartingIndex = 0;
  }

  public update(): void {
    if (this.uiUpdateLock !== null) {
      return;
    }

    this.uiUpdateLock = window.setTimeout(() => {
      this.clearUiUpdateLock();
      this.refreshDisplay();
    }, 500);
  }

  public complete(): void {
    this.$progressSection.addClass('hide');
    this.updateArchiveStatus();
    this.updateFilesStatus();
    this.updateCompletedStatusDiv();
    this.$completedSection.removeClass('hide');
  }

  public clearUiUpdateLock(): void {
    if (this.uiUpdateLock) {
      window.clearTimeout(this.uiUpdateLock);
    }
    this.uiUpdateLock = null;
  }

  private updateProgressCountDiv(committedCount: number, addedCount: number): void {
    const $progressCountSection = this.$progressSection.find('.progress-count');
    const $counts = $progressCountSection.find('.counts');
    $counts.find('.committed').text(committedCount);
    $counts.find('.added').text(addedCount);
  }

  private updateProgressPercentageDiv(percent: number | null): void {
    const roundedPercent = percent === null ? 0 : Math.round(percent * 100);

    const $progressPercentSection = this.$progressSection.find('.progress-percentage');
    const $percentBarLabel = $progressPercentSection.find('.progress-bar-value');
    const margin = Math.max(roundedPercent - 3, 0);
    $percentBarLabel.css('margin-left', `${margin}%`).text(`${roundedPercent}%`);

    const $percentBarWidget = $progressPercentSection.find('.progress-bar');
    $percentBarWidget.attr('aria-valuenow', roundedPercent).css('width', `${roundedPercent}%`);
  }

  private updateEstimatedTimeDiv(percent: number | null): void {
    const estimatedTime = this.uploadManager.getEstimatedTimeRemaining();
    const text =
      percent === null || estimatedTime === null
        ? 'Pending'
        : DocumentUploadStatus.getHumanReadableTime(estimatedTime);

    const $progressTimeSection = this.$progressSection.find('.progress-time');
    const $timeWidget = $progressTimeSection.find('.time');
    $timeWidget.text(text);
  }

  private updateErrorCountDiv(errorCount: number): void {
    const $errorCountSection = this.$progressSection.find('.progress-errors');
    $errorCountSection.find('.error').text(errorCount);
  }

  private logErrors(errors: { id: string; name: string; errorMessage?: string }[]): void {
    if (errors.length) {
      const filteredErrors = errors.slice(this.logErrorStartingIndex);

      if (filteredErrors.length) {
        setExtra('uploadErrors', filteredErrors);
        captureException(new Error('Failed to upload files'));
      }

      // Need to track which errors have already been sent to Sentry because it keeps adding more errors to the same array.
      this.logErrorStartingIndex = errors.length;
    }
  }

  private clearErrorTable(): void {
    this.$errorTable.addClass('hide');
    this.$errorTableBody.empty();
  }

  private updateFilesStatus(): void {
    // Only applies to SFM
    const pendingFileCount = this.uploadManager.getPendingNonArchiveFiles().length;
    // Correspondence attachments have a draft status and are not 'complete' until the user submits the form
    // because the form contains the data required to populate the corr entity
    const completedFileCount =
      this.uploadManager.getCompletedNonArchiveFiles().length +
      this.uploadManager.getDrafts().length;

    const totalFileCount = pendingFileCount + completedFileCount;

    if (totalFileCount === 0) return;

    this.$filesStatusSection
      .find('.files-progress')
      .text(`${completedFileCount}/${totalFileCount}`);

    this.$filesStatusSection.removeClass('hide');
  }

  private updateArchiveStatus(): void {
    const pending: ArchiveUpload[] = this.uploadManager.getPendingArchives();
    const completed: ArchiveUpload[] = this.uploadManager.getCompletedArchives();

    const totalArchiveCount = pending.length + completed.length;
    const hasArchives = totalArchiveCount !== 0;

    if (!hasArchives) return;

    const createListItem =
      (name: string) =>
      (message: string): JQuery =>
        $('<li>').append(
          $('<span class="name">').text(name),
          $('<span class="message">').text(message),
        );

    const toKiloBytesStr = (size: number) => `${Math.round(size / 1000)}kb`;
    const fileCount = (count: number) => `${count} ${count === 1 ? 'file' : 'files'}`;

    const failedStatuses = [CommitStatus.ERROR, CommitStatus.INFECTED];

    const pendingValid = pending.filter(
      ({ commit_status: commitStatus }) => !failedStatuses.includes(commitStatus),
    );
    const failed = pending.filter(({ commit_status: commitStatus }) =>
      failedStatuses.includes(commitStatus),
    );

    const $pendingArchives = pendingValid.map(
      ({ name, size, count_archive_entries: count, archive_entries: archiveEntries }) =>
        createListItem(name)(
          (count || 0) !== 0
            ? `Extracted ${archiveEntries.filter((ae) => ae.done).length} out of ${fileCount(
                count || 0,
              )} from archive (${toKiloBytesStr(size)})`
            : `Preparing to extract archive (${toKiloBytesStr(size)})`,
        ),
    );

    const $failedArchives = failed.map(({ name, error, infection_data: infectionData }) =>
      createListItem(name)(
        `Could not extract archive - ${archiveErrorToHumanReadable(error, infectionData)}`,
      ).addClass('archive-failed'),
    );

    const $completedArchives = completed.map(({ name, size, count_archive_entries: count }) =>
      createListItem(name)(
        `Finished extracting ${fileCount(count || 0)} (${toKiloBytesStr(size)})`,
      ),
    );

    this.$archiveStatusList
      .empty()
      .append(...$pendingArchives)
      .append(...$failedArchives)
      .append(...$completedArchives);

    this.$archiveStatusSection
      .find('.archive-progress')
      .text(`${completed.length}/${totalArchiveCount}`);

    this.$archiveStatusSection.removeClass('hide');
  }

  private updateCompletedStatusDiv(): void {
    const hasErrors = this.uploadManager.getErrors().length !== 0;
    this.$completedSection.empty();
    this.$uploadStatus.empty();
    this.$errorTable.addClass('hide');
    const $summary = $('<span>');

    if (hasErrors) {
      this.$uploadStatus.removeClass('alert-success').addClass('alert-danger');

      const $docCount = $('<strong>').text(
        `${this.uploadManager.getCompletedCount()}/${this.uploadManager.getTotalFilesCount()}`,
      );

      const $docCountSpan = $('<span>').append($docCount, ' documents uploaded successfully');

      const $viewErrorLink = $('<a>')
        .text('View errors')
        // eslint-disable-next-line no-script-url
        .attr('role', 'button')
        .addClass('link primary error-ctn-trigger');

      $summary.append($docCountSpan).append(' ').append($viewErrorLink);

      this.updateErrorTable();
    } else {
      this.clearErrorTable();
      this.$uploadStatus.removeClass('alert-danger').addClass('alert-success');

      const completedText =
        this.uploadManager.getCompletedCount() > 1
          ? `All <strong>${this.uploadManager.getCompletedCount()}</strong> documents uploaded successfully`
          : 'The document was uploaded successfully';

      $summary.append($('<i>').addClass('icon icon-ok'), $('<span>').append(completedText));
    }
    this.$uploadStatus.append(this.$dismissBtn[0].outerHTML).append($summary);

    this.$completedSection.append(this.$uploadStatus).append(this.$errorTable);
  }

  private updateErrorTable(): void {
    const $errorRows = this.uploadManager
      .getErrors()
      .map(({ name, errorMessage }: { name: string; errorMessage?: string }) => ({
        name,
        errorMessage: errorMessage || 'This file could not be saved',
      }))
      .map(({ name, errorMessage }: { name: string; errorMessage?: string }) =>
        $('<tr>').append(
          $('<td>').text(name),
          $('<td class="message">').text(errorMessage || 'Unknown error'),
        ),
      );

    this.$errorTableBody.empty().append(...$errorRows);
  }
}
