import { gql } from '@apollo/client';

const SUGGEST_CONSULTANT_CORRECTION = gql`
  mutation SuggestConsultantCorrection($input: SuggestConsultantCorrectionInput!) {
    suggestConsultantCorrection(input: $input)
  }
`;

const SUGGEST_PROJECT_CORRECTION = gql`
  mutation SuggestProjectCorrection($input: SuggestConsultantCorrectionInput!) {
    suggestProjectCorrection(input: $input)
  }
`;

export { SUGGEST_PROJECT_CORRECTION, SUGGEST_CONSULTANT_CORRECTION };
