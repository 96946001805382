import { gql } from '@apollo/client';
import { ColumnStatusItem } from './fragments';

export const GET_LETTING_COLUMN_STATUS = gql`
  query GetLettingColumnStatus($stageId: EntityId!) {
    userLettingColumnStatus(stageId: $stageId) {
      ...ColumnStatusItem
    }
  }
  ${ColumnStatusItem}
`;
