/* eslint-disable react/no-unstable-nested-components */
import { useCallback, useRef, useState } from 'react';
import {
  Checkbox,
  CheckboxStatus,
  E1Link,
  Icon,
  IconName,
  SkeletonLoader,
  Table,
  TableColumn,
  Toggle,
  joinClassNames,
  CellProps,
  TableInstance,
} from '@estimateone/frontend-components';
import { LettingScheduleStatus } from '@builder/features/ProcurementLettingSchedule/LettingScheduleStatus';
import { LettingScheduleStatusLabel } from '@builder/features/ProcurementLettingSchedule/LettingScheduleStatusLabel';
import { NotifyDownloadedPackage, NotifyMessageFieldName, NotifyPreferences } from './NotifyForm';
import { TextAreaField, TextInputField } from '@shared/FinalForm/Field';
import { useNotifyTableHeight } from '../RevisionFlow/hooks/useNotifyTableHeight';
import {
  GetDownloadedPackages_downloadedPackages as DownloadedPackage,
  GetDownloadedPackages,
} from '../RevisionFlow/types/GetDownloadedPackages';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

const isEmpty = (value: unknown) => typeof value !== 'string' || value.trim().length === 0;

type NotifyFormContentProps = {
  isLoadingDownloadedPackages: boolean;
  filteredPackages: DownloadedPackage[];
  notificationCount: number;
  notifyPreferences: NotifyPreferences;
  hasAttemptedSubmission: boolean;
  touched: Record<string, boolean> | undefined;
  setTitle: (value: string) => void;
  message: string;
  setMessage: (value: string) => void;
  setNotifyPreferences: (preferences: NotifyPreferences) => void;
  downloadedPackages: GetDownloadedPackages | undefined;
  selectedPackages: EntityId[];
  setSelectedPackages: (id: EntityId[]) => void;
  stageSettingNotifyAwarded: boolean | undefined;
  totalInvites: number | undefined;
};
export const NotifyFormContent = ({
  isLoadingDownloadedPackages,
  filteredPackages,
  notificationCount,
  notifyPreferences,
  hasAttemptedSubmission,
  touched,
  setTitle,
  message,
  setMessage,
  setNotifyPreferences,
  downloadedPackages,
  selectedPackages,
  setSelectedPackages,
  stageSettingNotifyAwarded,
  totalInvites,
}: NotifyFormContentProps) => {
  const tableHeight = useNotifyTableHeight();
  const [showNotDownloadedColumns, setShowNotDownloadedColumns] = useState(false);
  const tableRef = useRef<TableInstance<NotifyDownloadedPackage>>(null);

  const hideableColumns = ['notDownloadedResponded', 'notDownloadedNoResponse'];
  const toggleShowNotDownloadedColumns = () => {
    const willShowColumns = !showNotDownloadedColumns;
    if (!willShowColumns) {
      setNotifyPreferences({
        ...notifyPreferences,
        notifyNotDownloadedNoResponse: false,
        notifyNotDownloadedResponded: false,
      });
    }

    hideableColumns.forEach((colId) => {
      const tableInstance = tableRef.current;
      if (tableInstance) {
        tableInstance.toggleHideColumn(colId);
      }
    });

    setShowNotDownloadedColumns(willShowColumns);
  };

  const toggleAllPackages = useCallback(
    (selected: boolean) =>
      selected && downloadedPackages
        ? setSelectedPackages(filteredPackages.map((dp) => dp.id))
        : setSelectedPackages([]),
    [downloadedPackages, filteredPackages, setSelectedPackages],
  );

  const togglePackageSelection = useCallback(
    (id: EntityId) => {
      if (selectedPackages.includes(id)) {
        setSelectedPackages(selectedPackages.filter((pkgId) => pkgId !== id));
      } else {
        setSelectedPackages([...selectedPackages, id]);
      }
    },
    [selectedPackages, setSelectedPackages],
  );

  const columns: TableColumn<NotifyDownloadedPackage>[] = [
    {
      id: 'masterCheckbox',
      accessor: 'id',
      Header: () => {
        const checkboxStatus =
          selectedPackages.length === filteredPackages.length
            ? CheckboxStatus.Checked
            : selectedPackages.length === 0
              ? CheckboxStatus.Unchecked
              : CheckboxStatus.Indeterminate;

        return (
          <Checkbox
            className={styles.notifyTableHeaderCheckbox}
            id="select-package-all"
            label="Select All Packages for Notification"
            hideLabel
            statusCurrent={checkboxStatus}
            onClick={() =>
              toggleAllPackages(!(selectedPackages.length === filteredPackages.length))
            }
          />
        );
      },
      Cell: ({
        row: {
          original: { id },
        },
      }: CellProps<NotifyDownloadedPackage>) => (
        <Checkbox
          id={`select-package-${id}`}
          label="Select Package for Notification"
          hideLabel
          statusCurrent={
            selectedPackages.includes(id) ? CheckboxStatus.Checked : CheckboxStatus.Unchecked
          }
          onClick={() => togglePackageSelection(id)}
        />
      ),
      disableSortBy: true,
      fitToContent: true,
    },
    {
      id: 'title',
      accessor: 'title',
      // eslint-disable-next-line react/no-unstable-nested-components
      Header: () => <>Packages affected ({filteredPackages.length ?? 0})</>,
      Cell: ({ row: { original } }: CellProps<NotifyDownloadedPackage>) => (
        <div className={styles.notifyTablePackageTitleContainer}>
          <div>{original.title}</div>
          {original.awardedAt && stageSettingNotifyAwarded ? (
            <LettingScheduleStatusLabel status={LettingScheduleStatus.Awarded} isActive />
          ) : (
            false
          )}
        </div>
      ),
      minWidth: 429,
    },
    {
      id: 'downloadedNotified',
      accessor: 'numToBeNotified',
      Header: () =>
        showNotDownloadedColumns ? (
          <div className={styles.notifyTableHeaderCheckboxColumn}>
            <div className={styles.notifyTableHeaderCheckboxColumnText}>
              <div>Have downloaded the Package</div>
            </div>
            <Checkbox
              className={styles.notifyTableHeaderCheckboxColumnCheck}
              id="select-package-downloaded"
              label="Have downloaded the Package"
              hideLabel
              statusCurrent={
                notifyPreferences.notifyDownloaded
                  ? CheckboxStatus.Checked
                  : CheckboxStatus.Unchecked
              }
              onClick={() =>
                setNotifyPreferences({
                  ...notifyPreferences,
                  notifyDownloaded: !notifyPreferences.notifyDownloaded,
                })
              }
            />
          </div>
        ) : (
          <div
            className={joinClassNames(
              styles.notifyTableHeaderCheckboxColumn,
              styles.downloadedTitleCentered,
            )}
          >
            <div className={styles.notifyTableHeaderCheckboxColumnText}>
              <div>Have downloaded the Package</div>
            </div>
          </div>
        ),
      Cell: ({
        row: {
          original: { id, numToBeNotified },
        },
      }: CellProps<NotifyDownloadedPackage>) => (
        <div
          className={
            !selectedPackages.includes(id) || !notifyPreferences.notifyDownloaded
              ? styles.notifyStepDisabledData
              : undefined
          }
        >
          {numToBeNotified}
        </div>
      ),
      disableSortBy: true,
    },
    {
      id: 'notDownloadedResponded',
      accessor: 'rfqNotDownloadedResponded',
      Header: () => (
        <div className={styles.notifyTableHeaderCheckboxColumn}>
          <div className={styles.notifyTableHeaderCheckboxColumnText}>
            <div>Have NOT downloaded the Package</div>
            <div className={styles.notifyTableHeaderSubText}>
              <div>And response is:</div>
              <div>Quoted, Quoting</div>
            </div>
          </div>
          <Checkbox
            className={styles.notifyTableHeaderCheckboxColumnCheck}
            id="select-package-not-downloaded-quoting"
            label="Have not downloaded the package but responded"
            hideLabel
            statusCurrent={
              notifyPreferences.notifyNotDownloadedResponded
                ? CheckboxStatus.Checked
                : CheckboxStatus.Unchecked
            }
            onClick={() =>
              setNotifyPreferences({
                ...notifyPreferences,
                notifyNotDownloadedResponded: !notifyPreferences.notifyNotDownloadedResponded,
              })
            }
          />
        </div>
      ),
      Cell: ({
        row: {
          original: { id, rfqNotDownloadedResponded, awardedAt },
        },
      }: CellProps<NotifyDownloadedPackage>) => (
        <div
          className={
            !selectedPackages.includes(id) || !notifyPreferences.notifyNotDownloadedResponded
              ? styles.notifyStepDisabledData
              : undefined
          }
        >
          {awardedAt && stageSettingNotifyAwarded ? '-' : rfqNotDownloadedResponded}
        </div>
      ),
      disableSortBy: true,
    },
    {
      id: 'notDownloadedNoResponse',
      accessor: 'rfqNotDownloadedNoResponse',
      Header: () => (
        <div className={styles.notifyTableHeaderCheckboxColumn}>
          <div className={styles.notifyTableHeaderCheckboxColumnText}>
            <div>Have NOT downloaded the Package</div>
            <div className={styles.notifyTableHeaderSubText}>
              <div>And response is:</div>
              <div>No Response</div>
            </div>
          </div>
          <Checkbox
            className={styles.notifyTableHeaderCheckboxColumnCheck}
            id="select-package-not-downloaded-noresponse"
            label="Have not downloaded the package and no response"
            hideLabel
            statusCurrent={
              notifyPreferences.notifyNotDownloadedNoResponse
                ? CheckboxStatus.Checked
                : CheckboxStatus.Unchecked
            }
            onClick={() =>
              setNotifyPreferences({
                ...notifyPreferences,
                notifyNotDownloadedNoResponse: !notifyPreferences.notifyNotDownloadedNoResponse,
              })
            }
          />
        </div>
      ),
      Cell: ({
        row: {
          original: { id, rfqNotDownloadedNoResponse, awardedAt },
        },
      }: CellProps<NotifyDownloadedPackage>) => (
        <div
          className={
            !selectedPackages.includes(id) || !notifyPreferences.notifyNotDownloadedNoResponse
              ? styles.notifyStepDisabledData
              : undefined
          }
        >
          {awardedAt && stageSettingNotifyAwarded ? '-' : rfqNotDownloadedNoResponse}
        </div>
      ),
      disableSortBy: true,
    },
  ];

  const showMessageErrorStyles =
    isEmpty(message) && (touched?.[NotifyMessageFieldName.Message] || hasAttemptedSubmission);

  const showMessageErrorText =
    isEmpty(message) && !touched?.[NotifyMessageFieldName.Message] && hasAttemptedSubmission;

  return (
    <div className={styles.notifyStepContainer}>
      <div className={styles.notifyStepTableContainer}>
        <h3 className={styles.notifyStepTableHeading}>Select contacts to notify</h3>
        <div className={styles.notifyStepTableHeaderContainer}>
          <div className={styles.notifyStepTableHeadingToggleContainer}>
            <Toggle
              id="notify-form-toggle-show-not-downloaded"
              label="See who has not downloaded the package"
              checked={showNotDownloadedColumns}
              onChange={toggleShowNotDownloadedColumns}
            />
            <label
              className={styles.notifyToggleLabel}
              htmlFor="notify-form-toggle-show-not-downloaded"
            >
              See who has not downloaded the package
            </label>
          </div>
          <div className={styles.notifyStepTableHeadingHelp}>
            <Icon name={IconName.Help} />
            <E1Link
              type="button"
              className="help-trigger"
              data-help-template="addendum_revision_notify"
            >{`Who won't be notified?`}</E1Link>
          </div>
        </div>
        <div>
          {isLoadingDownloadedPackages ? (
            <SkeletonLoader width="100%" height={tableHeight} />
          ) : (
            <Table<NotifyDownloadedPackage>
              columns={columns}
              data={totalInvites && totalInvites > 0 ? filteredPackages : []}
              initSortColumnId="title"
              maxHeight={tableHeight}
              initHiddenColumns={showNotDownloadedColumns ? [] : hideableColumns}
              tableRef={tableRef}
            />
          )}
          {!filteredPackages.length ? (
            <div className={styles.notifyStepNoPackagesEmptyStateText}>
              No changes were made to packages, so no contacts can be selected
            </div>
          ) : (
            false
          )}
          {filteredPackages.length > 0 && totalInvites === 0 ? (
            <div className={styles.notifyStepNoPackagesEmptyStateText}>
              No invites have been sent for the affected packages, so no contacts can be selected
            </div>
          ) : (
            false
          )}
        </div>
      </div>
      <div className={styles.notifyStepMessageContainer}>
        <h3 className={styles.notifyStepPreviewHeading}>Add message</h3>
        {notificationCount ? (
          <>
            <TextInputField
              className={styles.notifyStepEmailSubject}
              fieldName={NotifyMessageFieldName.Title}
              id="notify-step-form--title"
              label="Subject"
              isRequired
              onChange={(event) => setTitle(event.currentTarget.value)}
              validate={(subject) =>
                isEmpty(subject) ? ['Add a subject for the email'] : undefined
              }
            />
            <div
              className={joinClassNames(
                styles.notifyStepEmailMessageContainer,
                styles.notifyStepEmailMessage,
                showMessageErrorStyles ? styles.notifyStepEmailMessageError : '',
              )}
            >
              <TextAreaField
                fieldName={NotifyMessageFieldName.Message}
                id="notify-step-form--message"
                label="Message"
                isRequired
                rows={15}
                onBlur={(event) => setMessage(event.currentTarget.value)}
                placeholder="Description of changes."
                validate={(value) => {
                  const messageErrors = ['Add a message describing the changes to the package'];
                  return isEmpty(value) ? messageErrors : undefined;
                }}
              />
              {showMessageErrorText ? (
                <div className={styles.notifyStepEmailMessageErrorText}>
                  Add a message describing the changes to the package
                </div>
              ) : (
                false
              )}
            </div>
          </>
        ) : (
          <div className={styles.notifyStepEmptyStateText}>No contacts selected to notify</div>
        )}
      </div>
    </div>
  );
};
