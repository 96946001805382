import { gql } from '@apollo/client';

export const EDIT_SUBBIE_NETWORK_OPT_IN_SETTING = gql`
  mutation EditSubbieDirectoryOptInSetting($isOptedIn: Boolean!) {
    editSubbieDirectoryOptInSetting(isOptedIn: $isOptedIn)
  }
`;

export const MARK_NETWORK_RE_ENROLMENT_MODAL_AS_VIEWED = gql`
  mutation MarkNetworkReEnrolModalAsViewed {
    markNetworkReEnrolModalAsViewed
  }
`;
