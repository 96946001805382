import { gql } from '@apollo/client';
import Errors from '../../../api/fragments/Errors';

const WatchlistItemCreator = gql`
  fragment WatchlistItemCreator on User {
    id
    fullName
    account {
      id
    }
  }
`;

const WatchlistItemUpdater = gql`
  fragment WatchlistItemUpdater on User {
    id
    fullName
    account {
      id
    }
  }
`;

const WatchlistAssignedBy = gql`
  fragment WatchlistAssignedBy on User {
    id
    fullName
    account {
      id
    }
  }
`;

const WatchlistAssignee = gql`
  fragment WatchlistAssignee on User {
    id
    fullName
    account {
      id
    }
  }
`;

export const WatchlistProjectAssignment = gql`
  fragment WatchlistProjectAssignment on AccountWatchlistProjectAssignment {
    id
    account {
      id
    }
    project {
      id
      name
    }
    accountWatchlistProject {
      id
    }
    assignedBy {
      ...WatchlistAssignedBy
    }
    assignee {
      ...WatchlistAssignee
    }
    createdAt
    previousAssignee {
      id
    }
  }
  ${WatchlistAssignedBy}
  ${WatchlistAssignee}
`;

export const WatchedProject = gql`
  fragment WatchedProject on NoticeboardProject {
    ... on WatchableProject {
      watchlistEntry {
        id
        status
        createdAt
        createdBy {
          ...WatchlistItemCreator
        }
        updatedAt
        updatedBy {
          ...WatchlistItemUpdater
        }
      }
    }
  }
  ${WatchlistItemCreator}
  ${WatchlistItemUpdater}
`;

export const WatchlistProjectAssignmentOrErrors = gql`
  fragment WatchlistProjectAssignmentOrErrors on AccountWatchlistProjectAssignmentOrErrors {
    ... on AccountWatchlistProjectAssignment {
      ...WatchlistProjectAssignment
    }
    ... on Errors {
      errors {
        ...ApiError
      }
    }
  }
  ${WatchlistProjectAssignment}
  ${Errors.fragments.error}
`;
