import { FiltersIllustration } from '@estimateone/frontend-components';
import styles from './styles.module.scss';

const NoTradeSelected = () => (
  <div className={styles.noTradesSelected}>
    <FiltersIllustration className={styles.illustration} />
    <p className={styles.text}>Select a Trade to start seeing results</p>
  </div>
);

export default NoTradeSelected;
