import { renderToStaticMarkup } from 'react-dom/server';
import $ from 'jquery';
import Routing from 'routing';
import moment from 'moment-timezone';
import _ from 'underscore';
import E1Request from '../classes/E1Request';
import { getInitials } from '../../components/subbie/utils/myteam-utils';
import Budget from '../classes/Budget';
import Modal from '../classes/Modal';
import ServerDataList from '../classes/ServerDataList';
import { getUserId } from '../utils/helpers';
import { truncate } from '../utils/truncate';
import { getUserTimezone } from './timezone';
import { addProjectToWatchList } from './watchlist';
import RfqResponse from '../../components/shared/RfqResponse';
import ProjectKeywordList from '../../components/subbie/features/NoticeboardFeature/ResultsTable/ProjectKeywordList';
import UserInitialsIcon from '../../components/shared/UserInitialsIcon';
import { WATCHLIST_UPDATE_EVENT } from '../../components/subbie/context/WatchlistProvider';
import { Country, QuoteReturnRoute } from '../../enums';
import {
  sendSpeciFinderInvitationAnalytics,
  sendSpeciFinderInvitationSliderButtonAnalytics,
} from './utils/sf_invitations_analytics';
import { getTranslation, LangRef } from '../../components/shared/Util/lang';
import { getAppcues, FlowEvent } from '../../components/helpers/Appcues';
import { getDisabledTooltipText } from '../../components/leads/features/LeadsManagement/LeadsContent/ActionCell/utils';

const isCurrentUser = (currentUserId, obj) => {
  const { user, contact } = obj;
  const userId = user ? user.id : contact.user.id;
  return userId === currentUserId;
};

function setInvitationResponseSelectorStatus($dropdown, rfq) {
  let status = '';
  let statusLabel = '';
  const notQuotingReasonId = rfq.notQuotingReasonId;
  const dataNotQuotingReason = 'data-not-quoting-reason';

  if (rfq.quotedAt) {
    status = 'quoted';
    statusLabel = $dropdown.data('declined-status-label');

    // Hide "Quoted" option from subbies. They shouldn't be able to update
    // an invitation to "Quoted". That option is reserved for builders only.
    $dropdown
      .find(`.modify-invitation-interest-level[data-interest-level-status=${status}]`)
      .hide();
  } else if (rfq.quotingAt) {
    status = 'quoting';
  } else if (rfq.notQuotingAt) {
    status = 'declined';
  } else {
    status = 'considering';
    statusLabel = $dropdown.data('interest-levels-default-status');
    $dropdown.addClass('invitation-unactioned');
  }

  if (statusLabel === '') {
    statusLabel = $dropdown
      .find(`.modify-invitation-interest-level[data-interest-level-status=${status}]`)
      .data('interest-level-label');
  }

  if (notQuotingReasonId) {
    $dropdown
      .find(`.modify-invitation-interest-level[${dataNotQuotingReason}=${notQuotingReasonId}]`)
      .addClass('active');
    $dropdown.attr(dataNotQuotingReason, notQuotingReasonId);
  } else {
    $dropdown.removeAttr(dataNotQuotingReason);
  }

  $dropdown
    .attr('data-status', status)
    .find('.invitation-interest-levels-status')
    .text(statusLabel);
}
function SubcontractorInvitationsTable($parentContainer) {
  const self = this;
  this.parentContainer = $parentContainer;
  const $target = $parentContainer.find('table.invitations-table');
  this.$target = $target;
  this.$container = $target;
  this.$userSelector = $parentContainer.find('.team-member-selector');
  this.$responseStatusSelector = $parentContainer.find('.stage-response-filter');
  this.route = $target.attr('data-url');
  this.minDate = $target.attr('data-min-date') ? $target.attr('data-min-date') : null;
  this.maxDate = $target.attr('data-max-date') ? $target.attr('data-max-date') : null;
  this.badge = $target.attr('data-badge');
  this.userIds = [];
  this.table = null;
  this.localStorageKey = `${$target.data('table-name')}_${getUserId()}`;
  this.storage = window.localStorage;
  this.showSpeciFinder = $target.attr('data-show-speciFinder');
  self.init();
}

const constructShortAddress = (address) => {
  if (!address) return '';

  if ([Country.COUNTRY_UK, Country.COUNTRY_IE].includes(address?.country.id)) {
    const { city, province } = address;

    return [city, province].filter(Boolean).join(', ');
  }

  const { suburb, state } = address;
  if (suburb && state?.shortName) {
    return `${suburb}, ${state.shortName}`;
  }
  return suburb ?? '';
};

SubcontractorInvitationsTable.prototype = {
  init() {
    const self = this;
    const budgetMap = new Budget();
    const defaultSorting = self.minDate ? 'asc' : 'desc';
    self.initMultiSelects();
    self.route = Routing.generate('app_invitation_fetch', self.generateParams());

    self.table = new ServerDataList(self.$target, self.route, (list) => {
      const $placeholderRow = list.$target.find('tr.data-table-placeholder').first();

      list.table = list.$target
        .DataTable(
          $.extend({}, list.getTableDefaults(), {
            pageLength: 25,
            processing: true,
            serverSide: true,
            ajax(data, cb, settings) {
              list.serverRequest(data, cb, settings);
            },
            order: [[3, defaultSorting]],
            columns: [
              {
                data: 'stage.projectName',
                name: 'stage.projectName',
                class: 'show-always',
                render(data, row, obj) {
                  const $container = $('<div>');
                  const $link = $('<a>');
                  $link.addClass('link view-rfq-slider').text(data).attr('data-rfq-id', obj.id);

                  const currentUserId = parseInt(getUserId(), 10);
                  const currentUser = isCurrentUser(currentUserId, obj);

                  $container.append($link);
                  if (data.private && !currentUser) {
                    $container.append(
                      $('<span>').text(' (Private Project)').addClass('private-project'),
                    );
                  }

                  const shortAddress = constructShortAddress(obj.stage.project.address);
                  const projectDetails = `#${obj.stage.project.id} - ${_.escape(shortAddress)}`;

                  return $container.prop('innerHTML') + '<br/>' + projectDetails;
                },
              },
              {
                data: 'SF',
                name: 'SF',
                orderable: false,
                class: 'show-always fit-to-content',
                render(data, row, obj) {
                  if (!self.showSpeciFinder) {
                    return null;
                  }
                  const keywords = obj.stage.keywords;

                  if (!keywords?.length) return renderToStaticMarkup(<span />);

                  const tooltipContent = renderToStaticMarkup(
                    <div className="sf-tooltip">
                      <ProjectKeywordList keywords={keywords} maxCount={6} />
                    </div>,
                  );

                  return renderToStaticMarkup(
                    <div
                      className="tooltip-trigger"
                      title={tooltipContent}
                      data-tooltip-content-class
                    >
                      <div className="specifinder-icon"></div>
                    </div>,
                  );
                },
              },
              {
                data: 'builder.name',
                name: 'builder.name',
                class: 'inline-stages',
                render(data, row, obj) {
                  const { account } = obj.stage;
                  const $link = $('<a>');
                  $link
                    .addClass('link builder-modal-trigger')
                    .text(account.name)
                    .attr('data-builder-id', account.id);
                  return $link.prop('outerHTML');
                },
              },
              {
                name: 'contact.name',
                orderable: false,
                class: 'show-always',
                render(data, row, obj) {
                  const first = obj.user !== null ? obj.user.firstName : obj.contact.user.firstName;
                  const last = obj.user !== null ? obj.user.lastName : obj.contact.user.lastName;
                  const initials = getInitials(first, last);

                  const currentUserId = parseInt(getUserId(), 10);
                  const currentUser = isCurrentUser(currentUserId, obj);

                  return renderToStaticMarkup(
                    <UserInitialsIcon initials={initials} isCurrentUser={currentUser} />,
                  );
                },
              },
              {
                data: 'stage.budgetAmount',
                name: 'stage.budgetAmount',
                type: 'num',
                render(data, display) {
                  switch (display) {
                    case 'filter':
                    case 'sort':
                      return data;
                    default:
                      return budgetMap.getBudgetName(data);
                  }
                },
              },
              {
                data: 'package.title',
                name: 'package.title',
                render: $.fn.dataTable.render.text(),
              },
              {
                data: 'rfq.quoteDueAt',
                name: 'rfq.quoteDueAt',
                render(data, display, obj) {
                  const $span = $('<span>');
                  $span.addClass('timestamp');

                  if (obj.quoteDueAt !== null) {
                    const downloadedAt = moment(obj.quoteDueAt.date);
                    $span.text(downloadedAt.format('DD MMM YYYY'));
                  } else {
                    $span.text('-');
                  }

                  return $span.prop('outerHTML');
                },
              },
              {
                name: 'responseStatus',
                orderable: false,
                class: 'show-always',
                render(data, display, obj, details) {
                  if (display === 'display') {
                    const currentUserId = parseInt(getUserId(), 10);
                    const currentUser = isCurrentUser(currentUserId, obj);

                    if (obj.stage.private && !currentUser) {
                      const isQuoting =
                        obj.quotingAt || obj.quotedAt ? true : obj.notQuotingAt ? false : null;

                      return renderToStaticMarkup(<RfqResponse isQuoting={isQuoting} />);
                    }
                    const $template = $(list.$placeholderRow.find('td').get(details.col)).clone();
                    const $menu = $template.children().first();

                    setInvitationResponseSelectorStatus($menu, obj);

                    return $menu.attr('data-rfq-id', obj.id).prop('outerHTML');
                  }

                  return '';
                },
              },
              {
                data: 'downloadedLastAt',
                name: 'downloadedLastAt',
                orderable: false,
                render(data, display, obj, details) {
                  const $template = $($placeholderRow.find('td').get(details.col)).clone();
                  const downloadedAt = data ? moment(data.date) : null;
                  let $span = null;

                  if (downloadedAt) {
                    const packageDownloadedAt = moment(obj.package.contentLastAddedAt.date);
                    if (
                      packageDownloadedAt === null ||
                      packageDownloadedAt.isBefore(downloadedAt)
                    ) {
                      $span = $template.find('.current');
                    } else {
                      $span = $template.find('.obsolete');
                    }
                  } else {
                    $span = $template.find('.not-downloaded');
                  }

                  $span.addClass('tag doc_status');
                  $span.attr('id', `rfq_doc_status_${obj.id}`);

                  return $span.prop('outerHTML');
                },
              },
              {
                name: 'actions',
                orderable: false,
                class: 'show-always fit-to-content',
                render(data, display, obj, details) {
                  const $template = $($placeholderRow.find('td').get(details.col)).clone();
                  const currentUserId = parseInt(getUserId(), 10);
                  const currentUser = isCurrentUser(currentUserId, obj);
                  const builderTerm = getTranslation(LangRef.BUILDER);

                  if (obj.stage.private && !currentUser) {
                    const user = obj.user !== null ? obj.user : obj.contact.user;
                    $template
                      .find('.btn-default')
                      .attr('disabled', true)
                      .prop(
                        'title',
                        getDisabledTooltipText(
                          `${user.firstName} ${user.lastName}`,
                          user.email,
                          builderTerm,
                        ),
                      );

                    return $template.prop('outerHTML');
                  }

                  const $dropdown = $template.find('.rfq-actions');
                  const $downloadBtn = $template.find('.rfq-download-btn');
                  const $docViewerBtn = $template.find('.rfq-document-viewer-trigger');

                  $downloadBtn.attr('data-rfq-id', obj.id);
                  $docViewerBtn.attr('data-rfq-id', obj.id).attr('data-stage-id', obj.stage.id);

                  $dropdown.find('.view-rfq-slider').attr('data-rfq-id', obj.id);
                  $dropdown.find('.rfq-download-link-trigger').attr('data-rfq-id', obj.id);

                  const $viewQuoteButton = $dropdown.find('.view-quote-trigger');
                  const $addQuoteButton = $dropdown.find('.add-quote-trigger');

                  if (obj.quoteId) {
                    $addQuoteButton.hide();
                    $viewQuoteButton.attr(
                      'href',
                      Routing.generate('app_quote_view', {
                        id: obj.quoteId,
                        returnTo: QuoteReturnRoute.SubbieInvites,
                      }),
                    );
                  } else {
                    $viewQuoteButton.hide();
                    $addQuoteButton.attr(
                      'href',
                      Routing.generate('app_quote_addforrfq', {
                        rfqId: obj.id,
                        returnTo: QuoteReturnRoute.SubbieInvites,
                      }),
                    );
                  }

                  if (!currentUser) {
                    $downloadBtn
                      .removeClass('rfq-download-link-trigger')
                      .addClass('rfq-confirm-download-link-trigger');
                    $dropdown
                      .find('.rfq-download-link-trigger')
                      .removeClass('rfq-download-link-trigger')
                      .addClass('rfq-confirm-download-link-trigger');

                    $docViewerBtn
                      .removeClass('rfq-document-viewer-trigger')
                      .addClass('rfq-confirm-document-viewer-trigger');
                  }

                  const downloadedAt = obj.downloadedLastAt
                    ? moment(obj.downloadedLastAt.date)
                    : null;

                  if (downloadedAt) {
                    const packageContentLastAddedAt = moment(obj.package.contentLastAddedAt.date);
                    if (
                      packageContentLastAddedAt === null ||
                      packageContentLastAddedAt.isBefore(downloadedAt)
                    ) {
                      $dropdown.find('.rfq-revision-link-trigger').remove();
                    } else {
                      $dropdown
                        .find('.rfq-revision-link-trigger')
                        .attr('data-rfq-id', obj.id)
                        .attr('data-last-at', downloadedAt.unix());

                      $downloadBtn
                        .attr('data-relative', true)
                        .attr('data-last-at', downloadedAt.unix());
                    }
                  } else {
                    $dropdown.find('.rfq-revision-link-trigger').remove();
                  }
                  return $template.prop('outerHTML');
                },
              },
            ],
          }),
        )
        .on('init.dt', () => {
          list.$container.addClass('has-loaded');
        })
        .on('xhr', (e, settings, { data: rfqs }) => {
          if (self.showSpeciFinder) {
            const hiddenHasKeywordMatches = $('#hiddenHasKeywordMatches').empty();
            rfqs.forEach((rfq) => {
              const rfqId = rfq.id;
              const hasKeywords = rfq.stage.keywords?.length ?? false;

              if (hasKeywords) {
                hiddenHasKeywordMatches.append(
                  $('<input type="hidden" readonly>')
                    .attr('id', `hasKeywordMatches-rfq-id-${rfqId}`)
                    .val(true),
                );
              }
            });
          }
        })
        .on('draw', () => {
          self.updateCount();
          self.initDropUpFields();
        });
    });
    self.$target.on('click', '.view-rfq-slider', async (viewSliderEvent) => {
      const id = $(viewSliderEvent.currentTarget).data('rfq-id');
      sendSpeciFinderInvitationAnalytics(id, 'SF_InviteAndResponsesSliderOpen');
      return new E1Request(Routing.generate('app_rfq_viewslider', { id })).submit();
    });
    self.$responseStatusSelector.on('change', () => {
      self.reloadTable();
    });
    $(document).on('update-invites-table', () => {
      self.reloadTable();
    });
  },
  loadTeamMembersFromStorage() {
    if (this.storage) {
      const storedCache = this.storage.getItem(this.localStorageKey);
      if (typeof storedCache === 'string' && storedCache.length !== 0) {
        return JSON.parse(storedCache);
      }
    }
    return null;
  },
  storeTeamMembers(teamMembers) {
    if (this.storage) {
      this.storage.setItem(this.localStorageKey, JSON.stringify(teamMembers));
    }
  },
  initDropUpFields() {
    const tableRows = this.table.table.rows().nodes().to$();
    if (tableRows && tableRows.length > 0) {
      tableRows.slice(-3).each((i, row) => {
        $(row).find('.interest-levels-dropdown').addClass('dropup').removeClass('dropdown');
      });
    }
  },
  initMultiSelects() {
    // we really should init the filter box as its own object that interacts with the datatable. but
    // this is just a POC
    const self = this;
    const teamMembers = self.loadTeamMembersFromStorage();
    if (Array.isArray(teamMembers)) {
      self.$userSelector.val(teamMembers);
    }

    const $select = self.$userSelector.multiselect({
      includeSelectAllOption: true,
      onChange(option, checked) {
        if (
          (option && option.val() === undefined && checked === false) ||
          self.$userSelector.find('option:selected').length === 0
        ) {
          this.select(getUserId(), true);
        }

        if (option === undefined) {
          if (checked) {
            this.selectAll(false, true);
          } else {
            this.deselectAll(false, true);
          }
          this.updateButtonText();
        }
      },
    });
    $select.multiselect('updateButtonText');

    $select.on(
      'change',
      _.debounce(() => {
        self.reloadTable();
      }, 1000),
    );
  },
  updateCount() {
    const self = this;
    let total = 0;
    if (self.table.table.page) {
      const info = self.table.table.page.info();
      total = info.recordsTotal;
    }
    $(`.${self.badge}`).text(total);
    if (self.badge === 'active-request-count') {
      const $invitationCounter = $('span.invitationsCounter');
      const unactionedRows = self.table.table
        .data()
        .filter((rfq) => rfq.quotingAt === null && rfq.quotedAt === null);

      $invitationCounter
        .text(total)
        .attr('data-unactioned-invitations-count', unactionedRows.length);
    }
  },
  generateParams() {
    const self = this;
    const params = {};

    if (self.minDate) {
      params.minDate = self.minDate;
    }

    if (self.maxDate) {
      params.maxDate = self.maxDate;
    }

    params.userIds = self.$userSelector.val();
    params.filter = self.$responseStatusSelector.val();

    return params;
  },
  reloadTable() {
    const self = this;
    const params = self.generateParams();
    self.storeTeamMembers(params.userIds);
    self.route = Routing.generate('app_invitation_fetch', params);
    self.table.fetchUrl = self.route;
    self.table.updateTable(true, false);
  },
};

function SubcontractorRequestsTable($target) {
  const self = this;
  this.$target = $target;
  this.route = $target.attr('data-url');
  this.badge = $target.attr('data-badge');
  this.hideDocStatus = $target.attr('data-hide-doc-status') === 1;
  this.table = null;
  self.init();
}

SubcontractorRequestsTable.prototype = {
  init() {
    const self = this;
    self.table = new ServerDataList(self.$target, self.route, (list) => {
      const $obsoleteMessage = $('.obsolete-documentation');
      const $placeholderRow = list.$target.find('tr.data-table-placeholder').first();

      // eslint-disable-next-line no-param-reassign
      list.table = list.$target
        .DataTable({
          pageLength: 25,
          processing: true,
          serverSide: true,
          ajax(data, cb, settings) {
            list.serverRequest(data, cb, settings);
          },
          info: false,
          order: [[4, 'desc']],
          columns: [
            {
              data: null,
              orderable: false,
              render(data, display, row, details) {
                const $template = $($placeholderRow.find('td').get(details.col)).clone();
                $template.find('.docs-icon').attr('data-project-id', row.project_id);
                const $container = $('<div>');
                $container.attr('data-project-id', row.project_id);
                $container.addClass('note-icon-container project-modal-trigger');
                $container.attr('data-target', 'notes');

                if (parseInt(data.has_notes, 10) === 1) {
                  $container.addClass('tender_note');
                }
                $template.append($container);

                return $template.html();
              },
            },
            {
              data: 'project_name',
              name: 'project.name',
              render(data, display, row, details) {
                const name = data;
                const $template = $($placeholderRow.find('td').get(details.col)).clone();
                const $link = $template.find('a').first();
                $link.attr('data-project-id', row.project_id);
                $link.text(name);
                return $link.prop('outerHTML');
              },
            },
            {
              data: 'package_title',
              name: 'package.title',
              type: 'num',
              render: $.fn.dataTable.render.text(),
            },
            {
              data: null,
              render(data, display, row, details) {
                const template = $placeholderRow.find('td').get(details.col);

                const isStageIncognito = data.account_id === null || data.account_name === null;

                if (isStageIncognito) {
                  const hiddenBuilderName = getTranslation(LangRef.INCOGNITO_BUILDER_HEADING);
                  return `<div>${hiddenBuilderName}</div>`;
                }

                const $link = $(template)
                  .find('a')
                  .attr('data-builder-id', data.account_id)
                  .text(data.account_name);

                return $link.prop('outerHTML');
              },
            },
            {
              data: 'rfq_requested_at',
              name: 'rfq.requestedAt',
              render(data) {
                const $timestamp = $('<span>').addClass('timestamp');

                if (data !== null) {
                  const downloadedAt = moment(data.date);
                  $timestamp.text(downloadedAt.format('DD MMM YY'));
                } else {
                  $timestamp.text('-');
                }

                return $timestamp.prop('outerHTML');
              },
            },
            {
              data: 'rfq_approved_at',
              name: 'rfq.approvedStatus',
              orderable: false,
              width: 85,
              render(data, display, row, details) {
                const $template = $($placeholderRow.find('td').get(details.col)).clone();
                let $span = null;
                if (data !== null) {
                  $span = $template.find('.approved');
                } else if (row.rfq_declined_at != null) {
                  $span = $template.find('.declined');
                  if ($obsoleteMessage.length > 0) {
                    $obsoleteMessage.removeClass('hide');
                  }
                } else {
                  $span = $template.find('.pending');
                }

                return $span.prop('outerHTML');
              },
            },
            {
              data: 'rfq_downloaded_at',
              name: 'rfq.downloadedAt',
              orderable: false,
              width: 85,
              visible: !self.hideDocStatus,
              render(data, display, row, details) {
                const $template = $($placeholderRow.find('td').get(details.col)).clone();
                const downloadedAt = data ? moment(data.date) : null;
                let $span = null;
                if (row.rfq_declined_at != null) {
                  $span = $template.find('.not-accessible');
                  return $span.prop('outerHTML');
                }

                if (downloadedAt) {
                  const packageUpdatedAt = moment(row.package_updated_at.date);
                  if (packageUpdatedAt == null || packageUpdatedAt.isBefore(downloadedAt)) {
                    $span = $template.find('.current');
                  } else {
                    $span = $template.find('.obsolete');
                  }
                } else {
                  $span = $template.find('.not-downloaded');
                }

                return $span.prop('outerHTML');
              },
            },
            {
              name: 'actions',
              orderable: false,
              width: 100,
              render(data, display, row, details) {
                const $template = $($placeholderRow.find('td').get(details.col)).clone();
                const $dropdown = $template.find('.rfq-actions');
                const $downloadBtn = $template.find('.rfq-download-btn');
                const $docViewerBtn = $template.find('.rfq-document-viewer-trigger');

                const showActions = row.rfq_approved_at !== null;

                if (!showActions) {
                  return '';
                }

                const rfqId = row.rfq_id;

                $downloadBtn.attr('data-rfq-id', rfqId);
                $docViewerBtn.attr('data-rfq-id', rfqId).attr('data-stage-id', row.stage_id);

                $dropdown
                  .find('.view-rfq-slider')
                  .attr('data-project-id', row.project_id)
                  .addClass('project-modal-trigger')
                  .removeClass('view-rfq-slider');

                $dropdown.find('.rfq-download-link-trigger').attr('data-rfq-id', rfqId);

                const downloadedAt = row.rfq_downloaded_at
                  ? moment(row.rfq_downloaded_at.date)
                  : null;

                if (downloadedAt) {
                  const packageUpdatedAt = moment(row.package_updated_at.date);
                  if (packageUpdatedAt == null || packageUpdatedAt.isBefore(downloadedAt)) {
                    $dropdown.find('.rfq-revision-link-trigger').remove();
                  } else {
                    $dropdown
                      .find('.rfq-revision-link-trigger')
                      .attr('data-rfq-id', rfqId)
                      .attr('data-last-at', downloadedAt.unix());

                    $downloadBtn
                      .attr('data-relative', true)
                      .attr('data-last-at', downloadedAt.unix());
                  }
                } else {
                  $dropdown.find('.rfq-revision-link-trigger').remove();
                }
                return $template.prop('outerHTML');
              },
            },
          ],
        })
        .on('init.dt', () => {
          list.$container.addClass('has-loaded');
        })
        .on('draw', () => {
          self.updateCount();
        });
    });
  },
  updateCount() {
    const self = this;
    let total = 0;
    if (self.table.table.page) {
      const info = self.table.table.page.info();
      total = info.recordsTotal;
    }
    $(`.${self.badge}`).text(total);
  },
};

$(() => {
  $('body').on('click', '.update-invitations-btn', (e) => {
    const responses = [];
    const $modal = $(e.currentTarget).closest('.respond-to-invitations-modal');
    const $dropdowns = $modal.find('.interest-levels-dropdown.invitation-dropdown');

    $dropdowns.each((i, dropdown) => {
      const $dropdown = $(dropdown);
      responses.push({
        rfq_id: $dropdown.data('rfq-id'),
        rfq_status: $dropdown.data('status'),
      });
    });

    const url = Routing.generate('app_invitation_changemultiplestatuses');
    const req = new E1Request(url, 'POST', { responses });

    req.submit();
  });

  $('body').on('click', '.modify-invitation-interest-level', async (onModifyInterest) => {
    const statusDataAttr = 'data-status';
    const dataNotQuotingReason = 'data-not-quoting-reason';
    const rfqDataAttr = 'data-rfq-id';
    const interestLevelStatus = '.invitation-interest-levels-status';
    const invitationDropdownClass = '.interest-levels-dropdown.invitation-dropdown';

    const $trigger = $(onModifyInterest.currentTarget);
    const $dropdown = $trigger.closest(invitationDropdownClass);
    const appcues = getAppcues();
    const rfqId = $dropdown.attr(rfqDataAttr);
    const notQuotingReasonSelected = $trigger.data('not-quoting-reason');
    const rfqStatus = notQuotingReasonSelected
      ? 'declined'
      : $trigger.data('interest-level-status');

    // Don't submit request if new status and current status are the same
    if (
      $dropdown.attr(statusDataAttr) === rfqStatus &&
      $dropdown.attr(dataNotQuotingReason)?.toString() === notQuotingReasonSelected?.toString()
    ) {
      return;
    }

    const updateInvitationDropdownStatus = () => {
      // There can be more than one of the same dropdown on the page at once, so
      // here we're updating all of them. For example, when we have the project slider
      // or project modal open, we want to update the dropdown inside those windows
      // but also the corresponding dropdown in the datatable in the background.
      $(`${invitationDropdownClass}[${rfqDataAttr}="${rfqId}"]`)
        .toArray()
        .forEach((elem) => {
          let statusLabel = '';
          if (rfqStatus === 'considering') {
            statusLabel = $dropdown.data('interest-levels-default-status');
            $dropdown.addClass('invitation-unactioned');
            $(elem).removeAttr(dataNotQuotingReason);
          } else {
            statusLabel = $trigger.data('interest-level-label');
            $dropdown.removeClass('invitation-unactioned');
            $(elem).attr(dataNotQuotingReason, $trigger.data('not-quoting-reason'));
          }

          $(elem).attr(statusDataAttr, rfqStatus).find(interestLevelStatus).text(statusLabel);

          $(elem)
            .find('.modify-invitation-interest-level')
            .toArray()
            .forEach((linkElem) => {
              const nqReason = $(linkElem).data('not-quoting-reason');
              const intStatusLevel = $(linkElem).data('interest-level-status');
              $(linkElem).toggleClass(
                'active',
                intStatusLevel === rfqStatus && nqReason === notQuotingReasonSelected,
              );
            });
        });

      sendSpeciFinderInvitationAnalytics(rfqId, 'SF_InvitationUpdatedRFQResponse');

      $(document)
        .find('.rfq-slider .super-slider-sub-bar .not-quoting-reason-message #not-quoting-reason')
        .text($trigger.data('not-quoting-reason-label') || '');

      $(`.not-quoting-reason-message[data-rfq-id=${rfqId}]`).toggleClass(
        'd-none',
        !notQuotingReasonSelected,
      );

      $(document).trigger('update-invites-table', rfqStatus);
    };

    // dispatch an event that can be listened for from react/vanilla js
    const event = new CustomEvent('rfq-update-interest-level', {
      detail: {
        rfqId,
        rfqStatus,
      },
    });
    document.dispatchEvent(event);

    // If auto submit is enabled, update the dropdown status without an AJAX request
    if ($dropdown.attr('data-disable-auto-submit') === 'true') {
      updateInvitationDropdownStatus();
      return;
    }

    try {
      const statusChangeResponse = await new E1Request(
        Routing.generate('app_invitation_changestatus', { id: rfqId }),
        'POST',
        {
          response: rfqStatus,
          notQuotingReason: notQuotingReasonSelected,
        },
      ).submit();
      if (
        statusChangeResponse.success &&
        appcues?.track &&
        !$dropdown.parents('.rfq-slider').length
      ) {
        appcues.track(FlowEvent.DISCOVER_RFQ_STATUS_UPDATE, {
          rfqId,
          status: rfqStatus,
          notQuotingReasonId: notQuotingReasonSelected,
          url: window.location.pathname,
        });
      }
      updateInvitationDropdownStatus();
      if (statusChangeResponse.updated_log) {
        $('div.super-slider-content .user-activity-log').replaceWith(
          statusChangeResponse.updated_log,
        );
      }
    } catch (err) {
      err.message = 'Request to change quoting status failed';
      throw err;
    }
  });

  const $invitationContainer = $('.invites-requests-container.invitations');
  const $requestsContainer = $('.invites-requests-container.requests');
  const $correspondenceContainer = $('.invites-requests-container.correspondence');
  const $recentlyAwardedContainer = $('.recently-awarded-projects-banner div');

  if ($invitationContainer.length) {
    $('.invitations-wrapper').each((i, element) => {
      // eslint-disable-next-line no-new
      new SubcontractorInvitationsTable($(element));
    });

    if ($invitationContainer.attr('data-rfq-id')) {
      const rfqId = $invitationContainer.attr('data-rfq-id');
      const requestNew = Routing.generate('app_rfq_viewslider', { id: rfqId });
      const req = new E1Request(requestNew);
      req.show_loading_modal = true;
      req.loadingModal = 'slider';
      req.submit();
    }
  }

  const handleWatchlistEvent = async (e) => {
    const $button = $(e.currentTarget);
    const projectId = $button.data('project-id');
    const status = $button.data('status');
    const source = $button.data('source');

    await addProjectToWatchList(projectId, status, source, false);

    const event = new CustomEvent(WATCHLIST_UPDATE_EVENT, {
      detail: {
        projectId,
        status,
      },
    });

    document.dispatchEvent(event);
    Modal.closeAll();
  };

  $('body')
    .on('click', '.add-project-to-watchlist-btn', handleWatchlistEvent)
    .on('click', '.update-watchlist-btn', handleWatchlistEvent);

  if ($requestsContainer.length > 0) {
    $('table.request-status-table').each((index, element) => {
      const $table = $(element);
      // eslint-disable-next-line
      new SubcontractorRequestsTable($table);
    });
  }
  if ($correspondenceContainer.length > 0) {
    const method = Routing.generate('app_rfqcorrespondence_fetch');
    const $target = $correspondenceContainer.find('.subcontractor-correspondence-table');

    const $projectFilter = $('select#correspondence_table_project_filter');
    const projectIdColIndex = 5;

    const correspondenceTable = new ServerDataList($target, method, (list) => {
      const $placeholderRow = list.$target.find('tr.data-table-placeholder').first();

      // eslint-disable-next-line no-param-reassign
      list.table = list.$target
        .DataTable({
          pageLength: 25,
          processing: true,
          serverSide: true,
          ajax(data, cb, settings) {
            list.serverRequest(data, cb, settings);
          },
          searchCols: [null, null, null, null, null, { search: $projectFilter.val() }],
          order: [[3, 'desc']],
          columns: [
            {
              name: 'builder.name',
              data: 'rfq.stage.account',
              render(data) {
                if (data.id === null || data.name === null) {
                  return getTranslation(LangRef.INCOGNITO_BUILDER_HEADING);
                }
                return _.escape(truncate(data.name, 30));
              },
            },
            {
              name: 'project.name',
              data: null,
              render(data, display, obj) {
                let projectName;
                if (obj.rfq.stage.projectName) {
                  projectName = obj.rfq.stage.projectName;
                } else {
                  projectName = obj.rfq.stage.project.name;
                }
                return _.escape(truncate(projectName, 50));
              },
            },
            {
              name: 'correspondence.title',
              data: 'correspondence.title',
              render: $.fn.dataTable.render.text(),
            },
            {
              data: 'createdAt',
              name: 'correspondenceItem.createdAt',
              render(data, display, obj, details) {
                const template = $placeholderRow.find('td').get(details.col);
                const date = moment.utc(data.date);
                date.tz(getUserTimezone());
                $(template)
                  .find('.date')
                  .text(date.format($(template).find('.date').attr('data-format')));
                return $(template).html();
              },
            },
            {
              data: null,
              orderable: false,
              render(data, row, obj, details) {
                const $template = $($placeholderRow.find('td').get(details.col)).clone();
                const $link = $template.find('a');
                const route = Routing.generate('app_rfqcorrespondence_view', { id: obj.id });
                $link.attr('target', '_tab');
                $link.attr('href', route);
                return $link.prop('outerHTML');
              },
            },
            {
              name: 'project.id',
              data: 'rfq.stage.project.id',
              visible: false,
              render: $.fn.dataTable.render.text(),
            },
          ],
        })
        .on('init.dt', () => {
          list.$container.addClass('has-loaded');
        });
    });

    if ($projectFilter.length > 0) {
      $projectFilter.on('change', () => {
        correspondenceTable.table.column(projectIdColIndex).search($projectFilter.val()).draw();
      });
    }
  }

  if ($recentlyAwardedContainer.length > 0) {
    $recentlyAwardedContainer.on('click', () => {
      const url = Routing.generate('app_recommendation_recently_awarded');
      const request = new E1Request(url, 'GET', { trigger: 'banner' });
      request.show_loading_modal = true;
      request.submit();
    });
  }

  $('body').on('click', '.rfq-slider .view-keywords', () => {
    $('.rfq-slider .nav-tabs .tender-keywords').trigger('click');
    sendSpeciFinderInvitationSliderButtonAnalytics('view_keywords_link');
  });

  $('body').on('click', '.rfq-slider .tender-keywords', () => {
    sendSpeciFinderInvitationSliderButtonAnalytics('view_keywords_tab');
  });

  $('body').on('click', '.rfq-slider .refine-keywords', () => {
    sendSpeciFinderInvitationSliderButtonAnalytics('refine_keywords_link');
  });
});
