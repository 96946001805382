import { InfoIcon, Tooltip } from '@estimateone/frontend-components';
import CreditBalance from '@subbie/common/CreditBalance';
import { makePlural } from '@subbie/modal/ProjectSlider/ProjectSliderBody/ProjectSubbies/util';
import { SubbieConnectionCreditBalanceDetails } from '@subbie/common/CreditBalance/hooks';
import styles from '@subbie/modal/ProjectSlider/ProjectSliderBody/ProjectSubbies/MatchingTradesAndLockedCount/styles.scss';

type MatchingTradesAndLockedCountProps = {
  readonly subbieCount: number;
  readonly lockedSubbieCount: number;
  readonly isUnlocked: boolean;
  readonly creditBalanceSummary: SubbieConnectionCreditBalanceDetails;
};

export const MatchingTradesAndLockedCount = ({
  subbieCount,
  lockedSubbieCount,
  isUnlocked,
  creditBalanceSummary,
}: MatchingTradesAndLockedCountProps) => {
  const projectHasSubbiesWithMatchingTrades = subbieCount !== 0;

  const subbieText = `${subbieCount} ${makePlural(
    subbieCount,
    'subcontractor',
    'subcontractors',
  )} matching your trades ${makePlural(subbieCount, 'is', 'are')} interested in this project`;

  const lockedText =
    lockedSubbieCount === 0 ? (
      <p>
        All interested subcontractors have viewed your profile.
        <br />
        Add this project to your Watchlist and be notified when subcontractors indicate their
        interest.
      </p>
    ) : (
      <p>
        {`${lockedSubbieCount} ${makePlural(
          lockedSubbieCount,
          'subcontractor',
          'subcontractors',
        )} ${makePlural(
          lockedSubbieCount,
          'is',
          'are',
        )} locked. Use a credit to gain access to them.`}
      </p>
    );

  const creditExplanation = projectHasSubbiesWithMatchingTrades ? (
    lockedText
  ) : (
    <p>
      We&apos;re currently finding interested subcontractors that match your trades.
      <br />
      Add this project to your Watchlist and be notified when subcontractors indicate their
      interest.
    </p>
  );

  return (
    <>
      <div className={styles.container}>
        <div className={styles.containerRow}>
          <p className={styles.heading}>{subbieText}</p>
          <Tooltip
            tooltipId="subbie-count-tooltip"
            text="Subcontractors who have interacted with this project by responding to the builder or adding it to their Watchlist and marking it interested, quoting, or quoted."
          >
            <InfoIcon />
          </Tooltip>
        </div>
        {!isUnlocked && creditExplanation}
      </div>
      {!isUnlocked && <CreditBalance creditBalanceSummary={creditBalanceSummary} />}
    </>
  );
};
