import { useMemo, useState } from 'react';
import { joinClassNames } from '@estimateone/frontend-components';
import Moment from 'moment-timezone';
import { truncate } from '@ascension/js/utils/truncate';
import BudgetRange from '../../../../shared/BudgetRange';
import { getTranslation, LangRef } from '@shared/Util/lang';
import { getCurrencyInfo, StageStatus } from '@ascension/enums';
import { GetProjectSliderData_projectForSlider_project as Project } from '@subbie/modal/types/GetProjectSliderData';
import styles from './styles.scss';

type ViewableNoticeboardProject = Extract<Project, { __typename: 'ViewableNoticeboardProject' }>;

const MAX_DESCRIPTION_LEN = 300;

type StageDescriptionProps = {
  accountName: string;
  budgetAmount: number;
  countryId: number;
  description?: string;
  compact?: boolean;
};

const extractDescriptionTextContent = (el: HTMLElement): string =>
  Array.from(el.childNodes)
    .map((child) => {
      if (child instanceof HTMLOListElement || child instanceof HTMLUListElement) {
        return extractDescriptionTextContent(child);
      }

      return child.textContent;
    })
    .join(' ');

const StageDescription = ({
  accountName,
  budgetAmount,
  countryId,
  description,
  compact = false,
}: StageDescriptionProps) => {
  const descriptionTextContent = useMemo(() => {
    if (description) {
      const div = document.createElement('div');
      // eslint-disable-next-line fp/no-mutation
      div.innerHTML = description;

      return extractDescriptionTextContent(div);
    }

    return null;
  }, [description]);

  return (
    <li className={styles.stageDescription}>
      {description && descriptionTextContent ? (
        <>
          <strong>{accountName} says:</strong>
          {compact ? (
            <div className={styles.description}>
              {truncate(descriptionTextContent, MAX_DESCRIPTION_LEN)}
            </div>
          ) : (
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          )}
        </>
      ) : (
        <>
          <strong>{accountName} </strong>
          has not provided a description.
        </>
      )}
      <div className={styles.description}>
        Their approximate budget is{' '}
        <strong>
          <BudgetRange budget={budgetAmount} currency={getCurrencyInfo(countryId)} />
        </strong>
        .
      </div>
    </li>
  );
};

const ProjectSummary = ({
  project,
  uniqueTradeCount,
}: {
  project: ViewableNoticeboardProject;
  uniqueTradeCount: number;
}) => {
  const quotesDue = Moment.utc(project.tenderQuoteDueAt).format('Do MMM YYYY');
  const hasUniqueTrades = uniqueTradeCount > 0;
  const singularTrade = uniqueTradeCount === 1;

  const { countryId, tenderQuoteDueAt, maxTenderBudget } = project;

  return (
    <div>
      {hasUniqueTrades && (
        <p>
          We&apos;ve identified{' '}
          <strong>
            {uniqueTradeCount} trade
            {singularTrade ? '' : 's'}
          </strong>{' '}
          involved in the construction of this project.
        </p>
      )}

      {tenderQuoteDueAt !== null && (
        <p>
          Quotes are required to be submitted by <strong>{quotesDue}</strong>.
        </p>
      )}
      <p>
        The approximate overall budget for the construction is{' '}
        <strong>
          <BudgetRange budget={maxTenderBudget} currency={getCurrencyInfo(countryId)} />
        </strong>
        .<br />
        Please contact the tendering {getTranslation(LangRef.BUILDERS)} listed below for further
        information.
      </p>
    </div>
  );
};

type ProjectDescriptionProps = {
  project: ViewableNoticeboardProject;
  uniqueTradeCount: number;
  displayedStageLimit?: number;
};

const ProjectDescription = ({
  project,
  uniqueTradeCount,
  displayedStageLimit = 2,
}: ProjectDescriptionProps) => {
  const { countryId } = project;

  const [showAllStages, setShowAllStages] = useState(false);

  const handleSwitchShowAllStages = () => setShowAllStages(!showAllStages);

  const activeStages = project.stages.filter(
    (stage) => stage.status !== StageStatus.TENDER_WITHDRAWN,
  );

  const displayedStages = showAllStages ? activeStages : activeStages.slice(0, displayedStageLimit);

  const readMoreNeeded =
    activeStages.length > displayedStageLimit ||
    activeStages.some(({ description }) => description && description.length > MAX_DESCRIPTION_LEN);

  const showNBC = activeStages.some(({ isBuilderCode }) => isBuilderCode);

  return (
    <div
      id="project-details"
      className={joinClassNames(styles.projectSection, styles.projectDescription)}
    >
      <ProjectSummary project={project} uniqueTradeCount={uniqueTradeCount} />
      <ul className={styles.descriptionsList}>
        {displayedStages.map(({ id, description, builderDetails: details, budgetAmount }) => (
          <StageDescription
            key={id}
            accountName={
              details.__typename === 'BuilderDetails'
                ? details.name
                : getTranslation(LangRef.INCOGNITO_BUILDER_HEADING)
            }
            budgetAmount={budgetAmount}
            countryId={countryId}
            description={description ?? undefined}
            compact={!showAllStages}
          />
        ))}
      </ul>
      {readMoreNeeded && (
        <div className={`${styles.hideShowWrapper}`}>
          <a
            href="#project-details"
            className={`${styles.hideShow} link`}
            onClick={handleSwitchShowAllStages}
          >
            {showAllStages ? 'Read less ' : 'Read more '}
            <span
              className={`${styles['faded-chevron']} icon icon-xs icon-chevron-${
                showAllStages ? 'up' : 'down'
              }`}
            />
          </a>
        </div>
      )}
      {showNBC && (
        <div className={styles.nbcFlag}>
          <span className="tag nbc mr-3">NBC</span>
          <div className="small muted">
            The Code for the Tendering and Performance of Building Work 2016 applies to the building
            work associated with this project. By submitting an expression of interest in, or tender
            for, this project, you will become subject to the Code for the Tendering and Performance
            of Building Work 2016.{' '}
            <a
              href="https://www.abcc.gov.au/building-code/what-code"
              className="small link secondary"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn More
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectDescription;
