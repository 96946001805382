import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { showUniversalProfileDrawer } from '@builder/pages/Profiles/UniversalProfileDrawer';
import SubbieProfilesSlider from '../../../features/ProfilesFeature/SubbieProfilesSlider';
import PageLoadingSpinner from '../../../../shared/LoadingSpinner/PageLoadingSpinner';
import QuoteView, { QuoteViewPageProps } from '../../../../shared/Quotes/View';
import { CurrencyInfoContext } from '@shared';
import { GET_QUOTE_WITH_CONTACT_DETAILS } from '@shared/Quotes/View/queries';
import {
  GetQuoteAndVersionsAndContact,
  GetQuoteAndVersionsAndContactVariables,
} from '@shared/Quotes/View/types/GetQuoteAndVersionsAndContact';
import { useIsUniversalDrawerEnabled } from '@builder/context/AccountProvider/hooks';
import { getCurrencyInfo } from '@ascension/enums';
import { EntityId } from '@ascension/types';

const QuoteViewPage = ({
  profilesAscensionUrl,
  quoteId,
  quotesListUrl,
  returnTo,
  returnUrl,
}: QuoteViewPageProps & { profilesAscensionUrl: string }) => {
  const [subbieAccountId, setSubbieAccountId] = useState<EntityId>();
  const [subbieProfileOpen, setSubbieProfileOpen] = useState(false);
  const isUniversalDrawerEnabled = useIsUniversalDrawerEnabled();

  const { data, loading } = useQuery<
    GetQuoteAndVersionsAndContact,
    GetQuoteAndVersionsAndContactVariables
  >(GET_QUOTE_WITH_CONTACT_DETAILS, {
    variables: { id: quoteId },
  });

  const [, setCurrencyInfoContext] = useContext(CurrencyInfoContext);

  useEffect(() => {
    const currencyInfo = getCurrencyInfo(data?.quote.recipientCountry?.id);
    setCurrencyInfoContext({ currencyInfo });
  }, [data, loading, setCurrencyInfoContext]);

  if (loading || !data) return <PageLoadingSpinner />;

  const { quote } = data;

  const handleViewSubbieDetails = () => {
    const companyId = quote.rfq?.contact?.company?.id;
    const accountId = quote.rfq?.contactAccount?.id;

    if (isUniversalDrawerEnabled) {
      showUniversalProfileDrawer({
        companyId,
        accountId,
      });
    } else if (companyId) {
      document.dispatchEvent(
        new CustomEvent('address_book_slider_open', { detail: { companyId } }),
      );
    } else if (accountId) {
      setSubbieAccountId(accountId);
      setSubbieProfileOpen(true);
    }
  };

  return (
    <>
      <QuoteView
        quote={quote}
        quotesListUrl={quotesListUrl}
        returnTo={returnTo}
        returnUrl={returnUrl}
        handleViewSubbieDetails={handleViewSubbieDetails}
      />

      {subbieAccountId && (
        <SubbieProfilesSlider
          profilesAscensionUrl={profilesAscensionUrl}
          accountId={subbieAccountId}
          isOpen={subbieProfileOpen}
          onRequestClose={() => setSubbieProfileOpen(false)}
        />
      )}
    </>
  );
};

export default QuoteViewPage;
