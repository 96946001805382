import { SingleSelectField } from '@shared/FinalForm/Field/SingleSelectField';
import { WorkforceSizeEnum } from '@ascension/_gqltypes/profiles.generated';

export type WorkforceSizeOption = {
  value: WorkforceSizeEnum;
  label: string;
};

const FIELD_IDENTIFIER = 'workforceSize';

export type WorkforceSizeFieldTypes = {
  [FIELD_IDENTIFIER]: null | WorkforceSizeOption;
};

const workforceSizeOptions: WorkforceSizeOption[] = [
  { value: WorkforceSizeEnum.RANGE_0_5, label: '0-5' },
  { value: WorkforceSizeEnum.RANGE_6_20, label: '6-20' },
  { value: WorkforceSizeEnum.RANGE_21_50, label: '21-50' },
  { value: WorkforceSizeEnum.RANGE_51_250, label: '51-250' },
  { value: WorkforceSizeEnum.RANGE_251_PLUS, label: '251+' },
];

export const getWorkforceSizeOptionFromEnum = (value?: WorkforceSizeEnum | null) =>
  workforceSizeOptions.find((option) => value === option.value) ?? null;

export const WorkforceSizeSingleSelect = () => (
  <div>
    <SingleSelectField<WorkforceSizeOption>
      id={FIELD_IDENTIFIER}
      label="Workforce size"
      hideLabel
      placeholder="Workforce size"
      fieldName={FIELD_IDENTIFIER}
      options={workforceSizeOptions}
      isClearable
    />
  </div>
);
