import { gql, useMutation } from '@apollo/client';
import { LettingScheduleNotesSlideoutSource } from '../../LettingScheduleNotesSlideout/LettingScheduleNotesSlideout';
import { NOTES_FOR_LETTING_SCHEDULE } from '../../LettingScheduleNotesSlideout/queries';
import { PACKAGE_DASHBOARD_RFQS } from '../../PackageDashboard/queries';
import { ADD_LETTING_SCHEDULE_NOTE } from '../mutations';
import {
  AddNoteToLettingScheduleDetails,
  AddNoteToLettingScheduleDetailsVariables,
} from '../types/AddNoteToLettingScheduleDetails';
import { CriticalStatus } from '@ascension/_gqltypes/builder.generated';
import { EntityId } from '@ascension/types';

const getRefetchQueries = (source: LettingScheduleNotesSlideoutSource) => {
  switch (source) {
    case LettingScheduleNotesSlideoutSource.LETTING_SCHEDULE:
      return [NOTES_FOR_LETTING_SCHEDULE];
    case LettingScheduleNotesSlideoutSource.PACKAGE_DASHBOARD:
      return [NOTES_FOR_LETTING_SCHEDULE, PACKAGE_DASHBOARD_RFQS];
    default:
      return [];
  }
};
const NOTE_PACKAGE_FRAGMENT = gql`
  fragment NotePackage on Package {
    id
    lettingScheduleDetails {
      id
      latestNote {
        id
        text
        isCritical
      }
    }
  }
`;

export const useAddNoteToLettingScheduleDetails = (source: LettingScheduleNotesSlideoutSource) => {
  const [addNoteToLettingScheduleDetails, { loading }] = useMutation<
    AddNoteToLettingScheduleDetails,
    AddNoteToLettingScheduleDetailsVariables
  >(ADD_LETTING_SCHEDULE_NOTE, {
    refetchQueries: getRefetchQueries(source),
  });

  const submit = async (packageId: EntityId, text: string, isCritical: CriticalStatus) => {
    const { data } = await addNoteToLettingScheduleDetails({
      variables: {
        input: {
          packageId,
          text,
          isCritical,
        },
      },
      optimisticResponse: {
        addNoteToLettingScheduleDetails: {
          __typename: 'LettingScheduleNote',
          id: performance.now(),
          text,
          createdAt: Date.now().toString(),
          authorName: '',
          isCritical,
        },
      },
      update(cache, result) {
        const packageData = cache.readFragment<{
          id: EntityId;
          lettingScheduleDetails: {
            id: EntityId;
            latestNote: { id: EntityId; text: string };
          };
        }>({
          id: `Package:${packageId}`,
          fragment: NOTE_PACKAGE_FRAGMENT,
        });

        if (packageData) {
          cache.modify({
            id: cache.identify(packageData.lettingScheduleDetails),
            fields: {
              isCritical(existingCriticalStatus) {
                if (result.data) {
                  return isCritical;
                }

                return existingCriticalStatus;
              },
              latestNote(existingLatestNote) {
                return result.data?.addNoteToLettingScheduleDetails ?? existingLatestNote;
              },
              noteCount(existingCount) {
                if (result.data) {
                  return existingCount + 1;
                }

                return existingCount;
              },
            },
          });
        }
      },
    });
    return data?.addNoteToLettingScheduleDetails;
  };

  return { submit, loading };
};
