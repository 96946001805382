export const sendSpeciFinderInvitationAnalytics = (
  rfqId: number,
  action: string,
  url?: string,
  isViaRedirect?: boolean,
) => {
  const invitationsPageKeywordMatch =
    $(`#hiddenHasKeywordMatches #hasKeywordMatches-rfq-id-${rfqId}`).val() === 'true';
  const leadsPageKeywordMatch =
    $(`#hiddenLeadsHasSFKeywordMatches-rfq-id-${rfqId}`).val() === 'true';
  const keywordMatch = invitationsPageKeywordMatch || leadsPageKeywordMatch;

  const viaRedirect =
    isViaRedirect || $('body.super-slider-active #hiddenIsViaRedirection').val() === 'true';
  window.analyticsService?.addInteractEvent(
    {
      action,
      keywordMatch,
      viaRedirect,
    },
    url ?? '/invitations',
  );
};

export const sendSpeciFinderInvitationSliderButtonAnalytics = (buttonName: string) => {
  window.analyticsService?.addInteractEvent({
    action: 'ButtonClicked',
    buttonName,
    screenName: 'invitations_responses_slider',
  });
};
