const USER_AND_ID_REGEX = /@\[.+?]\(.+?\)/gm;
const DISPLAY_NAME_REGEX = /@\[.+?]/g;
const ID_REGEX = /\(.+?\)/g;

const addUserLinks = (text: string) => {
  if (!text) {
    return text;
  }

  const matches = text.match(USER_AND_ID_REGEX);

  if (!matches) {
    return text;
  }

  const users = matches.map((m) => {
    const id = m.match(ID_REGEX)![0].replace('(', '').replace(')', '');
    const display = m.match(DISPLAY_NAME_REGEX)![0].replace('@[', '').replace(']', '');

    return { id, display };
  });

  const textParts = text.split(USER_AND_ID_REGEX);

  return textParts.reduce((acc, part, i) => {
    if (i === textParts.length - 1) {
      return `${acc}${part}`;
    }
    return `${acc}${part}<a href="#">@${users[i].display}</a>`;
  }, '');
};

export default addUserLinks;
