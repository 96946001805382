import { useContext } from 'react';
import { ApolloError } from '@apollo/client';
import { FeatureContext } from '@profiles/context/FeatureContext';

type UsePaidViewPayload = {
  isPaidAccount: undefined | boolean;
  loading: boolean;
  error: ApolloError | undefined;
};

export const usePaidView = (): UsePaidViewPayload => {
  const { loading, error, context } = useContext(FeatureContext);
  const isPaidAccount = context?.companyProfileAdvanced;

  return { loading, error, isPaidAccount };
};
