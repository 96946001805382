import { MutableRefObject, useEffect, useState } from 'react';
import {
  ActionMenu,
  Button,
  ButtonSize,
  ButtonVariant,
  ColumnsIcon,
  ColumnInstance,
  TableInstance,
} from '@estimateone/frontend-components';
import { ColumnActionMenuItem } from './ColumnActionMenuItem';
import LoadingSpinner from '@shared/LoadingSpinner';
import { CustomColumnNames } from '../hooks/useCustomColumns';
import { useUpdateLettingColumnStatusMutation } from './hooks/useUpdateLettingColumnStatus';
import { GetLettingColumnStatus_userLettingColumnStatus as UserLettingColumnStatus } from './types/GetLettingColumnStatus';
import { LettingScheduleCustomColumnIdentifier as CustomColumnIdentifier } from '@ascension/_gqltypes/builder.generated';
import { EntityId } from '@ascension/types';
import { Package } from '@builder/features/ProcurementLettingSchedule/types';
import styles from './styles.scss';

const ColumnMenuButton = (
  <Button variant={ButtonVariant.Grey} size={ButtonSize.Small}>
    <ColumnsIcon className={styles.columnMenuIcon} />
    Columns
  </Button>
);

type ColumnActionMenuProps = {
  stageId: EntityId;
  columnStatuses: UserLettingColumnStatus[];
  tableRef: MutableRefObject<TableInstance<Package> | undefined>;
  customColumnNames: CustomColumnNames;
  enableDefaultTimeframes: boolean;
};

type ColumnStatus = {
  columnName: string;
  isShown: boolean;
};

export const getColumnMenuMap = (
  customColumnNames: CustomColumnNames,
  enableDefaultTimeframes: boolean,
): { id: string; columnIds: string[]; label: string }[] => [
  { id: 'priority', columnIds: ['priority'], label: 'Priority' },
  { id: 'quoteCoverage', columnIds: ['quoteCoverage'], label: 'Quote Coverage: Graph' },
  {
    id: 'quoteCoverageTable',
    columnIds: ['invited', 'quoted', 'quoting', 'notQuoting', 'noResponse'],
    label: 'Quote Coverage: Columns',
  },
  { id: 'sendInvitesByDate', columnIds: ['sendInvitesByDate'], label: 'Send Invites' },
  ...(enableDefaultTimeframes
    ? [
        {
          id: 'quotesDueByDateInterval',
          columnIds: ['quotesDueByDateInterval'],
          label: 'Days Between: Send Invites and Quotes Due',
        },
      ]
    : []),
  { id: 'quotesDueByDate', columnIds: ['quotesDueByDate'], label: 'Quotes Due' },
  ...(enableDefaultTimeframes
    ? [
        {
          id: 'letByDateInterval',
          columnIds: ['letByDateInterval'],
          label: 'Days Between: Quotes Due and Let By',
        },
      ]
    : []),
  { id: 'letBy', columnIds: ['letBy'], label: 'Let By' },
  ...(enableDefaultTimeframes
    ? [
        {
          id: 'startOnSiteDateInterval',
          columnIds: ['startOnSiteDateInterval'],
          label: 'Days Between: Let By and Start on Site',
        },
      ]
    : []),
  { id: 'startOnSiteDate', columnIds: ['startOnSiteDate'], label: 'Start on Site' },
  { id: 'budgetAmount', columnIds: ['budgetAmount'], label: 'Budget' },
  { id: 'actualAmount', columnIds: ['actualAmount'], label: 'Actual' },
  { id: 'amountVariance', columnIds: ['amountVariance'], label: 'Variance' },
  { id: 'assignedTo', columnIds: ['assignedTo'], label: 'Assigned To' },
  { id: 'notes', columnIds: ['notes'], label: 'Notes' },
  {
    id: 'customColumn1',
    columnIds: ['customColumn1'],
    label: customColumnNames[CustomColumnIdentifier.CUSTOM_COLUMN_1],
  },
  {
    id: 'customColumn2',
    columnIds: ['customColumn2'],
    label: customColumnNames[CustomColumnIdentifier.CUSTOM_COLUMN_2],
  },
];

export const ColumnActionMenu = ({
  stageId,
  columnStatuses,
  tableRef,
  customColumnNames,
  enableDefaultTimeframes,
}: ColumnActionMenuProps) => {
  const [tableColumns, setTableColumns] = useState<ColumnInstance<Package>[]>([]);
  const [initialColumnStatuses, setInitialColumnStatuses] = useState<ColumnStatus[]>([]);
  const { updateLettingColumnStatus, loading: mutationLoading } =
    useUpdateLettingColumnStatusMutation();
  const columnMenuMap = getColumnMenuMap(customColumnNames, enableDefaultTimeframes);
  const defaultTimeframeColumnIds = [
    'quotesDueByDateInterval',
    'letByDateInterval',
    'startOnSiteDateInterval',
  ];

  useEffect(() => {
    if (!mutationLoading) {
      if (tableRef.current?.columns && tableColumns !== tableRef.current.columns) {
        setTableColumns(tableRef.current.columns);
      }

      columnStatuses.forEach((columnStatus) => {
        const currentColumn = tableColumns.find((column) => column.id === columnStatus.columnName);
        if (currentColumn) {
          currentColumn.toggleHidden(!columnStatus.isShown);
        }
      });

      if (!enableDefaultTimeframes) {
        defaultTimeframeColumnIds.forEach((id) => {
          const timeframeColumn = tableColumns.find((column) => column.id === id);
          timeframeColumn?.toggleHidden(true);
        });
      }
    }
  });

  const initUserColumnStatus = () => {
    setInitialColumnStatuses(
      tableColumns.map((column) => ({
        columnName: column.id,
        isShown: column.isVisible,
      })),
    );
  };

  const updateUserColumnStatus = async () => {
    const columnStatusesDiff = initialColumnStatuses.reduce(
      (diff: ColumnStatus[], columnStatus) => {
        const currentColumn = tableColumns.find((column) => column.id === columnStatus.columnName);
        if (currentColumn && columnStatus.isShown !== currentColumn.isVisible) {
          diff.push({ columnName: columnStatus.columnName, isShown: currentColumn.isVisible });
        }
        return diff;
      },
      [],
    );
    if (columnStatusesDiff.length > 0) {
      await updateLettingColumnStatus(stageId, columnStatusesDiff);
    }
  };

  if (tableColumns.length <= 0) {
    return (
      <ActionMenu triggerElement={ColumnMenuButton}>
        <LoadingSpinner />
      </ActionMenu>
    );
  }

  return (
    <ActionMenu
      triggerElement={ColumnMenuButton}
      onShown={() => initUserColumnStatus()}
      onHidden={() => updateUserColumnStatus()}
    >
      {columnMenuMap.map((columnMenuItem) => {
        const columns = tableColumns.filter((column) =>
          columnMenuItem.columnIds.includes(column.id),
        );

        const savedIsShown = columnStatuses?.find(
          (colStatus) => colStatus.columnName === columnMenuItem.columnIds[0],
        );
        const isShown = savedIsShown ? savedIsShown.isShown : columns[0].isVisible;

        return (
          <ColumnActionMenuItem
            key={columnMenuItem.id}
            displayName={columnMenuItem.label}
            columns={columns}
            isShown={isShown}
          />
        );
      })}
    </ActionMenu>
  );
};
