import { useQuery } from '@apollo/client';
import { isConnectedCompany } from '../providers/UniversalProfileProvider/utils';
import { useIsNetworkConnectionsEnabled } from '@builder/context/AccountProvider/hooks';
import { useUniversalProfileContext } from '../providers/UniversalProfileProvider/hooks';
import { GET_MATCHING_PROFILES } from '@subbie-directory/queries';
import { EntityId } from '@ascension/types';
import {
  SearchMatchingProfiles,
  SearchMatchingProfilesVariables,
} from '@subbie-directory/types/SearchMatchingProfiles';

const determineMatchingType = ({
  profileId,
  companyId,
  accountId,
}: {
  profileId: string | undefined;
  companyId: EntityId | undefined;
  accountId: EntityId | undefined;
}) => {
  const networkCompanyIdsDefined = profileId !== undefined || accountId !== undefined;
  const addressBookCompanyIdDefined = companyId !== undefined;

  if (networkCompanyIdsDefined && addressBookCompanyIdDefined) {
    // If we have all the IDs, the profile is already connected so finding a matching profile makes no sense
    return 'invalid' as const;
  }

  if (networkCompanyIdsDefined) {
    return 'matching-address-book-company' as const;
  }

  if (addressBookCompanyIdDefined) {
    return 'matching-network-company' as const;
  }

  // If we have no IDs there isn't any way to perform matching
  return 'invalid' as const;
};

export const useGetMatchingProfiles = ({
  profileId,
  companyId,
  accountId,
  companyDetailsLoading,
  isShadowProfile,
}: {
  profileId?: string;
  companyId?: EntityId;
  accountId?: EntityId;
  companyDetailsLoading: boolean;
  isShadowProfile?: boolean;
}) => {
  const isNetworkConnectionsEnabled = useIsNetworkConnectionsEnabled();
  const { universalProfile } = useUniversalProfileContext();
  const isSynced = universalProfile ? isConnectedCompany(universalProfile) : false;

  const matchingType = determineMatchingType({
    profileId,
    companyId,
    accountId,
  });

  const { data } = useQuery<SearchMatchingProfiles, SearchMatchingProfilesVariables>(
    GET_MATCHING_PROFILES,
    {
      variables: {
        search: {
          profileId,
          companyId: companyId?.toString(),
          // The search API will only accept one ID at a time
          // No need to provide the account ID if we already have a profile ID
          accountId: profileId === undefined ? accountId?.toString() : undefined,
        },
      },
      // We can only match one type of profile to another (e.g. C->U or U->C), so providing both IDs doesn't make sense.
      skip:
        !isNetworkConnectionsEnabled ||
        matchingType === 'invalid' ||
        companyDetailsLoading ||
        isShadowProfile === undefined ||
        isShadowProfile ||
        isSynced,
    },
  );

  return {
    searched: data?.searchMatchingProfiles.searched ?? null,
    matchingProfiles: data?.searchMatchingProfiles.results ?? null,
    highlights: data?.searchMatchingProfiles.highlights ?? null,
    matchingType,
  };
};
