import { DetailsCard } from '@estimateone/frontend-components';
import { format } from 'date-fns';
import { LockedIcon } from '../../DocumentIntegrationConnectSlider/DocumentIcons';
import { SelectedDocuments } from '../ProcoreConnectSlider';
import { ProcoreProject } from '../ProcoreProjectList';
import { getAddressString } from '../procore.utils';
import { stageProcoreMapSetup_stageProcoreMapSetup_stageProcoreMapOptions as StageProcoreMapOptions } from '../types/stageProcoreMapSetup';
import styles from './styles.scss';

interface ProcoreConfirmSelectionProps {
  selectedDocuments: SelectedDocuments;
  selectedProjectDetails: ProcoreProject;
  stageProcoreMapOptions: StageProcoreMapOptions;
  stageName: string;
}

export const ProcoreConfirmSelection = ({
  selectedDocuments,
  selectedProjectDetails,
  stageProcoreMapOptions,
  stageName,
}: ProcoreConfirmSelectionProps) => {
  const selectedDocumentSectionList = [
    ...selectedDocuments.documents.folders,
    ...selectedDocuments.documents.files,
  ];

  const getFileOrFolderNameById = (id: string): string | undefined =>
    stageProcoreMapOptions.documents.files.find((file) => file.id === id)?.name ||
    stageProcoreMapOptions.documents.folders.find((folder) => folder.id === id)?.name;

  const getSelectionContent = () => [
    {
      label: 'E1 Project',
      description: <p>{stageName}</p>,
    },
    {
      label: 'Procore Project',
      description: (
        <div>
          <p className={styles.projectLabel}>
            Project Number:&nbsp;
            <span className={styles.projectDescription}>{selectedProjectDetails.id}</span>
          </p>

          <p className={styles.projectLabel}>
            Project Name:&nbsp;
            <span className={styles.projectDescription}>{selectedProjectDetails.name}</span>
          </p>
          <p className={styles.projectLabel}>
            Address:&nbsp;
            <span className={styles.projectDescription}>
              {getAddressString(selectedProjectDetails.address)}
            </span>
          </p>
          <p className={styles.projectLabel}>
            Date Created:&nbsp;
            <span className={styles.projectDescription}>
              {selectedProjectDetails.dateCreatedAt
                ? format(new Date(selectedProjectDetails.dateCreatedAt), 'd MMM yyyy')
                : ''}
            </span>
          </p>
        </div>
      ),
    },
    {
      label: 'Importing',
      description: (
        <div>
          {selectedDocuments.includeDrawings && <p>Drawings (Project Management)</p>}
          {selectedDocuments.includeSpecifications && <p>Specifications (Project Management)</p>}
          {selectedDocumentSectionList.length > 0 && <p>Documents (Core Tools):</p>}
          {selectedDocumentSectionList.length > 0 && (
            <ul className={styles.documentsList}>
              {selectedDocumentSectionList.map((fileOrFolderId) => (
                <li key={fileOrFolderId}>{getFileOrFolderNameById(fileOrFolderId) || ''}</li>
              ))}
            </ul>
          )}
        </div>
      ),
    },
    {
      label: '',
      description: (
        <div className={styles.privateFileNote}>
          <LockedIcon className={styles.privateFileNoteIcon} />
          <div>Private folders and files won&apos;t be imported</div>
        </div>
      ),
    },
  ];
  return (
    <>
      <p className={styles.detailsText}>Confirm the connection details:</p>
      <DetailsCard className={styles.detailsCard} contents={getSelectionContent()} />
    </>
  );
};
