import { gql } from '@apollo/client';

export const GET_PRIMARY_CREDIT_CARD = gql`
  query PrimaryCreditCard {
    primaryCreditCard {
      lastFourDigits
      expiryMonth
      expiryYear
      brand
      isExpired
    }
  }
`;

const MONEY_FRAGMENT = gql`
  fragment Money on Money {
    amount
    currency {
      code
    }
  }
`;

const PRORATED_FRAGMENT = gql`
  fragment Prorated on ProratedAmount {
    creditsApplied {
      ...Money
    }
    amountDue {
      ...Money
    }
  }
`;

export const GET_LITE_TO_ESSENTIAL_COSTS = gql`
  query LiteToEssentialCosts {
    upgradeLiteToEssentialProratedAmounts {
      id
      annual {
        ...Prorated
      }
      monthly {
        ...Prorated
      }
    }
    essentialLicenseOngoingAmounts {
      annual {
        ...Money
      }
      monthly {
        ...Money
      }
    }
  }
  ${MONEY_FRAGMENT}
  ${PRORATED_FRAGMENT}
`;

export const GET_BILLING_CONTACT = gql`
  query GetBillingContact {
    billingContact {
      fullName
      email
    }
  }
`;
