type UnitForms = {
  word: string;
  unit: string;
};

export const getUnitForms = (n: number): UnitForms | undefined => {
  const absM = Math.abs(n);

  if (absM >= 1e9) {
    return {
      word: 'billion',
      unit: 'B',
    };
  }

  if (absM >= 1e6) {
    return {
      word: 'million',
      unit: 'M',
    };
  }

  return undefined;
};

const twoDecimalPlaces = (n: number) => Math.trunc(n * 100) / 100;

export const roundMoney = (n: number): number => {
  const absN = Math.abs(n);

  if (absN < 1e6) {
    return twoDecimalPlaces(n);
  }

  // More than 10 billion - billions to one decimal place
  if (absN >= 10e9) {
    return Math.trunc(n / 1e8) / 10;
  }

  const len = Math.round(absN).toString().length;
  const divisor = 10 ** len;

  const twoDp = Math.trunc(n / (divisor / 10e2)) / 1e2;
  const oneDp = Math.trunc(n / (divisor / 10e2)) / 10;

  return len % 3 === 1 ? twoDp : oneDp;
};
