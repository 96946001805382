import {
  Button,
  ButtonVariant,
  Col,
  Icon,
  IconName,
  Modal,
  ModalSize,
  Row,
} from '@estimateone/frontend-components';
import styles from './styles.scss';

export type MultiInviteWarningModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onContinueClick: () => void;
  onSingleInviteClick: () => void;
};

export const MultiInviteWarningModal = ({
  isOpen,
  onClose,
  onContinueClick,
  onSingleInviteClick,
}: MultiInviteWarningModalProps) => (
  <Modal isOpen={isOpen} size={ModalSize.Small} onRequestClose={onClose} hideClose>
    <Modal.Section>
      <h3>Send invitations without scopes?</h3>
      <div className={styles.warningModalInfoMessage}>
        <Row>
          <span className={styles.warningModalInfoIcon}>
            <Icon name={IconName.Info} />
          </span>
          <b>
            &nbsp;Bulk inviting multiple contacts currently doesn&prime;t support <br />
            &nbsp;attaching a Scope of Works document.
          </b>
        </Row>
      </div>
      <div className={styles.warningModalAdditionalMessage}>
        <p>
          However, an uploaded Scope of Works can be attached when inviting one subcontractor at a
          time.
        </p>
      </div>
      <Row>
        <Col span={6}>
          <Button
            onClick={onSingleInviteClick}
            className={styles.button}
            variant={ButtonVariant.Secondary}
            fullWidth
          >
            Invite One Subcontractor
          </Button>
        </Col>
        <Col span={6}>
          <Button onClick={onContinueClick} className={styles.button} fullWidth>
            Continue
          </Button>
        </Col>
      </Row>
    </Modal.Section>
  </Modal>
);
