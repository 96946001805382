import { Button, ButtonSize, ButtonVariant } from '@estimateone/frontend-components';
import { FontStyle, Heading } from '@ascension/components/fec';
import { FooterSection } from './FooterSection';
import { UnlockTenderGraphic } from './UnlockTenderGraphic';
import { TradesSection } from '../../shared/RoadblockCreditExperiments/TradesSection';
import styles from './styles.module.scss';

type UnlockTenderViewProps = {
  creditsAllowed: number;
  creditsUsed: number;
  creditsRenewalDate: string;
  projectId: number;
  onUnlock: () => void;
  isUnlockProjectLoading: boolean;
  upgradeUrl: string;
};

export const UnlockTenderView = ({
  creditsAllowed,
  creditsUsed,
  creditsRenewalDate,
  projectId,
  onUnlock,
  isUnlockProjectLoading,
  upgradeUrl,
}: UnlockTenderViewProps) => {
  const creditsRemaining = creditsAllowed - creditsUsed;

  return (
    <>
      <UnlockTenderGraphic />
      <Heading level={1} renderAs={FontStyle.h2}>
        Use 1 credit to unlock this project
      </Heading>
      <TradesSection projectId={projectId} />
      <div className={styles.buttonContainer}>
        <Button
          type="button"
          variant={ButtonVariant.Primary}
          fullWidth
          disabled={isUnlockProjectLoading}
          onClick={onUnlock}
          size={ButtonSize.Medium}
        >
          Unlock tender
        </Button>
      </div>
      <FooterSection
        upgradeUrl={upgradeUrl}
        creditsRenewalDate={creditsRenewalDate}
        remainingCredits={creditsRemaining}
      />
    </>
  );
};
