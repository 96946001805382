import { useNavigate } from 'react-router-dom-v5-compat';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  Card,
  CardVariant,
  Icon,
  IconName,
  LoadingSpinner,
  Tag,
  TagVariant,
} from '@estimateone/frontend-components';
import { useProfileContext } from '@profiles/providers/ProfileProvider';
import { GlobalErrorBanner } from '../../components/GlobalErrorBanner';
import { BuilderViewsChart } from '../../components/ProfileInsights/BuilderViewsChart';
import { CompleteProfileInsightsAlert } from '../../components/ProfileInsights/CompleteProfileInsightsAlert';
import { IncompleteProfileInsightsAlert } from '../../components/ProfileInsights/IncompleteProfileInsightsAlert';
import { InsightsStatsPanel } from '../../components/ProfileInsights/InsightsStatsPanel';
import { ProfileInsightsLog } from '../../components/ProfileInsights/ProfileInsightsLog';
import {
  getDailyViewLog,
  getPastYearViewTotals,
} from '../../components/ProfileInsights/aggregateViewStats';
import { getProfileStatus, hasCompletedProfile } from '../../components/ProfileInsights/utils';
import styles from './ProfileInsights.module.scss';
import { useProfileInsights } from '@profiles/providers/ProfileProvider/hooks/useProfileInsights';
import { ProfileRoute } from '@profiles/apps/CompanyProfile/enums';

export const ProfileInsightsPage = () => {
  const { rawData: data, loading } = useProfileInsights();

  const navigate = useNavigate();
  const handleEditProfileClick = () => {
    navigate(ProfileRoute.CompanyProfile);
  };

  const { profile } = useProfileContext();

  if (loading) {
    return <LoadingSpinner />;
  }

  const profileStatus = getProfileStatus(profile);
  const isProfileComplete = hasCompletedProfile(profileStatus);

  const stats = getPastYearViewTotals(data?.monthlyProfileViews);

  const { dailyViewers, viewerNames } = getDailyViewLog(data?.dailyProfileViews);

  return (
    <div className={styles.insightsContainer}>
      <GlobalErrorBanner />
      <div className={styles.headerActionContainer}>
        <div className={styles.titleContainer}>
          <h1 className={styles.settingsHeader}>Profile Insights</h1>
          <Tag variant={TagVariant.West200} text="BETA" />
        </div>
        <Button
          className={styles.buttonRight}
          variant={ButtonVariant.Secondary}
          onClick={handleEditProfileClick}
          size={ButtonSize.Small}
        >
          <Icon name={IconName.User} marginRight="small" size="1.2em" />
          Open/edit profile
        </Button>
      </div>
      {isProfileComplete ? (
        <CompleteProfileInsightsAlert />
      ) : (
        <IncompleteProfileInsightsAlert profileStatus={profileStatus} />
      )}
      <Card variant={CardVariant.Grey}>
        <Card.Body isWhite>
          <div className={styles.cardContent}>
            <InsightsStatsPanel
              stats={stats}
              accountCountryId={data?.currentAccount?.primaryCountry?.id}
            />
            <BuilderViewsChart monthlyProfileViews={data?.monthlyProfileViews} />
          </div>
        </Card.Body>
      </Card>
      {viewerNames.size > 0 && (
        <>
          <h4>Who&apos;s viewed your Profile?</h4>
          <ProfileInsightsLog dailyViewers={dailyViewers} viewerNames={viewerNames} />
        </>
      )}
    </div>
  );
};
