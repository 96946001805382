import { createContext, ReactNode, useContext, useMemo } from 'react';
import {
  DocSyncPlugin,
  DocSyncStorageRegion,
} from '@builder/features/DocSyncIntegration/DocSyncPlugin/DocSyncPlugin';
import { StageType } from '@ascension/enums';
import { EntityId } from '@ascension/types';

type DocumentIntegrationContextValue = {
  plugins: DocSyncPlugin[];
  stageId: EntityId;
  stageName: string;
  stageType: StageType;
  storageRegion: DocSyncStorageRegion;
  authHost: string;
  isInSharepointExperiment: boolean;
  hasExistingDocuments: boolean;
  hasDocumentIntegration: boolean;
};
const DocumentIntegrationContext = createContext<DocumentIntegrationContextValue>({
  plugins: [],
  stageId: -1,
  stageName: '',
  stageType: StageType.TYPE_TENDER,
  storageRegion: 'ap-southeast-2',
  authHost: 'https://localhost',
  isInSharepointExperiment: false,
  hasExistingDocuments: false,
  hasDocumentIntegration: false,
});

export type DocumentIntegrationProviderProps = {
  plugins: DocSyncPlugin[];
  stageId: EntityId;
  stageName: string;
  stageType: StageType;
  storageRegion: DocSyncStorageRegion;
  authHost: string;
  isInSharepointExperiment: boolean;
  hasExistingDocuments: boolean;
  hasDocumentIntegration: boolean;
  children: ReactNode;
};
export const DocumentIntegrationProvider = ({
  plugins,
  stageId,
  stageName,
  stageType,
  storageRegion,
  authHost,
  isInSharepointExperiment,
  hasExistingDocuments,
  hasDocumentIntegration,
  children,
}: DocumentIntegrationProviderProps) => {
  const value = useMemo(
    () => ({
      plugins,
      stageId,
      stageName,
      stageType,
      storageRegion,
      authHost,
      isInSharepointExperiment,
      hasExistingDocuments,
      hasDocumentIntegration,
    }),
    [
      plugins,
      stageId,
      stageName,
      stageType,
      storageRegion,
      authHost,
      isInSharepointExperiment,
      hasExistingDocuments,
      hasDocumentIntegration,
    ],
  );
  return (
    <DocumentIntegrationContext.Provider value={value}>
      {children}
    </DocumentIntegrationContext.Provider>
  );
};

export const useDocumentIntegrationContext = () => useContext(DocumentIntegrationContext);

export const useAvailablePlugins = () => {
  const { plugins, stageType, storageRegion } = useContext(DocumentIntegrationContext);
  return useMemo(
    () =>
      plugins.filter(
        (plugin) =>
          plugin.supportedStageTypes.includes(stageType) &&
          plugin.supportedStorageRegions.includes(storageRegion),
      ),
    [plugins, stageType, storageRegion],
  );
};

export const useDocsyncAuthHost = () => useContext(DocumentIntegrationContext).authHost;

export const useIsInSharepointExperiment = () =>
  useContext(DocumentIntegrationContext).isInSharepointExperiment;

export const useHasExistingDocuments = () =>
  useContext(DocumentIntegrationContext).hasExistingDocuments;

export const useStageNameForDocumentIntegration = () =>
  useContext(DocumentIntegrationContext).stageName ?? '';

export const useHasDocumentIntegration = () =>
  useContext(DocumentIntegrationContext).hasDocumentIntegration;
