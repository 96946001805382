import { Tag, TagVariant } from '@estimateone/frontend-components';
import { CoreTradeTagWithTooltip } from '@builder/common/CoreTradeTagWithTooltip';
import styles from './styles.module.scss';

type Trade = {
  __typename?: string;
  id: number;
  name: string;
};

type TradeTagsProps = {
  stockTrades: Trade[];
  coreTrades: Trade[];
};

export const TradeTags = ({ stockTrades, coreTrades }: TradeTagsProps) => {
  const trades = [...coreTrades, ...stockTrades];

  if (trades.length === 0) return null;

  const MAX_TRADE_TAGS_TO_SHOW = 2;

  const firstFive = trades.slice(0, MAX_TRADE_TAGS_TO_SHOW);
  const notShownCount = trades.length - MAX_TRADE_TAGS_TO_SHOW;

  return (
    <ul className={styles.tradeList}>
      {firstFive.map((trade) => (
        <li key={trade.id}>
          {coreTrades.find(({ id }) => id === trade.id) ? (
            <CoreTradeTagWithTooltip coreTrade={trade.name} />
          ) : (
            <Tag variant={TagVariant.Practical025} text={trade.name} />
          )}
        </li>
      ))}
      {notShownCount > 0 && (
        <li className={styles.andMoreText}>
          <small>and {notShownCount} more...</small>
        </li>
      )}
    </ul>
  );
};
