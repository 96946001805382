import { gql } from '@apollo/client';

export const DELETE_LOGO = gql`
  mutation DeleteProfileLogo {
    deleteLogo {
      ... on Profile {
        id
        # TODO: update the db and return null here so backend can be SoT for logo existingness
        logoUrl
      }
    }
  }
`;
