import { Modal } from '@estimateone/frontend-components';
import { BenefitsList } from './BenefitsList';
import { PlanDetailsCard } from './PlanDetailsCard';
import { useGetCurrentLiteLicenseDetails } from './hooks/useGetCurrentLiteLicenseDetails';
import { BillingCycle } from '@ascension/_gqltypes/subbie.generated';
import styles from './styles.scss';

export type BillingFrequency = 'annual' | 'monthly';

// TODO: something better than this
const getPlanDescription = (oldProductName: string) =>
  oldProductName.replace('Lite State License', 'Essential Plan');

export const AccountUpgradeModalPlanDetailsSection = () => {
  const currentLicenseDetails = useGetCurrentLiteLicenseDetails();
  if (!currentLicenseDetails) {
    return null;
  }

  const billingFrequency: BillingFrequency =
    currentLicenseDetails.billingCycle === BillingCycle.TWELVE_MONTHS ? 'annual' : 'monthly';

  return (
    <Modal.Section span={{ lg: 6 }}>
      <div className={styles.modalSectionTitle}>Essential plan details</div>
      <PlanDetailsCard
        billingFrequency={billingFrequency}
        planDescription={getPlanDescription(currentLicenseDetails.productName)}
        renewalDate={currentLicenseDetails.renewalDate}
      />
      <BenefitsList />
    </Modal.Section>
  );
};
