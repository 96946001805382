import { gql } from '@apollo/client';

export const UPDATE_OFFICE_DETAILS = gql`
  mutation UpdateOfficeDetails($input: UpdateOfficeDetailsInput!) {
    updateOfficeDetails(input: $input) {
      ... on Errors {
        errors {
          field
          message
        }
      }
      ... on Profile {
        offices {
          id
          name
          contactable {
            phone
            email
            web
          }
          serviceArea {
            id
            radius
            type
            boundaries {
              id
              name
              shortName
              type
            }
          }
          deletedAt
          address {
            id
            address1
            address2
            address3
            fullAddress
            shortAddress
            suburb
            postcode
            city
            province
            state {
              id
              shortName
            }
            country {
              id
              shortName
            }
          }
        }
      }
    }
  }
`;
