import { getStatus, Slider, Stepper } from '@estimateone/frontend-components';
import styles from '@builder/features/DocSyncIntegration/Sharepoint/styles.scss';

type SharepointSliderStepperProps = {
  currentStep: number;
  setCurrentStep: (step: number) => void;
};
export const SharepointSliderStepper = ({
  currentStep,
  setCurrentStep,
}: SharepointSliderStepperProps) => {
  const getStatusForStep = (stepNumber: number) => getStatus(stepNumber, currentStep);

  return (
    <Slider.Header variant="secondary">
      <div className={styles.sliderSteps}>
        <Stepper>
          <Stepper.Step
            stepNumber={1}
            label="Select Site"
            status={getStatusForStep(1)}
            onClick={() => setCurrentStep(1)}
          />
          <Stepper.Step
            stepNumber={2}
            label="Select Base Folder"
            status={getStatusForStep(2)}
            onClick={() => setCurrentStep(2)}
          />
          <Stepper.Step
            stepNumber={3}
            label="Select Items"
            status={getStatusForStep(3)}
            onClick={() => setCurrentStep(3)}
          />
          <Stepper.Step
            stepNumber={4}
            label="Confirm Connection"
            status={getStatusForStep(4)}
            onClick={() => setCurrentStep(4)}
          />
        </Stepper>
      </div>
    </Slider.Header>
  );
};
