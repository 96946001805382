import { useEffect, useState } from 'react';
import Routing from 'routing';
import { Button } from '@estimateone/frontend-components';
import { InviteToQuoteSlideout } from './InviteToQuoteSlideout';
import { MultiInviteWarningModal } from './MultiInviteWarningModal';
import { ListenerEvent } from '@ascension/enums';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

type InviteToQuoteProps = {
  stageId: EntityId;
};

export const InviteToQuote = ({ stageId }: InviteToQuoteProps) => {
  const [isSlideoutOpen, setIsSlideoutOpen] = useState<boolean>(false);
  const [isWarningModalOpen, setWarningModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const openWarningModal = () => {
      setWarningModalOpen(true);
    };

    document.addEventListener(ListenerEvent.InviteToQuoteMultiple, openWarningModal);

    return () => {
      document.removeEventListener(ListenerEvent.InviteToQuoteMultiple, openWarningModal);
    };
  }, []);

  const openSingleInviteSlideout = () => {
    setIsSlideoutOpen(true);
    setWarningModalOpen(false);
  };

  const redirectToMultipleInvitePage = () => {
    window.location.assign(
      Routing.generate('app_stage_invitations', {
        id: stageId,
        stageType: 'procurement',
      }),
    );
  };

  return (
    <>
      <Button onClick={() => setIsSlideoutOpen(true)} className={styles.inviteToQuoteButton}>
        Invite to Quote
      </Button>

      <InviteToQuoteSlideout
        stageId={stageId}
        isOpen={isSlideoutOpen}
        onRequestClose={() => setIsSlideoutOpen(false)}
        preselectedPackageId={undefined}
        preselectedCompany={undefined}
      />

      <MultiInviteWarningModal
        isOpen={isWarningModalOpen}
        onClose={() => setWarningModalOpen(false)}
        onSingleInviteClick={openSingleInviteSlideout}
        onContinueClick={redirectToMultipleInvitePage}
      />
    </>
  );
};
