import Routing from 'routing';
import E1Request from '@ascension/js/classes/E1Request';
import { sendSpeciFinderInvitationAnalytics } from '@ascension/js/app/utils/sf_invitations_analytics';
import { QuoteReturnRoute } from '@ascension/enums';
import { LeadContact, User } from '../../types';

export const INVITATION_SLIDER_OPEN_EVENT = 'invitation-slider-open-event';

type UserWithoutAccount = Omit<User, 'account'>;

const isMyRfq = (
  currentUserId: number,
  receiverUser: UserWithoutAccount | null,
  receiverContact: LeadContact | null,
) => {
  const userId = receiverUser?.id ?? receiverContact?.user?.id;
  return userId === currentUserId;
};

export const isActionButtonDisabled = (
  currentUserId: number,
  receiverUser: UserWithoutAccount | null,
  receiverContact: LeadContact | null,
  isPrivate: boolean,
) => isPrivate && !isMyRfq(currentUserId, receiverUser, receiverContact);

export const getViewDocsClassName = (
  currentUserId: number,
  receiverUser: UserWithoutAccount | null,
  receiverContact: LeadContact | null,
  isPrivate: boolean,
) => {
  if (isMyRfq(currentUserId, receiverUser, receiverContact)) {
    return 'rfq-document-viewer-trigger';
  }
  if (!isActionButtonDisabled(currentUserId, receiverUser, receiverContact, isPrivate)) {
    return 'rfq-confirm-document-viewer-trigger';
  }
  return '';
};

export const viewSlider = (rfqId: number, onSliderClose?: () => void, viaRedirect?: boolean) => {
  const params = {
    id: rfqId,
    returnTo: QuoteReturnRoute.Leads,
  };
  const route = Routing.generate(
    'app_rfq_viewslider',
    viaRedirect ? { ...params, viaRedirect } : params,
  );
  sendSpeciFinderInvitationAnalytics(
    rfqId,
    'SF_InviteAndResponsesSliderOpen',
    undefined,
    viaRedirect,
  );
  return new E1Request(route, 'GET', {}, false, undefined, onSliderClose).submit().then(() => {
    window.dispatchEvent(new CustomEvent(INVITATION_SLIDER_OPEN_EVENT));
  });
};
export const viewSliderViaRedirect = (rfqId: number) => viewSlider(rfqId, undefined, true);

export const getViewQuoteUrl = (quoteId: number) =>
  Routing.generate('app_quote_view', {
    id: quoteId,
    returnTo: QuoteReturnRoute.Leads,
  });

export const getAddQuoteUrl = (rfqId: number) =>
  Routing.generate('app_quote_addforrfq', {
    rfqId,
    returnTo: QuoteReturnRoute.Leads,
  });

export const getDownloadDocsClassName = (
  currentUserId: number,
  receiverUser: UserWithoutAccount | null,
  receiverContact: LeadContact | null,
  isPrivate: boolean,
) => {
  if (isMyRfq(currentUserId, receiverUser, receiverContact)) {
    return 'rfq-download-link-trigger';
  }
  if (!isActionButtonDisabled(currentUserId, receiverUser, receiverContact, isPrivate)) {
    return 'rfq-confirm-download-link-trigger';
  }
  return '';
};

export const getDisabledTooltipText = (
  fullName: string,
  email: string | undefined,
  builderTerm: string,
) =>
  `Private project: invitation sent to ${fullName}${email ? ` (${email}).` : '.'} Contact them or the ${builderTerm} for document access.`;
