import { RestrictedStageAccessFragment } from './fragments.generated';
import { FromGraphQL } from '@ascension/types';

export type RestrictedStageAccess = FromGraphQL<RestrictedStageAccessFragment>;
export type User = RestrictedStageAccess['user'];

export enum StageAccess {
  RESTRICTED = 'restricted',
  UNRESTRICTED = 'unrestricted',
}

export type DisplayUser = Pick<User, 'id' | 'fullName' | 'email' | 'phone'>;
