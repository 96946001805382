import { gql } from '@apollo/client';

export const GET_TEAM_MEMBERS = gql`
  query GetTeamMembers {
    team {
      user {
        id
        fullName
        firstName
        lastName
        isAdmin
        phone
        email
      }
    }
  }
`;
