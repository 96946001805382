import { useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { joinClassNames } from '@estimateone/frontend-components';
import { BroadcastChannel } from 'broadcast-channel';
import { AccountUpgradeModal } from '@ascension/components/account-upgrade/modal';
import { openContactUs } from '../util';
import FeatureItems from './FeatureItems';
import { NoTendersView, UnlockTenderView } from './SubbieRoadblockPricingExperiment';
import { PROJECT_UNLOCKED_WITH_CREDIT } from './events';
import {
  RoadblockButtonEnquire,
  RoadblockButtonUpgrade,
  RoadblockButtonLiteToEssentialUpgrade,
} from '../shared/RoadblockButtons';
import RoadblockAwardedContent from '../shared/RoadblockContent/RoadblockAwardedContent';
import RoadblockBudgetContent from '../shared/RoadblockContent/RoadblockBudgetContent';
import { RoadblockCreditExperimentUnlockContent } from '../shared/RoadblockContent/RoadblockCreditExperimentUnlockContent';
import RoadblockFiftyPercentContent from '../shared/RoadblockContent/RoadblockFiftyPercentContent';
import { ExhaustedCreditsView } from '../shared/RoadblockCreditExperiments/ContentView';
import { FooterSection } from '../shared/RoadblockCreditExperiments/FooterSection';
import { useFlashMessage } from '@shared';
import { useAccountContext } from '@subbie/context/AccountProvider';
import { useIsInCreditLiteExperiment } from '@subbie/context/AccountProvider/hooks';
import CreditAllowanceProvider from '@subbie/context/CreditAllowanceProvider';
import { useCanUpgradeLiteToEssential } from '@ascension/components/account-upgrade/modal/hooks/useCanUpgradeLiteToEssential';
import { Action, useInteractAnalytics } from '@ascension/components/hooks/Analytics';
import { useGetNewPaidLicenseReasons } from '@subbie/features/AccountUpgradeFeature/hooks';
import { useStandardLicenseFeaturesForCheckoutPage } from '@subbie/features/AccountUpgradeFeature/pages/NewUpgradeSelection/FeatureList/hooks';
import { useShowProjectUnlockedSuccessMessages } from '@subbie/modal/ProjectSlider/hooks/useShowProjectUnlockedSuccessMessages';
import { BURN_CREDIT_TO_UNLOCK_PROJECT } from '../mutations';
import { ProductType } from '@ascension/enums';
import {
  BurnCreditToUnlockProject,
  BurnCreditToUnlockProjectVariables,
} from '../types/BurnCreditToUnlockProject';
import { ProjectRedactedReason } from '@ascension/_gqltypes/subbie.generated';
import { PaywalledProject } from '@subbie/modal/ProjectSlider/types';
import styles from '../styles.scss';
import additionalFeaturesStyles from './styles.scss';

type SubblierRoadblockAdditionalFeaturesProp = {
  reason: ProjectRedactedReason;
  requireAdditionalLicenseShortState: string;
  requireAdditionalLicense: boolean;
  upgradeUrl: string;
  removeModal: () => void;
  projectState: string;
  productName: string;
  userPhone?: string;
  project: PaywalledProject;
};

// eslint-disable-next-line complexity
const SubblierRoadblockAdditionalFeatures = ({
  reason,
  requireAdditionalLicenseShortState,
  requireAdditionalLicense,
  upgradeUrl,
  removeModal,
  projectState,
  userPhone,
  productName,
  project,
}: SubblierRoadblockAdditionalFeaturesProp) => {
  const premiumFeatures = useGetNewPaidLicenseReasons();
  const standardFeatures = useStandardLicenseFeaturesForCheckoutPage();
  const features = productName === ProductType.SubbieEssential ? premiumFeatures : standardFeatures;
  const { showSuccessMessages } = useShowProjectUnlockedSuccessMessages();
  const [isPaidToPaidModalOpen, setIsPaidToPaidModalOpen] = useState(false);

  const { id: projectId, name: projectName, watchlistEntry } = project;

  /** SUBBIE_CREDIT_EXPERIMENT_ALPHA_CODE */
  const { user, account, onUpgrade } = useAccountContext();
  /** SUBBIE_CREDIT_EXPERIMENT_ALPHA_CODE remove broadcast-channel package */
  const broadcastChannel = useMemo(() => new BroadcastChannel('project_slider'), []);

  const { warning: showWarning } = useFlashMessage();
  const { addEvent } = useInteractAnalytics();

  const isInSubbieCreditLiteExperiment = useIsInCreditLiteExperiment();
  const canAccessLiteToEssentialUpgradeFlow = useCanUpgradeLiteToEssential();

  const [submit, { loading: isUnlockProjectLoading }] = useMutation<
    BurnCreditToUnlockProject,
    BurnCreditToUnlockProjectVariables
  >(BURN_CREDIT_TO_UNLOCK_PROJECT);

  const credits = account?.credits;

  useEffect(() => {
    if (isInSubbieCreditLiteExperiment && credits && credits.allowed - credits.used === 0) {
      addEvent({
        action: Action.PROJECT_SLIDER_OPEN_WITH_NO_CREDITS_LEFT,
        projectId,
      });
    }
  }, [addEvent, credits, isInSubbieCreditLiteExperiment, projectId]);

  const triggerTenderUnlockedAnalyticsEvent = () => {
    if (credits) {
      addEvent({
        action: Action.TENDER_UNLOCKED,
        projectId,
        creditsLeft: credits.allowed - credits.used - 1,
      });
    }
  };

  const burnCreditToUnlockProject = async () => {
    try {
      const { data } = await submit({ variables: { projectId } });
      if (data) {
        showSuccessMessages({
          projectId,
          projectName,
          previousWatchlistStatus: watchlistEntry?.status,
          newWatchlistStatus: data.burnCreditToUnlockProject.watchlistEntry?.status,
        });

        triggerTenderUnlockedAnalyticsEvent();
        await broadcastChannel.postMessage(PROJECT_UNLOCKED_WITH_CREDIT);
        onUpgrade();
      }
    } catch (e) {
      showWarning({ title: 'Unable to unlock tender, please try again later' });
      removeModal();
    }
  };

  const hasCreditsAvailable = credits && credits.used < credits.allowed;

  const handleEnquireButtonClick = () => {
    if (canAccessLiteToEssentialUpgradeFlow) {
      setIsPaidToPaidModalOpen(true);

      return;
    }

    openContactUs(projectState, userPhone);
    removeModal();
  };

  const handlePaidToPaidModalClose = () => {
    setIsPaidToPaidModalOpen(false);
  };

  const userId = user?.id;
  const accountId = account?.id;

  /** SUBBIE_CREDIT_EXPERIMENT_ALPHA_CODE */
  if (reason === ProjectRedactedReason.CREDIT_EXPERIMENT) {
    if (isInSubbieCreditLiteExperiment && credits) {
      return (
        <div className={joinClassNames(styles.roadblockModal)}>
          <CreditAllowanceProvider>
            {hasCreditsAvailable ? (
              <UnlockTenderView
                creditsAllowed={credits.allowed}
                creditsUsed={credits.used}
                creditsRenewalDate={credits.renewalDate}
                projectId={projectId}
                onUnlock={burnCreditToUnlockProject}
                isUnlockProjectLoading={isUnlockProjectLoading}
                upgradeUrl={upgradeUrl}
              />
            ) : (
              <NoTendersView
                projectId={projectId}
                upgradeUrl={upgradeUrl}
                creditsRenewalDate={credits.renewalDate}
              />
            )}
          </CreditAllowanceProvider>
        </div>
      );
    }

    return (
      <div className={joinClassNames(styles.roadblockModal)}>
        {hasCreditsAvailable ? (
          <RoadblockCreditExperimentUnlockContent
            upgradeUrl={upgradeUrl}
            isUnlockProjectLoading={isUnlockProjectLoading}
            onClick={burnCreditToUnlockProject}
            projectId={projectId}
            allowedCredits={credits.allowed}
            usedCredits={credits.used}
            removeModal={removeModal}
          />
        ) : credits ? (
          <ExhaustedCreditsView
            projectId={projectId}
            upgradeUrl={upgradeUrl}
            creditsAllowed={credits.allowed}
            removeModal={removeModal}
          />
        ) : null}
        {userId && accountId && credits && (
          <FooterSection userId={userId} accountId={accountId} creditsAllowed={credits.allowed} />
        )}
      </div>
    );
  }

  return (
    <div className={joinClassNames(styles.roadblockModal)}>
      {reason === ProjectRedactedReason.AWARDED ? (
        <RoadblockAwardedContent
          productName={productName}
          requireAdditionalLicense={requireAdditionalLicense}
          requireAdditionalLicenseShortState={requireAdditionalLicenseShortState}
          projectState={projectState}
        />
      ) : reason === ProjectRedactedReason.BUDGET ? (
        <RoadblockBudgetContent
          productName={productName}
          requireAdditionalLicense={requireAdditionalLicense}
          requireAdditionalLicenseShortState={requireAdditionalLicenseShortState}
          projectState={projectState}
        />
      ) : (
        reason === ProjectRedactedReason.FIFTY_PERCENT && (
          <RoadblockFiftyPercentContent
            requireAdditionalLicense={requireAdditionalLicense}
            requireAdditionalLicenseShortState={requireAdditionalLicenseShortState}
            projectState={projectState}
            productName={productName}
          />
        )
      )}
      <div className={additionalFeaturesStyles.additionalFeaturesContainer}>
        <p>Included in {productName}:</p>
        <div className={additionalFeaturesStyles.additionalFeaturesItemsContainer}>
          <FeatureItems features={features} />
        </div>
      </div>
      {requireAdditionalLicense ? (
        <>
          {canAccessLiteToEssentialUpgradeFlow ? (
            <RoadblockButtonLiteToEssentialUpgrade onClick={handleEnquireButtonClick} />
          ) : (
            <RoadblockButtonEnquire onClick={handleEnquireButtonClick} />
          )}

          <AccountUpgradeModal
            isOpen={isPaidToPaidModalOpen}
            onClose={handlePaidToPaidModalClose}
          />
        </>
      ) : (
        <RoadblockButtonUpgrade href={upgradeUrl} />
      )}
    </div>
  );
};

export default SubblierRoadblockAdditionalFeatures;
