import $ from 'jquery';
import Routing from 'routing';
import GenericList from '../../../classes/GenericList';

type CompetitorDatum = {
  tenderCount: number;
  competitorName: string;
};

type CompetitorResults = CompetitorDatum[];

const getCompetitorsRouteForRegionId = (regionId: number) =>
  Routing.generate('app_stat_competitors', {
    id: regionId,
  });

type ListData = { success: true; results: CompetitorResults };
type CompetitorGenericList = GenericList<ListData, CompetitorResults>;

export const initCompetitorTable = ($target: JQuery, regionId: number): CompetitorGenericList =>
  new GenericList<ListData, CompetitorResults>(
    $target,
    (list) => {
      // eslint-disable-next-line fp/no-mutation, no-param-reassign
      list.table = list.$target.DataTable({
        paging: false,
        data: list.data,
        lengthChange: false,
        info: false,
        order: [[1, 'DESC']],
        columns: [
          {
            data: 'competitorName',
            render: $.fn.dataTable.render.text(),
          },
          {
            data: 'tenderCount',
            render: $.fn.dataTable.render.text(),
          },
        ],
        dom: 'Rrtp',
      });
      list.toggleTableDisplay(true);
      list.$target.closest('.loading-container').addClass('has-loaded');
    },
    getCompetitorsRouteForRegionId(regionId),
    (data) => data.results,
  );

export const modifyCompetitorTable = async (
  table: CompetitorGenericList,
  regionId: number,
): Promise<void> => {
  table.setFetchUrl(getCompetitorsRouteForRegionId(regionId));

  return new Promise<void>((resolve) => {
    table.updateTable(true, resolve);
  });
};
