import { ConsultantsPaywall } from './ConsultantsPaywall';
import { ConsultantsSection } from './ConsultantsSection';
import LoadingSpinner from '@shared/LoadingSpinner';
import {
  useConsultantDetailsAccessIsLoading,
  useCanViewConsultants,
  useConsultantDetailsProvisionalLicenseId,
} from '@subbie/context/ConsultantDetailsAccessContext/hooks';
import { RequiresProjectId } from './ConsultantsTable/types';
import styles from './styles.scss';

const ProjectConsultants = ({ projectId }: RequiresProjectId) => {
  const loading = useConsultantDetailsAccessIsLoading();
  const canViewConsultants = useCanViewConsultants();
  const provisionedLicenseId = useConsultantDetailsProvisionalLicenseId();

  return (
    <div id="project-consultants" className={styles.projectSection}>
      <h3>Consultants</h3>
      {canViewConsultants ? (
        <ConsultantsSection projectId={projectId} />
      ) : loading ? (
        <LoadingSpinner />
      ) : (
        <ConsultantsPaywall projectId={projectId} provisionedLicenseId={provisionedLicenseId} />
      )}
    </div>
  );
};

export default ProjectConsultants;
