import { ApolloError, useMutation } from '@apollo/client';
import { reportError } from '@ascension/components/helpers/errorReporter';
import useFlashMessage from '@shared/Util/useFlashMessage';
import { UNHIDE_PROFILE_CONTACT } from './mutations';
import {
  UnhideProfileContactMutation,
  UnhideProfileContactMutationVariables,
} from './mutations.generated';

export const useUnhideProfileContact = (userId: number, fullName: string) => {
  const { success: showSuccess, warning: showWarning } = useFlashMessage();
  const name = fullName ?? 'This contact';

  const [unhideProfileContact] = useMutation<
    UnhideProfileContactMutation,
    UnhideProfileContactMutationVariables
  >(UNHIDE_PROFILE_CONTACT, {
    variables: { userId },
    onCompleted: () => {
      showSuccess({
        title: 'Success',
        message: `${name} is now visible in your Company Profile`,
      });
    },
    onError: (error: ApolloError) => {
      showWarning({ title: 'Error', message: 'Something went wrong. Please try again.' });
      reportError(error);
    },
  });

  return { unhideProfileContact };
};
