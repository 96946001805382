import { CellProps, E1Link } from '@estimateone/frontend-components';
import { showUniversalProfileDrawer } from '@builder/pages/Profiles/UniversalProfileDrawer';
import { LettingScheduleStatus, getLettingScheduleStatus } from '../LettingScheduleStatus';
import { LettingScheduleStatusLabel } from '../LettingScheduleStatusLabel';
import { LettingStatusPopup } from '../LettingStatusTimeline';
import { showCompanySlideout } from '../PackageDashboard/utils';
import { useIsUniversalDrawerEnabled } from '@builder/context/AccountProvider/hooks';
import { Package } from '@builder/features/ProcurementLettingSchedule/types';
import styles from '../styles.scss';

export const QuoteStatusCell = ({ row: { original } }: CellProps<Package>) => {
  const { lettingScheduleDetails } = original;
  const status = getLettingScheduleStatus(original);
  const isAwarded = status === LettingScheduleStatus.Awarded;
  const awardedCompany = lettingScheduleDetails?.awardedToRfq?.contact?.company ?? undefined;
  const isUniversalDrawerEnabled = useIsUniversalDrawerEnabled();

  return (
    <div className={styles.cellAlign}>
      <LettingStatusPopup lettingStatus={status} awardedCompanyName={awardedCompany?.name}>
        <LettingScheduleStatusLabel status={status} isActive={isAwarded} isScheduleTag />
        {isAwarded && awardedCompany && lettingScheduleDetails ? (
          <E1Link
            className={styles.awardedCompanyName}
            onClick={() => {
              if (isUniversalDrawerEnabled) {
                showUniversalProfileDrawer({ companyId: awardedCompany.id });
              } else {
                showCompanySlideout(awardedCompany.id, lettingScheduleDetails.id);
              }
            }}
          >
            {awardedCompany.name}
          </E1Link>
        ) : null}
      </LettingStatusPopup>
    </div>
  );
};
