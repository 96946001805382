import { gql } from '@apollo/client';

export const UPDATE_CATEGORIES = gql`
  mutation UpdateCategories($projectCategories: [StageCategory!]!) {
    updateProjectCategories(projectCategories: $projectCategories) {
      ... on Profile {
        id
        projectCategories
        updatedAt
        updatedById
      }
    }
  }
`;
