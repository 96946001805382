import { gql } from '@apollo/client';

export const PendingAddendumDetails = gql`
  fragment PendingAddendumDetails on Addendum {
    id
    name
    description
    documents {
      id
      title
      directoryName
      fileName
      drawingId
      revision
      documentToSupersede {
        id
        title
      }
    }
  }
`;
