import 'core-js/stable';
import 'custom-event-polyfill';
import 'isomorphic-unfetch';
import { Provider as JotaiProvider } from 'jotai';
import { BuilderApiProvider } from '@api/ApiProvider';
import { renderWithParents } from '@ascension/components/render-helper';
import SubbieNetwork from '@builder/features/SubbieNetwork';
import { NetworkUpsell } from '@builder/features/SubbieNetwork/NetworkUpsell/NetworkUpsell';
import SubbieNetworkAscensionDataProvider from '@builder/features/SubbieNetwork/providers/AscensionDataProvider';
import NetworkInvites from '@builder/features/SubbieNetworkInvitations/entrypoints/NetworkInvites';
import TenderWizardNetworkInvites from '@builder/features/SubbieNetworkInvitations/entrypoints/TenderWizardNetworkInvites';
import { ProfilesApolloProvider } from '@profiles/providers/ProfileProvider/ProfilesApolloProvider';
import SliderProfile from '@subbie/pages/Profiles/slider';
import { ErrorBoundary } from '@shared';
import { BuilderAccountProvider } from '@builder/context/AccountProvider/BuilderAccountProvider';
import SubbieNetworkInvitesFilterDataProvider from '@builder/context/SubbieNetworkFilterDataProvider';
import { SelectedContactsProvider } from '@builder/features/SubbieNetworkInvitations/context/SelectedContactsProvider';

const renderWithErrorBoundary = <T extends DOMStringMap>(containerId: string) =>
  renderWithParents<T>(<ErrorBoundary />)(containerId);

renderWithErrorBoundary<{
  hasProcurementPhaseFeature: string;
}>('subbie-network-v2-container')(({ hasProcurementPhaseFeature }) => (
  <BuilderApiProvider>
    <BuilderAccountProvider>
      <JotaiProvider>
        <SubbieNetworkAscensionDataProvider>
          <SubbieNetwork hasProcurementPhaseFeature={hasProcurementPhaseFeature === '1'} />
        </SubbieNetworkAscensionDataProvider>
      </JotaiProvider>
    </BuilderAccountProvider>
  </BuilderApiProvider>
));

renderWithErrorBoundary<{ stageId: string; stageName: string }>('subbie-network-invitations')(
  ({ stageId, stageName }) => (
    <BuilderApiProvider>
      <BuilderAccountProvider>
        <SelectedContactsProvider>
          <SubbieNetworkInvitesFilterDataProvider>
            <NetworkInvites stageId={Number(stageId)} stageName={stageName} />
          </SubbieNetworkInvitesFilterDataProvider>
        </SelectedContactsProvider>
      </BuilderAccountProvider>
    </BuilderApiProvider>
  ),
);

renderWithErrorBoundary<{ stageId: string }>('tender-wizard-subbie-network-invitations')(
  ({ stageId }) => (
    <BuilderApiProvider>
      <BuilderAccountProvider>
        <SelectedContactsProvider>
          <SubbieNetworkInvitesFilterDataProvider>
            <TenderWizardNetworkInvites stageId={Number(stageId)} />
          </SubbieNetworkInvitesFilterDataProvider>
        </SelectedContactsProvider>
      </BuilderAccountProvider>
    </BuilderApiProvider>
  ),
);

renderWithErrorBoundary('subbie-network-upsell-container')(() => <NetworkUpsell />);

export const renderSubbieProfileInSliderForBuilder = (accountId: number): Error | null => {
  renderWithErrorBoundary<{ profilesAscensionUrl: string }>(
    'subbie-profiles-slider-container-for-builder',
  )(({ profilesAscensionUrl }) => (
    <ProfilesApolloProvider endpoint={profilesAscensionUrl}>
      <SliderProfile accountId={accountId} />
    </ProfilesApolloProvider>
  ));

  return null;
};

export const renderSubbieProfileInSliderForBuilderWithProfileId = (
  profileId: string,
): Error | null => {
  renderWithErrorBoundary<{ profilesAscensionUrl: string }>(
    'subbie-profiles-slider-container-for-builder',
  )(({ profilesAscensionUrl }) => (
    <ProfilesApolloProvider endpoint={profilesAscensionUrl}>
      <SliderProfile profileId={profileId} />
    </ProfilesApolloProvider>
  ));

  return null;
};
