import { gql } from '@apollo/client';
import Quotes from '../../../api/fragments/Quotes';

const STAGE_QUOTES_AND_PACKAGES = gql`
  query StageQuotesAndPackages($stageId: EntityId!) {
    quotes(stageId: $stageId) {
      ...QuoteCommon
      ...QuotePackageRfqWithContactDetails
    }
    packages(stageId: $stageId) {
      id
      title
      deletedAt
    }
    stage(id: $stageId) {
      ... on BuilderStageInterface {
        account {
          primaryCountry {
            id
          }
        }
      }
    }
  }
  ${Quotes.fragments.common}
  ${Quotes.fragments.packageRfqWithContactDetails}
`;

export { STAGE_QUOTES_AND_PACKAGES };
