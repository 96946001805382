import { gql } from '@apollo/client';
import Quotes from '../../../api/fragments/Quotes';

const COMMIT = gql`
  mutation CommitUncategorisedQuotes($input: CommitUncategorisedQuoteInput!) {
    committedQuotes: commitMultipleQuotes(input: $input) {
      ...QuoteCommon
      ...QuotePackageRfqWithContactDetails
    }
  }
  ${Quotes.fragments.common}
  ${Quotes.fragments.packageRfqWithContactDetails}
`;

const ASSIGN_PACKAGE = gql`
  mutation AssignQuotePackage($input: AssignQuotePackageInput!) {
    quote: assignPackageToQuote(input: $input) {
      id
      ...QuotePackageRfqWithContactDetails
    }
  }
  ${Quotes.fragments.packageRfqWithContactDetails}
`;

export { COMMIT, ASSIGN_PACKAGE };
