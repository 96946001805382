import $ from 'jquery';
import Routing from 'routing';
import moment from 'moment-timezone';
import E1Request from '../classes/E1Request';
import Form from '../classes/Form';
import GenericList from '../classes/GenericList';
import {
  showRfqCorrespondenceComposeModal,
  showRfqEmailErrorModal,
} from './utils/response_list.utils';
import { ResponseList, viewHistory } from './stage_responses_list';
import { getUserTimezone } from './timezone';

function LettingTableActions($target, stageId, contactDataTable) {
  this.$target = $target;
  this.contactDataTable = contactDataTable.listsTable;
  this.stageId = stageId;
  this.$controlPanel = this.$target.find('.table-control-panel-container');
  this.init();
}

LettingTableActions.prototype = {
  init() {
    const self = this;
    if (self.$controlPanel.length > 0) {
      self.$controlPanel.prependTo('.bottom-bar');
      self.$controlPanel.removeClass('hide');
      self.$controlPanel.on('click', '.correspondence-trigger-broadcast', () => {
        showRfqCorrespondenceComposeModal(self.stageId, self.getSelectedIds());
      });
    }

    self.contactDataTable.$target.on('data-selected-updated', (evt, data) => {
      self.updateSelectedTotals();
    });
    self.$target.on('change', '.select-all-rows', function () {
      const checked = $(this).is(':checked');
      const $checkboxes = self.contactDataTable.$target.find('.select-checkbox:not([disabled])');
      $checkboxes.prop('checked', checked);
      self.contactDataTable.$target.trigger('data-selected-updated');
    });
    self.updateSelectedTotals();
  },
  getSelectedIds() {
    const dt = this.$target;
    const selected = [];
    if (dt != null) {
      $.each(dt.find('input[type=checkbox]:checked'), function () {
        if ($(this).data('id')) {
          selected.push($(this).data('id'));
        }
      });
    }
    return selected;
  },
  updateSelectedTotals() {
    const self = this;
    const listTable = this.contactDataTable;
    const selected = self.getSelectedIds();
    const selectedCount = selected.length;
    const totalCount = listTable.data.length;

    listTable.$target.find('.select-all-rows').prop('checked', selectedCount >= totalCount);
    $('.select_count', self.$controlPanel).each(function () {
      if (selectedCount == 0) {
        self.$controlPanel.addClass('hide');
      }

      const text = selectedCount >= totalCount ? $(this).attr('data-all-text') : selectedCount;
      $(this).text(text);

      if (selectedCount > 0) {
        self.$controlPanel.removeClass('hide');
      }
    });
  },
};

$(() => {
  const $body = $('body');
  const $lettingContainer = $('.construct-letting');
  if ($lettingContainer.length > 0) {
    const $lettingTables = $('.letting-responses');
    const $tenderTables = $('.tender-responses');
    const constructionStage = $lettingContainer.attr('data-stage-id');

    if ($lettingTables.length > 0) {
      $lettingTables.each(function () {
        const $container = $(this);
        const contactDataTable = new ResponseList($container);
        contactDataTable.updateTotals = function () {};
        new LettingTableActions($lettingTables, constructionStage, contactDataTable);

        $container.on('click', '.add-to-project', function () {
          const _rid = $(this).closest('div').attr('data-request-id');

          const route = Routing.generate('app_constructionstagedirectory_addfromrfq', {
            id: constructionStage,
            rfq: _rid,
          });
          const request = new E1Request(route);
          request.show_loading_modal = true;
          request.submit();
        });

        $body.on('click', '.rel-status.obsolete:not(.no-modal)', function () {
          const $ctn = $(this).closest('.icons');
          const stageId = constructionStage;
          const requestId = $ctn.attr('data-request-id');
          const route = Routing.generate('app_stagerfq_diff', {
            id: stageId,
            rfqId: requestId,
          });
          const request = new E1Request(route);
          request.show_loading_modal = true;
          request.submit();
        });

        $body.on('click', '.rel-status.email_error:not(.no-modal)', (e) => {
          const $ctn = $(e.currentTarget).closest('.icons');
          const stageId = constructionStage;
          const requestId = $ctn.attr('data-request-id');

          showRfqEmailErrorModal(stageId, requestId);
        });

        $body.on('submit', 'form.add-contact-to-project-form', (e) => {
          e.preventDefault();
          const $form = $(e.currentTarget);
          const magicForm = new Form($form);

          magicForm.extraCallback = function () {
            $body.trigger('update-rfq-table');
          };

          magicForm.submit();
          return false;
        });

        $body.on('submit', 'form.email-error-form', (e) => {
          e.preventDefault();
          const $form = $(e.currentTarget);
          const magicForm = new Form($form);

          magicForm.extraCallback = (response) => {
            if (response.success && response.rfq) {
              if (contactDataTable !== null) {
                const dataTable = contactDataTable.listsTable;
                if (dataTable !== null) {
                  dataTable.updateTable(true);
                }
              }
            }
          };
          magicForm.submit();
          return false;
        });

        $(document).on('update-rfq-table', (event, param1, param2) => {
          contactDataTable.listsTable.updateTable(true);
        });
      });
    }

    if ($tenderTables.length > 0) {
      $tenderTables.each(function () {
        const $container = $(this);
        const $listsTable = $('table.tender-requests-dataTable');
        const stageId = $(this).attr('data-stage');
        const $placeholderRow = $listsTable.find('tr.data-table-placeholder').first();
        const REQUEST_E1 = 1;

        const addStatusIcon = function (element, statusType) {
          return $('<span>').addClass('tag rel-status').addClass(statusType);
        };
        const addStatus = function (status, $main, row) {
          const $status = addStatusIcon($main, status);
          $main.append($status);
          row.response.push(status);
        };

        const oldDataTable = new GenericList(
          $listsTable,
          (list) => {
            list.table = list.$target.DataTable({
              paging: false,
              data: list.data,
              info: false,
              order: [
                [4, 'asc'],
                [1, 'asc'],
              ],
              columns: [
                {
                  data: null,
                  orderable: false,
                  render(data, row, col) {
                    const $container = $('<div />');

                    if (col.noteCount) {
                      $container.attr('data-request-id', col.id);
                      const $icon = $('<i/>');
                      $icon.addClass('icon icon-notes');

                      const $note = $('<a />');
                      $note
                        .addClass('tender_note view-history')
                        .attr('data-history-type', 'notes')
                        .append($icon);
                      $container.append($note);
                    }
                    return $container.prop('outerHTML');
                  },
                },
                {
                  data: null,
                  render(data, row, obj) {
                    const $link = $('<a />');
                    $link
                      .addClass('link vcard')
                      .text(obj.contact ? obj.contact.company.name : obj.user.account.name)
                      .attr('role', 'button');
                    if (obj.contact) {
                      $link.attr('data-company-id', obj.contact.company.id);
                      $link.attr('data-rfq-id', obj.id);
                    } else {
                      $link.attr('data-user-id', obj.user.id);
                      $link.attr('data-account-id', obj.user.account.id);
                      $link.attr('data-stage-id', stageId);
                    }
                    return $link.prop('outerHTML');
                  },
                },
                {
                  data: null,
                  render(data, row, obj) {
                    let firstName;
                    let lastName;
                    if (obj.contact) {
                      firstName = obj.contact.firstName ? obj.contact.firstName : '';
                      lastName = obj.contact.lastName ? obj.contact.lastName : '';
                    } else {
                      firstName = obj.user.firstName ? obj.user.firstName : '';
                      lastName = obj.user.lastName ? obj.user.lastName : '';
                    }

                    const contactName = `${firstName} ${lastName}`;

                    return contactName;
                    // const $link = $('<a />');
                    //
                    // $link
                    //   .addClass('link vcard')
                    //   .text(contactName)
                    //   .attr('role', 'button');
                    // if (obj.contact) {
                    //   $link.attr('data-company-id', obj.contact.company.id);
                    // } else {
                    //   $link.attr('data-account-id', obj.user.account.id);
                    // }
                    // return $link.prop('outerHTML');
                  },
                },
                {
                  data: null,
                  orderable: false,
                  render(data, row, obj) {
                    let phone;
                    if (obj.contact) {
                      if (obj.contact.company.phone) {
                        phone = obj.contact.company.phone;
                      } else if (obj.contact.phone) {
                        phone = obj.contact.phone;
                      } else {
                        phone = null;
                      }
                    } else {
                      phone = obj.user.office ? obj.user.office.phone : null;
                    }

                    return phone;

                    // const $link = $('<a />');
                    //
                    // $link.text(phone).attr('role', 'button');
                    // return $link.prop('outerHTML');
                  },
                },
                {
                  data: 'package.title',
                  render: $.fn.dataTable.render.text(),
                },
                {
                  data: null,
                  orderable: false,
                  render(data, type, row, details) {
                    const template = $placeholderRow.find('td').get(details.col);
                    const $main = $(template).find('.icons').clone();
                    const $content = $main.find('.dropdown').first();
                    let dirty = false;
                    let $error = -1;
                    let downloadedLastAtMoment = null;

                    $content.attr('data-request-id', row.id).attr('data-stage-id', stageId);

                    $main.attr('data-request-id', row.id);

                    if (row.downloadedLastAt !== null) {
                      row.response.push('downloaded');
                    }

                    if (row.notQuotingAt !== null) {
                      addStatus('declined', $main, row);
                      dirty = true;
                    }

                    if (row.hardCopyAt !== null) {
                      addStatus('hard_copy', $main, row);
                      dirty = true;
                    }

                    if (row.hasDocsAt !== null) {
                      addStatus('has-docs', $main, row);
                      dirty = true;
                    }

                    if (row.quotedAt !== null) {
                      addStatus('quoted', $main, row);
                      dirty = true;
                    }

                    if (row.quotingAt !== null) {
                      addStatus('quoting', $main, row);
                      dirty = true;
                    }

                    if (row.emailError) {
                      const $statusIcon = addStatusIcon(null, 'email_error');
                      $main.append($statusIcon);
                      dirty = true;
                      $error = true;
                    }

                    if (row.package.contentLastAddedAt !== null && row.downloadedLastAt !== null) {
                      const contentAddedMoment = moment.tz(
                        row.package.contentLastAddedAt.date,
                        row.package.contentLastAddedAt.timezone,
                      );
                      downloadedLastAtMoment = moment.tz(
                        row.downloadedLastAt.date,
                        row.downloadedLastAt.timezone,
                      );

                      if (contentAddedMoment.isAfter(downloadedLastAtMoment)) {
                        const $download = addStatusIcon($main, 'obsolete');
                        $main.find('.resend-addenda').show();
                        $download.text(
                          downloadedLastAtMoment.tz(getUserTimezone()).format('MMM D'),
                        );
                        $main.append($download);
                        dirty = true;
                      } else {
                        const $download = addStatusIcon($main, 'downloaded');
                        $download.text(
                          downloadedLastAtMoment.tz(getUserTimezone()).format('MMM D'),
                        );
                        $main.append($download);
                        dirty = true;
                      }
                    } else if (row.downloadedLastAt !== null) {
                      downloadedLastAtMoment = moment.tz(
                        row.downloadedLastAt.date,
                        row.downloadedLastAt.timezone,
                      );

                      const $download = addStatusIcon($main, 'downloaded');
                      $download.text(downloadedLastAtMoment.tz(getUserTimezone()).format('MMM D'));
                      $main.append($download);
                      dirty = true;
                    }

                    if (data.requestedChannel === REQUEST_E1) {
                      $main.prepend(addStatusIcon($main, 'e1-request'));
                    }

                    if (!dirty && $error === -1 && data.requestedChannel) {
                      $main.append(
                        "<span class='tag rel-status request_approved'>Rqst Approved</span>",
                      );
                    } else if (!dirty && $error === -1) {
                      $main.append("<span class='tag rel-status no_response'>No Response</span>");
                    }

                    if (row.contact && row.contact.company) {
                      $main
                        .find('.request-letting')
                        .attr('data-stage-id', stageId)
                        .attr('data-company-id', row.contact.company.id)
                        .attr('data-contact-id', row.contact.id);
                    }

                    if (!row.contact) {
                      $content.attr('data-user-id', row.user.id);
                    }
                    return $main.prop('outerHTML');
                  },
                },
                {
                  data: 'response[, ]',
                  visible: false,
                  render: $.fn.dataTable.render.text(),
                },
                {
                  data: null,
                  render(data, row, obj) {
                    if (obj.contact) {
                      return $.map(obj.contact.company.trades, (a, index) => [a.name]);
                    }
                    return [];
                  },
                  visible: false,
                },
              ],
              dom: 'Rrtp',
            });
            self.table = list.table;
            list.toggleTableDisplay(true);
            list.$target.closest('.loading-container').addClass('has-loaded');
          },
          Routing.generate('app_stagerfq_fetchapprovedrequests', { id: stageId }),
        );

        $container.on('click', '.view-history', function () {
          const _rid = $(this).closest('div').attr('data-request-id');
          const _history_type = $(this).attr('data-history-type');

          viewHistory(stageId, _rid, _history_type);
        });
        $container.on('click', '.request-letting', function () {
          const request_rid = $(this).closest('div').attr('data-request-id');

          const route = Routing.generate('app_constructionstageletting_addfromrfq', {
            id: constructionStage,
            rfq: request_rid,
          });
          const request = new E1Request(route);
          request.extraCallback = function (response) {
            if (response && response.success) {
              oldDataTable.updateTable(true);
            }
          };
          request.show_loading_modal = true;
          request.submit();
        });
        $container.on('click', '.rel-status.obsolete:not(.no-modal)', function () {
          const $ctn = $(this).closest('.icons');
          const requestId = $ctn.attr('data-request-id');
          const route = Routing.generate('app_stagerfq_diff', {
            id: stageId,
            rfqId: requestId,
          });
          const request = new E1Request(route);
          request.show_loading_modal = true;
          request.submit();
        });

        $container.on('click', '.add-to-project', (event) => {
          const $dataElement = $(event.currentTarget).closest('div.dropdown');
          const userId = $dataElement.attr('data-user-id');

          const addToDirectoryUrl = Routing.generate('app_constructionstagedirectory_addfromrfq', {
            id: constructionStage,
            rfq: $dataElement.data('request-id'),
          });

          if (typeof userId === 'undefined') {
            new E1Request(addToDirectoryUrl).submit();
          } else {
            // We want to remove the data-user-id for the row in question IFF/when the
            // confirmadduser form is submitted successfully, so we mark it now.
            $('[data-remove-if-successful="true"]').removeAttr('data-remove-if-successful');
            $dataElement.attr('data-remove-if-successful', true);
            new E1Request(
              Routing.generate('app_addressbookcontact_confirmadduser', {
                id: constructionStage,
                estimatingUser: userId,
                goal: 'add_to_directory',
                redirect: addToDirectoryUrl,
              }),
            ).submit();
          }
        });

        $body.on('submit', 'form.add-rfq-to-letting-form', function (e) {
          e.preventDefault();
          const $form = $(this);
          const magicForm = new Form($form);

          magicForm.extraCallback = function () {
            $body.trigger('update-rfq-table');
          };
          magicForm.submit();
          return false;
        });
        $body.on('change', '.stage-response-filter', function () {
          const $this = $(this);
          const status = $this.val();
          const filterCol = oldDataTable.table.column(6);

          if (status === 'all') {
            filterCol.search('');
          } else if (status === 'awaiting') {
            // When there is no status we must use a regex to search for an empty string
            filterCol.search('^$', true, false, false);
          } else if (status === 'has-docs') {
            // Display both HasDocs and downloaded
            filterCol.search('^(?=.*?(has-docs|downloaded)).*?', true, false, false);
          } else {
            filterCol.search(status);
          }
          oldDataTable.table.draw();
        });
      });
    }
  }

  $body.on('form-submitted-success', 'form.add-contact-to-project-form', (event, data) => {
    if (
      typeof data.awardedRfqId !== 'undefined' &&
      typeof data.stageId !== 'undefined' &&
      data.markAsLostRfqs
    ) {
      const route = Routing.generate('app_stagerfq_markaslost', {
        id: data.stageId,
        rfqId: data.awardedRfqId,
      });
      const request = new E1Request(route, 'GET', [], true);
      request.submit();
    }
  });

  $body.on('form-submitted-success', 'form[data-form-type="confirm-add-user"]', () => {
    $('[data-remove-if-successful="true"]').removeAttr('data-user-id data-remove-if-successful');
  });

  $body.on('form-submitted-success', 'form.mark-as-lost-rfq-form', () => {
    $(document).trigger('update-rfq-table');
  });
});
