import { useState, ReactNode, useCallback, useEffect } from 'react';
import { createGenericContext } from '@shared/context/createGenericContext';
import { useAccountContext } from '@subbie/context/AccountProvider';
import { useCurrentUser } from '@subbie/context/AccountProvider/hooks';
import { AccountContextQuery_currentUser_account_realUsers as RealUser } from '@subbie/context/AccountProvider/types/AccountContextQuery';
import useLocalStorage from '@ascension/components/hooks/LocalStorage';
import {
  ConnectionPreferenceFormValues,
  ConnectionPreferenceFormPage,
  ConnectionPreferenceField,
} from './types';
import { ConnectionPreferenceType, InterestLevel } from '@ascension/_gqltypes/subbie.generated';
import { GetConnectionPreference_connectionPreference as ConnectionPreference } from '@subbie/modal/ProjectSlider/ProjectSliderBody/types/GetConnectionPreference';

export type ConnectionPreferenceFormContextProps = {
  hasExistingPreference: boolean;
  preference: ConnectionPreference;
  pageIndex: number;
  page: ConnectionPreferenceFormPage;
  pageList: ConnectionPreferenceFormPage[];
  goToNextPage: () => void;
  goToFirstPage: () => void;
  initialValues: ConnectionPreferenceFormValues;
  setInitialValues: (values: ConnectionPreferenceFormValues) => void;
  setHideAddedToWatchlist: (hide: boolean) => void;
  hideAddedToWatchlist: boolean;
  showWatchlistPage: () => boolean;
  currentWatchlistStatus: InterestLevel | null;
};

const [useConnectionPreferenceFormContext, Provider] =
  createGenericContext<ConnectionPreferenceFormContextProps>('ConnectionPreferenceForm');

const isAlreadyTrackedOnWatchlist = (watchlistStatus: InterestLevel) =>
  [InterestLevel.INTERESTED, InterestLevel.QUOTING, InterestLevel.QUOTED].includes(watchlistStatus);

const pageList = [
  ConnectionPreferenceFormPage.ConnectionPreference,
  ConnectionPreferenceFormPage.WatchlistUpdated,
];

type ConnectionPreferenceFormProviderProps = {
  preference: ConnectionPreference;
  watchlistStatus: InterestLevel;
  children?: ReactNode;
};

const populateValues = (preference: ConnectionPreference, initialContact?: RealUser) => ({
  [ConnectionPreferenceField.Id]: preference.id,
  [ConnectionPreferenceField.ProjectId]: preference.projectId,
  [ConnectionPreferenceField.Type]:
    preference.deletedAt === null && preference.type
      ? preference.type
      : ConnectionPreferenceType.SPECIFIED,
  [ConnectionPreferenceField.Note]:
    preference.deletedAt === null && preference.note ? preference.note : undefined,
  [ConnectionPreferenceField.Contact]: initialContact && {
    value: initialContact.id,
    label: initialContact.fullName ?? '',
  },
});

export const ConnectionPreferenceFormProvider = ({
  children,
  preference,
  watchlistStatus,
}: ConnectionPreferenceFormProviderProps) => {
  const currentUser = useCurrentUser();
  const hasExistingPreference =
    preference?.deletedAt === null && typeof preference?.contactId === 'number';
  const [pageIndex, setPageIndex] = useState<number>(0);
  const { set: setHideAddedToWatchlist, value: hideAddedToWatchlist } = useLocalStorage<boolean>(
    'SSC_HideAddedToWatchlist',
  );
  const { realUsers: usersList } = useAccountContext();
  const initialContactUserId =
    preference.deletedAt !== null ? currentUser?.id : (preference.contactId ?? currentUser?.id);
  const initialContact = usersList.find((user) => user.id === initialContactUserId);

  const [initialValues, setInitialValues] = useState<ConnectionPreferenceFormValues>(
    populateValues(preference, initialContact),
  );

  useEffect(() => {
    setInitialValues(populateValues(preference, initialContact));
  }, [initialContact, preference]);

  const goToNextPage = useCallback(() => {
    if (pageIndex < pageList.length - 1) {
      setPageIndex(pageIndex + 1);
    }
  }, [pageIndex]);

  const goToFirstPage = useCallback(() => {
    setPageIndex(0);
  }, []);

  const showWatchlistPage = useCallback(
    () => !hasExistingPreference && !isAlreadyTrackedOnWatchlist(watchlistStatus),
    [hasExistingPreference, watchlistStatus],
  );

  const value: ConnectionPreferenceFormContextProps = {
    hasExistingPreference,
    preference,
    pageIndex,
    page: pageList[pageIndex],
    pageList,
    goToNextPage,
    goToFirstPage,
    initialValues,
    setInitialValues,
    setHideAddedToWatchlist,
    hideAddedToWatchlist: hideAddedToWatchlist ?? false,
    showWatchlistPage,
    currentWatchlistStatus: watchlistStatus,
  };
  return <Provider value={value}>{children}</Provider>;
};

export { useConnectionPreferenceFormContext };
