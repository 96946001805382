import { Button, ButtonVariant, Tag, TagVariant } from '@estimateone/frontend-components';
import { format } from 'date-fns';
import { showRfqEmailErrorModal } from '@ascension/js/app/utils/response_list.utils';
import { NotQuotingStatusTag } from '@builder/common/NotQuotingStatusTag';
import { QuotingStatusTag } from '@builder/common/QuotingStatusTag';
import { generateQuoteLinkFromRfq } from '../utils';
import { QuoteReturnRoute } from '@ascension/enums';
import { RfqQuotingStatus } from '@ascension/_gqltypes/builder.generated';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

export type LatestQuote = {
  id: EntityId;
  amount: number | null;
  updatedAt: GraphQLDateTime;
};

export type Rfq = {
  id: EntityId;
  quotingStatus: RfqQuotingStatus | null;
  notQuotingReasonText: string | null;
  latestQuote: LatestQuote | null;
  emailError: boolean;
};

export type QuotingStatusProps = {
  rfq: Rfq;
  returnTo: QuoteReturnRoute;
  openInNewTab?: boolean;
  onQuoteOpen?: () => void;
};

const QuoteStatusTag = ({ rfq, returnTo, openInNewTab, onQuoteOpen }: QuotingStatusProps) => {
  if (rfq.emailError && rfq.quotingStatus === null) {
    return null;
  }

  if (rfq.quotingStatus === RfqQuotingStatus.DECLINED && rfq.notQuotingReasonText) {
    return (
      <NotQuotingStatusTag
        className={styles.quotingStatus}
        uniqueId={rfq.id}
        reason={rfq.notQuotingReasonText}
      />
    );
  }

  const quoteLink = generateQuoteLinkFromRfq(rfq, returnTo);
  return quoteLink ? (
    <a
      href={quoteLink}
      onClick={onQuoteOpen}
      target={openInNewTab ? '_blank' : '_self'}
      rel="noreferrer"
    >
      <QuotingStatusTag
        className={styles.quotingStatus}
        quotingStatus={rfq.quotingStatus}
        showIcon
      />
    </a>
  ) : (
    <QuotingStatusTag className={styles.quotingStatus} quotingStatus={rfq.quotingStatus} />
  );
};

const EmailErrorTag = ({ stageId, rfq }: { stageId: EntityId; rfq: Rfq }) => {
  if (rfq.emailError) {
    return (
      <Button
        variant={ButtonVariant.Transparent}
        onClick={async () => {
          await showRfqEmailErrorModal(stageId.toString(), rfq.id.toString());
        }}
      >
        <Tag variant={TagVariant.Error} text="Email Error" />
      </Button>
    );
  }
  return null;
};

const QuoteStatusDate = ({ rfq }: { rfq: Rfq }) =>
  (rfq.latestQuote && rfq.quotingStatus === RfqQuotingStatus.QUOTED && (
    <p className={styles.quoteStatusTime}>
      {format(new Date(rfq.latestQuote.updatedAt), 'd MMM yyyy').toUpperCase()}
    </p>
  )) ||
  null;

export type ResponseStatusProps = QuotingStatusProps & {
  stageId: EntityId;
};

export const ResponseStatus = ({
  rfq,
  stageId,
  returnTo,
  openInNewTab = false,
  onQuoteOpen = () => null,
}: ResponseStatusProps) => (
  <>
    <div className={styles.tags}>
      <QuoteStatusTag
        rfq={rfq}
        returnTo={returnTo}
        openInNewTab={openInNewTab}
        onQuoteOpen={onQuoteOpen}
      />
      <EmailErrorTag stageId={stageId} rfq={rfq} />
    </div>
    <QuoteStatusDate rfq={rfq} />
  </>
);
