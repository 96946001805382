import { Tag, TagVariant } from '@estimateone/frontend-components';
import { HistoricalProjectActionMenu } from '@profiles/features/CompanyProfile/components/ProjectHistory/ProjectHistoryActions';
import { useTranslator } from '@profiles/providers/TranslationsProvider';
import { getDateMonthYearStringOrNull } from '../../../utils';
import { getCategoryLabel } from '../../Categories/utils';
import { ShowMoreOrLessContent } from '../../ShowMoreOrLessContent';
import { getContractSizeLabel } from '../ContractSizeSelect';
import styles from './ProjectHistoryCard.module.scss';
import { AvailableUponRequestText } from '@profiles/features/CompanyProfile/components/ProjectHistory/ContractSizeSelect/types';
import { HistoricalProject } from '@profiles/providers/ProfileProvider/types';

export type ProjectHistoryCardProps = {
  project: HistoricalProject;
  showActionMenu?: boolean;
};

const TRUNCATE_CHARACTER_LENGTH = 225;

export const ProjectHistoryCard = ({
  project,
  showActionMenu = false,
}: ProjectHistoryCardProps) => {
  const translate = useTranslator();

  const completionDate = getDateMonthYearStringOrNull(project.completionDate);
  const contractLabel = getContractSizeLabel(project.contractSize, translate);

  return (
    <div className={styles.cardContainer}>
      <div className={styles.cardHeader}>
        <div>
          <div className={styles.builderName}>{project.builderName}</div>
          <div className={styles.projectName}>{project.name}</div>
        </div>
        {showActionMenu && (
          <div>
            <HistoricalProjectActionMenu data={project} />
          </div>
        )}
      </div>
      <div className={styles.cardTags}>
        <Tag text={getCategoryLabel(project.category)} variant={TagVariant.Practical025} />
        {project.state?.shortName && (
          <Tag
            text={project.state?.shortName}
            variant={TagVariant.Plain}
            className={styles.historyTag}
          />
        )}
        <Tag
          text={completionDate ? `Completion: ${completionDate}` : 'Current Project'}
          variant={TagVariant.Plain}
          className={styles.historyTag}
        />
        {contractLabel && (
          <Tag
            text={`${contractLabel === AvailableUponRequestText ? contractLabel : `Contract: ${contractLabel}`}`}
            variant={TagVariant.Plain}
            className={styles.historyTag}
          />
        )}
      </div>
      {project.description && (
        <ShowMoreOrLessContent
          content={project.description}
          minContentSize={TRUNCATE_CHARACTER_LENGTH}
        />
      )}
    </div>
  );
};
