import { useCallback, useState, createContext, ReactNode } from 'react';
import { ID } from '@builder/common/SubbieNetwork/types';

export type AddCompanyContact = { companyType: string; contactId: ID };

export type CompanyContacts = { companyType: string; contactIds: ID[] };
export type SelectedCompanyContacts = Map<ID, CompanyContacts>;
export type SelectedContacts = Map<ID, SelectedCompanyContacts>;
export type TotalNumberOfSelectedContacts = () => number;
export type NumberOfSelectedContactsForPackage = (packageId: ID) => number;

type SelectedContactsContextProps = {
  isSelected: (packageId: ID, contactId: ID) => boolean;
  selectedContacts: SelectedContacts;
  resetSelectedContacts: () => void;
  resetSelectedContactsExcludingPackages: (packageIds: ID[]) => void;
  addContact: (packageId: ID, companyID: ID, contact: AddCompanyContact) => void;
  removeContact: (packageId: ID, companyID: ID, contactId: ID) => void;
  totalNumberOfSelectedContacts: TotalNumberOfSelectedContacts;
  numberOfSelectedContactsForPackage: NumberOfSelectedContactsForPackage;
};

export const SelectedContactsContext = createContext<SelectedContactsContextProps>({
  /* eslint-disable @typescript-eslint/no-unused-vars */
  isSelected: (packageId: ID, contactId: ID) => false,
  selectedContacts: new Map(),
  addContact: (packageId: ID, companyId: ID, companyContact: AddCompanyContact) => {},
  removeContact: (packageId: ID, companyId: ID, companyContactId: ID) => {},
  resetSelectedContacts: () => {},
  resetSelectedContactsExcludingPackages: (packageIds: ID[]) => {},
  totalNumberOfSelectedContacts: () => 0,
  numberOfSelectedContactsForPackage: (packageId: ID) => 0,
});

const { Provider } = SelectedContactsContext;

export const SelectedContactsProvider = ({ children }: { children: ReactNode }) => {
  const [selectedContacts, setSelectedContacts] = useState<Map<ID, SelectedCompanyContacts>>(
    new Map(),
  );

  const addContact = useCallback<SelectedContactsContextProps['addContact']>(
    (packageId: ID, companyID: ID, contact: AddCompanyContact) => {
      setSelectedContacts((prevPackageContacts) => {
        // This effectively clones our state which is what we need to trigger a render
        const nextPackageContacts = new Map(prevPackageContacts);

        const companyContacts =
          nextPackageContacts.get(packageId) ?? new Map<ID, CompanyContacts>();

        if (!companyContacts.has(companyID)) {
          companyContacts.set(companyID, {
            companyType: contact.companyType,
            contactIds: [contact.contactId],
          });
          nextPackageContacts.set(packageId, companyContacts);
          return nextPackageContacts;
        }

        // Using the Typescript "!" here because we know the companyID exists
        // this can be removed when we upgrade to Typescript 5.5
        const contactsNext = companyContacts.get(companyID)!;
        contactsNext.contactIds.push(contact.contactId);
        companyContacts.set(companyID, contactsNext);

        return nextPackageContacts.set(packageId, companyContacts);
      });
    },
    [],
  );

  const removeContact = useCallback((packageId: ID, companyID: ID, contactId: ID) => {
    setSelectedContacts((prevPackageContacts) => {
      // This effectively clones our state which is what we need to trigger a render
      const nextPackageContacts = new Map(prevPackageContacts);

      const companyContacts = nextPackageContacts.get(packageId);
      const contacts = companyContacts?.get(companyID);

      if (companyContacts === undefined || contacts === undefined) return nextPackageContacts;

      companyContacts.set(companyID, {
        ...contacts,
        contactIds: contacts.contactIds.filter((id) => id !== contactId),
      });

      return nextPackageContacts.set(packageId, companyContacts);
    });
  }, []);

  const resetSelectedContacts = useCallback(() => {
    setSelectedContacts(new Map());
  }, []);

  const resetSelectedContactsExcludingPackages = useCallback((excludedPackageIds: ID[]) => {
    setSelectedContacts((prevPackageContacts) => {
      const nextPackageContacts = new Map(prevPackageContacts);
      const packageIdsToReset = Array.from(nextPackageContacts.keys()).filter(
        (packageId) => !excludedPackageIds.includes(packageId),
      );

      packageIdsToReset.forEach((packageId) => {
        nextPackageContacts.delete(packageId);
      });

      return nextPackageContacts;
    });
  }, []);

  const numberOfSelectedContactsForPackage = (packageId: ID) => {
    const companiesForPackage = selectedContacts.get(packageId);

    if (companiesForPackage === undefined) return 0;

    return Array.from(companiesForPackage.values()).reduce(
      (sum, arr) => sum + arr.contactIds.length,
      0,
    );
  };

  const totalNumberOfSelectedContacts = () => {
    const packageIds = Array.from(selectedContacts.keys());

    return Number(
      packageIds.reduce(
        (acc: number, packageId: ID): number => acc + numberOfSelectedContactsForPackage(packageId),
        0,
      ),
    );
  };

  const isSelected = useCallback(
    (packageId: ID, contactId: ID) => {
      const companyContacts = selectedContacts.get(packageId);

      if (companyContacts === undefined) return false;

      const containing = Array.from(companyContacts.values()).find((company) =>
        company.contactIds.includes(contactId),
      );

      if (containing !== undefined) return true;

      return false;
    },
    [selectedContacts],
  );

  const value: SelectedContactsContextProps = {
    isSelected,
    selectedContacts,
    addContact,
    removeContact,
    resetSelectedContacts,
    resetSelectedContactsExcludingPackages,
    totalNumberOfSelectedContacts,
    numberOfSelectedContactsForPackage,
  };

  return <Provider value={value}>{children}</Provider>;
};
