import $ from 'jquery';
import Routing from 'routing';
import moment from 'moment-timezone';
import GenericList from '../classes/GenericList';
import { TimezoneAwareDateHelpers } from '../utils/date';
import { truncateMiddle } from '../utils/truncate';
import { StageStatus, StageType } from '../../enums';
import {
  appendAwardedIndicator,
  extractTemplate,
  setTenderStageLinkHref,
  setTooltipForRow,
  updateDataTableListeners,
} from './stage_table_helper';

const createRendererForTenderProjectName = (list) => (data, display, rowData, meta) => {
  const {
    id,
    private: isPrivate,
    retender,
    type,
    hasTeamAccessControl,
    hasAccess = true,
  } = rowData;
  const col = meta.col;
  const $template = extractTemplate(list, col);
  const $title = $('<strong />').text(truncateMiddle(data, 65));

  appendTenderLabel($title, $template, isPrivate, 'private-label');
  appendTenderLabel($title, $template, retender, 'retender-label');
  appendTenderLabel($title, $template, hasTeamAccessControl, 'restricted-label');

  const $options = $template.find('.project_options');
  updateTenderOptionsLinks($options, type, id, hasAccess);

  return $title.prop('outerHTML') + $options.prop('outerHTML');
};

const appendTenderLabel = ($title, $template, condition, labelClass) => {
  if (condition) {
    const $label = $template.find(`span.${labelClass}`);
    $title.append(`&nbsp;${$label.prop('outerHTML')}`);
  }
};

const updateTenderOptionsLinks = ($options, type, id, hasAccess) => {
  $options.find('.tab-link').each((i, link) => {
    if (type === StageType.TYPE_PROCUREMENT && link.innerText === 'Dashboard') {
      link.innerText = 'Schedule';
    }
    setTenderStageLinkHref($(link), id, type, hasAccess);
  });
};

const createRendererForTenderStatus = (list) => (data, display, rowData, meta) => {
  const { id, type, hasAccess = true } = rowData;
  const col = meta.col;
  const $template = extractTemplate(list, col);

  removeTenderRedundantSelect($template, type);
  updateTenderStatusSelect($template, data, id, hasAccess);

  return $template.html();
};

const removeTenderRedundantSelect = ($template, type) => {
  const selectClass =
    type === StageType.TYPE_TENDER ? 'procurement_status_modifier' : 'stage_status_modifier';
  const $redundantSelect = $template.find(`select.${selectClass}`);
  $redundantSelect.remove();
};

const updateTenderStatusSelect = ($template, data, id, hasAccess) => {
  const $select = $template.find('select');
  $select.val(data).attr('data-stage-id', id);
  const $selected = $select.find(`option[value=${data}]`);
  $selected.attr('selected', 'selected');
  if (!hasAccess) {
    $select.attr('disabled', true);
  }
};

const createRendererForTenderQuotesDue = (list) => (data, display, rowData, meta) => {
  const { id, hasAccess = true } = rowData;
  const col = meta.col;

  if (data) {
    const quoteDate = moment(data.date);
    const $template = extractTemplate(list, col);

    if (display === 'display') {
      updateTenderQuoteDisplay($template, quoteDate, id, hasAccess);
      return $template.html();
    }

    return quoteDate.format('X');
  }
  return '';
};

const updateTenderQuoteDisplay = ($template, quoteDate, id, hasAccess) => {
  const $input = $template.find('input.hidden_datetime_lazy');
  const dateClass = TimezoneAwareDateHelpers.getQuotesDueClassFromDate(quoteDate.format());
  const dateString = TimezoneAwareDateHelpers.getDateStringTodayTomorrow(
    quoteDate.format(),
    'ddd, MMM D YYYY',
    true,
  );
  const $dateCtn = $input.closest('.date');
  $dateCtn.addClass(dateClass);

  $input
    .attr('data-stage-id', id)
    .attr('data-date-format', 'ddd, MMM D YYYY')
    .attr('data-date-autoclose', true)
    .attr('value', dateString)
    .val(dateString);

  if (!hasAccess) {
    const $changeDateLink = $template.find('a.change_date');
    $changeDateLink.removeClass();
    $changeDateLink.addClass('disabled-link');
  }
};

const createRendererForTenderProjectActivity =
  (list, isProcurementPhaseView) => (docCount, display, rowData, meta) => {
    const {
      id,
      quotesCount,
      invitedRfqs: inviteCount,
      requestedRfqs: requestCount,
      type,
      status,
      linkedProcurementStageId,
      hasAccess = true,
    } = rowData;
    const col = meta.col;

    const $template = extractTemplate(list, col);
    $template.find('a').each((i, link) => {
      setTenderStageLinkHref($(link), id, type, hasAccess);
    });

    if (linkedProcurementStageId && $template.find('.procurement-started-message').length) {
      return $template.find('.procurement-started-message').prop('outerHTML');
    }

    if (
      $template.find('.procurement-prompt').length &&
      type === StageType.TYPE_TENDER &&
      status === StageStatus.TENDER_AWARDED
    ) {
      appendAwardedIndicator($template.find('.procurement-prompt'));
      return $template.find('.procurement-prompt').prop('outerHTML');
    }

    if (docCount) {
      return renderActivityDocs($template, isProcurementPhaseView, {
        invites: inviteCount,
        requests: requestCount,
        quotes: quotesCount,
      });
    }

    return $template.find('.no-docs').prop('outerHTML');
  };

const renderActivityDocs = ($template, isProcurementPhaseView, counts) => {
  const sectionsCounts = new Map(
    Object.entries(counts).filter(([key]) => {
      if (isProcurementPhaseView && key === 'requests') {
        return false;
      }
      return true;
    }),
  );

  const $activitySection = $template.find('.has-docs');
  for (let [sectionClass, count] of sectionsCounts) {
    const $countSection = $activitySection.find(`.activity-link.${sectionClass}`);
    $countSection.find('.count').text(count);
    if (parseInt(count, 10) === 1) {
      const $label = $countSection.find('.section-label');
      $label.text($label.data('singular'));
    }
  }

  return $activitySection.prop('outerHTML');
};

$(() => {
  const $currentTenderTable = $('table.current-tender-table');

  const defineColumns = (list, isProcurementPhaseView) => {
    const columns = [
      {
        data: 'projectName',
        render: createRendererForTenderProjectName(list),
      },
      {
        data: 'status',
        class: 'status',
        render: createRendererForTenderStatus(list),
      },
      {
        data: 'tenderQuotesDue',
        class: 'quotes_due',
        render: createRendererForTenderQuotesDue(list),
      },
      {
        data: 'doc_count',
        class: 'project_activity',
        render: createRendererForTenderProjectActivity(list, isProcurementPhaseView),
      },
    ];

    return isProcurementPhaseView
      ? columns.filter((column) => column.data !== 'tenderQuotesDue')
      : columns;
  };

  const defineDataTableSettings = (list, columns, isProcurementPhaseView, $table) => {
    const PROJECT_NAME = 0;
    const QUOTES_DUE = 2;
    const emptyTableText = `You don't have any current ${
      isProcurementPhaseView ? 'procurement' : 'tender'
    } projects on E1.`;

    return {
      paging: false,
      data: list.data,
      lengthChange: false,
      info: false,
      order: [[isProcurementPhaseView ? PROJECT_NAME : QUOTES_DUE, 'asc']],
      columnDefs: [
        {
          targets: 'no-sort',
          orderable: false,
        },
      ],
      columns: columns,
      dom: 'Rrtp',
      language: {
        emptyTable: emptyTableText,
      },
      createdRow: function (row, data, dataIndex) {
        if (data.hasAccess === false) {
          setTooltipForRow($table.data('access-restricted-tooltip'), row);
        }
      },
    };
  };

  const attachEvents = (projectTable) => {
    $(document).on('stage-archive-changed stage_status_updated', document, () => {
      projectTable.updateTable(true);
    });
  };

  if ($currentTenderTable.length > 0) {
    $currentTenderTable.each(function () {
      const $table = $(this);
      const isProcurementPhaseView = $table.data('is-procurement-phase-view');
      const url = Routing.generate($table.data('fetch-projects-route-name'));

      const projectTable = new GenericList(
        $table,
        (list) => {
          const columns = defineColumns(list, isProcurementPhaseView);
          const dataTableSettings = defineDataTableSettings(
            list,
            columns,
            isProcurementPhaseView,
            $table,
          );
          list.table = list.$target.DataTable(dataTableSettings);
          list.$target.closest('.loading-container').addClass('has-loaded');
          updateDataTableListeners(list.$target);
        },
        url,
        (data) => data.stages,
      );

      attachEvents(projectTable);
    });
  }
});
