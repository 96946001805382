import { useMutation } from '@apollo/client';
import { Profile } from '@profiles/apps/CompanyProfile';
import { useCompanyProfileCacheItemId } from '@profiles/providers/ProfileProvider';
import { EDIT_DIRECTORY_OPT_IN_SETTING } from './mutations';
import {
  EditDirectoryOptInSettingMutation,
  EditDirectoryOptInSettingMutationVariables,
} from './mutations.generated';

type Options = Parameters<
  typeof useMutation<EditDirectoryOptInSettingMutation, EditDirectoryOptInSettingMutationVariables>
>[1];

export const useSubmitDirectoryOptIn = (opts?: Options) => {
  const id = useCompanyProfileCacheItemId();

  const [editDirectoryOptIn, { error }] = useMutation<
    EditDirectoryOptInSettingMutation,
    EditDirectoryOptInSettingMutationVariables
  >(EDIT_DIRECTORY_OPT_IN_SETTING, {
    optimisticResponse: ({ optIn }) => ({
      editDirectoryOptInSetting: {
        id: 999_999,
        attribute: 'builder_directory_opt_in_status',
        value: optIn ? 1 : 0,
        success: true,
        errors: [],
        __typename: 'EditIntAccountSettingResponse',
      },
    }),
    update: (cache, { data }) => {
      if (data?.editDirectoryOptInSetting.success) {
        cache.modify<Profile>({
          id,
          fields: {
            isOptedIntoDirectory: () => data.editDirectoryOptInSetting.value === 1,
          },
        });
      }
    },
    ...opts,
  });

  const submit = (isOptingIn: boolean) =>
    editDirectoryOptIn({
      variables: {
        optIn: isOptingIn,
      },
    });

  return { submit, error: error ?? undefined };
};
