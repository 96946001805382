import { useReducer } from 'react';
import { DeleteIcon } from '@estimateone/frontend-components';
import { CompanyLogo } from '../CompanyLogo';
import styles from './DeleteCompanyLogo.module.scss';
import { DeleteCompanyLogoConfirmation } from './DeleteCompanyLogoConfirmation';
import { UnstyledButton } from '@profiles/features/CompanyProfile/components/UnstyledButton';

export const DeleteCompanyLogo = ({ onLogoDeleted }: { onLogoDeleted: () => void }) => {
  const [isDeleteLogoModalOpen, toggleDeleteLogoModal] = useReducer(
    (curr: boolean) => !curr,
    false,
  );

  return (
    <>
      <UnstyledButton
        onClick={toggleDeleteLogoModal}
        className={styles.deleteLogoButton}
        data-testid="delete-logo"
      >
        <CompanyLogo />
        <div className={styles.deleteCompanyLogoContainer}>
          <DeleteIcon className={styles.deleteIcon} />
          <p className={styles.text}>Remove</p>
        </div>
      </UnstyledButton>
      <DeleteCompanyLogoConfirmation
        isOpen={isDeleteLogoModalOpen}
        toggleModal={toggleDeleteLogoModal}
        onLogoDeleted={onLogoDeleted}
      />
    </>
  );
};
