import { ReactElement } from 'react';
import { getStageTypeAsString } from '@ascension/enums';
import { DisplayNotification, Notification, NotificationData } from '../types';
import { NotificationDataKV } from './types';

export const appendOrCreate = <K, V>(map: Map<K, V[]>, key: K, value: V) => {
  const newValue = map.get(key)?.concat([value]) ?? [value];

  return map.set(key, newValue);
};

export const buildDisplayNotification = (
  key: string,
  { createdAt, viewedAt }: Notification,
  text: ReactElement,
  link: string | null,
): DisplayNotification => ({
  key,
  createdAt,
  isUnread: viewedAt === null,
  text,
  link: link ?? undefined,
});

export const formatData = (kvPairs: NotificationData[]): NotificationDataKV =>
  kvPairs.reduce((data: NotificationDataKV, { name, value }) => {
    data.set(name, value);
    return data;
  }, new Map());

export const getStageTypeForRouteWithLegacyNotificationDefault = (stageType: number): string =>
  getStageTypeAsString(stageType) ?? 'tender';
