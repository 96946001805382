import { ClearIcon } from '@estimateone/frontend-components';
import styles from './styles.module.scss';

export const NoCreditsGraphic = () => (
  <div className={styles.roadblockGraphicContainer}>
    <div className={styles.graphicIconContainer}>
      <div className={styles.graphicIcon}>
        <ClearIcon />
      </div>
      <p>
        <strong>No credits</strong>
      </p>
      <div className={styles.redactedBlock} />
      <div className={styles.redactedBlock} />
    </div>
  </div>
);
