import Routing from 'routing';
import { E1Link, LockIcon } from '@estimateone/frontend-components';
import { formatDateForStageRow } from '../util';
import { Action, useInteractAnalytics } from '../../../../../../../hooks/Analytics';
import { useCanAccountUpgrade } from './hooks';
import styles from '../styles.scss';

type ConstructionDatesProps = {
  start: GraphQLDateTime | null;
  end: GraphQLDateTime | null;
};

const ConstructionDates = ({ start, end }: ConstructionDatesProps) => {
  const isAccountEligibleForUpgrade = useCanAccountUpgrade();

  const hasConstructionStartOrEndDate = start !== null || end !== null;

  const { addEvent: addInteractEvent } = useInteractAnalytics();
  const addUpgradeClickEvent = () =>
    addInteractEvent({ action: Action.CONSTRUCTION_DATES_PAYWALL_CLICKED });

  if (!hasConstructionStartOrEndDate) return null;

  if (isAccountEligibleForUpgrade) {
    return (
      <div className={styles.constructionStartEndPaywalled}>
        <LockIcon />
        <span className={styles.estDatesText}>Est. construction dates</span>
        <br />
        <span className={styles.upgradeLink}>
          <E1Link
            onClick={() => {
              addUpgradeClickEvent();
              window.location.assign(Routing.generate('app_accountupgrade_index'));
            }}
            href="#"
          >
            Upgrade to unlock
          </E1Link>
        </span>
      </div>
    );
  }

  return (
    <div className={styles.constructionStartEnd}>
      {start !== null && (
        <p className={styles.start}>
          <span>Est. Start Date</span>
          <span className={styles.date}>{formatDateForStageRow(start)}</span>
        </p>
      )}
      {end !== null && (
        <p className={styles.end}>
          <span>Est. End Date</span>
          <span className={styles.date}>{formatDateForStageRow(end)}</span>
        </p>
      )}
    </div>
  );
};

export default ConstructionDates;
