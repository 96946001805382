import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_TENDER_RESPONSES_FOR_PACKAGE } from './queries';
import { EntityId } from '../../../../../../../../types';
import {
  GetTenderResponsesForPackage,
  GetTenderResponsesForPackageVariables,
} from './types/GetTenderResponsesForPackage';

export const useTenderResponsesForPackage = (packageId?: EntityId) => {
  const [loadRfqs, { data, loading }] = useLazyQuery<
    GetTenderResponsesForPackage,
    GetTenderResponsesForPackageVariables
  >(GET_TENDER_RESPONSES_FOR_PACKAGE);

  useEffect(() => {
    if (packageId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      loadRfqs({ variables: { packageId } });
    }
  }, [packageId, loadRfqs]);

  return {
    rfqs: data?.package.activeRfqs,
    loading,
  };
};
