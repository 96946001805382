import { joinClassNames } from '@estimateone/frontend-components';
import PlaceholderCompanyProfileLogo from '@ascension/images/placeholderCompanyProfileLogo.png';
import styles from './CompanyLogo.module.scss';
import Image from '@shared/Image';

export enum CompanyLogoSize {
  SMALL,
  REGULAR,
}

type CompanyLogoProps = {
  url: string | null;
  companyName?: string;
  size?: CompanyLogoSize;
};

export const CompanyLogo = ({
  url,
  companyName,
  size = CompanyLogoSize.REGULAR,
}: CompanyLogoProps) => (
  <div
    className={joinClassNames(
      styles.logoContainer,
      size === CompanyLogoSize.SMALL ? styles.logoSizeSmall : styles.logoSizeRegular,
    )}
  >
    <object
      className={size === CompanyLogoSize.SMALL ? styles.logoSmall : styles.logoRegular}
      data={url ?? PlaceholderCompanyProfileLogo}
      type="image/png"
      data-testid="company-logo"
    >
      <Image
        className={size === CompanyLogoSize.SMALL ? styles.logoSmall : styles.logoRegular}
        alt={companyName ? `${companyName} logo` : 'no logo'}
        src={PlaceholderCompanyProfileLogo}
      />
    </object>
  </div>
);
