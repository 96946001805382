import { LockIcon } from '@estimateone/frontend-components';
import styles from './styles.module.scss';

/** SUBBIE_CREDIT_EXPERIMENT_ALPHA_CODE */
export const LitePlanGraphicSection = () => (
  <div className={styles.roadblockGraphicContainer}>
    <div className={styles.graphicIconContainer}>
      <div className={styles.graphicIcon}>
        <LockIcon className={styles.lockIcon} />
      </div>
      <p>
        <strong>Ten tenders (Lite)</strong>
      </p>
      <div className={styles.redactedBlock} />
      <div className={styles.redactedBlock} />
    </div>
  </div>
);
