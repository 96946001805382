import $ from 'jquery';
import Routing from 'routing';
import E1Request, { E1Response } from '../classes/E1Request';
import Form from '../classes/Form';
import Throttler from '../classes/Throttler';
import { isValidKeyup } from '../utils/helpers';
import DocumentList from './documents-list';

type SupersedeSearchResponse = {
  documents: string; // JSON string of SuggestedDocument[]
} & E1Response;

type SuggestedDocument = {
  id: string;
  title: string;
  directory_name: string;
  drawing_id: string;
  revision: string;
};

export default class SupersedeModal {
  $target: JQuery<HTMLElement>;
  stageId: number;
  docId: number | null = null;
  documentList: DocumentList; // TODO Define this type more accurately
  throttler: Throttler;
  route: string;
  form: Form<E1Response> | null;
  suggestions: SuggestedDocument[]; // TODO Define this type more accurately
  searched: boolean;
  $preMatches: JQuery<HTMLElement>;
  $postMatches: JQuery<HTMLElement>;
  $listItemTemplate: JQuery<HTMLElement>;
  $emptyMatches: JQuery<HTMLElement>;
  $loadingContainer: JQuery<HTMLElement>;
  $docInput: JQuery<HTMLElement>;
  $docLists: JQuery<HTMLElement>;
  $diffLoading: JQuery<HTMLElement>;
  $diffImageText: JQuery<HTMLElement>;
  $diffImageContainer: JQuery<HTMLElement>;

  constructor(
    $target: JQuery<HTMLElement>,
    stageId: number,
    docId: string,
    documentList: DocumentList,
  ) {
    this.$target = $target;
    this.stageId = stageId;
    this.throttler = new Throttler();
    this.route = Routing.generate('app_stagedocument_searchsupersede', {
      id: stageId,
      docId,
    });
    this.form = null;
    this.suggestions = [];
    this.searched = false;
    this.documentList = documentList;
    this.$preMatches = this.$target.find('.pre-matches');
    this.$postMatches = this.$target.find('.post-matches');
    this.$listItemTemplate = this.$postMatches.find('.list-item-template').clone();
    this.$emptyMatches = this.$target.find('.empty-search-results');
    this.$loadingContainer = this.$target.find('.loading-container');
    this.$docInput = this.$target.find('input.superseded-document-input');
    this.$docLists = this.$target.find('.docLists');
    this.$diffLoading = this.$target.find('.diff-loading');
    this.$diffImageText = this.$target.find('.diff-image-text');
    this.$diffImageContainer = this.$target.find('.diff-image-container');

    this.init();

    this.$target.on('click', '.document-supersede-trigger', async ({ currentTarget }) => {
      if (!this.form) {
        return;
      }
      const supersededDocId = $(currentTarget).data('doc-id');
      this.$docInput.val(parseInt(supersededDocId));
      const response = await this.form.submit();
      $('body').trigger('supersede-form-submitted', [this.form, response]);
    });

    this.$target.on('click', '.document-compare-trigger', async ({ currentTarget }) => {
      const documentStageId = $(currentTarget).data('stage-id');
      const origId = $(currentTarget).data('orig-id');
      const origTitle = $(currentTarget).data('orig-title');
      const compId = $(currentTarget).data('comp-id');
      const compTitle = $(currentTarget).data('comp-title');

      const { link } = await new E1Request<
        { success: true; link: string },
        { origId: number; compId: number }
      >(
        Routing.generate('app_stagedocument_diff', { id: documentStageId, origId, compId }),
      ).submit();

      this.$diffImageContainer.removeClass('hide');
      this.$docLists.addClass('hide');

      $('#diff_img')
        .attr('src', link)
        .on('load', () => {
          this.$diffLoading.hide();
          this.$diffImageText.html(
            `<small>Comparing <strong>${origTitle}</strong> with <strong>${compTitle}</strong></small>`,
          );
        });
    });

    this.$target.on('click', '.close-diff-trigger', () => {
      this.$diffImageContainer.addClass('hide');
      this.$diffImageText.text('');
      this.$docLists.removeClass('hide');
      this.$diffLoading.show();
    });
  }

  init() {
    this.form = new Form(this.$target);
    this.form.extraCallback = () => {
      this.documentList.updateTable(true);
    };
    this.$target.on('keyup', '.supersede-search', ({ charCode, keyCode, currentTarget }) => {
      const val = $(currentTarget).val()!.toString();

      const keyup = keyCode || charCode;
      if (isValidKeyup(keyup)) {
        this.toggleLoading(true);
        if (val.length > 2) {
          const searchRequest = new E1Request<SupersedeSearchResponse>(this.route, 'POST', {
            query: val,
          });
          this.throttler.add(async () => {
            const resp = await searchRequest.submit();
            this.suggestions = [];
            this.searched = true;
            if (typeof resp.documents !== 'undefined') {
              this.suggestions = JSON.parse(resp.documents);
            }
            this.update();
          });
        } else {
          this.suggestions = [];
          this.searched = false;
          this.update();
        }
      }
    });

    this.$target.on('keydown', '.supersede-search', ({ currentTarget }) => {
      const val = $(currentTarget).val()!.toString().toLowerCase();

      if (val.length <= 2) {
        this.suggestions = [];
        this.searched = false;
        this.update();
      }
    });
  }

  update() {
    const $ctn = $('<tbody />');
    this.suggestions.forEach((doc) => {
      const $tr = this.$listItemTemplate.clone();
      const directory = doc.directory_name || '/';
      const drawingId = doc.drawing_id ? doc.drawing_id : '-';
      const revision = doc.revision ? doc.revision : '-';

      $tr.find('.directory').text(directory);
      $tr.find('.drawing-id').text(drawingId);
      $tr.find('.revision').text(revision);
      $tr
        .find('.file-title')
        .attr('data-stage-id', this.stageId)
        .attr('data-document-id', doc.id)
        .text(doc.title);

      $tr.find('.document-supersede-trigger').attr('data-doc-id', doc.id);
      $tr.find('.document-compare-trigger').attr('data-comp-id', doc.id);
      $tr.find('.document-compare-trigger').attr('data-comp-title', doc.title);

      $ctn.append($tr);
    });

    this.$postMatches.find('.supersede-table-body').html($ctn.html());

    this.toggleLoading(false);
    if (this.searched) {
      if (this.suggestions.length > 0) {
        this.$emptyMatches.addClass('hide');
        this.$preMatches.addClass('hide');
        this.$postMatches.removeClass('hide');
      } else {
        this.$postMatches.addClass('hide');
        this.$preMatches.addClass('hide');
        this.$emptyMatches.removeClass('hide');
      }
    } else {
      this.$postMatches.addClass('hide');
      this.$emptyMatches.addClass('hide');
      this.$preMatches.removeClass('hide');
    }
  }

  toggleLoading(enable: boolean) {
    if (enable) {
      this.$preMatches.addClass('hide');
      this.$postMatches.addClass('hide');
      this.$emptyMatches.addClass('hide');
      this.$loadingContainer.removeClass('hide');
    } else {
      this.$loadingContainer.addClass('hide');
    }
  }
}
