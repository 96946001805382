import { useLazyQuery } from '@apollo/client';
import { GET_MATCHING_PROFILES } from '@subbie-directory/queries';
import {
  SearchMatchingProfiles,
  SearchMatchingProfilesVariables,
} from '@subbie-directory/types/SearchMatchingProfiles';

export const useSearchForMatchingAddressBookCompany = () => {
  const [searchMatchingCompanies, { loading }] = useLazyQuery<
    SearchMatchingProfiles,
    SearchMatchingProfilesVariables
  >(GET_MATCHING_PROFILES);

  return {
    searchMatchingCompanies,
    searchMatchingCompaniesLoading: loading,
  };
};
