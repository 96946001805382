import { MouseEvent, createRef, useState, useEffect, useCallback } from 'react';
import ScrollSpy from 'react-scrollspy';
import { joinClassNames } from '@estimateone/frontend-components';
import { FlowEvent, getAppcues } from '@ascension/components/helpers/Appcues';
import ProjectNotesExperiment from '@subbie/modal/ProjectSlider/ProjectSliderBody/ProjectNotesExperiment';
import { ProjectSubbies } from '@subbie/modal/ProjectSlider/ProjectSliderBody/ProjectSubbies';
import gtmDataLayerPush from '../../../../../js/utils/google_tag_manager_helper';
import ProjectConsultants from './ProjectConsultants';
import ProjectDescription from './ProjectDescription';
import ProjectLogs from './ProjectLogs';
import ProjectNotesForm from './ProjectNotesForm';
import ProjectStages from './ProjectStages';
import ProjectStockTrades from './ProjectStockTrades';
import { ProjectSuppliers } from './ProjectSuppliers';
import { NewTag } from '@shared/NewTag';
import { fireAndForget } from '@shared/Util/async';
import { PROJECT_NOTE_COUNT_CHANGE } from '@subbie/context/NoteCountProvider';
import { Action, Event, useAnalytics } from '../../../../hooks/Analytics';
import {
  ScrollSpySection,
  TabSuffixes,
} from '@subbie/modal/ProjectSlider/hooks/useScrollSpySections';
import { GetProjectSliderData_projectForSlider_notes as ProjectNote } from '../../types/GetProjectSliderData';
import { EntityId, ProjectWithNotesLogs, StockTrade, User } from '../types';
import { InterestLevel } from '@ascension/_gqltypes/subbie.generated';
import styles from './styles.scss';

type ProjectSliderBodyProps = ProjectWithNotesLogs & {
  uniqueProjectStockTrades: StockTrade[];
  userStockTradeIds: Set<EntityId>;
  scrollSpySections: ScrollSpySection[];
  user: User;
};

const ProjectSliderBody = ({
  project,
  uniqueProjectStockTrades,
  userStockTradeIds,
  projectNotes,
  projectLogs,
  projectSubbieSummary,
  scrollSpySections,
  user,
}: ProjectSliderBodyProps) => {
  const [notes, setNotes] = useState(projectNotes);
  const { addEvent: addInteractEvent } = useAnalytics(Event.INTERACT);
  const sectionTabSuffixes: Set<TabSuffixes> = new Set(
    scrollSpySections.map(({ tabSuffix }) => tabSuffix),
  );

  const {
    id: projectId,
    name: projectName,
    address,
    maxTenderBudget,
    stageCategoryName,
    hasDocs: nullableHasDocs, // TODO: Why?
  } = project;

  const { id: userId } = user;
  const hasDocs = nullableHasDocs || false;

  const handleOpenCloseAnalyticsEvent = useCallback(
    (open: boolean) => {
      addInteractEvent({
        action: open ? Action.PROJECT_SLIDER_OPEN : Action.PROJECT_SLIDER_CLOSE,
        projectId,
        hasDocs,
      });
      const appcues = getAppcues();
      if (appcues?.track) {
        appcues.track(FlowEvent.DISCO_ONBOARDING_PROJECT_SLIDER, {
          interestLevel: project.watchlistEntry?.status ?? InterestLevel.UNACTIONED,
          isOpen: open,
        });
      }
    },
    [addInteractEvent, projectId, hasDocs, project.watchlistEntry],
  );

  const handleTabAnalyticsEvent = useCallback(
    async (tabSuffix: string) =>
      addInteractEvent({
        action: Action.PROJECT_SLIDER_TAB,
        tab: `project_${tabSuffix}`,
        projectId,
      }),
    [addInteractEvent, projectId],
  );

  const handleTabSelection = async (tabSuffix: string, event: MouseEvent, element: string) => {
    event.preventDefault();
    document.querySelector(element)?.scrollIntoView({
      behavior: 'smooth',
    });
    await handleTabAnalyticsEvent(tabSuffix);
  };

  useEffect(() => {
    fireAndForget(async () => {
      gtmDataLayerPush({
        event: 'project-slider-open',
        userId,
        projectId,
        projectName,
        projectAddress: address?.fullAddress,
        projectBudget: maxTenderBudget,
        projectCategory: stageCategoryName,
      });

      await handleOpenCloseAnalyticsEvent(true);
    });

    return () => handleOpenCloseAnalyticsEvent(false);
  }, [
    handleOpenCloseAnalyticsEvent,
    address?.fullAddress,
    maxTenderBudget,
    stageCategoryName,
    projectId,
    projectName,
    userId,
  ]);

  const handleAddProjectNote = useCallback(
    (projectNote: ProjectNote) => {
      document.dispatchEvent(
        new CustomEvent(PROJECT_NOTE_COUNT_CHANGE, {
          detail: { id: project.id },
        }),
      );
      setNotes([projectNote, ...notes]);
    },
    [notes, project],
  );

  const handleDeleteProjectNote = useCallback(
    (projectNote: ProjectNote) => {
      document.dispatchEvent(
        new CustomEvent(PROJECT_NOTE_COUNT_CHANGE, {
          detail: { id: project.id },
        }),
      );
      setNotes(notes.filter(({ id }) => id !== projectNote.id));
    },
    [notes, project],
  );

  const sections = scrollSpySections.map(({ tabSuffix }) => `project-${tabSuffix}`);

  /* Safari scroll issue fix
     This component is currently used together with the SlidingPane,
     somehow the CSS animation is affect the scroll event in the #scrollSpyRoot
     making users unable to scroll in safari. Updating the height property on the div fixes this.
     The timeout is set to trigger after the animations finishes, running this while is animating
     doesn't fix the problem.
  */
  const scrollSpyRef = createRef<HTMLDivElement>();

  useEffect(() => {
    const setScrollSpyHeight = (height: string, timeout: number) =>
      new Promise<void>((resolve) => {
        setTimeout(() => {
          if (scrollSpyRef.current) {
            scrollSpyRef.current.style.height = height;
          }
          return resolve();
        }, timeout);
      });
    fireAndForget(async () => {
      await setScrollSpyHeight('100%', 300);
      await setScrollSpyHeight('auto', 10);
    });
  }, [scrollSpyRef]);
  // End safari fix

  return (
    <>
      <div id="project-slider-body" className={styles.navContainer}>
        <ScrollSpy
          rootEl="#scrollSpyRoot"
          items={sections}
          currentClassName="active"
          className={joinClassNames(
            'nav',
            'nav-tabs',
            'mb-0',
            (sectionTabSuffixes.has('subbies') || sectionTabSuffixes.has('suppliers')) &&
              styles.compactNavTabs,
          )}
        >
          {scrollSpySections.map(({ tabSuffix, label, badgeValue, isNew }) => (
            <li key={tabSuffix} className={styles.tabItem}>
              <button
                type="button"
                onClick={(event) => handleTabSelection(tabSuffix, event, `#project-${tabSuffix}`)}
              >
                {label}
                {badgeValue !== undefined && (
                  <span className={`badge badge-round-xs ${styles.badge}`}>{badgeValue}</span>
                )}
                {isNew && (
                  <span className={styles.newTagContainer}>
                    <NewTag />
                  </span>
                )}
              </button>
            </li>
          ))}
        </ScrollSpy>
      </div>

      <div id="scrollSpyRoot" className={styles.projectBody} ref={scrollSpyRef}>
        <ProjectDescription project={project} uniqueTradeCount={uniqueProjectStockTrades.length} />
        <ProjectStages project={project} />
        {sectionTabSuffixes.has('subbies') && (
          <ProjectSubbies projectSubbieSummary={projectSubbieSummary} project={project} />
        )}
        {sectionTabSuffixes.has('suppliers') && <ProjectSuppliers projectId={projectId} />}
        {sectionTabSuffixes.has('consultants') && <ProjectConsultants projectId={projectId} />}
        <ProjectStockTrades
          userStockTradeIds={userStockTradeIds}
          uniqueProjectStockTrades={uniqueProjectStockTrades}
        />
        <ProjectLogs projectLogs={projectLogs} />
        <div id="project-notes" className={styles.projectSection}>
          <ProjectNotesForm projectId={projectId} addProjectNote={handleAddProjectNote} />
          <ProjectNotesExperiment
            projectNotes={notes}
            deleteProjectNote={handleDeleteProjectNote}
          />
        </div>
      </div>
    </>
  );
};

export default ProjectSliderBody;
