import { gql } from '@apollo/client';
import { RestrictedStageAccess } from './fragments';

export const SET_STAGE_ACCESS_RESTRICTED = gql`
  mutation SetStageAccessRestricted($input: SetStageAccessRestrictedInput!) {
    setStageAccessRestricted(input: $input) {
      ... on UnrestrictedStage {
        id
        hasTeamAccessControl
      }
    }
  }
`;

export const GRANT_RESTRICTED_STAGE_ACCESS = gql`
  mutation GrantRestrictedStageAccess($input: GrantRestrictedStageAccessInput!) {
    grantRestrictedStageAccess(input: $input) {
      ...RestrictedStageAccess
    }
  }
  ${RestrictedStageAccess}
`;

export const REVOKE_RESTRICTED_STAGE_ACCESS = gql`
  mutation RevokeRestrictedStageAccess($input: RevokeRestrictedStageAccessInput!) {
    revokeRestrictedStageAccess(input: $input) {
      ...RestrictedStageAccess
    }
  }
  ${RestrictedStageAccess}
`;
