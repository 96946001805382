import { TagVariant, Tooltip } from '@estimateone/frontend-components';
import { CoreTradeTag } from '@profiles/features/CompanyProfile/components/Trades/CoreTrades';
import styles from './CoreTradeTagWithTooltip.module.scss';

type CoreTradeTagWithToolTipProps = {
  coreTrade: string;
  variant?: TagVariant;
  isHeightCorrected?: boolean;
};

export const CoreTradeTagWithTooltip = ({
  coreTrade,
  variant,
  isHeightCorrected,
}: CoreTradeTagWithToolTipProps) => (
  <Tooltip
    place="bottom"
    text="A star means this is their Core Trade - what they mostly do or specialise in"
    tooltipId="core-trade-tooltip"
  >
    <CoreTradeTag
      text={coreTrade}
      variant={variant}
      className={isHeightCorrected ? styles.heightCorrectedTag : undefined}
    />
  </Tooltip>
);
