import { FontStyle, Heading } from '@ascension/components/fec';

/** SUBBIE_CREDIT_EXPERIMENT_ALPHA_CODE */
export const FreeCreditHeader = () => (
  <Heading level={1} renderAs={FontStyle.h2}>
    Unlock my free monthly tender
  </Heading>
);

/** SUBBIE_CREDIT_EXPERIMENT_ALPHA_CODE */
export const FreeCreditsHeader = ({
  allowedCredits,
  usedCredits,
}: {
  allowedCredits: number;
  usedCredits: number;
}) => (
  <Heading level={1} renderAs={FontStyle.h2}>
    Unlock free monthly tender {usedCredits + 1} of {allowedCredits}
  </Heading>
);
