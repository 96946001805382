import uniqBy from 'lodash/uniqBy';
import { StageStatus } from '@ascension/enums';
import { Stage, StockTrade } from './types';
import { ProjectStatus } from '@ascension/_gqltypes/subbie.generated';

const getStockTradesForStage = (stage: Stage): StockTrade[] =>
  stage.trades.reduce((acc, { stockTrade }) => {
    if (stockTrade === null) {
      return acc;
    }
    return acc.concat([stockTrade]);
  }, new Array<StockTrade>());

const getUniqueStockTradesForStages = (stages: Stage[]): StockTrade[] => {
  const stockTrades = stages.reduce(
    (acc, stage) => acc.concat(getStockTradesForStage(stage)),
    new Array<StockTrade>(),
  );

  return uniqBy<StockTrade>(stockTrades, 'id');
};

export const getUniqueStockTradesForProject = (project: {
  status: ProjectStatus;
  stages: Stage[];
}): StockTrade[] => {
  const { status: projectStatus, stages } = project;
  if (projectStatus === ProjectStatus.AWARDED) {
    return getUniqueStockTradesForStages(
      stages.filter(({ status: stageStatus }) => stageStatus === StageStatus.TENDER_AWARDED),
    );
  }
  return getUniqueStockTradesForStages(stages);
};
