export type Address = {
  address1?: string | null;
  address2?: string | null;
  address3?: string | null;
  postcode?: string | null;
  shortAddress?: string | null;
  district?: string | null;
  province?: string | null;
  suburb?: string | null;
  region?: string | null;
  city?: string | null;
  state?: {
    shortName: string | null;
  } | null;
  country?: {
    id?: number | null;
  };
};

export const countryId = {
  AU: 1,
  NZ: 2,
  UK: 4,
  IE: 11,
} as const;

const DELIMITER = ', ';

export const getAddressString = (address: Address) =>
  [address.address1, address.shortAddress].filter(Boolean).join(DELIMITER);

export const getAddressLines = (address: Address): { firstLine: string; secondLine: string } => ({
  firstLine: [address.address1, address.address2, address.address3].filter(Boolean).join(DELIMITER),
  secondLine: [
    address.district,
    address.suburb,
    address.city,
    address.province,
    address.region,
    address.state?.shortName,
  ]
    .filter(Boolean)
    .join(DELIMITER)
    .concat(address.postcode ? ` ${address.postcode}` : ''),
});

export const getLocalArea = (address: Address): string | null => {
  switch (address.country?.id ?? null) {
    case countryId.AU:
      return [address.suburb, address.state?.shortName].filter(Boolean).join(DELIMITER);
    case countryId.NZ:
      return [address.suburb, address.province].filter(Boolean).join(DELIMITER);
    case countryId.IE:
    case countryId.UK:
      return [address.city, address.province].filter(Boolean).join(DELIMITER);
    default:
      return '';
  }
};
