import { MutableRefObject } from 'react';
import Routing from 'routing';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  EnterFullscreenIconButton,
  Icon,
  IconName,
  TableInstance,
} from '@estimateone/frontend-components';
import { ColumnActionMenu } from '../ColumnActionMenu/ColumnActionMenu';
import { LettingScheduleStatus } from '../LettingScheduleStatus';
import { AssignedToFilterInput } from './AssignedToFilter';
import { PackageNameFilter } from './PackageNameFilter';
import { PriorityFilterInput } from './PriorityFilter';
import { StatusFilterInput } from './StatusFilter';
import { CustomColumnNames } from '../hooks/useCustomColumns';
import { FilterApi } from '../hooks/useFilteredPackages';
import {
  Action as AnalyticsAction,
  useInteractAnalytics,
} from '@ascension/components/hooks/Analytics';
import { ButtonName } from '@ascension/components/hooks/Analytics/buttonNames';
import { ScreenName } from '@ascension/components/hooks/Analytics/screenName';
import {
  GetLettingColumnStatus,
  GetLettingColumnStatus_userLettingColumnStatus as UserLettingColumnStatus,
} from '../ColumnActionMenu/types/GetLettingColumnStatus';
import { LettingSchedulePriority } from '@ascension/_gqltypes/builder.generated';
import { EntityId } from '@ascension/types';
import { Package } from '@builder/features/ProcurementLettingSchedule/types';
import { ValueLabelOption } from '@estimateone/frontend-components/src/components/Form/Select/types';
import styles from './styles.scss';

const NO_COLUMN_STATUSES: UserLettingColumnStatus[] = [];

export type FilterOption = {
  label: string;
  value: LettingScheduleStatus;
};

export type PriorityFilterOption = {
  label: string;
  value: LettingSchedulePriority;
};

export type TableActionBarProps = {
  filterApi: FilterApi;
  assignedToFilterOptions: FilterOption[];
  statusFilterOptions: FilterOption[];
  priorityFilterOptions: PriorityFilterOption[];
  stageId: EntityId;
  tableRef: MutableRefObject<TableInstance<Package> | undefined>;
  columnStatuses: GetLettingColumnStatus;
  customColumnNames: CustomColumnNames;
  setIsNewPackageModalOpen: (value: boolean) => void;
  enableDefaultTimeframes: boolean;
  fullscreenContainerRef: MutableRefObject<HTMLDivElement | null>;
};

export const TableActionBar = ({
  filterApi,
  assignedToFilterOptions,
  statusFilterOptions,
  priorityFilterOptions,
  stageId,
  tableRef,
  columnStatuses,
  customColumnNames,
  setIsNewPackageModalOpen,
  enableDefaultTimeframes,
  fullscreenContainerRef,
}: TableActionBarProps) => {
  // Example of how to export the schedule
  const allowExports = true;
  const { addEvent } = useInteractAnalytics();
  const trackLettingScheduleExport = () =>
    addEvent({
      action: AnalyticsAction.BUTTON_CLICKED,
      buttonName: ButtonName.PROCUREMENT_LETTING_SCHEDULE_EXPORT,
      screenName: ScreenName.PROCUREMENT_LETTING_SCHEDULE,
    });
  const onExportHandler = async () => {
    await trackLettingScheduleExport();
    window.location.replace(
      Routing.generate('app_procurementstage_exportschedule_excel', { id: stageId }),
    );
  };

  return (
    <div className={styles.actionBar}>
      <div className={styles.scheduleFiltersContainer}>
        <PackageNameFilter
          onChange={(e) => {
            filterApi.setPackageNameFilter(e.target.value);
          }}
        />
        <div className={styles.selectFilterContainer}>
          <StatusFilterInput
            statusFilterOptions={statusFilterOptions}
            value={statusFilterOptions.filter((option) =>
              filterApi.statusFilter.includes(option.value),
            )}
            onChange={(values: ValueLabelOption<number>[]) => {
              filterApi.setStatusFilter(values.map((v) => v.value));
            }}
          />
        </div>
        <div className={styles.selectFilterContainer}>
          <PriorityFilterInput
            priorityFilterOptions={priorityFilterOptions}
            value={priorityFilterOptions.filter((option) =>
              filterApi.priorityFilter.includes(option.value),
            )}
            onChange={(values: ValueLabelOption<string>[]) => {
              filterApi.setPriorityFilter(values.map((v) => v.value));
            }}
          />
        </div>
        <div className={styles.selectFilterContainer}>
          <AssignedToFilterInput
            assignedToFilterOptions={assignedToFilterOptions}
            value={assignedToFilterOptions.filter((option) =>
              filterApi.assignedToFilter.includes(option.value),
            )}
            onChange={(values: ValueLabelOption<number>[]) => {
              filterApi.setAssignedToFilter(values.map((v) => v.value));
            }}
          />
        </div>
      </div>
      <div className={styles.tableActionsContainer}>
        <ColumnActionMenu
          stageId={stageId}
          tableRef={tableRef}
          columnStatuses={columnStatuses?.userLettingColumnStatus ?? NO_COLUMN_STATUSES}
          customColumnNames={customColumnNames}
          enableDefaultTimeframes={enableDefaultTimeframes}
        />
        <Button
          variant={ButtonVariant.Grey}
          size={ButtonSize.Small}
          onClick={() => setIsNewPackageModalOpen(true)}
        >
          <Icon name={IconName.Add} marginRight="small" />
          New Package
        </Button>

        {allowExports ? (
          <Button variant={ButtonVariant.Grey} size={ButtonSize.Small} onClick={onExportHandler}>
            Export to Excel
          </Button>
        ) : null}

        <EnterFullscreenIconButton fullscreenContainerRef={fullscreenContainerRef} />
      </div>
    </div>
  );
};
