import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { USERS_FOR_ACCOUNT } from '../queries';
import {
  GetUsersForAccount,
  GetUsersForAccount_accountUsers as Users,
} from '../types/GetUsersForAccount';

type UseGetUsersForCurrentAccount = {
  loading: boolean;
  accountUsers: Users[];
};

export const useGetUsersForCurrentAccount = (
  onCompleted?: (data: UseGetUsersForCurrentAccount) => void,
): UseGetUsersForCurrentAccount => {
  const { data, loading } = useQuery<GetUsersForAccount>(USERS_FOR_ACCOUNT, {
    onCompleted,
  });

  const accountUsers = useMemo(() => data?.accountUsers ?? [], [data]);

  return {
    loading,
    accountUsers,
  };
};
