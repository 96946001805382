import { gql } from '@apollo/client';

export const GET_TENDER_RESPONSES_FOR_PACKAGE = gql`
  query GetTenderResponsesForPackage($packageId: EntityId!) {
    package(id: $packageId) {
      id
      activeRfqs {
        id
        companyName
        contact {
          fullName
          phone
          email
        }
        quotingStatus
      }
    }
  }
`;
