import { joinClassNames } from '@estimateone/frontend-components';
import styles from './styles.scss';

export type IndicatorProps = {
  status: IndicatorStatus;
};

export enum IndicatorStatus {
  Quoted,
  Quoting,
  NotQuoting,
  NoResponse,
}

export const Indicator = ({ status }: IndicatorProps) => {
  const getStyle = () => {
    switch (status) {
      case IndicatorStatus.Quoted:
        return styles.rfqsQuoted;
      case IndicatorStatus.Quoting:
        return styles.rfqsQuoting;
      case IndicatorStatus.NotQuoting:
        return styles.rfqsNotQuoting;
      case IndicatorStatus.NoResponse:
      default:
        return styles.rfqsNoResponse;
    }
  };

  return <div className={joinClassNames(styles.coverageIndicator, getStyle())} />;
};
