import moment from 'moment-timezone';
import { BillingFrequency } from './PlanDetailsSection';
import styles from './styles.scss';

type PlanDetailsCardProps = {
  billingFrequency: BillingFrequency;
  planDescription: string;
  renewalDate: GraphQLDateTime | null;
};

const formatDate = (date: GraphQLDateTime | null) =>
  date ? moment(date).format('DD MMM yyyy') : '-';

export const PlanDetailsCard = ({
  billingFrequency,
  planDescription,
  renewalDate,
}: PlanDetailsCardProps) => (
  <div className={styles.planDetailsCard}>
    <div className={styles.e1Logo} />
    <div className={styles.planDetailsCardContainer}>
      <p className={styles.planDetailsCardTitle}>
        {billingFrequency === 'monthly' ? 'Monthly' : 'Annual'} Subscription{' '}
      </p>
      <p className={styles.planDetailsCardDesc}>{planDescription}</p>
      <p className={styles.planDetailsCardDesc}>Renews {formatDate(renewalDate)}</p>
    </div>
  </div>
);
