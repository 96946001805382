import { useContext } from 'react';
import { ViewerContext } from '../../modules/ViewerProvider/ViewerProvider';

export const useOwnAccount = () => {
  const { loading, context } = useContext(ViewerContext);

  const isOwnAccount = context === null ? false : context?.isOwnAccount();

  return { isOwnAccount, loading };
};
