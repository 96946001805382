import { ComponentProps, useEffect, useState } from 'react';
import {
  Slider,
  ButtonVariant,
  Button,
  LoadingSpinner,
  ModalSize,
  Modal,
  AlertIcon,
} from '@estimateone/frontend-components';
import { DriveItemSelection } from '../SliderSteps/DriveItemSelection';
import { useGetDocSyncMapForModification } from '../../hooks/useGetDocSyncMapForModification';
import { useUpdateStageSharepointMap } from '../../hooks/useUpdateStageSharepointMap';
import { SharePointDriveItemType } from '@ascension/_gqltypes/builder.generated';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

type SelectedDriveItems = ComponentProps<typeof DriveItemSelection>['selectedDriveItems'];

const getRemovedFiles = (
  initial: SelectedDriveItems,
  current: SelectedDriveItems,
): SelectedDriveItems => {
  const initialSelectionIds = [...initial.values()].map(({ id }) => id);
  const removedFileIds = initialSelectionIds.filter((id) => !current.has(id));
  const removedFiles = new Map(
    [...initial.values()]
      .filter(({ id }) => removedFileIds.includes(id))
      .map((item) => [item.id, item]),
  );

  return removedFiles;
};

const isValidSelection = (selected: SelectedDriveItems): boolean => selected.size > 0;

export const SharepointModifySlider = ({
  isOpen,
  onRequestClose,
  stageId,
}: {
  isOpen: boolean;
  onRequestClose: () => void;
  stageId: EntityId;
}) => {
  const { data, loading, refetch: refetchMapDetails } = useGetDocSyncMapForModification(stageId);
  const { submit: modifySharepointMap, isLoading: isModifying } = useUpdateStageSharepointMap();
  const [selectedDriveItems, setSelectedDriveItems] = useState<SelectedDriveItems | undefined>(
    undefined,
  );
  const [showWarningModal, setShowWarningModal] = useState(false);

  useEffect(() => {
    if (data !== undefined) {
      const { driveItems } = data.getDocSyncMapForModification;
      setSelectedDriveItems(
        new Map(driveItems.filter(({ isSelected }) => isSelected).map((item) => [item.id, item])),
      );
    }
  }, [data]);

  if (data === undefined && !loading) {
    throw new Error(`Unable to load Docsync map details for stage #${stageId}`);
  }

  const connectedDriveDetails = data?.getDocSyncMapForModification;
  const initialSelectedDriveItems = new Map(
    connectedDriveDetails?.driveItems
      .filter(({ isSelected }) => isSelected)
      .map((item) => [item.id, item]),
  );

  const isContentReady = !loading && !!selectedDriveItems;

  const selectionRemovals = isContentReady
    ? getRemovedFiles(initialSelectedDriveItems, selectedDriveItems)
    : undefined;

  const hasRemovals = selectionRemovals ? isValidSelection(selectionRemovals) : false;

  const handleSubmit = async () => {
    if (selectedDriveItems && connectedDriveDetails) {
      const selectedFileIds = [...selectedDriveItems.values()]
        .filter(({ type }) => type === SharePointDriveItemType.FILE)
        .map(({ id }) => id);
      const selectedFolderIds = [...selectedDriveItems.values()]
        .filter(({ type }) => type === SharePointDriveItemType.FOLDER)
        .map(({ id }) => id);

      await modifySharepointMap({
        driveId: connectedDriveDetails.driveId,
        siteId: connectedDriveDetails.siteId,
        stageId,
        baseFolderId: connectedDriveDetails.baseFolder.id,
        files: selectedFileIds,
        folders: selectedFolderIds,
      });
      setShowWarningModal(false);
      onRequestClose();
      await refetchMapDetails();
    }
  };

  return (
    <Slider isOpen={isOpen} onRequestClose={() => onRequestClose()}>
      <Slider.Header>
        <h2 className={styles.sliderHeading}>Change File Selection</h2>
      </Slider.Header>
      <Slider.Body scrollBodyOnly>
        {isContentReady ? (
          <DriveItemSelection
            driveItems={connectedDriveDetails?.driveItems ?? []}
            selectedDriveItems={selectedDriveItems}
            onSelectionChange={setSelectedDriveItems}
          />
        ) : (
          <LoadingSpinner />
        )}
      </Slider.Body>

      {isContentReady && (
        <Slider.Footer>
          <div className={styles.sliderFooter}>
            <Button variant={ButtonVariant.Grey} fullWidth onClick={() => onRequestClose()}>
              Cancel
            </Button>
            <Button
              variant={hasRemovals ? ButtonVariant.Red : ButtonVariant.Primary}
              onClick={async () => {
                if (hasRemovals) {
                  setShowWarningModal(true);
                } else {
                  await handleSubmit();
                }
              }}
              fullWidth
              disabled={isModifying}
            >
              Save and Close
            </Button>
          </div>
        </Slider.Footer>
      )}

      <Modal
        isOpen={showWarningModal}
        onRequestClose={() => {
          setShowWarningModal(false);
        }}
        size={ModalSize.Small}
        reactModalProps={{ contentLabel: 'Delete files from E1?' }}
      >
        <Modal.Section>
          <div className={styles.modalTextContainer}>
            <h3 className={styles.modalHeading}>Delete files from E1?</h3>
            <div className={styles.modalAlertContainer}>
              <AlertIcon className={styles.modalAlertIcon} />
              <p>
                Files or folders below will no longer be checked for updates, and will be deleted.
              </p>
            </div>
            <div className={styles.modalAlertContainer}>
              <AlertIcon className={styles.modalAlertIcon} />
              <p>
                If you continue, subcontractors will no longer be able to download these files. They
                won&apos;t be automatically notified of this change.
              </p>
            </div>
            <div className={styles.modalFileList}>
              <p>Files will be deleted from:</p>
              <ul>
                {[...(selectionRemovals?.values() ?? [])].map((folder) => (
                  <li key={folder.id}>{folder.name}</li>
                ))}
              </ul>
            </div>
            <p>You can add these files or folders to this project later again.</p>
          </div>
          <div className={styles.modalButtonContainer}>
            <Button
              variant={ButtonVariant.Grey}
              fullWidth
              onClick={() => setShowWarningModal(false)}
            >
              No, Go Back
            </Button>
            <Button
              variant={ButtonVariant.Red}
              fullWidth
              disabled={isModifying}
              onClick={handleSubmit}
            >
              Yes, Delete These Files
            </Button>
          </div>
        </Modal.Section>
      </Modal>
    </Slider>
  );
};
