import { renderToStaticMarkup } from 'react-dom/server';
import $ from 'jquery';
import Routing from 'routing';
import { AwardedIndicator } from '../../components/builder/common/AwardedIndicator';
import { initQuotesDueDatePicker } from '../utils/date';
import { getStageTypeAsString, StageType } from '../../enums';

export const setTenderStageLinkHref = ($link, id, type, hasAccess) => {
  const stageType = getStageTypeAsString(type)?.toLowerCase();

  if (!hasAccess) {
    $link.removeClass().addClass('disabled-link');
    return;
  }

  const routesMapping = {
    'stage-dashboard-link': 'app_stage_view',
    'stage-details-link': 'app_stage_details',
    'stage-trades-link': 'app_stage_trades',
    'stage-monitor-link': 'app_stage_monitor',
    'stage-documents-link': 'app_stage_documents',
    'stage-correspondence-link': 'app_stage_correspondence',
    'stage-requests-link': 'app_stage_requests',
    'stage-quotes-link': 'app_stage_quotes',
  };

  for (let [className, routeName] of Object.entries(routesMapping)) {
    if ($link.hasClass(className)) {
      const route = Routing.generate(routeName, { id, stageType });
      $link.attr('href', route);
      return;
    }
  }

  // Handle special cases
  if ($link.hasClass('stage-wizard-documents-link')) {
    const route =
      type === StageType.TYPE_PROCUREMENT
        ? Routing.generate('app_stage_documents', { id, stageType })
        : Routing.generate('app_tenderstagewizard_documents', { id });
    $link.attr('href', route);
  } else if ($link.hasClass('tender-stage-handover-wizard-link')) {
    const route = Routing.generate('app_tender_handover', { stage_id: id });
    $link.attr('href', route);
  }
};

export const setConstructionStageLinkHref = ($link, id) => {
  const routesMapping = {
    'stage-dashboard-link': 'app_constructionstage_view',
    'stage-directory-link': 'app_constructionstage_directory',
    'stage-letting-link': 'app_constructionstage_letting',
    'stage-documents-link': 'app_constructionstage_documents',
    'stage-correspondence-link': 'app_constructionstage_usercorrespondence',
  };

  for (let [className, routeName] of Object.entries(routesMapping)) {
    if ($link.hasClass(className)) {
      const route = Routing.generate(routeName, { id });
      $link.attr('href', route);
      return;
    }
  }
};

export const updateDataTableListeners = ($target) => {
  const $inputs = $('input.hidden_datetime_lazy[name=quotesDueDate]', $target);
  initQuotesDueDatePicker($inputs);
  $target.find('select').select2({
    theme: 'bootstrap',
    minimumResultsForSearch: -1,
  });
};

export const appendAwardedIndicator = ($procurementPrompt) => {
  const $awardedIndicator = $procurementPrompt.find('.awarded-indicator');
  $awardedIndicator.append(renderToStaticMarkup(<AwardedIndicator />));
};

export const setTooltipForRow = (tooltip, row) => {
  $(row)
    .addClass('tooltip-trigger')
    .attr('title', tooltip)
    .attr('data-tooltip-content-class', 'tooltip-confined-left');
};

export const extractTemplate = (list, col) => {
  return $(list.$placeholderRow.find('td').get(col)).clone();
};
