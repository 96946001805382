import { gql } from '@apollo/client';

export const GET_STAGE_ADDENDA = gql`
  query StageAddenda($stageId: EntityId!) {
    stageAddenda(stageId: $stageId) {
      id
      name
      description
      createdAt
      issuedAt
    }
  }
`;
