import { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ALL_COUNTRIES, GET_TENDER_DETAILS_HANDOVER } from './queries';
import { CREATE_HANDOVER_PROCUREMENT_STAGE } from './mutations';
import { Country } from '@ascension/enums';
import { GetAllCountries } from './types/GetAllCountries';
import {
  GetTenderDetailsForHandover,
  GetTenderDetailsForHandoverVariables,
  GetTenderDetailsForHandover_stage as Stage,
} from './types/GetTenderDetailsForHandover';
import {
  HandoverToProcurementStage,
  HandoverToProcurementStageVariables,
  HandoverToProcurementStage_handoverToProcurementStage as NewStage,
} from './types/HandoverToProcurementStage';
import { HandoverToProcurementStageInput } from '@ascension/_gqltypes/builder.generated';
import { EntityId } from '@ascension/types';

type GetTenderDetailsForHandoverResult = {
  loading: boolean;
  stage?: Stage;
  accountCountry: Country | undefined;
};

export const useGetTenderDetailsForHandover = (
  stageId: EntityId,
): GetTenderDetailsForHandoverResult => {
  const { data, loading } = useQuery<
    GetTenderDetailsForHandover,
    GetTenderDetailsForHandoverVariables
  >(GET_TENDER_DETAILS_HANDOVER, {
    variables: { stageId },
  });

  return {
    stage: data?.stage,
    accountCountry: data?.currentUser.account?.primaryCountry?.id,
    loading,
  };
};

type CreateHandoverProcurementStageResult = {
  submit: (input: HandoverToProcurementStageInput) => Promise<undefined>;
  responseData?: NewStage;
};

export const useCreateHandoverProcurementStage = (): CreateHandoverProcurementStageResult => {
  const [responseData, setResponseData] = useState<NewStage>();

  const [handleCreateHandoverProcurementStage] = useMutation<
    HandoverToProcurementStage,
    HandoverToProcurementStageVariables
  >(CREATE_HANDOVER_PROCUREMENT_STAGE);

  const submit = async (input: HandoverToProcurementStageInput): Promise<undefined> => {
    const { data } = await handleCreateHandoverProcurementStage({ variables: { input } });

    setResponseData(data?.handoverToProcurementStage);

    return undefined;
  };

  return { submit, responseData };
};

export const useGetAllCountries = () => {
  const { data, loading } = useQuery<GetAllCountries>(GET_ALL_COUNTRIES);

  return {
    countries: data?.countries,
    loading,
  };
};
