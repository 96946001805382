import { useCallback, useEffect, useState } from 'react';

interface LocalStorageHookInterface<T extends string | number | boolean | object> {
  set: (newValue: T, secondaryKey?: string) => void;
  get: (secondaryKey?: string) => T | undefined;
  remove: (secondaryKey?: string) => void;
  value: T | undefined;
  loaded: boolean;
}

const BAD_SERIALIZATION = '[object Object]';

function useLocalStorage<T extends string | number | boolean | object>(
  key: string,
): LocalStorageHookInterface<T> {
  const [value, setValue] = useState<T | undefined>();
  const [loaded, setLoaded] = useState(false);

  const getStorageKey = useCallback(
    (secondaryKey?: string): string => (secondaryKey ? `${key}-${secondaryKey}` : key),
    [key],
  );

  const hasLocalStorage = window.localStorage !== undefined;

  const set = (newValue: T, secondaryKey?: string): void => {
    if (hasLocalStorage) {
      window.localStorage.setItem(
        getStorageKey(secondaryKey),
        typeof newValue === 'object' ? JSON.stringify(newValue) : newValue.toString(),
      );
    }
    setValue(newValue);
  };

  const remove = (secondaryKey?: string): void => {
    if (hasLocalStorage) {
      window.localStorage.removeItem(getStorageKey(secondaryKey));
      setValue(undefined);
    }
  };

  const get = useCallback(
    (secondaryKey?: string) => {
      if (!hasLocalStorage) {
        return undefined;
      }
      const rawValue = window.localStorage.getItem(getStorageKey(secondaryKey));
      // Tolerate historical cases of bad serialisation
      if (!rawValue || rawValue === BAD_SERIALIZATION) {
        return undefined;
      }
      try {
        return JSON.parse(rawValue);
      } catch (err) {
        if (err instanceof SyntaxError) {
          return rawValue;
        }
        throw err;
      }
    },
    [getStorageKey, hasLocalStorage],
  );

  useEffect(() => {
    const parsedValue = get();
    if (!value) {
      setValue(parsedValue);
    }
    setLoaded(true);
  }, [value, setValue, get]);

  return { value, set, get, remove, loaded };
}

export default useLocalStorage;
