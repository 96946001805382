import { useState } from 'react';
import Routing from 'routing';
import {
  ActionMenu,
  Button,
  ButtonVariant,
  Icon,
  IconName,
} from '@estimateone/frontend-components';
import E1Request from '../../../../../js/classes/E1Request';
import {
  Addendum,
  AddendumRevisionHistorySlider,
} from '@builder/common/stage/AddendumRevisionHistorySlider';
import { ExportPackageRegisterModal } from '@builder/common/stage/ExportPackageRegister/ExportPackageRegisterModal';
import { EntityId } from '../../../../../types';

export type DocumentActionsMenuProps = {
  stageId: EntityId;
  addenda: Addendum[] | undefined;
  hasDocumentIntegration: boolean;
  existingAddendumId?: EntityId | null;
  onRevisionFlowOpen: () => void;
};

export const DocumentActionsMenu = ({
  stageId,
  addenda,
  hasDocumentIntegration,
  existingAddendumId,
  onRevisionFlowOpen,
}: DocumentActionsMenuProps) => {
  const [isExportPackageRegisterModalOpen, setIsExportPackageRegisterModalOpen] = useState(false);
  const [isHistorySliderOpen, setIsHistorySliderOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const triggerElement = (
    <Button variant={ButtonVariant.Secondary} onClick={() => setIsMenuOpen(!isMenuOpen)}>
      Document Actions
      <Icon name={IconName.ChevronDown} marginLeft="small" size="0.6em" />
    </Button>
  );

  const openExportPackageRegisterModal = () => {
    setIsMenuOpen(false);
    setIsExportPackageRegisterModalOpen(true);
  };

  const openAddendumRevisionHisotrySlider = () => {
    setIsMenuOpen(false);
    setIsHistorySliderOpen(true);
  };

  const triggerRevision = async () => {
    setIsMenuOpen(false);
    if (existingAddendumId) {
      window.location.assign(
        Routing.generate('app_stageaddendum_documents', { id: existingAddendumId }),
      );
    } else {
      await new E1Request(Routing.generate('app_stageaddendum_newmodal', { stage_id: stageId }))
        .setShowLoadingModal()
        .submit();
    }
  };

  return (
    <>
      <ActionMenu
        triggerElement={triggerElement}
        onClickOutside={() => setIsMenuOpen(false)}
        visible={isMenuOpen}
        placement="bottom-end"
      >
        <>
          {!hasDocumentIntegration ? (
            <ActionMenu.Item onClick={triggerRevision}>
              {existingAddendumId ? 'Resume Unfinished Revision' : 'Start New Revision'}
            </ActionMenu.Item>
          ) : (
            false
          )}
          <ActionMenu.Item onClick={openAddendumRevisionHisotrySlider}>
            Addendum/Revision History
          </ActionMenu.Item>
          <ActionMenu.Item onClick={openExportPackageRegisterModal}>
            Export Package Register
          </ActionMenu.Item>
        </>
      </ActionMenu>
      <ExportPackageRegisterModal
        stageId={stageId}
        existingAddendumId={existingAddendumId}
        isOpen={isExportPackageRegisterModalOpen}
        onRequestClose={() => setIsExportPackageRegisterModalOpen(false)}
      />
      <AddendumRevisionHistorySlider
        stageId={stageId}
        addenda={addenda}
        hasDocumentIntegration={hasDocumentIntegration}
        isOpen={isHistorySliderOpen}
        onRequestClose={() => setIsHistorySliderOpen(false)}
        onRevisionFlowOpen={onRevisionFlowOpen}
      />
    </>
  );
};
