import { AddressSearchFieldTypes } from '@shared/form/AddressSearchFields/AddressSearchFields';
import { PrimaryContactSingleSelectFieldTypes } from '@shared/form/PrimaryContactSingleSelect/PrimaryContactSingleSelect';
import { BusinessEntityType } from '@ascension/_gqltypes/profiles.generated';

export enum CompanyProfileFieldNames {
  CompanyName = 'name',
  CompanyNumber = 'companyNumber',
  BusinessEntity = 'businessEntity',
}

export enum CompanyProfileFieldLabels {
  CompanyName = 'Company Name',
  CompanyNumber = 'Company Number',
}

export type CompanyProfileFieldValues = AddressSearchFieldTypes &
  PrimaryContactSingleSelectFieldTypes & {
    [CompanyProfileFieldNames.CompanyName]?: string;
    [CompanyProfileFieldNames.CompanyNumber]?: string;
    [CompanyProfileFieldNames.BusinessEntity]?: BusinessEntityType;
  };

export type CompanyProfileFormErrors = Partial<
  Record<keyof CompanyProfileFieldValues, string[] | undefined>
>;
