import { E1Link } from '@estimateone/frontend-components';
import { format } from 'date-fns';
import { makePlural } from '@subbie/modal/ProjectSlider/ProjectSliderBody/ProjectSubbies/util';
import { SSCFreeTrialTag } from '../SSCFreeTrialTag';
import { SubbieConnectionCreditBalanceDetails } from './hooks';
import { useContactUsAboutUpgrade } from '@subbie/features/AccountUpgradeFeature/pages/NewUpgradeSelection/hooks';
import { UPGRADE_TYPE } from '@subbie/features/AccountUpgradeFeature/pages/NewUpgradeSelection/hooks/types';
import { useInSSCTrialExperiment } from '@subbie/modal/ProjectSlider/ProjectSliderBody/ProjectSubbies/hooks';
import styles from './styles.scss';

type CreditBalanceProps = {
  creditBalanceSummary: SubbieConnectionCreditBalanceDetails;
};

const CreditBalance = ({
  creditBalanceSummary: { subbieConnectionCreditBalance },
}: CreditBalanceProps) => {
  const showUpgradeEnquireForMoreCreditsModal = useContactUsAboutUpgrade(
    UPGRADE_TYPE.ProfessionalPlusMoreCredits,
  );
  const showTrialUpgradeModal = useContactUsAboutUpgrade(UPGRADE_TYPE.TrialSSC);

  const inTrialExperiment = useInSSCTrialExperiment();

  if (!subbieConnectionCreditBalance) {
    return null;
  }

  const onEnquiryClicked = () =>
    inTrialExperiment ? showTrialUpgradeModal() : showUpgradeEnquireForMoreCreditsModal();

  const creditRemaining =
    subbieConnectionCreditBalance.allowed - subbieConnectionCreditBalance.used;

  return (
    <div>
      <div className={styles.creditBalanceContainer}>
        <div className={styles.heading}>
          <span className="d-flex align-items-baseline">
            {creditRemaining} {makePlural(creditRemaining, 'Credit', 'Credits')} remaining
            {inTrialExperiment && (
              <span className="ml-1">
                <SSCFreeTrialTag />
              </span>
            )}
          </span>
          <span>
            <E1Link
              rel="noopener noreferrer"
              target="_blank"
              href="https://supplier.support.estimateone.com/en/articles/1702897248-guide-to-using-credits-with-the-subbie-connect-feature"
            >
              Learn more
            </E1Link>
          </span>
        </div>
        <div className={styles.creditBalanceBody}>
          {inTrialExperiment ? (
            <span>
              Your free trial includes 1 credit per month to share across your team. Trial ends 17
              June 2024.
            </span>
          ) : (
            <span>
              Your license includes {subbieConnectionCreditBalance.allowed} credits per month to
              share across your team.
            </span>
          )}
          <br />
          <span className={styles.enquireLinkSpacer}>
            Credits reset on{' '}
            {format(new Date(subbieConnectionCreditBalance.renewalDate), 'dd MMM yy')}.
          </span>
          <E1Link rel="noopener noreferrer" onClick={onEnquiryClicked}>
            Enquire now for more credits.
          </E1Link>
        </div>
      </div>
    </div>
  );
};

export default CreditBalance;
