import { gql } from '@apollo/client';

export const DELETE_HISTORICAL_PROJECT = gql`
  mutation DeleteHistoricalProject($id: EntityId!) {
    deleteHistoricalProjectV2(id: $id) {
      __typename
      id
      deletedAt
    }
  }
`;
