import { Alert, AlertVariant, InfoIcon } from '@estimateone/frontend-components';
import styles from './styles.module.scss';

export const DrawerError = () => (
  <div className={styles.errorDrawerBodyWrapper}>
    <Alert variant={AlertVariant.Red} className={styles.errorAlert}>
      <div className={styles.errorAlertContent}>
        <InfoIcon className={styles.errorIcon} />
        <div className={styles.errorTitle}>Something went wrong. </div>
        Please close and re-open the company profile or refresh your browser
      </div>
    </Alert>
  </div>
);
