import { HTMLAttributes, ReactNode } from 'react';
import {
  Accordion,
  AccordionTitle,
  Icon,
  IconName,
  Tooltip,
} from '@estimateone/frontend-components';
import { GetPackageWithRfqs_package_importedFromPackage as ImportedFromPackage } from '../../types/GetPackageWithRfqs';
import { RfqQuotingStatus } from '@ascension/_gqltypes/builder.generated';
import styles from './styles.scss';

type RFQ = ImportedFromPackage['activeRfqs'][0];

type AccordionTitleProps = {
  rfqs: RFQ[];
};

const TenderResponsesAccordionTitle = ({ rfqs }: AccordionTitleProps) => {
  const numQuoted = rfqs.filter((rfq) => rfq.quotingStatus === RfqQuotingStatus.QUOTED).length;

  return (
    <div className={styles.accordianTitleContainer}>
      <AccordionTitle titleText="Tender Responses" />
      <span>
        {' '}
        ({numQuoted} quoted, {rfqs.length} invited){' '}
      </span>
      <Tooltip
        tooltipId="tender-responses-accordion-tooltip"
        text="These are responses to invitations to quote from the Tender phase. They aren’t counted towards the Procurement phase (post-award) responses."
      >
        <Icon name={IconName.Help} />
      </Tooltip>
    </div>
  );
};

export type TenderResponsesAccordionProps = AccordionTitleProps &
  HTMLAttributes<HTMLDivElement> & {
    children: ReactNode;
  };

export const TenderResponsesAccordion = ({ children, rfqs }: TenderResponsesAccordionProps) => (
  <Accordion
    accordionTitle={<TenderResponsesAccordionTitle rfqs={rfqs} />}
    initIsCollapsed={false}
    className={styles.tenderResponses}
  >
    {children}
  </Accordion>
);
