import { StreetSelector, CitySelector, RegionSelector, CountrySelectOption } from './fields';
import { Country } from '@ascension/enums';
import styles from './styles.scss';

export const AddressInputField = ({
  selectedCountry,
  allCountries,
}: {
  selectedCountry: Country;
  allCountries: CountrySelectOption[] | undefined;
}) => (
  <div className={styles.addressInputField}>
    <StreetSelector country={selectedCountry} />
    <CitySelector country={selectedCountry} />
    <RegionSelector selectedCountry={selectedCountry} allCountries={allCountries} />
  </div>
);
