import { useState } from 'react';
import { E1Link, LinkVariant, Tag, TagVariant } from '@estimateone/frontend-components';
import { useRestrictedItemList } from '@subbie/modal/SubbieListSlider/SubbieListSliderContent/hooks';
import { ProjectInterestedSuppliers_projectInterestedSuppliers_stockTrades as StockTrade } from '../types/ProjectInterestedSuppliers';
import styles from './styles.scss';

export const SubbieAccountItemTagsList = ({ stockTrades }: { stockTrades: StockTrade[] }) => {
  const { firstItems, restOfItems, notShownCount } = useRestrictedItemList(stockTrades);
  const [allTagsVisible, setAllTagsVisible] = useState(false);

  const surplusTags = allTagsVisible ? (
    <>
      {restOfItems.map(({ id, name }) => (
        <Tag key={id} variant={TagVariant.Practical025} text={name} />
      ))}
      <E1Link
        variant={LinkVariant.Grey}
        onClick={() => setAllTagsVisible(false)}
        className={styles.showHideLinks}
      >
        Show less
      </E1Link>
    </>
  ) : (
    <E1Link
      variant={LinkVariant.Grey}
      onClick={() => setAllTagsVisible(true)}
      className={styles.showHideLinks}
      aria-label={`show ${notShownCount} more trades`}
    >{`+${notShownCount} more`}</E1Link>
  );

  return (
    <div className={styles.tradeContainer}>
      {firstItems.map(({ id, name }) => (
        <Tag key={id} variant={TagVariant.Practical025} text={name} />
      ))}

      {notShownCount > 0 && surplusTags}
    </div>
  );
};
