import { MouseEvent, KeyboardEvent } from 'react';
import { Slider } from '@estimateone/frontend-components';
import { SpeciFinderKeywordMatchesSliderHeader } from './SpeciFinderKeywordMatchesSliderHeader';
import { SpeciFinderKeywordMatchesSliderStageDetails } from './SpeciFinderKeywordMatchesSliderStageDetails';
import { SpeciFinderKeywordMatchesSliderBody } from './SpecifinderKeywordMatchesSliderBody';
import LoadingSpinnerFloating from '@shared/LoadingSpinner/LoadingSpinnerFloating';
import { useModalContext } from '@subbie/context/ModalContext';
import { useDocumentHighlightsForStageMap } from './hooks/useDocumentHighlightsByStage';

type SpeciFinderKeywordMatchesSliderProps = {
  isPaneOpen: boolean;
  stageId: number;
  handleClose: ((event: MouseEvent | KeyboardEvent) => void) & (() => void);
};

export const SpeciFinderKeywordMatchesSlider = ({
  isPaneOpen,
  stageId,
  handleClose,
}: SpeciFinderKeywordMatchesSliderProps) => {
  const { removeModal } = useModalContext();
  const { stage, keywordWithDocumentHighlights } = useDocumentHighlightsForStageMap(
    isPaneOpen ? stageId : undefined,
  );
  const project = stage?.project;

  const hasData = keywordWithDocumentHighlights && stage && project;

  const closeParentAndSelf = () => {
    handleClose();
    removeModal();
  };

  return (
    <Slider rootAppElement="#root" isOpen={isPaneOpen} onRequestClose={closeParentAndSelf}>
      {!hasData && <LoadingSpinnerFloating />}
      {hasData && isPaneOpen && (
        <>
          <SpeciFinderKeywordMatchesSliderHeader handleClose={handleClose} project={project} />
          <SpeciFinderKeywordMatchesSliderStageDetails stage={stage} />
          <SpeciFinderKeywordMatchesSliderBody
            keywordWithDocumentHighlights={keywordWithDocumentHighlights}
          />
        </>
      )}
    </Slider>
  );
};
