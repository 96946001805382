import { Country, getCurrencyInfo } from '@ascension/enums';
import { StageBudget } from '@ascension/_gqltypes/builder.generated';

const BUDGET_VALUE_MAP: Record<number, StageBudget> = {
  69: StageBudget.BUDGET_UNKNOWN,
  99: StageBudget.BUDGET_0K, // not used since 2019 - no corresponding option
  100000: StageBudget.BUDGET_100K,
  250000: StageBudget.BUDGET_250K,
  500000: StageBudget.BUDGET_500K,
  750000: StageBudget.BUDGET_750K,
  1000000: StageBudget.BUDGET_1000K,
  1500000: StageBudget.BUDGET_1500K,
  2000000: StageBudget.BUDGET_2M,
  3000000: StageBudget.BUDGET_3M,
  4000000: StageBudget.BUDGET_4M,
  5000000: StageBudget.BUDGET_5M,
  10000000: StageBudget.BUDGET_10M,
  15000000: StageBudget.BUDGET_15M,
  20000000: StageBudget.BUDGET_20M,
  30000000: StageBudget.BUDGET_30M,
  50000000: StageBudget.BUDGET_50M,
  100000000: StageBudget.BUDGET_100M,
  200000000: StageBudget.BUDGET_200M,
  200000001: StageBudget.BUDGET_1B,
};

export const getBudgetOptions = (country: Country | undefined) => {
  const { currencySymbol } = getCurrencyInfo(country);
  const budgetOptions = [
    { value: StageBudget.BUDGET_100K, label: `< ${currencySymbol}100k` },
    { value: StageBudget.BUDGET_250K, label: `${currencySymbol}100k - ${currencySymbol}250k` },
    { value: StageBudget.BUDGET_500K, label: `${currencySymbol}250k - ${currencySymbol}500k` },
    { value: StageBudget.BUDGET_750K, label: `${currencySymbol}500k - ${currencySymbol}750k` },
    { value: StageBudget.BUDGET_1000K, label: `${currencySymbol}750k - ${currencySymbol}1m` },
    { value: StageBudget.BUDGET_1500K, label: `${currencySymbol}1m - ${currencySymbol}1.5m` },
    { value: StageBudget.BUDGET_2M, label: `${currencySymbol}1.5m - ${currencySymbol}2m` },
    { value: StageBudget.BUDGET_3M, label: `${currencySymbol}2m - ${currencySymbol}3m` },
    { value: StageBudget.BUDGET_4M, label: `${currencySymbol}3m - ${currencySymbol}4m` },
    { value: StageBudget.BUDGET_5M, label: `${currencySymbol}4m - ${currencySymbol}5m` },
    { value: StageBudget.BUDGET_10M, label: `${currencySymbol}5m - ${currencySymbol}10m` },
    { value: StageBudget.BUDGET_15M, label: `${currencySymbol}10m - ${currencySymbol}15m` },
    { value: StageBudget.BUDGET_20M, label: `${currencySymbol}15m - ${currencySymbol}20m` },
    { value: StageBudget.BUDGET_30M, label: `${currencySymbol}20m - ${currencySymbol}30m` },
    { value: StageBudget.BUDGET_50M, label: `${currencySymbol}30m - ${currencySymbol}50m` },
    { value: StageBudget.BUDGET_100M, label: `${currencySymbol}50m - ${currencySymbol}100m` },
    { value: StageBudget.BUDGET_200M, label: `${currencySymbol}100m - ${currencySymbol}200m` },
    { value: StageBudget.BUDGET_1B, label: `> ${currencySymbol}200m` },
  ];

  if (country === Country.COUNTRY_UK || country === Country.COUNTRY_IE) {
    budgetOptions.unshift({ value: StageBudget.BUDGET_UNKNOWN, label: 'Not Available' });
  }

  return budgetOptions;
};

export const getInitialBudget = (budgetAmount: number, accountCountry: Country | undefined) => {
  const budgetOptions = getBudgetOptions(accountCountry);
  const initalBudgetValue = BUDGET_VALUE_MAP[budgetAmount];
  const initialBudgetLabel = budgetOptions.find(
    (option) => option.value === initalBudgetValue,
  )?.label;

  return initialBudgetLabel
    ? {
        value: initalBudgetValue,
        label: initialBudgetLabel,
      }
    : undefined;
};
