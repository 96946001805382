import { useMutation } from '@apollo/client';
import { UPDATE_SCHEDULE_SETTINGS } from '../mutations';
import {
  UpdateScheduleSettings,
  UpdateScheduleSettingsVariables,
} from '../types/UpdateScheduleSettings';
import { EntityId } from '@ascension/types';

export const useUpdateScheduleSettings = () => {
  const [updateScheduleSettings, { loading }] = useMutation<
    UpdateScheduleSettings,
    UpdateScheduleSettingsVariables
  >(UPDATE_SCHEDULE_SETTINGS);

  const submit = async (
    tradeScheduleSettingsId: EntityId,
    quotesDueByDateInterval: number | null,
    letByDateInterval: number | null,
    startOnSiteDateInterval: number | null,
  ) => {
    const { data } = await updateScheduleSettings({
      variables: {
        input: {
          tradeScheduleSettingsId,
          quotesDueByDateInterval,
          letByDateInterval,
          startOnSiteDateInterval,
        },
      },
    });

    return data?.updateScheduleSettings;
  };

  return { submit, loading };
};
