import { useState } from 'react';
import LoadingSpinner from '../../../shared/LoadingSpinner';
import useSearchAddressBookCompanies, {
  SearchedCompany,
} from '@builder/common/AddressBook/hooks/useSearchAddressBookCompanies';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

type SearchItemProps = {
  company: SearchedCompany;
  updateCompany: (company: SearchedCompany) => void;
};

const SearchItem = ({ company, updateCompany }: SearchItemProps) => (
  <li className={styles.company}>
    <a data-company-id={company.id} onClick={() => updateCompany(company)}>
      <div>{company.name}</div>
      <div>#{company.id}</div>
    </a>
  </li>
);

type SearchListProps = {
  searchedCompanies: SearchedCompany[];
  updateCompany: (company: SearchedCompany) => void;
  excludedCompanyId?: EntityId;
};

const SearchList = ({ searchedCompanies, updateCompany, excludedCompanyId }: SearchListProps) => (
  <>
    <h5 className={styles.searchCompanyHeader}>Search results</h5>
    <ul>
      {searchedCompanies
        .filter((company) => company.id !== excludedCompanyId)
        .map((company) => (
          <SearchItem key={company.id} company={company} updateCompany={updateCompany} />
        ))}
    </ul>
  </>
);

type SearchCompanyProps = {
  updateCompany: (company: SearchedCompany) => void;
  excludedCompanyId?: EntityId;
};

const SearchCompany = ({ updateCompany, excludedCompanyId }: SearchCompanyProps) => {
  const [query, setQuery] = useState('');
  const { data: companies, loading } = useSearchAddressBookCompanies(query);

  return (
    <>
      <h5 className={styles.searchCompanyHeader}>Select company to merge</h5>
      <input
        type="text"
        className="form-control"
        value={query}
        placeholder="Search by company, contact, email"
        onChange={(event) => setQuery(event.currentTarget.value)}
      />
      {loading && <LoadingSpinner size="medium" center />}
      {companies.length !== 0 && (
        <SearchList
          searchedCompanies={companies}
          updateCompany={updateCompany}
          excludedCompanyId={excludedCompanyId}
        />
      )}
    </>
  );
};

export default SearchCompany;
