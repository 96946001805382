import { Icon, IconName, joinClassNames } from '@estimateone/frontend-components';
import ScreenReaderOnly from '../../../fec/ScreenReaderOnly';
import styles from './styles.scss';

type PreferredIndicatorProps = {
  className?: string;
};

export const PreferredIndicator = ({ className }: PreferredIndicatorProps) => (
  <>
    <ScreenReaderOnly>This is a preferred contact</ScreenReaderOnly>
    <Icon
      name={IconName.StarSolid}
      className={joinClassNames(styles.preferredIndicator, className)}
    />
  </>
);
