import { useQuery } from '@apollo/client';
import { GET_TEAM_MEMBERS } from './queries';
import { GetTeamMembers } from './types/GetTeamMembers';

export const useGetTeamUsers = () => {
  const { data, loading } = useQuery<GetTeamMembers>(GET_TEAM_MEMBERS);
  return {
    teamUsers: data?.team.map((member) => member.user) ?? [],
    loading,
  };
};
