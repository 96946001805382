import { useReducer } from 'react';
import { E1Link } from '@estimateone/frontend-components';
import styles from './ShowMoreOrLessContent.module.scss';

type ShowMoreOrLessContentProps = {
  content: string | null;
  minContentSize: number;
};

export const ShowMoreOrLessContent = ({ content, minContentSize }: ShowMoreOrLessContentProps) => {
  const [showMore, toggleShowMore] = useReducer((state) => !state, false);
  const TRUNCATOR = '...';

  if (!content) return null;

  if (content.length < minContentSize) {
    return (
      <div>
        <p className={styles.content}>{content}</p>
      </div>
    );
  }

  return (
    <div>
      <p className={styles.content}>
        {showMore ? content : content.substring(0, minContentSize) + TRUNCATOR}
      </p>
      <div className={styles.buttonContainer}>
        <E1Link onClick={toggleShowMore}>{showMore ? 'Show less' : 'Show more'}</E1Link>
      </div>
    </div>
  );
};
