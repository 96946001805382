import Routing from 'routing';
import {
  Alert,
  AlertVariant,
  DownloadIcon,
  SuccessIcon,
  E1Link,
} from '@estimateone/frontend-components';
import { getStageTypeAsString } from '../../../../enums';
import styles from './styles.scss';

const getDocumentsUrl = (stageId: number, stageType: number) =>
  Routing.generate('app_stage_documents', {
    id: stageId,
    stageType: getStageTypeAsString(stageType)?.toLowerCase(),
  });

type ImportPreviousDocumentsAlertProps = { stageId: number; stageType: number };

export const ImportPreviousDocumentsAlert = ({
  stageId,
  stageType,
}: ImportPreviousDocumentsAlertProps) => (
  <Alert className={styles.alertWrapper} variant={AlertVariant.Green}>
    <div className={styles.alert}>
      <SuccessIcon />
      <p>
        You are now in a Procurement Phase project. We&apos;ve copied the trades and list of
        Packages from the Tender Phase.
      </p>
      <DownloadIcon />
      <div>
        <E1Link href={getDocumentsUrl(stageId, stageType)}>
          Import documents from the Tender phase.
        </E1Link>
      </div>
    </div>
  </Alert>
);
