import { useQuery } from '@apollo/client';
import omit from 'lodash/omit';
import CompleteQuoteForm from '../../../features/QuotesFeature/CompleteQuoteForm';
import PageLoadingSpinner from '../../../../shared/LoadingSpinner/PageLoadingSpinner';
import QuotePageLayout from '../../../../shared/Quotes/Page/QuotePageLayout';
import { CurrencyInfoContextProvider } from '@shared';
import { graphqlTypeToString } from '@shared/Quotes/utils/Address';
import { useFileManagerLocale } from '../../../hooks/FileManager';
import { QUOTE_TO_COMPLETE } from './queries';
import { getCurrencyInfo } from '@ascension/enums';
import { QuoteToComplete, QuoteToCompleteVariables } from './types/QuoteToComplete';
import { EntityId } from '@ascension/types';

type CompleteQuotePageProps = {
  quoteId: EntityId;
  returnUrl: string;
  stageId: EntityId;
};

const PageContent = ({ quoteId, returnUrl, stageId }: CompleteQuotePageProps) => {
  const { data, loading } = useQuery<QuoteToComplete, QuoteToCompleteVariables>(QUOTE_TO_COMPLETE, {
    variables: { quoteId, stageId },
  });
  const fileManagerLocale = useFileManagerLocale(data?.quote);

  if (loading || !fileManagerLocale) return <PageLoadingSpinner />;

  const quote = data?.quote;
  const stage = data?.stage?.__typename === 'UnrestrictedStage' ? data.stage : undefined;

  if (!quote || !stage) {
    throw new Error('Failed to load quote');
  }

  const { package: existingPackage, rfq } = quote;
  const { name: stageName, projectAddress, activePackages } = stage;

  if (rfq !== null) return <>Revise quote instead</>;

  const currencyInfo = getCurrencyInfo(stage.account?.primaryCountry?.id);

  return (
    <CurrencyInfoContextProvider currencyInfo={currencyInfo}>
      <CompleteQuoteForm
        fileManagerLocale={fileManagerLocale}
        quoteFile={omit(quote, ['package', 'rfq'])}
        quoteId={quoteId}
        existingPackage={existingPackage ?? undefined}
        projectAddress={projectAddress ? graphqlTypeToString(projectAddress) : ''}
        stageId={stageId}
        stageName={stageName}
        packages={activePackages}
        returnUrl={returnUrl}
      />
    </CurrencyInfoContextProvider>
  );
};

const CompleteQuotePage = ({ returnUrl, ...props }: CompleteQuotePageProps) => (
  <QuotePageLayout title="Complete quote" returnUrl={returnUrl}>
    <PageContent returnUrl={returnUrl} {...props} />
  </QuotePageLayout>
);

export default CompleteQuotePage;
