import { useEffect } from 'react';
import { LoadingSpinner } from '@estimateone/frontend-components';
import { InvitesResponsesStatsPanel } from './InvitesResponsesStatsPanel';
import { QuoteCoverage } from './QuoteCoverage';
import { useInviteAndResponseStats } from './hooks/useInviteAndResponseStats';
import { useQuoteCoverageReport } from './hooks/useQuoteCoverageReport';
import { ListenerEvent } from '@ascension/enums';
import { EntityId } from '@ascension/types';

export type InvitesAndResponsesProps = {
  stageId: EntityId;
};

export const InvitesAndResponses = ({ stageId }: InvitesAndResponsesProps) => {
  const { loading, packages, refetch } = useQuoteCoverageReport(stageId);

  useEffect(() => {
    const refetchEventHandler = () => refetch();
    document.addEventListener(ListenerEvent.InviteToQuoteSuccess, refetchEventHandler);
    document.addEventListener(ListenerEvent.QuoteStatusUpdated, refetchEventHandler);
    return () => {
      document.removeEventListener(ListenerEvent.InviteToQuoteSuccess, refetchEventHandler);
      document.removeEventListener(ListenerEvent.QuoteStatusUpdated, refetchEventHandler);
    };
  }, [refetch]);
  const stats = useInviteAndResponseStats(packages);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <InvitesResponsesStatsPanel {...stats} />
      <QuoteCoverage stageId={stageId} packages={packages ?? []} />
    </>
  );
};
