export enum PageType {
  Selection,
  Checkout,
}

export enum MaxSpeciFinderKeywords {
  Starter = 3,
  Standard = 10,
  Professional = 20,
}

export type FeatureDto = {
  text: string;
  types: PageType[];
  term: string;
};
