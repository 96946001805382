import { FontStyle, Heading } from '@ascension/components/fec';

/** SUBBIE_CREDIT_EXPERIMENT_ALPHA_CODE */
export const MultiFreeCreditsExhaustedHeader = () => (
  <Heading level={1} renderAs={FontStyle.h2}>
    You have no more free tenders this month
  </Heading>
);

export const LitePlanCreditsExhaustedHeader = () => (
  <Heading level={1} renderAs={FontStyle.h2}>
    You have no more tenders this month
  </Heading>
);
