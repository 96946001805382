import { useMutation } from '@apollo/client';
import { PACKAGE_DASHBOARD_RFQS } from '../queries';
import { SET_RFQ_QUOTING_STATUS } from '../PackageDashboardTable/mutations';
import {
  SetRfqQuotingStatus,
  SetRfqQuotingStatusVariables,
} from '../PackageDashboardTable/types/SetRfqQuotingStatus';
import { Rfq } from '../types';
import { RfqQuotingStatus } from '@ascension/_gqltypes/builder.generated';

export const useSetRfqQuotingStatus = () => {
  const [setRfqQuotingStatus] = useMutation<SetRfqQuotingStatus, SetRfqQuotingStatusVariables>(
    SET_RFQ_QUOTING_STATUS,
    { refetchQueries: [PACKAGE_DASHBOARD_RFQS] },
  );

  const submit = async (rfq: Rfq, status: RfqQuotingStatus | null) => {
    const { data } = await setRfqQuotingStatus({
      variables: {
        input: {
          rfqId: rfq.id,
          status,
        },
      },
      optimisticResponse: {
        setRfqQuotingStatus: {
          ...rfq,
          quotingStatus: status,
        },
      },
    });
    return data?.setRfqQuotingStatus;
  };

  return { submit };
};
