import { useMutation } from '@apollo/client';
import { UPDATE_LETTING_COLUMN_STATUS } from '../mutations';
import {
  UpdateLettingColumnStatus,
  UpdateLettingColumnStatusVariables,
} from '../types/UpdateLettingColumnStatus';
import { UpdateLettingColumnStatusesItemInput } from '@ascension/_gqltypes/builder.generated';
import { EntityId } from '@ascension/types';

export const useUpdateLettingColumnStatusMutation = () => {
  const [updateLettingColumnStatus, { loading }] = useMutation<
    UpdateLettingColumnStatus,
    UpdateLettingColumnStatusVariables
  >(UPDATE_LETTING_COLUMN_STATUS);

  const submit = async (stageId: EntityId, statuses: UpdateLettingColumnStatusesItemInput[]) => {
    const { data } = await updateLettingColumnStatus({
      variables: {
        input: {
          stageId,
          statuses,
        },
      },
    });
    return data;
  };

  return { updateLettingColumnStatus: submit, loading };
};
