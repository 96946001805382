import { mapCategoryToSelectOption } from './utils';
import {
  SingleSelectField,
  SingleSelectFieldProps,
} from '@shared/FinalForm/Field/SingleSelectField';
import { StageCategory } from '@ascension/_gqltypes/builder.generated';

const STAGE_CATEGORY_OPTIONS = Object.keys(StageCategory).map(mapCategoryToSelectOption);

export type StageCategorySelectOption = { value: StageCategory; label: string };

type StageCategorySelectFieldProps = Omit<
  SingleSelectFieldProps<StageCategorySelectOption>,
  'options'
>;

export const StageCategorySelectField = ({
  id,
  label,
  fieldName,
  ...props
}: StageCategorySelectFieldProps) => (
  <SingleSelectField
    id={id}
    label={label}
    fieldName={fieldName}
    options={STAGE_CATEGORY_OPTIONS}
    {...props}
  />
);
