import { useWatchlistContext } from '.';

export const useApplyStatusUpdate = () => useWatchlistContext().applyStatusUpdate;

export const useAssignProjectToTeamMember = () => useWatchlistContext().assignTeamMember;

export const useAssignmentsByProjectId = () => useWatchlistContext().assignmentsByProject;

export const useWatchlistStatusByProject = () => useWatchlistContext().statusesByProject;

export const useApplyTeamMemberAssignment = () => useWatchlistContext().applyTeamMemberAssignment;

export const useUpdateWatchlistStatus = () => useWatchlistContext().updateStatus;
