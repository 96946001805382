import { useMutation, useQuery } from '@apollo/client';
import { CREATE_RETENTION_SESSION } from '@subbie/pages/LicensesPage/shared/mutations';
import {
  CreateRetentionSessionMutation,
  CreateRetentionSessionMutationVariables,
} from '@subbie/pages/LicensesPage/shared/mutations.generated';
import { GET_LICENSES_TABLE_RESULT } from './queries';
import {
  GetLicenseTableResult,
  GetLicenseTableResult_getLicensesForTable_activeLicenses as ActiveLicenseResult,
  GetLicenseTableResult_getLicensesForTable_currentAccount_licensingInfo_paidLicenseProvider as PaidLicenseProviderResult,
  GetLicenseTableResult_getLicensesForTable_sharedLicenses as SharedLicenseResult,
} from './types/GetLicenseTableResult';

export const useGetLicensesTableResultQuery = () => {
  const { data, refetch } = useQuery<GetLicenseTableResult>(GET_LICENSES_TABLE_RESULT);

  return { data: data?.getLicensesForTable, refetch };
};

export const useCreateRetentionSessionMutation = () => {
  const [createRetentionSession, { loading, error }] = useMutation<
    CreateRetentionSessionMutation,
    CreateRetentionSessionMutationVariables
  >(CREATE_RETENTION_SESSION);

  const submit = async (licenseId: number) => {
    const { data: result } = await createRetentionSession({
      variables: {
        input: {
          licenseId,
        },
      },
    });

    return result?.createRetentionSession;
  };
  return { submit, isLoading: loading, hasError: error !== undefined };
};

export type ActiveLicense = ActiveLicenseResult;
export type SharedLicense = SharedLicenseResult;
export type PaidLicenseProvider = PaidLicenseProviderResult;
