import { useEffect } from 'react';
import { Form } from 'react-final-form';
import { joinClassNames } from '@estimateone/frontend-components';
import { FontStyle, Heading } from '../../../../fec';
import ModalFormErrorsAndButtons from './formSections/ModalFormErrorsAndButtons';
import NotifyOptionOthers from './formSections/NotifyOptionOthers';
import { useUpdateOtherStatus } from '../hooks';
import { UpdateStageStatusFieldName } from '../enums';
import { StageStatus, StageStatusMap } from '@ascension/enums';
import { UpdateOtherStatusFormValues, UpdateStageStatusResponseData } from '../types';
import { EntityId } from '@ascension/types';
import styles from '../styles.scss';

const FORM_ID = 'builder-update-other-stage-status-form';

export type UpdateOtherStatusContentProps = {
  stageId: EntityId;
  status: StageStatus;
  onFormSubmit: (responseData: UpdateStageStatusResponseData) => void;
  onCancel: () => void;
  prevStatus: StageStatus;
};

const UpdateOtherStatusContent = ({
  stageId,
  status,
  onFormSubmit,
  onCancel,
  prevStatus,
}: UpdateOtherStatusContentProps) => {
  const { submit: updateOtherStageStatus, responseData } = useUpdateOtherStatus(
    stageId,
    status,
    prevStatus,
  );
  const statusName = StageStatusMap[status];

  useEffect(() => {
    const { stageId: responseStageId, notifyOption } = responseData;
    if (responseStageId && notifyOption) {
      onFormSubmit({ stageId: responseStageId, notifyOption });
    }
  }, [onFormSubmit, responseData.stageId]);

  return (
    <div className={styles.modalContent}>
      <div className={joinClassNames(styles.modalTitle, styles.modalTitleNoIcon)}>
        <Heading level={1} renderAs={FontStyle.h2}>
          Who should we notify about the project status change?
        </Heading>
      </div>
      <p>
        We will send a short email out to let them know this project has been marked as{' '}
        {statusName.toLowerCase()}.
      </p>
      <Form<UpdateOtherStatusFormValues>
        onSubmit={updateOtherStageStatus}
        initialValues={{
          [UpdateStageStatusFieldName.NotifyInvited]: false,
        }}
      >
        {({ handleSubmit }) => (
          <form id={FORM_ID} onSubmit={handleSubmit}>
            <NotifyOptionOthers />
            <ModalFormErrorsAndButtons statusName={statusName} onCancel={onCancel} />
          </form>
        )}
      </Form>
    </div>
  );
};

export default UpdateOtherStatusContent;
