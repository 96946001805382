import {
  Col,
  Row,
  ChatIllustration,
  SendIllustration,
  EditAddFileIllustration,
  DashboardIllustration,
  SearchContentIllustration,
  GridMatrixIllustration,
  ReplaceIllustration,
  DocumentsIllustration,
  CraneIllustration,
  ToolsIllustration,
  AccessControlIllustration,
  HandoverIllustration,
  RulerPencilIllustration,
} from '@estimateone/frontend-components';
import { useTenderDashboardLinks } from './useTenderDashboardLinks';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

export type TenderDashboardLinksProps = {
  stageId: EntityId;
  stageType: string;
  hasAbView: boolean;
  hasBuilderQuotesFeature: boolean;
  hasStageRetenderAccess: boolean;
  hasTenderHandoverAccess: boolean;
  hasStageRestrictedAccess: boolean;
  hasDocumentIntegration: boolean;
};

export const TenderDashboardLinks = ({
  stageId,
  stageType,
  hasAbView,
  hasBuilderQuotesFeature,
  hasStageRetenderAccess,
  hasTenderHandoverAccess,
  hasStageRestrictedAccess,
  hasDocumentIntegration,
}: TenderDashboardLinksProps) => {
  const {
    getIssueInvitationsLink,
    openUpgradeAddressBook,
    getMonitorResponsesLink,
    getIssueCorrespondenceLink,
    getSearchCorrespondenceLink,
    getAddDeleteDocumentsLink,
    getViewEditPackagesLink,
    goToCreateNewAddendum,
    getAddQuotesLink,
    goToDocumentationHistory,
    getEditProjectDetailsLink,
    getAddRemoveTradesLink,
    getManageProjectAccessLink,
    goToHandoverToConstruction,
    goToReTenderProject,
  } = useTenderDashboardLinks({
    stageId,
    stageType,
  });
  return (
    <div>
      <Row>
        <Col span={4}>
          <div className={styles.infoBox}>
            <h3>Correspondence</h3>
            <p>
              Manage all communication with subcontractors and suppliers here. Issue Invitations to
              Tender, monitor the responses to those invitations, and send and search other forms of
              correspondence.
            </p>
          </div>
        </Col>
        <Col span={8}>
          <div className={styles.iconLinksContainer}>
            <a
              href={hasAbView ? getIssueInvitationsLink() : undefined}
              role={hasAbView ? 'link' : 'button'}
              className={styles.iconLink}
              onClick={hasAbView ? undefined : openUpgradeAddressBook}
            >
              <SendIllustration />
              <div className={styles.iconLinkText}>Issue Tender Invitations</div>
            </a>
            <a className={styles.iconLink} href={getMonitorResponsesLink()}>
              <DashboardIllustration />
              <div className={styles.iconLinkText}>Monitor Responses</div>
            </a>
            <a className={styles.iconLink} href={getIssueCorrespondenceLink()}>
              <ChatIllustration />
              <div className={styles.iconLinkText}>Issue Correspondence</div>
            </a>
            <a className={styles.iconLink} href={getSearchCorrespondenceLink()}>
              <SearchContentIllustration />
              <div className={styles.iconLinkText}>Search Correspondence</div>
            </a>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={4}>
          <div className={styles.infoBox}>
            <h3>Documentation</h3>
            <p>
              Add, edit or delete Tender Documentation here. If you need to issue an addendum, we
              recommend the Addendum Wizard.
            </p>
          </div>
        </Col>
        <Col span={8}>
          <div className={styles.iconLinksContainer}>
            <a className={styles.iconLink} href={getAddDeleteDocumentsLink()}>
              <EditAddFileIllustration />
              <div className={styles.iconLinkText}>Add / Delete Documents</div>
            </a>
            <a className={styles.iconLink} href={getViewEditPackagesLink()}>
              <GridMatrixIllustration />
              <div className={styles.iconLinkText}>View / Edit Packages</div>
            </a>
            {!hasDocumentIntegration ? (
              <button type="button" className={styles.iconLink} onClick={goToCreateNewAddendum}>
                <ReplaceIllustration />
                <div className={styles.iconLinkText}>Create New Addendum</div>
              </button>
            ) : null}
            <a
              className={styles.iconLink}
              role={hasBuilderQuotesFeature ? 'link' : 'button'}
              href={hasBuilderQuotesFeature ? getAddQuotesLink() : undefined}
              onClick={hasBuilderQuotesFeature ? undefined : goToDocumentationHistory}
            >
              <DocumentsIllustration />
              <div className={styles.iconLinkText}>
                {hasBuilderQuotesFeature ? 'Add Quotes' : 'Documentation History'}
              </div>
            </a>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={4}>
          <div className={styles.infoBox}>
            <h3>Management</h3>
            <p>
              Edit your Project Details. You can also modify the trades that have been selected for
              this project (there are currently 105) and hand this project over to the construction
              team.
            </p>
          </div>
        </Col>
        <Col span={8}>
          <div className={styles.iconLinksContainer}>
            <a className={styles.iconLink} href={getEditProjectDetailsLink()}>
              <CraneIllustration />
              <div className={styles.iconLinkText}>Edit Project Details</div>
            </a>
            <a className={styles.iconLink} href={getAddRemoveTradesLink()}>
              <ToolsIllustration />
              <div className={styles.iconLinkText}>Add / Remove Trades</div>
            </a>
            {hasStageRetenderAccess ? (
              <button type="button" className={styles.iconLink} onClick={goToReTenderProject}>
                <RulerPencilIllustration />
                <div className={styles.iconLinkText}>Re-Tender Project</div>
              </button>
            ) : null}
            {hasTenderHandoverAccess ? (
              <button
                type="button"
                className={styles.iconLink}
                onClick={goToHandoverToConstruction}
              >
                <HandoverIllustration />
                <div className={styles.iconLinkText}>Handover to Construction</div>
              </button>
            ) : null}
            {hasStageRestrictedAccess ? (
              <a className={styles.iconLink} href={getManageProjectAccessLink()}>
                <AccessControlIllustration />
                <div className={styles.iconLinkText}>Manage Project Access</div>
              </a>
            ) : null}
          </div>
        </Col>
      </Row>
    </div>
  );
};
