import { useMutation } from '@apollo/client';
import { PriorityOption } from '@builder/features/ProcurementLettingSchedule/PrioritySelect/PrioritySelect';
import { SET_LETTING_SCHEDULE_PRIORITY } from '../../mutations';
import { EntityId } from '@ascension/types';
import {
  SetLettingSchedulePriority,
  SetLettingSchedulePriorityVariables,
} from '@builder/features/ProcurementLettingSchedule/types/SetLettingSchedulePriority';

export const useSetLettingSchedulePriority = (lettingScheduleId: EntityId) => {
  const [mutate] = useMutation<SetLettingSchedulePriority, SetLettingSchedulePriorityVariables>(
    SET_LETTING_SCHEDULE_PRIORITY,
  );

  const setPriority = async (option: PriorityOption | null) =>
    mutate({
      variables: {
        input: {
          lettingScheduleId,
          priority: option?.value,
        },
      },
      optimisticResponse: {
        setLettingSchedulePriority: {
          __typename: 'LettingScheduleDetails',
          id: lettingScheduleId,
          priority: option?.value ?? null,
        },
      },
    });

  return { setPriority };
};
