import $ from 'jquery';
import Routing from 'routing';
import E1Request from '../classes/E1Request';
import FullscreenLauncher from '../classes/FullscreenLauncher';
import { E1PDFViewer, E1PDFViewerLauncher } from '../utils/pdf_doc_viewer';
import {
  DetailsWidget,
  HistoryWidget,
  PackagesWidget,
  SearchModule,
  SupersededByModule,
} from './doc_viewer_components';
import { TreeViewer } from './doc_viewer_tree';
import { sendSpeciFinderInvitationAnalytics } from './utils/sf_invitations_analytics';

$(() => {
  const $body = $('body');

  let docTree = null;
  let pdfViewer = null;
  const $treeOuterContainer = $('.doc-viewer-tree-view');
  const uniqueDocsDownloaded = [];

  let currentDocId = null;

  if ($treeOuterContainer.length > 0) {
    docTree = new TreeViewer($treeOuterContainer);
    $treeOuterContainer.on('doc-tree-loaded', () => {
      if (currentDocId) {
        docTree.focusDoc(currentDocId);
      }
      $('.scrollable-area').simplebar('recalculate');
    });
  }

  const $historyContainer = $('#doc_history');
  const historyWidget = new HistoryWidget($historyContainer);
  historyWidget.setDocTree(docTree);

  const $detailsContainer = $('#doc_info');
  const detailsWidget = new DetailsWidget($detailsContainer);

  const $packagesContainer = $('#doc_packages');
  let packagesWidget;
  if ($packagesContainer.length > 0) {
    packagesWidget = new PackagesWidget($packagesContainer);
  }

  const $searchFormCtn = $('div.search-container');
  const $searchModal = $('.preview-search-modal');
  let searchModule = null;
  if ($searchFormCtn.length > 0 && $searchModal.length > 0) {
    searchModule = new SearchModule($searchFormCtn, $searchModal);
    searchModule.setDocTree(docTree);
  }

  const $supersededByContainer = $('div.superseded-bar');
  const supersededByModule = new SupersededByModule($supersededByContainer);
  supersededByModule.setDocTree(docTree);

  $body.on('click', '.document-viewer-trigger', (e) => {
    const $link = $(e.currentTarget);
    const stageId = $link.attr('data-stage-id');
    const docId = $link.attr('data-document-id');

    if (clientDeviceIsMobileOrTablet()) {
      openDocumentInNewTab(stageId, docId);
      return false;
    }

    const viewerLauncher = new E1PDFViewerLauncher(stageId, {
      docId,
    });
    viewerLauncher.trigger();
  });

  $body.on('click', '.rfq-confirm-document-viewer-trigger', (e) => {
    const rfqId = $(e.currentTarget).attr('data-rfq-id');

    const modalReqUrl = Routing.generate('app_rfq_confirm_docviewmodal', {
      id: rfqId,
    });
    const modalReq = new E1Request(modalReqUrl);
    modalReq.show_loading_modal = true;
    modalReq.submit();
  });

  $body.on('click', '.rfq-document-viewer-trigger', (e) => {
    const $link = $(e.currentTarget);
    const stageId = $link.attr('data-stage-id');
    const rfqId = $link.attr('data-rfq-id');
    const docId = $link.attr('data-document-id') || null;

    sendSpeciFinderInvitationAnalytics(rfqId, 'SF_DocumentsView', window.location.pathname);

    const viewerLauncher = new E1PDFViewerLauncher(stageId, {
      rfqId,
      docId,
    });
    viewerLauncher.launchforrfq();

    const docStatusElem = $(this).closest('tr').find('.doc_status');
    docStatusElem.removeClass('obsolete');
    docStatusElem.removeClass('not-downloaded');
    docStatusElem.addClass('current');
    docStatusElem.text('Current');

    // dispatch an event that can be listened for from react/vanilla js
    const event = new CustomEvent('rfq-update-doc-status', {
      detail: {
        rfqId,
        hasDownloaded: true,
        hasLatestDocs: true,
      },
    });
    document.dispatchEvent(event);
  });

  if ($body.hasClass('doc-viewer-wrapper')) {
    $body.on('click', '.close-doc-viewer', () => {
      window.parent.closeAllDocViewer();
    });

    $body.on('form-submitted-success', 'form.document-details-form', () => {
      if (detailsWidget) {
        detailsWidget.editMode = 0;
        detailsWidget.reload();
      }

      if (historyWidget) {
        historyWidget.reload();
      }
    });

    $body.on('form-submitted-success', 'form.document-package-form', () => {
      window.parent.$('body').trigger('reload-matrix-changes');
    });

    // init the pdf viewer
    const $wrapper = $body.find('.main-content');
    const $docViewer = $wrapper.find('div.doc-viewer');
    const $mainCol = $wrapper.find('.main-col');

    const packagesEditableAttr = $docViewer.attr('data-packages-editable');
    const packagesEditable = parseInt(packagesEditableAttr) === 1;

    $docViewer.on('doc-loaded', (evt, data) => {
      currentDocId = data.docId;
      // docEditable is just an indicator, as we have security on all routes for editing, so if docEditable is not
      // set, we'll just request the edit routes and rely on their security.
      const canEditDoc = data.docEditable === true || data.docEditable === null;

      if (historyWidget) {
        historyWidget.updateDetails(data.stageId, data.docId);
        historyWidget.reload();
      }

      if (detailsWidget) {
        detailsWidget.updateDetails(data.stageId, data.docId);
        detailsWidget.reload();
      }

      if (packagesWidget) {
        packagesWidget.editable = packagesEditable;
        packagesWidget.shouldRequest = canEditDoc;
        packagesWidget.updateDetails(data.stageId, data.docId);
        packagesWidget.reload();
      }

      if (supersededByModule) {
        supersededByModule.updateDetails(data.stageId, data.docId);
        supersededByModule.reload();
      }
    });

    const treeColWidthMin = 240;
    const treeColWidthMax = 500;
    const mainColWidthMin = 500;

    function setToolbarHeight() {
      const $toolbarContainer = $('.toolbarContainer');

      const wrapper = $wrapper.get(0);
      // first, we need to set the height of the wrapper, so that the right-col and doc div's can be set correctly to fullscreen
      const wrapperOffset = wrapper.offsetTop;

      // This should be calc'd dynamically. For some reason, it's not working though;
      const docViewerOffset = wrapperOffset + 0;

      const documentHeight = $body.height();
      const docViewerHeight = documentHeight - docViewerOffset;

      const toolbarOffset = 81;
      const toolbarTopPosition = docViewerHeight - toolbarOffset;
      $toolbarContainer.css({ top: toolbarTopPosition });
    }

    function setMainColWidth() {
      const documentWidth = $body.width();

      let totalWidthOffset = 0;
      $('.main-content > div:not(.main-col)').each(function () {
        totalWidthOffset += $(this).outerWidth();
      });

      const mainColWidth = Math.max(documentWidth - totalWidthOffset, mainColWidthMin);
      $mainCol.width(mainColWidth);
    }

    function setWrapperDimensions() {
      const wrapper = $wrapper.get(0);
      // first, we need to set the height of the wrapper, so that the right-col and doc div's can be set correctly to fullscreen
      const wrapperOffset = wrapper.offsetTop;

      // This should be calc'd dynamically. For some reason, it's not working though;
      const docViewerOffset = wrapperOffset + 0;

      const documentHeight = $body.height();
      const wrapperHeight = documentHeight - wrapperOffset;
      const docViewerHeight = documentHeight - docViewerOffset;

      $wrapper.height(wrapperHeight);
      $docViewer.height(docViewerHeight);

      setMainColWidth();
    }

    setWrapperDimensions();
    setToolbarHeight();
    $(window).on('resize', () => {
      setWrapperDimensions();
      setToolbarHeight();
    });

    if ($docViewer.length > 0) {
      pdfViewer = new E1PDFViewer($docViewer);

      if (searchModule) {
        searchModule.setPdfViewer(pdfViewer);
      }

      if (historyWidget) {
        historyWidget.setPdfViewer(pdfViewer);
      }

      if (supersededByModule) {
        supersededByModule.setPdfViewer(pdfViewer);
      }
    }

    $body.on('click', '.hide-side-col', function () {
      const $toggleBtn = $(this);
      const $treeCol = $wrapper.find('.doc-tree-col');

      if ($wrapper.hasClass('tree-hidden')) {
        $toggleBtn.text('Hide documents').removeClass('closed');

        $wrapper.removeClass('tree-hidden');
        const targetWidth = $treeCol.data('old-width');
        $treeCol.css('display', 'block');
        $treeCol.animate(
          {
            width: targetWidth,
          },
          250,
          'swing',
          () => {
            setMainColWidth();
          },
        );
      } else {
        $toggleBtn.text('Show documents').addClass('closed');

        $wrapper.addClass('tree-hidden');
        $treeCol.data('old-width', $treeCol.width());
        $treeCol.animate(
          {
            width: 0,
          },
          250,
          'swing',
          function () {
            $(this).css('display', 'none');
            setMainColWidth();
          },
        );
      }
    });

    $body.on('click', '.doc-details-toggle', function () {
      const $toggleBtn = $(this);
      const $detailsCol = $wrapper.find('.doc-details-col');

      if ($wrapper.hasClass('details-hidden')) {
        $toggleBtn.removeClass('closed');
        $wrapper.removeClass('details-hidden');
        $detailsCol.width(`${$detailsCol.data('old-width')}px`);
        setMainColWidth();
      } else {
        $toggleBtn.addClass('closed');
        $wrapper.addClass('details-hidden');
        $detailsCol.data('old-width', $detailsCol.width());
        $detailsCol.width('0');
        setMainColWidth();
      }
    });

    $body.on('mousedown', '.doc-tree-col-resize-handle', (e) => {
      e.preventDefault();
      $(document.body).css('cursor', 'col-resize');
      $(document).mousemove((e) => {
        e.preventDefault();
        const x = e.pageX - $('.doc-tree-col').offset().left;
        if (
          x > treeColWidthMin &&
          x < treeColWidthMax &&
          e.pageX < $(window).width() - mainColWidthMin
        ) {
          $('.doc-tree-col').css('width', x);
          setMainColWidth();
        }
      });
    });

    $(document).mouseup((e) => {
      $(document).off('mousemove');
      $(document.body).css('cursor', 'default');
    });
  }

  if (
    $('.doc-viewer-doc-download-btn').length &&
    $('#document-status-container', window.parent.document).length
  ) {
    $('.doc-viewer-doc-download-btn').on('click', () => {
      if ($.inArray(pdfViewer.docId, uniqueDocsDownloaded) < 0) {
        const route = Routing.generate('app_constructionstagedocument_downloadmissingindividual', {
          id: pdfViewer.stageId,
          doc: pdfViewer.docId,
        });

        const request = new E1Request(route);
        request.extraCallback = (res) => {
          if ($.inArray(pdfViewer.docId, uniqueDocsDownloaded) < 0) {
            uniqueDocsDownloaded.push(res.document.toString());
          }

          if (res.wasMissing) {
            // subtract one from total "Not Downloaded" count
            subtractFromDocsDownloadedCount(1);
          }
        };
      }
    });
  }

  $treeOuterContainer.on('click', '.doc-viewer-toggle', (e) => {
    e.preventDefault();
    const $docViewerToggle = $(e.currentTarget);
    const $docViewerTreeView = $('.doc-viewer-tree-view');
    const docId = $docViewerToggle.attr('data-document-id');

    $docViewerTreeView.find('li.active-select').removeClass('active-select');

    $docViewerToggle.closest('li').addClass('active-select');

    if (clientDeviceIsMobileOrTablet()) {
      openDocumentInNewTab($docViewerTreeView.attr('data-stage-id'), docId);
      return false;
    }

    pdfViewer.loadDocument(docId);
    return false;
  });
});

export const DOC_VIEWER_URL_HASH = 'docviewer';

const MOBILE_DEVICE = 'mobile';
const TABLET_DEVICE = 'tablet';
const DESKTOP_DEVICE = 'desktop';

export function subtractFromDocsDownloadedCount(numToSubtruct) {
  const $docCountDisplayElem = $('#docs-not-downloaded', window.parent.document);
  if ($docCountDisplayElem.length) {
    const currentCount = parseInt($docCountDisplayElem.text());

    if (typeof numToSubtruct !== 'number') {
      numToSubtruct = currentCount;
    }

    let newCount = currentCount - numToSubtruct;
    if (newCount < 0) {
      newCount = 0;
    }

    $docCountDisplayElem.text(newCount.toString());
  }
}

// When the document viewer is open, and a user attempts to navigate
// back to the previous page, simply close the document viewer.
window.DOC_VIEWER_IS_OPEN = false;
window.addEventListener('popstate', () => {
  if (window.DOC_VIEWER_IS_OPEN === true) {
    FullscreenLauncher.closeAll();
    window.DOC_VIEWER_IS_OPEN = false;
  }
});

/**
 * @return {String}
 */
function getClientDevice() {
  const $body = $('body');

  if ($body.hasClass('is-tablet')) {
    return TABLET_DEVICE;
  }
  if ($body.hasClass('is-mobile')) {
    return MOBILE_DEVICE;
  }

  return DESKTOP_DEVICE;
}

/**
 * @return {Boolean}
 */
export function clientDeviceIsMobileOrTablet() {
  const clientDevice = getClientDevice();

  return clientDevice === MOBILE_DEVICE || clientDevice === TABLET_DEVICE;
}

/**
 * @param {Number} stageId
 * @param {Number} docId
 */
export function openDocumentInNewTab(stageId, docId) {
  const route = Routing.generate('app_stagedocument_url', {
    id: stageId,
    docId,
  });

  // In order to open files in a new tab/window on Safari, we
  // need to open the new window before starting the async call,
  // then pass the intended URL to the window inside the async callback.
  // See https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari#answer-39387533
  const windowRef = window.open();

  const request = new E1Request(route);
  request.extraCallback = (response) => {
    if (response.url) {
      windowRef.location = response.url;
    }
  };
  request.submit();
}
