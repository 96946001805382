import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useQuery } from '@apollo/client';
import { Step } from './enum';
import { filterAddonsByProductBillingCycle } from './pages/Addons/util';
import { getProductAndPricingForBillingCycle, getShoppingCartProductsForOffer } from './util';
import {
  useAccountOffer,
  useAccountType,
  useHasFeature,
} from '../../context/AccountProvider/hooks';
import { PlanName } from '@plan-select/context/PlanSelectProvider/types';
import { GET_SUBBIE_PRODUCTS_AND_RECOMMENDATION } from './queries';
import { BillingCycle, PaymentMethod } from '@ascension/enums';
import {
  Account,
  AccountOffer,
  CreditCardData,
  ExistingCreditCardData,
  PaymentData,
  PaymentDataUsingExistingCreditCardOrEFT,
  Product,
  ProductAddon,
  ProductWithPrice,
} from './types';
import { GetProductsAndRecommend } from './types/GetProductsAndRecommend';
import { AccountType, FeatureName } from '@ascension/_gqltypes/admin.generated';

type ContextProviderProps = {
  children: ReactNode;
  initialStep: number;
  billingCycle?: BillingCycle;
  productId?: number;
};

export type AccountUpgradeContextProps = {
  changeStep: (step: Step) => void;
  contextLoading: boolean;
  step: Step;
  getShoppingCartItems: () => ProductWithPrice[];
  setAccount: (account: Account) => void;
  account: Account | null;
  accountOffer: AccountOffer | null;
  setAddons: (addons: ProductWithPrice[]) => void;
  addons: ProductWithPrice[];
  setSelectedPlan: (planName: PlanName) => void;
  selectedPlan?: PlanName;
  setErrors: (errors: { [f: string]: string }) => void;
  errors: { [f: string]: string };
  updatePaymentData: (data: Partial<PaymentData>) => void;
  paymentData: PaymentData;
  updatePaymentDataUsingExistingCreditCardOrEFT: (
    data: Partial<PaymentDataUsingExistingCreditCardOrEFT>,
  ) => void;
  paymentDataWithExistingCreditCardOrEFT: PaymentDataUsingExistingCreditCardOrEFT;
  setProduct: (product: ProductWithPrice) => void;
  product: ProductWithPrice | null;
  canAccessAddons: boolean;
  isAccountPopulated: boolean;
  isPaymentDataValid: boolean;
  isPaymentDataWithExistingCCOrEFTValid: boolean;
  availableProducts: Product[];
  availableAddons: ProductAddon[];
  recommendedProduct: Product | null;
  liteProducts: Product[];
  supplierProducts: Product[];
  setOfflineInvoiceUrl: (url: string) => void;
  offlineInvoiceUrl: string;
};

const AccountUpgradeContext = createContext<AccountUpgradeContextProps | undefined>(undefined);

const initialPaymentData: CreditCardData = {
  paymentMethod: PaymentMethod.CC,
  secureCard: null,
  terms: false,
};

const initialPaymentDataWithExistingCC: ExistingCreditCardData = {
  paymentMethod: PaymentMethod.CC,
  creditCardId: null,
  terms: false,
};

export const requiredAccountFields = [
  'companyName',
  'companyPhone',
  'firstName',
  'lastName',
  'address1',
  'state',
  'country',
  'postcode',
];

const AccountUpgradeProvider = ({
  children,
  initialStep,
  billingCycle = undefined,
  productId = undefined,
}: ContextProviderProps) => {
  const [contextLoading, setContextLoading] = useState<boolean>(true);
  const [product, setProduct] = useState<ProductWithPrice | null>(null);
  const [account, setAccount] = useState<Account | null>(null);
  const [addons, setAddons] = useState<ProductWithPrice[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<PlanName | undefined>();
  const [step, setStep] = useState<Step>(initialStep);
  const [errors, setErrors] = useState<{ [f: string]: string }>({});
  const accountOffer = useAccountOffer();
  const canAccessAddons = useHasFeature(FeatureName.SUBBIE_ADDONS);
  const [paymentData, setPaymentData] = useState<PaymentData>(initialPaymentData);
  const [offlineInvoiceUrl, setOfflineInvoiceUrl] = useState<string>('');
  const [paymentDataWithExistingCreditCardOrEFT, setPaymentDataWithExistingCreditCardOrEFT] =
    useState<PaymentDataUsingExistingCreditCardOrEFT>(initialPaymentDataWithExistingCC);
  const { data, loading } = useQuery<GetProductsAndRecommend>(
    GET_SUBBIE_PRODUCTS_AND_RECOMMENDATION,
  );

  const accountType = useAccountType();
  const isSupplier = accountType === AccountType.SUPPLIER;

  useEffect(() => {
    setContextLoading(loading);
    const hasInitialProduct = productId !== null && billingCycle !== null;

    if (accountOffer) {
      setProduct(
        getProductAndPricingForBillingCycle(
          accountOffer.recommendedProduct,
          BillingCycle.Months1,
        ) ?? null,
      );
    } else if (data) {
      const initialProduct = hasInitialProduct
        ? data.products.find(({ id }) => id === productId)
        : undefined;

      const initial = initialProduct ?? data.recommendedProduct;
      const initialProductWithPricing = initial
        ? getProductAndPricingForBillingCycle(initial, billingCycle ?? BillingCycle.Months12)
        : null;

      setProduct(initialProductWithPricing ?? null);
    }
  }, [data, loading, accountOffer, billingCycle, productId]);

  const updatePaymentData = useCallback(
    (newPaymentData: PaymentData) => setPaymentData((p) => ({ ...p, ...newPaymentData })),
    [],
  );

  const updatePaymentDataUsingExistingCreditCardOrEFT = useCallback(
    (newPaymentData: PaymentDataUsingExistingCreditCardOrEFT) =>
      setPaymentDataWithExistingCreditCardOrEFT((p) => ({ ...p, ...newPaymentData })),
    [],
  );

  /**
   * TODO: this is what SRP violation looks like
   */
  const contextValue: AccountUpgradeContextProps = useMemo(() => {
    const changeStep = (newStep: Step) => {
      if (product) {
        setAddons(filterAddonsByProductBillingCycle(product, addons));
      }
      setStep(newStep);
    };

    const getShoppingCartItems = (): ProductWithPrice[] => {
      if (!product) {
        return [];
      }

      const cartOffers = accountOffer ? getShoppingCartProductsForOffer(product, accountOffer) : [];
      const cartAddons = filterAddonsByProductBillingCycle(product, addons);

      return [product, ...cartOffers, ...cartAddons];
    };

    const isAccountPopulated =
      account !== null &&
      requiredAccountFields.every(
        (field: keyof Account) => account[field] !== '' && account[field] !== null,
      );

    const isPaymentDataValid =
      paymentData.terms &&
      (paymentData.paymentMethod === PaymentMethod.EFT ||
        (paymentData.paymentMethod === PaymentMethod.CC &&
          typeof paymentData.secureCard?.secureCardToken === 'string'));

    const isPaymentDataWithExistingCCOrEFTValid =
      paymentDataWithExistingCreditCardOrEFT.terms &&
      (paymentDataWithExistingCreditCardOrEFT.paymentMethod === PaymentMethod.EFT ||
        (paymentDataWithExistingCreditCardOrEFT.paymentMethod === PaymentMethod.CC &&
          paymentDataWithExistingCreditCardOrEFT.creditCardId !== null));

    return {
      changeStep,
      contextLoading,
      step,
      getShoppingCartItems,
      setAccount,
      account,
      accountOffer,
      setAddons,
      addons,
      selectedPlan,
      setSelectedPlan,
      setErrors,
      errors,
      updatePaymentData,
      paymentData,
      updatePaymentDataUsingExistingCreditCardOrEFT,
      paymentDataWithExistingCreditCardOrEFT,
      setProduct,
      product,
      setOfflineInvoiceUrl,
      offlineInvoiceUrl,
      canAccessAddons,
      isAccountPopulated,
      isPaymentDataValid,
      isPaymentDataWithExistingCCOrEFTValid,
      availableProducts: data?.products || [],
      availableAddons: data?.addons || [],
      recommendedProduct: data?.recommendedProduct ?? null,
      liteProducts: data?.lite || [],
      supplierProducts: isSupplier ? data?.supplierProducts || [] : [],
    };
  }, [
    account,
    accountOffer,
    addons,
    contextLoading,
    data,
    selectedPlan,
    errors,
    paymentData,
    paymentDataWithExistingCreditCardOrEFT,
    product,
    canAccessAddons,
    step,
    updatePaymentData,
    updatePaymentDataUsingExistingCreditCardOrEFT,
    isSupplier,
  ]);

  return (
    <AccountUpgradeContext.Provider value={contextValue}>{children}</AccountUpgradeContext.Provider>
  );
};

const useAccountUpgradeContext = () => {
  const ctx = useContext(AccountUpgradeContext);
  if (!ctx) throw Error('AccountUpgradeProvider not a parent');
  return ctx;
};

export default AccountUpgradeContext;

export { AccountUpgradeProvider, Product, useAccountUpgradeContext };
