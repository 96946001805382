import { gql } from '@apollo/client';

export const SEARCH_PROJECTS = gql`
  query SearchE1Projects($input: SearchProjectsInput!) {
    searchE1Projects(input: $input) {
      projects {
        id
        name
        status
        address {
          state {
            id
            name
            shortName
          }
        }
        stageCategoryName
      }
    }
  }
`;
