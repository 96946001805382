import { useMemo } from 'react';
import { Form } from 'react-final-form';
import Routing from 'routing';
import {
  Alert,
  AlertVariant,
  Button,
  ButtonVariant,
  E1Link,
  Icon,
  IconName,
  LinkVariant,
  Slider,
} from '@estimateone/frontend-components';
import { PackageAwardeeSelectField } from './PackageAwardeeSelectField/PackageAwardeeSelectField';
import { EntityId } from '../../../../../../types';
import { GetAwardeesForPackage_package_activeRfqs as ActiveRfqs } from '../types/GetAwardeesForPackage';
import { AwardPackageFormFieldName, AwardPackageFormValues } from './types/AwardPackageFormValues';
import styles from './styles.scss';

const FORM_ID = 'award-package-form';

type AwardPackageFormProps = {
  activeRfqs: ActiveRfqs[];
  haveAllDeclined: boolean;
  packageName: string;
  awardedRfqId?: EntityId;
  preselected: boolean;
  onCancel: () => void;
  onFormSuccess: (awardedRfqId: EntityId) => void;
  notifyAwarded?: boolean;
  stageId: EntityId;
};

type NotificationsAlertProps = {
  packageName: string;
  projectSettingsLink: string;
  notifyAwarded?: boolean;
  companyName: string;
};

const NotificationsAlert = ({
  packageName,
  projectSettingsLink,
  notifyAwarded,
  companyName,
}: NotificationsAlertProps) => (
  <Alert className={styles.alert} variant={AlertVariant.Blue}>
    <div className={styles.alertContent}>
      <div>
        <Icon name={IconName.Info} size={16} />
      </div>
      <div className={styles.infoText}>
        {notifyAwarded ? (
          <div>
            Awarded subcontractors will be notified about document revisions. All other
            (unsuccessful) subcontractors for{' '}
            <span className={styles.infoPackageName}>{packageName}</span> will stop being notified.
          </div>
        ) : (
          <div>
            All subcontractors (including{' '}
            <span className={styles.infoPackageName}>{companyName}</span>) invited to quote for{' '}
            <span className={styles.infoPackageName}>{packageName}</span> will stop receiving
            notifications about document revisions.
          </div>
        )}
        <div>
          {notifyAwarded ? (
            <span>
              To <span className={styles.infoPackageName}>stop</span> notifying awarded
              subcontractors,{' '}
            </span>
          ) : (
            <span>To notify the awarded subcontractor about revisions, </span>
          )}
          <E1Link href={projectSettingsLink} variant={LinkVariant.Primary}>
            go to Project Settings
          </E1Link>
          .
        </div>
      </div>
    </div>
  </Alert>
);

export const AwardPackageForm = ({
  activeRfqs,
  haveAllDeclined,
  packageName,
  awardedRfqId,
  preselected,
  onCancel,
  onFormSuccess,
  notifyAwarded,
  stageId,
}: AwardPackageFormProps) => {
  const handleOnSubmit = (values: AwardPackageFormValues) => {
    onFormSuccess(values[AwardPackageFormFieldName.AwardedTo].value);
  };

  const rfqOptions = useMemo(
    () =>
      activeRfqs.map((rfq) => {
        const { id, quotingStatus, contact } = rfq;
        const companyName = contact?.company?.name ?? '';
        // Checking duplicates using company name rather than id because that is the required distinction in the UI,
        // eg. two companies could have the same name, but different ids - so they need to be distinguished with extra info
        const isDuplicateCompany =
          activeRfqs.filter((fr) => fr.contact?.company?.name.localeCompare(companyName) === 0)
            .length > 1;

        const label = isDuplicateCompany
          ? `${companyName} (${contact?.fullName})`
          : `${companyName}`;

        return {
          label,
          value: id,
          quotingStatus,
          contact,
        };
      }),
    [activeRfqs],
  );

  const awardedOption = rfqOptions.find((rfq) => rfq.value === awardedRfqId);

  const projectSettingsLink: string = Routing.generate('app_stage_settings', {
    id: stageId,
    stageType: 'procurement',
  });

  return (
    <Form<AwardPackageFormValues> onSubmit={handleOnSubmit}>
      {({ handleSubmit }) => (
        <form id={FORM_ID} className={styles.awardPackageForm} onSubmit={handleSubmit}>
          <Slider.Body>
            <PackageAwardeeSelectField
              id="package-awardee-selector"
              label={`Choose the subcontractor that was awarded the package ${packageName}`}
              fieldName={AwardPackageFormFieldName.AwardedTo}
              rfqOptions={rfqOptions}
              haveAllDeclined={haveAllDeclined}
              isRequired
              isDisabled={preselected}
              defaultValue={awardedOption}
            />
            <NotificationsAlert
              packageName={packageName}
              notifyAwarded={notifyAwarded}
              projectSettingsLink={projectSettingsLink}
              companyName={awardedOption?.contact?.company?.name || 'Awarded Subcontractor'}
            />
          </Slider.Body>
          <Slider.Footer>
            <div className={styles.sliderFooter}>
              <Button
                className={styles.sliderButton}
                onClick={onCancel}
                variant={ButtonVariant.Grey}
              >
                Cancel
              </Button>
              <Button className={styles.sliderButton} variant={ButtonVariant.Primary} type="submit">
                Next
              </Button>
            </div>
          </Slider.Footer>
        </form>
      )}
    </Form>
  );
};
