import Routing from 'routing';
import E1Request from '../../../../../js/classes/E1Request';
import { SourceTrackingToken } from '@ascension/enums/Analytics';
import { ProjectRedactedReason } from '@ascension/_gqltypes/subbie.generated';
import { EntityId } from '@ascension/types';

const handlePaywallAnalyticsEvent = (id: EntityId): Promise<{ success: boolean }> =>
  new E1Request(Routing.generate('app_upgrade_paywall_slider', { id })).submit();

export const reasonDescriptions = {
  [ProjectRedactedReason.AWARDED]: (): string => 'This project has been awarded',
  [ProjectRedactedReason.BUDGET]: (projectPaywallValue: number): string =>
    `This project's budget is over $${projectPaywallValue / 1e6} million`,
  [ProjectRedactedReason.CREDIT_EXPERIMENT]: (): string => 'Credit is needed to view this project',
  [ProjectRedactedReason.FIFTY_PERCENT]: (): string =>
    'You can only see half of the projects on E1',
  [ProjectRedactedReason.SINGLE_TENDERER]: (): string => 'N/A',
};

export const trackingToken = {
  [ProjectRedactedReason.AWARDED]: SourceTrackingToken.UPGRADE_ROADBLOCK_AWARDED,
  [ProjectRedactedReason.BUDGET]: SourceTrackingToken.UPGRADE_ROADBLOCK_BUDGET,
  [ProjectRedactedReason.FIFTY_PERCENT]: SourceTrackingToken.UPGRADE_ROADBLOCK_FIFTY,
  [ProjectRedactedReason.CREDIT_EXPERIMENT]: ProjectRedactedReason.CREDIT_EXPERIMENT,
  [ProjectRedactedReason.SINGLE_TENDERER]: SourceTrackingToken.UPGRADE_ROADBLOCK_SINGLE_TENDERER,
};

export { handlePaywallAnalyticsEvent };
